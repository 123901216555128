<!-- #COD: FDS 10/01/2023 Legislacao_Itens -->
<!-- VER: JER __/01/2023 Legislacao_Itens -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      class="mb-n15"
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPageDetail"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context }">
        <c-legislacao-view :context="context" :dataset="dataset" />
      </template>
    </c-base-detail>

    <c-base-list
      v-if="loadList"
      :hasSearch="pageModeView"
      :funcs="funcsList"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :noData="true"
      :noList="true"
      :pageModeEdit="true"
      @showSearchOptions="showSearchOptions = $event"
    >
      <template #header="{ context }">
        <v-row v-bind="prop.row" class="mb-n3">
          <v-col v-bind="prop.col" align="center">
            <v-btn
              v-if="hasItensAtualizados && hasItensAtualizados >= 1"
              text
              color="primary"
              @click="clickOriginalAtualizado(context)"
            >
              {{ isOriginal ? "itens originais" : "itens atualizados" }}
            </v-btn>
            <span v-else class="text-body-1"> itens originais </span>
          </v-col>
        </v-row>
      </template>
      <template #searchInSearch="{ context }">
        <v-container>
          <!-- so atuais -->
          <v-row v-bind="prop.row" v-if="!isOriginal" class="mt-n10">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="soItensVigentes"
                class="ml-n2"
                label="só itens vigentes"
                @change="clickSearch(context)"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template #listContent="{ datasets, context }">
        <c-expansion-panels
          flat
          :pageModeView="true"
          :params="params"
          :isDisabled="isDisabled"
          :value="datasets"
        >
          <template #header="{ dataset: item, index }">
            <v-container class="py-0" :ref="`item${index}`">
              <v-row v-bind="prop.row" v-if="item.idImposto">
                <!-- imposto -->
                <v-col v-bind="prop.col" cols="6">
                  <span
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    {{ getImposto(item.idImposto).nomeAlt }}
                  </span>
                </v-col>
                <!-- mva -->
                <v-col v-bind="prop.col" v-if="item.mva" align="end" cols="6">
                  <span
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    mva {{ numberFormat(item.mva / 100, "percent", 2) }}
                  </span>
                </v-col>
                <!-- base calculo -->
                <v-col
                  v-bind="prop.col"
                  v-else-if="item.baseCalculo"
                  align="end"
                  cols="6"
                >
                  <span
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    bc {{ numberFormat(item.baseCalculo / 100, "percent", 3) }}
                  </span>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row" class="mt-n3">
                <!-- item -->
                <v-col v-bind="prop.col" cols="6">
                  <span
                    v-if="item.item"
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    {{ item.item }}
                  </span>
                </v-col>
                <!-- aliquota -->
                <v-col
                  v-bind="prop.col"
                  v-if="item.aliquota"
                  align="end"
                  cols="6"
                >
                  <span
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    aliquota
                    {{ numberFormat(item.aliquota / 100, "percent", 2) }}
                  </span>
                </v-col>
              </v-row>

              <!-- itemReferencia -->
              <v-row
                v-bind="prop.row"
                v-if="item.item != item.itemReferencia"
                class="mt-n3"
              >
                <v-col v-bind="prop.col">
                  <span
                    v-if="item.item"
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    {{ item.itemReferencia }}
                  </span>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <!-- isSaida -->
                <v-col v-bind="prop.col" cols="6">
                  <span
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    {{
                      item.isSaida == null
                        ? "entrada/saída"
                        : item.isSaida == false
                        ? "entrada"
                        : "saída"
                    }}
                  </span>
                </v-col>
                <!-- isPuxar -->
                <v-col
                  v-bind="prop.col"
                  v-if="item.idReferencia"
                  align="end"
                  cols="6"
                >
                  <span
                    :class="`${
                      (!isOriginal && hasDot(item)) ||
                      (isOriginal && item.isPuxar == null)
                        ? 'text-decoration-line-through'
                        : ''
                    } text-body-2`"
                  >
                    {{ item.isPuxar == false ? "empurra" : "puxa" }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template #content="{ dataset: item, index }">
            <c-legislacao-page-item-view
              v-if="pageModeView"
              :context="context"
              :isOriginal="isOriginal"
              :item="item"
            />
            <c-legislacao-page-item-edit
              v-else
              :context="context"
              :index="index"
              :item="item"
            />
          </template>
        </c-expansion-panels>
      </template>
      <template #insert="{ context }">
        <!-- icone + -->
        <v-divider v-bind="prop.divider" />
        <v-row v-bind="prop.row" v-if="!pageModeView">
          <v-col v-bind="prop.col" cols="1">
            <c-btn icon @click="incluir(context)">
              <v-icon color="green">mdi-plus-circle</v-icon>
            </c-btn>
          </v-col>
          <v-col
            v-bind="prop.col"
            v-if="
              context.dataset.aux.referencias &&
              context.dataset.aux.referencias.filter((ref) => ref.isReferencia)
                .length
            "
            align="center"
            cols="10"
          >
            <c-btn text color="primary" @click="incluir(context)">
              incluir todos
            </c-btn>
          </v-col>
        </v-row>
      </template>
    </c-base-list>

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CLegislacaoPageItemEdit from "@/components/pages/legislacao/pages/CLegislacaoPageItemEdit";
import CLegislacaoPageItemView from "@/components/pages/legislacao/pages/CLegislacaoPageItemView";
import CLegislacaoView from "@/components/pages/legislacao/CLegislacaoView";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";
// import mixinTemplate from "@/mixins/mixinTemplate";

export default {
  /// NAME
  name: "Legislacao_Itens",

  /// COMPONENTS
  components: {
    CLegislacaoPageItemEdit,
    CLegislacaoPageItemView,
    CLegislacaoView,
  },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getLocalidade = this.$store.getters["localidades/getLocalidade"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idFeature: getFeature("optionItens").id,
      idPage: getPagina("legislacaoItens").id,
      idPageAlt: getPagina("legislacoes").id,
      idPageDetail: getPagina("legislacao").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("legislacaoPrincipal").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPais: getLocalidade("brasil").id,
            nome: null,
            data: null,
            dataPublicacao: null,
            dataInicio: null,
            dataFim: null,
            referencias: null,
            pessoas: null,
            manterItensAnteriores: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
        },
        aux: {
          referencias: [],
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          this.contextDetail = context;
          context.params.context = context;

          this.dataset = context.dataset;
          this.id = context.dataset.main.detail.id;

          if (this.contextList && this.loadList) {
            this.contextList.clickSearch();
          }

          this.loadList = true;
        },
      },

      funcsList: {
        adjustButtons: (buttons) => {
          return buttons.filter((button) =>
            !this.isOriginal
              ? !this.getFeature("optionAlterar").id == button.id
              : true
          );
        },

        changed: () => {
          return !this.pageModeView;
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );

          this.funcsList.clickEditView();
        },

        clickEditView: () => {
          this.contextDetail.clickEditView();
          this.pageModeView = !this.pageModeView;
        },

        clickSave: async (context) => {
          this.funcsList.clickEditView();

          const metadados = {
            idEvento: this.getTipo_item("eventoAlterado").id,
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
            idUsuario: context.options.click.getVar("idUsuario"),
            modo: context.options.click.getConst("config").modo.cadastro.id,
          };

          const datasetsSave = {
            metadados,
            main: {
              detail: this.dataset.main.detail,
              itens: context.options.click.datasets
                .reverse()
                .filter((dataset, i) => {
                  const datasetTemp = context.options.click.datasetsTemp[i];
                  // datasetTemp.dataInicio = this.dateFormat(
                  //   datasetTemp.dataInicio,
                  //   "yyyy-MM-dd"
                  // );
                  // datasetTemp.dataFim = this.dateFormat(
                  //   datasetTemp.dataFim,
                  //   "yyyy-MM-dd"
                  // );

                  return (
                    !dataset.id ||
                    Object.keys(dataset).some((key) => {
                      return datasetTemp[key] !== undefined
                        ? dataset[key] != datasetTemp[key]
                        : false;
                    })
                  );
                })
                .map((dataset) => {
                  return {
                    id: dataset.id,
                    idPai: dataset.idPai,
                    idImposto: dataset.idImposto,
                    item: dataset.item,
                    itemReferencia: dataset.itemReferencia,
                    idLocalidadesOrigem: dataset.idLocalidadesOrigem,
                    idLocalidadesDestino: dataset.idLocalidadesDestino,
                    codigosFiscais: dataset.codigosFiscais,
                    codigosFiscaisExcecoes: dataset.codigosFiscaisExcecoes,
                    codigosFiscais2: dataset.codigosFiscais2,
                    codigosFiscais2Excecoes: dataset.codigosFiscais2Excecoes,
                    options: dataset.options,
                    isCumulativo: dataset.isCumulativo,
                    mva: dataset.mva,
                    baseCalculo: dataset.baseCalculo,
                    aliquota: dataset.aliquota,
                    idReferencia: dataset.idReferencia,
                    isPuxar: dataset.isPuxar,
                    isSaida: dataset.isSaida,
                    observacoes: dataset.observacoes,
                    del: dataset.del || false,
                  };
                }),
            },
          };

          datasetsSave.main.itens = datasetsSave.main.itens.reverse();

          const method = "put";
          let url = `${context.options.click.getConst("app").baseUrlBackend}/${
            context.options.click.pageAlt.nome
          }`;
          const res = await context.options.click.submit(
            method,
            url,
            datasetsSave
          );

          if (res) {
            context.options.click.clickSearch();
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          let params = null;

          params = {
            idLegislacao: this.id,
            isOriginal: this.isOriginal,
            soItensVigentes: this.soItensVigentes,
          };

          return JSON.stringify(params);
        },

        getDetail: () => {
          return this.dataset;
        },

        posSubmit: async (dataset, context, datasets, index) => {
          dataset.valorItem = dataset.valor;
          this.hasItensAtualizados = !!parseFloat(
            context?.datasetList?.dataset?.aux?.detail?.hasItensAtualizados
          );

          if (index == 0) {
            this.contextList = context;
            context.page.nomeAjuda = context.pageAlt.nome;
          }

          dataset.del = false;
        },
      },

      params: {
        context: null,
      },

      contextDetail: null,
      contextList: null,
      datas: [],
      hasItensAtualizados: false,
      isOriginal: true,
      loadList: false,
      pageModeView: true,
      search: "",
      showSearchOptions: false,
      soItensVigentes: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getImposto: "impostos/getImposto",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
    }),
  },

  /// BEFOREMOUNT
  // beforeMount() {
  //   const page = this.getPagina(this.idPage);
  //   page.noPaginacao = true;
  // },

  /// METHODS
  methods: {
    clickOriginalAtualizado(context) {
      this.isOriginal = !this.isOriginal;
      this.clickSearch(context);
    },

    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    hasDot(item) {
      return item && item.dataFim && this.parseISO(item.dataFim) < new Date();
    },

    incluir(context) {
      const itens = {
        id: null,
        idPai: null,
        idImposto: null,
        item: null,
        itemReferencia: null,
        idLocalidadesOrigem: [],
        idLocalidadesDestino: [],
        codigosFiscais: [],
        codigosFiscaisExcecoes: [],
        codigosFiscais2: [],
        codigosFiscais2Excecoes: [],
        origens: [],
        mva: null,
        baseCalculo: null,
        aliquota: null,
        idReferencia: null,
        observacoes: null,
        del: false,
      };

      context.datasets.push(itens);

      this.goTo(`panel${context.datasets.length}`);
    },

    isDisabled(dataset) {
      return (
        dataset.id &&
        !(
          dataset.codigosFiscais?.length ||
          dataset.codigosFiscaisExcecoes?.length ||
          dataset.codigosFiscais2?.length ||
          dataset.codigosFiscais2Excecoes?.length ||
          dataset.idLocalidadesDestino?.length ||
          dataset.idLocalidadesOrigem?.length ||
          (!this.isOriginal && dataset.idReferencia) ||
          dataset.mensagem ||
          dataset.observacoes ||
          dataset.options
        )
      );
    },
  },
};
</script>