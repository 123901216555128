var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-menu',{attrs:{"classe":"ml-n2","items":_vm.paisesAtivos},scopedSlots:_vm._u([{key:"btn",fn:function(ref){
var itemSelected = ref.itemSelected;
return [_c('v-img',{staticClass:"mr-2",attrs:{"alt":"imagem","contain":"","src":itemSelected.image,"width":"25"}}),(itemSelected)?_c('span',{staticClass:"text=body-2"},[_vm._v(" "+_vm._s(itemSelected.text)+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"alt":"imagem","contain":"","src":item.image,"width":"25"}}),_c('v-list-item-title',{staticClass:"ml-2 text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.dataset.main.detail.idPais),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idPais", $$v)},expression:"dataset.main.detail.idPais"}})],1)],1),(
      _vm.getTipo_item('conviteContato').id == _vm.dataset.main.detail.idTipo ||
      _vm.getTipo_item('conviteColaborador').id == _vm.dataset.main.detail.idTipo
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":_vm.labelDocumentoFederal,"max":25,"options":{
          mask: _vm.getDocumentoMask(_vm.dataset.main.detail),
        },"required":""},model:{value:(_vm.dataset.main.detail.documentoFederal),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "documentoFederal", $$v)},expression:"dataset.main.detail.documentoFederal"}})],1)],1):_vm._e(),(
      _vm.getTipo_item('conviteContato').id == _vm.dataset.main.detail.idTipo ||
      _vm.getTipo_item('conviteColaborador').id == _vm.dataset.main.detail.idTipo
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nome e sobrenome","max":25,"required":""},model:{value:(_vm.dataset.main.detail.nomeAlt),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "nomeAlt", $$v)},expression:"dataset.main.detail.nomeAlt"}})],1)],1):_vm._e(),(
      _vm.getTipo_item('conviteContato').id == _vm.dataset.main.detail.idTipo ||
      _vm.getTipo_item('conviteColaborador').id == _vm.dataset.main.detail.idTipo
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"email","max":80,"required":""},model:{value:(_vm.dataset.main.detail.email),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "email", $$v)},expression:"dataset.main.detail.email"}})],1)],1):_vm._e(),(
      _vm.getTipo_item('conviteContato').id == _vm.dataset.main.detail.idTipo ||
      _vm.getTipo_item('conviteColaborador').id == _vm.dataset.main.detail.idTipo
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"celular","max":15,"options":{
          mask: _vm.dataset.main.detail.celular ? _vm.mascara : null,
          options: _vm.options,
        },"placeholder":_vm.placeholderTelefone,"type":"tel"},model:{value:(_vm.dataset.main.detail.celular),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "celular", $$v)},expression:"dataset.main.detail.celular"}})],1)],1):_vm._e(),(_vm.dataset.aux.detail.pessoaColaborador_nomeAlt)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("convidado por")])])],1):_vm._e(),(_vm.dataset.aux.detail.pessoaColaborador_nomeAlt)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dataset.aux.detail.pessoaColaborador_nomeAlt)+" ")])])],1):_vm._e(),(_vm.dataset.main.detail.data)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.dataset.main.detail.data, "date timeNoSecond"))+" ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }