<!-- COD: LYF 05/07/2022 CFeriadoEdit -->
<!-- *VER: JER 20/06/2022 CFeriadoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- abrangencia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="6">
        <c-select
          v-model="dataset.main.detail.idAbrangencia"
          clearable
          :items="abrangencia"
          label="abrangência"
          required
        />
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.observacao"
          label="observação"
          :max="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CFeriadoEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
    }),

    abrangencia() {
      return this.getTipo_itens(this.getTipo(`tipoLocalidade`).id).map(
        (localidade) => {
          return {
            value: localidade.value,
            text: this.getTipo_item(localidade.value).options.abrangencia,
          };
        }
      );
    },
  },
};
</script>