// COD: FDS 10/08/2022 mixinTemplate
// *VER: JER 19/08/2022 mixinTemplate
// /12/

import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinLib from "@/mixins/mixinLib";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";
import mixinUtil from "@/mixins/mixinUtil";


export default {
  /// NAME
  name: "mixinTemplate",

  /// COMPONENTS
  components: { CTheNavbar },

  /// MIXINS
  mixins: [
    mixinBottomSheet,
    mixinCarrinhos,
    mixinLib,
    mixinSnackbar,
    mixinSwipe,
    mixinUsuarios,
    mixinUtil
  ],

  /// HEAD
  head: {
    title() {
      return {
        inner: this.page.nomeAlt
      }
    },
  },

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];

    return {
      idPageMode: getConst("config").pageMode.view.id
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getImposto: "impostos/getImposto",
      getLocalidade_codigosAreas: "localidades/getLocalidade_codigosAreas",
      getLocalidade: "localidades/getLocalidade",
      getPagina_coluna: "paginas/getPagina_coluna",
      getPagina_colunas: "paginas/getPagina_colunas",
      getPagina: "paginas/getPagina",
      getPagina_documentos: "paginas/getPagina_documentos",
      getTabela_coluna: "tabelas/getTabela_coluna",
      getTabela_colunas: "tabelas/getTabela_colunas",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getVar: "variaveis/getVar",
    }),

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true,
        this.dataset?.main?.detail ?? null,
        this.idPageMode || null,
        this.dataset?.main?.detail?.idTipo || this.page.idContexto || null,
        this.dataset?.main?.detail?.idEstado || null,
        this.dataset?.main?.objProprietarios || null
      )
        .filter(button =>
          this.idPage != this.getPagina("pessoa").id
          || ![this.getFeature("optionPageParceiros").id].includes(button.idFeature)
          || !this.$route.params.id
          || [parseInt(this.$route.params.id), this.getConst("app").id].includes(this.getVar("idPessoaParceiro"))
        )
        .sortKeys('"order"');
    },

    feature() {
      return this.getFeature(this.idFeature);
    },

    icon() {
      return this.getFeature(this.page.idFeature)?.options?.icon;
    },

    options() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        false,
        this.dataset?.main?.detail ?? null,
        // this.dataset?.main?.detail?.idEstado ?? null,
        this.idPageMode || null,
        this.dataset?.main?.detail?.idTipo || this.page.idContexto || null,
        this.dataset?.main?.detail?.idEstado || null,
        this.dataset?.main?.objProprietarios || null
      )
        .filter(option =>
          this.idPage != this.getPagina("pessoa").id
          || ![this.getFeature("optionPageAnexos").id].includes(option.idFeature)
          || !this.$route.params.id
          || [parseInt(this.$route.params.id), this.getConst("app").id].includes(this.getVar("idPessoaParceiro"))
        )
        .map(option => {
          option.text = this.translate(option.text)
          return option
        })
        .sortKeys('"order"');
    },

    page() {
      return this.getPagina(this.idPage);
    },

    pageAlt() {
      return this.getPagina(this.idPageAlt);
    },

    pageModeView() {
      return this.funcs?.changed
        ? !this.changed
        : this.idPageMode == this.getConst("config").pageMode.view.id
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar"
    }),

    buttonTest(idButton) {
      return !!this.buttons.find(button => button.idFeature == idButton)
    },

    goTo(id) {
      setTimeout(() => {
        const params = {
          duration: 1000,
          offset: 0,
          easing: "linear",
        };
        const element = document.getElementById(id);
        this.$vuetify.goTo(element, params);
      }, 10);
    },

    modelCampo(model) {
      model = model.match(/\[.+\]$/) || model.split(".")
      return model[model.length - 1].replace(/^\[|\]$/g, "")
    },

    async submit(method, url, dataset, scrollTop = true) {
      this.setVar({ overlay: true });
      let result

      await axios[method](url, dataset)
        .then(res => {
          if (scrollTop) {
            window.scrollTo(0, 0);
          }

          result = res
        })
        .catch(err => {
          const erroMessagePadrao = "erro ao executar o comando" //? ajustar mensagem generica de erro.
          let erroMessage = err?.response?.data?.detail || err?.response?.data || erroMessagePadrao
          erroMessage = typeof erroMessage === "string" ? erroMessage : erroMessagePadrao
          erroMessage = (erroMessage || "").includes("already exists") ? "Registro duplicado" : erroMessage;

          this.setSnackbar(true, "ERRO", erroMessage, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
          }, this.getConst("config").overlay.timeout)
        });
      return result
    },
  }
}