<!-- COD: LYF 26/07/2022 CProdutoPageInventarioEdit -->
<!-- *VER: JER 10/08/2022 CProdutoPageInventarioEdit -->
<!-- /22/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- localizacoes -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip-group v-model="produto.localizacao" style="width: fit-content">
          <v-chip
            v-for="(localizacao, i) in localizacoes"
            :class="localizacaoClass(localizacao)"
            :disabled="context.funcs.changed(context)"
            :key="i"
            :outlined="localizacaoOutlined(localizacao)"
            small
            :value="localizacao.value"
            @click="clickLocalizacao(context, dataset)"
          >
            {{ localizacao.text }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- unidades por caixa -->
      <v-col v-bind="prop.col" cols="3">
        <c-text-money
          v-model="produto.unidadesPorCaixa"
          disabled
          label="un/cx"
          :max="13"
          :options="{
            length: 11,
            precision: produto.decimalEstoque,
            style: 'decimal',
          }"
        />
      </v-col>

      <!-- caixas -->
      <v-col v-bind="prop.col" cols="3">
        <c-text-money
          v-model="produto.caixas"
          :disabled="!parceiroInventario"
          label="caixas"
          :max="13"
          :options="{
            length: 11,
            precision: produto.decimalEstoque,
            style: 'decimal',
          }"
          :required="isRequired"
        />
      </v-col>

      <!-- unidades -->
      <v-col v-bind="prop.col" cols="3">
        <c-text-money
          v-model="produto.unidades"
          :disabled="!parceiroInventario"
          label="unidades"
          :max="13"
          :options="{
            length: 11,
            precision: produto.decimalEstoque,
            style: 'decimal',
          }"
          :required="isRequired"
        />
      </v-col>

      <!-- inventario -->
      <v-col v-bind="prop.col" cols="3">
        <c-text-field
          v-model="inventario"
          disabled
          :clearable="false"
          label="inventario"
          :max="20"
        />
      </v-col>
    </v-row>

    <!-- data contagem -->
    <v-row v-bind="prop.row" v-if="showColaboradores && produto.dataContagem">
      <v-col v-bind="prop.col">
        <span class="text-caption">
          {{
            `${dateFormat(produto.dataContagem, "date timeNoSecond")} ${
              produto.colaborador
            }`
          }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CProdutoPageInventarioEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object, required: true },
    dataset: { type: [Array, Object], required: true },
    produto: { type: Object, required: true },
    showColaboradores: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),

    inventario() {
      return (
        parseFloat(this.produto.caixas) * this.produto.unidadesPorCaixa +
          parseFloat(this.produto.unidades) || "0"
      );
    },

    isRequired() {
      return (this.dataset.caixas ?? this.dataset.unidades) != null;
    },

    localizacoes() {
      return this.parceiro.localizacoes.map((localizacao) => {
        return {
          value: localizacao,
          text: localizacao,
        };
      });
    },

    parceiro() {
      return this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      ).parceiro;
    },

    parceiroInventario() {
      return this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      ).parceiro.inventario;
    },
  },

  /// METHODS
  methods: {
    changeLocalizacao(context) {
      this.reloadParams(context);
    },

    clickLocalizacao: (context, dataset) => {
      setTimeout(() => {
        if (dataset.localizacoes) {
          this.reloadLocalizacao(dataset);

          this.changeLocalizacao(context);
        }
      }, 10);
    },

    localizacaoClass(localizacao) {
      return `primary ${
        localizacao.value != this.produto.localizacao
          ? "primary--text"
          : "white--text"
      }`;
    },

    localizacaoOutlined(localizacao) {
      return localizacao.value != this.produto.localizacao;
    },

    reloadLocalizacao(dataset) {
      if (this.pendentes) {
        this.reloadLocalizacoes = true;

        this.localizacaoInicial[0] = {
          value: dataset.localizacao,
          text: dataset.localizacao,
        };

        this.localizacao = dataset.localizacao;
        setTimeout(() => (this.reloadLocalizacoes = false), 10);
      }
    },

    async reloadParams(context) {
      context.params = await context.funcs.fnParams(context);
      context.clickSearch();
    },
  },
};
</script>