<!-- COD: FDS 04/07/2022 CColaboradorView -->
<!-- *VER: JER 05/07/2022 CColaboradorView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- situacaoTrabalho -->
    <v-row v-bind="prop.row" v-if="situacaoTrabalho">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ situacaoTrabalho }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- colaborador -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoa_nome">
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="primary--text" text @click="clickPessoa()">
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- grupo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.grupo">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ dataset.main.detail.grupo }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- dataAdmissaoDemissao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataAdmissaoDemissao }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CColaboradorView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    dataAdmissaoDemissao() {
      return `${this.dateFormat(
        this.dataset.main.detail.dataAdmissao,
        "date"
      )} - ${
        this.dataset.main.detail.dataDemissao
          ? `${this.dateFormat(this.dataset.main.detail.dataDemissao, "date")}`
          : "atual"
      }`;
    },

    situacaoTrabalho() {
      const situacaoTrabalho = this.dataset.main.situacoesTrabalhos.find(
        (situacaoTrabalho) =>
          this.parseISO(situacaoTrabalho.dataInicio) <= new Date() &&
          (!situacaoTrabalho.dataFim ||
            this.parseISO(situacaoTrabalho.dataFim) >= new Date())
      );

      return situacaoTrabalho
        ? this.getTipo_item(situacaoTrabalho.idSituacaoTrabalho).nomeAlt
        : this.getTipo_item("trabalhoNormal").nomeAlt;
    },
  },

  /// METHODS
  methods: {
    clickPessoa() {
      this.$router.push(
        `/pessoa/${this.dataset.main.detail.idPessoaColaborador}`
      );
    },
  },
};
</script>