<!-- COD: BJB 09/08/2022 CBtn -->
<!-- *VER: JER 10/08/2022 CBtn -->
<!-- /11/ -->

<template>
  <c-help
    :estilo="btnStyle"
    :help="help"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :value="!isHelp && hasHelp"
  >
    <v-btn
      v-bind="$attrs"
      :class="classe"
      :icon="icon || isHelp"
      :ripple="false"
      @click="click"
      @mousedown="$emit('mousedown', $event)"
      @mouseup="$emit('mouseup', $event)"
    >
      <slot>
        <v-icon v-if="isHelp">mdi-information-outline</v-icon>
      </slot>
    </v-btn>
  </c-help>
</template>

<script>
export default {
  /// NAME
  name: "CBtn",

  /// PROPS
  props: {
    classe: { type: String },
    help: { type: Object },
    icon: { type: Boolean, default: false },
    isHelp: { type: Boolean, default: false },
  },

  /// COMPUTED
  computed: {
    btnStyle() {
      return `width: ${"block" in this.$attrs ? "100%" : "fit-content"}`;
    },

    offsetX() {
      return this.help?.offsetX || "18";
    },

    offsetY() {
      return this.help?.offsetY || "16";
    },

    hasHelp() {
      return this.help?.hasHelp ?? true;
    },
  },

  /// METHODS
  methods: {
    click(event) {
      if (this.isHelp) {
        this.help.function(this.help);
      } else {
        this.clickElement(event);
      }
    },
  },
};
</script>