<!-- COD: LYF 04/07/2022 CFeriadoView -->
<!-- *VER: JER 05/07/2022 CFeriadoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- abrangencia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{
            getTipo_item(dataset.main.detail.idAbrangencia).options.abrangencia
          }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.observacao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">({{ dataset.main.detail.observacao }})</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CFeriadoView",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>