<!-- COD: LYF 01/07/2022 Transacoes -->
<!-- *VER: JER 04/07/2022 Transacoes -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-transacoes :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CTransacoes from "@/components/pages/transacao/CTransacoes";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Transacoes",

  /// COMPONENTS
  components: {
    CTransacoes,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("transacoes").id,
      idPageAlt: getPagina("transacao").id,

      funcs: {
        clickChip: (context) => {
          this.idPessoaParceiro = null;
          this.pessoaParceiro = null;
          context.params = this.funcs.fnParams();
          context.clickSearch();
        },

        fnParams: () => {
          return JSON.stringify({
            idPessoaParceiro: this.idPessoaParceiro,
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      idPessoaParceiro: null,
      pessoaParceiro: null,
    };
  },
};
</script>