var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-help',{attrs:{"help":_vm.help,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY}},[_c('v-select',_vm._b({ref:_vm.reference,class:_vm.classe,attrs:{"disabled":_vm.$attrs.disabled || !_vm.itemsTraduzidos.length,"items":_vm.itemsTraduzidos,"label":_vm.cLabel,"multiple":_vm.multiple,"offset-y":"","rules":_vm.cRules ||
      _vm.commonRules.common(
        'select',
        _vm.required,
        null,
        1,
        null,
        null,
        _vm.msg,
        null,
        null,
        _vm.panelCallback
      )},on:{"change":function($event){return _vm.$emit('change', $event)},"click":function($event){return _vm.$emit('click', $event)},"click:prepend-inner":function($event){return _vm.$emit('clickPrependInner', $event)}},scopedSlots:_vm._u([(
        _vm.cProperties &&
        (_vm.cProperties.chip || _vm.cProperties.image || _vm.cProperties.icon)
      )?{key:"selection",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [(_vm.cProperties.image)?_c('img',{staticClass:"mr-1",attrs:{"src":item.image,"width":"25"}}):_vm._e(),(_vm.cProperties.chip)?_c('draggable',_vm._b({attrs:{"id":index,"list":_vm.model,"move":_vm.move},on:{"change":_vm.change}},'draggable',_vm.dragOptions,false),[_c('v-chip',{attrs:{"draggable":"","small":"","text-color":"white"},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_c('span',{staticClass:"mr-4"},[_vm._l((item ? item.icons : []),function(icon,i){return _c('v-icon',_vm._b({key:i,class:("ml-1 " + (icon.class || ''))},'v-icon',icon.properties,false),[_vm._v(" "+_vm._s(icon.icon)+" ")])}),_vm._v(" "+_vm._s(item.text)+" ")],2)]}}:null,(_vm.cProperties && _vm.cProperties.image)?{key:"item",fn:function(ref){
      var item = ref.item;
return [(_vm.multiple)?_c('c-checkbox',{attrs:{"value":_vm.model.includes(item.value)}}):_vm._e(),_c('img',{staticClass:"mr-2",attrs:{"src":item.image,"width":"25"}}),_vm._v(" "+_vm._s(item.text)+" ")]}}:(_vm.cProperties && _vm.cProperties.icon)?{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":item.colsIcon || 1}},_vm._l((item ? item.icons : []),function(icon,i){return _c('v-row',{key:i},[_c('v-icon',_vm._b({class:("ml-1 mb-1 " + (icon.class || ''))},'v-icon',icon.properties,false),[_vm._v(" "+_vm._s(icon.icon)+" ")])],1)}),1),_c('v-col',{attrs:{"cols":item.colsText || 11}},[_c('span',{class:item.classText || ''},[_vm._v(" "+_vm._s(item.text)+" ")])])],1)]}}:null,(_vm.multiple && _vm.cProperties.todos)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.selectAll}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":(_vm.model || []).length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.iconSelectAll)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("todos")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-select',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }