var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.load)?_c('c-base-detail',{attrs:{"funcs":_vm.funcs,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"images",fn:function(ref){
var dataset = ref.dataset;
var pageModeView = ref.pageModeView;
return [_c('c-base-detail-images',{attrs:{"dataset":dataset,"height":70,"pageModeView":pageModeView,"width":70}})]}},{key:"default",fn:function(ref){
var context = ref.context;
var page = ref.page;
var pageModeView = ref.pageModeView;
return [(_vm.dataset.main.detail.idTipo == 0)?_c('c-base-detail-insert',{attrs:{"dataset":_vm.dataset,"idTipo":_vm.getPagina(page.nome).id}}):_c('div',[(_vm.dataset.main.detail.id && context.showEstado)?_c('c-base-dialog-estado',{attrs:{"context":context,"datasetTemp":JSON.parse(JSON.stringify(context.dataset)),"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"show":context.showEstado,"value":JSON.parse(JSON.stringify(context.dataset))},on:{"alterado":function($event){context.showEstado = false},"close":function($event){context.showEstado = false}}}):_vm._e(),(pageModeView)?_c('c-localidade-view',{attrs:{"context":context,"dataset":_vm.dataset}}):_c('c-localidade-edit',{attrs:{"context":context,"dataset":_vm.dataset}})],1)]}}],null,false,1080179800),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }