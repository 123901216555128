<!-- COD: FDS 04/07/2022 CContaContabilView -->
<!-- *VER: JER 05/07/2022 CContaContabilView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nivel -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nivel">
      <v-col v-bind="prop.col" align="center">
        <v-badge
          :color="dataset.main.detail.allowLancamento ? 'green' : 'grey'"
          :content="dataset.main.detail.nivel"
          inline
          overlap
          :value="!!dataset.main.detail.nivel"
        />
      </v-col>
    </v-row>

    <!-- pai -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPai">
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="primary--text" text @click="clickContaContabil()">
          {{ getContaContabil(dataset.main.detail.idPai).nomeAlt }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nome">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- detalhe -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idTipoDetalhe">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">tipo detalhe</span>
        <span class="text-body-1">
          {{ getTipo_item(dataset.main.detail.idTipoDetalhe).nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- isDetalheRequerido -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.isDetalheRequerido">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          detalhe requerido
        </v-chip>
      </v-col>
    </v-row>

    <!-- permiteLancamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.allowLancamento">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          permite lançamento
        </v-chip>
      </v-col>
    </v-row>

    <!-- permiteLancamentoManual -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.allowLancamentoManual">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          permite lançamento manual
        </v-chip>
      </v-col>
    </v-row>

    <!-- isSaldoDevedor -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.isSaldoDevedor">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          saldo devedor
        </v-chip>
      </v-col>
    </v-row>

    <!-- isSaldoCredor -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.isSaldoCredor">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          saldo credor
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CContaContabilView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getContaContabil: "contasContabeis/getContaContabil",
    }),
  },

  /// METHODS
  methods: {
    clickContaContabil() {
      this.$router.push(`/contaContabil/${this.dataset.main.detail.idPai}`);
    },
  },
};
</script>