<!-- COD: LYF 03/08/2022 Home -->
<!-- *VER: JER 10/08/2022 Home -->
<!-- /33/ -->

<template>
  <v-container
    v-touch="{
      down: ($event) => swipe('down', swipeRefresh, $event),
      up: ($event) => swipe('up', swipeInject, $event),
    }"
    class="pa-0"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="home" :slots="buttonsTop.length" :title="title">
      <template #icon>
        <v-icon large>mdi-home-outline</v-icon>
      </template>
      <template v-for="(button, i) in buttonsTop" v-slot:[i]>
        <div :key="i">
          <span v-if="showNotificacoes(button)">
            <v-badge
              bottom
              :color="badgePendenciasColor"
              :content="getVar('pendencias').notificacoes.value"
              overlap
              :value="getVar('pendencias').notificacoes.value"
            >
              <c-btn classe="ml-n3 mt-n3" icon @click="clickOption(button)">
                <v-icon color="white" large>
                  {{ button.options.icon }}
                </v-icon>
              </c-btn>
            </v-badge>
          </span>
          <c-btn v-else classe="ml-n2" icon @click="clickOption(button)">
            <v-icon large>
              {{ button.options.icon }}
            </v-icon>
          </c-btn>
        </div>
      </template>
    </c-the-navbar>

    <!-- ??? Onde posicionar o snacbar? -->
    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// REFRESH -->
    <!-- refresh -->
    <v-row v-bind="prop.row" v-if="!isTouchDevice">
      <v-col v-bind="prop.col" align="center">
        <c-btn fab small @click="getFeed()">
          <v-icon>mdi-refresh</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <!-- /// AUTH -->
    <c-home-dialog-auth
      :show="!!showAuth"
      @close="authClose($event)"
      @showDialogSenha="showDialogSenha"
    />

    <!-- /// SENHA -->
    <c-base-dialog-senha
      v-if="idUsuarioSenha && showSenha"
      :idPessoa="idUsuarioSenha"
      :objetoCodigo="objetoCodigo"
      :show="showSenha"
      @alterado="senhaAlterada"
      @close="showSenha = false"
    />

    <!-- /// CARROSSEL -->
    <div v-show="getVar('usuarios').length == 0">
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <v-carousel
            class="mb-8"
            :cycle="carouselCycle"
            height="auto"
            hide-delimiters
            :interval="loadImages ? getConst('carousel').interval : 25"
            progress
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in getConst('carousel').src"
              :class="!loadImages ? 'loadingImage' : ''"
              :key="i"
              :src="item"
              @click.stop="carouselCycle = !carouselCycle"
              @load="loadImage(getConst('carousel').src, i)"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <v-img
            v-if="imgInstale"
            alt="imagem"
            class="ml-0 mt-n6"
            contain
            :src="imgInstale"
            max-width="500"
          />
        </v-col>
      </v-row>

      <!-- <v-divider /> -->
    </div>

    <!-- /// TIMELINE -->
    <v-container class="pl-7">
      <!-- categorias -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <c-base-categorias />
        </v-col>
      </v-row>

      <v-divider v-bind="prop.divider" class="mb-6" />

      <!-- <div class="text-h6">
        {{ backendBaseUrl }}
      </div>
      <div class="text-h6">
        Env:
        {{ env }}
      </div> -->

      <!-- entregar retirar -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <v-badge
            color="grey"
            :content="feed.length.toString()"
            left
            :value="feed"
          />
          <v-chip
            v-if="isEntregar != null"
            class="primary white--text text-overline ml-4"
            close
            @click:close="isEntregar = null"
          >
            {{ isEntregar ? "entregar" : "retirar" }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>

    <!-- max-width="600px" -->
    <v-card
      v-for="(el, i) in feed"
      class="pa-3 mx-auto"
      flat
      :key="el.id"
      @click="cardAction(el)"
    >
      <v-divider v-bind="prop.divider" v-if="i > 0" class="my-4" />

      <v-row v-bind="prop.row">
        <!-- icon -->
        <v-col v-bind="prop.col" cols="1">
          <v-icon class="blue--text">
            {{ getTipo_item(el.idTipo).options.icon }}
          </v-icon>
        </v-col>
        <!-- parceiro -->
        <v-col v-bind="prop.col" cols="8">
          <v-chip
            v-bind="prop.chip"
            class="primary primary--text text-overline"
          >
            {{ el.parceiro }}
          </v-chip>
        </v-col>
        <!-- data -->
        <v-col v-bind="prop.col" cols="3">
          <span class="text-caption">
            {{ dataFormataRelativa(el.data, null) }}
          </span>
        </v-col>
      </v-row>

      <!-- titulo -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <span class="font-weight-medium text-body-1">
            {{ el.titulo[getVar("locale")] }}
          </span>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <v-carousel
            v-if="el.imagens && el.imagens.length > 1"
            :cycle="carouselCycle"
            hide-delimiters
            :interval="getConst('carousel').interval"
            progress
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(imagem, i) in el.imagens"
              max-width="400"
              :key="i"
              :src="imagem.endereco"
            ></v-carousel-item>
          </v-carousel>

          <v-img v-else max-width="400" :src="el.imagens[0].endereco" />
        </v-col>
      </v-row>

      <!-- conteudo -->
      <v-row
        v-bind="prop.row"
        v-if="el.idTipo != getTipo_item('publicacaoProduto').id"
      >
        <v-col v-bind="prop.col">
          <div v-html="el.conteudo[getVar('locale')]" class="ql-editor"></div>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row" v-if="getTipo_item(el.idTipo).nome == 'produto'">
        <!-- favorito -->
        <v-col v-bind="prop.col" :cols="el.isEntregar != el.isRetirar ? 2 : 6">
          <c-btn icon @click.stop="clickFavoritar(el)">
            <v-icon :class="el.favorito ? 'yellow--text' : 'grey--text'">
              {{ el.favorito ? "mdi-star" : "mdi-star-outline" }}
            </v-icon>
          </c-btn>
        </v-col>
        <!-- entregar retirar -->
        <v-col v-bind="prop.col" cols="4">
          <v-chip
            v-bind="prop.chip"
            v-if="el.isEntregar != el.isRetirar"
            :class="`${
              el.isEntregar ? 'corEntregar' : 'corRetirar'
            } white--text text-overline`"
          >
            {{ el.isEntregar ? "entregar" : "retirar" }}
          </v-chip>
        </v-col>
        <!-- preco -->
        <v-col v-bind="prop.col" cols="6">
          <v-chip
            v-bind="prop.chip"
            v-show="
              el.idUnidadeMedida &&
              el.idUnidadeMedida != getTipo_item('unidadeUnidade').id
            "
            class="grey darken-1 white--text text-overline mr-1"
            x-small
          >
            {{ getTipo_item(el.idUnidadeMedida).nomeAlt }}
          </v-chip>
          <c-btn
            align-self-baseline
            :class="getPrecoColor(el)"
            :loading="el.loading"
            rounded
            small
            @click.stop="clickComprar(el)"
          >
            {{ numberFormat(el.preco, "currency", el.decimalValor) }}
          </c-btn>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row">
        <!-- curtidas -->
        <v-col v-bind="prop.col" align="center" cols="3">
          <c-btn icon :ripple="false" @click.stop="clickCurtir(el)">
            <v-icon
              :class="`${el.isCurtiu ? 'red--text' : 'grey--text'} ${
                el.curtidas ? 'mt-5' : 'mt-n2'
              }`"
            >
              {{ el.isCurtiu ? "mdi-heart" : "mdi-heart-outline" }}
            </v-icon>
          </c-btn>
          <div v-if="getLocalidades(getTipo_item('localidadePais').id).length">
            <span class="text-overline font-weight-light">
              {{ el.curtidas ? numberFormat(el.curtidas) : "" }}
            </span>
          </div>
        </v-col>
        <!-- comentadas -->
        <v-col v-bind="prop.col" align="center" cols="3">
          <c-btn icon @click.stop="clickComentar(el)">
            <v-icon
              :class="`${el.comentou ? 'blue--text' : 'grey--text'} ${
                el.comentadas ? 'mt-5' : 'mt-n2'
              }`"
            >
              {{ el.comentou ? "mdi-comment" : "mdi-comment-outline" }}
            </v-icon>
          </c-btn>
          <div v-if="getLocalidades(getTipo_item('localidadePais').id).length">
            <span class="text-overline font-weight-light">
              {{ el.comentadas ? numberFormat(el.comentadas) : "" }}
            </span>
          </div>
        </v-col>
        <!-- compartilhadas -->
        <v-col v-bind="prop.col" align="center" cols="3">
          <c-tooltip texto="informações copiadas para área de transferência">
            <template v-slot="{ on }">
              <c-btn icon @click.stop="clickCompartilhar(el, on, $event)">
                <v-icon
                  :class="`${el.compartilhou ? 'blue--text' : 'grey--text'} ${
                    el.compartilhadas ? 'mt-5' : 'mt-n2'
                  }`"
                  >mdi-share</v-icon
                >
              </c-btn>
            </template>
          </c-tooltip>
          <div v-if="getLocalidades(getTipo_item('localidadePais').id).length">
            <span class="text-overline font-weight-light">
              {{ el.compartilhadas ? numberFormat(el.compartilhadas) : "" }}
            </span>
          </div>
        </v-col>
        <!-- visualizadas -->
        <v-col v-bind="prop.col" align="center" cols="3">
          <c-btn icon>
            <v-icon
              :class="`${el.visualizou ? 'blue--text' : 'grey--text'} ${
                el.visualizadas ? 'mt-5' : 'mt-n2'
              }`"
              >mdi-eye-outline</v-icon
            >
          </c-btn>
          <div
            v-if="
              getLocalidades(getTipo_item('localidadePais').id).length &&
              el.visualizadas
            "
          >
            <span class="text-overline font-weight-light ml-n1">
              {{ numberFormat(el.visualizadas) }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- botao plus -->
    <v-row
      v-bind="prop.row"
      v-if="!isTouchDevice && !btnInjectClicked && feed.length >= limit"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn class="mt-3" fab small @click="swipeInject">
          <v-icon>mdi-plus</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <!-- /// FOOTER -->
    <!-- <c-the-footer /> -->

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          componentes - remover
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- /// TESTES -->
          <span class="text-h6 mt-6">v-breadcrumbs</span>
          <v-breadcrumbs divider="•" :items="breadcrumbItems"></v-breadcrumbs>

          <span class="text-h6 mt-6">v-parallax</span>
          <v-parallax
            src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
          ></v-parallax>

          <span class="text-h6 mt-6">v-progress-linear</span>
          <v-row
            v-bind="prop.row"
            align="center"
            class="mt-2 mb-8"
            justify="center"
            no-gutters
          >
            <v-col v-bind="prop.col" cols="1">
              <span class="overline">0%</span>
            </v-col>
            <v-col v-bind="prop.col" cols="11">
              <v-progress-linear color="grey" height="6" rounded value="0" />
            </v-col>
          </v-row>

          <v-row
            v-bind="prop.row"
            align="center"
            class="mt-2 mb-8"
            justify="center"
            no-gutters
          >
            <v-col v-bind="prop.col" cols="1">
              <span class="overline">50%</span>
            </v-col>
            <v-col v-bind="prop.col" cols="11">
              <v-progress-linear color="red" height="6" rounded value="50" />
            </v-col>
          </v-row>

          <v-row
            v-bind="prop.row"
            align="center"
            class="mt-2 mb-8"
            justify="center"
            no-gutters
          >
            <v-col v-bind="prop.col" cols="1">
              <span class="overline">90%</span>
            </v-col>
            <v-col v-bind="prop.col" cols="11">
              <v-progress-linear color="orange" height="6" rounded value="90" />
            </v-col>
          </v-row>

          <v-row
            v-bind="prop.row"
            align="center"
            class="mt-2 mb-8"
            justify="center"
            no-gutters
          >
            <v-col v-bind="prop.col" cols="1">
              <span class="overline">110%</span>
            </v-col>
            <v-col v-bind="prop.col" cols="11">
              <v-progress-linear color="green" height="6" rounded value="110" />
            </v-col>
          </v-row>

          <span class="text-h6 mt-6">v-range-slider</span>
          <v-range-slider></v-range-slider>

          <span class="text-h6 mt-6">v-skeleton-loader</span>
          <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="card-avatar, article, actions"
          ></v-skeleton-loader>

          <span class="text-h6 mt-6">v-slider</span>
          <v-slider :max="75" :min="25"></v-slider>

          <span class="text-h6 mt-6"
            >v-sparkline Allplus 2022/jan faturam /contrib/margem</span
          >
          <v-sparkline
            auto-draw
            :auto-draw-duration="5000"
            :gradient="['green', 'yellow', 'red']"
            :line-width="2"
            :padding="5"
            :smooth="2"
            :value="[
              0, 19857, 40261, 303375, 350050, 515005, 213137, 200499, 415443,
              450858, 396476, 576596, 475349, 557444, 644949, 382109, 492974,
              524190, 479644, 1779175, 547813, 599025,
            ]"
          />
          <v-sparkline
            auto-draw
            :auto-draw-duration="5000"
            :gradient="['green', 'yellow', 'red']"
            :line-width="2"
            :padding="5"
            :smooth="2"
            :value="[
              0, 904, 3123, 22147, 21112, 39255, 14807, 20725, 29290, 30472,
              21108, 20200, 21288, 31993, 25086, 18836, 21470, 27838, 43977,
              86686, 24160, 30033,
            ]"
          />
          <v-sparkline
            auto-draw
            :auto-draw-duration="5000"
            :gradient="['green', 'yellow', 'red']"
            :line-width="2"
            :padding="5"
            :smooth="2"
            :value="[
              0.0, 4.57, 7.78, 7.33, 6.09, 7.68, 6.97, 10.37, 7.09, 6.78, 5.34,
              3.55, 4.5, 5.76, 3.92, 4.95, 4.37, 5.33, 9.21, 4.89, 4.44, 5.03,
            ]"
          />

          <span class="text-h6 mt-6">v-stepper</span>
          <v-stepper alt-labels flat>
            <v-stepper-header>
              <v-stepper-step color="red" editable step="1"
                >Step 1</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step editable step="2">Step 2</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="3">Step 3</v-stepper-step>
            </v-stepper-header>
          </v-stepper>

          <span class="text-h6 mt-6">v-timeline</span>
          <v-timeline>
            <v-timeline-item>item 1</v-timeline-item>
            <v-timeline-item class="text-right">item 2</v-timeline-item>
            <v-timeline-item>item 3</v-timeline-item>
          </v-timeline>

          <span class="text-h6 mt-6">v-treeview</span>
          <v-treeview open-on-click :items="treeviewItems"></v-treeview>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <c-btn
      v-scroll="onScroll"
      v-show="showFab"
      class="mb-11"
      bottom
      fab
      fixed
      right
      @click="toScroll"
    >
      <v-icon large>
        {{ iconScroll }}
      </v-icon>
    </c-btn>

    <c-the-navbar
      v-show="showBottom"
      bottom
      mode="home"
      :slots="buttonsBar.length"
      title=""
      @auth="showAuth = true"
      @getFeed="getFeed()"
      @toggleConfiguracoesRegionais="toggleConfiguracoesRegionais"
      @touchend.native.stop
      @touchmove.native.stop
      @touchstart.native.stop
    >
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-base-enderecos
          v-if="showEnderecos(button)"
          :key="i"
          @aplicar="enderecosAplicar()"
        />
        <span v-else-if="showButtonsBottom(button)" :key="i">
          <v-menu v-if="Array.isArray(button)" :key="i" offset-y top>
            <template #activator="{ on }">
              <c-btn icon v-on="on">
                <v-icon large>mdi-dots-horizontal</v-icon>
              </c-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(option, i) in button"
                :key="i"
                @click="clickOption(option)"
              >
                <v-icon :class="`${option.options.color}--text`">
                  {{ option.options.icon }}
                </v-icon>
                <v-list-item-title class="ml-2">
                  {{ option.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <c-btn v-else icon @click="clickOption(button)">
            <v-icon large color="white">
              {{ button.options.icon }}
            </v-icon>
          </c-btn>
          <v-badge
            v-if="button.nomeAlt == 'carrinho'"
            class="ml-n1 mr-2 mt-7"
            :color="badgeCarrinhoColor"
            :content="getVar('pendencias').carrinhoAtualItens.value"
            left
            :value="getVar('pendencias').carrinhoAtualItens.priority"
          ></v-badge>
          <v-badge
            v-else-if="showNotificacoes(button)"
            class="ml-n1 mr-2 mt-6"
            :color="badgeNotificacaoColor"
            :content="getVar('pendencias').notificacoes.value"
            left
            overlap
            :value="getVar('pendencias').notificacoes.value"
          ></v-badge>
        </span>
      </template>
    </c-the-navbar>
  </v-container>
</template>

<script>
/// IMPORT
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import CBaseCategorias from "@/components/bases/CBaseCategorias";
import CBaseDialogSenha from "@/components/bases/CBaseDialogSenha";
import CBaseEnderecos from "@/components/bases/CBaseEnderecos";
import CHomeDialogAuth from "@/components/pages/home/auth/CHomeDialogAuth";
// import CTheFooter from "@/components/bases/CTheFooter";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "Home",

  /// COMPONENTS
  components: {
    CBaseCategorias,
    CBaseDialogSenha,
    CBaseEnderecos,
    CHomeDialogAuth,
    // CTheFooter,
    CTheNavbar,
  },

  /// MIXINS
  mixins: [
    mixinCarrinhos,
    mixinData,
    mixinSnackbar,
    mixinSwipe,
    mixinUsuarios,
    mixinUtil,
  ],

  /// HEAD
  head: {
    meta: [
      {
        async: true,
        body: true,
        content:
          "30844414959-dvuhh2v6qvi0gpvu4gnurda0d36p5vpi.apps.googleusercontent.com",
        name: "google-signin-client_id",
      },
    ],
    script: [
      {
        async: true,
        body: true,
        src: "https://connect.facebook.net/en_US/sdk.js",
        type: "text/javascript",
      },
      {
        async: true,
        body: true,
        defer: true,
        src: "https://apis.google.com/js/platform.js",
        type: "text/javascript",
      },
    ],
  },

  /// DATA
  data() {
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      ativo: true,
      breadcrumbItems: [
        {
          href: "/",
          text: "home",
        },
        {
          href: "/notificacoes",
          text: "notificação",
        },
      ],
      carouselCycle: true,
      isEntregar: getVar("isEntregar"),
      fab: false,
      feed: [],
      id: this.$route.params.id,
      idTipoBusca: 0,
      idUsuarioSenha: null,
      limit: 20,
      loadImages: false,
      objetoCodigo: {},
      showAuth: false,
      showConfiguracoesRegionais: false,
      showSenha: false,
      treeviewItems: [
        {
          name: "Pasta 100",
          children: [
            {
              name: "Pasta 110",
              children: [
                {
                  name: "Pasta 111",
                  children: [],
                },
                {
                  name: "Pasta 112",
                  children: [],
                },
                {
                  name: "Pasta 113",
                  children: [],
                },
              ],
            },
            {
              name: "Pasta 120",
              children: [
                {
                  name: "Pasta 121",
                  children: [],
                },
                {
                  name: "Pasta 122",
                  children: [],
                },
                {
                  name: "Pasta 123",
                  children: [],
                },
              ],
            },
            {
              name: "Pasta 130",
              children: [
                {
                  name: "Pasta 131",
                  children: [],
                },
                {
                  name: "Pasta 132",
                  children: [],
                },
                {
                  name: "Pasta 133",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          name: "Pasta 200",
          children: [
            {
              name: "Pasta 210",
              children: [
                {
                  name: "Pasta 211",
                  children: [],
                },
                {
                  name: "Pasta 212",
                  children: [],
                },
                {
                  name: "Pasta 213",
                  children: [],
                },
              ],
            },
            {
              name: "Pasta 220",
              children: [
                {
                  name: "Pasta 221",
                  children: [],
                },
                {
                  name: "Pasta 222",
                  children: [],
                },
                {
                  name: "Pasta 223",
                  children: [],
                },
              ],
            },
            {
              name: "Pasta 230",
              children: [
                {
                  name: "Pasta 231",
                  children: [],
                },
                {
                  name: "Pasta 232",
                  children: [],
                },
                {
                  name: "Pasta 233",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          name: "Pasta 300",
          children: [
            {
              name: "Pasta 310",
              children: [
                {
                  name: "Pasta 311",
                  children: [],
                },
                {
                  name: "Pasta 312",
                  children: [],
                },
                {
                  name: "Pasta 313",
                  children: [],
                },
              ],
            },
            {
              name: "Pasta 320",
              children: [
                {
                  name: "Pasta 321",
                  children: [],
                },
                {
                  name: "Pasta 322",
                  children: [],
                },
                {
                  name: "Pasta 323",
                  children: [],
                },
              ],
            },
            {
              name: "Pasta 330",
              children: [
                {
                  name: "Pasta 331",
                  children: [],
                },
                {
                  name: "Pasta 332",
                  children: [],
                },
                {
                  name: "Pasta 333",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getLocalidades: "localidades/getLocalidades",
      getPagina: "paginas/getPagina",
      getPagina_documentos: "paginas/getPagina_documentos",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    backendBaseUrl() {
      return this.getConst("app").baseUrlBackend;
    },

    badgeCarrinhoColor() {
      return this.getVar("pendencias").carrinhoAtualItens.priority <= 1
        ? "red"
        : "blue";
    },

    badgeNotificacaoColor() {
      return this.getVar("pendencias").notificacoes.priority <= 1
        ? "red"
        : "blue";
    },

    badgePendenciasColor() {
      return this.getVar("pendencias").notificacoes.priority <= 1
        ? "red"
        : "blue";
    },

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true
      ).sortKeys('"order"');
    },

    buttonsBar() {
      return [
        ...this.buttonsBottom.map((button) => {
          return {
            ...button,
            clickFunction: this.clickOption,
          };
        }),
      ];
    },

    buttonsBottom() {
      return this.buttons.filter(
        (button) =>
          !["optionPageNotificacoes", "optionEntrar"].includes(button.nome)
      );
    },

    buttonsTop() {
      return this.buttons.filter((button) =>
        ["optionPageNotificacoes", "optionEntrar"].includes(button.nome)
      );
    },

    categoriasSelected() {
      return this.getVar("categoriasSelected");
    },

    env() {
      return process.env.VUE_APP_BACKEND_BASEURL;
    },

    idFilter() {
      return this.getFeature("filterEstadosAtivos").id;
    },

    idOrder() {
      return this.getFeature("orderOrdenar").id;
    },

    idPage() {
      return this.getPagina("home").id;
    },

    imgInstale() {
      const device = this.getUserDevice();
      return this.getConst("instale").src.find((img) =>
        img.toLowerCase().includes(device.os.name.toLowerCase())
      );
    },

    logado() {
      return !!this.getVar("idPessoaParceiro");
    },

    routePath() {
      return this.$route.path;
    },

    showLabelComprar() {
      return (
        this.feed.length &&
        (!this.getVar("usuarios").length ||
          (this.getVar("usuarios").length &&
            this.getVar("usuarios")[0].pedidos <=
              this.getConst("config").busca.labelComprar))
      );
    },

    storeIdPessoaEndereco() {
      return this.getVar("idPessoaEndereco");
    },

    title() {
      let title = this.getConst("app").nome;
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      if (idUsuario != idPessoaParceiro) {
        const usuario = this.getVar("usuarios").find(
          (usuario) => usuario.pessoa.id == idPessoaParceiro
        );

        title = usuario?.pessoa.nomeAlt || title;
      }

      return title.toUpperCase();
    },
  },

  /// WATCH
  watch: {
    categoriasSelected() {
      this.getFeed();
    },

    entregar(valor) {
      if (valor == null) this.getFeed();
    },

    routePath() {
      if (this.$route.path == "/oops") {
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );

        this.$router.push("/");
      } else if (this.$route.path == "/cadastroIncompleto") {
        this.setSnackbar(
          true,
          "CADASTRO",
          "Cadastro incompleto. Deseja conluir cadastro?",
          "warning",
          "mdi-check",
          "cadastro"
        );

        this.$router.push("/");
      }
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    authClose(action) {
      if (action) {
        this.showAuth = null;
        this.$router.push(action);
        return;
      } else {
        this.showAuth = false;
      }

      if (this.logado) {
        this.setVar({ showEnderecos: true });
        this.getFeed();
      }
    },

    cardAction(el) {
      if (!el.visualizou) this.visualizar(el);

      if (el.idTipo == this.getTipo_item("publicacaoNews").id) {
        this.$router.push(`/publicacao/${el.id}`);
      } else if (el.idTipo == this.getTipo_item("publicacaoProduto").id) {
        this.$router.push(`/produto/${el.idPublicado}`);
      } else if (el.idTipo == this.getTipo_item("publicacaoParceiro").id) {
        this.$router.push({
          path: `/busca/${this.getConst("config").tipoBusca.produtos.id}`,
          query: {
            idPessoaParceiro: el.idPessoaParceiro,
            parceiro: el.parceiro,
            parceiroCorPrimaria: el.corPrimaria,
          },
        });
      } else if (el.idTipo == this.getTipo_item("publicacaoMarca").id) {
        this.$router.push({
          path: `/busca/${this.getConst("config").tipoBusca.produtos.id}`,
          query: {
            marca: el.marca,
            idPessoaParceiro: el.idPessoaParceiro,
            parceiro: el.parceiro,
            parceiroCorPrimaria: el.corPrimaria,
          },
        });
      }
    },

    clickComentar(el) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      const url = `comentarios/${el.id}/${null}`;
      this.$router.push({
        path: url,
        query: { publicacao: el },
      });
    },

    clickCompartilhar(el, on, event) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      let url = window.location.origin;

      if (el.idTipo == this.getTipo_item("publicacaoNews").id) {
        url += `/publicacao/${el.id}`;
      } else if (el.idTipo == this.getTipo_item("publicacaoProduto").id) {
        url += `/produto/${el.idPublicado}`;
      } else if (el.idTipo == this.getTipo_item("publicacaoParceiro").id) {
        url += `/busca/${
          this.getConst("config").tipoBusca.produtos.id
        }?idPessoaParceiro=${el.idPessoaParceiro}&parceiro=${el.parceiro}`;
      } else if (el.idTipo == this.getTipo_item("publicacaoMarca").id) {
        url += `/busca/${this.getConst("config").tipoBusca.produtos.id}?marca=${
          el.marca
        }`;
      }

      this.copyToClipboard(url);

      if (navigator.share) {
        navigator
          .share({
            title: `${el.titulo} #${el.id}`,
            text: `${el.titulo} #${el.id}`,
            url,
          })
          .then(() => {
            const publicacao = {
              id: el.id,
              idUsuario: this.getVar("idUsuario"),
            };

            const url = `${
              this.getConst("app").baseUrlBackend
            }/publicacoes/compartilhar`;

            axios
              .put(url, publicacao)
              .then((res) => {
                el.compartilhadas = res.data.compartilhadas;
                el.compartilhou = res.data.compartilhou;
              })
              .catch((err) => {
                this.setSnackbar(true, "ERRO", err.response.data, "error");
              });
          });
      } else {
        this.clickOnTooltip(on, event);
      }
    },

    clickCurtir(el) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      el.isCurtiu = !el.isCurtiu;

      const publicacao = {
        id: el.id,
        idComentario: null,
        idUsuario: this.getVar("idUsuario"),
        isCurtiu: el.isCurtiu,
      };

      const url = `${this.getConst("app").baseUrlBackend}/publicacoes/curtir`;

      axios
        .put(url, publicacao)
        .then((res) => {
          el.curtidas = res.data.curtidas;
          el.isCurtiu = res.data.isCurtiu;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },

    clickFavoritar(el) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      el.favorito = !el.favorito;

      const produto = {
        id: el.idPublicado,
        idUsuario: this.getVar("idUsuario"),
        idPessoaParceiro: this.getVar("idPessoaParceiro"),
        favorito: el.favorito,
      };

      const url = `${this.getConst("app").baseUrlBackend}/produtos/favoritar`;

      axios
        .put(url, produto)
        .then((res) => {
          el.favorito = res.data.favorito;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },

    clickOption(option) {
      if (option.nomeAlt == "entrar") {
        this.showAuth = true;
      } else if (option.nomeAlt == "carrinho") {
        this.clickCarrinho();
      } else {
        this.$router.push(option.options.route);
      }
    },

    clickSnackbar(action) {
      if (action == "carrinhosRoute") {
        this.$router.push("/carrinhos");
      } else if (action == "endereco") {
        this.$router.push(`/pessoa/${this.getVar("idUsuario")}?edit=1`);
      } else if (action == "login") {
        this.showAuth = true;
        this.setSnackbar();
      } else if (action == "openEnderecos") {
        this.setVar({ showEnderecos: true });
        this.setSnackbar();
      } else if (action == "itemIncluir") {
        this.snackbar.params = true;
        this.snackbar.show = false;
      } else if (action == "clickAjuda") {
        const url = "/ajuda#menuEnderecos";
        this.$router.push(encodeURI(url));
        this.setSnackbar();
      } else if (action == "carrinho") {
        this.clickCarrinho();
        this.setSnackbar();
      } else if (action == "cadastro") {
        const url = `/pessoa/${this.getVar("idUsuario")}`;

        this.$router.push({
          path: encodeURI(url),
          query: {
            edit: true,
          },
        });
        this.setSnackbar();
      }
    },

    enderecosAplicar() {
      this.isEntregar = this.getVar("isEntregar");
      this.getFeed();
    },

    showDialogSenha(objeto) {
      this.idUsuarioSenha = objeto.idUsuario;
      this.objetoCodigo = objeto;
      this.showAuth = false;
      this.showSenha = true;
    },

    async getFeed(openEnderecos = false, offset = 0) {
      if (this.getVar("overlay")) return;

      this.setVar({ overlay: true });
      const search = "*";
      const idContexto = "*";
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;
      const url = `${
        this.getConst("app").baseUrlBackend
      }/busca/${search}/${idContexto}/${this.idFilter}/${
        this.idOrder
      }/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${this.limit}`;

      let coordenada;

      try {
        coordenada = await this.getPosition();
      } catch (err) {
        coordenada = {
          latitude: 0,
          longitude: 0,
        };

        this.setSnackbar(
          true,
          "LOCALIZAÇÃO",
          err,
          "warning",
          "mdi-check",
          "ajuda"
        );
      }

      const params = {
        idTipoBusca: this.idTipoBusca,
        categoriasSelected: this.categoriasSelected,
        isEntregar: this.isEntregar,
        idPessoaEndereco: this.getVar("idPessoaEndereco"),
        coordenada,
      };

      axios
        .get(url, { params })
        .then((res) => {
          const result = res.data;
          if (offset == 0) {
            this.feed = [];
          }

          result.forEach((element) => {
            this.feed.push(element);
          });
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
            this.btnInjectClicked = false;
          }, this.getConst("config").overlay.timeout);

          if (openEnderecos) {
            setTimeout(
              () => this.setVar({ showEnderecos: false }),
              this.getConst("config").snackbar.timeout / 2
            );
          }
        });
    },

    loadImage(el, index) {
      if (index + 1 == el.length) {
        this.loadImages = true;
      }
    },

    pendenciasAtualizar() {
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      if (!!idUsuario && !!idPessoaParceiro) {
        const url = `${
          this.getConst("app").baseUrlBackend
        }/home/pendencias/${idPessoaParceiro}/${idUsuario}`;

        axios.get(url).then((res) => {
          const result = res.data;
          this.setVar({ pendencias: result.pendencias });
        });
      }
    },

    senhaAlterada() {
      this.showSenha = false;
      this.setSnackbar(true, "SENHA", "Senha alterada.");

      if (this.getVar("isSenhaProvisoria")) {
        this.setVar({ isSenhaProvisoria: false });
        this.showAuth = true;
      }
    },

    showButtonsBottom(button) {
      return (
        button.nome != "notificacoes" ||
        this.getVar("pendencias").notificacoes.priority != 0
      );
    },

    showNotificacoes(button) {
      return (
        button.nome == "notificacoes" &&
        this.getVar("pendencias").notificacoes.priority != 0
      );
    },

    showEnderecos(button) {
      return button.nome == "optionEnderecos" && this.ativo;
    },

    swipeInject() {
      this.btnInjectClicked = true;
      this.getFeed(null, this.feed.length);
    },

    swipeRefresh() {
      this.getFeed();
    },

    toggleConfiguracoesRegionais() {
      this.showConfiguracoesRegionais = !this.showConfiguracoesRegionais;
    },

    visualizar(el) {
      if (!this.logado) {
        this.drawer = false;
        return;
      }

      el.visualizou = !el.visualizou;

      const publicacao = {
        id: el.id,
        idUsuario: this.getVar("idUsuario"),
      };

      const url = `${
        this.getConst("app").baseUrlBackend
      }/publicacoes/visualizar`;

      axios
        .post(url, publicacao)
        .then((res) => {
          el.visualizadas = res.data.visualizadas;
          el.visualizou = res.data.visualizou;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },
  },

  /// CREATED
  created() {
    this.getUserIP((ip) => {
      this.setVar({ ipInterno: ip });
    });
  },

  /// MOUNTED
  async mounted() {
    this.setVar({ logout: false });

    if (this.$route?.query?.reloadX !== undefined) {
      this.getVar("socket").emit("reloadX", this.$route.query.reloadX);
      this.$router.push("/");
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }

    if (this.$route.name == "Error") {
      this.setSnackbar(true, "ERRO", "Endereço não encontrado.", "error");
    }

    setTimeout(async () => {
      this.setVar({ showEnderecos: true });
      await this.getFeed(true);
    }, 10);

    if (this.$route.params.idConvite) {
      this.setVar({
        idTipoLogin: this.getConst("config").tipoLogin.cadastrar.id,
      });
    }
  },

  /// ACTIVATED
  async activated() {
    this.setVar({ logout: false });
    this.setVar({ goBack: -1 });
    this.$vuetify.goTo(0);

    if (this.getVar("reload")) {
      this.setVar({ reload: false });

      this.setVar({ overlay: true });

      const userData = JSON.parse(
        await localStorage.getItem(this.getConst("app").userKey)
      );

      const id = userData.metadados.idLogin;
      const isLogoutUsuario = false;
      const url = `${
        this.getConst("app").baseUrlBackend
      }/signout/${id}/${isLogoutUsuario}`;

      await axios
        .put(url)
        .then()
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });

      window.location.reload();
    }

    this.ativo = true;
    this.setVar({ routeIgnore: [] });
    this.pendenciasAtualizar();

    if (
      (!this.getVar("usuarios").length && this.getVar("usuariosTemp").length) ||
      this.$route.query.showAuth ||
      this.showAuth == null
    ) {
      this.showAuth = true;
    }

    if (!this.logado) {
      setTimeout(this.getFeed, 1000);
    }
  },

  /// DEACTIVATED
  deactivated() {
    this.ativo = false;
  },
};
</script>

<style scoped>
.loadingImage {
  visibility: hidden;
  width: 0;
  height: 0;
}
</style>
