<!-- COD: LYF 01/07/2022 CDicionarios -->
<!-- *VER: JER 01/07/2022 CDicionarios -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- texto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.texto }}
        </span>
      </v-col>
    </v-row>

    <!-- locale -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-chip v-bind="prop.chip" class="ml-n1">
          {{ localeById(dataset.idLocale) }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CDicionarios",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>