<!-- COD: BJB 09/08/2022 CBaseListSearch -->
<!-- *VER: JER 10/08/2022 CBaseListSearch -->
<!-- /22/ -->

<template>
  <!-- /// SEARCH -->
  <v-container class="mt-6 pa-0">
    <v-row v-bind="prop.row" v-if="hasSearch" class="mt-n2">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="search"
          :append-outer-icon="showBuscar ? 'mdi-tune' : null"
          clearable
          :max="100"
          :noAccept="null"
          placeholder="busca"
          :prepend-icon="iconLayout"
          prepend-inner-icon="mdi-magnify"
          solo
          type="search"
          @click-append="showSearchOptions = !showSearchOptions"
          @click-prepend="clickLayout"
          @input="inputSearch()"
          @keyup.enter="
            $emit('search', search);
            $event.target.blur();
          "
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" v-show="!showSearchOptions" class="mt-n3">
      <v-col v-bind="prop.col">
        <v-icon :class="iconFavoritoClass" @click="clickFavoritos">
          {{ iconFavorito }}
        </v-icon>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n6">
      <v-col v-bind="prop.col">
        <v-select
          v-if="filters.length"
          v-model="idFilter"
          :items="filters"
          :prepend-inner-icon="
            filter && filter.options ? filter.options.icon : null
          "
          solo
          @change="$emit('filter', idFilter)"
        >
          <template v-if="iconFavorito" v-slot:prepend>
            <v-icon :class="iconFavoritoClass" @click="clickFavoritos">{{
              iconFavorito
            }}</v-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n6">
      <v-col v-bind="prop.col">
        <c-select
          v-if="orders.length"
          v-model="idOrder"
          :items="orders"
          :prepend-inner-icon="
            this.ordem
              ? this.ordem == 'desc'
                ? 'mdi-arrow-down'
                : 'mdi-arrow-up'
              : order && order.options
              ? order.options.icon
              : null
          "
          solo
          @change="$emit('order', idOrder)"
          @clickPrependInner="changeOrderList()"
        />
      </v-col>
    </v-row>

    <v-row
      v-bind="prop.row"
      v-if="proprietarios"
      v-show="showSearchOptions"
      :class="idFilter ? 'mt-n6' : 'mt-n4'"
    >
      <v-col v-bind="prop.col">
        <c-select
          v-model="idsProprietarios"
          :items="proprietarios"
          label="proprietarios"
          multiple
          prepend-inner-icon="mdi-account-multiple-outline"
          :properties="{
            todos: true,
          }"
          solo
          @change="$emit('proprietarios', idsProprietarios)"
        />
      </v-col>
    </v-row>

    <v-row
      v-bind="prop.row"
      v-if="hasData"
      v-show="showSearchOptions"
      :class="orders.length || idFilter || proprietarios ? 'mt-n6' : ''"
    >
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="searchData.inicio"
          classe="text-body-1 pr-1"
          clearable
          date
          icon
          label="início"
          :maxValue="searchData.fim"
          solo
          @change="$emit('searchData', searchData)"
          @input="$emit('searchData', searchData)"
        />
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="searchData.fim"
          classe="text-body-1 pl-1"
          clearable
          date
          icon
          label="fim"
          :minValue="searchData.inicio"
          solo
          @change="$emit('searchData', searchData)"
          @input="$emit('searchData', searchData)"
        />
      </v-col>
    </v-row>

    <slot name="searchCustomOptions"></slot>

    <v-row
      v-bind="prop.row"
      v-show="showSearchOptions"
      :class="
        orders.length || idFilter || proprietarios || hasData
          ? 'mt-n6'
          : 'mt-n6'
      "
    >
      <v-col v-bind="prop.col" cols="6">
        <c-select
          v-if="getVar('idUsuario') <= 20 && hasPaginacao"
          v-model="limit"
          class="pr-1"
          :items="itensPorPagina"
          label="paginação"
          prepend-inner-icon="mdi-format-align-justify"
          solo
          @change="$emit('paginacao', limit)"
        />
      </v-col>
      <v-col v-bind="prop.col" align="end" cols="6">
        <!-- <div :class="getVar('idUsuario') <= 32 && hasPaginacao ? 'pl-1' : ''"> -->
        <c-btn
          v-if="showBuscar"
          block
          :classe="`primary white--text mr-8 ${
            getVar('idUsuario') <= 5 && hasPaginacao ? 'mt-n6' : 'mt-n2 mb-4'
          }`"
          large
          @click="$emit('buscar')"
        >
          buscar
        </c-btn>
        <!-- </div> -->
      </v-col>
    </v-row>
  </v-container>
</template>
0       
<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CBaseListSearch",

  /// PROPS
  props: {
    filters: { type: Array, required: true },
    hasLayout: { type: Boolean, default: false },
    hasData: { type: Boolean, default: false },
    hasPaginacao: { type: Boolean, default: false },
    hasSearch: { type: Boolean, default: false },
    orders: { type: Array, required: true },
    proprietarios: { type: Array },
  },

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      // idOrder: getFeature("orderOrdenar").id,
      idFilter: this.filters.length
        ? (
            this.filters.find((filter) => filter.options?.isDefault) ||
            this.filters[0]
          ).idFeature
        : null,
      idOrder: this.orders.length
        ? (
            this.orders.find((order) => order.options?.isDefault) ||
            this.orders[0]
          ).idFeature
        : getFeature("orderOrdenar").id,
      idsProprietarios: null,
      isBuscaLayout: true,
      limit: getVar("limit").selected,
      ordem: "asc",
      search: "",
      searchData: {
        inicio: "",
        fim: "",
      },
      showSearchOptions: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
    }),

    filter() {
      return this.filters.find((filter) => filter.id == this.idFilter);
    },

    hasFilterFavoritos() {
      return this.filters.some(
        (filter) => filter.id == this.getFeature("filterFavoritos").id
      );
    },

    iconFavorito() {
      return this.hasFilterFavoritos
        ? this.idFilter == this.getFeature("filterFavoritos").id
          ? "star"
          : "star_outline"
        : "";
    },

    iconFavoritoClass() {
      return this.idFilter == this.getFeature("filterFavoritos").id
        ? "text--darken-1 yellow--text"
        : "grey--text";
    },

    iconLayout() {
      return this.hasLayout
        ? this.isBuscaLayout
          ? "mdi-view-list"
          : "mdi-view-sequential"
        : "";
    },

    itensPorPagina() {
      return this.getVar("limit")
        .itens.filter(
          //? restrição provisoria para facilitar o desenvolvimento
          (item) => item.value <= (this.getVar("idUsuario") <= 32 ? 100 : 50)
        )
        .map((item) => {
          item.text = `${item.value}/${this.translate("página")}`;
          return item;
        });
    },

    order() {
      return this.orders.find((order) => order.id == this.idOrder);
    },

    showBuscar() {
      return (
        (this.getVar("idUsuario") <= 32 && this.hasPaginacao) ||
        this.idFilter ||
        this.proprietarios ||
        this.hasData ||
        !this.hasPaginacao
      );
    },
  },

  /// WATCH
  watch: {
    showSearchOptions() {
      this.$emit("showSearchOptions", this.showSearchOptions);
    },
  },

  /// METHODS
  methods: {
    changeOrderList() {
      this.ordem = this.ordem == "asc" ? "desc" : "asc";
      this.$emit("ordem", this.ordem);
    },

    clickFavoritos() {
      this.idFilter =
        this.idFilter != this.getFeature("filterFavoritos").id
          ? this.getFeature("filterFavoritos").id
          : this.filters[0].id;

      this.$emit("filter", this.idFilter);
    },

    clickLayout() {
      this.isBuscaLayout = !this.isBuscaLayout;

      this.$emit("buscaLayout", this.isBuscaLayout);
    },

    inputSearch() {
      if (!this.search) {
        this.$emit("search", this.search);
      }
    },
  },

  /// ACTIVATED
  activated() {
    if (this.$route.params.idFilter) {
      this.idFilter = parseInt(this.$route.params.idFilter);
      this.$emit("filter", this.idFilter);
    }
  },
};
</script>