<!-- COD: LYF 30/06/2022 CBancoEdit -->
<!-- *VER: JER 04/07/2022 CBancoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais bandeira -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-menu
          v-model="dataset.main.detail.idPais"
          classe="ml-n4"
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          :items="paises"
        >
          <template #btn="{ itemSelected }">
            <v-img
              v-if="dataset.main.detail.idPais"
              alt="imagem"
              class="mr-2"
              contain
              :src="getLocalidade(dataset.main.detail.idPais).endereco"
              width="25"
            />
            <v-icon v-else large color="grey">mdi-earth</v-icon>
            <span v-if="itemSelected" class="text=body-2">
              {{ itemSelected.text }}
            </span>
          </template>
          <template #item="{ item }">
            <v-img
              alt="imagem"
              contain
              :src="getLocalidade(item.value).endereco"
              width="25"
            />
            <v-list-item-title class="ml-2 text-body-2">
              {{ item.text }}
            </v-list-item-title>
          </template>
        </c-menu>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nomeAlt"
          :max="50"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.data"
          date
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          label="data"
          required
        />
      </v-col>
      <!-- dataPublicacao -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataPublicacao"
          date
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          label="data publicação"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataInicio -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataInicio"
          date
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          label="data ínicio"
          required
        />
      </v-col>
      <!-- dataFim -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataFim"
          clearable
          date
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          label="data fim"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CBancoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidades: "localidades/getLocalidades",
      getTipo_item: "tipos/getTipo_item",
    }),

    paises() {
      return this.paisesAtivos;
    },
  },
};
</script>