<!-- COD: FDS 01/07/2022 CHistoricosPadrao pagamentos -->
<!-- *VER: JER 03/07/2022 CHistoricosPadrao -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- grupo -->
    <v-row v-bind="prop.row" v-if="grupoAux">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ grupoAux }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.nomeAlt">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.nomeAlt }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CHistoricosPadrao",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
    }),

    grupoAux() {
      return this.context.idOrder != this.getFeature("orderGrupo").id
        ? this.dataset.grupo
        : null;
    },
  },
};
</script>