// COD: FDS 10/08/2022 mixinSnackbar
// *VER: JER 19/08/2022 mixinSnackbar
// /11/

import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "mixinSnackbar",

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];

    return {
      snackbar: {
        action: null,
        color: "success",
        icone: null,
        params: null,
        show: false,
        text: "",
        timeout: getConst("config").snackbar.timeout,
        title: "",
      }
    }
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst"
    }),
  },

  /// METHODS
  methods: {
    async setSnackbar(
      show = false,
      title = "",
      text = "",
      color = "success",
      icone = null,
      action = null,
      timeout = this.getConst("config").snackbar.timeout,
      params = null
    ) {
      if (this.snackbar.show && show) {
        await this.setSnackbar();
      }

      if (timeout) {
        let numero = Math.ceil(text.length / this.getConst("config").snackbar.tamanho);
        numero *= action ? 2 : 1;
        timeout = timeout * numero;
      }

      this.snackbar = { action, color, icone, params, show, text, timeout, title };
      await this.waitSnackbar()
    },

    waitSnackbar() {
      if (!this.snackbar.timeout && this.snackbar.action) {
        return new Promise(resolve => {
          let interval = setInterval(() => {

            if (!this.snackbar.show) {
              clearInterval(interval);
              interval = null;
              resolve();
            }
          }, 100);
        });
      }
    }
  }
}