<!-- COD: FDS 09/01/2023 Impostos -->
<!-- #VER: JER __/01/2023 Impostos -->
<!-- /11/ -->
<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-impostos :dataset="dataset" :order="order" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CImpostos from "@/components/pages/imposto/CImpostos";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Impostos",

  /// COMPONENTS
  components: {
    CImpostos,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("impostos").id,
      idPageAlt: getPagina("imposto").id,

      funcs: {
        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },
    };
  },
};
</script>