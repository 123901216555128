// COD: BJB 15/08/2022 tipos
// *VER: JER 19/08/2022 tipos
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    tipos: [],
    tipos_itens: []
  },

  /// GETTERS
  getters: {
    getTipos: state => (prop = "id") => {
      return state.tipos
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nome
          };
        });
    },

    getTipo: state => search => {
      return state.tipos.length
        ? state.tipos
          .find(element => element.id == search || element.nome == search)
        : { search }
    },

    getTipo_itens: state => (id, options = null, prop = "id") => {
      return state.tipos_itens
        .filter(element => element.idPai == id)
        .filter(element => !options || !element.options ||
          !Object.keys(options).some(key => element.options[key] != options[key])
        )
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nomeAlt,
            isDefault: element.isDefault,
            isAplicar: element.isAplicar,
            options: element.options
          };
        });
    },

    getTipo_item: state => search => {
      return state.tipos_itens.length
        ? state.tipos_itens
          .find(element => element.id == search || element.nome == search)
        : { search }
    }
  },

  /// MUTATIONS
  mutations: {
    setTipos: (state, payload) => {
      state.tipos = []
      payload.forEach(element => {
        state.tipos.push(element)
      });
    },

    setTipos_itens: (state, payload) => {
      state.tipos_itens = []
      payload.forEach(element => {
        element.options = element.options || {}
        state.tipos_itens.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setTipos: (context, payload) => {
      context.commit("setTipos", payload)
    },

    setTipos_itens: (context, payload) => {
      context.commit("setTipos_itens", payload)
    }
  }
}