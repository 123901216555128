<!-- COD: FDS 04/07/2022 CParceiroView -->
<!-- *VER: JER 18/07/2022 CParceiroView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- inadimplente -->
    <v-row
      v-bind="prop.row"
      v-if="isAppOrParceiro && dataset.main.detail.isInadimplente"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="red white--text" rounded @click="clickTransacao()">
          INADIMPLENTE
        </c-btn>
      </v-col>
    </v-row>

    <!-- inventario -->
    <v-row
      v-bind="prop.row"
      v-if="isAppOrParceiro && dataset.main.detail.isInventario"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip class="blue white--text">EM INVENTÁRIO</v-chip>
      </v-col>
    </v-row>

    <!-- fechado -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.isFechado">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="red white--text">FECHADO NO MOMENTO</v-chip>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoa_nome">
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="primary--text" text @click="clickPessoa()">
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- pessoa nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoa_nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoa_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- capacidade -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.capacidade">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">capacidade</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.capacidade }}
        </span>
        <span class="text-body-2"> pessoas</span>
      </v-col>
    </v-row>

    <!-- reserva duracao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.reservaDuracao">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">duração reserva</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.reservaDuracao, "decimal") }}
        </span>
        <span class="text-body-2">h</span>
      </v-col>
    </v-row>

    <!-- idFaturamentoMensal -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idFaturamentoMensal">
      <v-col v-bind="prop.col" align="end" cols="6">
        <c-help
          :help="{
            title: 'FATURAMENTO MENSAL',
            text: `Faixa de faturamento mensal que o parceiro realiza.`,
            function: context.setUiBottomSheet,
          }"
        >
          <span class="text-body-2">faturamento mensal</span>
        </c-help>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1 ml-1">
          {{
            `${moedaSimbolo} ${
              getTipo_item(dataset.main.detail.idFaturamentoMensal).nomeAlt
            }`
          }}
        </span>
      </v-col>
    </v-row>

    <!-- percentual online -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.percentualOnline">
      <v-col v-bind="prop.col" align="end" cols="6">
        <c-help
          :help="{
            title: 'PERCENTUAL ONLINE',
            text: `Percentual cobrado do parceiro sobre o valor do pedido, referente ao pagamento online.`,
            function: context.setUiBottomSheet,
          }"
        >
          <span class="text-body-2">percentual online</span>
        </c-help>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1 ml-1">
          {{
            numberFormat(dataset.main.detail.percentualOnline / 100, "percent")
          }}
        </span>
      </v-col>
    </v-row>

    <!-- limite conciliacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.limiteConciliacao">
      <v-col v-bind="prop.col" align="end" cols="6">
        <c-help
          :help="{
            title: 'LIMITE CONCILIAÇÃO',
            text: `Se o extrato conciliado mais recente exceder este prazo, sistema não permite a conciliação de lançamento do extrato intraday com valores pag/rec, forçando, com isso, que os extratos passados sejam conciliados.`,
            function: context.setUiBottomSheet,
          }"
        >
          <span class="text-body-2">limite conciliação</span>
        </c-help>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1 ml-1">
          {{ dataset.main.detail.limiteConciliacao }}
          {{ dataset.main.detail.limiteConciliacao > 1 ? "dias" : "dia" }}
        </span>
      </v-col>
    </v-row>

    <!-- dia semana acerto contas -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.diaSemanaAcertoContas">
      <v-col v-bind="prop.col" align="end" cols="6">
        <c-help
          :help="{
            title: 'ACERTO CONTAS',
            text: `Dia da semana que será feito o acerto de contas entre app e parceiro.`,
            function: context.setUiBottomSheet,
          }"
        >
          <span class="text-body-2">acerto contas</span>
        </c-help>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1 ml-1">
          {{ diasSemanaAcertoContas }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CParceiroView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// DATA
  data() {
    return {
      diasSemana: [
        { value: 1, text: "S", nome: "segunda-feira" },
        { value: 2, text: "T", nome: "terça-feira" },
        { value: 3, text: "Q", nome: "quarta-feira" },
        { value: 4, text: "Q", nome: "quinta-feira" },
        { value: 5, text: "S", nome: "sexta-feira" },
      ],
    };
  },

  /// COMPUTED
  computed: {
    diasSemanaAcertoContas() {
      return this.diasSemana.find(
        (diaSemana) =>
          diaSemana.value == this.dataset.main.detail.diaSemanaAcertoContas
      ).nome;
    },

    isAppOrParceiro() {
      return [
        this.context.dataset.main.detail.idPessoa,
        this.getConst(`app`).id,
      ].includes(this.getVar(`idPessoaParceiro`));
    },

    moeda() {
      return this.getMoeda();
    },

    moedaSimbolo() {
      return this.moeda?.options?.simbolo;
    },
  },

  /// METHODS
  methods: {
    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
    },

    clickTransacao() {
      this.$router.push(
        `/transacoes/${this.getFeature("filterPagamentosPendentes").id}`
      );
    },
  },
};
</script>