<!-- COD: LYF 10/08/2022 CAvaliacaoView -->
<!-- *VER: JER 10/08/2022 CAvaliacaoView -->
<!-- /11/ -->

<template>
  <v-container class="pa-0">
    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataFormata(dataset.main.detail.data, "date time", true) }}
        </span>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span
          :class="
            dataset.main.detail.idPessoaParceiro == getVar('idPessoaParceiro')
              ? 'text-h6'
              : 'text-body-1'
          "
        >
          {{ dataset.aux.detail.pessoa_nome }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- itens -->
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-1">
          {{
            `${dataset.main.detail.itens} ${
              dataset.main.detail.itens > 1 ? "itens" : "item"
            }`
          }}
        </span>
      </v-col>
      <!-- valor total -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{
            dataset.main.detail.valorTotal
              ? numberFormat(dataset.main.detail.valorTotal, "currency")
              : ""
          }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinPageList from "@/mixins/mixinPageList";
import mixinUtil from "@/mixins/mixinUtil";
import mixinSwipe from "@/mixins/mixinSwipe";

export default {
  /// NAME
  name: "CAvaliacaoView",

  /// MIXINS
  mixins: [mixinPageList, mixinUtil, mixinSwipe],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>