<!-- COD: FDS 01/07/2022 Colaboradores -->
<!-- *VER: JER 04/07/2022 Colaboradores -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ context, dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-colaboradores :context="context" :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CColaboradores from "@/components/pages/colaborador/CColaboradores";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Colaboradores",

  /// COMPONENTS
  components: {
    CColaboradores,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("colaboradores").id,
      idPageAlt: getPagina("colaborador").id,

      funcs: {
        fnParams: (context) => {
          const contextParams = context.params
            ? JSON.parse(context.params)
            : null;
          this.params.proprietarios = contextParams?.proprietarios ?? true;

          return JSON.stringify(this.params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo)?.nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado)?.nomeAlt;
        },

        clickHorasExtras: () => {
          setTimeout(() => {
            const url = `/colaboradores/horasExtras`;
            this.$router.push(encodeURI(url));
          }, 10);
        },
      },

      params: {},
    };
  },
};
</script>