<!-- COD: FDS 10/08/2022 CPontoEletronicoDialogPontoEletronico -->
<!-- *VER: JER 08/09/2022 CPontoEletronicoDialogPontoEletronico -->
<!-- /32/ -->

<template>
  <v-dialog
    max-width="400"
    persistent
    ref="comprovante"
    scrollable
    :value="true"
  >
    <v-card v-if="!showComprovanteData">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <c-btn icon @click="clickClose()">
            <v-icon>mdi-close</v-icon>
          </c-btn>
          <v-spacer />
          <v-toolbar-title>PONTO ELETRÔNICO</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>

      <v-card-text class="px-0">
        <v-container class="px-0">
          <!-- horaServidor -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="font-weight-light text-h3">
                {{ horaServidor }}
              </span>
            </v-col>
          </v-row>

          <!-- foto -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <div>
                <video
                  v-show="!photo"
                  autoplay
                  class="ml-0 video"
                  id="video"
                  muted
                  playsinline
                  @click="clickFoto"
                />
                <canvas v-show="photo" id="canvas" @click="photo = null" />
              </div>
            </v-col>
          </v-row>

          <!-- toque para foto -->
          <v-row v-bind="prop.row" class="mt-n4">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2 red--text">
                {{
                  photo
                    ? "toque para tirar outra foto"
                    : "toque para tirar foto"
                }}
              </span>
            </v-col>
          </v-row>

          <!-- bater ponto -->
          <v-row v-bind="prop.row" class="mt-2">
            <v-col v-bind="prop.col" align="center">
              <c-btn
                :class="!photo ? 'loadingImage' : ''"
                color="primary"
                block
                @click="clickBaterPonto"
              >
                bater ponto
              </c-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card v-if="showComprovanteData" id="comprovante">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <c-btn icon @click="clickClose()">
            <v-icon>mdi-close</v-icon>
          </c-btn>
          <v-spacer />
          <v-toolbar-title>COMPROVANTE DE PONTO</v-toolbar-title>
          <v-spacer />
          <c-btn icon :ripple="false" @click="clickConfirm()">
            <v-icon>mdi-export-variant</v-icon>
          </c-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="px-0">
        <v-container class="mt-2 px-0">
          <!-- pessoa parceiro nome -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-subtitle-1">
                {{ dataset.parceiro.pessoaParceiro_nome }}
              </span>
            </v-col>
          </v-row>

          <!-- documento -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-1">
                {{ documentoParceiroFormatado }}
              </span>
            </v-col>
          </v-row>

          <!-- foto -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <v-img
                v-if="dataset.marcacao.pessoaColaborador_endereco_imagem"
                id="imagem"
                max-height="200"
                max-width="200"
                :src="dataset.marcacao.pessoaColaborador_endereco_imagem"
              />
            </v-col>
          </v-row>

          <!-- colaborador -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-subtitle-1">
                {{ dataset.marcacao.pessoaColaborador_nome.toUpperCase() }}
              </span>
            </v-col>
          </v-row>

          <!-- documento -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-1">
                {{ documentoColaboradorFormatado }}
              </span>
            </v-col>
          </v-row>

          <!-- endereco -->
          <v-row v-bind="prop.row" class="mb-4">
            <v-col v-bind="prop.col" align="center">
              <a
                class="text-caption"
                rel="noopener noreferrer"
                target="_blank"
                @click="clickEndereco"
              >
                {{ dataset.marcacao.enderecoPonto }}
              </a>
            </v-col>
          </v-row>

          <!-- marcacao -->
          <v-row v-bind="prop.row" class="mb-2">
            <v-col v-bind="prop.col" align="center">
              <span class="text-h6">
                {{
                  dataFormata(dataset.marcacao.data, "date timeNoSecond", true)
                }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import axios from "axios";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "CPontoEletronicoDialogPontoEletronico",

  /// MIXINS
  mixins: [mixinData, mixinSnackbar, mixinUtil],

  /// MODEL
  model: { prop: "value", event: "input" },

  /// PROPS
  props: {
    dataset: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showComprovante: { type: Boolean, default: false },
    value: Boolean,
  },

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];

    return {
      anexo: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        anexo: {
          id: null,
          idTipo: 0,
          idTabela: null,
          idRegistro: null,
          order: null,
          nome: null,
          tipo: "",
          tamanho: 0,
          endereco: null,
        },
      },

      dataServidor: null,
      enviarEmail: false,
      imageBlob: null,
      latitude: null,
      local: "",
      localeTemp: "en-US",
      longitude: null,
      marcacao: {},
      mediaStream: null,
      parceiro: {},
      photo: null,
      showComprovanteData: this.showComprovante,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
      getTipo_item: "tipos/getTipo_item",
      getLocalidade_configuracoesRegionais:
        "localidades/getLocalidade_configuracoesRegionais",
      getTabela: "tabelas/getTabela",
    }),

    documentoColaboradorFormatado() {
      const dadoUsuario =
        this.getVar("usuarios")[
          this.getVar("usuarios").findIndex(
            (usuario) => usuario.pessoa.id == this.getVar("idUsuario")
          )
        ];

      const documento =
        this.dataset.marcacao.pessoaColaborador_documento_federal.mask(
          this.documentoMascara(
            dadoUsuario.enderecos[0].idPais,
            this.dataset.marcacao.pessoaColaborador_idTipo
          )
        );

      return documento;
    },

    documentoParceiroFormatado() {
      const dadoParceiro =
        this.getVar("usuarios")[
          this.getVar("usuarios").findIndex(
            (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
          )
        ];

      const documento =
        this.dataset.parceiro.pessoaParceiro_documento_federal.mask(
          this.documentoMascara(
            dadoParceiro.enderecos[0].idPais,
            this.dataset.parceiro.pessoaParceiro_idTipo
          )
        );

      return documento;
    },

    heightImage() {
      let height = 0;
      const video = document.querySelector("#video");

      if (video) {
        const rect = video.getBoundingClientRect();
        height = rect.height;
      }

      return height;
    },

    horaServidor() {
      return this.dataFormata(this.dataServidor, "timeNoSecond", true);
    },

    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    widthImage() {
      let width = 0;

      const video = document.querySelector("#video");

      if (video) {
        const rect = video.getBoundingClientRect();
        width = rect.width;
      }

      return width;
    },
  },

  /// HEAD
  head: {
    script: [
      {
        async: false,
        body: true,
        defer: true,
        src: "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js",
        type: "text/javascript",
      },
    ],
  },

  /// METHODS
  methods: {
    async clickBaterPonto() {
      this.setVar({ overlay: true });
      this.closeCamera();

      let coordenada;

      const url = `${
        this.getConst("app").baseUrlBackend
      }/pontosEletronicos/marcacao/`;

      try {
        coordenada = await this.getPosition();
      } catch (err) {
        this.setSnackbar(
          true,
          "LOCALIZAÇÃO",
          err,
          "warning",
          "mdi-check",
          "ajuda"
        );
      }

      if (coordenada) {
        const params = {
          idPessoaParceiro: this.getVar("idPessoaParceiro"),
          idPessoaColaborador: this.getVar("idUsuario"),
          latitude: coordenada.latitude,
          longitude: coordenada.longitude,
        };

        try {
          const res = await axios.post(url, params);

          if (res) {
            const datasetMarcacoes = res.data;
            const urlAnexo = `${this.getConst("app").baseUrlBackend}/anexos`;

            const marcacoes = datasetMarcacoes.main.marcacoes
              .filter(
                (marcacao) =>
                  (marcacao.idPessoaColaborador = this.getVar("idUsuario"))
              )
              .pop();

            this.anexo.anexo.idTipo = this.getTipo_item("anexoArquivo").id;
            this.anexo.anexo.idTabela = this.getTabela(
              "pontosEletronicos_datas_marcacoes"
            ).id;
            this.anexo.anexo.idRegistro = marcacoes.id;
            this.anexo.anexo.order = 1;
            this.anexo.anexo.nome = ".png";

            const file = this.dataUrlToFile(this.photo, "photo.png");

            let formData = new FormData();
            formData.append("anexo", JSON.stringify(this.anexo));
            formData.append("file", file);

            axios.post(urlAnexo, formData).then(async (resAnexo) => {
              const auxMarcacoes = datasetMarcacoes.aux.marcacoes
                .filter(
                  (marcacao) =>
                    (marcacao.idPessoaColaborador = this.getVar("idUsuario"))
                )
                .pop();

              const url = resAnexo.data.anexo.endereco;

              fetch(url, {
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
              })
                .then((response) => {
                  return response.blob();
                })
                .then((myBlob) => {
                  this.imageBlob = URL.createObjectURL(myBlob);
                });

              this.dataset.parceiro = {
                pessoaParceiro_idTipo:
                  datasetMarcacoes.aux.detail.pessoaParceiro_idTipo,
                pessoaParceiro_nome:
                  datasetMarcacoes.aux.detail.pessoaParceiro_nome,
                pessoaParceiro_documento_federal:
                  datasetMarcacoes.aux.detail.pessoaParceiro_documento_federal,
              };

              this.dataset.marcacao = {
                pessoaColaborador_idTipo: auxMarcacoes.pessoaColaborador_idTipo,
                pessoaColaborador_nome: auxMarcacoes.pessoaColaborador_nome,
                pessoaColaborador_documento_federal:
                  auxMarcacoes.pessoaColaborador_documento_federal,
                pessoaColaborador_endereco_imagem: resAnexo.data.anexo.endereco,
                latitude: marcacoes.latitude,
                longitude: marcacoes.longitude,
                data: marcacoes.data,
                imagemPonto: this.imageBlob,
              };

              this.latitude = marcacoes.latitude;
              this.longitude = marcacoes.longitude;

              const urlMap = `${this.getConst("app").baseUrlBackend}/mapQuest`;
              const body = {
                lat: this.latitude,
                lon: this.longitude,
              };

              const resMapQuest = await axios.post(urlMap, body);
              const enderecoPonto = resMapQuest.data[0].formattedAddress;
              this.dataset.marcacao.enderecoPonto = enderecoPonto;
              this.showComprovanteData = true;

              this.setVar({ overlay: false });
            });
          }
        } catch (err) {
          this.setVar({ overlay: false });
          console.log("err", err);
          this.setSnackbar(
            true,
            "PONTO ELETRÔNICO",
            err?.response?.data || "erro",
            "error"
          );
        }
      }
    },

    clickClose() {
      this.closeCamera();
      this.model = false;
    },

    async clickConfirm() {
      const htmlComprovante = document.getElementById("comprovante");
      window
        .html2canvas(htmlComprovante, { useCORS: true, allowTaint: true })
        .then(async (canvas) => {
          // let shareData = {};
          canvas.toBlob(async (blob) => {
            const files = [new File([blob], "image.png", { type: blob.type })];
            const shareData = {
              files,
            };
            await navigator.share(shareData);
          });
        });
    },

    clickEndereco() {
      const baseUrl = this.getConst("config").urlGoogleMaps;
      const complementoUrl = `${this.latitude}, ${this.longitude}`;
      const url = `${baseUrl}${complementoUrl}`;
      window.open(url, "_blank");
    },

    clickEnviarEmail() {
      this.enviarEmail = !this.enviarEmail;
    },

    clickFoto() {
      let canvas = document.querySelector("#canvas");
      let video = document.querySelector("#video");

      canvas.width = this.widthImage;
      canvas.height = this.heightImage;

      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, this.widthImage, this.heightImage);

      this.photo = canvas.toDataURL("image/png");
    },

    clickSnackbar(event) {
      this.funcs.clickSnackbar(event, this);
    },

    closeCamera() {
      let video = document.querySelector("#video");

      if (video && video.srcObject) {
        const stream = video.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
        video.srcObject = null;
      }
    },

    dataUrlToFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    documentoMascara(idPais, idTipo) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id,
        idTipo
      )[0]?.mascara;

      return mascara;
    },
  },

  /// MOUNTED
  async mounted() {
    this.getVar("socket").emit("getDate");

    this.getVar("socket").on("date", (date) => {
      this.dataServidor = this.parseISO(date);
    });

    //? 60000 duro - amolecer
    setInterval(() => {
      this.dataServidor = this.addMinutes(this.dataServidor, 1);
    }, 60000);

    if (!this.showComprovanteData) {
      let video = document.querySelector("#video");
      let stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 1028,
          height: 1028,
        },
        audio: false,
      });
      video.srcObject = stream;
    }
  },
};
</script>

<style scoped>
.loadingImage {
  visibility: hidden;
}

.video {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}
</style>