<!-- COD: FDS 01/07/2022 CFeriadosPageFeriadosDoAno -->
<!-- *VER: JER 05/08/2022 CFeriadosPageFeriadosDoAno -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" v-if="dataset.data" align="center" cols="2">
        <span :class="`${dataset.class} text-body-1`">
          {{ dataFormat }}
        </span>
      </v-col>
      <!-- localidade imagem -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.idLocalidade"
        align="center"
        cols="1"
      >
        <v-img :src="dataset.anexo_endereco" width="25"></v-img>
      </v-col>
      <!-- icon -->
      <v-col
        v-bind="prop.col"
        v-if="!dataset.idLocalidade"
        align="center"
        cols="1"
      >
        <v-icon>
          {{ dataset.icon }}
        </v-icon>
      </v-col>
      <!-- icone -->
      <v-col v-bind="prop.col" v-if="dataset.icone" align="center" cols="1">
        <v-icon class="corRetirar--text" small>
          {{ dataset.icone }}
        </v-icon>
      </v-col>
      <v-col v-bind="prop.col" v-if="dataset.nome" cols="8">
        <!-- nome -->
        <span class="text-body-2">
          {{ dataset.nome }}
        </span>
        <!-- localidade -->
        <span v-if="dataset.localidade" class="text-caption">
          ({{ dataset.localidade }})
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFeriadosPageFeriadosDoAno",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    dataFormat() {
      return this.dateFormat(
        this.dataset.data.substring(0, 10),
        `dateNoYear dateWeekday`
      )
        .replace(",", "")
        .replace(".", "");
    },
  },
};
</script>