<!-- COD: FDS 04/08/2022 Logs -->
<!-- *VER: JER 10/08/2022 Logs -->
<!-- /32/ -->

<template>
  <div
    v-touch="{
      down: ($event) => swipe('down', swipeRefresh, $event),
      up: ($event) => swipe('up', swipeInject, $event),
    }"
  >
    <!-- NAVBAR -->
    <c-the-navbar mode="page" title="Logs">
      <template #icon>
        <c-btn icon router to="/">
          <v-icon large>
            {{ getFeature("optionLogs").options.icon }}
          </v-icon>
        </c-btn>
      </template>
    </c-the-navbar>

    <!-- ??? Onde posicionar o snacbar? -->
    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <v-container class="ma-0 pa-0">
      <div v-if="!isRegistro" class="mt-4">
        <!-- search -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-text-field
              v-model="search"
              appendOuterIcon="mdi-tune"
              clearable
              :max="100"
              placeholder="busca"
              prepend-inner-icon="mdi-magnify"
              solo
              type="search"
              @clickAppendOuter="advancedSearch = !advancedSearch"
              @input="logsFilterClear"
              @keyup.enter="logsFilter()"
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-show="advancedSearch" class="mt-n8">
          <!-- ordenar -->
          <v-col v-bind="prop.col" cols="6">
            <c-select
              v-model="idOrdenar"
              class="pr-1"
              :items="ordenar"
              prepend-inner-icon="mdi-arrow-up"
              solo
              @change="idOrdenar ? logs.sortKeys(idOrdenar) : logsFilter()"
            />
          </v-col>
          <!-- evento -->
          <v-col v-bind="prop.col" cols="6">
            <c-select
              v-model="params.idEvento"
              class="pl-1"
              :items="eventos"
              prepend-inner-icon="mdi-flash-outline"
              solo
              @change="logsFilter()"
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-show="advancedSearch" class="mt-n8">
          <!-- tabela -->
          <v-col v-bind="prop.col" cols="6">
            <c-select
              v-model="params.idTabela"
              class="pr-1"
              :items="tabelas"
              prepend-inner-icon="mdi-page-layout-header"
              solo
              @change="logsFilter()"
            />
          </v-col>
          <!-- usuario -->
          <v-col v-bind="prop.col" cols="6">
            <c-select
              v-model="params.idUsuario"
              class="pl-1"
              :items="usuarios"
              prepend-inner-icon="mdi-account-outline"
              solo
              @change="logsFilter()"
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-show="advancedSearch" class="mt-n8">
          <!-- data inicio -->
          <v-col v-bind="prop.col" cols="6">
            <c-datetime-picker
              v-model="params.dataInicio"
              classe="pr-1"
              clearable
              date
              icon
              label="início"
              :max="params.dataFim"
              :min="data.min"
              solo
              time
            />
          </v-col>
          <!-- data fim -->
          <v-col v-bind="prop.col" cols="6">
            <c-datetime-picker
              v-model="params.dataFim"
              classe="pl-1"
              clearable
              date
              icon
              label="fim"
              :max="data.max"
              :min="params.dataInicio"
              solo
              time
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-show="advancedSearch" class="mt-n8">
          <!-- itens por pagina -->
          <v-col v-bind="prop.col" cols="6" v-if="getVar('idUsuario') <= 32">
            <c-select
              v-model="limit"
              class="pr-1"
              :items="itensPorPagina"
              label="paginação"
              prepend-inner-icon="mdi-format-align-justify"
              solo
            />
          </v-col>
          <!-- buscar -->
          <v-col
            v-bind="prop.col"
            align="end"
            :cols="getVar('idUsuario') <= 32 ? '6' : '12'"
          >
            <c-btn
              block
              classe="primary white--text mt-n6"
              large
              @click="logsFilter()"
            >
              buscar
            </c-btn>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row v-bind="prop.row" class="ml-n2">
          <!-- icone feature -->
          <v-col v-bind="prop.col">
            <v-icon class="primary--text" x-large>
              {{ getIconFeatureByTable(params.idTabela) }}
            </v-icon>
            <!-- registro -->
            <span class="text-h6 ml-2">
              {{ registro }}
              #
              {{ params.idRegistro }}
            </span>
          </v-col>
        </v-row>
      </div>

      <v-row v-bind="prop.row">
        <!-- espaco -->
        <v-col v-bind="prop.col">
          <v-badge
            :content="logs.length.toString()"
            color="grey"
            left
          ></v-badge>
        </v-col>
      </v-row>

      <v-timeline dense class="ml-n7">
        <v-timeline-item
          v-for="(log, i) in logs"
          :color="getTipo_item(log.idEvento).options.color"
          :icon="getTipo_item(log.idEvento).options.icon"
          :key="i"
          right
        >
          <v-card class="pa-3">
            <v-row v-bind="prop.row">
              <!-- icone calendario -->
              <v-col v-bind="prop.col" align="center" cols="1">
                <v-icon> mdi-calendar </v-icon>
              </v-col>
              <!-- data -->
              <v-col v-bind="prop.col" cols="11">
                <span class="text-body-1">
                  {{ dataFormata(log.data, "date timeNoSeconds", true) }}
                </span>
              </v-col>
            </v-row>

            <v-row v-bind="prop.row">
              <!-- icone usuario -->
              <v-col v-bind="prop.col" align="center" cols="1">
                <v-icon> mdi-account-outline </v-icon>
              </v-col>
              <!-- usuario -->
              <v-col v-bind="prop.col" cols="11">
                <span class="text-body-1">
                  {{ log.usuario }}
                </span>
              </v-col>
            </v-row>

            <v-row v-bind="prop.row">
              <!-- icone calendario -->
              <v-col v-bind="prop.col" align="center" cols="1">
                <v-icon>
                  {{ getIconFeatureByTable(log.idTabela) }}
                </v-icon>
              </v-col>
              <!-- data -->
              <v-col v-bind="prop.col" cols="11">
                <span class="text-body-1">
                  {{ getTabela(log.idTabela).nomeAlt }}
                  #
                  {{ log.idRegistro }}
                </span>
              </v-col>
            </v-row>

            <v-card-subtitle
              v-for="(logDetalhe, j) in logsDetalhes.filter(
                (element) => element.idPai == log.id
              )"
              v-show="mostrarDetalhes"
              :key="j"
            >
              <v-row
                v-bind="prop.row"
                v-if="logDetalhe.idTabela && getTabela(logDetalhe.idTabela)"
              >
                <v-col v-bind="prop.col" cols="4">
                  <!-- campo -->
                  <span class="font-weight-medium text-body-2">
                    {{ logDetalhe.campo }}
                  </span>
                </v-col>
                <!-- tabela -->
                <v-col v-bind="prop.col" cols="8">
                  <span class="font-weight-medium text-body-2">
                    {{ getTabela(logDetalhe.idTabela).nomeAlt }}
                  </span>
                </v-col>
              </v-row>

              <!-- valor de -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <span class="text-body-2">
                    {{ logDetalheValor(logDetalhe, logDetalhe.de) }}
                  </span>
                </v-col>
              </v-row>

              <!-- valor para -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <span class="text-body-2">
                    {{ logDetalheValor(logDetalhe, logDetalhe.para) }}
                  </span>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <!-- /// LIST -->
    </v-container>

    <!-- botao plus -->
    <v-row
      v-bind="prop.row"
      v-if="!isTouchDevice && !btnInjectClicked && logs.length >= limit"
    >
      <v-col v-bind="prop.col" align="center">
        <v-btn class="mt-3" fab small @click="swipeInject">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <c-the-navbar bottom mode="page" :slots="buttonsBar.length" title="">
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-btn
          v-if="button.nome == 'optionAlternarOrdem'"
          icon
          :key="i"
          @click="clickOption(button)"
        >
          <v-icon>
            {{ ordemAscendente ? iconeOrdem[0] : iconeOrdem[1] }}
          </v-icon>
        </c-btn>
        <c-btn
          v-else-if="button.nome == 'optionAlternarNivelDetalhe'"
          icon
          :key="i"
          @click="clickOption(button)"
        >
          <v-icon>
            {{ mostrarDetalhes ? iconeDetalhe[0] : iconeDetalhe[1] }}
          </v-icon>
        </c-btn>
        <c-btn
          v-else-if="!Array.isArray(button)"
          icon
          :key="i"
          @click="clickOption(button)"
        >
          <v-icon>
            {{ button.options.icon }}
          </v-icon>
        </c-btn>
        <v-menu v-else :key="i" offset-y top>
          <template #activator="{ on }">
            <c-btn v-on="on" icon>
              <v-icon large>mdi-dots-horizontal</v-icon>
            </c-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, i) in button"
              :key="i"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </c-the-navbar>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "Logs",

  /// COMPONENTS
  components: {
    CTheNavbar,
  },

  /// MIXINS
  mixins: [mixinData, mixinSnackbar, mixinSwipe, mixinUsuarios],

  /// HEAD
  head: {
    title() {
      return {
        inner: this.getPagina("logs").nomeAlt,
      };
    },
  },

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("logs").id,

      advancedSearch: false,
      data: { min: null, max: null },
      eventos: [{ value: null, text: "evento" }],
      iconeDetalhe: ["mdi-collapse-all-outline", "mdi-expand-all-outline"],
      iconeOrdem: ["mdi-arrow-up", "mdi-arrow-down"],
      idOrdenar: "id",
      limit: getVar("limit").selected,
      logs: [],
      logsDetalhes: [],
      modeBusca: parseInt(this.$route.params.modeBusca),
      mostrarDetalhes: false,
      ordem: -1,
      ordemAscendente: false,
      ordenar: [
        { value: null, text: "ordenar", ordem: 1, agrupar: false },
        { value: "id", text: "id", ordem: 1, agrupar: false },
        { value: "idEstado", text: "estado", ordem: 1, agrupar: true },
        { value: "data", text: "data", ordem: -1, agrupar: false },
        { value: "idTabela", text: "tabela", ordem: 1, agrupar: true },
        { value: "idRegistro", text: "idRegistro", ordem: 1, agrupar: false },
        { value: "idEvento", text: "evento", ordem: 1, agrupar: true },
        { value: "idUsuario", text: "usuário", ordem: 1, agrupar: true },
      ],
      params: {
        idTabela: parseInt(this.$route.params.idTabela),
        idRegistro: parseInt(this.$route.params.idRegistro),
        idEvento: null,
        idUsuario: null,
        dataInicio: null,
        dataFim: null,
        timeZone: Intl.DateTimeFormat()
          .resolvedOptions()
          .timeZone.replace("/", "__"),
      },
      search: "",
      tabelas: [{ value: 0, text: "pagina" }],
      usuarios: [{ value: null, text: "usuário" }],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getPagina: "paginas/getPagina",
      getPaginas: "paginas/getPaginas",
      getPagina_documentos: "paginas/getPagina_documentos",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true
      ).sortKeys('"order"');
    },

    buttonsBar() {
      return [
        ...this.buttons.map((button) => {
          return {
            ...button,
            clickFunction: this.clickOption,
          };
        }),
        this.options,
      ];
    },

    isRegistro() {
      return this.registro != "*";
    },

    itensPorPagina() {
      return this.getVar("limit")
        .itens.filter(
          //? restrição provisoria para facilitar o desenvolvimento
          (item) => item.value <= (this.getVar("idUsuario") <= 32 ? 100 : 50)
        )
        .map((item) => {
          item.text = `${item.value}/${this.translate("página")}`;
          return item;
        });
    },

    options() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        false,
        null
      ).sortKeys('"order"');
    },

    paginas() {
      return this.getPaginas().slice();
    },

    registro() {
      return this.$route.params.registro;
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    clickDetalhe() {
      this.mostrarDetalhes = !this.mostrarDetalhes;
    },

    clickOption(option) {
      const action = option.options.click;
      if (action == "clickAlternarOrdem") {
        this.clickOrder();
      } else if (action == "clickAlternarNivelDetalhe") {
        this.clickDetalhe();
      } else if (action == "clickAjuda") {
        const paginaDocumento = this.getPagina_documentos(
          this.getPagina("ajuda")?.id
        ).find(
          (paginaDocumento) =>
            paginaDocumento.options.feature &&
            this.getFeature(paginaDocumento.options.feature)?.id ==
              this.getPagina(this.idPage).idFeature
        );

        const url = paginaDocumento ? `/ajuda#${paginaDocumento.id}` : "/ajuda";
        this.$router.push(encodeURI(url));
      } else {
        this.$router.push(option.options.route);
      }
    },

    clickOrder() {
      this.ordemAscendente = !this.ordemAscendente;
      this.ordem = this.ordem * -1;
      this.logs.sort((a, b) => {
        if (this.ordem > 0) {
          return parseInt(a.id) - parseInt(b.id);
        } else {
          return parseInt(b.id) - parseInt(a.id);
        }
      });
    },

    clickRouter(log) {
      setTimeout(() => {
        const url = `/${this.getTabela(log.idTabela).nomeAlt}/${
          log.idRegistro
        }`;
        this.$router.push(encodeURI(url));
      }, 10);
    },

    clickSearch() {
      this.setVar({ overlay: true });

      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const url = `${
        this.getConst("app").baseUrlBackend
      }/logs/options/${idUsuario}/${idPessoaParceiro}`;

      axios
        .get(url)
        .then((res) => {
          const result = res.data;
          // Este codigo substituiria os 3 trechos abaixo
          // Mas prefirimos os 3 trechos por serem mais faceis de entender
          // Object.keys(result).forEach(element => {
          //   result[element].forEach(item => {
          //     this[element].push(item)
          //   })
          // })

          // eventos
          result.eventos.forEach((evento) => {
            this.eventos.push(evento);
          });

          // tabelas
          result.tabelas.forEach((tabela) => {
            this.tabelas.push(tabela);
          });

          // usuarios
          result.usuarios.forEach((usuario) => {
            this.usuarios.push(usuario);
          });

          if (result.data) {
            this.params.dataInicio = this.data.min;
            this.params.dataFim = this.data.max;
          }
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });

      if (this.modeBusca == this.getConst("config").tipoBusca.publicacoes.id)
        this.logsFilter();
    },

    getFeatureByTable(idTabela) {
      let ret = "";

      if (idTabela > 0) {
        ret = this.paginas
          .filter((page) => page.idTabela == idTabela)
          .map((page) => this.getPagina(page.value))
          .map((page) => this.getFeature(page.idFeature))
          .slice();

        if (ret.length) {
          ret = ret[0];
        }
      }

      return ret;
    },

    getIconFeatureByTable(idTabela) {
      return this.getFeatureByTable(idTabela).options?.icon;
    },

    logDetalheValor(logDetalhe, valor) {
      if (logDetalhe.campo == "idEstado") {
        valor = this.getEstado(valor).nomeAlt;
      }

      return valor;
    },

    logsFilter(offset = 0) {
      this.setVar({ overlay: true });
      const search = this.search || "0";
      const idContexto = "*";
      const idFilter = null;
      const idOrder = null;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;
      const params = JSON.stringify(this.params);

      let url = `${
        this.getConst("app").baseUrlBackend
      }/logs/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
        this.limit
      }/${params}`;

      axios
        .get(url)
        .then((res) => {
          const result = res.data;

          if (offset == 0) {
            this.logs = [];
            this.logsDetalhes = [];
          }

          result.logs.forEach((element) => {
            this.logs.push(element);
          });

          result.detalhes.forEach((element) => {
            this.logsDetalhes.push(element);
          });
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
            this.btnInjectClicked = false;
          }, this.getConst("config").overlay.timeout);
        });
    },

    logsFilterClear() {
      if (!this.search) {
        this.logsFilter();
      }
    },

    swipeInject() {
      this.btnInjectClicked = true;
      this.logsFilter(this.logs.length);
    },

    swipeRefresh() {
      this.logsFilter();
    },
  },

  /// MOUNTED
  mounted() {
    this.clickSearch();
    this.logsFilter();
  },

  /// ACTIVATED
  activated() {
    this.logsFilter();
  },
};
</script>