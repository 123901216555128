<!-- COD: FDS 04/07/2022 CPerguntaFrequenteView -->
<!-- *VER: JER 19/07/2022 CPerguntaFrequenteView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- grupo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.grupo">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ dataset.main.detail.grupo[locale] }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- pergunta -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.pergunta">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.pergunta[locale] }}
        </span>
      </v-col>
    </v-row>

    <!-- like -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <v-icon color="green">mdi-thumb-up-outline</v-icon>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.aux.perguntasFrequentes_curtidas.numeroLikes }}
        </span>
      </v-col>
    </v-row>

    <!-- dislike -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <v-icon color="red">mdi-thumb-down-outline</v-icon>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.aux.perguntasFrequentes_curtidas.numeroDislikes }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPerguntaFrequenteView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    locale() {
      return this.context.getVar("locale");
    },
  },
};
</script>