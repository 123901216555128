<!-- COD: BJB 19/04/2023 CBaseBuscaLayout -->
<!-- VER: JER __/04/2023 CBaseBuscaLayout -->
<!-- /11/ -->

<template>
  <!-- card -->
  <div class="ma-0 pa-0" style="width: 100%">
    <v-container v-if="layout == 'card'" class="pa-0">
      <!-- produto -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <span class="pb-1 text-body-2">
            {{ produtoFormat(element) }}
          </span>
        </v-col>
      </v-row>

      <!-- encomenda -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <v-chip
            v-if="element.id % 2 === 0"
            class="mb-2 primary primary--text text-overline"
            outlined
            x-small
          >
            encomenda
          </v-chip>
        </v-col>
      </v-row>

      <!-- previsaoEntrega -->
      <v-row v-bind="prop.row" :class="isPromocao ? 'mt-n2' : 'mt-n3'">
        <v-col v-bind="prop.col" align="center">
          <span class="mt-3 pb-1 primary--text text-body-2">
            {{ element.previsaoEntrega }}
          </span>
        </v-col>
      </v-row>
    </v-container>

    <!-- list -->
    <v-container v-else-if="layout == 'list'" class="pa-0">
      <!-- produto -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <span class="font-weight-medium py-1 text-body-1">
            {{ produtoFormat(element) }}
          </span>
        </v-col>
      </v-row>
      <v-row v-bind="prop.row" class="ml-n1">
        <v-col v-bind="prop.col" :cols="hasImagem ? '12' : '6'">
          <!-- encomenda -->
          <div class="pa-0 ma-0">
            <v-chip
              v-if="element.id % 2 === 0"
              class="mb-2 primary primary--text text-overline"
              outlined
              x-small
            >
              encomenda
            </v-chip>
          </div>
          <!-- previsaoEntrega -->
          <div class="pa-0 ma-0">
            <span class="mt-3 pb-1 primary--text text-body-2">
              {{ element.previsaoEntrega }}
            </span>
          </div>
        </v-col>
        <v-col v-bind="prop.col" v-if="!hasImagem" cols="6">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  /// NAME
  name: "CBaseBuscaLayout",

  props: {
    element: { type: Object, required: true },
    hasImagem: { type: Boolean, default: true },
    isPromocao: { type: Boolean, default: false },
    layout: { type: String, default: "list" }, // opções: card, list
  },
};
</script>