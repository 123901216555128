<!-- COD: BJB 19/08/2022 Sobre -->
<!-- *VER: JER 19/08/2022 Sobre -->
<!-- /11/ -->

<template>
  <div>
    <v-container>
      <v-layout justify-center row>
        <v-card>
          <!-- /// NAVBAR -->
          <c-the-navbar mode="page" title="Sobre">
            <template #icon>
              <c-btn icon router to="/">
                <v-icon class="ml-2" large>
                  {{ icon }}
                </v-icon>
              </c-btn>
            </template>
          </c-the-navbar>

          <!-- /// SOBRE -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="end">
              <c-btn icon classe="mr-n2" @click="clickPanels">
                <v-icon>
                  {{ iconPanelCollapse }}
                </v-icon>
              </c-btn>
            </v-col>
          </v-row>

          <v-expansion-panels
            v-model="panelsOpen"
            flat
            multiple
            ref="panelsDocumentos"
          >
            <!-- /// MODULOS -->
            <v-container
              v-bind="prop.container"
              v-for="documento in documentos(null)"
              class="mb-3"
              :key="documento.index"
            >
              <c-base-documento
                :context="context"
                :documento="documento"
                :hasAdminOptions="hasAdminOptions"
                :id="'open' in documento.options ? documento.id : null"
                :idPage="idPage"
                :pageModeViewPai="pageModeView"
                @clickEditView="clickEditView"
              />
            </v-container>
          </v-expansion-panels>

          <v-card-actions>
            <c-btn
              v-scroll="onScroll"
              v-show="showFab"
              bottom
              fab
              fixed
              right
              @click="toScroll"
            >
              <v-icon>
                {{ iconScroll }}
              </v-icon>
            </c-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
      <p class="mb-n2 ml-n2 mt-8 text-caption">
        {{ this.documentos(null)[0].options.idioma["pt-BR"].copyrights }}
      </p>
      {{ documentos(null)[0].options.idiomas }}

      <!-- /// SNACKBAR -->
      <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
    </v-container>

    <!--   FOOTER -->
    <v-container>
      <!-- redes sociais -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <!-- //?b ajustar cbtn e atualizar este trecho -->
          <v-btn
            v-for="(redeSocial, i) in redesSociais"
            bottom
            class="ma-3"
            :class="redeSocial.color"
            icon
            :key="i"
            left
            @click="clickLink(redeSocial.action)"
          >
            <v-icon x-large>
              {{ redeSocial.icone }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <c-the-navbar bottom mode="page" :slots="buttons.length" title="">
      <template v-for="(button, i) in buttons" v-slot:[i]>
        <c-btn icon :key="i" @click="clickOption(button)">
          <v-icon large color="white">
            {{ button.options.icon }}
          </v-icon>
        </c-btn>
      </template>
    </c-the-navbar>
  </div>
</template>

<script>
/// IMPORT
import mixinDocumento from "@/mixins/mixinDocumento";

export default {
  /// NAME
  name: "Sobre",

  /// MIXINS
  mixins: [mixinDocumento],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("sobre").id,
      //? Buscar estas informacoes de redes sociais em constantes, tipos_itens e pessoa app
      redesSociais: [
        // {
        //   icone: "mdi-instagram",
        //   action: getConst("redesSociais").instagram,
        //   color: "purple darken-2",
        // },
        // {
        //   icone: "mdi-youtube",
        //   action: getConst("redesSociais").youtube,
        //   color: "red darken-2",
        // },
        // {
        //   icone: "mdi-twitter",
        //   action: getConst("redesSociais").twitter,
        //   color: "blue darken-1",
        // },
        // {
        //   icone: "mdi-facebook",
        //   action: getConst("redesSociais").facebook,
        //   color: "blue darken-3",
        // },
        // {
        //   icone: "mdi-whatsapp",
        //   action: getConst("redesSociais").whatsapp,
        //   color: "green darken-2",
        // },
        {
          icone: "mdi-email-outline",
          action: getConst("redesSociais").email,
          color: "primary--text",
          // color: "grey darken-2",
        },
      ],
      timeOutFab: null,
    };
  },

  /// COMPUTED
  computed: {
    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true
      ).sortKeys('"order"');
    },
  },

  /// METHODS
  methods: {
    clickOption(button) {
      this.clickLink(button.options.route);
    },
  },
};
</script>