<!-- COD: BJB 10/08/2022 CBaseDetail -->
<!-- *VER: JER 08/09/2022 CBaseDetail -->
<!-- /33/ -->

<template>
  <div
    v-touch="{
      down: ($event) => !pageModeView || swipe('down', swipeRefresh, $event),
      left: ($event) => !pageModeView || swipe('left', datasetsRouter, $event),
      right: ($event) =>
        !pageModeView || swipe('right', datasetsRouter, $event),
    }"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" :pageModeView="pageModeView" :title="title">
      <template #icon>
        <c-btn icon router :to="pageModeView ? '/' : null">
          <v-icon class="ml-2" large>
            {{ icon }}
          </v-icon>
        </c-btn>
      </template>
    </c-the-navbar>

    <v-row
      v-bind="prop.row"
      v-if="!isTouchDevice && pageModeView"
      class="my-3"
      justify="space-around"
    >
      <c-btn
        v-if="!isDetailList"
        fab
        small
        @click="datasetsRouter('right', true)"
      >
        <v-icon>mdi-chevron-double-left</v-icon>
      </c-btn>

      <c-btn v-if="!isDetailList" fab small @click="datasetsRouter('right')">
        <v-icon>mdi-chevron-left</v-icon>
      </c-btn>

      <c-btn fab small @click="clickSearch()">
        <v-icon>mdi-refresh</v-icon>
      </c-btn>

      <c-btn v-if="!isDetailList" fab small @click="datasetsRouter('left')">
        <v-icon>mdi-chevron-right</v-icon>
      </c-btn>

      <c-btn
        v-if="!isDetailList"
        fab
        small
        @click="datasetsRouter('left', true)"
      >
        <v-icon>mdi-chevron-double-right</v-icon>
      </c-btn>
    </v-row>

    <!-- /// DETAIL -->
    <v-form
      class="mb-10 pa-0"
      id="formDetail"
      ref="form"
      @submit="submitForm($event)"
    >
      <div v-if="!defaultParams || !defaultParams.isBlank" class="pa-0 ma-0">
        <!-- // IMAGES -->
        <slot name="images" :dataset="dataset" :pageModeView="pageModeView" />

        <!-- favoritar -->
        <v-row v-bind="prop.row" v-if="dataset.main.favoritos && pageModeView">
          <v-col v-bind="prop.col" align="center">
            <v-btn @click.stop="favoritar" icon>
              <v-icon
                :color="
                  dataset.main.favoritos.some(
                    (favorito) => favorito.idUsuario == this.getVar('idUsuario')
                  )
                    ? 'yellow darken-1'
                    : 'grey'
                "
              >
                {{
                  dataset.main.favoritos.some(
                    (favorito) => favorito.idUsuario == this.getVar("idUsuario")
                  )
                    ? "star"
                    : "star_outline"
                }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- // HEADER -->
        <v-container v-if="dataset.main.detail.idTipo != 0" class="px-0">
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <div>
                <v-chip
                  v-if="dataset.main.detail.idTipo > 0"
                  class="text-overline"
                  :class="tipoClass"
                  :outlined="idPage != getPagina('cupom').id"
                  x-small
                >
                  {{
                    translate(getTipo_item(dataset.main.detail.idTipo).nomeAlt)
                  }}
                </v-chip>
                <v-chip x-small :class="estadoClass">
                  {{
                    translate(getEstado(dataset.main.detail.idEstado).nomeAlt)
                  }}
                </v-chip>
                <c-help
                  v-if="
                    dataset.aux &&
                    dataset.aux.logs &&
                    dataset.aux.logs.quantidadeEstado > 1
                  "
                  estilo="width: fit-content"
                  :help="{
                    title: 'NÚMERO REPETIÇÕES ESTADO',
                    text: 'Número de vezes que o registro passou neste estado.',
                    function: setUiBottomSheet,
                  }"
                >
                  <span class="text-caption ml-1">
                    {{ dataset.aux.logs.quantidadeEstado }}
                  </span>
                </c-help>
                <v-progress-circular
                  v-if="dataset.main.detail.dataEstado"
                  class="ml-1"
                  color="red"
                  indeterminate
                  size="16"
                  width="2"
                />
                <v-icon
                  v-if="
                    pageModeView &&
                    dataset.main.detail.isSuspenso &&
                    dataset.main.detail.idEstado != getEstado('recorrente').id
                  "
                  class="red--text mt-n1 ml-1"
                >
                  <!-- class="ml-n5 mt-8 red--text" -->
                  mdi-lock-outline
                </v-icon>
              </div>
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" v-if="detailId" class="mt-2">
            <v-col v-bind="prop.col" align="center">
              <span :class="classTamanhoId">{{ detailId }}</span>
            </v-col>
          </v-row>

          <v-row
            v-bind="prop.row"
            v-if="
              dataset.main.detail.idPessoaParceiro !=
              dataset.main.detail.idUsuario
            "
            class="mt-2"
          >
            <v-col v-bind="prop.col" align="center">
              <v-chip
                class="primary primary--text text-overline"
                outlined
                x-small
              >
                {{
                  dataset.aux.detail.pessoaParceiro
                    ? dataset.aux.detail.pessoaParceiro.nomeAlt
                    : dataset.aux.detail.pessoaParceiro_nomeAlt
                }}
              </v-chip>
            </v-col>
          </v-row>
        </v-container>

        <div>
          <slot
            :context="this"
            :page="page"
            :pageAlt="pageAlt"
            :pageModeView="pageModeView"
          ></slot>

          <v-container
            v-if="page.hasProprietario && dataset.main.proprietarios"
          >
            <template v-if="pageModeView || params.proprietariosModeView">
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="center">
                  <span class="text-body-2">proprietarios</span>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="center">
                  <v-chip
                    v-for="(proprietario, i) in dataset.aux.proprietarios"
                    :class="`primary primary--text ${i > 0 ? 'ml-1' : ''}`"
                    outlined
                    :key="i"
                  >
                    {{ proprietario }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>
            <v-row
              v-bind="prop.row"
              v-else-if="dataset.main.detail.idTipo !== 0"
            >
              <v-col v-bind="prop.col">
                <c-autocomplete
                  v-model="dataset.main.proprietarios"
                  :itemsIniciais="dataset.aux.autocomplete.proprietarios"
                  label="proprietarios"
                  multiple
                  :path="`/pessoas/autocomplete/usuarioEmpresa/0/0/${getVar(
                    'idPessoaParceiro'
                  )}/`"
                  :rules="[]"
                />
              </v-col>
            </v-row>
          </v-container>
        </div>

        <v-container>
          <v-row
            v-bind="prop.row"
            v-if="
              page.hasAjuda && !pageModeView && dataset.main.detail.idTipo !== 0
            "
          >
            <!-- //? traduzir -->
            <v-col v-bind="prop.col" align="center" class="mb-n4">
              <span class="text-caption">clique e segure nos campos com</span>
              <span class="ml-0 text-caption red--text">?</span>
              <span class="ml-1 text-caption">para mais informações</span>
            </v-col>
          </v-row>
        </v-container>

        <!-- /// PANELS -->
        <div v-if="dataset.main.detail.idTipo != 0 && !isDetailList">
          <v-container>
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="end">
                <c-btn
                  v-if="panelsOpen.length && showDoubleChevron"
                  class="mr-n1"
                  icon
                  @click="clickPanelsDouble"
                >
                  <v-icon>
                    {{ chevronDoubleIcon }}
                  </v-icon>
                </c-btn>
                <c-btn class="mr-n3" icon @click="clickPanels()">
                  <v-icon>
                    {{
                      !panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}
                  </v-icon>
                </c-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-expansion-panels
            v-model="panelsOpen"
            class="px-0"
            multiple
            ref="panelsDetail"
          >
            <template v-for="(panel, i) in panels">
              <component
                v-if="defaultParams.context"
                :dataset="dataset"
                :field="
                  panel.nome.replace('CPanel', '').firstLetterToLowerCase()
                "
                :header="panel.nomeAlt"
                :id="panel.nome"
                :is="panel.nome"
                :key="i"
                :pageModeView="
                  direitosTestar(
                    page.id,
                    dataset.main.detail.id,
                    panel.id,
                    idPageMode
                  )
                    ? pageModeView
                    : true
                "
                :panel="panel"
                :params="defaultParams"
                @goTo="goTo"
              ></component>
            </template>
          </v-expansion-panels>
        </div>

        <!-- // BOTTOM -->
        <slot name="bottom" :context="this"></slot>

        <!-- /// BOTTOMSHEET -->
        <c-bottom-sheet :bottomSheet="bottomSheet" />
      </div>
      <slot
        v-else
        :pageModeView="pageModeView"
        :page="page"
        :pageAlt="pageAlt"
        :context="this"
      ></slot>
    </v-form>

    <c-btn
      v-if="!isDetailList"
      v-scroll="onScroll"
      v-show="showFab"
      bottom
      class="mb-11"
      fab
      fixed
      right
      @click="toScroll"
    >
      <v-icon large>{{ iconScroll }}</v-icon>
    </c-btn>

    <!-- /// NAVBAR -->
    <c-the-navbar
      v-if="!isDetailList"
      v-show="showBottom"
      bottom
      mode="page"
      :pageModeView="pageModeView"
      :slots="buttonsBar.length"
      title=""
      @showMenuVoltar="menuOptions = false"
    >
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-btn
          v-if="!Array.isArray(button)"
          icon
          :key="i"
          @click="button.clickFunction(button)"
        >
          <v-icon :color="button.options.color || 'white'" large>
            {{ buttonIcon(button) }}
          </v-icon>
        </c-btn>
        <v-menu v-else v-model="menuOptions" :key="i" offset-y top>
          <template #activator="{ on }">
            <c-btn icon v-on="on">
              <v-icon large>mdi-dots-horizontal</v-icon>
            </c-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, i) in button"
              :key="i"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </c-the-navbar>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </div>
</template>

<script>
/// IMPORT
import mixinTemplate from "@/mixins/mixinTemplate";

export default {
  /// NAME
  name: "CBaseDetail",

  /// MIXINS
  mixins: [mixinTemplate],

  /// PROPS
  props: {
    funcs: { type: Object },
    idFeature: { type: Number },
    idPage: { type: Number, required: true },
    idPageAlt: { type: Number, required: true },
    params: { type: Object, default: Object },
    showPanels: { type: Boolean, default: true },
    value: { type: Object, required: true },
  },

  /// DATA
  data() {
    return {
      dataset: this.value,
      datasetTemp: null,
      defaultParams: {
        clickPanelsOpen: null,
        context: this,
        panels: [],
        reloadButtonsBar: false,
        setSnackbar: this.setSnackbar,
      },
      idEstadoNext: null,
      isEstadoCancelar: false,
      idLocale: null,
      menuOptions: false,
      panelsOpen: [],
      showEstado: false,
      x: 0,
    };
  },

  /// COMPUTED
  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },

    buttonsBar() {
      let ret;

      if (this.defaultParams.reloadButtonsBar) {
        return [];
      }

      if (this.funcs.buttonsBar) {
        ret = this.funcs.buttonsBar(this);
      } else {
        if (this.idFeature && this.defaultParams.isBlank) {
          ret = [];
        } else if (this.pageModeView) {
          ret = [
            ...this.buttons.map((button) => {
              return {
                ...button,
                clickFunction: this.clickOption,
              };
            }),
            this.options,
          ];
        } else if (!this.pageModeView) {
          // if (!this.defaultParams.escolheTipo) {
          if (this.dataset.main.detail.idTipo !== 0) {
            ret = [
              {
                clickFunction: this.clickEditView,
                options: {
                  icon: "mdi-close",
                  color: "red accent-3",
                },
              },
              {
                clickFunction: this.clickSave,
                options: {
                  icon: "mdi-check",
                  color: "green accent-3",
                },
              },
            ];
          } else {
            ret = [
              {
                clickFunction: this.clickEditView,
                options: {
                  icon: "mdi-close",
                  color: "red accent-3",
                },
              },
            ];
          }
        }
      }

      return ret;
    },

    changed() {
      return this.funcs.changed ? this.funcs.changed(this) : false;
    },

    chevronDoubleIcon() {
      return !this.subPanelsOpen
        ? "mdi-chevron-double-down"
        : "mdi-chevron-double-up";
    },

    classTamanhoId() {
      const tamanhoId = this.getPagina(this.idPage).tamanhoId;
      return `text-h${tamanhoId}`;
    },

    dadosParceiro() {
      return this.dadosUsuario?.parceiro;
    },

    dadosUsuario() {
      const idPessoaParceiro =
        this.dataset?.main?.detail?.idPessoaParceiro ||
        this.dataset?.main?.detail?.idPessoa ||
        this.getVar("idPessoaParceiro");

      return this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == idPessoaParceiro
      );
    },

    detailId() {
      return this.dataset.main.detail.id &&
        this.idPageMode != this.getConst("config").pageMode.insert.id
        ? `#${this.dataset.main.detail.id}`
        : null;
    },

    isDetailList() {
      return this.paginaFeature?.options?.isDetailList;
    },

    estadoClass() {
      return `${
        this.getEstado(this.dataset.main.detail.idEstado).nomeAlt
      } white--text text-overline ${
        this.dataset.main.detail.idTipo ? "ml-n3" : ""
      }`;
    },

    indexLocale() {
      return this.idLocale
        ? this.dadosParceiro.idiomas.findIndex(
            (idioma) => idioma.idLocale == this.idLocale
          )
        : 0;
    },

    localeParceiro() {
      let ret = "pt-BR";

      if (this.idLocale) {
        const idioma = this.dadosParceiro.idiomas[this.indexLocale];
        ret = `${
          this.getTipo_item(this.getLocalidade_idioma(idioma.idLocale).idIdioma)
            .codigo
        }-${
          this.getLocalidade(this.getLocalidade_idioma(idioma.idLocale).idPai)
            .codigo2
        }`;
      }

      return ret;
    },

    paginaFeature() {
      let paginaFeature = {};

      if (this.idFeature) {
        paginaFeature = this.getPagina_features(this.idPage)
          .map((el) => this.getPagina_feature(el.value))
          .find((el) => el.idFeature == this.idFeature);
      }

      return paginaFeature;
    },

    panels() {
      const panels =
        !this.isDetailList && this.showPanels
          ? this.direitosFeatures(
              this.idPage,
              this.getTipo_item("featurePanel").id,
              null,
              this.dataset.main.detail,
              this.idPageMode,
              this.dataset?.main?.detail?.idTipo ||
                this.page.idContexto ||
                null,
              this.dataset?.main?.detail?.idEstado || null,
              this.dataset?.main?.objProprietarios || null
            )
              .map((panel) => {
                panel.forceUpdate = false;
                return panel;
              })
              .sortKeys('"order"')
          : [];

      panels.forEach((panel, i) => {
        panel.callbackRules = () => {
          if (!this.panelsOpen.includes(i)) {
            this.panelsOpen.push(i);
            this.$refs.form.validate();
          }
        };
      });

      return panels;
    },

    routeParamsId() {
      return this.$route.params.id;
    },

    showDoubleChevron() {
      return !!document.getElementsByClassName("subPanel")?.length;
    },

    subPanelsOpen() {
      return this.panels.some(
        (panel) =>
          !!this.defaultParams.panels.find(
            (paramsPanel) => paramsPanel.nome == panel.nome
          )?.specificPanelsOpen?.length
      );
    },

    tipoClass() {
      return this.idPage == this.getPagina("cupom").id
        ? `${
            this.getTipo_item(this.dataset.main.detail.idTipo).nomeAlt
          }1 white--text`
        : "corTipo corTipo--text";
    },

    tipos() {
      const contexto = this.getTipo_item(this.page.idContexto);

      return this.getTipo_itens(contexto.idPai).filter((item) => {
        return this.direitosTestar(
          this.getPagina("tiposItens")?.id,
          item.value
        );
      });
    },

    title() {
      return this.feature?.nomeAlt || this.page.nomeAlt;
    },
  },

  /// WATCH
  watch: {
    pageModeView() {
      setTimeout(() => {
        this.$forceUpdate();
      }, 10);
    },

    panels: {
      handler() {
        this.adjustPanels();
      },
      deep: true,
    },

    parceiro: {
      handler() {
        if (this.dadosParceiro?.idiomas?.length && !this.idLocale) {
          this.idLocale = this.dadosParceiro.idiomas[0].idLocale;
        }
      },
      deep: true,
      immediate: true,
    },

    async routeParamsId(valor, valorAnterior) {
      if (valor && valorAnterior) {
        const rotas = this.getVar("rotas");
        rotas[rotas.length - 1].name = this.title;
        rotas[rotas.length - 1].icon = this.icon;
        this.setVar({ rotas });
        await this.clickSearch();
      }
    },
  },

  /// METHODS
  methods: {
    adjustPanels() {
      this.panels.forEach((panel) => {
        if (!this.$options.components[`${panel.nome}`]) {
          this.defaultParams.panels.push({
            nome: panel.nome,
            specificPanelsOpen: null,
          });

          this.$options.components[`${panel.nome}`] = () =>
            import(
              `@/components/pages${panel.options?.route || "/panels"}/${
                panel.nome
              }`
            );
        }
      });
    },

    adjustPanelsOpen() {
      let count = 0;

      let interval = setInterval(() => {
        if (this.$refs?.panelsDetail?.$children?.length) {
          clearInterval(interval);
          interval = null;

          this.panels.forEach((panel) => {
            const index = this.$refs.panelsDetail.$children.findIndex(
              (panelDetail) =>
                panelDetail.$vnode.componentOptions.tag == panel.nome
            );

            if (index >= 0) {
              panel.index = index;
              if (panel.options?.isOpen) {
                this.panelsOpen.push(panel.index);
              }
            }
          });
        } else {
          if (count == 20) {
            clearInterval(interval);
            interval = null;
          }

          count++;
        }
      }, 100);
    },

    buttonIcon(button) {
      if (this.funcs.buttonIcon) {
        return this.funcs.buttonIcon(button, this);
      } else {
        return button.options.icon;
      }
    },

    clickCompartilhar() {
      const url = window.location.href;

      this.copyToClipboard(url);

      if (navigator.share) {
        navigator
          .share({
            title: `${this.dataset.main.detail.titulo || this.page.nomeAlt} #${
              this.dataset.main.detail.id
            }`,
            text: `${this.dataset.main.detail.titulo || this.page.nomeAlt} #${
              this.dataset.main.detail.id
            }`,
            url,
          })
          .then();
      } else {
        this.setSnackbar(
          true,
          "COMPARTILHAR",
          "Informações copiadas para área de transferência",
          "success"
        );
      }
    },

    clickDuplicar() {
      this.menuOptions = false;

      const tabela = this.getTabela(this.page.idTabela);

      const getValorInicial = (valorInicial) => {
        if (!valorInicial) {
          return valorInicial;
        } else if (valorInicial == "<idTipo>") {
          return 0; //?b tratar
        } else if (valorInicial.includes("<idEstado:")) {
          const estado = valorInicial.substring(10, valorInicial.length - 1);
          return this.getEstado(estado).id;
        } else if (valorInicial == "<idUsuario>") {
          return this.getVar("idUsuario");
        } else if (valorInicial == "<idPessoaParceiro>") {
          return this.getVar("idPessoaParceiro");
        } else if (valorInicial == "<idOrigem:pedidoOrigemErp>") {
          const tipo = valorInicial.substring(10, valorInicial.length - 1);
          return this.getTipo_item(tipo).id;
        } else {
          return this.getVal(valorInicial.replace(/[<>]/g, ""));
        }
      };

      Object.keys(this.dataset.main).forEach((key) => {
        const idTabela =
          key == "detail"
            ? tabela.id
            : this.getTabela(`${tabela.nome}_${key}`)?.id;

        if (idTabela) {
          const tabelaColunas = this.getTabela_colunas(idTabela).map(
            (tabelaColuna) => this.getTabela_coluna(tabelaColuna.value)
          );

          if (
            key != "detail" &&
            !tabelaColunas.some((coluna) => coluna.isDuplicar) &&
            Array.isArray(this.dataset.main[key])
          ) {
            this.dataset.main[key] = [];
          } else {
            tabelaColunas.forEach((coluna) => {
              if (!coluna.isDuplicar) {
                if (Array.isArray(this.dataset.main[key])) {
                  this.dataset.main[key]
                    .filter((registro) => typeof registro == "object")
                    .forEach((registro) => {
                      registro[coluna.nome] = getValorInicial(
                        coluna.valorInicial
                      );
                    });
                } else {
                  this.dataset.main[key][coluna.nome] = getValorInicial(
                    coluna.valorInicial
                  );
                }
              } else if (coluna.isConfirmar && coluna.dataType == "string") {
                //? tratar confirmar (? no final dos campos)
                if (Array.isArray(this.dataset.main[key])) {
                  this.dataset.main[key].forEach((registro) => {
                    if (typeof registro == "object") {
                      registro[coluna.nome];
                    } else {
                      registro;
                    }
                  });
                } else {
                  this.dataset.main[key][coluna.nome];
                }
              }
            });
          }
        } else if (Array.isArray(this.dataset.main[key])) {
          this.dataset.main[key] = [];
        }
      });

      this.clickEditView();
      this.idPageMode = this.getConst("config").pageMode.insert.id;
    },

    clickEditView() {
      if (this.pageModeView) {
        if (
          this.page.id == this.getPagina("parceiro").id &&
          this.dataset.main.detail.idEstado == this.getEstado("analise").id &&
          this.getVar("idPessoaParceiro") != this.getConst("app").id
        ) {
          this.setSnackbar(
            true,
            "PARCEIRO",
            "Parceiro está em análise. Assim que a análise for concluída, você será notificado",
            "warning"
          );
          return;
        }

        this.idPageMode = this.getConst("config").pageMode.edit.id;
        this.datasetTemp = JSON.parse(JSON.stringify(this.dataset));
      } else if (this.$route.query?.blank) {
        window.close();
      } else if (
        this.idPageMode == this.getConst("config").pageMode.insert.id
      ) {
        this.$router.go(this.getVar("goBack"));
      } else {
        this.idPageMode = this.getConst("config").pageMode.view.id;
        this.dataset = JSON.parse(JSON.stringify(this.datasetTemp));
        this.$emit("input", this.dataset);

        if (this.funcs.posCancel) {
          this.funcs.posCancel(this);
        }
      }
    },

    clickOption(paginaFeature) {
      const search = "*";
      const action =
        paginaFeature.options?.click || paginaFeature.options?.route;

      if (action == "clickAlterar") {
        this.clickEditView();
      } else if (action == "clickAlterarEstado") {
        this.showEstado = true;
      } else if (["clickSuspender", "clickLiberar"].includes(action)) {
        this.clickSuspenderLiberar();
      } else if (action == "/publicacao") {
        const imagens = this.dataset.aux.imagens.filter(
          (imagem) => imagem.endereco
        );

        if (imagens.length) {
          const url = `/publicacao?idPublicado=${this.dataset.main.detail.id}&titulo=${this.dataset.main.detail.descricao}, ${this.dataset.main.detail.marca}`;
          this.$router.push(encodeURI(url));
        } else {
          this.setSnackbar(true, "IMAGEM", "Produto não tem imagem", "error");
        }
      } else if (action == "/parceiro") {
        if (
          this.dataset.main.detail.idEstado == this.getEstado("cadastro").id
        ) {
          this.setSnackbar(
            true,
            "CADASTRO",
            "Cadastro incompleto. Deseja concluir cadastro?",
            "warning",
            "mdi-check",
            "cadastro"
          );

          return;
        }

        const idPessoaParceiro =
          this.dataset.aux.detail.idParceiro || -this.dataset.main.detail.id;
        const url = `/parceiro/${idPessoaParceiro}/${this.dataset.main.detail.idTipo}/${this.dataset.main.detail.nome}/${this.dataset.main.detail.nomeAlt}`;
        this.$router.push(encodeURI(url));
      } else if (action == "clickRelatorios") {
        const idContexto = this.page.idContexto || "*";
        let url = `/relatorios/${this.page.id}/${this.page.idTabela}/${this.dataset.main.detail.id}/0/${idContexto}/${this.idFilter}/${this.idOrder}/${search}`;

        if (this.funcs.fnParamsReport) {
          const params = this.funcs.fnParamsReport();
          url += `/${params}`;
        }

        this.$router.push(encodeURI(url));
      } else if (action == "clickCancelar") {
        this.showEstado = true;
        this.idEstadoNext = this.getEstado("cancelado").id;
        this.isEstadoCancelar = true;
      } else if (action == "clickCupons") {
        this.funcs.clickCupons(this);
      } else if (action == "cancelar") {
        this.funcs.cancelarConfirm(this);
      } else if (action == "/carrinho") {
        this.clickCarrinho(this.dataset.main.detail.idCarrinho);
      } else if (action == "/transacao") {
        this.funcs.clickTransacao(this);
      } else if (action == "/pedido") {
        this.funcs.clickPedido(this);
      } else if (action == "/notaFiscal") {
        this.funcs.clickNotaFiscal(this);
      } else if (action == "/busca/1") {
        this.$router.push(action);
      } else if (action == "clickFinalizar") {
        this.funcs.finalizarConfirm(this);
      } else if (action == "clickEsvaziar") {
        this.funcs.esvaziarConfirmar(this);
      } else if (action == "clickAtual") {
        this.funcs.setAtualConfirmar(this);
      } else if (action == "clickDuplicar") {
        if (this.funcs.duplicar) {
          this.funcs.duplicar(this);
        } else {
          this.clickDuplicar();
        }
      } else if (action == "clickNumerosSerie") {
        this.funcs.clickNumerosSerie(this);
      } else if (action == "clickEnviar") {
        this.funcs.clickEnviar(this);
      } else if (action == "clickCompartilhar") {
        if (this.funcs.clickCompartilhar) {
          this.funcs.clickCompartilhar(this);
        } else {
          this.clickCompartilhar();
        }
      } else if (action == "clickQrcode") {
        this.funcs.qrcodeTimeout();
      } else if (action == "clickCorParceiro") {
        this.funcs.clickCorParceiro();
      } else if (action == "clickAlterarSenha") {
        this.funcs.clickAlterarSenha();
      } else if (action == "clickProdutos") {
        this.funcs.clickProdutos(this);
      } else if (action == "clickPedidos") {
        this.funcs.clickPedidos(this);
      } else if (action == "clickNotasFiscais") {
        this.funcs.clickNotasFiscais(this);
      } else if (action == "clickAssistenciasTecnicas") {
        this.funcs.clickAssistenciasTecnicas(this);
      } else if (action == "clickContasPagRecAssociar") {
        this.funcs.clickContasPagRecAssociar(this);
      } else if (action == "clickValoresPagRec") {
        this.funcs.clickValoresPagRec(this);
      } else if (action == "clickContasPagRec") {
        this.funcs.clickContasPagRec(this);
      } else if (action == "clickKardex") {
        this.funcs.clickKardex(this);
      } else if (action == "clickRelatorioCheque") {
        this.funcs.clickRelatorioCheque();
      } else if (action == "clickAssociarLotes") {
        this.funcs.clickAssociarLotes(this);
      } else if (action == "clickImprimir") {
        this.funcs.clickImprimir(this);
      } else if (action == "clickModerar") {
        this.funcs.moderar(this);
      } else if (action == "clickBaixaCruzada") {
        this.funcs.clickBaixaCruzada(this);
      } else if (action == "clickRelatorioCartaAnuencia") {
        this.funcs.clickRelatorioCartaAnuencia();
      } else if (action == "clickRelatorioNotaDebito") {
        this.funcs.clickRelatorioNotaDebito();
      } else if (action == "clickColaboradores") {
        this.funcs.clickColaboradores();
      } else if (action == "clickItens") {
        this.funcs.clickItens();
      } else if (action == "clickOcorrencias") {
        this.funcs.clickOcorrencias();
      } else if (action == "clickVerbas") {
        this.funcs.clickVerbas();
      } else if (action == "clickApontamentos") {
        this.funcs.clickApontamentos();
      } else if (action == "clickConciliar") {
        this.funcs.clickConciliar();
      } else if (action == "/pessoa") {
        this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
      } else if (action == "clickAnexos") {
        const description = this.getTabela(this.page.idTabela).description;
        let descriptionField =
          this.getValorTraduzido(this.dataset.main.detail, description) ||
          this.getValorTraduzido(this.dataset.aux.detail, description) ||
          this.page.nomeAlt;

        if (typeof descriptionField == "object") {
          descriptionField =
            descriptionField[this.getVar("locale")] ||
            Object.values(descriptionField).reduce(
              (acc, value) => acc || value
            );
        }

        const url = `/anexos/${this.page.idTabela}/${
          this.dataset.main.detail.id
        }/${encodeURIComponent(descriptionField)}`;
        this.$router.push({
          path: url,
          query: { page: this.page },
        });
      } else if (action == "clickDeletar") {
        this.deleteConfirm();
      } else if (action == "clickRecuperar") {
        this.delete(
          this.dataset.main.detail.id,
          this.getTipo_item("eventoRecuperado").id
        );
      } else if (action == "clickLogs") {
        let registro =
          this.dataset.main.detail[
            this.getTabela(this.page.idTabela).description
          ] || this.page.nome;

        registro =
          typeof registro == "object"
            ? registro[this.getVar("locale")] ||
              Object.values(registro).reduce(
                (acc, value) => (acc = acc || value)
              )
            : registro;

        const url = `/logs/0/${encodeURIComponent(
          this.getTabela(this.pageAlt.nome).id
        )}/${this.dataset.main.detail.id}/${encodeURIComponent(registro)}`;

        this.$router.push(url);
      } else if (action == "clickAjuda") {
        const paginaDocumento = this.getPagina_documentos(
          this.getPagina("ajuda")?.id
        ).find(
          (paginaDocumento) =>
            paginaDocumento.options.feature &&
            this.getFeature(paginaDocumento.options.feature)?.id ==
              this.pageAlt.idFeature
        );

        const url = paginaDocumento ? `/ajuda#${paginaDocumento.id}` : "/ajuda";
        this.$router.push(encodeURI(url));
      }
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? []
        : Array(this.panels.length)
            .fill()
            .map((_, i) => i);
    },

    clickPanelsDouble() {
      this.defaultParams.clickPanelsOpen = !this.subPanelsOpen;
      setTimeout(() => {
        this.defaultParams.clickPanelsOpen = null;
      }, 10);
    },

    async clickSave() {
      try {
        this.$emit("input", this.dataset);

        if (this.funcs.preValidate) {
          await this.funcs.preValidate(this);
        }

        await this.validate();

        if (this.funcs.posValidate) {
          await this.funcs.posValidate(this);
        }

        this.save();
      } catch (err) {
        if (err) {
          this.setSnackbar(err.show, err.title, err.text, err.color);
        }
      }
    },

    async clickSearch() {
      if (this.getVar("logout")) {
        return false;
      }

      const method = "get";

      const pageNome =
        this.page.id == this.getPagina("avaliacao").id
          ? "avaliacoes"
          : this.pageAlt.nome;

      let url = `${this.getConst("app").baseUrlBackend}/${pageNome}/${
        this.$route.params.id || (this.dataset.main.detail.idTipo || 0) * -1
      }/${this.getVar("idPessoaParceiro")}/${this.getVar("idUsuario")}`;

      if (this.funcs.fnParams) {
        const params = this.funcs.fnParams();
        url += `/${params}`;
      }

      const dataset = null;
      const res = await this.submit(method, url, dataset);

      if (res) {
        this.dataset = res.data;
        const datasetsIds = this.getVar("datasetsIds");

        if (datasetsIds?.length > 0) {
          let datasetsSelectedIndex = datasetsIds.findIndex(
            (id) => this.dataset.main.detail.id == id
          );

          if (datasetsSelectedIndex >= 0) {
            this.setVar({ datasetsSelectedIndex });
          }
        }

        if (
          !this.dataset.main.detail.id &&
          "idPessoaParceiro" in this.dataset.main.detail
        ) {
          this.dataset.main.detail.idPessoaParceiro =
            this.getVar("idPessoaParceiro");

          if (this.dataset.aux.detail.pessoaParceiro) {
            this.dataset.aux.detail.pessoaParceiro.nomeAlt =
              this.dadosUsuario.pessoa.nomeAlt;
          } else {
            this.dataset.aux.detail.pessoaParceiro_nomeAlt =
              this.dadosUsuario.pessoa.nomeAlt;
          }
        }

        if (this.funcs.posSubmit) {
          this.funcs.posSubmit(this);
        }

        this.$emit("input", this.dataset);
      }
    },

    clickSnackbar(event) {
      this.funcs.clickSnackbar(event, this);
    },

    clickSuspenderLiberar() {
      const method = "post";
      const url = `${this.getConst("app").baseUrlBackend}/suspenderLiberar`;
      const dataset = {
        metadados: {
          idUsuario: this.getVar("idUsuario"),
          idPessoaParceiro: this.getVar("idPessoaParceiro"),
          idEvento: this.getTipo_item(
            !this.dataset.main.detail.isSuspenso
              ? "eventoSuspenso"
              : "eventoLiberado"
          ).id,
        },
        main: {
          detail: {
            id: this.dataset.main.detail.id,
            isSuspenso: !this.dataset.main.detail.isSuspenso,
          },
        },
        aux: {
          detail: {
            idTabela: this.page.idTabela,
          },
        },
      };

      const ret = this.submit(method, url, dataset);

      if (ret) {
        this.dataset.main.detail.isSuspenso =
          !this.dataset.main.detail.isSuspenso;
      }
    },

    async datasetsRouter(direction, max = false) {
      const datasetsIds = this.getVar("datasetsIds");
      let datasetsSelectedIndex = this.getVar("datasetsSelectedIndex");

      if (direction == "left") {
        if (max) {
          datasetsSelectedIndex = datasetsIds.length - 1;
        } else {
          datasetsSelectedIndex++;
        }
      } else if (direction == "right") {
        if (max) {
          datasetsSelectedIndex = 0;
        } else {
          datasetsSelectedIndex--;
        }
      }

      if (
        datasetsSelectedIndex >= 0 &&
        datasetsSelectedIndex <= datasetsIds.length - 1
      ) {
        const goBack = this.getVar("goBack") - 1;
        this.setVar({ goBack });
        this.setVar({ datasetsSelectedIndex });

        const routeName = this.$route.name.split("/");
        const idIndex = routeName.findIndex((el) => el == "id");

        if (this.dataset.main.detail.id == datasetsIds[datasetsSelectedIndex]) {
          await this.datasetsRouter(direction);
          return;
        }

        const toTemp = this.$route.path.split("/");
        toTemp[idIndex + 1] = datasetsIds[datasetsSelectedIndex];
        const to = toTemp.join("/");

        this.$router.push(to);
      }
    },

    async delete(id, idEvento = this.getTipo_item("eventoDeletado").id) {
      this.setSnackbar();
      const method = "delete";
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const pageNome =
        this.page.id == this.getPagina("avaliacao").id
          ? "avaliacoes"
          : this.pageAlt.nome;
      const params = {
        xmin: this.dataset.main.detail.xmin,
      };
      const url = `${
        this.getConst("app").baseUrlBackend
      }/${pageNome}/${id}/${idEvento}/${idPessoaParceiro}/${idUsuario}/${JSON.stringify(
        params
      )}`;
      const dataset = null;

      const res = await this.submit(method, url, dataset);

      if (res) {
        this.dataset.main.detail.idEstado = parseInt(res.data);
        if (this.funcs.posSubmit) {
          this.funcs.posSubmit(this);
        }

        this.setVar({ detailUpdated: true });
      }
    },

    deleteConfirm() {
      this.setSnackbar(
        true,
        "DELETAR",
        `Deletar ${this.page.nomeAlt}?`,
        "error",
        "mdi-check",
        "delete"
      );
    },

    async favoritar() {
      if (!this.getVar("idPessoaParceiro")) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      const params = {
        id: this.dataset.main.detail.id,
        idPage: this.idPage,
        idUsuario: this.getVar("idUsuario"),
        idPessoaParceiro: this.getVar("idPessoaParceiro"),
        favorito: !(this.dataset?.main?.favoritos || []).some(
          (favorito) => favorito.idUsuario == this.getVar("idUsuario")
        ),
        complete: true,
      };

      const url = `${this.getConst("app").baseUrlBackend}/favoritos/favoritar`;

      const res = await this.submit("post", url, params);

      if (res) {
        this.clickSearch();
      }
    },

    panelTest(idPanel) {
      return !!this.panels.find((panel) => panel.idFeature == idPanel);
    },

    async save(modeView = true) {
      this.dataset.metadados.idUsuario = this.getVar("idUsuario");
      this.dataset.metadados.idPessoaParceiro = this.getVar("idPessoaParceiro");
      this.dataset.metadados.idEvento = this.getTipo_item(
        this.idPageMode == this.getConst("config").pageMode.insert.id
          ? "eventoIncluido"
          : "eventoAlterado"
      ).id;
      const dataset = this.dataset;
      const method = this.dataset.main.detail.id ? "put" : "post";
      const pageNome =
        this.page.id == this.getPagina("avaliacao").id
          ? "avaliacoes"
          : this.pageAlt.nome;
      const url = `${this.getConst("app").baseUrlBackend}/${pageNome}`;
      const res = await this.submit(method, url, dataset);

      if (res) {
        this.dataset = res.data;

        if (method == "post") {
          this.$router.push(
            `/${this.$route.path.replace(/[/\d]/g, "")}/${
              this.dataset.main.detail.id
            }`
          );
        }

        if (this.funcs.posSubmit) {
          this.funcs.posSubmit(this, true);
        }

        this.$emit("input", this.dataset);

        if (modeView) {
          this.idPageMode = this.getConst("config").pageMode.view.id;
        }

        this.setVar({ detailUpdated: true });
      }
      // else if (this.dataset.main.detail.id) {
      //   setTimeout(() => {
      //     this.clickEditView();
      //   }, 3000);
      // }
    },

    submitForm(event) {
      event.preventDefault();
      return event.key != "Enter";
    },

    swipeRefresh() {
      this.clickSearch();
    },

    syncAux(datasetsAux, moved) {
      const oldIndex = parseInt(moved?.oldIndex ?? -1);
      const newIndex = parseInt(moved?.newIndex ?? -1);

      if (datasetsAux && oldIndex >= 0 && newIndex >= 0) {
        datasetsAux.splice(newIndex, 0, ...datasetsAux.splice(oldIndex, 1));
      }
    },

    async validate() {
      return new Promise((resolve, reject) => {
        if (this.$refs.form.validate()) {
          this.$refs.form.resetValidation();
          resolve();
        } else {
          const err = {
            show: true,
            title: "CAMPOS INVÁLIDOS",
            text: "Verificar campos inválidos.",
            color: "error",
          };
          reject(err);
        }
      });
    },
  },

  /// BEFOREMOUNT
  async beforeMount() {
    this.adjustPanels();
    this.defaultParams = Object.assign(this.defaultParams, this.params);
    this.defaultParams.context = this;
  },

  /// MOUNTED
  async mounted() {
    this.setVar({ overlay: false });

    const rotas = this.getVar("rotas");
    if (rotas.length) {
      rotas[rotas.length - 1].name = this.title;
      rotas[rotas.length - 1].icon = this.icon;
      this.setVar({ rotas });
    }

    if (this.$route.params.id) {
      await this.clickSearch();
    } else if (!this.defaultParams?.isBlank) {
      this.idPageMode = this.getConst("config").pageMode.insert.id;

      if (this.pageAlt.idContexto) {
        this.dataset.main.detail.idTipo = this.pageAlt.idContexto;
      }

      await this.clickSearch();

      //?b remover este trecho após ajuste deste campo no backend.
      if (this.pageAlt.idContexto) {
        this.dataset.main.detail.idTipo = this.pageAlt.idContexto;
      }
    }

    if (this.funcs.posLoad) {
      this.funcs.posLoad(this);
    }

    this.adjustPanelsOpen();
  },
};
</script>