<!-- COD: LYF 20/07/2022 CPublicacaoView -->
<!-- *VER: JER 20/07/2022 CPublicacaoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- produto marca -->
    <v-row v-bind="prop.row" v-if="produtoMarca">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ produtoMarca }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- titulo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.titulo[getVar("locale")] }}
        </span>
      </v-col>
    </v-row>

    <!-- data inicio -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">início</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.dataInicio,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- data fim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">fim</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.dataFim,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row
      v-bind="prop.row"
      v-if="
        [dataset.main.detail.idPessoaParceiro, getConst(`app`).id].includes(
          getVar(`idPessoaParceiro`)
        )
      "
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">data</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.data,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- recorrencia -->
    <v-row
      v-bind="prop.row"
      v-if="
        [dataset.main.detail.idPessoaParceiro, getConst(`app`).id].includes(
          getVar(`idPessoaParceiro`)
        )
      "
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">recorrência</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.recorrencia }}
        </span>
        <span class="ml-1 text-body-2">h</span>
      </v-col>
    </v-row>

    <!-- hasNotificacao -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.hasNotificacao &&
        [dataset.main.detail.idPessoaParceiro, getConst(`app`).id].includes(
          getVar(`idPessoaParceiro`)
        )
      "
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">notificação</v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPublicacaoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    produtoMarca() {
      return this.dataset.aux.autocomplete.produtos[0]
        ? this.dataset.aux.autocomplete.produtos[0].text
        : this.dataset.main.detail.marca || "";
    },
  },
};
</script>