// COD: FDS 10/08/2022 mixinData
// #VER: JER __/08/2022 mixinData
// 2?

import {
  addDays, addHours, addMinutes, addMonths, addYears, differenceInCalendarMonths, differenceInDays, differenceInMilliseconds, differenceInMinutes, differenceInMonths, format, getDay, getHours, getMinutes, getMonth, hoursToMinutes, isBefore, lastDayOfMonth, max, min, parseISO, setMinutes, setHours, startOfMonth, subDays, subMinutes, subMonths, subYears
} from "date-fns";
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "mixinData",

  /// DATA
  data() {
    return {
      // sem -feira: data.split("-")[0]
      // 3 primeiras letras: data.substring(0,3)
      // 1 primeira letra: data.substring(0,1))
      // minúsculas: data.toLowerCase()
      diasSemana: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado"
      ],
      mesesAno: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    }
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade_configuracoesRegionais: "localidades/getLocalidade_configuracoesRegionais",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    mesesAnoAbreviado() {
      const { format } = new Intl.DateTimeFormat(this.$vuetify.lang.current, { month: "short" });
      return [...Array(12).keys()].map((n) => {
        return {
          value: n,
          text: format(new Date(Date.UTC(0, n + 1)))
            .firstLetterToUpperCase()
            .replace(".", ""),
        };
      });
    },
  },

  /// METHODS
  methods: {
    addDays,

    addHours,

    addMinutes,

    addMonths,

    addYears,

    dateFormat(date, mask, idPais, timezone = false, locale, options = {}) {
      /*
        mascara = 'date' => usa a mascara da configuração regional do país
        mascara = 'dateNoYear' => usa a mascara da configuração regional do país sem o ano
        mascara = 'time' => usa a mascara 'HH:mm:ss'
        mascara = 'timeNoSecond' => usa a mascara 'HH:mm'
        mascara contendo '*' indica que é para usar o simbolo da mascara da configuração regional do país
      */

      const paramOptions = !!Object.keys(options).length;

      if (!timezone) {
        options.timeZone = 'UTC';
      }

      if (date) {
        try {
          if (mask.includes("date") || mask.includes("time") || mask.includes("extenso")) {
            if (!paramOptions) {
              if (typeof date == "string") {
                date = this.parseISO(date);
              }

              if (mask.includes("extenso")) {
                options.month = 'long';
                options.day = '2-digit'

                if (!mask.includes("extensoNoYear")) {
                  options.year = 'numeric';
                }
              }

              if (mask.includes("date")) {
                options.day = '2-digit'

                if (!mask.includes("dateNoMonth")) {
                  options.month = '2-digit';
                }

                if (mask.includes("dateWeekday")) {
                  options.weekday = 'short';
                }

                if (!mask.includes("dateNoYear") && !mask.includes("dateNoMonth")) {
                  options.year = 'numeric';
                }
              }

              if (mask.includes("time")) {
                options.hour = '2-digit';
                options.minute = '2-digit'

                if (!mask.includes("timeNoSecond")) {
                  options.second = '2-digit';
                }
              }
            }

            idPais = idPais ? this.getVal(idPais) : this.getVar("idPaisConfiguracaoRegional")
            locale = locale || this.localeByPais(idPais)

            return locale ? new Intl.DateTimeFormat(locale, options).format(date) : date
          } else if (mask == "relative") {
            return this.dataFormataRelativa(date)
          } else {
            const tamanho = timezone ? 24 : 23
            return date.trim().length ? format(parseISO(date.substring(0, tamanho)), mask) : ""
          }
        } catch (err) {
          return ""
        }
      } else {
        return ""
      }

      // const tamanho = comTimezone ? 24 : 23
      // var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric" };

      // if (data) {
      //   if (mascara.includes("date") || mascara.includes("dateNoYear") || mascara.includes("*")) {
      //     const mascaraRegional = this.getLocalidade_configuracoesRegionais(
      //       this.getVar("idPaisConfiguracaoRegional"),
      //       this.getTipo_item('configuracaoRegionalData').id
      //     )[0].mascara

      //     // const simboloData = mascara.indexOf("y") == 0 ? mascara[4] : mascara[mascara.length - 5]
      //     const simboloData = mascaraRegional.includes('-') ? '-' : mascaraRegional.includes('.') ? '.' : '/'

      //     if (mascara.includes("dateNoYear")) {
      //       mascara = mascara
      //         .replace("dateNoYear", mascaraRegional)
      //         .replace(`${simboloData}yyyy`, "")
      //         .replace(`yyyy${simboloData}`, "")
      //     } else {
      //       mascara = mascara
      //         .replace("date", mascaraRegional)
      //     }

      //     mascara = mascara
      //       .replaceAll("*", simboloData)
      //   }

      //   mascara = mascara
      //     .replace("timeNoSecond", "HH:mm")
      //     .replace("time", "HH:mm:ss")

      //   return data.trim().length ? format(parseISO(data.substring(0, tamanho)), mascara) : ""
      // } else {
      //   return ""
      // }
    },

    //? deprecar
    dataFormata(date, mask, timezone = false, idPais, locale, options = {}) {
      return this.dateFormat(date, mask, idPais, timezone, locale, options);
    },

    //?e Resolver esta função de acordo com o dataFormata
    dataFormataExtenso(data) {
      let dataExtenso = ""
      dataExtenso = `${this.diasSemana[getDay(parseISO(data))]}, ${this.dataFormata(data, "dd", false)} de ${this.mesesAno[getMonth(parseISO(data))]}`
      return dataExtenso
    },

    dataFormataRelativa(data, dataReferencia) {
      let dataRelativa = ""
      if (data) {
        const dataRef = dataReferencia ? parseISO(dataReferencia) : new Date()
        let tempo = differenceInMinutes(dataRef, parseISO(data))
        let tempoTemp

        if (tempo < 0) {
          // data futura
          dataRelativa = this.dataFormata(data, "date time", false)
        } else {
          if (tempo < 3) {
            // agora
            dataRelativa = "agora"
          } else {
            if (tempo < 60) {
              // minutos
              dataRelativa = `${tempo} minutos`
            } else {
              tempo = Math.floor(tempo / 60)

              if (tempo < 24) {
                // horas
                dataRelativa = `${tempo} ${tempo > 1 ? 'horas' : 'hora'}`
              } else {
                tempo = Math.floor(tempo / 24)

                if (tempo < 2) {
                  // ontem (Ugo pediu para retornar numero de dias)
                  // dataRelativa = `ontem ${this.dataFormata(data, "HH:mm", false)}`
                  // dias
                  dataRelativa = `${tempo} dia`
                } else {
                  if (tempo < 7) {
                    // esta semana (Ugo pediu para retornar numero de dias)
                    // dataRelativa = `${this.diasSemana[getDay(parseISO(data))].substring(0, 3).toLowerCase()} ${this.dataFormata(data, "HH:mm", false)}`
                    dataRelativa = `${tempo} dias`
                  } else {
                    tempoTemp = tempo
                    tempo = Math.floor(tempo / 7)

                    if (tempo < 5) {
                      // semanas
                      dataRelativa = `${tempo} ${tempo > 1 ? 'semanas' : 'semana'}`
                    }
                    else {
                      tempo = tempoTemp
                      tempo = Math.floor(tempo / 30)

                      if (tempo < 12) {
                        // meses
                        dataRelativa = `${tempo} ${tempo > 1 ? 'meses' : 'mês'}`
                      }
                      else {
                        // anos
                        tempo = Math.floor(tempo / 12)
                        dataRelativa = `${tempo} ${tempo > 1 ? 'anos' : 'ano'}`
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return dataRelativa
      }
    },

    differenceInCalendarMonths,

    differenceInDays,

    differenceInHoursMinutes(dateLeft, dateRight) {
      const totalMinutes = differenceInMinutes(dateLeft, dateRight)
      let hours = Math.floor(totalMinutes / 60)
      hours = hours < 10 ? `0${hours}` : `${hours}`
      let minutes = totalMinutes - (parseInt(hours) * 60)
      minutes = minutes < 10 ? `0${minutes}` : `${minutes}`

      return `${hours}:${minutes}`
    },

    differenceInMilliseconds,

    differenceInMinutes,

    differenceInMonths,

    format,

    getDay,

    getHours,

    getMinutes,

    isBefore,

    horaFormata(hora, tamanho) {
      return hora ? hora.substring(0, tamanho) : ""
    },

    hoursToMinutes,

    lastDayOfMonth,

    maxDateValue: max,

    minDateValue: min,

    minutesToHours(value) {  // value in minutes
      const hoursMinutes = value / 60;
      const hours = Math.floor(hoursMinutes);
      const minutes = Math.round((hoursMinutes - hours) * 60).toString().padStart(2, "0");
      return `${hours}:${minutes}`
    },

    parseISO,

    startOfMonth,

    setMinutes,

    setHours,

    subDays,

    subMinutes,

    subMonths,

    subYears,
  },
}