<!-- COD: LYF 01/07/2022 ContasContabeis -->
<!-- *VER: JER 05/07/2022 ContasContabeis -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-contasContabeis :dataset="dataset" />
    </c-base-list-item>

    <c-contaContabil-dialog-relatorio-diario-contabil
      v-if="showRelatorioDiarioContabil"
      v-model="showRelatorioDiarioContabil"
      :contaPagRec="dataset"
    />
  </c-base-list>
</template>

<script>
/// IMPORT
import CContasContabeis from "@/components/pages/contaContabil/CContasContabeis";
import CContaContabilDialogRelatorioDiarioContabil from "@/components/pages/contaContabil/relatorio/CContaContabilDialogRelatorioDiarioContabil";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "ContasContabeis",

  /// COMPONENTS
  components: {
    CContasContabeis,
    CContaContabilDialogRelatorioDiarioContabil,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("contasContabeis").id,
      idPageAlt: getPagina("contaContabil").id,
      showRelatorioDiarioContabil: false,

      funcs: {
        clickRelatorioDiarioContabil: async () => {
          this.showRelatorioDiarioContabil = true;
        },

        fnParams: () => {
          return JSON.stringify(this.params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      params: {},
    };
  },
};
</script>