<!-- COD: BJB 26/07/2022 Produtos_InventarioDiferencas -->
<!-- *VER: JER 28/07/2022 Produtos_InventarioDiferencas -->
<!-- /32/ -->

<template>
  <c-base-list
    v-if="idPage && idPageAlt"
    :funcs="funcs"
    :idFeature="idFeature"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
    :pageModeEdit="true"
  >
    <template #listContent="{ context, datasets }">
      <c-expansion-panels
        flat
        :pageModeView="false"
        :value="context.datasetList.dataset.main.produtos"
      >
        <template #header="{ dataset: produto }">
          <!-- produto -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-body-1">
                {{ produtoFormat(produto, false) }}
              </span>
            </v-col>
          </v-row>

          <!-- inventario -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-body-2">inventário</span>
              <span class="ml-0 text-body-1">
                {{ getProdutoInventario(produto, datasets) }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template #content="{ dataset: produto }">
          <c-produto-page-inventario-diferenca-edit
            :context="context"
            :datasets="datasets"
            :produto="produto"
          />
        </template>
      </c-expansion-panels>
    </template>
    <template #bottom="{ context }">
      <v-row v-bind="prop.row" v-if="showGerarPedido(context)">
        <v-col v-bind="prop.col" align="center">
          <c-btn
            classe="primary--text mt-4"
            text
            @click="pedidosAcertoConfirm(context)"
          >
            gerar pedidos de acerto
          </c-btn>
        </v-col>
      </v-row>
    </template>
  </c-base-list>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CProdutoPageInventarioDiferencaEdit from "@/components/pages/produto/pages/CProdutoPageInventarioDiferencaEdit";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Produtos_InventarioDiferencas",

  /// COMPONENTS
  components: {
    CProdutoPageInventarioDiferencaEdit,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idFeature: getFeature("optionDiferencas").id,
      idPage: getPagina("produtosInventario").id,
      idPageAlt: getPagina("produtos").id,

      funcs: {
        changed: (context) => {
          return context.datasets.some((dataset, i) =>
            Object.keys(dataset).some(
              (key) =>
                JSON.stringify(dataset[key]) !=
                JSON.stringify(context.datasetsTemp[i][key])
            )
          );
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        async clickSave(context) {
          const metadados = {
            modo: context.options.click.getConst("config").modo.cadastro.id,
            idUsuario: context.options.click.getVar("idUsuario"),
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
          };

          const datasetsSave = {
            metadados,
            details: context.options.click.datasets.filter((dataset, i) =>
              Object.keys(dataset).some(
                (key) =>
                  dataset[key] != context.options.click.datasetsTemp[i][key]
              )
            ),
          };

          if (datasetsSave.details.length) {
            datasetsSave.details = datasetsSave.details.map((dataset) => {
              return {
                id: dataset.id,
                caixas: parseInt(dataset.caixas),
                unidades: parseFloat(dataset.unidades),
                idColaborador: metadados.idUsuario,
              };
            });

            const method = "put";
            let url = `${
              context.options.click.getConst("app").baseUrlBackend
            }/${context.options.click.page.nome}`;
            const res = await context.options.click.submit(
              method,
              url,
              datasetsSave
            );

            if (res) {
              context.options.click.clickSearch();
            }
          }
        },

        clickSnackbar: (action, context) => {
          if (action == "pedidosAcerto") {
            this.gerarPedidosAcerto(context);
          } else if (action == "pedidos") {
            this.listPedidos(context);
          }

          context.setSnackbar();
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          const params = {};
          params.idColaborador = this.getVar("idUsuario");

          return JSON.stringify(params);
        },
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getLocalidade: "localidades/getLocalidade",
      getOperacao: "operacoes/getOperacao",
      getTipo_item: "tipos/getTipo_item",
    }),
  },

  /// METHODS
  methods: {
    async gerarPedidosAcerto(context) {
      const metadados = {
        modo: this.getConst("config").modo.cadastro.id,
        idEvento: this.getTipo_item("eventoIncluido").id,
        idUsuario: this.getVar("idUsuario"),
        idPessoaParceiro: this.getVar("idPessoaParceiro"),
      };

      let datasetsSave = {
        metadados,
        details: [],
      };

      //Detalhes do endereço para o pedido
      const enderecoObservacao = "estoque";

      const enderecoEndereco =
        this.getVar("usuarios").find(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        ).enderecos[0].endereco || "";

      const enderecoCidade =
        this.getLocalidade(
          this.getVar("usuarios").find(
            (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
          ).enderecos[0].idCidade
        ).nome || "";

      const produtos = [...new Set(context.datasets.map((ds) => ds.idProduto))];

      context.datasets.forEach((dataset) => {
        dataset.diferenca = this.getValorDiferenca(dataset);
      });

      const produtosSaida = context.datasets
        .filter(
          (ds) =>
            produtos.find((produto) => produto == ds.idProduto) ==
              ds.idProduto && ds.diferenca > 0
        )
        .map((produto) => {
          return {
            id: null,
            idPai: null,
            idProduto: produto.idProduto,
            quantidade: produto.diferenca,
            preco: produto.custoMedio || produto.preco,
            cupom: null,
            observacao: null,
            idCarrinhoItem: null,
            idColaborador: metadados.idUsuario,
          };
        });

      const produtosEntrada = context.datasets
        .filter(
          (ds) =>
            produtos.find((produto) => produto == ds.idProduto) ==
              ds.idProduto && ds.diferenca < 0
        )
        .map((produto) => {
          return {
            id: null,
            idPai: null,
            idProduto: produto.idProduto,
            quantidade: produto.diferenca * -1,
            preco: produto.custoMedio || produto.preco,
            cupom: null,
            observacao: null,
            idCarrinhoItem: null,
            idColaborador: metadados.idUsuario,
          };
        });

      //Pedido de saída
      if (produtosSaida.length > 0) {
        const valorTotalSaida = produtosSaida.reduce((acc, prod) => {
          return (acc += parseFloat(prod.preco) * prod.quantidade);
        }, 0);

        datasetsSave.details.push({
          detail: {
            id: null,
            idTipo: this.getTipo_item("pedidoSaida").id,
            idEstado: this.getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: metadados.idPessoaParceiro,
            isFechado: false,
            data: new Date(),
            idOrigem: this.getTipo_item("pedidoOrigemErp").id,
            idOperacao: this.getOperacao("estoqueQuantidadeReducao").id,
            idPessoa: metadados.idPessoaParceiro,
            idLocalEntrega: null,
            idUsuario: metadados.idUsuario,
            idConvite: null,
            hasNotaFiscal: false,
            isEntregar: false,
            idPessoaEndereco: null,
            enderecoObservacao: enderecoObservacao,
            enderecoEndereco: enderecoEndereco,
            enderecoCidade: enderecoCidade,
            idPessoaTransportadora: null,
            transportadora: null,
            idModalTransporte: null,
            idIncoterm: null,
            dataPrevisaoEntrega: new Date(),
            volumes: null,
            totalPesoLiquido: null,
            totalPesoBruto: null,
            totalPesoConsolidado: null,
            portadorNome: null,
            portadorDocumento: null,
            portadorPlacaVeiculo: null,
            codigoRastreio: null,
            isPagamentoOnline: false,
            idFormaPagamento: this.getTipo_item("formaPagamentoCarteira").id,
            troco: null,
            idCartao: null,
            idPessoaCartao: null,
            parcelas: 1,
            prazo1: 0,
            prazoN: null,
            prazoMedioPagamento: 0,
            variacao: 0,
            valorFrete: 0,
            valorOutrasDespesas: 0,
            valorImpostos: 0,
            valorTotal: valorTotalSaida,
            valorTotalOriginal: valorTotalSaida,
            valorComissaoRevenda: 0,
            seuPedido: null,
            observacao: null,
            observacoes: null,
            idCarrinho: null,
          },
          itens: produtosSaida,
        });
      }

      //Pedido de entrada
      if (produtosEntrada.length > 0) {
        const valorTotalEntrada = produtosEntrada.reduce((acc, prod) => {
          return (acc += parseFloat(prod.preco) * prod.quantidade);
        }, 0);

        datasetsSave.details.push({
          detail: {
            id: null,
            idTipo: this.getTipo_item("pedidoEntrada").id,
            idEstado: this.getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: metadados.idPessoaParceiro,
            isFechado: false,
            data: new Date(),
            idOrigem: this.getTipo_item("pedidoOrigemErp").id,
            idOperacao: this.getOperacao("estoqueQuantidadeAumento").id,
            idPessoa: metadados.idPessoaParceiro,
            idUsuario: metadados.idUsuario,
            idConvite: null,
            hasNotaFiscal: false,
            isEntregar: false,
            idPessoaEndereco: null,
            enderecoObservacao: enderecoObservacao,
            enderecoEndereco: enderecoEndereco,
            enderecoCidade: enderecoCidade,
            idPessoaTransportadora: null,
            transportadora: null,
            idModalTransporte: null,
            idIncoterm: null,
            dataPrevisaoEntrega: new Date(),
            volumes: null,
            totalPesoLiquido: null,
            totalPesoBruto: null,
            totalPesoConsolidado: null,
            portadorNome: null,
            portadorDocumento: null,
            portadorPlacaVeiculo: null,
            codigoRastreio: null,
            isPagamentoOnline: false,
            idFormaPagamento: this.getTipo_item("formaPagamentoCarteira").id,
            troco: null,
            idCartao: null,
            idPessoaCartao: null,
            parcelas: 1,
            prazo1: 0,
            prazoN: null,
            prazoMedioPagamento: 0,
            variacao: 0,
            valorFrete: 0,
            valorOutrasDespesas: 0,
            valorImpostos: 0,
            valorTotal: valorTotalEntrada,
            valorTotalOriginal: valorTotalEntrada,
            valorComissaoRevenda: 0,
            seuPedido: null,
            observacao: null,
            observacoes: null,
            idCarrinho: null,
          },
          itens: produtosEntrada,
        });
      }

      const method = "post";
      const url = `${this.getConst("app").baseUrlBackend}/pedidos/all`;
      const res = await context.submit(method, url, datasetsSave);

      const numeroPedidos = datasetsSave.details.length;

      const mensagem =
        numeroPedidos == 1
          ? `${numeroPedidos} pedido gerado. Listar pedidos?`
          : `${numeroPedidos} pedidos gerados. Listar pedidos?`;

      if (res) {
        this.pedidoGerado(context, mensagem);
      }
    },

    getProdutoInventario(produto, datasets) {
      return this.numberFormat(
        datasets
          .filter((dataset) => dataset.idProduto == produto.idProduto)
          .reduce(
            (acc, dataset) =>
              parseFloat((acc += this.getValorInventario(dataset))),
            0
          ),
        "decimal"
      );
    },

    getValorDiferenca(dataset) {
      return this.getValorInventario(dataset) - dataset.estoqueProprio;
    },

    getValorInventario(dataset) {
      return (
        parseFloat(dataset.caixas || 0) *
          parseFloat(dataset.unidadesPorCaixa || 0) +
        parseFloat(dataset.unidades || 0)
      );
    },

    async pedidoGerado(context, mensagem) {
      await context.setSnackbar(
        true,
        "PEDIDOS",
        mensagem,
        "success",
        "mdi-check",
        "pedidos",
        null,
        false
      );
    },

    listPedidos(context) {
      context.$router.push("/pedidos");
    },

    pedidosAcertoConfirm(context) {
      context.setSnackbar(
        true,
        "DIFERENÇAS",
        "Gerar pedidos?",
        "warning",
        "mdi-check",
        "pedidosAcerto",
        this.getConst("config").snackbar.timeout
      );
    },

    showGerarPedido(context) {
      const perfisApropriados = [
        this.getTipo_item("perfilAdmin").id,
        this.getTipo_item("perfilLogistica").id,
      ];

      const niveisApropriados = [
        this.getTipo_item("nivelPresidente").id,
        this.getTipo_item("nivelDiretor").id,
        this.getTipo_item("nivelGerente").id,
        this.getTipo_item("nivelSupervisor").id,
      ];

      const HasAutorizacao = this.getVar("usuarios")
        .find((usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro"))
        ?.perfis.some(
          (perfil) =>
            perfisApropriados.includes(perfil.idPerfil) &&
            niveisApropriados.includes(perfil.idNivel)
        );

      return (
        HasAutorizacao &&
        !context.changed &&
        context.datasetsAll.some(
          (dataset) => this.getValorDiferenca(dataset) > 0
        )
      );
    },
  },
};
</script>