var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{attrs:{"funcs":_vm.funcsDetail,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
return [_c('c-valorPagRec-view',{attrs:{"context":context,"dataset":_vm.dataset}}),_c('v-divider',_vm._b({},'v-divider',_vm.prop.divider,false))]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}),(_vm.loadList)?_c('c-base-list',{attrs:{"funcs":_vm.funcsList,"idFeature":_vm.cIdFeature,"idPage":_vm.idPageList,"idPageAlt":_vm.idPage,"pageModeEdit":true},scopedSlots:_vm._u([{key:"insert",fn:function(ref){
var context = ref.context;
return [(context.datasets.length)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"red--text text-body-2"},[_vm._v(" selecione as contas a associar ")])])],1)],1):_vm._e(),(context.datasets.length)?_c('v-row',{staticClass:"sticky mt-3",attrs:{"justify":"center"}},[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('div',{staticClass:"white",staticStyle:{"height":"65%"}},[_c('c-text-money',{attrs:{"classe":"text-h5 font-weight-light","clearable":false,"color":"green","label":"saldo a associar","max":15,"options":{
                length: 11,
                precision: 2,
                style: 'currency',
              },"outlined":"","readonly":"","success":_vm.getTotais(context).saldo == 0,"value":_vm.getTotais(context).saldo}})],1)])],1):_vm._e()]}},{key:"bottom",fn:function(ref){
              var context = ref.context;
return [(context.datasets.length)?_c('div',{staticClass:"mt-5"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{staticClass:"text-h5 font-weight-light",attrs:{"clearable":false,"label":"total selecionado","max":15,"options":{
                length: 11,
                precision: 2,
                style: 'currency',
              },"outlined":"","readonly":"","value":_vm.getTotais(context).totalSelecionado}}),(_vm.getTotais(context).quantidade > 0)?_c('v-badge',{staticClass:"ml-n2 mb-n3",attrs:{"color":"grey","content":_vm.getTotais(context).quantidade,"overlap":""}}):_vm._e()],1)],1)],1):_vm._e()]}},{key:"default",fn:function(ref){
              var context = ref.context;
              var contaPagRec = ref.dataset;
              var index = ref.index;
return [_c('c-valorPagRec-page-contaPagRec-edit',{attrs:{"contaPagRec":contaPagRec,"context":context,"index":index,"saldoAssociar":_vm.saldoAssociar}})]}}],null,false,3116117656)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }