<!-- *COD: FDS __/08/2022 Agendas -->
<!-- VER: JER __/08/2022 Agendas -->
<!-- 3? -->

<template>
  <div
    v-touch="{
      down: ($event) => swipe('down', swipeRefresh, $event),
    }"
    class="calendar"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" :title="page.nomeAlt">
      <template #icon>
        <v-btn icon router to="/">
          <v-icon class="ml-2" large>
            {{ icon }}
          </v-icon>
        </v-btn>
      </template>
    </c-the-navbar>

    <!-- ??? Onde posicionar o snacbar? -->
    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// AGENDAS -->
    <v-container>
      <!-- pessoa nome alt -->
      <v-row v-bind="prop.row" v-if="parceiro.aux" class="my-4">
        <v-col v-bind="prop.col" align="center">
          <v-chip class="primary primary--text text-overline">
            {{ parceiro.aux.detail.pessoa_nomeAlt }}
          </v-chip>
        </v-col>
      </v-row>

      <!-- data agenda -->
      <v-row v-bind="prop.row" v-if="parceiro.aux" class="mt-4">
        <v-col v-bind="prop.col" align="center">
          <c-datetime-picker
            v-if="showPicker"
            v-model="dataAgenda"
            classe="text-h6"
            date
            :disabled="getVar('idUsuario') == getVar('idPessoaParceiro')"
            estilo="width: 160px"
            :event-color="pickerEventsColor"
            :events="
              parceiro.aux.agendas
                .filter((agenda) => agenda.minutos > 0)
                .map((agenda) => agenda.data)
            "
            icon
            :max="addDays(new Date(), 60).toISOString()"
            :min="subDays(new Date(), 365).toISOString()"
            solo
          />
        </v-col>
      </v-row>

      <!-- data agenda -->
      <v-row v-bind="prop.row" v-if="parceiro.aux" class="mt-4">
        <v-col v-bind="prop.col" align="center">
          <c-datetime-picker
            v-if="showPicker"
            v-model="dataAgenda"
            classe="text-h6"
            date
            :disabled="getVar('idUsuario') == getVar('idPessoaParceiro')"
            estilo="width: 160px"
            :event-color="pickerEventsColor"
            :events="
              parceiro.aux.agendas
                .filter((agenda) => agenda.minutos > 0)
                .map((agenda) => agenda.data)
            "
            icon
            :max="addDays(new Date(), 60).toISOString()"
            :min="subDays(new Date(), 365).toISOString()"
            solo
          />
        </v-col>
      </v-row>

      <!-- data agenda -->
      <v-row v-bind="prop.row" v-if="parceiro.aux" class="mt-4">
        <v-col v-bind="prop.col" align="center">
          <v-select
            v-model="colaboradoresSelected"
            :items="colaboradores"
            label="Colaboradores"
            :menu-props="{ maxHeight: '400' }"
            multiple
            prepend-inner-icon="mdi-account-multiple-outline"
            :readonly="!colaboradoresLength"
            @change="changeColaborador()"
          >
            <template #prepend-item>
              <v-list-item ripple @click="selectAll">
                <v-list-item-action>
                  <v-icon
                    :color="colaboradoresSelected.length > 0 ? 'primary' : ''"
                  >
                    {{ iconSelectAll }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>

    <!-- :events="pickerEvents" -->
    <!-- //?b O que é type="category"? -->
    <v-card flat class="no-select">
      <v-calendar
        v-if="atendimento"
        v-model="dataAgenda"
        :categories="categories"
        category-show-all
        :event-color="calendarEventColor"
        :events="events"
        :first-interval="atendimento.horarioInicio"
        :interval-count="cargaAtendimento"
        locale="pt-BR"
        :short-intervals="false"
        :short-weekdays="false"
        type="category"
        @click:event="clickDetail"
        @mousedown:event="startTimerEvent"
        @mousedown:time-category="startTimerTime"
        @mouseleave.native="cancelDrag"
        @mousemove:time-category="mouseMove"
        @mouseup:time-category="endDrag"
        @touchcancel.native="cancelDrag"
        @touchend:time-category="endDrag"
        @touchleave.native="cancelDrag"
        @touchmove:time-category="touchMove"
        @touchstart:event="startTimerEvent"
        @touchstart:time-category="startTimerTime"
      >
        <!-- <template #event="{ event, timed, eventSummary }"> -->
        <template #event="{ event, timed }">
          <!-- <div class="v-event-draggable" v-html="eventSummary()"></div> -->
          <div
            :class="
              getVar('idUsuario') != getVar('idPessoaParceiro') &&
              (dragEvent || extendOriginal)
                ? 'v-event-opacity'
                : ''
            "
          >
            <span v-if="getVar('idUsuario') != getVar('idPessoaParceiro')">
              {{ event.name }}
            </span>
          </div>
          <div
            v-if="timed"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
            @touchstart.stop="extendBottom(event)"
          ></div>
        </template>
      </v-calendar>
    </v-card>
    <v-divider class="mb-12" />
    <c-agenda-dialog-evento
      v-model="showDetail"
      :atendimento="atendimento || {}"
      :colaboradores="
        colaboradores.filter(
          (colaborador) =>
            (!dataset.id ||
              dataset.idColaborador ||
              perfis.includes(getTipo_item('perfilAdmin').id)) &&
            colaboradoresSelected.includes(colaborador.value)
        )
      "
      :dataset="dataset"
      :datasets="datasets"
      @click:delete="deleteConfirm($event)"
      @click:save="save($event)"
    />

    <c-the-navbar bottom mode="page" title="">
      <template v-for="(button, i) in buttons" v-slot:[i]>
        <v-btn
          v-for="(button, y) in buttons"
          icon
          :key="`4.${i}.${y}`"
          @click="clickOption(button)"
        >
          <v-icon large>
            {{ button.options.icon }}
          </v-icon>
        </v-btn>
        <v-menu :key="`5.${i}`" offset-y top>
          <template #activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon large>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, y) in options"
              :key="`5.${i}.${y}`"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </c-the-navbar>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import {
  addDays,
  differenceInDays,
  format,
  getHours,
  getMinutes,
  parseISO,
  subDays,
} from "date-fns";
import CAgendaDialogEvento from "@/components/pages/agenda/evento/CAgendaDialogEvento";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinData from "@/mixins/mixinData";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinTemplate from "@/mixins/mixinTemplate";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "Agendas",

  /// COMPONENTS
  components: {
    CAgendaDialogEvento,
    CTheNavbar,
  },

  /// MIXINS
  mixins: [mixinData, mixinSwipe, mixinTemplate, mixinUsuarios],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("agendas").id,
      idPageAlt: getPagina("agendas").id,
      colaboradoresSelected: [],
      cor: null,
      createEvent: null,
      createStart: null,
      dataAgenda: new Date().toISOString(),
      dataset: {},
      datasets: [],
      dragEvent: null,
      dragStart: null,
      event: {},
      events: [],
      extendOriginal: null,
      idParceiro: null,
      idPessoaParceiro: getVar("idPessoaParceiro"),
      paramColaboradores: [getVar("idUsuario")],
      parceiro: {
        main: {
          atendimentos: [],
          atendimentos_dias: [],
        },
        aux: {
          detail: {
            pessoa_idTipo: null,
            pessoa_nome: null,
            pessoa_nomeAlt: null,
          },
          parceiroApp: {
            percentualComissaoBase: 0,
            percentualComissaoAdicional: 0,
          },
          colaboradoresParceiros: [],
          imagem: {
            id: null,
            endereco: null,
          },
          agendas: [],
        },
      },
      pressTimer: null,
      showDetail: { value: false },
      showPicker: true,
      touchMoving: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getPagina: "paginas/getPagina",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getTipo: "tipos/getTipo",
      getVar: "variaveis/getVar",
    }),

    atendimento() {
      let atendimento = null;

      const diaSemana = this.getDay(this.parseISO(this.dataAgenda));
      const index = this.parceiro.main.atendimentos_dias.findIndex((dias) =>
        dias.includes(diaSemana)
      );

      atendimento =
        index >= 0
          ? JSON.parse(JSON.stringify(this.parceiro.main.atendimentos[index]))
          : null;

      if (
        differenceInDays(this.parseISO(this.dataAgenda), new Date()) < 0 &&
        this.datasets.length
      ) {
        const minDate = new Date(
          Math.min.apply(
            null,
            this.datasets.map((dataset) => this.parseISO(dataset.dataInicio))
          )
        );
        const maxDate = new Date(
          Math.max.apply(
            null,
            this.datasets.map((dataset) => this.parseISO(dataset.dataFim))
          )
        );

        const horarioInicio = format(minDate, "HH:mm:ss");
        const horarioFim = format(maxDate, "HH:mm:ss");

        if (atendimento) {
          atendimento.horarioInicio =
            atendimento.horarioInicio < horarioInicio
              ? atendimento.horarioInicio
              : horarioInicio;
          atendimento.horarioFim =
            atendimento.horarioFim > horarioFim
              ? atendimento.horarioFim
              : horarioFim;
        } else {
          atendimento = {
            horarioInicio,
            horarioFim,
          };
        }
      }

      return atendimento;
    },

    cargaAtendimento() {
      let cargaAtendimento = 0;

      if (this.atendimento) {
        const horaInicio = parseInt(
          this.atendimento.horarioInicio.split(":")[0]
        );
        const horaFim = parseInt(this.atendimento.horarioFim.split(":")[0]);

        cargaAtendimento = horaFim - horaInicio;
      }

      return cargaAtendimento;
    },

    categories() {
      return this.colaboradores
        .filter((colaborador) =>
          this.colaboradoresSelected.includes(colaborador.value)
        )
        .map((category) => this.colaboradorPrimeiroNome(category.text));
    },

    colaboradores() {
      return (
        differenceInDays(this.parseISO(this.dataAgenda), new Date()) >= 0
          ? this.colaboradoresParceiro
          : this.colaboradoresAgenda
      ).filter(
        (colaborador) =>
          this.perfis.some((perfil) =>
            [
              this.getTipo_item("perfilAdmin").id,
              this.getTipo_item("perfilVendas").id,
            ].includes(perfil)
          ) || this.paramColaboradores.includes(colaborador.value)
      );
    },

    colaboradoresAgenda() {
      return [...new Set(this.datasets.map((dataset) => dataset.idColaborador))]
        .filter((idColaborador) => idColaborador)
        .map((idColaborador) => {
          const value = idColaborador;
          const text = this.datasets.find(
            (dataset) => dataset.idColaborador == idColaborador
          ).colaborador;

          return {
            value,
            text,
          };
        })
        .sort((a, b) => (a.text < b.text ? -1 : 1));
    },

    colaboradoresLength() {
      return this.colaboradores.length;
    },

    colaboradoresParceiro() {
      return this.parceiro.aux.colaboradoresParceiros;
    },

    colaboradoresSelectedAll() {
      return this.colaboradoresSelected.length == this.colaboradores.length;
    },

    iconSelectAll() {
      if (this.colaboradoresSelectedAll) {
        return "mdi-checkbox-marked";
      } else if (this.colaboradoresSelected.length > 0) {
        return "mdi-minus-box";
      } else {
        return "mdi-checkbox-blank-outline";
      }
    },

    showDivider() {
      return window.pageYOffset > 0;
    },

    showDetailValue() {
      return this.showDetail.value;
    },
  },

  /// WATCH
  watch: {
    async dataAgenda() {
      await this.clickSearch();
    },

    showDetailValue() {
      if (this.showDetailValue == false) {
        this.changeColaborador();
      }
    },
  },

  /// MOUNTED
  async mounted() {
    this.loadAgendas();

    await this.getParceiro();
    await this.clickSearch();

    const reloadComponents = this.getVar("reloadComponents");
    const component = this.page.nome.firstLetterToUpperCase();

    if (!reloadComponents.includes(component)) {
      reloadComponents.push(component);
      this.setVar({ reloadComponents });
    }
  },

  ///ACTIVATED
  async activated() {
    this.loadAgendas();

    if (this.$route.query.params) {
      await this.getParceiro();
      await this.clickSearch();
    }
  },

  /// METHODS
  methods: {
    addDays,

    differenceInDays,

    format,

    parseISO,

    subDays,

    calendarEventColor(event) {
      if (this.getVar("idUsuario") == this.getVar("idPessoaParceiro")) {
        return "corDisabled";
      } else {
        const dataset = this.datasets.find((dataset) => dataset.id == event.id);

        if (!dataset || !dataset.idColaborador) {
          return "error";
        } else if (dataset.idPedido) {
          return "success";
        } else if (!dataset.cliente) {
          return "warning";
        } else {
          return "primary";
        }
      }
    },

    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.endTimer();
    },

    changeColaborador() {
      this.events = this.datasets
        .filter(
          (dataset) =>
            this.colaboradoresSelected.includes(dataset.idColaborador) ||
            !dataset.idColaborador
        )
        .reduce((acc, dataset) => {
          if (!dataset.idColaborador) {
            this.colaboradoresSelected.forEach((idColaborador) => {
              const event = JSON.parse(JSON.stringify(dataset));
              event.idColaborador = idColaborador;
              event.colaborador = this.colaboradores.find(
                (colaborador) => colaborador.value == idColaborador
              ).text;

              acc.push(event);
            });
          } else {
            acc.push(dataset);
          }

          return acc;
        }, [])
        .map((dataset) => {
          return {
            id: dataset.id,
            name: dataset.cliente || dataset.observacao,
            color: "primary",
            start: this.parseISO(dataset.dataInicio),
            end: this.parseISO(dataset.dataFim),
            timed: true,
            category: this.colaboradorPrimeiroNome(dataset.colaborador),
          };
        });
    },

    clickDetail(event) {
      if (this.getVar("idUsuario") != this.getVar("idPessoaParceiro")) {
        const dataset = this.datasets.find(
          (dataset) => dataset.id == event.event.id
        );
        this.dataset = { ...dataset };

        this.dataset.dataInicio = `${format(
          event.event.start,
          "yyyy-MM-dd"
        )}T${format(event.event.start, "HH:mm:ss")}.000`;
        this.dataset.dataFim = `${format(
          event.event.end,
          "yyyy-MM-dd"
        )}T${format(event.event.end, "HH:mm:ss")}.000`;

        this.showDetail.value = true;
      }
    },

    clickOption(paginaFeature) {
      const action =
        paginaFeature.options?.click || paginaFeature.options?.route;

      if (action == "clickIncluir") {
        const hoje = new Date();
        let hora = getHours(hoje);
        let minuto = getMinutes(hoje);

        if (minuto > 30) {
          minuto = "00";
          hora++;
        } else {
          minuto = "30";
        }

        let horaFim = hora + 1;
        hora = hora < 10 ? `0${hora}` : hora;
        horaFim = horaFim < 10 ? `0${horaFim}` : horaFim;

        const dataset = {
          id: null,
          idPessoaParceiro: this.getVar("idPessoaParceiro"),
          idColaborador: null,
          idProduto: null,
          idPedidoItem: null,
          cliente: null,
          numero: null,
          data: null,
          dataInicio: `${this.dataAgenda}T${hora}:${minuto}:00.000`,
          dataFim: `${this.dataAgenda}T${horaFim}:${minuto}:00.000`,
          observacao: null,
        };

        this.datasets.push(dataset);
        this.dataset = dataset;
        this.showDetail.value = true;
      } else if (action == "clickRelatorios") {
        let url = `/relatorios/${this.page.idTabela}/0/0/*/300/200/*`;

        this.$router.push(encodeURI(url));
      } else if (action == "clickAjuda") {
        const url = `/ajuda#${this.page.nome}`;
        this.$router.push(encodeURI(url));
      }
    },

    async clickSearch(offset = 0) {
      const method = "get";
      const search = this.dataAgenda;
      const idContexto = "*";
      const idFilter = this.getFeature("filterFiltrar").id;
      const idOrder = this.getFeature("orderOrdenar").id;
      const idPessoaParceiro = this.idPessoaParceiro;
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;
      let url = `${this.getConst("app").baseUrlBackend}/${
        this.page.nome
      }/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
        this.limit
      }`;

      const dataset = null;
      const scrollTop = offset == 0;
      const res = await this.submit(method, url, dataset, scrollTop);

      if (res) {
        this.datasets = [...res.data];

        if (this.colaboradoresLength == 1) {
          this.colaboradoresSelected = this.colaboradores.map(
            (colaborador) => colaborador.value
          );
        }

        this.changeColaborador();
      }
    },

    clickSnackbar(action) {
      if (action == "delete") {
        this.delete(this.dataset.id);
      }
    },

    colaboradorPrimeiroNome(nome) {
      return nome ? nome.split(" ")[0] : nome;
    },

    async delete(id, idEvento = this.getTipo_item("eventoDeletado").id) {
      this.setSnackbar();
      if (id) {
        const method = "delete";
        const idUsuario = this.getVar("idUsuario");
        const idPessoaParceiro = this.getVar("idPessoaParceiro");
        const pageNome = "agendas";
        const url = `${
          this.getConst("app").baseUrlBackend
        }/${pageNome}/${id}/${idEvento}/${idPessoaParceiro}/${idUsuario}`;
        const dataset = null;

        await this.submit(method, url, dataset);
      }

      this.datasets.splice(
        this.datasets.findIndex((dataset) => dataset.id == id),
        1
      );

      this.changeColaborador();
      this.showDetail.value = false;

      // if (res) {
      //   this.dataset.detail.idEstado = parseInt(res.data);
      //   if (this.funcs.posSubmit) {
      //     this.funcs.posSubmit(this);
      //   }

      //   this.setVar({ detailUpdated: true });
      // }
    },

    deleteConfirm() {
      if (this.dataset.id) {
        this.setSnackbar(
          true,
          "DELETAR",
          "Deletar evento?",
          "error",
          "mdi-check",
          "delete"
        );
      } else {
        this.delete(this.dataset.id);
      }
    },

    endDrag() {
      if (this.dragEvent || this.createEvent) {
        const event = {
          event: this.dragEvent ? this.dragEvent : this.createEvent,
        };
        this.clickDetail(event);
      }

      // setTimeout(() => {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.touchMoving = false;
      this.endTimer();
      // }, 10);
    },

    endTimer() {
      if (this.pressTimer !== null) {
        clearTimeout(this.pressTimer);
        this.pressTimer = null;
      }
    },

    extendBottom(event) {
      const dataset = this.datasets.find((dataset) => dataset.id == event.id);

      if (
        this.getVar("idUsuario") == this.getVar("idPessoaParceiro") ||
        dataset.idPedido ||
        (!dataset.idColaborador &&
          !this.perfis.includes(this.getTipo_item("perfilAdmin").id))
      ) {
        return;
      }

      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },

    async getParceiro() {
      const method = "get";
      const pageNome = "parceiros";
      let url = `${this.getConst("app").baseUrlBackend}/${pageNome}/${
        this.idParceiro
      }`;
      const dataset = null;

      const res = await this.submit(method, url, dataset);

      if (res) {
        this.parceiro = res.data;
      }
    },

    loadAgendas() {
      if (this.$route.query.params) {
        this.showPicker = false;

        this.idParceiro = this.$route.query.params.idParceiro;
        this.idPessoaParceiro = this.$route.query.params.idPessoaParceiro;
        this.dataAgenda = this.$route.query.params.dataAgenda;
        this.paramColaboradores =
          this.$route.query.params.colaboradores.slice();
        this.colaboradoresSelected =
          this.$route.query.params.colaboradores.slice();

        setTimeout(() => {
          this.showPicker = true;
        }, 10);
      } else {
        this.idPessoaParceiro = this.getVar("idPessoaParceiro");
        this.idParceiro = this.getVar("usuarios").find(
          (usuario) => usuario.pessoa.id == this.idPessoaParceiro
        ).parceiro.id;
      }
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = new Date(newStart);
        this.dragEvent.end = new Date(newEnd);
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = new Date(min);
        this.createEvent.end = new Date(max);
      }
    },

    pickerEventsColor(date) {
      const dataEvento = this.parceiro.aux.agendas.find(
        (agenda) => agenda.data == date
      );

      if (dataEvento.temcliente) {
        ///?ee calcular capacidadeDia
        const capacidadeDia = 1000;
        const percentualAtendimento = dataEvento.minutos / capacidadeDia;

        if (percentualAtendimento >= 0.5) {
          return "error";
        } else if (percentualAtendimento >= 0.25) {
          return "warning";
        } else if (percentualAtendimento > 0) {
          return "success";
        }
      } else {
        return "corDisabled";
      }
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    roundTime(time, down = true) {
      const roundTo = 10; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },

    async save(event) {
      const method = event.id ? "put" : "post";
      const pageNome = this.page.nome;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      let url = `${this.getConst("app").baseUrlBackend}/${pageNome}`;

      const dataset = {
        metadados: {
          idUsuario: this.getVar("idUsuario"),
          idPessoaParceiro: idPessoaParceiro,
        },
        detail: {
          ...event,
        },
      };

      const res = await this.submit(method, url, dataset);

      if (res) {
        await this.clickSearch();
        this.showDetail.value = false;
      }
    },

    selectAll() {
      if (this.colaboradoresSelectedAll) {
        this.colaboradoresSelected = [];
      } else {
        this.colaboradoresSelected = this.colaboradores.map(
          (colaborador) => colaborador.value
        );
      }

      this.changeColaborador();
    },

    startDrag(nativeEvent, event) {
      const dataset = this.datasets.find((dataset) => dataset.id == event.id);

      if (
        this.getVar("idUsuario") == this.getVar("idPessoaParceiro") ||
        dataset.idPedido ||
        (!dataset.idColaborador &&
          !this.perfis.includes(this.getTipo_item("perfilAdmin").id))
      ) {
        this.endDrag();
        return;
      }

      if (event && event.timed && !this.touchMoving) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }

      nativeEvent.stopImmediatePropagation();
      nativeEvent.stopPropagation();
    },

    startTime(tms) {
      if (this.touchMoving) return;

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        if (this.getVar("idUsuario") != this.getVar("idPessoaParceiro")) {
          this.createStart = this.roundTime(mouse);
          this.createEvent = {
            id: null,
            name: "Sem título",
            color: "primary",
            start: new Date(this.createStart),
            end: new Date(this.createStart + 60 * 60 * 1000), // 1 hora
            timed: true,
            category: tms.category,
          };

          const dataset = {
            cliente: null,
            colaborador: tms.category,
            data: new Date(),
            dataFim: new Date(this.createStart + 60 * 60 * 1000).toISOString(),
            dataInicio: new Date(this.createStart).toISOString(),
            descricao: null,
            id: null,
            idColaborador: this.colaboradores.find(
              (colaborador) => colaborador.text == tms.category
            ).value,
            idPedido: null,
            idPedidoItem: null,
            idPessoaParceiro: this.getVar("idPessoaParceiro"),
            idProduto: null,
            marca: null,
            numero: null,
            observacao: null,
            parceiro: this.getVar("usuarios").find(
              (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
            ).parceiro.nomeAlt,
          };

          this.events.push(this.createEvent);
          this.datasets.push(dataset);
        }
      }
    },

    startTimerEvent({ nativeEvent, event }) {
      if (this.pressTimer === null) {
        this.pressTimer = setTimeout(() => {
          this.startDrag(nativeEvent, event);
        }, 500);
      }
    },

    startTimerTime(tms) {
      if (this.pressTimer === null) {
        this.pressTimer = setTimeout(() => {
          this.startTime(tms);
        }, 500);
      }
    },

    swipeRefresh() {
      this.clickSearch();
    },

    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },

    touchMove(tms) {
      this.touchMoving = true;
      this.mouseMove(tms);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  touch-action: none;
  cursor: ns-resize;

  &::after {
    display: block;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  // &:hover::after {
  //   display: block;
  // }
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-opacity {
  opacity: 0.7;
  transition: 0.5s;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}
</style>