<!-- COD: FDS 04/07/2022 CPontoEletronicoView -->
<!-- *VER: JER 07/08/2022 CPontoEletronicoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- data inicio fim -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.dataInicio">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- funcionarios -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.funcionarios">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">colaboradores</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.funcionarios }}
        </span>
      </v-col>
    </v-row>

    <!-- tolerancia atraso -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.toleranciaAtraso">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">tolerância atraso</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.toleranciaAtraso.substring(0, 5) }}
        </span>
      </v-col>
    </v-row>

    <!-- tolerancia hora extra -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.toleranciaHoraExtra">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">tolerância hora extra</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.toleranciaHoraExtra.substring(0, 5) }}
        </span>
      </v-col>
    </v-row>

    <!-- folha pagamento -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.idFolhaPagamento">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">folha pagamento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <a class="primary--text text-body-1" @click="clickFopag()">
          #
          {{ dataset.aux.detail.idFolhaPagamento }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPontoEletronicoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    dataInicioFim() {
      return `${this.dateFormat(
        this.parseISO(
          `${this.dataset.main.detail.dataInicio.substring(0, 10)}`
        ),
        "date"
      )} - 
      ${this.dateFormat(
        this.parseISO(`${this.dataset.main.detail.dataFim.substring(0, 10)}`),
        "date"
      )}`;
    },
  },

  /// METHODS
  methods: {
    clickFopag() {
      this.$router.push(
        `/folhaPagamento/${this.dataset.aux.detail.idFolhaPagamento}`
      );
    },
  },
};
</script>