<!-- COD: BJB 30/06/2022 CPessoaEdit -->
<!-- *VER: JER 20/07/2022 CPessoaEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          :label="labelNome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          :label="labelNomeAlt"
          :max="25"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataNascimento -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataNascimento"
          date
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          :label="labelDataNascimento"
          :maxValue="maxValueDataNascimento"
          required
        />
      </v-col>
      <!-- sexo -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.main.detail.idTipo != getTipo_item('pessoaJuridica').id"
        cols="6"
      >
        <c-select
          v-model="dataset.main.detail.idSexo"
          :disabled="dataset.main.detail.idEstado == getEstado('ativo').id"
          :items="getTipo_itens(getTipo('sexo').id)"
          label="sexo"
          required
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPessoaEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
    }),

    labelDataNascimento() {
      return this.dataset.main.detail.idTipo ==
        this.getTipo_item("pessoaFisica").id
        ? "data nascimento"
        : "data fundação";
    },

    labelNome() {
      return this.dataset.main.detail.idTipo ==
        this.getTipo_item("pessoaFisica").id
        ? "nome completo"
        : "razão social";
    },

    labelNomeAlt() {
      return this.dataset.main.detail.idTipo ==
        this.getTipo_item("pessoaFisica").id
        ? "nome e sobrenome"
        : "nome fantasia";
    },

    maxValueDataNascimento() {
      return this.getTipo_item("pessoaJuridica").id ==
        this.dataset.main.detail.idTipo
        ? new Date().toISOString().substring(0, 10)
        : this.subYears(new Date(), 16).toISOString().substring(0, 10);
    },
  },
};
</script>