var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"date":"","label":"data","required":""},model:{value:(_vm.dataset.main.detail.data),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "data", $$v)},expression:"dataset.main.detail.data"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"date":"","label":"vencimento"},model:{value:(_vm.dataset.main.detail.dataVencimento),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "dataVencimento", $$v)},expression:"dataset.main.detail.dataVencimento"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"documento","max":20},model:{value:(_vm.dataset.main.detail.documento),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "documento", $$v)},expression:"dataset.main.detail.documento"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"valor","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.valor),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valor", $$v)},expression:"dataset.main.detail.valor"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"valor considerado","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.valorConsiderado),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valorConsiderado", $$v)},expression:"dataset.main.detail.valorConsiderado"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"valor app","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.valorApp),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valorApp", $$v)},expression:"dataset.main.detail.valorApp"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"percentual","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'percent',
          suffix: ' %',
        }},model:{value:(_vm.dataset.main.detail.percentual),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "percentual", $$v)},expression:"dataset.main.detail.percentual"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"valor comissao","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.valorComissao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valorComissao", $$v)},expression:"dataset.main.detail.valorComissao"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"valor acerto","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.valorAcerto),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valorAcerto", $$v)},expression:"dataset.main.detail.valorAcerto"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"valor saldo","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.valorSaldo),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valorSaldo", $$v)},expression:"dataset.main.detail.valorSaldo"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"observação","max":50},model:{value:(_vm.dataset.main.detail.observacao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "observacao", $$v)},expression:"dataset.main.detail.observacao"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }