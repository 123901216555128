<!-- COD: FDS 04/07/2022 CTransacaoView -->
<!-- *VER: JER 10/08/2022 CTransacaoView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- cliente nome -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoaCliente_nome">
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="primary--text" text @click="clickPessoa">
          {{ dataset.aux.detail.pessoaCliente_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- data vencimento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.dataVencimento">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">vencimento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <span
          :class="
            parseISO(dataset.main.detail.dataVencimento) < new Date()
              ? 'red--text text-body-1'
              : 'text-body-1'
          "
        >
          {{ dateFormat(dataset.main.detail.dataVencimento, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- pedido -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPedido">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">pedido</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <a class="primary--text text-body-1" @click="clickPedido">
          {{
            `${dataset.main.detail.idPedido} (${dataFormata(
              dataset.main.detail.data,
              "dateNoYear",
              true
            )})`
          }}
        </a>
      </v-col>
    </v-row>

    <!-- forma pagamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idFormaPagamento">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">pagamento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <span class="text-body-1">
          {{
            `${dataset.main.detail.isPagamentoOnline ? "online" : ""} ${
              getTipo_item(dataset.main.detail.idFormaPagamento).nomeAlt
            }`
          }}
        </span>
      </v-col>
    </v-row>

    <!-- documento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.documento">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">documento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <span class="text-body-1">
          {{ dataset.main.detail.documento }}
        </span>
      </v-col>
    </v-row>

    <!-- valor -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valor">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">valor</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'VALOR',
            text: `Valor da transação.`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span
            :class="
              dataset.main.detail.valor < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            {{ numberFormat(dataset.main.detail.valor, "currency") }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- valor cupons app -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valorCuponsApp">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">cupons app</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'CUPONS APP',
            text: `Valor total dos cupons emitidos pelo app.`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span
            :class="
              dataset.main.detail.valorCuponsApp < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            {{ numberFormat(dataset.main.detail.valorCuponsApp, "currency") }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- valor cupons parceiro -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valorCuponsParceiro">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">cupons parceiro</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'CUPONS PARCEIRO',
            text: `Valor total dos cupons emitidos pelo parceiro.`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span
            :class="
              dataset.main.detail.valorCuponsParceiro < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            {{
              numberFormat(dataset.main.detail.valorCuponsParceiro, "currency")
            }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- valor app -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valorApp">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">valor app</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'VALOR APP',
            text: `Valor de responsabilidade do app.\n

Se pagamento:
- online = valor - cupons parceiro
- não online = cupons app`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span
            :class="
              dataset.main.detail.valorApp < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            {{ numberFormat(dataset.main.detail.valorApp, "currency") }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- valor comissao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valorComissao">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">comissão</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'COMISSÃO',
            text: `Valor da comissão da transação.\n
            
comissao = valor * %comissao`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span
            :class="
              dataset.main.detail.valorComissao < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            {{ numberFormat(dataset.main.detail.valorComissao, "currency") }}
          </span>
          <span
            :class="
              dataset.main.detail.percentual < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            ({{
              numberFormat(dataset.main.detail.percentual / 100, "percent", 2)
            }})</span
          >
        </c-help>
      </v-col>
    </v-row>

    <!-- valor acerto -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valorAcerto">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">acerto</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'ACERTO',
            text: `Valor do acerto da transação.\n
            
acerto = valor app - comissao`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span
            :class="
              dataset.main.detail.valorAcerto < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            {{ numberFormat(dataset.main.detail.valorAcerto, "currency") }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- valor saldo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valorSaldo">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">saldo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'SALDO',
            text: `Saldo das transações até esta transação.\n
            
saldo = saldo anterior + acerto`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span
            :class="
              dataset.main.detail.valorSaldo < 0
                ? 'red--text text-body-1'
                : 'text-body-1'
            "
          >
            {{ numberFormat(dataset.main.detail.valorSaldo, "currency") }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- transacao pagamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idTransacaoPagamento">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">transação pagamento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'TRANSAÇÃO PAGAMENTO',
            text: `Id da transação que foi feito o acerto (pagamento) desta transação.`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span class="text-body-1">
            {{ dataset.main.detail.idTransacaoPagamento }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- transacao faturamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idTransacaoFaturamento">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="text-body-2">transação faturamento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="7">
        <c-help
          :help="{
            title: 'TRANSAÇÃO FATURAMENTO',
            text: `Id da transação que foi feito a nota fiscal de serviço desta transação.`,
            function: params.context.setUiBottomSheet,
          }"
        >
          <span class="text-body-1">
            {{
              numberFormat(
                dataset.main.detail.idTransacaoFaturamento,
                "currency"
              )
            }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.observacao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">({{ dataset.main.detail.observacao }})</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CTransacaoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),
  },

  /// METHODS
  methods: {
    clickPedido() {
      this.$router.push(`/pedido/${this.dataset.main.detail.idPedido}`);
    },

    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
    },
  },
};
</script>