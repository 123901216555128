// COD: BJB 15/08/2022 feriados
// *VER: JER 19/08/2022 feriados
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    feriados: [],
  },

  /// GETTERS
  getters: {
    getFeriados: state => (prop = "id") => {
      return state.feriados
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nome
          };
        })
    },

    getFeriado: state => id => {
      return state.feriados.length
        ? state.feriados
          .find(element => element.id == id)
        : { id }
    },
  },

  /// MUTATIONS
  mutations: {
    setFeriados: (state, payload) => {
      state.feriados = []
      payload.forEach(element => {
        state.feriados.push(element)
      });
    },
  },

  /// ACTIONS
  actions: {
    setFeriados: (context, payload) => {
      context.commit("setFeriados", payload)
    },
  }
}