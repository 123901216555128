<!-- COD: BJB 25/07/2022 CHomeDialogAuth -->
<!-- *VER: JER 08/08/2022 CHomeDialogAuth -->
<!-- /33/ -->

<template>
  <v-dialog v-model="show" max-width="380px" persistent scrollable>
    <!-- /// ENTRAR -->
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark extended>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>ENTRAR</v-toolbar-title>
          <v-spacer />
          <template #extension>
            <v-tabs
              v-model="idTipoLogin"
              active-class="blue lighten-2"
              background-color="accent"
              centered
              dark
              grow
            >
              <v-tab key="0" value="0">
                <v-icon>mdi-account-plus</v-icon>
                <span class="ml-2">cadastrar</span>
              </v-tab>
              <v-tab key="1" value="1">
                <v-icon>mdi-login</v-icon>
                <span class="ml-2">entrar</span>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="form">
          <v-container class="py-6">
            <v-row
              v-bind="prop.row"
              v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
            >
              <v-col v-bind="prop.col">
                <c-menu
                  v-model="usuario.main.enderecos[0].idPais"
                  classe="ml-n4"
                  :items="
                    getLocalidades(getTipo_item('localidadePais').id).filter(
                      (localidade) =>
                        localidade.idEstado == getEstado('ativo').id
                    )
                  "
                >
                  <template #btn="{ itemSelected }">
                    <v-img
                      alt="imagem"
                      class="mr-2"
                      contain
                      :src="itemSelected.image"
                      width="25"
                    />
                    <span v-if="itemSelected" class="text=body-2">
                      {{ itemSelected.text }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <v-img alt="imagem" contain :src="item.image" width="25" />
                    <v-list-item-title class="ml-2 text-body-2">
                      {{ item.text }}
                    </v-list-item-title>
                  </template>
                </c-menu>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
            >
              <v-col v-bind="prop.col">
                <c-text-field
                  v-model.trim="usuario.main.documentos[0].numero"
                  :label="getLabelDocumento"
                  :help="{
                    title: `POR QUE PEDIMOS ${getLabelDocumento}?`,
                    text: `Usamos o ${getLabelDocumento} para evitar duplicidade de cadastro.
${getDocumentoInfo}`,
                    height: 220,
                    function: setUiBottomSheet,
                  }"
                  :max="documentoDigitosMax"
                  :min="documentoDigitosMin"
                  :options="{
                    mask: documentoMask,
                    options: documentoOptions,
                  }"
                  :placeholder="documentoMask"
                  required
                  type="tel"
                />
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
            >
              <v-col v-bind="prop.col">
                <c-text-field
                  v-if="
                    idTipoLogin == getConst('config').tipoLogin.cadastrar.id
                  "
                  v-model="usuario.main.detail.nomeAlt"
                  clearable
                  label="nome e sobrenome"
                  :min="5"
                  :max="25"
                  required
                />
              </v-col>
            </v-row>

            <!-- dataNascimento -->
            <v-row
              v-bind="prop.row"
              v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
            >
              <v-col v-bind="prop.col" cols="6">
                <c-datetime-picker
                  v-model="usuario.main.detail.dataNascimento"
                  date
                  label="data de nascimento"
                  :maxValue="maxValueDataNascimento"
                  required
                />
              </v-col>
            </v-row>

            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <c-text-field
                  v-model="usuario.main.emails[0].endereco"
                  clearable
                  label="email"
                  :max="80"
                  required
                  :rules="rules.inputUrl"
                  type="email"
                  @change="changeEmail()"
                />
              </v-col>
            </v-row>

            <!-- botao verificar -->
            <v-row
              v-bind="prop.row"
              v-show="
                !usuario.main.emails[0].isVerificado &&
                usuario.main.emails[0].endereco &&
                !showCodigoEmail &&
                idTipoLogin == getConst('config').tipoLogin.cadastrar.id &&
                !showCodigoCelular
              "
              class="mt-n4 ml-n3"
            >
              <v-col v-bind="prop.col">
                <c-tooltip
                  :close-delay="3000"
                  texto="Informe o código de verificação enviado para o e-mail"
                >
                  <template v-slot="{ on }">
                    <c-btn
                      color="primary"
                      text
                      @click="clickVerificarEmail(on, $event)"
                    >
                      verificar
                    </c-btn>
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <!-- timer -->
            <v-row
              v-bind="prop.row"
              v-if="
                showCodigoEmail &&
                idTipoLogin == getConst('config').tipoLogin.cadastrar.id
              "
            >
              <v-col v-bind="prop.col" align="center">
                <c-tooltip :close-delay="3000" texto="tempo expirado">
                  <template v-slot="{ on }">
                    <span class="text-caption">
                      {{ timerLeft }} s {{ loadTimer(on) }}
                    </span>
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="
                showCodigoEmail &&
                idTipoLogin == getConst('config').tipoLogin.cadastrar.id
              "
              justify="center"
            >
              <v-col v-bind="prop.col" cols="8">
                <c-tooltip
                  :close-delay="3000"
                  texto="código de verificação inválido"
                >
                  <template v-slot="{ on }">
                    <v-otp-input
                      v-model="codigoVerificaoEmail"
                      length="6"
                      type="number"
                      @change="changeCodigoVerificacao(on, 'email')"
                    />
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="
                !usuario.main.emails[0].isVerificado &&
                showCodigoEmail &&
                idTipoLogin == getConst('config').tipoLogin.cadastrar.id
              "
              class="mt-n4 ml-n3"
              justify="center"
            >
              <v-col v-bind="prop.col" cols="8">
                <c-btn color="primary" text @click="clickNaoRecebiCodigo(true)">
                  não recebi o código
                </c-btn>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
            >
              <v-col v-bind="prop.col">
                <c-text-field
                  v-model="usuario.main.telefones[0].numero"
                  :help="{
                    title: 'NÚMERO TELEFONE',
                    text: getTelefoneInfo,
                    function: setUiBottomSheet,
                  }"
                  label="celular"
                  :max="15"
                  :options="{ mask: mascara, options: options }"
                  :placeholder="placeholderTelefone"
                  required
                  type="text"
                  @change="changeCelular()"
                />
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-show="
                !usuario.main.telefones[0].isVerificado &&
                usuario.main.telefones[0].numero &&
                !showCodigoCelular &&
                idTipoLogin == getConst('config').tipoLogin.cadastrar.id &&
                !showCodigoEmail
              "
              class="mt-n4 ml-n3"
            >
              <v-col v-bind="prop.col">
                <c-tooltip
                  :close-delay="3000"
                  texto="Informe o código de verificação enviado para o celular"
                >
                  <template v-slot="{ on }">
                    <c-btn
                      color="primary"
                      text
                      @click="clickVerificarCelular(on, $event)"
                    >
                      verificar
                    </c-btn>
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <!-- timer -->
            <v-row v-bind="prop.row" v-show="showCodigoCelular">
              <v-col v-bind="prop.col" align="center">
                <c-tooltip :close-delay="3000" texto="tempo expirado">
                  <template v-slot="{ on }">
                    <span class="text-caption">
                      {{ timerLeft }} s {{ loadTimer(on) }}
                    </span>
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="
                showCodigoCelular &&
                idTipoLogin == getConst('config').tipoLogin.cadastrar.id
              "
              justify="center"
            >
              <v-col v-bind="prop.col" cols="8">
                <c-tooltip
                  :close-delay="3000"
                  texto="código de verificação inválido"
                >
                  <template v-slot="{ on }">
                    <v-otp-input
                      v-model="codigoVerificaoCelular"
                      length="6"
                      type="number"
                      @change="changeCodigoVerificacao(on, 'celular')"
                    />
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="
                !usuario.main.telefones[0].isVerificado &&
                showCodigoCelular &&
                idTipoLogin == getConst('config').tipoLogin.cadastrar.id
              "
              class="mt-n4 ml-n3"
              justify="center"
            >
              <v-col v-bind="prop.col" cols="8">
                <c-btn color="primary" text @click="clickNaoRecebiCodigo(true)">
                  não recebi o código
                </c-btn>
              </v-col>
            </v-row>

            <v-row v-bind="prop.row">
              <v-col
                v-bind="prop.col"
                v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
              >
                <c-text-field
                  v-model="usuario.main.enderecos[0].codigoPostal"
                  clearable
                  :help="{
                    title: `POR QUE PEDIMOS ${getLabelCEP}?`,
                    text: `Usamos o ${getLabelCEP} para oferecer produtos dos parceiros que atendem sua região.`,
                    height: 220,
                    function: setUiBottomSheet,
                  }"
                  :label="`${getLabelCEP} ${cidade}`"
                  :max="20"
                  :placeholder="cepMask"
                  :options="{
                    mask: cepMask,
                    placeholder: cepMask,
                  }"
                  required
                  type="tel"
                  @keypress="buscaCep"
                />
              </v-col>
            </v-row>

            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <c-text-field
                  v-model="usuario.main.senhas[0].senha"
                  clearable
                  id="senha"
                  :help="helpSenha"
                  label="senha"
                  :max="20"
                  required
                  :rules="rules.inputSenha"
                  type="password"
                  @keyup.enter="enterAuth($event)"
                />
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
            >
              <v-col v-bind="prop.col">
                <c-text-field
                  v-model="usuario.main.senhas[0].confirmar"
                  clearable
                  id="senhaConfirm"
                  label="confirmar senha"
                  :max="20"
                  required
                  :rules="rules.inputSenhaConfirmar"
                  type="password"
                  @keyup.enter="enterAuth($event)"
                />
              </v-col>
            </v-row>

            <div
              v-if="idTipoLogin == getConst('config').tipoLogin.cadastrar.id"
            >
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <span class="text-body-1"
                    >Ao se cadastrar, você concorda com:</span
                  >
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-btn
                    classe="ml-n1 px-1 text-body-1"
                    color="primary"
                    text
                    @click="clickTermoServico('close', '/termos-servico')"
                  >
                    Termos de Serviço
                  </c-btn>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-btn
                    classe="ml-n1 px-1 text-body-1"
                    color="primary"
                    text
                    @click="
                      clickPoliticaPrivacidade('close', '/politica-privacidade')
                    "
                  >
                    Política de Privacidade
                  </c-btn>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <span class="text-body-1">
                    {{ response }}
                  </span>
                </v-col>
              </v-row>
            </div>

            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <c-btn
                  block
                  classe="text-body-1"
                  color="primary"
                  @click="enterAuth"
                >
                  {{ entrarCadastrar }}
                </c-btn>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-if="idTipoLogin == getConst('config').tipoLogin.entrar.id"
            >
              <v-col v-bind="prop.col">
                <c-btn
                  classe="ml-n1 px-1 text-body-1"
                  color="primary"
                  small
                  text
                  @click="esqueciSenha"
                >
                  Esqueci a senha
                </c-btn>
              </v-col>
            </v-row>
          </v-container>

          <!-- /// AUTHCONFIRM -->
          <c-home-dialog-auth-confirm
            @close="authConfirm($event)"
            :show="showAuthConfirm"
          />

          <!-- /// BOTTOMSHEET -->
          <c-bottom-sheet :bottomSheet="bottomSheet" />
        </v-form>

        <v-container
          v-show="idTipoLogin == getConst('config').tipoLogin.entrar.id"
        >
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="4">
              <v-divider v-bind="prop.divider" />
            </v-col>
            <v-col v-bind="prop.col" cols="4">
              <span class="text-body-2">ou entrar com</span>
            </v-col>
            <v-col v-bind="prop.col" cols="4">
              <v-divider v-bind="prop.divider" />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-btn
                block
                classe="text-body-1"
                color="primary"
                @click="logInWithFacebook"
              >
                <v-icon color="#4267b2" left>mdi-facebook</v-icon>
                <span class="text-body-1">Facebook</span>
              </c-btn>
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <GoogleLogin
                style="width: 100%"
                :params="params"
                :onSuccess="onSignIn"
                :onFailure="onFailureSignIn"
              >
                <c-btn block>
                  <!-- logo do google -->
                  <svg
                    class="abcRioButtonSvg"
                    height="18px"
                    version="1.1"
                    viewBox="0 0 48 48"
                    width="18px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <!-- //? Daria para fazer o logo do Google de uma forma menos verbosa? -->
                    <g>
                      <path
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        fill="#EA4335"
                      />
                      <path
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        fill="#4285F4"
                      />
                      <path
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        fill="#34A853"
                      />
                      <path d="M0 0h48v48H0z" fill="none" />
                    </g>
                  </svg>
                  <span class="ml-3 text-body-1">Google</span>
                </c-btn>
              </GoogleLogin>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import GoogleLogin from "vue-google-login";
import CHomeDialogAuthConfirm from "@/components/pages/home/auth/CHomeDialogAuthConfirm.vue";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "CHomeDialogAuth",

  /// COMPONENTS
  components: {
    GoogleLogin,
    CHomeDialogAuthConfirm,
  },

  /// MIXINS
  mixins: [
    mixinBottomSheet,
    mixinCarrinhos,
    mixinData,
    mixinLib,
    mixinSnackbar,
    mixinUtil,
  ],

  /// PROPS
  props: {
    show: Boolean,
  },

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      cidade: "",
      codigoVerificaoCelular: null,
      codigoVerificaoEmail: null,
      dadosLogin: null,
      detalharPessoa: false,
      idTipoLogin: getVar("idTipoLogin"),
      intervalTimer: null,
      loadTimerOn: null,
      params: {
        client_id: getConst("google").clientId,
      },
      renderParams: {
        width: 160,
        height: 36,
        longtitle: false,
      },
      response: "",
      replace: null,
      rules: {
        inputUrl: [
          (value) => (!!value && value.length >= 7) || `mínimo 7 caracteres`,
          (value) =>
            (!!value &&
              !!value.match(
                /^(\S{1,50})@([a-z0-9][a-z0-9-]{0,24}[a-z0-9])(\.[a-z]{2,6})(\.[a-z]{2})?$/g
              )) ||
            "email inválido",
        ],
        inputSenha: [
          (value) => (!!value && value.length >= 6) || "mínimo 6 caracteres",
          (value) =>
            this.idTipoLogin !=
              this.getConst("config").tipoLogin.cadastrar.id ||
            (!!value &&
              !!value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,20}$/g)) ||
            "senha inválida",
        ],
        inputSenhaConfirmar: [
          (value) =>
            (!!value && value == this.usuario.main.senhas[0].senha) ||
            "senha não confere",
        ],
      },
      showAuthConfirm: false,
      showCodigoCelular: false,
      showCodigoEmail: false,
      timer: 60,
      usuario: {
        metadados: {
          coordenada: null,
          idPessoaParceiro: null,
          idUsuario: null,
          ip: {},
          modo: getConst("config").modo.signup.id,
          userDevice: null,
        },
        main: {
          detail: {
            id: null,
            idEstado: getEstado("cadastro").id,
            idTipo: getTipo_item("pessoaFisica").id,
            nomeAlt: null,
          },
          enderecos: [
            {
              order: 1,
              idTipo: getTipo_item("enderecoCasa").id,
              idPais: getConst("localidades").paises.brasil.id,
              codigoPostal: "",
              idCidade: null,
              bairro: "",
              endereco: "",
              numero: "",
              complemento: "",
              observacao: null,
              latitude: null,
              longitude: null,
              isVerificado: false,
              isVisivel: false,
            },
          ],
          emails: [
            {
              order: 1,
              idTipo: getTipo_item("emailCasa").id,
              endereco: null,
              observacao: null,
              isVerificado: false,
              isVisivel: false,
            },
          ],
          documentos: [
            {
              order: 1,
              idTipo: getTipo_item("documentoPFFederal").id,
              idPais: getConst("localidades").paises.brasil.id,
              numero: "",
              emissor: "",
              dataEmissao1: null,
              dataEmissao: null,
              dataValidade: null,
              observacao: null,
            },
          ],
          telefones: [
            {
              order: 1,
              idTipo: getTipo_item("telefoneCelular").id,
              idPais: getConst("localidades").paises.brasil.id,
              numero: "",
              observacao: null,
              isVerificado: false,
              isVisivel: false,
              del: false,
            },
          ],
          senhas: [
            {
              idTipo: getTipo_item("senhaApp").id,
              senha: null,
              isProvisoria: false,
            },
          ],
        },
        convite: null,
      },
      usuarioSocialDados: null,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getEstado: "estados/getEstado",
      getLocalidade: "localidades/getLocalidade",
      getLocalidade_configuracoesRegionais:
        "localidades/getLocalidade_configuracoesRegionais",
      getLocalidades: "localidades/getLocalidades",
      getPagina: "paginas/getPagina",
      getPagina_documentos: "paginas/getPagina_documentos",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    cepMask() {
      const mascara = this.getLocalidade_configuracoesRegionais(
        this.usuario.main.enderecos[0].idPais,
        this.getTipo_item("configuracaoRegionalCodigoPostal").id
      )[0]?.mascara;

      return mascara;
    },

    cepDigitos() {
      if (
        this.usuario.main.enderecos[0].idPais ==
        this.getConst("localidades").paises.brasil.id
      ) {
        return 8; // confirmar valor
      }
      if (
        this.usuario.main.enderecos[0].idPais ==
        this.getConst("localidades").paises.estadosUnidos.id
      ) {
        return 9; // confirmar valor
      } else {
        return 9;
      }
    },

    configuracaoRegionalTelefone() {
      return this.configuracaoRegionalByTipo(
        this.idLocalidade,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    documentoMask() {
      const mascara = this.getLocalidade_configuracoesRegionais(
        this.usuario.main.enderecos[0].idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id
      )[0]?.mascara;

      return mascara;
    },

    documentoOptions() {
      const options = this.getLocalidade_configuracoesRegionais(
        this.usuario.main.enderecos[0].idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id
      )[0]?.options;

      return options;
    },

    documentoDigitosMin() {
      if (this.documentoMask) {
        const masks = this.cleanMask(this.documentoMask, true);

        return Array.isArray(masks) ? masks[0].length : masks.length;
      } else {
        return 10;
      }
    },

    documentoDigitosMax() {
      if (this.documentoMask) {
        const masks = this.cleanMask(this.documentoMask, true);

        return Array.isArray(masks)
          ? masks[masks.length - 1].length
          : masks.length;
      } else {
        return 10;
      }
    },

    entrarCadastrar() {
      return this.idTipoLogin == this.getConst("config").tipoLogin.entrar.id
        ? "Entrar"
        : "Cadastrar";
    },

    getDocumentoInfo() {
      const infoDocumento = this.masksInfoByPais(
        this.usuario.main.enderecos[0].idPais,
        null,
        "configuracaoRegionalDocumento",
        this.usuario.main.detail.idTipo
      );

      let text = "";

      infoDocumento.types.map((type, i) => {
        if (type) {
          text += `${infoDocumento.text[i] ? infoDocumento.text[i] : ""} \n`;
        }
      });

      return text;
    },

    getLabelCEP() {
      const label = this.getLocalidade_configuracoesRegionais(
        this.usuario.main.enderecos[0].idPais,
        this.getTipo_item("configuracaoRegionalCodigoPostal").id
      )[0]?.nomeAlt;

      return label || "código postal";
    },

    getLabelDocumento() {
      const label = this.getLocalidade_configuracoesRegionais(
        this.usuario.main.enderecos[0].idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id
      )[0]?.nomeAlt;

      return label;
    },

    getTelefoneInfo() {
      return (
        this.masksInfoByPais(
          this.idLocalidade,
          null,
          "configuracaoRegionalTelefone"
        ) || ""
      );
    },

    helpSenha() {
      return this.idTipoLogin == this.getConst("config").tipoLogin.cadastrar.id
        ? {
            function: this.setUiBottomSheet,
            ...this.bottomSheetData.senha[0],
          }
        : null;
    },

    idLocalidade() {
      return this.usuario.main.enderecos[0].idPais;
    },

    logado() {
      return !!this.getVar("idPessoaParceiro");
    },

    mascara() {
      return this.maskByTipo(
        this.idLocalidade,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    maxValueDataNascimento() {
      return this.subYears(new Date(), 16).toISOString().substring(0, 10);
    },

    options() {
      return this.optionsByTipo(
        this.idLocalidade,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    placeholderTelefone() {
      return Array.isArray(this.configuracaoRegionalTelefone?.mascaras)
        ? ""
        : this.mascara.replaceAll("!", "");
    },

    timerLeft() {
      return this.timer;
    },
  },

  /// WATCH
  watch: {
    idTipoLogin() {
      this.setVar({ idTipoLogin: this.idTipoLogin });
    },

    show() {
      if (!this.show && this.$refs.form) {
        this.$refs.form.resetValidation();
      } else {
        if (
          !this.getVar("usuarios").length &&
          this.getVar("usuariosTemp").length
        ) {
          this.$emit("close");
          this.showAuthConfirm = true;
        }
      }
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    async auth() {
      // quando o login for por redes sociais não valida os campos.
      if (this.usuarioSocialDados || this.$refs.form.validate()) {
        this.setVar({ overlay: true });

        this.usuario.metadados.idEvento = this.getTipo_item(
          this.idTipoLogin == this.getConst("config").tipoLogin.entrar.id
            ? "eventoAlterado"
            : "eventoIncluido"
        ).id;

        if (this.idTipoLogin == this.getConst("config").tipoLogin.entrar.id) {
          this.usuario.metadados.ip.interno = this.getVar("ipInterno");

          try {
            await axios
              .get("https://api.ipify.org/?format=json")
              .then((res) => {
                this.usuario.metadados.ip.externo = res.data.ip;
              });
          } catch (err) {
            console.log(this.usuario.metadados.ip.externo);
          }

          //?b http://ip-api.com - pesquisar
          /*
          await axios
            .get(`http://ip-api.com/json/${this.usuario.metadados.ip.externo}`)
            .then(res => {
              this.usuario.metadados.latitude = res.data.lat;
              this.usuario.metadados.longitude = res.data.lon;
            })
          */

          let coordenada;

          try {
            coordenada = await this.getPosition();
          } catch (err) {
            coordenada = {
              latitude: 0,
              longitude: 0,
            };

            this.setSnackbar(true, "LOCALIZAÇÃO", err, "error");
          }

          if (coordenada) {
            this.usuario.metadados.coordenada = coordenada;
          }

          this.usuario.metadados.userDevice = this.getUserDevice();

          if (this.usuarioSocialDados) {
            this.usuario.main.detail.nomeAlt = this.usuarioSocialDados.name;
            this.usuario.main.emails[0].endereco =
              this.usuarioSocialDados.email;
            this.usuario.main.senhas[0].senha = this.usuarioSocialDados.id;
            // habilitar esta linha
            // imagem = this.usuarioSocialDados.imageUrl
          }

          axios
            .post(`${this.getConst("app").baseUrlBackend}/signin`, this.usuario)
            .then(async (res) => {
              this.dadosLogin = res.data;

              if (this.dadosLogin.isProvisoria) {
                this.setVar({ isSenhaProvisoria: true });
                this.usuario.main.senhas[0].senha = null;
                this.dadosLogin.email = this.usuario.main.emails[0];
                this.$emit("showDialogSenha", this.dadosLogin);
                return;
              }

              const termosServicoVersionUltimoLoginOk =
                this.dadosLogin?.main?.usuarios[0]?.login?.termosServicoVersion;

              const termosServicoVersionAtual = this.getPagina_documentos(
                this.getPagina("termosServico").id
              )[0].options.versao;

              if (
                termosServicoVersionUltimoLoginOk != termosServicoVersionAtual
              ) {
                this.setVar({ usuariosTemp: this.dadosLogin.main.usuarios });
                this.showAuthConfirm = true;
              } else {
                this.setVar({ usuarios: this.dadosLogin.main.usuarios });
                await this.fazerLogin();
              }

              this.usuarioReset();
            })
            .catch((err) => {
              this.setSnackbar(true, "ERRO", err.response.data, "error");
            })
            .finally(() => {
              this.setVar({ overlay: false });

              if (
                this.dadosLogin.metadados &&
                !this.dadosLogin.metadados.idUltimoLogin &&
                !this.dadosLogin.metadados.logPessoaInclusao &&
                this.getVar("idUsuario")
              ) {
                this.$router.push(`/pessoa/${this.getVar("idUsuario")}`);
              }

              if (this.logado) this.$emit("close");
            });
        } else {
          const resPosition = await this.setPosition();

          if (!resPosition) {
            this.setVar({ overlay: false });
            return;
          }

          let naoVerificado = false;

          if (!this.usuario.main.emails[0].isVerificado) {
            naoVerificado = true;
            this.setSnackbar(
              true,
              "CADASTRO",
              "e-mail não verificado",
              "error"
            );
          } else if (!this.usuario.main.telefones[0].isVerificado) {
            naoVerificado = true;
            this.setSnackbar(
              true,
              "CADASTRO",
              "celular não verificado",
              "error"
            );
          }

          if (naoVerificado) {
            this.setVar({ overlay: false });
            return;
          }

          axios
            .post(`${this.getConst("app").baseUrlBackend}/signup`, this.usuario)
            .then(() => {
              this.usuarioReset();
              this.$refs.form.resetValidation();
              this.idTipoLogin = this.getConst("config").tipoLogin.entrar.id;
              this.setSnackbar(true, "CADASTRO", "Usuário cadastrado.");
            })
            .catch((err) => {
              this.setSnackbar(true, "ERRO", err.response.data, "error");
            })
            .finally(() => {
              this.setVar({ overlay: false });
            });
        }
      } else {
        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );
      }

      this.setVar({ overlay: false });
    },

    async authConfirm(termosServico = false) {
      this.showAuthConfirm = false;

      if (this.getVar("usuarios").length) {
        this.setVar({ usuariosTemp: [] });
        await this.fazerLogin();
        this.$emit("close");
      } else if (termosServico) {
        this.$emit("close");
        this.$router.push("/termos-servico");
      } else {
        this.setVar({ usuariosTemp: [] });
      }
    },

    async buscaCep() {
      const cep = this.usuario.main.enderecos[0].codigoPostal;

      if (cep && cep.clean().length < 8) return;

      if (
        this.usuario.main.enderecos[0].idPais ==
        this.getConst("localidades").paises.brasil.id
      ) {
        this.cidade = "";
        this.usuario.main.enderecos[0].bairro = "";
        this.usuario.main.enderecos[0].endereco = "";
        this.usuario.main.enderecos[0].idCidade = null;

        if (cep) {
          const cepLimpo = cep.clean();

          let cepDados;

          if (cepLimpo.length >= this.cepDigitos)
            cepDados = await this.$viaCep
              .buscarCep(cepLimpo)
              .then((obj) => obj);

          if (cepDados && !cepDados.erro) {
            this.cidade = `(${cepDados.localidade})`;

            const url = `${
              this.getConst("app").baseUrlBackend
            }/localidades_estadoscidades/${
              this.usuario.main.enderecos[0].idPais
            }/${cepLimpo}`;

            console.log(url);

            const ret = await axios.get(url);
            const localidades = ret?.data || [];

            const cidades = localidades.filter(
              (localidade) =>
                localidade.idTipo == this.getTipo_item("localidadeCidade").id
            );

            if (cidades.length) {
              this.usuario.main.enderecos[0].idCidade = cidades[0].value;
            }

            this.usuario.main.enderecos[0].bairro = cepDados.bairro.substring(
              0,
              30
            );
            this.usuario.main.enderecos[0].endereco =
              cepDados.logradouro.substring(0, 40);
          }

          this.$refs.form.validate();

          this.usuario.main.senhas[0].senha = null;
          this.usuario.main.senhas[0].confirmar = null;
        }
      }
    },

    async changeCodigoVerificacao(on, tipo) {
      const codigoVerificacao =
        tipo == "email"
          ? this.codigoVerificaoEmail
          : this.codigoVerificaoCelular;

      if (codigoVerificacao && codigoVerificacao.length == 6) {
        try {
          const url = `${
            this.getConst("app").baseUrlBackend
          }/pessoas/tokenValidate`;

          const objeto = {
            token: this.replace.token,
            codigoVerificacao: codigoVerificacao,
          };

          await axios.post(url, objeto);

          if (tipo == "email") {
            this.showCodigoEmail = false;
            this.codigoVerificaoEmail = null;
            this.usuario.main.emails[0].isVerificado = true;
          } else {
            this.showCodigoCelular = false;
            this.codigoVerificaoCelular = null;
            this.usuario.main.telefones[0].isVerificado = true;
          }
        } catch (err) {
          if (tipo == "email") {
            this.codigoVerificaoEmail = null;
          } else {
            this.codigoVerificaoCelular = null;
          }
          this.clickOnTooltip(on, { stopPropagation: () => {} });
        }
      }
    },

    changeCelular() {
      this.usuario.main.telefones[0].isVerificado = false;
    },

    changeEmail() {
      this.usuario.main.emails[0].isVerificado = false;
    },

    changePais() {
      this.usuario.main.documentos[0].numero = null;
      this.usuario.main.documentos[0].idPais =
        this.usuario.main.enderecos[0].idPais;
    },

    clickNaoRecebiCodigo(fromClick = false) {
      const showCodigo = this.showCodigoCelular
        ? "showCodigoCelular"
        : "showCodigoEmail";
      const time = !fromClick ? 3000 : 10;
      setTimeout(() => {
        this[showCodigo] = false;
        this.loadTimerOn = null;
      }, time);

      if (!fromClick) {
        this.clickOnTooltip(this.loadTimerOn, { stopPropagation: () => {} });
      }
    },

    clickPoliticaPrivacidade(parent, action) {
      this.$emit(parent, action);
    },

    clickTermoServico(parent, action) {
      this.$emit(parent, action);
    },

    async clickVerificarCelular(on, event) {
      try {
        this.setVar({ overlay: true });
        this.usuario.main.telefones[0].isVerificado = true; //? remover
        console.log(event); //? remover
        // const url = `${
        //   this.getConst("app").baseUrlBackend
        // }/pessoas/celularVerifica`;

        // this.replace = {
        //   celular: `+${this.usuario.main.telefones[0].numero.clean()}`,
        //   idPagina: this.getPagina("pessoa").id,
        //   idFeature: this.getFeature("CPessoaPanelEmails").id,
        //   idUsuario: this.getConst("app").id, //? permitir nulo
        //   idPessoaParceiro: this.getVar("idPessoaParceiro"),
        // };

        // const ret = await axios.post(url, this.replace);

        // this.replace = ret.data;
        this.setVar({ overlay: false });

        // this.clickOnTooltip(on, event);

        // setTimeout(() => {
        //   this.showCodigoCelular = true;
        //   this.timer = 60;
        //   this.setIntervalTimer();
        // }, 3000);
      } catch (err) {
        this.setVar({ overlay: false });
        this.setSnackbar(
          true,
          "ERRO",
          err.response ? err.response.data : err.data,
          "error"
        );
      }
    },

    async clickVerificarEmail(on, event) {
      try {
        this.setVar({ overlay: true });
        this.usuario.main.emails[0].isVerificado = true; //? remover
        console.log(event); //? remover
        // const url = `${
        //   this.getConst("app").baseUrlBackend
        // }/pessoas/emailVerifica`;

        // this.replace = {
        //   email: this.usuario.main.emails[0].endereco,
        //   idPagina: this.getPagina("pessoa").id,
        //   idFeature: this.getFeature("CPessoaPanelEmails").id,
        //   idUsuario: this.getConst("app").id, //? permitir nulo
        //   idPessoaParceiro: this.getVar("idPessoaParceiro"),
        // };

        // const ret = await axios.post(url, this.replace);

        // this.replace = ret.data;
        this.setVar({ overlay: false });

        // this.clickOnTooltip(on, event);

        // setTimeout(() => {
        //   this.showCodigoEmail = true;
        //   this.timer = 60;
        //   this.setIntervalTimer();
        // }, 3000);
      } catch (err) {
        this.setVar({ overlay: false });
        this.setSnackbar(
          true,
          "ERRO",
          err.response ? err.response.data : err.data,
          "error"
        );
      }
    },

    async conviteSelected(idConvite) {
      this.setVar({ overlay: true });
      const url = `${
        this.getConst("app").baseUrlBackend
      }/convites/${idConvite}`;

      await axios
        .get(url)
        .then((res) => {
          const convite = res.data;
          this.usuario.main.documentos[0].numero =
            convite.main.detail.documentoFederal;
          this.usuario.main.detail.nomeAlt = convite.main.detail.nomeAlt;
          this.usuario.main.emails[0].endereco = convite.main.detail.email;
          this.usuario.main.convite = { ...convite.main.detail };
        })
        .catch((err) => {
          this.setSnackbar(
            true,
            "ERRO",
            err.response ? err.response.data : err.data,
            "error"
          );
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });
    },

    async enterAuth(event) {
      if (event.target.id == "senha" && this.idTipoLogin == 0) {
        return;
      }

      await this.auth();
      // event.target.blur();
    },

    async esqueciSenha() {
      if (this.usuario.main.emails[0].endereco) {
        this.setVar({ overlay: true });
        const url = `${this.getConst("app").baseUrlBackend}/esqueciSenha`;

        await axios
          .post(url, this.usuario.main.emails[0])
          .then((res) => {
            this.setSnackbar(true, "SENHA", res.data.mensagem);

            res.data.email = this.usuario.main.emails[0];

            setTimeout(() => {
              this.$emit("showDialogSenha", res.data);
            }, this.getConst("config").snackbar.timeout);
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          })
          .finally(() => {
            this.setVar({ overlay: false });
          });
      } else {
        this.setSnackbar(true, "SENHA", "Informe o email.", "error");
        this.setVar({ overlay: false });
      }
    },

    async fazerLogin() {
      return new Promise((resolve) => {
        this.setVar({ overlay: true });

        const termosServicoVersionAtual = this.getPagina_documentos(
          this.getPagina("termosServico").id
        )[0].options.versao;

        this.dadosLogin.metadados.termosServicoVersionUltimoLoginOk =
          termosServicoVersionAtual;

        localStorage.setItem(
          this.getConst("app").userKey,
          JSON.stringify(this.dadosLogin)
        );

        axios
          .put(
            `${this.getConst("app").baseUrlBackend}/signinOk/${
              this.dadosLogin.metadados.idLogin
            }`
          )
          .then(async () => {
            this.setVar({ idUsuario: this.getVar("usuarios")[0].pessoa.id });
            this.setVar({
              idPessoaParceiro: this.getVar("usuarios")[0].pessoa.id,
            });

            if (
              this.getVar("usuarios")[0].enderecos &&
              this.getVar("usuarios")[0].enderecos.length
            ) {
              this.setVar({
                idPessoaEndereco: this.getVar("usuarios").filter(
                  (usuario) =>
                    usuario.pessoa.id == this.getVar("idPessoaParceiro")
                )[0].enderecos[0].id,
              });
            }

            await this.carrinhosAtualizar();
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          })
          .finally(() => {
            this.setVar({ overlay: false });
            resolve();
          });
      });
    },

    // LOGIN FACEBOOK
    fbGetUserInfo(userId) {
      window.FB.api(
        "/" + userId + "/?fields=id,name,email,picture",
        "GET",
        {},
        (response) => {
          this.usuarioSocialDados = {
            id: response.id,
            name: response.name,
            email: response.email,
            imageUrl: response.picture.data.url,
          };

          this.usuario.main.senhas[0].idTipo =
            this.getTipo_item("senhaFacebook").id;

          this.auth();
        }
      );
    },

    initFacebook() {
      return new Promise((resolve, reject) => {
        window.fbAsyncInit = (() => {
          try {
            window.FB.init({
              appId: this.getConst("facebook").appId,
              cookie: true,
              version: this.getConst("facebook").version,
            });

            resolve();
          } catch (err) {
            reject(err);
          }
        })();
      });
    },

    loadTimer(on) {
      this.loadTimerOn = on;
    },

    async logInWithFacebook() {
      if (!window.FB.getAccessToken()) {
        await this.initFacebook();
      }

      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const fbUserId = response.authResponse.userID;
            this.fbGetUserInfo(fbUserId);
          } else {
            this.setSnackbar(
              true,
              "LOGIN FACEBOOK",
              "Login não autorizado.",
              "error"
            );
          }
        },
        { scope: "public_profile,email" }
      );
      return false;
    },

    onFailureSignIn(googleError) {
      if (googleError) {
        this.setSnackbar(true, "LOGIN GOOGLE", "Login não autorizado", "error");
      }
    },

    // LOGIN GOOGLE
    onSignIn(googleUser) {
      const profile = googleUser.getBasicProfile();
      this.usuarioSocialDados = {
        id: profile.getId(),
        name: profile.getName(),
        email: profile.getEmail(),
        imageUrl: profile.getImageUrl(),
      };

      this.usuario.main.senhas[0].idTipo = this.getTipo_item("senhaGoogle").id;

      this.auth();
    },

    setIntervalTimer() {
      //? 1000 duro
      this.intervalTimer = setInterval(() => {
        if (this.timer != 0) {
          this.timer = this.timer - 1;
        } else {
          clearInterval(this.intervalTimer);
          this.intervalTimer = null;
          this.clickNaoRecebiCodigo();
        }
      }, 1000);
    },

    async setPosition() {
      let ret = true;
      const endereco = this.usuario.main.enderecos[0];

      //??? consultar endereços em outros países
      if (endereco.idPais != this.getLocalidade("Brasil").id) {
        endereco.idCidade = 10233004;
        return ret;
      }

      if (!endereco.idCidade) {
        this.setSnackbar(
          true,
          "GEOLOCALIZAÇÃO",
          "Endereço não localizado ou não atendido no momento.",
          "error"
        );

        ret = false;
      } else {
        let url = `${this.getConst("app").baseUrlBackend}/localidades/${
          endereco.idCidade
        }/0/0`;
        const resCidade = await axios.get(url);
        const objCidade = resCidade.data ? resCidade.data.main.detail : null;
        // const objCidade = this.getLocalidade(endereco.idCidade);
        const cidade = objCidade ? objCidade.nome : null;
        const estado = objCidade ? this.getLocalidade(objCidade.idPai) : null;
        const pais = this.getLocalidade(endereco.idPais);
        const enderecoEndereco = endereco.endereco
          ? `${endereco.endereco} ${endereco.numero || ""}, `
          : "";
        const enderecoCompleto =
          `${enderecoEndereco}${cidade} ${estado.codigo2}, ${pais.nome}`.replaceAll(
            " ,",
            ","
          );
        url = `${this.getConst("app").baseUrlBackend}/mapQuest`;
        const body = {
          address: enderecoCompleto,
        };

        if (!endereco.endereco) {
          const zipcode = (
            this.usuario.main.enderecos[0].codigoPostal || ""
          ).substring(0, 5);

          body.country = pais.nome;
          body.city = cidade;
          body.zipcode = zipcode;
        }

        try {
          const res = await axios.post(url, body);
          const street = res.data[0].streetName;
          const city = res.data[0].city;
          const stateCode = res.data[0].stateCode;
          const countryCode = res.data[0].countryCode;

          if (
            (street != "" || !endereco.endereco) &&
            [city, new Buffer.from(city, "latin1").toString()].some(
              (city) =>
                city.toUpperCase().normalizeBr() ==
                cidade.toUpperCase().normalizeBr()
            ) &&
            [stateCode, new Buffer.from(stateCode, "latin1").toString()].some(
              (state) => [estado.codigo2, estado.nome].includes(state)
            ) &&
            countryCode == pais.codigo2
          ) {
            endereco.latitude = res.data[0].latitude;
            endereco.longitude = res.data[0].longitude;
          } else {
            this.setSnackbar(
              true,
              "GEOLOCALIZAÇÃO",
              `Endereço não localizado: ${enderecoCompleto}.`,
              "error"
            );

            ret = false;
          }
        } catch (err) {
          this.setSnackbar(true, "GEOLOCALIZAÇÃO", err, "error");
          ret = false;
        }
      }

      return ret;
    },

    usuarioReset() {
      this.usuario.main.documentos[0].numero = "";
      this.usuario.main.detail.nomeAlt = null;
      this.usuario.main.enderecos[0].codigoPostal = "";
      this.usuario.main.emails[0].endereco = null;
      this.usuario.main.senhas[0].senha = null;
      this.usuario.main.senhas[0].confirmar = null;
      this.idTipoLogin = this.getConst("config").tipoLogin.entrar.id;
      this.cidade = "";
    },

    // implementar
    // LOGIN APPLE
    // loginApple() {}
  },

  /// ACTIVATED
  activated() {
    this.idTipoLogin = this.getVar("idTipoLogin");
  },

  /// MOUNTED
  mounted() {
    const idConvite = this.$route.params.idConvite;

    if (idConvite) this.conviteSelected(idConvite);
  },
};
</script>