var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{attrs:{"funcs":_vm.funcsDetail,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
return [_c('c-contaPagRec-view',{attrs:{"context":context,"dataset":_vm.dataset}})]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}),(_vm.loadList)?_c('c-base-list',{attrs:{"funcs":_vm.funcsList,"idFeature":_vm.cIdFeature,"idPage":_vm.idPageAlt,"idPageAlt":_vm.idPage,"pageModeEdit":true},scopedSlots:_vm._u([{key:"insert",fn:function(ref){
var context = ref.context;
return [(context.datasets.length)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"red--text text-body-2"},[_vm._v(" selecione as contas a baixar ")])])],1)],1):_vm._e(),(context.datasets.length)?_c('v-row',{staticClass:"sticky mt-3",attrs:{"justify":"center"}},[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('div',{staticClass:"white",staticStyle:{"height":"65%"}},[_c('c-text-money',{attrs:{"classe":"font-weight-light text-h5","clearable":false,"label":"saldo a baixar","max":13,"options":{
                length: 11,
                precision: 2,
                style: 'currency',
              },"outlined":"","readonly":"","success":_vm.getTotais(context).saldo == 0,"value":_vm.getTotais(context).saldo}})],1)])],1):_vm._e()]}},{key:"default",fn:function(ref){
              var context = ref.context;
              var contaPagRec = ref.dataset;
              var index = ref.index;
return [_c('c-contaPagRec-page-baixaCruzada-edit',{attrs:{"contaPagRec":contaPagRec,"context":context,"index":index,"idPage":_vm.idPage,"saldoBaixar":_vm.saldoBaixar}})]}},{key:"bottom",fn:function(ref){
              var context = ref.context;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center","cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"classe":"font-weight-light mt-5 text-h5","clearable":false,"label":"total selecionado","max":13,"options":{
              length: 11,
              precision: 2,
              style: 'currency',
            },"outlined":"","readonly":"","value":_vm.getTotais(context).totalSelecionado}}),(_vm.getTotais(context).quantidade > 0)?_c('v-badge',{staticClass:"mb-n3 ml-n2",attrs:{"color":"grey","content":_vm.getTotais(context).quantidade,"overlap":""}}):_vm._e()],1)],1)]}}],null,false,3016602551)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }