<!-- COD: BJB 20/07/2022 CBottomSheet -->
<!-- *VER: JER 10/08/2022 CBottomSheet -->
<!-- /12/ -->

<template>
  <v-bottom-sheet v-model="bottomSheet.show">
    <v-sheet :class="bottomSheet.alignText || 'text-center'" id="sheet">
      <v-container>
        <v-row v-bind="prop.row" class="mb-4">
          <v-col v-bind="prop.col" cols="1">
            <v-btn icon @click="bottomSheet.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>

          <v-col v-bind="prop.col" align="center" cols="10">
            <span class="text-h6 py-1">
              {{ translate(bottomSheet.title) }}
            </span>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-for="(text, i) in texts" :key="i">
          <v-col v-bind="prop.col">
            <span
              v-if="!Array.isArray(text)"
              class="text-body-1 py-1"
              @click="clickAction(i, $event)"
            >
              {{ translate(text) }}
            </span>
            <span
              v-else
              v-for="(item, j) in text"
              :class="getBottomSheetClass(item, i)"
              :key="`${i}.${j}`"
              @click="getBottomSheetActions(i)"
            >
              {{ translate(item.text) }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
/// IMPORT
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CBottomSheet",

  /// MIXINS
  mixins: [mixinLib],

  /// PROPS
  props: {
    bottomSheet: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },

    show() {
      return this.bottomSheet.show;
    },

    texts() {
      let ret = "";
      if (typeof this.bottomSheet.text == "string") {
        ret = this.bottomSheet.text.split("\n");
      } else if (Array.isArray(this.bottomSheet.text)) {
        ret = this.bottomSheet.text;
      }

      return ret;
    },
  },

  /// WATCH
  watch: {
    breakpointName() {
      this.resize();
    },

    show() {
      this.resize();
    },
  },

  /// METHODS
  methods: {
    getBottomSheetActions(i) {
      return this.bottomSheet.actions
        ? this.bottomSheet.actions[i]
          ? this.bottomSheet.actions[i]()
          : null
        : null;
    },

    getBottomSheetClass(item, i) {
      return `${
        this.getBottomSheetTypes(i) == "lable" ? "text-body-2" : "text-body-1"
      } py-1 ${item.class}`;
    },

    getBottomSheetTypes(i) {
      return this.bottomSheet.types ? this.bottomSheet.types[i] : null;
    },

    resize() {
      if (this.show) {
        setTimeout(() => {
          const sheet = document.getElementById("sheet");
          sheet.style.height = `100%`;
          sheet.style.height = `${
            sheet.offsetHeight + this.getConst("config").bottomSheet.heightBase
          }px`;
        }, 100);
      }
    },
  },
};
</script>