// COD: BJB 15/08/2022 bancos
// *VER: JER 19/08/2022 bancos
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    bancos: []
  },

  /// GETTERS
  getters: {
    getBancos: state => (prop = "id") => {
      return state.bancos
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nomeAlt,
            idPais: element.idPais,
            image: element.endereco,
          };
        })
    },

    getBanco: state => id => {
      return state.bancos.length
        ? state.bancos
          .find(element => element.id == id)
        : { id }
    }
  },

  /// MUTATIONS
  mutations: {
    setBancos: (state, payload) => {
      state.bancos = []
      payload.forEach(element => {
        state.bancos.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setBancos: (context, payload) => {
      context.commit("setBancos", payload)
    }
  }
}