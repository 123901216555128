<!-- COD: FDS 04/07/2022 NotaFiscal -->
<!-- *VER: JER 03/08/2022 NotaFiscal -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-notaFiscal-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-notaFiscal-edit v-else :context="context" :dataset="dataset" />
        </div>
        <c-notaFiscal-dialog-notaFiscal
          v-if="dataset.main.detail.id && showPrint"
          v-model="showPrint"
          :notaFiscal="dataset"
        />
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CNotaFiscalDialogNotaFiscal from "@/components/pages/notaFiscal/dialogs/CNotaFiscalDialogNotaFiscal";
import CNotaFiscalEdit from "@/components/pages/notaFiscal/CNotaFiscalEdit";
import CNotaFiscalView from "@/components/pages/notaFiscal/CNotaFiscalView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "NotaFiscal",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CNotaFiscalDialogNotaFiscal,
    CNotaFiscalEdit,
    CNotaFiscalView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("notaFiscal").id,
      idPageAlt: getPagina("notasFiscais").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("pedidoEntrada").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            notaFiscal: null,
            serie: null,
            data: null,
            isEmitente: null,
            idOperacao: null,
            operacao: null,
            dataEntradaSaida: null,
            idColaborador: null,
            colaborador: null,
            idPedido: null,
            itens: null,
            totalProdutos: null,
            totalImpostos: null,
            valorFrete: null,
            totalNota: null,
            idModalTransporte: null,
            modalTransporte: null,
            idIncoterm: null,
            incoterm: null,
            volumes: null,
            pesoBruto: null,
            pesoLiquido: null,
            chaveAcesso: null,
            seuPedido: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          pessoas: [],
          itens: [],
          duplicatas: [],
          mensagens: [],
          referencias: [],
        },
        aux: {
          detail: {
            pessoaParceiro_nomeAlt: null,
          },
          referencias: [],
        },
      },

      funcs: {
        clickImprimir: async () => {
          this.showPrint = true;
        },

        clickNumerosSerie: async (context) => {
          this.$router.push(
            `/pedido/numerosserie/${context.dataset.main.detail.idPedido}`
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },
      },

      params: {
        context: null,
      },

      showPrint: false,
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>