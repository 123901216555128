// COD: LYF 10/08/2022 mixinPageDetail
// *VER: JER 19/08/2022 mixinPageDetail
// /12/

import CBaseDetail from "@/components/bases/CBaseDetail";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinPage from "@/mixins/mixinPage";

export default {
  /// NAME
  name: "mixinPageDetail",

  /// COMPONENTS
  components: { CBaseDetail },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinPage],

  /// DATA
  data() {
    return {
      load: false,
      rules: {
        date: [value => (!!value && value.length == 10) || "campo requerido"],
        dateTime: [value => (!!value && value.length == 16) || "campo requerido"],
        inputCodigoBarra: [
          value =>
            !value ||
            value.length == 0 ||
            value.length >= 13 ||
            "vazio ou completo",
          value => !value || value.length == 0 ||
            this.codigoBarraVerificar(value) || "DV inválido"
        ],
        inputDocumento: [
          value =>
            !value ||
            this.dataset.detail.idPais !=
            this.getConst("localidades").paises.brasil.id ||
            (this.const_idTipoDocumento != this.getTipo_item('documentoPFFederal').id &&
              this.const_idTipoDocumento != this.getTipo_item('documentoPJFederal').id) ||
            (this.dataset.detail.idPais ==
              this.getConst("localidades").paises.brasil.id &&
              this.const_idTipoDocumento == this.getTipo_item('documentoPFFederal').id &&
              value.length == 14) ||
            (this.dataset.detail.idPais ==
              this.getConst("localidades").paises.brasil.id &&
              this.const_idTipoDocumento == this.getTipo_item('documentoPJFederal').id &&
              value.length == 18) ||
            "preenchimento completo",
          value =>
            !value ||
            value.clean().length >= this.documentoDigitos ||
            `mínimo ${this.documentoDigitos} digitos`,
          value =>
            !value ||
            !(
              this.dataset.detail.idPais ==
              this.getConst("localidades").paises.brasil.id &&
              (this.const_idTipoDocumento == this.getTipo_item('documentoPFFederal').id ||
                this.const_idTipoDocumento == this.getTipo_item('documentoPJFederal').id)
            ) ||
            this.documentoVerificar(
              value,
              this.dataset.detail.idPais,
              this.const_idTipoDocumento,
              0
            ) ||
            "DV incorreto"
        ],
        inputMaior0: [
          value => (!!value && value.clean(0) >= 1) || "maior que 0,00"
        ],
        inputMaiorZero: [
          value => (!!value && value.clean(2) > 0) || "maior que zero"
        ],
        inputMin: length => [
          value => (!!value && value.length >= length) || `mínimo ${length} ${length <= 1 ? 'caracter' : 'caracteres'}`
        ],
        inputMin1: [
          value =>
            (!!value && value.toString().length >= 1) || "mínimo 1 caracteres"
        ],
        inputMin2: [
          value => (!!value && value.length >= 2) || "mínimo 2 caracteres"
        ],
        inputMin5: [
          value => (!!value && value.length >= 5) || "mínimo 5 caracteres"
        ],
        quantidade: [
          value =>
            (!!value && value.toString().length >= 1) || "mínimo 1",
          value =>
            (!!value && value >= (this.dataset.utilizados || 0)) || `mínimo ${this.dataset.utilizados}`
        ],
        select: [value => !!value || "campo requerido"]
      }
    }
  },

  /// METHODS
  methods: {
    //?b Por que este metodo esta neste arquivo?
    codigoBarraVerificar(value) {
      const tamanho = 13

      if (!value || value.length != tamanho) {
        return false
      }

      const digitos = 1
      const modulo = 10
      const posicao = tamanho - digitos
      const codigoNumero = value.slice(0, posicao)
      const codigoDigito = value.slice(posicao)
      const codigoArray = codigoNumero.split("")

      const soma = codigoArray
        .reduce((acc, numero, i) => acc + (parseInt(numero) * (i % 2 ? 3 : 1)), 0)

      const digitoTemp = ((Math.floor(soma / modulo) + 1) * modulo) - soma

      const digitoCalculado = digitoTemp < modulo ? digitoTemp : 0

      return codigoDigito == digitoCalculado
    },

    inicio() {
      return new Promise(resolve => {
        let timer = setInterval(() => {
          if (this.getCategoriasLength()) {
            clearInterval(timer);
            timer = null;
            this.load = true
            resolve();
          }
        }, 10);
      });
    }
  }
}