<!-- #COD: FDS 13/04/2023 CBaseDialogComprar -->
<!-- VER: JER __/__/2023 CBaseDialogComprar -->
<!-- /11/ -->

<template>
  <v-dialog v-model="show" fullscreen persistent scrollable>
    <v-card max-width="580">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-row v-bind="prop.row">
            <!-- botao fechar -->
            <v-col v-bind="prop.col" cols="1">
              <v-btn class="ml-n4" icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <!-- titulo -->
            <v-col v-bind="prop.col" align="center" cols="10">
              <v-toolbar-title>COMPRAR</v-toolbar-title>
            </v-col>
            <v-col v-bind="prop.col" cols="1"> </v-col>
          </v-row>
        </v-toolbar>
        <v-progress-linear
          v-model="percentComplete"
          class="mt-1 zindex"
          color="green"
          height="5"
        />
      </v-card-title>

      <v-card-text id="configurador" class="mx-0 pa-2" ref="configurador">
        <!-- //? usar c-base-detail-imagens -->
        <!-- imagem -->
        <v-row
          v-bind="prop.row"
          v-show="isModeResumo"
          id="images"
          class="ma-n2"
        >
          <v-col v-bind="prop.col" align="center">
            <v-carousel
              cycle
              :height="200"
              hide-delimiters
              :interval="getConst('carousel').interval"
              progress
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(imagem, i) in imagens"
                contain
                :key="i"
                :src="imagem.endereco"
              >
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <v-form ref="form">
          <v-container class="pa-1">
            <div v-if="isModeResumo">
              <!-- produto -->
              <v-row v-bind="prop.row" v-if="dataset.main" class="my-2">
                <v-col v-bind="prop.col" align="center">
                  <span class="font-weight-medium text-body-1">
                    {{ produtoFormat(dataset.main.detail) }}
                  </span>
                </v-col>
                <v-col v-bind="prop.col" align="center" cols="1"> </v-col>
              </v-row>

              <!-- favoritar -->
              <!-- 
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-btn
                    v-if="produto.favorito !== undefined"
                    classe="mr-n2"
                    icon
                    @click.stop="favoritar()"
                  >
                    <v-icon
                      :class="
                        !!produto.favorito
                          ? 'text--darken-2 yellow--text'
                          : 'grey--text'
                      "
                    >
                      {{ !!produto.favorito ? "star" : "star_outline" }}
                    </v-icon>
                  </c-btn>
                </v-col>
              </v-row>
              -->

              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="center" cols="4">
                  <v-row v-bind="prop.row">
                    <!-- botao menos -->
                    <v-col v-bind="prop.col" cols="3">
                      <c-btn
                        class="ml-n3"
                        :disabled="quantidadeTotal == 1"
                        icon
                        @click="clickQuantidadeTotal(-1)"
                      >
                        <v-icon class="red--text"
                          >mdi-minus-circle-outline</v-icon
                        >
                      </c-btn>
                    </v-col>
                    <!-- quantidade -->
                    <v-col v-bind="prop.col" cols="6">
                      <c-text-money
                        v-model="quantidadeTotal"
                        classe=""
                        :clearable="false"
                        dense
                        label=" "
                        :max="3"
                        :options="{
                          length: 3,
                          precision: 0,
                        }"
                      />
                    </v-col>
                    <!-- botao mais -->
                    <v-col v-bind="prop.col" cols="3">
                      <c-btn
                        class="ml-n2"
                        :disabled="quantidadeTotal == 999"
                        icon
                        @click="clickQuantidadeTotal(1)"
                      >
                        <v-icon class="green--text"
                          >mdi-plus-circle-outline</v-icon
                        >
                      </c-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- preco unitario -->
                <v-col v-bind="prop.col" cols="3">
                  <span class="ml-2 text-body-2">
                    {{ numberFormat(precoTotal, "currency", 2) }}
                  </span>
                </v-col>

                <!-- preco total -->
                <v-col v-bind="prop.col" align="end" cols="5">
                  <span class="text-body-1">
                    {{
                      numberFormat(precoTotal * quantidadeTotal, "currency", 2)
                    }}
                  </span>
                </v-col>
              </v-row>

              <div v-if="carrinho && carrinho.main && carrinho.main.detail.id">
                <!-- atualizar ao carrinho  -->
                <v-row v-bind="prop.row" class="mt-10">
                  <v-col>
                    <c-btn
                      block
                      classe="mx-0"
                      color="primary"
                      dark
                      height="50"
                      @click="clickAtualizarCarrinho()"
                    >
                      atualizar carrinho
                    </c-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <!-- adicionar ao carrinho  -->
                <v-row
                  v-bind="prop.row"
                  v-if="
                    !hasItensInconsistentes &&
                    (!auxDetail.parceiro_hasBotaoComprarAgora ||
                      !auxDetail.parceiro_isBotaoComprarAgoraAcima)
                  "
                  class="mt-10"
                >
                  <v-col>
                    <c-btn
                      block
                      classe="mx-0"
                      color="primary"
                      dark
                      height="50"
                      @click="clickComprar(false)"
                    >
                      adicionar ao carrinho
                    </c-btn>
                  </v-col>
                </v-row>

                <!-- comprar agora  -->
                <v-row
                  v-bind="prop.row"
                  v-if="
                    !hasItensInconsistentes &&
                    auxDetail.parceiro_hasBotaoComprarAgora &&
                    !auxDetail.parceiro_isBotaoComprarAgoraAcima
                  "
                  class="mb-4"
                >
                  <v-col>
                    <c-btn
                      block
                      classe="mb-2 mx-0"
                      color="primary"
                      dark
                      height="50"
                      outlined
                      @click="clickComprar(true)"
                    >
                      comprar agora
                    </c-btn>
                  </v-col>
                </v-row>

                <!-- comprar agora  -->
                <v-row
                  v-bind="prop.row"
                  v-if="
                    !hasItensInconsistentes &&
                    auxDetail.parceiro_hasBotaoComprarAgora &&
                    auxDetail.parceiro_isBotaoComprarAgoraAcima
                  "
                  class="mt-10"
                >
                  <v-col>
                    <c-btn
                      block
                      classe="mx-0"
                      color="primary"
                      dark
                      height="50"
                      @click="clickComprar(true)"
                    >
                      comprar agora
                    </c-btn>
                  </v-col>
                </v-row>

                <!-- adicionar ao carrinho  -->
                <v-row
                  v-bind="prop.row"
                  v-if="
                    !hasItensInconsistentes &&
                    auxDetail.parceiro_hasBotaoComprarAgora &&
                    auxDetail.parceiro_isBotaoComprarAgoraAcima
                  "
                  class="mb-4"
                >
                  <v-col>
                    <c-btn
                      block
                      classe="mb-2 mx-0"
                      color="primary"
                      dark
                      height="50"
                      outlined
                      @click="clickComprar(false)"
                    >
                      adicionar ao carrinho
                    </c-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-else class="sticky white">
              <!-- //? sticky nao esta funcionando -->
              <v-row v-bind="prop.row" class="mt-0">
                <!-- imagem -->
                <v-col v-bind="prop.col" align="center" cols="3">
                  <c-base-image
                    :endereco="dataset.aux.imagens[0].endereco || ''"
                    height="100"
                  />
                </v-col>
                <v-col v-bind="prop.col" cols="9">
                  <!-- produto -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col">
                      <span class="text-body-2">
                        {{ produtoFormat(produto) }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row v-bind="prop.row" class="mt-n1">
                    <!-- badge -->
                    <v-col v-bind="prop.col" cols="2">
                      <v-badge
                        class="ml-n1"
                        color="grey"
                        :content="quantidadeTotal"
                        inline
                        overlap
                        :value="quantidadeTotal"
                      />
                    </v-col>
                    <!-- valor unitario -->
                    <v-col
                      v-bind="prop.col"
                      class="d-flex justify-space-between"
                      cols="10"
                    >
                      <span class="ml-2 text-body-2">
                        {{ numberFormat(precoTotal, "currency", 2) }}
                      </span>
                      <!-- valor total -->
                      <span class="text-body-1">
                        {{
                          numberFormat(
                            precoTotal * quantidadeTotal,
                            "currency",
                            2
                          )
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- tabs -->
              <v-row v-bind="prop.row" class="mx-n3">
                <!-- //? remover -->
                <!-- background-color="white" -->
                <v-col v-bind="prop.col">
                  <v-tabs centered class="my-2" show-arrows>
                    <v-tab
                      v-for="(grupo, i) of grupos"
                      :key="i"
                      @click="goToPanel(i)"
                    >
                      {{ grupo.grupo }}
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
            </div>

            <!-- :multiple="!isModeResumo" -->
            <c-expansion-panels
              v-if="grupos.length"
              :colorHeader="
                panelsOpen.length ? 'grey lighten-1' : 'grey lighten-3'
              "
              flat
              :hasPanelsCollapse="!isModeResumo"
              :multiple="!isModeResumo"
              :open="panelsOpen"
              :pageModeView="true"
              :panel="{ nomeAlt: `grupoConfigurador` }"
              :value="grupos"
              @clickPanels="panelsOpen = $event"
              @panelsOpen="panelsOpen = $event"
            >
              <template #header="{ dataset: grupo, index }">
                <v-row v-bind="prop.row" class="my-n3" :id="`grupo${index}`">
                  <!-- imagem -->
                  <v-col v-bind="prop.col" align="center" cols="2">
                    <c-base-image
                      v-if="!(parseFloat(grupo.quantidadeSelecionada) == 0)"
                      :endereco="grupo.endereco || ''"
                      estilo=""
                      height="auto"
                    />
                  </v-col>
                  <v-col v-bind="prop.col" cols="10">
                    <!-- nome -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <span class="text-body-1">
                          {{ grupo.nome || grupo.grupo }}
                        </span>
                      </v-col>
                    </v-row>

                    <v-row v-bind="prop.row">
                      <!-- badge -->
                      <v-col v-bind="prop.col" cols="1">
                        <v-badge
                          class="ml-n1"
                          :color="colorBadgeGrupo(grupo)"
                          :content="badgeGrupo(grupo).badge"
                          inline
                          overlap
                          :value="badgeGrupo(grupo).badge"
                        />
                      </v-col>
                      <!-- valor total -->
                      <v-col v-bind="prop.col" align="end" cols="11">
                        <span
                          :class="`${
                            badgeGrupo(grupo).total < 0 ? 'red--text' : ''
                          } text-body-1`"
                        >
                          {{
                            numberFormat(badgeGrupo(grupo).total, "currency", 2)
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template #content="{ dataset: grupo, index: indexGrupo }">
                <div class="mx-n4 pl-3">
                  <c-expansion-panels
                    flat
                    :hasPanelsCollapse="!isModeResumo"
                    :multiple="!isModeResumo"
                    :open="panelsOpenItens[grupo.idProduto]"
                    :pageModeView="true"
                    :panel="{ nomeAlt: `item${grupo.idProduto}` }"
                    :prop="{ expansionPanelHeader: { color: 'red' } }"
                    :value="gruposItens(grupo)"
                    @clickPanels="panelsOpenItens[grupo.idProduto] = $event"
                    @panelsOpen="panelsOpenItens[grupo.idProduto] = $event"
                  >
                    <template #header="{ dataset: item }">
                      <v-row v-bind="prop.row" class="my-n3">
                        <!-- imagem -->
                        <v-col v-bind="prop.col" align="center" cols="2">
                          <c-base-image
                            :endereco="headerItem(grupo, item).foto"
                            estilo=""
                            height="auto"
                          />
                        </v-col>

                        <!-- nome -->
                        <v-col v-bind="prop.col" cols="10">
                          <v-row v-bind="prop.row">
                            <v-col v-bind="prop.col">
                              <span class="text-body-2">
                                {{ headerItem(grupo, item).nome }}
                              </span>
                            </v-col>
                          </v-row>

                          <v-row v-bind="prop.row">
                            <!-- badge -->
                            <v-col v-bind="prop.col" cols="1">
                              <v-badge
                                class="ml-n1"
                                :color="headerItem(grupo, item).color"
                                :content="headerItem(grupo, item).badge"
                                inline
                                overlap
                                :value="headerItem(grupo, item).badge"
                              />
                            </v-col>
                            <!-- valor total -->
                            <v-col v-bind="prop.col" align="end" cols="11">
                              <span
                                :class="`${
                                  headerItem(grupo, item).total < 0
                                    ? 'red--text'
                                    : ''
                                } text-body-1`"
                              >
                                {{
                                  numberFormat(
                                    headerItem(grupo, item).total,
                                    "currency",
                                    2
                                  )
                                }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                    <template #content="{ dataset: item, index }">
                      <div
                        v-for="(itemProduto, i) of itensProduto(
                          grupo.idProduto,
                          item
                        )"
                        :key="`itemProduto${i}`"
                      >
                        <!-- escolha até x itens -->
                        <v-row v-bind="prop.row" v-if="i == 0">
                          <v-col v-bind="prop.col" align="center">
                            <span class="my-2 text-caption">
                              {{ labelEscolhaItens(itemProduto) }}
                            </span>
                          </v-col>
                        </v-row>

                        <!-- divider -->
                        <v-divider
                          v-if="itemProduto.hasDivider"
                          class="mt-n2 primary"
                        ></v-divider>

                        <v-row v-bind="prop.row" class="mt-0">
                          <!-- imagem -->
                          <v-col v-bind="prop.col" align="center" cols="2">
                            <c-base-image
                              :endereco="itemProduto.endereco || ''"
                              height="100"
                            />
                          </v-col>
                          <v-col v-bind="prop.col" cols="10">
                            <!-- produto -->
                            <v-row v-bind="prop.row">
                              <v-col v-bind="prop.col">
                                <span class="text-body-2">
                                  {{ produtoFormat(itemProduto, true) }}
                                </span>
                              </v-col>
                            </v-row>

                            <!-- componente -->
                            <v-row v-bind="prop.row" class="mt-n3">
                              <v-col v-bind="prop.col" cols="4">
                                <!-- checkbox -->
                                <div
                                  v-if="
                                    componente(
                                      grupo,
                                      item,
                                      itemProduto,
                                      'checkBox'
                                    )
                                  "
                                >
                                  <c-checkbox
                                    label=""
                                    :value="
                                      !!parseFloat(
                                        itemProduto.quantidadeSelecionada
                                      )
                                    "
                                    @change="
                                      changeCheckbox(itemProduto, $event)
                                    "
                                  />
                                </div>

                                <!-- radioButton -->
                                <div
                                  v-else-if="
                                    componente(
                                      grupo,
                                      item,
                                      itemProduto,
                                      'radioButton'
                                    )
                                  "
                                >
                                  <v-radio-group
                                    :value="radioValue(grupo.idProduto, item)"
                                  >
                                    <v-radio
                                      label=""
                                      :value="itemProduto.id"
                                      @change="
                                        changeRadioValue(
                                          grupo,
                                          item,
                                          itemProduto
                                        )
                                      "
                                    ></v-radio>
                                  </v-radio-group>
                                </div>

                                <!-- botoes menos/mais -->
                                <div
                                  v-else-if="
                                    componente(
                                      grupo,
                                      item,
                                      itemProduto,
                                      'botoes'
                                    )
                                  "
                                >
                                  <v-row v-bind="prop.row">
                                    <!-- botao menos -->
                                    <v-col v-bind="prop.col" cols="3">
                                      <c-btn
                                        class="ml-n3"
                                        :disabled="
                                          disabledBotaoMenos(item, itemProduto)
                                        "
                                        icon
                                        @click="
                                          changeQuantidadeItem(itemProduto, -1)
                                        "
                                      >
                                        <v-icon class="red--text"
                                          >mdi-minus-circle-outline</v-icon
                                        >
                                      </c-btn>
                                    </v-col>
                                    <!-- quantidade -->
                                    <v-col
                                      v-bind="prop.col"
                                      align="center"
                                      cols="6"
                                    >
                                      <span
                                        v-if="itemProduto.quantidadeSelecionada"
                                        class="text-body-1"
                                      >
                                        {{
                                          numberFormat(
                                            itemProduto.quantidadeSelecionada,
                                            "decimal",
                                            0
                                          )
                                        }}
                                      </span>
                                    </v-col>
                                    <!-- botao mais -->
                                    <v-col v-bind="prop.col" cols="3">
                                      <c-btn
                                        class="ml-n3"
                                        :disabled="
                                          disabledBotaoMais(item, itemProduto)
                                        "
                                        icon
                                        @click="
                                          changeQuantidadeItem(itemProduto, 1)
                                        "
                                      >
                                        <v-icon class="green--text"
                                          >mdi-plus-circle-outline</v-icon
                                        >
                                      </c-btn>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-col>
                              <!-- valor unitario -->
                              <v-col v-bind="prop.col" cols="4">
                                <span v-if="itemProduto.precoUnitario >= 0">
                                  +
                                </span>
                                <span
                                  :class="`${
                                    itemProduto.precoUnitario < 0
                                      ? 'red--text'
                                      : ''
                                  } ml-n1 text-caption`"
                                >
                                  {{
                                    numberFormat(
                                      itemProduto.precoUnitario,
                                      "currency",
                                      2
                                    )
                                  }}
                                </span>
                              </v-col>
                              <!-- valor total -->
                              <v-col
                                v-bind="prop.col"
                                v-if="
                                  parseFloat(
                                    itemProduto.quantidadeSelecionada
                                  ) > 0
                                "
                                align="end"
                                cols="4"
                              >
                                <span
                                  :class="`${
                                    itemProduto.precoSelecionado < 0
                                      ? 'red--text'
                                      : ''
                                  } text-body-2`"
                                >
                                  {{
                                    numberFormat(
                                      parseFloat(
                                        itemProduto.precoSelecionado || 0
                                      ),
                                      "currency",
                                      2
                                    )
                                  }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!-- divider -->
                        <v-divider class="mb-2 mt-n2" />
                      </div>

                      <!-- ir para o proximo panel -->
                      <v-row v-bind="prop.row">
                        <v-col v-bind="prop.col" align="end">
                          <v-btn
                            class="mr-n4"
                            icon
                            @click="clickNext(grupo, indexGrupo, index)"
                          >
                            <v-icon color="primary" large
                              >mdi-arrow-down</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </c-expansion-panels>
                </div>
              </template>
            </c-expansion-panels>

            <!-- botao confirmar itens -->
            <div v-if="grupos.length">
              <v-row v-bind="prop.row" v-if="isModeResumo" class="mb-4 mt-10">
                <v-col v-bind="prop.col">
                  <c-btn
                    block
                    classe="mb-2 mx-0"
                    color="primary"
                    height="50"
                    @click="clickConfirmarItens(false)"
                  >
                    confirmar itens
                  </c-btn>
                </v-col>
              </v-row>

              <!-- botao resumo -->
              <v-row v-bind="prop.row" v-else class="mt-4">
                <v-col v-bind="prop.col">
                  <c-btn
                    block
                    classe="mb-2 mx-0"
                    color="primary"
                    height="50"
                    @click="clickResumo"
                  >
                    resumo
                  </c-btn>
                </v-col>
              </v-row>
            </div>

            <div v-if="isModeResumo" class="mt-4">
              <!-- argumento venda -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <p class="text-body-2 text-justify">
                    {{ getValorTraduzido(produto, "argumentoVenda") }}
                  </p>
                </v-col>
              </v-row>

              <c-expansion-panels
                v-if="panelsAdicionais.length && dataset.main"
                class="mb-10"
                flat
                :open="[]"
                :pageModeView="true"
                :panel="{ nomeAlt: `panelsAdicionais` }"
                :value="panelsAdicionais"
              >
                <template #header="{ dataset: panel }">
                  <v-row v-bind="prop.row" class="my-n3">
                    <!-- nome do panel -->
                    <v-col v-bind="prop.col" cols="11">
                      <span class="text-body-1">
                        {{ panel }}
                      </span>
                    </v-col>
                    <!-- badge -->
                    <v-col v-bind="prop.col" align="end" cols="1">
                      <v-badge
                        class="ml-n1"
                        color="grey"
                        :content="badgePanelsAdicionais(panel)"
                        inline
                        overlap
                        :value="badgePanelsAdicionais(panel)"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template #content="{ dataset: panel }">
                  <c-produto-panel-caracteristica-view
                    v-if="dataset.main && panel == 'características'"
                    :dataset="dataset"
                  />
                  <div v-else>
                    <!-- codigo barra -->
                    <v-row
                      v-bind="prop.row"
                      v-if="dataset.main.detail.codigoBarra"
                    >
                      <v-col v-bind="prop.col" align="end" cols="6">
                        <span class="text-body-2">código barra</span>
                      </v-col>
                      <v-col v-bind="prop.col" cols="6">
                        <span class="text-body-1">
                          {{ dataset.main.detail.codigoBarra }}
                        </span>
                      </v-col>
                    </v-row>

                    <!-- origem -->
                    <v-row
                      v-bind="prop.row"
                      v-if="dataset.main.detail.idOrigem"
                    >
                      <v-col v-bind="prop.col" align="end" cols="6">
                        <span class="text-body-2">origem</span>
                      </v-col>
                      <v-col v-bind="prop.col" cols="6">
                        <c-help
                          estilo="width: fit-content"
                          :help="{
                            title: 'ORIGEM',
                            text: `0 - Nacional, exceto códigos 3, 4, 5 e 8
1 - Estrangeira - importação direta, exceto código 6
2 - Estrangeira - adquirida no mercado interno, exceto código 7
3 - Nacional - com conteúdo de importação > 40% e <= 70%
4 - Nacional - produção em  conformidade com os processos produtivos básicos 
5 - Nacional - com conteúdo de importação <= 40% 
6 - Estrangeira - importação direta, sem similar nacional, constante em lista CAMEX
7 - Estrangeira - adquirida no mercado interno, sem similar nacional, constante em lista CAMEX
8 - Nacional - com conteúdo de importação > 70%`,
                            function: setUiBottomSheet,
                            alignText: 'text-left',
                          }"
                        >
                          <span class="text-body-1">
                            {{
                              getTipo_item(dataset.main.detail.idOrigem).codigo
                            }}
                          </span>
                        </c-help>
                      </v-col>
                    </v-row>

                    <!-- codigo fiscal -->
                    <v-row
                      v-bind="prop.row"
                      v-if="
                        labelCodigoFiscal && dataset.main.detail.codigoFiscal
                      "
                    >
                      <v-col v-bind="prop.col" align="end" cols="6">
                        <span class="text-body-2">{{ labelCodigoFiscal }}</span>
                      </v-col>
                      <v-col v-bind="prop.col" cols="6">
                        <span class="text-body-1">
                          {{ dataset.main.detail.codigoFiscal }}
                        </span>
                      </v-col>
                    </v-row>

                    <!-- codigo fiscal 2 -->
                    <v-row
                      v-bind="prop.row"
                      v-if="
                        labelCodigoFiscal2 && dataset.main.detail.codigoFiscal2
                      "
                    >
                      <v-col v-bind="prop.col" align="end" cols="6">
                        <span class="text-body-2">{{
                          labelCodigoFiscal2
                        }}</span>
                      </v-col>
                      <v-col v-bind="prop.col" cols="6">
                        <span class="text-body-1">
                          {{ dataset.main.detail.codigoFiscal2 }}
                        </span>
                      </v-col>
                    </v-row>

                    <!-- prazo garantia -->
                    <v-row
                      v-bind="prop.row"
                      v-if="dataset.main.detail.prazoGarantia"
                    >
                      <v-col v-bind="prop.col" align="end" cols="6">
                        <span class="text-body-2">garantia</span>
                      </v-col>
                      <v-col v-bind="prop.col" cols="6">
                        <span class="text-body-1">
                          {{ dataset.main.detail.prazoGarantia }}
                        </span>
                        <span class="ml-0 text-body-2">meses</span>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </c-expansion-panels>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import CBaseImage from "@/components/bases/CBaseImage";
import CProdutoPanelCaracteristicaView from "@/components/pages/produto/caracteristica/CProdutoPanelCaracteristicaView";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "CBaseDialogComprar",

  /// MIXINS
  mixins: [
    mixinBottomSheet,
    mixinCarrinhos,
    mixinSnackbar,
    mixinSwipe,
    mixinUtil,
  ],

  /// COMPONENTS
  components: {
    CBaseImage,
    CProdutoPanelCaracteristicaView,
  },

  /// PROPS
  props: {
    carrinho: { type: Object, required: false, default: null },
    produto: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {
      carousel: 0,
      dataset: {},
      datasets: [],
      isLastPanelOpen: true,
      isModeResumo: true,
      itens: [],
      panelsOpen: [],
      panelsOpenItens: {},
      quantidadeTotal: 1,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    auxDetail() {
      return this.dataset?.aux?.detail || {};
    },

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    grupos() {
      if (
        this.dataset.main &&
        this.dataset.main.detail.idTipo ==
          this.getTipo_item("produtoProduto").id &&
        this.datasets.length > 0
      ) {
        return [
          {
            nome: this.produtoFormat(this.dataset.main.detail),
            grupo: this.dataset.main.detail.produto,
            idProduto: this.dataset.main.detail.id,
            idTipo: this.dataset.main.detail.idTipo,
            isProduto: true,
            endereco: this.dataset.aux.imagens.length
              ? this.dataset.aux.imagens[0].endereco
              : "",
          },
        ];
      }

      const grupos = this.datasets.filter(
        (dataset) => dataset.idPai == this.dataset.main.detail.id
      );

      const filhoSelecionado = this.datasets.filter(
        (dataset) =>
          parseFloat(dataset.quantidadeSelecionada) > 0 &&
          !dataset.idPai2 &&
          grupos
            .filter(
              (grupo) =>
                grupo.idTipo != this.getTipo_item("produtoProduto").id ||
                (grupo.idTipo == this.getTipo_item("produtoProduto").id &&
                  dataset.id == grupo.id)
            )
            .map((grupo) => grupo.idProduto)
            .includes(dataset.idPai)
      );

      this.datasets
        .filter((dataset) => dataset.idPai == this.dataset.main.detail.id)
        .forEach((dataset) => {
          const filhosDataset = filhoSelecionado.filter(
            (filho) => filho.idPai == dataset.idProduto
          );

          if (filhosDataset.length == 1) {
            dataset.nome = this.produtoFormat(filhosDataset[0]);
            dataset.endereco = filhosDataset[0].endereco;
            dataset.quantidadeSelecionada =
              filhosDataset[0].quantidadeSelecionada;
          }
        });

      return grupos;
    },

    hasItensInconsistentes() {
      this.checkOk();
      return this.grupos.some((grupo) => !grupo.isOk);
    },

    imagens() {
      return this.dataset?.aux?.imagens || [];
    },

    labelCodigoFiscal() {
      return this.getLocalidade(this.dadosParceiro.enderecos[0].idPais).idPai ==
        this.getLocalidade("América do Sul").id &&
        [
          this.getTipo_item("produtoProduto").id,
          this.getTipo_item("produtoComplemento").id,
          this.getTipo_item("produtoGrupo").id,
          this.getTipo_item("produtoCombo").id,
        ].includes(this.dataset.main.detail.idTipo)
        ? "NCM"
        : this.dadosParceiro.enderecos[0].idPais ==
            this.getLocalidade("Brasil").id &&
          this.getTipo_item("produtoServico").id ==
            this.dataset.main.detail.idTipo
        ? "código prestação"
        : null;
    },

    labelCodigoFiscal2() {
      return this.getLocalidade(this.dadosParceiro.enderecos[0].idPais).idPai ==
        this.getLocalidade("América do Sul").id &&
        [
          this.getTipo_item("produtoProduto").id,
          this.getTipo_item("produtoComplemento").id,
          this.getTipo_item("produtoGrupo").id,
          this.getTipo_item("produtoCombo").id,
        ].includes(this.dataset.main.detail.idTipo)
        ? "CEST"
        : this.dadosParceiro.enderecos[0].idPais ==
            this.getLocalidade("Brasil").id &&
          this.getTipo_item("produtoServico").id ==
            this.dataset.main.detail.idTipo
        ? null
        : null;
    },

    panelsAdicionais() {
      const panels = [];

      const hasCaracteristicas =
        this.dataset.main &&
        (this.dataset.main.detail.pesoBruto ||
          this.dataset.main.detail.pesoLiquido ||
          this.dataset.main.detail.volume ||
          this.dataset.main.detail.largura ||
          this.dataset.main.detail.altura ||
          this.dataset.main.detail.profundidade ||
          this.dataset.main.detail.unidadesPorCaixa ||
          this.dataset.main.detail.cubagem ||
          this.dataset.main.detail.pesoCubadoRodoviario ||
          this.dataset.main.detail.pesoCubadoAereo ||
          this.getValorTraduzido(this.dataset.main.detail, "caracteristicas")
            .length);

      if (hasCaracteristicas) {
        panels.push("características");
      }

      if (
        this.dataset.main &&
        (this.dataset.main.detail.codigoBarra ||
          this.dataset.main.detail.idOrigem ||
          this.dataset.main.detail.codigoFiscal ||
          this.dataset.main.detail.codigoFiscal2 ||
          this.dataset.main.detail.prazoGarantia)
      ) {
        panels.push("outras informações");
      }

      return panels;
    },

    percentComplete() {
      const gruposOk = this.grupos.filter((grupo) => grupo.isOk);
      return (gruposOk.length / this.grupos.length) * 100;
    },

    precoTotal() {
      const datasetPai = this.datasets.find(
        (dataset) => dataset.idPai == dataset.idProduto
      );

      const valorReferencia = this.datasets
        .filter((dataset) => {
          const diferencaSelecionadoDefault =
            parseFloat(dataset.quantidadeSelecionada || 0) -
            parseFloat(dataset.quantidadeDefault || 0);

          return (
            dataset.isItemReferencia &&
            (dataset.idPai != datasetPai?.idPai ||
              diferencaSelecionadoDefault > 0)
          );
        })
        .reduce(
          (acc, item) =>
            (acc += parseFloat(item.precoSelecionado || item.preco || 0)),
          0
        );

      const valorSemReferencia = this.datasets
        .filter(
          (dataset) =>
            !dataset.isItemReferencia &&
            parseFloat(dataset.quantidadeSelecionada || 0) > 0 &&
            dataset.id != datasetPai?.id
        )
        .reduce(
          (acc, item) => (acc += parseFloat(item.precoSelecionado || 0)),
          0
        );

      return (
        parseFloat(datasetPai?.preco || this.dataset.main?.detail.preco || 0) +
        valorReferencia +
        valorSemReferencia
      );
    },
  },

  /// MOUNTED
  async mounted() {
    await this.clickSearch();
  },

  /// WATCH
  watch: {
    isModeResumo: {
      handler() {
        this.setPanelsOpen();
        this.grupos.forEach((grupo) => {
          this.setPanelsOpenItens(grupo.idProduto);
        });
      },
      immediate: true,
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    badgeGrupo(grupo) {
      const retorno = {
        badge: 0,
        total: 0,
      };

      const datasets = this.datasets.filter(
        (dataset) =>
          dataset.idPai == grupo.idProduto &&
          parseFloat(dataset.quantidadeSelecionada) >= 1
      );

      retorno.badge = datasets
        .filter(
          (dataset) => dataset.idTipo == this.getTipo_item("produtoProduto").id
        )
        .reduce(
          (acc, dataset) => (acc += parseFloat(dataset.quantidadeSelecionada)),
          0
        )
        .toString();

      retorno.total = datasets.reduce(
        (acc, dataset) => (acc += parseFloat(dataset.precoSelecionado || 0)),
        0
      );

      return retorno;
    },

    badgePanelsAdicionais(panel) {
      let badge = 0;
      if (panel == "características") {
        badge += this.dataset.main.detail.pesoBruto ? 1 : 0;
        badge += this.dataset.main.detail.pesoLiquido ? 1 : 0;
        badge += this.dataset.main.detail.volume ? 1 : 0;
        badge += this.dataset.main.detail.largura ? 1 : 0;
        badge += this.dataset.main.detail.altura ? 1 : 0;
        badge += this.dataset.main.detail.profundidade ? 1 : 0;
        badge += this.dataset.main.detail.unidadesPorCaixa ? 1 : 0;
        badge += this.dataset.main.detail.cubagem ? 1 : 0;
        badge += this.dataset.main.detail.pesoCubadoRodoviario ? 1 : 0;
        badge += this.dataset.main.detail.pesoCubadoAereo ? 1 : 0;
        badge += this.getValorTraduzido(
          this.dataset.main.detail,
          "caracteristicas"
        ).length;
      } else {
        badge += this.dataset.main.detail.codigoBarra ? 1 : 0;
        badge += this.dataset.main.detail.idOrigem ? 1 : 0;
        badge += this.dataset.main.detail.codigoFiscal ? 1 : 0;
        badge +=
          this.labelCodigoFiscal2 && this.dataset.main.detail.codigoFiscal2
            ? 1
            : 0;
        badge += this.dataset.main.detail.prazoGarantia ? 1 : 0;
      }

      return badge;
    },

    calculaPrecoSelecionado(idProduto, item) {
      const produtosRateio = this.datasets.filter(
        (dataset) =>
          dataset.grupo == item &&
          dataset.idPai == idProduto &&
          dataset.hasRateio
      );

      if (produtosRateio.length) {
        const totalSelecionado = produtosRateio.reduce(
          (acc, item) => (acc += parseFloat(item.quantidadeSelecionada)),
          0
        );

        const produtoMaiorValor = produtosRateio
          .filter((dataset) => parseFloat(dataset.quantidadeSelecionada) > 0)
          .sort((a, b) => (parseFloat(a.preco) > parseFloat(b.preco) ? -1 : 1));

        this.datasets
          .filter(
            (dataset) =>
              dataset.grupo == item &&
              dataset.idPai == idProduto &&
              dataset.hasRateio
          )
          .forEach((dataset) => {
            const preco =
              !dataset.isRateioProporcional &&
              dataset.id == produtoMaiorValor[0]?.id
                ? parseFloat(dataset.preco || 0)
                : !dataset.isRateioProporcional
                ? 0
                : (parseFloat(dataset.preco || 0) / totalSelecionado) *
                  parseFloat(dataset.quantidadeSelecionada);

            dataset.precoSelecionado = preco;
          });
      } else {
        const referenciaSemProduto = this.datasets.find(
          (dataset) =>
            dataset.idProduto == null &&
            dataset.grupo == item &&
            dataset.idPai == idProduto
        );

        this.datasets
          .filter(
            (dataset) =>
              dataset.idProduto &&
              dataset.idPai == idProduto &&
              dataset.grupo == item &&
              dataset.isItemReferencia
          )
          .forEach((dataset) => {
            const diferencaSelecionadoDefault =
              parseFloat(dataset.quantidadeSelecionada || 0) -
              parseFloat(dataset.quantidadeDefault || 0);

            if (
              diferencaSelecionadoDefault <= 0 ||
              !parseFloat(dataset.quantidadeDefault || 0)
            ) {
              dataset.precoSelecionado = 0;
            } else {
              dataset.precoSelecionado =
                parseFloat(dataset.preco || 0) * diferencaSelecionadoDefault;
            }
          });

        const produtoReferencia = this.datasets.filter(
          (dataset) =>
            dataset.idProduto &&
            dataset.idPai == idProduto &&
            dataset.grupo == item &&
            dataset.isItemReferencia
        );

        const totalSelecionado = this.datasets
          .filter(
            (dataset) =>
              dataset.idProduto &&
              dataset.idPai == idProduto &&
              dataset.grupo == item
          )
          .reduce(
            (acc, produto) =>
              (acc += parseFloat(produto.quantidadeSelecionada || 0)),
            0
          );

        if (produtoReferencia?.length) {
          this.datasets
            .filter(
              (dataset) =>
                dataset.idProduto &&
                dataset.idPai == idProduto &&
                dataset.grupo == item
            )
            .forEach((dataset) => {
              const diferencaSelecionadoCortesia =
                totalSelecionado -
                parseFloat(produtoReferencia[0].quantidadeDefault || 0);

              const diferencaPreco =
                parseFloat(dataset.preco || 0) -
                parseFloat(produtoReferencia[0].preco || 0);

              if (
                (parseFloat(
                  referenciaSemProduto?.quantidadeMinima ??
                    dataset.quantidadeMinima
                ) == 1 &&
                  parseFloat(
                    referenciaSemProduto?.quantidadeMaxima ??
                      dataset.quantidadeMaxima
                  ) == 1) ||
                diferencaSelecionadoCortesia < 0
              ) {
                dataset.precoUnitario = diferencaPreco;
              } else {
                dataset.precoUnitario = parseFloat(dataset.preco || 0);
              }
            });

          this.datasets
            .filter(
              (dataset) =>
                dataset.idProduto &&
                dataset.idPai == idProduto &&
                dataset.grupo == item &&
                !dataset.isItemReferencia
            )
            .forEach((dataset) => {
              const diferencaSelecionadoCortesia =
                totalSelecionado -
                parseFloat(produtoReferencia[0].quantidadeDefault || 0);

              const diferencaPreco =
                parseFloat(dataset.preco || 0) -
                parseFloat(produtoReferencia[0].preco || 0);

              if (
                (parseFloat(
                  referenciaSemProduto?.quantidadeMinima ??
                    dataset.quantidadeMinima
                ) == 1 &&
                  parseFloat(
                    referenciaSemProduto?.quantidadeMaxima ??
                      dataset.quantidadeMaxima
                  ) == 1) ||
                diferencaSelecionadoCortesia <= 0
              ) {
                dataset.precoSelecionado = diferencaPreco;
              } else {
                dataset.precoSelecionado =
                  parseFloat(dataset.preco || 0) * diferencaSelecionadoCortesia;
              }
            });
        } else {
          this.datasets
            .filter(
              (dataset) =>
                dataset.idProduto &&
                dataset.idPai == idProduto &&
                dataset.grupo == item
            )
            .forEach((dataset) => {
              const diferencaSelecionadoCortesia =
                parseFloat(dataset.quantidadeSelecionada || 0) -
                parseFloat(dataset.quantidadeCortesia || 0);

              if (
                (parseFloat(
                  referenciaSemProduto?.quantidadeMinima ??
                    dataset.quantidadeMinima
                ) == 1 &&
                  parseFloat(
                    referenciaSemProduto?.quantidadeMaxima ??
                      dataset.quantidadeMaxima
                  ) == 1) ||
                diferencaSelecionadoCortesia < 0
              ) {
                dataset.precoUnitario = 0;
              } else {
                dataset.precoUnitario = parseFloat(dataset.preco || 0);
              }
            });

          this.datasets
            .filter(
              (dataset) =>
                dataset.idProduto &&
                dataset.idPai == idProduto &&
                dataset.grupo == item &&
                !dataset.isItemReferencia
            )
            .forEach((dataset) => {
              const diferencaSelecionadoCortesia =
                parseFloat(dataset.quantidadeSelecionada || 0) -
                parseFloat(dataset.quantidadeCortesia || 0);

              if (
                (parseFloat(
                  referenciaSemProduto?.quantidadeMinima ??
                    dataset.quantidadeMinima
                ) == 1 &&
                  parseFloat(
                    referenciaSemProduto?.quantidadeMaxima ??
                      dataset.quantidadeMaxima
                  ) == 1) ||
                diferencaSelecionadoCortesia <= 0
              ) {
                dataset.precoSelecionado = 0;
              } else {
                dataset.precoSelecionado =
                  parseFloat(dataset.preco || 0) * diferencaSelecionadoCortesia;
              }
            });
        }
      }
    },

    caracteristicaAlign(caracteristica) {
      return caracteristica[0] ? "start" : "center";
    },

    caracteristicaCols(caracteristica) {
      return caracteristica[0] ? 8 : 12;
    },

    changeCheckbox(itemProduto, event) {
      itemProduto.quantidadeSelecionada = event ? 1 : 0;

      this.checkOk();
    },

    changeQuantidadeItem(itemProduto, quantidade) {
      itemProduto.quantidadeSelecionada =
        parseFloat(itemProduto.quantidadeSelecionada) + parseFloat(quantidade);

      this.checkOk();
    },

    changeRadioValue(grupo, item, itemProduto) {
      itemProduto.quantidadeSelecionada = 1;

      this.datasets
        .filter(
          (dataset) =>
            dataset.idPai == itemProduto.idPai &&
            dataset.idPai2 &&
            dataset.idPai2 != itemProduto.idProduto
        )
        .forEach((dataset) => {
          dataset.quantidadeSelecionada = 0;
        });

      this.datasets
        .filter(
          (dataset) =>
            dataset.idPai == itemProduto.idPai &&
            dataset.idPai2 &&
            dataset.idPai2 == itemProduto.idProduto
        )
        .forEach((dataset) => {
          dataset.quantidadeSelecionada = parseFloat(dataset.quantidadeDefault);
        });

      this.datasets
        .filter(
          (dataset) =>
            dataset.grupo == item &&
            dataset.idPai == grupo.idProduto &&
            dataset.id != itemProduto.id &&
            !!parseFloat(dataset.quantidadeSelecionada)
        )
        .forEach((dataset) => (dataset.quantidadeSelecionada = 0));

      this.checkOk();
    },

    changeSwitch(itemProduto, event) {
      itemProduto.quantidadeSelecionada = event ? 1 : 0;

      this.checkOk();
    },

    checkOk() {
      this.grupos.forEach((grupo) => {
        let isOk = false;
        grupo.isOk = true;

        this.datasets
          .filter(
            (dataset) =>
              dataset.idPai == grupo.idProduto &&
              (dataset.idPai2
                ? this.datasets.some(
                    (dataset2) =>
                      dataset2.idProduto == dataset.idPai2 &&
                      dataset2.idPai == grupo.idProduto
                  )
                : true) &&
              parseFloat(dataset.quantidadeMinima || 0) == 0
          )
          .forEach((dataset) => {
            dataset.isOk = true;
          });

        const datasets = this.datasets.filter(
          (dataset) =>
            dataset.idPai == grupo.idProduto &&
            parseFloat(dataset.quantidadeMinima || 0) > 0
        );

        const grupos = [...new Set(datasets.map((dataset) => dataset.grupo))];

        for (let grupo2 of grupos) {
          const datasetPai = this.datasets.find(
            (dataset) =>
              !dataset.idProduto &&
              dataset.grupo == grupo2 &&
              dataset.idPai == grupo.idProduto &&
              (dataset.idPai2
                ? this.datasets.some(
                    (dataset2) =>
                      dataset2.idProduto == dataset.idPai2 &&
                      dataset2.idPai == grupo.idProduto
                  )
                : true)
          );

          const quantidadeMinimaPai = parseFloat(
            datasetPai?.quantidadeMinima || 0
          );
          const quantidadeMaximaPai = parseFloat(
            datasetPai?.quantidadeMaxima || 0
          );

          const datasets2 = this.datasets.filter(
            (dataset) =>
              dataset.grupo == grupo2 &&
              dataset.idPai == grupo.idProduto &&
              (dataset.idPai2
                ? this.datasets.some(
                    (dataset2) =>
                      dataset2.idProduto == dataset.idPai2 &&
                      dataset2.idPai == grupo.idProduto
                  )
                : true) &&
              parseFloat(quantidadeMinimaPai || dataset.quantidadeMinima || 0) >
                0
          );

          const quantidadeSelecionada = this.datasets
            .filter(
              (dataset) =>
                dataset.grupo == grupo2 &&
                dataset.idPai == grupo.idProduto &&
                (dataset.idPai2
                  ? this.datasets.some(
                      (dataset2) =>
                        dataset2.idProduto == dataset.idPai2 &&
                        dataset2.idPai == grupo.idProduto
                    )
                  : true)
            )
            .reduce(
              (acc, item) =>
                (acc += parseFloat(item.quantidadeSelecionada || 0)),
              0
            );

          this.datasets
            .filter(
              (dataset) =>
                dataset.grupo == grupo2 &&
                dataset.idPai == grupo.idProduto &&
                (dataset.idPai2
                  ? this.datasets.some(
                      (dataset2) =>
                        dataset2.idProduto == dataset.idPai2 &&
                        dataset2.idPai == grupo.idProduto
                    )
                  : true) &&
                parseFloat(
                  quantidadeMinimaPai || dataset.quantidadeMinima || 0
                ) > 0
            )
            .forEach((dataset) => {
              if (
                quantidadeSelecionada >=
                  parseFloat(
                    quantidadeMinimaPai || dataset.quantidadeMinima || 0
                  ) &&
                quantidadeSelecionada <=
                  parseFloat(
                    quantidadeMaximaPai || dataset.quantidadeMaxima || 0
                  )
              ) {
                dataset.isOk = true;
              } else {
                dataset.isOk = false;
              }
            });

          if (
            quantidadeSelecionada >=
              parseFloat(
                quantidadeMinimaPai || datasets2[0].quantidadeMinima || 0
              ) &&
            quantidadeSelecionada <=
              parseFloat(
                quantidadeMaximaPai || datasets2[0].quantidadeMaxima || 0
              )
          ) {
            isOk = true;
          } else {
            isOk = false;
          }

          grupo.isOk = grupo.isOk ? isOk : grupo.isOk;

          // if (!isOk) {
          //   break;
          // }
        }
      });
    },

    clickAtualizarCarrinho() {
      this.setVar({ overlay: true });

      const indexSemidItemPai = this.carrinho.main.itens.findIndex(
        (itemConfigurar) =>
          !itemConfigurar.idItemPai &&
          itemConfigurar.idProduto == this.produto.idProduto
      );

      this.carrinho.main.itens[indexSemidItemPai].quantidade =
        this.quantidadeTotal;
      this.carrinho.main.itens[indexSemidItemPai].preco = this.precoTotal;

      const pais = this.datasets
        .filter(
          (dataset) =>
            parseFloat(dataset.quantidadeSelecionada || 0) > 0 &&
            (this.dataset.main.detail.idTipo ==
            this.getTipo_item("produtoCombo").id
              ? dataset.idPai == this.dataset.main.detail.id
              : this.dataset.main.detail.id == dataset.idProduto)
        )
        .map((dataset) => {
          return {
            ...dataset,
          };
        });

      let indexI =
        this.dataset.main.detail.idTipo == this.getTipo_item("produtoCombo").id
          ? indexSemidItemPai + 1
          : indexSemidItemPai;

      for (let pai1 of pais) {
        const filhos = this.datasets.filter(
          (dataset) =>
            dataset.idPai == pai1.idProduto &&
            dataset.idPai != dataset.idProduto &&
            dataset.idProduto &&
            parseFloat(dataset.quantidadeSelecionada || 0) > 0 &&
            (dataset.idPai2
              ? this.datasets.some(
                  (dataset2) =>
                    dataset2.idProduto == dataset.idPai2 &&
                    parseFloat(dataset2.quantidadeSelecionada || 0) > 0
                )
              : true)
        );

        if (
          !pai1.precoSelecionado &&
          this.dataset.main.detail.idTipo ==
            this.getTipo_item("produtoCombo").id
        ) {
          let precoPai2 = filhos.reduce(
            (acc, item) =>
              (acc +=
                parseFloat(item.preco || 0) *
                parseFloat(item.quantidadeSelecionada || 0)),
            0
          );

          pai1.preco = precoPai2;
          pai1.precoSelecionado = precoPai2;

          const filhoPrincipal = this.datasets.find(
            (dataset) =>
              dataset.idPai == pai1.idProduto &&
              dataset.idPai != dataset.idProduto &&
              dataset.idProduto &&
              parseFloat(dataset.quantidadeSelecionada || 0) > 0 &&
              !dataset.idPai2
          );

          const objetoPai1 = {
            id: filhoPrincipal?.idItemCarrinho || null,
            idPai: filhoPrincipal?.idPaiItemCarrinho || null,
            isSuspenso: filhoPrincipal?.isSuspensoItemCarrinho || false,
            idItemPai: filhoPrincipal?.idItemPaiItemCarrinho || null,
            idProduto: filhoPrincipal.idProduto,
            quantidade: parseFloat(pai1.quantidadeSelecionada),
            preco: parseFloat(filhoPrincipal.preco),
            observacao: filhoPrincipal?.observacaoItemCarrinho || null,
            idColaborador: filhoPrincipal?.idColaboradorItemCarrinho || null,
            options: filhoPrincipal?.optionsItemCarrinho || {
              precoTabela: parseFloat(filhoPrincipal.precoTabela || 0),
              descontoCombo: parseFloat(filhoPrincipal.descontoCombo || 0),
              isDescontoComboPercentual:
                filhoPrincipal.isDescontoComboPercentual,
              grupo: filhoPrincipal.grupo,
              quantidadeMinima: filhoPrincipal.quantidadeMinima
                ? parseFloat(filhoPrincipal.quantidadeMinima)
                : null,
              quantidadeMaxima: filhoPrincipal.quantidadeMaxima
                ? parseFloat(filhoPrincipal.quantidadeMaxima)
                : null,
              quantidadeDefault: filhoPrincipal.quantidadeDefault
                ? parseFloat(filhoPrincipal.quantidadeDefault)
                : null,
              quantidadeCortesia: filhoPrincipal.quantidadeCortesia
                ? parseFloat(filhoPrincipal.quantidadeCortesia)
                : null,
              isItemReferencia: filhoPrincipal.isItemReferencia,
            },
            del: parseFloat(pai1.quantidadeSelecionada) <= 0,
            indexReferencia: filhoPrincipal?.idItemCarrinho
              ? null
              : { campo: "idItemPai", index: 0 },
          };

          this.carrinho.main.itens.splice(indexI, 0, objetoPai1);
          this.carrinho.aux.itens.splice(indexI, 0, objetoPai1);
        } else {
          const objetoPai1 = {
            id: pai1?.idItemCarrinho || null,
            idPai: pai1?.idPaiItemCarrinho || null,
            isSuspenso: pai1?.isSuspensoItemCarrinho || false,
            idItemPai: pai1?.idItemPaiItemCarrinho || null,
            idProduto: pai1.idProduto,
            quantidade: parseFloat(pai1.quantidadeSelecionada),
            preco: parseFloat(pai1.preco),
            observacao: pai1?.observacaoItemCarrinho || null,
            idColaborador: pai1?.idColaboradorItemCarrinho || null,
            options: pai1?.optionsItemCarrinho || {
              precoTabela: parseFloat(pai1.precoTabela || 0),
              descontoCombo: parseFloat(pai1.descontoCombo || 0),
              isDescontoComboPercentual: pai1.isDescontoComboPercentual,
              grupo: pai1.grupo,
              quantidadeMinima: pai1.quantidadeMinima
                ? parseFloat(pai1.quantidadeMinima)
                : null,
              quantidadeMaxima: pai1.quantidadeMaxima
                ? parseFloat(pai1.quantidadeMaxima)
                : null,
              quantidadeDefault: pai1.quantidadeDefault
                ? parseFloat(pai1.quantidadeDefault)
                : null,
              quantidadeCortesia: pai1.quantidadeCortesia
                ? parseFloat(pai1.quantidadeCortesia)
                : null,
              isItemReferencia: pai1.isItemReferencia,
            },
            del: parseFloat(pai1.quantidadeSelecionada) <= 0,
          };

          this.carrinho.main.itens.length > 0 && !pai1?.idItemCarrinho
            ? (objetoPai1.indexReferencia = {
                campo: "idItemPai",
                index: 0,
              })
            : null;

          this.carrinho.main.itens.splice(indexI, 0, objetoPai1);
          this.carrinho.aux.itens.splice(indexI, 0, objetoPai1);
        }

        //? verificar filhosFilteredx
        const filhosFiltered = filhos.filter(
          (filho) =>
            !this.carrinho.main.itens.some(
              (item) => item.idProduto == filho.idProduto
            )
        );

        const pai = this.carrinho.main.itens[indexI];

        for (let [r, filho] of filhos.entries()) {
          const objetoFilho = {
            id: filho?.idItemCarrinho || null,
            idPai: filho?.idPaiItemCarrinho || pai?.idPai || null,
            isSuspenso: filho?.isSuspensoItemCarrinho || false,
            idItemPai: filho?.idItemPaiItemCarrinho || pai?.id || null,
            idProduto: filho.idProduto,
            quantidade: parseFloat(filho.quantidadeSelecionada),
            preco: parseFloat(filho.preco),
            observacao: filho?.observacaoItemCarrinho || null,
            idColaborador: filho?.idColaboradorItemCarrinho || null,
            options: filho?.optionsItemCarrinho || {
              precoTabela: parseFloat(filho.precoTabela || 0),
              descontoCombo: parseFloat(filho.descontoCombo || 0),
              isDescontoComboPercentual: filho.isDescontoComboPercentual,
              grupo: filho.grupo,
              quantidadeMinima: filho.quantidadeMinima
                ? parseFloat(filho.quantidadeMinima)
                : null,
              quantidadeMaxima: filho.quantidadeMaxima
                ? parseFloat(filho.quantidadeMaxima)
                : null,
              quantidadeDefault: filho.quantidadeDefault
                ? parseFloat(filho.quantidadeDefault)
                : null,
              quantidadeCortesia: filho.quantidadeCortesia
                ? parseFloat(filho.quantidadeCortesia)
                : null,
              isItemReferencia: filho.isItemReferencia,
            },
            del: parseFloat(filho.quantidadeSelecionada) <= 0,
            indexReferencia:
              filho?.idItemCarrinho || pai?.id
                ? null
                : { campo: "idItemPai", index: indexI },
          };

          if (!objetoFilho.id) {
            this.carrinho.main.itens.splice(indexI + r + 1, 0, objetoFilho);
            this.carrinho.aux.itens.splice(indexI + r + 1, 0, objetoFilho);
          } else {
            this.carrinho.main.itens.find(
              (item) => item.id == objetoFilho.id
            ).quantidade = objetoFilho.quantidade;
          }
        }

        indexI += filhosFiltered.length;

        indexI += 1;
      }

      this.carrinho.main.itens
        .filter((item) => item.idItemPai)
        .forEach((item) => {
          const isDel = this.datasets.some(
            (dataset) =>
              dataset.id &&
              dataset.grupo == item.options.grupo &&
              dataset.idProduto == item.idProduto &&
              parseFloat(dataset.quantidadeSelecionada) == 0 &&
              (dataset.idPai2
                ? this.datasets.some(
                    (dataset2) =>
                      dataset2.idProduto == dataset.idPai2 &&
                      dataset2.idPai == dataset.idPai &&
                      parseFloat(dataset2.quantidadeSelecionada) > 0
                  )
                : true)
          );

          if (isDel) {
            item.del = true;
            item.isDeleted = true;
            item.quantidade = 0;
            item.preco = 0;
          }
        });
      const url = `${this.getConst("app").baseUrlBackend}/carrinhos`;

      axios
        .post(url, this.carrinho)
        .then(() => {
          this.setVar({ overlay: false });
          this.carrinhosAtualizar();
          this.setSnackbar(
            true,
            "Produto",
            `item atualizado.`,
            "success",
            "",
            ""
          );
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          let interval = setInterval(() => {
            if (!this.snackbar.show) {
              clearInterval(interval);
              interval = null;
              this.$emit("close");
            }
          }, 100);

          this.setVar({ overlay: false });
        });
    },

    clickComprar(isComprarAgora = false) {
      this.setVar({ overlay: true });

      const carrinho = {
        idPessoaParceiro: this.dataset.main.detail.idPessoaParceiro,
        itens: [],
      };

      if (
        this.dataset.main.detail.idTipo == this.getTipo_item("produtoCombo").id
      ) {
        carrinho.itens.push({
          id: null,
          idPai: null,
          isSuspenso: false,
          idItemPai: null,
          idProduto: this.dataset.main.detail.id,
          quantidade: this.quantidadeTotal,
          preco: this.precoTotal,
          observacao: null,
          idColaborador: null,
          options: {
            precoTabela: parseFloat(this.dataset.main.detail.precoTabela || 0),
            descontoCombo: parseFloat(
              this.dataset.main.detail.descontoCombo || 0
            ),
            isDescontoComboPercentual:
              this.dataset.main.detail.isDescontoComboPercentual,
          },
        });
      } else if (this.datasets.length < 1) {
        carrinho.itens.push({
          id: null,
          idPai: null,
          isSuspenso: false,
          idProduto: this.dataset.main.detail.id,
          quantidade: this.quantidadeTotal,
          preco: this.precoTotal,
          observacao: null,
          idColaborador: null,
        });
      }

      // pai
      const pais1 = this.datasets
        .filter(
          (dataset) =>
            parseFloat(dataset.quantidadeSelecionada || 0) > 0 &&
            (this.dataset.main.detail.idTipo ==
            this.getTipo_item("produtoCombo").id
              ? dataset.idPai == this.dataset.main.detail.id
              : this.dataset.main.detail.id == dataset.idProduto)
        )
        .map((dataset) => {
          return {
            ...dataset,
          };
        });

      let indexI =
        this.dataset.main.detail.idTipo == this.getTipo_item("produtoCombo").id
          ? 1
          : 0;

      for (let pai1 of pais1) {
        const filhos = this.datasets.filter(
          (dataset) =>
            dataset.idPai == pai1.idProduto &&
            dataset.idPai != dataset.idProduto &&
            dataset.idProduto &&
            parseFloat(dataset.quantidadeSelecionada || 0) > 0 &&
            (dataset.idPai2
              ? this.datasets.some(
                  (dataset2) =>
                    dataset2.idProduto == dataset.idPai2 &&
                    parseFloat(dataset2.quantidadeSelecionada || 0) > 0
                )
              : true)
        );

        if (
          !pai1.precoSelecionado &&
          this.dataset.main.detail.idTipo ==
            this.getTipo_item("produtoCombo").id
        ) {
          let precoPai2 = filhos.reduce(
            (acc, item) =>
              (acc +=
                parseFloat(item.preco || 0) *
                parseFloat(item.quantidadeSelecionada || 0)),
            0
          );

          pai1.preco = precoPai2;
          pai1.precoSelecionado = precoPai2;

          const filhoPrincipal = this.datasets.find(
            (dataset) =>
              dataset.idPai == pai1.idProduto &&
              dataset.idPai != dataset.idProduto &&
              dataset.idProduto &&
              parseFloat(dataset.quantidadeSelecionada || 0) > 0 &&
              !dataset.idPai2
          );

          const objetoPai1 = {
            id: filhoPrincipal?.idItemCarrinho || null,
            idPai: filhoPrincipal?.idPaiItemCarrinho || null,
            isSuspenso: filhoPrincipal?.isSuspensoItemCarrinho || false,
            idItemPai: filhoPrincipal?.idItemPaiItemCarrinho || null,
            idProduto: filhoPrincipal.idProduto,
            quantidade: parseFloat(pai1.quantidadeSelecionada),
            preco: parseFloat(filhoPrincipal.preco),
            observacao: filhoPrincipal?.observacaoItemCarrinho || null,
            idColaborador: filhoPrincipal?.idColaboradorItemCarrinho || null,
            options: filhoPrincipal?.optionsItemCarrinho || {
              precoTabela: parseFloat(filhoPrincipal.precoTabela || 0),
              descontoCombo: parseFloat(filhoPrincipal.descontoCombo || 0),
              isDescontoComboPercentual:
                filhoPrincipal.isDescontoComboPercentual,
              grupo: filhoPrincipal.grupo,
              quantidadeMinima: filhoPrincipal.quantidadeMinima
                ? parseFloat(filhoPrincipal.quantidadeMinima)
                : null,
              quantidadeMaxima: filhoPrincipal.quantidadeMaxima
                ? parseFloat(filhoPrincipal.quantidadeMaxima)
                : null,
              quantidadeDefault: filhoPrincipal.quantidadeDefault
                ? parseFloat(filhoPrincipal.quantidadeDefault)
                : null,
              quantidadeCortesia: filhoPrincipal.quantidadeCortesia
                ? parseFloat(filhoPrincipal.quantidadeCortesia)
                : null,
              isItemReferencia: filhoPrincipal.isItemReferencia,
            },
            del: parseFloat(pai1.quantidadeSelecionada) <= 0,
            indexReferencia: filhoPrincipal?.idItemCarrinho
              ? null
              : { campo: "idItemPai", index: 0 },
          };

          carrinho.itens.splice(indexI, 0, objetoPai1);
        } else {
          const objetoPai1 = {
            id: pai1?.idItemCarrinho || null,
            idPai: pai1?.idPaiItemCarrinho || null,
            isSuspenso: pai1?.isSuspensoItemCarrinho || false,
            idItemPai: pai1?.idItemPaiItemCarrinho || null,
            idProduto: pai1.idProduto,
            quantidade: parseFloat(pai1.quantidadeSelecionada),
            preco: parseFloat(pai1.preco),
            observacao: pai1?.observacaoItemCarrinho || null,
            idColaborador: pai1?.idColaboradorItemCarrinho || null,
            options: pai1?.optionsItemCarrinho || {
              precoTabela: parseFloat(pai1.precoTabela || 0),
              descontoCombo: parseFloat(pai1.descontoCombo || 0),
              isDescontoComboPercentual: pai1.isDescontoComboPercentual,
              grupo: pai1.grupo,
              quantidadeMinima: pai1.quantidadeMinima
                ? parseFloat(pai1.quantidadeMinima)
                : null,
              quantidadeMaxima: pai1.quantidadeMaxima
                ? parseFloat(pai1.quantidadeMaxima)
                : null,
              quantidadeDefault: pai1.quantidadeDefault
                ? parseFloat(pai1.quantidadeDefault)
                : null,
              quantidadeCortesia: pai1.quantidadeCortesia
                ? parseFloat(pai1.quantidadeCortesia)
                : null,
              isItemReferencia: pai1.isItemReferencia,
            },
            del: parseFloat(pai1.quantidadeSelecionada) <= 0,
          };

          carrinho.itens.length > 0 && !pai1?.idItemCarrinho
            ? (objetoPai1.indexReferencia = {
                campo: "idItemPai",
                index: 0,
              })
            : null;

          carrinho.itens.splice(indexI, 0, objetoPai1);
        }

        const filhosFiltered = filhos.filter(
          (filho) =>
            !carrinho.itens.some((item) => item.idProduto == filho.idProduto)
        );

        const pai = carrinho.itens[indexI];

        for (let [r, filho] of filhosFiltered.entries()) {
          const objetoFilho = {
            id: filho?.idItemCarrinho || null,
            idPai: filho?.idPaiItemCarrinho || pai?.idPai || null,
            isSuspenso: filho?.isSuspensoItemCarrinho || false,
            idItemPai: filho?.idItemPaiItemCarrinho || pai?.id || null,
            idProduto: filho.idProduto,
            quantidade: parseFloat(filho.quantidadeSelecionada),
            preco: parseFloat(filho.preco),
            observacao: filho?.observacaoItemCarrinho || null,
            idColaborador: filho?.idColaboradorItemCarrinho || null,
            options: filho?.optionsItemCarrinho || {
              precoTabela: parseFloat(filho.precoTabela || 0),
              descontoCombo: parseFloat(filho.descontoCombo || 0),
              isDescontoComboPercentual: filho.isDescontoComboPercentual,
              grupo: filho.grupo,
              quantidadeMinima: filho.quantidadeMinima
                ? parseFloat(filho.quantidadeMinima)
                : null,
              quantidadeMaxima: filho.quantidadeMaxima
                ? parseFloat(filho.quantidadeMaxima)
                : null,
              quantidadeDefault: filho.quantidadeDefault
                ? parseFloat(filho.quantidadeDefault)
                : null,
              quantidadeCortesia: filho.quantidadeCortesia
                ? parseFloat(filho.quantidadeCortesia)
                : null,
              isItemReferencia: filho.isItemReferencia,
            },
            del: parseFloat(filho.quantidadeSelecionada) <= 0,
            indexReferencia:
              filho?.idItemCarrinho || pai?.id
                ? null
                : { campo: "idItemPai", index: indexI },
          };

          carrinho.itens.splice(indexI + r + 1, 0, objetoFilho);
        }

        indexI += filhosFiltered.length;

        indexI += 1;
      }

      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro =
        this.getVar("parceiroCliente").idPessoa ||
        this.getVar("idPessoaParceiro");
      const isEntregar = this.getVar("isEntregar") ? 1 : 0;
      const idPessoaEndereco = this.getVar("idPessoaEndereco");
      const url = `${
        this.getConst("app").baseUrlBackend
      }/carrinhos/comprar/${idUsuario}/${idPessoaParceiro}/${isEntregar}/${idPessoaEndereco}/${
        isComprarAgora ? 1 : 0
      }`;

      axios
        .post(url, carrinho)
        .then(() => {
          this.setVar({ overlay: false });
          this.carrinhosAtualizar();

          if (!isComprarAgora) {
            this.setSnackbar(
              true,
              "COMPRA",
              `${this.quantidadeTotal} ${
                this.quantidadeTotal > 1
                  ? "itens adicionados"
                  : "item adicionado"
              } no carrinho. Detalhar carrinho?`,
              "success",
              "mdi-cart-outline",
              "carrinho"
            );
          }
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          if (!isComprarAgora) {
            let interval = setInterval(() => {
              if (!this.snackbar.show) {
                clearInterval(interval);
                interval = null;
                this.$emit("close");
              }
            }, 100);
          } else {
            setTimeout(() => {
              this.clickCarrinho(null, isComprarAgora);
              this.$emit("close");
            }, 1000);
          }

          this.setVar({ overlay: false });
        });
    },

    clickConfirmarItens(scrollToGrupo) {
      this.isModeResumo = false;

      if (scrollToGrupo) {
        setTimeout(() => {
          this.$forceUpdate();
          const indexGrupo = this.grupos.findIndex((grupo) => !grupo.isOk);
          this.goToPanel(indexGrupo);
        }, 1250);
      }
    },

    clickNext(grupo, index, groupItemIndex) {
      const currentPanelIndex = this.panelsOpenItens[grupo.idProduto].findIndex(
        (panel) => panel == groupItemIndex
      );
      this.panelsOpenItens[grupo.idProduto].splice(currentPanelIndex, 1);
      let idProduto = grupo.idProduto;
      let nextItem = groupItemIndex;

      if (this.gruposItens(grupo)[groupItemIndex + 1]) {
        nextItem = groupItemIndex + 1;
      } else {
        if (this.grupos[index + 1]) {
          nextItem = 0;
          idProduto = this.grupos[index + 1].idProduto;
        }
      }

      setTimeout(() => {
        this.goToPanel(nextItem, `item${idProduto}`);
      }, 500);
    },

    clickPanels(panelsOpen) {
      const isLastPanelOpen = !!panelsOpen.length;

      this.isLastPanelOpen = isLastPanelOpen;
    },

    clickQuantidade(item, itemProduto) {
      const somaSelecionados = this.datasets
        .filter(
          (dataset) =>
            dataset.grupo == item &&
            dataset.idPai == itemProduto.idPai &&
            dataset.isItemReferencia == itemProduto.isItemReferencia
        )
        .reduce(
          (acc, produto) => (acc += parseFloat(produto.quantidadeSelecionada)),
          0
        );

      itemProduto.quantidadeSelecionada =
        somaSelecionados >= parseFloat(itemProduto.quantidadeMaxima)
          ? 0
          : parseFloat(itemProduto.quantidadeSelecionada) + 1;
    },

    clickQuantidadeTotal(valor) {
      this.quantidadeTotal += valor;
    },

    clickResumo() {
      this.isModeResumo = true;

      setTimeout(() => {
        document.getElementById("images").scrollIntoView(true);
      }, 100);
    },

    async clickSearch() {
      this.setVar({ overlay: true });
      const method = "get";
      const params = JSON.stringify({
        idPai: this.carrinho?.main?.detail?.id
          ? this.produto.idProduto
          : this.produto.id,
      });
      let url = `${this.getConst("app").baseUrlBackend}/buscaItens/${params}`;

      await axios[method](url)
        .then((res) => {
          this.datasets = res.data.datasets;
          this.dataset = res.data.dataset;

          this.datasets.forEach((dataset) => {
            dataset.isOriginal = false;
            dataset.isOk = false;
            dataset.precoUnitario = dataset.preco;
          });

          if (this.carrinho?.main?.itens?.length) {
            this.datasets.forEach((dataset) => {
              const item = this.carrinho.main.itens.find(
                (itemConfigurar) =>
                  itemConfigurar.options?.grupo == dataset.grupo &&
                  itemConfigurar.idProduto == dataset.idProduto &&
                  (dataset.idPai2
                    ? this.datasets.some(
                        (dataset2) =>
                          dataset2.idProduto == dataset.idPai2 &&
                          dataset2.idPai == dataset.idPai &&
                          parseFloat(dataset2.quantidadeSelecionada) > 0
                      )
                    : true)
              );

              dataset.preco = item?.preco || dataset.preco;
              dataset.precoSelecionado = this.carrinho.main.itens.length
                ? item?.preco || 0
                : dataset.precoSelecionado;
              dataset.quantidadeSelecionada = this.carrinho.main.itens.length
                ? item?.quantidade || 0
                : dataset.quantidadeSelecionada;
              dataset.quantidadeMinima =
                item?.options.quantidadeMinima || dataset.quantidadeMinima;
              dataset.quantidadeMaxima =
                item?.options.quantidadeMaxima || dataset.quantidadeMaxima;
              dataset.quantidadeDefault =
                item?.options.quantidadeDefault || dataset.quantidadeDefault;
              dataset.quantidadeCortesia =
                item?.options.quantidadeCortesia || dataset.quantidadeCortesia;
              dataset.isItemReferencia =
                item?.options.isItemReferencia || dataset.isItemReferencia;
              dataset.idItemCarrinho = item?.id;
              dataset.idPaiItemCarrinho = item?.idPai;
              dataset.isSuspensoItemCarrinho = item?.isSuspenso;
              dataset.idItemPaiItemCarrinho = item?.idItemPai;
              dataset.observacaoItemCarrinho = item?.observacao;
              dataset.idColaboradorItemCarrinho = item?.idColaborador;
              dataset.optionsItemCarrinho = item?.options;
              dataset.isDeletedItemCarrinho = item?.isDeleted;
            });
          }

          this.checkOk();
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
          }, this.getConst("config").overlay.timeout);
        });
    },

    clickSnackbar(action) {
      if (action == "carrinho") {
        this.clickCarrinho();
        this.setSnackbar();
      }
    },

    colorBadgeGrupo(grupo) {
      return grupo.isOk ? "green" : "red";
    },

    componente(grupo, item, itemProduto, componente) {
      let referencia = this.datasets.find(
        (dataset) =>
          dataset.idProduto == null &&
          dataset.grupo == item &&
          dataset.idPai == grupo.idProduto
      );

      if (componente == "checkBox") {
        return (
          parseFloat(
            referencia?.quantidadeMinima ?? itemProduto.quantidadeMinima
          ) == 0 &&
          parseFloat(
            referencia?.quantidadeMaxima ?? itemProduto.quantidadeMaxima
          ) == 1
        );
      } else if (componente == "radioButton") {
        return (
          parseFloat(
            referencia?.quantidadeMinima ?? itemProduto.quantidadeMinima
          ) == 1 &&
          parseFloat(
            referencia?.quantidadeMaxima ?? itemProduto.quantidadeMaxima
          ) == 1
        );
      } else if (componente == "botoes") {
        return (
          parseFloat(
            referencia?.quantidadeMinima ?? itemProduto.quantidadeMinima
          ) >= 0 &&
          parseFloat(
            referencia?.quantidadeMaxima ?? itemProduto.quantidadeMaxima
          ) > 1
        );
      }
    },

    disabledBotaoMais(item, itemProduto) {
      const somaSelecionados = this.datasets
        .filter(
          (dataset) =>
            dataset.idProduto &&
            dataset.grupo == item &&
            dataset.idPai == itemProduto.idPai
        )
        .reduce(
          (acc, produto) => (acc += parseFloat(produto.quantidadeSelecionada)),
          0
        );

      const dataset = this.datasets.find(
        (dataset) =>
          !dataset.idProduto &&
          dataset.grupo == item &&
          dataset.idPai == itemProduto.idPai
      );

      const datasetGrupo = this.datasets.find(
        (dataset) =>
          dataset.idProduto == itemProduto.idPai &&
          dataset.grupo == item &&
          dataset.idPai == this.dataset.main.detail.id &&
          dataset.idTipo == this.getTipo_item("produtoGrupo").id
      );

      if (
        somaSelecionados ==
        parseFloat(
          dataset?.quantidadeMaxima ||
            datasetGrupo?.quantidadeMaxima ||
            itemProduto.quantidadeSelecionada
        )
      ) {
        return true;
      } else if (
        parseFloat(itemProduto.quantidadeSelecionada || 0) ==
        parseFloat(itemProduto.quantidadeMaxima || 0)
      ) {
        return true;
      } else {
        return false;
      }
    },

    disabledBotaoMenos(item, itemProduto) {
      const somaSelecionados = this.datasets
        .filter(
          (dataset) =>
            dataset.idProduto &&
            dataset.grupo == item &&
            dataset.idPai == itemProduto.idPai
        )
        .reduce(
          (acc, produto) => (acc += parseFloat(produto.quantidadeSelecionada)),
          0
        );

      const dataset = this.datasets.find(
        (dataset) =>
          !dataset.idProduto &&
          dataset.grupo == item &&
          dataset.idPai == itemProduto.idPai
      );

      if (somaSelecionados <= parseFloat(dataset?.quantidadeMinima || 0)) {
        return true;
      } else if (
        parseFloat(itemProduto.quantidadeSelecionada || 0) <=
        parseFloat(itemProduto.quantidadeMinima || 0)
      ) {
        return true;
      } else {
        return false;
      }
    },

    favoritar() {
      this.$emit("favoritar", this.produto);
      this.produto.favorito = !this.produto.favorito;
    },

    goToPanel(index, nomeElemento = "grupoConfigurador", offset = 100) {
      setTimeout(() => {
        const params = {
          duration: 500,
          offset,
          easing: "linear",
          container: this.$refs.configurador,
        };
        const element = document.getElementById(`${nomeElemento}${index}`);
        this.$vuetify.goTo(element, params);
      }, 10);
    },

    gruposItens(grupo) {
      return [
        ...new Set(
          this.datasets
            .filter(
              (dataset) =>
                dataset.idPai == grupo.idProduto &&
                (grupo.isProduto ||
                  (dataset.idPai2
                    ? this.datasets.some(
                        (dataset2) =>
                          dataset2.idProduto == dataset.idPai2 &&
                          parseFloat(dataset2.quantidadeSelecionada || 0) > 0
                      )
                    : true))
            )
            .map((dataset) => dataset.grupo)
        ),
      ];
    },

    headerItem(grupo, item) {
      const retorno = {
        color: "grey",
        badge: 0,
        total: 0,
        nome: item,
        foto: "",
      };

      const pai2Selecionado = this.datasets
        .filter(
          (dataset) =>
            dataset.idProduto &&
            dataset.idPai == grupo.idProduto &&
            !dataset.idPai2 &&
            parseFloat(dataset.quantidadeSelecionada || 0) > 0
        )
        .map((dataset) => dataset.idProduto);

      const datasets = this.datasets.filter(
        (dataset) =>
          dataset.grupo == item &&
          dataset.idProduto &&
          (dataset.idPai2
            ? pai2Selecionado.includes(dataset.idPai2)
            : dataset.idPai == grupo.idProduto)
      );

      retorno.badge = datasets
        .reduce(
          (acc, dataset) =>
            (acc += parseFloat(dataset.quantidadeSelecionada || 0)),
          0
        )
        .toString();

      retorno.total = datasets
        .filter((dataset) => parseFloat(dataset.quantidadeSelecionada || 0) > 0)
        .reduce(
          (acc, dataset) => (acc += parseFloat(dataset.precoSelecionado || 0)),
          0
        );

      const datasetSelecionados = datasets.filter(
        (dataset) => parseFloat(dataset.quantidadeSelecionada || 0) > 0
      );

      if (datasetSelecionados.length == 1) {
        retorno.nome = this.produtoFormat(datasetSelecionados[0]);
        retorno.foto = datasetSelecionados[0]?.endereco || "";
      }

      if (datasets.some((dataset) => dataset.isOk)) {
        retorno.color = "green";
      } else {
        retorno.color = "red";
      }

      return retorno;
    },

    itensProduto(idProduto, item) {
      this.setIsOriginal(idProduto, item);
      this.sethasDivider(idProduto, item);
      this.calculaPrecoSelecionado(idProduto, item);

      return this.datasets.filter(
        (dataset) =>
          dataset.idProduto &&
          dataset.idPai == idProduto &&
          dataset.grupo == item &&
          (dataset.idPai2
            ? this.datasets.some(
                (dataset2) =>
                  dataset2.idProduto == dataset.idPai2 &&
                  parseFloat(dataset2.quantidadeSelecionada) >= 1
              )
            : true)
      );
    },

    labelEscolhaItens(itemProduto) {
      const datasets = this.datasets.filter(
        (dataset) =>
          dataset.idProduto &&
          dataset.grupo == itemProduto.grupo &&
          dataset.idPai == itemProduto.idPai
      );

      const dataset = this.datasets.find(
        (dataset) =>
          !dataset.idProduto &&
          dataset.grupo == itemProduto.grupo &&
          dataset.idPai == itemProduto.idPai
      );

      const quantidadeMinima = parseFloat(
        dataset?.quantidadeMinima ?? itemProduto.quantidadeMinima
      );
      const quantidadeMaxima = parseFloat(
        dataset?.quantidadeMaxima ?? itemProduto.quantidadeMaxima
      );

      if (
        datasets.length <= 1 &&
        quantidadeMinima == 1 &&
        quantidadeMinima == quantidadeMaxima
      ) {
        return "";
      }

      if (quantidadeMinima == 1 && quantidadeMinima == quantidadeMaxima) {
        return "escolha 1 item";
      } else if (quantidadeMinima > 1 && quantidadeMinima == quantidadeMaxima) {
        return `(${this.numberFormat(quantidadeMinima, "decimal", 0)} itens`;
      } else if (quantidadeMinima == 0 && quantidadeMaxima == 1) {
        return `escolha até 1 item`;
      } else if (quantidadeMinima == 0 && quantidadeMaxima > 1) {
        return `escolha até ${this.numberFormat(
          quantidadeMaxima,
          "decimal",
          0
        )} itens`;
      } else if (quantidadeMinima > 0 && quantidadeMaxima > quantidadeMinima) {
        return `escolha de ${this.numberFormat(
          quantidadeMinima,
          "decimal",
          0
        )} a ${this.numberFormat(quantidadeMaxima, "decimal", 0)} itens`;
      }
    },

    sethasDivider(idProduto, item) {
      this.datasets
        .filter(
          (dataset) =>
            dataset.idProduto &&
            dataset.idPai == idProduto &&
            dataset.grupo == item
        )
        .forEach((dataset, i, array) => {
          const index = i == 0 ? i : i - 1;
          if (
            dataset.isOriginal &&
            (dataset.isOriginal != array[index].isOriginal || i == 0)
          ) {
            dataset.hasDivider = null;
          } else if (
            !dataset.isOriginal &&
            dataset.isOriginal != array[index].isOriginal &&
            i != 0
          ) {
            dataset.hasDivider = true;
          } else {
            dataset.hasDivider = null;
          }
        });
    },

    setIsOriginal(idProduto, item) {
      this.datasets
        .filter(
          (dataset) =>
            dataset.idProduto &&
            dataset.idPai == idProduto &&
            dataset.grupo == item &&
            dataset.quantidadeDefault >= 1
        )
        .forEach((dataset) => {
          dataset.isOriginal = true;
        });
    },

    setPanelsOpen() {
      if (this.isModeResumo) {
        this.panelsOpen = [];
      } else {
        this.panelsOpen = Array(this.grupos.length)
          .fill()
          .map((_, i) => i);
      }
    },

    setPanelsOpenItens(idProduto) {
      const datasets = this.datasets.filter(
        (dataset) => dataset.idPai == idProduto
      );

      this.panelsOpenItens[idProduto] = !this.isModeResumo
        ? Array(datasets.length)
            .fill()
            .map((_, i) => i)
        : [];
    },

    radioValue(idProduto, item) {
      return this.datasets.find(
        (dataset) =>
          dataset.grupo == item &&
          dataset.idPai == idProduto &&
          !!parseFloat(dataset.quantidadeSelecionada)
      )?.id;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: -10px;
  z-index: 10;
}

.marginBtn {
  margin-top: 280px;
}

.centered-input input {
  text-align: center;
}
</style>