<!-- COD: LYF 01/07/2022 CPontosEletronicos -->
<!-- *VER: JER 03/07/2022 CPontosEletronicos -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- data ponto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataPontoInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- funcionarios -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">colaboradores</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ colaboradores }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { parseISO } from "date-fns";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPontosEletronicos",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    dataPontoInicioFim() {
      return `${this.dataFormata(
        parseISO(`${this.dataset.dataInicio.substring(0, 10)}`),
        "date"
      )} - ${this.dataFormata(
        parseISO(`${this.dataset.dataFim.substring(0, 10)}`),
        "date"
      )}`;
    },

    //? Este dataset.funcionarios deve ser dataset.colaboradores. Corrigir
    colaboradores() {
      return `${this.dataset.funcionarios} colaboradores`;
    },
  },
};
</script>