// COD: LYF 10/08/2022 mixinPendencias
// *VER: JER 19/08/2022 mixinPendencias
// /11/

import { mapGetters } from "vuex";
import axios from "axios";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";

export default {
  /// NAME
  name: "mixinPendencias",

  /// MIXINS
  mixins: [mixinCarrinhos],

  /// DATA
  data() {
    return {
      refresh: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    })
  },

  /// METHODS
  methods: {
    pendenciasAtualizar(callback) {
      this.pendenciasLimpar();
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      if (!!idUsuario && !!idPessoaParceiro) {
        const url = `${this.getConst("app").baseUrlBackend
          }/home/pendencias/${idPessoaParceiro}/${idUsuario}`;
        axios
          .get(url)
          .then((res) => {
            const result = res.data;
            const pendencias = result.pendencias;
            this.setVar({ pendencias });

            if (callback) {
              callback();
            }

            this.setVar({ "atualizacao.pendencias": new Date() });
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          })
          .finally(() => {
            this.carrinhosAtualizar();
          });
      } else {
        this.carrinhosAtualizar();
      }
    },

    pendenciasLimpar() {
      const pendencias = {
        notificacoes: { priority: null, value: null },
        carrinhoAtualItens: { priority: null, value: null },
        convites: { priority: null, value: null },
        pessoas: { priority: null, value: null },
        parceiros: { priority: null, value: null },
        produtos: { priority: null, value: null },
        publicacoes: { priority: null, value: null },
        cupons: { priority: null, value: null },
        carrinhos: { priority: null, value: null },
        pedidos: { priority: null, value: null },
        avaliacoes: { priority: null, value: null },
        transacoes: { priority: null, value: null },
        feedbacks: { priority: null, value: null },
      };
      this.setVar({ pendencias });
    },
  }
}