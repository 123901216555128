<!-- COD: FDS 27/07/2022 ValorPagRec_ContasPagRec -->
<!-- *VER: JER 06/08/2022 ValorPagRec_ContasPagRec -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context }">
        <c-valorPagRec-view :context="context" :dataset="dataset" />
        <v-divider v-bind="prop.divider" />
      </template>
    </c-base-detail>

    <c-base-list
      v-if="loadList"
      :funcs="funcsList"
      :idFeature="cIdFeature"
      :idPage="idPageList"
      :idPageAlt="idPage"
      :pageModeEdit="true"
    >
      <template #insert="{ context }">
        <div v-if="context.datasets.length">
          <!-- label -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="red--text text-body-2">
                selecione as contas a associar
              </span>
            </v-col>
          </v-row>
        </div>

        <!-- saldo a associar -->
        <v-row
          v-if="context.datasets.length"
          class="sticky mt-3"
          justify="center"
        >
          <v-col v-bind="prop.col" cols="6">
            <!-- div criada para o campo não ficar transparente sobre a tela -->
            <div class="white" style="height: 65%">
              <c-text-money
                classe="text-h5 font-weight-light"
                :clearable="false"
                color="green"
                label="saldo a associar"
                :max="15"
                :options="{
                  length: 11,
                  precision: 2,
                  style: 'currency',
                }"
                outlined
                readonly
                :success="getTotais(context).saldo == 0"
                :value="getTotais(context).saldo"
              />
            </div>
          </v-col>
        </v-row>
      </template>

      <template #bottom="{ context }">
        <div v-if="context.datasets.length" class="mt-5">
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="5">
              <!-- total selecionado -->
              <c-text-money
                class="text-h5 font-weight-light"
                :clearable="false"
                label="total selecionado"
                :max="15"
                :options="{
                  length: 11,
                  precision: 2,
                  style: 'currency',
                }"
                outlined
                readonly
                :value="getTotais(context).totalSelecionado"
              />
              <!-- quantidade selecionada -->
              <v-badge
                v-if="getTotais(context).quantidade > 0"
                class="ml-n2 mb-n3"
                color="grey"
                :content="getTotais(context).quantidade"
                overlap
              />
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-slot="{ context, dataset: contaPagRec, index }">
        <c-valorPagRec-page-contaPagRec-edit
          :contaPagRec="contaPagRec"
          :context="context"
          :index="index"
          :saldoAssociar="saldoAssociar"
        />
      </template>
    </c-base-list>
  </div>
</template>

<script>
/// IMPORT
import CValorPagRecPageContaPagRecEdit from "@/components/pages/valorPagRec/pages/CValorPagRecPageContaPagRecEdit";
import CValorPagRecView from "@/components/pages/valorPagRec/CValorPagRecView";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "ValorPagRec_ContasPagRec",

  /// COMPONENTS
  components: {
    CValorPagRecPageContaPagRecEdit,
    CValorPagRecView,
  },

  /// MIXINS
  mixins: [mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idFeature: getFeature("optionContasPagRecAssociar").id,
      idPage: getPagina("valorPagRec").id,
      idPageAlt: getPagina("valoresPagRec").id,
      idPageList: getPagina("contasPagRec").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("valorRec").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            idPessoa: null,
            idFormaPagamento: null,
            numeroDocumento: null,
            motivoDevolucao: null,
            emitenteNome: null,
            emitenteDocumento: null,
            idPessoaParceiroContaBancaria: null,
            banco: null,
            agencia: null,
            conta: null,
            dataEmissao: null,
            dataApropriacao: null,
            valor: null,
            saldoAssociar: null,
            identificador: null,
            historico: null,
            observacoes: null,
            dataAtualizacao: null,
          },
        },
        aux: {
          detail: {
            pessoaParceiro: null,
            pessoa: null,
          },
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          context.params.context = context;
          if (context.dataset.main.detail.banco) {
            context.dataset.aux.detail.bancoAgenciaConta = `${context.dataset.main.detail.banco}/${context.dataset.main.detail.agencia}/${context.dataset.main.detail.conta}`;
          }
          const tipo = context.tipos.find(
            (tipo) => tipo.value == context.dataset.main.detail.idTipo
          );
          const pageList = this.getPagina(this.idPageList);
          pageList.idContexto = tipo.value - 100;
          this.saldoAssociar = context.dataset.main.detail.saldoAssociar;
          this.id = context.dataset.main.detail.id;
          this.idTipo = context.dataset.main.detail.idTipo;
          this.loadList = true;
        },
      },

      funcsList: {
        changed: (context) => {
          return context.datasets.some(
            (dataset, i) =>
              dataset.contaAssociada != context.datasetsTemp[i].contaAssociada
          );
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        clickSave: async (context) => {
          const itensSelecionados = this.getTotais(
            context.options.click
          ).quantidade;
          const totalSelecionado = this.getTotais(
            context.options.click
          ).totalSelecionado;
          const saldoAssociar = this.getTotais(context.options.click).saldo;
          const textoItensSelecionados =
            itensSelecionados > 1
              ? `nos ${itensSelecionados} itens selecionados.`
              : "no item selecionado.";
          const textoTotalSelecionado = `${this.numberFormat(
            totalSelecionado,
            "currency"
          )} serão associados`;
          const textoSaldoAssociar = saldoAssociar
            ? `\nMas ainda há ${this.numberFormat(
                saldoAssociar,
                "currency"
              )} de saldo a associar.`
            : "";
          const acao = saldoAssociar ? "Prosseguir assim mesmo" : "Prosseguir";
          const texto = `${textoTotalSelecionado} ${textoItensSelecionados}${textoSaldoAssociar}\n${acao}?`;
          const color = saldoAssociar ? "error" : "warning";

          context.options.click.setSnackbar(
            true,
            "ASSOCIAR CONTAS",
            texto,
            color,
            "mdi-check",
            "associarConfirm",
            this.getConst("config").snackbar.timeout,
            null
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "associarConfirm") {
            this.associarContasPagRec(context);
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          return JSON.stringify({
            isBaixa: true,
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
          dataset.valorItem = dataset.saldoLimite;
          dataset.valorAssociar = null;
          dataset.contaAssociada = false;
          dataset.disabled = false;
        },

        tipos: (tipos, context) => {
          tipos = tipos.filter(
            (tipo) => tipo.value != this.dataset.main.detail.idTipo
          );
          context.pageAlt.idContexto = tipos[0].value;

          return tipos;
        },
      },

      params: {
        clickValorPagRec: null,
        context: null,
      },

      id: null,
      idTipo: null,
      loadList: false,
      saldoAssociar: "0",
    };
  },

  /// COMPUTED
  computed: {
    cIdFeature() {
      const idFeature = new Number(this.idFeature);
      idFeature.pageAlt = true;

      return idFeature;
    },
  },

  /// METHODS
  methods: {
    async associarContasPagRec(context) {
      const metadados = {
        idEvento: context.getTipo_item("eventoAlterado").id,
        modo: context.getConst("config").modo.cadastro.id,
        idUsuario: context.getVar("idUsuario"),
        idPessoaParceiro: context.getVar("idPessoaParceiro"),
      };

      const dataset = {
        metadados,
        params: {
          itensSelecionados: context.datasets
            .filter((dataset) => dataset.contaAssociada)
            .filter((dataset, i) =>
              Object.keys(dataset).some(
                (key) => dataset[key] != context.datasetsTemp[i][key]
              )
            ),
        },
      };

      if (dataset.params.itensSelecionados.length) {
        dataset.params.itensSelecionados = dataset.params.itensSelecionados.map(
          (detail) => {
            return {
              id: parseInt(detail.id),
              valorAssociar: parseFloat(detail.valorAssociar),
            };
          }
        );

        const method = "put";
        let url = `${
          context.getConst("app").baseUrlBackend
        }/valoresPagRec/associar/${this.id}`;

        await context.submit(method, url, dataset);

        context.datasets = JSON.parse(JSON.stringify(context.datasetsTemp));

        const id = this.id;
        const tipo = context.getTipo_item(this.idTipo).nomeAlt;
        const itensSelecionados = this.getTotais(context).quantidade;
        const textoItensSelecionados =
          itensSelecionados > 1
            ? `Os ${itensSelecionados} itens selecionados foram associados`
            : "O item selecionado foi associado";
        const textoSelecionado = `a este valor ${tipo} (#${id}).`;
        const texto = `${textoItensSelecionados} ${textoSelecionado}`;
        const color = "success";

        context.setSnackbar(true, "ASSOCIAR CONTAS", texto, color);

        let interval = setInterval(() => {
          if (!context.snackbar.show) {
            clearInterval(interval);
            interval = null;
            context.funcs.clickVoltar();
          }
        }, 100);
      }
    },

    getTotais(context) {
      const totalSelecionado = context.datasets
        .filter((dataset) => dataset.contaAssociada)
        .reduce((acc, item) => {
          return (acc += parseFloat(item.valorAssociar));
        }, 0);

      const saldo =
        this.saldoAssociar -
        context.datasets
          .filter((dataset) => dataset.contaAssociada)
          .reduce((acc, item) => (acc += parseFloat(item.valorAssociar)), 0);

      const quantidade = context.datasets.filter(
        (dataset) => dataset.contaAssociada
      ).length;

      const totais = {
        totalSelecionado,
        saldo,
        quantidade,
      };

      return totais;
    },
  },
};
</script>