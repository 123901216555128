<!-- COD: LYF 06/07/2022 Produto -->
<!-- *VER: JER 08/07/2022 Produto -->
<!-- /22/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- // IMAGENS -->
      <template #images="{ dataset, pageModeView }">
        <c-base-detail-images :dataset="dataset" :pageModeView="pageModeView" />
      </template>

      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-produto-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
            :params="params"
          />
          <c-produto-edit
            v-else
            :context="context"
            :dataset="dataset"
            :params="params"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseDetailImages from "@/components/bases/CBaseDetailImages";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CProdutoEdit from "@/components/pages/produto/CProdutoEdit";
import CProdutoView from "@/components/pages/produto/CProdutoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Produto",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CBaseDetailImages,
    CBaseDetailInsert,
    CProdutoEdit,
    CProdutoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("produto").id,
      idPageAlt: getPagina("produtos").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idGrupo: null,
            produto: "",
            idFabricante: null,
            marca: "",
            serie: "",
            descricao: {},
            descricaoComplementar: {},
            modelo: "",
            partNumber: "",
            codigoBarra: "",
            idOrigem: null,
            codigoFiscal: null,
            codigoFiscal2: null,
            idUnidadeMedida: null,
            isControlaEstoque: false,
            decimalEstoque: 0,
            estoqueProprio: null,
            estoqueEmprestado: null,
            estoqueDefeito: null,
            reservaCompra: null,
            reservaVenda: null,
            dataDisponibilidade: null,
            decimalValor: 2,
            quantidadeMinima: null,
            quantidadeMaxima: null,
            custoReposicao: null,
            custoMedio: null,
            dataPagamentoMedia: null,
            margemContribuicao: null,
            preco: null,
            margemUsuario: null,
            prazoPagamento: null,
            prazoGarantia: null,
            isEntregar: false,
            isRetirar: false,
            requirePresencaCliente: false,
            tempoExecucao: null,
            tempoPosExecucao: null,
            isSimultaneo: null,
            idParte: null,
            pesoBruto: null,
            pesoLiquido: null,
            volume: null,
            largura: null,
            altura: null,
            profundidade: null,
            unidadesPorCaixa: null,
            observacao: null,
            argumentoVenda: {},
            caracteristicas: {},
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
            isDisponivel: null,
          },
          fornecedores: [],
          categorias: [],
          identificadores: [],
          funcoes: [],
          relacionadosRecomendados: [],
          relacionadosRequeridos: [],
          favoritos: [],
          kardexes: [],
          inventarios: [],
        },
        aux: {
          autocomplete: {
            fabricantes: [],
            fornecedores: [],
            grupos: [],
            identificadores: [],
            localizacoes: [],
            marcas: [],
            produtos: [],
            proprietarios: [],
            series: [],
          },
          detail: {
            pessoaParceiro_nomeAlt: null,
            pessoafabricante: null,
          },
          imagens: [
            {
              id: null,
              endereco: null,
            },
          ],
          relacionadosRequeridos: [],
          relacionadosRecomendados: [],
        },
      },

      funcs: {
        clickCupons: (context) => {
          const route = {
            path: "/cupons",
            query: {
              nome: this.produtoFormat(
                context.dataset.main.detail,
                false,
                true,
                true,
                true,
                true
              ),
            },
          };
          route.query["idProduto"] = context.dataset.main.detail.id;

          this.$router.push(route);
        },

        clickPedidos: (context) => {
          const route = {
            path: "/pedidos",
            query: {
              nome: this.produtoFormat(
                context.dataset.main.detail,
                false,
                true,
                true,
                true,
                true
              ),
            },
          };
          route.query["idProduto"] = context.dataset.main.detail.id;

          this.$router.push(route);
        },

        clickKardex: (context) => {
          this.$router.push(
            `/produto/kardex/${context.dataset.main.detail.id}`
          );
        },

        clickNumerosSerie: async (context) => {
          const route = {
            path: `/produto/numerosserie/${context.dataset.main.detail.id}`,
            query: {
              nome: this.produtoFormat(
                context.dataset.main.detail,
                false,
                true,
                true,
                true
              ),
            },
          };

          this.$router.push(route);
        },

        clickSnackbar: (action, context) => {
          if (action == "login") {
            this.$router.push({
              path: "/",
              query: { showAuth: true },
            });
          } else if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
            context.setSnackbar();
          } else if (action == "itemIncluir") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          } else if (action == "grupo") {
            context.dataset.grupoAlterar = true;
            context.setSnackbar();
          } else if (action == "carrinho") {
            context.clickCarrinho();
            context.setSnackbar();
          }
        },

        fnParams: () => {
          return JSON.stringify({
            idPessoaParceiro: this.getVar("idPessoaParceiro"),
          });
        },

        preValidate: async (context) => {
          if (context.dataset.main.detail.id) {
            const grupos = context.dataset.aux.autocomplete.grupos;
            if (
              grupos.length &&
              typeof context.dataset.main.detail.idGrupo === "string"
            ) {
              await context.setSnackbar(
                true,
                "GRUPO DE PRODUTO",
                "Deseja alterar este grupo para todos os produtos?",
                "warning",
                "mdi-check",
                "grupo",
                null
              );
            }
          }
        },

        posSubmit: (context) => {
          context.defaultParams.escolheTipo = false;

          if (context.dataset.aux.detail.parceiro_corPrimaria) {
            this.$vuetify.theme.themes.light.primary =
              context.dataset.aux.detail.parceiro_corPrimaria;
          }

          if (context.dataset.main.detail.idUnidadeMedida) {
            context.dataset.main.detail.decimalEstoque = parseInt(
              this.getTipo_item(context.dataset.main.detail.idUnidadeMedida)
                .options.produtoEstoqueDecimais
            );
          }
        },
      },

      params: {
        context: this,
        clickEntrega: null,
        escolheTipo: true,
        parceiro: null,
        recarregar: false,
      },

      colaboradores: true,
      detecteds: [],
      forceUpdate: false,
      mostrarValor: true,
      parceiro: getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == getVar("idPessoaParceiro")
      )?.parceiro,
      readerSize: {
        width: 640,
        height: 480,
      },
    };
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getTipo: "tipos/getTipo",
    }),

    favoritos() {
      return this.dataset.main.favoritos.length;
    },

    favoritou() {
      return this.dataset.main.favoritos.find(
        (favorito) => favorito.idUsuario == this.getVar("idUsuario")
      );
    },
  },

  ///METHODS
  methods: {
    setForceUpdate() {
      this.forceUpdate = false;
      this.$forceUpdate();
    },
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },

  /// DESTROYED
  destroyed() {
    this.$vuetify.theme.themes.light.primary =
      this.getUsuario(this.getVar("idPessoaParceiro"))?.parceiro?.corPrimaria ||
      this.getConst("app").corPrimaria;
  },
};
</script>