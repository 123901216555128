<!-- COD: FDS 01/07/2022 CExtratos -->
<!-- *VER: JER 01/07/2022 CExtratos -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- banco_anexo -->
    <v-row v-bind="prop.row" v-if="dataset.pessoaParceiro_banco_anexo_endereco">
      <v-col v-bind="prop.col" align="center">
        <v-img :src="dataset.pessoaParceiro_banco_anexo_endereco" width="30" />
      </v-col>
    </v-row>

    <!-- pessoaParceiro_bancoAgenciaConta -->
    <v-row v-bind="prop.row" v-if="pessoaParceiro_bancoAgenciaConta">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ pessoaParceiro_bancoAgenciaConta }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- dataInicioFim -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="dataset.idEstado != getEstado('cadastro').id && dataInicioFim"
        align="center"
      >
        <span class="text-h6">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- dataEmissao -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="
          dataset.idEstado != getEstado('cadastro').id && dataset.dataEmissao
        "
        align="center"
      >
        <span class="text-body-2">
          {{ dataFormata(dataset.dataEmissao, "date timeNoSecond") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- diferenca de saldo -->
      <v-col v-bind="prop.col" cols="1">
        <v-icon
          v-if="
            dataset.extratoAnterior_saldoFinal &&
            dataset.saldoInicial != dataset.extratoAnterior_saldoFinal
          "
          color="red"
        >
          mdi-link-variant-off
        </v-icon>
      </v-col>

      <!-- debitos -->
      <v-col v-bind="prop.col" cols="2" align="end">
        <span class="mr-n1 red--text text-caption">
          {{ dataset.debitos }}
        </span>
        <v-icon class="red--text">mdi-alpha-d-circle</v-icon>
      </v-col>

      <!-- creditos -->
      <v-col v-bind="prop.col" cols="2" align="end">
        <span class="blue--text mr-n1 text-caption">
          {{ dataset.creditos }}
        </span>
        <v-icon class="blue--text">mdi-alpha-c-circle</v-icon>
      </v-col>

      <!-- saldo -->
      <v-col
        v-bind="prop.col"
        align="end"
        :class="dataset.saldoFinal < 0 ? 'red--text' : ''"
        class="ml-n2"
        cols="7"
      >
        <span class="text-body-2">saldo</span>
        <span class="`text-body-1">
          {{ numberFormat(dataset.saldoFinal, "currency") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CExtratos",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
    }),

    dataInicioFim() {
      const dataInicio =
        this.dataset.dataInicio != this.dataset.dataFim
          ? `${this.dataFormata(this.dataset.dataInicio, "dateNoYear")} - `
          : "";

      return `${dataInicio}${this.dataFormata(this.dataset.dataFim, "date")}`;
    },

    pessoaParceiro_bancoAgenciaConta() {
      return `${this.dataset.pessoaParceiro_banco_codigo} ${this.dataset.pessoaParceiro_agencia} ${this.dataset.pessoaParceiro_conta}`;
    },
  },
};
</script>