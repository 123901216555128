<!-- COD: LYF 12/08/2022 CCupomEdit -->
<!-- #BUG: JER __/08/2022 CCupomEdit: não existem os campos marca e fornecedor na tabela cupom -->
<!-- VER: JER __/08/2022 CCupomEdit -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- codigo -->
      <v-col v-bind="prop.col" cols="9">
        <c-text-field
          v-model="dataset.main.detail.codigo"
          :disabled="
            dataset.main.detail.idTipo == getTipo_item('cupomCashback').id
          "
          label="código"
          :max="25"
          required
        />
      </v-col>
      <!-- quantidade -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.main.detail.idTipo != getTipo_item('cupomRebate').id"
        cols="3"
      >
        <c-text-money
          v-model="dataset.main.detail.quantidade"
          :disabled="
            dataset.main.detail.idTipo == getTipo_item('cupomCashback').id
          "
          label="quantidade"
          :max="4"
          :options="{
            length: 4,
            precision: 0,
          }"
          :required="
            dataset.main.detail.idTipo != getTipo_item('cupomGerador').id
          "
        />
      </v-col>
    </v-row>

    <!-- descricao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.descricao"
          :disabled="
            dataset.main.detail.idTipo == getTipo_item('cupomCashback').id
          "
          label="descrição"
          :max="50"
          required
        />
      </v-col>
    </v-row>

    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idTipo == getTipo_item('cupomGerador').id ||
        dataset.main.detail.idTipo == getTipo_item('cupomDesconto').id
      "
    >
      <!-- icone -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.main.detail.idTipo == getTipo_item('cupomDesconto').id"
        cols="1"
      >
        <c-btn
          icon
          :help="{
            title: 'VALOR OU PERCENTUAL',
            text: 'Clique no ícone para alternar entre valor ou percentual.',
            function: context.setUiBottomSheet,
          }"
          @click="clickIcon"
        >
          <v-icon>{{ icone }}</v-icon>
        </c-btn>
      </v-col>
      <!-- valor -->
      <v-col v-bind="prop.col" v-if="showValor" cols="5">
        <c-text-money
          v-model="dataset.main.detail.valor"
          :disabled="
            dataset.main.detail.idTipo == getTipo_item('cupomCashback').id
          "
          label="valor"
          :max="10"
          :options="{
            style: 'currency',
            length: 8,
            precision: 2,
          }"
          required
        />
      </v-col>
      <!-- percentual -->
      <v-col
        v-bind="prop.col"
        v-if="
          !showValor ||
          dataset.main.detail.idTipo == getTipo_item('cupomGerador').id
        "
        cols="5"
      >
        <c-text-money
          v-model="dataset.main.detail.percentual"
          label="percentual"
          :max="5"
          :options="{
            style: 'percent',
            length: 3,
            precision: 1,
            suffix: '%',
          }"
          required
        />
      </v-col>
    </v-row>

    <!-- fornecedores -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idTipo == getTipo_item('cupomDesconto').id ||
        dataset.main.detail.idTipo == getTipo_item('cupomRebate').id
      "
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPessoaFornecedor"
          :itemsIniciais="dataset.aux.autocomplete.fornecedor"
          label="fornecedores"
          :path="`/pessoas/autocomplete/fornecedores/0/1/${idPessoaParceiro}/`"
          :required="
            dataset.main.detail.idTipo == getTipo_item('cupomRebate').id
          "
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- cliente -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idTipo == getTipo_item('cupomDesconto').id ||
        dataset.main.detail.idTipo == getTipo_item('cupomCashback').id
      "
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPessoaCliente"
          :disabled="
            dataset.main.detail.idTipo == getTipo_item('cupomCashback').id
          "
          :itemsIniciais="dataset.aux.autocomplete.cliente"
          label="cliente"
          :path="`/pessoas/autocomplete/cliente/0/0/${idPessoaParceiro}/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idTipo == getTipo_item('cupomDesconto').id ||
        dataset.main.detail.idTipo == getTipo_item('cupomRebate').id
      "
    >
      <!-- desconsiderar -->
      <v-col v-bind="prop.col" cols="5">
        <c-select
          v-model="dataset.main.detail.idDesconsiderar"
          :help="{
            title: 'DESCONSIDERAR',
            text: 'Tempo que será desconsiderado para cálculo do vencimento do cupom.',
            function: context.setUiBottomSheet,
          }"
          :items="getTipo_itens(getTipo('unidadeTempo').id)"
          label="desconsiderar"
          required
        />
      </v-col>
      <!-- prazo -->
      <v-col v-bind="prop.col" cols="3">
        <c-text-field
          v-model="dataset.main.detail.prazo"
          :help="{
            title: 'PRAZO',
            text: 'Prazo que será considerado para cálculo do vencimento do cupom.',
            function: context.setUiBottomSheet,
          }"
          label="prazo"
          :max="3"
          required
          type="tel"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CTextField from "../../ui/CTextField.vue";

export default {
  components: { CTextField },
  /// NAME
  name: "CCupomEdit",

  /// DATA
  data() {
    return {
      showValor: this.dataset.main.detail.valor ? true : false,
    };
  },

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    icone() {
      return this.showValor ? "mdi-currency-usd" : "mdi-percent-outline";
    },

    idPessoaParceiro() {
      return (
        this.dataset.main.detail.idPessoaParceiro ||
        this.getVar("idPessoaParceiro")
      );
    },
  },

  /// METHODS
  methods: {
    clickIcon() {
      this.dataset.main.detail.valor = null;
      this.dataset.main.detail.percentual = null;
      this.showValor = !this.showValor;
    },
  },
};
</script>