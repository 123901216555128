<!-- COD: FDS 29/07/2022 CPontoEletronicoPageApontamentoView -->
<!-- *VER: JER 07/08/2022 CPontoEletronicoPageApontamentoView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- marcacoes -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span
          v-for="(marcacao, i) in dataset.marcacoes[data.id]"
          :key="`marcacao${i}`"
        >
          <!-- marcacao referente a data anterior -->
          <v-icon v-if="isMarcacaoAnterior(marcacao)" class="ml-2 mr-n3" small>
            mdi-arrow-down-thin
          </v-icon>
          <!-- marcacao referente a data seguinte -->
          <v-icon v-if="isMarcacaoSeguinte(marcacao)" class="ml-2 mr-n3" small>
            mdi-arrow-up-thin
          </v-icon>
          <!-- marcacao registrada/incluida manualmente -->
          <span v-if="marcacao.isUpdated && marcacao.data" class="ml-2 pt-3">
            {{ format(parseISO(marcacao.data), "HH:mm") }}
          </span>
          <!-- marcacao registrada pelo ponto -->
          <a
            v-else-if="marcacao.id && marcacao.data"
            class="ml-2 pt-3"
            @click="$emit('clickComprovante', marcacao.id)"
          >
            {{ format(parseISO(marcacao.data), "HH:mm") }}
          </a>
        </span>
      </v-col>
    </v-row>

    <!-- alerta de inconsistencias -->
    <v-row v-bind="prop.row" v-if="data.idInconsistencia">
      <v-col v-bind="prop.col">
        <v-chip v-bind="prop.chip">marcações inconsistentes</v-chip>
      </v-col>
    </v-row>

    <!-- botao para complementar a marcacao -->
    <v-row v-bind="prop.row" v-if="data.idInconsistencia">
      <v-col v-bind="prop.col">
        <c-btn class="text-body-2 text-lowercase" color="error">
          complementar
        </c-btn>
      </v-col>
    </v-row>

    <!-- apontamentos -->
    <div
      v-for="(apontamento, i) in dataset.apontamentos[data.id]"
      :key="`apontamento${i}`"
    >
      <v-row v-bind="prop.row">
        <!-- apontamento -->
        <v-col v-bind="prop.col" cols="5">
          <v-chip v-bind="prop.chip">
            {{
              typeof apontamento == "string"
                ? apontamento
                : getVerba(apontamento.idVerba).options.nomeAlt
            }}
          </v-chip>
        </v-col>
        <!-- horas apontadas -->
        <v-col
          v-bind="prop.col"
          v-if="
            apontamento.horasApontadas &&
            getVerba(apontamento.idVerba).options.unidade == 'hora'
          "
          cols="2"
        >
          <span :class="`${apontamentosColor(data, apontamento)} text-body-2`">
            {{ apontamento.horasApontadas.substring(0, 5) }}
          </span>
        </v-col>
        <!-- horas confirmadas -->
        <v-col
          v-bind="prop.col"
          v-if="
            apontamento.horasConfirmadas != null &&
            getVerba(apontamento.idVerba).options.unidade == 'hora'
          "
          cols="2"
        >
          <span
            class="ml-4 text-body-2"
            :class="apontamentosEfetivosColor(apontamento)"
          >
            {{ apontamento.horasConfirmadas.substring(0, 5) }}
          </span>
        </v-col>
      </v-row>

      <!-- confirmar apontamento -->
      <v-row
        v-bind="prop.row"
        v-if="
          isUsuarioGestorSelected &&
          apontamento.horasConfirmadas == null &&
          apontamento.idSituacaoTrabalho == null &&
          apontamento.idVerba
        "
      >
        <v-col v-bind="prop.col">
          <c-btn class="text-body-2 text-lowercase" color="error">
            confirmar
          </c-btn>
        </v-col>
      </v-row>

      <!-- justificar falta -->
      <v-row
        v-bind="prop.row"
        v-if="
          isUsuarioGestorSelected &&
          apontamento.idVerba &&
          getVerba(apontamento.idVerba).options.nomeAlt == 'falta'
        "
      >
        <v-col v-bind="prop.col">
          <c-btn class="text-body-2 text-lowercase" color="error">
            justificar
          </c-btn>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row" v-else>
        <v-col v-bind="prop.col">
          <!-- situacao trabalho -->
          <div v-if="apontamento.idSituacaoTrabalho">
            <span class="text-body-2">
              {{ getTipo_item(apontamento.idSituacaoTrabalho).nomeAlt }}
            </span>
          </div>
          <!-- jsutificativa -->
          <div v-if="apontamento.justificativa">
            <span class="text-body-2">
              {{ apontamento.justificativa }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- feriado -->
    <v-row v-bind="prop.row" v-if="data.idFeriado">
      <v-col v-bind="prop.col">
        <v-chip v-bind="prop.chip">feriado</v-chip>
      </v-col>
    </v-row>

    <!-- feriado -->
    <v-row v-bind="prop.row" v-if="data.idFeriado">
      <v-col v-bind="prop.col">
        <span class="text-body-2">
          {{ getFeriado(data.idFeriado).nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPontoEletronicoPageApontamentoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    data: { type: Object, required: true },
    dataset: { type: Object, required: true },
    idGestorImediato: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeriado: "feriados/getFeriado",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
      getVerba: "verbas/getVerba",
    }),

    isUsuarioGestorSelected() {
      return this.getVar("idUsuario") == this.idGestorImediato;
    },

    marcacoes() {
      return this.dataset.main.marcacoes.filter(
        (marcacao) => marcacao.idPai2 == this.data.id
      );
    },
  },

  /// METHODS
  methods: {
    apontamentosColor(data, apontamento) {
      const greyColor = data.isFolga || data.idFeriado;

      if (greyColor) {
        return ["grey--text"];
      }

      const idVerbasOcorrencia = [
        this.getVerba("p4FeriasMes").id,
        this.getVerba("p4SalarioMaternidade").id,
      ];

      if (
        !idVerbasOcorrencia.includes(apontamento.idVerba) &&
        apontamento.idPai2 == data.id &&
        apontamento.idSituacaoTrabalho == null &&
        apontamento.horasConfirmadas == null
      ) {
        return "red--text";
      }
    },

    apontamentosEfetivosColor(apontamento) {
      if (apontamento.horasApontadas == apontamento.horasConfirmadas) {
        return "green--text";
      } else if (apontamento.horasConfirmadas == "00:00:00") {
        return "red--text";
      } else {
        return "blue--text";
      }
    },

    isMarcacaoAnterior(marcacao) {
      return (
        marcacao.data &&
        this.format(this.parseISO(marcacao.data), "yyyy-MM-dd") >
          this.format(
            this.parseISO(this.data.data.substring(0, 10)),
            "yyyy-MM-dd"
          )
      );
    },

    isMarcacaoSeguinte(marcacao) {
      return (
        marcacao.data &&
        this.format(this.parseISO(marcacao.data), "yyyy-MM-dd") <
          this.format(
            this.parseISO(this.data.data.substring(0, 10)),
            "yyyy-MM-dd"
          )
      );
    },
  },
};
</script>