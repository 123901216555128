<!-- COD: LYF 29/07/2022 FolhaPagamento_Colaboradores -->
<!-- *VER: JER 08/08/2022 FolhaPagamento_Colaboradores -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPageDetail"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context }">
        <c-folhaPagamento-view :context="context" :dataset="dataset" />
      </template>
    </c-base-detail>

    <c-base-list
      v-if="loadList"
      :funcs="funcsList"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :pageModeEdit="true"
      @showSearchOptions="showSearchOptions = $event"
    >
      <template #search="{ context }">
        <v-container>
          <!-- pessoal administrativo -->
          <v-row v-bind="prop.row" v-if="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="pessoalAdministrativo"
                label="só pessoal administrativo"
                @click="filtroDatasets(context, false)"
              />
            </v-col>
          </v-row>

          <!-- pessoal comercial -->
          <v-row v-bind="prop.row" v-if="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="pessoalComercial"
                label="só pessoal comercial"
                @click="filtroDatasets(context, true)"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #listContent="{ context, datasets }">
        <c-expansion-panels
          flat
          :pageModeView="true"
          :params="params"
          :value="datasets"
        >
          <template #header="{ dataset }">
            <v-container class="py-0">
              <v-row v-bind="prop.row">
                <!-- nome colaborador -->
                <v-col v-bind="prop.col" cols="10">
                  <span class="text-body-1">
                    {{ dataset.nome }}
                  </span>
                </v-col>
                <!-- numero registros -->
                <v-col
                  v-bind="prop.col"
                  v-if="bagdeColaborador(dataset, context) > 0"
                  align="end"
                  cols="2"
                >
                  <v-badge
                    color="grey"
                    :content="bagdeColaborador(dataset, context).toString()"
                    inline
                    overlap
                  ></v-badge>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <!-- cargo -->
                <v-col v-bind="prop.col" cols="6">
                  <span class="text-body-2">
                    {{ dataset.cargo }}
                  </span>
                </v-col>
                <!-- valor salario -->
                <v-col v-bind="prop.col" align="end" cols="6">
                  <v-badge
                    color="grey"
                    :content="numberFormat(dataset.valorSalario, 'currency')"
                    inline
                    overlap
                  ></v-badge>
                </v-col>
              </v-row>

              <!-- situacao trabalho -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <v-chip v-bind="prop.chip">
                    {{ situacaoTrabalho }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template #content="{ dataset: colaborador }">
            <c-folhaPagamento-page-colaborador-view
              v-if="pageModeView"
              :colaborador="colaborador"
              :context="context"
            />
            <c-folhaPagamento-page-colaborador-edit
              v-else
              :colaborador="colaborador"
              :context="context"
            />
          </template>
        </c-expansion-panels>
      </template>
    </c-base-list>
  </div>
</template>

<script>
/// IMPORT
import CFolhaPagamentoPageColaboradorEdit from "@/components/pages/folhaPagamento/pages/CFolhaPagamentoPageColaboradorEdit";
import CFolhaPagamentoPageColaboradorView from "@/components/pages/folhaPagamento/pages/CFolhaPagamentoPageColaboradorView";
import CFolhaPagamentoView from "@/components/pages/folhaPagamento/CFolhaPagamentoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "FolhaPagamento_Colaboradores",

  /// COMPONENTS
  components: {
    CFolhaPagamentoPageColaboradorEdit,
    CFolhaPagamentoPageColaboradorView,
    CFolhaPagamentoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVerba = this.$store.getters["verbas/getVerba"];

    return {
      idFeature: getFeature("optionColaboradoresFolha").id,
      idPage: getPagina("folhaPagamentoColaboradores").id,
      idPageAlt: getPagina("folhasPagamentos").id,
      idPageDetail: getPagina("folhaPagamento").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("fpNormal").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            dataInicio: null,
            dataFim: null,
          },
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          this.contextDetail = context;
          context.params.context = context;

          this.dataset = context.dataset;
          this.id = context.dataset.main.detail.id;

          if (this.contextList && this.loadList) {
            this.contextList.clickSearch();
          }

          this.loadList = true;
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },

      funcsList: {
        changed: () => {
          return !this.pageModeView;
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );

          this.funcsList.clickEditView();
        },

        clickEditView: () => {
          this.contextDetail.clickEditView();
          this.pageModeView = !this.pageModeView;
        },

        clickSave: async (context) => {
          this.funcsList.clickEditView();

          const metadados = {
            modo: context.options.click.getConst("config").modo.cadastro.id,
            idUsuario: context.options.click.getVar("idUsuario"),
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
          };

          const datasetsSave = {
            metadados,
            main: {
              detail: this.dataset.main.detail,
              colaboradores: context.options.click.datasets
                .filter((dataset, i) => {
                  const datasetTemp = context.options.click.datasetsTemp[i];
                  return (
                    !dataset.id ||
                    Object.keys(dataset).some((key) => {
                      return datasetTemp[key] !== undefined
                        ? dataset[key] != datasetTemp[key]
                        : false;
                    })
                  );
                })
                .map((dataset) => {
                  return {
                    id: dataset.idFp_col,
                    peso: dataset.peso,
                    comissao: dataset.comissao,
                    isUpdated: true,
                  };
                }),
              metas: context.options.click.datasetList.dataset.main.metas.map(
                (dataset) => {
                  return {
                    id: dataset.idFp_met,
                    metaPlanejada: dataset.metaPlanejada,
                    isUpdated: true,
                  };
                }
              ),
            },
          };

          const method = "put";
          let url = `${context.options.click.getConst("app").baseUrlBackend}/${
            context.options.click.pageAlt.nome
          }`;
          const res = await context.options.click.submit(
            method,
            url,
            datasetsSave
          );

          if (res) {
            context.options.click.clickSearch();
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          let params = null;
          params = {
            idFolhaPagamento: this.id,
            pessoalAdministrativo: this.pessoalAdministrativo,
            pessoalComercial: this.pessoalComercial,
          };

          return JSON.stringify(params);
        },

        getDetail: () => {
          return this.dataset;
        },

        posSubmit: (dataset, context, datasets, index) => {
          dataset.valorItem = dataset.valorSalario;

          if (index == 0) {
            this.contextList = context;
            context.page.nomeAjuda = context.pageAlt.nome;

            context.datasetList.dataset.aux = {
              metas: [],
            };
            context.datasetList.dataset.main.metas.forEach((meta, i) => {
              meta.index = i;
              context.datasetList.dataset.aux.metas.push({
                ...meta,
                numberFormatStyle: meta.options?.faixa
                  ? "decimal"
                  : meta.options?.percentual
                  ? "decimal"
                  : "currency",
                numberFormatLength: meta.options?.faixa
                  ? 5
                  : meta.options?.percentual
                  ? 5
                  : 11,
                numberFormatPrecision: meta.options?.faixa
                  ? 2
                  : meta.options?.percentual
                  ? 0
                  : 2,
                numberFormatSuffix:
                  meta.idVerba == getVerba("p4PremioCapilaridade").id
                    ? "clientes"
                    : meta.idVerba == getVerba("p4PremioMixProdutos").id
                    ? "produtos"
                    : meta.idVerba == getVerba("p4PremioMixMarcas").id
                    ? "marcas"
                    : "",
              });
            });
          }
        },
      },

      params: {
        context: null,
      },

      contextDetail: null,
      contextList: null,
      datas: [],
      loadList: false,
      pageModeView: true,
      pessoalAdministrativo: false,
      pessoalComercial: false,
      search: "",
      showSearchOptions: false,
    };
  },

  /// COMPUTED
  computed: {
    situacaoTrabalho() {
      return this.dataset.idSituacaoTrabalho
        ? this.getTipo_item(this.dataset.idSituacaoTrabalho).nomeAlt
        : this.getTipo_item("trabalhoNormal").nomeAlt;
    },
  },

  /// METHODS
  methods: {
    bagdeColaborador(colaborador, context) {
      return context.datasetList.dataset.main.metas
        .filter((meta) => meta.idPessoaColaborador == colaborador.id)
        .filter((meta) =>
          this.idVerbas?.length ? this.idVerbas.includes(meta.idVerba) : true
        ).length;
    },

    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    filtroDatasets(context, clickPessoalComercial = null) {
      if (clickPessoalComercial) {
        this.pessoalAdministrativo = false;
      } else if (clickPessoalComercial === false) {
        this.pessoalComercial = false;
      }

      const idPerfilComercial = [
        this.getTipo_item("perfilProdutos").id,
        this.getTipo_item("perfilVendas").id,
      ];

      context.datasets = JSON.parse(JSON.stringify(context.datasetsTemp));
      context.datasets = context.datasets.filter((dataset) =>
        this.pessoalAdministrativo || this.pessoalComercial
          ? idPerfilComercial.includes(dataset.idArea) ==
            !!this.pessoalComercial
          : true
      );
    },

    totalColaborador(colaborador, context) {
      const valor = context.datasetList.dataset.main.metas
        .filter((meta) => meta.idPessoaColaborador == colaborador.id)
        .filter((meta) =>
          this.idVerbas?.length ? this.idVerbas.includes(meta.idVerba) : true
        )
        .reduce((acc, meta) => acc + parseFloat(meta.valor), 0);

      return valor;
    },
  },
};
</script>