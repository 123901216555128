<!-- COD: BJB 09/08/2022 CBaseItemIdentification -->
<!-- *VER: JER 10/08/2022 CBaseItemIdentification -->
<!-- /11/ -->

<template>
  <v-container class="px-0">
    <v-row v-bind="prop.row" :class="classe">
      <v-col v-bind="prop.col" v-if="dataset.idFavorito !== undefined" cols="1">
        <c-btn icon @click.stop="clickFavorito">
          <v-icon
            :class="
              dataset.idFavorito ? 'text--darken-2 yellow--text' : 'grey--text'
            "
          >
            {{ dataset.idFavorito ? "star" : "star_outline" }}
          </v-icon>
        </c-btn>
      </v-col>
      <!-- //? estudar melhor o alinhamento dos componentes -->
      <v-col v-bind="prop.col" class="d-flex justify-space-between">
        <!-- //? remover -->
        <!-- <slot name="icon"></slot> -->
        <span
          class="mt-2"
          :class="dataset.idFavorito !== undefined ? 'ml-5' : ''"
        >
          <v-chip
            v-if="dataset.tipo"
            v-show="!order.text || order.text != 'tipo'"
            :outlined="idPage != getPagina('cupons').id"
            x-small
            :class="chipTipoClass"
          >
            {{ translate(dataset.tipo) }}
          </v-chip>

          <v-chip
            v-if="dataset.estado"
            v-show="order.text != 'estado'"
            class="white--text text-overline"
            :class="chipEstadoClass"
            x-small
          >
            {{ translate(dataset.estado) }}
          </v-chip>

          <v-icon v-if="dataset.isSuspenso" class="red--text" small>
            mdi-lock-outline
          </v-icon>
        </span>

        <!-- //? remover -->
        <!-- <slot name="info"></slot> -->
        <!-- <v-spacer /> -->

        <span v-if="dataset.id">
          <span v-if="!route" class="text-overline"> #{{ dataset.id }} </span>
          <c-btn v-else text @click="clickRouter()">
            <span class="text-overline primary--text"> #{{ dataset.id }} </span>
          </c-btn>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CBaseItemIdentification",

  /// MIXINS
  mixins: [mixinLib],

  /// PROPS
  props: {
    classe: { type: String },
    dataset: { type: Object, required: true },
    idPage: { type: Number, required: false },
    order: { type: Object, default: Object },
    route: { type: String },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getPagina: "paginas/getPagina",
    }),

    chipEstadoClass() {
      return `${this.dataset.estado} ${
        this.dataset.tipo && this.order.text != "tipo" ? "ml-n3" : ""
      }`;
    },

    chipTipoClass() {
      return `${
        this.idPage == this.getPagina("cupons").id
          ? `${this.dataset.tipo}1 white--text`
          : "primary primary--text"
      } text-overline`;
    },
  },

  /// METHODS
  methods: {
    clickRouter() {
      const url = `${this.route}/${this.dataset.id}`;
      this.$router.push(url);
    },

    clickFavorito() {
      const params = {
        id: this.dataset.id,
        idPage: this.idPage,
        idUsuario: this.getVar("idUsuario"),
        idPessoaParceiro: this.getVar("idPessoaParceiro"),
        favorito: !this.dataset.idFavorito,
      };

      const url = `${this.getConst("app").baseUrlBackend}/favoritos/favoritar`;

      axios
        .post(url, params)
        .then((res) => {
          this.dataset.idFavorito = res.data.id;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },
  },
};
</script>