<!-- COD: FDS 05/07/2022 FornecedorCliente -->
<!-- *VER: JER 18/07/2022 FornecedorCliente -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      v-if="load"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="changeEstado(context, $event)"
            @close="context.showEstado = false"
          />

          <c-fornecedor-cliente-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-fornecedor-cliente-edit
            v-else
            :context="context"
            :dataset="dataset"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import axios from "axios";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CFornecedorClienteEdit from "@/components/pages/fornecedorCliente/CFornecedorClienteEdit";
import CFornecedorClienteView from "@/components/pages/fornecedorCliente/CFornecedorClienteView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "FornecedorCliente",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CFornecedorClienteEdit,
    CFornecedorClienteView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("fornecedorCliente").id,
      idPageAlt: getPagina("fornecedoresClientes").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idPessoa: null,
            idClassificacaoFornecedor: null,
            idNoFornecedorCliente: null,
            isEntregarDefault: null,
            transportadoraDefault: null,
            numeroParcelasMaximo: null,
            prazoMedioPagamentoMaximo: null,
            numeroParcelasDefault: null,
            prazo1Default: null,
            prazoNDefault: null,
            allowPagamentoNaEntrega: null,
            dataDesde: "",
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          creditos: [],
          contatos: [],
          valoresAcumulados: [],
          auxPessoaParceiro: {
            nomeAlt: null,
          },
        },
        aux: {
          detail: {
            pessoaParceiro: null,
            pessoa: null,
            idExterno: null,
          },
          creditos_seguradoras: [],
          creditos_colaboradores: [],
          contatos_pessoas: [],
          contatos_pessoas_urls: [],
          contatos_pessoas_telefones: [],
          seguradoras: [],
          autocomplete: {
            pessoas: [],
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          if (action == "incluirCredito") {
            context.snackbar.params.clickIncluir();
          }

          context.setSnackbar();
        },
      },

      params: {
        context: null,
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
    }),
  },

  /// METHODS
  methods: {
    async changeEstado(context, event) {
      context.showEstado = false;

      if (event.main.detail.idEstado == this.getEstado("ativo").id) {
        setTimeout(async () => {
          context.setVar({ overlay: true });
          let url = `${
            this.getConst("app").baseUrlBackend
          }/fornecedoresClientes/enviarEmail`;

          await axios
            .post(url, event)
            .then((res) => {
              context.setSnackbar(
                true,
                "CADASTRO DE PESSOA",
                `A pessoa ${res.data} foi informada por e-mail que ela foi cadastrada`,
                "success"
              );
            })
            .catch((err) => {
              context.setSnackbar(true, "ERRO", err.response.data, "error");
            })
            .finally(() => {
              context.setVar({ overlay: false });
            });
        }, 1000);
      }
    },
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>