<!-- COD: FDS 20/07/2022 Faq -->
<!-- *VER: JER 06/08/2022 Faq -->
<!-- #VER: JER __/09/2022 Faq -->
<!-- /22/ -->

<template>
  <div>
    <c-base-list
      v-if="idPage && idPageAlt"
      :funcs="funcs"
      :hasData="false"
      :hasSearch="false"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      noList
      :pageModeEdit="false"
    >
      <!-- search -->
      <template #search="{ context }">
        <v-container>
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-text-field
                v-model="search"
                clearable
                :max="100"
                :noAccept="null"
                placeholder="busca"
                prependInnerIcon="mdi-magnify"
                solo
                type="search"
                @click:clear="filtroDatasets(context)"
                @keyup="filtroDatasets(context)"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #listContent="{ context, datasets }">
        <c-perguntaFrequente-page-faqs
          :context="context"
          :datasets="datasets"
        />
      </template>
    </c-base-list>
  </div>
</template>

<script>
/// IMPORT
import axios from "axios";
import CPerguntaFrequentePageFaqs from "@/components/pages/perguntaFrequente/pages/CPerguntaFrequentePageFaqs";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Faq",

  /// COMPONENTS
  components: {
    CPerguntaFrequentePageFaqs,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getFeature = this.$store.getters["paginas/getFeature"];

    return {
      idFeature: getFeature("optionPageFaq").id,
      idPage: getPagina("faq").id,
      idPageAlt: getPagina("perguntaFrequente").id,

      funcs: {
        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          const params = {};
          return JSON.stringify(params);
        },

        posSubmit: (dataset, context) => {
          this.perguntasFrequentesTemp =
            context.datasetList.dataset.main.perguntasFrequentes;
          this.params.panelsOpen[dataset.grupo] = [];

          if (this.routeHash) {
            const idPergunta = this.routeHash.match(/\d+/g);
            const perguntaIndex =
              context.datasetList.dataset.main.perguntasFrequentes
                .filter((pergunta) => pergunta.grupo == dataset.grupo)
                .findIndex((pergunta) => pergunta.id == idPergunta);

            if (perguntaIndex >= 0) {
              this.params.panelsOpen[dataset.grupo] = [perguntaIndex];
              this.goToPergunta();
            }
          }

          this.params.context = context;
          dataset.noDivider = true;
          context.limit = 100; //? f- remover
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },

      params: {
        panelsOpen: {},
        context: null,
      },

      panels: [],
      perguntasFrequentesTemp: null,
      search: "",
    };
  },

  /// COMPUTED
  computed: {
    pageModeView() {
      return false;
    },

    routeHash() {
      return this.$route.hash;
    },
  },

  /// WATCH
  watch: {
    search(valor, valorAnterior) {
      if (valor != valorAnterior) {
        this.filtroDatasets(this.params.context);
      }
    },
  },

  /// METHODS
  methods: {
    clickCurtir(like, pergunta, context) {
      like
        ? (pergunta.isCurtiu = pergunta.isCurtiu ? null : like)
        : (pergunta.isCurtiu = pergunta.isCurtiu == false ? null : like);

      const perguntasFrequentes_curtidas = {
        id: pergunta.idCurtida,
        idPai: pergunta.id,
        data: new Date(),
        idUsuario: this.getVar("idUsuario"),
        isCurtiu: pergunta.isCurtiu,
      };

      const url = `${this.getConst("app").baseUrlBackend}/faq`;
      axios
        .post(url, perguntasFrequentes_curtidas)
        .then((res) => {
          const indice =
            context.datasetList.dataset.main.perguntasFrequentes.findIndex(
              (perguntaFrequente) => perguntaFrequente.id == pergunta.id
            );

          context.datasetList.dataset.main.perguntasFrequentes[
            indice
          ].idCurtida = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });
    },

    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    filtroDatasets(context) {
      context.datasetList.dataset.main.perguntasFrequentes = JSON.parse(
        JSON.stringify(this.perguntasFrequentesTemp)
      );

      context.datasetList.dataset.main.perguntasFrequentes =
        context.datasetList.dataset.main.perguntasFrequentes.filter((dataset) =>
          dataset.pergunta && this.search
            ? dataset.pergunta
                .normalize("NFD")
                .replace(/[^a-zA-Zs]/g, "")
                .toUpperCase()
                .indexOf(
                  this.search
                    .normalize("NFD")
                    .replace(/[^a-zA-Zs]/g, "")
                    .toUpperCase()
                ) >= 0
            : true
        );
    },

    goToPergunta() {
      setTimeout(() => {
        this.$vuetify.goTo(this.routeHash, {
          duration: 1000,
          offset: 0,
          easing: "linear",
        });
      }, 100);
    },
  },
};
</script>