<!-- COD: FDS 05/07/2022 ContaContabil -->
<!-- *VER: JER 06/08/2022 ContaContabil -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ pageModeView, page, context }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-contaContabil-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-contaContabil-edit
            v-else
            :context="context"
            :dataset="dataset"
            :params="params"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CContaContabilEdit from "@/components/pages/contaContabil/CContaContabilEdit";
import CContaContabilView from "@/components/pages/contaContabil/CContaContabilView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "ContaContabil",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CContaContabilEdit,
    CContaContabilView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("contaContabil").id,
      idPageAlt: getPagina("contasContabeis").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idPai: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            nome: null,
            nomeAlt: null,
            nivel: null,
            idTipoDetalhe: null,
            isDetalheRequerido: null,
            allowLancamento: true,
            allowLancamentoManual: null,
            isSaldoDevedor: null,
            isSaldoCredor: null,
            options: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          contasContabeisFilhas: [],
          historicosPadrao: [],
        },

        aux: {
          autocomplete: {
            contasContabeisPai: [],
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        fnParamsReport: () => {
          const params = {};
          params.idContaContabil = this.dataset.main.detail.id;

          return JSON.stringify(params);
        },

        posLoad: (context) => {
          this.mode = context.pageMode;
          setTimeout(() => {
            context.params.context = context;
          }, 10);
        },
      },

      params: {
        context: this,
        idModeInsert: getConst("config").pageMode.insert.id,
      },
    };
  },
};
</script>