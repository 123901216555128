<!-- COD: FDS 05/07/2022 CFeedbackEdit -->
<!-- *VER: JER 19/07/2022 CFeedbackEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- titulo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.titulo"
          label="título"
          :max="50"
          required
        />
      </v-col>
    </v-row>

    <!-- pagina -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idPagina"
          clearable
          :items="paginas"
          label="página"
        />
      </v-col>
    </v-row>

    <!-- usuario -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.usuario_nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">
          {{ dataset.aux.detail.usuario_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.data">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">
          {{ dateFormat(dataset.main.detail.data, "date timeNoSecond") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFeedbackEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getPagina: "paginas/getPagina",
      getPaginas: "paginas/getPaginas",
    }),

    paginas() {
      return this.getPaginas()
        .filter((pagina) => this.getPagina(pagina.value).hasFeedback)
        .sortKeys("text");
    },
  },
};
</script>