<!-- COD: BJB 30/06/2022 CHelp -->
<!-- *VER: JER 01/07/2022 CHelp -->
<!-- /11/ -->

<template>
  <v-badge
    bottom
    color="red--text"
    content="?"
    :style="estilo"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :value="!!help && value"
  >
    <div
      v-touch="vTouch"
      class="ma-0 pa-0"
      :style="estilo"
      @click="clickElement"
      @mousedown="startTouchPressed"
      @mouseup="endTouchPressed"
    >
      <slot></slot>
    </div>
  </v-badge>
</template>

<script>
export default {
  /// NAME
  name: "CHelp",

  /// PROPS
  props: {
    estilo: { type: String, default: "width: 100%" },
    help: { type: Object },
    offsetX: { type: String, default: "7" },
    offsetY: { type: String, default: "16" },
    value: { type: Boolean, default: true },
  },
};
</script>