<!-- COD: BJB 19/08/2022 Ajuda -->
<!-- *VER: JER 19/08/2022 Ajuda -->
<!-- /11/ -->

<template>
  <v-container>
    <v-layout justify-center row>
      <v-card>
        <!-- /// NAVBAR -->
        <c-the-navbar mode="page" title="Ajuda">
          <template #icon>
            <c-btn icon router to="/">
              <v-icon class="ml-2" large>
                {{ icon }}
              </v-icon>
            </c-btn>
          </template>
        </c-the-navbar>

        <!-- /// AJUDA -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="end">
            <c-btn
              v-if="panelsOpen.length"
              class="mr-n1"
              icon
              @click="clickPanelsDouble"
            >
              <v-icon>
                {{ chevronDoubleIcon }}
              </v-icon>
            </c-btn>
            <c-btn icon classe="mr-n2" @click="clickPanels">
              <v-icon>
                {{ iconPanelCollapse }}
              </v-icon>
            </c-btn>
          </v-col>
        </v-row>

        <v-expansion-panels
          v-model="panelsOpen"
          flat
          multiple
          ref="panelsDocumentos"
        >
          <!-- /// MODULOS -->
          <v-container
            v-bind="prop.container"
            v-for="documento in documentos(null)"
            class="mb-3"
            :key="documento.index"
          >
            <c-base-documento
              :context="context"
              :documento="documento"
              :hasAdminOptions="hasAdminOptions"
              :id="'open' in documento.options ? documento.id : null"
              :idPage="idPage"
              :pageModeViewPai="pageModeView"
              @clickEditView="clickEditView"
            />
          </v-container>
        </v-expansion-panels>

        <v-card-actions>
          <c-btn
            v-scroll="onScroll"
            v-show="showFab"
            bottom
            fab
            fixed
            right
            @click="toScroll"
          >
            <v-icon>
              {{ iconScroll }}
            </v-icon>
          </c-btn>
        </v-card-actions>

        <c-the-navbar
          bottom
          mode="page"
          :pageModeView="pageModeView"
          title=""
        />
      </v-card>
    </v-layout>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-container>
</template>

<script>
/// IMPORT
import mixinDocumento from "@/mixins/mixinDocumento";

export default {
  /// NAME
  name: "Ajuda",

  /// MIXINS
  mixins: [mixinDocumento],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("ajuda").id,
    };
  },
};
</script>