<!-- COD: BJB 21/07/2022 CTextMoney -->
<!-- *VER: JER 10/08/2022 CTextMoney -->
<!-- /11/ -->

<template>
  <c-help :help="help" :offsetX="offsetX" :offsetY="offsetY">
    <c-base-money
      v-bind="$attrs"
      v-model="model"
      :aceitaNegativo="aceitaNegativo"
      :classe="classe"
      :clearable="clearable"
      :label="cLabel"
      :max="max"
      :options="options"
      :rules="
        $attrs.disabled
          ? []
          : cRules ||
            commonRules.common(
              type,
              required,
              min,
              max,
              noAccept,
              clean,
              msg,
              null,
              null,
              panelCallback
            )
      "
      :type="type"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @clickClear="$emit('clickClear', $event)"
      @keypress="$emit('keypress', $event)"
      @keyup="$emit('keyup', $event)"
    />
  </c-help>
</template>

<script>
/// IMPORT
import CBaseMoney from "@/components/bases/CBaseMoney";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CTextMoney",

  ///COMPONENTS
  components: { CBaseMoney },

  /// MIXINS
  mixins: [mixinLib],

  /// MODEL
  model: { prop: "value", event: "input" },

  /// PROPS
  props: {
    aceitaNegativo: { type: Boolean, default: true },
    classe: { type: String, default: "text-body-1" },
    clean: { type: String, default: "text" },
    clearable: { type: Boolean, default: true },
    cRules: { type: Array },
    help: { type: Object },
    max: { type: Number, required: true },
    min: { type: Number },
    msg: { type: String },
    noAccept: { type: String },
    options: { type: Object },
    panel: { type: Object },
    required: { type: Boolean, default: false },
    type: { type: String, default: "tel" },
    value: { type: [String, Number] },
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue || null);
      },
    },

    cLabel() {
      const label = this.translate(this.$attrs.label || "");

      return `${label}${label && this.required ? " *" : ""}`;
    },

    offsetX() {
      return "8";
    },

    offsetY() {
      return "32";
    },

    panelCallback() {
      return this.panel ? this.panel.callbackRules : null;
    },
  },
};
</script>