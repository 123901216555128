<!-- COD: LYF 22/09/2022 Extrato_Conciliacoes -->
<!-- #VER: JER __/09/2022 Extrato_Conciliacoes -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
      :showPanels="false"
    >
      <template v-slot="{ context }">
        <c-extrato-view
          :context="context"
          :dataset="dataset"
          :isConcilicao="true"
        />
      </template>

      <template #bottom="{ context }">
        <!-- debitos -->
        <v-row v-bind="prop.row" class="mt-n5">
          <v-col v-bind="prop.col">
            <c-checkbox
              v-model="soDebito"
              label="débitos"
              @click="clickDebitoCredito(true)"
            />
          </v-col>
        </v-row>

        <!-- creditos -->
        <v-row v-bind="prop.row" class="mt-n5">
          <v-col v-bind="prop.col">
            <c-checkbox
              v-model="soCredito"
              label="créditos"
              @click="clickDebitoCredito(false)"
            />
          </v-col>
        </v-row>

        <!-- nao conciliados -->
        <v-row v-bind="prop.row" class="mt-n5">
          <v-col v-bind="prop.col">
            <c-checkbox
              v-model="naoConciliados"
              :help="{
                title: 'NÃO CONCILIADOS',
                text: `marcado: conciliados
desmarcado: não conciliados
traço: todos`,
                function: context.setUiBottomSheet,
              }"
              indeterminate
              :label="labelNaoConciliados"
            />
          </v-col>
        </v-row>

        <v-expansion-panels
          v-model="panelsOpen"
          class="px-0"
          multiple
          ref="panelsDetail"
        >
          <c-base-panel
            :badge="badgeLancamentos"
            :datasets="lancamentos"
            header="lancamentos"
          >
            <v-container
              v-for="(lancamentoData, i) in lancamentosDatas_distinct()"
              class="mt-3"
              :key="`lancamentoData${i}`"
            >
              <v-row v-bind="prop.row">
                <!-- data -->
                <v-col v-bind="prop.col" cols="11">
                  <span class="font-weight-medium ml-2 primary--text">
                    {{ dateFormat(lancamentoData.data, "dateWeekday") }}
                  </span>
                </v-col>
                <!-- quantidade -->
                <v-col v-bind="prop.col" cols="1">
                  <v-badge
                    :content="lancamentoData.quantidade"
                    class="mb-n10 ml-n5"
                    color="grey"
                  ></v-badge>
                </v-col>
              </v-row>

              <c-expansion-panels
                flat
                :params="params"
                :value="lancamentos_distinct_filter(lancamentoData.data)"
                :key="componentKey"
              >
                <template #header="{ dataset: lancamento }">
                  <v-row v-bind="prop.row" class="my-n5">
                    <!-- conciliar -->
                    <v-col v-bind="prop.col" cols="1">
                      <c-checkbox
                        v-model="lancamento.conciliar"
                        label=""
                        @click.stop="getValoresPagRec(context)"
                      />
                    </v-col>
                    <!-- historico/identificador -->
                    <v-col v-bind="prop.col" cols="5">
                      <span class="text-body-1">
                        {{
                          lancamento.historico
                            ? lancamento.historico
                            : lancamento.identificador
                        }}
                      </span>
                    </v-col>
                    <!-- valor -->
                    <v-col v-bind="prop.col" align="end" cols="6">
                      <span
                        :class="`${
                          lancamento.valor < 0 ? 'red--text' : ''
                        } text-body-1`"
                      >
                        {{ numberFormat(lancamento.valor, "currency") }}
                      </span>
                    </v-col>
                  </v-row>

                  <!-- valor pag rec -->
                  <v-row
                    v-bind="prop.row"
                    v-if="lancamento.idValorPagRec"
                    class="my-n5 ml-n3"
                  >
                    <v-col v-bind="prop.col">
                      <c-btn
                        class="text-body-1"
                        color="primary"
                        text
                        @click="clickValorPagRec(lancamento.idValorPagRec)"
                      >
                        {{ `VALOR #${lancamento.idValorPagRec}` }}
                      </c-btn>
                    </v-col>
                  </v-row>
                </template>
                <template #content="{ dataset: lancamento }">
                  <c-extrato-page-conciliacao-view
                    :dataset="dataset"
                    :isLancamento="true"
                    :objeto="lancamento"
                    :params="params"
                  />
                </template>
              </c-expansion-panels>
            </v-container>
          </c-base-panel>

          <c-base-panel
            v-if="
              dataset.aux.valoresPagRec &&
              lancamentos_distinct.filter(
                (lancamento) =>
                  lancamento.conciliar && !lancamento.idValorPagRec
              ).length == 1
            "
            :badge="badgeValoresPagRec"
            :datasets="dataset.aux.valoresPagRec"
            header="valores pag/rec"
          >
            <v-container
              v-for="(valorPagRecData, i) in valoresPagRecDatas_distinct"
              class="mt-3"
              :key="`valorPagRecData${i}`"
            >
              <v-row v-bind="prop.row">
                <!-- data emissao -->
                <v-col v-bind="prop.col" cols="11">
                  <span class="font-weight-medium ml-2 primary--text">
                    {{ dateFormat(valorPagRecData.dataEmissao, "dateWeekday") }}
                  </span>
                </v-col>
                <!-- quantidade -->
                <v-col v-bind="prop.col" cols="1">
                  <v-badge
                    :content="valorPagRecData.quantidade"
                    class="mb-n10 ml-n5"
                    color="grey"
                  ></v-badge>
                </v-col>
              </v-row>

              <c-expansion-panels
                flat
                :params="params"
                :value="
                  valoresPagRec_distinct_filter(valorPagRecData.dataEmissao)
                "
              >
                <template #header="{ dataset: valorPagRec }">
                  <v-row v-bind="prop.row" class="my-n5">
                    <!-- conciliar -->
                    <v-col v-bind="prop.col" cols="1">
                      <c-checkbox
                        v-model="valorPagRec.conciliar"
                        label=""
                        @click.stop="
                          clickValorPagRecConciliar(context, valorPagRec)
                        "
                      />
                    </v-col>
                    <!-- historico/identificador -->
                    <v-col v-bind="prop.col" cols="5">
                      <span class="text-body-1">
                        {{
                          valorPagRec.historico
                            ? valorPagRec.historico
                            : valorPagRec.identificador
                        }}
                      </span>
                    </v-col>
                    <!-- valor -->
                    <v-col v-bind="prop.col" align="end" cols="6">
                      <span
                        :class="`${
                          valorPagRec.valor < 0 ? 'red--text' : ''
                        } text-body-1`"
                      >
                        {{ numberFormat(valorPagRec.valor, "currency") }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template #content="{ dataset: valorPagRec }">
                  <c-extrato-page-conciliacao-view
                    :dataset="dataset"
                    :isLancamento="false"
                    :objeto="valorPagRec"
                    :params="params"
                  />
                </template>
              </c-expansion-panels>
            </v-container>
          </c-base-panel>
        </v-expansion-panels>

        <v-row v-bind="prop.row" class="mt-6">
          <!-- placar lancamentos -->
          <v-col v-bind="prop.col" cols="6">
            <!-- total selecionado -->
            <c-text-money
              class="text-h5 font-weight-light"
              :clearable="false"
              label="lançamentos selecionados"
              :max="15"
              :options="{
                length: 11,
                precision: 2,
                style: 'currency',
              }"
              outlined
              readonly
              :value="getTotaisLancamentos().valor"
            />
            <!-- quantidade selecionada -->
            <v-badge
              v-if="getTotaisLancamentos().quantidade > 0"
              class="mb-n3 ml-n2"
              color="grey"
              :content="getTotaisLancamentos().quantidade"
              overlap
            />
          </v-col>
          <!-- placar valorPagRec -->
          <v-col
            v-bind="prop.col"
            v-if="
              dataset.aux.valoresPagRec &&
              lancamentos_distinct.filter(
                (lancamento) =>
                  lancamento.conciliar && !lancamento.idValorPagRec
              ).length == 1
            "
            cols="6"
          >
            <!-- total selecionado -->
            <c-text-money
              class="text-h5 font-weight-light"
              :clearable="false"
              label="valores pag/rec selecionados"
              :max="15"
              :options="{
                length: 11,
                precision: 2,
                style: 'currency',
              }"
              outlined
              readonly
              :value="getTotaisValoresPagRec().valor"
            />
            <!-- quantidade selecionada -->
            <v-badge
              v-if="getTotaisValoresPagRec().quantidade > 0"
              class="mb-n3 ml-n2"
              color="grey"
              :content="getTotaisValoresPagRec().quantidade"
              overlap
            />
          </v-col>
        </v-row>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { differenceInDays, parseISO } from "date-fns";
import CBasePanel from "@/components/bases/CBasePanel";
import CExtratoPageConciliacaoView from "@/components/pages/extrato/page/CExtratoPageConciliacaoView";
import CExtratoView from "@/components/pages/extrato/CExtratoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Extrato_Conciliacoes",

  /// COMPONENTS
  components: {
    CBasePanel,
    CExtratoPageConciliacaoView,
    CExtratoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idFeature: getFeature("optionConciliar").id,
      idPage: getPagina("extrato").id,
      idPageAlt: getPagina("extratos").id,

      componentKey: 0,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("extratoBanco").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idPessoaParceiroContaBancaria: null,
            dataEmissao: new Date(),
            dataInicio: new Date(),
            dataFim: new Date(),
            debitos: null,
            totalDebitos: null,
            creditos: null,
            totalCreditos: null,
            saldo: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          lancamentos: [],
        },
        aux: {
          detail: {
            pessoaParceiro_nomeAlt: null,
            idBanco: null,
            banco: null,
            agencia: null,
            agenciaDV: null,
            conta: null,
            contaDV: null,
          },
          contaBancaria: {
            banco: null,
            agencia: null,
            conta: null,
          },
          lancamentos: [],
        },
      },

      funcsDetail: {
        buttonsBar: (context) => {
          let valorPagRec = [];
          const ret = [];
          const lancamento = this.lancamentos_distinct.filter(
            (lancamento) => lancamento.conciliar
          );

          if (this.dataset.aux.valoresPagRec) {
            valorPagRec = this.valoresPagRec_distinct.filter(
              (valorPagRec) => valorPagRec.conciliar
            );
          }

          if (!context.pageModeView) {
            ret.push({
              clickFunction: this.clickCancel,
              options: {
                icon: "mdi-close",
                color: "red accent-3",
                context,
              },
            });

            if (valorPagRec.length == 1) {
              ret.push({
                clickFunction: this.clickConciliacao,
                options: {
                  icon: "mdi-link-variant-plus",
                  associar: true,
                  context,
                },
              });
            } else if (lancamento.length >= 1) {
              if (lancamento.some((lanc) => lanc.idValorPagRec)) {
                ret.push({
                  clickFunction: this.clickConciliacao,
                  options: {
                    icon: "mdi-link-variant-minus",
                    associar: false,
                    context,
                  },
                });
              } else {
                ret.push({
                  clickFunction: this.clickConciliacao,
                  options: {
                    icon: "mdi-plus",
                    associar: null,
                    context,
                  },
                });
              }
            }
          }

          return ret;
        },

        clickSnackbar: () => {},

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          let params = null;
          params = {
            idExtrato: this.id,
            isConciliacao: true,
            soDebito: this.soDebito,
            soCredito: this.soCredito,
          };

          return JSON.stringify(params);
        },

        posLoad: async () => {
          const method = "put";
          const params = JSON.stringify({
            idExtrato: this.id,
          });
          const pageNome = "extratoConciliacaoAutomatica";
          let url = `${
            this.getConst("app").baseUrlBackend
          }/${pageNome}/${params}`;

          await this.params.context.submit(method, url, null);

          //? verificar se vai manter esse comportamento
          // const res = await this.params.context.submit(method, url, null);

          // if (res) {
          //   if (res.data == 0) {
          //     this.params.context.funcs.clickVoltar();
          //   }
          // }

          this.params.context.clickSearch();
        },

        posSubmit: (context) => {
          this.contextDetail = context;
          context.params.context = context;

          this.dataset = context.dataset;
          this.id = context.dataset.main.detail.id;

          if (this.contextList && this.loadList) {
            this.contextList.clickSearch();
          }

          this.loadList = true;
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },

      loadList: false,
      naoConciliados: false,

      params: {
        context: null,
        specificButtonsBar: [],
      },

      panelsOpen: [],

      soCredito: true,
      soDebito: true,
    };
  },

  /// COMPUTED
  computed: {
    badgeLancamentos() {
      return this.lancamentos.length;
    },

    badgeValoresPagRec() {
      return this.dataset.aux.valoresPagRec.length;
    },

    labelNaoConciliados() {
      return this.naoConciliados
        ? "conciliados"
        : this.naoConciliados === false
        ? "não conciliados"
        : "todos";
    },

    lancamentos() {
      return this.dataset.main.lancamentos.filter(
        (lancamento) =>
          (this.soDebito && this.soCredito
            ? true
            : this.soDebito
            ? lancamento.valor < 0
            : this.soCredito
            ? lancamento.valor >= 0
            : true) &&
          (this.naoConciliados
            ? lancamento.idValorPagRec > 0
            : this.naoConciliados === false
            ? lancamento.idValorPagRec === null
            : true)
      );
    },

    lancamentos_distinct() {
      const lancamentos = [
        ...new Set(
          this.lancamentos.map((lancamento) => {
            const tempLancamento = {
              data: lancamento.data,
              idLancamento: lancamento.id,
            };

            return JSON.stringify(tempLancamento);
          })
        ),
      ].map((tempLancamento) => {
        const lancamento = JSON.parse(tempLancamento);
        const auxLancamentos = this.lancamentos.filter(
          (auxLancamento) =>
            auxLancamento.data == lancamento.data &&
            auxLancamento.id == lancamento.idLancamento
        );

        lancamento.conciliar = false;
        lancamento.idValorPagRec = auxLancamentos[0]?.idValorPagRec;
        lancamento.historico = auxLancamentos[0]?.historico;
        lancamento.identificador = auxLancamentos[0]?.identificador;
        lancamento.saldo = auxLancamentos[0]?.saldo;
        lancamento.quantidade = auxLancamentos.length;
        lancamento.valor = auxLancamentos.reduce(
          (acc, auxLancamento) => (acc += parseFloat(auxLancamento.valor)),
          0
        );

        return lancamento;
      });

      return lancamentos;
    },

    valoresPagRec_distinct() {
      const lancamento = this.lancamentos_distinct.find(
        (lancamento) => lancamento.conciliar
      );

      return [
        ...new Set(
          this.dataset.aux.valoresPagRec.map((valorPagRec) => {
            const tempValorPagRec = {
              dataEmissao: valorPagRec.dataEmissao,
              idValorPagRec: valorPagRec.id,
            };

            return JSON.stringify(tempValorPagRec);
          })
        ),
      ]
        .map((tempValorPagRec) => {
          const valorPagRec = JSON.parse(tempValorPagRec);
          const auxValoresPagRec = this.dataset.aux.valoresPagRec.filter(
            (auxValorPagRec) =>
              auxValorPagRec.dataEmissao == valorPagRec.dataEmissao &&
              auxValorPagRec.id == valorPagRec.idValorPagRec
          );

          valorPagRec.conciliar = false;
          valorPagRec.idExtrato = auxValoresPagRec[0]?.idExtrato;
          valorPagRec.historico = auxValoresPagRec[0]?.historico;
          valorPagRec.identificador = auxValoresPagRec[0]?.identificador;
          valorPagRec.quantidade = auxValoresPagRec.length;
          valorPagRec.valor = auxValoresPagRec.reduce(
            (acc, auxValorPagRec) => (acc += parseFloat(auxValorPagRec.valor)),
            0
          );

          return valorPagRec;
        })
        .sort((a, b) => {
          if (lancamento) {
            return (
              Math.abs(parseFloat(a.valor) - parseFloat(lancamento.valor)) -
              Math.abs(parseFloat(b.valor) - parseFloat(lancamento.valor))
            );
          } else {
            return 0;
          }
        });
    },

    valoresPagRecDatas_distinct() {
      const lancamento = this.lancamentos_distinct.find(
        (lancamento) => lancamento.conciliar
      );

      return [
        ...new Set(
          this.valoresPagRec_distinct.map(
            (valorPagRec) => valorPagRec.dataEmissao
          )
        ),
      ]
        .map((dataEmissao) => {
          const valoresPagRec_distinct = this.valoresPagRec_distinct.filter(
            (valorPagRec) => valorPagRec.dataEmissao == dataEmissao
          );

          return {
            dataEmissao,
            quantidade: valoresPagRec_distinct.length,
            valor: valoresPagRec_distinct.reduce(
              (acc, valorPagRec) => (acc += valorPagRec.valor),
              0
            ),
          };
        })
        .sort(
          (a, b) =>
            Math.abs(
              differenceInDays(
                parseISO(a.dataEmissao),
                parseISO(lancamento.data)
              )
            ) -
            Math.abs(
              differenceInDays(
                parseISO(b.dataEmissao),
                parseISO(lancamento.data)
              )
            )
        );
    },
  },

  /// METHODS
  methods: {
    clickCancel(button) {
      this.dataset.aux.valoresPagRec.splice(
        0,
        this.dataset.aux.valoresPagRec.length
      );

      this.lancamentos_distinct.forEach((lancamento_distinct) => {
        lancamento_distinct.conciliar = false;
      });

      button.options.context.clickEditView();
    },

    async clickConciliacao(button) {
      let valoresPagRec = [];
      const associar = button.options.associar;

      const lancamentos = this.lancamentos_distinct.filter(
        (lancamento) => lancamento.conciliar
      );

      if (this.dataset.aux.valoresPagRec) {
        valoresPagRec = this.valoresPagRec_distinct.filter(
          (valorPagRec) => valorPagRec.conciliar
        );
      }

      if (valoresPagRec.length) {
        if (lancamentos[0].valor != valoresPagRec[0].valor) {
          this.params.context.setSnackbar(
            true,
            "ERRO",
            "Valor do lançamento e do VL tem que ser igual",
            "error"
          );

          return;
        }
      }

      const method = "put";
      const params = JSON.stringify({
        idExtrato: this.id,
        associar: associar,
        idColaborador: this.getVar("idUsuario"),
      });
      const pageNome = "extratoConciliacoes";
      const datasets = {
        lancamentos: lancamentos,
        valoresPagRec: valoresPagRec,
      };

      let url = `${this.getConst("app").baseUrlBackend}/${pageNome}/${params}`;
      await this.params.context.submit(method, url, datasets);
      //? verificar se vai manter esse comportamento
      // const res = await this.params.context.submit(method, url, datasets);

      // if (res) {
      //   if (res.data == 0) {
      //     this.params.context.funcs.clickVoltar();
      //   }
      // }

      this.params.context.clickSearch();
      button.options.context.clickEditView();
    },

    clickDebitoCredito(clickDebito = null) {
      if (clickDebito) {
        this.soCredito = true;
      } else if (clickDebito === false) {
        this.soDebito = true;
      }
    },

    clickValorPagRec(idValorPagRec) {
      this.$router.push(`/valorPagRec/${idValorPagRec}`);
    },

    clickValorPagRecConciliar(context, valorPagRec) {
      this.valoresPagRec_distinct.forEach((valorPagRec_distinct) => {
        if (valorPagRec_distinct.idValorPagRec != valorPagRec.idValorPagRec) {
          valorPagRec_distinct.conciliar = false;
        }
      });

      this.reloadButtonsBar(context);
    },

    forceRerender() {
      this.componentKey += 1;
    },

    getTotaisLancamentos() {
      const quantidade = this.lancamentos_distinct.filter(
        (valorPagRec) => valorPagRec.conciliar
      ).length;

      const valor = this.lancamentos_distinct
        .filter((valorPagRec) => valorPagRec.conciliar)
        .reduce((acc, item) => {
          return (acc += parseFloat(item.valor));
        }, 0);

      const lancamentos = {
        quantidade,
        valor,
      };

      return lancamentos;
    },

    getTotaisValoresPagRec() {
      const quantidade = this.valoresPagRec_distinct.filter(
        (valorPagRec) => valorPagRec.conciliar
      ).length;

      const valor = this.valoresPagRec_distinct
        .filter((valorPagRec) => valorPagRec.conciliar)
        .reduce((acc, item) => {
          return (acc += parseFloat(item.valor));
        }, 0);

      const valoresPagRec = {
        quantidade,
        valor,
      };

      return valoresPagRec;
    },

    async getValoresPagRec(context) {
      this.forceRerender();

      const lancamentos = this.lancamentos_distinct.filter(
        (lancamento) => lancamento.conciliar
      );

      if (
        (lancamentos.length > 0 && context.pageModeView) ||
        (lancamentos.length == 0 && !context.pageModeView)
      ) {
        context.clickEditView();
      }

      if (this.dataset.aux.valoresPagRec) {
        this.dataset.aux.valoresPagRec.splice(
          0,
          this.dataset.aux.valoresPagRec.length
        );
      }

      if (
        lancamentos.length == 1 &&
        lancamentos.some((lanc) => !lanc.idValorPagRec)
      ) {
        const method = "get";
        const params = JSON.stringify({
          idExtrato: this.id,
          soDebito: this.soDebito,
          soCredito: this.soCredito,
          lancamento: lancamentos[0],
        });
        const pageNome = "extratoConciliacoes";
        let url = `${
          this.getConst("app").baseUrlBackend
        }/${pageNome}/${params}`;

        const res = await this.params.context.submit(method, url, null, false);

        if (res?.data?.length) {
          this.dataset.aux.valoresPagRec.push(...res.data);
        }
      }

      this.reloadButtonsBar(context);
    },

    lancamentos_distinct_filter(data) {
      const lancamentoConciliar = this.lancamentos_distinct.find(
        (lancamento) => lancamento.conciliar
      );

      return this.lancamentos_distinct
        .filter((lancamento) => lancamento.data == data)
        .filter((lancamento) => {
          if (lancamentoConciliar) {
            return lancamentoConciliar.idValorPagRec > 0
              ? lancamento.idValorPagRec > 0
              : lancamentoConciliar.idValorPagRec === null
              ? lancamento.idValorPagRec === null
              : true;
          } else {
            return true;
          }
        });
    },

    lancamentosDatas_distinct() {
      const lancamentoConciliar = this.lancamentos_distinct.find(
        (lancamento) => lancamento.conciliar
      );

      return [
        ...new Set(
          this.lancamentos_distinct
            .filter((lancamento) => {
              if (lancamentoConciliar) {
                return lancamentoConciliar.idValorPagRec > 0
                  ? lancamento.idValorPagRec > 0
                  : lancamentoConciliar.idValorPagRec === null
                  ? lancamento.idValorPagRec === null
                  : true;
              } else {
                return true;
              }
            })
            .map((lancamento) => lancamento.data)
        ),
      ].map((data) => {
        const lancamentos_distinct = this.lancamentos_distinct.filter(
          (lancamento) => lancamento.data == data
        );

        return {
          data,
          quantidade: lancamentos_distinct.length,
          valor: lancamentos_distinct.reduce(
            (acc, lancamento) => (acc += lancamento.valor),
            0
          ),
        };
      });
    },

    reloadButtonsBar(context) {
      context.defaultParams.reloadButtonsBar = true;
      setTimeout(() => (context.defaultParams.reloadButtonsBar = false), 10);
    },

    valoresPagRec_distinct_filter(data) {
      return this.valoresPagRec_distinct.filter(
        (valorPagRec) => valorPagRec.dataEmissao == data
      );
    },
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>