<!-- COD: BJB 20/07/2022 CFileInput -->
<!-- *VER: JER 10/08/2022 CFileInput -->
<!-- /11/ -->

<template>
  <v-file-input
    v-bind="$attrs"
    v-model="model"
    :autofocus="autofocus"
    :class="classe"
    :filled="filled"
    :label="label"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :show-size="showSize"
  />
</template>

<script>
export default {
  /// NAME
  name: "CFileInput",

  /// PROPS
  props: {
    autofocus: { type: Boolean, default: true },
    classe: { type: String, default: "text-body-1" },
    filled: { type: Boolean, default: true },
    label: { type: String, default: "arquivo" },
    placeholder: {
      type: String,
      default: "clique aqui para encontrar o arquivo",
    },
    prependIcon: { type: String, default: "mdi-paperclip" },
    showSize: { type: Boolean, default: true },
    type: { type: String, default: "text" },
    value: { type: [File, Blob] },
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>