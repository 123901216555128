var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{attrs:{"id":("form" + _vm.label)},on:{"submit":function($event){return _vm.submitForm($event)}}}),_c('c-help',{attrs:{"help":_vm.help,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY}},[_c('v-autocomplete',_vm._b({ref:("autocomplete" + _vm.label),staticClass:"text-body-1 mt-3",attrs:{"chips":"","clearable":_vm.clearable,"color":"blue-grey lighten-2","filled":"","filter":function () { return true; },"form":_vm.form,"items":_vm.items,"label":_vm.translate(_vm.cLabel),"loading":_vm.isLoading,"multiple":_vm.multiple,"no-data-text":"nenhuma opção encontrada","placeholder":"digite para buscar...","return-object":_vm.returnObject,"rules":_vm.rules.length
          ? _vm.rules
          : _vm.commonRules.common(
              'select',
              _vm.required,
              null,
              1,
              null,
              null,
              _vm.msg,
              null,
              null,
              _vm.panelCallback
            ),"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.$emit('change', $event)},"focus":function($event){return _vm.focus($event)},"keydown":function($event){return _vm.changeForm(("form" + _vm.label), $event)},"keyup":function($event){return _vm.changeForm("formDetail", $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(_vm.lengthLimit)?_c('v-chip',_vm._b({ref:("chip" + _vm.label),staticClass:"mt-3 ml-n1 mr-2",class:_vm.chipClass,attrs:{"close":_vm.chipClose,"color":data.item.cor,"dark":"","input-value":data.selected,"small":""},on:{"click":function($event){return _vm.clickChip(data)},"click:close":function($event){return _vm.remove(data)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.textAlt || data.item.text)+" ")]):_c('div',{staticClass:"my-2"},[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(data.item.textAlt || data.item.text)+" ")]),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.remove(data)}}},[_vm._v("mdi-close")])],1)]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('span',{staticClass:"text-body-2",on:{"click":function($event){return _vm.clickSpan(data)}}},[_vm._v(" "+_vm._s(data.item.text)+" ")])])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-autocomplete',_vm.$attrs,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }