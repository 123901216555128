// COD: BJB 15/08/2022 tabelas
// *VER: JER 19/08/2022 tabelas
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    tabelas: [],
    tabelas_colunas: [],
    tabelas_queries: [],
  },

  /// GETTERS
  getters: {
    getTabelas: state => (prop = "id") => {
      return state.tabelas
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nome,
            idMaquinaEstado: element.idMaquinaEstado
          };
        })
    },

    getTabela: state => search => {
      return state.tabelas.length
        ? state.tabelas
          .find(element => element.id == search || element.nome == search)
        : { search }
    },

    getTabela_colunas: state => (id, prop = "id") => {
      return state.tabelas_colunas
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nome
          };
        });
    },


    getTabela_coluna: state => id => {
      return state.tabelas_colunas.length
        ? state.tabelas_colunas
          .find(element => element.id == id)
        : { id }
    },
  },

  /// MUTATIONS
  mutations: {
    setTabelas: (state, payload) => {
      state.tabelas = []
      payload.forEach(element => {
        state.tabelas.push(element)
      });
    },

    setTabelas_colunas: (state, payload) => {
      state.tabelas_colunas = []
      payload.forEach(element => {
        state.tabelas_colunas.push(element)
      });
    },
  },

  /// ACTIONS
  actions: {
    setTabelas: (context, payload) => {
      context.commit("setTabelas", payload)
    },

    setTabelas_colunas: (context, payload) => {
      context.commit("setTabelas_colunas", payload)
    },
  }
}