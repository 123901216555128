<!-- COD: LYF 01/07/2022 Publicacoes -->
<!-- *VER: JER 04/07/2022 Publicacoes -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ context, dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-publicacoes :context="context" :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CPublicacoes from "@/components/pages/publicacao/CPublicacoes";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Publicacoes",

  /// COMPONENTS
  components: {
    CPublicacoes,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("publicacoes").id,
      idPageAlt: getPagina("publicacao").id,

      funcs: {
        fnParams: () => {
          return JSON.stringify(this.params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      params: {},
    };
  },
};
</script>