<!-- COD: LYF 10/08/2022 Avaliacoes -->
<!-- *VER: JER 10/08/2022 Avaliacoes -->
<!-- /12/ -->

<template>
  <div
    v-touch="{
      down: ($event) => swipe('down', swipeRefresh, $event),
      up: ($event) => swipe('up', swipeInject, $event),
    }"
    class="avaliacoes"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" title="Avaliações" />

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <c-avaliacao-page-avaliacoes
      v-if="avaliacoes.length"
      :avaliado="avaliado"
      :dataset="avaliacoes"
      :limit="limit"
    />

    <!-- /// NAVBAR -->
    <c-the-navbar bottom mode="page" title="" />
  </div>
</template>

<script>
/// IMPORT
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import CAvaliacaoPageAvaliacoes from "@/components/pages/avaliacao/page/CAvaliacaoPageAvaliacoes";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";

export default {
  /// NAME
  name: "Avaliacoes",

  /// COMPONENTS
  components: { CAvaliacaoPageAvaliacoes, CTheNavbar },

  // MIXINS
  mixins: [mixinData, mixinSnackbar, mixinSwipe],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("avaliacoes").id,

      avaliacoes: [],
      avaliado: {},
      limit: 20,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    async avaliacoesFilter(offset = 0) {
      this.setVar({ overlay: true });

      const search = "*";
      const idContexto = "*";
      const idFilter = null;
      const idOrder = null;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;

      const params = {
        searchIdPessoaParceiro: this.$route.params.idAvaliado,
      };

      const url = `${
        this.getConst("app").baseUrlBackend
      }/avaliacoes/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
        this.limit
      }/${JSON.stringify(params)}`;

      await axios
        .get(url)
        .then((res) => {
          const result = res.data;
          this.avaliado = JSON.parse(JSON.stringify(result[0]));

          this.avaliacoes = [];
          result.forEach((element) => {
            this.avaliacoes.push(element);
          });
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
          this.btnInjectClicked = false;
        });
    },

    swipeInject() {
      this.btnInjectClicked = true;
      this.avaliacoesFilter(this.avaliacoes.length);
    },

    swipeRefresh() {
      this.avaliacoesFilter();
    },
  },

  /// MOUNTED
  mounted() {
    this.avaliacoesFilter(this.avaliacoes.length);
  },
};
</script>