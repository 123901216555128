var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-6 pa-0"},[(_vm.hasSearch)?_c('v-row',_vm._b({staticClass:"mt-n2"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"append-outer-icon":_vm.showBuscar ? 'mdi-tune' : null,"clearable":"","max":100,"noAccept":null,"placeholder":"busca","prepend-icon":_vm.iconLayout,"prepend-inner-icon":"mdi-magnify","solo":"","type":"search"},on:{"click-append":function($event){_vm.showSearchOptions = !_vm.showSearchOptions},"click-prepend":_vm.clickLayout,"input":function($event){return _vm.inputSearch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.$emit('search', _vm.search);
          $event.target.blur();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.showSearchOptions),expression:"!showSearchOptions"}],staticClass:"mt-n3"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-icon',{class:_vm.iconFavoritoClass,on:{"click":_vm.clickFavoritos}},[_vm._v(" "+_vm._s(_vm.iconFavorito)+" ")])],1)],1),_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],staticClass:"mt-n6"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[(_vm.filters.length)?_c('v-select',{attrs:{"items":_vm.filters,"prepend-inner-icon":_vm.filter && _vm.filter.options ? _vm.filter.options.icon : null,"solo":""},on:{"change":function($event){return _vm.$emit('filter', _vm.idFilter)}},scopedSlots:_vm._u([(_vm.iconFavorito)?{key:"prepend",fn:function(){return [_c('v-icon',{class:_vm.iconFavoritoClass,on:{"click":_vm.clickFavoritos}},[_vm._v(_vm._s(_vm.iconFavorito))])]},proxy:true}:null],null,true),model:{value:(_vm.idFilter),callback:function ($$v) {_vm.idFilter=$$v},expression:"idFilter"}}):_vm._e()],1)],1),_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],staticClass:"mt-n6"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[(_vm.orders.length)?_c('c-select',{attrs:{"items":_vm.orders,"prepend-inner-icon":this.ordem
            ? this.ordem == 'desc'
              ? 'mdi-arrow-down'
              : 'mdi-arrow-up'
            : _vm.order && _vm.order.options
            ? _vm.order.options.icon
            : null,"solo":""},on:{"change":function($event){return _vm.$emit('order', _vm.idOrder)},"clickPrependInner":function($event){return _vm.changeOrderList()}},model:{value:(_vm.idOrder),callback:function ($$v) {_vm.idOrder=$$v},expression:"idOrder"}}):_vm._e()],1)],1),(_vm.proprietarios)?_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],class:_vm.idFilter ? 'mt-n6' : 'mt-n4'},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"items":_vm.proprietarios,"label":"proprietarios","multiple":"","prepend-inner-icon":"mdi-account-multiple-outline","properties":{
          todos: true,
        },"solo":""},on:{"change":function($event){return _vm.$emit('proprietarios', _vm.idsProprietarios)}},model:{value:(_vm.idsProprietarios),callback:function ($$v) {_vm.idsProprietarios=$$v},expression:"idsProprietarios"}})],1)],1):_vm._e(),(_vm.hasData)?_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],class:_vm.orders.length || _vm.idFilter || _vm.proprietarios ? 'mt-n6' : ''},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1 pr-1","clearable":"","date":"","icon":"","label":"início","maxValue":_vm.searchData.fim,"solo":""},on:{"change":function($event){return _vm.$emit('searchData', _vm.searchData)},"input":function($event){return _vm.$emit('searchData', _vm.searchData)}},model:{value:(_vm.searchData.inicio),callback:function ($$v) {_vm.$set(_vm.searchData, "inicio", $$v)},expression:"searchData.inicio"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1 pl-1","clearable":"","date":"","icon":"","label":"fim","minValue":_vm.searchData.inicio,"solo":""},on:{"change":function($event){return _vm.$emit('searchData', _vm.searchData)},"input":function($event){return _vm.$emit('searchData', _vm.searchData)}},model:{value:(_vm.searchData.fim),callback:function ($$v) {_vm.$set(_vm.searchData, "fim", $$v)},expression:"searchData.fim"}})],1)],1):_vm._e(),_vm._t("searchCustomOptions"),_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],class:_vm.orders.length || _vm.idFilter || _vm.proprietarios || _vm.hasData
        ? 'mt-n6'
        : 'mt-n6'},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[(_vm.getVar('idUsuario') <= 20 && _vm.hasPaginacao)?_c('c-select',{staticClass:"pr-1",attrs:{"items":_vm.itensPorPagina,"label":"paginação","prepend-inner-icon":"mdi-format-align-justify","solo":""},on:{"change":function($event){return _vm.$emit('paginacao', _vm.limit)}},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}):_vm._e()],1),_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[(_vm.showBuscar)?_c('c-btn',{attrs:{"block":"","classe":("primary white--text mr-8 " + (_vm.getVar('idUsuario') <= 5 && _vm.hasPaginacao ? 'mt-n6' : 'mt-n2 mb-4')),"large":""},on:{"click":function($event){return _vm.$emit('buscar')}}},[_vm._v(" buscar ")]):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }