<!-- COD: LYF 27/07/2022 CContaPagRecPageBaixaCruzadaEdit -->
<!-- *VER: JER 05/08/2022 CContaPagRecPageBaixaCruzadaEdit -->
<!-- /22/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- formaPagamento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(contaPagRec.idFormaPagamento).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- spacer -->
      <v-col v-bind="prop.col" cols="4">
        <v-spacer />
      </v-col>
      <!-- conta associada -->
      <v-col v-bind="prop.col" align="center" cols="2">
        <c-checkbox
          v-model="context.datasets[index].contaAssociada"
          :disabled="disabled"
          @click="calcularSaldo(context, index)"
        />
      </v-col>
      <!-- valor baixar -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="context.datasets[index].valorBaixar"
          :disabled="disabled"
          hint="o saldo menor"
          label="valor a baixar"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
          @clickClear="alterarSaldoBaixar(context, index, $event)"
          @keyup="alterarSaldoBaixar(context, index, $event)"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data vencimento -->
      <v-col v-bind="prop.col" cols="5">
        <span class="text-body-2">venc</span>
        <span class="ml-1 text-body-1">
          {{ dateFormat(contaPagRec.dataVencimento, "date") }}
        </span>
      </v-col>
      <!-- saldo -->
      <v-col v-bind="prop.col" align="end" cols="7">
        <span class="text-body-1">saldo</span>
        <span class="ml-1 text-body-1">
          {{ numberFormat(contaPagRec.saldo, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- pedido -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-2">pedido</span>
        <span class="ml-1 text-body-1">
          {{ contaPagRec.idPedido }}
        </span>
      </v-col>
      <!-- duplicata  -->
      <v-col
        v-bind="prop.col"
        v-if="contaPagRec.duplicata"
        align="end"
        cols="6"
      >
        <span class="text-body-2">duplicata</span>
        <span class="ml-1 text-body-1">
          {{ contaPagRec.duplicata.mask("##?#?#?#?#?#?#?#?#?#?#?#?#?#?/A") }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ contaPagRec.pessoa_nome }}
        </span>
      </v-col>
    </v-row>

    <!-- identification -->
    <c-base-item-identification
      v-if="contaPagRec.estado"
      :dataset="contaPagRec"
      :idPage="idPage"
    />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseItemIdentification from "@/components/bases/CBaseItemIdentification";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CContaPagRecPageBaixaCruzadaEdit",

  /// COMPONENTS
  components: { CBaseItemIdentification },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    contaPagRec: { type: Object, required: true },
    idPage: {},
    index: {},
    saldoBaixar: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    disabled() {
      return this.context.datasets[this.index].disabled;
    },

    getSaldo() {
      return (
        this.saldoBaixar -
        this.context.datasets
          .filter((dataset) => dataset.contaAssociada)
          .reduce((acc, item) => (acc += parseFloat(item.valorBaixar)), 0)
      );
    },
  },

  /// METHODS
  methods: {
    alterarSaldoBaixar(context, index, event) {
      if (event.type == "click") {
        context.datasets[index].valorBaixar = null;
      }

      context.datasets[index].contaAssociada = true;
      context.datasets[index].contaAssociada =
        context.datasets[index].valorBaixar != null;

      this.calcularSaldo(context, index);
    },

    calcularSaldo(context, index) {
      const saldoMinimo = this.getSaldoMinimo(context, index);

      context.datasets[index].valorBaixar = !context.datasets[index]
        .contaAssociada
        ? null
        : parseFloat(context.datasets[index].valorBaixar) <=
          parseFloat(saldoMinimo)
        ? context.datasets[index].valorBaixar
        : saldoMinimo.toString();

      context.datasets[index].valorAssociar =
        context.datasets[index].valorAssociar == 0
          ? null
          : context.datasets[index].valorAssociar;

      context.datasets[index].contaAssociada =
        context.datasets[index].valorBaixar > 0;

      context.datasets
        .filter((dataset) => !dataset.contaAssociada)
        .map((item) => {
          item.disabled = this.getSaldo <= 0;
        });
    },

    getSaldoMinimo(context, index) {
      const valorMaximoAssociar =
        parseFloat(this.saldoBaixar) -
        context.datasets
          .filter(
            (dataset) =>
              dataset.contaAssociada == true &&
              context.datasets[index].id != dataset.id
          )
          .reduce((acc, item) => {
            return (acc += parseFloat(item.valorBaixar));
          }, 0);

      const saldoMinimo = Math.min(
        valorMaximoAssociar,
        context.datasets[index].saldo
      );

      return saldoMinimo;
    },
  },
};
</script>