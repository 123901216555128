<!-- COD: FDS 28/07/2022 CColaboradorPageApontamentoView -->
<!-- *VER: JER 07/08/2022 CColaboradorPageApontamentoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- situacao trabalho -->
    <v-row v-bind="prop.row" v-if="itemSituacaoTrabalho">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ itemSituacaoTrabalho }}
        </span>
      </v-col>
    </v-row>

    <!-- justificativa -->
    <v-row v-bind="prop.row" v-if="itemJustificativa">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ itemJustificativa }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CColaboradorPageApontamentoView",

  /// PROPS
  props: {
    item: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
      getVerba: "verbas/getVerba",
    }),

    itemJustificativa() {
      return this.item.tipo != "tipoOcorrencia"
        ? this.item.justificativa || this.item.observacao || ""
        : "";
    },

    itemSituacaoTrabalho() {
      return this.item.tipo == "tipoOcorrencia"
        ? this.getVerba(this.item.idVerba).options.nomeAlt || ""
        : this.getTipo_item(this.item.idSituacaoTrabalho)?.nomeAlt || "";
    },
  },
};
</script>