<!-- COD: FDS 27/03/2023 CBaseDialogExtrato -->
<!-- #VER: JER __/__/2023 CBaseDialogExtrato -->
<!-- /11/ -->

<template>
  <v-dialog v-model="show" max-width="580px" persistent scrollable>
    <v-card max-width="580">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>EXTRATO DE PREÇO</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mx-0 pa-2">
        <v-form ref="form">
          <v-container class="pa-0">
            <v-row v-bind="prop.row" class="mt-5">
              <v-col v-bind="prop.col" align="center">
                <span class="font-weight-medium py-1 text-body-1">
                  {{ produtoFormat(produto) }}
                </span>
              </v-col>
            </v-row>
            <c-expansion-panels
              v-model="grupos"
              flat
              :isDisabled="isDisabled"
              :pageModeView="true"
            >
              <template #header="{ dataset: grupo }">
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" cols="1">
                    <v-icon
                      v-if="
                        extratoGrupo(grupo)[0] && extratoGrupo(grupo)[0].icon
                      "
                    >
                      {{ extratoGrupo(grupo)[0].icon }}
                    </v-icon>
                    <v-badge
                      v-else
                      color="grey"
                      :content="`${extratoGrupo(grupo).length}`"
                      inline
                      overlap
                      :value="`${extratoGrupo(grupo).length}`"
                    />
                  </v-col>
                  <v-col v-bind="prop.col" cols="6">
                    <span class="text-body-2 ml-1">
                      {{ grupo }}
                    </span>
                  </v-col>
                  <v-col v-bind="prop.col" align="end" cols="5">
                    <span
                      :class="`${
                        extratoGrupo(grupo).reduce(
                          (acc, item) => (acc += parseFloat(item.valor)),
                          0
                        ) <= 0
                          ? 'red--text'
                          : ''
                      } ${isDisabled(grupo) ? 'mr-6' : ''} text-body-2`"
                      >{{
                        numberFormat(
                          extratoGrupo(grupo).reduce(
                            (acc, item) => (acc += parseFloat(item.valor)),
                            0
                          ),
                          "currency"
                        )
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row v-bind="prop.row"> </v-row>
              </template>
              <template #content="{ dataset: grupo }">
                <div v-for="(extrato, i) of extratoGrupo(grupo)" :key="i">
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" cols="7">
                      <span class="text-body-1">{{ extrato.item }}</span>
                    </v-col>
                    <v-col
                      v-bind="prop.col"
                      v-if="extrato.valor"
                      align="end"
                      cols="5"
                    >
                      <span
                        :class="`${
                          extrato.valor < 0 ? 'red--text' : ''
                        } text-body-2`"
                        >{{ numberFormat(extrato.valor, "currency") }}</span
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    v-bind="prop.row"
                    v-if="extrato.percentual && extrato.baseCalculo"
                    class="mt-n2"
                  >
                    <v-col v-bind="prop.col">
                      <span class="text-caption"
                        >({{
                          numberFormat(extrato.percentual / 100, "percent", 2)
                        }}
                        *
                        {{
                          numberFormat(extrato.baseCalculo, "currency")
                        }})</span
                      >
                    </v-col>
                  </v-row>
                  <v-divider class="my-2" />
                </div>
              </template>
            </c-expansion-panels>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "CBaseDialogExtrato",

  /// MIXINS
  mixins: [mixinUtil, mixinSnackbar],

  /// PROPS
  props: {
    show: { type: Boolean, required: true },
    produto: { type: Object, required: true },
    extrato: { type: Array, required: true },
    configuracao: { type: Object, required: false },
  },

  /// DATA
  data() {
    return {};
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    grupos() {
      const grupos = [...new Set(this.extrato.map((extrato) => extrato.grupo))];
      return grupos;
    },
  },

  /// METHODS
  methods: {
    extratoGrupo(grupo) {
      const isCuponsEmpty = this.extrato.some(
        (extrato) =>
          grupo == "cupons" && extrato.valor == 0 && extrato.percentual == 0
      );

      if (grupo == "cupons" && isCuponsEmpty) {
        return [];
      }

      return this.extrato.filter((extrato) => extrato.grupo == grupo);
    },

    isDisabled(grupo) {
      return this.extrato.some(
        (extrato) =>
          (extrato.grupo == grupo && extrato.icon == "mdi-equal") ||
          (grupo == "cupons" && extrato.valor == 0 && extrato.percentual == 0)
      );
    },
  },
};
</script>