<!-- COD: BJB 29/07/2022 CFolhaPagamentoPageOcorrenciaEdit -->
<!-- *VER: JER 07/08/2022 CFolhaPagamentoPageOcorrenciaEdit -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- deletar -->
    <v-row v-bind="prop.row" v-if="ocorrencia.verbas == 0">
      <v-col v-bind="prop.col">
        <c-btn icon :ripple="false" @click="deletar">
          <v-icon color="red">mdi-minus-circle</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <!-- label acerto e verba -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="2">
        <span class="text-body-2">acerto</span>
      </v-col>
      <v-col v-bind="prop.col" cols="10">
        <span class="text-body-2">verba</span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" class="mt-n6">
      <!-- isAcerto -->
      <v-col v-bind="prop.col" cols="2">
        <c-switch
          v-model="ocorrencia.isAcerto"
          :disabled="ocorrencia.verbas > 0"
        />
      </v-col>
      <!-- idVerba -->
      <v-col v-bind="prop.col" cols="10">
        <c-select
          v-model="ocorrencia.idVerba"
          :disabled="ocorrencia.verbas > 0"
          :items="verbas"
          required
        />
      </v-col>
    </v-row>

    <!-- idVerba -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="ocorrencia.idPessoaColaborador"
          :disabled="ocorrencia.verbas > 0"
          :items="colaboradores"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataInicio -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="ocorrencia.dataInicio"
          classe="text-body-1"
          clearable
          date
          :disabled="ocorrencia.verbas > 0"
          label="data início"
        />
      </v-col>
      <!-- dataFim -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-if="ocorrencia.dataInicio"
          v-model="ocorrencia.dataFim"
          classe="text-body-1 ml-2"
          clearable
          date
          label="data fim"
          :minValue="
            maxDateValue([
              new Date(),
              parseISO(ocorrencia.dataInicio),
              ocorrencia.dataUltimaVerba
                ? parseISO(ocorrencia.dataUltimaVerba)
                : new Date(),
            ]).toISOString()
          "
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" v-if="ocorrencia.isAcerto" cols="6">
        <c-datetime-picker
          v-model="ocorrencia.data"
          classe="text-body-1"
          clearable
          date
          :disabled="ocorrencia.verbas > 0"
          label="data"
        />
      </v-col>
      <!-- valor -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="ocorrencia.valor"
          :disabled="ocorrencia.verbas > 0"
          label="valor"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
          required
        />
      </v-col>
    </v-row>

    <!-- referencia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="ocorrencia.referencia"
          :disabled="ocorrencia.verbas > 0"
          label="referência"
          :max="40"
        />
      </v-col>
    </v-row>

    <!-- pessoa favorecida -->
    <v-row
      v-bind="prop.row"
      v-if="
        ocorrencia.idVerba &&
        getVerba(ocorrencia.idVerba).idTipo ==
          getTipo_item('verbaOcorrenciaFavorecido').id
      "
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="ocorrencia.idPessoaFavorecida"
          :itemsIniciais="autocompletePessoaFavorecida"
          label="favorecido"
          path="/pessoas/autocomplete/pessoas/0/"
          :rules="[]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhaPagamentoPageOcorrenciaEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    index: { type: Number, required: true },
    ocorrencia: { type: Object, required: true },
  },

  /// DATA
  data() {
    return {
      autocompletePessoaFavorecida: this.ocorrencia.idPessoaFavorecida
        ? [
            {
              value: this.ocorrencia.idPessoaFavorecida,
              text: this.ocorrencia.pessoaFavorecida_nome,
            },
          ]
        : [],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getVerba: "verbas/getVerba",
    }),

    colaboradores() {
      if (this.context.datasetList?.dataset?.aux?.colaboradores) {
        return this.context.datasetList.dataset.aux.colaboradores.map(
          (colaborador) => {
            return {
              value: colaborador.id,
              text: colaborador.nome,
            };
          }
        );
      } else {
        return [];
      }
    },

    verbas() {
      if (this.context.datasetList?.dataset?.aux?.verbas) {
        const idTiposOcorrencia = this.getTipo_itens(
          this.getTipo("tipoVerba").id
        )
          .filter((tipo) => this.getTipo_item(tipo.value).isAplicar)
          .map((tipo) => tipo.value);

        return this.context.datasetList.dataset.aux.verbas
          .filter((verba) =>
            this.ocorrencia.isAcerto
              ? verba.allowAcerto
              : idTiposOcorrencia.includes(verba.idTipo)
          )
          .map((verba) => {
            return {
              value: verba.id,
              text: `${verba.id} ${verba.nomeAlt}`,
            };
          });
      } else {
        return [];
      }
    },
  },

  /// METHODS
  methods: {
    deletar() {
      if (this.ocorrencia.id) {
        this.ocorrencia.del = true;
      } else {
        this.context.datasets.splice(this.index, 1);
        this.context.datasetsTemp.splice(this.index, 1);
      }
    },
  },
};
</script>