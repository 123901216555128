<!-- COD: FDS 05/07/2022 CDicionarioEdit -->
<!-- *VER: JER 20/07/2022 CDicionarioEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- texto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-textarea
          v-model.trim="dataset.main.detail.texto"
          clearable
          label="texto"
          :max="500"
          required
        />
      </v-col>
    </v-row>

    <!-- locale -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="3">
        <c-select
          v-model="dataset.main.detail.idLocale"
          :items="locales"
          label="locale"
          required
        />
      </v-col>
    </v-row>

    <!-- tabela -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idTabela"
          clearable
          :items="tabelas"
          label="tabela"
        />
      </v-col>
    </v-row>

    <!-- traducao frontend -->
    <v-row
      v-bind="prop.row"
      v-if="getVar(`idPessoaParceiro`) == getConst(`app`).id"
    >
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.hasTraducaoFrontend"
          label="traduzido no frontend"
        />
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.observacao"
          label="observação"
          :max="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CDicionarioEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTabelas: "tabelas/getTabelas",
    }),

    tabelas() {
      return this.getTabelas();
    },
  },
};
</script>