<!-- COD: FDS 27/07/2022 CValorPagRecPageContaPagRecEdit -->
<!-- *VER: JER 05/08/2022 CValorPagRecPageContaPagRecEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- forma pagamento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(contaPagRec.idFormaPagamento).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="6">
        <v-spacer />
      </v-col>
      <!-- conta associada -->
      <v-col v-bind="prop.col" cols="1">
        <c-checkbox
          v-model="contaPagRec.contaAssociada"
          :disabled="disabled"
          @click="checkSaldoAssociar(contaPagRec, context)"
        />
      </v-col>
      <!-- valor associar -->
      <v-col v-bind="prop.col" cols="5">
        <c-text-money
          v-model="contaPagRec.valorAssociar"
          :clearable="false"
          :disabled="disabled"
          hint="o saldo menor"
          label="valor associar"
          :max="15"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
          @clickClear="alterarSaldoAssociar(contaPagRec, context, $event)"
          @keyup="alterarSaldoAssociar(contaPagRec, context, $event)"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data vencimento -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(contaPagRec.dataVencimento, "date") }}
        </span>
      </v-col>
      <!-- saldo -->
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">saldo</span>
        <span class="ml-1 text-body-1">
          {{ numberFormat(contaPagRec.saldoLimite, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- pedido -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-2">pedido</span>
        <span class="ml-1 text-body-1">
          {{ contaPagRec.idPedido }}
        </span>
      </v-col>
      <!-- duplicata -->
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-1">
          {{ contaPagRec.duplicata }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ contaPagRec.pessoa_nome }}
        </span>
      </v-col>
    </v-row>

    <!-- identification -->
    <c-base-item-identification
      v-if="contaPagRec.estado"
      :dataset="contaPagRec"
    />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseItemIdentification from "@/components/bases/CBaseItemIdentification";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CValorPagRecPageContaPagRecEdit",

  /// COMPONENTS
  components: { CBaseItemIdentification },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    contaPagRec: { type: Object, required: true },
    context: { type: Object, required: true },
    index: {},
    saldoAssociar: {},
  },

  /// DATA
  data() {
    return {
      status: ["proprio", "defeito", "cliente", "fornecedor"],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    disabled() {
      return this.context.datasets[this.index].disabled;
    },

    getSaldo() {
      return (
        this.saldoAssociar -
        this.context.datasets
          .filter((dataset) => dataset.contaAssociada)
          .reduce((acc, item) => (acc += parseFloat(item.valorAssociar)), 0)
      );
    },
  },

  /// METHODS
  methods: {
    alterarSaldoAssociar(contaPagRec, context, event) {
      if (event.type == "click") {
        contaPagRec.valorAssociar = null;
      }

      contaPagRec.contaAssociada = true;
      contaPagRec.contaAssociada = contaPagRec.valorAssociar != null;

      this.checkSaldoAssociar(contaPagRec, context);
    },

    checkSaldoAssociar(contaPagRec, context) {
      const saldoMinimo = this.getSaldoMinimo(contaPagRec, context);

      contaPagRec.valorAssociar = !contaPagRec.contaAssociada
        ? null
        : parseFloat(contaPagRec.valorAssociar) <= parseFloat(saldoMinimo)
        ? contaPagRec.valorAssociar
        : saldoMinimo.toString();

      contaPagRec.valorAssociar =
        contaPagRec.valorAssociar == 0 ? null : contaPagRec.valorAssociar;

      contaPagRec.contaAssociada = contaPagRec.valorAssociar > 0;

      context.datasets
        .filter((dataset) => !dataset.contaAssociada)
        .map((item) => {
          item.disabled = this.getSaldo <= 0;
        });
    },

    getSaldoMinimo(contaPagRec, context) {
      const valorMaximoAssociar =
        this.saldoAssociar -
        context.datasets
          .filter(
            (dataset) => dataset.contaAssociada && contaPagRec.id != dataset.id
          )
          .reduce((acc, item) => {
            return (acc += parseFloat(item.valorAssociar));
          }, 0);

      const saldoMinimo = Math.min(
        valorMaximoAssociar,
        contaPagRec.saldoLimite
      );

      return saldoMinimo;
    },
  },
};
</script>