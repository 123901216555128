<!-- COD: FDS 12/08/2022 Relatorios -->
<!-- *VER: JER 08/09/2022 Relatorios -->
<!-- /32/ -->

<template>
  <div class="relatorios">
    <!-- NAVBAR -->
    <c-the-navbar
      v-if="idTabela"
      mode="page"
      :title="getPagina(idPagina).nomeAlt"
    >
      <template #icon>
        <v-btn icon router to="/">
          <v-icon class="ml-2" large>{{ icon }}</v-icon>
        </v-btn>
      </template>
    </c-the-navbar>

    <!-- RELATORIO -->
    <v-container class="pa-0" fluid ref="printArea">
      <!-- parceiro -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <v-chip color="primary" outlined x-small>
            <span class="primary--text text-overline">
              {{ dadosParceiro.pessoa.nomeAlt }}
            </span>
          </v-chip>
        </v-col>
      </v-row>

      <!-- relatorios -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <c-select
            v-model="idFeature"
            :items="paginaRelatorios"
            label=""
            required
            solo
          />
        </v-col>
      </v-row>

      <div v-if="getFeature(idFeature).options.isHere">
        <!-- busca -->
        <v-row v-bind="prop.row" class="mt-n5">
          <v-col v-bind="prop.col">
            <c-text-field
              v-model="search"
              :appendOuterIcon="
                idFeature != getFeature('adapterReportDefault').id
                  ? 'mdi-tune'
                  : null
              "
              clearable
              :max="100"
              :noAccept="null"
              prepend-inner-icon="mdi-magnify"
              solo
              type="search"
              @clickAppendOuter="showSearchOptions = !showSearchOptions"
            />
          </v-col>
        </v-row>

        <template v-if="showSearchOptions">
          <component
            v-if="featureRelatorio"
            :context="this"
            :field="featureRelatorio.nome"
            :is="featureRelatorio.nome"
            :list="list"
            :panel="featureRelatorio"
            :params="params"
          ></component>
        </template>

        <!-- so lancamentos conciliados -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-btn block classe="primary mb-4" @click="clickGerar()">
              gerar
            </c-btn>
          </v-col>
        </v-row>

        <v-slider
          v-if="params && params.hasNivel"
          v-model="params.nivel"
          class="mb-3"
          :max="4"
          step="1"
          :tick-labels="params.niveis"
          tick-size="5"
          ticks="always"
          @click="changeNivel(params.nivel)"
        />

        <v-row v-bind="prop.row">
          <!-- quantidade registro -->
          <v-col v-bind="prop.col" cols="1">
            <v-badge
              class="ml-6"
              :color="
                idFilter == getFeature('filterPendentes').id ? 'blue' : 'grey'
              "
              :content="list.listaMap.length.toString()"
              left
            />
          </v-col>
          <!-- data -->
          <v-col v-bind="prop.col" align="end" cols="11">
            <span class="text-body-1">
              {{ dataFormata(new Date(), "date timeNoSecond", true) }}
            </span>
          </v-col>
        </v-row>

        <v-data-table
          v-if="list.headers && list.headers.length"
          dense
          :disable-pagination="disablePagination"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30, -1],
            itemsPerPageAllText: 'Todos',
          }"
          :group-by="params ? params.groupBy : null"
          :headers="hiddenHeaders"
          hide-default-header
          item-key="index"
          :items="
            list.listaMap.map((itemList, i) => {
              return {
                ...itemList,
                index: i,
              };
            })
          "
          :items-per-page="pagination.itemsPerPage"
          :mobile-breakpoint="0"
          :search="search"
        >
          <template v-if="params && params.groupBy" v-slot:top>
            <v-btn v-if="open" icon small @click="closeAll">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn v-else icon small @click="openAll">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <template
            v-slot:[`group.header`]="{ group, headers, toggle, isOpen }"
          >
            <td :colspan="headers.length">
              <v-btn
                :data-open="isOpen"
                icon
                :ref="group"
                small
                @click="toggle"
              >
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <span
                v-if="
                  list.headers.some(
                    (header) =>
                      header.options &&
                      header.options.isGroupBy &&
                      params &&
                      params.groupBy == header.value
                  )
                "
              >
                {{
                  formatValue(
                    group,
                    list.headers.find(
                      (header) =>
                        header.options &&
                        header.options.isGroupBy &&
                        params.groupBy == header.value
                    ).options
                  )
                }}
              </span>
            </td>
          </template>

          <template v-slot:header="{ props }">
            <thead class="v-data-table-header">
              <tr>
                <th
                  v-bind:class="[
                    pagination.sortBy == header.value ? 'active' : '',
                    pagination.descending ? 'desc' : 'asc',
                  ]"
                  v-for="(header, i) in props.headers.filter(
                    (header) => !header.isHidden
                  )"
                  :key="i"
                  scope="col"
                  @click="params.isOrderable ? changeSort(header.value) : ''"
                >
                  <span>
                    {{ header.text }}
                    <v-icon v-if="pagination.sortBy == header.value" x-small>
                      {{
                        pagination.descending
                          ? "mdi-arrow-down-thin"
                          : "mdi-arrow-up-thin"
                      }}
                    </v-icon>
                  </span>
                </th>
              </tr>
            </thead>
          </template>

          <template
            v-for="(header, i) in list.headers"
            v-slot:[`item.${header.value}`]="{ item }"
          >
            <div class="py-2" :key="`header${i}`">
              <v-icon
                v-if="header.options && header.options.hasIcon"
                :color="item.info.color"
              >
                {{ item[header.value] }}
              </v-icon>
              <c-help
                v-else-if="header.options && header.options.hasInfo"
                estilo="width: fit-content"
                :help="{
                  title: item[header.value],
                  text: item.options.info[getVar('locale')],
                  function: setUiBottomSheet,
                }"
              >
                <span
                  :class="`${
                    header.options && header.options.isChangeColor
                      ? `${getColor(item[header.value])}--text`
                      : null
                  } 
              ${
                header.isBold && item.isBold
                  ? 'font-weight-medium primary--text'
                  : !item.isBold && header.isBold
                  ? 'grey--text'
                  : null
              }
              `"
                >
                  {{
                    header.fixedValue !== undefined
                      ? header.fixedValue
                      : formatValue(item[header.value], header.options)
                  }}
                  <span
                    v-if="header.options && header.options.suffix"
                    class="text-body-2"
                  >
                    {{ header.options.suffix }}
                  </span>
                </span>
              </c-help>
              <span
                v-else
                :class="`${
                  header.options && header.options.isChangeColor
                    ? `${getColor(item[header.value])}--text`
                    : null
                } 
              ${
                header.isBold && item.isBold
                  ? 'font-weight-medium primary--text'
                  : !item.isBold && header.isBold
                  ? 'grey--text'
                  : null
              }
              `"
              >
                {{
                  header.fixedValue !== undefined
                    ? header.fixedValue
                    : formatValue(item[header.value], header.options)
                }}
                <span
                  v-if="header.options && header.options.suffix"
                  class="text-body-2"
                >
                  {{ header.options.suffix }}
                </span>
              </span>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-container>

    <c-the-navbar
      bottom
      mode="page"
      :pageModeView="pageModeView"
      :slots="buttonsBar.length"
      title=""
      @showMenuVoltar="menuOptions = false"
    >
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-btn
          v-if="!Array.isArray(button)"
          icon
          :key="i"
          @click="button.clickFunction(button)"
        >
          <v-icon :color="button.options.color || 'white'" large>
            {{ button.options.icon }}
          </v-icon>
        </c-btn>

        <v-menu v-else v-model="menuOptions" :key="i" offset-y top>
          <template #activator="{ on }">
            <c-btn v-on="on" icon>
              <v-icon large>mdi-dots-horizontal</v-icon>
            </c-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, i) in button"
              :key="i"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </c-the-navbar>

    <report-dialog-configuracoes
      v-if="showConfiguracoes"
      :dataset="dataset"
      :idFeature="idFeature"
      :idPagina="idPagina"
      :params="params"
      :show="showConfiguracoes"
      @close="closeConfiguracoes()"
      @save="saveConfiguracoes()"
    />

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />
  </div>
</template>

<script>
/// IMPORT
import { mapActions, mapGetters } from "vuex";
import { utils, writeFile } from "xlsx";
import axios from "axios";
import CTheNavbar from "@/components/bases/CTheNavbar";
import reportDialogConfiguracoes from "@/components/pages/relatorio/reportDialogConfiguracoes";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinTemplate from "@/mixins/mixinTemplate";

export default {
  /// NAME
  name: "Relatorios",

  /// COMPONENTS
  components: {
    CTheNavbar,
    reportDialogConfiguracoes,
  },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinData, mixinLib, mixinSnackbar, mixinTemplate],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("relatorios").id,
      idFeature: getFeature("adapterReportDefault").id,
      dataset: null,
      disablePagination: false,
      featureRelatorio: null,
      idContexto: null,
      idFilter: null,
      idOrder: null,
      idPagina: null,
      idPessoaFornecedorOld: false,
      idRegistro: null,
      idTabela: null,
      limit: 10000,
      list: {
        headers: [],
        listaMap: [],
      },
      listTemp: {
        headers: [],
        listaMap: [],
      },
      menuOptions: false,
      open: true,
      pagination: {
        sortBy: null,
        descending: false,
        page: 1,
        itemsPerPage: 20,
      },
      params: {
        fornecedores: [],
        hasNivel: false,
        idPessoaFornecedor: null,
        isOrderable: true,
        itemsPerPage: 20,
        lang: getVar("locale"),
        searchData: {
          inicio: null,
          fim: null,
        },
      },
      registro: null,
      search: "",
      searchData: {
        inicio: null,
        fim: null,
      },
      showConfiguracoes: false,
      showSearchOptions: true,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getPagina_configuracoes: "paginas/getPagina_configuracoes",
      getPagina_documentos: "paginas/getPagina_documentos",
      getPagina: "paginas/getPagina",
      getTabela: "tabelas/getTabela",
      getVar: "variaveis/getVar",
    }),

    buttonsBar() {
      const feature = this.getFeature(this.idFeature);

      return [
        ...this.buttons
          .filter(
            (buttom) =>
              feature.options.isHere ||
              (feature.options.isConfiguravel &&
                buttom.id == this.getFeature("optionConfiguracoes").id)
          )
          .map((button) => {
            return {
              ...button,
              clickFunction: this.clickOption,
            };
          }),
        this.options,
      ];
    },

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    headersColor() {
      return this.list.headers.filter((header) => header.options?.isChangeColor);
    },

    hiddenHeaders() {
      return this.list.headers.filter((header) => !header.isHidden);
    },

    paginaRelatorios() {
      return this.getPagina_configuracoes(this.idPagina)
        .map((relatorio) => this.getFeature(relatorio.idFeature))
        .map((feature) => {
          return {
            value: feature.id,
            text: feature.nomeAlt.toUpperCase(),
          };
        });
    },
  },

  /// WATCH
  watch: {
    idFeature() {
      this.adjustRelatorios();
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    adjustRelatorios() {
      this.list.listaMap = [];
      this.list.headers = [];
      this.headerRelatorio();
      delete this.params.groupBy;
      delete this.params.isOrderable;

      if (this.idFeature != this.getFeature("adapterReportDefault").id) {
        this.featureRelatorio = this.getFeature(this.idFeature);

        this.$options.components[`${this.featureRelatorio.nome}`] = () =>
          import(`@/components/pages/relatorio/${this.featureRelatorio.nome}`);
      } else {
        this.params = {
          searchData: {
            inicio: null,
            fim: null,
          },
          itemsPerPage: 20,
          hasNivel: false,
          isOrderable: true,
          lang: this.getVar("locale"),
        };

        this.featureRelatorio = null;
        this.clickGerar();
      }
    },

    changeNivel(nivel) {
      this.list.listaMap = this.listTemp.listaMap.filter(
        (list) => list.nivel <= nivel + 1
      );
    },

    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }

      if (this.pagination.sortBy) {
        this.list.listaMap = this.list.listaMap.sort((a, b) => {
          const sortA = a[this.pagination.sortBy];
          const sortB = b[this.pagination.sortBy];

          if (this.pagination.descending) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
    },

    async clickCompartilhar() {
      const listagem = this.getListagem();
      const nomeArquivo = `${this.getNomeArquivo(true)}.csv`;

      const keys = Object.keys(listagem[0]).join(";");

      listagem.forEach((item, i) => {
        listagem[i] = Object.values(item).join(";");
      });
      listagem.unshift(keys);

      const listagemText = listagem.join("\n");
      const blob = new Blob([listagemText], {
        type: "text/csv",
      });
      const file = new File([blob], nomeArquivo, {
        type: blob.type,
      });

      if (navigator.share) {
        if ("canShare" in navigator && navigator.canShare({ files: [file] })) {
          const share = (options) =>
            new Promise((resolve, reject) => {
              navigator
                .share(options)
                .then(resolve())
                .catch((error) => {
                  reject(error);
                });
            });

          share({
            text: "some_text",
            title: "some_title",
            files: [file],
          });
        }
      }
    },

    clickConfiguracoes() {
      this.idPessoaFornecedorOld = this.params.idPessoaFornecedor
        ? this.params.idPessoaFornecedor
        : null;
      this.showConfiguracoes = true;
    },

    clickDownload(share = false) {
      const listagem = this.getListagem();

      const relatorio = utils.json_to_sheet(listagem);
      const workBook = utils.book_new();
      const nomeArquivo = `${this.getNomeArquivo()}.xlsx`;
      const options = {
        bookType: "xlsx",
      };

      utils.book_append_sheet(workBook, relatorio, "relatório");
      if (share) {
        return writeFile(workBook, nomeArquivo, {
          bookType: "xlsx",
          type: "array",
        });
      }
      writeFile(workBook, nomeArquivo, options);
    },

    clickGerar() {
      this.list.listaMap = [];

      this.setVar({ overlay: true });
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      this.idPagina = this.$route.params.idPagina;
      this.idTabela = this.$route.params.idTabela;
      this.idRegistro = this.$route.params.idRegistro;
      this.idRelatorio = this.$route.params.idRelatorio;
      this.idFilter = this.$route.params.idFilter;
      this.idOrder = this.$route.params.idOrder;
      this.search = this.$route.params.search;
      this.idContexto = this.$route.params.idContexto || "*";

      const search = this.search;
      this.search = this.search == "*" ? "" : this.search;

      let url;

      url = `${this.getConst("app").baseUrlBackend}/${
        this.getTabela(this.idTabela).nome
      }/${search}/${this.idContexto}/${this.idFilter}/${
        this.idOrder
      }/${idPessoaParceiro}/${idUsuario}/1/0/${this.limit}`;

      if (this.params) {
        const parametros = JSON.parse(JSON.stringify(this.params));
        delete parametros.itemsPerPage;
        delete parametros.hasNivel;
        delete parametros.fornecedores;
        delete parametros.isOrderable;

        if (this.idFeature == this.getFeature("adapterReportDefault").id) {
          delete parametros.lang;
        }

        url += `/${JSON.stringify(parametros)}`;
      }

      if (this.idFeature != this.getFeature("adapterReportDefault").id) {
        url += `/${this.idFeature}`;
      }

      axios
        .get(url)
        .then(async (res) => {
          const result = res?.data;
          this.list.listaMap = result;

          this.list.listaMap.forEach((list, i) => {
            if (
              i == 0 ||
              list[this.params?.separador] !=
                this.list.listaMap[i - 1][this.params?.separador]
            ) {
              list.isBold = true;
            }
          });

          await this.headerRelatorio();

          this.list.headers = this.list.headers.config.map((header) => {
            return {
              ...header,
              fixedValue: header.valorFixo,
              value: header.colunaApp || header.valorFixo,
              text: header.nomeColuna,
              isHidden: header.isHidden ? this.getVal(header.isHidden) : false,
            };
          });

          this.listTemp = JSON.parse(JSON.stringify(this.list));

          if (this.params && this.params.hasNivel) {
            this.changeNivel(this.params.nivel);
          }
        })
        .catch((err) => {
          const message =
            (typeof err === "object" ? err?.response?.data : err) ||
            "erro ao executar o comando";
          this.setSnackbar(true, "ERRO", message, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
          this.search = "";
        });
    },

    clickOption(paginaFeature) {
      const action =
        paginaFeature.options?.click || paginaFeature.options?.route;

      if (action == "clickDownload") {
        this.clickDownload();
      } else if (action == "clickImprimir") {
        // window.print();
        this.disablePagination = true;
        setTimeout(() => {
          this.pagePrint("relatorio");

          setTimeout(() => {
            this.disablePagination = false;
          }, 100);
        }, 100);
      } else if (action == "clickCompartilhar") {
        this.clickCompartilhar();
      } else if (action == "clickConfiguracoes") {
        this.clickConfiguracoes();
      } else if (action == "clickAjuda") {
        const paginaDocumento = this.getPagina_documentos(
          this.getPagina("ajuda")?.id
        ).find(
          (paginaDocumento) =>
            paginaDocumento.options.feature &&
            this.getFeature(paginaDocumento.options.feature)?.id ==
              this.getFeature("optionRelatorios")?.id
        );

        const url = paginaDocumento ? `/ajuda#${paginaDocumento.id}` : "/ajuda";

        this.$router.push(encodeURI(url));
      }
    },

    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });

      this.open = false;
    },

    closeConfiguracoes() {
      this.params.idPessoaFornecedor = this.idPessoaFornecedorOld;
      this.showConfiguracoes = false;
      this.$forceUpdate();
    },

    formatValue(valor, options) {
      if (valor) {
        if (options?.format == "numberFormat") {
          return this.numberFormat(
            parseFloat(valor),
            options.mask,
            options.decimal
          );
        } else if (options?.format == "dataFormata") {
          const data =
            valor instanceof Date && !isNaN(valor.valueOf())
              ? new Date(valor)
              : valor;

          return this.dataFormata(data, options.mask, false);
        } else if (typeof valor == "object") {
          return (
            valor[this.getVar("locale")] || this.getFirstLocaleContent(valor)
          );
        }
      }

      return valor;
    },

    getBackgroundColor(valor) {
      if (valor == 601) return "grey";
      else if (valor == 603) return "blue";
      else return "white";
    },

    getColor(valor) {
      if (valor < 0) return "red";
      else return "black";
    },

    getListagem() {
      return this.list.listaMap.map((lista) => {
        const itemLista = {};
        return this.list.headers
          .filter((header) => !header.isHiddenDownload)
          .map((header) => {
            let options = header.options;
            if (options?.mask) {
              options.mask =
                options.format == "numberFormat" ? "decimal" : options.mask;
            }

            itemLista[header.nomeColuna] = this.formatValue(
              lista[header.value] || header.valorFixo,
              options
            );
            return itemLista;
          })[0];
      });
    },

    getNomeArquivo(share = false) {
      const nomeRelatorio =
        this.featureRelatorio?.nomeAlt || this.getPagina(this.idPagina).nomeAlt;
      const parceiro = ` ${this.dadosParceiro.pessoa.nomeAlt}`;
      const nomeFornecedor = this.params.idPessoaFornecedor
        ? ` - ${
            this.params.fornecedores.find(
              (fornecedor) => fornecedor.value == this.params.idPessoaFornecedor
            ).text
          }`
        : "";
      const hoje = this.dataFormata(
        new Date(),
        "date timeNoSecond",
        true
      ).replace(/[/: ]/g, "");
      const dataInicio = this.params.searchData.inicio
        ? this.dataFormata(
            new Date(this.params.searchData.inicio),
            "date",
            true
          )
        : "";
      const dataFim = this.params.searchData.fim
        ? ` ${this.params.searchData.inicio ? "-" : ""} ${this.dataFormata(
            new Date(this.params.searchData.fim),
            "date",
            true
          )}`
        : "";

      return share
        ? `${nomeRelatorio}${parceiro}`
        : `${nomeRelatorio}${parceiro}${nomeFornecedor} ${dataInicio}${dataFim} - em ${hoje}`;
    },

    async headerRelatorio() {
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      let params = {
        idPagina: this.idPagina,
        idFeature: this.idFeature,
        idPessoaParceiro,
        idPessoaFornecedor: this.params?.idPessoaFornecedor || null,
      };

      params = JSON.stringify(params);
      const url = `${
        this.getConst("app").baseUrlBackend
      }/paginas_configuracoes/${params}`;
      const res = await axios.get(url);

      this.dataset = res.data;
      this.list.headers = this.dataset.main.detail.options;
    },

    openAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && !this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });

      this.open = true;
    },

    saveConfiguracoes() {
      this.idPessoaFornecedorOld = this.params.idPessoaFornecedor;
      this.showConfiguracoes = false;
      if (this.getFeature(this.idFeature).options.isHere) {
        this.clickGerar();
        this.$forceUpdate();
      }
    },
  },

  /// CREATED
  created() {
    this.$root.$emit("resize", {});
  },

  /// BEFOREMOUNT
  beforeMount() {
    this.setVar({ overlay: true });

    this.idPagina = this.$route.params.idPagina;
    this.idTabela = this.$route.params.idTabela;
    this.idRegistro = this.$route.params.idRegistro;
    this.idRelatorio = this.$route.params.idRelatorio;
    this.idFilter = this.$route.params.idFilter;
    this.idOrder = this.$route.params.idOrder;
    this.search = this.$route.params.search;
    this.idContexto = this.$route.params.idContexto || "*";

    if (this.idRegistro > 0) {
      this.relatorios.pop();
    }

    if (this.$route?.params?.params)
      this.params = JSON.parse(this.$route.params.params);

    this.search = this.search == "*" ? "" : this.search;

    if (this.params) {
      this.params.searchData = this.searchData;
      this.params.itemsPerPage = 20;
      this.params.isOrderable = true;
      this.params.lang = this.getVar("locale");
    }

    this.clickGerar();
  },

  /// DESTROYED
  destroyed() {
    this.$root.$emit("resize");
  },
};
</script>