<!-- COD: BJB 18/07/2022 CExpansionPanels -->
<!-- *VER: JER 18/07/2022 CExpansionPanels -->
<!-- /22/ -->

<template>
  <v-container v-bind="prop.container" class="pa-0 subPanel">
    <v-row v-bind="prop.row" v-if="hasPanelsCollapse && model.length">
      <v-col v-bind="prop.col" cols="11">
        <slot name="contentCollapse" :datasets="model"></slot>
      </v-col>
      <v-col v-bind="prop.col" align="end" cols="1">
        <c-btn classe="ml-n1" icon @click="clickPanels">
          <v-icon>
            {{ iconPanelCollapse }}
          </v-icon>
        </c-btn>
      </v-col>
    </v-row>
    <v-row v-bind="prop.row" :class="!hasPanelsCollapse ? 'mt-8' : ''">
      <v-expansion-panels
        v-bind="$attrs"
        v-model="panelsOpenComputed"
        class="ma-0 pa-0"
        :class="classe"
        :multiple="multiple"
        style="width: 100%"
      >
        <draggable
          v-bind="dragOptions"
          v-model="model"
          draggable=".draggableItem"
          style="width: 100%"
          @change="changeDraggable"
        >
          <div
            v-for="(dataset, i) in model"
            v-show="
              ((dataset && !dataset.del) || dataset == null) &&
              showDataset(dataset, i)
            "
            class="draggableItem v-expansion-panels--flat"
            :id="`${panel ? panel.nomeAlt.normalizeBr() : 'panel'}${i}`"
            :key="i"
          >
            <v-expansion-panel
              v-bind="prop.expansionPanel"
              v-if="dataset || dataset === null"
              :disabled="isPanelDisabled(dataset, i)"
              @change="changePanel(i)"
            >
              <v-expansion-panel-header
                v-bind="prop.expansionPanelHeader"
                :color="colorHeader"
              >
                <v-container v-bind="prop.container">
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" :cols="cols()">
                      <slot
                        name="header"
                        :dataset="dataset"
                        :index="i"
                        :panelOpen="
                          Array.isArray(panelsOpen)
                            ? panelsOpen.includes(i)
                            : null
                        "
                      ></slot>
                    </v-col>
                    <!-- drag-drop -->
                    <v-col v-bind="prop.col" v-if="!isDisableDragDrop" cols="1">
                      <v-icon class="ml-n2">mdi-drag-horizontal-variant</v-icon>
                    </v-col>
                    <!-- badge -->
                    <v-col
                      v-bind="prop.col"
                      v-if="hasDot(dataset, i) && isDisableDragDrop"
                      align="end"
                      :class="badgeClass(dataset, i)"
                      cols="1"
                    >
                      <v-badge dot :color="colorDot(dataset, i)"></v-badge>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-bind="prop.expansionPanelContent"
                class="mt-4"
                eager
              >
                <slot name="content" :dataset="dataset" :index="i"></slot>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
        </draggable>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  /// NAME
  name: "CExpansionPanels",

  /// COMPONENTS
  components: {
    draggable,
  },

  /// PROPS
  props: {
    classe: { type: String },
    colorDot: { type: Function, default: () => "error" },
    colorHeader: { type: String, default: "grey lighten-3" },
    hasDot: { type: Function, default: () => false },
    hasPanelsCollapse: { type: Boolean, default: true },
    isDisabled: { type: Function, default: () => false },
    isDraggable: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
    open: { type: Array, default: () => [] },
    pageModeView: { type: Boolean, default: true },
    panel: { type: Object },
    params: { type: Object, default: () => {} },
    showDataset: { type: Function, default: () => true },
    value: { type: Array, required: true },
  },

  /// DATA
  data() {
    return {
      panelsOpen: this.open,
    };
  },

  /// COMPUTED
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    cParams() {
      return this.panel && this.params
        ? this.params.panels.find((panel) => panel.nome == this.panel.nome)
        : this.params;
    },

    dragOptions() {
      return {
        animation: 200,
        delay: 200,
        disabled: this.isDisableDragDrop,
        ghostClass: "ghost",
        group: "description",
      };
    },

    iconPanelCollapse() {
      return !this.panelsOpen?.length ? "mdi-chevron-down" : "mdi-chevron-up";
    },

    isDisableDragDrop() {
      return (
        !this.isDraggable || this.pageModeView || !!this.panelsOpen?.length
      );
    },

    panelsOpenComputed: {
      get() {
        return this.multiple ? this.panelsOpen : this.panelsOpen[0] || null;
      },
      set(newValue) {
        if (this.multiple) {
          this.panelsOpen = newValue;
        } else {
          this.panelsOpen = newValue != undefined ? [newValue] : [];
        }
      },
    },
  },

  watch: {
    "model.length"(valor, valorAnterior) {
      if (valorAnterior < valor) {
        if (!this.model[valor - 1].id) {
          if (Array.isArray(this.panelsOpen)) {
            this.panelsOpen.push(valor - 1);
          }
        } else if (!this.model[0].id) {
          if (Array.isArray(this.panelsOpen)) {
            this.panelsOpen.unshift(0);
          }
        }
      }
    },

    panelsOpen: {
      handler() {
        if (this.cParams && "specificPanelsOpen" in this.cParams) {
          this.cParams.specificPanelsOpen = this.panelsOpen;
        }
      },
      immediate: true,
    },

    "params.clickPanelsOpen"() {
      if (this.params.clickPanelsOpen !== null) {
        this.panelsOpen = !this.params.clickPanelsOpen
          ? []
          : Array(this.model.length)
              .fill()
              .map((_, i) => i)
              .filter((i) => !this.isPanelDisabled(this.model[i], i));
      }
    },

    model: {
      handler() {
        this.model.forEach((dataset, i) => {
          const hasDot = this.hasDot(dataset, i);

          if (hasDot) {
            this.panelsOpen.push(i);
          }
        });
      },
    },

    open: {
      handler(valor, valorAnterior) {
        if (valor != valorAnterior) {
          this.panelsOpen = this.open;
        }
      },
      deep: true,
    },
  },

  /// METHODS
  methods: {
    badgeClass(dataset, index) {
      return this.isPanelDisabled(dataset, index) ? "ml-n10" : "ml-n6";
    },

    changePanel(index) {
      this.$emit("changePanel", index, this.panelsOpen, this.value);
      setTimeout(() => {
        this.$emit("panelsOpen", this.panelsOpen);
      }, 10);
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? []
        : Array(this.model.length)
            .fill()
            .map((_, i) => i)
            .filter((i) => !this.isPanelDisabled(this.model[i], i));

      this.$emit("clickPanels", this.panelsOpen);
    },

    changeDraggable(event) {
      if (event && "moved" in event) {
        this.$emit("draggable", event.moved);
      }
    },

    cols() {
      const hasDot = this.model.some((dataset, i) => this.hasDot(dataset, i));
      return !this.isDisableDragDrop || hasDot ? 11 : 12;
    },

    isPanelDisabled(dataset, index) {
      return this.pageModeView ? this.isDisabled(dataset, index) : false;
    },
  },
};
</script>

<style>
.v-expansion-panel--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>