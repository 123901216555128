// COD: BJB 19/08/2022 mixinDocumento
// #VER: JER __/08/2022 mixinDocumento
// 2?

import { mapGetters } from "vuex";
import CBaseDocumento from "@/components/bases/CBaseDocumento";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "mixinDocumento",

  /// COMPONENTS
  components: { CBaseDocumento, CTheNavbar },

  /// MIXINS
  mixins: [mixinData, mixinSnackbar, mixinUsuarios],

  /// HEAD
  head: {
    title() {
      return {
        inner: this.page.nomeAlt,
      };
    },
  },

  /// DATA
  data() {
    return {
      context: this,
      documentoSave: null,
      pageModeView: true,
      panels: [],
      panelsOpen: [],
      params: {
        clickSubPanelsOpen: null,
        subPanelsOpen: [],
      },
      specificPanelsOpen: [],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getCategoriasLength: "categorias/getCategoriasLength",
      getFeature: "paginas/getFeature",
      getPagina: "paginas/getPagina",
      getPagina_documentos: "paginas/getPagina_documentos",
      getPagina_documento: "paginas/getPagina_documento",
      getTipo_item: "tipos/getTipo_item",
    }),

    chevronDoubleIcon() {
      return !this.hasSubPanelsOpen
        ? "mdi-chevron-double-down"
        : "mdi-chevron-double-up";
    },

    documentosPage() {
      return this.getPagina_documentos(this.idPage);
    },

    hasAdminOptions() {
      const perfilAdmin = this.getTipo_item("perfilAdmin").id;
      const perfilSuporte = this.getTipo_item("perfilSuporte").id;

      return (
        this.getVar("idPessoaParceiro") == this.getConst("app").id &&
        (this.perfis.includes(perfilAdmin) ||
          this.perfis.includes(perfilSuporte))
      );
    },

    hasSubPanelsOpen() {
      return this.params.subPanelsOpen.some(subPanelOpen => !!subPanelOpen[1].length)
    },

    icon() {
      return this.getFeature(this.page.idFeature).options?.icon;
    },

    iconPanelCollapse() {
      return !this.panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up";
    },

    page() {
      return this.getPagina(this.idPage);
    },
  },

  /// ACTIVATED
  async activated() {
    if (this.$route.hash) {
      const hash = parseInt(this.$route.hash.substring(1));
      this.specificPanelsOpen = this.openSpecificPanels(hash);

      this.specificPanelsOpen.forEach((idSpecificPanel) => {
        const index = this.panels.findIndex(
          (idPanel) => idPanel == idSpecificPanel
        );
        if (index >= 0 && !this.panelsOpen.includes(index)) {
          this.panelsOpen.push(index);
        }
      });

      setTimeout(() => {
        const elementGoTo = document.getElementById(hash);

        if (elementGoTo) {
          this.$vuetify.goTo(elementGoTo, {
            duration: 1000,
            offset: 0,
            easing: "linear",
          });
        }
      }, 300);
    }
  },

  /// MOUNTED
  async mounted() {
    await this.inicio();

    this.panels = this.getPanels(this.$refs.panelsDocumentos);

    this.panels.forEach((idPanel, i) => {
      if (
        this.documentosPage.find((documento) => documento.id == idPanel)
          ?.options?.isOpen
      ) {
        this.panelsOpen.push(i);
      }
    });

    if (this.$route.hash) {
      const hash = parseInt(this.$route.hash.substring(1));
      this.specificPanelsOpen = this.openSpecificPanels(hash);

      this.specificPanelsOpen.forEach((idSpecificPanel) => {
        const index = this.panels.findIndex(
          (idPanel) => idPanel == idSpecificPanel
        );
        if (index >= 0 && !this.panelsOpen.includes(index)) {
          this.panelsOpen.push(index);
        }
      });

      setTimeout(() => {
        const elementGoTo = document.getElementById(hash);

        if (elementGoTo) {
          this.$vuetify.goTo(elementGoTo, {
            duration: 1000,
            offset: 0,
            easing: "linear",
          });
        }
      }, 300);
    }
  },

  /// METHODS
  methods: {
    clickEditView() {
      this.pageModeView = !this.pageModeView;
    },

    clickFooter(action) {
      window.open(action, "_blank");
    },

    clickLink(hash) {
      if (hash.substring(0, 4) == "http" || hash.substring(0, 6) == "mailto") {
        window.open(hash, "_blank");
      } else if (hash.substring(0, 1) == "/") {
        this.$router.push(hash);
      }
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? []
        : Array(this.panels.length)
          .fill()
          .map((_, i) => i);
    },

    clickPanelsDouble() {
      this.params.clickSubPanelsOpen = !this.hasSubPanelsOpen;

      setTimeout(() => {
        this.params.clickSubPanelsOpen = null;
      }, 10);
    },

    documentos(idDocumentoPai) {
      return this.documentosPage.filter(
        (documento) => documento.idDocumentoPai == idDocumentoPai
      );
    },

    getPanels(element, panels = []) {
      element.$children.forEach((elementChildren) => {
        if (elementChildren.$attrs?.id) {
          panels.push(elementChildren.$attrs?.id);
        } else {
          panels = this.getPanels(elementChildren, panels);
        }
      });

      return panels;
    },

    goToHash(hash) {
      if (hash) {
        hash = parseInt(hash.substring(1));
        this.specificPanelsOpen = this.openSpecificPanels(hash);

        this.specificPanelsOpen.forEach((idSpecificPanel) => {
          const index = this.panels.findIndex(
            (idPanel) => idPanel == idSpecificPanel
          );
          if (index >= 0 && !this.panelsOpen.includes(index)) {
            this.panelsOpen.push(index);
          }
        });

        setTimeout(() => {
          const elementGoTo = document.getElementById(hash);

          if (elementGoTo) {
            this.$vuetify.goTo(elementGoTo, {
              duration: 1000,
              offset: 0,
              easing: "linear",
            });
          }
        }, 300);
      }
    },

    inicio() {
      return new Promise((resolve) => {
        let timer = setInterval(() => {
          if (this.getCategoriasLength()) {
            clearInterval(timer);
            timer = null;
            resolve();
          }
        }, 10);
      });
    },

    openSpecificPanels(id, specificPanels = []) {
      const panel = this.getPagina_documento(id);

      if (panel) {
        if ("open" in panel.options) {
          specificPanels.push(id);
        }

        if (panel.idDocumentoPai) {
          return this.openSpecificPanels(panel.idDocumentoPai, specificPanels);
        } else {
          return specificPanels;
        }
      } else {
        return specificPanels;
      }
    },
  },
}