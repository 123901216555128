<!-- COD: BJBN 09/01/2023 Legislacao -->
<!-- *VER: JER __/01/2023 Legislacao -->
<!-- 11 -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ pageModeView, page, context }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-legislacao-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-legislacao-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CLegislacaoEdit from "@/components/pages/legislacao/CLegislacaoEdit";
import CLegislacaoView from "@/components/pages/legislacao/CLegislacaoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Legislacao",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CLegislacaoEdit,
    CLegislacaoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getLocalidade = this.$store.getters["localidades/getLocalidade"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("legislacao").id,
      idPageAlt: getPagina("legislacoes").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("legislacaoPrincipal").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPais: getLocalidade("brasil").id,
            nome: null,
            data: null,
            dataPublicacao: null,
            dataInicio: null,
            dataFim: null,
            referencias: null,
            pessoas: null,
            manterItensAnteriores: false,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          itens: [],
        },
        aux: {
          detail: {
            pessoaParceiro: null,
          },
        },
      },

      funcs: {
        clickItens: () => {
          this.$router.push(
            `/legislacao/legislacao_Itens/${this.dataset.main.detail.id}`
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posSubmit: (context) => {
          if (!context.dataset.main.detail.id) {
            context.dataset.main.detail.idPais =
              context.dadosUsuario.enderecos[0].idPais;
          }
        },
      },

      params: {
        context: null,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>