<!-- COD: FDS 12/08/2022 reportDialogConfiguracoes -->
<!-- *VER: JER 08/09/2022 reportDialogConfiguracoes -->
<!-- /33/ -->

<template>
  <v-dialog
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    :value="show"
  >
    <v-card>
      <v-card-title class="pa-0 mb-5">
        <v-app-bar color="primary" dark fixed>
          <v-row justify="center" no-gutters>
            <v-col cols="12" lg="6" md="8" sm="10" xl="4">
              <v-row align="center" justify="space-between">
                <v-btn icon @click="clickCancel()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>CONFIGURAÇÕES</v-toolbar-title>
                <v-btn icon @click="clickSave">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-app-bar>
      </v-card-title>

      <v-row justify="center" no-gutters>
        <v-col cols="12" lg="6" md="8" sm="10" xl="4">
          <v-card-text class="mt-8">
            <v-form class="px-0" ref="form">
              <!-- parceiro -->
              <v-row
                v-bind="prop.row"
                v-if="getVar('idUsuario') != getVar('idPessoaParceiro')"
              >
                <v-col v-bind="prop.col" align="center">
                  <v-chip color="primary" outlined x-small>
                    <span class="primary--text text-overline">
                      {{ parceiro.pessoa.nomeAlt }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>

              <!-- feature -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="center">
                  <span
                    :class="
                      getFeature(idFeature).nomeAlt.length < 20
                        ? 'text-h4 font-weight-light'
                        : 'text-h5 font-weight-light'
                    "
                  >
                    {{ getFeature(idFeature).nomeAlt }}
                  </span>
                </v-col>
              </v-row>

              <!-- suspender -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="center">
                  <c-btn icon @click="clickSusp">
                    <v-icon :color="suspColor">
                      {{ suspIcon }}
                    </v-icon>
                  </c-btn>
                </v-col>
              </v-row>

              <!-- parceiro -->
              <v-row
                v-bind="prop.row"
                v-if="getConst('app').id == getVar('idPessoaParceiro')"
              >
                <v-col v-bind="prop.col">
                  <c-autocomplete
                    v-model="datasetTemp.main.detail.idPessoaParceiro"
                    clearable
                    :itemsIniciais="[]"
                    label="parceiro"
                    :path="`/pessoas/autocomplete/parceiro/0/0/0/`"
                    :rules="[]"
                  />
                </v-col>
              </v-row>

              <!-- bancos -->
              <v-row
                v-bind="prop.row"
                v-if="
                  getFeature(idFeature).options.hasBanco &&
                  !datasetTemp.main.detail.idPessoaFornecedor
                "
              >
                <v-col v-bind="prop.col">
                  <c-autocomplete
                    v-model="datasetTemp.main.detail.idBanco"
                    :arraySearch="bancos"
                    :itemsIniciais="bancos"
                    label="banco"
                    :rules="[]"
                    :searchLength="0"
                    @change="changeBanco(datasetTemp.main.detail.idBanco)"
                  />
                </v-col>
              </v-row>

              <!-- cartao -->
              <v-row
                v-bind="prop.row"
                v-if="
                  getFeature(idFeature).options.hasCartao &&
                  !datasetTemp.main.detail.idPessoaFornecedor
                "
              >
                <v-col v-bind="prop.col">
                  <c-autocomplete
                    v-model="datasetTemp.main.detail.idCartao"
                    :arraySearch="cartoes"
                    :itemsIniciais="cartoes"
                    label="cartao"
                    :rules="[]"
                    :searchLength="0"
                    @change="changeCartao(datasetTemp.main.detail.idCartao)"
                  />
                </v-col>
              </v-row>

              <!-- fornecedor -->
              <v-row
                v-bind="prop.row"
                v-if="
                  getFeature(idFeature).options.hasPessoafornecedor &&
                  !datasetTemp.main.detail.idBanco &&
                  !datasetTemp.main.detail.idCartao
                "
                class="mt-n5 mb-n7"
              >
                <v-col v-bind="prop.col">
                  <c-autocomplete
                    v-model="datasetTemp.main.detail.idPessoaFornecedor"
                    :arraySearch="fornecedores"
                    :itemsIniciais="fornecedores"
                    label="fornecedor"
                    :rules="[]"
                    :searchLength="0"
                    @change="
                      changeFornecedor(
                        datasetTemp.main.detail.idPessoaFornecedor
                      )
                    "
                  />
                </v-col>
              </v-row>

              <!-- clonar -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-select
                    v-model="clonar"
                    clearable
                    :help="{
                      title: 'CLONAR',
                      text: `• limpar seleção, para utilizar a configuração default,
• selecionar algum fornecedor, para utilizar a configuração deste fornecedor.`,
                      function: setUiBottomSheet,
                    }"
                    :items="clones"
                    label="clonar"
                    @change="changeClone()"
                  />
                </v-col>
              </v-row>

              <!-- tipo arquivo -->
              <v-row
                v-bind="prop.row"
                v-if="getFeature(idFeature).options.direction == 'in'"
                class="mt-n5 mb-n5"
              >
                <v-col v-bind="prop.col" cols="4">
                  <c-select
                    v-model="datasetTemp.main.detail.idTipoArquivo"
                    clearable
                    :items="tiposArquivos"
                    label="tipo arquivo"
                  />
                </v-col>
              </v-row>

              <!-- origem extrato -->
              <v-row
                v-bind="prop.row"
                v-if="getFeature(idFeature).options.direction == 'in'"
              >
                <v-col v-bind="prop.col">
                  <c-select
                    v-model="datasetTemp.main.detail.options.idExtratoOrigens"
                    clearable
                    :items="extratosOrigens"
                    label="origem extrato"
                    multiple
                    :properties="{
                      todos: true,
                    }"
                  />
                </v-col>
              </v-row>

              <v-container>
                <!-- expansion panel -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" align="end">
                    <c-btn icon @click="clickPanels(panelsOpen.length)">
                      <v-icon>
                        {{
                          !panelsOpen.length
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}
                      </v-icon>
                    </c-btn>
                  </v-col>
                </v-row>
              </v-container>

              <v-expansion-panels
                v-model="panelsOpen"
                class="ma-0 pa-0"
                multiple
              >
                <v-expansion-panel class="mb-1 ma-0 pa-0" expand>
                  <v-expansion-panel-header>
                    <v-container class="py-0">
                      <!-- colunas -->
                      <v-row v-bind="prop.row">
                        <v-col v-bind="prop.col">
                          <span class="text-h6 ml-n5 py-1">colunas</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0 mx-n5">
                    <!-- map list -->
                    <v-row v-bind="prop.row" v-if="options" class="mx-0">
                      <v-col v-bind="prop.col">
                        <c-map-list
                          v-model="options"
                          bindKey="colunaApp"
                          :hasDotMapList="hasDotMapList"
                          hasSelectAll
                          isDraggable
                          :item="{
                            valorFixo: null,
                            nomeColuna: null,
                            options: {},
                          }"
                          :items="optionsPadrao"
                        >
                          <!-- :value="options" -->
                          <template #title="{ item, itemsSelected, index }">
                            <v-row v-bind="prop.row">
                              <v-col v-bind="prop.col">
                                <span>
                                  {{ item.colunaApp || item.valorFixo || "" }}
                                </span>
                                <span
                                  v-if="
                                    itemsSelected[index] &&
                                    (item.valorFixo !== undefined ||
                                      itemsSelected.some(
                                        (itemSelected) =>
                                          itemSelected.colunaApp ==
                                          item.colunaApp
                                      ))
                                  "
                                >
                                  <v-icon class="mx-1 primary--text" small>
                                    {{ arrowIcon }}
                                  </v-icon>
                                  <span>
                                    {{ title(itemsSelected[index]) }}
                                  </span>
                                  <span
                                    v-if="item.nomeColuna && item.options"
                                    class="text-caption ml-1"
                                  >
                                    {{
                                      item.options.mask
                                        ? `(${item.options.mask})`
                                        : ""
                                    }}
                                  </span>
                                </span>
                              </v-col>
                            </v-row>
                          </template>
                          <template #content="{ item }">
                            <div
                              v-if="
                                datasetTemp.main.detail.idTipoArquivo &&
                                getTipo_item(
                                  datasetTemp.main.detail.idTipoArquivo
                                ).options.fields.length
                              "
                            >
                              <v-row v-bind="prop.row" class="mt-n5">
                                <!-- nome coluna -->
                                <v-col
                                  v-bind="prop.col"
                                  v-if="
                                    getTipo_item(
                                      datasetTemp.main.detail.idTipoArquivo
                                    ).options.fields.includes('nomeColuna')
                                  "
                                >
                                  <c-text-field
                                    v-model="item.nomeColuna"
                                    clearable
                                    :max="40"
                                    placeholder="nome coluna"
                                    @click.stop
                                  />
                                </v-col>
                                <!-- nome tag -->
                                <v-col
                                  v-bind="prop.col"
                                  v-if="
                                    getTipo_item(
                                      datasetTemp.main.detail.idTipoArquivo
                                    ).options.fields.includes('nomeTag')
                                  "
                                >
                                  <c-text-field
                                    v-model="item.nomeTag"
                                    clearable
                                    :max="40"
                                    placeholder="nome tag"
                                    @click.stop
                                  />
                                </v-col>
                                <!-- tipo registro -->
                                <v-col
                                  v-bind="prop.col"
                                  v-if="
                                    getTipo_item(
                                      datasetTemp.main.detail.idTipoArquivo
                                    ).options.fields.includes('tipoRegistro')
                                  "
                                  cols="4"
                                >
                                  <c-text-field
                                    v-model="item.tipoRegistro"
                                    clearable
                                    :max="10"
                                    placeholder="tipo registro"
                                    @click.stop
                                  />
                                </v-col>
                                <!-- ordem-->
                                <v-col
                                  v-bind="prop.col"
                                  v-if="
                                    getTipo_item(
                                      datasetTemp.main.detail.idTipoArquivo
                                    ).options.fields.includes('ordem')
                                  "
                                  cols="4"
                                >
                                  <c-text-field
                                    v-model="item.ordem"
                                    clearable
                                    :max="4"
                                    placeholder="ordem"
                                    @click.stop
                                  />
                                </v-col>
                                <!-- posicao-->
                                <v-col
                                  v-bind="prop.col"
                                  v-if="
                                    getTipo_item(
                                      datasetTemp.main.detail.idTipoArquivo
                                    ).options.fields.includes('posicao')
                                  "
                                  cols="4"
                                >
                                  <c-text-field
                                    v-model="item.posicao"
                                    clearable
                                    :max="3"
                                    placeholder="posição"
                                    @click.stop
                                  />
                                </v-col>
                                <!-- tamanho -->
                                <v-col
                                  v-bind="prop.col"
                                  v-if="
                                    getTipo_item(
                                      datasetTemp.main.detail.idTipoArquivo
                                    ).options.fields.includes('tamanho')
                                  "
                                  cols="4"
                                >
                                  <c-text-field
                                    v-model="item.tamanho"
                                    clearable
                                    :max="3"
                                    placeholder="tamanho"
                                    @click.stop
                                  />
                                </v-col>
                              </v-row>
                            </div>
                            <div v-else-if="isCheque">
                              <v-row v-bind="prop.row" class="mt-n5">
                                <!-- nome coluna -->
                                <v-col v-bind="prop.col">
                                  <c-text-field
                                    v-model="item.nomeColuna"
                                    clearable
                                    :max="40"
                                    placeholder="nome coluna"
                                    @click.stop
                                  />
                                </v-col>
                              </v-row>
                              <v-row v-bind="prop.row" class="mt-n5">
                                <!-- linha-->
                                <v-col v-bind="prop.col" cols="4">
                                  <c-text-field
                                    v-model="item.linha"
                                    clearable
                                    :max="4"
                                    placeholder="linha"
                                    @click.stop
                                  />
                                </v-col>
                                <!-- coluna-->
                                <v-col v-bind="prop.col" cols="4">
                                  <c-text-field
                                    v-model="item.coluna"
                                    clearable
                                    :max="3"
                                    placeholder="coluna"
                                    @click.stop
                                  />
                                </v-col>
                                <!-- tamanho -->
                                <v-col v-bind="prop.col" cols="4">
                                  <c-text-field
                                    v-model="item.tamanho"
                                    clearable
                                    :max="2"
                                    placeholder="tamanho"
                                    @click.stop
                                  />
                                </v-col>
                              </v-row>
                            </div>
                            <div v-else>
                              <!-- valor fixo -->
                              <v-row
                                v-bind="prop.row"
                                v-if="item.valorFixo !== undefined"
                                class="mt-n5"
                              >
                                <v-col v-bind="prop.col">
                                  <c-text-field
                                    v-model="item.valorFixo"
                                    clearable
                                    :max="50"
                                    placeholder="valor fixo"
                                    @click.stop
                                  />
                                </v-col>
                              </v-row>

                              <!-- nome coluna -->
                              <v-row v-bind="prop.row" class="mt-n5">
                                <v-col v-bind="prop.col">
                                  <c-text-field
                                    v-model="item.nomeColuna"
                                    clearable
                                    :max="50"
                                    placeholder="nome da coluna"
                                    @click:clear.stop="changeItemTo(item)"
                                    @click.stop
                                    @keydown="changeItemTo(item)"
                                  />
                                </v-col>
                              </v-row>

                              <!-- mascara -->
                              <v-row
                                v-bind="prop.row"
                                v-if="
                                  item.nomeColuna &&
                                  item.valorFixo === undefined
                                "
                                class="mt-n5"
                              >
                                <v-col v-bind="prop.col">
                                  <c-text-field
                                    v-if="item.options"
                                    v-model="item.options.mask"
                                    clearable
                                    :max="50"
                                    placeholder="máscara"
                                    @click.stop
                                  />
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </c-map-list>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel
                  v-if="getConst('app').id == getVar('idPessoaParceiro')"
                  class="mb-1 ma-0"
                  expand
                >
                  <v-expansion-panel-header>
                    <v-container class="py-0">
                      <!-- json -->
                      <v-row v-bind="prop.row">
                        <v-col v-bind="prop.col">
                          <span class="text-h6 ml-n5 py-1">json</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- colunas formatadas -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <pre class="my-4">
                          {{ options }}
                        </pre>
                      </v-col>
                    </v-row>

                    <!-- info -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <c-btn
                          :help="{
                            title: 'JSON',
                            text: {
                              actions,
                              text,
                              types,
                            },
                            height: 300,
                            function: setUiBottomSheet,
                          }"
                          icon
                          isHelp
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </c-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel
                  v-if="getFeature(this.idFeature).options.direction != 'in'"
                  class="mb-1 ma-0"
                  expand
                >
                  <v-expansion-panel-header>
                    <v-container class="py-0">
                      <!-- json -->
                      <v-row v-bind="prop.row">
                        <v-col v-bind="prop.col">
                          <span class="text-h6 ml-n5 py-1">
                            envios automáticos
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- info -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <c-btn
                          :help="{
                            title: 'VARIÁVEIS',
                            text: `As variáveis abaixo (termos entre *) podem ser utilizadas nos campos 'assunto', 'corpo' e 'nome arquivo' para substituir os seus respectivos conteúdos quando o relatório for gerado.
• *parceiro*:  é substituida pelo nome do parceiro,
• *fornecedor*: é substituida pelo nome do fornecedor
• máscara de data (*yyyyMMdd*, *MMddyyyy*, *ddMMyyyy*, *dd/MM/yyyy*, ...): é substituida pela data de emissão do relatório no formato escolhido
• *dataInicio*: é substituida pela data início do período
• *dataFim*: é substituida pela data fim do período`,
                            height: 500,
                            function: setUiBottomSheet,
                          }"
                          icon
                          isHelp
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </c-btn>
                      </v-col>
                    </v-row>

                    <c-expansion-panels
                      v-model="datasetTemp.main.envios"
                      flat
                      :hasDot="hasDot"
                      :pageModeView="false"
                      :params="params"
                      id="enviosAutomaticos"
                    >
                      <template #header="{ dataset: envio, index: i }">
                        <!-- condicao envio -->
                        <v-row v-bind="prop.row">
                          <v-col v-bind="prop.col">
                            {{ changeCondicaoEnvio(i) }}
                          </v-col>
                        </v-row>

                        <!-- tipo envio -->
                        <v-row v-bind="prop.row" v-if="envio.idTipoEnvio">
                          <v-col v-bind="prop.col">
                            {{ getTipo_item(envio.idTipoEnvio).nomeAlt }}
                          </v-col>
                        </v-row>
                      </template>
                      <template #content="{ dataset: envio, index: i }">
                        <v-container class="pa-0 ma-0">
                          <!-- deletar -->
                          <v-row v-bind="prop.row">
                            <v-col v-bind="prop.col">
                              <c-btn
                                icon
                                :ripple="false"
                                @click="deletar(envio)"
                              >
                                <v-icon color="red">mdi-minus-circle</v-icon>
                              </c-btn>
                            </v-col>
                          </v-row>

                          <!-- condicoes envio -->
                          <v-row v-bind="prop.row">
                            <v-col v-bind="prop.col">
                              <c-checkbox
                                v-model="envio.hasCondicaoEnvio"
                                indeterminate
                                :label="changeCondicaoEnvio(i)"
                              />
                              <!-- @click="changeCondicaoEnvio(i)" -->
                            </v-col>
                          </v-row>

                          <!-- tipo envio -->
                          <v-row v-bind="prop.row">
                            <v-col v-bind="prop.col" cols="4">
                              <c-select
                                v-model="envio.idTipoEnvio"
                                :items="envios"
                                label="tipo envio"
                                required
                                @change="changeTipoEnvio(i)"
                              />
                            </v-col>
                          </v-row>

                          <!-- endereco -->
                          <v-row v-bind="prop.row" v-if="envio.idTipoEnvio">
                            <v-col v-bind="prop.col">
                              <c-textarea
                                v-model="envio.endereco"
                                class="text-body-1"
                                clearable
                                :label="changeTipoEnvio(i)"
                                :max="500"
                                :maxRows="3"
                                :required="true"
                                :rows="3"
                                type="text"
                              />
                            </v-col>
                          </v-row>

                          <!-- assunto -->
                          <v-row
                            v-bind="prop.row"
                            v-if="
                              getTipo_item('emailTrabalho').id ==
                              envio.idTipoEnvio
                            "
                          >
                            <v-col v-bind="prop.col">
                              <c-textarea
                                v-model="envio.assunto"
                                class="text-body-1"
                                clearable
                                label="assunto"
                                :max="50"
                                :maxRows="3"
                                :required="true"
                                :rows="3"
                                type="text"
                              />
                            </v-col>
                          </v-row>

                          <!-- corpo -->
                          <v-row
                            v-bind="prop.row"
                            v-if="
                              getTipo_item('emailTrabalho').id ==
                              envio.idTipoEnvio
                            "
                          >
                            <v-col v-bind="prop.col">
                              <c-textarea
                                v-model.trim="envio.corpo"
                                class="text-body-1"
                                label="corpo"
                                :max="500"
                                :maxRows="10"
                                :rows="3"
                                type="text"
                              />
                            </v-col>
                          </v-row>

                          <!-- usuario -->
                          <v-row
                            v-bind="prop.row"
                            v-if="
                              getTipo_item('urlTrabalho').id ==
                              envio.idTipoEnvio
                            "
                          >
                            <v-col v-bind="prop.col">
                              <c-textarea
                                v-model="envio.usuario"
                                class="text-body-1"
                                clearable
                                label="usuário"
                                :max="80"
                                :maxRows="3"
                                :rows="3"
                                type="text"
                              />
                            </v-col>
                          </v-row>

                          <!-- senha -->
                          <v-row
                            v-bind="prop.row"
                            v-if="
                              getTipo_item('urlTrabalho').id ==
                              envio.idTipoEnvio
                            "
                          >
                            <v-col v-bind="prop.col">
                              <c-textarea
                                v-model="envio.senha"
                                class="text-body-1"
                                clearable
                                label="senha"
                                :max="225"
                                :maxRows="3"
                                :rows="3"
                                type="text"
                              />
                            </v-col>
                          </v-row>

                          <!-- chave -->
                          <v-row
                            v-bind="prop.row"
                            v-if="
                              getTipo_item('urlTrabalho').id ==
                              envio.idTipoEnvio
                            "
                          >
                            <v-col v-bind="prop.col">
                              <c-textarea
                                v-model="envio.chave"
                                class="text-body-1"
                                clearable
                                label="chave"
                                :max="225"
                                :maxRows="3"
                                :rows="3"
                                type="text"
                              />
                            </v-col>
                          </v-row>

                          <!-- tipo arquivo -->
                          <v-row v-bind="prop.row">
                            <v-col v-bind="prop.col" cols="4">
                              <c-select
                                v-model="envio.idTipoArquivo"
                                :items="tiposArquivos"
                                label="tipo arquivo"
                              />
                            </v-col>
                          </v-row>

                          <!-- nome arquivo -->
                          <v-row v-bind="prop.row">
                            <v-col v-bind="prop.col">
                              <c-text-field
                                v-model="envio.nomeArquivo"
                                clearable
                                label="nome arquivo"
                                :max="40"
                                required
                              />
                            </v-col>
                          </v-row>

                          <v-row v-bind="prop.row">
                            <!-- frequencia -->
                            <v-col v-bind="prop.col" cols="4">
                              <c-select
                                v-model="envio.idFrequencia"
                                :items="frequencia"
                                label="a cada"
                              />
                            </v-col>
                            <!-- data inicio -->
                            <v-col v-bind="prop.col" cols="8">
                              <c-datetime-picker
                                v-model="envio.dataInicio"
                                classe="text-body-1 pr-1 ml-2"
                                clearable
                                date
                                :help="{
                                  title: 'DATA INÍCIO',
                                  text: `Informar a data e hora que relatório deverá ser gerado.
O relatório será gerado na frequência  e horário especificados.`,
                                  function: setUiBottomSheet,
                                }"
                                isWeek
                                label="data início"
                                time
                                show-week
                              />
                            </v-col>
                          </v-row>

                          <!-- divider -->
                          <v-divider
                            v-bind="prop.divider"
                            v-if="i != datasetTemp.main.envios.length - 1"
                          ></v-divider>
                        </v-container>
                      </template>
                    </c-expansion-panels>

                    <!-- divider -->
                    <v-divider v-bind="prop.bind"></v-divider>

                    <!-- adicionar -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <v-btn
                          :disabled="disabledIncluir"
                          icon
                          @click="incluir()"
                        >
                          <v-icon color="green">mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>

      <!-- /// BOTTOMSHEET -->
      <c-bottom-sheet :bottomSheet="bottomSheet" />

      <!-- /// SNACKBAR -->
      <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinData from "@/mixins/mixinData";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "reportDialogConfiguracoes",

  /// MIXINS
  mixins: [
    mixinBottomSheet,
    mixinData,
    mixinPageDetail,
    mixinPageList,
    mixinSnackbar,
  ],

  /// PROPS
  props: {
    dataset: { type: Object, required: true },
    idFeature: { type: [Number, String], required: true },
    idPagina: { type: [Number, String], required: true },
    params: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {
      actions: [null, null, null, null, this.clickSaibaMais],
      clonar: null,
      clones: [],
      datasetTemp: JSON.parse(JSON.stringify(this.dataset)),
      fornecedores: this.params.fornecedores
        ? JSON.parse(JSON.stringify(this.params.fornecedores))
        : [],
      options: [],
      optionsPadrao: [],
      outputJson: [],
      panelsOpen: [0, 1, 2],
      text: [
        "JSON (JavaScript Object Notation) é uma formatação utilizada para estruturar dados em formato de texto e transmiti-los de um sistema para outro.",
        'Os dados contidos em um arquivo no formato JSON devem ser estruturados por meio de uma coleção de pares "chave-valor" ou  ser uma lista ordenada de valores.',
        "• chave: corresponde ao identificador do conteúdo;",
        "• valor: representa o conteúdo correspondente.",
        "saiba mais",
      ],
      types: ["text", "text", "text", "text", "a"],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getBancos: "bancos/getBancos",
      getCartoes: "cartoes/getCartoes",
      getFeature: "paginas/getFeature",
      getTipo_itens: "tipos/getTipo_itens",
    }),

    arrowIcon() {
      return this.getFeature(this.idFeature).options.direction == "in"
        ? "mdi-arrow-left"
        : "mdi-arrow-right";
    },

    bancos() {
      return this.getBancos().map((banco) => {
        return { value: banco.value, text: banco.text };
      });
    },

    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },

    cartoes() {
      return this.getCartoes();
    },

    disabledIncluir() {
      return (
        this.datasetTemp.main.envios.filter((envio) => !envio.del).length == 3
      );
    },

    envios() {
      const tipoItemEmailTrabalho = this.getTipo_item("emailTrabalho");
      const tipoItemUrlTrabalho = this.getTipo_item("urlTrabalho");

      const tiposEnvios = [
        {
          value: tipoItemEmailTrabalho.id,
          text: tipoItemEmailTrabalho.options.tipoEnvio,
        },
        {
          value: tipoItemUrlTrabalho.id,
          text: tipoItemUrlTrabalho.options.tipoEnvio,
        },
      ];

      return tiposEnvios;
    },

    extratosOrigens() {
      return this.getTipo_itens(this.getTipo("extratoOrigem").id);
    },

    frequencia() {
      return this.getTipo_itens(this.getTipo("unidadeTempo").id).filter(
        (frequencia) =>
          this.getTipo_item(frequencia.value).options.isRecorrencia
      );
    },

    isCheque() {
      return this.getFeature("adapterDocumentoCheque").id == this.idFeature;
    },

    parceiro() {
      return this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      );
    },

    suspColor() {
      return this.datasetTemp.main.detail.isSuspenso ? "red" : "green";
    },

    suspIcon() {
      return this.datasetTemp.main.detail.isSuspenso
        ? "mdi-lock-outline"
        : "mdi-lock-open-outline";
    },

    tiposArquivos() {
      return this.getTipo_itens(this.getTipo("tipoArquivo").id)
        .filter((tipoArquivo) => tipoArquivo.isAplicar)
        .map((tipoArquivo) => {
          const arquivo = this.getTipo_item(tipoArquivo.value);
          const icons = [
            {
              icon: arquivo.options.icon,
            },
          ];

          return {
            value: tipoArquivo.value,
            text: arquivo.nomeAlt,
            icons,
            colsIcon: this.breakpointName == "xs" ? 3 : 2,
            colsText: this.breakpointName == "xs" ? 9 : 10,
            classText: this.breakpointName == "xs" ? "ml-n2" : "ml-n4",
          };
        });
    },
  },

  /// METHODS
  methods: {
    async changeBanco(idBanco) {
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      let params = {
        idPagina: this.idPagina,
        idFeature: this.idFeature,
        idPessoaParceiro,
        idBanco,
      };

      params = JSON.stringify(params);
      const url = `${
        this.getConst("app").baseUrlBackend
      }/paginas_configuracoes/${params}`;
      const res = await axios.get(url);

      this.datasetTemp = res.data;
      this.getClone();
      this.clonar = null;
      this.reloadContent();
    },

    async changeCartao(idCartao) {
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      let params = {
        idPagina: this.idPagina,
        idFeature: this.idFeature,
        idPessoaParceiro,
        idCartao,
      };

      params = JSON.stringify(params);
      const url = `${
        this.getConst("app").baseUrlBackend
      }/paginas_configuracoes/${params}`;
      const res = await axios.get(url);

      this.datasetTemp = res.data;
      this.getClone();
      this.clonar = null;
      this.reloadContent();
    },

    changeCondicaoEnvio(indice) {
      return this.datasetTemp.main.envios[indice].hasCondicaoEnvio == null
        ? "Enviar sempre"
        : this.datasetTemp.main.envios[indice].hasCondicaoEnvio
        ? "Enviar só quando tem conteúdo"
        : "Enviar só quando não tem conteúdo";
    },

    changeClone() {
      this.datasetTemp.main.detail.options.config = this.clonar
        ? this.clones.find((clone) => clone.value == this.clonar).options.config
        : JSON.parse(JSON.stringify(this.dataset.main.detail.options.config));

      console.log(this.datasetTemp.main.detail.options);

      this.reloadContent();
    },

    async changeFornecedor(idPessoaFornecedor) {
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      let params = {
        idPagina: this.idPagina,
        idFeature: this.idFeature,
        idPessoaParceiro,
        idPessoaFornecedor,
      };

      this.params.idPessoaFornecedor = idPessoaFornecedor;

      params = JSON.stringify(params);
      const url = `${
        this.getConst("app").baseUrlBackend
      }/paginas_configuracoes/${params}`;
      const res = await axios.get(url);

      this.datasetTemp = res.data;
      if (!res.data.main.detail.options.config) {
        this.datasetTemp.main.detail.options.config = this.optionsPadrao;
      }
      this.getClone();
      this.clonar = null;
      this.reloadContent();
    },

    changeItemTo(item) {
      if (!item.nomeColuna && item.options?.mask) {
        item.options.mask = "";
      }
    },

    changeTipoEnvio(indice) {
      return this.datasetTemp.main.envios[indice].idTipoEnvio ==
        this.getTipo_item("emailTrabalho").id
        ? "emails destino"
        : "endereço";
    },

    clickCancel() {
      this.$emit("close");
    },

    clickFaq() {
      this.$router.push("/faq#pergunta7");
    },

    clickPanels(isPanelOpen) {
      this.panelsOpen = isPanelOpen ? [] : [0, 1, 2];
    },

    clickSaibaMais() {
      //? rockcontent aqui mesmo?
      window.open("https://rockcontent.com/br/blog/json", "_blank");
    },

    async clickSave() {
      this.dataset.main = JSON.parse(JSON.stringify(this.datasetTemp.main));

      this.dataset.main.detail.options.config = this.options.filter(
        (option) => !option.del
      );

      this.dataset.metadados.idEvento = this.getTipo_item(
        this.dataset.main.detail.idPessoaParceiro
          ? "eventoAlterado"
          : "eventoIncluido"
      ).id;

      this.dataset.main.detail.idPessoaParceiro =
        this.dataset.main.detail.idPessoaParceiro ||
        this.getVar("idPessoaParceiro");

      this.setVar({ overlay: true });

      const url = `${
        this.getConst("app").baseUrlBackend
      }/paginas_configuracoes`;

      try {
        await axios.post(url, this.dataset);

        if (!this.dataset.main.detail.options.config) {
          this.params.idPessoaFornecedor = null;
        }
        this.setVar({ overlay: false });
        this.$emit("save");
      } catch (err) {
        this.setVar({ overlay: false });
        this.setSnackbar(true, "ERRO", err.response.data, "error");
      }
    },

    clickSusp() {
      this.datasetTemp.main.detail.isSuspenso =
        !this.datasetTemp.main.detail.isSuspenso;
    },

    deletar(envio) {
      envio.del = true;
      this.$forceUpdate();
    },

    formatOptions(options) {
      this.options = options || this.datasetTemp.main.detail.options.config;
    },

    async getClone() {
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      let params = {
        idPagina: this.idPagina,
        idFeature: this.idFeature,
        idPessoaParceiro,
        idPessoaFornecedor: this.datasetTemp.main.detail.idPessoaFornecedor,
        idBanco: this.datasetTemp.main.detail.idBanco,
        idCartao: this.datasetTemp.main.detail.idCartao,
      };

      params = JSON.stringify(params);

      const url = `${
        this.getConst("app").baseUrlBackend
      }/paginas_configuracoes/clones/${params}`;
      const res = await axios.get(url);

      this.clones = res.data;
      this.$forceUpdate();
    },

    async getFornecedoresLayout() {
      this.idPessoaParceiro = this.getVar("idPessoaParceiro");
      const url = `${
        this.getConst("app").baseUrlBackend
      }/fornecedoresClientes/fornecedoresLayout/${this.idPessoaParceiro}`;
      const res = await axios.get(url);

      this.fornecedores = res.data;
      this.params.fornecedores = this.fornecedores;
    },

    async getPadrao() {
      let params = {
        idPagina: this.idPagina,
        idFeature: this.idFeature,
      };

      params = JSON.stringify(params);

      const url = `${
        this.getConst("app").baseUrlBackend
      }/paginas_configuracoes/padrao/${params}`;
      const res = await axios.get(url);

      this.optionsPadrao = res.data.options.config;
      this.$forceUpdate();
    },

    hasDot(envio) {
      return (
        !envio.idTipoEnvio ||
        !envio.endereco ||
        !envio.usuario ||
        !envio.senha ||
        !envio.chave ||
        !envio.idTipoArquivo ||
        !envio.nomeArquivo ||
        !envio.idFrequencia ||
        !envio.dataInicio
      );
    },

    hasDotMapList(item) {
      return !item.nomeColuna;
    },

    incluir() {
      const envio = {
        id: null,
        idPai2: this.datasetTemp.main.detail.id,
        idTipoEnvio: null,
        hasCondicaoEnvio: null,
        endereco: null,
        usuario: null,
        senha: null,
        chave: null,
        assunto: null,
        corpo: null,
        idTipoArquivo: null,
        nomeArquivo: null,
        idFrequencia: null,
        dataInicio: null,
        del: false,
      };

      this.datasetTemp.main.envios.push(envio);

      setTimeout(() => {
        const params = {
          duration: 1000,
          offset: 0,
          easing: "linear",
          container: `.v-dialog--fullscreen`,
        };
        const element = document.getElementById(
          `panel${this.datasetTemp.main.envios.length - 1}`
        );
        this.$vuetify.goTo(element, params);
      }, 100);
    },

    reloadContent() {
      this.formatOptions();
      this.$forceUpdate();
    },

    title(item) {
      const title = this.getTipo_item(
        this.datasetTemp.main.detail.idTipoArquivo
      )
        ?.options?.fields?.filter((field) => item[field])
        .map((field) => {
          return item[field];
        });

      return title
        ? title.join(" - ")
        : item.nomeColuna || item.valorFixo || "";
    },
  },

  /// MOUNTED
  mounted() {
    this.reloadContent();
    this.getFornecedoresLayout();
    this.getClone();
    this.getPadrao();
  },
};
</script>