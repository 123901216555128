import { render, staticRenderFns } from "./CBaseList.vue?vue&type=template&id=92857fa4&"
import script from "./CBaseList.vue?vue&type=script&lang=js&"
export * from "./CBaseList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBadge,VBtn,VChip,VCol,VContainer,VDivider,VIcon,VImg,VList,VListItem,VListItemGroup,VListItemTitle,VMenu,VRow,VSlideGroup,VSlideItem,VSubheader})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Scroll,Touch})
