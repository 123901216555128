<!-- COD: LYF 30/06/2022 CBancoEdit -->
<!-- *VER: JER 04/07/2022 CBancoEdit -->
<!-- /11/ -->
<!-- #arq:CBancoEdit #complex:11 #ver:0.3 #data:20220630 #fase:0 #status:1 #dev:LYF -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais bandeira -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-menu
          v-model="dataset.main.detail.idPais"
          classe="ml-n4"
          :items="paises"
        >
          <template #btn="{ itemSelected }">
            <v-img
              v-if="dataset.main.detail.idPais"
              alt="imagem"
              class="mr-2"
              contain
              :src="getLocalidade(dataset.main.detail.idPais).endereco"
              width="25"
            />
            <v-icon v-else large color="grey">mdi-earth</v-icon>
            <span v-if="itemSelected" class="text=body-2">
              {{ itemSelected.text }}
            </span>
          </template>
          <template #item="{ item }">
            <v-img
              alt="imagem"
              contain
              :src="getLocalidade(item.value).endereco"
              width="25"
            />
            <v-list-item-title class="ml-2 text-body-2">
              {{ item.text }}
            </v-list-item-title>
          </template>
        </c-menu>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nomeAlt"
          :max="25"
          required
        />
      </v-col>
    </v-row>

    <!-- swift (prefixo) -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="5">
        <c-text-field
          v-model="dataset.main.detail.swiftPrefixo"
          :help="{
            title: 'SWIFT',
            text: `Society for Worldwide Interbank Financial Telecommunication
BIC (Bank Identifier Code)

AAAABBCCDDD
A: bank
B: country
C: location (*)
D: branch (*) (optional)

(*) Campo informado na conta bancária da pessoa`,
            function: context.setUiBottomSheet,
          }"
          label="swift (prefixo)"
          :max="6"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- codigo -->
      <v-col v-bind="prop.col" cols="5">
        <c-text-field
          v-model="dataset.main.detail.codigo"
          label="número banco"
          :max="3"
          required
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CBancoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidades: "localidades/getLocalidades",
      getTipo_item: "tipos/getTipo_item",
    }),

    paises() {
      return this.paisesAtivos;
    },
  },
};
</script>