var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.load)?_c('c-base-detail',{attrs:{"funcs":_vm.funcs,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"params":_vm.params},on:{"goto":function($event){return _vm.goto($event)}},scopedSlots:_vm._u([{key:"images",fn:function(ref){
var dataset = ref.dataset;
var pageModeView = ref.pageModeView;
return [_c('c-base-detail-images',{attrs:{"avatar":dataset.main.detail.idTipo == _vm.getTipo_item('pessoaFisica').id,"dataset":dataset,"pageModeView":pageModeView}})]}},{key:"default",fn:function(ref){
var context = ref.context;
var page = ref.page;
var pageModeView = ref.pageModeView;
return [(_vm.dataset.main.detail.idTipo == 0)?_c('c-base-detail-insert',{attrs:{"dataset":_vm.dataset,"idTipo":_vm.getTipo(("tipo" + (page.nome.firstLetterToUpperCase()))).id}}):_c('div',[(_vm.dataset.main.detail.id && context.showEstado)?_c('c-base-dialog-estado',{attrs:{"context":context,"datasetTemp":JSON.parse(JSON.stringify(context.dataset)),"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"show":context.showEstado,"value":JSON.parse(JSON.stringify(context.dataset))},on:{"alterado":function($event){context.showEstado = false},"close":function($event){context.showEstado = false}}}):_vm._e(),(pageModeView)?_c('c-pessoa-view',{attrs:{"context":context,"dataset":_vm.dataset}}):_c('c-pessoa-edit',{attrs:{"context":context,"dataset":_vm.dataset}})],1),(_vm.dataset.main.detail.id && _vm.showSenha)?_c('c-base-dialog-senha',{attrs:{"context":context,"idPessoa":_vm.dataset.main.detail.id,"show":_vm.showSenha},on:{"alterado":_vm.senhaAlterada,"close":_vm.closeSenha}}):_vm._e()]}}],null,false,2245314925),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }