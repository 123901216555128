<!-- COD: FDS 08/08/2022 CConviteDialogConvite -->
<!-- *VER: FDS 10/08/2022 CConviteDialogConvite -->
<!-- /11/ -->

<template>
  <v-dialog v-model="value" max-width="380px" persistent scrollable>
    <v-card class="mx-auto" max-width="380">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <c-btn icon @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </c-btn>
          <v-spacer />
          <v-toolbar-title>CONVITE</v-toolbar-title>
          <v-spacer />
          <c-btn icon @click="print()">
            <v-icon>mdi-printer</v-icon>
          </c-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-layout justify-center>
          <canvas height="100%" id="canvas" width="100%" />
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/// IMPORT
import qrCode from "qrcode";

export default {
  /// NAME
  name: "CConviteDialogConvite",

  /// PROPS
  props: {
    code: { type: String, default: "" },
    value: { type: Boolean, required: true },
  },

  data() {
    const getConst = this.$store.getters["constantes/getConst"];

    return {
      frases: [
        `Olá, você já conhece o ${getConst("app").nome}?`,
        `O ${
          getConst("app").nome
        } é um aplicativo de compras para aproximar os moradores do bairro ao comércio local.`,
        "Aponte a camera do seu celular para o código abaixo 👇",
      ],
    };
  },

  /// WATCH
  watch: {
    code() {
      if (this.code.length) {
        setTimeout(() => {
          this.qrcode();
        }, 10);
      }
    },
  },

  /// METHODS
  methods: {
    print() {
      const canvas = document.querySelector("#canvas");
      const win = window.open();
      self.focus();
      win.document.open();
      win.document.write("<html><body>");
      win.document.write("<br/>");
      this.frases.forEach((frase) => win.document.write(`<p>${frase}</p>`));
      win.document.write("<br/>");
      win.document.write("<img src='" + canvas.toDataURL() + "' />");
      win.document.write("<br/>");
      win.document.write("</body></html>");
      win.document.close();
      setTimeout(() => {
        win.print();
        win.close();
      }, 10);
    },

    qrcode() {
      const canvas = document.querySelector("#canvas");
      qrCode.toCanvas(canvas, this.code);
      canvas.style.width = "300px";
      canvas.style.height = "300px";
    },
  },
};
</script>