var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"380px","persistent":"","scrollable":""},model:{value:(_vm.idPagina),callback:function ($$v) {_vm.idPagina=$$v},expression:"idPagina"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"380"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-btn',{staticClass:"ml-n3",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',_vm._b({attrs:{"align":"center","cols":"10"}},'v-col',_vm.prop.col,false),[_c('v-toolbar-title',[_vm._v("INCLUIR PESSOA")])],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-form',{ref:"form",staticClass:"px-0"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-menu',{attrs:{"classe":"ml-n2","disabled":_vm.jaVerificou,"items":_vm.paisesAtivos},scopedSlots:_vm._u([{key:"btn",fn:function(ref){
var itemSelected = ref.itemSelected;
return [_c('v-img',{staticClass:"mr-2",attrs:{"alt":"imagem","contain":"","src":itemSelected.image,"width":"25"}}),(itemSelected)?_c('span',{staticClass:"text=body-2"},[_vm._v(" "+_vm._s(itemSelected.text)+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"alt":"imagem","contain":"","src":item.image,"width":"25"}}),_c('v-list-item-title',{staticClass:"ml-2 text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.documento.idPais),callback:function ($$v) {_vm.$set(_vm.documento, "idPais", $$v)},expression:"documento.idPais"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"disabled":_vm.jaVerificou,"items":_vm.tiposPessoa},on:{"change":_vm.changeTipoPessoa},model:{value:(_vm.idTipo),callback:function ($$v) {_vm.idTipo=$$v},expression:"idTipo"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"disabled":_vm.jaVerificou,"dynamicsRules":_vm.rules.documento,"help":{
                title: 'NÚMERO DOCUMENTO',
                text: _vm.documentoInfo,
                function: _vm.setUiBottomSheet,
              },"label":_vm.labelDocumentoFederal(false),"max":25,"options":{
                mask: _vm.mascaraDocumento(false),
                options: _vm.optionsDocumento(false),
              },"placeholder":_vm.placeholderDocumento(false),"required":""},model:{value:(_vm.documento.federal),callback:function ($$v) {_vm.$set(_vm.documento, "federal", $$v)},expression:"documento.federal"}})],1)],1),(_vm.idTipo == _vm.getTipo_item('pessoaFisica').id)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"date":"","disabled":_vm.jaVerificou,"label":"data nascimento","maxValue":_vm.maxValueDataNascimento,"required":""},model:{value:(_vm.dataNascimento),callback:function ($$v) {_vm.dataNascimento=$$v},expression:"dataNascimento"}})],1)],1):_vm._e(),(!_vm.jaVerificou)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"classe":"text-body-1","color":"primary"},on:{"click":_vm.clickBuscar}},[_vm._v(" buscar ")])],1)],1):_vm._e(),(_vm.jaVerificou && _vm.dataset.nome)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('a',{staticClass:"text-h6",on:{"click":_vm.clickPessoa}},[_vm._v(" "+_vm._s(_vm.dataset.nome)+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"classe":"text-body-1","color":"primary"},on:{"click":_vm.clickSelecionar}},[_vm._v(" selecionar ")])],1)],1)],1):_vm._e(),(_vm.jaVerificou && !_vm.dataset.nome)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('v-chip-group',{staticStyle:{"width":"fit-content"},attrs:{"mandatory":""},model:{value:(_vm.tipoCadastro),callback:function ($$v) {_vm.tipoCadastro=$$v},expression:"tipoCadastro"}},_vm._l((_vm.tiposCadastro.filter(
                    function (tipoCadastro) { return _vm.idTipoConvite || tipoCadastro.text == 'cadastrar'; }
                  )),function(tipo,i){return _c('v-chip',{key:i,staticClass:"primary text-overline",class:_vm.tipoCadastro != tipo.value
                      ? 'primary--text'
                      : 'white--text',attrs:{"outlined":_vm.tipoCadastro != tipo.value,"value":tipo.value},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.translate(tipo.text))+" ")])}),1)],1)],1),(_vm.tipoCadastro)?_c('div',[(_vm.idTipo == _vm.getTipo_item('pessoaJuridica').id)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nome fantasia","max":20,"required":""},model:{value:(_vm.convite.nomeFantasia),callback:function ($$v) {_vm.$set(_vm.convite, "nomeFantasia", $$v)},expression:"convite.nomeFantasia"}})],1)],1):_vm._e(),(_vm.idTipo == _vm.getTipo_item('pessoaJuridica').id)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
                    title: 'NÚMERO DOCUMENTO',
                    text: _vm.documentoInfo,
                    function: _vm.setUiBottomSheet,
                  },"label":_vm.labelDocumentoFederal(true),"max":25,"options":{
                    mask: _vm.mascaraDocumento(true),
                    options: _vm.optionsDocumento(true),
                  },"placeholder":_vm.placeholderDocumento(true),"required":""},model:{value:(_vm.convite.documentoFederal),callback:function ($$v) {_vm.$set(_vm.convite, "documentoFederal", $$v)},expression:"convite.documentoFederal"}})],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nome e sobrenome","max":30,"required":""},model:{value:(_vm.convite.nomeCompleto),callback:function ($$v) {_vm.$set(_vm.convite, "nomeCompleto", $$v)},expression:"convite.nomeCompleto"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"e-mail","max":80,"required":"","type":"text"},model:{value:(_vm.convite.email),callback:function ($$v) {_vm.$set(_vm.convite, "email", $$v)},expression:"convite.email"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
                    title: 'NÚMERO TELEFONE',
                    text: _vm.telefoneInfo,
                    function: _vm.setUiBottomSheet,
                  },"label":"celular","max":15,"options":{
                    mask: _vm.mascaraTelefone,
                    options: _vm.optionsTelefone,
                  },"placeholder":_vm.placeholderTelefone,"type":"tel"},model:{value:(_vm.convite.celular),callback:function ($$v) {_vm.$set(_vm.convite, "celular", $$v)},expression:"convite.celular"}})],1)],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-btn',{staticClass:"primary--text",attrs:{"icon":""},on:{"click":_vm.clickVoltar}},[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-arrow-left")])],1)],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"block":"","classe":"text-body-1","color":"primary"},on:{"click":function($event){return _vm.clickConvidarCadastrar(_vm.tipoCadastro)}}},[_vm._v(" "+_vm._s(_vm.tipoCadastro ? "convidar" : "cadastrar")+" ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('c-bottom-sheet',{attrs:{"bottomSheet":_vm.bottomSheet}}),_c('c-snackbar',{attrs:{"snackbar":_vm.snackbar},on:{"click":function($event){return _vm.clickSnackbar($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }