<!-- COD: FDS 01/07/2022 CConvites -->
<!-- *VER: JER 01/07/2022 CConvites -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.nomeAlt">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" v-if="dataset.data">
        <span class="text-body-1">
          {{ dateFormat(dataset.data, "date timeNoSecond", null, true) }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CConvites",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>