<!-- COD: BJBN 09/01/2023 CLegislacoes -->
<!-- *VER: JER __/01/2023 CLegislacoes -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" v-if="order.text != 'localidade'" cols="auto">
        <!-- pais -->
        <v-img
          alt="imagem"
          class="ml-0"
          contain
          :src="getLocalidade(dataset.idPais).endereco"
          width="25"
        />
      </v-col>
      <!-- nomeAlt -->
      <v-col v-bind="prop.col" cols="auto">
        <span class="text-body-1">
          {{ dataset.nomeAlt }}
        </span>
      </v-col>
    </v-row>
    <v-row v-bind="prop.row">
      <!-- nome -->
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.nome }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data/dataPublicacao -->
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dateFormat(dataset.data, "date") }}
        </span>
        <span class="ml-1 text-body-2">
          ({{ dateFormat(dataset.dataPublicacao, "date") }})
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataInicioFim -->
      <v-col v-bind="prop.col">
        <span class="text-body-2">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- itens -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" v-if="this.dataset.itens > 0">
        <span class="text-body-1">
          {{ itensFormatado }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLegislacoes",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    order: { type: Object },
  },

  /// COMPUTED
  computed: {
    dataInicioFim() {
      let ret = "";
      const dataInicio = this.dataset.dataInicio
        ? this.dateFormat(this.dataset.dataInicio, "date")
        : "";

      if (dataInicio) {
        const dataFim = this.dataset.dataFim
          ? this.dateFormat(this.dataset.dataFim, "date")
          : "atual";

        ret = `${dataInicio} - ${dataFim}`;
      }

      return ret;
    },

    itensFormatado() {
      return `${this.dataset.itens} ${
        this.dataset.itens > 1 ? " itens" : " item"
      }`;
    },
  },
};
</script>