<!-- COD: BJB 05/07/2022 CNotaFiscalEdit -->
<!-- *VER: JER 05/08/2022 CNotaFiscalEdit -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- operacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.operacao">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ dataset.main.detail.operacao }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nota fiscal -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.notaFiscal }}
        </span>
        <span v-if="dataset.main.detail.serie" class="ml-1 text-h6">
          série
        </span>
        <span class="ml-1 text-h6">
          {{ dataset.main.detail.serie }}
        </span>
        <span class="ml-1 text-h6">
          {{ dateFormat(dataset.main.detail.data, "date timeNoSecond") }}
        </span>
      </v-col>
    </v-row>

    <!-- data entrada saida -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.dataEntradaSaida">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">
          {{
            `data ${getTipo_item(context.dataset.main.detail.idTipo).nomeAlt}`
          }}
        </span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataEntradaSaida, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- pedido -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          color="primary"
          class="text-body-1 ma-1"
          text
          @click="clickPedido(context)"
        >
          {{ `pedido ${context.dataset.main.detail.idPedido}` }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- colaborador -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.colaborador">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.colaborador }}
        </span>
      </v-col>
    </v-row>

    <!-- chave acesso -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.chaveAcesso">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.chaveAcesso }}
        </span>
      </v-col>
    </v-row>

    <v-divider v-bind="prop.divider" />

    <!-- total produtos -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.totalProdutos">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">total produtos</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.totalProdutos, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- total impostos -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.totalImpostos">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">total impostos</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.totalImpostos, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- valor frete -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valorFrete">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">valor frete</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.valorFrete, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- total nota -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.totalNota">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="font-weight-medium text-body-2">total nota</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="font-weight-medium text-body-1">
          {{ numberFormat(dataset.main.detail.totalNota, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-divider :v-bind="prop.divider" />

    <!-- volumes -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.volumes">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">volumes</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.volumes, "decimal") }}
        </span>
      </v-col>
    </v-row>

    <!-- peso bruto -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.pesoBruto">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">peso bruto</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.pesoBruto, "decimal", 2) }}
        </span>
        <span class="ml-0 text-body-2">Kg</span>
      </v-col>
    </v-row>

    <!-- peso liquido -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.pesoLiquido">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">peso líquido</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.pesoLiquido, "decimal", 2) }}
        </span>
        <span class="ml-0 text-body-2">Kg</span>
      </v-col>
    </v-row>

    <v-divider v-bind="prop.divider" />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CNotaFiscalEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),
  },

  /// METHODS
  methods: {
    clickPedido() {
      this.$router.push(`/pedido/${this.dataset.main.detail.idPedido}`);
    },
  },
};
</script>