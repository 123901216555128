var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"itemsIniciais":_vm.dataset.aux.autocomplete.pessoas,"label":"pessoa","path":"/pessoas/autocomplete/pessoas/0/","rules":[]},model:{value:(_vm.dataset.main.detail.idPessoa),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idPessoa", $$v)},expression:"dataset.main.detail.idPessoa"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"clearable":"","items":_vm.tiposFormaPagamento,"label":"forma pagamento"},model:{value:(_vm.dataset.main.detail.idFormaPagamento),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idFormaPagamento", $$v)},expression:"dataset.main.detail.idFormaPagamento"}})],1),(
        _vm.getTipo_item('formaPagamentoCheque').id ==
        _vm.dataset.main.detail.idFormaPagamento
      )?_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"número","max":8,"required":""},model:{value:(_vm.dataset.main.detail.numeroDocumento),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "numeroDocumento", $$v)},expression:"dataset.main.detail.numeroDocumento"}})],1):_vm._e(),(
        _vm.getTipo_item('formaPagamentoCheque').id ==
        _vm.dataset.main.detail.idFormaPagamento
      )?_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"motivo","max":2},model:{value:(_vm.dataset.main.detail.motivoDevolucao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "motivoDevolucao", $$v)},expression:"dataset.main.detail.motivoDevolucao"}})],1):_vm._e()],1),(
      (_vm.getTipo_item('formaPagamentoCheque').id ==
        _vm.dataset.main.detail.idFormaPagamento &&
        _vm.getTipo_item('valorPag').id == _vm.dataset.main.detail.idTipo) ||
      _vm.getTipo_item('formaPagamentoCreditoConta').id ==
        _vm.dataset.main.detail.idFormaPagamento
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"12","sm":"7","md":"6"}},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"clearable":"","items":_vm.contasBancarias,"label":"banco/agência/conta","properties":{
          image: true,
        },"required":""},model:{value:(_vm.dataset.main.detail.idPessoaParceiroContaBancaria),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idPessoaParceiroContaBancaria", $$v)},expression:"dataset.main.detail.idPessoaParceiroContaBancaria"}})],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(
        _vm.getTipo_item('formaPagamentoCheque').id ==
          _vm.dataset.main.detail.idFormaPagamento &&
        _vm.getTipo_item('valorRec').id == _vm.dataset.main.detail.idTipo
      )?_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"banco","max":4,"options":{
          mask: 'X###?',
        },"required":""},model:{value:(_vm.dataset.main.detail.banco),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "banco", $$v)},expression:"dataset.main.detail.banco"}})],1):_vm._e(),(
        _vm.getTipo_item('formaPagamentoCheque').id ==
          _vm.dataset.main.detail.idFormaPagamento &&
        _vm.getTipo_item('valorRec').id == _vm.dataset.main.detail.idTipo
      )?_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"agência","max":5,"options":{
          mask: '#####?',
        },"required":""},model:{value:(_vm.dataset.main.detail.agencia),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "agencia", $$v)},expression:"dataset.main.detail.agencia"}})],1):_vm._e(),(
        _vm.getTipo_item('formaPagamentoCheque').id ==
          _vm.dataset.main.detail.idFormaPagamento &&
        _vm.getTipo_item('valorRec').id == _vm.dataset.main.detail.idTipo
      )?_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"conta","max":15,"options":{
          mask: '#####?#?#?#?#?#?#?#?#?#?#?',
        },"required":""},model:{value:(_vm.dataset.main.detail.conta),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "conta", $$v)},expression:"dataset.main.detail.conta"}})],1):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(
        _vm.getTipo_item('formaPagamentoCheque').id ==
          _vm.dataset.main.detail.idFormaPagamento &&
        _vm.getTipo_item('valorRec').id == _vm.dataset.main.detail.idTipo
      )?_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"emitente","max":40,"required":""},model:{value:(_vm.dataset.main.detail.emitenteNome),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "emitenteNome", $$v)},expression:"dataset.main.detail.emitenteNome"}})],1):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(
        _vm.getTipo_item('formaPagamentoCheque').id ==
          _vm.dataset.main.detail.idFormaPagamento &&
        _vm.getTipo_item('valorRec').id == _vm.dataset.main.detail.idTipo
      )?_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"documento emitente","max":25,"required":""},model:{value:(_vm.dataset.main.detail.emitenteDocumento),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "emitenteDocumento", $$v)},expression:"dataset.main.detail.emitenteDocumento"}})],1):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(
        _vm.getTipo_item('formaPagamentoCheque').id ==
        _vm.dataset.main.detail.idFormaPagamento
      )?_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nominal a","max":40},model:{value:(_vm.dataset.main.detail.nominal),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "nominal", $$v)},expression:"dataset.main.detail.nominal"}})],1):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(
        _vm.getTipo_item('formaPagamentoCheque').id ==
        _vm.dataset.main.detail.idFormaPagamento
      )?_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"local emissão","max":35},model:{value:(_vm.dataset.main.detail.localEmissao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "localEmissao", $$v)},expression:"dataset.main.detail.localEmissao"}})],1):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"date":"","label":"data emissão","required":""},model:{value:(_vm.dataset.main.detail.dataEmissao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "dataEmissao", $$v)},expression:"dataset.main.detail.dataEmissao"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"date":"","label":"data apropriação"},model:{value:(_vm.dataset.main.detail.dataApropriacao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "dataApropriacao", $$v)},expression:"dataset.main.detail.dataApropriacao"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"valor","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.valor),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valor", $$v)},expression:"dataset.main.detail.valor"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"saldo associar","max":13,"options":{
          length: 11,
          precision: 2,
          style: 'currency',
        }},model:{value:(_vm.dataset.main.detail.saldoAssociar),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "saldoAssociar", $$v)},expression:"dataset.main.detail.saldoAssociar"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"identificador","max":25},model:{value:(_vm.dataset.main.detail.identificador),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "identificador", $$v)},expression:"dataset.main.detail.identificador"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"histórico","max":25},model:{value:(_vm.dataset.main.detail.historico),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "historico", $$v)},expression:"dataset.main.detail.historico"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }