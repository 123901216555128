<!-- COD: FDS 10/08/2022 CContaContabilDialogRelatorioDiarioContabil -->
<!-- *VER: JER 10/08/2022 CContaContabilDialogRelatorioDiarioContabil -->
<!-- /11/ -->

<template>
  <v-dialog v-model="value" max-width="380px" persistent scrollable>
    <v-form ref="form">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="primary" dark flat>
            <c-btn icon @click="$emit('input', false)">
              <v-icon>mdi-close</v-icon>
            </c-btn>
            <v-spacer />
            <v-toolbar-title>
              {{ title }}
            </v-toolbar-title>
            <v-spacer />
            <c-btn icon @click="print()">
              <v-icon>mdi-printer</v-icon>
            </c-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text ref="printArea">
          <!-- nome alt parceiro -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <v-chip
                class="primary primary--text text-overline"
                outlined
                x-small
              >
                {{ dadosParceiro.pessoa.nomeAlt }}
              </v-chip>
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <!-- data inicio -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="searchData.inicio"
                classe="text-body-1 pr-1"
                clearable
                date
                icon
                label="início"
                :maxValue="new Date().toISOString().substring(0, 10)"
                @input="$emit('searchData', searchData)"
              />
            </v-col>
            <!-- data fim -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="searchData.fim"
                classe="text-body-1 pl-1"
                clearable
                date
                icon
                label="fim"
                :minValue="
                  searchData.inicio || new Date().toISOString().substring(0, 10)
                "
                @input="$emit('searchData', searchData)"
              />
            </v-col>
          </v-row>

          <!-- mostrar fato contabil -->
          <v-row v-bind="prop.row" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="mostrarFatoContabil"
                label="mostrar fato contábil"
              />
            </v-col>
          </v-row>

          <!-- mostrar detalhe -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-switch v-model="mostrarDetalhe" label="mostrar detalhe" />
            </v-col>
          </v-row>

          <!-- gerar -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="end">
              <c-btn
                classe="primary--text mr-8 mt-n2 mb-4"
                text
                @click="clickSearch()"
              >
                gerar
              </c-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CContaContabilDialogRelatorioDiarioContabil",

  /// MIXINS
  mixins: [mixinData, mixinSnackbar],

  /// PROPS
  props: {
    contaPagRec: Object,
    value: Boolean,
  },

  /// DATA
  data() {
    return {
      colaboradorFinancas_id: null,
      mostrarDetalhe: false,
      mostrarFatoContabil: false,
      printing: false,
      searchData: { inicio: null, fim: null },
      title: "DIÁRIO CONTÁBIL",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },
  },

  /// METHODS
  methods: {
    clickSearch() {
      console.log("vai fazer a busca no Back");
      this.clickGerar();
    },

    clickGerar() {
      console.log("Gera o arquivo");
    },
  },
};
</script>