// COD: BJB 15/08/2022 dicionarios
// *VER: JER 19/08/2022 dicionarios
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    dicionarios: []
  },

  /// GETTERS
  getters: {
    getDicionarios: state => (prop = "id") => {
      return state.dicionarios
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.texto
          };
        })
    },

    getDicionario: state => search => {
      return state.dicionarios.length
        ? state.dicionarios
          .find(element => element.id == search || element.texto.toLowerCase() == search.toString().toLowerCase())
        : { search }
    }
  },

  /// MUTATIONS
  mutations: {
    setDicionarios: (state, payload) => {
      state.dicionarios = []
      payload.forEach(element => {
        state.dicionarios.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setDicionarios: (context, payload) => {
      context.commit("setDicionarios", payload)
    }
  }
}