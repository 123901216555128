<!-- COD: BJB 26/07/2022 CContaPagRecDialogNotaDebito -->
<!-- *VER: JER 05/08/2022 CContaPagRecDialogNotaDebito -->
<!-- /22/ -->

<template>
  <v-dialog scrollable persistent max-width="380px" v-model="value">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="print()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text ref="printArea">
        <v-row v-bind="prop.row">
          <!-- logo -->
          <v-col v-bind="prop.col" cols="3">
            <img :src="logoParceiro" width="100" />
          </v-col>

          <!-- title -->
          <v-col v-bind="prop.col" align="center" class="mb-n8" cols="6">
            <span v-if="printing" class="font-weight-bold text-body-2">
              {{ title }}
            </span>
          </v-col>

          <!-- id -->
          <v-col v-bind="prop.col" align="end" class="mb-n8" cols="3">
            <span class="font-weight-bold mr-n1 text-body-2">
              #
              {{ contaPagRec.main.detail.id }}
            </span>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" class="mt-n2" />

        <!-- emitente -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <p class="font-weight-medium mb-n1 text-body-1">
              {{ contaPagRec.aux.detail.pessoaParceiro.nome.toUpperCase() }}
            </p>
            <p class="mb-n1 text-body-2">
              <!-- //?b fazer endereço conforme código comentado -->
              <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco1 }} -->
              {{ pessoaParceiroEndereco.endereco1 }}
            </p>
            <p class="mb-n1 text-body-2">
              <!-- //?b fazer endereço conforme código comentado -->
              <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco2 }} -->
              {{ pessoaParceiroEndereco.endereco2 }}
            </p>
            <p class="mb-n1 text-body-2">
              {{
                pessoaDocumentosFormat(contaPagRec.aux.detail.pessoaParceiro)
              }}
            </p>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" />

        <!-- sacado -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <p class="mt-3 mb-n1 text-caption">SACADO</p>
            <p class="font-weight-medium mb-n1 text-body-1">
              {{ contaPagRec.aux.detail.pessoa.nome.toUpperCase() }}
            </p>
            <p class="mb-n1 text-body-2">
              <!-- //?b fazer endereço conforme código comentado -->
              <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco1 }} -->
              {{ pessoaEndereco.endereco1 }}
            </p>
            <p class="mb-n1 text-body-2">
              <!-- //?b fazer endereço conforme código comentado -->
              <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco2 }} -->
              {{ pessoaEndereco.endereco2 }}
            </p>
            <p class="mb-n1 text-body-2">
              {{ pessoaDocumentosFormat(contaPagRec.aux.detail.pessoa) }}
            </p>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" />

        <!-- data emissao -->
        <v-row v-bind="prop.row" class="mb-n3">
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-2">emissão</span>
          </v-col>
          <v-col v-bind="prop.col" align="start" cols="6">
            <span class="text-body-1">
              {{
                dateFormat(
                  contaPagRec.main.detail.dataEmissao,
                  "date",
                  null,
                  true
                )
              }}
            </span>
          </v-col>
        </v-row>

        <!-- data vencimento -->
        <v-row v-bind="prop.row" class="mt-n3">
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-2">vencimento</span>
          </v-col>
          <v-col v-bind="prop.col" align="start" cols="6">
            <span class="text-body-1">
              {{
                dateFormat(
                  contaPagRec.main.detail.dataVencimento,
                  "date",
                  null,
                  true
                )
              }}
            </span>
          </v-col>
        </v-row>

        <!-- valor -->
        <v-row v-bind="prop.row" class="mb-n3">
          <v-col v-bind="prop.col" align="center">
            <span class="font-weight-medium text-body-1">
              {{ numberFormat(contaPagRec.main.detail.valor, "currency") }}
            </span>
          </v-col>
        </v-row>

        <!-- valor extenso -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              ({{ contaPagRec.main.detail.valor.numberToWords(locale) }})
            </span>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" />

        <!-- mensagem sacado -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <p class="text-body-1">O sacado deve à</p>
            <p class="font-weight-medium ml-1 mt-n3 text-body-1">
              {{ contaPagRec.aux.detail.pessoaParceiro.nome }}
            </p>
            <p class="ml-1 mt-n3 text-body-1">
              a importância desta Nota de Débito de prestação de serviços,
              referente a:
            </p>
          </v-col>
        </v-row>

        <!-- mensagem sacado -->
        <v-row
          v-bind="prop.row"
          v-if="contaPagRec.main.detail.observacoes"
          class="mt-2"
        >
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-1">
              {{ contaPagRec.main.detail.observacoes }}
            </span>
          </v-col>
        </v-row>

        <!-- mensagem sacado -->
        <v-row
          v-bind="prop.row"
          v-if="contaPagRec.main.detail.observacoes"
          class="mb-8 mt-4"
        >
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-1">
              Valor a ser descontado de suas duplicatas a serem definidas.
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CContaPagRecDialogNotaDebito",

  /// MIXINS
  mixins: [mixinData, mixinLib],

  /// PROPS
  props: {
    contaPagRec: Object,
    value: Boolean,
  },

  /// DATA
  data() {
    return {
      printing: false,
      title: "NOTA DE DÉBITO",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    locale() {
      return this.localeByPais(
        this.contaPagRec.aux.detail.pessoaParceiro.endereco.idPais
      );
    },

    logoParceiro() {
      return this.dadosParceiro.imagem.endereco;
    },

    pessoaEndereco() {
      return this.pessoasEnderecoFormat(
        this.contaPagRec.aux.detail.pessoa.endereco
      );
    },

    pessoaParceiroEndereco() {
      return this.pessoasEnderecoFormat(
        this.contaPagRec.aux.detail.pessoaParceiro.endereco
      );
    },
  },

  /// METHODS
  methods: {
    print() {
      this.printing = true;

      setTimeout(() => {
        this.pagePrint("notaDebito");
        setTimeout(() => {
          this.printing = false;
        }, 10);
      }, 10);
    },
  },
};
</script>