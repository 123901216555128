var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"prazo pagamento","max":3,"options":{
          length: 3,
          precision: 0,
          suffix: 'dias',
        }},model:{value:(_vm.produto.prazoPagamento),callback:function ($$v) {_vm.$set(_vm.produto, "prazoPagamento", $$v)},expression:"produto.prazoPagamento"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"custo reposição","max":17,"options":{
          length: 11,
          precision: _vm.produto.decimalValor,
          style: 'currency',
        }},model:{value:(_vm.produto.custoReposicao),callback:function ($$v) {_vm.$set(_vm.produto, "custoReposicao", $$v)},expression:"produto.custoReposicao"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"margem contribuição","max":7,"options":{
          length: 5,
          precision: 2,
          style: 'percent',
          suffix: '%',
        }},model:{value:(_vm.produto.margemContribuicao),callback:function ($$v) {_vm.$set(_vm.produto, "margemContribuicao", $$v)},expression:"produto.margemContribuicao"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"margem usuário","max":7,"options":{
          length: 5,
          precision: 2,
          style: 'percent',
          suffix: '%',
        }},model:{value:(_vm.produto.margemUsuario),callback:function ($$v) {_vm.$set(_vm.produto, "margemUsuario", $$v)},expression:"produto.margemUsuario"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"prazo garantia","max":3,"options":{
          length: 3,
          precision: 0,
          suffix: 'meses',
        }},model:{value:(_vm.produto.prazoGarantia),callback:function ($$v) {_vm.$set(_vm.produto, "prazoGarantia", $$v)},expression:"produto.prazoGarantia"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }