<!-- COD: FDS 01/07/2022 Localidades -->
<!-- *VER: JER 04/07/2022 Localidades -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ context, dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
    :pessoaParceiro="pessoaParceiro"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-localidades :context="context" :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CLocalidades from "@/components/pages/localidade/CLocalidades";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Localidades",

  /// COMPONENTS
  components: {
    CLocalidades,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("localidades").id,
      idPageAlt: getPagina("localidade").id,

      funcs: {
        clickChip: (context) => {
          this.idPai = null;
          this.pessoaParceiro = null;
          context.params = this.funcs.fnParams();
          context.clickSearch();
        },

        clickPai: (dataset, context) => {
          this.idPai = dataset.idPai;
          this.pessoaParceiro = dataset.localidadePai_nome;
          context.params = this.funcs.fnParams();
          context.clickSearch();
        },

        fnParams: () => {
          return JSON.stringify({
            idPai: this.idPai,
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      idPai: null,
      pessoaParceiro: null, //? nomePai
    };
  },
};
</script>