<!-- COD: LYF 01/07/2022 CContasContabeis -->
<!-- *VER: JER 05/07/2022 CContasContabeis -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- nivel -->
      <v-col v-bind="prop.col" cols="1">
        <v-badge
          :color="dataset.allowLancamento ? 'green' : 'grey'"
          :content="dataset.nivel"
          inline
          overlap
          :value="!!dataset.nivel"
        ></v-badge>
      </v-col>
      <!-- tipoDetalhe -->
      <v-col v-bind="prop.col" v-if="dataset.idTipoDetalhe" cols="11">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.idTipoDetalhe).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CContasContabeis",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>