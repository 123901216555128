<!-- COD: FDS 05/07/2022 CAssistenciaTecnicaEdit -->
<!-- *VER: JER 05/08/2022 CAssistenciaTecnicaEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- status -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip" v-if="dataset.main.detail.isProprio">
          proprio
        </v-chip>
        <v-chip v-bind="prop.chip" v-if="dataset.main.detail.isDefeito">
          defeito
        </v-chip>
        <v-chip v-bind="prop.chip" v-if="dataset.main.detail.isCliente">
          cliente
        </v-chip>
        <v-chip
          v-bind="prop.chip"
          v-if="dataset.main.detail.isPendenteFornecedor"
        >
          fornecedor
        </v-chip>
      </v-col>
    </v-row>

    <!-- produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <a class="text-body-1" @click="clickProduto">
          {{ produtoFormat(dataset.aux.detail.produto) }}
        </a>
      </v-col>
    </v-row>

    <!-- numero serie -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">NS </span>
        <span class="ml-0 text-body-1">
          {{ dataset.main.detail.numeroSerie }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.data, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- fornecedor nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          text
          class="text-body-1 ma-1"
          color="primary"
          @click="clickFornecedor"
        >
          {{ dataset.aux.detail.pessoaFornecedor_nome }}
        </c-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CAssistenciaTecnicaView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// METHODS
  methods: {
    clickFornecedor() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idFornecedor}`);
    },

    clickProduto() {
      this.$router.push(`/produto/${this.dataset.main.detail.idProduto}`);
    },
  },
};
</script>