<!-- COD: LYF 01/07/2022 CPerguntasFrequentes -->
<!-- *VER: JER 03/07/2022 CPerguntasFrequentes -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- grupo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-chip v-bind="prop.chip">
          {{ grupoPergunta ? grupoPergunta[getVar("locale")] : "" }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- pergunta -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.pergunta[getVar("locale")] }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- thumb-up -->
      <v-col v-bind="prop.col" cols="1">
        <v-icon class="green--text">mdi-thumb-up-outline</v-icon>
      </v-col>
      <!-- numeroLikes -->
      <v-col v-bind="prop.col" cols="2">
        <span class="text-body-1">
          {{ dataset.numeroLikes }}
        </span>
      </v-col>
      <!-- thumb-down -->
      <v-col v-bind="prop.col" cols="1">
        <v-icon class="red--text">mdi-thumb-down-outline</v-icon>
      </v-col>
      <!-- numeroDislikes -->
      <v-col v-bind="prop.col" cols="2">
        <span class="text-body-1">
          {{ dataset.numeroDislikes }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CPerguntasFrequentes",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
      getFeature: "paginas/getFeature",
    }),

    grupoPergunta() {
      return this.context.idOrder != this.getFeature("orderGrupo").id
        ? this.dataset.grupo
        : null;
    },
  },
};
</script>