<!-- COD: LYF 01/07/2022 Produtos -->
<!-- *VER: JER 04/07/2022 Produtos -->
<!-- /11/ -->

<template>
  <v-container>
    <c-base-list
      v-slot="{ context, dataset, order }"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
    >
      <c-base-list-item
        :context="context"
        :dataset="dataset"
        :idPage="idPage"
        :order="order"
      >
        <c-produtos :context="context" :dataset="dataset" :order="order" />
      </c-base-list-item>
    </c-base-list>
    <c-produtos-dialog-importar
      v-if="showImportarProduto"
      @close="closeImportarProduto"
    />
  </v-container>
</template>

<script>
/// IMPORT
import CProdutos from "@/components/pages/produto/CProdutos";
import CProdutosDialogImportar from "@/components/pages/produto/dialogs/CProdutosDialogImportar";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Produtos",

  /// COMPONENTS
  components: {
    CProdutos,
    CProdutosDialogImportar,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("produtos").id,
      idPageAlt: getPagina("produto").id,
      context: null,
      funcs: {
        clickGerenciar: () => {
          this.setVar({ overlay: true });

          setTimeout(() => {
            const url = `/produtos/gerenciar`;
            this.$router.push(encodeURI(url));
          }, 10);
        },

        clickImportar: (context) => {
          this.context = context;
          this.showImportarProduto = true;
        },

        clickInventario: () => {
          this.setVar({ overlay: true });

          setTimeout(() => {
            const url = `/produtos/inventario`;
            this.$router.push(encodeURI(url));
          }, 10);
        },

        clickNumerosSerie: () => {
          setTimeout(() => {
            this.$router.push(`/produto/numerosserie`);
          }, 10);
        },

        clickRelatorioEstoque: () => {
          setTimeout(() => {
            this.$router.push(`/produtos/relatorio/estoque`);
          }, 10);
        },

        fnParams: (context) => {
          const contextParams = context.params
            ? JSON.parse(context.params)
            : null;
          const proprietarios = contextParams?.proprietarios ?? true;

          return JSON.stringify({
            proprietarios,
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo)?.nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado)?.nomeAlt;
          dataset.tipoEntrega = null;

          if (dataset.isEntregar != dataset.isRetirar) {
            dataset.tipoEntrega = dataset.isEntregar ? "entregar" : "retirar";
          }
        },
      },

      showImportarProduto: false,
    };
  },

  /// METHODS
  methods: {
    async closeImportarProduto() {
      this.showImportarProduto = false;
      await this.context.clickSearch();
    },
  },
};
</script>