import { render, staticRenderFns } from "./CExpansionPanel.vue?vue&type=template&id=09085b3f&"
import script from "./CExpansionPanel.vue?vue&type=script&lang=js&"
export * from "./CExpansionPanel.vue?vue&type=script&lang=js&"
import style0 from "./CExpansionPanel.vue?vue&type=style&index=0&id=09085b3f&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VRow})
