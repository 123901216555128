// COD: BJB 15/08/2022 verbas
// *VER: JER 19/08/2022 verbas
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    verbas: []
  },

  /// GETTERS
  getters: {
    getVerbas: state => () => {
      return state.verbas
        // .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nomeAlt,
            nivel: element.nivel,
            idTipo: element.idTipo,
            isProvento: element.isProvento,
            isBeneficio: element.isBeneficio,
            isAusencia: element.isAusencia,
            allowAlterarValor: element.allowAlterarValor,
            allowAcerto: element.allowAcerto,
            options: element.options
          };
        })
    },

    getVerba: state => search => {
      return state.verbas.length
        ? state.verbas
          .find(element => element.id == search || element.nome == search)
        : { search }
    }
  },

  /// MUTATIONS
  mutations: {
    setVerbas: (state, payload) => {
      state.verbas = []
      payload.forEach(element => {
        state.verbas.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setVerbas: (context, payload) => {
      context.commit("setVerbas", payload)
    }
  }
}