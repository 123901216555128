// COD: BJB 15/08/2022 registerServiceWorker
// *VER: JER 19/08/2022 registerServiceWorker
// /11/

/// IMPORT
import { register } from 'register-service-worker'

/// SERVICEWORKER
// Este if foi comentado para que o service worker rode também em modo dev
// if (process.env.NODE_ENV === 'production') {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB'
    )
  },
  registered() {
    // console.log('Service worker has been registered.')
    // console.log(navigator.serviceWorker)
  },
  cached() {
    // console.log('Content has been cached for offline use.')
  },
  updatefound() {
    // console.log('New content is downloading.')
  },
  async updated() {
    // console.log('New content is available; please refresh.')
    const existingCaches = await caches.keys();
    await Promise.all(existingCaches.map(ic => caches.delete(ic)));

    window.location.reload();
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  }
})
// }