var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.idPage && _vm.idPageAlt)?_c('c-base-list',{attrs:{"funcs":_vm.funcs,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"pageModeEdit":true},scopedSlots:_vm._u([{key:"listContent",fn:function(ref){
var context = ref.context;
var datasets = ref.datasets;
return [_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":false,"value":context.datasetList.dataset.main.produtos},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var produto = ref.dataset;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.produtoFormat(produto, false))+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("inventário")]),_c('span',{staticClass:"ml-0 text-body-1"},[_vm._v(" "+_vm._s(_vm.getProdutoInventario(produto, datasets))+" ")])])],1)]}},{key:"content",fn:function(ref){
var produto = ref.dataset;
return [_c('c-produto-page-inventario-diferenca-edit',{attrs:{"context":context,"datasets":datasets,"produto":produto}})]}}],null,true)})]}},{key:"bottom",fn:function(ref){
var context = ref.context;
return [(_vm.showGerarPedido(context))?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"classe":"primary--text mt-4","text":""},on:{"click":function($event){return _vm.pedidosAcertoConfirm(context)}}},[_vm._v(" gerar pedidos de acerto ")])],1)],1):_vm._e()]}}],null,false,2232753035)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }