// COD: LYF 10/08/2022 mixinPageList
// *VER: JER 19/08/2022 mixinPageList
// /11/

import CBaseList from "@/components/bases/CBaseList";
import CBaseListItem from "@/components/bases/CBaseListItem";
import mixinPage from "@/mixins/mixinPage";

export default {
  /// NAME
  name: "mixinPageList",

  /// COMPONENTS
  components: { CBaseList, CBaseListItem },

  /// MIXINS
  mixins: [mixinPage]
}