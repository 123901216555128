// COD: FDS 10/08/2022 mixinUtil
// *VER: JER 19/08/2022 mixinUtil
// /23/

export default {
  /// NAME
  name: "mixinUtil",

  /// METHODS
  methods: {
    // pegamos esta função do Overfly, existe uma forma nova de fazer, porém, não funciona no iPhone
    copyToClipboard(value) {
      // Create new element
      var element = document.createElement("textArea");
      // Set value (string to be copied)
      element.value = value;
      // Set non-editable to avoid focus and move outside of view
      element.readOnly = true;
      // element.style = { position: 'absolute', left: '-9999px' };
      element.style.position = "absolute";
      element.style.left = "-9999px";
      // Inlcude element in DOM
      window.top.document.body.appendChild(element);
      // Select text inside element
      element.select();
      // Copy text to clipboard
      window.top.document.execCommand("copy");
      // Remove temporary element
      window.top.document.body.removeChild(element);
    },

    getPosition() {
      return new Promise((resolve, reject) => {
        const coordenada = { latitude: null, longitude: null }

        if (navigator.geolocation) {
          try {
            setTimeout(() => {
              reject("Tempo esgotado.\nVerifique as configurações de GPS.")
            }, 5000)
            navigator.geolocation.getCurrentPosition(
              position => {
                coordenada.latitude = position.coords.latitude
                coordenada.longitude = position.coords.longitude
                resolve(coordenada)
              },
              () => {
                reject("Seu GPS está desabilitado.\nPor favor, habilite para ver as ofertas.\nDeseja ajuda?")
              }
            )
          } catch (err) {
            reject(err)
          }
        } else {
          reject("Geolocalização não é suportada por este browser.")
        }
      });
    },

    getUserDevice() {
      const module = {
        options: [],
        header: [
          navigator.platform,
          navigator.userAgent,
          navigator.appVersion,
          navigator.vendor,
          window.opera
        ],
        dataos: [
          { name: "Windows", value: "Win", version: "NT" },
          { name: "Android", value: "Android", version: "Android" },
          { name: "iOS", value: "iPhone", version: "OS" },
          { name: "iOS", value: "iPad", version: "OS" },
          { name: "macOS", value: "Mac", version: "OS X" },
          { name: "Linux", value: "Linux", version: "rv" }
        ],
        databrowser: [
          { name: "Edge", value: "Edg", version: "Edg" },
          { name: "Chrome", value: "Chrome", version: "Chrome" },
          { name: "Safari", value: "Safari", version: "Version" },
          { name: "Firefox", value: "Firefox", version: "Firefox" },
        ],
        init: function () {
          const agent = this.header.join(" "),
            os = this.matchItem(agent, this.dataos),
            browser = this.matchItem(agent, this.databrowser);

          return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
          let i = 0, regex, regexv, match, matches, version;
          for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, "i");
            match = regex.test(string);
            if (match) {
              regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
              matches = string.match(regexv);
              version = "";
              if (matches) {
                if (matches[1]) {
                  matches = matches[1];
                }
              }
              if (matches) {
                version = matches.replace(/_/g, '.')
              } else {
                version = "0";
              }
              return {
                name: data[i].name,
                version
              };
            }
          }
          return { name: "unknown", version: 0 };
        }
      };

      const userDevice = module.init();
      const deviceTemp = navigator.userAgent.match(/\(([^)]+)\)/)[0]
      const deviceTemp2 = deviceTemp.split(";")
      const deviceTemp3 = deviceTemp2[deviceTemp2.length - 1].replace(")", "").trim().substring(0, 20)

      let device


      if (deviceTemp.includes('Windows')) {
        device = {
          name: 'Windows',
          model: deviceTemp3
        }
      } else if (deviceTemp.includes('Android')) {
        device = {
          name: 'Android',
          model: deviceTemp3
        }
      } else if (deviceTemp.includes('iPhone')) {
        device = {
          name: 'iPhone',
          model: null
        }
      } else if (deviceTemp.includes('iPad')) {
        device = {
          name: 'iPad',
          model: null
        }
      } else {
        device = {
          name: 'Other',
          model: deviceTemp3
        }
      }
      userDevice.device = { ...device }
      return userDevice;
    },

    // função que retorna o IP interno
    //?b confirmar esta função
    getUserIP(onNewIP) {
      // onNewIp - your listener function for new IPs compatibility for firefox and chrome
      const myPeerConnection =
        window.RTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.webkitRTCPeerConnection;
      const pc = new myPeerConnection({ iceServers: [] });
      const noop = function () { };
      const localIPs = {};
      const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

      const iterateIP = ip => {
        if (!localIPs[ip]) onNewIP(ip);
        localIPs[ip] = true;
      };

      //create a bogus data channel
      pc.createDataChannel("");
      // create offer and set local description
      pc.createOffer()
        .then(function (sdp) {
          sdp.sdp.split("\n").forEach(function (line) {
            if (line.indexOf("candidate") < 0) return;

            line.match(ipRegex).forEach(iterateIP);
          });
          pc.setLocalDescription(sdp, noop, noop);
        })
        .catch(function (/* reason */) {
          // An error occurred, so handle the failure to connect
        });
      //listen for candidate events
      pc.onicecandidate = function (ice) {
        if (
          !ice ||
          !ice.candidate ||
          !ice.candidate.candidate ||
          !ice.candidate.candidate.match(ipRegex)
        ) {
          return;
        }

        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
      };
    },
  }
}