<!-- COD: FDS 06/07/2022 CTransacaoEdit -->
<!-- *VER: JER 10/08/2022 CTransacaoEdit -->
<!-- 11 -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.data"
          date
          label="data"
          required
        />
      </v-col>
      <!-- data vencimento -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataVencimento"
          date
          label="vencimento"
        />
      </v-col>
    </v-row>

    <!-- documento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.documento"
          label="documento"
          :max="20"
        />
      </v-col>
    </v-row>

    <!-- valor -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-money
          v-model="dataset.main.detail.valor"
          label="valor"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor considerado -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valorConsiderado"
          label="valor considerado"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
      <!-- valor app -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valorApp"
          label="valor app"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- percentual -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.percentual"
          label="percentual"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'percent',
            suffix: ' %',
          }"
        />
      </v-col>
      <!-- valor comissao -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valorComissao"
          label="valor comissao"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor acerto -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valorAcerto"
          label="valor acerto"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
      <!-- valor saldo -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valorSaldo"
          label="valor saldo"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.observacao"
          label="observação"
          :max="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CTransacaoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },
};
</script>