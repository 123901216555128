<!-- COD: LYF 06/07/2022 CLancamentoContabilEdit -->
<!-- *VER: JER 07/08/2022 CLancamentoContabilEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- data inicio -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="dataset.main.detail.dataInicio"
          date
          label="data início"
          @change="dataChanged"
        />
      </v-col>
      <!-- data fim -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="dataset.main.detail.dataFim"
          date
          :disabled="!dataset.main.detail.dataInicio"
          :minValue="dataset.main.detail.dataInicio"
          label="data fim"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- tolerancia atraso -->
      <v-col v-bind="prop.col" cols="5">
        <!-- //? maxValue duro -->
        <c-datetime-picker
          v-model="dataset.main.detail.toleranciaAtraso"
          :disabled="
            ![getEstado('cadastro').id, getEstado('aberto').id].includes(
              dataset.main.detail.idEstado
            )
          "
          label="tolerância atraso"
          maxValue="02:00"
          time
        />
      </v-col>
      <!-- tolerancia hora extra -->
      <v-col v-bind="prop.col" cols="5">
        <!-- //? maxValue duro -->
        <c-datetime-picker
          v-model="dataset.main.detail.toleranciaHoraExtra"
          :disabled="
            ![getEstado('cadastro').id, getEstado('aberto').id].includes(
              dataset.main.detail.idEstado
            )
          "
          label="tolerância hora extra"
          maxValue="02:00"
          time
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CPontoEletronicoEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
    }),
  },

  /// METHODS
  methods: {
    dataChanged() {
      if (
        new Date(this.dataset.main.detail.dataInicio) >
        new Date(this.dataset.main.detail.dataFim)
      ) {
        this.dataset.main.detail.dataFim = null;
      }
    },
  },
};
</script>