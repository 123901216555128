<!-- COD: BJB 10/08/2022 CBaseMoney -->
<!-- *VER: JER 10/08/2022 CBaseMoney -->
<!-- /22/ -->

<template>
  <div>
    <v-text-field
      v-bind="$attrs"
      v-model="cmpValue"
      :class="classe"
      :clearable="clearable"
      :disabled="disabled"
      :label="label"
      :maxlength="max"
      :placeholder="placeholder"
      :prefix="options.prefix"
      :readonly="readonly"
      :ref="reference"
      :rules="rules"
      :suffix="cmpValue ? options.suffix : null"
      @blur="onBlur"
      @change="$emit('change', $event)"
      @click:clear="$emit('clickClear', $event)"
      @keypress="keyPress"
      @keyup="$emit('keyup', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  /// MODEL
  model: { prop: "value", event: "input" },

  /// PROPS
  props: {
    aceitaNegativo: { type: Boolean, default: true },
    classe: { type: String, default: "text-body-1" },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    max: { type: Number, required: true },
    options: {
      type: Object,
      default: () => {
        return {
          locale: "pt-BR",
          prefix: "",
          suffix: "",
          length: 11,
        };
      },
    },
    placeholder: { type: String, default: undefined },
    // Other v-text-field properties
    properties: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: { type: Boolean, default: false },
    reference: { type: String, default: "" },
    rules: { type: [Array, String], default: () => [] },
    value: { type: [String, Number], default: "0" },
    valueOptions: {
      type: Object,
      default: () => {
        return {
          min: 0,
          minEvent: "SetValueMin",
        };
      },
    },
    valueWhenIsEmpty: { type: String, default: "" },
  },

  /// DATA
  data() {
    return {
      valorNegativo: false,
    };
  },

  /*
   v-model="cmpValue": Dessa forma, ao digitar, o valor é atualizado automaticamente no componente pai.
   O valor digitado entra pelo newValue do Set e é emitido para o componente pai.
   the-vue-mask nao funciona corretamente ao incluir valores existentes no componente pai.
  */

  ///COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    //? nome de variavel estranho
    cmpValue: {
      get: function () {
        return this.value !== null && this.value !== ""
          ? this.humanFormat(this.value.toString())
          : this.valueWhenIsEmpty;
      },
      set: function (newValue) {
        this.valorNegativo =
          this.valorNegativo || (this.value && this.value < 0);
        return this.$emit("input", this.machineFormat(newValue));
      },
    },

    locale() {
      return this.options.locale || this.getVar("locale");
    },

    precision() {
      return this.options.precision ?? this.getConst("config").money.precision;
    },
  },

  /// METHODS
  methods: {
    // Retira todos os caracteres não numéricos e zeros à esquerda
    cleanNumber(value) {
      let result = "";

      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");
        for (var i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              // Retirar zeros à esquerda
              if (arrayValue.length == 1 || arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }

      return result;
    },

    humanFormat(number) {
      if (isNaN(number)) {
        number = "";
      } else {
        // number = Number(number).toLocaleString(this.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
        if (this.options.style == "currency") {
          number = new Intl.NumberFormat(this.locale, {
            style: this.options.style,
            currency: this.options.currency || this.getVar("moedaCodigo"),
            maximumFractionDigits: this.precision,
            minimumFractionDigits: this.precision,
          }).format(Number(number));
        } else {
          number = Number(number).toLocaleString(this.locale, {
            maximumFractionDigits: this.precision,
            minimumFractionDigits: this.precision,
          });
        }
      }
      return number;
    },

    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    },

    keyPress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        // 46 is -
        if (keyCode == 45 || (this.cmpValue || "").length == 0) {
          this.valorNegativo = true;
        }
        $event.preventDefault();
      }
      if (this.targetLength()) {
        $event.preventDefault();
      }

      this.$emit("keypress", $event);
    },

    machineFormat(number) {
      if (number) {
        number = this.cleanNumber(number);
        // Ajustar quantidade de zeros à esquerda
        number = number.padStart(parseInt(this.precision) + 1, "0");
        // Incluir ponto na casa correta, conforme a precisão configurada
        if (this.precision > 0) {
          number =
            number.substring(0, number.length - parseInt(this.precision)) +
            "." +
            number.substring(
              number.length - parseInt(this.precision),
              number.length
            );
        }

        if (isNaN(number)) {
          number = this.valueWhenIsEmpty;
        }
      } else {
        number = this.valueWhenIsEmpty;
      }
      if (this.precision === 0) {
        number = this.cleanNumber(number);
      }
      if (this.valorNegativo && this.aceitaNegativo) {
        number = number * -1;
        this.valorNegativo = false;
      }

      return number;
    },

    onBlur() {
      if (
        (this.value ?? "").length === 0 ||
        parseFloat(this.value) <= this.valueOptions.min
      )
        this.$emit(
          this.valueOptions.minEvent || "SetValueMin",
          this.valueOptions.min
        );

      if (
        this.valueOptions.max &&
        parseFloat(this.value) >= this.valueOptions.max
      )
        this.$emit(
          this.valueOptions.maxEvent || "SetValueMax",
          this.valueOptions.max
        );
    },

    targetLength() {
      if (
        Number(this.cleanNumber(this.value).length) >=
        Number(this.options.length)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>