// #COD: BJB __/08/2022 constantes
// VER: JER __/08/2022 constantes
// 2?

export default {
  namespaced: true,

  /// STATE
  state: {
    app: {
      id: 1, // idPessoa do app
      nome: "App2",
      //?b remover constante version
      version: "v0.2.69-beta",
      dataRelease: new Date("2020-08-28 13:38:00"),
      userKey: "__app2_user",
      varKey: "__app2_var",
      baseUrlBackend: window.location.origin.includes("localhost")
        ? "http://localhost:3000"
        : process.env.VUE_APP_BACKEND_BASEURL ||
        "https://app2-backend.w4y.com.br",
      // usamos a url do backend com o IP para realizar testes do localhost pelo celular
      // baseUrlBackend: 'http://192.168.0.10:3000',
      corPrimaria: "#1565C0",
      urlSemImagem: "https://img.icons8.com/ios/500/no-image.png",
    },
    // link do facebook dev
    // https://developers.facebook.com/apps/550798775573238/settings/basic/
    facebook: {
      appId: "550798775573238",
      version: "v6.0",
      // Eduardo facebookId: "10159677441412891"
    },
    // link do google dev
    // https://developers.google.com/???
    google: {
      clientId:
        "30844414959-dvuhh2v6qvi0gpvu4gnurda0d36p5vpi.apps.googleusercontent.com",
      clientScret: "RjeaLqyrMSIph-4uogkVhr3C",
    },
    mapQuest: {
      endereco: "https://www.mapquestapi.com",
      key: "R7cSw1L0UA8rGqxWQIfomjNMAxxaylQa",
    },
    config: {
      urlGoogleMaps: "https://www.google.com/maps/place/",
      modo: {
        signin: { id: 0 },
        signup: { id: 1 },
        cadastro: { id: 2 },
      },
      tipoLogin: {
        cadastrar: { id: 0 },
        entrar: { id: 1 },
      },
      pageMode: {
        view: { id: 0 },
        insert: { id: 1 },
        edit: { id: 2 },
      },
      tipoBusca: {
        publicacoes: { id: 0 },
        produtos: { id: 1 },
        parceiros: { id: 2 },
        marcas: { id: 3 },
      },
      busca: {
        labelComprar: 100, // 3  // numero de pedidos
      },
      snackbar: {
        tamanho: 40, // caracteres
        timeout: 3000, // milissegundos
      },
      tooltip: {
        timeout: 5000, // milissegundos
      },
      alerta: {
        timeout: 500, // milissegundos
      },
      overlay: {
        timeout: 500, // milissegundos
      },
      bottomBar: {
        timeout: 0, // milissegundos //? confirmar tempo
      },
      bottomSheet: {
        timeout: 500, // milissegundos //? confirmar tempo
        heightBase: 60, // px
      },
      pendenciasAtualizar: 3, // minutos
      /*
      REMOVER ESTES COMENTARIOS
      patterns de telefonia
      All patterns are prefixed by "_" character, but please do not enter more than one "_" at the beginning. 
      All patterns can add comments, such as "_pattern".
      In patterns, some characters have special meanings:
        - [12345 - 9] ...Any digit in the brackets. In this example, 1, 2, 3, 4, 5, 6, 7, 8, 9 are allowed.
        - N ...Any digit from 2-9.
        - . ...Wildcard, matching one or more characters.
        - ! ...Wildcard, matching zero or more characters immediately.
        - X ...Any digit from 0 - 9.
        - Z ...Any digit from 1 - 9.
        - - ... Hyphen is to connect characters and it will be ignored.
        - [] Contain special characters ([x], [n], [z]) represent letters x, n, z.
      */
      tokens: {
        "*": {
          pattern: ".",
          legenda: "letra, número ou símbolo",
        },
        Y: {
          pattern: ".",
          transform: (value) => value.toLocaleUpperCase(),
          legenda: "letra maiúscula, número ou símbolo",
        },
        y: {
          pattern: ".",
          transform: (value) => value.toLocaleLowerCase(),
          legenda: "letra minúscula, número ou símbolo",
        },
        // { simbolos: "!@#..." }
        Z: {
          pattern: "[A-Za-z0-9<>]", // + simbolos
          simbolos: "-",
          legenda: "letra, número ou ",
        },
        // { simbolos: "!@#..." }
        S: {
          pattern: "[A-Za-z0-9<>]", // + simbolos
          simbolos: "-",
          transform: (value) => value.toLocaleUpperCase(),
          legenda: "letra maiúscula, número ou ",
        },
        // { simbolos: "!@#..." }
        s: {
          pattern: "[A-Za-z0-9<>]", // + simbolos
          simbolos: "-",
          transform: (value) => value.toLocaleLowerCase(),
          legenda: "letra minúscula, número ou ",
        },
        W: {
          pattern: "[A-Za-z0-9]",
          legenda: "letra ou número",
        },
        X: {
          pattern: "[A-Za-z0-9]",
          transform: (value) => value.toLocaleUpperCase(),
          legenda: "letra maiúscula ou número",
        },
        x: {
          pattern: "[A-Za-z0-9]",
          transform: (value) => value.toLocaleLowerCase(),
          legenda: "letra minúscula ou número",
        },
        // { simbolos: "!@#..." => [A-Za-z + simbolos] }
        "@": {
          pattern: "[A-Za-z<>]", // + simbolos
          simbolos: "-",
          legenda: "letra ou ",
        },
        // { simbolos: "!@#..." => [A-Za-z + simbolos] }
        B: {
          pattern: "[A-Za-z<>]", // + simbolos
          simbolos: "-",
          transform: (value) => value.toLocaleUpperCase(),
          legenda: "letra maiúscula ou ",
        },
        // { simbolos: "!@#..." => [A-Za-z + simbolos] }
        b: {
          pattern: "[A-Za-z<>]", // + simbolos
          simbolos: "-",
          transform: (value) => value.toLocaleLowerCase(),
          legenda: "letra minúscula ou ",
        },
        L: {
          pattern: "[A-Za-z]",
          legenda: "letra",
        },
        A: {
          pattern: "[A-Za-z]",
          transform: (value) => value.toLocaleUpperCase(),
          legenda: "letra maiúscula",
        },
        a: {
          pattern: "[A-Za-z]",
          transform: (value) => value.toLocaleLowerCase(),
          legenda: "letra minúscula",
        },
        // { simbolos: "!@#..." }
        N: {
          pattern: "[0-9<>]", // + simbolos
          simbolos: "-",
          legenda: "número ou ",
        },
        "#": {
          pattern: "\\d",
          legenda: "número",
        },
        "%": {
          pattern: "[0-9<>]",
          simbolos: "",
          legenda: "dígito-verificador (número<>)",
        },
        $: {
          pattern: "[<>]", // + simbolos
          simbolos: "-",
          legenda: "",
        },
        // customizaveis
        C: {},
        D: { transform: (value) => value.toLocaleUpperCase() },
        d: { transform: (value) => value.toLocaleLowerCase() },
        E: {},
        F: { transform: (value) => value.toLocaleUpperCase() },
        f: { transform: (value) => value.toLocaleLowerCase() },
        G: {},
        H: { transform: (value) => value.toLocaleUpperCase() },
        h: { transform: (value) => value.toLocaleLowerCase() },
        // '!': escape
        // '?': opcional
        // '()': grupo de caracteres (considera todos os caracteres do grupo)
        // '[]': conjunto de caracteres (considera apenas um dentre os caracteres do conjunto)
      },
      textarea: {
        maxRows: 5, // linhas //? remover
        max: 2000,
      },
      autocomplete: {
        searchLength: 3,
      },
      swipePixels: 50,
      injectLimit: 1000, // registros
      money: {
        width: 8, // pixel
        precision: 2, // decimal
      },
      pix: {
        ///?b remover chave após homologação.
        // chave pix utilizada em homologação para o banco do brasil.
        chave: "7f6844d0-de89-47e5-9ef7-e0a35a681615",
        carrinho: {
          ///?ee confirmar tempo de expiração
          expiracao: 600, // segundos
          solicitacaoPagador: "`App2 carrinho ${context.dataset.main.detail.id}`",
        },
        transacao: {
          ///?ee confirmar tempo de expiração
          expiracao: 36000, // segundos
          solicitacaoPagador: "`App2 transação ${context.dataset.main.detail.id}`",
        },
      },
      parceiro: {
        entrega: {
          raio: 100, // km
          tolerancia: 5, // minutos
        },
        categorias: {
          max: 4,
        },
      },
      carrinhos: {
        abertos: {
          min: 3,
          max: 10,
        },
      },
      pedido: {
        entrega: {
          atrasoWarning: 24, // horas
          atrasoError: 72, // horas
        },
      },
      avaliacao: {
        prazoAvaliacao: 30, // dias
        prazoResposta: 30, // dias
        prazoModeracao: 7, // dias
        periodoCalculo: 180, // dias
      },
      image: {
        params: {
          dimensao: {
            min: 400 //? 200 - 400 - 70 confirmar valor
          },
          aspectRatio: {
            min: "9:27", //? confirmar valor
            max: "27:9", //? confirmar valor
          },
          tamanho: {
            min: 2,
            max: 4000,
          },
        },
        config: {
          quality: 1,
          type: "image/png",
          orientation: 1,
        },
      },
    },
    //? remover localidades assim que possível
    localidades: {
      paises: {
        brasil: { id: 20300000 },
        estadosUnidos: { id: 10200000 },
      },
    },
    carousel: {
      interval: 3000,
      src: [
        require("@/assets/carrossel/bemVindo.png"),
        require("@/assets/carrossel/multiplosCarrinhos.png"),
        require("@/assets/carrossel/multiplosParceiros.png"),
        require("@/assets/carrossel/comprasRecorrentes.png"),
        require("@/assets/carrossel/agendamentoServicos.png"),
        require("@/assets/carrossel/reservaRestaurantes.png"),
        require("@/assets/carrossel/boasCompras.png"),
      ],
    },
    instale: {
      src: [
        "https://s3-sa-east-1.amazonaws.com/anexos.w4y.com.br/instale/instaleWindows.png",
        "https://s3-sa-east-1.amazonaws.com/anexos.w4y.com.br/instale/instaleAndroid.png",
        "https://s3-sa-east-1.amazonaws.com/anexos.w4y.com.br/instale/instaleIos.png",
      ],
    },
    //e redesSociais - corrigir
    redesSociais: {
      instagram: "https://www.instagram.com/eduardorodrigues6/",
      youtube:
        "https://www.youtube.com/playlist?list=PLjuC3X7tLqLR-FnGv5dw3XkHgI2-tM9gc",
      twitter: "https://www.twitter.com/EduardoRodrigg",
      facebook: "https://www.facebook.com/eduardo1rodrigues",
      whatsapp: "https://wa.me/5511994528999?text=%20",
      email: "mailto:eduardo66.rodrigues@gmail.com",
    },
  },

  /// GETTERS
  getters: {
    getConst: (state) => (chave) => {
      return state[chave];
    },
  },
};
