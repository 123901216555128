<!-- COD: BJB 27/07/2022 ValoresPagRec_DepositoBancario -->
<!-- *VER: JER 06/08/2022 ValoresPagRec_DepositoBancario -->
<!-- /21/ -->

<template>
  <v-form class="mb-10 pa-0" id="formSelect" ref="form">
    <c-base-list
      v-if="idPage && idPageAlt"
      :funcs="funcs"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :pageModeEdit="true"
    >
      <template #searchInSearch>
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="isDinheiro"
              label="dinheiro"
              @click="clickDinheiro"
            />
          </v-col>
        </v-row>
        <v-row v-bind="prop.row" class="mt-n8">
          <v-col v-bind="prop.col">
            <c-switch v-model="isCheque" label="cheque" @click="clickCheque" />
          </v-col>
        </v-row>
      </template>
      <template #insert>
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <span class="red--text text-body-1">
              selecione os valores a depositar
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-slot="{ context, dataset }">
        <c-valoresPagRec-page-depositoBancario-edit
          v-if="showDataset(dataset)"
          :context="context"
          :valorPagRec="dataset"
        />
      </template>
      <template #bottom="{ context }">
        <!-- conta bancaria -->
        <v-row v-bind="prop.row" class="mt-4">
          <v-col v-bind="prop.col">
            <c-select
              v-model="idPessoaParceiroContaBancaria"
              :items="contasBancarias"
              label="conta bancária"
              required
            />
          </v-col>
        </v-row>

        <!-- total selecionado -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" cols="6">
            <v-badge
              bottom
              color="grey"
              :content="itensSelecionados(context)"
              offset-y="35"
              :value="!!itensSelecionados(context)"
            >
              <c-text-money
                label="total selecionado"
                :max="13"
                :options="{
                  style: 'currency',
                  length: 11,
                  precision: 2,
                }"
                outlined
                readonly
                required
                :value="totalSelecionado(context)"
              />
            </v-badge>
          </v-col>
        </v-row>
      </template>
    </c-base-list>
  </v-form>
</template>

<script>
/// IMPORT
import CValoresPagRecPageDepositoBancarioEdit from "@/components/pages/valorPagRec/pages/CValoresPagRecPageDepositoBancarioEdit";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "ValoresPagRec_DepositoBancario",

  /// COMPONENTS
  components: {
    CValoresPagRecPageDepositoBancarioEdit,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idFeature: getFeature("optionDepositoBancario").id,
      idPage: getPagina("valoresPagRec").id,
      idPageAlt: getPagina("valorPagRec").id,

      funcs: {
        changed: (context) => {
          return (
            this.changeAllow &&
            context.datasets.some(
              (dataset, i) =>
                dataset.valorSelecionado !=
                context.datasetsTemp[i].valorSelecionado
            )
          );
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        clickSave: async (context) => {
          if (!this.$refs.form.validate()) {
            context.options.click.setSnackbar(
              true,
              "CAMPOS INVÁLIDOS",
              "Verificar campos inválidos.",
              "error",
              "",
              "depositarErro",
              this.getConst("config").snackbar.timeout,
              false
            );

            let interval = setInterval(() => {
              if (!context.options.click.snackbar.show) {
                clearInterval(interval);
                interval = null;
              }
            }, 100);

            return null;
          }

          const itensSelecionados = this.itensSelecionados(
            context.options.click
          );
          const totalSelecionado = this.totalSelecionado(context.options.click);
          const textoItensSelecionados =
            itensSelecionados > 1
              ? `dos ${itensSelecionados} itens selecionados`
              : "do item selecionado";
          const textoTotalSelecionado = `${this.numberFormat(
            totalSelecionado,
            "currency"
          )}`;

          const textoSelecionado = "serão associados ao depósito.";
          const acao = "Prosseguir";
          const texto = `${textoTotalSelecionado} ${textoItensSelecionados} ${textoSelecionado}\n${acao}?`;
          const color = "warning";

          context.options.click.setSnackbar(
            true,
            "DEPÓSITO BANCÁRIO",
            texto,
            color,
            "mdi-check",
            "depositarConfirm",
            this.getConst("config").snackbar.timeout,
            null
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "depositar") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          } else if (action == "depositarErro") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          } else if (action == "depositarConfirm") {
            this.depositarValoresPagRec(context);
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          return JSON.stringify({
            idFormaPagamento: [
              this.getTipo_item("formaPagamentoDinheiro").id,
              this.getTipo_item("formaPagamentoCheque").id,
            ],
            depositos: true,
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
          dataset.valorSelecionado = false;
          dataset.valorItem = dataset.valor;
          dataset.valorDepositar = null;
        },
      },

      changeAllow: true,
      idPessoaParceiroContaBancaria: null,
      isCheque: false,
      isDinheiro: false,
    };
  },

  /// COMPUTED
  computed: {
    contasBancarias() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ].contas.map((conta) => {
        return {
          value: conta.id,
          text: this.contaBancariaFormat(conta),
        };
      });
    },
  },

  /// METHODS
  methods: {
    clickCheque() {
      if (this.isCheque) {
        this.isDinheiro = false;
      }
    },

    clickDinheiro() {
      if (this.isDinheiro) {
        this.isCheque = false;
      }
    },

    async depositarValoresPagRec(context) {
      this.changeAllow = false;

      const metadados = {
        modo: context.getConst("config").modo.cadastro.id,
        idUsuario: context.getVar("idUsuario"),
        idPessoaParceiro: context.getVar("idPessoaParceiro"),
      };

      const dataset = {
        metadados,
        params: {
          itensSelecionados: context.datasets
            .filter((dataset) => dataset.valorSelecionado)
            .filter((dataset, i) =>
              Object.keys(dataset).some(
                (key) => dataset[key] != context.datasetsTemp[i][key]
              )
            ),
        },
      };

      if (dataset.params.itensSelecionados.length) {
        dataset.params.itensSelecionados = dataset.params.itensSelecionados.map(
          (detail) => {
            return {
              id: parseInt(detail.id),
              valorDepositar: parseFloat(detail.valorDepositar),
            };
          }
        );

        const method = "put";
        let url = `${context.getConst("app").baseUrlBackend}/${
          context.page.nome
        }/depositar`;

        const res = await context.submit(method, url, dataset);

        if (res) {
          context.datasets = JSON.parse(JSON.stringify(context.datasetsTemp));

          const conta_id = res.data;
          const itensSelecionados = context.datasets[0].itensSelecionados;
          const textoItensSelecionados =
            itensSelecionados > 1
              ? `para os ${itensSelecionados} itens selecionados.`
              : "para o item selecionado.";
          const textoSelecionado = `Foi criado o depósito bancário #${conta_id}`;
          const acao = "Detalhar";
          const texto = `${textoSelecionado} ${textoItensSelecionados}\n${acao}?`;
          const color = "success";

          context.setSnackbar(
            true,
            "DEPÓSITO BANCÁRIO",
            texto,
            color,
            "mdi-check",
            "depositar",
            this.getConst("config").snackbar.timeout,
            false
          );

          let interval = setInterval(() => {
            if (context.snackbar.params) {
              clearInterval(interval);
              const url = `/contaPagRec/${res.data}`;
              context.$router.push(encodeURI(url));
            } else if (!context.snackbar.show) {
              clearInterval(interval);
              interval = null;
              context.funcs.clickVoltar();
            }
          }, 100);
        }
      }
    },

    itensSelecionados(context) {
      return context.datasets.filter((dataset) => dataset.valorSelecionado)
        .length;
    },

    showDataset(dataset) {
      const formaPagamento = this.isDinheiro
        ? "formaPagamentoDinheiro"
        : this.isCheque
        ? "formaPagamentoCheque"
        : null;
      const idFormaPagamento = this.getTipo_item(formaPagamento)?.id || null;
      const ret =
        !idFormaPagamento || dataset.idFormaPagamento == idFormaPagamento;
      dataset.noDivider = !ret;
      return ret;
    },

    totalSelecionado(context) {
      return context.datasets
        .filter((dataset) => dataset.valorSelecionado)
        .reduce((acc, item) => {
          return acc + parseFloat(item.valorDepositar);
        }, 0);
    },
  },
};
</script>