<!-- COD: LYF 25/08/2022 CExtratoEdit -->
<!-- *VER: JER 30/08/2022 CExtratoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- banco_anexo -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.banco_anexo_endereco">
      <v-col v-bind="prop.col" align="center">
        <v-img :src="dataset.aux.detail.banco_anexo_endereco" width="30" />
      </v-col>
    </v-row>

    <!-- banco/agencia/conta -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idPessoaParceiroContaBancaria"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ this.contaBancariaFormat(dataset.aux.detail) }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- requer conciliacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-switch
          v-if="context.dataset.main.detail.isIntraday"
          v-model="dataset.main.detail.requireConciliacao"
          :disabled="hasServicoContratado"
          label="requer conciliação"
        />
        <v-chip
          v-bind="prop.chip"
          v-else-if="dataset.main.detail.requireConciliacao"
        >
          requer conciliação
        </v-chip>
      </v-col>
    </v-row>

    <!-- isIntraday -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.isIntraday">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip"> intraday </v-chip>
      </v-col>
    </v-row>

    <!-- dataInicioFim -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idEstado != getEstado('cadastro').id"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- dataEmissao -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idEstado != getEstado('cadastro').id"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">
          {{
            dataFormata(
              this.dataset.main.detail.dataEmissao,
              "date timeNoSecond"
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- saldo inicial -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.saldoInicial">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">saldo inicial</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.saldoInicial)"
        >
          {{
            numberFormat(context.dataset.main.detail.saldoInicial, "currency")
          }}
        </span>
      </v-col>
    </v-row>

    <!-- debitos -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.debitos">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="mr-n2 text-caption">
          {{ context.dataset.main.detail.debitos }}
        </span>
      </v-col>
      <v-col v-bind="prop.col" align="end" cols="1">
        <v-icon class="red--text">mdi-alpha-d-circle</v-icon>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.totalDebitos)"
        >
          {{
            numberFormat(context.dataset.main.detail.totalDebitos, "currency")
          }}
        </span>
      </v-col>
    </v-row>

    <!-- creditos -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.creditos">
      <v-col v-bind="prop.col" align="end" cols="5">
        <span class="mr-n2 text-caption">
          {{ context.dataset.main.detail.creditos }}
        </span>
      </v-col>
      <v-col v-bind="prop.col" align="end" cols="1">
        <v-icon class="blue--text">mdi-alpha-c-circle</v-icon>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.totalCreditos)"
        >
          {{
            numberFormat(context.dataset.main.detail.totalCreditos, "currency")
          }}
        </span>
      </v-col>
    </v-row>

    <!-- saldo final -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.saldoFinal">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">saldo final</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.saldoFinal)"
        >
          {{ numberFormat(context.dataset.main.detail.saldoFinal, "currency") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CExtratoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
    }),

    // contasBancarias() {
    //   return this.getVar("usuarios")[
    //     this.getVar("usuarios").findIndex(
    //       (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
    //     )
    //   ].contas.map((conta) => {
    //     return {
    //       value: conta.id,
    //       text: this.contaBancariaFormat(conta),
    //     };
    //   });
    // },

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    dataInicioFim() {
      return `${this.dataFormata(
        this.dataset.main.detail.dataInicio,
        "dateNoYear"
      )} - ${this.dataFormata(this.dataset.main.detail.dataFim, "date")}`;
    },

    hasServicoContratado() {
      return this.dadosParceiro.servicosContratados.some(
        (servicoContratado) => servicoContratado.produto == "contabilidade"
      );
    },
  },

  /// METHODS
  methods: {
    classValor(valor) {
      return parseFloat(valor) < 0 ? "red--text" : "";
    },
  },
};
</script>