var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":"","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-toolbar-title',[_vm._v("ASSOCIAR LOTES")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.clickSave}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1),_c('v-card-text',{ref:"associarLote",staticClass:"mx-0 pa-2 mt-8"},[_c('c-pedido-view',{attrs:{"context":_vm.context,"dataset":_vm.pedido}}),_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.dataset;
var index = ref.index;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.produtoFormat(_vm.pedido.aux.itens[index]))+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(item.quantidade)+" "+_vm._s(_vm.getTipo_item(_vm.pedido.aux.itens[index].idUnidadeMedida).codigo)+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false))]}},{key:"content",fn:function(ref){
var item = ref.dataset;
var index = ref.index;
return [_c('div',{staticClass:"mx-n1"},[_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":true,"value":_vm.lotes(item)},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var lote = ref.dataset;
return [(lote.nome)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(((lote.nome) + " " + (lote.idLote ? ("#" + (lote.idLote)) : "")))+" ")])])],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"9"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.dataInicioFim(lote))+" ")])]),_c('v-col',_vm._b({attrs:{"align":"end","cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.numberFormat( lote.quantidade, "decimal", _vm.pedido.aux.itens[index].decimalEstoque || 0 ))+" "+_vm._s(_vm.getTipo_item(_vm.pedido.aux.itens[index].idUnidadeMedida) .codigo)+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false))]}},{key:"content",fn:function(ref){
var lote = ref.dataset;
return [_c('div',{staticClass:"mx-0 mr-n4 px-0"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-btn',{attrs:{"icon":"","ripple":false},on:{"click":function($event){return _vm.dissociarLote(lote)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"itemsIniciais":lote.autocomplete ? [lote.autocomplete] : [],"label":"lote","path":("/lotes/autocomplete/lotes/" + (item.idProduto) + "/"),"required":"","rejectValues":_vm.rejectValuesLotes(item),"returnObject":"","rules":[]},on:{"change":function($event){return _vm.changeLote(lote)}},model:{value:(lote.autocomplete),callback:function ($$v) {_vm.$set(lote, "autocomplete", $$v)},expression:"lote.autocomplete"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"quantidade","max":5,"options":{
                          length: 5,
                          precision: 0,
                        },"required":""},on:{"change":function($event){return _vm.changeQuantidade(item, lote.index)}},model:{value:(lote.quantidade),callback:function ($$v) {_vm.$set(lote, "quantidade", $$v)},expression:"lote.quantidade"}})],1)],1)],1)]}}],null,true)})],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-btn',{attrs:{"disabled":_vm.getDiferencaQuantidade(item) <= 0,"icon":"","ripple":false},on:{"click":function($event){return _vm.associarLote(item)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-plus-circle")])],1)],1)],1)]}}]),model:{value:(_vm.itens),callback:function ($$v) {_vm.itens=$$v},expression:"itens"}}),_c('c-bottom-sheet',{attrs:{"bottomSheet":_vm.bottomSheet}})],1)],1),_c('c-snackbar',{attrs:{"snackbar":_vm.snackbar},on:{"click":function($event){return _vm.clickSnackbar($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }