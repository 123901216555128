// COD: BJB 15/08/2022 historicosPadrao
// *VER: JER 19/08/2022 historicosPadrao
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    historicosPadrao: [],
    historicosPadrao_contasContabeis: [],
  },

  /// GETTERS
  getters: {
    getHistoricosPadrao: state => () => {
      return state.historicosPadrao
        .map(element => {
          return {
            value: element.id,
            text: element.nomeAlt,
          };
        })
    },

    getHistoricoPadrao: state => search => {
      return state.historicosPadrao.length
        ? state.historicosPadrao
          .find(element => element.id == search || element.nome == search)
        : { search }
    },

    getHistoricosPadrao_contasContabeis: state => () => {
      return state.historicosPadrao_contasContabeis
    },

    getHistoricoPadrao_contaContabil: state => search => {
      return state.historicosPadrao_contasContabeis.length
        ? state.historicosPadrao_contasContabeis
          .find(element => element.id == search || element.nome == search)
        : { search }
    },
  },

  /// MUTATIONS
  mutations: {
    setHistoricosPadrao: (state, payload) => {
      state.historicosPadrao = []
      payload.forEach(element => {
        state.historicosPadrao.push(element)
      });
    },

    setHistoricosPadrao_contasContabeis: (state, payload) => {
      state.historicosPadrao_contasContabeis = []
      payload.forEach(element => {
        state.historicosPadrao_contasContabeis.push(element)
      });
    },
  },

  /// ACTIONS
  actions: {
    setHistoricosPadrao: (context, payload) => {
      context.commit("setHistoricosPadrao", payload)
    },
    setHistoricosPadrao_contasContabeis: (context, payload) => {
      context.commit("setHistoricosPadrao_contasContabeis", payload)
    },
  }
}