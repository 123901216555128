<!-- COD: BJB 09/08/2022 CBaseEstados -->
<!-- *VER: JER 10/08/2022 CBaseEstados -->
<!-- /11/ -->

<template>
  <!-- // BOTOES-ESTADO -->
  <v-container id="botoes-estado" class="mb-6">
    <v-row v-bind="prop.row" v-if="estados.length">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          v-if="objeto.idEstado != objetoTemp.idEstado && !idEstadoNext"
          classe="corDisabled--text"
          dark
          fab
          icon
          id="botao-estado-cancelar"
          outlined
          x-small
          @click="objeto.idEstado = objetoTemp.idEstado"
        >
          <v-icon>mdi-close</v-icon>
        </c-btn>

        <c-btn
          v-if="
            objeto.idEstado == objetoTemp.idEstado &&
            estadoAnterior &&
            estadoAnterior.value > 0
          "
          :classe="estadoAnterior ? estadoAnterior.color : ''"
          dark
          fab
          icon
          id="botao-estado-anterior"
          small
          @click="objeto.idEstado = estadoAnterior.value"
        >
          <v-icon>
            {{ estadoAnterior ? estadoAnterior.icon : "" }}
          </v-icon>
        </c-btn>

        <c-menu
          v-model="objeto.idEstado"
          classe="ml-n3"
          :disabled="isEstadoCancelar"
          :hasBtn="false"
          :items="estados"
        >
          <template #btn>
            <c-btn
              :classe="`font-weight-medium px-1 mx-3 text-overline white--text ${
                getEstado(objeto.idEstado).nomeAlt
              }`"
              rounded
            >
              {{ getEstado(objeto.idEstado).nomeAlt }}
            </c-btn>
          </template>
          <template #item="{ item }">
            <v-icon :class="`${item.color}--text`">
              {{ item.icon }}
            </v-icon>
            <v-list-item-title class="text-body-1 ml-2">
              {{ item.textAlt }}
            </v-list-item-title>
          </template>
        </c-menu>

        <c-btn
          id="botao-estado-posterior"
          :classe="`${estadoPosterior ? estadoPosterior.color : ''}`"
          dark
          fab
          icon
          small
          :style="btnEstadoPosteriorStyle"
          @click="objeto.idEstado = estadoPosterior.value"
        >
          <v-icon>
            {{ estadoPosterior ? estadoPosterior.icon : "" }}
          </v-icon>
        </c-btn>
      </v-col>
    </v-row>
    <v-row v-bind="prop.row" v-else>
      <v-col v-bind="prop.col" align="center">
        <span> Não há estado disponível </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "CBaseEstados",

  /// MIXINS
  mixins: [mixinUsuarios],

  /// PROPS
  props: {
    idEstadoNext: { type: [Number, String], required: false },
    isEstadoCancelar: { type: Boolean, required: false },
    maquinaEstado: { type: String, required: true },
    objeto: { type: Object, required: true },
    objetoTemp: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getMaquinaEstado_transicoes: "estados/getMaquinaEstado_transicoes",
      getMaquinaEstado: "estados/getMaquinaEstado",
      getPagina: "paginas/getPagina",
    }),

    btnEstadoPosteriorStyle() {
      return `visibility: ${
        this.objeto.idEstado == this.objetoTemp.idEstado &&
        this.estadoPosterior &&
        this.estadoPosterior.value > 0
          ? "inherit"
          : "hidden"
      }`;
    },

    estadoAnterior() {
      return this.estados.find((estado) => !estado.isAvancar && estado.isDefault);
    },

    estadoPosterior() {
      return this.estados.find((estado) => estado.isAvancar && estado.isDefault);
    },

    estados() {
      return this.getMaquinaEstado_transicoes(
        this.getMaquinaEstado(this.maquinaEstado).id,
        this.objetoTemp.idEstado,
        null
      )
        .filter((estado) =>
          !this.idEstadoNext
            ? !estado.isSistema
            : this.idEstadoNext == estado.value
        )
        .filter((estado) => {
          return (
            this.isEstadoCancelar ||
            ((!estado.idContexto ||
              estado.idContexto == this.objetoTemp.idTipo) &&
              this.direitosTestar(
                this.getPagina("maquinasEstados_transicoes").id,
                estado.idTransicao
              ))
          );
        })
        .filter((estado) => {
          if (
            this.maquinaEstado == "pedido" &&
            this.objetoTemp.idEstado == this.getEstado("expedicao").id
          ) {
            if (this.objeto.entregar) {
              return estado.value != this.getEstado("entregue").id;
            } else {
              return estado.value != this.getEstado("enviado").id;
            }
          }

          return true;
        });
    },
  },

  /// MOUNTED
  mounted() {
    if (
      this.idEstadoNext &&
      this.estados.find((estado) => estado.value == this.idEstadoNext)
    ) {
      this.objeto.idEstado = this.idEstadoNext;
    }
  },
};
</script>