<!-- COD: BJB 08/08/2022 Carrinho -->
<!-- *VER: JER 08/08/2022 Carrinho -->
<!-- /33/ -->

<!-- //? pensar sobre componentizar este arquivo -->
<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context }">
        <!-- /// DETAIL -->

        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <c-carrinho-edit :context="context" :dataset="dataset" />
        </div>
      </template>
      <template #bottom="{ context }">
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <div v-show="showBtns">
              <c-btn
                v-if="showAgenda(context)"
                block
                classe="mt-8 mb-5 primary white--text"
                @click="clickConsultarAgenda"
              >
                verificar agendamento
              </c-btn>
              <c-btn
                v-else-if="showFazerPedido(context)"
                block
                classe="mt-8 mb-5 success white--text"
                @click="pedidosGeradoresConfirmar(context)"
              >
                fazer pedido
              </c-btn>
            </div>
            <div v-if="showCodePix">
              <c-btn
                block
                classe="mt-8 mb-5 primary white--text"
                @click="funcs.qrcodeTimeout"
              >
                qr Code PIX
              </c-btn>
            </div>
          </v-col>
        </v-row>
        <c-base-dialog-pagamento
          v-model="showDialog"
          :code="code"
          @pagamentoConfirmado="pagamentoConfirmado(context)"
        />
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogPagamento from "@/components/bases/CBaseDialogPagamento";
import CCarrinhoEdit from "@/components/pages/carrinho/CCarrinhoEdit";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "Carrinho",

  /// COMPONENTS
  components: { CBaseDialogPagamento, CCarrinhoEdit },

  /// MIXINS
  mixins: [mixinPageDetail, mixinUtil],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("carrinho").id,
      idPageAlt: getPagina("carrinhos").id,
      code: "",

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("carrinho").id,
            idEstado: getEstado("aberto").id,
            isSuspenso: false,
            data: null,
            titulo: null,
            idCliente: null,
            isEntregar: true,
            idPessoaEndereco: null,
            idTipoEntrega: null,
            idPessoaTransportadora: null,
            transportadora: null,
            recorrenciaUnidades: null,
            idRecorrenciaUnidade: null,
            dataEntrega: null,
            dataRecorrenciaFim: null,
            isPagamentoOnline: true,
            idFormaPagamento: null,
            troco: null,
            idCartao: null,
            idPessoaCartao: null,
            documentoFederal: null,
            parcelas: 1,
            prazo1: 0,
            prazoN: 0,
            valorJuros: 0,
            valorFrete: 0,
            valorTotal: 0,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
            dataUltimoPedido: null,
            dataCalculoEntrega: null,
          },
          itens: [],
          cupons: [],
        },
        aux: {
          detail: {
            cliente_nome: null,
            cliente_idTipoPessoa: null,
            cliente_documentoFederal: null,
          },
          atual: {
            id: null,
          },
          itens: [],
          itensColaboradores: [],
          cupons: [],
          recorrenciasDias: [],
          clienteEnderecos: [],
          clienteCartoes: [],
          parceiros: [],
          parceirosCategorias: [],
          parceirosPagamentos: [],
          pagamentos: [],
          pedidos: [],
          agendas: [],
          autocomplete: {
            cupons: [],
          },
        },
        now: this.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      },

      funcs: {
        buttonIcon: (button, context) => {
          if (button.id == this.getFeature("optionCarrinhoAtual").id) {
            let icon = button.options.icon;

            if (
              context.dataset.main.detail.id == context.dataset.aux.atual.id
            ) {
              icon = "mdi-check-decagram";
            } else {
              icon = "mdi-decagram-outline";
            }

            return icon;
          } else {
            return button.options.icon;
          }
        },

        changed: (context) => {
          let ret = false;

          //carrinho
          if (context.datasetTemp) {
            Object.keys(context.dataset.main.detail).forEach((campo) => {
              if (
                context.dataset.main.detail[campo] !=
                context.datasetTemp.main.detail[campo]
              ) {
                ret = true;
              }
            });

            // itens
            if (!ret) {
              context.dataset.main.itens.forEach((item, index) => {
                Object.keys(item).forEach((campo) => {
                  if (!["options", "idPai"].includes(campo)) {
                    if (
                      !context.datasetTemp.main.itens[index] ||
                      item[campo] !=
                        context.datasetTemp.main.itens[index][campo]
                    ) {
                      ret = true;
                    }
                  }
                });
              });
            }

            // cupons
            if (!ret) {
              if (
                context.dataset.main.cupons.length !=
                context.datasetTemp.main.cupons.length
              )
                ret = true;

              if (!ret) {
                context.dataset.main.cupons.forEach((cupom) => {
                  if (!context.datasetTemp.main.cupons.includes(cupom))
                    ret = true;
                });
              }
            }

            // recorrencias
            if (!ret) {
              if (
                context.dataset.aux.recorrenciasDias.length !=
                context.datasetTemp.aux.recorrenciasDias.length
              )
                ret = true;

              if (!ret) {
                context.dataset.aux.recorrenciasDias.forEach((recorrencia) => {
                  if (
                    !context.datasetTemp.aux.recorrenciasDias.includes(
                      recorrencia
                    )
                  )
                    ret = true;
                });
              }
            }
          }

          return ret;
        },

        changeEndereco: (context) => {
          let ret = true;

          const clienteEndereco = context.dataset.aux.clienteEnderecos.find(
            (clienteEndereco) =>
              clienteEndereco.id == context.dataset.main.detail.idPessoaEndereco
          );

          if (
            context.dataset.main.detail.isEntregar &&
            !clienteEndereco?.compativel
          ) {
            ret = false;

            setTimeout(() => {
              context.dataset.main.detail.idPessoaEndereco =
                context.datasetTemp.detail.idPessoaEndereco;
            }, 10);

            context.params.setSnackbar(
              true,
              "ENDEREÇO",
              "Endereço incompatível com um dos parceiros do carrinho.",
              "warning"
            );
          } else if (
            context.dataset.main.detail.isEntregar &&
            !clienteEndereco?.cidadeAtiva
          ) {
            ret = false;

            context.params.setSnackbar(
              true,
              "ENDEREÇO",
              "A cidade deste endereço não está ativa, mas o pessoal de suporte do app ja foi acionado.",
              "warning"
            );
          }

          return ret;
        },

        clickSnackbar: async (action, context) => {
          if (action == "confirmarHorario") {
            context.params.agendaConfirmada = true;
          } else if (action == "verAgenda") {
            context.params.verAgenda = true;
          } else if (action == "setAtual") {
            context.funcs.setAtual(context);
          } else if (action == "detalhar") {
            const url = `/${context.page.nome}/${context.snackbar.params.main.detail.id}`;
            this.$router.push(url);
            await context.clickSearch();
          } else if (action == "move") {
            context.funcs.move(context);
          } else if (action == "esvaziar") {
            context.funcs.esvaziar(context);
          } else if (action == "delete") {
            await context.delete(context.dataset.main.detail.id);

            const carrinho = {
              id: context.dataset.main.detail.id,
              idAtual: context.dataset.aux.atual.id,
            };

            await context.setAtual(carrinho);
          } else if (action == "finalizar") {
            this.finalizar(context);
          } else if (action == "cadastro") {
            const url = `/pessoa/${this.dataset.main.detail.idCliente}`;
            this.$router.push({
              path: encodeURI(url),
              query: {
                edit: true,
              },
            });
          } else if (action == "login") {
            this.$router.push("/");
          }

          context.setSnackbar();
        },

        duplicar: async (context) => {
          const method = "post";
          const url = `${context.getConst("app").baseUrlBackend}/${
            context.pageAlt.nome
          }`;
          const newDataset = JSON.parse(JSON.stringify(context.dataset));
          newDataset.metadados.idUsuario = this.getVar("idUsuario");
          newDataset.metadados.idPessoaParceiro =
            this.getVar("idPessoaParceiro");
          newDataset.metadados.idEvento =
            this.getTipo_item("eventoIncluido").id;
          newDataset.main.detail.id = null;
          newDataset.main.detail.idEstado = context.getEstado("aberto").id;
          delete newDataset.main.cupons;

          newDataset.main.itens.forEach((item) => {
            item.id = null;
            item.idPai = null;
            item.preco = null;
          });

          const res = await context.submit(method, url, newDataset);
          context.setSnackbar(
            true,
            "DUPLICAR",
            `Criado carrinho ${res.data.main.detail.id}.\nDetalhar?`,
            "success",
            "mdi-check",
            "detalhar",
            context.getConst("config").snackbar.timeout,
            res.data
          );
        },

        esvaziar: (context) => {
          context.setSnackbar();
          context.dataset.main.itens.forEach((item) => (item.del = true));
          context.save();
        },

        esvaziarConfirmar: (context) => {
          context.setSnackbar(
            true,
            "ESVAZIAR CARRINHO",
            "Esvaziar o carrinho?",
            "error",
            "mdi-check",
            "esvaziar"
          );
        },

        finalizarConfirm: (context) => {
          context.setSnackbar(
            true,
            "FINALIZAR CARRINHO",
            "Finalizar o carrinho?",
            "warning",
            "mdi-check",
            "finalizar"
          );
        },

        fnParams: () => {
          const now = this.format(new Date(), "yyyy-MM-dd HH:mm:ss");

          return JSON.stringify({
            idUsuario: this.getVar("idUsuario"),
            idPessoaParceiroUsuario: this.getVar("idPessoaParceiro"),
            now,
          });
        },

        getMargem: (context, indice, setItens = false) => {
          let ret = "";

          const totalItens = parseFloat(context.funcs.totalItens(context));
          let preco = parseFloat(context.dataset.main.itens[indice].preco);

          if (totalItens && preco) {
            const valorJuros = parseFloat(
              context.dataset.main.detail.valorJuros || 0
            );

            const quantidade = (
              context.dataset.main.itens[indice].quantidade ?? 0
            ).clean(context.dataset.aux.itens[indice].decimalEstoque);

            const precoTotal = preco * quantidade;

            const valorJurosItemTotal = valorJuros * (precoTotal / totalItens);
            const valorJurosItem = valorJurosItemTotal / quantidade;

            const custoMedio = parseFloat(
              context.dataset.aux.itens[indice].custoMedio || 0
            );

            const precoBase = preco - valorJurosItem;
            const contribuicao = precoBase - custoMedio;
            const margem = contribuicao / precoBase;
            const margemFormatada = this.numberFormat(margem, "percent", 2);

            if (setItens) {
              ret = margem;
            } else if (context.params.ajustarPreco) {
              const margemContribuicaoItem =
                context.dataset.aux.itens[indice].margemContribuicao;

              if (
                margemContribuicaoItem != null &&
                margemContribuicaoItem != margem
              ) {
                const taxaJuros = context.dataset.aux.detail.taxaJuros || 0;

                preco = custoMedio / (1 - margemContribuicaoItem);
                preco = preco * (1 + taxaJuros / 100);

                context.dataset.main.itens[indice].preco = parseFloat(
                  preco.toFixed(2)
                );
              }
            }

            if (
              !context.params.isCliente() &&
              context.dataset.aux.parceiros[0].isMostrarMargemCarrinho
            ) {
              ret = `margem ${margemFormatada}`;
            }
          }

          return ret;
        },

        move: async (context) => {
          context.setSnackbar();
          const method = "put";
          const idUsuario = context.getVar("idUsuario");
          const idPessoaParceiro = context.getVar("idPessoaParceiro");
          const url = `${
            context.getConst("app").baseUrlBackend
          }/carrinhos/move/${idUsuario}/${idPessoaParceiro}`;
          const itemMove =
            context.dataset.main.itens[this.params.itemMoveIndice];
          itemMove.idPai = context.dataset.aux.atual.id;

          const res = await context.submit(method, url, itemMove);
          context.dataset = res.data;
          await this.funcs.posSubmit(context);

          this.setVar({ detailUpdated: true });

          context.setSnackbar(
            true,
            "MOVER ITENS",
            `Item movido para o carrinho atual (#${context.dataset.aux.atual.id}).`
          );
        },

        posSubmit: (context) => {
          context.carrinhosAtualizar();

          if (context.dataset.aux.transportadoras?.length) {
            const transportadora = context.dataset.aux.transportadoras.find(
              (transportadora) => transportadora.text == "local"
            );

            if (transportadora) {
              transportadora.text += ` (${this.numberFormat(
                context.dataset.aux.parceiros[0].entrega.valor,
                "currency"
              )})`;
            }
          }

          if (context.dataset.main.detail.troco) {
            context.dataset.main.detail.troco = parseFloat(
              context.dataset.main.detail.troco
            ).toFixed(2);
          }

          if (context.dataset.aux.atual.id == context.dataset.main.detail.id) {
            context.setVar({
              isEntregar: context.dataset.main.detail.isEntregar,
            });
            context.setVar({
              idPessoaEndereco: context.dataset.main.detail.idPessoaEndereco,
            });
          }

          context.dataset.main.itens.forEach((item, i) => {
            item.quantidade = parseFloat(item.quantidade).toFixed(
              context.dataset.aux.itens[i].decimalEstoque
            );

            item.preco = parseFloat(item.preco).toFixed(
              context.dataset.aux.itens[i].decimalValor
            );
          });

          context.dataset.aux.pedidos.forEach((pedido) => {
            pedido.estado = this.getEstado(pedido.idEstado).nomeAlt;
            pedido.tipo = this.getTipo_item(pedido.idTipo).nomeAlt;
          });

          context.dataset.main.itens.forEach((item, i) => {
            const decimalEstoque = context.dataset.aux.itens[i].decimalEstoque;
            context.params.rules[`quantidade${item.id}`] = [
              (value) =>
                (!!value && value.clean(decimalEstoque) > 0) || "inválido",
              (value) => {
                if (context.dataset.aux.itens[i].estoqueProprio == null) {
                  return true;
                }

                const estoque = parseFloat(
                  context.dataset.aux.itens[i].estoqueProprio
                ).toFixed(decimalEstoque);
                const saldo = estoque - (value ?? 0).clean(decimalEstoque);

                return saldo >= 0 || `estoque ${estoque}`;
              },
            ];
          });

          if (context.dataset.aux.cliente) {
            context.dataset.aux.itens.forEach((item, i) => {
              item.margemContribuicao = this.funcs.getMargem(context, i, true);
            });
          }

          const cuponsIndisponiveis = !!context.dataset.aux.cupons.filter(
            (cupom) =>
              !cupom.isDisponivel &&
              context.dataset.main.cupons.includes(cupom.value)
          ).length;

          if (cuponsIndisponiveis) {
            context.setSnackbar(
              true,
              "CUPONS INDISPONÍVEIS",
              "Verifique os cupons na cor vermelha.",
              "error"
            );
          }

          const panelIndex = context.panels.findIndex(
            (panel) => panel.id == this.getFeature("CCarrinhoPanelItens").id
          );

          if (panelIndex) {
            const panelsTemp = context.panels.slice();
            context.panelsOpen = [];

            context.dataset.aux.parceiros.forEach((parceiro, i) => {
              if (i > 0) {
                panelsTemp.push(panelsTemp[panelIndex]);
              }
            });

            panelsTemp.forEach((panel, i) => {
              if (panel.open) {
                context.panelsOpen.push(i);
              }
            });
          }

          if (
            context.dataset.main.detail.idEstado == this.getEstado("fechado").id
          ) {
            context.panelsOpen = [];

            const indexPanel = context.panels.findIndex(
              (panel) =>
                panel.idFeature == this.getFeature("CCarrinhoPanelPedidos").id
            );

            if (indexPanel) {
              context.panelsOpen.push(indexPanel);
            }
          }

          setTimeout(() => {
            context.datasetTemp = JSON.parse(JSON.stringify(context.dataset));

            context.params.changed = context.changed;
            context.params.clickSave = context.clickSave;
            context.params.setSnackbar = context.setSnackbar;
          }, 10);

          if (
            context.dataset.main.detail.idEstado == this.getEstado("aberto").id
          ) {
            setTimeout(() => {
              if (
                context.dataset.aux.parceirosCategorias.length &&
                this.getCategoria(
                  context.dataset.aux.parceirosCategorias[0][0].idCategoria
                ).allowParceiroUsarAgenda
              ) {
                if (
                  this.parseISO(
                    context.dataset.main.detail.dataEntrega.substring(
                      0,
                      context.dataset.main.detail.dataEntrega.length - 1
                    )
                  ) >= new Date()
                ) {
                  this.clickConsultarAgenda();
                }
              }
            }, 100);
          }

          if (context.dataset.aux.pedidos.length) {
            this.isComprarAgora = false;

            if (this.isComprarAgoraDetalharPedido) {
              this.$router.push(`/pedido/${context.dataset.aux.pedidos[0].id}`);
            }
          }

          if (this.isComprarAgora) {
            this.isComprarAgora = false;

            if (this.isComprarAgoraPagamento) {
              this.isComprarAgora = true;

              this.isComprarAgoraPagamento = false;
              const idFormaPagamentoCartaoCredito = this.getTipo_item(
                "formaPagamentoCartaoCredito"
              ).id;
              const idFormaPagamentoPagamentoInstantaneo = this.getTipo_item(
                "formaPagamentoInstantaneo"
              ).id;
              context.dataset.main.detail.troco = null;
              context.dataset.main.detail.idCartao = null;

              const cartoes = context.dataset.aux.clienteCartoes.filter(
                (clienteCartao) =>
                  clienteCartao.idFormaPagamento ==
                  idFormaPagamentoCartaoCredito
              );

              if (cartoes.length) {
                context.dataset.main.detail.idFormaPagamento =
                  idFormaPagamentoCartaoCredito;
                context.dataset.main.detail.idPessoaCartao = cartoes[0].value;
              } else {
                context.dataset.main.detail.idFormaPagamento =
                  idFormaPagamentoPagamentoInstantaneo;
              }
            }

            if (
              context.dataset.main.detail.isEntregar &&
              context.dataset.aux.transportadoras &&
              !context.dataset.main.detail.idPessoaTransportadora
            ) {
              this.isComprarAgora = true;

              context.dataset.main.detail.idPessoaTransportadora =
                context.dataset.aux.transportadoras[0].value;
            }

            if (!this.isComprarAgora) {
              setTimeout(() => {
                this.isComprarAgoraDetalharPedido = true;
                this.pedidosGeradoresConfirmar(context);
              }, 100);
            } else {
              context.clickSave();
            }
          }

          if (this.pageYOffset) {
            window.scrollTo(0, this.pageYOffset);
            this.pageYOffset = 0;
          }
        },

        posCancel: (context) => {
          context.dataset.aux.detail.itens = () => {
            return `${context.dataset.main.itens.length}
              ${context.dataset.main.itens.length > 1 ? "itens" : "item"}`;
          };
        },

        preValidate: async (context) => {
          this.pageYOffset = window.pageYOffset;
          delete context.dataset.main.proprietarios;
          context.dataset.now = this.format(new Date(), "yyyy-MM-dd HH:mm:ss");
        },

        qrcodeTimeout: async () => {
          this.code =
            this.dataset.aux.pagamentos[
              this.dataset.aux.pagamentos.length - 1
            ].textoImagemQRcode;

          this.copyToClipboard(this.code);

          setTimeout(() => {
            this.showDialog = true;
          }, 100);
        },

        setAtual: async (context) => {
          const carrinho = {
            ...context.dataset.main.detail,
            idAtual: context.dataset.aux.atual.id,
          };

          const ret = await context.setAtual(carrinho);

          const carrinhoAtual = ret.data;

          context.dataset.aux.atual = carrinhoAtual;
          context.datasetTemp.aux.atual = carrinhoAtual;
          this.setVar({ detailUpdated: true });
        },

        setAtualConfirmar: (context) => {
          const carrinho = {
            id: context.dataset.main.detail.id,
            idAtual: context.dataset.aux.atual.id,
          };

          context.setAtualConfirmar(carrinho);
        },

        totalItens: (context) => {
          return context.dataset.main.itens
            .filter((item) => !item.idItemPai)
            .reduce(
              (totalItens, item, i) =>
                (totalItens +=
                  item.preco *
                  (item.quantidade ?? 0).clean(
                    context.dataset.aux.itens[i].decimalEstoque
                  )),
              0
            );
        },
      },

      isComprarAgora: false,
      isComprarAgoraDetalharPedido: false,
      isComprarAgoraPagamento: false,

      params: {
        agendaConfirmada: null,
        ajustarPreco: true,
        autocompleteCupom: true,
        carrinho: true,
        consultarAgenda: false,
        context: null,
        isCliente: () => {
          return !this.dataset.aux.itens.some(
            (item) => item.idPessoaParceiro == this.getVar(`idPessoaParceiro`)
          );
        },
        itemMoveIndice: null,
        proprietariosModeView: true,
        recarregar: false,
        recarregarDatas: false,
        rules: {},
        showAgendas: false, // criado após refatorar
        showValores: true, // criado após refatorar
        tiposPagamentos: [
          { value: true, text: "pagar pelo app" },
          { value: false, text: "pagar ao parceiro" },
        ],
        valorTotalPagar: null,
        verAgenda: false,
      },

      pageYOffset: 0,
      showDialog: false,
    };
  },

  /// COMPUTED
  computed: {
    showBtns() {
      return (
        this.dataset.main.detail.idEstado == this.getEstado("aberto").id &&
        this.dataset.main.itens.length > 0
      );
    },

    showCodePix() {
      return (
        this.dataset.main.detail.idEstado == this.getEstado("confirmando").id
      );
    },
  },

  /// MOUNTED
  mounted() {
    this.isComprarAgora = !!parseInt(this.$route.query?.isComprarAgora);
    this.isComprarAgoraPagamento = this.isComprarAgora;
  },

  /// METHODS
  methods: {
    clickConsultarAgenda() {
      this.params.consultarAgenda = true;
    },

    cuponsVerifica(context) {
      let ret = true;

      const cuponsIndisponiveis = !!context.dataset.aux.cupons.filter(
        (cupom) =>
          !cupom.isDisponivel &&
          context.dataset.main.cupons.includes(cupom.value)
      ).length;

      if (cuponsIndisponiveis) {
        context.setSnackbar(
          true,
          "CUPONS INDISPONÍVEIS",
          "Verifique os cupons na cor vermelha.",
          "error"
        );

        ret = false;
      }

      return ret;
    },

    dataEntregaVerifica(context, data = null) {
      let ret = false;

      if (context.dataset.aux.parceirosAtendimentos) {
        data = data || new Date();

        const parceirosFechados = context.dataset.aux.parceiros.some(
          (parceiro) => parceiro.isFechado
        );

        if (!parceirosFechados) {
          if (
            context.dataset.main.detail.idTipoEntrega !=
            context.getTipo_item("entregaAgora").id
          ) {
            const dataEntrega = context.dataset.main.detail.dataEntrega;

            data = this.parseISO(
              dataEntrega.substring(0, dataEntrega.length - 1)
            );
          }

          const diaSemana = this.getDay(data);
          let hora = this.getHours(data);
          hora = hora < 10 ? `0${hora}` : hora;
          let minuto = this.getMinutes(data);
          minuto = minuto < 10 ? `0${minuto}` : minuto;
          const horaMinuto = `${hora}:${minuto}:00`;

          ret = !!context.dataset.aux.parceirosAtendimentos.find(
            (atendimento) =>
              !atendimento.isEntregaLocal ||
              !atendimento.entregaAbrangenciaCidade ||
              (atendimento.diaSemana == diaSemana &&
                atendimento.horarioInicio <= horaMinuto &&
                atendimento.horarioFim >= horaMinuto)
          );
        }

        if (!ret) {
          context.setSnackbar(
            true,
            "ENTREGA",
            `Oops... um ou mais parceiros estão fechados no momento. Você pode agendar a ${
              context.dataset.main.detail.isEntregar ? "entrega" : "retirada"
            } para uma data futura.`,
            "warning"
          );
        }
      }

      return ret;
    },

    finalizar(context) {
      context.dataset.main.detail.idEstado = context.getEstado("fechado").id;
      context.clickSave();
    },

    async pagamento(context, dataset) {
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const method = "post";
      const url = `${this.getConst("app").baseUrlBackend}/pagamentos`;

      const devedor = {
        nome: dataset.aux.detail.cliente_nome,
      };

      devedor[
        this.getTipo_item("pessoaFisica").id ==
        dataset.aux.detail.cliente_idTipoPessoa
          ? "cpf"
          : "cnpj"
      ] = dataset.aux.detail.cliente_documentoFederal;

      const datasetPagamento = {
        metadados: {
          idUsuario,
          idPessoaParceiro,
        },
        main: {
          detail: {
            isPagamento: false,
            isDevolucao: false,
            idCarrinho: dataset.main.detail.id,
            valor: context.defaultParams.valorTotalPagar,
            idUsuario,
          },
        },
        dataset: dataset,
      };

      if (
        dataset.main.detail.idFormaPagamento ==
        this.getTipo_item("formaPagamentoInstantaneo").id
      ) {
        datasetPagamento.main.cobranca = {
          calendario: {
            expiracao: this.getConst("config").pix.carrinho.expiracao,
          },
          devedor,
          valor: {
            original: context.defaultParams.valorTotalPagar.toString(),
          },
          chave: this.getConst("config").pix.chave,
          solicitacaoPagador: eval(
            this.getConst("config").pix.carrinho.solicitacaoPagador
          ),
        };
      } else {
        datasetPagamento.main.transaction = {
          Customer: {
            Name: devedor.nome,
          },
          Payment: {
            Type: "CreditCard",
            Amount: context.defaultParams.valorTotalPagar * 100,
            Installments: 1,
            SoftDescriptor: `Carrinho ${dataset.main.detail.id}`,
          },
        };
      }

      const ret = await context.submit(method, url, datasetPagamento);

      if (ret && ret.status == 200) {
        if (
          dataset.main.detail.idFormaPagamento ==
          this.getTipo_item("formaPagamentoInstantaneo").id
        ) {
          await context.clickSearch();

          this.funcs.qrcodeTimeout();

          context.setSnackbar(true, "PAGAMENTO", "Cobrança pix gerada.");
        } else {
          if (ret.data[0].dataConfirmacao) {
            if (ret.data[0].status == "Authorized") {
              setTimeout(() => {
                this.pedidosGeradores(context, dataset);
              }, this.getConst("config").overlay.timeout);
            } else {
              context.setSnackbar(
                true,
                "PAGAMENTO",
                "pagamento recusado",
                "error"
              );
            }
          } else {
            await context.clickSearch();

            context.setSnackbar(
              true,
              "PAGAMENTO",
              "Seu pagamento está em análise."
            );
          }
        }
      } else {
        context.setSnackbar(true, "PAGAMENTO", ret.data, "error");
      }
    },

    pagamentoConfirmado(context) {
      const text =
        "Obrigado pela confirmação do pagamento.\nAssim que o pagamento for processado, daremos prosseguimento a sua compra.";

      context.setSnackbar(true, "PAGAMENTO", text, "success");
    },

    async pedidosGeradores(context, dataset) {
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const method = "post";
      const url = `${this.getConst("app").baseUrlBackend}/pedidos/geradores/${
        dataset.main.detail.id
      }/${idUsuario}/${idPessoaParceiro}`;

      const ret = await context.submit(method, url, dataset);
      if (ret.status == 200) {
        setTimeout(async () => {
          await context.clickSearch();
        }, this.getConst("config").overlay.timeout);
      } else {
        context.setSnackbar(true, "PEDIDO", ret.data, "error");
      }
    },

    async pedidosGeradoresConfirmar(context) {
      const dataset = Object.assign({}, context.dataset);
      await context.clickSearch();

      if (!this.funcs.changeEndereco(context)) return;

      if (!this.cuponsVerifica(context)) return;

      if (!this.dataEntregaVerifica(context)) return;

      if (!context.$refs.form.validate()) {
        context.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );
        return;
      }

      // este trecho será usado no futuro, quando implementarmos o carrinho sem pessoa.
      if (!this.getVar("usuarios").length) {
        context.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
      } else if (
        this.getVar("usuarios").find(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        ).pessoa.idEstado == this.getEstado("cadastro").id
      ) {
        context.setSnackbar(
          true,
          "CADASTRO",
          "Cadastro incompleto. Deseja conluir cadastro?",
          "warning",
          "mdi-check",
          "cadastro"
        );

        return;
      }

      if (context.dataset.main.detail.isPagamentoOnline) {
        this.pagamento(context, dataset);
      } else {
        this.pedidosGeradores(context, dataset);
      }
    },

    showAgenda(context) {
      return (
        !context.changed &&
        this.dataset.aux.atual.id == this.dataset.main.detail.id &&
        this.dataset.aux.parceirosCategorias.length &&
        this.getCategoria(
          this.dataset.aux.parceirosCategorias[0][0].idCategoria
        ).allowParceiroUsarAgenda &&
        !this.params.agendaConfirmada
      );
    },

    showFazerPedido(context) {
      return (
        !context.changed &&
        this.dataset.aux.atual.id == this.dataset.main.detail.id
      );
    },
  },
};
</script>