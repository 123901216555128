<!-- COD: BJB 21/07/2022 CMenu -->
<!-- *VER: JER 10/08/2022 CMenu -->
<!-- /11/ -->

<template>
  <v-menu
    v-model="showOptions"
    v-if="items.length"
    bottom
    :disabled="disabled"
    offset-y
  >
    <template #activator="{}">
      <span @click="clickShowOptions">
        <c-btn
          v-if="hasBtn"
          :classe="`${classe} px-2 primary--text`"
          :disabled="disabled"
          :fab="image"
          :help="help"
          :icon="icon"
          text
          :x-small="image"
        >
          <slot name="btn" :itemSelected="itemSelected">
            {{ items[indexItem].text }}
          </slot>
        </c-btn>
        <slot v-else name="btn" :itemSelected="itemSelected">
          {{ items[indexItem].text }}
        </slot>
      </span>
    </template>
    <v-list class="pa-0 overflow-y-auto" max-height="300">
      <v-list-item
        v-for="(item, i) in items"
        :class="`py-0 px-3 ${color} ${selected(item)}`"
        :id="`item-${item.value}`"
        :key="`item${i}`"
        text
        @click="setItem(item.value)"
      >
        <slot :index="i" :item="item" name="item">
          <v-list-item-title class="text-body-2 ml-2">
            {{ item.text }}
          </v-list-item-title>
        </slot>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/// IMPORT
export default {
  /// NAME
  name: "CMenu",

  props: {
    classe: { type: String, default: "" },
    color: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    hasBtn: { type: Boolean, default: true },
    hasScroll: { type: Boolean, default: true },
    help: { type: Object },
    icon: { type: Boolean, default: false },
    image: { type: Boolean, default: false },
    items: { type: Array, required: true },
    value: { type: [Number, String], required: false },
  },

  /// DATA
  data() {
    return {
      clickAbortByDblClick: false,
      showOptions: false,
    };
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      },
    },

    indexItem() {
      return this.model
        ? this.items.findIndex((item) => item.value == this.model)
        : 0;
    },

    itemSelected() {
      return this.items.find((item) => item.value == this.model);
    },

    offsetX() {
      return this.image ? "10" : "18";
    },

    offsetY() {
      return "16";
    },
  },

  /// METHODS
  methods: {
    clickShowOptions() {
      this.showOptions = true;

      if (this.model && this.hasScroll) {
        setTimeout(() => {
          document.getElementById(`item-${this.model}`).scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }
    },

    selected(item) {
      return item.value && item.value == this.model ? "grey lighten-1" : "";
    },

    setItem(value) {
      this.model = value;
    },
  },
};
</script>