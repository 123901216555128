// COD: BJB 15/08/2022 cartoes
// *VER: JER 19/08/2022 cartoes
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    impostos: [],
  },

  /// GETTERS
  getters: {
    getImpostos: state => () => {
      return state.impostos
        .map(element => {
          return {
            value: element.id,
            text: element.nome,
          };
        })
    },

    getImposto: state => search => {
      return state.impostos.length
        ? state.impostos
          .find(element => element.id == search || element.nome == search)
        : { search }
    },
  },

  /// MUTATIONS
  mutations: {
    setImpostos: (state, payload) => {
      state.impostos = []
      payload.forEach(element => {
        state.impostos.push(element)
      });
    },
  },

  /// ACTIONS
  actions: {
    setImpostos: (context, payload) => {
      context.commit("setImpostos", payload)
    },
  }
}