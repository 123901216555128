<!-- COD: LYF 01/07/2022 CCarrinhos -->
<!-- *VER: JER 01/07/2022 CCarrinhos -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- cliente_nomeAlt -->
    <v-row
      v-bind="prop.row"
      v-if="getVar('idPessoaParceiro') != getVar('idUsuario')"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip" class="primary primary--text text-overline">
          {{ dataset.cliente_nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" cols="5">
        <span class="font-weight-medium text-body-1">
          {{ dateFormat(dataset.data, "date timeNoSecond", null, true) }}
        </span>
      </v-col>
      <!-- itens -->
      <v-col v-bind="prop.col" align="center" cols="3">
        <span class="font-weight-medium text-body-1">
          {{ itensFormatado }}
        </span>
      </v-col>
      <!-- valor total -->
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="font-weight-medium text-body-1">
          {{ numberFormat(dataset.valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- titulo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-1">
          {{ dataset.titulo }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CCarrinhos",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),

    itensFormatado() {
      return `${this.dataset.itens} ${
        this.dataset.itens > 1 ? " itens" : " item"
      }`;
    },
  },
};
</script>