<!-- COD: FDS 10/08/2022 CBaseList -->
<!-- *VER: BJB 08/09/2022 CBaseList -->
<!-- /33/ -->

<template>
  <div
    v-touch="{
      down: ($event) =>
        !isChanged ? swipe('down', swipeRefresh, $event) : null,
      up: ($event) => swipe('up', swipeInject, $event),
    }"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar v-if="!isDetailList" mode="page" :title="title">
      <template #icon>
        <c-btn icon router to="/">
          <v-icon class="ml-2" large>
            {{ icon }}
          </v-icon>
        </c-btn>
      </template>
    </c-the-navbar>

    <!-- /// REFRESH -->
    <!-- refresh -->
    <v-row
      v-bind="prop.row"
      v-if="
        !isTouchDevice && !isChanged && (!pageModeEdit || offsetAtual > limit)
      "
      class="mt-4"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn fab small @click="swipeRefresh">
          <v-icon>{{
            offsetAtual > limit ? "mdi-arrow-up" : "mdi-refresh"
          }}</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <!-- parceiro -->
    <v-row
      v-bind="prop.row"
      v-if="getVar('idUsuario') != getVar('idPessoaParceiro') && !isDetailList"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip class="primary primary--text text-overline" outlined x-small>
          {{ parceiro.pessoa.nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <slot name="header" :context="context"></slot>

    <!-- /// CONTEXTO -->
    <!-- parceiro -->
    <v-row
      v-bind="prop.row"
      v-if="page.idContexto && !noContexto"
      justify="center"
    >
      <v-col v-bind="prop.col" align="center">
        <v-slide-group
          v-model="page.idContexto"
          center-active
          class="mt-5"
          mandatory
          style="width: fit-content"
          @change="changeContexto($event)"
        >
          <v-slide-item
            #default="{ active, toggle }"
            v-for="(tipo, i) in tipos"
            :key="i"
            :value="tipo.value"
          >
            <!-- active-class="primary white--text" -->
            <c-btn
              class="mx-2"
              :class="tipo.value != page.idContexto ? 'primary--text' : ''"
              color="primary"
              depressed
              :input-value="active"
              :outlined="tipo.value != page.idContexto"
              rounded
              @click="toggle"
            >
              {{ translate(getTipo_item(tipo.value).nomeAlt) }}
            </c-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>

    <!-- /// SEARCH -->
    <!-- v-if="idFilter" -->
    <c-base-list-search
      :filters="filters"
      :hasLayout="page.hasLayout"
      :hasData="!!page.hasData && !noData"
      :hasPaginacao="!noPaginacao && !page.noPaginacao"
      :hasSearch="hasSearch"
      :orders="orders"
      :proprietarios="page.hasProprietario ? proprietarios : null"
      @buscar="clickSearch()"
      @buscaLayout="isBuscaLayout = $event"
      @filter="changeFilter($event)"
      @order="changeOrder($event)"
      @paginacao="limit = $event"
      @proprietarios="idsProprietarios = $event"
      @search="
        search = $event;
        clickSearch();
      "
      @searchData="searchData = $event"
      @showSearchOptions="$emit('showSearchOptions', $event)"
      @ordem="
        ordem = $event;
        clickSearch();
      "
    >
      <template #searchCustomOptions>
        <slot :context="context" name="searchInSearch"></slot>
      </template>
    </c-base-list-search>

    <slot :context="context" name="search"></slot>

    <!-- /// LIST -->
    <v-list class="mt-8" dense subheader three-line>
      <v-container class="pa-0">
        <v-row v-bind="prop.row" class="mt-n4">
          <!-- quantidade itens -->
          <v-col v-bind="prop.col" cols="2">
            <v-badge
              class="ml-2"
              :color="
                filter ? filter.options.color || 'corDisabled' : 'corDisabled'
              "
              :content="datasets.length.toString()"
            ></v-badge>
          </v-col>
          <!-- total itens -->
          <v-col v-bind="prop.col" align="end" cols="10">
            <v-badge
              v-if="totalItens != null"
              :content="numberFormat(totalItens, 'currency')"
              color="corDisabled"
              left
            />
          </v-col>
        </v-row>

        <!-- pessoa Parceiro -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <v-chip
              v-if="pessoaParceiro"
              class="primary white--text text-overline ml-3"
              :close="!!funcs.clickChip"
              @click:close="clickChip"
            >
              {{ pessoaParceiro }}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>

      <template v-if="this.datasets.length">
        <slot :context="context" :datasets="datasets" name="listContent">
          <v-list-item-group v-for="(group, i) in groups" :key="i">
            <!-- grupo -->
            <v-row v-bind="prop.row" class="sticky" justify="center">
              <v-subheader v-if="group" class="mt-4">
                <v-img
                  v-if="order && order.groupBy == 'localidade'"
                  alt="imagem"
                  contain
                  :src="getLocalidade(group).endereco"
                  width="30"
                />
                <v-chip
                  v-else
                  :class="classGroup(group)"
                  class="font-weight-medium my-4 text-overline white--text"
                  x-small
                >
                  {{
                    typeof group == "object" ? group[getVar("locale")] : group
                  }}
                </v-chip>
              </v-subheader>
            </v-row>

            <template v-for="(dataset, i) in datasetsSort(group)">
              <v-list-item
                v-if="!noList"
                class="pa-0"
                :key="i"
                @click="
                  pageAlt.id != page.id && !idFeature
                    ? clickRouter(`${action}/${dataset.id}`, i)
                    : null
                "
              >
                <slot
                  :context="context"
                  :dataset="dataset"
                  :filter="filter"
                  :index="i"
                  :order="order"
                ></slot>
              </v-list-item>
              <slot
                v-else
                :context="context"
                :dataset="dataset"
                :filter="filter"
                :index="i"
                :order="order"
              ></slot>
              <v-divider v-if="!dataset.noDivider" :key="(i + 1) * -1" />
            </template>
          </v-list-item-group>
        </slot>
      </template>

      <slot :context="context" name="insert"></slot>

      <slot :context="context" name="bottom"></slot>
    </v-list>

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />

    <!-- /// INJECT -->
    <v-row v-bind="prop.row">
      <!-- records -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-caption">
          {{
            `${
              offsetAtual - datasets.length + 1
            } - ${offsetAtual} de ${records}`
          }}
        </span>
      </v-col>
      <!-- plus -->
      <v-col v-bind="prop.col" cols="6">
        <c-btn
          v-if="showInject"
          class="mt-3 ml-n6"
          fab
          small
          @click="swipeInject"
        >
          <v-icon>mdi-arrow-down</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <c-btn
      v-scroll="onScroll"
      v-show="showFab"
      bottom
      class="mb-11"
      fab
      fixed
      right
      @click="toScroll"
    >
      <v-icon large>
        {{ iconScroll }}
      </v-icon>
    </c-btn>

    <c-the-navbar
      v-show="showBottom"
      bottom
      mode="page"
      :pageModeView="!pageModeEdit"
      :slots="buttonsBar.length"
      title=""
      @showMenuVoltar="menuOptions = false"
    >
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-base-enderecos v-if="button.nome == 'optionEnderecos'" :key="i" />
        <!-- @aplicar="enderecosAplicar()" -->
        <c-btn
          v-else-if="!Array.isArray(button)"
          icon
          :key="i"
          @click="button.clickFunction(button)"
        >
          <v-icon :color="button.options.color || 'white'" large>
            {{ button.options.icon }}
          </v-icon>
        </c-btn>
        <v-menu
          v-else-if="button.length"
          v-model="menuOptions"
          :key="i"
          offset-y
          top
        >
          <template #activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon large>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, i) in button"
              :key="i"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </c-the-navbar>

    <!-- /// SNACKBAR -->
    <c-snackbar
      :snackbar="snackbar"
      @click="funcs.clickSnackbar($event, context)"
    />
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseEnderecos from "@/components/bases/CBaseEnderecos";
import CBaseListSearch from "@/components/bases/CBaseListSearch";
import mixinTemplate from "@/mixins/mixinTemplate";

export default {
  /// NAME
  name: "CBaseList",

  /// COMPONENTS
  components: { CBaseEnderecos, CBaseListSearch },

  /// MIXINS
  mixins: [mixinTemplate],

  /// PROPS
  props: {
    funcs: { type: Object },
    hasSearch: { type: Boolean, default: true },
    idFeature: { type: Number },
    idPage: { type: Number, required: true },
    idPageAlt: { type: Number, required: true },
    noList: { type: Boolean, default: false },
    pageModeEdit: { type: Boolean, default: false },
    pessoaParceiro: { type: String },
  },

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      columns: [],
      dataset: null,
      datasetList: [],
      datasets: [],
      datasetsAll: [],
      datasetsSearch: 0,
      datasetsTemp: [],
      detailUpdated: false,
      idFilter: null,
      idOrder: getFeature("orderOrdenar").id,
      idsProprietarios: null,
      idTipo: getVar("idTipoPedido"),
      isBuscaLayout: true,
      limit: getVar("limit").selected,
      menuOptions: false,
      offsetAtual: 0,
      offsetLast: 0,
      ordem: "",
      pageYOffset: 0,
      params: null,
      proprietarios: null,
      records: 0,
      search: "",
      searchData: {
        inicio: "",
        fim: "",
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getPagina_feature: "paginas/getPagina_feature",
      getPagina_features: "paginas/getPagina_features",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    action() {
      return this.getFeature(this.pageAlt.idFeature).options?.route;
    },

    buttonsBar() {
      let ret = [];

      const changed = this.funcs.changed ? this.funcs.changed(this) : false;

      const buttons = this.funcs.adjustButtons
        ? this.funcs.adjustButtons(this.buttons, this)
        : this.buttons;
      const options = this.funcs.adjustOptions
        ? this.funcs.adjustOptions(this.options, this)
        : this.options;

      if (!this.idFeature || (!this.hasPaginaFeature && !changed)) {
        ret = [
          ...buttons.map((button) => {
            return {
              ...button,
              clickFunction: this.clickOption,
            };
          }),
          options,
        ];
      }

      if (this.idFeature && this.pageModeEdit) {
        if (!changed) {
          ret.unshift({
            clickFunction: this.funcs.clickVoltar,
            options: {
              click: this.context,
              icon: "mdi-arrow-left",
            },
          });
        } else {
          ret = [
            {
              clickFunction: this.funcs.clickCancel,
              options: {
                click: this.context,
                icon: "mdi-close",
                color: "red accent-3",
              },
            },
            {
              clickFunction: this.funcs.clickSave,
              options: {
                click: this.context,
                icon: "mdi-check",
                color: "green accent-3",
              },
            },
          ];
        }
      }

      return ret;
    },

    changed() {
      return this.funcs.changed ? this.funcs.changed(this) : false;
    },

    context() {
      return this;
    },

    isDetailList() {
      return this.paginaFeature?.options?.isDetailList;
    },

    filter() {
      if (!this.filters.length && this.idFilter) {
        return {
          ...this.getFeature(this.idFilter),
          idFeature: this.idFilter,
        };
      } else {
        return this.filters.find((filter) => filter.id == this.idFilter);
      }
    },

    filters() {
      return !this.paginaFeature?.options?.noFilters
        ? this.direitosFeatures(
            this.idPage,
            this.getTipo_item("featureFilter").id
          )
        : [];
    },

    groups() {
      return this.order?.groupBy
        ? [
            ...new Set(
              this.datasets.map((dataset) => dataset[this.order.groupBy])
            ),
          ]
        : [null];
    },

    isChanged() {
      return this.funcs.changed ? this.funcs.changed(this) : false;
    },

    noContexto() {
      return this.paginaFeature?.options?.noContexto;
    },

    noData() {
      return this.paginaFeature?.options?.noData;
    },

    noPaginacao() {
      return this.paginaFeature?.options?.noPaginacao;
    },

    order() {
      if (!this.filters.length && this.idFilter) {
        return {
          ...this.getFeature(this.idOrder),
          idFeature: this.idOrder,
        };
      } else {
        return this.orders.find((order) => order.id == this.idOrder);
      }
    },

    orders() {
      return !this.paginaFeature?.options?.noOrders
        ? this.direitosFeatures(
            this.idPage,
            this.getTipo_item("featureOrder").id
          )
        : [];
    },

    pageFeature() {
      let pageFeature = null;

      if (this.idFeature) {
        // const idPage = this.idFeature.pageAlt ? this.idPageAlt : this.idPage;

        pageFeature = this.getPagina_features(this.idPage)
          .map((el) => this.getPagina_feature(el.value))
          .find((el) => el.idFeature == this.idFeature);
      }

      return pageFeature;
    },

    pageAltFeature() {
      let pageAltFeature = null;

      if (this.idFeature) {
        // const idPage = this.idFeature.pageAlt ? this.idPageAlt : this.idPage;

        pageAltFeature = this.getPagina_features(this.idPageAlt)
          .map((el) => this.getPagina_feature(el.value))
          .find((el) => el.idFeature == this.idFeature);
      }

      return pageAltFeature;
    },

    paginaFeature() {
      let paginaFeature = {};

      if (this.idFeature) {
        // const idPage = this.idFeature.pageAlt ? this.idPageAlt : this.idPage;

        paginaFeature = this.pageFeature || this.pageAltFeature;
      }

      return paginaFeature;
    },

    hasPaginaFeature() {
      let ret = false;

      if (this.idFeature) {
        ret = !!(this.idFeature.pageAlt
          ? this.pageAltFeature
          : this.pageFeature);
      }

      return ret;
    },

    paramsSearchData() {
      return !!this.page.hasData && !this.noData && this.searchData;
    },

    parceiro() {
      return this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      );
    },

    showInject() {
      return (
        this.page.injectNext ||
        (this.datasetsSearch == this.limit &&
          this.datasets.length < this.getConst("config").injectLimit &&
          (!this.funcs.changed || !this.funcs.changed(this)))
      );
    },

    tipos() {
      const contexto = this.getTipo_item(this.pageAlt.idContexto);

      const tipos = this.getTipo_itens(contexto.idPai).filter((item) =>
        this.direitosTestar(this.getPagina("tipos_itens").id, item.value)
      );

      return this.funcs.tipos ? this.funcs.tipos(tipos, this) : tipos;
    },

    title() {
      return this.feature?.nomeAlt || this.page.nomeAlt;
    },

    totalItens() {
      return this.datasets.length && this.datasets[0].valorItem != undefined
        ? this.datasets.reduce((acc, item) => {
            return (acc += parseFloat(item.valorItem));
          }, 0)
        : null;
    },
  },

  /// WATCH
  watch: {
    limit() {
      this.setVar({ "limit.selected": this.limit });
    },

    params() {
      if (this.detailUpdated) {
        this.setVar({ detailUpdated: this.detailUpdated });
      }
    },

    searchData: {
      handler() {
        this.clickSearch();
      },
      deep: true,
    },
  },

  /// ACTIVATED
  async activated() {
    const rotas = this.getVar("rotas");
    if (rotas.length) {
      rotas[rotas.length - 1].name = this.title;
      rotas[rotas.length - 1].icon = this.icon;
      this.setVar({ rotas });
    }

    if (this.datasetsTemp.length) {
      this.datasets = JSON.parse(JSON.stringify(this.datasetsTemp));
    }

    this.setVar({ goBack: -1 });

    if (this.funcs.fnParams) {
      this.detailUpdated = true;
      this.params = await this.funcs.fnParams(this);

      setTimeout(async () => {
        this.detailUpdated = false;
      }, 10);
    }

    const fullPath = this.$route.query?.from?.fullPath || "";
    const pageAltNome = this.pageAlt?.nome || "";

    if (this.getVar("detailUpdated") || !fullPath.includes(pageAltNome)) {
      await this.clickSearch(this.offsetLast);
      this.setVar({ detailUpdated: false });
    }

    if (this.funcs.posLoad && this.datasets.length) {
      this.funcs.posLoad(this);
    }

    if (this.pageYOffset) {
      setTimeout(() => {
        window.scrollTo(0, this.pageYOffset);
        this.pageYOffset = 0;
      }, 100);
    }
  },

  /// DEACTIVATED
  deactivated() {
    this.datasets = [];
  },

  /// MOUNTED
  async mounted() {
    const rotas = this.getVar("rotas");
    if (rotas.length) {
      rotas[rotas.length - 1].name = this.title;
      rotas[rotas.length - 1].icon = this.icon;
      this.setVar({ rotas });
    }

    if (this.funcs.getDetail) {
      this.dataset = this.funcs.getDetail();
    }

    if (this.funcs.preLoad) {
      this.funcs.preLoad(this);
    }

    if (this.filters.length) {
      this.idFilter = (
        this.filters.find((filter) => filter.options?.isDefault) ||
        this.filters[0]
      ).idFeature;
    }

    const reloadComponents = this.getVar("reloadComponents");
    const component = this.page.nome.firstLetterToUpperCase();

    if (!reloadComponents.includes(component)) {
      reloadComponents.push(component);
      this.setVar({ reloadComponents });
    }

    if (this.funcs.fnParams) {
      this.params = await this.funcs.fnParams(this);
    }

    await this.clickSearch();

    if (this.funcs.posLoad) {
      this.funcs.posLoad(this);
    }
  },

  /// METHODS
  methods: {
    async changeContexto(event) {
      this.idTipo = event;
      this.setVar({ idTipoPedido: this.idTipo });
      if (this.funcs.fnParams) {
        this.params = await this.funcs.fnParams(this);
      }

      this.clickSearch();
    },

    changeFilter(event) {
      this.idFilter = event;

      if (this.$route.params.idFilter) {
        const route = this.$router.options.routes.find(
          (route) => route.name.toLowerCase() == this.page.nome
        );

        if (route) {
          const routeIgnore = this.getVar("routeIgnore");
          routeIgnore.push(this.$route.name);
          this.setVar({ routeIgnore });

          this.$router.push(route.path);
        }
      }

      const params = this.params ? JSON.parse(this.params) : null;
      this.params = JSON.stringify(params);

      this.clickSearch();
    },

    changeOrder(event) {
      this.idOrder = event;

      if (this.$route.params.idOrder) {
        const route = this.$router.options.routes.find(
          (route) => route.name.toLowerCase() == this.page.nome
        );

        if (route) {
          const routeIgnore = this.getVar("routeIgnore");
          routeIgnore.push(this.$route.name);
          this.setVar({ routeIgnore });

          this.$router.push(route.path);
        }
      }

      const params = this.params ? JSON.parse(this.params) : null;
      this.params = JSON.stringify(params);

      this.clickSearch();
    },

    classGroup(group) {
      switch (this.order?.groupBy) {
        case "tipo":
          return this.idPage == this.getPagina("cupons").id
            ? `${group}1`
            : "primary";
        case "estado":
          return group;
        case "tipoEntrega":
          return group == "entregar" ? "corEntregar" : "corRetirar";
        default:
          return "primary";
      }
    },

    async clickChip() {
      await this.funcs.clickChip(this);
      this.clickSearch();
    },

    async clickOption(paginaFeature) {
      const action =
        paginaFeature.options?.click || paginaFeature.options?.route;
      const search = this.search || "*";

      if (action == "clickIncluir") {
        if (this.funcs.clickIncluir) {
          this.funcs.clickIncluir(this);
        } else {
          this.$router.push(`/${this.pageAlt.nome}`);
        }
      } else if (action == "clickAlterar") {
        this.funcs.clickEditView();
      } else if (action == "clickExportar") {
        this.downloadCsv();
      } else if (action == "clickImportar") {
        this.funcs.clickImportar(this);
      } else if (action == "clickRelatorios") {
        const idContexto = this.page.idContexto || "*";
        let url = `/relatorios/${this.page.id}/${this.page.idTabela}/0/0/${idContexto}/${this.idFilter}/${this.idOrder}/${search}`;

        if (this.params) {
          url += `/${this.params}`;
        }
        this.$router.push(encodeURI(url));
      } else if (action == "clickGerenciar") {
        this.funcs.clickGerenciar();
      } else if (action == "clickInventario") {
        this.funcs.clickInventario();
      } else if (action == "clickFeriadosDoAno") {
        this.funcs.clickFeriadosDoAno();
      } else if (action == "clickRelatorioEstoque") {
        this.funcs.clickRelatorioEstoque();
      } else if (action == "clickRelatorioPedidos") {
        this.funcs.clickRelatorioPedidos();
      } else if (action == "clickIniciar") {
        this.funcs.clickIniciar(this);
      } else if (action == "clickDiferencas") {
        this.funcs.clickDiferencas(this);
      } else if (action == "clickFinalizar") {
        this.funcs.clickFinalizar(this);
      } else if (action == "clickNumerosSerie") {
        this.funcs.clickNumerosSerie(this);
      } else if (action == "clickDepositoBancario") {
        this.funcs.clickDepositoBancario(this);
      } else if (action == "clickPedidoSaidaGerar") {
        this.funcs.clickPedidoSaidaGerar(this);
      } else if (action == "clickHorasExtras") {
        this.funcs.clickHorasExtras();
      } else if (action == "clickOcorrencias") {
        this.funcs.clickOcorrencias();
      } else if (action == "clickVerbas") {
        this.funcs.clickVerbas();
      } else if (action == "clickRelatorioDiarioContabil") {
        this.funcs.clickRelatorioDiarioContabil();
      } else if (action == "clickRecalcular") {
        this.funcs.clickRecalcular(this);
      } else if (action == "clickReload") {
        this.getVar("socket").emit("reload");

        this.setSnackbar(
          true,
          "RELOAD",
          "Os usuários logados irão recarregar o app"
        );
      } else if (action == "clickAjuda") {
        const paginaDocumento = this.getPagina_documentos(
          this.getPagina("ajuda").id
        ).find(
          (paginaDocumento) =>
            paginaDocumento.options.feature &&
            this.getFeature(paginaDocumento.options.feature).id ==
              this.page.idFeature
        );

        const url = paginaDocumento ? `/ajuda#${paginaDocumento.id}` : "/ajuda";
        //? verificar this.page.nomeAjuda na forma nova de chamar a ajuda.
        // const url = `/ajuda#${this.page.nomeAjuda || this.page.nome}`;
        this.$router.push(encodeURI(url));
      }
    },

    clickRouter(to, indice) {
      this.setVar({ overlay: true });

      setTimeout(() => {
        this.setVar({ datasetsSelectedIndex: indice });
        this.pageYOffset = window.pageYOffset;
        this.$router.push(to);
      }, 10);
    },

    async clickSearch(offset = 0, changeFilter = false) {
      if (this.getVar("logout")) {
        return false;
      }

      const method = "get";
      const search = encodeURIComponent(this.search) || "*";
      const idContexto = this.page.idContexto || "*";
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = this.idFeature ? 1 : 0;
      let params = this.params ? JSON.parse(this.params) : null;
      const limit = params?.proprietarios ? 1000 : this.limit;
      const urlFeature = this.paginaFeature?.options?.url || "";
      let url = `${this.getConst("app").baseUrlBackend}/${
        this.page.nome
      }${urlFeature}/${escape(search)}/${idContexto}/${this.idFilter}/${
        this.idOrder
      }/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${limit}`;
      this.offsetLast = offset;

      if (this.funcs.fnParams) {
        params = await this.funcs.fnParams(this);
      }

      if (this.idsProprietarios) {
        params = params ? JSON.parse(params) : {};
        params.idsProprietarios = this.idsProprietarios;
        params = JSON.stringify(params);
      }

      if (this.paramsSearchData) {
        params = params ? JSON.parse(params) : {};
        params.searchData = this.searchData;
        params = JSON.stringify(params);
      }

      params = params ? JSON.parse(params) : {};
      params.ordem = this.ordem ? this.ordem : null;
      params = JSON.stringify(params);

      if (params) {
        url += `/${params}`;
      }

      let dataset = null;

      const scrollTop = offset <= 0;

      const res = await this.submit(method, url, dataset, scrollTop);

      if (res) {
        params = this.params ? JSON.parse(this.params) : null;

        if (params?.proprietarios) {
          this.proprietarios = res.data || [];
          params.proprietarios = false;
          this.params = JSON.stringify(params);

          if (!changeFilter) {
            this.clickSearch();
          } else {
            this.datasetsAll = [];
            this.datasets = [];
          }
        } else {
          //? implementar datasetList no backend de todas as listagens.
          if (Array.isArray(res.data)) {
            this.datasetList.datasets = res.data; //? remover após implementação.
          } else {
            this.datasetList = res.data;
          }

          this.datasetsSearch = this.datasetList.datasets.length;

          this.limit = Math.max(this.datasetsSearch, this.limit);

          if (this.funcs.posSubmit) {
            this.datasetList.datasets.forEach((dataset, i) =>
              this.funcs.posSubmit(
                dataset,
                this,
                this.datasetList.datasets,
                i,
                offset
              )
            );
          }

          if (offset <= 0 || this.page.injectNext) {
            this.datasetsAll = [...this.datasetList.datasets];
          } else {
            this.datasetsAll.push(...this.datasetList.datasets);
          }

          this.offsetAtual = this.datasetsAll.length;
          this.records =
            this.datasetsAll[this.datasetsAll.length - 1]?.records || 0;

          const diffOffset = this.offsetAtual - this.offsetLast;

          this.datasets = this.datasetsAll.filter(
            (_, i) => i >= this.offsetAtual - diffOffset && i < this.offsetAtual
          );

          this.datasetsTemp = JSON.parse(JSON.stringify(this.datasets));

          if (offset && offset > this.limit) {
            const scrollTo = document.documentElement.offsetHeight / 2;
            this.$vuetify.goTo(scrollTo);
          }

          if (!this.pageModeEdit) {
            const datasetsIds = this.datasets.map((dataset) => dataset.id);
            this.setVar({ datasetsIds });
          }

          this.setVar({ goBack: -1 });

          this.btnInjectClicked = false;

          setTimeout(() => {
            this.$vuetify.goTo(0);
          }, 10);
        }
      }
    },

    datasetsSort(group) {
      return !this.order?.groupBy
        ? this.datasets
        : this.datasets
            .filter((dataset) => dataset[this.order.groupBy] == group)
            .sortKeys(JSON.stringify(this.order.orderBy));
    },

    reloadList(sub = true) {
      if (!sub || this.offsetAtual > this.limit) {
        const limitMax = this.datasetsAll.length - this.offsetAtual;
        const limit = sub ? this.limit : Math.min(limitMax, this.limit);

        if (sub && this.offsetAtual > this.offsetLast) {
          const diffOffset = this.offsetAtual - this.offsetLast;
          this.offsetAtual += diffOffset * -1;
        } else {
          this.offsetAtual += limit * (sub ? -1 : 1);
        }

        this.datasets = this.datasetsAll.filter(
          (_, i) => i >= this.offsetAtual - limit && i < this.offsetAtual
        );
        this.datasetsTemp = JSON.parse(JSON.stringify(this.datasets));

        this.datasetsSearch = this.datasets.length;

        this.$vuetify.goTo(0);
      }
    },

    swipeInject() {
      if (this.showInject) {
        this.btnInjectClicked = true;

        if (this.offsetAtual && this.datasetsAll.length > this.offsetAtual) {
          this.reloadList(false);
        } else {
          this.clickSearch(this.datasetsAll.length);
        }
      }
    },

    swipeRefresh() {
      if (this.offsetAtual > this.limit) {
        this.reloadList();
      } else {
        this.clickSearch(-1);
      }
    },
  },
};
</script>