<!-- COD: LYF 06/07/2022 CPedidoEdit -->
<!-- *VER: JER 08/08/2022 CPedidoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- origem -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idOrigem">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1">
          {{ getTipo_item(dataset.main.detail.idOrigem).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.data,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- operacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idOperacao"
          :disabled="!!dataset.main.detail.id"
          :items="operacao"
          label="operação"
          @change="changeOperacao"
          required
        />
      </v-col>
    </v-row>

    <!-- pessoa -->
    <v-row v-bind="prop.row" v-show="showPessoa">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPessoa"
          :disabled="
            !dataset.main.detail.idOperacao || !!dataset.main.detail.id
          "
          :itemsIniciais="pessoaItemsIniciais"
          label="pessoa"
          :path="`/pessoas/autocomplete/${getTipoAutcompletePessoa}/0/0/${dataset.main.detail.idPessoaParceiro}/`"
          :rules="[]"
          required
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPedidoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// DATA
  data() {
    return {
      showPessoa: true,
    };
  },

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getOperacao: "operacoes/getOperacao",
      getOperacoes: "operacoes/getOperacoes",
      getTipo_item: "tipos/getTipo_item",
    }),

    getTipoAutcompletePessoa() {
      let ret = "fornecedoresClientes";

      if (this.dataset.main.detail.idOperacao) {
        const operacao = this.getOperacao(this.dataset.main.detail.idOperacao);

        if (operacao.hasCliente != null) {
          if (!operacao.hasCliente) {
            ret = "fornecedores";
          } else {
            ret = "cliente";
          }
        }
      }

      return ret;
    },

    operacao() {
      return this.getOperacoes().filter(
        (operacao) =>
          operacao.isEntrada ==
            (this.dataset.main.detail.idTipo ==
              this.getTipo_item(`pedidoEntrada`).id) &&
          (this.dataset.main.detail.id ||
            operacao.isEntrada ||
            (!operacao.options.hasResultado &&
              !operacao.options.hasNumeroSerie))
      );
    },

    pessoaItemsIniciais() {
      return this.dataset.aux.autocomplete?.pessoas || [];
    },
  },

  /// METHODS
  methods: {
    changeOperacao() {
      this.dataset.main.detail.idPessoa = null;
      this.showPessoa = false;

      setTimeout(() => (this.showPessoa = true), 10);
    },
  },
};
</script>