var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[(_vm.dataset.aux.detail.pessoa_nome)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"classe":"primary--text","text":""},on:{"click":function($event){return _vm.clickPessoa()}}},[_vm._v(" "+_vm._s(_vm.dataset.aux.detail.pessoa_nome)+" ")])],1)],1):_vm._e(),(_vm.dataset.aux.detail.pessoa_nomeAlt)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dataset.aux.detail.pessoa_nomeAlt)+" ")])])],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"aceitaNegativo":false,"help":{
          title: 'CAPACIDADE',
          text: "É a capacidade de atendimento do parceiro. Quantas pessoas o parceiro consegue atender simultaneamente.",
          function: _vm.context.setUiBottomSheet,
        },"label":"capacidade","max":5,"options":{
          length: 4,
          precision: 0,
          style: 'decimal',
        },"type":"tel"},model:{value:(_vm.dataset.main.detail.capacidade),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "capacidade", $$v)},expression:"dataset.main.detail.capacidade"}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"aceitaNegativo":false,"help":{
          title: 'DURAÇÃO DA RESERVA',
          text: "Tempo de duração da reserva que o parceiro faz para seus clientes (horas).",
          function: _vm.context.setUiBottomSheet,
        },"label":"duração reserva","max":3,"options":{
          length: 2,
          precision: 1,
          style: 'decimal',
          suffix: 'h',
        }},model:{value:(_vm.dataset.main.detail.reservaDuracao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "reservaDuracao", $$v)},expression:"dataset.main.detail.reservaDuracao"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"help":{
          title: 'FATURAMENTO MENSAL',
          text: "Faixa de faturamento mensal que o parceiro realiza.",
          function: _vm.context.setUiBottomSheet,
        },"items":_vm.tiposFaturamentos,"label":"faturamento mensal"},model:{value:(_vm.dataset.main.detail.idFaturamentoMensal),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idFaturamentoMensal", $$v)},expression:"dataset.main.detail.idFaturamentoMensal"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"aceitaNegativo":false,"help":{
          title: 'PERCENTUAL ONLINE',
          text: "Percentual cobrado do parceiro sobre o valor do pedido, referente ao pagamento online.",
          function: _vm.context.setUiBottomSheet,
        },"label":"percentual online","max":6,"options":{
          length: 5,
          precision: 2,
          style: 'percent',
          suffix: '%',
        }},model:{value:(_vm.dataset.main.detail.percentualOnline),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "percentualOnline", $$v)},expression:"dataset.main.detail.percentualOnline"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"help":{
          title: 'LIMITE CONCILIAÇÃO',
          text: "Se o extrato conciliado mais recente exceder este prazo, sistema não permite a conciliação de lançamento do extrato intraday com valores pag/rec, forçando, com isso, que os extratos passados sejam conciliados.",
          function: _vm.context.setUiBottomSheet,
        },"items":_vm.limitesConciliacao,"label":"limite conciliação"},model:{value:(_vm.dataset.main.detail.limiteConciliacao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "limiteConciliacao", $$v)},expression:"dataset.main.detail.limiteConciliacao"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("acerto contas")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
          title: 'ACERTO DE CONTAS',
          text: "Dia da semana que será feito o acerto de contas entre app e parceiro.",
          function: _vm.context.setUiBottomSheet,
        }}},[_c('v-chip-group',{attrs:{"active-class":"primary"},model:{value:(_vm.dataset.main.detail.diaSemanaAcertoContas),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "diaSemanaAcertoContas", $$v)},expression:"dataset.main.detail.diaSemanaAcertoContas"}},_vm._l((_vm.diasSemanaAtendimento),function(diaSemana){return _c('v-chip',{key:diaSemana.value,attrs:{"value":diaSemana.value}},[_vm._v(" "+_vm._s(diaSemana.text)+" ")])}),1)],1)],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"fechado temporariamente?"},model:{value:(_vm.dataset.main.detail.isFechado),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "isFechado", $$v)},expression:"dataset.main.detail.isFechado"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }