<!-- COD: FDS 30/06/2022 CCartaoEdit -->
<!-- *VER: JER 13/09/2022 CCartaoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-menu
          v-model="dataset.main.detail.idPais"
          classe="ml-n4"
          :items="paises"
        >
          <template #btn="{ itemSelected }">
            <v-img
              v-if="dataset.main.detail.idPais"
              alt="imagem"
              class="mr-2"
              contain
              :src="imagem"
              width="25"
            />
            <v-icon v-else large color="grey">mdi-earth</v-icon>
            <span v-if="itemSelected && itemSelected.value" class="text=body-2">
              {{ itemSelected.text }}
            </span>
          </template>
          <template #item="{ item }">
            <v-img
              v-if="item.value"
              alt="imagem"
              contain
              :src="getLocalidade(item.value).endereco"
              width="25"
            />
            <v-icon v-else>mdi-earth</v-icon>
            <v-list-item-title class="ml-2 text-body-2">
              {{ item.text }}
            </v-list-item-title>
          </template>
        </c-menu>
      </v-col>
    </v-row>
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nomeAlt"
          :max="25"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- bandeira -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="dataset.main.detail.bandeira"
          label="bandeira"
          :max="25"
          required
        />
      </v-col>
      <!-- código segurança -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="dataset.main.detail.codigoSegurancaDigitos"
          :help="{
            title: 'CÓDIGO SEGURANÇA',
            text: 'Número de digitos do código de segurança (cvv) que está no verso do cartão.',
            function: context.setUiBottomSheet,
          }"
          label="código segurança"
          :max="3"
          required
        />
      </v-col>
    </v-row>

    <!-- observação -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.observacao"
          label="observação"
          :max="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CCartaoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidades: "localidades/getLocalidades",
      getTipo_item: "tipos/getTipo_item",
    }),

    paises() {
      return [{ value: null, text: "internacional" }, ...this.paisesAtivos];
    },

    imagem() {
      return this.dataset.main.detail.idPais
        ? this.getLocalidade(this.dataset.main.detail.idPais).endereco
        : "";
    },
  },
};
</script>