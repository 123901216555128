<!-- COD: FDS 04/07/2022 Parceiro -->
<!-- *VER: JER 26/07/2022 Parceiro -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <!-- /// COR PARCEIRO -->
          <c-parceiro-dialog-cor
            :show="showCorParceiro"
            @close="clickCorParceiro($event)"
          />

          <c-parceiro-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
            :params="params"
          />
          <c-parceiro-edit
            v-else
            :context="context"
            :dataset="dataset"
            :params="params"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import { VMoney } from "v-money";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CParceiroDialogCor from "@/components/pages/parceiro/dialogs/CParceiroDialogCor.vue";
import CParceiroEdit from "@/components/pages/parceiro/CParceiroEdit";
import CParceiroView from "@/components/pages/parceiro/CParceiroView";
import mixinLib from "@/mixins/mixinLib";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Parceiro",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CBaseDetailInsert,
    CParceiroDialogCor,
    CParceiroEdit,
    CParceiroView,
  },

  /// DIRECTIVES
  directives: { money: VMoney },

  /// MIXINS
  mixins: [mixinLib, mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("parceiro").id,
      idPageAlt: getPagina("parceiros").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: null,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoa: null,
            isInadimplente: false,
            isInventario: false,
            isFechado: false,
            capacidade: null,
            reservaDuracao: null,
            idFaturamentoMensal: null,
            percentualComissaoRevendaMaximo: null,
            hasMargemNoCarrinho: null,
            hasMargemNoPedido: null,
            numerosParcelasMaximo: null,
            prazoMedioPagamentoMaximo: null,
            diasBaseJuros: null,
            taxaJuros: null,
            allowPagamentoNaEntrega: false,
            isEntregar: false,
            isRetirar: false,
            idEntregaAbrangencia: null,
            isEntregaLocal: null,
            entregaRaio: null,
            retiradaTempo: null,
            percentualComissaoBase: 0,
            percentualComissaoAdicional: 0,
            limiteConciliacao: 7,
            diaSemanaAcertoContas: null,
            localizacaoMascara: null,
            pontoToleranciaMarcacoes: null,
            pontoHorasExtrasAutorizadas: null,
            corPrimaria: null,
            baseUrlExterno: null,
            loginExterno: null,
            senhaExterno: null,
            observacao: null,
            apresentacao: {},
            termosServico: {},
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          categorias: [],
          categorias_filhas: [],
          servicosContratados: [],
          idiomas: [],
          atendimentos: [
            {
              horarioInicio: null,
              horarioFim: null,
              horarioEntregaInicio: null,
              horarioEntregaFim: null,
            },
          ],
          atendimentos_dias: [],
          feriadosFacultativos: [],
          horarios: [],
          pagamentos: [
            {
              idFormaPagamento: null,
            },
          ],
          pagamentos_cartoes: [],
          entregas: [],
          transportadoras: [],
          gruposProdutos: [],
          inventariosAreas: [],
          inventariosColaboradores: [],
          despesas: [],
          reservas: [],
          beneficios: [],
          politicasAusencias: [],
          partesProdutos: [],
        },
        aux: {
          detail: {
            pessoa_idTipo: null,
            pessoa_nome: null,
            pessoa_nomeAlt: null,
          },
          parceiroApp: {
            percentualComissaoBase: 0,
            percentualComissaoAdicional: 0,
          },
          colaboradores: [],
          imagem: {
            id: null,
            endereco: null,
          },
        },
      },

      funcs: {
        clickCorParceiro: () => {
          this.clickCorParceiro();
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posCancel: (context) => {
          context.params.categoriasRecarregar();

          this.$vuetify.theme.themes.light.primary = this.getUsuario(
            this.getVar("idPessoaParceiro")
          ).parceiro.corPrimaria;

          if (this.$route.query?.idPanel) {
            if (
              this.$route.query.idPanel ==
              this.getFeature("CParceiroPanelServicosContratados").id
            ) {
              this.routeToPessoa(context);
            }
          }
        },

        posLoad: (context) => {
          if (!context.dataset.main.detail.id) {
            context.dataset.main.categorias = [];
          }

          if (!(this.$route.params.id > 0)) {
            context.dataset.main.detail.idPessoa = Math.abs(
              this.$route.params.id
            );
            context.params.context = context;

            context.dataset.aux.detail.idTipo = this.$route.params.idTipo;
            context.dataset.aux.detail.nome = this.$route.params.nome;
            context.dataset.aux.detail.nomeAlt = this.$route.params.nomeAlt;

            setTimeout(() => {
              context.clickEditView();
            }, 10);
          }

          if (this.$route.query?.idPanel) {
            setTimeout(() => {
              if (
                this.$route.query.idPanel ==
                this.getFeature("CParceiroPanelServicosContratados").id
              ) {
                context.clickEditView();
              }

              const index = context.$refs.panelsDetail.$children.findIndex(
                (panelDetail) =>
                  panelDetail.$vnode.componentOptions.tag ==
                  this.getFeature(this.$route.query.idPanel).nome
              );

              if (index >= 0) {
                context.panelsOpen = [index];
                context.goTo(this.getFeature(this.$route.query.idPanel).nome);
              }
            }, 500);
          }
        },

        posSubmit: (context, save = false) => {
          // context.params.diasSemana = this.diasSemana;
          if (
            save &&
            this.getUsuarios().find(
              (usuario) => usuario.value == context.dataset.main.detail.idPessoa
            )
          ) {
            context.usuariosAtualizar();
          }

          if (context.dataset.main.detail.corPrimaria) {
            this.$vuetify.theme.themes.light.primary =
              context.dataset.main.detail.corPrimaria;
          }

          context.params.categoriasRecarregar();

          context.dataset.aux.politicasAusencias =
            context.dataset.main.politicasAusencias.map((politicaAusencia) => {
              return {
                prescricao:
                  politicaAusencia.prescricaoMes &&
                  politicaAusencia.prescricaoDia
                    ? `${politicaAusencia.prescricaoMes}/${politicaAusencia.prescricaoDia}`
                    : politicaAusencia.prescricaoMes ||
                      politicaAusencia.prescricaoDia,

                suffixPrescricao:
                  (politicaAusencia.prescricaoMes &&
                    politicaAusencia.prescricaoDia) ||
                  (!politicaAusencia.prescricaoMes &&
                    !politicaAusencia.prescricaoDia)
                    ? null
                    : politicaAusencia.prescricaoMes
                    ? " meses"
                    : " dias",

                suffixTempo: ` ${
                  politicaAusencia.tempo == 1
                    ? this.getTipo_item(politicaAusencia.idUnidadeTempo).nomeAlt
                    : this.getTipo_item(politicaAusencia.idUnidadeTempo).options
                        .plural
                }${politicaAusencia.isUtil ? " úteis " : " "}por ano`,
              };
            });

          if (save && this.$route.query?.idPanel) {
            if (
              this.$route.query.idPanel ==
              this.getFeature("CParceiroPanelServicosContratados").id
            ) {
              this.routeToPessoa(context);
            }
          }
        },

        preValidate: async (context) => {
          if (
            !context.dataset.main.detail.isEntregar &&
            !context.dataset.main.detail.isRetirar
          ) {
            const err = {
              show: true,
              title: "ENTREGA",
              text: "Defina os tipos de entrega.",
              color: "error",
            };
            throw err;
          }

          if (!context.dataset.main.categorias.length) {
            const err = {
              show: true,
              title: "CATEGORIAS",
              text: "Defina as categorias.",
              color: "error",
            };
            throw err;
          }

          if (
            !context.dataset.main.atendimentos_dias.filter(
              (atendimentoDias) => !!atendimentoDias.length
            ).length
          ) {
            const err = {
              show: true,
              title: "DIAS ATENDIMENTO",
              text: "Defina os dias de todos os atendimentos.",
              color: "error",
            };
            throw err;
          }
        },
      },

      params: {
        cartoes: null,
        categoriasFilhas: [],
        categoriasNetas: [],
        categorias: this.categorias,

        categoriasRecarregar: () => {
          this.params.recarregaCategorias = true;

          setTimeout(() => {
            this.params.recarregaCategorias = false;
          }, 10);
        },

        clickEntregar: null,
        context: null,
        diasSemana: null,
        panelsOpen: null,
        personalizarComissao: false,
        prestadores: null,
        raioEntrega: null,
        recarregaCategorias: false,
        verbas: null,
      },

      categoriasSelected: [
        getVar("categoriasSelected")[0],
        getVar("categoriasSelected")[1],
        getVar("categoriasSelected")[2],
      ],
      diasSemana: [
        { value: 1, text: "S", nome: "segunda-feira" },
        { value: 2, text: "T", nome: "terça-feira" },
        { value: 3, text: "Q", nome: "quarta-feira" },
        { value: 4, text: "Q", nome: "quinta-feira" },
        { value: 5, text: "S", nome: "sexta-feira" },
      ],
      parceiro: getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == getVar("idPessoaParceiro")
      ).parceiro,
      parceiroCategorias: [],
      showCorParceiro: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getCategoria: "categorias/getCategoria",
      getCategorias: "categorias/getCategorias",
      getUsuarios: "variaveis/getUsuarios",
      getVar: "variaveis/getVar",
    }),
  },

  /// METHODS
  methods: {
    clickCorParceiro(event) {
      this.showCorParceiro = !this.showCorParceiro;
      if (this.showCorParceiro) {
        this.$vuetify.theme.themes.light.primary =
          this.dataset.main.detail.corPrimaria;
      } else {
        if (event) {
          this.dataset.main.detail.corPrimaria =
            this.$vuetify.theme.themes.light.primary;
          if (
            !event ||
            this.getVar("idPessoaParceiro") != this.dataset.main.detail.idPessoa
          ) {
            this.$vuetify.theme.themes.light.primary = this.getUsuario(
              this.getVar("idPessoaParceiro")
            ).parceiro.corPrimaria;
          }
        }
      }
    },

    routeToPessoa(context) {
      const url = `/pessoa/${context.dataset.main.detail.idPessoa}`;
      const idPanel = this.getFeature("CPessoaPanelContasBancarias")?.id;

      this.$router.push({
        path: url,
        query: {
          idPanel,
        },
      });
    },
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }

    this.params.clickCorParceiro = this.clickCorParceiro;
  },

  /// DESTROYED
  destroyed() {
    this.$vuetify.theme.themes.light.primary = this.getUsuario(
      this.getVar("idPessoaParceiro")
    ).parceiro.corPrimaria;
  },
};
</script>