<!-- COD: BJB 04/07/2022 CAssistenciasTecnicas -->
<!-- *VER: JER 08/07/2022 CAssistenciasTecnicas -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- status -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip" v-if="dataset.isProprio" class="mx-1">
          proprio
        </v-chip>
        <v-chip v-bind="prop.chip" v-if="dataset.isDefeito" class="mx-1">
          defeito
        </v-chip>
        <v-chip v-bind="prop.chip" v-if="dataset.isCliente" class="mx-1">
          cliente
        </v-chip>
        <v-chip
          v-bind="prop.chip"
          v-if="dataset.isPendenteFornecedor"
          class="mx-1"
        >
          fornecedor
        </v-chip>
      </v-col>
    </v-row>

    <!-- produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ produtoFormat(dataset) }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" v-if="dataset.numeroSerie">
      <!-- numero serie -->
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-2">NS</span>
        <span class="ml-1 text-body-1">
          {{ dataset.numeroSerie }}
        </span>
      </v-col>
      <!-- data -->
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="ml-1 text-body-1">
          {{ dateFormat(dataset.data, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- cliente nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoaCliente_nome }}
        </span>
      </v-col>
    </v-row>

    <!-- fornecedor nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoaFornecedor_nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CAssistenciasTecnicas",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>