<!-- COD: BJB 09/05/2023 CBaseDialogAssociarLotes -->
<!-- #VER: JER __/05/2023 CBaseDialogAssociarLotes -->
<!-- /21/ -->

<template>
  <v-dialog v-model="show" max-width="500px" persistent scrollable>
    <!-- /// ASSOCIAR LOTES -->
    <v-card class="mx-auto" max-width="500">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row justify="end">
            <v-toolbar-title>ASSOCIAR LOTES</v-toolbar-title>
          </v-row>
          <v-spacer />
          <v-btn icon @click="clickSave">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mx-0 pa-2 mt-8" ref="associarLote">
        <c-pedido-view :context="context" :dataset="pedido" />

        <c-expansion-panels v-model="itens" flat :pageModeView="true">
          <template #header="{ dataset: item, index }">
            <!-- produto -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <span class="text-body-2">
                  {{ produtoFormat(pedido.aux.itens[index]) }}
                </span>
              </v-col>
            </v-row>

            <!-- quantidade -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <span class="text-body-2">
                  {{ item.quantidade }}
                  {{
                    getTipo_item(pedido.aux.itens[index].idUnidadeMedida).codigo
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row v-bind="prop.row"> </v-row>
          </template>
          <template #content="{ dataset: item, index }">
            <div class="mx-n1">
              <c-expansion-panels
                flat
                :pageModeView="true"
                :value="lotes(item)"
              >
                <template #header="{ dataset: lote }">
                  <!-- lote -->
                  <v-row v-bind="prop.row" v-if="lote.nome">
                    <v-col v-bind="prop.col">
                      <span class="text-body-2">
                        {{
                          `${lote.nome} ${lote.idLote ? `#${lote.idLote}` : ""}`
                        }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row v-bind="prop.row">
                    <!-- data -->
                    <v-col v-bind="prop.col" cols="9">
                      <span class="text-body-2">
                        {{ dataInicioFim(lote) }}
                      </span>
                    </v-col>

                    <!-- quantidade -->
                    <v-col v-bind="prop.col" align="end" cols="3">
                      <span class="text-body-2">
                        {{
                          numberFormat(
                            lote.quantidade,
                            "decimal",
                            pedido.aux.itens[index].decimalEstoque || 0
                          )
                        }}
                        {{
                          getTipo_item(pedido.aux.itens[index].idUnidadeMedida)
                            .codigo
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-bind="prop.row"> </v-row>
                </template>
                <template #content="{ dataset: lote }">
                  <div class="mx-0 mr-n4 px-0">
                    <!-- botao menos -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <v-btn
                          icon
                          :ripple="false"
                          @click="dissociarLote(lote)"
                        >
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <!-- lote -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <c-autocomplete
                          v-model="lote.autocomplete"
                          :itemsIniciais="
                            lote.autocomplete ? [lote.autocomplete] : []
                          "
                          label="lote"
                          :path="`/lotes/autocomplete/lotes/${item.idProduto}/`"
                          required
                          :rejectValues="rejectValuesLotes(item)"
                          returnObject
                          :rules="[]"
                          @change="changeLote(lote)"
                        />
                      </v-col>
                    </v-row>

                    <!-- quantidade -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col" cols="4">
                        <c-text-money
                          v-model="lote.quantidade"
                          label="quantidade"
                          :max="5"
                          :options="{
                            length: 5,
                            precision: 0,
                          }"
                          required
                          @change="changeQuantidade(item, lote.index)"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </c-expansion-panels>
            </div>

            <!-- botao mais -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <v-btn
                  :disabled="getDiferencaQuantidade(item) <= 0"
                  icon
                  :ripple="false"
                  @click="associarLote(item)"
                >
                  <v-icon color="green">mdi-plus-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </c-expansion-panels>

        <!-- /// BOTTOMSHEET -->
        <c-bottom-sheet :bottomSheet="bottomSheet" />
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CPedidoView from "@/components/pages/pedido/CPedidoView";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CBaseDialogAssociarLotes",

  /// COMPONENTS
  components: {
    CPedidoView,
  },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinData, mixinSnackbar],

  /// PROPS
  props: {
    context: { type: Object, required: true },
    pedido: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {};
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    itens() {
      return this.pedido.main.itens;
    },
  },

  /// METHODS
  methods: {
    associarLote(item) {
      this.pedido.aux.lotes.push({
        idPedidoItemLote: null,
        idLote: null,
        nome: null,
        dataInicio: null,
        dataFim: null,
        quantidade: 0,
        idProduto: item.idProduto,
        idPedidoItem: item.id,
        autocomplete: null,
        del: false,
      });

      // setTimeout(() => {
      //   const params = {
      //     duration: 500,
      //     offset: 0,
      //     easing: "linear",
      //     container: this.$refs.associarLote,
      //   };

      //   const element = document.getElementById(
      //     `panel${this.pedido.aux.lotes.filter((lote) => !lote.del).length - 1}`
      //   );
      //   this.$vuetify.goTo(element, params);
      // }, 10);
    },

    changeLote(lote) {
      if (!this.pedido.aux.lotes[lote.index]?.autocomplete?.value) {
        this.pedido.aux.lotes[lote.index].autocomplete = null;
      }

      this.pedido.aux.lotes[lote.index].idLote =
        this.pedido.aux.lotes[lote.index]?.autocomplete?.value || null;
      this.pedido.aux.lotes[lote.index].nome =
        this.pedido.aux.lotes[lote.index]?.autocomplete?.text || "";
      this.pedido.aux.lotes[lote.index].dataInicio =
        this.pedido.aux.lotes[lote.index]?.autocomplete?.dataInicio || "";
      this.pedido.aux.lotes[lote.index].dataFim =
        this.pedido.aux.lotes[lote.index]?.autocomplete?.dataFim || "";
    },

    changeQuantidade(item, index) {
      const diferencaQuantidade = this.getDiferencaQuantidade(item);

      if (diferencaQuantidade < 0) {
        this.pedido.aux.lotes[index].quantidade =
          parseFloat(this.pedido.aux.lotes[index].quantidade) -
          Math.abs(diferencaQuantidade);
      }
    },

    async clickSave() {
      const datasets = {
        metadados: {
          idPessoaParceiro: this.getVar("idPessoaParceiro"),
          idUsuario: this.getVar("idUsuario"),
        },
      };
      const lotes = [];
      this.pedido.aux.lotes
        .filter((lote) => parseFloat(lote.quantidade) > 0)
        .forEach((lote) => {
          const objeto = {
            id: lote.idPedidoItemLote,
            idPai: lote.idLote,
            idPedidoItem: lote.idPedidoItem,
            quantidade: lote.quantidade,
            del: lote.del,
          };

          lotes.push(objeto);
        });

      datasets.lotes = lotes;

      const method = "put";
      let url = `${
        this.context.getConst("app").baseUrlBackend
      }/lotes/associarDissociar`;

      await this.context.submit(method, url, datasets);

      this.context.clickSearch();
      this.$emit("close");
    },

    dataInicioFim(lote) {
      let ret = "";
      const dataInicio = lote.dataInicio
        ? this.dateFormat(lote.dataInicio, "date")
        : "";

      const dataFim = lote.dataFim
        ? `- ${this.dateFormat(lote.dataFim, "date")}`
        : "";

      ret = `${dataInicio} ${dataFim}`;

      return ret;
    },

    dissociarLote(lote) {
      if (lote.idPedidoItemLote) {
        this.pedido.aux.lotes[lote.index].del = true;
      } else {
        this.pedido.aux.lotes.splice(lote.index, 1);
      }
    },

    getDiferencaQuantidade(item) {
      const itemQuantidade = parseFloat(item.quantidade);

      const quantidadeTotalAssociar = this.pedido.aux.lotes
        .filter((lote) => !lote.del && lote.idProduto == item.idProduto)
        .reduce((acc, lote) => (acc += parseFloat(lote.quantidade)), 0);

      return itemQuantidade - quantidadeTotalAssociar;
    },

    lotes(item) {
      this.pedido.aux.lotes.forEach((lote, i) => {
        lote.index = i;
        if (!Object.keys(lote).includes("autocomplete")) {
          lote.autocomplete = {
            value: lote.id,
            text: lote.nome,
          };
        }
      });

      return this.pedido.aux.lotes.filter(
        (lote) => !lote.del && lote.idProduto == item.idProduto
      );
    },

    itensIniciais(lote) {
      return [
        {
          value: lote.idLote,
          text: lote.nome,
        },
      ];
    },

    rejectValuesLotes(item) {
      return this.pedido.aux.lotes
        .filter((lote) => !lote.del && lote.idProduto == item.idProduto)
        .map((lote) => lote.idLote);
    },
  },
};
</script>