<!-- COD: BJB 09/08/2022 CBaseIdiomas -->
<!-- VER: JER 09/09/2022 CBaseIdiomas -->
<!-- /11/ -->

<template>
  <!-- /// IDIOMAS -->
  <div class="idiomas">
    <c-menu
      v-model="model"
      class="text-lowercase"
      :classe="classe"
      :hasScroll="false"
      :image="noText"
      :items="paises"
    >
      <template #btn="{ itemSelected }">
        <v-img
          v-if="itemSelected"
          :src="itemSelected.endereco"
          width="25"
          class="mr-2"
        />
        <span v-if="itemSelected && !noText" class="text=body-2">
          {{ itemSelected.text }}
        </span>
      </template>
      <template #item="{ item }">
        <v-img :src="item.endereco" width="25" class="mr-2" />
        <v-list-item-title class="text-body-2">
          {{ item.text }}
        </v-list-item-title>
      </template>
    </c-menu>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CBaseIdiomas",

  props: {
    classe: { type: String, default: "" },
    idiomas: { type: Array, required: true },
    size: { type: Number, default: 28 },
    noText: { type: Boolean, default: false },
    value: { type: Number, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
      getLocalidade_idioma: "localidades/getLocalidade_idioma",
      getTipo_item: "tipos/getTipo_item",
    }),

    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },

    paises() {
      return this.idiomas.map((idioma) => {
        const localidade = this.getLocalidade(
          this.getLocalidade_idioma(idioma.idLocale).idPai
        );
        const text = this.getTipo_item(
          this.getLocalidade_idioma(idioma.idLocale).idIdioma
        ).nomeAlt;

        return {
          ...idioma,
          ...localidade,
          value: idioma.idLocale,
          text,
        };
      });
    },
  },
};
</script>