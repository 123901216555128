<!-- COD: LYF 19/07/2022 CProdutoPanelCaracteristicaView -->
<!-- *VER: JER 28/07/2022 CProdutoPanelCaracteristicaView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- peso bruto -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.pesoBruto">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">peso bruto</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.pesoBruto }}
        </span>
        <span class="ml-0 text-body-2">Kg</span>
      </v-col>
    </v-row>

    <!-- peso liquido -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.pesoLiquido">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">peso líquido</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.pesoLiquido }}
        </span>
        <span class="ml-0 text-body-2">Kg</span>
      </v-col>
    </v-row>

    <!-- volume -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.volume">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">volume</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.volume }}
        </span>
        <span class="ml-0 text-body-2">L</span>
      </v-col>
    </v-row>

    <!-- largura -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.largura">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">largura</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.largura }}
        </span>
        <span class="ml-0 text-body-2">mm</span>
      </v-col>
    </v-row>

    <!-- altura -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.altura">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">altura</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.altura }}
        </span>
        <span class="ml-0 text-body-2">mm</span>
      </v-col>
    </v-row>

    <!-- profundidade -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.profundidade">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">profundidade</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.profundidade }}
        </span>
        <span class="ml-0 text-body-2">mm</span>
      </v-col>
    </v-row>

    <!-- unidades por caixa -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.unidadesPorCaixa">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">unidades por caixa</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.unidadesPorCaixa }}
        </span>
      </v-col>
    </v-row>

    <!-- cubagem -->
    <v-row v-bind="prop.row" v-if="showCubagem && cubagem">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">cubagem</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ numberFormat(cubagem, "decimal", 8) }}
        </span>
        <span class="ml-0 text-body-2">m³</span>
      </v-col>
    </v-row>

    <!-- peso cubado rodoviario -->
    <v-row v-bind="prop.row" v-if="showCubagem && pesoCubadoRodoviario">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">peso cubado rod</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ numberFormat(pesoCubadoRodoviario, "decimal", 3) }}
        </span>
        <span class="ml-0 text-body-2">Kg</span>
      </v-col>
    </v-row>

    <!-- peso cubado aereo -->
    <v-row v-bind="prop.row" v-if="showCubagem && pesoCubadoAereo">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">peso cubado aéreo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ numberFormat(pesoCubadoAereo, "decimal", 3) }}
        </span>
        <span class="ml-0 text-body-2">Kg</span>
      </v-col>
    </v-row>

    <!-- caracteristicas -->
    <v-row
      v-bind="prop.row"
      v-for="(caracteristica, i) in caracteristicas"
      :key="i"
    >
      <v-col v-bind="prop.col" v-if="caracteristica[1]" align="end" cols="4">
        <span class="text-body-2">
          {{ caracteristica[0] }}
        </span>
      </v-col>
      <v-col
        v-bind="prop.col"
        :align="caracteristicaAlign(caracteristica)"
        :cols="caracteristicaCols(caracteristica)"
      >
        <span class="text-body-1">
          {{ caracteristica[1] }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "CProdutoPanelCaracteristicaView",

  /// PROPS
  props: {
    dataset: { type: Object, required: true },
    params: {},
  },

  /// MIXINS
  mixins: [mixinUsuarios],

  /// DATA
  data() {
    const getTipo_Item = this.$store.getters["tipos/getTipo_item"];

    return {
      perfisCubagem: [
        getTipo_Item("perfilAdmin").id,
        getTipo_Item("perfilLogistica").id,
        getTipo_Item("perfilProdutos").id,
      ],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),

    caracteristicas() {
      return this.dataset.main.detail.caracteristicas[this.getVar("locale")];
    },

    cubagem() {
      const largura = this.dataset.main.detail.largura || "";
      const altura = this.dataset.main.detail.altura || "";
      const profundidade = this.dataset.main.detail.profundidade || "";

      return (
        (largura.clean(0) * altura.clean(0) * profundidade.clean(0)) /
        Math.pow(10, 9)
      );
    },

    pesoCubadoAereo() {
      return this.cubagem * 167; // duro
    },

    pesoCubadoRodoviario() {
      return this.cubagem * 300; // duro
    },

    showCubagem() {
      if (!this.getVar("usuarios").length) {
        return false;
      }

      return this.perfisTestar(this.perfisCubagem);
    },
  },

  /// METHODS
  methods: {
    caracteristicaAlign(caracteristica) {
      return caracteristica[0] ? "start" : "center";
    },

    caracteristicaCols(caracteristica) {
      return caracteristica[0] ? 8 : 12;
    },
  },
};
</script>