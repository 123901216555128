<!-- COD: BJB 17/08/2022 CBaseDocumento -->
<!-- *VER: JER 08/09/2022 CBaseDocumento -->
<!-- /32/ -->

<template>
  <div v-show="showDocumento" :class="divClass" style="width: 100%">
    <div v-if="!isPanel">
      <!-- titulo -->
      <div v-if="!pageModeView">
        <c-base-documento-edit
          :documento="documentoLocal"
          @clickEditView="clickEditView"
        />
      </div>
      <v-container v-bind="prop.container" v-else>
        <v-row v-bind="prop.row" v-if="showEdit && !documentoTitulo">
          <v-col v-bind="prop.col" align="end">
            <c-btn
              classe="ml-n4"
              icon
              @click.stop="pageModeView ? clickEditView($event) : null"
            >
              <v-icon :class="!pageModeView ? 'primary--text' : ''">
                mdi-pencil-outline
              </v-icon>
            </c-btn>
          </v-col>
        </v-row>
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" :cols="tituloCols">
            <span
              v-if="documentoTitulo"
              class="ml-2 primary--text text-h6 text-uppercase"
            >
              {{ documentoTitulo }}
            </span>
            <c-base-html
              v-else-if="documentoTexto && showTexto"
              :html="documentoTexto"
              @clickGoToHash="clickGoToHash($event)"
            />
          </v-col>
          <v-col
            v-bind="prop.col"
            v-if="showEdit && documentoTitulo"
            align="end"
            cols="1"
          >
            <c-btn
              classe="ml-n4"
              icon
              @click.stop="pageModeView ? clickEditView($event) : null"
            >
              <v-icon :class="!pageModeView ? 'primary--text' : ''">
                mdi-pencil-outline
              </v-icon>
            </c-btn>
          </v-col>
        </v-row>
        <!-- text -->
        <v-row v-bind="prop.row" v-if="documentoTitulo && documentoTexto">
          <v-col v-bind="prop.col">
            <c-base-html
              v-if="showTexto"
              :html="documentoTexto"
              @clickGoToHash="clickGoToHash($event)"
            />
          </v-col>
        </v-row>

        <c-base-documento
          v-for="documentoFilho in documentos(documento.id)"
          :context="context"
          :documento="documentoFilho"
          :hasAdminOptions="hasAdminOptions"
          :id="'open' in documentoFilho.options ? documentoFilho.id : null"
          :idPage="idPage"
          :key="documentoFilho.index"
          :pageModeViewPai="pageModeViewPai"
          @clickEditView="$emit('clickEditView')"
        />
      </v-container>
    </div>
    <div
      v-else
      class="v-expansion-panel v-expansion-panels--flat"
      style="width: 100%"
    >
      <v-expansion-panel :id="documento.id">
        <v-expansion-panel-header :class="panelHeaderClass">
          <!-- titulo -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="11">
              <v-icon v-if="documentoIcon" class="mr-1">
                {{ documentoIcon }}
              </v-icon>
              <span class="text-h6" v-if="documentoTitulo">
                {{ documentoTitulo }}
              </span>
            </v-col>
            <v-col v-bind="prop.col" v-if="showEdit" cols="1">
              <c-btn
                classe="ml-n4"
                icon
                @click.stop="pageModeView ? clickEditView($event) : null"
              >
                <v-icon :class="!pageModeView ? 'primary--text' : ''">
                  mdi-pencil-outline
                </v-icon>
              </c-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-n3">
          <div v-if="!pageModeView" class="mx-0" style="width: 100%">
            <c-base-documento-edit
              :documento="documentoLocal"
              @clickEditView="clickEditView"
            />
          </div>
          <v-container v-bind="prop.container" v-else>
            <!-- text -->
            <v-row v-bind="prop.row" v-if="documentoTexto" class="mt-4">
              <v-col v-bind="prop.col">
                <c-base-html
                  v-if="showTexto"
                  :html="documentoTexto"
                  @clickGoToHash="clickGoToHash($event)"
                />
              </v-col>
            </v-row>

            <template v-if="nivel != 3 && documentos(documentoLocal.id).length">
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="end">
                  <c-btn icon classe="mr-n2" @click="clickPanels">
                    <v-icon>
                      {{ iconPanelCollapse }}
                    </v-icon>
                  </c-btn>
                </v-col>
              </v-row>

              <v-expansion-panels
                v-model="panelsOpen"
                flat
                multiple
                :ref="`panelsDocumentos_${documentoLocal.id}`"
              >
                <c-base-documento
                  v-for="documentoFilho in documentos(documentoLocal.id)"
                  class="mt-1"
                  :context="contextLocal"
                  :documento="documentoFilho"
                  :hasAdminOptions="hasAdminOptions"
                  :id="
                    'open' in documentoFilho.options ? documentoFilho.id : null
                  "
                  :idPage="idPage"
                  :key="documentoFilho.index"
                  :pageModeViewPai="pageModeViewPai"
                  @clickEditView="$emit('clickEditView')"
                />

                <c-ajuda-elementos
                  v-if="documentoLocal.options.feature"
                  class="mt-1"
                  :documento="documentoLocal"
                  :id="`${documentoLocal.id}_mais`"
                  panel="mais"
                />

                <c-ajuda-elementos
                  v-if="documentoLocal.options.feature"
                  class="mt-1"
                  :documento="documentoLocal"
                  :id="`${documentoLocal.id}_videos`"
                  panel="videos"
                />

                <c-ajuda-elementos
                  v-if="documentoLocal.options.feature"
                  class="mt-2"
                  :documento="documentoLocal"
                  :id="`${documentoLocal.id}_links`"
                  panel="links"
                />
              </v-expansion-panels>
            </template>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </div>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CAjudaElementos from "@/components/pages/ajuda/CAjudaElementos";
import CBaseDocumentoEdit from "@/components/bases/CBaseDocumentoEdit";
import CBaseHtml from "@/components/bases/CBaseHtml";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "CBaseDocumento",

  /// COMPONENTS
  components: {
    CAjudaElementos,
    CBaseDocumentoEdit,
    CBaseHtml,
  },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinData, mixinSnackbar, mixinUsuarios],

  props: {
    context: { type: Object, required: true },
    documento: { type: Object, required: true },
    hasAdminOptions: { type: Boolean, required: true },
    idPage: { type: Number, required: true },
    pageModeViewPai: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {
      contextLocal: this,
      documentoLocal: null,
      documentoOptionsTemp: JSON.parse(JSON.stringify(this.documento.options)),
      goToHash: this.context?.goToHash,
      pageModeView: true,
      panels: [],
      panelsOpen: [],
      params: this.context?.params,
      showTexto: true,
      specificPanelsOpen: this.context?.specificPanelsOpen,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getPagina_documento: "paginas/getPagina_documento",
      getPagina_documentos: "paginas/getPagina_documentos",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    divClass() {
      return this.isPanel ? "v-expansion-panel v-expansion-panels--flat" : "";
    },

    documentoIcon() {
      return (
        this.documentoLocal.options.icon ||
        this.getFeature(this.documentoLocal.options.feature)?.options.icon ||
        null
      );
    },

    documentoTitulo() {
      let titulo =
        this.documentoLocal.options.idioma[this.locale]?.titulo ||
        this.translate(
          this.getTipo_item(this.documentoLocal.options.modulo)?.nomeAlt ||
            this.getFeature(this.documentoLocal.options.feature)?.nomeAlt ||
            ""
        );

      if (!titulo && this.documentoTituloVersao && this.documentoTituloData) {
        titulo = `${this.documentoTituloVersao}, ${this.documentoTituloData}`;
      }

      return titulo;
    },

    documentoTituloData() {
      return this.documentoLocal.options.data
        ? this.translate(
            this.dataFormataRelativa(this.documentoLocal.options.data, null)
          )
        : "";
    },

    documentoTituloVersao() {
      return this.documentoLocal.options.versao || "";
    },

    documentoTexto() {
      return this.documentoLocal.options.idioma[this.locale]?.texto;
    },

    iconPanelCollapse() {
      return !this.panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up";
    },

    isPanel() {
      const documentoLocal =
        this.documentoLocal || this.getPagina_documento(this.documento.id);
      return "open" in (documentoLocal.options || {});
    },

    isPanelOpen() {
      let ret = !this.isPanel;

      const documentoLocal =
        this.documentoLocal || this.getPagina_documento(this.documento.id);

      if (!ret) {
        const index = this.context.panels.findIndex(
          (idPanel) => idPanel == documentoLocal.id
        );

        ret = index >= 0 && this.context.panelsOpen.includes(index);
      }

      return ret;
    },

    locale() {
      return this.getVar("locale");
    },

    nivel() {
      return !this.documentoLocal.idDocumentoPai
        ? 1
        : this.getPagina_documento(this.documentoLocal.idDocumentoPai)
            .idDocumentoPai
        ? 3
        : 2;
    },

    panelHeaderClass() {
      return `${this.nivel == 3 ? "grey lighten-4" : "grey lighten-3"} px-1`;
    },

    showDocumento() {
      let ret = true;
      const tiposAtuacoes = this.documentoLocal?.options?.tiposAtuacoes || [];

      if (tiposAtuacoes.length) {
        ret = tiposAtuacoes
          .map((tipoAtuacao) => this.getTipo_item(tipoAtuacao).id)
          .includes(this.idAtuacao);
      }

      return ret;
    },

    showEdit() {
      return (
        this.hasAdminOptions &&
        ((this.pageModeViewPai && this.isPanelOpen) || !this.pageModeView)
      );
    },

    tituloCols() {
      return this.documentoTitulo ? 11 : 12;
    },
  },

  /// BEFOREMOUNT
  beforeMount() {
    this.documentoLocal = this.getPagina_documento(this.documento.id);
  },

  /// WATCH
  watch: {
    "context.params.clickSubPanelsOpen"() {
      if (this.context.params.clickSubPanelsOpen !== null) {
        this.panelsOpen = !this.context.params.clickSubPanelsOpen
          ? []
          : Array(this.panels.length)
              .fill()
              .map((_, i) => i);
      }
    },

    "context.specificPanelsOpen"() {
      this.specificPanelsOpen = this.context.specificPanelsOpen;
      this.goToHash = this.context.goToHash;

      this.specificPanelsOpen.forEach((idSpecificPanel) => {
        const index = this.panels.findIndex(
          (idPanel) => idPanel == idSpecificPanel
        );

        if (index >= 0 && !this.panelsOpen.includes(index)) {
          this.panelsOpen.push(index);
        }
      });
    },

    isPanelOpen() {
      if (this.isPanelOpen && !this.panels.length) {
        setTimeout(() => {
          this.panels = this.getPanels(
            this.$refs[`panelsDocumentos_${this.documentoLocal.id}`]
          );

          this.panels.forEach((panel, i) => {
            if (
              this.documentos(this.documentoLocal.id).find(
                (documento) => documento.id == panel.id
              )?.options?.isOpen
            ) {
              this.panelsOpen.push(i);
            }
          });

          this.specificPanelsOpen = this.context.specificPanelsOpen;
          this.goToHash = this.context.goToHash;

          this.specificPanelsOpen.forEach((idSpecificPanel) => {
            const index = this.panels.findIndex(
              (idPanel) => idPanel == idSpecificPanel
            );

            if (index >= 0 && !this.panelsOpen.includes(index)) {
              this.panelsOpen.push(index);
            }
          });
        }, 100);
      }
    },

    locale() {
      this.showTexto = false;

      setTimeout(() => (this.showTexto = true), 10);
    },

    panelsOpen() {
      const index = this.context.params.subPanelsOpen.findIndex(
        (panelOpens) => panelOpens[0] == this.documentoLocal.id
      );

      if (index >= 0) {
        this.context.params.subPanelsOpen.splice(index, 1);
      }

      this.context.params.subPanelsOpen.push([
        this.documentoLocal.id,
        this.panelsOpen,
      ]);
    },
  },

  /// METHODS
  methods: {
    clickEditView(save = false) {
      this.pageModeView = !this.pageModeView;

      if (this.pageModeView) {
        if (save) {
          this.documentoOptionsTemp = JSON.parse(
            JSON.stringify(this.documentoLocal.options)
          );
        } else {
          this.documentoLocal.options = JSON.parse(
            JSON.stringify(this.documentoOptionsTemp)
          );

          this.$forceUpdate();
        }
      }

      this.$emit("clickEditView");
    },

    clickGoToHash(hash) {
      this.goToHash(hash);
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? []
        : Array(this.panels.length)
            .fill()
            .map((_, i) => i);
    },

    documentos(idDocumentoPai) {
      return this.getPagina_documentos(this.idPage).filter(
        (documento) => documento.idDocumentoPai == idDocumentoPai
      );
    },

    getPanels(element, panels = []) {
      if (element) {
        element.$children.forEach((elementChildren) => {
          if (elementChildren.$attrs?.id) {
            panels.push(elementChildren.$attrs?.id);
          } else {
            panels = this.getPanels(elementChildren, panels);
          }
        });
      }

      return panels;
    },
  },
};
</script>