<!-- COD: FDS 30/06/2022 CCartaoView -->
<!-- *VER: JER 04/07/2022 CCartaoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais bandeira -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-img
          v-if="dataset.main.detail.idPais"
          alt="imagem"
          contain
          :src="getLocalidade(dataset.main.detail.idPais).endereco"
          width="30"
        />
        <v-icon v-else large>mdi-earth</v-icon>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- bandeira -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.bandeira">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">bandeira</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.bandeira }}
        </span>
      </v-col>
    </v-row>

    <!-- código segurança -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.codigoSegurancaDigitos">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">código segurança</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.codigoSegurancaDigitos }}
        </span>
        <span class="text-body-2">dígitos</span>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.observacao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">({{ dataset.main.detail.observacao }})</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CCartaoView",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
    }),
  },
};
</script>