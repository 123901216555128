<!-- COD: BJB 10/08/2022 CBasePanelInsert -->
<!-- *VER: JER 10/08/2022 CBasePanelInsert -->
<!-- /11/ -->

<template>
  <div>
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-btn
          class="ml-2"
          :disabled="elementsLength >= max || disabled"
          icon
          :ripple="false"
          @click="$emit('incluir')"
        >
          <v-icon color="green">mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CBasePanelInsert",

  /// PROPS
  props: {
    datasets: { type: [Array, Object] },
    disabled: { type: Boolean, default: false },
    panel: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    elementsLength() {
      return this.datasets.filter((element) => !element?.del).length;
    },

    max() {
      return this.panel.options.max;
    },
  },
};
</script>