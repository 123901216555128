<!-- COD: FDS 09/01/2023 CImpostoView -->
<!-- #VER: JER __/01/2023 CImpostoView -->
<!-- /11/ -->
<template>
  <v-container v-bind="prop.container">
    <!-- pais bandeira -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPais">
      <v-col v-bind="prop.col" align="center">
        <v-img
          alt="imagem"
          contain
          :src="getLocalidade(dataset.main.detail.idPais).endereco"
          width="30"
        />
      </v-col>
    </v-row>

    <!-- abrangencia -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idAbrangencia">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.main.detail.idAbrangencia).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CImpostoView",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
      getTipo_item: "tipos/getTipo_item",
    }),
  },
};
</script>