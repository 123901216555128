<!-- COD: BJB 25/07/2022 CHomeDialogAuthConfirm -->
<!-- *VER: JER 08/08/2022 CHomeDialogAuthConfirm -->
<!-- /11/ -->

<template>
  <!-- /// AUTHCONFIRM -->
  <v-dialog v-model="show" persistent scrollable width="380px">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Termos de Serviço alterados</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-2">
              Prosseguindo, você concorda com as alterações:
            </span>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-btn
              classe="px-1 ml-n1 text-body-1"
              color="primary"
              text
              @click="clickTermoServico('close', true)"
            >
              Termos de Serviço
            </c-btn>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-1">Prosseguir?</span>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="end" cols="6">
            <c-btn
              classe="px-1 ml-n1 text-body-1"
              color="primary"
              outlined
              rounded
              @click="clickCancel('close')"
            >
              Não
            </c-btn>
          </v-col>
          <v-col v-bind="prop.col" align="center" cols="6">
            <c-btn
              classe="px-1 ml-n1 text-body-1"
              color="primary"
              outlined
              rounded
              @click="clickConfirm"
            >
              Sim
            </c-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";

export default {
  /// NAME
  name: "CHomeDialogAuthConfirm",

  /// PROPS
  props: {
    show: Boolean,
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    clickCancel(action) {
      this.$emit(action);
    },

    clickConfirm() {
      this.setVar({ usuarios: this.getVar("usuariosTemp") });
      this.$emit("close");
    },

    clickTermoServico(parent, action) {
      this.$emit(parent, action);
    },
  },
};
</script>