var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PinchZoom',{staticClass:"transparent",attrs:{"limitPan":true,"limitZoom":1000,"disableZoomControl":"disable"}},[_c('v-container',{staticClass:"pa-0"},[(_vm.pageModeView && _vm.auxImagens && _vm.auxImagens.length)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[(_vm.auxImagens && _vm.auxImagens.length > 1)?_c('v-carousel',{attrs:{"cycle":_vm.carouselCycle,"height":_vm.height,"hide-delimiters":"","interval":_vm.getConst('carousel').interval,"progress":"","show-arrows-on-hover":""}},_vm._l((_vm.auxImagens),function(imagem,i){return _c('v-carousel-item',{key:i,attrs:{"contain":"","src":imagem.endereco},on:{"click":function($event){$event.stopPropagation();return _vm.clickCarousel.apply(null, arguments)}}})}),1):_vm._e()],1)],1):_vm._e(),(
        _vm.pageModeView &&
        _vm.auxImagens &&
        _vm.auxImagens.length == 1 &&
        _vm.auxImagens[0].endereco
      )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[(_vm.avatar)?_c('v-avatar',{attrs:{"size":"150"}},[_c('v-img',{attrs:{"src":_vm.auxImagens[0].endereco,"contain":"","alt":"imagem"}})],1):_c('v-img',{staticClass:"ml-0 mt-0",attrs:{"alt":"imagem","contain":"","height":_vm.height,"src":_vm.auxImagens[0].endereco,"width":_vm.width}})],1)],1):_vm._e(),(
        !_vm.pageModeView &&
        _vm.auxImagens &&
        _vm.auxImagens.length &&
        _vm.auxImagens[0].endereco
      )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[(_vm.avatar)?_c('v-avatar',{attrs:{"size":"150"}},[_c('v-img',{attrs:{"alt":"imagem","contain":"","src":_vm.auxImagens[0].endereco},on:{"click":_vm.clickImage}})],1):_c('v-img',{staticClass:"ml-0 mt-0",attrs:{"alt":"imagem","contain":"","height":_vm.height,"src":_vm.auxImagens[0].endereco,"width":_vm.width},on:{"click":_vm.clickImage}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }