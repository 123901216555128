<!-- COD: FDS 04/08/2022 Pedido_NumerosSerie -->
<!-- *VER: JER 08/08/2022 Pedido_NumerosSerie -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ pageModeView, context }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <c-pedido-page-numeroSerie-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-pedido-page-numeroSerie-edit
            v-else
            :context="context"
            :dataset="dataset"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CPedidoPageNumeroSerieEdit from "@/components/pages/pedido/pages/CPedidoPageNumeroSerieEdit";
import CPedidoPageNumeroSerieView from "@/components/pages/pedido/pages/CPedidoPageNumeroSerieView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Pedido_NumerosSerie",

  /// COMPONENTS
  components: {
    CPedidoPageNumeroSerieEdit,
    CPedidoPageNumeroSerieView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("pedidoNumerosSerie").id,
      idPageAlt: getPagina("pedidos").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("banco").id,
            idEstado: getEstado("aberto").id,
            isSuspenso: false,
            nome: null,
            nomeAlt: null,
            codigo: null,
            codigoDV: null,
            idPais: null,
            observacao: null,
            observacoes: null,
            dataAtualizacao: null,
          },
          numerosSerie_movimentos: [],
          numerosSerie: [],
        },
        aux: {
          detail: {
            pessoaParceiro: null,
          },
          produtosNS: [],
          numerosSerie: [],
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (["clickLimparNS", "clickPicking"].includes(action)) {
            context.snackbar.params = true;
            context.snackbar.show = false;
          }
        },

        fnParams: () => {
          return JSON.stringify({
            numeroSerie: true,
          });
        },

        posSubmit: (context) => {
          context.params.context = context;

          context.dataset.main.itens.forEach((item, i) => {
            const decimalEstoque = context.dataset.aux.itens[i].decimalEstoque;
            const decimalValor = context.dataset.aux.itens[i].decimalValor;

            item.quantidade = parseFloat(item.quantidade).toFixed(
              decimalEstoque
            );

            item.preco = parseFloat(item.preco).toFixed(decimalValor);
          });
        },
      },

      params: {
        context: null,
      },

      index: null,
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>