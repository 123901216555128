<!-- #COD: BJB 18/10/2022 CExtratoDialogIncluirExtrato -->
<!-- VER: JER __/10/2022 CExtratoDialogIncluirExtrato -->
<!-- /22/ -->

<template>
  <v-dialog max-width="1000" persistent scrollable :value="true">
    <v-form ref="form">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="primary" dark>
            <v-btn icon @click="clickClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer />
            <v-toolbar-title>INCLUIR EXTRATO</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="clickShare">
              <v-icon>mdi-export-variant</v-icon>
            </v-btn>

            <template #extension>
              <v-tabs
                v-model="tipo"
                active-class="primary lighten-2"
                background-color="accent"
                centered
                dark
                grow
              >
                <v-tab class="primary">
                  <span class="ml-2">manual</span>
                </v-tab>
                <v-tab v-if="contasBancariasApi.length > 0" class="primary">
                  <span class="ml-2">automático</span>
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text id="extrato" class="px-1" ref="printArea">
          <v-container class="px-1">
            <!-- logo -->
            <v-row v-bind="prop.row" v-if="showResumoComputed">
              <v-col v-bind="prop.col" align="center">
                <v-img :src="contaBancariaSelecionada.image" width="50"></v-img>
              </v-col>
            </v-row>

            <!-- conta -->
            <v-row v-bind="prop.row" v-if="showResumoComputed">
              <v-col v-bind="prop.col" align="center">
                <v-chip v-bind="prop.chip">
                  {{ contaBancariaFormat(contaBancariaSelecionada) }}
                </v-chip>
              </v-col>
            </v-row>

            <!-- conta -->
            <v-row v-bind="prop.row" v-if="showChipVerificar">
              <v-col v-bind="prop.col" align="center">
                <v-chip v-bind="prop.chip"> verificação </v-chip>
              </v-col>
            </v-row>

            <!-- último extrato -->
            <v-row
              v-bind="prop.row"
              v-if="showResumoComputed && !showChipVerificar"
            >
              <v-col v-bind="prop.col" align="center">
                <c-btn
                  classe="primary--text"
                  text
                  @click="
                    clickExtrato(
                      contaBancariaSelecionada.dataset.main.detail.id
                    )
                  "
                >
                  #{{ contaBancariaSelecionada.dataset.main.detail.id }}
                </c-btn>
              </v-col>
            </v-row>

            <!-- estado último extrato -->
            <v-row
              v-bind="prop.row"
              v-if="showResumoComputed && !showChipVerificar"
            >
              <v-col v-bind="prop.col" align="center">
                <v-chip v-bind="prop.chip">
                  {{
                    getEstado(
                      contaBancariaSelecionada.dataset.main.detail.idEstado
                    ).nomeAlt
                  }}
                </v-chip>
              </v-col>
            </v-row>

            <!-- data -->
            <v-row
              v-bind="prop.row"
              v-if="showResumoComputed && !showChipVerificar"
            >
              <v-col v-bind="prop.col" align="center">
                <span
                  v-if="
                    contaBancariaSelecionada.dataset.main.detail.dataInicio !=
                    contaBancariaSelecionada.dataset.main.detail.dataFim
                  "
                  class="text-h6"
                >
                  {{
                    `${dateFormat(
                      parseISO(
                        contaBancariaSelecionada.dataset.main.detail.dataInicio
                      ),
                      getMaskDataInicio(
                        contaBancariaSelecionada.dataset.main.detail
                      )
                    )} - ${dateFormat(
                      parseISO(
                        contaBancariaSelecionada.dataset.main.detail.dataFim
                      ),
                      "date"
                    )}`
                  }}
                </span>
                <span v-else class="text-h6">
                  {{
                    dateFormat(
                      parseISO(
                        contaBancariaSelecionada.dataset.main.detail.dataFim
                      ),
                      "date"
                    )
                  }}
                </span>
              </v-col>
            </v-row>

            <!-- datas -->
            <v-row v-bind="prop.row" v-if="showChipVerificar">
              <v-col v-bind="prop.col" align="center">
                <span
                  v-if="dataSelecionada.dataInicio != dataSelecionada.dataFim"
                  class="text-h6"
                >
                  {{
                    `${dateFormat(
                      parseISO(dataSelecionada.dataInicio),
                      getMaskDataInicio(dataSelecionada)
                    )} - ${dateFormat(
                      parseISO(dataSelecionada.dataFim),
                      "date"
                    )}`
                  }}
                </span>
                <span v-else class="text-h6">
                  {{ dateFormat(parseISO(dataSelecionada.dataFim), "date") }}
                </span>
              </v-col>
            </v-row>

            <!-- data de verificação -->
            <v-row v-bind="prop.row" v-if="showChipVerificar">
              <v-col v-bind="prop.col" align="center">
                <span class="text-body-1">
                  {{ dateFormat(new Date(), "date timeNoSecond") }}
                </span>
              </v-col>
            </v-row>

            <!-- emissao ultimo extrato -->
            <v-row
              v-bind="prop.row"
              v-if="showResumoComputed && !showChipVerificar"
            >
              <v-col v-bind="prop.col" align="center">
                <span class="text-body-2">
                  {{
                    `${dateFormat(
                      contaBancariaSelecionada.dataset.main.detail.dataEmissao,
                      "date timeNoSecond"
                    )}`
                  }}
                </span>
              </v-col>
            </v-row>

            <!-- resultado diferencaSaldo -->
            <v-row
              v-bind="prop.row"
              v-if="
                showResumo &&
                (showVerificarRetroativo || showResultadosDiferencaSaldo)
              "
            >
              <v-col v-bind="prop.col" align="center">
                <v-expansion-panels
                  v-model="panelsOpen"
                  class="py-0 ma-0 my-6"
                  flat
                  multiple
                >
                  <v-expansion-panel class="mb-1 ma-0" expand>
                    <v-expansion-panel-header class="grey lighten-3">
                      <v-container class="py-0">
                        <!-- titulo -->
                        <v-row v-bind="prop.row">
                          <v-col v-bind="prop.col" cols="6">
                            <span class="red--text text-h6"
                              >inconsistência de saldo</span
                            >
                          </v-col>
                          <!-- totais -->
                          <v-col v-bind="prop.col" align="end" cols="6">
                            <v-badge
                              color="grey"
                              :content="
                                numberFormat(
                                  retornoExtrato.diferencaSaldo,
                                  'currency'
                                )
                              "
                              left
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <!-- extrato retroativo -->
                        <v-row v-bind="prop.row">
                          <v-col v-bind="prop.col">
                            <span class="text-body-1">
                              {{
                                !showVerificarRetroativo
                                  ? retornoExtrato.mensagem
                                  : "- Extrato retroativo não foi suficiente."
                              }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row v-bind="prop.row" v-if="showVerificarRetroativo">
                          <v-col v-bind="prop.col">
                            <span class="text-body-1">
                              - Obter outro extrato mais retroativo para
                              verificação da inconsistência.
                            </span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <!-- verificar -->
            <v-row
              v-bind="prop.row"
              v-if="
                showResumo &&
                (showVerificarRetroativo || showResultadosDiferencaSaldo)
              "
            >
              <v-col v-bind="prop.col" align="center">
                <c-btn classe="primary" @click="clickNovoExtrato(true)">
                  verificar
                </c-btn>
              </v-col>
            </v-row>

            <!-- resultado Sincronizar -->
            <v-row v-bind="prop.row" v-if="showResultadosSincronizar">
              <v-col v-bind="prop.col" align="center">
                <v-expansion-panels
                  v-model="panelsOpen"
                  class="py-0 ma-0 my-6"
                  flat
                  multiple
                >
                  <v-expansion-panel class="mb-1 ma-0" expand>
                    <v-expansion-panel-header class="grey lighten-3">
                      <v-container class="py-0">
                        <v-row v-bind="prop.row">
                          <!-- titulo -->
                          <v-col v-bind="prop.col" cols="11">
                            <span class="text-h6">incluidos/excluidos</span>
                          </v-col>
                          <!-- quantidade registros -->
                          <v-col v-bind="prop.col" align="end" cols="1">
                            <v-badge
                              color="grey"
                              :content="retornoExtrato.sincronizar.length"
                              inline
                              overlap
                              :value="!!retornoExtrato.sincronizar.length"
                            />
                          </v-col>
                        </v-row>

                        <!-- totais -->
                        <v-row v-bind="prop.row">
                          <v-col v-bind="prop.col" align="end" cols="12">
                            <v-badge
                              color="grey"
                              :content="
                                numberFormat(totalSincronizar, 'currency')
                              "
                              left
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <c-expansion-panels
                        flat
                        :colorDot="colorDot"
                        :hasDot="hasDot"
                        :isDisabled="isDisabled"
                        :value="retornoExtrato.sincronizar"
                      >
                        <template #header="{ dataset: extratoLancamento }">
                          <v-container>
                            <v-row v-bind="prop.row">
                              <!-- historico/identificador -->
                              <v-col v-bind="prop.col" cols="6">
                                <span class="text-body-1">
                                  {{
                                    extratoLancamento.historico
                                      ? extratoLancamento.historico
                                      : extratoLancamento.identificador
                                  }}
                                </span>
                              </v-col>
                              <!-- valor -->
                              <v-col v-bind="prop.col" align="end" cols="6">
                                <span
                                  :class="`${
                                    extratoLancamento.valor < 0
                                      ? 'red--text'
                                      : ''
                                  } text-body-1`"
                                >
                                  {{
                                    numberFormat(
                                      extratoLancamento.valor,
                                      "currency"
                                    )
                                  }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                      </c-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <!-- resultado Verificar -->
            <v-row
              v-bind="prop.row"
              v-if="showResumo && showResultadosVerificar"
            >
              <v-col v-bind="prop.col" align="center">
                <v-expansion-panels
                  v-model="panelsOpen"
                  class="py-0 ma-0 my-6"
                  flat
                  multiple
                >
                  <v-expansion-panel class="mb-1 ma-0" expand>
                    <v-expansion-panel-header class="grey lighten-3">
                      <v-container class="py-0">
                        <v-row v-bind="prop.row">
                          <!-- titulo -->
                          <v-col v-bind="prop.col" cols="11">
                            <span class="text-h6">inconsistência de saldo</span>
                          </v-col>
                          <!-- totais -->
                          <v-col v-bind="prop.col" align="end" cols="12">
                            <v-badge
                              color="grey"
                              :content="
                                numberFormat(totalVerificar, 'currency')
                              "
                              left
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- verificar mensagem -->
                      <v-row v-bind="prop.row" class="mt-2">
                        <v-col v-bind="prop.col" align="center">
                          <span class="text-body-1">{{
                            getVerificarMensagem
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <!-- corrigir -->
            <v-row
              v-bind="prop.row"
              v-if="showResumo && showResultadosVerificar"
              class="mt-5"
            >
              <v-col v-bind="prop.col" align="center" cols="12">
                <c-btn classe="primary" @click="clickNovoExtrato()">
                  corrigir
                </c-btn>
              </v-col>
            </v-row>

            <v-row v-bind="prop.row" v-if="showRowEstadosNovo" class="mt-5">
              <v-col v-bind="prop.col" align="center">
                <!-- estado -->
                <c-btn
                  v-if="showBtnEstados"
                  :classe="`${
                    showResultadosDiferencaSaldo
                      ? 'white black--text'
                      : 'primary'
                  } mr-2`"
                  @click="clickEstado"
                >
                  {{
                    getEstado(
                      contaBancariaSelecionada.dataset.main.detail.idEstado
                    ).nome
                  }}
                </c-btn>
                <!-- novo -->
                <c-btn
                  v-if="showBtnNovo"
                  :classe="btnNovoClass"
                  @click="clickNovoExtrato()"
                >
                  novo
                </c-btn>
              </v-col>
            </v-row>

            <!-- proxima conta -->
            <v-row v-bind="prop.row" v-if="showResumoComputed" class="mt-5">
              <v-col v-bind="prop.col" v-if="proximaConta" align="center">
                <c-btn classe="primary" @click="clickProximaConta()">
                  próxima conta
                </c-btn>
              </v-col>
            </v-row>

            <!-- contas -->
            <v-row v-bind="prop.row" v-if="!showResumo" class="ml-2 mt-2">
              <v-col v-bind="prop.col">
                <c-select
                  v-model="idContaBancaria"
                  :items="tipo == 0 ? contasBancarias : contasBancariasApi"
                  label="conta"
                  :properties="{ image: true }"
                  required
                />
              </v-col>
            </v-row>

            <!-- isVerificar -->
            <v-row v-bind="prop.row" v-if="!showResumo" class="ml-2 mt-n7">
              <v-col v-bind="prop.col">
                <c-switch
                  v-model="isVerificar"
                  :disabled="!enabledVerificar"
                  label="verificação"
                />
              </v-col>
            </v-row>

            <!-- datas -->
            <v-row
              v-bind="prop.row"
              v-if="idContaBancaria && !showResumo"
              class="ml-2 mt-n6"
            >
              <v-col v-bind="prop.col" align="center">
                <span
                  v-if="dataSelecionada.dataInicio != dataSelecionada.dataFim"
                  class="text-h6"
                >
                  {{
                    `${dateFormat(
                      parseISO(dataSelecionada.dataInicio),
                      getMaskDataInicio(dataSelecionada)
                    )} - ${dateFormat(
                      parseISO(dataSelecionada.dataFim),
                      "date"
                    )}`
                  }}
                </span>
                <span v-else class="text-h6">
                  {{ dateFormat(parseISO(dataSelecionada.dataFim), "date") }}
                </span>
              </v-col>
            </v-row>

            <!-- arquivo -->
            <v-row
              v-bind="prop.row"
              v-if="tipo == 0 && !showResumo"
              class="my-4"
            >
              <v-col v-bind="prop.col">
                <c-file-input
                  v-model="file"
                  :accept="acceptComponent"
                  :rules="rules.file"
                />
              </v-col>
            </v-row>

            <!-- ultimo extrato -->
            <v-row
              v-bind="prop.row"
              v-if="idContaBancaria && !showResumo"
              class="mt-5"
            >
              <v-col v-bind="prop.col" align="center">
                <c-btn classe="primary" @click="clickObterExtrato()">
                  obter
                </c-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CExtratoDialogIncluirExtrato",

  /// MIXINS
  mixins: [mixinData, mixinSnackbar],

  data() {
    return {
      contasBancarias: [],
      contasBancariasApi: [],
      diferencaDataEmissao: 0,
      file: null,
      idContaBancaria: null,
      interval: null,
      intervaloMinimoNovo: -1,
      isVerificar: false,
      panelsOpen: [0],
      retornoExtrato: null,
      rules: {
        file: [
          (value) =>
            (!!value &&
              (!value.name || !!value.name.match(this.acceptRegex))) ||
            "tipo inválido",
        ],
      },
      showResumo: true,
      tipo: 0,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getPagina_configuracoes: "paginas/getPagina_configuracoes",
      getPagina: "paginas/getPagina",
      getVar: "variaveis/getVar",
    }),

    acceptBanks() {
      let accept = [];

      const idBanco = this.contaBancariaSelecionada?.idBanco;

      const paginaConfiguracao = this.getPagina_configuracoes(
        this.getPagina("bancos").id
      ).find(
        (paginaConfiguracao) =>
          paginaConfiguracao.idBanco == idBanco &&
          paginaConfiguracao.idFeature ==
            this.getFeature("adapterExtratoBanco").id
      );

      if (paginaConfiguracao) {
        accept.push(
          this.getTipo_item(paginaConfiguracao.idTipoArquivo).nomeAlt
        );
      }

      return accept;
    },

    acceptComponent() {
      return this.acceptBanks.map((el) => `.${el}`).join(", ");
    },

    acceptRegex() {
      return new RegExp(`.+\\.(${this.acceptBanks.join("|")})$`, "gi");
    },

    btnNovoClass() {
      const estados = [
        this.getEstado("validar").id,
        this.getEstado("sincronizar").id,
        this.getEstado("conciliar").id,
      ];

      return this.showResultadosDiferencaSaldo ||
        estados.includes(
          this.contaBancariaSelecionada.dataset.main.detail.idEstado
        )
        ? "white black--text"
        : "primary";
    },

    btnNovoCols() {
      const estados = [
        this.getEstado("validar").id,
        this.getEstado("sincronizar").id,
        this.getEstado("conciliar").id,
      ];

      return estados.includes(
        this.contaBancariaSelecionada.dataset.main.detail.idEstado
      )
        ? 6
        : 12;
    },

    contaBancariaSelecionada() {
      return this.contasBancarias.find(
        (contaBancaria) => contaBancaria.value == this.idContaBancaria
      );
    },

    dataSelecionada() {
      return this.isVerificar
        ? this.contaBancariaSelecionada.parametros[1]
        : this.contaBancariaSelecionada.parametros[0];
    },

    enabledVerificar() {
      return !!this.contaBancariaSelecionada?.parametros[1].dataInicio;
    },

    getVerificarMensagem() {
      let mensagem = "";

      if (this.retornoExtrato?.verificar?.length) {
        mensagem = `- Extrato do dia ${this.dateFormat(
          this.retornoExtrato.verificar[0].data,
          "date"
        )} apresentou inconsistência de saldo.`;
      }

      return mensagem;
    },

    indexContaSelecionada() {
      const manualAutomatico =
        this.tipo == 0 ? "contasBancarias" : "contasBancariasApi";

      return this[manualAutomatico].findIndex(
        (contaBancaria) => contaBancaria.value == this.idContaBancaria
      );
    },

    proximaConta() {
      const manualAutomatico =
        this.tipo == 0 ? "contasBancarias" : "contasBancariasApi";

      const hasProximaConta =
        this.idContaBancaria &&
        this.showResumo &&
        this.contaBancariaSelecionada.dataset &&
        !this.contaBancariaSelecionada.dataset.main.lancamentos.some(
          (lancamento) => !lancamento.idValorPagRec
        ) &&
        this.format(
          this.parseISO(
            this.contaBancariaSelecionada.dataset.main.detail.dataFim
          ),
          "yyyy-MM-dd"
        ) == this.format(new Date(), "yyyy-MM-dd");
      // this.contaBancariaSelecionada.dataset.main.detail.idEstado ==
      //   this.getEstado("conciliado").id &&
      this.indexContaSelecionada < this[manualAutomatico].length - 1;

      return hasProximaConta;
    },

    showBtnEstados() {
      const estados = [
        this.getEstado("validar").id,
        this.getEstado("sincronizar").id,
        this.getEstado("conciliar").id,
      ];

      return estados.includes(
        this.contaBancariaSelecionada.dataset.main.detail.idEstado
      );
    },

    showBtnNovo() {
      return (
        this.contaBancariaSelecionada.dataset.main.detail.idEstado ==
          this.getEstado("validar").id ||
        this.diferencaDataEmissao > this.intervaloMinimoNovo
      );
    },

    showChipVerificar() {
      return this.retornoExtrato && "verificar" in this.retornoExtrato;
    },

    showResultadosDiferencaSaldo() {
      return !!this.retornoExtrato?.diferencaSaldo;
    },

    showResultadosSincronizar() {
      return !!this.retornoExtrato?.sincronizar?.length && this.showResumo;
    },

    showResultadosVerificar() {
      return !!this.retornoExtrato?.verificar?.length;
    },

    showResumoComputed() {
      return (
        this.idContaBancaria &&
        this.contaBancariaSelecionada?.dataset?.main &&
        this.showResumo
      );
    },

    showRowEstadosNovo() {
      return (
        !this.showResultadosVerificar &&
        !this.showVerificarRetroativo &&
        this.idContaBancaria &&
        this.showResumo &&
        this.contaBancariaSelecionada?.dataset?.main
      );
    },

    showVerificarRetroativo() {
      return (
        !!this.contaBancariaSelecionada?.isVerificar &&
        !this.retornoExtrato?.verificar?.length
      );
    },

    totalSincronizar() {
      return (this.retornoExtrato?.sincronizar || []).reduce(
        (acc, extratoLancamento) =>
          (acc += extratoLancamento.valor * (extratoLancamento.id ? -1 : 1)),
        0
      );
    },

    totalVerificar() {
      return (this.retornoExtrato?.verificar || []).reduce(
        (acc, extratoLancamento) =>
          (acc += extratoLancamento.valor
            ? extratoLancamento.valor * (extratoLancamento.id ? -1 : 1)
            : extratoLancamento.saldo),
        0
      );
    },
  },

  /// MOUNTED
  async mounted() {
    await this.setContasbancarias();

    if (this.$route.query.from.name != "Extrato_Conciliacoes") {
      await this.extratoAutomatico();
    }

    this.interval = setInterval(() => {
      this.updateDiferencaDataEmissao();
    }, 1000 * 60);
  },

  /// DESTROYED
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },

  /// HEAD
  head: {
    script: [
      {
        async: false,
        body: true,
        defer: true,
        src: "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js",
        type: "text/javascript",
      },
    ],
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    clickClose() {
      this.$emit("close", false);
    },

    clickContaBancaria() {
      this.$forceUpdate();
    },

    async clickEstado() {
      const idEstado =
        this.contaBancariaSelecionada.dataset.main.detail.idEstado;
      const idExtrato = this.contaBancariaSelecionada.dataset.main.detail.id;

      if (idEstado == this.getEstado("conciliar").id) {
        setTimeout(() => {
          this.$router.push(`/extrato/Extrato_Conciliacoes/${idExtrato}`);
        }, 10);
        this.clickClose();
      } else {
        this.setVar({ overlay: true });
        const url = `${this.getConst("app").baseUrlBackend}/extratos/state`;

        let ret;

        try {
          ret = await axios.put(url, this.contaBancariaSelecionada.dataset);
        } catch (err) {
          this.setSnackbar(true, "EXTRATO", err.response.data, "error");
        } finally {
          this.setVar({ overlay: false });
        }

        if (ret?.data) {
          this.processaRetorno(ret.data);
        }
      }
    },

    clickExtrato(idExtrato) {
      setTimeout(() => {
        this.$router.push(`/extrato/${idExtrato}`);
      }, 10);
      this.clickClose();
    },

    clickNovoExtrato(isVerificar = false) {
      this.isVerificar = isVerificar;

      this.showResumo = false;
    },

    async clickObterExtrato() {
      let body = {};

      if (this.$refs.form.validate()) {
        this.setVar({ overlay: true });

        body = {
          file: this.file,
          contaBancaria: this.contaBancariaSelecionada,
          isVerificar: this.isVerificar,
          parametros: this.dataSelecionada,
        };

        const retornoExtrato = await this.extratosAutomaticos(body);

        this.processaRetorno(retornoExtrato);
      }
    },

    clickProximaConta() {
      const manualAutomatico =
        this.tipo == 0 ? "contasBancarias" : "contasBancariasApi";

      this.idContaBancaria =
        this[manualAutomatico][this.indexContaSelecionada + 1]?.value;

      if (!this[manualAutomatico][this.indexContaSelecionada]?.dataset?.main) {
        this.showResumo = false;
      }
    },

    async clickShare() {
      this.panelsOpen = [0];
      setTimeout(() => {
        const htmlExtrato = document.getElementById("extrato");
        window
          .html2canvas(htmlExtrato, {
            useCORS: true,
            allowTaint: true,
            windowHeight: htmlExtrato.scrollHeight + window.screen.height,
          })
          .then(async (canvas) => {
            canvas.toBlob(async (blob) => {
              const files = [
                new File([blob], "image.png", { type: blob.type }),
              ];

              const shareData = {
                files,
              };
              await navigator.share(shareData);
            });
          });
      }, 10);
    },

    async clickSnackbar(action) {
      if (action == "gerarExtratoAutomatico") {
        this.setVar({ overlay: true });

        const url = `${
          this.getConst("app").baseUrlBackend
        }/extratos/extratosApiAutomaticos/${this.getVar("idPessoaParceiro")}`;

        let ret;
        try {
          ret = await axios.post(url);

          setTimeout(() => {
            const mensagemExtratosAfetados = `${
              ret.data.numeroRegistrosAfetados
            } ${
              ret.data.numeroRegistrosAfetados > 1
                ? "extratos sincronizados"
                : "extrato sincronizado"
            }`;

            this.setSnackbar(
              true,
              "EXTRATOS AUTOMÁTICOS",
              mensagemExtratosAfetados,
              "success"
            );
          }, 10);
        } catch (err) {
          this.setSnackbar(true, "EXTRATO", err.response.data, "error");
        } finally {
          this.setVar({ overlay: false });
        }
      }

      this.setSnackbar();
    },

    colorDot(dataset) {
      return dataset.id ? "error" : "blue";
    },

    extratoAutomatico() {
      if (this.contasBancariasApi.length) {
        this.setSnackbar(
          true,
          "EXTRATOS AUTOMÁTICOS",
          "Antecipar os extratos automáticos?",
          "warning",
          "mdi-check",
          "gerarExtratoAutomatico"
        );
      }
    },

    async extratosAutomaticos(body) {
      const url = `${
        this.getConst("app").baseUrlBackend
      }/extratos/extratosAutomaticos`;

      let formData = new FormData();
      formData.append("extrato", JSON.stringify(body));

      if (body.file) {
        formData.append("file", body.file);
      }

      let ret;

      try {
        ret = await axios.post(url, formData);
      } catch (err) {
        this.setSnackbar(true, "EXTRATO", err.response.data, "error");
      } finally {
        this.setVar({ overlay: false });
      }

      if (ret?.data) {
        return ret.data;
      }
    },

    getContasBancariasDatas() {
      return this.contasBancarias
        ? this.contasBancarias.find(
            (conta) => conta.value == this.idContaBancaria
          )?.datas[0]
        : null;
    },

    getMaskDataInicio(objeto) {
      const dataInicio = this.parseISO(objeto.dataInicio);
      const dataFim = this.parseISO(objeto.dataFim);

      let mask = "date";

      if (dataInicio.getFullYear() == dataFim.getFullYear()) {
        mask = "dateNoYear";
      }

      return mask;
    },

    hasDot() {
      return true;
    },

    isDisabled() {
      return true;
    },

    async processaRetorno(retorno) {
      const diferencaSaldo = this.retornoExtrato?.diferencaSaldo || null;
      this.retornoExtrato = retorno;

      await this.setContasbancarias();

      if (this.retornoExtrato.dataset) {
        this.contaBancariaSelecionada.dataset = this.retornoExtrato.dataset;
      } else if (this.isVerificar && !this.retornoExtrato?.verificar?.length) {
        this.contaBancariaSelecionada.isVerificar = true;
        this.retornoExtrato.diferencaSaldo =
          this.retornoExtrato.diferencaSaldo || diferencaSaldo;
      }

      this.updateDiferencaDataEmissao();

      this.showResumo = true;
    },

    async setContasbancarias() {
      this.setVar({ overlay: true });

      const url = `${
        this.getConst("app").baseUrlBackend
      }/extratosContasBancarias/${this.getVar("idPessoaParceiro")}`;

      try {
        const body = {
          contasBancarias: this.contasBancarias.length
            ? this.contasBancarias
            : null,
        };

        const ret = await axios.post(url, body);

        if (ret?.data) {
          this.contasBancarias = ret.data.map((contaBancaria) => {
            return {
              ...contaBancaria,
              value: contaBancaria.id,
              text: this.contaBancariaFormat(contaBancaria),
              image: contaBancaria.endereco,
            };
          });

          this.contasBancariasApi = this.contasBancarias.filter(
            (contaBancaria) =>
              contaBancaria.idServico &&
              contaBancaria.idPessoaVan &&
              contaBancaria.vanAccountId
          );

          if (!this.idContaBancaria) {
            if (this.contasBancariasApi.length) {
              this.tipo = 1;
              this.idContaBancaria = this.contasBancariasApi[0].value;
            } else {
              this.idContaBancaria = this.contasBancarias[0].value;
            }
          }

          if (!this.contaBancariaSelecionada?.dataset?.main) {
            this.showResumo = false;
          }
        }
      } catch (err) {
        this.setSnackbar(true, "EXTRATO", err.response.data, "error");
      } finally {
        this.setVar({ overlay: false });
        this.updateDiferencaDataEmissao();
      }
    },

    updateDiferencaDataEmissao() {
      const dataHoje = new Date();

      this.diferencaDataEmissao = this.differenceInMinutes(
        dataHoje,
        this.parseISO(
          this.contaBancariaSelecionada?.dataset?.main?.detail?.dataEmissao ||
            dataHoje
        )
      );
    },
  },
};
</script>