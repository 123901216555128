<!-- *COD: LYF __/08/2022 CAgendaDialogEvento -->
<!-- VER: JER __/08/2022 CAgendaDialogEvento -->

<template>
  <v-dialog v-if="showDetail" v-model="value.value" max-width="400px">
    <!-- fullscreen -->
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar :color="calendarEventColor" dark>
          <c-btn icon @click="clickClose">
            <v-icon>mdi-close</v-icon>
          </c-btn>
          <v-toolbar-title class="ml-2">EVENTO</v-toolbar-title>
          <v-spacer></v-spacer>
          <c-btn
            icon
            v-if="
              !dataset.idPedidoItem &&
              (dataset.idColaborador ||
                perfis.includes(getTipo_item('perfilAdmin').id))
            "
            v-show="modeView"
            @click="modeView = false"
          >
            <v-icon>mdi-pencil</v-icon>
          </c-btn>
          <c-btn
            v-if="
              modeView &&
              !dataset.idPedidoItem &&
              (dataset.idColaborador ||
                perfis.includes(getTipo_item('perfilAdmin').id))
            "
            icon
            @click="clickDelete"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </c-btn>
          <c-btn
            v-else-if="
              !dataset.idPedidoItem &&
              (dataset.idColaborador ||
                perfis.includes(getTipo_item('perfilAdmin').id))
            "
            icon
            @click="clickSave"
          >
            <v-icon>mdi-check</v-icon>
          </c-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text v-if="modeView && dataset" class="mt-2">
        <!-- colaborador -->
        <v-row v-bind="prop.row" v-if="dataset.colaborador">
          <v-col
            v-bind="prop.col"
            align="center"
            class="primary primary--text text-overline"
          >
            <v-chip v-bind="prop.chip">
              {{ dataset.colaborador }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- cliente -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <span class="text-h6">
              {{ dataset.cliente }}
            </span>
          </v-col>
        </v-row>

        <!-- descricao/marca -->
        <v-row v-bind="prop.row" v-if="dataset.descricao || dataset.marca">
          <v-col v-bind="prop.col" align="center">
            <span class="font-weight-medium text-body-1">
              {{
                `${dataset.descricao || ""}${
                  dataset.marca ? ", " + dataset.marca : ""
                }`
              }}
            </span>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-if="dataset.dataInicio">
          <!-- isVerificado -->
          <v-col v-bind="prop.col" align="end" cols="3">
            <v-icon v-if="cartao.isVerificado" class="blue--text">
              mdi-clock-outline
            </v-icon>
          </v-col>
          <!-- data inicio/fim -->
          <v-col v-bind="prop.col" cols="9">
            <span class="font-weight-medium text-body-1">
              {{
                `${format(parseISO(dataset.dataInicio), "HH:mm")} -${format(
                  parseISO(dataset.dataFim),
                  "HH:mm"
                )} (${duracao})`
              }}
            </span>
          </v-col>
        </v-row>

        <!-- observacao -->
        <v-row v-bind="prop.row" v-show="dataset.observacao">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ dataset.observacao }}
            </span>
          </v-col>
        </v-row>

        <!-- idPedido -->
        <v-row v-bind="prop.row" v-show="dataset.idPedido">
          <v-col v-bind="prop.col" align="center">
            <a class="text-body-2" @click="clickPedido">
              {{ `pedido #${dataset.idPedido}` }}
            </a>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <!-- colaboradores -->
        <v-row v-bind="prop.row" v-if="colaboradores.length" class="mt-2 ml-n2">
          <v-col v-bind="prop.col">
            <c-select
              v-model="dataset.idColaborador"
              dense
              :items="colaboradores"
              label="colaborador"
              :menuProps="{ maxHeight: '400' }"
              outlined
              rounded
            />
          </v-col>
        </v-row>

        <!-- cliente -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-text-field
              v-model="dataset.cliente"
              label="cliente"
              :max="40"
              required
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-if="!recarregarDatas">
          <!-- horario inicio -->
          <v-col v-bind="prop.col" align="start" cols="4">
            <c-datetime-picker
              v-model="horarioInicio"
              icon
              label="início"
              :max="atendimento.horarioFim"
              :min="atendimento.horarioInicio"
              required
              time
            />
          </v-col>
          <!-- horario fim -->
          <v-col v-bind="prop.col" align="start" cols="4">
            <c-datetime-picker
              v-model="horarioFim"
              icon
              label="fim"
              :max="atendimento.horarioFim"
              :min="horarioInicio"
              required
              time
            />
          </v-col>
          <!-- duracao -->
          <v-col v-bind="prop.col" align="start" cols="4">
            <div>duração</div>
            <div>({{ duracao }})</div>
          </v-col>
        </v-row>

        <!-- observacao -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-text-field
              v-model="dataset.observacao"
              label="observação"
              :max="50"
              :noAccept="null"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  addMinutes,
  differenceInMinutes,
  format,
  getDate,
  parseISO,
} from "date-fns";
import mixinData from "@/mixins/mixinData";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "CAgendaDialogEvento",

  /// MIXINS
  mixins: [mixinData, mixinUsuarios],

  /// PROPS
  props: {
    atendimento: { type: Object, required: true },
    colaboradores: { type: Array, required: true },
    dataset: { type: Object, required: true },
    datasets: { type: Array, required: true },
    value: { type: Object, default: new Object({ value: false }) },
  },

  /// DATA
  data() {
    return {
      diffInMinutes: null,
      horarioInicio: this.dataset.dataInicio
        ? format(parseISO(this.dataset.dataInicio), "HH:mm")
        : "",
      horarioFim: this.dataset.dataFim
        ? format(parseISO(this.dataset.dataFim), "HH:mm")
        : "",
      modeView: true,
      recarregarDatas: false,
    };
  },

  /// COMPUTED
  computed: {
    calendarEventColor() {
      if (this.dataset.idPedido) {
        return "success";
      } else if (!this.dataset.idColaborador) {
        return "error";
      } else if (!this.dataset.cliente) {
        return "warning";
      } else {
        return "primary";
      }
    },

    datasetChange() {
      const datasetTemp = this.datasets.find(
        (dataset) => dataset.id == this.dataset.id
      );

      if (datasetTemp) {
        const dataset = JSON.parse(JSON.stringify(datasetTemp));

        dataset.dataInicio = `${format(
          parseISO(dataset.dataInicio),
          "yyyy-MM-dd"
        )}T${format(parseISO(dataset.dataInicio), "HH:mm:ss")}.000`;

        dataset.dataFim = `${format(
          parseISO(dataset.dataFim),
          "yyyy-MM-dd"
        )}T${format(parseISO(dataset.dataFim), "HH:mm:ss")}.000`;

        return JSON.stringify(dataset) != JSON.stringify(this.dataset);
      } else {
        return false;
      }
    },

    duracao() {
      const diff = this.differenceInHoursMinutes(
        parseISO(this.dataset.dataFim),
        parseISO(this.dataset.dataInicio)
      );

      return diff;
    },

    observacoes() {
      return this.datasets
        .filter((dataset) => !dataset.observacao && dataset.observacao)
        .map((dataset) => {
          return {
            value: dataset.observacao,
            text: dataset.observacao,
          };
        });
    },

    showDetail() {
      return this.value.value;
    },
  },

  /// WATCH
  watch: {
    horarioFim() {
      this.dataset.dataFim = `${format(
        parseISO(this.dataset.dataFim),
        "yyyy-MM-dd"
      )} ${this.horarioFim}:00.000Z`;

      this.diffInMinutes = differenceInMinutes(
        parseISO(this.dataset.dataFim),
        parseISO(this.dataset.dataInicio)
      );
    },

    horarioInicio() {
      this.dataset.dataInicio = `${format(
        parseISO(this.dataset.dataInicio),
        "yyyy-MM-dd"
      )} ${this.horarioInicio}:00.000Z`;

      if (this.diffInMinutes) {
        this.recarregarDatas = true;

        const dataFim = addMinutes(
          parseISO(
            this.dataset.dataInicio.substring(
              0,
              this.dataset.dataInicio.length - 1
            )
          ),
          this.diffInMinutes
        );

        const horarioFim =
          getDate(dataFim) ==
          getDate(
            parseISO(
              this.dataset.dataInicio.substring(
                0,
                this.dataset.dataInicio.length - 1
              )
            )
          )
            ? format(dataFim, "HH:mm")
            : this.atendimento.horarioFim.substring(0, 5);

        this.horarioFim =
          `${horarioFim}:00` < this.atendimento.horarioFim
            ? horarioFim
            : this.atendimento.horarioFim.substring(0, 5);

        this.dataRecarrega();
      }
    },

    modeView() {
      if (!this.modeView) {
        this.diffInMinutes = null;
        this.recarregarDatas = true;

        this.horarioInicio = this.dataset.dataInicio
          ? format(parseISO(this.dataset.dataInicio), "HH:mm")
          : "";

        this.horarioFim = this.dataset.dataFim
          ? format(parseISO(this.dataset.dataFim), "HH:mm")
          : "";

        this.dataRecarrega();
      }
    },

    showDetail() {
      this.modeView = true;
      this.modeView = !this.datasetChange && !(this.dataset.id == null);

      if (this.colaboradores.length == 1) {
        this.dataset.idColaborador = this.colaboradores[0].value;
      }
    },
  },

  /// METHODS
  methods: {
    parseISO,

    format,

    clickClose() {
      if (this.dataset.id == null) {
        this.clickDelete();
      } else if (this.modeView || this.datasetChange) {
        this.$emit("input", { value: false });
      } else {
        this.modeView = true;
      }
    },

    clickDelete() {
      this.$emit("click:delete", this.dataset);
    },

    clickPedido() {
      this.$router.push(`pedido/${this.dataset.idPedido}`);
    },

    clickSave() {
      this.$emit("click:save", this.dataset);
    },

    dataRecarrega() {
      setTimeout(() => {
        this.recarregarDatas = false;

        this.diffInMinutes = differenceInMinutes(
          parseISO(this.dataset.dataFim),
          parseISO(this.dataset.dataInicio)
        );
      }, 1);
    },
  },
};
</script>