<!-- COD: LYF 29/07/2022 CFolhaPagamentoPageColaboradorEdit -->
<!-- *VER: JER 07/08/2022 CFolhaPagamentoPageColaboradorEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- comissao -->
    <v-row
      v-bind="prop.row"
      v-if="
        context.datasetList.dataset.main.metas.some(
          (meta) => meta.idPessoaColaborador == colaborador.id
        )
      "
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-1">comissão</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="colaborador.comissao"
          :max="9"
          :options="{
            length: 5,
            precision: 2,
            style: 'decimal',
            suffix: '%',
          }"
        />
      </v-col>
    </v-row>

    <!-- peso -->
    <v-row
      v-bind="prop.row"
      v-if="
        !context.datasetList.dataset.main.metas.some(
          (meta) => meta.idPessoaColaborador == colaborador.id
        )
      "
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-1">peso</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="colaborador.peso"
          :max="5"
          :options="{
            length: 5,
            precision: 0,
            style: 'decimal',
          }"
        />
      </v-col>
    </v-row>

    <!-- metas -->
    <c-expansion-panels
      flat
      :pageModeView="true"
      :value="
        context.datasetList.dataset.main.metas.filter(
          (meta) => meta.idPessoaColaborador == colaborador.id
        )
      "
    >
      <template #header="{ dataset: meta }">
        <!-- meta verba -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-2">
              {{ metaVerba(meta.index) }}
            </span>
          </v-col>
        </v-row>

        <!-- meta percentual-->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" cols="1">
            <span class="text-body-2">
              {{ metaPercentual(meta.index, true) }}
            </span>
          </v-col>
          <v-col v-bind="prop.col" cols="11">
            <v-progress-linear
              class="ml-2"
              :color="colorProgressLinear(metaPercentual(meta.index))"
              height="6"
              rounded
              :value="metaPercentual(meta.index)"
            />
          </v-col>
        </v-row>
      </template>

      <template #content="{ dataset: meta }">
        <!-- meta planejada -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-1">planejado</span>
          </v-col>
          <v-col v-bind="prop.col" cols="6">
            <c-text-money
              v-model="meta.metaPlanejada"
              :max="15"
              :options="{
                length: metaAux(meta.index).numberFormatLength,
                precision: metaAux(meta.index).numberFormatPrecision,
                style: metaAux(meta.index).numberFormatStyle,
                suffix: metaAux(meta.index).numberFormatSuffix,
              }"
            />
          </v-col>
        </v-row>
      </template>
    </c-expansion-panels>
  </v-container>
</template>

<script>
/// IMPORT
export default {
  /// NAME
  name: "CFolhaPagamentoPageColaboradorEdit",

  /// PROPS
  props: {
    colaborador: { type: Object, required: true },
    context: { type: Object },
  },

  /// METHODS
  methods: {
    colorProgressLinear(column) {
      if (column == 0) {
        return "grey";
      } else if (column < 90) {
        return "red";
      } else if (column < 100) {
        return "orange";
      } else {
        return "green";
      }
    },

    metaAux(index) {
      return this.context.datasetList.dataset.aux.metas[index];
    },

    metaPercentual(index, formatada = false) {
      const meta = this.context.datasetList.dataset.main.metas[index];

      const percentualRealizado = parseFloat(meta.percentualRealizado || 0);

      return formatada
        ? this.numberFormat(percentualRealizado / 100, "percent", 0)
        : percentualRealizado;
    },

    metaVerba(index) {
      const meta = this.context.datasetList.dataset.main.metas[index];

      return `${meta.options?.nomeAlt ? meta.options.nomeAlt : meta.nomeAlt}`;
    },
  },
};
</script>