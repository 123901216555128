<!-- COD: FDS 26/07/2022 CProdutoPageNumeroSerieView -->
<!-- *VER: JER 28/07/2022 CProdutoPageNumeroSerieView -->
<!-- /12/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- numero serie -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ produto.numeroSerie }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- produto -->
    <v-row v-bind="prop.row" v-if="!$route.params.idProduto">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ produtoFormat(produto, false, true, true, true) }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" cols="4">
        <span class="text-body-1">
          {{ dateFormat(produto.data, "date") }}
        </span>
      </v-col>
      <!-- pessoa -->
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ produto.pessoaNome }}
        </span>
      </v-col>
    </v-row>

    <!-- identification -->
    <c-base-item-identification v-if="produto.estado" :dataset="produto" />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseItemIdentification from "@/components/bases/CBaseItemIdentification";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CProdutoPageNumeroSerieView",

  /// COMPONENTS
  components: { CBaseItemIdentification },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    produto: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getTipo_item: "tipos/getTipo_item",
    }),
  },

  /// BEFOREMOUNT
  beforeMount() {
    this.setParams();
  },

  /// BEFOREUPDATE
  beforeUpdate() {
    this.setParams();
  },

  /// METHODS
  methods: {
    setParams() {
      this.produto.estado = this.getEstado(this.produto.idEstado).nomeAlt;
      this.produto.tipo = this.getTipo_item(this.produto.idTipo).nomeAlt;
    },
  },
};
</script>