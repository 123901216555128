// COD: BJB 15/08/2022 main
// *VER: JER 19/08/2022 main
// /11/

/// PARA RODAR HTTPS NO LOCALHOST
/*
// package.json:
"scripts": {
  "serve": "vue-cli-service serve --https",
  "build": "vue-cli-service build",
  "lint": "vue-cli-service lint"
},

// chrome://flags/#allow-insecure-localhost
*/

/// IMPORT
import App from './App.vue';
import "./axios";
import { Buffer } from 'buffer';
import prototypes from './prototypes';
import './registerServiceWorker';
import router from './router/index';
import store from './store/index';
import ViaCep from 'vue-viacep';
import Vue from 'vue';
import Vue2Editor from 'vue2-editor';
import VueHead from 'vue-head';
import VueQuagga from 'vue-quaggajs';
import vuetify from './plugins/vuetify';
import mixinLib from "@/mixins/mixinLib";

Vue.config.productionTip = false;

Vue.mixin(mixinLib);

Vue.use(ViaCep);
Vue.use(Vue2Editor);
Vue.use(VueHead);
Vue.use(VueQuagga);

window.Buffer = Buffer;

/// VUE
new Vue({
  prototypes,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');