<!-- COD: LYF 06/07/2022 CLancamentoContabilEdit -->
<!-- *VER: JER 06/08/2022 CLancamentoContabilEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="dataset.main.detail.data"
          clearable
          date
          label="data"
          required
        />
      </v-col>
      <!-- data balancete -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="dataset.main.detail.dataBalancete"
          clearable
          date
          label="balancete"
          required
        />
      </v-col>
    </v-row>

    <!-- historico padrao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idHistoricoPadrao"
          :itemsIniciais="dataset.aux.autocomplete.historicoPadrao"
          label="histórico padrão"
          :path="`/historicosPadrao/autocomplete/historicoPadraoLancamento/null/${dataset.main.detail.idTipo}/`"
          :rules="[]"
          required
        />
      </v-col>
    </v-row>

    <!-- historico complementar -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.historicoComplementar"
          label="histórico complementar"
          :max="30"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor total -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valorTotal"
          label="valor"
          :max="20"
          :options="{
            style: 'currency',
            length: 11,
            precision: 2,
          }"
          required
        />
      </v-col>
      <!-- isEstorno -->
      <v-col v-bind="prop.col" cols="6">
        <c-switch v-model="dataset.main.detail.isEstorno" label="estorno" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CLancamentoContabilEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },
};
</script>