// COD: FDS 10/08/2022 mixinSwipe
// *VER: JER 19/08/2022 mixinSwipe
// /11/

import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "mixinSwipe",

  /// DATA
  data() {
    return {
      btnInjectClicked: false,
    };
  },


  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst"
    }),

    isTouchDevice() {
      return "ontouchstart" in window || "onmsgesturechange" in window;
    },
  },

  /// METHODS
  methods: {
    async swipe(direction, callback, event) {
      if (direction == "down" && window.pageYOffset <= 0) {
        const area = (window.screen.height / 2)

        if (event.touchstartY <= area && (event.touchendY - event.touchstartY) > this.getConst("config").swipePixels) {
          await callback();
        }
      } else if (direction == "up") {
        const area = (window.screen.height / 2) * 1

        if ((window.screen.height + window.pageYOffset) >= document.documentElement.offsetHeight) {
          if (event.touchstartY >= area && (event.touchstartY - event.touchendY) > this.getConst("config").swipePixels / 4) {
            callback();
          }
        }
      } else if (direction == "left") {
        const area = ((window.screen.width / 2) * 1)
        if (event.touchstartX >= area && (event.touchstartX - event.touchendX) > this.getConst("config").swipePixels) {
          callback(direction);
        }
      } else if (direction == "right") {
        const area = (window.screen.width / 2)
        if (event.touchstartX <= area && (event.touchendX - event.touchstartX) > this.getConst("config").swipePixels) {
          callback(direction);
        }
      }
    }
  }
}