<!-- COD: LYF 30/06/2022 CBancoView -->
<!-- *VER: JER 08/07/2022 CBancoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais (bandeira) -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPais">
      <v-col v-bind="prop.col" align="center">
        <v-img
          :src="getLocalidade(dataset.main.detail.idPais).endereco"
          width="30"
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ data }}
        </span>
      </v-col>
    </v-row>

    <!-- dataInicioFim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- manterItensAnteriores -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset &&
        dataset.aux &&
        dataset.aux.detail &&
        dataset.aux.detail.substituiItensAnteriores
      "
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1"> substitui itens anteriores </span>
      </v-col>
    </v-row>

    <!-- itens -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.itens && dataset.main.itens.length"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.itens.length }}
          {{ dataset.main.itens.length > 1 ? "itens" : "item" }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CBancoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getImposto: "impostos/getImposto",
      getLocalidade: "localidades/getLocalidade",
    }),

    data() {
      let ret = "";
      const data = this.dataset.main.detail.data
        ? this.dateFormat(this.dataset.main.detail.data, "date")
        : "";

      if (data) {
        const dataPublicacao = this.dataset.main.detail.dataPublicacao
          ? this.dateFormat(this.dataset.main.detail.dataPublicacao, "date")
          : "atual";

        ret = `${data} (${dataPublicacao})`;
      }

      return ret;
    },

    dataInicioFim() {
      let ret = "";
      const dataInicio = this.dataset.main.detail.dataInicio
        ? this.dateFormat(this.dataset.main.detail.dataInicio, "date")
        : "";

      if (dataInicio) {
        const dataFim = this.dataset.main.detail.dataFim
          ? this.dateFormat(this.dataset.main.detail.dataFim, "date")
          : "atual";

        ret = `${dataInicio} - ${dataFim}`;
      }

      return ret;
    },
  },
};
</script>