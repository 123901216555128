<!-- COD: LYF 01/07/2022 Carrinhos -->
<!-- *VER: JER 04/07/2022 Carrinhos -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order, context }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item
      :context="context"
      :dataset="dataset"
      :idPage="idPage"
      :order="order"
    >
      <c-carrinhos :dataset="dataset" />
      <template #icon>
        <v-row v-bind="prop.row" class="ml-n2">
          <v-col v-bind="prop.col">
            <c-btn
              classe="blue--text"
              :disabled="dataset.idEstado != getEstado('aberto').id"
              icon
              @click.stop="context.setAtualConfirmar(dataset)"
            >
              <v-icon>
                {{
                  dataset.idAtual
                    ? "mdi-check-decagram"
                    : "mdi-decagram-outline"
                }}
              </v-icon>
            </c-btn>
          </v-col>
        </v-row>
      </template>
      <template #info>
        <v-row v-bind="prop.row" v-show="order.text != 'entrega'">
          <v-col v-bind="prop.col">
            <v-chip
              class="white--text text-overline"
              :class="dataset.isEntregar ? 'corEntregar' : 'corRetirar'"
              x-small
            >
              {{ dataset.isEntregar ? "entregar" : "retirar" }}
            </v-chip>
          </v-col>
        </v-row>
      </template>
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CCarrinhos from "@/components/pages/carrinho/CCarrinhos";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Carrinhos",

  /// COMPONENTS
  components: {
    CCarrinhos,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("carrinhos").id,
      idPageAlt: getPagina("carrinho").id,
      isEntregar: getVar("isEntregar"),

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "setAtual") {
            context.funcs.setAtual(context.snackbar.params, context);
          }

          context.setSnackbar();
        },

        fnParams: (context) => {
          const contextParams = context?.params
            ? JSON.parse(context.params)
            : null;

          return JSON.stringify({
            isEntregar: this.isEntregar,
            proprietarios: contextParams?.proprietarios ?? true,
            idPessoaParceiroUsuario: getVar("idPessoaParceiro"),
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo)?.nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado)?.nomeAlt;
          dataset.tipoEntrega = null;

          if (dataset.isEntregar != null) {
            dataset.tipoEntrega = dataset.isEntregar ? "entregar" : "retirar";
          }
        },

        setAtual: async (carrinho, context) => {
          context.setSnackbar();
          const ret = await context.setAtual(carrinho);

          let carrinhoAtual = context.datasets.find(
            (dataset) => !!dataset.idAtual
          );

          if (carrinhoAtual) {
            carrinhoAtual.idAtual = null;
          }

          carrinho.idAtual = ret.data.id;
        },
      },
    };
  },

  ///METHODS
  methods: {
    enderecosAplicar() {
      this.isEntregar = this.getVar("isEntregar");
    },
  },
};
</script>