<!-- #COD: FDS 04/01/2023 CProdutosDialogImportar -->
<!-- VER: JER __/01/2023 CProdutosDialogImportar -->
<!-- /22/ -->

<template>
  <v-dialog max-width="380" persistent scrollable :value="true">
    <v-form ref="form">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="primary" dark>
            <v-btn icon @click="clickClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer />
            <v-toolbar-title>IMPORTAR PRODUTO</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-title>

        <v-card-text id="extrato" class="px-1" ref="printArea">
          <!-- arquivo -->
          <v-row v-bind="prop.row" class="my-4">
            <v-col v-bind="prop.col">
              <c-file-input v-model="file" :rules="rules.file" />
              <!-- :accept="acceptComponent" -->
            </v-col>
          </v-row>

          <!-- read file -->
          <v-row v-bind="prop.row" class="mt-5">
            <v-col v-bind="prop.col" align="center">
              <c-btn classe="primary" @click="clickImportar()">
                importar
              </c-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CProdutosDialogImportar",

  /// MIXINS
  mixins: [mixinData, mixinSnackbar],

  data() {
    return {
      file: null,
      rules: {
        file: [
          (value) =>
            (!!value && (!value.name || !!value.name.match(".csv"))) ||
            "tipo inválido",
        ],
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getPagina_configuracoes: "paginas/getPagina_configuracoes",
      getPagina: "paginas/getPagina",
      getVar: "variaveis/getVar",
    }),
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    clickClose() {
      this.$emit("close", false);
    },

    async clickImportar() {
      if (this.$refs.form.validate()) {
        const body = {
          file: this.file,
          idPessoaParceiro: this.getVar("idPessoaParceiro"),
          idUsuario: this.getVar("idUsuario"),
        };

        const url = `${this.getConst("app").baseUrlBackend}/produtos/importar`;

        let formData = new FormData();
        formData.append("produto", JSON.stringify(body));

        if (body.file) {
          formData.append("file", body.file);
        }

        let ret;

        try {
          ret = await axios.post(url, formData);
        } catch (err) {
          this.setSnackbar(true, "PRODUTO", err.response.data, "error");
        } finally {
          this.setVar({ overlay: false });
        }

        if (ret?.data) {
          console.log(ret.data);
        }
      }
    },
  },
};
</script>