<!-- COD: FDS 28/07/2022 Colaborador_Apontamentos -->
<!-- *VER: JER 08/08/2022 Colaborador_Apontamentos -->
<!-- /31/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPageAlt"
      :idPageAlt="idPage"
      :params="params"
    >
      <template v-slot="{ context }">
        <!-- nome colaborador  -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-1">
              {{ context.dataset.main.detail.nomeAlt }}
            </span>
          </v-col>
        </v-row>
      </template>
    </c-base-detail>

    <c-base-list
      v-if="loadList"
      :funcs="funcsList"
      :hasSearch="false"
      :idFeature="cIdFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :noList="true"
      :pageModeEdit="true"
    >
      <template #search="{ context }">
        <v-container>
          <!-- busca -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-text-field
                v-model="search"
                append-outer-icon="mdi-tune"
                clearable
                :max="100"
                :noAccept="null"
                placeholder="busca"
                prepend-inner-icon="mdi-magnify"
                solo
                type="search"
                @clickAppendOuter="showSearchOptions = !showSearchOptions"
                @keyup.enter="
                  $emit('search', search);
                  $event.target.blur();
                "
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <!-- data inicio -->
            <v-col v-bind="prop.col">
              <c-datetime-picker
                v-model="searchData.inicio"
                classe="text-body-1 pr-1"
                date
                icon
                label="início"
                :maxValue="new Date().toISOString().substring(0, 10)"
                solo
                @change="changeDataInicio"
              />
            </v-col>
            <!-- data fim -->
            <v-col v-bind="prop.col">
              <c-datetime-picker
                v-model="searchData.fim"
                classe="text-body-1 pl-1"
                date
                :disabled="!searchData.inicio"
                label="fim"
                icon
                :maxValue="
                  minDateValue([
                    new Date(),
                    addYears(parseISO(searchData.inicio), 1),
                  ])
                    .toISOString()
                    .substring(0, 10)
                "
                :minValue="searchData.inicio"
                solo
              />
            </v-col>
          </v-row>

          <!-- botao buscar -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col" align="end">
              <c-btn
                block
                classe="primary white--text mb-4"
                label="buscar"
                large
                @click="clickSearch(context)"
              >
                buscar
              </c-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot="{ dataset, context, index }">
        <v-container v-if="index == 0" class="py-0">
          <!-- sparkline -->
          <v-sparkline
            v-if="index == 0"
            auto-draw
            :auto-draw-duration="5000"
            :gradient="['green', 'yellow', 'red']"
            :line-width="2"
            :padding="5"
            :smooth="2"
            :value="context.datasetList.dataset.aux.datas"
          />
          <c-expansion-panels
            flat
            :pageModeView="true"
            :params="params"
            :value="[dataset]"
          >
            <template #header="{ dataset }">
              <v-row v-bind="prop.row">
                <!-- tipo nome -->
                <v-col v-bind="prop.col" cols="10">
                  <span class="text-body-1">
                    {{ dataset.tipoNome }}
                  </span>
                </v-col>
                <!-- apontamentos -->
                <v-col v-bind="prop.col" align="center" cols="2">
                  <v-badge
                    color="grey"
                    :content="
                      context.datasetList.dataset.aux.items.filter(
                        (item) => item.tipo == dataset.tipo
                      ).length
                    "
                    inline
                    overlap
                    :value="
                      !!context.datasetList.dataset.aux.items.filter(
                        (item) => item.tipo == dataset.tipo
                      ).length
                    "
                  ></v-badge>
                </v-col>
              </v-row>
              <!-- horas apontadas -->
              <v-row v-bind="prop.row" justify="end">
                <v-col v-bind="prop.col" align="center" cols="2">
                  <span>
                    {{ itemTemposTotais(dataset, context) }}
                  </span>
                </v-col>
              </v-row>
            </template>

            <template #content="{ dataset }">
              <c-expansion-panels
                flat
                :hasDot="hasDot"
                :isDisabled="isDisabled"
                :pageModeView="true"
                :params="params"
                :value="getItems(context, dataset)"
              >
                <template #header="{ dataset: item }">
                  <v-row v-bind="prop.row">
                    <!-- data inicio fim -->
                    <v-col v-bind="prop.col" cols="10">
                      <span class="text-body-1">
                        {{ itemData(item) }}
                      </span>
                    </v-col>
                    <!-- hora apontada -->
                    <v-col v-bind="prop.col" align="center" cols="2">
                      <span class="text-body-1">
                        {{ itemTempos(item) }}
                      </span>
                    </v-col>
                  </v-row>
                </template>

                <template #content="{ dataset: item }">
                  <c-colaborador-page-apontamento-view :item="item" />
                </template>
              </c-expansion-panels>
            </template>
          </c-expansion-panels>
        </v-container>
      </template>
    </c-base-list>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CColaboradorPageApontamentoView from "@/components/pages/colaborador/pages/CColaboradorPageApontamentoView";
import mixinData from "@/mixins/mixinData";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Colaborador_Apontamentos",

  /// COMPONENTS
  components: {
    CColaboradorPageApontamentoView,
  },

  /// MIXINS
  mixins: [mixinData, mixinPageDetail, mixinPageList],

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    cIdFeature() {
      const idFeature = new Number(this.idFeature);
      idFeature.pageAlt = true;

      return idFeature;
    },
  },

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idFeature: getFeature("optionApontamentos").id,
      idPage: getPagina("colaboradores").id,
      idPageAlt: getPagina("colaborador").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("colaboradorColaborador").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            idPessoaColaborador: null,
            nomeAlt: null,
            grupo: null,
            dataAdmissao: null,
            dataDemissao: null,
            observacoes: null,
            dataAtualizacao: null,
          },
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          this.contextDetail = context;

          this.idPessoaColaborador =
            context.dataset.main.detail.idPessoaColaborador;
          context.dataset.main.proprietarios = null;

          if (this.contextList && this.loadList) {
            this.contextList.clickSearch();
          }

          this.loadList = true;
        },
      },

      funcsList: {
        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          let params = null;
          params = {
            idPessoaColaborador: this.idPessoaColaborador,
            dataInicio: this.searchData.inicio,
            dataFim: this.searchData.fim,
          };

          return JSON.stringify(params);
        },

        posSubmit: async (dataset, context, datasets, indice) => {
          if (indice == 0) {
            this.contextList = context;

            context.datasetList.dataset.aux.apontamentos.forEach(
              (apontamento, i) => {
                apontamento.index = i;
              }
            );
          }
        },
      },

      params: {
        context: null,
      },

      contextDetail: null,
      contextList: null,
      idPessoaColaborador: null,
      loadList: false,
      panelsOpen: [],
      search: "",
      searchData: {
        inicio: this.subYears(new Date(), 1).toISOString().substring(0, 10),
        fim: new Date().toISOString().substring(0, 10),
      },
      showSearchOptions: false,
    };
  },

  /// METHODS
  methods: {
    changeDataInicio() {
      this.searchData.fim = null;
    },

    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    fnConvertTimeToMinutes(time) {
      if (!time || typeof time !== "string") {
        return time;
      }

      const horario = time.split(/[.:]/);
      return this.hoursToMinutes(
        parseInt(horario[0], 10) + parseInt(horario[1], 10) / 60
      );
    },

    getItems(context, dataset) {
      return context.datasetList.dataset.aux.items.filter(
        (item) => item.tipo == dataset.tipo
      );
    },

    //? - Fer: Definir o hasDot
    hasDot(traducao) {
      return !traducao.texto;
    },

    //? - Fer: Definir o isDisabled
    isDisabled(traducao) {
      return (traducao.texto?.length || 0) <= this.maxLength;
    },

    itemData(item) {
      const dataFim = item.dataFim
        ? ` - ${this.dateFormat(item.dataFim, "date")}`
        : "";
      return `${this.dateFormat(
        item.dataInicio || item.data,
        `date`
      )}${dataFim}`;
    },

    itemTempos(dataset) {
      return dataset.horasApontadas
        ? dataset.horasApontadas.substring(0, 5)
        : dataset.dias;
    },

    itemTemposTotais(dataset, context) {
      let time = true;

      const horasApontadas = context.datasetList.dataset.aux.items
        .filter((item) => item.tipo == dataset.tipo)
        .map((item) => {
          if (item.horasApontadas) {
            return this.fnConvertTimeToMinutes(item.horasApontadas);
          } else {
            time = false;
            return item.dias;
          }
        })
        .reduce((acc, item) => (acc += item), 0);

      return time
        ? new Date(null, null, null, null, null, horasApontadas)
            .toTimeString()
            .split(" ")[0]
            .substring(3)
        : horasApontadas;
    },
  },
};
</script>