<!-- COD: FDS 01/07/2022 CContasPagRec -->
<!-- *VER: JER 01/07/2022 CContasPagRec -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- forma de pagamento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.idFormaPagamento).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataVencimento -->
      <v-col v-bind="prop.col" v-if="dataset.dataVencimento" cols="5">
        <span class="font-weight-medium text-body-2">venc </span>
        <span class="font-weight-medium text-body-1">
          {{ dateFormat(dataset.dataVencimento, "date") }}
        </span>
      </v-col>
      <!-- valor -->
      <v-col v-bind="prop.col" v-if="dataset.valor" align="end" cols="7">
        <span class="font-weight-medium text-body-2">valor </span>
        <span class="font-weight-medium text-body-1">
          {{ numberFormat(dataset.valor, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataEmissao -->
      <v-col v-bind="prop.col" v-if="dataset.dataEmissao" cols="5">
        <span class="text-body-2">emis </span>
        <span class="text-body-1">
          {{ dateFormat(dataset.dataEmissao, "date") }}
        </span>
      </v-col>
      <!-- saldo -->
      <v-col v-bind="prop.col" v-if="dataset.saldo" align="end" cols="7">
        <span class="text-body-2">saldo</span>
        <span class="text-body-1">
          {{ numberFormat(dataset.saldo, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- prazo -->
      <v-col v-bind="prop.col" cols="6">
        <div v-if="dataset.prazo">
          <span class="text-body-2">prazo</span>
          <span class="text-body-1">
            {{ dataset.prazo }}
          </span>
          <span class="ml-1 text-body-2">dias</span>
        </div>
      </v-col>
      <!-- duplicata -->
      <v-col v-bind="prop.col" v-if="dataset.duplicata" align="end" cols="6">
        <span class="text-body-2">duplicata</span>
        <span class="text-body-1">
          {{ dataset.duplicata.mask("##?#?#?#?#?#?#?#?#?#?#?#?#?#?/A") }}
        </span>
      </v-col>
    </v-row>

    <!-- pedido -->
    <v-row v-bind="prop.row" v-if="dataset.idPedido">
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-2">pedido </span>
        <span class="text-body-1">
          {{ dataset.idPedido }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa_nome -->
    <v-row v-bind="prop.row" v-if="dataset.pessoa_nome">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoa_nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CContasPagRec",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),
  },
};
</script>