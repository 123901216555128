<!-- COD: BJB 06/07/2022 CVerbaEdit -->
<!-- *VER: JER 06/08/2022 CVerbaEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- verba pai nomeAlt -->
    <v-row
      v-bind="prop.row"
      v-if="getConst('config').pageMode.edit.id == context.idPageMode"
    >
      <v-col v-bind="prop.col" align="center">
        <span classe="text-body-1">
          {{ dataset.aux.detail.verbaPai_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- pai -->
    <v-row
      v-bind="prop.row"
      v-if="getConst('config').pageMode.insert.id == context.idPageMode"
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPai"
          :itemsIniciais="dataset.aux.autocomplete.verbasPai"
          label="verba pai"
          :path="`/verbas/autocomplete/verbasPai/${dataset.main.detail.idTipo}/`"
          required
          :rules="[]"
          @change="changeVerbaPai"
        />
      </v-col>
    </v-row>

    <!-- nivel -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-badge
          :color="dataset.main.detail.nivel == 4 ? 'green' : 'grey'"
          :content="dataset.main.detail.nivel"
        />
      </v-col>
    </v-row>

    <!-- id -->
    <v-row
      v-bind="prop.row"
      v-if="getConst('config').pageMode.insert.id == context.idPageMode"
    >
      <v-col v-bind="prop.col" cols="4">
        <c-text-field
          v-model="dataset.main.detail.id"
          label="id"
          :max="15"
          :min="1"
          required
          type="tel"
        />
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nome alt"
          :max="40"
          required
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CVerbaEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVerba: "verbas/getVerba",
    }),
  },

  /// METHODS
  methods: {
    changeVerbaPai() {
      if (this.dataset.main.detail.idPai) {
        const verbaPai = this.getVerba(this.dataset.main.detail.idPai);
        this.dataset.main.detail.id = verbaPai.id;
        this.dataset.main.detail.nivel = verbaPai.nivel + 1;
      }
    },

    clickVerba() {
      this.$router.push(`/verba/${this.dataset.main.detail.idPai}`);
    },
  },
};
</script>