// COD: BJB 15/08/2022 vuetify
// *VER: JER 19/08/2022 vuetify
// /11/

/// IMPORT
import Vue from 'vue'
import CAutocomplete from "@/components/ui/CAutocomplete";
import CBottomSheet from "@/components/ui/CBottomSheet";
import CBtn from "@/components/ui/CBtn";
import CCheckbox from "@/components/ui/CCheckbox";
import CDatetimePicker from "@/components/ui/CDatetimePicker";
import CExpansionPanel from "@/components/ui/CExpansionPanel";
import CExpansionPanels from "@/components/ui/CExpansionPanels";
import CFileInput from "@/components/ui/CFileInput";
import CHelp from "@/components/ui/CHelp";
import CMapList from "@/components/ui/CMapList";
import CMenu from "@/components/ui/CMenu";
import CRichtext from "@/components/ui/CRichtext";
import CSelect from "@/components/ui/CSelect";
import CSnackbar from "@/components/ui/CSnackbar";
import CSwitch from "@/components/ui/CSwitch";
import CTextarea from "@/components/ui/CTextarea";
import CTextField from "@/components/ui/CTextField";
import CTextMoney from "@/components/ui/CTextMoney";
import CTooltip from "@/components/ui/CTooltip";
import CBaseImage from "@/components/bases/CBaseImage";

import Vuetify, { VAppBar, VFooter } from 'vuetify/lib'
import 'vuetify/src/styles/main.sass'
import en from 'vuetify/src/locale/en.ts'
import es from 'vuetify/src/locale/es.ts'
import pt from 'vuetify/src/locale/pt.ts'

Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
  components: {
    CAutocomplete,
    CBaseImage,
    CBottomSheet,
    CBtn,
    CCheckbox,
    CDatetimePicker,
    CExpansionPanel,
    CExpansionPanels,
    CFileInput,
    CHelp,
    CMapList,
    CMenu,
    CRichtext,
    CSelect,
    CSnackbar,
    CSwitch,
    CTextarea,
    CTextField,
    CTextMoney,
    CTooltip,

    VAppBar,
    VFooter,
  },
})

export default new Vuetify({
  /// VUETIFY
  icons: {
    iconfont: 'md'
  },
  lang: {
    locales: { pt, en, es },
    current: 'pt',
    t: (key, ...params) => {
      if (!key.startsWith("$vuetify"))
        return this.t(key, ...params);
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#1565C0', // blue darken-3
        secondary: '#546E7A', // blue-grey darken-1
        accent: '#1976D2', // blue darken-2
        info: '#039BE5', // light-blue darken-1
        success: '#43A047', // green darken-1
        warning: '#FFB300', // amber darken-1
        error: '#D81B60',  // pink darken-1
        notification: '#BDBDBD',  // grey lighten-1
        corOutlet: '#8E24AA', // purple darken-1
        corEntregar: '#6A1B9A', // purple darken-3
        corRetirar: '#AD1457', // pink darken-3
        corDisabled: '#757575', // grey darken-1
        corTipo: '#424242', // grey darken-3
        corZebra: '#FAFAFA' // grey lighten-5
      }
    }
  }
});