<!-- COD: LYF 30/06/2022 CBancoView -->
<!-- *VER: JER 08/07/2022 CBancoView -->
<!-- /11/ -->
<!-- #arq:CBancoView #complex:11 #ver:0.3 #data:20220630 #fase:0 #status:1 #dev:LYF -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais (bandeira) -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPais">
      <v-col v-bind="prop.col" align="center">
        <v-img
          :src="getLocalidade(dataset.main.detail.idPais).endereco"
          width="30"
        />
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- swift (prefixo) -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.swiftPrefixo">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">swift (prefixo)</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.swiftPrefixo }}
        </span>
      </v-col>
    </v-row>

    <!-- codigo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.codigo">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">código</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.codigo }}
        </span>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.observacao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">({{ dataset.main.detail.observacao }})</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CBancoView",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
    }),
  },
};
</script>