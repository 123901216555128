<!-- COD: FDS 04/07/2022 TermosServico -->
<!-- *VER: JER 19/08/2022 TermosServico -->
<!-- /22/ -->

<template>
  <div>
    <v-container>
      <v-layout justify-center row>
        <v-card>
          <!-- /// NAVBAR -->
          <c-the-navbar mode="page" title="Termos de Serviço">
            <template #icon>
              <c-btn icon router to="/">
                <v-icon class="ml-2" large>
                  {{ icon }}
                </v-icon>
              </c-btn>
            </template>
          </c-the-navbar>

          <!-- /// TERMOS DE SERVICO -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="end">
              <c-btn icon classe="mr-n1" @click="clickPanels">
                <v-icon>
                  {{ iconPanelCollapse }}
                </v-icon>
              </c-btn>
            </v-col>
          </v-row>

          <v-expansion-panels
            v-model="panelsOpen"
            multiple
            ref="panelsDocumentos"
          >
            <c-base-documento
              v-for="documento in documentos(null)"
              :context="context"
              :documento="documento"
              :hasAdminOptions="hasAdminOptions"
              :id="'open' in documento.options ? documento.id : null"
              :idPage="idPage"
              :key="documento.index"
              :pageModeViewPai="pageModeView"
              @clickEditView="clickEditView"
            />
          </v-expansion-panels>

          <!-- /// VERSAO -->
          <c-expansion-panel v-if="documentosPage[0].options" class="my-8">
            <template #header>
              <v-container v-bind="prop.container">
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <span class="text-h6"
                      >{{ documentosPage[0].options.versao }},
                      {{
                        dataFormataRelativa(
                          documentosPage[0].options.data,
                          null
                        )
                      }}</span
                    >
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </c-expansion-panel>

          <v-card-actions>
            <c-btn
              v-scroll="onScroll"
              v-show="showFab"
              bottom
              fab
              fixed
              right
              @click="toScroll"
            >
              <v-icon>
                {{ iconScroll }}
              </v-icon>
            </c-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-container>
    <c-the-navbar bottom mode="page" title="" />
  </div>
</template>

<script>
/// IMPORT
import mixinDocumento from "@/mixins/mixinDocumento";

export default {
  /// NAME
  name: "TermosServico",

  /// MIXINS
  mixins: [mixinDocumento],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("termosServico").id,
    };
  },
};
</script>