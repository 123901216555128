// COD: LYF 10/08/2022 mixinPage
// *VER: JER 19/08/2022 mixinPage
// /11/

import { mapGetters, mapActions } from "vuex";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "mixinPage",

  /// MIXINS
  mixins: [mixinData, mixinLib],

  /// COMPUTED
  computed: {
    ...mapGetters({
      getBanco: "bancos/getBanco",
      getBancos: "bancos/getBancos",
      getCartao: "cartoes/getCartao",
      getCategoria: "categorias/getCategoria",
      getCategoriasLength: "categorias/getCategoriasLength",
      getConst: "constantes/getConst",
      getContaContabil: "contasContabeis/getContaContabil",
      getContasContabeis: "contasContabeis/getContasContabeis",
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getFeatures: "paginas/getFeatures",
      getFeriado: "feriados/getFeriado",
      getFeriados: "feriados/getFeriados",
      getImposto: "impostos/getImposto",
      getLocalidade_configuracoesRegionais: "localidades/getLocalidade_configuracoesRegionais",
      getLocalidade_idioma: "localidades/getLocalidade_idioma",
      getLocalidade: "localidades/getLocalidade",
      getOperacao: "operacoes/getOperacao",
      getOperacoes: "operacoes/getOperacoes",
      getPagina: "paginas/getPagina",
      getPaginas: "paginas/getPaginas",
      getTabela_coluna: "tabelas/getTabela_coluna",
      getTabela_colunas: "tabelas/getTabela_colunas",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
      getUsuario: "variaveis/getUsuario",
      getVar: "variaveis/getVar",
      getVerba: "verbas/getVerba",
      getVerbas: "verbas/getVerbas",
    }),
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar"
    })
  }
}