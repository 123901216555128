<!-- COD: BJB 21/07/2022 CMapList -->
<!-- *VER: JER 10/08/2022 CMapList -->
<!-- /22/ -->

<template>
  <v-card class="mt-4" tile>
    <!-- /// filtrar itens selecionados -->
    <v-row class="mb-n8">
      <v-col align="center" cols="12">
        <v-btn class="primary--text ml-n4" text @click.stop="clickFiltrarItens">
          {{ labelShowOnlySelecteds }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center" class="ml-1 mb-2">
      <!-- /// busca -->
      <v-col cols="11">
        <c-text-field
          v-model="searchItems"
          classe="mr-5"
          clearable
          :max="50"
          placeholder="busca"
          prepend-inner-icon="mdi-magnify"
          type="search"
        />
      </v-col>

      <!-- /// incluir item novo na lista -->
      <v-col cols="1">
        <c-btn
          v-if="showOnlySelecteds"
          classe="ml-n10"
          :disabled="
            !showOnlySelecteds || model.some((item) => item.valorFixo === null)
          "
          icon
          @click.stop="incluir"
        >
          <v-icon color="green">mdi-plus-circle-outline</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <v-row class="ml-3 mt-n8 mb-2" align="center">
      <!-- /// busca -->
      <v-col cols="1">
        <v-badge
          :content="itemsComputed.length.toString()"
          :color="'corDisabled'"
        ></v-badge>
      </v-col>
    </v-row>

    <!-- /// lista -->
    <v-list shaped class="overflow-y-auto" max-height="500">
      <c-checkbox
        v-if="hasSelectAll && !showOnlySelecteds"
        class="ml-2 mt-n3"
        indeterminate
        label="select all"
        :value="
          model.length == items.length ? true : model.length ? null : false
        "
        @click.stop="selectAll()"
      />
      <draggable
        v-bind="dragOptions"
        v-if="!model.some((itemSelected) => !!itemSelected.del)"
        v-model="model"
        draggable=".draggableItem"
      >
        <!-- /// list groupo -->
        <v-list-group
          v-for="(item, i) in itemsComputed"
          class="mx-n2"
          :class="listGroupClass"
          :disabled="!showOnlySelecteds"
          :key="i"
          :value="false"
        >
          <!-- /// titulo -->
          <template v-slot:activator>
            <v-row align="center">
              <v-col cols="1">
                <c-checkbox
                  :value="selectedItem(item)"
                  @click.stop="selectItem(item, $event)"
                />
              </v-col>
              <v-col cols="10">
                <slot
                  :index="i"
                  :item="item"
                  :itemsSelected="model"
                  name="title"
                />
              </v-col>
              <v-col cols="1" align="center">
                <v-icon
                  v-if="isDraggable && showOnlySelecteds && !searchItems"
                  class="ml-n1"
                  >mdi-drag-horizontal-variant</v-icon
                >
                <v-badge
                  v-if="hasDotMapList(item, i)"
                  class="ml-1"
                  color="red"
                  dot
                ></v-badge>
              </v-col>
            </v-row>
          </template>

          <!-- /// items -->
          <v-list-item>
            <v-list-item-content>
              <slot :item="item" :model="model" name="content" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </draggable>
    </v-list>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";

export default {
  /// NAME
  name: "CMapList",

  /// PROPS
  props: {
    bindKey: { type: String, required: true }, // representa a chave de ligação entre a lista completa e lista selecionada
    hasDotMapList: { type: Function, default: () => false },
    hasSelectAll: { type: Boolean, default: false }, // indica se o checkbox selectAll será mostrado
    isDraggable: { type: Boolean, default: false }, // indica se os items da lista selecionada podem ser reordenados com drag and drop
    item: { type: [Object, String, Number], default: null }, // é um item a ser adicionado na lista selecionada
    items: { type: Array, required: true }, // são os itens da lista completa
    value: { type: Array, required: true }, // são os itens da lista selecionada
  },

  /// COMPONENTS
  components: {
    draggable,
  },

  /// DATA
  data() {
    return {
      optionsJson: [],
      searchItems: "",
      showOnlySelecteds: !!this.value.length,
    };
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        delay: 1000,
        ghostClass: "ghost",
      };
    },

    itemsComputed() {
      return this.showOnlySelecteds
        ? this.model.filter(
            (item) =>
              !this.searchItems ||
              (item[this.bindKey] || "")
                .toUpperCase()
                .includes(this.searchItems.toUpperCase()) ||
              (item.valorFixo &&
                item.valorFixo
                  .toUpperCase()
                  .includes(this.searchItems.toUpperCase()))
          )
        : this.items.filter(
            (item) =>
              !this.searchItems ||
              (item[this.bindKey] || "")
                .toUpperCase()
                .includes(this.searchItems.toUpperCase())
          );
    },

    labelShowOnlySelecteds() {
      return this.showOnlySelecteds
        ? "selecionar novos itens"
        : "editar itens selecionados";
    },

    listGroupClass() {
      return this.isDraggable && this.showOnlySelecteds && !this.searchItems
        ? "draggableItem"
        : "";
    },
  },

  /// WATCH
  watch: {
    showOnlySelecteds() {
      setTimeout(() => {
        this.model = this.model.filter((itemSelected) => {
          const ret = !itemSelected.del;

          delete itemSelected.del;

          return ret;
        });
      }, 100);
    },
  },

  /// METHODS
  methods: {
    clickFiltrarItens() {
      this.showOnlySelecteds = !this.showOnlySelecteds;
    },

    incluir() {
      const item =
        typeof this.item == "object"
          ? JSON.parse(JSON.stringify(this.item))
          : this.item;

      this.model.unshift(item);
    },

    selectAll() {
      if (this.model.length == this.items.length) {
        this.model = [];
      } else {
        this.model.push(
          ...this.items.filter(
            (optionBase) =>
              !this.model.some(
                (optionSelected) =>
                  optionSelected[this.bindKey] == optionBase[this.bindKey]
              )
          )
        );
      }
    },

    selectedItem(item) {
      return this.model.some(
        (itemSelected) =>
          !item.del &&
          (item[this.bindKey] === undefined ||
            itemSelected[this.bindKey] == item[this.bindKey])
      );
    },

    selectItem(item, evento) {
      evento.preventDefault();

      if (this.showOnlySelecteds) {
        if (item.del) {
          delete item.del;
        } else {
          item.del = true;
        }
      } else {
        const index = this.model.findIndex(
          (itemSelected) =>
            itemSelected[this.bindKey] &&
            itemSelected[this.bindKey] == item[this.bindKey]
        );

        if (index >= 0) {
          this.model.splice(index, 1);
        } else {
          this.model.push(item);
        }
      }
    },
  },
};
</script>