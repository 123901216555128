var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"mb-10 pa-0",attrs:{"id":"formSelect"}},[(_vm.idPage && _vm.idPageAlt)?_c('c-base-list',{attrs:{"funcs":_vm.funcs,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"pageModeEdit":true},scopedSlots:_vm._u([{key:"searchInSearch",fn:function(){return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"dinheiro"},on:{"click":_vm.clickDinheiro},model:{value:(_vm.isDinheiro),callback:function ($$v) {_vm.isDinheiro=$$v},expression:"isDinheiro"}})],1)],1),_c('v-row',_vm._b({staticClass:"mt-n8"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"cheque"},on:{"click":_vm.clickCheque},model:{value:(_vm.isCheque),callback:function ($$v) {_vm.isCheque=$$v},expression:"isCheque"}})],1)],1)]},proxy:true},{key:"insert",fn:function(){return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"red--text text-body-1"},[_vm._v(" selecione os valores a depositar ")])])],1)]},proxy:true},{key:"default",fn:function(ref){
var context = ref.context;
var dataset = ref.dataset;
return [(_vm.showDataset(dataset))?_c('c-valoresPagRec-page-depositoBancario-edit',{attrs:{"context":context,"valorPagRec":dataset}}):_vm._e()]}},{key:"bottom",fn:function(ref){
var context = ref.context;
return [_c('v-row',_vm._b({staticClass:"mt-4"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"items":_vm.contasBancarias,"label":"conta bancária","required":""},model:{value:(_vm.idPessoaParceiroContaBancaria),callback:function ($$v) {_vm.idPessoaParceiroContaBancaria=$$v},expression:"idPessoaParceiroContaBancaria"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('v-badge',{attrs:{"bottom":"","color":"grey","content":_vm.itensSelecionados(context),"offset-y":"35","value":!!_vm.itensSelecionados(context)}},[_c('c-text-money',{attrs:{"label":"total selecionado","max":13,"options":{
                style: 'currency',
                length: 11,
                precision: 2,
              },"outlined":"","readonly":"","required":"","value":_vm.totalSelecionado(context)}})],1)],1)],1)]}}],null,false,317546046)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }