<!-- COD: LYF 25/08/2022 Extrato -->
<!-- *VER: JER 30/08/2022 Extrato -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo('tipoExtrato').id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-extrato-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-extrato-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CExtratoEdit from "@/components/pages/extrato/CExtratoEdit";
import CExtratoView from "@/components/pages/extrato/CExtratoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Extrato",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CExtratoEdit,
    CExtratoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("extrato").id,
      idPageAlt: getPagina("extratos").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("extratoBanco").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idPessoaParceiroContaBancaria: null,
            requireConciliacao: false,
            dataEmissao: new Date(),
            dataInicio: new Date(),
            dataFim: new Date(),
            debitos: null,
            totalDebitos: null,
            creditos: null,
            totalCreditos: null,
            saldo: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          lancamentos: [],
        },
        aux: {
          detail: {
            pessoaParceiro_nomeAlt: null,
            idBanco: null,
            banco: null,
            agencia: null,
            agenciaDV: null,
            conta: null,
            contaDV: null,
          },
          contaBancaria: {
            banco: null,
            agencia: null,
            conta: null,
          },
          lancamentos: [],
        },
      },

      funcs: {
        clickConciliar: () => {
          this.$router.push(
            `/extrato/extrato_conciliacoes/${this.dataset.main.detail.id}`
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        fnParams: () => {
          const params = JSON.parse(JSON.stringify(this.params));
          delete params.context;
          delete params.clickValorPagRec;

          return JSON.stringify(params);
        },
      },

      params: {
        context: null,
        clickValorPagRec: null,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>