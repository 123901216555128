<!-- COD: BJB 21/07/2022 CTextField -->
<!-- *VER: JER 10/08/2022 CTextField -->
<!-- /22/ -->

<template>
  <c-help :help="help" :offsetX="offsetX" :offsetY="offsetY">
    <v-text-field
      v-bind="$attrs"
      v-model="model"
      :class="classe"
      :clearable="clearable"
      :label="cLabel"
      :maxlength="cMax"
      :placeholder="cPlaceholder"
      :rules="
        $attrs.disabled
          ? []
          : cRules ||
            commonRules.common(
              cType,
              required,
              min,
              max,
              noAccept,
              clean,
              msg,
              options ? options.mask : null,
              options ? options.options : null,
              panelCallback,
              dynamicsRules
            )
      "
      :type="cType"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @click="$emit('click', $event)"
      @click:clear="$emit('clickClear', $event)"
      @click:append="$emit('clickAppend', $event)"
      @click:append-outer="$emit('clickAppendOuter', $event)"
      @click:prepend="$emit('clickPrepend', $event)"
      @focus="$emit('focus', $event)"
      @keypress="keyPress($event)"
      @keyup="$emit('keyup', $event)"
    >
      <template #prepend-inner>
        <slot name="prepend-inner"> </slot>
      </template>
      <template #prepend>
        <slot name="prepend"> </slot>
      </template>
    </v-text-field>
  </c-help>
</template>

<script>
/// IMPORT
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CTextField",

  /// MIXINS
  mixins: [mixinLib],

  /// MODEL
  model: { prop: "value", event: "input" },

  /// PROPS
  props: {
    classe: { type: String, default: "text-body-1" },
    clean: { type: String, default: " " },
    clearable: { type: Boolean, default: true },
    cRules: { type: Array },
    dynamicsRules: { type: Array },
    help: { type: Object },
    max: { type: Number, required: true },
    min: { type: Number },
    msg: { type: String },
    options: { type: Object },
    noAccept: { type: String },
    panel: { type: Object },
    required: { type: Boolean, default: false },
    value: { type: [String, Number] },
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.options?.mask
          ? (this.value?.toString() || "").mask(
              this.options.mask,
              this.options?.options || {}
            )
          : this.value;
      },
      set: function (newValue) {
        const options = JSON.parse(JSON.stringify(this.options?.options || {}));
        options.clean = true;

        this.$emit(
          "input",
          this.options?.mask && newValue
            ? newValue.mask(this.options.mask, options)
            : newValue || null
        );
      },
    },

    cLabel() {
      const label = this.translate(this.$attrs.label || "");

      return `${label}${label && this.required ? " *" : ""}`;
    },

    cMax() {
      let max = this.max;

      if (this.options?.mask) {
        const masks = this.cleanMask(this.options.mask, true);

        max = Array.isArray(masks)
          ? masks[masks.length - 1].length
          : masks.length;
      }

      return max;
    },

    cOptions() {
      const cOptions = this.options || null;

      if (cOptions) {
        if (cOptions.inputMask) {
          cOptions.inputMask =
            cOptions.inputMask.length == 1
              ? cOptions.inputMask.repeat(this.max)
              : cOptions.inputMask;
          cOptions.outputMask =
            cOptions.outputMask == "*"
              ? cOptions.inputMask
              : cOptions.outputMask;
          cOptions.empty =
            cOptions.empty == "*" ? cOptions.inputMask : cOptions.empty;
        }
      }

      return cOptions;
    },

    cPlaceholder() {
      return this.translate(
        this.$attrs.placeholder == "*"
          ? this.cOptions.inputMask
          : this.$attrs.placeholder?.length == 1
          ? this.$attrs.placeholder.repeat(this.max)
          : this.$attrs.placeholder
      );
    },

    cType() {
      let cType = this.$attrs.type;

      if (this.options?.mask && !cType) {
        const regexGrupos = new RegExp(
          `(${this.strTokens.replace("|#", "")})+`,
          "g"
        );

        const grupos = this.options.mask.match(regexGrupos);

        if (!grupos) {
          cType = "tel";
        }
      }

      return cType;
    },

    offsetX() {
      return "8";
    },

    offsetY() {
      return "32";
    },

    panelCallback() {
      return this.panel ? this.panel.callbackRules : null;
    },
  },

  /// METHODS
  methods: {
    keyPress(event) {
      if (this.options?.mask) {
        event.preventDefault();

        const options = JSON.parse(JSON.stringify(this.options?.options || {}));

        options.clean = true;

        const newValue = `${this.value || ""}${event.key}`.mask(
          this.options.mask,
          options
        );

        if ((this.value || "").length < newValue.length) {
          this.$emit(
            "input",
            this.options?.mask && newValue
              ? newValue.mask(this.options.mask, options)
              : newValue
          );
        }
      }

      this.$emit("keypress", event);
    },
  },
};
</script>