<!-- COD: BJB 29/07/2022 FolhaPagamento_Verbas -->
<!-- *VER: JER 08/08/2022 FolhaPagamento_Verbas -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPageDetail"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context, pageModeView }">
        <c-folhaPagamento-view
          :context="context"
          :dataset="dataset"
          :pageModeView="pageModeView"
        />
      </template>
    </c-base-detail>

    <c-base-list
      v-if="loadList"
      :funcs="funcsList"
      :hasSearch="true"
      :hasPessoaParceiro="false"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :pageModeEdit="true"
      @showSearchOptions="showSearchOptions = $event"
    >
      <template #search="{ context }">
        <v-container>
          <v-row v-bind="prop.row" v-show="showSearchOptions">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idVerbas"
                clearable
                :items="verbas"
                label="verbas"
                multiple
                :properties="{
                  chip: true,
                }"
                @change="filtroDatasets(context)"
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" v-show="showSearchOptions">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idColaboradores"
                clearable
                :items="colaboradores"
                label="colaboradores"
                multiple
                :properties="{
                  chip: true,
                }"
                @change="filtroDatasets(context)"
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="pessoalAdministrativo"
                label="só pessoal administrativo"
                @click="filtroDatasets(context, false)"
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="pessoalComercial"
                label="só pessoal comercial"
                @click="filtroDatasets(context, true)"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #listContent="{ context, datasets }">
        <v-container>
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="end">
              <c-btn icon @click="clickPanels(context)" class="mr-1">
                <v-icon>
                  {{
                    !panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}
                </v-icon>
              </c-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-expansion-panels v-model="panelsOpen" class="py-0 ma-0" multiple>
          <v-expansion-panel v-if="context.datasetList.dataset" expand>
            <v-expansion-panel-header class="grey lighten-3">
              <v-container class="py-0">
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" cols="10">
                    <span class="text-h6 ml-n6">valores totais</span>
                  </v-col>
                  <v-col v-bind="prop.col" align="end" cols="2">
                    <v-badge
                      :content="
                        totais(context.datasetList.dataset.main.verbas).length
                      "
                      overlap
                      inline
                      color="grey"
                    />
                  </v-col>
                </v-row>
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" align="end">
                    <v-badge
                      color="grey"
                      :content="
                        numberFormat(
                          totalLiquidos(context.datasetList.dataset.main.verbas)
                            .total,
                          'currency'
                        )
                      "
                      left
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <c-expansion-panels
                flat
                :hasDot="hasDot"
                :hasPanelsCollapse="false"
                :isDisabled="() => true"
                :pageModeView="pageModeView"
                :value="
                  totais(context.datasetList.dataset.main.verbas).filter(
                    (verba) => verba.total
                  )
                "
              >
                <template #header="{ dataset: total }">
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" cols="6">
                      <span :class="`text-body-1 ${classTotais(total)}`">
                        {{ total.item }}
                      </span>
                    </v-col>
                    <v-col v-bind="prop.col" align="end" cols="6">
                      <span
                        :class="`text-body-1 ${classTotais(total)} ${
                          total.total < 0 ? 'red--text' : ''
                        }`"
                      >
                        {{ numberFormat(total.total || 0, "currency") }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </c-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-for="(dataset, i) in datasets" expand :key="i">
            <v-expansion-panel-header class="grey lighten-3">
              <v-container class="py-0">
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" cols="10">
                    <span class="text-h6 ml-n6">
                      {{
                        `${funcsList.idHeader(context, dataset.id)}${translate(
                          dataset.header
                        )}`
                      }}
                    </span>
                  </v-col>
                  <v-col v-bind="prop.col" align="end" cols="2">
                    <v-badge
                      :content="bagdePanel(dataset, context)"
                      :value="!!bagdePanel(dataset, context)"
                      overlap
                      inline
                      color="grey"
                    />
                  </v-col>
                </v-row>

                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" cols="6">
                    <span class="text-body-1 ml-n6">
                      {{ translate(dataset.subheader) }}
                    </span>
                  </v-col>
                  <v-col v-bind="prop.col" align="end" cols="6">
                    <v-badge
                      color="grey"
                      :content="
                        numberFormat(totalPanel(dataset, context), 'currency')
                      "
                      left
                    />
                  </v-col>
                </v-row>

                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <span class="text-body-1 ml-n6">
                      {{ getAdmissaoDemissao(dataset) }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-bind="prop.row" v-if="pageModeView">
                <v-col v-bind="prop.col" align="center">
                  <a
                    class="text-body-1"
                    @click="funcsList.clickHeader(context, dataset.id)"
                  >
                    {{ translate(dataset.header) }}
                  </a>
                </v-col>
              </v-row>

              <c-expansion-panels
                flat
                :pageModeView="pageModeView"
                :value="
                  context.datasetList.dataset.main.verbas.filter(
                    (verba) => verba.idDataset == dataset.id
                  )
                "
              >
                <template #header="{ dataset: verba }">
                  <v-row v-bind="prop.row">
                    <!-- nome -->
                    <v-col v-bind="prop.col" cols="6">
                      <span class="text-body-1">
                        {{ verba.nome }}
                      </span>
                    </v-col>
                    <!-- valor -->
                    <v-col v-bind="prop.col" align="end" cols="6">
                      <span
                        class="text-body-1"
                        :class="verba.valor < 0 ? 'red--text' : ''"
                      >
                        {{ numberFormat(verba.valor || 0, "currency") }}
                      </span>
                    </v-col>
                  </v-row>
                </template>

                <template #content="{ dataset: verba }">
                  <c-folhaPagamento-page-verba-view
                    v-if="pageModeView"
                    :context="context"
                    :verba="verba"
                  />
                  <c-folhaPagamento-page-verba-edit
                    v-else
                    :context="context"
                    :verba="verba"
                  />
                </template>
              </c-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </c-base-list>
    <c-folhaPagamento-dialog-holerite
      v-if="showHolerite"
      v-model="showHolerite"
      :dataset="dataset"
      :idPessoaColaborador="idPessoaColaborador"
    />
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CFolhaPagamentoDialogHolerite from "@/components/pages/folhaPagamento/dialogs/CFolhaPagamentoDialogHolerite.vue";
import CFolhaPagamentoPageVerbaEdit from "@/components/pages/folhaPagamento/pages/CFolhaPagamentoPageVerbaEdit";
import CFolhaPagamentoPageVerbaView from "@/components/pages/folhaPagamento/pages/CFolhaPagamentoPageVerbaView";
import CFolhaPagamentoView from "@/components/pages/folhaPagamento/CFolhaPagamentoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "FolhaPagamento_Verbas",

  /// COMPONENTS
  components: {
    CFolhaPagamentoDialogHolerite,
    CFolhaPagamentoPageVerbaEdit,
    CFolhaPagamentoPageVerbaView,
    CFolhaPagamentoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idFeature: getFeature("optionVerbasFolha").id,
      idPage: getPagina("folhaPagamentoVerbas").id,
      idPageAlt: getPagina("folhasPagamentos").id,
      idPageDetail: getPagina("folhaPagamento").id,

      dataset: {
        metadados: {
          idEvento: getTipo_item("eventoAlterado").id,
          idPessoaParceiro: null,
          idUsuario: null,
          modo: getConst("config").modo.cadastro.id,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("fpNormal").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
          },
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          this.contextDetail = context;
          context.params.context = context;

          this.dataset = context.dataset;
          this.id = context.dataset.main.detail.id;

          if (this.contextList && this.loadList) {
            this.contextList.clickSearch();
          }

          this.loadList = true;
        },
      },

      funcsList: {
        changed: () => {
          return !this.pageModeView;
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );

          this.funcsList.clickEditView();
        },

        clickEditView: () => {
          this.contextDetail.clickEditView();
          this.pageModeView = !this.pageModeView;
        },

        clickHeader: (context, id, verba) => {
          const isVerba = context.idOrder == this.getFeature("orderVerba").id;
          id = id || (isVerba ? verba.idPessoaColaborador : verba.idVerba);

          if (verba ? !isVerba : isVerba) {
            this.$router.push(`/verba/${id}`);
          } else {
            this.idPessoaColaborador = id;
            this.showHolerite = true;
          }
        },

        clickOcorrencias: () => {
          this.$router.push(
            `/folhaPagamento/folhaPagamento_Ocorrencias/${this.dataset.main.detail.id}`
          );
        },

        clickSave: async (context) => {
          this.funcsList.clickEditView();

          const metadados = {
            idEvento: context.options.click.getTipo_item("eventoAlterado").id,
            modo: context.options.click.getConst("config").modo.cadastro.id,
            idUsuario: context.options.click.getVar("idUsuario"),
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
          };

          const datasetsSave = {
            metadados,
            main: {
              detail: this.dataset.main.detail,
              verbas: context.options.click.datasetList.dataset.main.verbas
                .filter(
                  (verba, i) =>
                    !verba.id ||
                    Object.keys(verba).some((key) => {
                      const verbaTemp = this.verbasTemp[i];
                      return verbaTemp[key] !== undefined
                        ? verba[key] != verbaTemp[key]
                        : false;
                    })
                )
                .map((verba) => {
                  return {
                    id: verba.id,
                    referencia: verba.referencia,
                    valor: verba.valor,
                    isUpdated: true,
                  };
                }),
            },
          };

          const method = "put";
          let url = `${context.options.click.getConst("app").baseUrlBackend}/${
            context.options.click.pageAlt.nome
          }`;
          const res = await context.options.click.submit(
            method,
            url,
            datasetsSave
          );

          if (res) {
            context.options.click.clickSearch();
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        data: (context, index) => {
          const verba = context.datasetList.dataset.main.verbas[index];

          return verba.data;
        },

        fnParams: () => {
          let params = null;
          params = {
            idFolhaPagamento: this.id,
            idVerbas: this.idVerbas,
            idColaboradores: this.idColaboradores,
            pessoalAdministrativo: this.pessoalAdministrativo,
            pessoalComercial: this.pessoalComercial,
            totalizar: this.totalizar,
          };

          return JSON.stringify(params);
        },

        getDetail: () => {
          return this.dataset;
        },

        idHeader: (context, id) => {
          const isVerba = context.idOrder == this.getFeature("orderVerba").id;
          return isVerba ? `${id} ` : "";
        },

        pessoaFavorecida: (context, index) => {
          const verba = context.datasetList.dataset.main.verbas[index];

          return verba.pessoaFavorecida_nome;
        },

        posSubmit: async (dataset, context, datasets, index) => {
          dataset.valorItem = this.totalPanel(dataset, context);

          if (index == 0) {
            this.contextList = context;

            this.verbasTemp = JSON.parse(
              JSON.stringify(context.datasetList.dataset.main.verbas)
            );

            context.page.nomeAjuda = context.pageAlt.nome;
            this.panelsOpen = Array(datasets.length)
              .fill()
              .map((_, i) => i);

            context.datasetList.dataset.main.verbas.forEach((verba, i) => {
              verba.index = i;
            });

            this.verbas = [
              ...new Set(context.datasetList.dataset.main.verbas),
            ].map((verba) => {
              return {
                value: verba.idVerba,
                text: `${verba.idVerba} ${
                  this.getVerba(verba.idVerba).nomeAlt
                }`,
              };
            });

            this.colaboradores =
              context.datasetList.dataset.main.colaboradores.map((dataset) => {
                return {
                  value: dataset.id,
                  text: this.nomeColaborador(dataset),
                };
              });

            this.colaboradores.sort((a, b) => (a.text < b.text ? -1 : 1));
          }
        },

        referencia: (context, index) => {
          const verba = context.datasetList.dataset.main.verbas[index];

          return verba.referencia;
        },

        valores: (context, index) => {
          const verba = context.datasetList.dataset.main.verbas[index];

          return verba.valor;
        },

        verbaUpdated: (context, index) => {
          return context.datasetList.dataset.main.verbas[index].isUpdated
            ? "mdi-asterisk"
            : "";
        },
      },

      params: {
        context: null,
      },

      colaboradores: [],
      contextDetail: null,
      contextList: null,
      datas: [],
      idColaboradores: null,
      idPerfilComercial: [
        getTipo_item("perfilProdutos").id,
        getTipo_item("perfilVendas").id,
      ],
      idPessoaColaborador: null,
      idVerbas: null,
      loadList: false,
      search: "",
      showSearchOptions: false,
      pageModeView: true,
      panelsOpen: [],
      pessoalAdministrativo: false,
      pessoalComercial: false,
      showHolerite: false,
      totalizar: false,
      verbas: [],
      verbasTemp: [],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    cIdFeature() {
      const idFeature = new Number(this.idFeature);
      idFeature.pageAlt = true;

      return idFeature;
    },
  },

  /// METHODS
  methods: {
    bagdeColaborador(colaborador, context) {
      return context.datasetList.dataset.main.verbas
        .filter((verba) => verba.idPessoaColaborador == colaborador.id)
        .filter((verba) =>
          this.idVerbas?.length ? this.idVerbas.includes(verba.idVerba) : true
        ).length;
    },

    bagdePanel(dataset, context) {
      return context.datasetList.dataset.main.verbas
        .filter((verba) => verba.idDataset == dataset.id)
        .filter((verba) => this.showVerba(verba))
        .filter((verba) =>
          this.idVerbas?.length ? this.idVerbas.includes(verba.idVerba) : true
        ).length;
    },

    classTotais(total) {
      return total.isLiquido ? "font-weight-medium" : "";
    },

    clickPanels(context) {
      this.panelsOpen = this.panelsOpen.length
        ? []
        : Array(context.datasets.length)
            .fill()
            .map((_, i) => i);
    },

    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    filtroDatasets(context, clickPessoalComercial = null) {
      if (clickPessoalComercial) {
        this.pessoalAdministrativo = false;
      } else if (clickPessoalComercial === false) {
        this.pessoalComercial = false;
      }

      context.datasets = JSON.parse(JSON.stringify(context.datasetsTemp));
      context.datasets = context.datasets
        .filter(
          (dataset) =>
            !this.idVerbas?.length ||
            (dataset.idArea && this.bagdeColaborador(dataset, context) > 0) ||
            this.idVerbas?.includes(dataset.id)
        )
        .filter(
          (dataset) =>
            !this.idColaboradores?.length ||
            (dataset.idArea && this.idColaboradores?.includes(dataset.id)) ||
            context.datasetList.dataset.main.verbas.some(
              (verba) =>
                verba.idDataset == dataset.id &&
                this.idColaboradores?.includes(verba.idPessoaColaborador)
            )
        )
        .filter(
          (dataset) =>
            (!this.pessoalAdministrativo && !this.pessoalComercial) ||
            (dataset.idArea &&
              this.idPerfilComercial.includes(dataset.idArea) ==
                !!this.pessoalComercial) ||
            context.datasetList.dataset.main.verbas.some(
              (verba) =>
                verba.idDataset == dataset.id &&
                this.idPerfilComercial.includes(verba.idArea) ==
                  !!this.pessoalComercial
            )
        );
    },

    getAdmissaoDemissao(dataset) {
      const dataDemissao = dataset.dataDemissao
        ? this.dateFormat(dataset.dataDemissao, "date")
        : "atual";
      return `${this.dateFormat(
        dataset.dataAdmissao,
        "date"
      )} - ${dataDemissao}`;
    },

    hasDot(verba) {
      return !verba.valor;
    },

    nomeColaborador(colaborador) {
      return colaborador.nome;
    },

    showVerba(verba) {
      let ret = true;

      if (this.pessoalComercial || this.pessoalAdministrativo) {
        ret =
          this.idPerfilComercial.includes(verba.idArea) ==
          !!this.pessoalComercial;
      }

      if (this.idVerbas?.length && ret) {
        ret = this.idVerbas.includes(verba.idVerba);
      }

      if (this.idColaboradores?.length && ret) {
        ret = this.idColaboradores.includes(verba.idPessoaColaborador);
      }

      return ret;
    },

    totalPanel(dataset, context) {
      const valor = context.datasetList.dataset.main.verbas
        .filter((verba) => verba.idDataset == dataset.id)
        .filter((verba) => this.showVerba(verba))
        .filter((verba) =>
          this.idVerbas?.length ? this.idVerbas.includes(verba.idVerba) : true
        )
        .reduce(
          (acc, verba) =>
            acc + parseFloat((isNaN(verba.valor) ? 0 : verba.valor) || 0),
          0
        );

      return valor || 0;
    },

    totalVerbas(dataset, context) {
      return context.datasetList.dataset.main.verbas
        .filter((verba) => verba.idDataset == dataset.id)
        .filter((verba) =>
          this.idVerbas?.length ? this.idVerbas.includes(verba.idVerba) : true
        );
    },

    totais(verbas) {
      let total;
      let item;
      const ret = [];

      // total proventos
      total = verbas
        .filter((verba) => verba.isProvento)
        .reduce((acc, verba) => (acc += parseFloat(verba.valor || 0)), 0);

      item = {
        item: "total proventos",
        total,
        isLiquido: false,
      };

      ret.push(item);

      // total descontos
      total = verbas
        .filter((verba) => verba.isProvento == false)
        .reduce((acc, verba) => (acc += parseFloat(verba.valor || 0)), 0);

      item = {
        item: "total descontos",
        total,
        isLiquido: false,
      };

      ret.push(item);

      // total líquidos
      total = ret.reduce((acc, el) => (acc += parseFloat(el.total || 0)), 0);

      item = {
        item: "total líquidos",
        total,
        isLiquido: true,
      };

      ret.push(item);

      // total bases
      total = verbas
        .filter((verba) => verba.idTipo == this.getTipo_item("verbaBase").id)
        .reduce((acc, verba) => (acc += parseFloat(verba.valor || 0)), 0);

      if (total) {
        item = {
          item: "total bases",
          total,
          isLiquido: false,
        };

        ret.push(item);
      }

      return ret;
    },

    totalLiquidos(verbas) {
      return this.totais(verbas).filter((el) => el.isLiquido)[0];
    },

    verba(context, index) {
      const verba = context.datasetList.dataset.main.verbas[index];

      return verba.nome;
    },
  },

  /// MOUNTED
  mounted() {
    this.params.datasColaboradorFilter = this.datasColaboradorFilter;
  },
};
</script>