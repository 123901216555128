<!-- COD: FDS 09/01/2023 CImpostoEdit -->
<!-- #VER: JER __/01/2023 CImpostoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-menu
          v-model="dataset.main.detail.idPais"
          classe="ml-n2"
          :items="paises"
        >
          <template #btn="{ itemSelected }">
            <v-img
              v-if="dataset.main.detail.idPais"
              alt="imagem"
              class="mr-2"
              contain
              :src="imagem"
              width="25"
            />
            <v-icon v-else large color="grey">mdi-earth</v-icon>
            <span v-if="itemSelected && itemSelected.value" class="text=body-2">
              {{ itemSelected.text }}
            </span>
          </template>
          <template #item="{ item }">
            <v-img
              v-if="item.value"
              alt="imagem"
              contain
              :src="getLocalidade(item.value).endereco"
              width="25"
            />
            <v-icon v-else>mdi-earth</v-icon>
            <v-list-item-title class="ml-2 text-body-2">
              {{ item.text }}
            </v-list-item-title>
          </template>
        </c-menu>
      </v-col>
    </v-row>

    <!-- abrangencia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="6">
        <c-select
          v-model="dataset.main.detail.idAbrangencia"
          clearable
          :items="abrangencias"
          label="abrangência"
          required
        />
      </v-col>
    </v-row>
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="50"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nomeAlt"
          :max="25"
          required
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CImpostoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidades: "localidades/getLocalidades",
      getTipo_item: "tipos/getTipo_item",
    }),

    abrangencias() {
      return this.getTipo_itens(this.getTipo(`tipoLocalidade`).id)
        .filter(
          (localidade) =>
            this.getTipo_item(localidade.value).options.abrangenciaImposto
        )
        .map((localidade) => {
          return {
            value: localidade.value,
            text: this.getTipo_item(localidade.value).options
              .abrangenciaImposto,
          };
        });
    },

    imagem() {
      return this.dataset.main.detail.idPais
        ? this.getLocalidade(this.dataset.main.detail.idPais).endereco
        : "";
    },

    paises() {
      return this.paisesAtivos;
    },
  },
};
</script>