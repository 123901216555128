<!-- COD: FDS 25/07/2022 Produtos_Gerenciar -->
<!-- *VER: JER 28/07/2022 Produtos_Gerenciar -->
<!-- /11/ -->

<template>
  <c-base-list
    v-if="idPage && idPageAlt"
    :funcs="funcs"
    :idFeature="idFeature"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
    :pageModeEdit="true"
  >
    <template #listContent="{ datasets }">
      <c-expansion-panels flat :pageModeView="false" :value="datasets">
        <template #header="{ dataset: produto }">
          <!-- produto -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-body-1">
                {{ produtoFormat(produto, false) }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template #content="{ dataset: produto }">
          <c-produto-page-gerenciar-edit
            v-if="!pageModeView"
            :dataset="datasets"
            :produto="produto"
          />
        </template>
      </c-expansion-panels>
    </template>
  </c-base-list>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CProdutoPageGerenciarEdit from "@/components/pages/produto/pages/CProdutoPageGerenciarEdit";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Produtos_Gerenciar",

  /// COMPONENTS
  components: {
    CProdutoPageGerenciarEdit,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getFeature = this.$store.getters["paginas/getFeature"];

    return {
      idFeature: getFeature("optionGerenciar").id,
      idPage: getPagina("produtos").id,
      idPageAlt: getPagina("produto").id,

      funcs: {
        changed: (context) => {
          const cleanKeys = ["custoReposicao"];
          const keys = [
            "prazoPagamento",
            "custoReposicao",
            "margemContribuicao",
            "margemUsuario",
            "prazoGarantia",
          ];

          return keys.some((key) =>
            context.datasets.some((dataset, i) =>
              cleanKeys.includes(key)
                ? parseFloat(dataset[key]) !=
                  parseFloat(context.datasetsTemp[i][key])
                : dataset[key] != context.datasetsTemp[i][key]
            )
          );
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        clickSave: async (context) => {
          const cleanKeys = ["custoReposicao"];

          const metadados = {
            idEvento: this.getTipo_item("eventoAlterado").id,
            idUsuario: context.options.click.getVar("idUsuario"),
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
            modo: context.options.click.getConst("config").modo.cadastro.id,
          };

          const datasetsSave = {
            metadados,
            details: context.options.click.datasets.filter((dataset, i) =>
              Object.keys(dataset).some((key) =>
                cleanKeys.includes(key)
                  ? dataset[key].clean(dataset.decimalValor) !=
                    context.options.click.datasetsTemp[i][key].clean(
                      dataset.decimalValor
                    )
                  : dataset[key] != context.options.click.datasetsTemp[i][key]
              )
            ),
          };

          if (datasetsSave.details.length) {
            datasetsSave.details = datasetsSave.details.map((dataset) => {
              return {
                id: dataset.id,
                idEstado: dataset.idEstado,
                prazoPagamento: dataset.prazoPagamento,
                custoReposicao: dataset.custoReposicao,
                margemContribuicao: dataset.margemContribuicao,
                margemUsuario: dataset.margemUsuario,
                prazoGarantia: dataset.prazoGarantia,
              };
            });

            const method = "put";
            let url = `${
              context.options.click.getConst("app").baseUrlBackend
            }/${context.options.click.page.nome}/all`;
            const res = await context.options.click.submit(
              method,
              url,
              datasetsSave
            );

            if (res) {
              context.options.click.clickSearch();
            }
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    pageModeView() {
      return false;
    },
  },
};
</script>