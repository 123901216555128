// COD: FDS 10/08/2022 mixinCarrinhos
// *VER: JER 19/08/2022 mixinCarrinhos
// /22/

import axios from "axios"
import { mapGetters, mapActions } from "vuex"

export default {
  /// NAME
  name: "mixinCarrinhos",

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    carrinhoAtual() {
      return this.getVar("carrinhos").find(carrinho => !!carrinho.idAtual)
    },

    logado() {
      return !!this.getVar("idPessoaParceiro");
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar"
    }),

    carrinhosAtualizar() {
      const search = "*";
      const idContexto = "*";
      const idFilter = this.getFeature("filterAbertos").id;
      const idOrder = this.getFeature("orderOrdenar").id;
      const idPessoaParceiro = this.getVar("parceiroCliente").idPessoa || this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;
      const offset = 0;
      const limit = 20;
      const params = JSON.stringify({
        itensRetornar: true
      });
      const url = `${this.getConst("app").baseUrlBackend}/carrinhos/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${limit}/${params}`

      axios
        .get(url, { params })
        .then(res => {
          const carrinhos = res.data
          this.setVar({ carrinhos })
          this.setVar({ "pendencias.carrinhoAtualItens": this.carrinhoAtual ? { priority: 2, value: this.carrinhoAtual.itens.length } : { priority: null, value: null } })
          if (this.carrinhoAtual) {
            this.setVar({ isEntregar: this.carrinhoAtual.isEntregar })
            this.setVar({ idPessoaEndereco: this.carrinhoAtual.idPessoaEndereco })
          }
        })
    },

    clickCarrinho(idCarrinho, isComprarAgora = false) {
      let url

      if (idCarrinho || this.getVar("carrinhos").length) {
        if (idCarrinho || this.carrinhoAtual) {
          url = `/carrinho/${idCarrinho || this.carrinhoAtual.id}?isComprarAgora=${isComprarAgora ? 1 : 0}`
        } else {
          url = "/carrinhos"
        }

        this.$router.push(encodeURI(url))
      } else {
        this.setSnackbar(
          true,
          "CARRINHO",
          "Oops... você ainda não tem um carrinho.",
          "warning"
        )
      }
    },

    async clickComprar(elemento) {
      if (!this.logado) {
        this.drawer = false
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        )
        return
      }

      if (
        !((this.getVar('isEntregar') && elemento.isEntregar) || (!this.getVar('isEntregar') && elemento.isRetirar))
      ) {
        this.setSnackbar(
          true,
          "COMPRA",
          "Entrega incompatível com este item. Deseja alterar a entrega?",
          "warning",
          "mdi-check",
          "openEnderecos"
        )
        return
      }

      this.setVar({ overlay: true });

      if (!this.carrinhoAtual) {
        const carrinhos = this.getVar("carrinhos")

        if (carrinhos.length) {
          if (carrinhos.length >= this.getConst("config").carrinhos.abertos.max) {
            this.setVar({ overlay: false });
            await this.setSnackbar(
              true,
              "COMPRA",
              `Limite máximo de ${this.getConst("config").carrinhos.abertos.max} carrinhos abertos excedido. Você deve gerenciar seus carrinhos antes de prosseguir.`,
              "error",
              null,
              "null",
              null
            )
            this.$router.push("/carrinhos")
            return
          } else {
            const carrinhosCompativeis = carrinhos.filter(
              carrinho =>
                carrinho.isEntregar == this.getVar('isEntregar') &&
                carrinho.idPessoaEndereco == this.getVar("idPessoaEndereco")
            )

            if (carrinhosCompativeis.length >= this.getConst("config").carrinhos.abertos.min) {
              this.setVar({ overlay: false });
              await this.setSnackbar(
                true,
                "COMPRA",
                `Existem ${this.getConst("config").carrinhos.abertos.min} ou mais carrinhos abertos. Deseja gerenciar seus carrinhos antes de prosseguir?`,
                "warning",
                "mdi-check",
                "itemIncluir",
                null,
                false
              )
              this.setVar({ overlay: true });
              const resSnackbar = !!this.snackbar.params
              this.setSnackbar()

              if (resSnackbar) {
                this.setVar({ overlay: false });
                this.$router.push("/carrinhos")
                return
              }
            }
          }
        }
      } else {
        const idProduto = elemento.idPublicado ? parseInt(elemento.idPublicado) : parseInt(elemento.id)

        if (this.carrinhoAtual.itens.includes(idProduto)) {
          this.setVar({ overlay: false });
          await this.setSnackbar(
            true,
            "COMPRA",
            "Item já está no carrinho. Deseja incluir novamente?",
            "warning",
            "mdi-check",
            "itemIncluir",
            null,
            false
          )
          this.setVar({ overlay: true });
          const resSnackbar = !!this.snackbar.params
          this.setSnackbar()

          if (!resSnackbar) {
            this.setVar({ overlay: false });
            return
          }
        }
      }

      const idUsuario = this.getVar("idUsuario")
      const idPessoaParceiro = this.getVar("parceiroCliente").idPessoa || this.getVar("idPessoaParceiro")
      const isEntregar = this.getVar("isEntregar") ? 1 : 0
      const idPessoaEndereco = this.getVar("idPessoaEndereco")
      const url = `${this.getConst("app").baseUrlBackend
        }/carrinhos/comprar/${idUsuario}/${idPessoaParceiro}/${isEntregar}/${idPessoaEndereco}`

      axios
        .post(url, elemento)
        .then(() => {
          this.setVar({ overlay: false });
          this.carrinhosAtualizar()
          this.setSnackbar(true, "COMPRA", "Item adicionado ao carrinho.", "success", "mdi-cart-outline", "carrinho")
        })
        .catch(err => {
          this.setSnackbar(true, "ERRO", err.response.data, "error")
        })
        .finally(() => {
          this.setVar({ overlay: false });
        })
    },

    getPrecoColor(element) {
      let color = "corDisabled"
      const idProduto = element.idPublicado ? parseInt(element.idPublicado) : parseInt(element.id)

      if (
        (this.getVar('isEntregar') && element.isEntregar) ||
        (!this.getVar('isEntregar') && element.isRetirar)
      ) {
        if (
          this.carrinhoAtual &&
          this.carrinhoAtual.itens.includes(idProduto)
        ) {
          color = "success"
        } else {
          color = "primary"
        }
      }

      return color
    },

    async setAtual(carrinho) {
      this.setVar({ overlay: true });
      this.setSnackbar();
      let ret;
      const idCliente = 0;
      const idUsuario = this.getVar("idUsuario");
      const atualizar = carrinho.idAtual == carrinho.id ? 0 : 1;

      const url = `${this.getConst("app").baseUrlBackend
        }/carrinhos/setAtual/${carrinho.id
        }/${idCliente}/${idUsuario}/${atualizar}`;

      await axios
        .put(url)
        .then(res => {
          if (res.data.id == carrinho.id) {
            this.setVar({ isEntregar: carrinho.isEntregar });
            this.setVar({ idPessoaEndereco: carrinho.idPessoaEndereco });
          }

          this.carrinhosAtualizar();

          ret = res
        })
        .catch(err => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });

      return ret
    },

    setAtualConfirmar(carrinho) {
      const atualizar = carrinho.idAtual == carrinho.id ? 0 : 1;
      this.setSnackbar(
        true,
        "CARRINHO ATUAL",
        atualizar
          ? "Tornar este o carrinho atual?"
          : "Deixar este de ser o carrinho atual?",
        "warning",
        "mdi-check",
        "setAtual",
        this.getConst("config").snackbar.timeout,
        carrinho
      );
    },
  }
}