<!-- COD: BJB 09/08/2022 CBaseDetailInsert -->
<!-- *VER: JER 10/08/2022 CBaseDetailInsert -->
<!-- /11/ -->

<template>
  <!-- /// PAGE-MODE-INSERT -->
  <v-container v-if="!getVar('overlay') && idItem" class="px-0">
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-slide-group
          center-active
          :show-arrows="!isMobile"
          style="width: fit-content"
          @change="changeTipo($event)"
        >
          <v-slide-item
            v-for="(tipo, i) in tipos"
            :key="i"
            :value="tipo.value"
            #default="{ active, toggle }"
          >
            <v-btn
              class="mx-2 primary--text"
              depressed
              :input-value="active"
              outlined
              rounded
              @click="toggle"
            >
              {{ tipo.text }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <ul class="mb-6">
          <li v-for="(tipo, i) in tipos" class="text-justify pa-2" :key="i">
            <span class="text-h6">
              {{ tipo.text }}
              :
            </span>
            <span class="text-boby-1 ml-2">
              {{ getTipo_item(tipo.value).options.ajuda }}
            </span>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "CBaseDetailInsert",

  /// MIXINS
  mixins: [mixinUsuarios],

  /// PROPS
  props: {
    dataset: { type: Object, required: true },
    idTipo: { type: Number, required: true },
    params: { type: Object },
  },

  /// DATA
  data() {
    return {
      idItem: null,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getVar: "variaveis/getVar",
    }),

    isMobile() {
      const toMatch = [/Android/i, /iPhone/i, /iPad/i, /iPod/i];

      return toMatch.some((toMatchItem) =>
        navigator.userAgent.match(toMatchItem)
      );
    },

    tipos() {
      return this.getTipo_itens(this.idTipo).filter(
        (item) =>
          (item.isAplicar || item.isAplicar == null) &&
          this.direitosTestar(
            this.getPagina("tipos_itens").id,
            item.value,
            null,
            null
          ) &&
          this.direitosTestar(
            this.getPagina("tipos_itens").id,
            item.value,
            null,
            this.getConst("config").pageMode.insert.id
          )
      );
    },
  },

  /// METHODS
  methods: {
    changeTipo(event) {
      if (this.params.escolheTipo) {
        this.params.escolheTipo = !this.params.escolheTipo;
      }

      if (this.dataset.main) {
        this.dataset.main.detail.idTipo = event;
        return null;
      }
      this.dataset.detail.idTipo = event;
    },
  },

  /// MOUNTED
  mounted() {
    if (this.tipos.length) {
      this.idItem = this.tipos[0].value;
    }
  },
};
</script>