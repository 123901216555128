<!-- COD: BJB 19/08/2022 App -->
<!-- *VER: JER 08/09/2022 App -->
<!-- /33/ -->

<!-- BJB/EMR/FDS/JER/LGQ/MAF/UMR -->
<template>
  <div class="app" media="print">
    <!-- /// APP -->
    <v-app class="grey lighten-4" :style="cssProps">
      <!-- //? link aqui mesmo?  -->
      <link
        href="https://cdn.jsdelivr.net/npm/@mdi/font@7.2.96/css/materialdesignicons.min.css"
        rel="stylesheet"
      />
      <v-main class="mb-4 px-1">
        <v-container
          v-if="getVar('keepAlive') && validation"
          class="px-4"
          fluid
        >
          <v-row justify="center" no-gutters>
            <v-col v-bind="layout">
              <keep-alive :exclude="excludeComponents">
                <router-view />
              </keep-alive>
            </v-col>
          </v-row>
        </v-container>
        <router-view v-else-if="validation" />

        <!-- /// SNACKBAR -->
        <c-snackbar @click="clickSnackbar($event)" :snackbar="snackbar" />

        <!-- /// AUTHCONFIRM -->
        <c-home-dialog-auth-confirm
          :show="showAuthConfirm"
          @close="authConfirm($event)"
        />
      </v-main>
    </v-app>

    <!-- /// OVERLAY -->
    <v-overlay :value="getVar('overlay')" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import io from "socket.io-client";
import CHomeDialogAuthConfirm from "@/components/pages/home/auth/CHomeDialogAuthConfirm.vue";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinPendencias from "@/mixins/mixinPendencias";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUsuarios from "@/mixins/mixinUsuarios";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "App",

  /// COMPONENTS
  components: { CHomeDialogAuthConfirm },

  /// MIXINS
  mixins: [
    mixinCarrinhos,
    mixinPendencias,
    mixinSnackbar,
    mixinUsuarios,
    mixinUtil,
  ],

  /// DATA
  data() {
    return {
      excludeComponentsTemp: [
        "Anexo",
        "Anexos",
        "AssistenciasTecnicas_PedidoSaida",
        "AssistenciaTecnica",
        "Avaliacao",
        "Avaliacoes",
        "Banco",
        "Carrinho",
        "Cartao",
        "Imposto",
        "Legislacao",
        "Legislacao_Itens",
        "Colaborador_Apontamentos",
        "Colaborador",
        "Comentarios",
        "ContaContabil",
        "ContaPagRec_BaixaCruzada",
        "ContaPagRec",
        "Convite",
        "Cupom",
        "Dicionario",
        "Extrato",
        "Extrato_Conciliacoes",
        "Feedback",
        "Feriado",
        "Feriados_FeriadosDoAno",
        "FolhaPagamento_Colaboradores",
        "FolhaPagamento_Ocorrencias",
        "FolhaPagamento_Verbas",
        "FolhaPagamento",
        "FornecedorCliente",
        "HistoricoPadrao",
        "LancamentoContabil",
        "Localidade",
        "Logs",
        "NotaFiscal",
        "Notificacao",
        "Parceiro",
        "Pedido_NumerosSerie",
        "Pedido",
        "PerguntaFrequente",
        "PerguntasFrequentes_Faqs",
        "Pessoa",
        "PontoEletronico_Apontamentos",
        "PontoEletronico",
        "Print",
        "Produto",
        "Produtos_Gerenciar",
        "Produtos_Inventario",
        "Publicacao",
        "Relatorios",
        "Transacao",
        "ValoresPagRec_DepositoBancario",
        "ValorPagRec_ContasPagRec",
        "ValorPagRec",
        "Verba",
      ],
      firebase: null,
      layout: {},
      layoutDefault: {
        cols: 12,
        sm: 10,
        md: 8,
        lg: 6,
        xl: 4,
      },
      messaging: null,
      reason: null,
      reloadComponents: false,
      showAuthConfirm: false,
      validatingToken: true,
      validation: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getLocalidade_idioma: "localidades/getLocalidade_idioma",
      getPagina_documentos: "paginas/getPagina_documentos",
      getPagina: "paginas/getPagina",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getUsuario: "variaveis/getUsuario",
      getVar: "variaveis/getVar",
    }),

    cssProps() {
      var themeColors = {};

      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
      });

      return themeColors;
    },

    excludeComponents() {
      if (this.reloadComponents) {
        return [
          ...this.excludeComponentsTemp,
          ...this.getVar("reloadComponents"),
        ];
      } else {
        return this.excludeComponentsTemp;
      }
    },

    idPessoaParceiro() {
      return this.getVar("idPessoaParceiro");
    },

    idUsuario() {
      return this.getVar("idUsuario");
    },

    logado() {
      return !!this.getVar("idPessoaParceiro");
    },

    termosServicoVersionAtual() {
      let ret = null;
      const idPage = this.getPagina("termosServico").id;

      if (idPage) {
        const paginas_documentos = this.getPagina_documentos(idPage);
        if (paginas_documentos?.length) {
          ret = paginas_documentos[0].options.versao;
        }
      }

      return ret;
    },
  },

  /// WATCH
  watch: {
    async idPessoaParceiro() {
      this.changeComponents();

      if (this.getVar("socket") && this.idUsuario) {
        const obj = {
          idUsuario: this.getVar("idUsuario"),
          idPessoaParceiro: this.getVar("idPessoaParceiro"),
        };

        this.getVar("socket").emit("register", obj);
      }

      if (this.idPessoaParceiro) {
        this.$vuetify.theme.themes.light.primary =
          this.getUsuario(this.idPessoaParceiro)?.parceiro?.corPrimaria ||
          this.getConst("app").corPrimaria;
      }
    },

    async logado(valor, valorAnterior) {
      if (!valorAnterior && valor) {
        this.inicializarFirebase();
        await this.pedirPermissaoParaReceberNotificacoes();

        // this.messaging.onMessage((payload) => {
        //   this.setSnackbar(
        //     true,
        //     payload.notification.title,
        //     payload.notification.body,
        //     "notification",
        //     payload.notification.click_action ? "mdi-check" : null,
        //     payload.notification.click_action,
        //     this.getConst("config").snackbar.timeout * 2,
        //     payload.data.notificao ? payload.data.notificao : null
        //   );
        // });
      }
    },

    async termosServicoVersionAtual(valor, valorAnterior) {
      if (valor && !valorAnterior) {
        await this.validateToken();
        this.validation = true;
      }
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setBancos: "bancos/setBancos",
      setCartoes_numeros: "cartoes/setCartoes_numeros",
      setCartoes_pagamentos: "cartoes/setCartoes_pagamentos",
      setCartoes: "cartoes/setCartoes",
      setImpostos: "impostos/setImpostos",
      setCategorias: "categorias/setCategorias",
      setContasContabeis: "contasContabeis/setContasContabeis",
      setDicionarios: "dicionarios/setDicionarios",
      setEstados: "estados/setEstados",
      setFeatures: "paginas/setFeatures",
      setFeriados: "feriados/setFeriados",
      setHistoricosPadrao_contasContabeis:
        "historicosPadrao/setHistoricosPadrao_contasContabeis",
      setHistoricosPadrao: "historicosPadrao/setHistoricosPadrao",
      setLocalidades_codigosAreas: "localidades/setLocalidades_codigosAreas",
      setLocalidades_codigosPostais:
        "localidades/setLocalidades_codigosPostais",
      setLocalidades_configuracoesRegionais:
        "localidades/setLocalidades_configuracoesRegionais",
      setLocalidades_idiomas: "localidades/setLocalidades_idiomas",
      setLocalidades_moedas: "localidades/setLocalidades_moedas",
      setLocalidades: "localidades/setLocalidades",
      setMaquinasEstados_transicoes: "estados/setMaquinasEstados_transicoes",
      setMaquinasEstados: "estados/setMaquinasEstados",
      setOperacoes: "operacoes/setOperacoes",
      setPaginas_direitos: "paginas/setPaginas_direitos",
      setPaginas_documentos: "paginas/setPaginas_documentos",
      setPaginas_features: "paginas/setPaginas_features",
      setPaginas_configuracoes: "paginas/setPaginas_configuracoes",
      setPaginas: "paginas/setPaginas",
      setTabelas_colunas: "tabelas/setTabelas_colunas",
      setTabelas: "tabelas/setTabelas",
      setTipos_itens: "tipos/setTipos_itens",
      setTipos: "tipos/setTipos",
      setVar: "variaveis/setVar",
      setVerbas: "verbas/setVerbas",
    }),

    async authConfirm(termosServico = false) {
      this.showAuthConfirm = false;

      if (this.getVar("usuarios").length) {
        await this.fazerLogin();
        this.$emit("close");
      } else if (termosServico) {
        this.validatingToken = false;
        this.setVar({ reload: true });
        this.$router.push("/termos-servico");
      } else {
        this.validatingToken = false;
        this.setVar({ usuariosTemp: [] });
      }
    },

    changeComponents() {
      this.reloadComponents = true;

      setTimeout(() => {
        this.setVar({ reloadComponents: [] });
        this.reloadComponents = false;
      }, 100);
    },

    clickSnackbar(action) {
      this.$router.push(action);
      this.setSnackbar();
    },

    async fazerLogin(userData) {
      this.setVar({ usuariosTemp: [], usuarios: [] });

      if (!userData) {
        userData = JSON.parse(
          await localStorage.getItem(this.getConst("app").userKey)
        );
      }

      let coordenada;

      try {
        coordenada = await this.getPosition();
      } catch (err) {
        coordenada = {
          latitude: 0,
          longitude: 0,
        };

        this.setSnackbar(
          true,
          "LOCALIZAÇÃO",
          err,
          "warning",
          "mdi-check",
          "ajuda"
        );
      }

      if (coordenada) {
        userData.metadados.coordenada = coordenada;
      }

      userData.metadados.ip = {
        interno: this.getVar("ipInterno"),
      };

      userData.metadados.userDevice = await this.getUserDevice();

      try {
        const res = await axios.post(
          `${this.getConst("app").baseUrlBackend}/validateToken`,
          userData
        );

        if (res.data.err) {
          this.validatingToken = false;
          return;
        }

        if (res.data) {
          res.data.metadados.termosServicoVersionUltimoLoginOk =
            this.termosServicoVersionAtual;

          await localStorage.setItem(
            this.getConst("app").userKey,
            JSON.stringify(res.data)
          );

          userData = JSON.parse(
            await localStorage.getItem(this.getConst("app").userKey)
          );

          const localStorageVar = JSON.parse(
            await localStorage.getItem(this.getConst("app").varKey)
          );

          if (localStorageVar) {
            this.setVar(localStorageVar);
          }

          this.setVar({ usuarios: [...userData.main.usuarios] });
          this.setVar({ idUsuario: this.getVar("usuarios")[0].pessoa.id });

          if (!localStorageVar) {
            this.setVar({
              idPessoaParceiro: this.getVar("usuarios")[0].pessoa.id,
            });

            if (
              this.getVar("usuarios")[0].enderecos &&
              this.getVar("usuarios")[0].enderecos.length
            ) {
              this.setVar({
                idPessoaEndereco: this.getVar("usuarios").filter(
                  (usuario) =>
                    usuario.pessoa.id == this.getVar("idPessoaParceiro")
                )[0].enderecos[0].id,
              });
            }
          }

          await this.carrinhosAtualizar();
        } else {
          localStorage.removeItem(this.getConst("app").userKey);
        }

        this.validatingToken = false;
      } catch (err) {
        this.validatingToken = false;
        return;
      }
    },

    inicializarFirebase() {
      this.firebase = initializeApp({
        apiKey: "AIzaSyBsnLwtPjZ7YtyjicG5q2K6qWVFMD-xsiI",
        authDomain: "pushnotification-c003c.firebaseapp.com",
        databaseURL: "https://pushnotification-c003c.firebaseio.com",
        projectId: "pushnotification-c003c",
        storageBucket: "pushnotification-c003c.appspot.com",
        messagingSenderId: "817322039489", // troque pelo seu sender id
        appId: "1:817322039489:web:a955503a3720f774c55c40",
      });

      // navigator.serviceWorker
      //   .register(`${process.env.BASE_URL}firebase-messaging-sw.js`)
      //   .then((registration) => {
      //     firebase.messaging().useServiceWorker(registration);
      //   });

      this.messaging = getMessaging();
      // this.messaging.usePublicVapidKey(
      //   "BBg6aS_19ZgTNzMvM-x61Q5SSj0JUJ-Gw1oyRQrDve5ME7oFx41N0BFtfJXp9UfFzla1meZBO2ARFdoTY5slEBc"
      // );
    },

    async pedirPermissaoParaReceberNotificacoes() {
      const userDevice = await this.getUserDevice();
      const dispositivo = userDevice.os.name;
      const idUsuario = this.getVar("idUsuario");

      await getToken(this.messaging, {
        vapidKey:
          "BBg6aS_19ZgTNzMvM-x61Q5SSj0JUJ-Gw1oyRQrDve5ME7oFx41N0BFtfJXp9UfFzla1meZBO2ARFdoTY5slEBc",
      })
        .then((currentToken) => {
          if (currentToken) {
            axios.post(
              `${
                this.getConst("app").baseUrlBackend
              }/pessoas/saveDispositivo/${idUsuario}/${dispositivo}/${currentToken}`
            );
          } else {
            // Show permission request.
            console.log(
              "No Instance ID token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    },

    setDicionarios_traducoes(dicionarios_traducoes) {
      const locales = dicionarios_traducoes.reduce((acc, item) => {
        acc.includes(item.idLocale) || acc.push(item.idLocale);
        return acc;
      }, []);

      // const dicionarios = {};

      locales.forEach(async (idLocalePara) => {
        const dicBase = [];
        const localePara = this.getLocalidade_idioma(idLocalePara);

        const idLocaleDefault = this.locales.find(
          (locale) => locale.text == "pt-BR"
        ).value;

        dicionarios_traducoes
          //? Verificar com o Enzo o motivo desse filter. Com ele o App parou de traduzir
          //.filter((item) => item.idLocale == localePara.id)
          .filter((item) => item.idLocale == idLocaleDefault)
          .forEach((item) => {
            const itemDe = item;
            const itemPara = dicionarios_traducoes.find(
              (item) =>
                item.idPai == itemDe.idPai && item.idLocale == idLocalePara
            );

            const termo = {
              idLocalePara,
              termoDe: itemDe.texto,
              termoPara: itemPara ? itemPara.texto : null,
              // termoPara: itemPara ? itemPara.texto : itemDe.texto,
            };

            dicBase.push(termo);
          });

        const dicionarioIdioma = {};

        dicBase.forEach((item) => {
          if (item.termoPara) {
            const termosDe = [
              ...new Set([
                item.termoDe,
                item.termoDe.toUpperCase(),
                item.termoDe.toLowerCase(),
                // item.termoDe.firstLetterToUpperCase(),
                // item.termoDe
                //   .split(" ")
                //   .map((termo) =>
                //     termo ? termo.firstLetterToUpperCase() : termo
                //   )
                //   .join(" "),
              ]),
            ];

            const termosPara = [
              ...new Set([
                item.termoPara,
                item.termoPara.toUpperCase(),
                item.termoPara.toLowerCase(),
                // item.termoPara.firstLetterToUpperCase(),
                // item.termoPara
                //   .split(" ")
                //   .map((termo) =>
                //     termo ? termo.firstLetterToUpperCase() : termo
                //   )
                //   .join(" "),
              ]),
            ];

            termosDe.forEach((termoDe, i) => {
              dicionarioIdioma[termoDe] = termosPara[i];
            });
          }
        });

        const nomeIdioma = this.getTipo_item(localePara.idIdioma)?.codigo;
        if (nomeIdioma) {
          const dicionarioVuetify = require(`vuetify/lib/locale/${nomeIdioma}.js`);

          const dicionario = {
            ...dicionarioVuetify.default,
            ...dicionarioIdioma,
          };

          this.$vuetify.lang.locales[nomeIdioma] = dicionario;
        }
      });
    },

    async validateToken() {
      this.validatingToken = true;

      let userData = JSON.parse(
        await localStorage.getItem(this.getConst("app").userKey)
      );

      if (!userData || userData.err) {
        this.validatingToken = false;
        return;
      }

      if (
        userData.metadados.termosServicoVersionUltimoLoginOk !=
        this.termosServicoVersionAtual
      ) {
        this.setVar({ usuariosTemp: userData.main.usuarios });
        this.showAuthConfirm = true;
      } else {
        await this.fazerLogin(userData);
      }

      this.loadOptionsNavBar();
    },
  },

  /// MOUNTED
  async mounted() {
    //e PWA com AXIOS - confirmar se funcionam
    // fetch(`${url}/estados`)
    //   .then(res => res.json())
    //   .then(res => this.setEstados(res));

    this.setVar({ overlay: true });
    const url = this.getConst("app").baseUrlBackend;

    axios
      .get(`${url}/estados`)
      .then((res) => this.setEstados(res.data))
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${url}/maquinasEstados`)
      .then((res) => this.setMaquinasEstados(res.data));

    axios
      .get(`${url}/maquinasEstados_transicoes`)
      .then((res) => this.setMaquinasEstados_transicoes(res.data));

    axios.get(`${url}/tipos`).then((res) => this.setTipos(res.data));

    axios
      .get(`${url}/tipos_itens`)
      .then((res) => this.setTipos_itens(res.data));

    axios
      .get(`${url}/localidades`)
      .then((res) => this.setLocalidades(res.data));

    axios
      .get(`${url}/localidades_configuracoesRegionais`)
      .then((res) => this.setLocalidades_configuracoesRegionais(res.data));

    axios
      .get(`${url}/localidades_codigosPostais`)
      .then((res) => this.setLocalidades_codigosPostais(res.data));

    axios
      .get(`${url}/localidades_codigosAreas`)
      .then((res) => this.setLocalidades_codigosAreas(res.data));

    axios
      .get(`${url}/localidades_idiomas`)
      .then((res) => this.setLocalidades_idiomas(res.data));

    axios
      .get(`${url}/localidades_moedas`)
      .then((res) => this.setLocalidades_moedas(res.data));

    axios.get(`${url}/feriados`).then((res) => this.setFeriados(res.data));

    axios.get(`${url}/bancos`).then((res) => this.setBancos(res.data));

    axios.get(`${url}/cartoes`).then((res) => this.setCartoes(res.data));

    axios.get(`${url}/impostos`).then((res) => this.setImpostos(res.data));

    axios.get(`${url}/tabelas`).then((res) => this.setTabelas(res.data));

    axios
      .get(`${url}/tabelas_colunas`)
      .then((res) => this.setTabelas_colunas(res.data));

    axios.get(`${url}/features`).then((res) => this.setFeatures(res.data));

    axios.get(`${url}/paginas`).then((res) => this.setPaginas(res.data));

    axios
      .get(`${url}/paginas_direitos`)
      .then((res) => this.setPaginas_direitos(res.data));

    axios
      .get(`${url}/paginas_features`)
      .then((res) => this.setPaginas_features(res.data));

    axios
      .get(`${url}/paginas_configuracoes`)
      .then((res) => this.setPaginas_configuracoes(res.data));

    axios
      .get(`${url}/paginas_documentos`)
      .then((res) => this.setPaginas_documentos(res.data));

    axios
      .get(`${url}/dicionarios`)
      .then((res) => this.setDicionarios(res.data));

    axios.get(`${url}/categorias`).then((res) => this.setCategorias(res.data));

    axios.get(`${url}/operacoes`).then((res) => this.setOperacoes(res.data));

    axios
      .get(`${url}/contasContabeis`)
      .then((res) => this.setContasContabeis(res.data));

    axios
      .get(`${url}/historicosPadrao`)
      .then((res) => this.setHistoricosPadrao(res.data));

    axios
      .get(`${url}/historicosPadraoContasContabeis`)
      .then((res) => this.setHistoricosPadrao_contasContabeis(res.data));

    axios.get(`${url}/verbas`).then((res) => this.setVerbas(res.data));

    axios
      .get(`${url}/dicionarios_traducoes`)
      .then((res) => this.setDicionarios_traducoes(res.data));

    const socket = await io(url);

    socket.on("connect", () => {
      if (this.getVar("socket") && this.reason == "transport close") {
        this.setVar({ reload: true });
      }
      this.setVar({ socket });
      this.reason = null;
    });

    socket.on("reload", () => {
      this.setVar({ reload: true });
    });

    socket.on("disconnect", (reason) => {
      this.setVar({ disconnections: this.getVar("disconnections") + 1 });
      this.reason = reason;
    });

    socket.on("atualizarUsuario", () => {
      this.usuariosAtualizar();
    });

    socket.on("notificacao", (notificacao) => {
      let action = null;

      //? ajustar este trecho para funcionar de forma generica para qualquer tabela.
      if (notificacao.notificacao.idTabela == this.getTabela("pedidos").id) {
        action = `/pedido/${notificacao.notificacao.idRegistro}`;
      }

      let interval = setInterval(() => {
        if (!this.snackbar.show) {
          clearInterval(interval);
          interval = null;

          this.setSnackbar(
            true,
            notificacao.notificacao.titulo,
            notificacao.notificacao.descricao,
            "notification",
            action ? "mdi-check" : null,
            action,
            this.getConst("config").snackbar.timeout * 2,
            action ? notificacao : null
          );

          this.pendenciasAtualizar();
        }
      }, 100);
    });

    this.setVar({ overlay: false });
  },

  /// CREATED
  created() {
    this.layout = this.layoutDefault;
    this.$root.$on("resize", (layout) => {
      this.layout = layout || this.layoutDefault;
    });
  },
};
</script>

<style>
a {
  text-decoration: none;
}

Navbar {
  width: 100%;
}

v-main {
  width: 100%;
  height: 100%;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  z-index: 1;
}

/* ///? fff - Confirmar as cores */
.v-chip {
  background: grey !important;
}

.v-chip.aberto,
.aberto {
  background: #43a047 !important;
}

.v-chip.aceito,
.aceito {
  background: #ffb300 !important;
}

.v-chip.alterado {
  background: #1565c0 !important;
}

.v-chip.análise,
.análise {
  background: #ffb300 !important;
}

.v-chip.aprovação,
.aprovação {
  background: #ffb300 !important;
}

.v-chip.ativo,
.ativo {
  background: #43a047 !important;
}

.v-chip.cadastro,
.cadastro {
  background: #757575 !important;
}

.v-chip.cancelado,
.cancelado {
  background: #757575 !important;
}

.v-chip.cobranca,
.cobranca {
  background: #1565c0 !important;
}

.v-chip.concluido,
.concluido {
  background: #8e24aa !important;
}

.v-chip.confirmado,
.confirmado {
  background: #43a047 !important;
}

.v-chip.confirmar,
.confirmar {
  background: #757575 !important;
}

.v-chip.deletado {
  background: black !important;
}

.v-chip.desativo,
.desativo {
  background: #d81b60 !important;
}

.v-chip.emitido,
.emitido {
  background: #43a047 !important;
}

.v-chip.entregue,
.entregue {
  background: #43a047 !important;
}

.v-chip.enviado,
.enviado {
  background: #1565c0 !important;
}

.v-chip.expedição,
.expedição {
  background: #1565c0 !important;
}

.v-chip.faturamento,
.faturamento {
  background: #1565c0 !important;
}

.v-chip.fechado,
.fechado {
  background: #8e24aa !important;
}

.v-chip.finalizado,
.finalizado {
  background: #8e24aa !important;
}

.v-chip.incluido {
  background: #43a047 !important;
}

.v-chip.inspeção,
.inspeção {
  background: #1565c0 !important;
}

.v-chip.lançamento,
.lançamento {
  background: #ffb300 !important;
}

.v-chip.outlet,
.outlet {
  background: #8e24aa !important;
}

.v-chip.pendente,
.pendente {
  background: #ffb300 !important;
}

.v-chip.preparo,
.preparo {
  background: #1565c0 !important;
}

.v-chip.pré-venda,
.pré-venda {
  background: #ffb300 !important;
}

.v-chip.processado,
.processado {
  background: #1565c0 !important;
}

.v-chip.promoção,
.promoção {
  background: #dd369d !important;
}

.v-chip.recebido,
.recebido {
  background: #ffb300 !important;
}

.v-chip.recebimento,
.recebimento {
  background: #1565c0 !important;
}

.v-chip.recorrente,
.recorrente {
  background: #ffb300 !important;
}

.v-chip.recuperado {
  background: #43a047 !important;
}

.v-chip.recusado,
.recusado {
  background: #757575 !important;
}

.v-chip.reserva,
.reserva {
  background: #ffb300 !important;
}

.v-chip.black1 {
  background: #121212 !important;
}

.v-chip.blue1 {
  background: #0d47a1 !important;
}

.v-chip.green1 {
  background: #2e7d32 !important;
}

.v-chip.yellow1 {
  background: #ffd600 !important;
}

.drawingBuffer {
  position: inherit !important;
}
</style>