<!-- COD: BJB 21/07/2022 CCheckbox -->
<!-- *VER: JER 10/08/2022 CCheckbox -->
<!-- /11/ -->

<template>
  <c-help
    estilo="width: fit-content"
    :help="help"
    :offsetX="offsetX"
    :offsetY="offsetY"
  >
    <v-checkbox
      v-bind="$attrs"
      v-model="model"
      :disabled="disabled"
      :indeterminate="indeterminate && model == null"
      :off-icon="icon"
      :ripple="false"
      style="width: fit-content"
      @change="changeCheckbox($event)"
      @click="$emit('click', $event)"
    />
  </c-help>
</template>

<script>
export default {
  /// NAME
  name: "CCheckbox",

  /// PROPS
  props: {
    value: { type: Boolean },
    help: { type: Object },
    indeterminate: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },

    icon() {
      return this.indeterminate && this.model == null
        ? "$checkboxIndeterminate"
        : "$checkboxOff";
    },

    offsetX() {
      return "8";
    },

    offsetY() {
      return "35";
    },
  },

  // METHODS
  methods: {
    changeCheckbox(event) {
      if (this.indeterminate) {
        this.model = this.model ? false : this.model === false ? null : true;
      } else {
        this.model = !this.model;
      }
      this.$emit("change", event);
    },
  },
};
</script>