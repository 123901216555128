<!-- COD: BJB 21/07/2022 CDatetimePicker -->
<!-- *VER: JER 10/08/2022 CDatetimePicker -->
<!-- /32/ -->

<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="290px"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on }">
      <c-text-field
        v-on="on"
        :class="`${classe} ${time ? 'text-body-2' : 'text-body-1'}`"
        :clearable="clearable"
        :disabled="disabled"
        :help="help"
        :label="cLabel"
        :max="textFieldMax"
        offset-y
        :prepend-inner-icon="prependIcon"
        readonly
        :cRules="
          commonRules.common(
            'date',
            required,
            null,
            1,
            null,
            'date',
            msg,
            null,
            null,
            panelCallback
          )
        "
        :solo="solo"
        :style="estilo"
        :value="textFieldValue"
        @focus="clickTextField()"
        @clickClear="clickClean()"
      />
    </template>
    <v-date-picker
      v-if="date"
      v-model="data"
      v-show="pickerDataHora"
      :allowed-dates="allowedDates"
      color="primary"
      :eventColor="eventColor"
      :events="events"
      :locale="$vuetify.lang.current"
      :max="maxDate"
      :min="minDate"
      :picker-date="pickerDate"
      :type="tipo"
      @change="changeDate()"
    ></v-date-picker>
    <v-time-picker
      v-if="time"
      v-model="hora"
      v-show="!pickerDataHora"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
      color="primary"
      format="24hr"
      locale="pt-BR"
      :max="maxTime"
      :min="minTime"
      :use-seconds="useSeconds"
      @click:hour="clickHour($event)"
      @click:minute="useSeconds ? null : changeTime()"
      @click:second="changeTime()"
    >
      <c-btn
        v-if="date"
        class="primary--text mt-n12"
        icon
        x-large
        @click="pickerDataHora = true"
      >
        <v-icon>mdi-calendar-blank</v-icon>
      </c-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";
import { lastDayOfMonth, parseISO } from "date-fns";

export default {
  /// NAME
  name: "CDatetimePicker",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    allowedDatetimes: { type: Array, required: false },
    classe: { type: String, default: "text-body-1" },
    clearable: { type: Boolean, default: false },
    date: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    estilo: { type: String, required: false },
    eventColor: { type: [Function, String], default: "primary" },
    events: { type: Array, required: false },
    hasTimeZone: { type: Boolean, default: true },
    help: { type: Object },
    icon: { type: Boolean, default: false },
    isWeek: { type: Boolean, default: false },
    label: { type: String, required: false },
    maxValue: { type: String, required: false },
    minValue: { type: String, required: false },
    msg: { type: String },
    panel: { type: Object },
    required: { type: Boolean, default: false },
    rules: { type: Array },
    solo: { type: Boolean, default: false },
    stepHours: { type: Number, required: false },
    stepMinutes: { type: Number, required: false },
    time: { type: Boolean, default: false },
    tipo: { type: String, default: "date" },
    useSeconds: { type: Boolean, default: false },
    value: { type: String, required: false },
  },

  /// DATA
  data() {
    return {
      clearControl: false,
      data: "",
      dataTemp: "",
      hora: "",
      horaTemp: "",
      inputTimeout: false,
      menu: null,
      pickerDataHora: this.date,
      pickerDateMenu: true,
    };
  },

  /// COMPUTED
  computed: {
    cLabel() {
      const label = this.label ? this.translate(this.label) : "";

      return `${label}${label && this.required ? " *" : ""}`;
    },

    isMonth() {
      return this.tipo == "month";
    },

    maxDate() {
      return this.maxValue
        ? this.maxValue.toString().substring(0, 10).trim()
        : null;
    },

    minDate() {
      return this.minValue ? this.minValue.substring(0, 10).trim() : null;
    },

    maxTime() {
      if (!this.date && this.time) {
        return this.maxValue;
      } else {
        return this.maxValue && this.data == this.maxDate
          ? this.maxValue.toString().substring(11, 16).trim()
          : null;
      }
    },

    minTime() {
      if (!this.date && this.time) {
        return this.minValue;
      } else {
        return this.minValue && this.data == this.minDate
          ? this.minValue.substring(11, 16).trim()
          : null;
      }
    },

    panelCallback() {
      return this.panel ? this.panel.callbackRules : null;
    },

    pickerDate() {
      return this.minDate && !this.value && this.pickerDateMenu
        ? this.minDate
        : this.maxDate && !this.value && this.pickerDateMenu
        ? this.maxDate
        : undefined;
    },

    prependIcon() {
      if (this.icon) {
        if (this.time) {
          return this.date ? "mdi-calendar-clock" : "mdi-clock-outline";
        } else {
          return "mdi-calendar";
        }
      } else {
        return "";
      }
    },

    textFieldMax() {
      return (this.textFieldValue || "").length || 10;
    },

    textFieldValue() {
      return this.date
        ? this.dataFormata(
            `${this.data} ${this.time ? this.hora : ""}`,
            `${this.isWeek ? "iii " : ""}${!this.isMonth ? "dd/" : ""}MM/${
              !this.isMonth ? "yy" : ""
            }yy${this.time && this.hora ? " HH:mm" : ""}`,
            true
          )
        : this.hora;
    },
  },

  /// WATCH
  watch: {
    menu(valor, valorAnterior) {
      if (valorAnterior && valor === false) {
        this.data = this.dataTemp;
        this.hora = this.horaTemp;
      }

      setTimeout(() => {
        this.pickerDateMenu = !this.menu;
      }, 10);
    },

    value: {
      handler() {
        if (!this.inputTimeout || this.value === null) {
          if (this.value) {
            const dataHora = this.date
              ? this.dataFormata(
                  this.value,
                  `yyyy-MM-dd${this.time ? " HH:mm" : ""}`,
                  this.hasTimeZone
                )
              : this.value;

            this.data = dataHora.substring(0, 10).trim();
            this.hora = this.date
              ? dataHora.substring(11, 16).trim()
              : dataHora.substring(0, this.useSeconds ? 8 : 5).trim();

            this.inputTimeout = true;

            this.$emit(
              "input",
              this.date
                ? this.time
                  ? `${this.data}T${this.hora}:00.000Z`
                  : this.data
                : this.hora
            );
          } else {
            this.data = null;
            this.hora = null;
          }

          this.setTemps();
        }

        setTimeout(() => (this.inputTimeout = false), 100);
      },
      immediate: true,
    },
  },

  /// METHODS
  methods: {
    allowedDates(date) {
      if (this.allowedDatetimes) {
        const allowedDays = [
          ...new Set(this.allowedDatetimes.map((dia) => dia.diaSemana)),
        ];

        return allowedDays.includes(this.getDay(this.parseISO(date)));
      } else {
        return true;
      }
    },

    allowedHours(hours) {
      if (this.allowedDatetimes && this.data) {
        const allowedDays = this.allowedDatetimes.filter(
          (dia) => this.getDay(this.parseISO(this.data)) == dia.diaSemana
        );

        return allowedDays.reduce((acc, dia) => {
          const horarioInicio = dia.horarioInicio.split(":");
          const horaInicio = parseInt(horarioInicio[0]);
          const horarioFim = dia.horarioFim.split(":");
          const horaFim = parseInt(horarioFim[0]);

          return acc + (horaInicio <= hours && hours <= horaFim);
        }, false);
      } else if (this.stepHours) {
        const allowedHour = hours % this.stepHours == 0;
        return allowedHour;
      } else {
        return true;
      }
    },

    allowedMinutes(minutes) {
      if (this.hora) {
        if (this.allowedDatetimes && this.data) {
          const hora = parseInt(this.hora);

          const allowedDays = this.allowedDatetimes.filter(
            (dia) => this.getDay(this.parseISO(this.data)) == dia.diaSemana
          );

          return allowedDays.reduce((acc, dia) => {
            const horarioInicio = dia.horarioInicio.split(":");
            const horaInicio = parseInt(horarioInicio[0]);
            const minutoInicio = parseInt(horarioInicio[1]);
            const horarioFim = dia.horarioFim.split(":");
            const horaFim = parseInt(horarioFim[0]);
            const minutoFim = parseInt(horarioFim[1]);

            return (
              acc +
              ((horaInicio == hora && minutoInicio <= minutes) ||
                (horaFim == hora && minutes <= minutoFim) ||
                (horaInicio < hora && horaFim > hora))
            );
          }, false);
        } else if (this.stepMinutes) {
          const allowedMinute = minutes % this.stepMinutes == 0;
          return allowedMinute;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    changeDate() {
      if (!this.time) {
        this.menu = null;
        this.inputTimeout = true;

        this.$emit(
          "input",
          !this.isMonth
            ? this.data
            : this.dataFormata(
                lastDayOfMonth(parseISO(this.data)).toISOString(),
                "yyyy-MM-dd"
              )
        );

        this.setTemps();
      } else {
        this.pickerDataHora = false;
      }

      this.$emit("change");
    },

    changeTime() {
      this.inputTimeout = true;
      if (this.date && this.hora) {
        this.pickerDataHora = true;
        this.$emit("input", `${this.data}T${this.hora}:00.000Z`);
      } else if (!this.date) {
        this.$emit("input", this.hora);
      } else {
        this.$emit("input", this.data);
      }
      this.setTemps();
      this.menu = null;

      this.$emit("change");
    },

    clickClean() {
      this.data = null;
      this.hora = null;
      this.clearControl = true;
      this.changeDate();
      this.changeTime();
    },

    clickHour(event) {
      this.hora = `${event < 10 ? "0" : ""}${event}:00`;
    },

    clickTextField() {
      if (this.clearControl) {
        this.clearControl = false;
        return;
      }

      setTimeout(() => {
        if (!this.clickAbort) {
          this.menu = true;
        }
      }, 100);
    },

    setTemps() {
      this.dataTemp = this.data;
      this.horaTemp = this.hora;
    },
  },
};
</script>