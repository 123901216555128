<!-- COD: BJB 01/07/2022 Pessoa -->
<!-- *VER: JER 08/07/2022 Pessoa -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
      @goto="goto($event)"
    >
      <!-- // IMAGENS -->
      <template #images="{ dataset, pageModeView }">
        <c-base-detail-images
          :avatar="
            dataset.main.detail.idTipo == getTipo_item('pessoaFisica').id
          "
          :dataset="dataset"
          :pageModeView="pageModeView"
        />
      </template>

      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <!-- //? getConst verificar o page.nome para ser igual ao da tabela tip -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-pessoa-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-pessoa-edit v-else :context="context" :dataset="dataset" />
        </div>

        <c-base-dialog-senha
          v-if="dataset.main.detail.id && showSenha"
          :context="context"
          :idPessoa="dataset.main.detail.id"
          :show="showSenha"
          @alterado="senhaAlterada"
          @close="closeSenha"
        />
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import axios from "axios";
import CBaseDetailImages from "@/components/bases/CBaseDetailImages";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CBaseDialogSenha from "@/components/bases/CBaseDialogSenha";
import CPessoaEdit from "@/components/pages/pessoa/CPessoaEdit";
import CPessoaView from "@/components/pages/pessoa/CPessoaView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Pessoa",

  /// COMPONENTS
  components: {
    CBaseDetailImages,
    CBaseDetailInsert,
    CBaseDialogEstado,
    CBaseDialogSenha,
    CPessoaEdit,
    CPessoaView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("pessoa").id,
      idPageAlt: getPagina("pessoas").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: this.$route.query.idTipo,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            nome: "",
            nomeAlt: "",
            dataNascimento: null,
            idSexo: null,
            idTipoChavePix: null,
            chavePix: null,
            observacoes: null,
          },
          enderecos: [],
          documentos: [],
          telefones: [],
          urls: [],
          cartoes: [],
          contasBancarias: [],
          relacoes: [],
          pagamentosInstantaneos: [],
          parceiros: [],
          parceiros_perfis: [],
        },
        aux: {
          detail: {
            idParceiro: null,
            sexo: null,
          },
          enderecos: [],
          documentos: [],
          telefones: [],
          urls: [],
          cartoes: [],
          contasBancarias: [],
          relacoes: [],
          pagamentosInstantaneos: [],
          imagens: [],
        },
      },

      funcs: {
        clickAlterarSenha: () => {
          this.showSenha = true;
        },

        clickAssistenciasTecnicas: (context) => {
          context.funcs.clickRoute(
            "/assistenciasTecnicas",
            context,
            "ast_pesCli.idPessoa"
          );
        },

        clickContasPagRec: (context) => {
          context.funcs.clickRoute("/contasPagRec", context);
        },

        clickNotasFiscais: (context) => {
          context.funcs.clickRoute("/notasFiscais", context);
        },

        clickPedidos: (context) => {
          context.funcs.clickRoute("/pedidos", context);
        },

        clickProdutos: (context) => {
          const route = {
            path: "/pedidos",
            query: {
              nome:
                context.dataset.main.detail.nomeAlt ||
                context.dataset.main.detail.nome,
            },
          };
          route.query["idPessoa"] = context.dataset.main.detail.id;
          route.query["isProduto"] = true;

          this.$router.push(route);
        },

        clickRoute: (url, context, idPessoa = "idPessoa") => {
          const route = {
            path: url,
            query: {
              nome: context.dataset.main.detail.nome,
            },
          };
          route.query[idPessoa] = context.dataset.main.detail.id;

          this.$router.push(route);
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          } else if (action == "cadastro") {
            context.clickEditView();
          } else if (action == "extratoAutomatico") {
            context.snackbar.params.res = true;
            context.snackbar.show = false;
          }

          context.setSnackbar();
        },

        clickValoresPagRec: (context) => {
          context.funcs.clickRoute("/valoresPagRec", context);
        },

        fnParams: () => {
          return JSON.stringify({
            idPessoaParceiro: this.getVar("idPessoaParceiro"),
          });
        },

        preValidate: async (context) => {
          let resPosition = true;

          for (let endereco of context.dataset.main.enderecos
            .map((endereco, i) => {
              return {
                ...endereco,
                index: i,
              };
            })
            .filter((endereco) => endereco.idCidade === 0)) {
            await this.funcs.saveCidade(endereco.index);
          }

          if (context.dataset.main.enderecos) {
            resPosition = await this.setPosition(context);
          }

          if (resPosition.err) {
            throw resPosition.err;
          }

          return resPosition;
        },

        posLoad: (context) => {
          if (
            this.$route.query.idTipo ==
            context.getTipo_item("pessoaJuridica").id
          ) {
            const atualizacao = context.getVar("atualizacao");
            atualizacao.usuarios = null;
            context.setVar({ atualizacao });
          }

          if (!this.$route.params.id) {
            setTimeout(() => {
              context.idPageMode = this.getConst("config").pageMode.insert.id;
            }, 10);
          }

          if (this.$route.query.edit) {
            setTimeout(() => {
              context.clickEditView();
            }, 10);
          }

          if (this.$route.query.novoCartao) {
            setTimeout(() => {
              const index = context.$refs.panelsDetail.$children.findIndex(
                (panelDetail) =>
                  panelDetail.$vnode.componentOptions.tag ==
                  "CPessoaPanelCartoes"
              );

              if (index >= 0) {
                context.clickEditView();
                context.panelsOpen = [index];
                context.dataset.main.carrinho = {
                  ...this.$route.query,
                };

                setTimeout(() => {
                  this.params.novoCartao = true;
                }, 100);
              }
            }, 10);
          }

          if (this.$route.query?.idPanel) {
            setTimeout(() => {
              const index = context.$refs.panelsDetail.$children.findIndex(
                (panelDetail) =>
                  panelDetail.$vnode.componentOptions.tag ==
                  this.getFeature(this.$route.query.idPanel).nome
              );

              if (index >= 0) {
                context.panelsOpen = [index];
                context.goTo(this.getFeature(this.$route.query.idPanel).nome);
              }
            }, 500);
          }
        },

        posSubmit: async (context, save = false) => {
          if (
            ![context.dataset.main.detail.id, getConst("app").id].includes(
              context.getVar("idPessoaParceiro")
            )
          ) {
            const anexoIndex = context.options.findIndex(
              (option) => option.nome == "optionAnexos"
            );
            context.options.splice(anexoIndex, 1);
          }
          if (save) {
            if (context.dataset.main.detail.id == context.getVar("idUsuario"))
              context.usuariosAtualizar();

            if (this.$route.query.novoCartao) {
              const url = `/carrinho/${this.$route.query.idCarrinho}`;
              this.$router.push(encodeURI(url));
            }
          }

          if (context.dataset.main.detail.dataNascimento) {
            context.dataset.main.detail.dataNascimento =
              context.dataset.main.detail.dataNascimento.substring(0, 23);
          }

          if (!context.dataset.main.detail.id && this.$route.query) {
            context.dataset.aux.detail.isAdmin = this.$route.query.home;

            context.dataset.main.detail.idTipo =
              this.$route.query?.idTipo || context.dataset.main.detail.idTipo;
            context.dataset.main.detail.dataNascimento =
              this.$route.query?.dataNascimento ||
              context.dataset.main.detail.dataNascimento;

            if (
              this.$route.query?.idPais &&
              this.$route.query?.documentoFederal
            ) {
              const tipoItem =
                context.dataset.main.detail.idTipo ==
                this.getTipo_item("pessoaFisica").id
                  ? "documentoPFFederal"
                  : "documentoPJFederal";

              const documento = {
                id: null,
                idPai: null,
                order: 1,
                idPais: this.$route.query.idPais,
                idTipo: this.getTipo_item(tipoItem).id,
                numero: this.$route.query.documentoFederal,
                emissor: "",
                dataEmissao1: null,
                dataEmissao: null,
                dataValidade: null,
                observacao: null,
              };

              context.dataset.main.documentos.push(documento);
            }
          }

          setTimeout(() => {
            context.params.setSnackbar = context.setSnackbar;
          }, 10);
        },

        posValidate: async (context) => {
          if (
            context.dataset.main.detail.idEstado ==
            context.getEstado("cadastro").id
          ) {
            context.dataset.main.detail.idEstado =
              context.getEstado("ativo").id;
          }
        },

        saveCidade: async (index) => {
          const cidade = this.dataset.aux.enderecos[index].cidades.find(
            (cidade) => cidade.value == 0
          );

          const datasetLocalidade = {
            metadados: {
              modo: this.getConst("config").modo.cadastro.id,
              idUsuario: this.getVar("idUsuario"),
              idPessoaParceiro: this.getVar("idPessoaParceiro"),
              idEvento: this.getTipo_item("eventoIncluido").id,
            },
            main: {
              detail: {
                id: null,
                idPai: this.dataset.aux.enderecos[index].idUf,
                idTipo: this.getTipo_item("localidadeCidade").id,
                idEstado: this.getEstado("cadastro").id,
                nome: cidade.text,
                codigo: cidade.codigo,
              },
              codigosPostais: [
                {
                  id: null,
                  idPai: null,
                  codigoInicio: this.dataset.main.enderecos[index].codigoPostal,
                  codigoFim: this.dataset.main.enderecos[index].codigoPostal,
                },
              ],
              codigosAreas: [
                {
                  id: null,
                  idPai: null,
                  codigo: cidade.ddd,
                  observacao: null,
                },
              ],
            },
          };

          const pageNome = "localidades";
          const url = `${
            this.getConst("app").baseUrlBackend
          }/${pageNome}/${this.getVar("idPessoaParceiro")}/${this.getVar(
            "idUsuario"
          )}`;
          const res = await axios.post(url, datasetLocalidade);

          if (res?.data?.main?.detail?.id) {
            cidade.value = res.data.main.detail.id;
            this.dataset.main.enderecos[index].idCidade = cidade.value;
          } else {
            const err = {
              show: true,
              title: "CIDADE NÃO CADASTRADA",
              text: `Não foi possivel concluir o seu cadastro com a cidade ${cidade.text}`,
              color: "error",
            };

            throw err;
          }
        },
      },

      params: {
        novoCartao: false,
      },

      showSenha: false,
    };
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getTipo: "tipos/getTipo",
      getLocalidade: "localidades/getLocalidade",
    }),
  },

  /// METHODS
  methods: {
    closeSenha() {
      this.showSenha = false;
    },

    senhaAlterada(context) {
      this.showSenha = false;
      context.setSnackbar(true, "SENHA", "Senha alterada.");
    },

    async setPosition(context) {
      let ret = true;
      let i = 0;

      for (let endereco of context.dataset.main.enderecos.filter(
        (endereco) => endereco.endereco
      )) {
        if (!endereco.endereco) {
          const err = {
            show: true,
            title: "GEOLOCALIZAÇÃO",
            text: "Endereço não localizado ou não atendido no momento.",
            color: "error",
          };

          ret = { err };

          break;
        }

        endereco.isVerificado = false;

        let url = `${this.getConst("app").baseUrlBackend}/localidades/${
          endereco.idCidade
        }/${this.getVar("idPessoaParceiro")}/${this.getVar("idUsuario")}`;

        const resCidade = await axios.get(url);

        const objCidade = resCidade.data ? resCidade.data.main.detail : null;
        const cidade = objCidade ? objCidade.nome : null;
        const estado = objCidade
          ? context.getLocalidade(objCidade.idPai)
          : null;
        const pais = context.getLocalidade(endereco.idPais);

        const enderecoCompleto = `${endereco.endereco} ${
          endereco.numero || ""
        } ${endereco.bairro}, ${cidade} ${estado.codigo2}, ${pais.nome}`;

        let enderecoCompletoTemp = "";

        const enderecoTemp = context.datasetTemp
          ? context.datasetTemp.main.enderecos[i] || null
          : null;

        if (enderecoTemp) {
          url = `${this.getConst("app").baseUrlBackend}/localidades/${
            enderecoTemp.idCidade
          }/${this.getVar("idPessoaParceiro")}/${this.getVar("idUsuario")}`;

          const resCidadeTemp = await axios.get(url);
          const objCidadeTemp = resCidadeTemp.data
            ? resCidadeTemp.data.main.detail
            : null;
          const cidadeTemp = objCidadeTemp?.nome || "";
          const ufTemp = context.getLocalidade(objCidadeTemp.idPai).codigo2;
          const paisTemp = context.getLocalidade(enderecoTemp.idPais).nome;

          enderecoCompletoTemp = `${enderecoTemp.endereco} ${
            enderecoTemp.numero || ""
          }, ${cidadeTemp} ${ufTemp}, ${paisTemp}`;
        }

        if (enderecoCompleto != enderecoCompletoTemp) {
          try {
            url = `${this.getConst("app").baseUrlBackend}/mapQuest`;
            const body = {
              address: enderecoCompleto,
            };
            const res = await axios.post(url, body);

            const street = res.data[0].streetName;
            const city = res.data[0].city;
            const stateCode = res.data[0].stateCode;
            const countryCode = res.data[0].countryCode;

            if (
              street != "" &&
              [city, new Buffer.from(city, "latin1").toString()].some(
                (city) =>
                  city.toUpperCase().normalizeBr() ==
                  cidade.toUpperCase().normalizeBr()
              ) &&
              [stateCode, new Buffer.from(stateCode, "latin1").toString()].some(
                (state) => [estado.codigo2, estado.nome].includes(state)
              ) &&
              countryCode == pais.codigo2
            ) {
              endereco.latitude = res.data[0].latitude;
              endereco.longitude = res.data[0].longitude;
              endereco.isVerificado = true;
            } else {
              const err = {
                show: true,
                title: "GEOLOCALIZAÇÃO",
                text: `Endereço não localizado: ${enderecoCompleto}.`,
                color: "error",
              };

              ret = { err };
            }
          } catch (e) {
            const err = {
              show: true,
              title: "GEOLOCALIZAÇÃO",
              text: e,
              color: "error",
            };

            ret = { err };
          }
        }

        if (!ret) break;

        i++;
      }

      return ret;
    },
  },

  /// BEFOREMOUNT
  beforeMount() {
    if (this.$route.fullPath == "/pessoa?idTipo=4102") {
      const idEstado = this.getUsuario(this.getVar("idUsuario")).pessoa
        .idEstado;

      if (idEstado == this.getEstado("cadastro").id) {
        this.$router.push("/cadastroIncompleto");
      }
    }
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>