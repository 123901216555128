<!-- COD: EMR 19/08/2022 CBaseHtml -->
<!-- *VER: JER 19/08/2022 CBaseHtml -->
<!-- /12/ -->

<script>
import VueWithCompiler from "vue/dist/vue.esm";
import { VChip, VIcon } from "vuetify/lib";

export default {
  /// NAME
  name: "CBaseHtml",

  /// COMPONENTS
  components: {
    VChip,
    VIcon,
  },

  /// PROPS
  props: {
    html: { type: String, default: "" },
  },

  /// DATA
  data() {
    return { templateRender: undefined };
  },

  /// WATCH
  watch: {
    html() {
      this.updateRender();
    },
  },

  /// CREATED
  created() {
    this.updateRender();
  },

  /// MOUNTED
  mounted() {
    if (document.addEventListener) {
      document.addEventListener("click", this.interceptClickEvent);
    } else if (document.attachEvent) {
      document.attachEvent("onclick", this.interceptClickEvent);
    }
  },

  /// METHODS
  methods: {
    interceptClickEvent(event) {
      if (event) {
        const target = event?.target || event?.srcElement;
        if (target.tagName === "A") {
          const href = target.getAttribute("href");

          if (href && href.startsWith("#")) {
            event.preventDefault();
            this.$emit("clickGoToHash", href);
          }
        }
      }
    },

    updateRender() {
      const html = this.html.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
      const compiled = VueWithCompiler.compile(`<div>${html}</div>`);
      this.templateRender = compiled.render;

      this.$options.staticRenderFns = [];
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction);
      }
    },
  },

  /// RENDER
  render() {
    return this.templateRender();
  },
};
</script>

<style lang="scss">
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-size-small {
  font-size: 0.75rem;
}
.ql-indent-1 {
  margin-left: 20px;
  list-style-type: circle;
}
.ql-indent-2 {
  margin-left: 40px;
  list-style-type: square;
}
</style>