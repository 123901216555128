<!-- COD: LYF 12/08/2022 Cupom -->
<!-- *VER: JER 19/08/2022 Cupom -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-cupom-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-cupom-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CCupomEdit from "@/components/pages/cupom/CCupomEdit";
import CCupomView from "@/components/pages/cupom/CCupomView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Cupom",

  /// COMPONENTS
  components: {
    CCupomEdit,
    CCupomView,
    CBaseDetailInsert,
    CBaseDialogEstado,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("cupom").id,
      idPageAlt: getPagina("cupons").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            data: null,
            quantidade: null,
            codigo: null,
            descricao: null,
            valor: null,
            percentual: null,
            idCliente: null,
            valorCompraMinimo: null,
            valorDescontoMaximo: null,
            dataInicio: null,
            dataFim: null,
            isSomentePrimeiraCompra: false,
            isSomenteEmUnicaCompra: false,
            isSomenteSeUnicoNaCompra: false,
            isDependeCustoReposicao: false,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          localidades: [],
          pagamentos: [],
          produtos: [],
        },
        aux: {
          detail: {
            pessoaParceiro_nomeAlt: null,
            pessoaCliente_nome: null,
          },
          autocomplete: {
            parceiro: [],
            cliente: [],
            localidades: [],
            produtos: [],
          },
          utilizados: null,
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posLoad: (context) => {
          context.params.setBottomSheet = context.setBottomSheet;
        },

        posSubmit: (context) => {
          if (
            !context.dataset.main.detail.id &&
            context.getVar("idPessoaParceiro") != context.getConst("app").id
          ) {
            context.dataset.main.detail.idPessoaParceiro =
              context.getVar("idPessoaParceiro");
            context.dataset.aux.detail.pessoaParceiro_nomeAlt =
              context.dadosUsuario.pessoa.nomeAlt;
          }
        },

        fnParams: () => {
          const params = JSON.stringify({
            idPessoaParceiro: this.getVar("idPessoaParceiro"),
          });

          return params;
        },
      },

      params: {
        context: null,
      },
      valor: true,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({ getTabelas: "tabelas/getTabelas" }),

    // idTipo() {
    //   console.log(this.dataset.main.detail);
    //   return this.dataset.main.detail.idTipo;
    // },
  },

  /// WATCH
  // watch: {
  //   idTipo() {
  //     if (
  //       [this.getTipo_item("cupomGreen").id].includes(
  //         this.dataset.main.detail.idTipo
  //       )
  //     ) {
  //       this.dataset.main.detail.idPessoaParceiro =
  //         this.getVar("idPessoaParceiro");
  //     }
  //   },
  // },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>