// COD: BJB 15/08/2022 variaveis
// *VER: JER 19/08/2022 variaveis
// /11/

import constantes from "./constantes.js";

export default {
  namespaced: true,

  /// STATE
  state: {
    detailUpdated: false,
    disconnections: 0,
    keepAlive: true,
    reload: false,
    reloadComponents: [],
    rotas: [],
    socket: null,

    // navbar
    atualizacao: { usuarios: new Date(), pendencias: null },
    isSenhaProvisoria: false,
    idPessoaParceiro: null,
    parceiroCliente: {
      idPessoa: null,
      nome: null,
      idTipo: null,
      enderecos: null,
      value: null,
      text: null,
      textAlt: null,
    },
    idTipoPedido: 5302,  // (5301-entrada, 5302-saida)
    idUsuario: null,
    optionsNavBar: [],
    pendencias: {
      avaliacoes: { priority: null, value: null },
      carrinhoAtualItens: { priority: null, value: null },
      carrinhos: { priority: null, value: null },
      convites: { priority: null, value: null },
      cupons: { priority: null, value: null },
      feedbacks: { priority: null, value: null },
      notificacoes: { priority: null, value: null },
      parceiros: { priority: null, value: null },
      pedidos: { priority: null, value: null },
      pessoas: { priority: null, value: null },
      produtos: { priority: null, value: null },
      publicacoes: { priority: null, value: null },
      transacoes: { priority: null, value: null },
    },
    usuarios: [],
    usuariosTemp: [],

    // menuEnderecos
    isEntregar: true,
    idPessoaEndereco: null,
    showEnderecos: false,

    //impressora
    print: {},

    // outras
    isBarraInferiorColorida: false,
    carrinhos: [],
    categoriasSelected: [10000, 10300, []],  //? confirmar default
    datasetsIds: [],
    datasetsSelectedIndex: 0,
    goBack: -1,
    idPaisConfiguracaoRegional: 20300000, // Brasil
    idTipoLogin: 1,  // (0-cadastrar, 1-entrar)
    ipInterno: null,
    limit: {
      itens: [
        { value: 25 },
        { value: 50 },
        { value: 100 }],
      selected: 25,
    },
    idLocale: 1,
    locale: "pt-BR",
    // locale: "en-US",
    logout: false,
    moedaCodigo: 'USD',
    // moedaCodigo: 'BRL',
    moedaSimbolo: '$',
    // moedaSimbolo: 'R$',
    overlay: false,
    routeIgnore: [],
    routeReplace: false,
  },

  /// GETTERS
  getters: {
    getUsuarios: state => () => {
      return state.usuarios
        .map(element => {
          return {
            value: element.pessoa.id,
            text: element.pessoa.nomeAlt
          };
        })
    },

    getUsuario: state => id => {
      return state.usuarios.length
        ? id > 0
          ? state.usuarios
            .find(element => element.pessoa.id == id)
          : state.usuarios[0]
        : { id }
    },

    getVar: state => chave => {
      return state[chave]
    },
  },

  /// MUTATIONS
  mutations: {
    setVar: async (state, payload) => {
      Object.keys(payload).forEach(chave => {
        const chaves = chave.split(".")
        let run = "";

        chaves.forEach(chaveArr => {
          run += !run ? `state["${chaveArr}"]` : `["${chaveArr}"]`
        })

        run += ' = payload[chave]'

        eval(run)
      })

      if (state.usuarios.length) {
        const varApp = {
          idPessoaParceiro: state.idPessoaParceiro,
          idTipoPedido: state.idTipoPedido,
          isEntregar: state.isEntregar,
          idPessoaEndereco: state.idPessoaEndereco,
          print: state.print
        };

        if (state.usuarios.find(usuario => usuario.pessoa.id == state.idPessoaParceiro)?.temp) {
          varApp.idPessoaParceiro = state.usuarios[0].pessoa.id;
        }

        await localStorage.setItem(
          constantes.state.app.varKey,
          JSON.stringify(varApp)
        );
      }
    }
  },

  /// ACTIONS
  actions: {
    setVar: (context, payload) => {
      context.commit("setVar", payload)
    }
  }
}