var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"mb-10 pa-0",attrs:{"id":"formSelect"}},[(_vm.idPage && _vm.idPageAlt)?_c('c-base-list',{attrs:{"funcs":_vm.funcs,"hasSearch":false,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"pageModeEdit":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var context = ref.context;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('v-chip-group',{staticStyle:{"width":"fit-content"},attrs:{"disabled":context.snackbar.show},on:{"change":function($event){return _vm.changeFilter(context)}},model:{value:(_vm.isCliente),callback:function ($$v) {_vm.isCliente=$$v},expression:"isCliente"}},_vm._l((_vm.clienteFornecedor),function(cliFor,i){return _c('v-chip',{key:i,class:_vm.isCliente != cliFor.value ? 'primary--text' : 'white--text',attrs:{"color":"primary","outlined":_vm.isCliente != cliFor.value,"value":cliFor.value}},[_vm._v(" "+_vm._s(cliFor.text)+" ")])}),1)],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"disabled":context.snackbar.show,"items":_vm.pessoas,"label":"pessoa","required":""},model:{value:(_vm.idPessoa),callback:function ($$v) {_vm.idPessoa=$$v},expression:"idPessoa"}})],1)],1)]}},{key:"insert",fn:function(ref){
var context = ref.context;
return [(context.datasets.length)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"red--text text-body-2"},[_vm._v(" selecione os itens a enviar ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"classe":"pa-0 primary--text","text":""},on:{"click":function($event){return _vm.selecionarTodos(context)}}},[_vm._v(" "+_vm._s(_vm.labelSelecionarDeselecionarTodos(context))+" ")])],1)],1)],1):_vm._e()]}},{key:"bottom",fn:function(ref){
var context = ref.context;
return [(context.datasets.length)?_c('div',{staticClass:"mt-5"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"disabled":context.snackbar.show,"items":_vm.operacoes,"label":"operação","required":""},model:{value:(_vm.idOperacao),callback:function ($$v) {_vm.idOperacao=$$v},expression:"idOperacao"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"disabled":context.snackbar.show,"items":_vm.prestadores,"label":"prestador","required":""},model:{value:(_vm.idPrestador),callback:function ($$v) {_vm.idPrestador=$$v},expression:"idPrestador"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"disabled":context.snackbar.show,"label":"prazo","max":3,"options":{
                length: 3,
                precision: 0,
                prefix: '',
                suffix: ' dias',
              },"required":""},model:{value:(_vm.prazo),callback:function ($$v) {_vm.prazo=$$v},expression:"prazo"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{staticClass:"text-h5 font-weight-light",attrs:{"clearable":false,"label":"total selecionado","max":15,"options":{
                length: 11,
                precision: 2,
                style: 'currency',
              },"outlined":"","readonly":"","value":_vm.getTotais(context).valor}}),(_vm.getTotais(context).quantidade > 0)?_c('v-badge',{staticClass:"mb-n3 ml-n2",attrs:{"color":"grey","content":_vm.getTotais(context).quantidade,"overlap":""}}):_vm._e()],1)],1)],1):_vm._e()]}},{key:"default",fn:function(ref){
              var assistenciaTecnica = ref.dataset;
return [_c('c-assistenciaTecnica-page-pedido-saida-edit',{attrs:{"assistenciaTecnica":assistenciaTecnica}})]}}],null,false,1293702744)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }