<!-- COD: FDS 05/07/2022 AssistenciaTecnica -->
<!-- *VER: JER 05/08/2022 AssistenciaTecnica -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      v-if="load"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ pageModeView, context }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-assistenciaTecnica-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-assistenciaTecnica-edit
            v-else
            :context="context"
            :dataset="dataset"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CAssistenciaTecnicaEdit from "@/components/pages/assistenciaTecnica/CAssistenciaTecnicaEdit";
import CAssistenciaTecnicaView from "@/components/pages/assistenciaTecnica/CAssistenciaTecnicaView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "AssistenciaTecnica",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CAssistenciaTecnicaEdit,
    CAssistenciaTecnicaView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("assistenciaTecnica").id,
      idPageAlt: getPagina("assistenciasTecnicas").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("assistenciaTecnica").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: null,
            idPessoaParceiro: null,
            isProprio: null,
            isDefeito: null,
            isCliente: null,
            isPendenteFornecedor: null,
            data: null,
            idProduto: null,
            numeroSerie: null,
            idFornecedor: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          pessoas: [],
          pedidos: [],
        },
        aux: {
          detail: {
            pessoaParceiro_nomeAlt: null,
            pessoaFornecedor_nome: null,
            produto: {
              id: null,
              produto: null,
              marca: null,
              serie: null,
              descricao: null,
              descricaoComplementar: null,
              modelo: null,
              partNumber: null,
            },
          },

          pessoas: [],
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },
      },

      params: {
        context: null,
        clickPessoa: null,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>