<!-- COD: BJB 29/07/2022 CFolhaPagamentoPageOcorrenciaView -->
<!-- *VER: JER 07/08/2022 CFolhaPagamentoPageOcorrenciaView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <a class="text-body-1" @click="clickHolerite()">
          {{ ocorrencia.nome }}
        </a>
      </v-col>
    </v-row>

    <!-- ocorrencia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <a class="text-body-1" @click="clickVerba()">
          {{ `${ocorrencia.idVerba} ${getVerba(ocorrencia.idVerba).nomeAlt}` }}
        </a>
      </v-col>
    </v-row>

    <!-- dataInicioFim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- dataValor -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataValor }}
        </span>
      </v-col>
    </v-row>

    <!-- referencia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ ocorrencia.referencia }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoaFavorecida_nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ ocorrencia.pessoaFavorecida_nome }}
        </span>
      </v-col>
    </v-row>

    <c-folhaPagamento-dialog-holerite
      v-if="showHolerite"
      v-model="showHolerite"
      :dataset="context.dataset"
      :idPessoaColaborador="ocorrencia.idPessoaColaborador"
    />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CFolhaPagamentoDialogHolerite from "@/components/pages/folhaPagamento/dialogs/CFolhaPagamentoDialogHolerite.vue";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhaPagamentoPageOcorrenciaView",

  /// COMPONENTS
  components: {
    CFolhaPagamentoDialogHolerite,
  },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    ocorrencia: { type: Object, required: true },
  },

  /// DATA
  data() {
    return {
      showHolerite: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVerba: "verbas/getVerba",
    }),

    dataInicioFim() {
      const dataFim = this.ocorrencia.dataFim
        ? this.dateFormat(this.ocorrencia.dataFim, "date")
        : "atual";

      return `${this.dateFormat(
        this.ocorrencia.dataInicio,
        "date"
      )} - ${dataFim}`;
    },

    dataValor() {
      return `${
        this.ocorrencia.data
          ? this.dateFormat(this.ocorrencia.data, "date")
          : ""
      }  ${this.numberFormat(this.ocorrencia.valor, "currency")}`;
    },
  },

  /// METHODS
  methods: {
    clickHolerite() {
      this.showHolerite = true;
    },

    clickVerba() {
      this.$router.push(`/verba/${this.ocorrencia.idVerba}`);
    },
  },
};
</script>