// COD: BJB 15/08/2022 paginas
// *VER: JER 19/08/2022 paginas
// /21/

export default {
  namespaced: true,

  /// STATE
  state: {
    features: [],
    paginas: [],
    paginas_direitos: [],
    paginas_features: [],
    paginas_configuracoes: [],
    paginas_documentos: [],
  },

  /// GETTERS
  getters: {
    getFeatures: state => (prop = "id") => {
      return state.features
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            ...element,
            value: element.id,
            text: element.nomeAlt
          };
        });
    },

    getFeature: state => search => {
      return state.features.length
        ? state.features
          .find(element => element.id == search || element.nome == search)
        : { search }
    },

    getPaginas: state => () => {
      return state.paginas
        .map(element => {
          return {
            value: element.id,
            text: element.nomeAlt,
            ...element
          };
        });
    },

    getPagina: state => search => {
      return state.paginas.length
        ? state.paginas
          .find(element => element.id == search || element.nome == search)
        : { search }
    },

    getPagina_direitos: state => (id, prop = "id") => {
      return state.paginas_direitos
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.id,
            ...element
          };
        });
    },

    getPagina_direito: state => id => {
      return state.paginas_direitos.length
        ? state.paginas_direitos
          .find(element => element.id == id)
        : { id }
    },

    getPagina_features: state => (id, prop = "id") => {
      return state.paginas_features
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.id
          };
        });
    },

    getPagina_feature: state => id => {
      return state.paginas_features.length
        ? state.paginas_features
          .find(element => element.id == id)
        : { id }
    },

    getPagina_configuracoes: state => (id, prop = "id") => {
      return state.paginas_configuracoes
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.id,
            ...element
          };
        });
    },

    getPagina_configuracao: state => id => {
      return state.paginas_configuracoes.length
        ? state.paginas_configuracoes
          .find(element => element.id == id)
        : { id }
    },

    getPagina_documentos: state => (id, prop = "id") => {
      return state.paginas_documentos
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map((element, i) => {
          return {
            value: element.id,
            text: element.id,
            index: i,
            ...element
          };
        });
    },

    getPagina_documento: state => id => {
      return state.paginas_documentos.length
        ? state.paginas_documentos
          .find(element => element.id == id)
        : { id }
    },
  },

  /// MUTATIONS
  mutations: {
    setFeatures: (state, payload) => {
      state.features = []
      payload.forEach(element => {
        state.features.push(element)
      });
    },

    setPaginas: (state, payload) => {
      state.paginas = []
      payload.forEach(element => {
        state.paginas.push(element)
      });
    },

    setPaginas_direitos: (state, payload) => {
      state.paginas_direitos = []
      payload.forEach(element => {
        state.paginas_direitos.push(element)
      });
    },

    setPaginas_features: (state, payload) => {
      state.paginas_features = []
      payload.forEach(element => {
        state.paginas_features.push(element)
      });
    },

    setPaginas_configuracoes: (state, payload) => {
      state.paginas_configuracoes = []
      payload.forEach(element => {
        state.paginas_configuracoes.push(element)
      });
    },

    setPaginas_documentos: (state, payload) => {
      state.paginas_documentos = []
      payload.forEach(element => {
        state.paginas_documentos.push(element)
      });
    },
  },

  /// ACTIONS
  actions: {
    setFeatures: (context, payload) => {
      context.commit("setFeatures", payload)
    },

    setPaginas: (context, payload) => {
      context.commit("setPaginas", payload)
    },

    setPaginas_direitos: (context, payload) => {
      context.commit("setPaginas_direitos", payload)
    },

    setPaginas_features: (context, payload) => {
      context.commit("setPaginas_features", payload)
    },

    setPaginas_configuracoes: (context, payload) => {
      context.commit("setPaginas_configuracoes", payload)
    },

    setPaginas_documentos: (context, payload) => {
      context.commit("setPaginas_documentos", payload)
    },
  }
}