var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{attrs:{"funcs":_vm.funcsDetail,"idFeature":_vm.idFeature,"idPage":_vm.idPageDetail,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
return [_c('c-folhaPagamento-view',{attrs:{"context":context,"dataset":_vm.dataset}})]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}),(_vm.loadList)?_c('c-base-list',{attrs:{"funcs":_vm.funcsList,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"pageModeEdit":true},on:{"showSearchOptions":function($event){_vm.showSearchOptions = $event}},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var context = ref.context;
return [_c('v-container',[(_vm.showSearchOptions)?_c('v-row',_vm._b({staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"só pessoal administrativo"},on:{"click":function($event){return _vm.filtroDatasets(context, false)}},model:{value:(_vm.pessoalAdministrativo),callback:function ($$v) {_vm.pessoalAdministrativo=$$v},expression:"pessoalAdministrativo"}})],1)],1):_vm._e(),(_vm.showSearchOptions)?_c('v-row',_vm._b({staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"só pessoal comercial"},on:{"click":function($event){return _vm.filtroDatasets(context, true)}},model:{value:(_vm.pessoalComercial),callback:function ($$v) {_vm.pessoalComercial=$$v},expression:"pessoalComercial"}})],1)],1):_vm._e()],1)]}},{key:"listContent",fn:function(ref){
var context = ref.context;
var datasets = ref.datasets;
return [_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":true,"params":_vm.params,"value":datasets},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var dataset = ref.dataset;
return [_c('v-container',{staticClass:"py-0"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"10"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(dataset.nome)+" ")])]),(_vm.bagdeColaborador(dataset, context) > 0)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"2"}},'v-col',_vm.prop.col,false),[_c('v-badge',{attrs:{"color":"grey","content":_vm.bagdeColaborador(dataset, context).toString(),"inline":"","overlap":""}})],1):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(dataset.cargo)+" ")])]),_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('v-badge',{attrs:{"color":"grey","content":_vm.numberFormat(dataset.valorSalario, 'currency'),"inline":"","overlap":""}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v(" "+_vm._s(_vm.situacaoTrabalho)+" ")])],1)],1)],1)]}},{key:"content",fn:function(ref){
var colaborador = ref.dataset;
return [(_vm.pageModeView)?_c('c-folhaPagamento-page-colaborador-view',{attrs:{"colaborador":colaborador,"context":context}}):_c('c-folhaPagamento-page-colaborador-edit',{attrs:{"colaborador":colaborador,"context":context}})]}}],null,true)})]}}],null,false,509892031)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }