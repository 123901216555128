<!-- COD: FDS 08/05/2023 CLoteView -->
<!-- #VER: JER __/05/2023 CLotesView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- dataInicioFim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.data, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.observacao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">({{ dataset.main.detail.observacao }})</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLoteView",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// MIXINS
  mixins: [mixinData],

  // COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
    }),

    dataInicioFim() {
      let ret = "";

      const dataInicio = this.dataset.main.detail.dataInicio
        ? this.dateFormat(this.dataset.main.detail.dataInicio, "date")
        : "";

      const dataFim = this.dataset.main.detail.dataFim
        ? `- ${this.dateFormat(this.dataset.main.detail.dataFim, "date")}`
        : "";

      ret = `${dataInicio} ${dataFim}`;

      return ret;
    },
  },
};
</script>