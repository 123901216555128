<!-- COD: FDS 10/08/2022 CBasePanel -->
<!-- *VER: JER 10/08/2022 CBasePanel -->
<!-- /22/ -->

<template>
  <v-expansion-panel v-if="showPanel" class="pl-n6" expand>
    <v-expansion-panel-header
      class="grey lighten-3"
      :class="badgeValue ? 'px-1 py-2' : 'px-1 py-0'"
    >
      <v-container class="py-0" :class="badgeValue ? '' : 'mt-2 mb-n2'">
        <v-row v-bind="prop.row">
          <!-- nome header -->
          <v-col v-bind="prop.col" :cols="headerCols">
            <slot name="header">
              <span v-if="header" class="ml-n3 text-h6">
                {{ translate(header) }}
              </span>
            </slot>
          </v-col>
          <!-- quantidade registros -->
          <v-col v-bind="prop.col" align="end" :cols="badgeCols">
            <v-badge
              v-if="badgeDot || parseInt(badgeContent)"
              :class="badgeClass"
              :color="badgeColor"
              :content="badgeContent"
              :dot="badgeDot"
              :inline="!badgeDot"
              :overlap="!badgeDot"
              :value="badgeValue"
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <!-- subheader -->
          <v-col v-bind="prop.col" cols="6">
            <slot name="subheader">
              <span class="text-body-1 ml-n4">
                {{ translate(subheader) }}
              </span>
            </slot>
          </v-col>
          <!-- totais -->
          <v-col v-bind="prop.col" v-if="totalItens" align="end" cols="6">
            <v-badge
              :content="numberFormat(totalItens, 'currency')"
              color="grey"
              left
            />
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mx-n6 mt-2" eager>
      <slot name="cabecalho"></slot>
      <slot>
        <div v-if="params && params.context">
          <v-container
            v-for="(dataset, i) in datasets"
            v-show="dataset ? !dataset.del : !dataset"
            class="pa-0"
            :id="`${panel.nomeAlt.normalizeBr()}${i}`"
            :key="i"
          >
            <slot
              :context="params.context"
              :dataset="dataset"
              :indice="i"
              name="item"
            >
            </slot>
          </v-container>
        </div>
      </slot>
      <slot name="bottom"></slot>
      <v-divider v-bind="prop.divider" v-if="showInsert" />
      <c-base-panel-insert
        v-if="showInsert"
        :datasets="datasets"
        :panel="panel"
        @incluir="$emit('incluir')"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CBasePanelInsert from "@/components/bases/CBasePanelInsert";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CBasePanel",

  /// COMPONENTS
  components: {
    CBasePanelInsert,
  },

  /// MIXINS
  mixins: [mixinLib],

  /// PROPS
  props: {
    badge: { type: Number, required: false },
    color: { type: String, required: false },
    datasets: { type: [Array, Object] },
    existsDot: { type: Boolean, default: false },
    header: { type: String, required: false },
    identification: { type: Boolean, default: false },
    min: { type: Number },
    pageModeView: { type: Boolean, default: false },
    panel: { type: Object, default: Object },
    params: {},
    requiredFields: { type: Array, default: () => [] },
    subheader: { type: String, required: false },
    totaisHeader: { type: Number, required: false },
  },

  /// COMPUTED
  computed: {
    badgeClass() {
      return this.badgeDot ? "mr-1" : "";
    },

    badgeColor() {
      return this.badgeColorError
        ? "red"
        : this.badgeColorMax || this.badgeColorMin
        ? "blue"
        : this.color || "grey";
    },

    badgeColorError() {
      return (
        this.existsDot ||
        this.datasets?.some((dataset) =>
          this.requiredFields.some((requiredField) => !dataset[requiredField])
        )
      );
    },

    badgeColorMax() {
      const max = this.panel?.options?.max;

      return max >= 0 && this.datasetsLength > max;
    },

    badgeColorMin() {
      const min = this.min ?? this.panel?.options?.min ?? 0;

      const hasRequiredFields = this.datasets?.some((dataset) =>
        this.requiredFields.some((requiredField) => !dataset[requiredField])
      );

      return hasRequiredFields || this.datasetsLength < min;
    },

    badgeCols() {
      return this.badgeValue ? "1" : "0";
    },

    badgeContent() {
      return this.badgeDot ? "" : this.badge?.toString();
    },

    badgeDot() {
      return this.badge == -1;
    },

    badgeValue() {
      return this.badgeDot
        ? this.badgeColorError || this.badgeColorMax || this.badgeColorMin
        : this.badge >= 0;
    },

    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },

    datasetsLength() {
      return this.datasets?.filter((dataset) => !dataset?.del)?.length ?? 0;
    },

    forceUpdate() {
      return this.panel.forceUpdate;
    },

    headerCols() {
      return this.badgeValue ? "11" : "12";
    },

    showInsert() {
      return !this.pageModeView && this.panel?.options?.max;
    },

    showPanel() {
      const min = this.min ?? this.panel?.options?.min ?? 0;

      return !this.pageModeView || min > 0 || !!this.datasetsLength;
    },

    totalItens() {
      return this.totaisHeader || this.totaisHeader == 0
        ? this.totaisHeader || "0.00"
        : this.datasets?.length && this.datasets[0]?.valorItem != undefined
        ? this.datasets.reduce((acc, item) => {
            return (acc += parseFloat(item.valorItem));
          }, 0)
        : null;
    },
  },

  /// WATCH
  watch: {
    breakpointName() {
      setTimeout(() => {
        this.panel.forceUpdate = true;
        this.$forceUpdate();
      }, 1);
    },

    forceUpdate() {
      this.$forceUpdate();
    },
  },

  /// METHODS
  methods: {
    change: function (value) {
      if (value.removed) {
        const model = this.model;
        // insert
        model.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          model[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          model.splice(this.dragged.from, 1);
        // RTL
        else model.splice(this.dragged.from + 1, 1);

        this.model = model;
      }
    },

    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },

    setForceUpdate() {
      this.panel.forceUpdate = false;
      this.$forceUpdate();
    },
  },
};
</script>