// COD: BJB 15/08/2022 cartoes
// *VER: JER 19/08/2022 cartoes
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    legislacao_itensAtualizados: [],
  },

  /// GETTERS
  getters: {
    getLegislacao_itensAtualizados: state => () => {
      return state.legislacao_itensAtualizados
        .map(element => {
          return {
            value: element.id,
            text: element.nome,
            ... element
          };
        })
    },

    getLegislacao_itemAtualizado: state => id => {
      return state.legislacao_itensAtualizados.length
        ? state.legislacao_itensAtualizados
          .find(element => element.id == id)
        : { id }
    },
  },

  /// MUTATIONS
  mutations: {
    setLegislacao_itensAtualizados: (state, payload) => {
      state.legislacao_itensAtualizados = []
      payload.forEach(element => {
        state.legislacao_itensAtualizados.push(element)
      });
    },
  },

  /// ACTIONS
  actions: {
    setLegislacao_itensAtualizados: (context, payload) => {
      context.commit("setLegislacao_itensAtualizados", payload)
    },
  }
}