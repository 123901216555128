<!-- COD: LYF 20/07/2022 CProdutoEdit -->
<!-- *VER: JER 28/07/2022 CProdutoEdit -->
<!-- /22/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- produto -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.id">
      <v-col v-bind="prop.col" align="center">
        <span class="font-weight-medium text-body-1">
          {{ produtoFormat(dataset.main.detail, true) }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- codigo barra -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="dataset.main.detail.codigoBarra"
          label="código barra"
          :max="13"
          reference="barCode"
        />
      </v-col>
      <!-- barcode -->
      <v-col v-bind="prop.col" cols="1">
        <c-btn icon @click="openCamera = !openCamera">
          <v-icon>mdi-barcode</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <!-- camera -->
    <v-row v-bind="prop.row" v-if="openCamera">
      <v-col v-bind="prop.col" cols="4">
        <v-quagga
          :onDetected="logIt"
          :readerSize="{ width: 640, height: 480 }"
          :readerTypes="['ean_reader']"
        ></v-quagga>
      </v-col>
    </v-row>

    <!-- origem -->
    <v-row v-bind="prop.row" v-if="hasOrigem">
      <v-col v-bind="prop.col" cols="4">
        <c-select
          v-model="dataset.main.detail.idOrigem"
          :items="origens"
          :help="{
            title: 'ORIGEM',
            text: `0 - Nacional, exceto códigos 3, 4, 5 e 8
1 - Estrangeira - importação direta, exceto código 6
2 - Estrangeira - adquirida no mercado interno, exceto código 7
3 - Nacional - com conteúdo de importação > 40% e <= 70%
4 - Nacional - produção em  conformidade com os processos produtivos básicos 
5 - Nacional - com conteúdo de importação <= 40% 
6 - Estrangeira - importação direta, sem similar nacional, constante em lista CAMEX
7 - Estrangeira - adquirida no mercado interno, sem similar nacional, constante em lista CAMEX
8 - Nacional - com conteúdo de importação > 70%`,
            function: params.context.setUiBottomSheet,
            alignText: 'text-left',
          }"
          label="origem"
          required
        />
      </v-col>
    </v-row>

    <!-- codigo fiscal -->
    <!-- //? alterar o autocomplete para receber o tipo do produto -->
    <v-row v-bind="prop.row" v-if="labelCodigoFiscal">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.codigoFiscal"
          buscaIncluir
          :itemsIniciais="dataset.aux.autocomplete.codigosFiscais"
          :label="labelCodigoFiscal"
          :path="`/produtos/autocomplete/codigosFiscais/${dataset.main.detail.idPessoaParceiro}/1/1/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- codigo fiscal 2 -->
    <!-- //? alterar o autocomplete para receber o tipo do produto -->
    <v-row v-bind="prop.row" v-if="labelCodigoFiscal2">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.codigoFiscal2"
          buscaIncluir
          :itemsIniciais="dataset.aux.autocomplete.codigosFiscais2"
          :label="labelCodigoFiscal2"
          :path="`/produtos/autocomplete/codigosFiscais2/${dataset.main.detail.idPessoaParceiro}/1/1/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- grupo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idGrupo"
          buscaIncluir
          :itemsIniciais="dataset.aux.autocomplete.grupos"
          label="grupo"
          :path="`/produtos/autocomplete/grupos/${dataset.main.detail.idPessoaParceiro}/1/1/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.produto"
          buscaIncluir
          :itemsIniciais="dataset.aux.autocomplete.produtos"
          label="produto"
          :path="`/produtos/autocomplete/familias/${dataset.main.detail.idPessoaParceiro}/1/1/`"
          required
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- marca -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.marca"
          buscaIncluir
          :itemsIniciais="dataset.aux.autocomplete.marcas"
          label="marca"
          :path="`/produtos/autocomplete/marcas/${dataset.main.detail.idPessoaParceiro}/1/1/`"
          :rules="[]"
          :searchLength="2"
        />
      </v-col>
    </v-row>

    <!-- serie -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.serie"
          buscaIncluir
          :itemsIniciais="dataset.aux.autocomplete.series"
          label="série"
          :path="`/produtos/autocomplete/series/${dataset.main.detail.idPessoaParceiro}/1/1/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- idioma -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-base-idiomas
          v-model="context.idLocale"
          class="ml-n2"
          :idiomas="context.dadosParceiro.idiomas"
        />
      </v-col>
    </v-row>

    <!-- descricao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.descricao[context.localeParceiro]"
          label="descrição"
          :max="50"
          prepend-inner-icon="mdi-translate"
          required
        />
      </v-col>
    </v-row>

    <!-- descricao complementar -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="
            dataset.main.detail.descricaoComplementar[context.localeParceiro]
          "
          label="descrição complementar"
          :max="50"
          prepend-inner-icon="mdi-translate"
        />
      </v-col>
    </v-row>

    <!-- modelo -->
    <v-row v-bind="prop.row" v-if="isProduto">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.modelo"
          label="modelo"
          :max="18"
        />
      </v-col>
    </v-row>

    <!-- part number -->
    <v-row v-bind="prop.row" v-if="isProduto">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.partNumber"
          label="part number"
          :max="20"
        />
      </v-col>
    </v-row>

    <!-- unidade medida -->
    <v-row v-bind="prop.row" v-if="isProduto && isCombo && isComplemento">
      <v-col v-bind="prop.col" cols="6">
        <c-select
          v-model="dataset.main.detail.idUnidadeMedida"
          :items="unidadeMedida"
          label="unidade"
          required
        />
      </v-col>
    </v-row>

    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idUnidadeMedida &&
        getTipo_item(dataset.main.detail.idUnidadeMedida).isAplicar
      "
    >
      <!-- quantidade min -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.quantidadeMinima"
          label="quantidade min"
          :max="13"
          :options="{
            length: 10,
            precision: getTipo_item(dataset.main.detail.idUnidadeMedida).options
              .produtoEstoqueDecimais,
            suffix: getTipo_item(dataset.main.detail.idUnidadeMedida).options
              .abreviado,
          }"
        />
      </v-col>
      <!-- quantidade max -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.quantidadeMaxima"
          label="quantidade max"
          :max="13"
          :options="{
            length: 10,
            precision: getTipo_item(dataset.main.detail.idUnidadeMedida).options
              .produtoEstoqueDecimais,
            suffix: getTipo_item(dataset.main.detail.idUnidadeMedida).options
              .abreviado,
          }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseIdiomas from "@/components/bases/CBaseIdiomas";

export default {
  /// NAME
  name: "CProdutoEdit",

  /// COMPONENTS
  components: {
    CBaseIdiomas,
  },

  /// DATA
  data() {
    return {
      barcodeReader: false,
      dialog: false,
      openCamera: false,
    };
  },

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getVar: "variaveis/getVar",
    }),

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    hasOrigem() {
      return (
        this.dadosParceiro.enderecos[0].idPais ==
          this.getLocalidade("Brasil").id &&
        [
          this.getTipo_item("produtoProduto").id,
          this.getTipo_item("produtoCombo").id,
        ].includes(this.dataset.main.detail.idTipo)
      );
    },

    isCombo() {
      return (
        this.getTipo_item("produtoCombo").id == this.dataset.main.detail.idTipo
      );
    },

    isComplemento() {
      return (
        this.getTipo_item("produtoComplemento").id ==
        this.dataset.main.detail.idTipo
      );
    },

    isProduto() {
      return (
        this.getTipo_item("produtoProduto").id ==
        this.dataset.main.detail.idTipo
      );
    },

    labelCodigoFiscal() {
      return this.getLocalidade(this.dadosParceiro.enderecos[0].idPais).idPai ==
        this.getLocalidade("América do Sul").id &&
        [
          this.getTipo_item("produtoProduto").id,
          this.getTipo_item("produtoCombo").id,
        ].includes(this.dataset.main.detail.idTipo)
        ? "NCM"
        : this.dadosParceiro.enderecos[0].idPais ==
            this.getLocalidade("Brasil").id &&
          this.getTipo_item("produtoServico").id ==
            this.dataset.main.detail.idTipo
        ? "código prestação"
        : null;
    },

    labelCodigoFiscal2() {
      return this.getLocalidade(this.dadosParceiro.enderecos[0].idPais).idPai ==
        this.getLocalidade("América do Sul").id &&
        [
          this.getTipo_item("produtoProduto").id,
          this.getTipo_item("produtoCombo").id,
        ].includes(this.dataset.main.detail.idTipo)
        ? "CEST"
        : this.dadosParceiro.enderecos[0].idPais ==
            this.getLocalidade("Brasil").id &&
          this.getTipo_item("produtoServico").id ==
            this.dataset.main.detail.idTipo
        ? "código tomação"
        : null;
    },

    origens() {
      return this.getTipo_itens(this.getTipo("origemProduto").id).map(
        (item) => {
          return {
            value: item.value,
            text: this.getTipo_item(item.value).codigo,
          };
        }
      );
    },

    unidadeMedida() {
      let unidadesMedidas = [];

      this.getTipo_itens(this.getTipo("unidadeMedida").id)
        .filter(
          (item) => this.getTipo_item(item.value).options.isProdutoUnidade
        )
        .forEach((item) => {
          unidadesMedidas.push({
            value: item.value,
            text: `${this.getTipo_item(item.value).options.abreviado} (${
              item.text
            })`,
          });
        });
      this.getTipo_itens(this.getTipo("unidadeTempo").id)
        .filter(
          (item) => this.getTipo_item(item.value).options.isProdutoUnidade
        )
        .forEach((item) => {
          unidadesMedidas.push({
            value: item.value,
            text: `${this.getTipo_item(item.value).codigo} (${item.text})`,
          });
        });
      this.getTipo_itens(this.getTipo("unidadeComprimento").id)
        .filter(
          (item) => this.getTipo_item(item.value).options.isProdutoUnidade
        )
        .forEach((item) => {
          unidadesMedidas.push({
            value: item.value,
            text: `${this.getTipo_item(item.value).codigo} (${item.text})`,
          });
        });
      this.getTipo_itens(this.getTipo("unidadeMassa").id)
        .filter(
          (item) => this.getTipo_item(item.value).options.isProdutoUnidade
        )
        .forEach((item) => {
          unidadesMedidas.push({
            value: item.value,
            text: `${this.getTipo_item(item.value).codigo} (${item.text})`,
          });
        });
      this.getTipo_itens(this.getTipo("unidadeVolume").id)
        .filter(
          (item) => this.getTipo_item(item.value).options.isProdutoUnidade
        )
        .forEach((item) => {
          unidadesMedidas.push({
            value: item.value,
            text: `${this.getTipo_item(item.value).codigo} (${item.text})`,
          });
        });
      this.getTipo_itens(this.getTipo("unidadeTemperatura").id)
        .filter(
          (item) => this.getTipo_item(item.value).options.isProdutoUnidade
        )
        .forEach((item) => {
          unidadesMedidas.push({
            value: item.value,
            text: `${this.getTipo_item(item.value).codigo} (${item.text})`,
          });
        });

      return unidadesMedidas;
    },
  },

  /// METHODS
  methods: {
    clickBarcode() {
      this.barcodeReader = !this.barcodeReader;
    },

    async codigoBarraChange() {
      if (
        !this.dataset.main.detail.codigoBarra ||
        this.dataset.main.detail.codigoBarra.length < 13
      ) {
        return;
      }

      const complete = 1;
      const dataset = null;
      const idContexto = "*";
      const idFilter = this.getFeature("filterFiltrar").id;
      const idOrder = this.getFeature("orderOrdenar").id;
      const idPessoaParceiro = 0;
      const idUsuario = this.getVar("idUsuario");
      const limit = 100;
      const method = "get";
      const offset = 0;
      const search = this.dataset.main.detail.codigoBarra;
      const url = `${this.getConst("app").baseUrlBackend}/${
        this.pageAlt.nome
      }/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${limit}`;
      const ret = await this.submit(method, url, dataset);
      this.posChangeCodigoBarra(ret);
    },

    getBarCode(code) {
      this.$refs.barCode.focus();
      this.barcodeReader = false;
      this.dataset.main.detail.codigoBarra = code;
      this.$refs.barCode.blur();
      this.$refs.descricao.focus();
    },

    logIt(data) {
      this.dataset.main.detail.codigoBarra = data.codeResult.code;
      this.openCamera = false;
    },

    posChangeCodigoBarra(ret) {
      if (ret.data.length) {
        this.params.recarregar = true;
        const retTemp = ret.data[0];
        this.dataset.main.detail.descricao = retTemp.descricao;
        this.dataset.main.detail.marca = retTemp.marca;
        this.dataset.main.detail.idUnidadeMedida = retTemp.idUnidadeMedida;
        this.dataset.main.detail.pesoBruto = retTemp.pesoBruto;
        this.dataset.main.detail.pesoLiquido = retTemp.pesoLiquido;
        this.dataset.main.detail.volume = retTemp.volume;
        this.dataset.main.detail.largura = retTemp.largura;
        this.dataset.main.detail.altura = retTemp.altura;
        this.dataset.main.detail.profundidade = retTemp.profundidade;
        this.dataset.main.detail.caracteristicas = retTemp.caracteristicas;
        const autocompleteMarca = {
          value: retTemp.marca,
          text: retTemp.marca,
        };
        this.dataset.main.aux.autocomplete.marcas.push(autocompleteMarca);
        setTimeout(() => {
          this.params.recarregar = false;
        }, 10);
      }
    },
  },
};
</script>