<!-- COD: FDS 01/07/2022 CLancamentosContabeis -->
<!-- *VER: JER 05/07/2022 CLancamentosContabeis -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- isEstorno -->
    <v-row v-bind="prop.row" v-if="dataset.isEstorno">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">estorno</v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" v-if="dataset.data" cols="6">
        <span class="text-h6">
          {{ dateFormat(dataset.data, "date") }}
        </span>
      </v-col>
      <!-- valorTotal -->
      <v-col v-bind="prop.col" v-if="dataset.valorTotal" align="end" cols="6">
        <span class="text-h6">
          {{ numberFormat(dataset.valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- dataBalancete -->
    <v-row v-bind="prop.row" v-if="dataset.data != dataset.dataBalancete">
      <v-col v-bind="prop.col">
        <span class="text-body-2">balancete</span>
        <span class="ml-0 text-body-1">
          {{ dateFormat(dataset.dataBalancete, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- historicoPadraoEComplementar -->
    <v-row v-bind="prop.row" v-if="historicoPadraoEComplementar">
      <v-col v-bind="prop.col">
        <span class="text-body-2">
          {{ historicoPadraoEComplementar }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLancamentosContabeis",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  computed: {
    ...mapGetters({
      getHistoricoPadrao: "historicosPadrao/getHistoricoPadrao",
    }),

    historicoComplementar() {
      return this.dataset.historicoComplementar
        ? `- ${this.dataset.historicoComplementar}`
        : "";
    },

    historicoPadrao() {
      return `${
        this.getHistoricoPadrao(this.dataset.idHistoricoPadrao).nomeAlt
      }`;
    },

    historicoPadraoEComplementar() {
      return `${this.historicoPadrao} ${this.historicoComplementar}`;
    },
  },
};
</script>