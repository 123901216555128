<!-- COD: BJB 28/07/2022 Colaboradores_HorasExtras -->
<!-- *VER: JER 08/08/2022 Colaboradores_HorasExtras -->
<!-- /21/ -->

<template>
  <div>
    <c-base-list
      v-if="idPage && idPageAlt"
      :funcs="funcs"
      :hasSearch="false"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :pageModeEdit="true"
    >
      <template #search="{ context }">
        <v-container>
          <!-- search -->
          <v-row v-bind="prop.row" class="mt-n2">
            <v-col v-bind="prop.col">
              <c-text-field
                v-model="search"
                appendOuterIcon="mdi-tune"
                clearable
                :max="100"
                :noAccept="null"
                placeholder="busca"
                prepend-inner-icon="mdi-magnify"
                type="search"
                solo
                @clickAppendOuter="showSearchOptions = !showSearchOptions"
                @keyup.enter="
                  $emit('search', search);
                  $event.target.blur();
                "
              />
            </v-col>
          </v-row>

          <!-- gestorImediato -->
          <v-row v-bind="prop.row" class="mt-n2">
            <v-col v-bind="prop.col">
              <c-select
                v-model="gestorImediato"
                clearable
                disabled
                :items="gestoresImediatos"
                label="gestor imediato"
              />
            </v-col>
          </v-row>

          <!-- idColaboradores -->
          <v-row v-bind="prop.row" class="mt-n2">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idColaboradores"
                clearable
                :items="colaboradores"
                label="colaboradores"
                multiple
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" v-show="showSearchOptions">
            <!-- searchData.inicio -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="searchData.inicio"
                classe="text-body-1 pr-1"
                clearable
                label="início"
                date
                icon
                solo
                @input="$emit('searchData', searchData)"
              />
            </v-col>
            <!-- searchData.fim -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="searchData.fim"
                classe="text-body-1 pl-1"
                clearable
                label="fim"
                date
                icon
                solo
                @input="$emit('searchData', searchData)"
              />
            </v-col>
          </v-row>

          <!-- soAutorizacaoEmVigor -->
          <v-row v-bind="prop.row" v-show="showSearchOptions">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="soAutorizacaoEmVigor"
                label="só autorizações em vigor"
              />
            </v-col>
          </v-row>

          <!-- soAutorizacaoEmVigor -->
          <v-row v-bind="prop.row" v-show="showSearchOptions">
            <v-col v-bind="prop.col">
              <c-btn
                block
                classe="mb-4 mr-8 mt-n2 primary white--text"
                label="buscar"
                large
                @click="clickSearch(context)"
              >
                buscar
              </c-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot="{ context, dataset, index }">
        <c-colaborador-page-horaExtra-edit
          v-if="!dataset.del"
          :context="context"
          :horaExtra="dataset"
          :index="index"
        />
      </template>

      <template #insert>
        <c-base-panel-insert
          class="ml-n3"
          :datasets="[]"
          :panel="panel"
          @incluir="showDialog = true"
        />
      </template>
    </c-base-list>

    <c-base-dialog-horasExtras
      v-if="params.context"
      :colaboradores="colaboradores"
      :context="params.context"
      :show="showDialog"
      @close="showDialog = false"
      @incluido="afterSave(params.context)"
    />
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogHorasExtras from "@/components/bases/CBaseDialogHorasExtras";
import CBasePanelInsert from "@/components/bases/CBasePanelInsert";
import CColaboradorPageHoraExtraEdit from "@/components/pages/colaborador/pages/CColaboradorPageHoraExtraEdit";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Produtos_Gerenciar",

  /// COMPONENTS
  components: {
    CBaseDialogHorasExtras,
    CBasePanelInsert,
    CColaboradorPageHoraExtraEdit,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getUsuario = this.$store.getters["variaveis/getUsuario"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idFeature: getFeature("optionHorasExtras").id,
      idPage: getPagina("colaboradores").id,
      idPageAlt: getPagina("colaborador").id,

      funcs: {
        changed: (context) => {
          const cleanDatas = ["data", "dataInicio", "dataFim"];
          const cleanHoras = ["horaInicio", "horaFim", "horasExtras"];

          return (
            context.datasets.length !== context.datasetsTemp.length ||
            context.datasets.some((dataset, i) =>
              Object.keys(dataset).some((key) =>
                cleanDatas.includes(key)
                  ? dataset[key].toString().substring(0, 10) !=
                    context.datasetsTemp[i][key].toString().substring(0, 10)
                  : cleanHoras.includes(key)
                  ? dataset[key].toString().substring(0, 5) !=
                    context.datasetsTemp[i][key].toString().substring(0, 5)
                  : dataset[key] != context.datasetsTemp[i][key]
              )
            )
          );
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        async clickSave(context) {
          const metadados = {
            modo: context.options.click.getConst("config").modo.cadastro.id,
            idUsuario: context.options.click.getVar("idUsuario"),
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
          };

          const cleanDatas = ["data", "dataInicio", "dataFim"];
          const cleanHoras = ["horaInicio", "horaFim", "horasExtras"];

          const datasetsSave = {
            metadados,
            details: context.options.click.datasets.filter((dataset, i) =>
              Object.keys(dataset).some((key) =>
                cleanDatas.includes(key)
                  ? dataset[key].toString().substring(0, 10) !=
                    context.options.click.datasetsTemp[i][key]
                      .toString()
                      .substring(0, 10)
                  : cleanHoras.includes(key)
                  ? dataset[key].toString().substring(0, 5) !=
                    context.options.click.datasetsTemp[i][key]
                      .toString()
                      .substring(0, 5)
                  : dataset[key] != context.options.click.datasetsTemp[i][key]
              )
            ),
          };

          if (
            context.options.click.datasets.length !==
            context.options.click.datasetsTemp.length
          ) {
            const idsDataset = context.options.click.datasets.map(
              (dataset) => dataset.id
            );

            const deleted = context.options.click.datasetsTemp
              .map((datasetTemp) => {
                if (!idsDataset.includes(datasetTemp.id)) {
                  return {
                    ...datasetTemp,
                    del: true,
                  };
                }
              })
              .find((datasetDeleted) => datasetDeleted);

            datasetsSave.details.push(deleted);
          }

          if (datasetsSave.details.length) {
            datasetsSave.details = datasetsSave.details.map((dataset) => {
              return {
                id: dataset.id,
                dataInicio: dataset.dataInicio,
                dataFim: dataset.dataFim,
                horaInicio: dataset.horaInicio,
                horaFim: dataset.horaFim,
                horasExtras: dataset.horasExtras,
                data: dataset.data,
                idPessoaAprovador: metadados.idUsuario,
                observacao: dataset.observacao,
                idPai: dataset.idPai,
                del: dataset.del,
              };
            });

            const method = "put";
            let url = `${
              context.options.click.getConst("app").baseUrlBackend
            }/${context.options.click.page.nome}/all`;

            const res = await context.options.click.submit(
              method,
              url,
              datasetsSave
            );

            if (res) {
              context.options.click.clickSearch();
            }
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          let params = null;
          params = {
            idColaboradores: this.idColaboradores,
            datas: this.searchData,
            soAutorizacaoEmVigor: this.soAutorizacaoEmVigor,
          };

          return JSON.stringify(params);
        },

        posLoad: (context) => {
          this.colaboradores = context.datasetList.dataset.aux.colaboradores;
        },

        posSubmit: (dataset, context) => {
          this.params.context = context;
          dataset.dataInicio = dataset.dataInicio.substring(0, 10);
          dataset.dataFim = dataset.dataFim.substring(0, 10);
        },
      },

      params: {
        context: null,
      },

      colaboradores: [],
      gestoresImediatos: [
        {
          value: getVar("idUsuario"),
          text: getUsuario(getVar("idUsuario")).pessoa.nomeAlt,
        },
      ],
      gestorImediato: getVar("idUsuario"),
      idColaboradores: [],
      isUsuarioGestor: !!getUsuario(getVar("idPessoaParceiro")).gestores.find(
        (gestor) => gestor.value == getVar("idUsuario")
      ),
      search: "",
      searchData: {
        inicio: null,
        fim: null,
      },
      showDialog: false,
      showSearchOptions: false,
      soAutorizacaoEmVigor: false,
    };
  },

  /// COMPUTED
  computed: {
    panel() {
      return this.getFeature("optionHorasExtras");
    },
  },

  /// METHODS
  methods: {
    afterSave(context) {
      this.showDialog = false;
      this.clickSearch(context);
    },

    clickSearch(context) {
      context.datasets = [];
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },
  },
};
</script>