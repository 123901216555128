<!-- COD: LYF 26/07/2022 Produtos_Inventario -->
<!-- *VER: JER 28/07/2022 Produtos_Inventario -->
<!-- /32/ -->

<template>
  <v-form id="formSelect" class="mb-10 pa-0" ref="form">
    <c-base-list
      v-if="idPage && idPageAlt"
      :funcs="funcs"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :pageModeEdit="true"
    >
      <template #header="{ context }">
        <v-container>
          <!-- localizacao -->
          <v-row v-bind="prop.row" v-if="!reloadLocalizacoes">
            <v-col v-bind="prop.col">
              <c-autocomplete
                v-model="localizacao"
                :arraySearch="localizacoes"
                buscaIncluir
                :itemsIniciais="localizacaoInicial"
                label="localização"
                :rules="[]"
                :searchLength="2"
                @change="changeLocalizacao(context)"
              />
            </v-col>
          </v-row>

          <!-- produto -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-autocomplete
                v-model="produto"
                :itemsIniciais="[]"
                label="produto"
                :path="`/produtos/autocomplete/produtos/${getVar(
                  'idPessoaParceiro'
                )}/${getVar('locale')}/0/`"
                returnObject
                :rules="[]"
                :searchLength="2"
              />
            </v-col>
          </v-row>

          <!-- incluir -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <c-btn
                class="primary--text"
                :disabled="!localizacao || !produto"
                text
                @click="clickIncluir(context)"
              >
                incluir
              </c-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #listContent="{ context, datasets }">
        <c-expansion-panels flat :pageModeView="false" :value="datasets">
          <template #header="{ dataset: produto }">
            <!-- produto -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <span class="text-body-1">
                  {{ produtoFormat(produto, false) }}
                </span>
              </v-col>
            </v-row>
            <!-- inventario -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <span class="text-body-2">inventário</span>
                <span class="ml-0 text-body-1">
                  {{
                    parseFloat(produto.caixas) * produto.unidadesPorCaixa +
                      parseFloat(produto.unidades) || "0"
                  }}
                </span>
              </v-col>
            </v-row>
          </template>

          <template #content="{ dataset: produto }">
            <c-produto-page-inventario-edit
              v-if="!pageModeView"
              :context="context"
              :dataset="datasets"
              :produto="produto"
              :showColaboradores="showColaboradores"
            />
          </template>
        </c-expansion-panels>
      </template>

      <template #search="{ context }">
        <!-- inventariadas -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="inventariadas"
              label="mostrar só localizações inventáriadas"
              @click="clickInventariadas(context)"
            />
          </v-col>
        </v-row>

        <!-- pendentes -->
        <v-row v-bind="prop.row" v-if="parceiro.isInventario">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="pendentes"
              label="mostrar só localizações pendentes"
              @click="clickPendentes(context)"
            />
          </v-col>
        </v-row>

        <!-- arrow -->
        <v-row v-if="!inventariadas && localizacao" v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <c-btn
              v-if="context.datasets.length > 0 && idDirecao"
              fab
              icon
              @click="clickArrow(context)"
            >
              <v-icon color="primary">
                {{ iconArrow }}
              </v-icon>
            </c-btn>
          </v-col>
        </v-row>
      </template>
    </c-base-list>
  </v-form>
</template>

<script>
/// IMPORT
import CProdutoPageInventarioEdit from "@/components/pages/produto/pages/CProdutoPageInventarioEdit";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Produtos_Inventario",

  /// COMPONENTS
  components: {
    CProdutoPageInventarioEdit,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idFeature: getFeature("optionInventario").id,
      idPage: getPagina("produtosInventario").id,
      idPageAlt: getPagina("produtos").id,

      funcs: {
        adjustOptions: (options, context) => {
          const feature = this.parceiro.isInventario
            ? "optionIniciar"
            : "optionFinalizar";

          return options.filter((option) => {
            return option.id != context.getFeature(feature).id;
          });
        },

        changed: (context) => {
          return context.datasets.some(
            (dataset, i) =>
              !dataset.id ||
              dataset.caixas != context.datasetsTemp[i].caixas ||
              dataset.unidades != context.datasetsTemp[i].unidades
          );
        },

        clickCancel: (context) => {
          context.options.click.datasetsTemp =
            context.options.click.datasetsTemp.filter((ds) => ds.id);
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        clickDiferencas: (context) => {
          const url = "/produtos/inventario/diferencas";
          context.$router.push(encodeURI(url));
        },

        clickFinalizar: async (context) => {
          await context.setSnackbar(
            true,
            "INVENTÁRIO",
            "Finalizar inventário?",
            "warning",
            "mdi-check",
            "inventario",
            null,
            false
          );
          const resSnackbar = !!context.snackbar.params;
          context.setSnackbar();

          if (resSnackbar) {
            const method = "put";
            const idPessoaParceiro = this.getVar("idPessoaParceiro");
            let url = `${this.getConst("app").baseUrlBackend}/${
              context.page.nome
            }/finalizar/${idPessoaParceiro}`;

            const res = await context.submit(method, url);

            if (res) {
              const data = res.data;

              if (data.erro) {
                context.setSnackbar(true, "INVENTÁRIO", data.mensagem, "error");

                let interval = setInterval(() => {
                  if (!context.snackbar.show) {
                    clearInterval(interval);
                    interval = null;

                    if (data.erro == "diferencas") {
                      url = "/produtos/inventario/diferencas";
                      context.$router.push(encodeURI(url));
                    } else {
                      this.localizacao = null;
                      this.pendentes = true;
                      this.reloadParams(context);
                    }
                  }
                }, 10);
              } else {
                this.parceiro.isInventario = false;
                context.page.hasData = !this.parceiro.isInventario;

                context.setSnackbar(
                  true,
                  "INVENTÁRIO",
                  "inventário finalizado",
                  "success"
                );

                context.clickSearch();
              }
            }
          }
        },

        clickIniciar: async (context) => {
          await context.setSnackbar(
            true,
            "INVENTÁRIO",
            "Iniciar inventário?",
            "warning",
            "mdi-check",
            "inventario",
            null,
            false
          );
          const resSnackbar = !!context.snackbar.params;
          context.setSnackbar();

          if (resSnackbar) {
            const method = "put";
            const idPessoaParceiro = this.getVar("idPessoaParceiro");
            let url = `${this.getConst("app").baseUrlBackend}/${
              context.page.nome
            }/iniciar/${idPessoaParceiro}`;

            const res = await context.submit(method, url);

            if (res) {
              const data = res.data;

              if (!data.erro) {
                this.parceiro.isInventario = true;
                context.page.hasData = !this.parceiro.isInventario;

                context.setSnackbar(
                  true,
                  "INVENTÁRIO",
                  "inventário iniciado",
                  "success"
                );

                context.clickSearch();
              }
            }
          }
        },

        clickSave: async (context) => {
          if (!this.$refs.form.validate()) {
            context.options.click.setSnackbar(
              true,
              "CAMPOS INVÁLIDOS",
              "Verificar campos inválidos.",
              "error",
              "",
              "depositarErro",
              this.getConst("config").snackbar.timeout,
              false
            );

            let interval = setInterval(() => {
              if (!context.options.click.snackbar.show) {
                clearInterval(interval);
                interval = null;
              }
            }, 100);

            return null;
          }

          const metadados = {
            modo: context.options.click.getConst("config").modo.cadastro.id,
            idUsuario: context.options.click.getVar("idUsuario"),
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
          };

          const datasetsSave = {
            metadados,
            details: context.options.click.datasets.filter(
              (dataset, i) =>
                !dataset.id ||
                dataset.caixas !=
                  context.options.click.datasetsTemp[i].caixas ||
                dataset.unidades !=
                  context.options.click.datasetsTemp[i].unidades
            ),
          };

          if (datasetsSave.details.length) {
            datasetsSave.details = datasetsSave.details.map((dataset) => {
              return {
                id: dataset.id,
                idPai: dataset.idPai || dataset.idProduto,
                localizacao: dataset.localizacao,
                caixas: parseInt(dataset.caixas),
                unidades: parseFloat(dataset.unidades),
                idColaborador: !datasetsSave.details.some(
                  (detail) => !detail.caixas && !detail.unidades
                )
                  ? context.options.click.getVar("idUsuario")
                  : null,
              };
            });

            const method = "put";
            let url = `${
              context.options.click.getConst("app").baseUrlBackend
            }/${context.options.click.page.nome}`;
            const res = await context.options.click.submit(
              method,
              url,
              datasetsSave
            );

            if (res) {
              context.options.click.clickSearch();
            }
          }
        },

        clickSnackbar: (action, context) => {
          if (action == "inventario") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: async () => {
          const params = {};
          params.inventario = true;

          if (this.localizacao) {
            params.localizacao = this.localizacao;
          } else {
            params.localizacao = null;
          }

          if (this.pendentes && this.parceiro.isInventario) {
            params.pendentes = this.pendentes;
          }

          if (this.inventariadas && this.parceiro.isInventario) {
            params.inventariadas = this.inventariadas;
          }

          params.idPessoaParceiro = this.getVar("idPessoaParceiro");
          params.idColaborador = this.getVar("idUsuario");
          params.idParceiro = this.parceiro.id;
          params.idDirecao = this.idDirecao;
          params.isAscendentePrimario = this.isAscendentePrimario;
          params.isAscendenteSecundario = this.isAscendenteSecundario;

          return JSON.stringify(params);
        },

        posLoad: (context) => {
          this.context = context;
        },

        posSubmit: async (dataset, context, datasets, index) => {
          if (index == 0) {
            context.page.nomeAjuda = context.pageAlt.nome;
          }

          if (index == 0) {
            const idDirecaoInventario = dataset.idDirecaoInventario;
            this.isAscendentePrimario = dataset.isAscendentePrimario;
            this.isAscendenteSecundario = dataset.isAscendenteSecundario;

            if (this.changedDirection) {
              this.idDirecao = this.getTipo_item(idDirecaoInventario).id;
              this.changedDirection = false;
            }

            this.iconArrow =
              this.getTipo_item(idDirecaoInventario)?.options?.icon;
            this.reloadLocalizacao(dataset);
          }
        },

        showColaboradores: () => {
          return this.showColaboradores;
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },

      changedDirection: true,
      context: null,
      iconArrow: null,
      idDirecao: null,
      inventariadas: false,
      isAscendentePrimario: null,
      isAscendenteSecundario: null,
      localizacao: null,
      localizacaoInicial: [],
      parceiro: getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == getVar("idPessoaParceiro")
      ).parceiro,
      pendentes: true,
      produto: null,
      reloadLocalizacoes: false,
      showColaboradores: false,
    };
  },

  /// COMPUTED
  computed: {
    localizacoes() {
      return this.parceiro.localizacoes.map((localizacao) => {
        return {
          value: localizacao,
          text: localizacao,
        };
      });
    },

    pageModeView() {
      return false;
    },
  },

  /// ACTIVATED
  activated() {
    this.setVar({ overlay: false });
  },

  /// BEFOREMOUNT
  beforeMount() {
    const page = this.getPagina(this.idPage);
    page.hasData = !this.parceiro.isInventario;
    page.noPaginacao = true;
    page.injectNext = true;
  },

  /// METHODS
  methods: {
    changeLocalizacao(context) {
      this.reloadParams(context);
    },

    clickArrow() {
      this.changedDirection = true;
      const direcao = this.getTipo_item(this.idDirecao);
      const direcaoProxima = this.getTipo_item(direcao.options.next);

      this.isAscendentePrimario = direcaoProxima.options.isAscendente;
      this.isAscendenteSecundario = direcaoProxima.options.isAscendente;
      this.iconArrow = direcaoProxima.options.icon;
      this.idDirecao = direcaoProxima.id;
    },

    clickIncluir(context) {
      const localizacao = this.localizacao;

      const dataset = {
        produtoFormatado: this.produto.text,
        idPai: this.produto.value,
        localizacao,
        caixas: null,
        unidades: null,
        unidadesPorCaixa: this.produto.unidadesPorCaixa,
        idUnidadeMedida: this.produto.idUnidadeMedida,
      };

      this.produto = null;

      context.datasets = context.datasets || [];
      context.datasetsTemp = context.datasetsTemp || [];
      context.datasets.unshift(dataset);
      context.datasetsTemp.unshift(dataset);

      setTimeout(() => {
        dataset.localizacao = localizacao;
      }, 10);
    },

    clickInventariadas(context) {
      if (this.inventariadas) {
        this.pendentes = false;
        this.localizacao = null;
      } else {
        this.pendentes = true;
      }

      this.showColaboradores = this.inventariadas || !this.pendentes;
      this.reloadParams(context);
    },

    clickPendentes(context) {
      if (!this.pendentes) {
        this.localizacao = null;
      } else {
        this.inventariadas = false;
      }

      context.page.injectNext = this.pendentes;
      this.reloadParams(context);
    },

    async reloadParams(context) {
      context.params = await context.funcs.fnParams(context);
      context.clickSearch();
    },

    reloadLocalizacao(dataset) {
      if (this.pendentes) {
        this.reloadLocalizacoes = true;

        this.localizacaoInicial[0] = {
          value: dataset.localizacao,
          text: dataset.localizacao,
        };

        this.localizacao = dataset.localizacao;
        setTimeout(() => (this.reloadLocalizacoes = false), 10);
      }
    },
  },
};
</script>