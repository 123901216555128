<!-- COD: LYF 06/07/2022 PontoEletronico -->
<!-- *VER: JER 08/07/2022 PontoEletronico -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-pontoEletronico-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
            :params="params"
          />
          <c-pontoEletronico-edit
            v-else
            :context="context"
            :dataset="dataset"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CPontoEletronicoEdit from "@/components/pages/pontoEletronico/CPontoEletronicoEdit";
import CPontoEletronicoView from "@/components/pages/pontoEletronico/CPontoEletronicoView";
import mixinData from "@/mixins/mixinData";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "PontoEletronico",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CPontoEletronicoEdit,
    CPontoEletronicoView,
  },

  /// MIXINS
  mixins: [mixinData, mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("pontoEletronico").id,
      idPageAlt: getPagina("pontosEletronicos").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("pontoEletronico").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            dataInicio: null,
            dataFim: null,
            toleranciaAtraso: null,
            toleranciaHoraExtra: null,
            funcionarios: null,
            horasNormais: null,
            horasExtras: null,
            faltas: null,
            atrasos: null,
            abonos: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          datas: [],
          marcacoes: [],
          apontamentos: [],
          aprovacoes: [],
        },
        aux: {
          detail: {},
        },
      },

      funcs: {
        clickApontamentos: () => {
          this.$router.push(
            `/pontoEletronico/PontoEletronico_Apontamentos/${this.dataset.main.detail.id}`
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posSubmit: (context) => {
          context.params.context = context;
        },
      },

      params: {
        context: this,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>