<!-- COD: BJB 01/09/2022 CTooltip -->
<!-- *VER: JER 08/09/2022 CTooltip -->
<!-- /11/ -->

<template>
  <v-tooltip
    v-bind="$attrs"
    bottom
    :close-delay="closeDelay || getConst('config').tooltip.timeout"
    :open-on-focus="false"
    :open-on-hover="false"
  >
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <span class="text-caption">{{ texto }}</span>
  </v-tooltip>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CTooltip",

  /// PROPS
  props: {
    texto: { type: String, required: true },
    closeDelay: { type: [String, Number] },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
    }),
  },
};
</script>