<!-- COD: FDS 29/07/2022 CFolhaPagamentoPageVerbaEdit -->
<!-- *VER: JER 07/08/2022 CFolhaPagamentoPageVerbaEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- valor -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">valor</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="verba.valor"
          label=""
          :max="15"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
    </v-row>

    <!-- valor base -->
    <v-row v-bind="prop.row" v-if="verba.valorBase">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">base</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(verba.valorBase, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- multiplicador -->
    <v-row v-bind="prop.row" v-if="verba.multiplicador">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">multiplicador</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(verba.multiplicador) }}{{ verba.unidade }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row" v-if="verba.data">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">data</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(verba.data, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- referencia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field v-model="verba.referencia" label="referência" :max="40" />
      </v-col>
    </v-row>

    <!-- pessoa favorecida -->
    <v-row v-bind="prop.row" v-if="verba.pessoaFavorecida_nome">
      <v-col v-bind="prop.col" align="center">
        <v-icon> mdi-arrow-right </v-icon>
        <span class="text-body-1">
          {{ verba.pessoaFavorecida_nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhaPagamentoPageVerbaEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    verba: { type: Object, required: true },
  },
};
</script>