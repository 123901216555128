<!-- COD: FDS 04/07/2022 CLancamentoContabilView -->
<!-- *VER: JER 05/07/2022 CLancamentoContabilView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- isEstorno -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.isEstorno">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">estorno</v-chip>
      </v-col>
    </v-row>

    <!-- valorTotal -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.valorTotal">
      <v-col v-bind="prop.col" align="center">
        <span class="font-weight-medium text-body-1">
          {{ numberFormat(dataset.main.detail.valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.data">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.data, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- dataBalancete -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.data != dataset.main.detail.dataBalancete"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">balancete</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataBalancete, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- idHistoricoPadrao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idHistoricoPadrao">
      <v-col v-bind="prop.col" align="center">
        <a class="text-body-1" @click="clickHistoricoPadrao()">
          {{ historicoPadraoEComplementar }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLancamentoContabilView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getHistoricoPadrao: "historicosPadrao/getHistoricoPadrao",
    }),

    historicoPadraoEComplementar() {
      return `${
        this.getHistoricoPadrao(this.dataset.main.detail.idHistoricoPadrao)
          .nomeAlt
      } ${
        this.context.dataset.aux.detail.historicoComplementar
          ? `- ${this.context.dataset.aux.detail.historicoComplementar}`
          : ""
      }`;
    },
  },

  /// METHODS
  methods: {
    clickHistoricoPadrao() {
      this.$router.push(
        `/historicoPadrao/${this.dataset.main.detail.idHistoricoPadrao}`
      );
    },
  },
};
</script>