<!-- COD: FDS 05/07/2022 Feedback -->
<!-- *VER: JER 19/07/2022 Feedback -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-feedback-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-feedback-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CFeedbackEdit from "@/components/pages/feedback/CFeedbackEdit";
import CFeedbackView from "@/components/pages/feedback/CFeedbackView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Feedback",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CFeedbackEdit,
    CFeedbackView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("feedback").id,
      idPageAlt: getPagina("feedbacks").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("aberto").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            data: null,
            idUsuario: getVar("idUsuario"),
            idPagina: null,
            titulo: "",
            descricao: null,
            resposta: {},
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
        },
        aux: {
          detail: {
            pessoaParceiro: null,
            pagina: null,
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posSubmit: (context) => {
          context.dataset.main.detail.idUsuario = this.getVar("idUsuario");
        },
      },

      params: {
        context: this,
        escolheTipo: false,
        recarregar: false,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>