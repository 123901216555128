<!-- COD: LYF 01/07/2022 CParceiros -->
<!-- *VER: JER 03/07/2022 CParceiros -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- imagem -->
      <v-col v-bind="prop.col" v-if="context.isBuscaLayout" cols="2">
        <c-base-image
          :avatar="dataset.idTipo == getTipo_item('pessoaFisica').id"
          :endereco="dataset.anexo_endereco"
          height="60"
        />
      </v-col>

      <v-col v-bind="prop.col">
        <!-- nome -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-h6">
              {{ dataset.pessoa_nome }}
            </span>
          </v-col>
        </v-row>

        <!-- nomeAlt -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-1">
              {{ dataset.pessoa_nomeAlt }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CParceiros",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>