<!-- COD: FDS 01/07/2022 Extratos -->
<!-- *VER: JER 04/07/2022 Extratos -->
<!-- /11/ -->

<template>
  <v-container>
    <c-base-list
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      @showSearchOptions="showSearchOptions = $event"
    >
      <template #searchInSearch="{ context }">
        <v-container v-bind="prop.container" v-if="showSearchOptions">
          <v-row v-bind="prop.row" class="mt-n6">
            <v-col v-bind="prop.col">
              <c-select
                v-model="params.idPessoaParceiroContaBancaria"
                clearable
                :items="contasBancarias"
                label="contas"
                :properties="{ image: true }"
                solo
                @change="context.clickSearch()"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot="{ dataset, order }">
        <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
          <c-extratos :dataset="dataset" />
        </c-base-list-item>
      </template>
    </c-base-list>

    <c-extrato-dialog-incluir-extrato
      v-if="showIncluirExtrato"
      @close="closeIncluirExtrato"
      @incluirExtrato="extratosAutomaticos($event)"
    />
  </v-container>
</template>

<script>
/// IMPORT
import CExtratos from "@/components/pages/extrato/CExtratos";
import CExtratoDialogIncluirExtrato from "@/components/pages/extrato/dialogs/CExtratoDialogIncluirExtrato";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Extratos",

  /// COMPONENTS
  components: {
    CExtratos,
    CExtratoDialogIncluirExtrato,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("extratos").id,
      idPageAlt: getPagina("extrato").id,
      context: null,
      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "confirmIncluir") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          }
        },

        clickIncluir: async (context) => {
          this.context = context;
          this.showIncluirExtrato = true;
        },

        fnParams: () => {
          return JSON.stringify(this.params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      params: {
        idPessoaParceiroContaBancaria: null,
      },

      showIncluirExtrato: false,
      showSearchOptions: false,
    };
  },

  computed: {
    contasBancarias() {
      const parceiro = this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      );

      return (parceiro?.contas || []).map((contaBancaria) => {
        const banco = this.getBanco(contaBancaria.idBanco);

        return {
          value: contaBancaria.id,
          text: this.contaBancariaFormat(contaBancaria),
          image: banco.endereco,
        };
      });
    },
  },

  /// ACTIVATED
  activated() {
    if (this.$route.query.from.name == "Extrato_Conciliacoes") {
      this.showIncluirExtrato = true;
    }
  },

  /// METHODS
  methods: {
    async closeIncluirExtrato() {
      this.showIncluirExtrato = false;
      await this.context.clickSearch();
    },

    async extratosAutomaticos(body) {
      const method = "post";
      const url = `${
        this.context.getConst("app").baseUrlBackend
      }/extratos/extratosAutomaticos`;

      let formData = new FormData();
      formData.append("extrato", JSON.stringify(body));

      if (body.file) {
        formData.append("file", body.file);
      }

      await this.context.submit(method, url, formData);
      this.showIncluirExtrato = false;
      this.context.clickSearch();
    },
  },
};
</script>