// COD: BJB 15/08/2022 localidades
// *VER: JER 19/08/2022 localidades
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    localidades: [],
    localidades_codigosAreas: [],
    localidades_codigosPostais: [],
    localidades_configuracoesRegionais: [],
    localidades_idiomas: [],
    localidades_moedas: []
  },

  /// GETTERS
  getters: {
    getLocalidades: state => (idTipo, idPai, id, prop = "nome") => {
      return state.localidades
        .filter(element => element.idTipo == idTipo)
        .filter(element => element.idPai == (idPai || element.idPai))
        .filter(element => element.id == (id || element.id))
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nome,
            idEstado: element.idEstado,
            image: element.endereco,
            hasParceiros: element.hasParceiros ?? null
          };
        });
    },

    getLocalidade: state => search => {
      return state.localidades.length
        ? state.localidades
          .find(element => element.id == search || (search && element.nome.toLowerCase() == search.toString().toLowerCase()))
        : { search }
    },

    getLocalidade_codigosAreas: state => (id, prop = "id") => {
      return state.localidades_codigosAreas
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.codigo,
            text: element.codigo
          };
        });
    },

    getLocalidade_codigosPostais: state => (id, prop = "id") => {
      return state.localidades_codigosPostais
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.codigoInicio,
            text: element.codigoInicio
          };
        });
    },

    getLocalidades_codigoPostal: (state, getters) => (codigoPostal, idPais, prop = "id") => {
      return state.localidades_codigosPostais
        .filter(element => !idPais || element.idPais == idPais)
        .filter(element => element.codigoInicio <= codigoPostal)
        .filter(element => element.codigoFim >= codigoPostal)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.idPai,
            text: getters.getLocalidade(element.idPai).nome,
            codigoInicio: element.codigoInicio,
            codigoFim: element.codigoFim,
            idTipo: element.idTipo,
          };
        });
    },

    getLocalidade_configuracoesRegionais: (state, getters, rootState, rootGetters) => (id, idTipo, idTipoPessoa, prop = "id") => {
      let configuracoesRegionais = state.localidades_configuracoesRegionais
        .filter(element => element.idPai == id
          && element.idTipo == (idTipo || element.idTipo)
          && element.idTipoPessoa == (idTipoPessoa || element.idTipoPessoa))
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)

      if (!configuracoesRegionais.length) {
        const localidadePai = getters.getLocalidade(getters.getLocalidade(id)?.idPai)

        const tipos = [
          rootGetters["tipos/getTipo_item"]("localidadePais").id,
          rootGetters["tipos/getTipo_item"]("localidadeEstado").id,
          rootGetters["tipos/getTipo_item"]("localidadeCidade").id
        ]

        if (localidadePai && tipos.includes(localidadePai.idTipo)) {
          configuracoesRegionais = getters.getLocalidade_configuracoesRegionais(localidadePai.id, idTipo, idTipoPessoa, prop)
        }
      }

      return configuracoesRegionais
    },

    getLocalidade_idiomas: (state, getters, rootState, rootGetters) => (id, prop = "id") => {
      return state.localidades_idiomas
        .filter(element => element.idPai == id || !id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: rootGetters["tipos/getTipo_item"](element.idIdioma).nomeAlt
          };
        });
    },

    getLocalidade_idioma: (state) => id => {
      return state.localidades_idiomas.length
        ? state.localidades_idiomas
          .find(element => element.id == id)
        : { id }
    },

    getLocalidade_moedas: (state, getters, rootState, rootGetters) => (id, prop = "id") => {
      return state.localidades_moedas
        .filter(element => element.idPai == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.idMoeda,
            text: rootGetters["tipos/getTipo_item"](element.idMoeda).nomeAlt,
            isOficial: element.isOficial,
          };
        });
    }
  },

  /// MUTATIONS
  mutations: {
    setLocalidades: (state, payload) => {
      state.localidades = []
      payload.forEach(element => {
        state.localidades.push(element)
      });
    },

    setLocalidades_codigosAreas: (state, payload) => {
      state.localidades_codigosAreas = []
      payload.forEach(element => {
        state.localidades_codigosAreas.push(element)
      });
    },

    setLocalidades_codigosPostais: (state, payload) => {
      state.localidades_codigosPostais = []
      payload.forEach(element => {
        state.localidades_codigosPostais.push(element)
      });
    },

    setLocalidades_configuracoesRegionais: (state, payload) => {
      state.localidades_configuracoesRegionais = []
      payload.forEach(element => {
        state.localidades_configuracoesRegionais.push(element)
      });
    },

    setLocalidades_idiomas: (state, payload) => {
      state.localidades_idiomas = []
      payload.forEach(element => {
        state.localidades_idiomas.push(element)
      });
    },

    setLocalidades_moedas: (state, payload) => {
      state.localidades_moedas = []
      payload.forEach(element => {
        state.localidades_moedas.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setLocalidades: (context, payload) => {
      context.commit("setLocalidades", payload)
    },

    setLocalidades_codigosAreas: (context, payload) => {
      context.commit("setLocalidades_codigosAreas", payload)
    },

    setLocalidades_codigosPostais: (context, payload) => {
      context.commit("setLocalidades_codigosPostais", payload)
    },

    setLocalidades_configuracoesRegionais: (context, payload) => {
      context.commit("setLocalidades_configuracoesRegionais", payload)
    },

    setLocalidades_idiomas: (context, payload) => {
      context.commit("setLocalidades_idiomas", payload)
    },

    setLocalidades_moedas: (context, payload) => {
      context.commit("setLocalidades_moedas", payload)
    }
  }
}