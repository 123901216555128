var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{attrs:{"funcs":_vm.funcs,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
var page = ref.page;
var pageModeView = ref.pageModeView;
return [(_vm.dataset.main.detail.idTipo == 0)?_c('c-base-detail-insert',{attrs:{"dataset":_vm.dataset,"idTipo":_vm.getTipo(("tipo" + (page.nome.firstLetterToUpperCase()))).id}}):_c('div',[(_vm.dataset.main.detail.id && context.showEstado)?_c('c-base-dialog-estado',{attrs:{"context":context,"datasetTemp":JSON.parse(JSON.stringify(context.dataset)),"idEstadoNext":context.idEstadoNext,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"isEstadoCancelar":context.isEstadoCancelar,"params":_vm.paramsCamposEspecificos(),"show":context.showEstado,"value":JSON.parse(JSON.stringify(context.dataset))},on:{"alterado":function($event){return _vm.alterado(context)},"close":function($event){context.showEstado = false;
            context.idEstadoNext = null;
            context.isEstadoCancelar = false;}},scopedSlots:_vm._u([{key:"camposEspecificos",fn:function(ref){
            var datasetTemp = ref.datasetTemp;
return [(
                _vm.getTipo_item("pedidoEntrada").id ==
                  _vm.dataset.main.detail.idTipo &&
                _vm.dataset.main.detail.idEstado == _vm.getEstado('faturamento').id &&
                datasetTemp.main.detail.idEstado ==
                  _vm.getEstado('recebimento').id
              )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nota fiscal","max":25},model:{value:(_vm.params.notaFiscal),callback:function ($$v) {_vm.$set(_vm.params, "notaFiscal", $$v)},expression:"params.notaFiscal"}})],1),_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{staticClass:"ml-2",attrs:{"id":"serie","label":"série","max":1},model:{value:(_vm.params.notaFiscal_serie),callback:function ($$v) {_vm.$set(_vm.params, "notaFiscal_serie", $$v)},expression:"params.notaFiscal_serie"}})],1),_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"ml-2 pr-1 text-body-1","clearable":"","date":"","label":"data emissão"},model:{value:(_vm.params.notaFiscal_data),callback:function ($$v) {_vm.$set(_vm.params, "notaFiscal_data", $$v)},expression:"params.notaFiscal_data"}})],1)],1):_vm._e()]}}],null,true)}):_vm._e(),(pageModeView)?_c('c-pedido-view',{attrs:{"context":context,"dataset":_vm.dataset}}):_c('c-pedido-edit',{attrs:{"context":context,"dataset":_vm.dataset}})],1),(_vm.dataset.main.detail.id)?_c('c-pedido-dialog-pedido',{attrs:{"pedido":_vm.dataset},model:{value:(_vm.showPrint),callback:function ($$v) {_vm.showPrint=$$v},expression:"showPrint"}}):_vm._e(),(_vm.dataset.main.detail.id && _vm.showAssociarLotes)?_c('c-base-dialog-associar-lotes',{attrs:{"show":_vm.showAssociarLotes,"pedido":_vm.dataset,"context":context},on:{"close":function($event){_vm.showAssociarLotes = false}}}):_vm._e()]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }