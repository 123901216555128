<!-- COD: FDS 01/07/2022 CColaboradores -->
<!-- *VER: JER 05/07/2022 CColaboradores -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- imagem -->
      <v-col v-bind="prop.col" v-if="context.isBuscaLayout" cols="2">
        <c-base-image avatar :endereco="dataset.anexo_endereco" height="60" />
      </v-col>
      <v-col v-bind="prop.col">
        <!-- situacaoTrabalho -->
        <v-row v-bind="prop.row" v-if="situacaoTrabalho">
          <v-col v-bind="prop.col">
            <v-chip v-bind="prop.chip">
              {{ situacaoTrabalho }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- pessoa -->
        <v-row v-bind="prop.row" v-if="dataset.pessoa_nome">
          <v-col v-bind="prop.col">
            <span class="text-h6">
              {{ dataset.pessoa_nome }}
            </span>
          </v-col>
        </v-row>

        <!-- nomeAlt -->
        <v-row v-bind="prop.row" v-if="dataset.nomeAlt">
          <v-col v-bind="prop.col">
            <span class="text-body-1">
              {{ dataset.nomeAlt }}
            </span>
          </v-col>
        </v-row>

        <!-- cargoAreaNivel -->
        <v-row v-bind="prop.row" v-if="cargoAreaNivel">
          <v-col v-bind="prop.col">
            <span class="text-body-1">
              {{ cargoAreaNivel }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- dataAdmissaoDemissao -->
    <v-row v-bind="prop.row" v-if="dataAdmissaoDemissao">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataAdmissaoDemissao }}
        </span>
      </v-col>
    </v-row>

    <!-- proprietario -->
    <!-- <v-row v-bind="prop.row" v-if="proprietario"> -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-chip class="primary primary--text" outlined>
          {{ dataset.proprietario }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CColaboradores",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    cargo() {
      return this.dataset?.idCargo
        ? this.getTipo_item(this.dataset?.idCargo)?.nomeAlt
        : "";
    },

    cargoAreaNivel() {
      return `${this.cargo || ""} ${this.nivel || ""}`;
    },

    dataAdmissaoDemissao() {
      return `${this.dateFormat(this.dataset.dataAdmissao, "date")}  
             ${
               this.dataset.dataDemissao
                 ? `- ${this.dateFormat(this.dataset.dataDemissao, "date")}`
                 : "- atual"
             }`;
    },

    nivel() {
      return this.dataset?.nivel ? this.dataset?.nivel : "";
    },

    situacaoTrabalho() {
      return this.dataset.idSituacaoTrabalho
        ? this.getTipo_item(this.dataset.idSituacaoTrabalho).nomeAlt
        : this.getTipo_item("trabalhoNormal").nomeAlt;
    },
  },
};
</script>