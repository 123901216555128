<!-- COD: BJB 19/04/2023 CBaseImage -->
<!-- VER: JER __/04/2023 CBaseImage -->
<!-- /11/ -->

<template>
  <!-- imagem -->
  <v-avatar v-if="endereco && avatar" :size="height">
    <v-img
      v-if="endereco"
      alt="imagem"
      contain
      :height="height"
      :width="width"
      :src="endereco"
      :style="estilo"
    />
  </v-avatar>
  <v-img
    v-else-if="!avatar && endereco"
    alt="imagem"
    contain
    :height="height"
    :width="width"
    :src="endereco"
    :style="estilo"
  />
  <v-avatar v-else-if="avatar">
    <v-icon x-large>mdi-account-outline</v-icon>
  </v-avatar>
  <v-icon v-else class="py-4" x-large> mdi-camera-off-outline </v-icon>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CBaseImage",

  props: {
    avatar: { type: Boolean, default: false },
    endereco: { type: String, required: false, defaul: null },
    estilo: {
      type: String,
      default: "width: fit-content; height: fit-content",
    },
    height: { type: [Number, String], default: 300 },
    width: { type: [Number, String], default: 300 },
  },
};
</script>