var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"580px","persistent":"","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"max-width":"580"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("EXTRATO DE PREÇO")]),_c('v-spacer')],1)],1),_c('v-card-text',{staticClass:"mx-0 pa-2"},[_c('v-form',{ref:"form"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',_vm._b({staticClass:"mt-5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"font-weight-medium py-1 text-body-1"},[_vm._v(" "+_vm._s(_vm.produtoFormat(_vm.produto))+" ")])])],1),_c('c-expansion-panels',{attrs:{"flat":"","isDisabled":_vm.isDisabled,"pageModeView":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var grupo = ref.dataset;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[(
                      _vm.extratoGrupo(grupo)[0] && _vm.extratoGrupo(grupo)[0].icon
                    )?_c('v-icon',[_vm._v(" "+_vm._s(_vm.extratoGrupo(grupo)[0].icon)+" ")]):_c('v-badge',{attrs:{"color":"grey","content":("" + (_vm.extratoGrupo(grupo).length)),"inline":"","overlap":"","value":("" + (_vm.extratoGrupo(grupo).length))}})],1),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2 ml-1"},[_vm._v(" "+_vm._s(grupo)+" ")])]),_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{class:((_vm.extratoGrupo(grupo).reduce(
                        function (acc, item) { return (acc += parseFloat(item.valor)); },
                        0
                      ) <= 0
                        ? 'red--text'
                        : '') + " " + (_vm.isDisabled(grupo) ? 'mr-6' : '') + " text-body-2")},[_vm._v(_vm._s(_vm.numberFormat( _vm.extratoGrupo(grupo).reduce( function (acc, item) { return (acc += parseFloat(item.valor)); }, 0 ), "currency" )))])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false))]}},{key:"content",fn:function(ref){
                    var grupo = ref.dataset;
return _vm._l((_vm.extratoGrupo(grupo)),function(extrato,i){return _c('div',{key:i},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(extrato.item))])]),(extrato.valor)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{class:((extrato.valor < 0 ? 'red--text' : '') + " text-body-2")},[_vm._v(_vm._s(_vm.numberFormat(extrato.valor, "currency")))])]):_vm._e()],1),(extrato.percentual && extrato.baseCalculo)?_c('v-row',_vm._b({staticClass:"mt-n2"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-caption"},[_vm._v("("+_vm._s(_vm.numberFormat(extrato.percentual / 100, "percent", 2))+" * "+_vm._s(_vm.numberFormat(extrato.baseCalculo, "currency"))+")")])])],1):_vm._e(),_c('v-divider',{staticClass:"my-2"})],1)})}}]),model:{value:(_vm.grupos),callback:function ($$v) {_vm.grupos=$$v},expression:"grupos"}})],1)],1)],1)],1),_c('c-snackbar',{attrs:{"snackbar":_vm.snackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }