<!-- COD: BJB 10/08/2022 CBasePanelDelete -->
<!-- *VER: JER 10/08/2022 CBasePanelDelete -->
<!-- /11/ -->

<template>
  <div>
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-menu
          classe="ml-n1"
          color="red"
          :disabled="disabledBtn"
          icon
          :items="['deletar']"
        >
          <template #btn>
            <c-btn classe="ml-n1" :disabled="disabledBtn" icon>
              <v-icon color="red">mdi-minus-circle</v-icon>
            </c-btn>
          </template>
          <template #item="{ item }">
            <c-btn classe="pa-0 white--text" text @click="deletar()">
              {{ item }}
            </c-btn>
          </template>
        </c-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CBasePanelDelete",

  /// PROPS
  props: {
    datasets: { type: [Array, Object] },
    disabled: { type: Boolean, required: false, default: false },
    indice: { type: Number, required: true },
    panel: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    disabledBtn() {
      return this.elementsLength <= this.min || this.disabled;
    },

    elementsLength() {
      return this.datasets.filter((element) => !element?.del).length;
    },

    min() {
      return this.panel.options.min;
    },
  },

  /// METHODS
  methods: {
    deletar() {
      if (this.datasets[this.indice].id) {
        this.datasets[this.indice].del = true;
      } else {
        this.datasets.splice(this.indice, 1);
      }

      this.$emit("click");
    },
  },
};
</script>