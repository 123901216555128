var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relatorios"},[(_vm.idTabela)?_c('c-the-navbar',{attrs:{"mode":"page","title":_vm.getPagina(_vm.idPagina).nomeAlt},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-btn',{attrs:{"icon":"","router":"","to":"/"}},[_c('v-icon',{staticClass:"ml-2",attrs:{"large":""}},[_vm._v(_vm._s(_vm.icon))])],1)]},proxy:true}],null,false,131109321)}):_vm._e(),_c('v-container',{ref:"printArea",staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('v-chip',{attrs:{"color":"primary","outlined":"","x-small":""}},[_c('span',{staticClass:"primary--text text-overline"},[_vm._v(" "+_vm._s(_vm.dadosParceiro.pessoa.nomeAlt)+" ")])])],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"items":_vm.paginaRelatorios,"label":"","required":"","solo":""},model:{value:(_vm.idFeature),callback:function ($$v) {_vm.idFeature=$$v},expression:"idFeature"}})],1)],1),(_vm.getFeature(_vm.idFeature).options.isHere)?_c('div',[_c('v-row',_vm._b({staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"appendOuterIcon":_vm.idFeature != _vm.getFeature('adapterReportDefault').id
                ? 'mdi-tune'
                : null,"clearable":"","max":100,"noAccept":null,"prepend-inner-icon":"mdi-magnify","solo":"","type":"search"},on:{"clickAppendOuter":function($event){_vm.showSearchOptions = !_vm.showSearchOptions}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.showSearchOptions)?[(_vm.featureRelatorio)?_c(_vm.featureRelatorio.nome,{tag:"component",attrs:{"context":this,"field":_vm.featureRelatorio.nome,"list":_vm.list,"panel":_vm.featureRelatorio,"params":_vm.params}}):_vm._e()]:_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"block":"","classe":"primary mb-4"},on:{"click":function($event){return _vm.clickGerar()}}},[_vm._v(" gerar ")])],1)],1),(_vm.params && _vm.params.hasNivel)?_c('v-slider',{staticClass:"mb-3",attrs:{"max":4,"step":"1","tick-labels":_vm.params.niveis,"tick-size":"5","ticks":"always"},on:{"click":function($event){return _vm.changeNivel(_vm.params.nivel)}},model:{value:(_vm.params.nivel),callback:function ($$v) {_vm.$set(_vm.params, "nivel", $$v)},expression:"params.nivel"}}):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-badge',{staticClass:"ml-6",attrs:{"color":_vm.idFilter == _vm.getFeature('filterPendentes').id ? 'blue' : 'grey',"content":_vm.list.listaMap.length.toString(),"left":""}})],1),_c('v-col',_vm._b({attrs:{"align":"end","cols":"11"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dataFormata(new Date(), "date timeNoSecond", true))+" ")])])],1),(_vm.list.headers && _vm.list.headers.length)?_c('v-data-table',{attrs:{"dense":"","disable-pagination":_vm.disablePagination,"footer-props":{
          itemsPerPageOptions: [10, 20, 30, -1],
          itemsPerPageAllText: 'Todos',
        },"group-by":_vm.params ? _vm.params.groupBy : null,"headers":_vm.hiddenHeaders,"hide-default-header":"","item-key":"index","items":_vm.list.listaMap.map(function (itemList, i) {
            return Object.assign({}, itemList,
              {index: i});
          }),"items-per-page":_vm.pagination.itemsPerPage,"mobile-breakpoint":0,"search":_vm.search},scopedSlots:_vm._u([(_vm.params && _vm.params.groupBy)?{key:"top",fn:function(){return [(_vm.open)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.closeAll}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.openAll}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true}:null,{key:"group.header",fn:function(ref){
          var group = ref.group;
          var headers = ref.headers;
          var toggle = ref.toggle;
          var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"data-open":isOpen,"icon":"","small":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),(
                _vm.list.headers.some(
                  function (header) { return header.options &&
                    header.options.isGroupBy &&
                    _vm.params &&
                    _vm.params.groupBy == header.value; }
                )
              )?_c('span',[_vm._v(" "+_vm._s(_vm.formatValue( group, _vm.list.headers.find( function (header) { return header.options && header.options.isGroupBy && _vm.params.groupBy == header.value; } ).options ))+" ")]):_vm._e()],1)]}},{key:"header",fn:function(ref){
              var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers.filter(
                  function (header) { return !header.isHidden; }
                )),function(header,i){return _c('th',{key:i,class:[
                  _vm.pagination.sortBy == header.value ? 'active' : '',
                  _vm.pagination.descending ? 'desc' : 'asc' ],attrs:{"scope":"col"},on:{"click":function($event){_vm.params.isOrderable ? _vm.changeSort(header.value) : ''}}},[_c('span',[_vm._v(" "+_vm._s(header.text)+" "),(_vm.pagination.sortBy == header.value)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.pagination.descending ? "mdi-arrow-down-thin" : "mdi-arrow-up-thin")+" ")]):_vm._e()],1)])}),0)])]}},_vm._l((_vm.list.headers),function(header,i){return {key:("item." + (header.value)),fn:function(ref){
                var item = ref.item;
return [_c('div',{key:("header" + i),staticClass:"py-2"},[(header.options && header.options.hasIcon)?_c('v-icon',{attrs:{"color":item.info.color}},[_vm._v(" "+_vm._s(item[header.value])+" ")]):(header.options && header.options.hasInfo)?_c('c-help',{attrs:{"estilo":"width: fit-content","help":{
                title: item[header.value],
                text: item.options.info[_vm.getVar('locale')],
                function: _vm.setUiBottomSheet,
              }}},[_c('span',{class:((header.options && header.options.isChangeColor
                    ? ((_vm.getColor(item[header.value])) + "--text")
                    : null) + " \n            " + (header.isBold && item.isBold
                ? 'font-weight-medium primary--text'
                : !item.isBold && header.isBold
                ? 'grey--text'
                : null) + "\n            ")},[_vm._v(" "+_vm._s(header.fixedValue !== undefined ? header.fixedValue : _vm.formatValue(item[header.value], header.options))+" "),(header.options && header.options.suffix)?_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(header.options.suffix)+" ")]):_vm._e()])]):_c('span',{class:((header.options && header.options.isChangeColor
                  ? ((_vm.getColor(item[header.value])) + "--text")
                  : null) + " \n            " + (header.isBold && item.isBold
                ? 'font-weight-medium primary--text'
                : !item.isBold && header.isBold
                ? 'grey--text'
                : null) + "\n            ")},[_vm._v(" "+_vm._s(header.fixedValue !== undefined ? header.fixedValue : _vm.formatValue(item[header.value], header.options))+" "),(header.options && header.options.suffix)?_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(header.options.suffix)+" ")]):_vm._e()])],1)]}}})],null,true)}):_vm._e()],2):_vm._e()],1),_c('c-the-navbar',{attrs:{"bottom":"","mode":"page","pageModeView":_vm.pageModeView,"slots":_vm.buttonsBar.length,"title":""},on:{"showMenuVoltar":function($event){_vm.menuOptions = false}},scopedSlots:_vm._u([_vm._l((_vm.buttonsBar),function(button,i){return {key:i,fn:function(){return [(!Array.isArray(button))?_c('c-btn',{key:i,attrs:{"icon":""},on:{"click":function($event){return button.clickFunction(button)}}},[_c('v-icon',{attrs:{"color":button.options.color || 'white',"large":""}},[_vm._v(" "+_vm._s(button.options.icon)+" ")])],1):_c('v-menu',{key:i,attrs:{"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('c-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true),model:{value:(_vm.menuOptions),callback:function ($$v) {_vm.menuOptions=$$v},expression:"menuOptions"}},[_c('v-list',_vm._l((button),function(option,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.clickOption(option)}}},[_c('v-icon',{class:((option.options.color) + "--text")},[_vm._v(" "+_vm._s(option.options.icon)+" ")]),_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(option.text)+" ")])],1)}),1)],1)]},proxy:true}})],null,true)}),(_vm.showConfiguracoes)?_c('report-dialog-configuracoes',{attrs:{"dataset":_vm.dataset,"idFeature":_vm.idFeature,"idPagina":_vm.idPagina,"params":_vm.params,"show":_vm.showConfiguracoes},on:{"close":function($event){return _vm.closeConfiguracoes()},"save":function($event){return _vm.saveConfiguracoes()}}}):_vm._e(),_c('c-snackbar',{attrs:{"snackbar":_vm.snackbar},on:{"click":function($event){return _vm.clickSnackbar($event)}}}),_c('c-bottom-sheet',{attrs:{"bottomSheet":_vm.bottomSheet}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }