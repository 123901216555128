<!-- COD: FDS 04/08/2022 Busca -->
<!-- *VER: JER 10/08/2022 Busca -->
<!-- /33/ -->

<template>
  <div
    v-touch="{
      down: ($event) => swipe('down', swipeRefresh, $event),
      up: ($event) => swipe('up', swipeInject, $event),
    }"
    class="busca mx-n5"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" title="Busca" />

    <!-- ??? Onde posicionar o snacbar? -->
    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// LIST -->
    <v-container class="py-0 px-2">
      <!-- tipos de busca -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <v-slide-group
            v-model="idTipoBusca"
            center-active
            class="py-3"
            mandatory
          >
            <v-slide-item
              v-for="(tipoBusca, i) in tiposBusca"
              #default="{ toggle }"
              :key="i"
            >
              <c-btn
                class="mx-1 text-overline"
                :class="
                  idTipoBusca != tipoBusca.value
                    ? 'primary--text'
                    : 'white--text'
                "
                color="primary"
                depressed
                :outlined="idTipoBusca != tipoBusca.value"
                rounded
                small
                @click="toggle"
              >
                {{ tipoBusca.text }}
              </c-btn>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>

      <div v-if="!parceiro">
        <!-- usar categorias na busca -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <v-sheet>
              <c-switch
                v-model="usarCategoriasBusca"
                class="ml-2 mt-n2"
                inset
                label="Usar categorias na busca?"
              />
            </v-sheet>
          </v-col>
        </v-row>

        <!-- categorias -->
        <v-row v-bind="prop.row" v-show="usarCategoriasBusca">
          <v-col v-bind="prop.col">
            <c-base-categorias />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-container class="pa-0 mb-3">
          <!-- imagem parceiro -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <v-img
                v-if="parceiro.imagem"
                alt="imagem"
                class="ml-0 mt-0"
                :src="parceiro.imagem"
                @click="clickParceiro"
              />
            </v-col>
          </v-row>

          <!-- parceiro -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-btn block @click="clickParceiro" class="primary--text">
                <span class="text-h5 font-weight-ligth">
                  {{ parceiro.parceiro }}
                </span>
              </c-btn>
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <!-- categoria -->
            <v-col v-bind="prop.col" cols="8">
              <span class="text-overline">
                {{ getCategoria(parceiro.idCategoria).nomeAlt }}
              </span>
            </v-col>
            <!-- avaliacao -->
            <v-col v-bind="prop.col" align="end" cols="4">
              <c-btn
                v-if="parceiro.numeroAvaliacoes"
                class="orange--text"
                text
                @click="$router.push(`/avaliacoes/${parceiro.idPessoa}`)"
              >
                <v-icon class="orange--text" small>mdi-star</v-icon>
                <span class="ml-1 text-body-1">
                  {{ parceiro.mediaAvaliacoes }}
                </span>
                <span class="ml-1 text-overline">
                  ({{ parceiro.numeroAvaliacoes }})
                </span>
              </c-btn>
            </v-col>
          </v-row>

          <!-- apresentacao -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <div
                v-html="parceiro.apresentacao[getVar('locale')]"
                class="ql-editor"
              ></div>
            </v-col>
          </v-row>

          <!-- entrega -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="9">
              <span v-if="parceiro.entrega.tempo.tempoDe" class="text-body-1">
                {{
                  `entrega em ${parseInt(
                    parceiro.entrega.tempo.tempoDe -
                      getConst("config").parceiro.entrega.tolerancia
                  )} - ${parseInt(
                    parceiro.entrega.tempo.tempoAte +
                      getConst("config").parceiro.entrega.tolerancia
                  )} min (${numberFormat(
                    parceiro.entrega.distancia,
                    "decimal",
                    1
                  )} Km)`
                }}
              </span>
              <span v-else class="text-body-1">
                {{
                  `${numberFormat(parceiro.entrega.distancia, "decimal", 1)} Km`
                }}
              </span>
            </v-col>
            <v-col v-bind="prop.col" align="end" cols="3">
              <span class="font-weight-medium success--text text-body-1">
                grátis
              </span>
            </v-col>
          </v-row>

          <!-- status atendimento -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span
                class="text-h6"
                :class="
                  parceiro.atendimento.status == 'FECHADO'
                    ? 'red--text'
                    : 'green--text'
                "
              >
                {{ parceiro.atendimento.status }}
              </span>
            </v-col>
          </v-row>

          <!-- info atendimento -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="overline">
                {{ parceiro.atendimento.info }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- search -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" cols="12">
          <c-text-field
            v-model="search"
            :append-outer-icon="
              idTipoBusca == getConst('config').tipoBusca.produtos.id
                ? 'mdi-tune'
                : null
            "
            class="ml-1"
            clearable
            :max="100"
            :prepend-icon="iconLayout"
            prepend-inner-icon="search"
            solo
            type="search"
            @clickAppendOuter="clickShowSearchOptions"
            @clickPrepend="clickLayout"
            @input="buscaRetornoClear"
            @keyup.enter="
              getBusca();
              $event.target.blur();
            "
          />
        </v-col>
      </v-row>

      <v-row v-bind="prop.row" v-show="!showSearchOptions" class="mt-n1 px-1">
        <v-col v-bind="prop.col">
          <v-icon :class="iconFavoritoClass" @click="clickFavoritos">
            {{ iconFavorito }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n4 px-1">
        <v-col v-bind="prop.col">
          <v-select
            v-if="filters.length"
            v-model="idFilter"
            :items="filters"
            :prepend-inner-icon="
              filter && filter.options ? filter.options.icon : null
            "
            solo
            @change="getBusca()"
          >
            <template v-if="iconFavorito" v-slot:prepend>
              <v-icon :class="iconFavoritoClass" @click="clickFavoritos">
                {{ iconFavorito }}
              </v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n4">
        <v-col v-bind="prop.col">
          <v-select
            v-if="orders.length"
            v-model="idOrder"
            :items="orders"
            :prepend-inner-icon="
              this.ordem
                ? this.ordem == 'desc'
                  ? 'mdi-arrow-down'
                  : 'mdi-arrow-up'
                : order && order.options
                ? order.options.icon
                : null
            "
            solo
            @change="getBusca()"
            @clickPrependInner="changeOrderList()"
          />
        </v-col>
      </v-row>

      <!-- operacao -->
      <v-row
        v-bind="prop.row"
        v-if="
          !isCompra &&
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id
        "
        class="mt-n4"
      >
        <v-col v-bind="prop.col">
          <v-select v-model="idOperacao" :items="operacoes" label="operação" />
        </v-col>
      </v-row>

      <!-- parceiroCliente -->
      <v-row
        v-bind="prop.row"
        v-show="
          !isCompra &&
          showCliente &&
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id
        "
        class="mt-n4"
      >
        <v-col v-bind="prop.col">
          <c-autocomplete
            v-model="parceiroCliente"
            dense
            :itemsIniciais="
              parceiroCliente && parceiroCliente.value ? [parceiroCliente] : []
            "
            label="cliente"
            :path="`/pessoas/autocomplete/cliente/0/0/${idPessoaParceiroSelecionado}/`"
            returnObject
            :rules="[]"
            @change="changeCliente"
          />
        </v-col>
      </v-row>

      <!-- localidade -->
      <v-row
        v-bind="prop.row"
        v-if="
          !isCompra &&
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id
        "
        class="mt-n4"
      >
        <v-col v-bind="prop.col">
          <v-select
            v-model="idLocalidade"
            clearable
            :disabled="parceiroCliente && !!parceiroCliente.value"
            :items="localidades"
            label="localidade (uf)"
            @change="changeLocalidade"
          />
        </v-col>
      </v-row>

      <!-- finalidade -->
      <v-row
        v-bind="prop.row"
        v-if="
          !isCompra &&
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id
        "
        class="mt-n4"
      >
        <v-col v-bind="prop.col">
          <v-select
            v-model="idFinalidade"
            clearable
            :items="finalidades"
            label="finalidade"
          />
        </v-col>
      </v-row>

      <!-- regime tributário -->
      <v-row
        v-bind="prop.row"
        v-if="
          !isCompra &&
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id &&
          [
            getTipo_item('finalidadeIndustrializacao').id,
            getTipo_item('finalidadeComercializacao').id,
          ].includes(idFinalidade)
        "
        class="mt-n4"
      >
        <v-col v-bind="prop.col">
          <v-select
            v-model="idRegimeTributario"
            clearable
            :disabled="parceiroCliente && !!parceiroCliente.value"
            :items="regimesTributarios"
            label="regime tributário"
          />
        </v-col>
      </v-row>

      <!-- constribuiente -->
      <v-row
        v-bind="prop.row"
        v-if="
          !isCompra &&
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id &&
          [
            getTipo_item('finalidadeIndustrializacao').id,
            getTipo_item('finalidadeComercializacao').id,
          ].includes(idFinalidade)
        "
        class="mt-n6"
      >
        <v-col v-bind="prop.col">
          <c-switch
            v-model="isContribuinte"
            :disabled="parceiroCliente && !!parceiroCliente.value"
            inset
            :label="labelContribuinte"
          />
        </v-col>
      </v-row>

      <!-- área livre comércio -->
      <v-row
        v-bind="prop.row"
        v-if="
          !isCompra &&
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id &&
          [
            getTipo_item('finalidadeIndustrializacao').id,
            getTipo_item('finalidadeComercializacao').id,
          ].includes(idFinalidade) &&
          localidadeIsAreaLivreComercio
        "
        class="mt-n8"
      >
        <v-col v-bind="prop.col">
          <c-switch
            v-model="isAreaLivreComercio"
            :disabled="parceiroCliente && !!parceiroCliente.value"
            inset
            label="operação para área de livre comércio"
          />
        </v-col>
      </v-row>

      <!-- com estoque -->
      <v-row
        v-bind="prop.row"
        v-if="
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id
        "
        class="mt-n8"
      >
        <v-col v-bind="prop.col">
          <c-switch
            v-model="soItemComEstoque"
            inset
            label="só itens com estoque"
          />
        </v-col>
      </v-row>

      <v-row
        v-bind="prop.row"
        v-if="
          showSearchOptions &&
          idTipoBusca == getConst('config').tipoBusca.produtos.id
        "
        class="mt-n5"
      >
        <v-col v-bind="prop.col" cols="6">
          <v-select
            v-if="getVar('idUsuario') <= 20"
            v-model="limit"
            class="ml-n2 mt-2 pr-1"
            :items="itensPorPagina"
            label="paginação"
            prepend-inner-icon="mdi-format-align-justify"
            solo
          />
        </v-col>
        <!-- botao buscar -->
        <v-col v-bind="prop.col" align="end" cols="6">
          <v-btn
            block
            class="primary white--text mb-4 mt-n1"
            label="buscar"
            large
            @click="getBusca()"
          >
            buscar
          </v-btn>
        </v-col>
      </v-row>

      <!-- /// LIST -->
      <v-container class="px-0">
        <v-row v-bind="prop.row">
          <!-- search parceiro -->
          <v-col v-bind="prop.col" align="center">
            <v-chip
              v-if="
                searchParceiro.id &&
                idTipoBusca != getConst('config').tipoBusca.parceiros.id
              "
              class="primary white--text text-overline"
              close
              @click:close="chipSearchParcerioClose(searchParceiro)"
            >
              {{ searchParceiro.nomeAlt }}
            </v-chip>
            <!-- search marca -->
            <v-chip
              v-if="
                searchMarca.id &&
                idTipoBusca != getConst('config').tipoBusca.marcas.id
              "
              class="primary white--text text-body-2 ml-3"
              close
              @click:close="chipSearchMarcaClose(searchMarca)"
            >
              {{ searchMarca.nomeAlt }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- entregar/retirar -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <v-chip
              v-if="isEntregar != null"
              class="primary white--text text-overline"
              close
              @click:close="isEntregar = null"
            >
              {{ isEntregar ? "entregar" : "retirar" }}
            </v-chip>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" class="mt-4">
          <!-- quantidade de retorno da busca -->
          <v-col v-bind="prop.col" cols="2">
            <v-badge
              class="ml-8"
              color="corDisabled"
              :content="buscaRetorno.length.toString()"
              left
            ></v-badge>
          </v-col>
          <col v-bind="prop.col" cols="2" />
        </v-row>
      </v-container>

      <v-container class="py-0">
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="end">
            <v-btn icon @click="clickPanels()" class="mr-n5">
              <v-icon>
                {{ !panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up" }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div
        v-if="idTipoBusca == getConst('config').tipoBusca.produtos.id"
        class="pa-0"
      >
        <div v-if="isBuscaLayout === null" class="pa-0">
          <v-expansion-panels class="px-0" v-model="panelsOpen" flat multiple>
            <v-expansion-panel
              v-for="(produtosEstado, i) in produtosEstados"
              :key="i"
              class="pa-0"
            >
              <v-expansion-panel-header class="pa-0">
                <v-container class="pa-0">
                  <v-row v-bind="prop.row">
                    <!-- grupo de produtos -->
                    <v-col v-bind="prop.col" cols="10">
                      <v-icon class="mt-n3">
                        {{
                          produtosEstado != -1
                            ? getEstado(produtosEstado).options.icon
                            : "mdi-tag-outline"
                        }}
                      </v-icon>
                      <span class="text-h5 font-weight-ligth text-uppercase">
                        {{
                          produtosEstado != -1
                            ? getEstado(produtosEstado).options.plural
                            : "todos"
                        }}
                      </span>
                    </v-col>
                    <!-- quantidade retorno da busca -->
                    <v-col v-bind="prop.col" align="end" cols="2">
                      <v-badge
                        class="mr-4"
                        color="grey"
                        :content="quantidadeRetorno(produtosEstado).toString()"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <div v-if="produtosEstado != -1" class="mx-n6">
                  <v-slide-group v-model="model" :show-arrows="!isTouchDevice">
                    <v-slide-item
                      v-for="el in buscaCards.filter(
                        (item) =>
                          item.idEstado == produtosEstado &&
                          (!soItemComEstoque ||
                            (item.estoqueProprio && item.estoqueProprio > 0))
                      )"
                      class="mr-2"
                      :key="el.id"
                    >
                      <v-container class="pa-0">
                        <v-card
                          class="my-1 pa-1"
                          :height="heightCard(produtosEstado)"
                          :width="widthCard(produtosEstado)"
                          @click.stop="clickProduto(el)"
                        >
                          <v-container class="pa-0">
                            <v-row v-bind="prop.row" class="pa-0">
                              <!-- imagem -->
                              <v-col
                                v-bind="prop.col"
                                align="center"
                                class="pa-0"
                              >
                                <c-base-image
                                  :endereco="el.imagens[0].endereco"
                                  estilo=""
                                  :height="heightImage(produtosEstado)"
                                />
                              </v-col>
                            </v-row>
                            <v-row v-bind="prop.row" class="pa-0">
                              <!-- favoritar -->
                              <v-col
                                v-bind="prop.col"
                                align="start"
                                class="pa-0"
                                cols="6"
                              >
                                <c-btn
                                  v-if="el.favorito !== undefined"
                                  icon
                                  @click.stop="favoritar(el)"
                                >
                                  <v-icon
                                    :class="
                                      el.favorito
                                        ? 'text--darken-1 yellow--text'
                                        : 'grey--text'
                                    "
                                  >
                                    {{ el.favorito ? "star" : "star_outline" }}
                                  </v-icon>
                                </c-btn>
                              </v-col>
                              <!-- detalhar produto -->
                              <v-col
                                v-bind="prop.col"
                                align="end"
                                class="pa-0"
                                cols="6"
                              >
                                <c-btn
                                  v-if="!isCompra"
                                  icon
                                  @click.stop="cardAction(el)"
                                >
                                  <v-icon> mdi-tag-outline </v-icon>
                                </c-btn>
                              </v-col>
                            </v-row>
                            <c-base-busca-layout
                              :element="el"
                              :isPromocao="
                                produtosEstado == getEstado('promocao').id
                              "
                              layout="card"
                            />
                          </v-container>
                          <v-card-actions class="card-actions">
                            <c-base-preco
                              align="center"
                              :color="getPrecoColor(el)"
                              :decimalValor="el.decimalValor"
                              :extrato="el.preco.extrato"
                              :produto="el"
                              :idLocalidade="idLocalidadeExtrato"
                              :idUnidadeMedida="el.idUnidadeMedida"
                              :preco="el.preco.preco || el.preco"
                              @clickComprar.stop="clickProduto(el)"
                            />
                          </v-card-actions>
                        </v-card>
                      </v-container>
                    </v-slide-item>
                  </v-slide-group>
                </div>
                <div v-else-if="produtosEstado == -1" class="mx-n6">
                  <c-expansion-panels
                    flat
                    :pageModeView="false"
                    :value="produtosGrupos"
                  >
                    <template #header="{ dataset: produtosGrupo }">
                      <v-container class="pa-0">
                        <v-row v-bind="prop.row">
                          <!-- grupo de produtos -->
                          <v-col v-bind="prop.col" cols="10">
                            <span class="text-h6">
                              {{ produtosGrupo || "Resultados" }}
                            </span>
                          </v-col>
                          <!-- quantidade retorno da busca -->
                          <v-col v-bind="prop.col" align="end" cols="2">
                            <v-badge
                              class="mr-4"
                              color="grey"
                              :content="
                                buscaRetorno.filter(
                                  (item) =>
                                    (!produtosGrupo ||
                                      item.grupo == produtosGrupo) &&
                                    (!soItemComEstoque ||
                                      (item.estoqueProprio &&
                                        item.estoqueProprio > 0))
                                ).length
                              "
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template #content="{ dataset: produtosGrupo }">
                      <div
                        v-for="(el, i) in buscaRetorno.filter(
                          (item) =>
                            item.grupo == produtosGrupo &&
                            (!soItemComEstoque ||
                              (item.estoqueProprio && item.estoqueProprio > 0))
                        )"
                        :key="el.id"
                        @click="clickProduto(el)"
                      >
                        <v-divider
                          v-bind="prop.divider"
                          v-if="i > 0"
                        ></v-divider>
                        <v-container class="pa-0">
                          <!-- imagem -->
                          <v-row v-bind="prop.row" class="pa-0">
                            <v-col v-bind="prop.col" align="center" cols="3">
                              <c-base-image
                                :endereco="el.imagens[0].endereco"
                              />
                            </v-col>
                            <!-- conteudo  -->
                            <v-col v-bind="prop.col" cols="9">
                              <c-base-busca-layout :element="el" />

                              <c-base-preco
                                :color="getPrecoColor(el)"
                                :decimalValor="el.decimalValor"
                                :extrato="el.preco.extrato"
                                :produto="el"
                                :idLocalidade="idLocalidadeExtrato"
                                :idUnidadeMedida="el.idUnidadeMedida"
                                :preco="el.preco.preco || el.preco"
                                @clickComprar.stop="clickProduto(el)"
                              />
                            </v-col>
                          </v-row>
                          <v-row v-bind="prop.row" class="ml-n1">
                            <!-- favoritar -->
                            <v-col
                              v-bind="prop.col"
                              align="start"
                              class="pa-0"
                              cols="1"
                            >
                              <c-btn
                                v-if="el.favorito !== undefined"
                                icon
                                @click.stop="favoritar(el)"
                              >
                                <v-icon
                                  :class="
                                    el.favorito
                                      ? 'text--darken-1 yellow--text'
                                      : 'grey--text'
                                  "
                                >
                                  {{ el.favorito ? "star" : "star_outline" }}
                                </v-icon>
                              </c-btn>
                            </v-col>

                            <v-col v-bind="prop.col">
                              <!-- estado -->
                              <v-chip
                                v-if="el.idEstado != getEstado('ativo').id"
                                class="mx-1 text-overline white--text"
                                :class="getEstado(el.idEstado).nomeAlt"
                                x-small
                              >
                                {{ translate(getEstado(el.idEstado).nomeAlt) }}
                              </v-chip>
                            </v-col>

                            <!-- detalhar produto -->
                            <v-col
                              v-bind="prop.col"
                              align="end"
                              class="pa-0"
                              cols="1"
                            >
                              <c-btn
                                v-if="!isCompra"
                                icon
                                @click.stop="cardAction(el)"
                              >
                                <v-icon> mdi-tag-outline </v-icon>
                              </c-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </div>
                    </template>
                  </c-expansion-panels>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-else class="pa-0">
          <div
            v-for="(el, i) in buscaRetornoFilterList"
            class="my-n3"
            :key="el.id"
            @click="clickProduto(el)"
          >
            <v-divider v-bind="prop.divider" v-if="i > 0"></v-divider>

            <v-container class="pa-0">
              <v-row v-bind="prop.row" class="sticky" justify="center">
                <v-subheader v-if="order && order.groupBy" class="mt-4">
                  <v-chip
                    :class="getGroup(el)"
                    class="font-weight-medium my-4 text-overline white--text"
                    x-small
                  >
                    {{ getGroup(el) }}
                  </v-chip>
                </v-subheader>
              </v-row>

              <v-row v-bind="prop.row" class="pa-0">
                <!-- imagem -->
                <v-col
                  v-bind="prop.col"
                  v-if="isBuscaLayout"
                  align="center"
                  cols="3"
                >
                  <c-base-image :endereco="el.imagens[0].endereco" />
                </v-col>
                <!-- conteudo  -->
                <v-col v-bind="prop.col" :cols="isBuscaLayout ? 9 : 12">
                  <c-base-busca-layout :element="el" :hasImagem="isBuscaLayout">
                    <c-base-preco
                      v-if="!isBuscaLayout"
                      :color="getPrecoColor(el)"
                      :decimalValor="el.decimalValor"
                      :extrato="el.preco.extrato"
                      :produto="el"
                      :idLocalidade="idLocalidadeExtrato"
                      :idUnidadeMedida="el.idUnidadeMedida"
                      :preco="el.preco.preco || el.preco"
                      @clickComprar.stop="clickProduto(el)"
                    />
                  </c-base-busca-layout>

                  <c-base-preco
                    v-if="isBuscaLayout"
                    :color="getPrecoColor(el)"
                    :decimalValor="el.decimalValor"
                    :extrato="el.preco.extrato"
                    :produto="el"
                    :idLocalidade="idLocalidadeExtrato"
                    :idUnidadeMedida="el.idUnidadeMedida"
                    :preco="el.preco.preco || el.preco"
                    @clickComprar.stop="clickProduto(el)"
                  />
                </v-col>
              </v-row>
              <v-row v-bind="prop.row" class="ml-n1">
                <!-- favoritar -->
                <v-col v-bind="prop.col" align="start" class="pa-0" cols="1">
                  <c-btn
                    v-if="el.favorito !== undefined"
                    icon
                    @click.stop="favoritar(el)"
                  >
                    <v-icon
                      :class="
                        el.favorito
                          ? 'text--darken-1 yellow--text'
                          : 'grey--text'
                      "
                    >
                      {{ el.favorito ? "star" : "star_outline" }}
                    </v-icon>
                  </c-btn>
                </v-col>
                <v-col v-bind="prop.col">
                  <!-- estado -->
                  <v-chip
                    v-if="el.idEstado != getEstado('ativo').id"
                    class="mx-1 text-overline white--text"
                    :class="getEstado(el.idEstado).nomeAlt"
                    x-small
                  >
                    {{ translate(getEstado(el.idEstado).nomeAlt) }}
                  </v-chip>
                  <!-- parceiro -->
                  <v-chip
                    v-if="!searchParceiro.id"
                    class="mx-1 primary primary--text text-overline"
                    outlined
                    x-small
                  >
                    {{ el.parceiro }}
                  </v-chip>
                </v-col>
                <!-- detalhar produto -->
                <v-col v-bind="prop.col" align="end" class="pa-0" cols="1">
                  <c-btn v-if="!isCompra" icon @click.stop="cardAction(el)">
                    <v-icon> mdi-tag-outline </v-icon>
                  </c-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>

      <v-expansion-panels
        v-if="!idTipoBusca == getConst('config').tipoBusca.produtos.id"
        class="px-0"
        v-model="panelsOpen"
        flat
        multiple
      >
        <v-expansion-panel
          v-for="(produtosGrupo, i) in produtosGrupos"
          :key="i"
          class="pa-0"
        >
          <v-expansion-panel-header class="pa-0">
            <v-container class="pa-0">
              <v-row v-bind="prop.row">
                <!-- grupo de produtos -->
                <v-col v-bind="prop.col" cols="2">
                  <span class="text-h6">
                    {{ produtosGrupo || "Resultados" }}
                  </span>
                </v-col>
                <!-- quantidade retorno da busca -->
                <v-col v-bind="prop.col" align="end" cols="10">
                  <v-chip class="mr-1" outlined small>
                    {{
                      buscaRetorno.filter(
                        (item) =>
                          item.grupo == produtosGrupo &&
                          (!soItemComEstoque ||
                            (item.estoqueProprio && item.estoqueProprio > 0))
                      ).length
                    }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <div
              v-for="(el, i) in buscaRetorno.filter(
                (item) =>
                  item.grupo == produtosGrupo &&
                  (!soItemComEstoque ||
                    (item.estoqueProprio && item.estoqueProprio > 0))
              )"
              class="mx-n6 my-n3"
              :key="el.id"
              @click="cardAction(el)"
            >
              <v-divider v-bind="prop.divider" v-if="i > 0"></v-divider>

              <v-container
                v-if="
                  idTipoBusca == getConst('config').tipoBusca.publicacoes.id
                "
              >
                <v-row v-bind="prop.row">
                  <!-- icon -->
                  <v-col v-bind="prop.col" cols="1">
                    <v-icon>
                      {{ getTipo_item(el.idTipo).options.icon }}
                    </v-icon>
                  </v-col>
                  <!-- parceiro -->
                  <v-col v-bind="prop.col" cols="9">
                    <v-chip
                      class="primary primary--text text-overline"
                      outlined
                      x-small
                    >
                      <span class="text-body-1">
                        {{ el.parceiro }}
                      </span>
                    </v-chip>
                  </v-col>
                  <!-- parceiro -->
                  <v-col v-bind="prop.col" align="end" cols="2">
                    <span class="text-overline">
                      {{ dataFormataRelativa(el.data, null) }}
                    </span>
                  </v-col>
                </v-row>

                <!-- titulo e marca -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <span class="text-h6">
                      {{ el.titulo[getVar("locale")] }}
                      {{ el.marca ? `, ${el.marca}` : "" }}
                    </span>
                  </v-col>
                </v-row>

                <!-- imagem -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" align="center">
                    <v-img
                      v-if="el.imagens"
                      :src="el.imagens[0].endereco"
                    ></v-img>
                  </v-col>
                </v-row>

                <!-- conteudo -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <div
                      v-html="el.conteudo[getVar('locale')]"
                      class="ql-editor"
                    ></div>
                  </v-col>
                </v-row>

                <v-row
                  v-bind="prop.row"
                  v-if="el.idTipo == getTipo_item('publicacaoProduto').id"
                >
                  <!-- favoritar -->
                  <v-col v-bind="prop.col" cols="1">
                    <c-btn @click.stop="favoritar(el)" icon>
                      <v-icon
                        :class="
                          el.favorito ? 'yellow-darken-2--text' : 'grey--text'
                        "
                      >
                        {{ el.favorito ? "star" : "star_outline" }}
                      </v-icon>
                    </c-btn>
                  </v-col>
                  <!-- comprar -->
                  <v-col v-bind="prop.col" align="end" cols="11">
                    <c-btn
                      align-self-baseline
                      class="ml-1 px-3"
                      :color="getPrecoColor(el)"
                      :loading="el.loading"
                      rounded
                      @click.stop="clickProduto(el)"
                    >
                      <!-- @click.stop="clickComprar(el)" -->
                      <span v-if="el.preco.preco" class="text-body-1">
                        {{
                          numberFormat(
                            parseFloat(el.preco.preco),
                            "currency",
                            el.decimalValor
                          )
                        }}
                      </span>
                      <span v-else-if="el.preco" class="text-body-1">
                        {{
                          numberFormat(
                            parseFloat(el.preco),
                            "currency",
                            el.decimalValor
                          )
                        }}
                      </span>
                      <span
                        v-if="
                          el.idUnidadeMedida !=
                          getTipo_item('unidadeUnidade').id
                        "
                        class="mt-2 text-lowercase"
                      >
                        /{{ getTipo_item(el.idUnidadeMedida).codigo }}
                      </span>
                    </c-btn>
                  </v-col>
                </v-row>

                <v-row v-bind="prop.row">
                  <!-- curtidas -->
                  <v-col v-bind="prop.col" align="center" cols="3">
                    <c-btn icon :ripple="false" @click.stop="clickCurtir(el)">
                      <v-icon
                        :class="`${el.isCurtiu ? 'red--text' : 'grey--text'} ${
                          el.curtidas ? 'mt-5' : 'mt-n2'
                        }`"
                      >
                        {{ el.isCurtiu ? "mdi-heart" : "mdi-heart-outline" }}
                      </v-icon>
                    </c-btn>
                    <div
                      v-if="
                        getLocalidades(getTipo_item('localidadePais').id).length
                      "
                    >
                      <span class="text-overline font-weight-light">
                        {{ el.curtidas ? numberFormat(el.curtidas) : "" }}
                      </span>
                    </div>
                  </v-col>
                  <!-- comentadas -->
                  <v-col v-bind="prop.col" align="center" cols="3">
                    <c-btn icon @click.stop="clickComentar(el)">
                      <v-icon
                        :class="`${el.comentou ? 'blue--text' : 'grey--text'} ${
                          el.comentadas ? 'mt-5' : 'mt-n2'
                        }`"
                      >
                        {{
                          el.comentou ? "mdi-comment" : "mdi-comment-outline"
                        }}
                      </v-icon>
                    </c-btn>
                    <div
                      v-if="
                        getLocalidades(getTipo_item('localidadePais').id).length
                      "
                    >
                      <span class="text-overline font-weight-light">
                        {{ el.comentadas ? numberFormat(el.comentadas) : "" }}
                      </span>
                    </div>
                  </v-col>
                  <!-- compartilhadas -->
                  <v-col v-bind="prop.col" align="center" cols="3">
                    <c-tooltip
                      texto="informações copiadas para área de transferência"
                    >
                      <template v-slot="{ on }">
                        <c-btn
                          icon
                          @click.stop="clickCompartilhar(el, on, $event)"
                        >
                          <v-icon
                            :class="`${
                              el.compartilhou ? 'blue--text' : 'grey--text'
                            } ${el.compartilhadas ? 'mt-5' : 'mt-n2'}`"
                          >
                            mdi-share
                          </v-icon>
                        </c-btn>
                      </template>
                    </c-tooltip>
                    <div
                      v-if="
                        getLocalidades(getTipo_item('localidadePais').id).length
                      "
                    >
                      <span class="text-overline font-weight-light">
                        {{
                          el.compartilhadas
                            ? numberFormat(el.compartilhadas)
                            : ""
                        }}
                      </span>
                    </div>
                  </v-col>
                  <!-- visualizadas -->
                  <v-col v-bind="prop.col" align="center" cols="3">
                    <c-btn icon>
                      <v-icon
                        :class="`${
                          el.visualizou ? 'blue--text' : 'grey--text'
                        } ${el.visualizadas ? 'mt-5' : 'mt-n2'}`"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </c-btn>
                    <div
                      v-if="
                        getLocalidades(getTipo_item('localidadePais').id)
                          .length && el.visualizadas
                      "
                    >
                      <span class="text-overline font-weight-light ml-n1">
                        {{ numberFormat(el.visualizadas) }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-container
                v-else-if="
                  idTipoBusca == getConst('config').tipoBusca.produtos.id
                "
                class="pa-0"
              >
                <!-- imagem -->
                <v-row v-bind="prop.row" class="pa-0">
                  <v-col v-bind="prop.col" v-if="!soItensSemImagem" cols="3">
                    <v-img
                      style="width: fit-content; height: fit-content"
                      v-if="el.imagens[0].endereco"
                      alt="imagem"
                      contain
                      height="300"
                      :src="el.imagens[0].endereco"
                      width="300"
                    />
                    <v-img
                      v-else
                      style="width: fit-content; height: fit-content"
                      alt="imagem"
                      contain
                      :src="getConst('app').urlSemImagem"
                    />
                  </v-col>
                  <!-- nome produto  -->
                  <v-col v-bind="prop.col" :cols="!soItensSemImagem ? 9 : 12">
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <span class="font-weight-medium py-1 text-body-1">
                          {{ produtoFormat(el) }}
                        </span>
                      </v-col>
                    </v-row>

                    <!-- parceiro -->
                    <v-row v-bind="prop.row">
                      <v-col v-bind="prop.col">
                        <v-chip
                          class="primary primary--text text-overline"
                          outlined
                          x-small
                        >
                          {{ el.parceiro }}
                        </v-chip>
                      </v-col>
                    </v-row>

                    <!-- estado -->
                    <v-row
                      v-bind="prop.row"
                      v-if="el.idEstado != getEstado('ativo').id"
                    >
                      <v-col v-bind="prop.col">
                        <v-chip
                          class="text-overline white--text"
                          :class="getEstado(el.idEstado).nomeAlt"
                          x-small
                        >
                          {{ translate(getEstado(el.idEstado).nomeAlt) }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-bind="prop.row">
                  <!-- favoritar -->
                  <v-col v-bind="prop.col" cols="1">
                    <c-btn icon @click.stop="favoritar(el)">
                      <v-icon
                        :class="
                          el.favorito
                            ? 'text--darken-2 yellow--text'
                            : 'grey--text'
                        "
                      >
                        {{ el.favorito ? "star" : "star_outline" }}
                      </v-icon>
                    </c-btn>
                  </v-col>
                  <!-- preco -->
                  <v-col
                    v-bind="prop.col"
                    align="end"
                    :cols="el.preco.extrato ? 10 : 11"
                  >
                    <c-btn
                      class="px-3 text-body-2"
                      :color="getPrecoColor(el)"
                      :loading="el.loading"
                      @click.stop="clickProduto(el)"
                    >
                      <!-- @click.stop="clickComprar(el)" -->
                      <span v-if="el.preco.preco" class="text-body-1">
                        {{
                          numberFormat(
                            parseFloat(el.preco.preco),
                            "currency",
                            el.decimalValor
                          )
                        }}
                      </span>
                      <span v-else-if="el.preco" class="text-body-1">
                        {{
                          numberFormat(
                            parseFloat(el.preco),
                            "currency",
                            el.decimalValor
                          )
                        }}
                      </span>
                      <span
                        v-if="
                          el.idUnidadeMedida !=
                          getTipo_item('unidadeUnidade').id
                        "
                        class="mt-2 text-lowercase"
                      >
                        /{{ getTipo_item(el.idUnidadeMedida).codigo }}
                      </span>
                    </c-btn>
                  </v-col>
                  <v-col
                    v-bind="prop.col"
                    v-if="el.preco.extrato"
                    align="end"
                    cols="1"
                  >
                    <c-btn class="ml-n1" icon @click.stop="clickExtrato(el)">
                      <v-icon>mdi-help-circle-outline</v-icon>
                    </c-btn>
                  </v-col>
                </v-row>
              </v-container>

              <v-container
                v-else-if="
                  idTipoBusca == getConst('config').tipoBusca.parceiros.id
                "
              >
                <!-- nome alt -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <span class="text-body-1">
                      {{ el.nomeAlt }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>

              <v-container
                v-else-if="
                  idTipoBusca == getConst('config').tipoBusca.marcas.id
                "
              >
                <!-- marca -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <span class="text-body-1">
                      {{ el.marca }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          v-if="
            !isTouchDevice && !btnInjectClicked && buscaRetorno.length >= limit
          "
          class="mt-3"
          fab
          small
          @click="swipeInject"
        >
          <v-icon>mdi-plus</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <v-btn
      v-scroll="onScroll"
      v-show="showFab"
      bottom
      class="mb-11"
      fab
      fixed
      right
      @click="toScroll"
    >
      <v-icon large>
        {{ iconScroll }}
      </v-icon>
    </v-btn>

    <c-the-navbar
      v-show="showBottom"
      bottom
      mode="page"
      :slots="buttonsBar.length"
      title=""
    >
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-base-enderecos
          v-if="button.nome == 'optionEnderecos' && ativo"
          :key="i"
          @aplicar="enderecosAplicar()"
        />
        <span v-else :key="i">
          <v-menu v-if="Array.isArray(button)" :key="i" offset-y top>
            <template #activator="{ on }">
              <c-btn icon v-on="on">
                <v-icon large>mdi-dots-horizontal</v-icon>
              </c-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(option, i) in button"
                :key="i"
                @click="clickOption(option)"
              >
                <v-icon :class="`${option.options.color}--text`">
                  {{ option.options.icon }}
                </v-icon>
                <v-list-item-title class="ml-2">
                  {{ option.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <c-btn v-else icon @click="clickOption(button)">
            <v-icon large color="white">
              {{ button.options.icon }}
            </v-icon>
          </c-btn>
          <v-badge
            v-if="button.nomeAlt == 'carrinho'"
            :color="
              getVar('pendencias').carrinhoAtualItens.priority <= 1
                ? 'red'
                : 'blue'
            "
            :content="getVar('pendencias').carrinhoAtualItens.value"
            left
            :value="getVar('pendencias').carrinhoAtualItens.priority"
          ></v-badge>
        </span>
      </template>
    </c-the-navbar>

    <c-base-dialog-extrato
      v-if="showExtrato"
      :configuracao="configuracaoExtrato"
      :produto="produtoExtrato"
      :show="showExtrato"
      @close="showExtrato = false"
    />

    <c-base-dialog-comprar
      v-if="showComprar"
      :produto="produtoComprar"
      :show="showComprar"
      @close="
        showComprar = false;
        produtoComprar = {};
      "
      @favoritar="favoritar"
    />
  </div>
</template>

<script>
/// IMPORT
import { format } from "date-fns";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CBaseImage from "@/components/bases/CBaseImage";
import CBaseBuscaLayout from "@/components/bases/CBaseBuscaLayout";
import CBaseCategorias from "@/components/bases/CBaseCategorias";
import CBaseDialogComprar from "@/components/bases/CBaseDialogComprar";
import CBaseDialogExtrato from "@/components/bases/CBaseDialogExtrato";
import CBaseEnderecos from "@/components/bases/CBaseEnderecos";
import CBasePreco from "@/components/bases/CBasePreco";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "Busca",

  /// COMPONENTS
  components: {
    CBaseImage,
    CBaseBuscaLayout,
    CBaseCategorias,
    CBaseDialogComprar,
    CBaseDialogExtrato,
    CBaseEnderecos,
    CBasePreco,
    CTheNavbar,
  },

  /// MIXINS
  mixins: [
    mixinCarrinhos,
    mixinData,
    mixinLib,
    mixinSnackbar,
    mixinSwipe,
    mixinUsuarios,
    mixinUtil,
  ],

  /// HEAD
  head: {
    title() {
      return {
        inner: this.getPagina("busca").nomeAlt,
      };
    },
  },

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];
    const getOperacao = this.$store.getters["operacoes/getOperacao"];

    return {
      idFilter: null,
      idOrder: getFeature("orderOrdenar").id,
      idPage: getPagina("busca").id,
      ativo: false,
      buscaCards: [],
      buscaRetorno: [],
      buscaRetornoTemp: [],
      produtoExtrato: null,
      isEntregar: getVar("isEntregar"),
      isChangeClienteRun: false,
      getBuscaRunning: false,
      idFinalidade: null,
      idLocalidade: null,
      idOperacao: getOperacao("operacaoSaidaVendaProduto").id,
      idParceiro: getVar("idPessoaParceiro"),
      idPessoaParceiro: getVar("idPessoaParceiro"),
      idRegimeTributario: null,
      idTipoBusca: -1,
      isAreaLivreComercio: false,
      isBuscaLayout: true,
      isContribuinte: true,
      limit: 50, //?eee colocar em constantes
      localidades: [],
      manterCor: false,
      model: null,
      ordem: "asc",
      panels: [],
      panelsOpen: [],
      parceiro: null,
      produtoComprar: {},
      search: "",
      searchMarca: { id: null, nomeAlt: null },
      searchParceiro: { id: null, nomeAlt: null },
      showComprar: false,
      showExtrato: false,
      showSearchOptions: false,
      soItemComEstoque: false,
      usarCategoriasBusca: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getCategoria: "categorias/getCategoria",
      getConst: "constantes/getConst",
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getLocalidade: "localidades/getLocalidade",
      getLocalidades: "localidades/getLocalidades",
      getOperacao: "operacoes/getOperacao",
      getOperacoes: "operacoes/getOperacoes",
      getPagina: "paginas/getPagina",
      getPagina_documentos: "paginas/getPagina_documentos",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getUsuario: "variaveis/getUsuario",
      getVar: "variaveis/getVar",
    }),

    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },

    buscaRetornoFilterList() {
      return this.buscaRetorno.filter(
        (item) =>
          !this.soItemComEstoque ||
          (item.estoqueProprio && item.estoqueProprio > 0)
      );
    },

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true,
        null
      ).sortKeys('"order"');
    },

    buttonsBar() {
      return [
        ...this.buttons.map((button) => {
          return {
            ...button,
          };
        }),
        this.options,
      ];
    },

    categoriasSelected() {
      return this.usarCategoriasBusca
        ? this.getVar("categoriasSelected")
        : [null, null, []];
    },

    colsCard() {
      return ["lg", "xl"].includes(this.breakpointName)
        ? 3
        : ["sm", "md"].includes(this.breakpointName)
        ? 4
        : 6;
    },

    dadosParceiro() {
      return this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      );
    },

    filter() {
      if (!this.filters.length && this.idFilter) {
        return {
          ...this.getFeature(this.idFilter),
          idFeature: this.idFilter,
        };
      } else {
        return this.filters.find((filter) => filter.id == this.idFilter);
      }
    },

    filters() {
      const filters = this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureFilter").id
      );

      return filters;
    },

    finalidades() {
      return this.getTipo_itens(this.getTipo(`tipoFinalidade`).id);
    },

    hasFilterFavoritos() {
      return this.filters.some(
        (filter) => filter.id == this.getFeature("filterFavoritos").id
      );
    },

    iconFavorito() {
      return this.hasFilterFavoritos
        ? this.idFilter == this.getFeature("filterFavoritos").id
          ? "star"
          : "star_outline"
        : "";
    },

    iconFavoritoClass() {
      return this.idFilter == this.getFeature("filterFavoritos").id
        ? "text--darken-1 yellow--text"
        : "grey--text";
    },

    iconLayout() {
      return this.isBuscaLayout
        ? "mdi-view-list"
        : this.isBuscaLayout === false
        ? "mdi-view-sequential"
        : "mdi-view-grid";
    },

    idLocalidadeExtrato() {
      const localidadeCliente = this.parceiroCliente?.enderecos
        ? this.getLocalidade(
            this.parceiroCliente.enderecos.find(
              (endereco) => endereco.order == 1
            ).idCidade
          ).idPai
        : null;

      return localidadeCliente || this.idLocalidade;
    },

    isCompra() {
      return (
        this.isUsuario ||
        this.searchParceiro.id != this.getVar("idPessoaParceiro")
      );
    },

    isUsuario() {
      return this.getVar("idUsuario") == this.getVar("idPessoaParceiro");
    },

    itensPorPagina() {
      return this.getVar("limit")
        .itens.filter(
          //? restrição provisoria para facilitar o desenvolvimento
          (item) => item.value <= (this.getVar("idUsuario") <= 32 ? 100 : 50)
        )
        .map((item) => {
          item.text = `${item.value}/${this.translate("página")}`;
          return item;
        });
    },

    labelClickComprar() {
      return (
        (this.idTipoBusca == this.getConst("config").tipoBusca.produtos.id &&
          !this.getVar("usuarios").length) ||
        (this.getVar("usuarios").length &&
          this.getVar("usuarios")[0].pedidos <=
            this.getConst("config").busca.labelComprar &&
          this.panels.length)
      );
    },

    labelContribuinte() {
      let ret = "";

      if (this.isContribuinte) {
        ret = "cliente contribuinte";
      } else {
        ret = "cliente não contribuinte";
      }

      return ret;
    },

    localidadeIsAreaLivreComercio() {
      return (
        this.getLocalidade(this.idLocalidade)?.options?.isAreaLivreComercio ||
        false
      );
    },

    logado() {
      return !!this.getVar("idPessoaParceiro");
    },

    idPessoaParceiroSelecionado() {
      return this.idParceiro || this.idPessoaParceiro;
    },

    operacoes() {
      return this.getOperacoes()
        .filter(
          (operacao) =>
            !this.getOperacao(operacao.value).isEntrada &&
            this.getOperacao(operacao.value).hasCliente &&
            this.getOperacao(operacao.value).options.hasResultado
        )
        .map((operacao) => {
          return {
            value: operacao.value,
            text: this.getOperacao(operacao.value).nomeAlt,
          };
        });
    },

    options() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        false,
        null
      ).sortKeys('"order"');
    },

    order() {
      if (!this.filters.length && this.idFilter) {
        return {
          ...this.getFeature(this.idOrder),
          idFeature: this.idOrder,
        };
      } else {
        return this.orders.find((order) => order.id == this.idOrder);
      }
    },

    orders() {
      const orders =
        this.direitosFeatures(
          this.idPage,
          this.getTipo_item("featureOrder").id
        ) || [];

      return orders;
    },

    parceiroCliente: {
      get: function () {
        return this.getVar("parceiroCliente").value;
      },
      set: function (newValue) {
        this.setVar({ parceiroCliente: newValue });
      },
    },

    page() {
      return this.getPagina(this.idPage);
    },

    produtosEstados() {
      const produtosEstados = [
        ...new Set(this.buscaCards.map((item) => item.idEstado)),
      ];
      produtosEstados.push(-1); // todos

      return produtosEstados;
    },

    produtosGrupos() {
      return [
        ...new Set(
          this.buscaRetorno
            .filter(
              (item) =>
                !this.soItemComEstoque ||
                (item.estoqueProprio && item.estoqueProprio > 0)
            )
            .map((item) => item.grupo)
        ),
      ];
    },

    showCliente() {
      const servicosERP = ["ERP pedidos", "ERP faturamento"];

      const hasServicoERPContratado = this.getUsuario(
        this.getVar("idPessoaParceiro")
      ).servicosContratados?.some(
        (servicoContratado) =>
          servicosERP.includes(servicoContratado.produto) &&
          new Date(servicoContratado.dataInicio) <= new Date()
      );

      if (!hasServicoERPContratado) {
        return false;
      } else if (this.idPessoaParceiro != this.getConst("app").id) {
        return true;
      } else if (this.idParceiro) {
        return true;
      }

      return false;
    },

    regimesTributarios() {
      return this.getTipo_itens(this.getTipo(`tipoRegimeTributario`).id);
    },

    storeIdPessoaEndereco() {
      return this.getVar("idPessoaEndereco");
    },

    tiposBusca() {
      return [
        { value: 0, text: "publicações" },
        { value: 1, text: "produtos" },
        { value: 2, text: "parceiros" },
        { value: 3, text: "marcas" },
      ];
    },
  },

  /// WATCH
  watch: {
    categoriasSelected() {
      this.getBusca();
    },

    entregar(valor) {
      if (valor == null) this.getBusca();
    },

    idFilter() {
      if (
        this.idFilter != this.getFeature("filterFiltrar").id &&
        this.isBuscaLayout === null
      ) {
        this.isBuscaLayout = true;
      }
    },

    idFinalidade: {
      handler() {
        this.changeFinalidade();
      },
      immediate: true,
    },

    idOrder() {
      if (
        this.idOrder != this.getFeature("orderOrdenar").id &&
        this.isBuscaLayout === null
      ) {
        this.isBuscaLayout = true;
      }
    },

    idTipoBusca(valor, valorAnterior) {
      if (valorAnterior == -1)
        this.idTipoBusca = parseInt(this.$route.params.idTipoBusca);

      this.buscaRetornoTemp = [];
      this.buscaRetorno = [];
      this.search = "";
      this.soItemComEstoque = false;
      this.getBusca();
    },

    isBuscaLayout() {
      if (this.isBuscaLayout === null) {
        this.idOrder = this.getFeature("orderOrdenar").id;
        this.idFilter = this.getFeature("filterFiltrar").id;
      }
    },

    parceiroCliente: {
      handler() {
        if (
          !this.isChangeClienteRun &&
          this.searchParceiro.id == this.getVar("idPessoaParceiro")
        ) {
          this.changeCliente();
        }
      },
      deep: true,
      immediate: true,
    },

    searchParceiro: {
      handler() {
        this.isBuscaLayout = this.searchParceiro.id !== null ? null : true;
      },
      deep: true,
      immediate: true,
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    changeOrderList() {
      this.ordem = this.ordem == "asc" ? "desc" : "asc";
    },

    clickFavoritos() {
      this.idFilter =
        this.idFilter != this.getFeature("filterFavoritos").id
          ? this.getFeature("filterFavoritos").id
          : this.filters[0].id;

      this.getBusca();
    },

    quantidadeRetorno(idEstado) {
      return idEstado != -1
        ? this.buscaCards.filter(
            (item) =>
              item.idEstado == idEstado &&
              (!this.soItemComEstoque ||
                (item.estoqueProprio && item.estoqueProprio > 0))
          ).length
        : this.buscaRetorno.filter(
            (item) =>
              !this.soItemComEstoque ||
              (item.estoqueProprio && item.estoqueProprio > 0)
          ).length;
    },

    buscaRetornoClear() {
      if (!this.search || this.search.length == 0) {
        this.buscaRetornoTemp = [];
        this.buscaRetorno = [];
      }
    },

    cardAction(el) {
      if (
        this.idTipoBusca == this.getConst("config").tipoBusca.publicacoes.id
      ) {
        if (!el.visualizou) this.visualizar(el);

        if (el.idTipo == this.getTipo_item("publicacaoNews").id) {
          this.$router.push(`/publicacao/${el.id}`);
        } else if (el.idTipo == this.getTipo_item("publicacaoProduto").id) {
          this.$router.push(`/produto/${el.idPublicado}`);
        } else if (el.idTipo == this.getTipo_item("publicacaoParceiro").id) {
          this.searchParceiro.id = el.idPessoaParceiro;
          this.searchParceiro.nomeAlt = el.parceiro;
          this.searchParceiro.corPrimaria = el.corPrimaria;
          this.searchMarca.id = null;
          this.searchMarca.nomeAlt = null;
          this.idTipoBusca = this.getConst("config").tipoBusca.produtos.id;
        } else if (el.idTipo == this.getTipo_item("publicacaoMarca").id) {
          this.searchParceiro.id = null;
          this.searchParceiro.nomeAlt = null;
          this.searchParceiro.corPrimaria = null;
          this.searchMarca.id = el.marca;
          this.searchMarca.nomeAlt = el.marca;
          this.idTipoBusca = this.getConst("config").tipoBusca.produtos.id;
        }
      } else if (
        this.idTipoBusca == this.getConst("config").tipoBusca.produtos.id
      ) {
        this.manterCor = true;
        this.$router.push(`/produto/${el.id}`);
      } else {
        if (
          this.idTipoBusca == this.getConst("config").tipoBusca.parceiros.id
        ) {
          this.searchParceiro.id = el.id;
          this.searchParceiro.nomeAlt = el.nomeAlt;
          this.searchParceiro.corPrimaria = el.corPrimaria;
          this.$vuetify.theme.themes.light.primary = el.corPrimaria;
        }
        if (this.idTipoBusca == this.getConst("config").tipoBusca.marcas.id) {
          this.searchMarca.id = el.id;
          this.searchMarca.nomeAlt = el.marca;
        }

        this.idTipoBusca = this.getConst("config").tipoBusca.produtos.id;
      }
    },

    changeCliente() {
      this.isChangeClienteRun = true;
      this.idLocalidade = null;
      this.idRegimeTributario = null;
      this.isAreaLivreComercio = null;
      this.isContribuinte = null;

      this.idFinalidade =
        this.parceiroCliente?.idTipo == this.getTipo_item("pessoaFisica").id
          ? this.getTipo_item("finalidadeConsumo").id
          : this.getTipo_item("finalidadeComercializacao").id;

      const parceiroCliente = {
        idPessoa: this.parceiroCliente?.value || null,
        nome: this.parceiroCliente?.nome || null,
        idTipo: this.parceiroCliente?.idTipo || null,
        enderecos: this.parceiroCliente?.enderecos || null,
        value: this.parceiroCliente?.value || null,
        text: this.parceiroCliente?.text || this.parceiroCliente?.nome || null,
        textAlt:
          this.parceiroCliente?.textAlt || this.parceiroCliente?.nome || null,
      };

      const localidade = parceiroCliente.enderecos
        ? parceiroCliente.enderecos[0]
        : this.getVar("usuarios").filter(
            (usuario) => usuario.pessoa.id == this.idPessoaParceiro
          )[0]?.enderecos[0];

      const idPessoaEndereco = localidade?.id;

      const hasLocalidade = this.localidades.some(
        (localidadeList) =>
          localidadeList.value == this.getLocalidade(localidade.idCidade).idPai
      );

      if (!hasLocalidade) {
        this.localidades.push({
          value: this.getLocalidade(localidade.idCidade).idPai,
          text: this.getLocalidade(
            this.getLocalidade(localidade.idCidade).idPai
          ).nome,
        });
      }

      this.idLocalidade = this.getLocalidade(localidade.idCidade).idPai;

      this.setVar({ idPessoaEndereco });
      this.carrinhosAtualizar();

      this.isChangeClienteRun = false;
    },

    changeFinalidade() {
      if (
        ![
          this.getTipo_item("finalidadeIndustrializacao").id,
          this.getTipo_item("finalidadeComercializacao").id,
        ].includes(this.idFinalidade)
      ) {
        this.idRegimeTributario = null;
        this.isContribuinte = null;
        this.isAreaLivreComercio = null;
      } else {
        const finalidade = this.getTipo_item(this.idFinalidade);

        this.idRegimeTributario = finalidade.options.idRegimeTributario;
        this.isContribuinte = finalidade.options.isContribuinte;
        this.isAreaLivreComercio = finalidade.options.isAreaLivreComercio;
      }
    },

    changeLocalidade() {
      this.isAreaLivreComercio = false;
    },

    chipSearchMarcaClose() {
      this.searchMarca.id = null;
      this.searchMarca.nomeAlt = null;

      this.getBusca();
    },

    chipSearchParcerioClose() {
      this.searchParceiro.id = null;
      this.searchParceiro.nomeAlt = null;
      this.searchParceiro.corPrimaria = null;

      this.$vuetify.theme.themes.light.primary =
        this.getUsuario(this.getVar("idUsuario"))?.parceiro?.corPrimaria ||
        this.getConst("app").corPrimaria;

      this.getBusca();
    },

    clickComentar(el) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      const url = `/comentarios/${el.id}/${null}`;
      this.$router.push({
        path: url,
        query: { publicacao: el },
      });
    },

    clickCompartilhar(el, on, event) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      let url = window.location.origin;

      if (el.idTipo == this.getTipo_item("publicacaoNews").id) {
        url += `/publicacao/${el.id}`;
      } else if (el.idTipo == this.getTipo_item("publicacaoProduto").id) {
        url += `/produto/${el.idPublicado}`;
      } else if (el.idTipo == this.getTipo_item("publicacaoParceiro").id) {
        url += `/busca/${
          this.getConst("config").tipoBusca.produtos.id
        }?idPessoaParceiro=${el.idPessoaParceiro}&parceiro=${el.parceiro}`;
      } else if (el.idTipo == this.getTipo_item("publicacaoMarca").id) {
        url += `/busca/${this.getConst("config").tipoBusca.produtos.id}?marca=${
          el.marca
        }`;
      }

      this.copyToClipboard(url);

      if (navigator.share) {
        navigator
          .share({
            title: `${el.titulo} #${el.id}`,
            text: `${el.titulo} #${el.id}`,
            url,
          })
          .then(() => {
            const publicacao = {
              id: el.id,
              idUsuario: this.getVar("idUsuario"),
            };

            const url = `${
              this.getConst("app").baseUrlBackend
            }/publicacoes/compartilhar`;

            axios
              .put(url, publicacao)
              .then((res) => {
                el.compartilhadas = res.data.compartilhadas;
                el.compartilhou = res.data.compartilhou;
              })
              .catch((err) => {
                this.setSnackbar(true, "ERRO", err.response.data, "error");
              });
          });
      } else {
        this.clickOnTooltip(on, event);
      }
    },

    clickCurtir(el) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      el.isCurtiu = !el.isCurtiu;

      const publicacao = {
        id: el.id,
        idComentario: null,
        idUsuario: this.getVar("idUsuario"),
        isCurtiu: el.isCurtiu,
      };

      const url = `${this.getConst("app").baseUrlBackend}/publicacoes/curtir`;

      axios
        .put(url, publicacao)
        .then((res) => {
          el.curtidas = res.data.curtidas;
          el.isCurtiu = res.data.isCurtiu;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },

    clickExtrato(element) {
      const localidadeCliente = this.parceiroCliente.enderecos
        ? this.getLocalidade(
            this.parceiroCliente.enderecos.find(
              (endereco) => endereco.order == 1
            ).idCidade
          ).idPai
        : null;
      this.showExtrato = true;
      this.configuracaoExtrato = {
        prazo: 0,
        localidade: localidadeCliente
          ? this.getLocalidade(localidadeCliente).codigo2
          : this.getLocalidade(this.idLocalidade).codigo2,
      };
      this.produtoExtrato = element.element;
    },

    clickLayout() {
      if (!this.isCompra || this.searchParceiro.id) {
        if (this.isBuscaLayout === null) {
          this.isBuscaLayout = true; // list
        } else if (this.isBuscaLayout && !this.isCompra) {
          this.isBuscaLayout = false; // list sem imagem
        } else {
          this.isBuscaLayout = null; // card
        }
      }
    },

    clickProduto(el) {
      this.produtoComprar = el;
      document.documentElement.classList.add("overflow-y-hidden");
      this.showComprar = true;
    },

    clickOption(option) {
      if (option.nomeAlt == "carrinho") {
        this.clickCarrinho();
      } else if (option?.options?.click == "clickAjuda") {
        const paginaDocumento = this.getPagina_documentos(
          this.getPagina("ajuda").id
        ).find(
          (paginaDocumento) =>
            paginaDocumento.options.feature &&
            this.getFeature(paginaDocumento.options.feature).id ==
              this.getPagina(this.idPage).idFeature
        );

        const url = paginaDocumento ? `/ajuda#${paginaDocumento.id}` : "/ajuda";
        this.$router.push(encodeURI(url));
      } else {
        this.$router.push(option.options.route);
      }
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? (this.panelsOpen = [])
        : Array(this.produtosEstados.length)
            .fill()
            .map((_, i) => i);
    },

    clickParceiro() {
      this.manterCor = true;
      this.$router.push(`/parceiro/${this.parceiro.id}`);
    },

    clickShowSearchOptions() {
      this.showSearchOptions = !this.showSearchOptions;
    },

    clickSnackbar(action) {
      if (action == "login") {
        this.$router.push({
          path: "/",
          query: { showAuth: true },
        });
        this.setSnackbar();
      } else if (action == "openEnderecos") {
        this.setVar({ showEnderecos: true });
        this.setSnackbar();
      } else if (action == "itemIncluir") {
        this.snackbar.params = true;
        this.snackbar.show = false;
      } else if (action == "clickAjuda") {
        const url = "/ajuda#menuEnderecos";
        this.$router.push(encodeURI(url));
        this.setSnackbar();
      } else if (action == "carrinho") {
        this.clickCarrinho();
        this.setSnackbar();
      }
    },

    enderecosAplicar() {
      this.isEntregar = this.getVar("isEntregar");
      this.getBusca();
    },

    favoritar(el) {
      if (!this.logado) {
        this.drawer = false;
        this.setSnackbar(
          true,
          "LOGIN",
          "Oops... você precisa fazer login.",
          "warning",
          "mdi-login",
          "login"
        );
        return;
      }

      el.favorito = !el.favorito;

      const produto = {
        id:
          this.idTipoBusca == this.getConst("config").tipoBusca.produtos.id
            ? el.id
            : el.idPublicado,
        idUsuario: this.getVar("idUsuario"),
        idPessoaParceiro: this.getVar("idPessoaParceiro"),
        favorito: el.favorito,
      };

      const url = `${this.getConst("app").baseUrlBackend}/produtos/favoritar`;

      axios
        .put(url, produto)
        .then((res) => {
          el.favorito = res.data.favorito;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },

    async getBusca(offset = 0, isCard = false) {
      if (this.getVar("overlay") || !this.ativo) return;

      this.setVar({ overlay: true });
      const search = this.search || "*";
      const idContexto = "*";
      const idPessoaParceiro =
        this.parceiroCliente?.idPessoa || this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;
      const url = `${
        this.getConst("app").baseUrlBackend
      }/busca/${search}/${idContexto}/${this.idFilter}/${
        this.idOrder
      }/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${this.limit}`;

      let coordenada;

      try {
        coordenada = await this.getPosition();
      } catch (err) {
        coordenada = {
          latitude: 0,
          longitude: 0,
        };

        this.setSnackbar(
          true,
          "LOCALIZAÇÃO",
          err,
          "warning",
          "mdi-check",
          "ajuda"
        );
      }

      const now = format(new Date(), "yyyy-MM-dd HH:mm:ss");

      const params = {
        idTipoBusca: this.idTipoBusca,
        categoriasSelected: this.categoriasSelected,
        isEntregar: this.isEntregar,
        idPessoaEndereco: this.getVar("idPessoaEndereco"),
        searchIdPessoaParceiro: this.searchParceiro.id,
        searchMarca: this.searchMarca.id,
        coordenada,
        now,
        idOperacao: this.idOperacao,
        idCliente: this.parceiroCliente?.value || null,
        idLocalidade: this.idLocalidade,
        idRegimeTributario: this.idRegimeTributario,
        idFinalidade: this.idFinalidade,
        isAreaLivreComercio: this.isAreaLivreComercio,
        isBuscaLayout: this.isBuscaLayout,
        isContribuinte: this.isContribuinte,
        isCard,
        ordem: this.ordem ? this.ordem : null,
      };

      axios
        .get(url, { params })
        .then((res) => {
          const result = res.data;
          if (offset == 0) {
            if (isCard) {
              this.buscaCards = [];
            } else {
              this.buscaRetornoTemp = [];
              this.buscaRetorno = [];
            }
          }

          this.parceiro = null;

          if (
            this.searchParceiro.id &&
            this.idTipoBusca == this.getConst("config").tipoBusca.produtos.id
          ) {
            this.parceiro = JSON.parse(JSON.stringify(result[0]));
            result.splice(0, 1);
          }

          if (isCard) {
            this.buscaCards.push(...result);
          } else {
            this.buscaRetorno.push(...result);

            if (
              !(
                this.searchParceiro.id &&
                this.idTipoBusca ==
                  this.getConst("config").tipoBusca.produtos.id
              )
            ) {
              this.buscaRetorno.forEach((item) => (item.grupo = null));
            }

            this.buscaRetornoTemp = JSON.parse(
              JSON.stringify(this.buscaRetorno)
            );
          }

          if (offset == 0) {
            this.panelsOpen = [];
            this.clickPanels();
          }
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
            this.btnInjectClicked = false;

            if (
              offset == 0 &&
              !isCard &&
              this.isBuscaLayout === null &&
              this.idTipoBusca == this.getConst("config").tipoBusca.produtos.id
            ) {
              this.getBusca(offset, true);
            }
          }, this.getConst("config").overlay.timeout);
        });
    },

    getGroup(element, index = null) {
      let value = "";

      if (this.order.groupBy == "estado") {
        value = this.getEstado(element.idEstado).nomeAlt;
      } else {
        value =
          typeof element[this.order.groupBy] == "object"
            ? element[this.order.groupBy][this.getVar("locale")]
            : element[this.order.groupBy];
      }

      if (index) {
        if (this.getGroup(this.buscaRetornoFilterList[index - 1]) == value) {
          value = "";
        }
      }

      return value;
    },

    heightCard(idEstado) {
      const percentual =
        idEstado == this.getEstado("promocao").id
          ? ["xs", "sm"].includes(this.breakpointName)
            ? 0.5 * (window.orientation == 0 ? 1 : 1.8)
            : 0.45
          : ["xs", "sm"].includes(this.breakpointName)
          ? 0.45 * (window.orientation == 0 ? 1 : 1.7)
          : 0.35;

      const heightCard = window.innerHeight * 0.95 * percentual;
      return heightCard;
    },

    heightImage(idEstado) {
      const percentual =
        idEstado == this.getEstado("promocao").id
          ? this.breakpointName == "xl"
            ? 0.6
            : 0.5
          : this.breakpointName == "xl"
          ? 0.45
          : 0.35;

      return this.heightCard(idEstado) * percentual;
    },

    panelsCollapse() {
      if (this.panels.length == this.produtosGrupos.length) {
        this.panels = [];
      } else {
        this.panels = this.produtosGrupos.map((produtosGrupo, i) => i);
      }
    },

    swipeInject() {
      this.btnInjectClicked = true;
      this.getBusca(this.buscaRetorno.length);
    },

    swipeRefresh() {
      this.getBusca();
    },

    visualizar(el) {
      if (!this.logado) {
        this.drawer = false;
        return;
      }

      el.visualizou = !el.visualizou;

      const publicacao = {
        id: el.id,
        idUsuario: this.getVar("idUsuario"),
      };

      const url = `${
        this.getConst("app").baseUrlBackend
      }/publicacoes/visualizar`;

      axios
        .post(url, publicacao)
        .then((res) => {
          el.visualizadas = res.data.visualizadas;
          el.visualizou = res.data.visualizou;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },

    widthCard(produtosEstado) {
      const percentual =
        produtosEstado == this.getEstado("promocao").id
          ? ["xs", "sm"].includes(this.breakpointName)
            ? 0.8
            : 0.4
          : ["xs", "sm"].includes(this.breakpointName)
          ? 0.6
          : 0.2;

      return window.innerWidth * percentual;
    },
  },

  /// ACTIVATED
  activated() {
    this.buscaRetorno = JSON.parse(JSON.stringify(this.buscaRetornoTemp));

    this.$vuetify.goTo(0);

    const hasSearch = Object.keys(this.$route.query).length > 1;

    this.parceiro = null;

    if (this.$route.query.idPessoaParceiro) {
      this.searchParceiro = { id: null, nomeAlt: null };
      this.idTipoBusca = -1;
      this.searchParceiro.id = this.$route.query.idPessoaParceiro;
      this.searchParceiro.nomeAlt = this.$route.query.parceiro;
      this.searchParceiro.corPrimaria = this.$route.query.parceiroCorPrimaria;
      this.searchMarca.id = null;
      this.searchMarca.nomeAlt = null;
    }

    if (this.$route.query.marca) {
      this.searchMarca = { id: null, nomeAlt: null };
      this.idTipoBusca = -1;
      this.searchParceiro.id = this.$route.query.idPessoaParceiro;
      this.searchParceiro.nomeAlt = this.$route.query.parceiro;
      this.searchParceiro.corPrimaria = this.$route.query.parceiroCorPrimaria;
      this.searchMarca.id = this.$route.query.marca;
      this.searchMarca.nomeAlt = this.$route.query.marca;
    }

    if (this.idTipoBusca < 0) {
      this.idTipoBusca = parseInt(this.$route.params.idTipoBusca);
    }

    if (this.searchParceiro.id) {
      this.$vuetify.theme.themes.light.primary =
        this.searchParceiro.corPrimaria;
    }

    if (!this.ativo) {
      this.buscaRetornoTemp = [];
      this.buscaRetorno = [];
      this.search = "";

      if (hasSearch) {
        this.ativo = true;
        this.getBusca();
      } else {
        setTimeout(() => {
          this.ativo = true;
        }, 10);
      }
    }
    this.getBusca();
  },

  /// DEACTIVATED
  deactivated() {
    this.buscaRetorno = [];
    this.ativo = false;

    if (!this.manterCor) {
      this.$vuetify.theme.themes.light.primary =
        this.getUsuario(this.getVar("idPessoaParceiro"))?.parceiro
          ?.corPrimaria || this.getConst("app").corPrimaria;
    }

    this.manterCor = false;
  },

  /// MOUNTED
  mounted() {
    if (!this.isUsuario) {
      this.searchParceiro.id = this.dadosParceiro.pessoa.id;
      this.searchParceiro.nomeAlt = this.dadosParceiro.pessoa.nomeAlt;
      this.searchParceiro.corPrimaria = this.dadosParceiro.parceiro.corPrimaria;

      const idCategoria = this.dadosParceiro.parceiro.categorias[0];

      if (idCategoria) {
        const categoria = this.getCategoria(idCategoria);

        if (categoria?.options?.idFinalidade) {
          this.idFinalidade = categoria.options.idFinalidade;
        }
      }
    }

    if (this.filters.length) {
      this.idFilter = (
        this.filters.find((filter) => filter.options?.isDefault) ||
        this.filters[0]
      ).idFeature;
    }

    const endereco = this.getUsuario(this.idPessoaParceiro)?.enderecos?.find(
      (endereco) => endereco.order == 1
    );

    this.localidades = this.getLocalidades(
      this.getTipo_item("localidadeEstado").id,
      endereco?.idPais || this.getLocalidade("Brasil").id
    );

    const reloadComponents = this.getVar("reloadComponents");
    const component = this.getPagina(this.idPage).nome.firstLetterToUpperCase();

    if (!reloadComponents.includes(component)) {
      reloadComponents.push(component);
      this.setVar({ reloadComponents });
    }

    setTimeout(() => {
      this.ativo = true;
    }, 10);
  },
};
</script>

<style scoped>
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>