var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{attrs:{"funcs":_vm.funcsDetail,"idFeature":_vm.idFeature,"idPage":_vm.idPageDetail,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
var pageModeView = ref.pageModeView;
return [(pageModeView)?_c('c-folhaPagamento-view',{attrs:{"context":context,"dataset":_vm.dataset}}):_vm._e()]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}),(_vm.loadList)?_c('c-base-list',{attrs:{"hasSearch":true,"funcs":_vm.funcsList,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"noList":true,"pageModeEdit":true},on:{"showSearchOptions":function($event){_vm.showSearchOptions = $event}},scopedSlots:_vm._u([{key:"searchInSearch",fn:function(ref){
var context = ref.context;
return [_c('v-container',[_c('v-row',_vm._b({staticClass:"mt-n6"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-switch',{staticClass:"ml-n2",attrs:{"help":{
                title: 'ACERTO',
                text: 'Verba de acerto ou todas',
                function: _vm.setUiBottomSheet,
              }},on:{"click":function($event){_vm.idVerbas = []}},model:{value:(_vm.soAcerto),callback:function ($$v) {_vm.soAcerto=$$v},expression:"soAcerto"}})],1),_c('v-col',_vm._b({attrs:{"cols":"10"}},'v-col',_vm.prop.col,false),[_c('c-select',{staticClass:"ml-n1",attrs:{"clearable":"","items":_vm.funcsList.verbas(context, _vm.soAcerto),"label":"verbas","multiple":"","properties":{
                chip: true,
              }},model:{value:(_vm.idVerbas),callback:function ($$v) {_vm.idVerbas=$$v},expression:"idVerbas"}})],1)],1),_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],staticClass:"mt-n2"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"clearable":"","items":_vm.funcsList.colaboradores(context),"label":"colaboradores","multiple":"","properties":{
                chip: true,
              }},model:{value:(_vm.idColaboradores),callback:function ($$v) {_vm.idColaboradores=$$v},expression:"idColaboradores"}})],1)],1)],1)]}},{key:"listContent",fn:function(ref){
              var context = ref.context;
              var datasets = ref.datasets;
return [_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":true,"params":_vm.params,"value":datasets},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var ocorrencia = ref.dataset;
return [_c('v-container',{staticClass:"py-0"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(ocorrencia.nome)+" ")])]),_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.numberFormat(ocorrencia.valor, "currency"))+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(ocorrencia.idVerba)?_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(((ocorrencia.idVerba) + " " + (_vm.getVerba(ocorrencia.idVerba).nomeAlt)))+" ")])]):_vm._e(),(ocorrencia.isAcerto)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v("acerto")])],1):_vm._e()],1)],1)]}},{key:"content",fn:function(ref){
              var ocorrencia = ref.dataset;
              var index = ref.index;
return [(_vm.pageModeView)?_c('c-folhaPagamento-page-ocorrencia-view',{attrs:{"context":context,"ocorrencia":ocorrencia}}):_c('c-folhaPagamento-page-ocorrencia-edit',{attrs:{"context":context,"index":index,"ocorrencia":ocorrencia}})]}}],null,true)})]}},{key:"insert",fn:function(ref){
              var context = ref.context;
return [(!_vm.pageModeView)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.incluir(context)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-plus-circle")])],1)],1)],1):_vm._e()]}}],null,false,2726780909)}):_vm._e(),_c('c-bottom-sheet',{attrs:{"bottomSheet":_vm.bottomSheet}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }