<!-- COD: BJB 07/07/2022 FornecedoresClientes -->
<!-- *VER: JER 08/07/2022 FornecedoresClientes -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ context, dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-fornecedores-clientes :context="context" :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CFornecedoresClientes from "@/components/pages/fornecedorCliente/CFornecedoresClientes";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "FornecedoresClientes",

  /// COMPONENTS
  components: {
    CFornecedoresClientes,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("fornecedoresClientes").id,
      idPageAlt: getPagina("fornecedorCliente").id,

      funcs: {
        fnParams: (context) => {
          const contextParams = context.params
            ? JSON.parse(context.params)
            : null;
          const proprietarios = contextParams?.proprietarios ?? true;

          return JSON.stringify({
            proprietarios,
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo)?.nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado)?.nomeAlt;
        },
      },
    };
  },
};
</script>