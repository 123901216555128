<!-- COD: BJB 27/07/2022 CValoresPagRecPageDepositoBancarioEdit -->
<!-- *VER: JER 05/08/2022 CValoresPagRecPageDepositoBancarioEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- forma pagamento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(valorPagRec.idFormaPagamento).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor selecionado -->
      <v-col v-bind="prop.col" align="end" cols="6">
        <c-checkbox
          v-model="valorPagRec.valorSelecionado"
          @click="clickValorSelecionado"
        />
      </v-col>

      <!-- valor depositar -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="valorPagRec.valorDepositar"
          :disabled="
            valorPagRec.idFormaPagamento ==
            getTipo_item('formaPagamentoCheque').id
          "
          label="valor a depositar"
          :max="20"
          :options="{
            style: 'currency',
            length: 11,
            precision: 2,
          }"
          @clickClear="clickClearValorDepositar"
          @keyup="changeValorDepositar"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor selecionado -->
      <v-col v-bind="prop.col" cols="5">
        <span class="text-body-1">
          {{ dateFormat(valorPagRec.dataApropriacao, "date") }}
        </span>
      </v-col>

      <!-- valor depositar -->
      <v-col v-bind="prop.col" align="end" cols="7">
        <span class="text-body-1">
          {{ numberFormat(valorSaldo, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- numero documento -->
    <v-row v-bind="prop.row" v-if="valorPagRec.numeroDocumento">
      <v-col v-bind="prop.col">
        <span class="text-body-2">documento</span>
        <span class="ml-1 text-body-1">
          {{ valorPagRec.numeroDocumento }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa_nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ valorPagRec.pessoa_nome }}
        </span>
      </v-col>
    </v-row>

    <!-- banco agencia conta -->
    <v-row v-bind="prop.row" v-if="bancoAgenciaConta">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ bancoAgenciaConta }}
        </span>
      </v-col>
    </v-row>

    <!-- identification -->
    <c-base-item-identification
      v-if="valorPagRec.estado"
      :dataset="valorPagRec"
    />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseItemIdentification from "@/components/bases/CBaseItemIdentification";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CValoresPagRecPageDepositoBancarioEdit",

  /// COMPONENTS
  components: { CBaseItemIdentification },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object, required: true },
    valorPagRec: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    bancoAgenciaConta() {
      return this.valorPagRec.banco
        ? `${this.valorPagRec.banco}/${this.valorPagRec.agencia}/${this.valorPagRec.conta}`
        : null;
    },

    valorSaldo() {
      return this.valorPagRec.valor - (this.valorPagRec.valorDepositado || 0);
    },
  },

  /// METHODS
  methods: {
    clickClearValorDepositar() {
      this.valorPagRec.valorDepositar = null;
      this.valorPagRec.valorSelecionado = this.valorPagRec.valorDepositar > 0;
    },

    changeValorDepositar() {
      this.valorPagRec.valorDepositar = Math.min(
        this.valorSaldo,
        this.valorPagRec.valorDepositar
      );

      this.valorPagRec.valorSelecionado = this.valorPagRec.valorDepositar > 0;
    },

    clickValorSelecionado() {
      this.valorPagRec.valorDepositar = !this.valorPagRec.valorSelecionado
        ? null
        : Math.min(
            parseFloat(this.valorPagRec.valorDepositar || this.valorSaldo),
            parseFloat(this.valorSaldo)
          );

      this.valorPagRec.valorSelecionado = this.valorPagRec.valorDepositar > 0;
    },
  },
};
</script>