<!-- COD: LYF 05/07/2022 CFolhaPagamentoEdit -->
<!-- *VER: JER 07/08/2022 CFolhaPagamentoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- valor total -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.valorTotal">
      <v-col v-bind="prop.col" align="center">
        <span class="font-weight-medium text-body-1">
          {{ numberFormat(dataset.main.detail.valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- colaboradores -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.colaboradores">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">colaboradores</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ colaboradores }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data inicio -->
      <v-col v-bind="prop.col" cols="4">
        <c-datetime-picker
          v-model="dataset.main.detail.dataInicio"
          date
          :disabled="dataset.main.detail.idEstado != getEstado(`cadastro`).id"
          label="data início"
        />
      </v-col>
      <!-- data fim -->
      <v-col v-bind="prop.col" cols="4">
        <c-datetime-picker
          v-model="dataset.main.detail.dataFim"
          date
          :disabled="dataset.main.detail.idEstado != getEstado('cadastro').id"
          label="data fim"
        />
      </v-col>
      <!-- data pagamento -->
      <v-col v-bind="prop.col" cols="4">
        <c-datetime-picker
          v-model="dataset.main.detail.dataPagamento"
          date
          :disabled="dataset.main.detail.idEstado != getEstado('cadastro').id"
          label="data pagamento"
          required
        />
      </v-col>
    </v-row>

    <!-- ponto eletronico -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idTipo == getTipo_item('fpNormal').id ||
        dataset.main.detail.idTipo == getTipo_item('fpNormalSemComissoes').id
      "
    >
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idPontoEletronico"
          clearable
          :disabled="dataset.main.detail.idEstado != getEstado('cadastro').id"
          :items="pontosEletronicos"
          label="ponto eletrônico"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhaPagamentoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getTipo_item: "tipos/getTipo_item",
    }),

    colaboradores() {
      return this.context.dataset.main.colaboradores.filter(
        (colaborador) => !colaborador.isDeleted
      ).length;
    },

    pontosEletronicos() {
      return this.dataset.aux.pontosEletronicos.map((pontoEletronico) => {
        return {
          value: pontoEletronico.id,
          text: `#${pontoEletronico.id} (${this.dateFormat(
            pontoEletronico.dataInicio,
            "date"
          )} - ${this.dateFormat(pontoEletronico.dataFim, "date")})`,
        };
      });
    },
  },
};
</script>