<!-- COD: FDS 29/07/2022 PontoEletronico_Apontamentos -->
<!-- *VER: JER 08/08/2022 PontoEletronico_Apontamentos -->
<!-- /33/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPageDetail"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context }">
        <!-- data inicio fim -->
        <v-row
          v-bind="prop.row"
          v-if="
            context.dataset.main.detail.dataInicio &&
            context.dataset.main.detail.dataFim
          "
        >
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-1">
              {{
                `${dateFormat(
                  parseISO(
                    `${context.dataset.main.detail.dataInicio.substring(0, 10)}`
                  ),
                  "date"
                )} - 
                 ${dateFormat(
                   parseISO(
                     `${context.dataset.main.detail.dataFim.substring(0, 10)}`
                   ),
                   "date"
                 )}`
              }}
            </span>
          </v-col>
        </v-row>
      </template>
    </c-base-detail>

    <c-base-list
      v-if="loadList"
      :funcs="funcsList"
      :hasSearch="false"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :noList="true"
      :pageModeEdit="true"
    >
      <template #search="{ context }">
        <v-container>
          <!-- busca -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-text-field
                v-model="search"
                appendOuterIcon="mdi-tune"
                clearable
                :max="100"
                :noAccept="null"
                placeholder="busca"
                prepend-inner-icon="mdi-magnify"
                solo
                type="search"
                @clickAppendOuter="showSearchOptions = !showSearchOptions"
                @keyup.enter="
                  $emit('search', search);
                  $event.target.blur();
                "
              />
            </v-col>
          </v-row>

          <!-- gestor -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idGestorImediato"
                clearable
                :disabled="!isUsuarioGestor"
                :items="gestoresImediatos"
                label="gestor imediato"
                @change="changeGestorImediato(context)"
              />
            </v-col>
          </v-row>

          <!-- colaborador -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n4">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idColaborador"
                clearable
                :disabled="!isUsuarioGestor"
                :items="colaboradores"
                label="colaborador"
                @change="changeColaborador(context)"
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n3">
            <!-- data inicio -->
            <v-col v-bind="prop.col">
              <c-datetime-picker
                v-model="searchData.inicio"
                classe="text-body-1 pr-1"
                date
                icon
                label="início"
                :maxValue="dataPonto.dataFim"
                :minValue="dataPonto.dataInicio"
                solo
                @input="$emit('searchData', searchData)"
              />
            </v-col>
            <!-- data fim -->
            <v-col v-bind="prop.col">
              <c-datetime-picker
                v-model="searchData.fim"
                classe="text-body-1 pl-1"
                date
                icon
                label="fim"
                :maxValue="dataPonto.dataFim"
                :minValue="searchData.inicio"
                solo
                @input="$emit('searchData', searchData)"
              />
            </v-col>
          </v-row>

          <!-- marcacao inconsistentes -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="marcacaoIncosistente"
                label="só marcações inconsistentes"
              />
            </v-col>
          </v-row>

          <!-- apontamento pendente -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="apontamentosPendentes"
                label="só apontamentos pendentes"
              />
            </v-col>
          </v-row>

          <!-- so data com apontamento -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="soApontamentos"
                label="só datas com apontamentos"
              />
            </v-col>
          </v-row>

          <!-- com de acordo -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="comDeAcordo"
                label='só colaboradores com "de acordo"'
                @click="filtroDatasets(context, 'semDeAcordo')"
              />
            </v-col>
          </v-row>

          <!-- sem de acordo -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col">
              <c-switch
                v-model="semDeAcordo"
                label='só colaboradores sem "de acordo"'
                @click="filtroDatasets(context, 'comDeAcordo')"
              />
            </v-col>
          </v-row>

          <!-- botao buscar -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n5">
            <v-col v-bind="prop.col" align="end">
              <c-btn
                block
                classe="primary white--text"
                large
                @click="clickSearch(context)"
              >
                buscar
              </c-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #listContent="{ datasets, context }">
        <c-expansion-panels
          flat
          :pageModeView="true"
          :params="params"
          :value="datasets"
        >
          <template #header="{ dataset }">
            <v-row v-bind="prop.row">
              <!-- pessoa colaborador_nome -->
              <v-col v-bind="prop.col" cols="10">
                <span class="text-body-1">
                  {{ dataset.pessoaColaborador_nome }}
                </span>
              </v-col>
              <v-col v-bind="prop.col" align="center" cols="2">
                <!-- quantidade de datas -->
                <v-badge
                  v-if="!getQuantidadeInconsistencias(context, dataset)"
                  color="grey"
                  :content="dataset.datas.length"
                  inline
                  overlap
                  :value="dataset.datas.length"
                ></v-badge>
                <!-- quantidade inconsistencias -->
                <v-badge
                  v-if="getQuantidadeInconsistencias(context, dataset)"
                  color="error"
                  :content="getQuantidadeInconsistencias(context, dataset)"
                  inline
                  overlap
                  :value="getQuantidadeInconsistencias(context, dataset)"
                ></v-badge>
              </v-col>
            </v-row>
          </template>

          <template #content="{ dataset }">
            <!-- horas extras -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="end" cols="6">
                <span class="text-body-2"> horas extras </span>
              </v-col>
              <v-col v-bind="prop.col" cols="6">
                <span class="text-body-1">
                  {{ minutesToHours(dataset.resumo.horasExtras) }} h
                </span>
              </v-col>
            </v-row>

            <!-- atrasos -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="end" cols="6">
                <span class="text-body-2"> atrasos </span>
              </v-col>
              <v-col v-bind="prop.col" cols="6">
                <span class="text-body-1">
                  {{ minutesToHours(dataset.resumo.atrasos) }} h
                </span>
              </v-col>
            </v-row>

            <!-- faltas -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="end" cols="6">
                <span class="text-body-2"> faltas </span>
              </v-col>
              <v-col v-bind="prop.col" cols="6">
                <span class="text-body-1">
                  {{ minutesToHours(dataset.resumo.faltas) }} d
                </span>
              </v-col>
            </v-row>

            <c-expansion-panels
              flat
              :hasDot="hasDot"
              :isDisabled="isDisabled"
              :pageModeView="true"
              :params="params"
              :value="dataset.datas"
            >
              <template #header="{ dataset: data }">
                <v-row v-bind="prop.row" v-show="showData(context, data)">
                  <!-- data -->
                  <v-col v-bind="prop.col" cols="10">
                    <span
                      class="font-weight-medium py-1 text-body-1"
                      :class="dateColor(data)"
                    >
                      {{ dateFormat(new Date(data.data), 'dateNoYear dateWeekday'), }}
                    </span>
                  </v-col>
                  <!-- quantidade marcacoes -->
                  <v-col v-bind="prop.col" align="center" cols="2">
                    <v-badge
                      v-if="!hasDot(data)"
                      color="grey"
                      :content="
                        dataset.marcacoes[data.id].filter(
                          (marcacao) => marcacao.id
                        ).length
                      "
                      inline
                      overlap
                      :value="
                        dataset.marcacoes[data.id].filter(
                          (marcacao) => marcacao.id
                        ).length
                      "
                    ></v-badge>
                  </v-col>
                </v-row>
              </template>

              <template #content="{ dataset: data }">
                <c-pontoEletronico-page-apontamento-view
                  v-if="pageModeView"
                  :data="data"
                  :dataset="dataset"
                  :idGestorImediato="idGestorImediato"
                  @clickComprovante="clickComprovante"
                />
                <c-pontoEletronico-page-apontamento-edit
                  v-else
                  :data="data"
                  :dataset="dataset"
                  :idGestorImediato="idGestorImediato"
                  @clickComplementar="clickComplementar"
                  @clickMarcacao="clickMarcacao"
                />
              </template>
            </c-expansion-panels>
            <div
              v-if="
                pageModeView &&
                getQuantidadeInconsistencias(context, dataset) > 0 &&
                showDeAcordo(dataset)
              "
            >
              <!-- botao de acordo -->
              <v-row v-bind="prop.row" v-if="!dataDeAcordo(dataset)">
                <v-col v-bind="prop.col">
                  <!-- //? verificar pq o c-btn não aceita o block -->
                  <v-btn
                    block
                    color="blue white--text"
                    @click="clickDeAcordo(dataset)"
                  >
                    de acordo
                  </v-btn>
                </v-col>
              </v-row>

              <!-- de acordo em  -->
              <v-row v-bind="prop.row" v-else>
                <v-col v-bind="prop.col" align="center">
                  <c-btn
                    color="blue white--text"
                    text
                    @click="clickDeAcordo(dataset)"
                  >
                    {{
                      `de acordo em ${format(
                        dataDeAcordo(dataset),
                        "dd/MM/yyy HH:mm:ss"
                      )}`
                    }}
                  </c-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </c-expansion-panels>
      </template>
    </c-base-list>
    <c-pontoEletronico-dialog-pontoEletronico
      v-if="showPontoEletronico"
      v-model="showPontoEletronico"
      :dataset="datasetComprovante"
      :showComprovante="true"
    />
    <c-pontoEletronico-dialog-marcacao
      v-if="datasetMarcacao && showMarcacao"
      :dataset="datasetMarcacao"
      :show="showMarcacao"
      @close="showMarcacao = false"
      @incluido="incluirMarcacao(datasetMarcacao, params.context)"
    />
  </div>
</template>

<script>
/// IMPORT
import axios from "axios";
import { mapGetters } from "vuex";
import CPontoEletronicoPageApontamentoEdit from "@/components/pages/pontoEletronico/pages/CPontoEletronicoPageApontamentoEdit";
import CPontoEletronicoPageApontamentoView from "@/components/pages/pontoEletronico/pages/CPontoEletronicoPageApontamentoView";
import CPontoEletronicoDialogMarcacao from "@/components/pages/pontoEletronico/dialogs/CPontoEletronicoDialogMarcacao.vue";
import CPontoEletronicoDialogPontoEletronico from "@/components/pages/pontoEletronico/pontoEletronico/CPontoEletronicoDialogPontoEletronico.vue";
import mixinData from "@/mixins/mixinData";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "PontoEletronico_Apontamentos",

  /// COMPONENTS
  components: {
    CPontoEletronicoPageApontamentoEdit,
    CPontoEletronicoPageApontamentoView,
    CPontoEletronicoDialogMarcacao,
    CPontoEletronicoDialogPontoEletronico,
  },

  /// MIXINS
  mixins: [mixinData, mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getUsuario = this.$store.getters["variaveis/getUsuario"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idFeature: getFeature("optionApontamentos").id,
      idPage: getPagina("pontoEletronicoApontamentos").id,
      idPageAlt: getPagina("pontosEletronicos").id,
      idPageDetail: getPagina("pontoEletronico").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("pontoEletronico").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            dataInicio: null,
            dataFim: null,
            toleranciaAtraso: null,
            toleranciaHoraExtra: null,
            funcionarios: null,
            horasExtras: null,
            faltas: null,
            atrasos: null,
            abonos: null,
            observacoes: null,
            dataAtualizacao: null,
          },
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          this.dataPonto.dataInicio = context.dataset.main.detail.dataInicio;
          this.dataPonto.dataFim = this.isBefore(
            this.parseISO(context.dataset.main.detail.dataFim),
            new Date()
          )
            ? context.dataset.main.detail.dataFim
            : this.format(new Date(), "yyyy-MM-dd");

          this.searchData.inicio = this.format(
            this.parseISO(
              context.dataset.main.detail.dataInicio.substring(0, 10)
            ),
            "yyyy-MM-dd"
          );
          this.searchData.fim = this.isBefore(
            this.parseISO(context.dataset.main.detail.dataFim),
            new Date()
          )
            ? this.format(
                this.parseISO(
                  context.dataset.main.detail.dataFim.substring(0, 10)
                ),
                "yyyy-MM-dd"
              )
            : this.format(new Date(), "yyyy-MM-dd");

          this.id = context.dataset.main.detail.id;

          this.gestoresImediatos = []
            .concat(this.getUsuario(this.getVar("idPessoaParceiro")).gestores)
            .map((gestor) => {
              if (!this.idGestorImediato) {
                this.idGestorImediato = gestor.idProprietario;
              }

              return {
                value: gestor.idProprietario,
                text: gestor.nomeAlt,
              };
            });

          this.changeGestorImediato();

          this.loadList = true;
        },
      },

      funcsList: {
        changed: () => {
          return !this.pageModeView;
        },

        clickAlterar: () => {
          this.funcsList.clickEditView();
        },

        clickCancel: (context) => {
          context.options.click.datasets = this.datasetsTemp;
          this.funcsList.clickEditView();
          context.options.click.clickSearch();
        },

        clickEditView: () => {
          this.pageModeView = !this.pageModeView;
        },

        clickRecalcular: async (context) => {
          this.setVar({ overlay: true });
          const url = `${
            this.getConst("app").baseUrlBackend
          }/pontosEletronicos/processamentoManual`;
          await axios.post(url, this.dataset.main.detail);
          this.setVar({ overlay: false });

          context.clickSearch();
        },

        clickSave: async (context) => {
          let marcacoes = [];
          let apontamentos = [];
          let datas = [];

          const metadados = {
            idEvento: this.getTipo_item("eventoAlterado").id,
            modo: context.options.click.getConst("config").modo.cadastro.id,
            idUsuario: context.options.click.getVar("idUsuario"),
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
          };

          context.options.click.datasets.forEach((colaborador) => {
            colaborador.datas.forEach((data) => {
              colaborador.marcacoes[data.id]
                .filter((marcacao) => marcacao.isAlterado)
                .forEach((marcacao) => {
                  if (!marcacao.id && marcacao.data) {
                    marcacoes.push({
                      ...marcacao,
                      isUpdated: true,
                      idPai2: data.id,
                    });
                  } else {
                    marcacoes.push(marcacao);
                  }
                });

              colaborador.apontamentos[data.id]
                .filter(
                  (apontamento) =>
                    apontamento.idPai2 == data.id && apontamento.isAlterado
                )
                .forEach((apontamento) => {
                  if (!apontamento.id) {
                    apontamentos.push({
                      ...apontamento,
                      isUpdated: true,
                      idPai2: data.id,
                    });
                  } else {
                    apontamentos.push(apontamento);
                  }
                });

              const dataAlterada =
                marcacoes.some((marcacao) => marcacao.isAlterado) ||
                data.idInconsistencia ||
                colaborador.marcacoes[data.id].filter(
                  (marcacao) => marcacao.data
                ).length %
                  2 !=
                  0 ||
                apontamentos.some(
                  (apontamento) => apontamento.isAlterado && apontamento.del
                );

              if (dataAlterada) {
                datas.push({
                  ...data,
                  idInconsistencia: null,
                  dataProcessado: null,
                });
              }

              marcacoes.forEach((marcacao) => delete marcacao.isAlterado);
              apontamentos.forEach((apontamento) => {
                delete apontamento.isAlterado;
                delete apontamento.disabled;
                delete apontamento.oldIdSituacaoTrabalho;
              });
            });
          });

          const datasetsSave = {
            metadados,
            main: {
              detail: this.dataset.main.detail,
              marcacoes: marcacoes,
              apontamentos: apontamentos,
              datas: datas,
            },
            aux: {
              detail: {
                isApontamento: true,
              },
            },
          };

          const method = "put";
          let url = `${context.options.click.getConst("app").baseUrlBackend}/${
            context.options.click.pageAlt.nome
          }`;

          const res = await context.options.click.submit(
            method,
            url,
            datasetsSave
          );

          if (res) {
            this.funcsList.clickEditView();
            context.options.click.clickSearch();
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          let params = null;
          params = {
            idPontoEletronico: this.id,
            idGestorImediato: this.idGestorImediato,
            colaborador: [this.idColaborador],
            dataInicio: this.searchData.inicio + " 00:00:00.000",
            dataFim: this.searchData.fim + " 23:59:59.997",
            marcacaoIncosistente: this.marcacaoIncosistente,
            apontamentosPendentes: this.apontamentosPendentes,
            soApontamentos: this.soApontamentos,
            comDeAcordo: this.comDeAcordo,
            semDeAcordo: this.semDeAcordo,
          };

          return JSON.stringify(params);
        },

        posSubmit: async (dataset, context, datasets, index) => {
          if (index == 0) {
            context.page.nomeAjuda = context.pageAlt.nome;
          }

          dataset.marcacoes = {};
          dataset.apontamentos = {};
          dataset.datas.forEach((data) => {
            dataset.marcacoes[data.id] = this.marcacoes(context, data);

            dataset.apontamentos[data.id] = this.apontamentos(context, data);
          });

          this.params.context = context;

          this.datasetsTemp = JSON.parse(
            JSON.stringify(context.datasetList.datasets)
          );
        },
      },

      params: {
        context: null,
      },

      apontamentosPendentes: false,
      colaboradores: [
        {
          value: getVar("idUsuario"),
          text: getUsuario(getVar("idUsuario")).pessoa.nomeAlt,
        },
      ],
      comDeAcordo: false,
      dataPonto: {
        dataInicio: null,
        dataFim: null,
      },
      datas: [],
      datasetComprovante: null,
      datasetMarcacao: null,
      datasetsTemp: [],
      gestoresImediatos: [],
      idColaborador: getVar("idUsuario"),
      idGestorImediato: null,
      loadList: false,
      marcacaoIncosistente: false,
      pageModeView: true,
      panelsOpen: [],
      search: "",
      searchData: {
        inicio: null,
        fim: null,
      },
      semDeAcordo: false,
      showMarcacao: false,
      showSearchOptions: false,
      showPontoEletronico: false,
      soApontamentos: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
      getVerba: "verbas/getVerba",
    }),

    isUsuarioGestor() {
      return !!this.getUsuario(this.getVar("idPessoaParceiro")).gestores.find(
        (gestor) => gestor.idProprietario == this.getVar("idUsuario")
      );
    },
  },

  /// METHODS
  methods: {
    apontamentos(context, data) {
      const apontamentos = context.datasetList.dataset.main.apontamentos.filter(
        (apontamento) => apontamento.idPai2 == data.id
      );

      apontamentos.forEach((apontamento) => {
        apontamento.oldIdSituacaoTrabalho = apontamento.idSituacaoTrabalho;
        apontamento.disabled = false;
      });

      if (apontamentos.length == 0 && !data.idFeriado && data.isFolga) {
        return ["folga"];
      }

      return apontamentos;
    },

    changeColaborador(context) {
      if (context) {
        context.datasets = [];
        context.params = this.funcsList.fnParams();
      }
    },

    changeGestorImediato(context) {
      this.colaboradores = this.getUsuario(this.getVar("idPessoaParceiro"))
        .subordinados.filter(
          (subordinado) => subordinado.idProprietario == this.idGestorImediato
        )
        .map((subordinado) => {
          return {
            value: subordinado.idPessoaColaborador,
            text: subordinado.nomeAlt,
          };
        });

      if (context) {
        this.idColaborador = null;
        this.changeColaborador(context);
      }
    },

    clickComplementar(colaborador, data) {
      const indiceData = colaborador.datas.findIndex(
        (dataEscolhida) => dataEscolhida.id == data.id
      );

      let marcacaoAnterior = colaborador.datas[indiceData];
      let marcacaoSeguinte = colaborador.datas[indiceData];

      if (indiceData > 0) {
        marcacaoAnterior = colaborador.marcacoes[
          colaborador.datas[indiceData - 1].id
        ]
          .filter((marcacao) => marcacao.data)
          .sort((a, b) =>
            this.parseISO(a.data) > this.parseISO(b.data) ? -1 : 1
          )[0];

        if (!marcacaoAnterior) {
          marcacaoAnterior = {
            ...colaborador.datas[indiceData],
            data: colaborador.datas[indiceData - 1]
              ? new Date(
                  this.parseISO(
                    colaborador.datas[indiceData - 1].data.substring(0, 10)
                  ).setHours(23, 59)
                )
              : new Date(
                  this.parseISO(
                    colaborador.datas[indiceData].data.substring(0, 10)
                  ).setHours(23, 59)
                ),
          };
        }
      }

      if (indiceData < colaborador.datas.length - 1) {
        marcacaoSeguinte = colaborador.marcacoes[
          colaborador.datas[indiceData + 1].id
        ]
          .filter((marcacao) => marcacao.data)
          .sort((a, b) =>
            this.parseISO(a.data) < this.parseISO(b.data) ? -1 : 1
          )[0];

        if (!marcacaoSeguinte) {
          marcacaoSeguinte = {
            ...colaborador.datas[indiceData],
            data: colaborador.datas[indiceData + 1]
              ? new Date(
                  this.parseISO(
                    colaborador.datas[indiceData + 1].data.substring(0, 10)
                  ).setHours(23, 59)
                )
              : new Date(
                  this.parseISO(
                    colaborador.datas[indiceData].data.substring(0, 10)
                  ).setHours(23, 59)
                ),
          };
        }
      }

      this.datasetMarcacao = {
        data: data,
        label: "incluir nova marcação",
        colaborador,
        dataAnterior: marcacaoAnterior,
        dataSeguinte: marcacaoSeguinte,
        isAlterarMarcacao: true,
      };

      this.showMarcacao = true;
    },

    async clickComprovante(idMarcacao) {
      if (idMarcacao) {
        const url = `${this.getConst("app").baseUrlBackend}/pontosEletronicos/${
          this.id
        }`;

        const res = await axios.get(url);
        const parceiro = res.data.aux.detail;
        const marcacao = res.data.aux.marcacoes.find(
          (marcacao) => marcacao.id == idMarcacao
        );

        marcacao.enderecoPonto = null;
        if (marcacao.latitude && marcacao.latitude) {
          const urlMap = `${this.getConst("app").baseUrlBackend}/mapQuest`;
          const body = {
            lat: marcacao.latitude,
            lon: marcacao.longitude,
          };

          const res = await axios.post(urlMap, body);
          const enderecoPonto = res.data[0].formattedAddress;
          marcacao.enderecoPonto = enderecoPonto;
        }

        const dataset = {
          parceiro,
          marcacao,
        };

        this.datasetComprovante = dataset;
        this.showPontoEletronico = true;
      }
    },

    async clickDeAcordo(colaborador) {
      const aprovacao = this.dataset.main.aprovacoes.find(
        (aprovacao) =>
          aprovacao.idPessoaColaborador == colaborador.id &&
          aprovacao.idPessoaAprovador == this.getVar("idUsuario")
      );

      const url = `${
        this.getConst("app").baseUrlBackend
      }/pontosEletronicos/aprovacao`;

      if (aprovacao?.id) {
        await axios.put(url, aprovacao);

        this.dataset.main.aprovacoes.splice(
          this.dataset.main.aprovacoes.findIndex(
            (aprovacao) =>
              aprovacao.idPessoaColaborador == colaborador.id &&
              aprovacao.idPessoaAprovador == this.getVar("idUsuario")
          ),
          1
        );
      } else {
        const aprovacao = {
          id: null,
          idPai: this.id,
          idPessoaColaborador: colaborador.id,
          idPessoaAprovador: this.getVar("idUsuario"),
          data: null,
        };

        const res = await axios.post(url, aprovacao);

        this.dataset.main.aprovacoes.push(res.data);
      }
    },

    clickMarcacao(marcacao, colaborador, data, indiceMarcacao) {
      const indiceData = colaborador.datas.findIndex(
        (dataEscolhida) => dataEscolhida.id == data.id
      );

      let dataAnterior = colaborador.datas[indiceData];
      let dataSeguinte = colaborador.datas[indiceData];

      if (marcacao.isUpdated) {
        if (indiceData > 0) {
          dataAnterior = colaborador.marcacoes[
            colaborador.datas[indiceData - 1].id
          ]
            .filter((marcacao) => marcacao.data)
            .sort((a, b) =>
              this.parseISO(a.data) > this.parseISO(b.data) ? -1 : 1
            )[0];

          if (!dataAnterior) {
            dataAnterior = {
              ...colaborador.datas[indiceData],
              data: colaborador.datas[indiceData - 1]
                ? new Date(
                    this.parseISO(
                      colaborador.datas[indiceData - 1].data.substring(0, 10)
                    ).setHours(0, 0)
                  )
                : new Date(
                    this.parseISO(
                      colaborador.datas[indiceData].data.substring(0, 10)
                    ).setHours(0, 0)
                  ),
            };
          }
        }

        if (indiceData < colaborador.datas.length - 1) {
          dataSeguinte = colaborador.marcacoes[
            colaborador.datas[indiceData + 1].id
          ]
            .filter((marcacao) => marcacao.data)
            .sort((a, b) =>
              this.parseISO(a.data) < this.parseISO(b.data) ? -1 : 1
            )[0];

          if (!dataSeguinte) {
            dataSeguinte = {
              ...colaborador.datas[indiceData],
              data: colaborador.datas[indiceData + 1]
                ? new Date(
                    this.parseISO(
                      colaborador.datas[indiceData + 1].data.substring(0, 10)
                    ).setHours(0, 0)
                  )
                : new Date(
                    this.parseISO(
                      colaborador.datas[indiceData].data.substring(0, 10)
                    ).setHours(0, 0)
                  ),
            };
          }
        }
      } else {
        if (indiceMarcacao == 0) {
          if (indiceData > 0) {
            dataAnterior = {
              ...colaborador.datas[indiceData - 1],
              data: new Date(
                this.parseISO(
                  colaborador.datas[indiceData - 1].data.substring(0, 10)
                ).setHours(0, 0)
              ),
            };
          }

          dataSeguinte = {
            ...colaborador.datas[indiceData],
            data: new Date(
              this.parseISO(
                colaborador.datas[indiceData].data.substring(0, 10)
              ).setHours(0, 0)
            ),
          };
        } else {
          dataAnterior = {
            ...colaborador.datas[indiceData],
            data: new Date(
              this.parseISO(
                colaborador.datas[indiceData].data.substring(0, 10)
              ).setHours(0, 0)
            ),
          };

          if (indiceData < colaborador.datas.length - 1) {
            dataSeguinte = {
              ...colaborador.datas[indiceData + 1],
              data: new Date(
                this.parseISO(
                  colaborador.datas[indiceData + 1].data.substring(0, 10)
                ).setHours(0, 0)
              ),
            };
          }
        }
      }

      this.datasetMarcacao = {
        data: marcacao,
        dataReferencia: data,
        label: "alterar marcação para",
        marcacao,
        colaborador,
        dataAnterior: dataAnterior,
        dataSeguinte: dataSeguinte,
        isAlterarMarcacao: false,
      };

      this.showMarcacao = true;
    },

    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    dataDeAcordo(colaborador) {
      const aprovacao = this.dataset.main.aprovacoes.find(
        (aprovacao) =>
          aprovacao.idPessoaColaborador == colaborador.id &&
          aprovacao.idPessoaAprovador == this.getVar("idUsuario")
      );

      if (!aprovacao) {
        return false;
      }

      return new Date(aprovacao.data);
    },

    dateColor(data) {
      let color = "font-weight-medium";

      const blueColor =
        this.dateFormat(new Date(data.data), "date") ==
        this.dateFormat(new Date(), "date");
      const greyColor = data.isFolga || data.idFeriado;

      if (blueColor) {
        color = `${color} blue lighten-3 ml-n2 pa-2 pr-2 rounded-lg`;
      }

      color = greyColor ? `${color} grey--text` : color;

      return color;
    },

    filtroDatasets(context, campoAnular) {
      this[campoAnular] = false;
      context.datasets = this.datasetsTemp;
      context.datasets = context.datasets.filter((dataset) =>
        this.showColaborador(dataset)
      );
    },

    getQuantidadeInconsistencias(context, dataset) {
      const idVerbasOcorrencia = [
        this.getVerba("p4FeriasMes").id,
        this.getVerba("p4SalarioMaternidade").id,
      ];

      return dataset.datas.reduce((acc, data) => {
        const inconsistencias =
          context.datasetList.dataset.main.marcacoes.filter(
            (marcacao) => marcacao.id != null && marcacao.idPai2 == data.id
          ).length % 2 ||
          context.datasetList.dataset.main.apontamentos.some(
            (apontamento) =>
              !idVerbasOcorrencia.includes(apontamento.idVerba) &&
              apontamento.idPai2 == data.id &&
              apontamento.idSituacaoTrabalho == null &&
              apontamento.horasConfirmadas == null
          );
        return (acc += inconsistencias ? 1 : 0);
      }, 0);
    },

    hasDot(data) {
      const idVerbasOcorrencia = [
        this.getVerba("p4FeriasMes").id,
        this.getVerba("p4SalarioMaternidade").id,
      ];

      const isInconsistencia =
        this.params.context.datasetList.dataset.main.marcacoes.filter(
          (marcacao) => marcacao.id != null && marcacao.idPai2 == data.id
        ).length % 2 ||
        this.params.context.datasetList.dataset.main.apontamentos.some(
          (apontamento) =>
            !idVerbasOcorrencia.includes(apontamento.idVerba) &&
            apontamento.idPai2 == data.id &&
            apontamento.idSituacaoTrabalho == null &&
            apontamento.horasConfirmadas == null
        );

      return isInconsistencia;
    },

    incluirMarcacao(datasetMarcacao, context) {
      if (!datasetMarcacao.isAlterarMarcacao) {
        const datas = [datasetMarcacao.dataReferencia];

        if (datasetMarcacao.dataAnterior) {
          datas.push(datasetMarcacao.dataAnterior);
        }

        if (datasetMarcacao.dataSeguinte) {
          datas.push(datasetMarcacao.dataSeguinte);
        }

        datas.forEach((data) => {
          const marcacao = this.marcacoes(context, data);

          datasetMarcacao.colaborador.marcacoes[data.id] = marcacao;
        });
      }

      this.showMarcacao = false;

      this.$forceUpdate();
    },

    //? - Fer: Definir o isDisabled
    isDisabled(traducao) {
      return (traducao.texto?.length || 0) <= this.maxLength;
    },

    marcacoes(context, data) {
      const marcacoesData = context.datasetList.dataset.main.marcacoes.filter(
        (marcacao) => marcacao.idPai2 == data.id
      );

      if (marcacoesData.length % 2 != 0) {
        marcacoesData.push({
          id: null,
          idPai2: data.id,
          data: null,
          latitude: null,
          longitude: null,
          isUpdated: false,
        });
      }

      return marcacoesData;
    },

    showColaborador(colaborador) {
      if (this.comDeAcordo) {
        return this.dataDeAcordo(colaborador) != false;
      }

      if (this.semDeAcordo) {
        return this.dataDeAcordo(colaborador) == false;
      }

      return true;
    },

    showData(context, data) {
      let marcacaoIncosistente = true;
      let apontamentosPendentes = true;
      let soApontamentos = true;

      if (this.marcacaoIncosistente) {
        marcacaoIncosistente =
          context.datasetList.dataset.main.marcacoes.filter(
            (marcacao) => marcacao.data != null && marcacao.idPai2 == data.id
          ).length % 2;
      }

      if (this.apontamentosPendentes) {
        apontamentosPendentes =
          context.datasetList.dataset.main.apontamentos.some(
            (apontamento) =>
              apontamento.idPai2 == data.id &&
              this.dateFormat(apontamento.data, "dateNoYear dateWeekday") ==
                this.dateFormat(
                  new Date(data.data),
                  "dateNoYear dateWeekday"
                ) &&
              apontamento.idSituacaoTrabalho == null &&
              apontamento.horasConfirmadas == null
          );
      }

      if (this.soApontamentos) {
        soApontamentos = context.datasetList.dataset.main.apontamentos.some(
          (apontamento) =>
            apontamento.idPai2 == data.id &&
            this.dateFormat(apontamento.data, "dateNoYear dateWeekday") ==
              this.dateFormat(new Date(data.data), "dateNoYear dateWeekday")
        );
      }

      return !!marcacaoIncosistente && apontamentosPendentes && soApontamentos;
    },

    showDeAcordo(colaborador) {
      const aprovacaoGestor = this.getVar("idUsuario") == this.idGestorImediato;

      const aprovacaoColaborador =
        this.getVar("idUsuario") == this.idColaborador &&
        this.dataset.main.aprovacoes.some(
          (aprovacao) =>
            aprovacao.idPessoaColaborador == colaborador.id &&
            aprovacao.idPessoaAprovador == this.idGestorImediato
        );

      const isPerfilRH = this.getUsuario(this.getVar("idPessoaParceiro"))
        .perfis.map((perfil) => perfil.idPerfil)
        .some(
          (perfil) =>
            this.getTipo_item("perfilRh").id == perfil &&
            (this.idColaborador != this.getVar("idUsuario") || aprovacaoGestor)
        );

      const hasDatasNaoProcessadas = colaborador.datas.some(
        (data) => !data.dataProcessado
      );

      return (
        this.pageModeView &&
        hasDatasNaoProcessadas &&
        !isPerfilRH &&
        (aprovacaoGestor || aprovacaoColaborador)
      );
    },
  },

  /// MOUNTED
  mounted() {
    this.params.datasColaboradorFilter = this.datasColaboradorFilter;
  },
};
</script>