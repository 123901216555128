<!-- COD: FDS 04/07/2022 CFolhaPagamentoView -->
<!-- *VER: JER 05/07/2022 CFolhaPagamentoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- dataInicioFim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- dataPagamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.dataPagamento">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataPagamento, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- valorTotal -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.valorTotal">
      <v-col v-bind="prop.col" align="center">
        <span
          class="font-weight-medium text-body-1"
          :class="classValor(dataset.main.detail.valorTotal)"
        >
          {{ numberFormat(dataset.main.detail.valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- colaboradores -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.colaboradores">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">colaboradores</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ colaboradores }}
        </span>
      </v-col>
    </v-row>

    <!-- idPontoEletronico -->
    <v-row v-bind="prop.row" v-if="hasPontoEletronico">
      <v-col v-bind="prop.col" align="center">
        <a v-if="pageModeView" @click="clickPontoEletronico()">
          <span class="text-body-2">ponto eletrônico</span>
          <span class="text-body-1">
            {{ context.dataset.main.detail.idPontoEletronico }}
          </span>
        </a>
        <div v-else>
          <span class="text-body-2">ponto eletrônico</span>
          <span class="text-body-1">
            {{ context.dataset.main.detail.idPontoEletronico }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhaPagamentoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    pageModeView: { type: Boolean, default: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getPagina: "paginas/getPagina",
    }),

    colaboradores() {
      return this.context.dataset.main.colaboradores.filter(
        (colaborador) => !colaborador.isDeleted
      ).length;
    },

    dataInicioFim() {
      return `${this.dateFormat(this.dataset.main.detail.dataInicio, "date")} - 
        ${this.dateFormat(this.dataset.main.detail.dataFim, "date")}`;
    },

    hasPontoEletronico() {
      return (
        (this.dataset.main.detail.idTipo == this.getTipo_item("fpNormal").id ||
          this.dataset.main.detail.idTipo ==
            this.getTipo_item("fpNormalSemComissoes").id) &&
        this.dataset.main.detail.idPontoEletronico
      );
    },
  },

  /// METHODS
  methods: {
    classValor(valor) {
      return parseFloat(valor) < 0 ? "red--text" : "";
    },

    clickPontoEletronico() {
      this.$router.push(
        `/pontoEletronico/${this.dataset.main.detail.idPontoEletronico}`
      );
    },
  },
};
</script>