// COD: BJB 15/08/2022 operacoes
// *VER: JER 19/08/2022 operacoes
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    operacoes: []
  },

  /// GETTERS
  getters: {
    getOperacoes: state => (prop = "id") => {
      return state.operacoes
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nomeAlt,
            isEntrada: element.isEntrada,
            options: element.options,
          };
        })
    },

    getOperacao: state => search => {
      return state.operacoes.length
        ? state.operacoes
          .find(element => element.id == search || element.nome == search)
        : { search }
    }
  },

  /// MUTATIONS
  mutations: {
    setOperacoes: (state, payload) => {
      state.operacoes = []
      payload.forEach(element => {
        state.operacoes.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setOperacoes: (context, payload) => {
      context.commit("setOperacoes", payload)
    }
  }
}