<!-- COD: LYF 01/07/2022 CTransacoes -->
<!-- *VER: JER 03/07/2022 CTransacoes -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- data vencimento -->
      <v-col v-bind="prop.col" cols="5">
        <span
          :class="`font-weight-medium text-body-2 ${colorDataVencValidade}`"
        >
          venc
        </span>
        <span :class="`ml-1 text-h6 ${colorDataVencValidade}`">
          {{ dateFormat(dataset.dataVencimento, "date") }}
        </span>
      </v-col>
      <!-- valor acerto -->
      <v-col v-bind="prop.col" align="end" cols="7">
        <span class="font-weight-medium text-body-2">acerto</span>
        <span class="ml-1 text-h6">
          {{ numberFormat(dataset.valorAcerto, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- valor saldo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end">
        <span class="text-body-2">saldo</span>
        <span class="ml-1 text-body-1">
          {{ numberFormat(dataset.valorSaldo, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- id pedido -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-2">pedido</span>
        <span class="ml-1 text-body-1">
          {{ dataset.idPedido }}
        </span>
      </v-col>
    </v-row>

    <!-- parceiro nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoaParceiro_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoa_nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CTransacoes",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    colorDataVencValidade() {
      return this.isVencido ? "red--text" : "";
    },

    isVencido() {
      return (
        this.dataset.dataVencimento &&
        this.parseISO(this.dataset.dataVencimento) < new Date()
      );
    },
  },
};
</script>