<!-- COD: LYF 05/07/2022 Verba -->
<!-- *VER: JER 07/07/2022 Verba -->
<!-- /21/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ pageModeView, page, context }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-verba-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-verba-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CVerbaEdit from "@/components/pages/verba/CVerbaEdit";
import CVerbaView from "@/components/pages/verba/CVerbaView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Verba",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CVerbaEdit,
    CVerbaView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("verba").id,
      idPageAlt: getPagina("verbas").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idPai: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            nome: null,
            nomeAlt: null,
            nivel: null,
            isProvento: null,
            isBeneficio: null,
            isAusencia: null,
            allowAlterarValor: null,
            allowAcerto: null,
            idMetodoDataReferencia: null,
            idMetodoReferencia: null,
            idMetodoValor: null,
            requireDataInicio: null,
            requireDataFim: null,
            requireDataReferencia: null,
            requireReferencia: null,
            requireValor: null,
            idContaContabil: null,
            isDebito: null,
            isImporte: null,
            idHistoricoPadrao: null,
            idImposto: null,
            isAvancarEstado: null,
            options: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          localidades: [],
          verbasFilhas: [],
        },
        aux: {
          detail: {
            verbaPai_nome: null,
          },
          autocomplete: {
            incidencias: [],
            verbasPai: [],
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        comboPai: () => {
          return this.getVerbas()
            .filter(
              (verba) => verba.nivel == this.dataset.main.detail.nivel - 1
            )
            .sortKeys("value")
            .map((verba) => {
              return {
                ...verba,
                text: `${verba.value} ${verba.text}`,
              };
            });
        },

        metodoLimpar: (required, metodo) => {
          const isRequired = this.dataset.main.detail[required];

          if (isRequired == false) {
            this.dataset.main.detail[metodo] = null;
          }
        },

        niveis: () => {
          return [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
          ];
        },

        nivelChanged: () => {
          const currentNivel = this.dataset.main.detail.nivel;

          if (currentNivel != 4) {
            this.dataset.main.detail.isProvento = false;
            this.dataset.main.detail.isBeneficio = false;
            this.dataset.main.detail.isAusencia = false;
            this.dataset.main.detail.allowAlterarValor = false;
            this.dataset.main.detail.allowAcerto = false;
            this.dataset.main.detail.requireDataInicio = false;
            this.dataset.main.detail.requireDataFim = false;
            this.dataset.main.detail.requireDataReferencia = false;
            this.dataset.main.detail.requireReferencia = false;
            this.dataset.main.detail.requireValor = false;

            this.funcs.parametros_check();
          }
        },

        parametros: () => {
          return Object.keys(this.dataset.main.detail.options || {}).map(
            (parametro) => {
              return {
                value: parametro,
                text: parametro,
              };
            }
          );
        },

        parametrosChanged: () => {
          Object.keys(this.dataset.main.detail.options).forEach((parametro) => {
            this.dataset.main.detail.options[parametro] =
              this.dataset.aux.detail.options.includes(parametro);
          });
        },

        parametros_check: () => {
          if (
            this.dataset.main.detail.idTipo !=
            this.getTipo_item("verbaAutomaticaPerformance").id
          ) {
            Object.keys(this.dataset.main.detail.options || {}).forEach(
              (parametro) => {
                this.dataset.main.detail.options[parametro] = false;
              }
            );
          }
        },

        posLoad: (context) => {
          setTimeout(() => {
            context.params.context = context;
          }, 10);
        },

        posSubmit: (context) => {
          context.dataset.aux.detail.options = Object.keys(
            JSON.parse(context.dataset.main.detail.options || "{}")
          ).filter(
            (parametro) => context.dataset.main.detail.options[parametro]
          );

          setTimeout(() => {
            context.params.context = context;
          }, 10);
        },
      },

      params: {
        context: this,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>