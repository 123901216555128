<!-- COD: FDS 01/07/2022 FolhasPagamentos -->
<!-- *VER: JER 04/07/2022 FolhasPagamentos -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-folhasPagamentos :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CFolhasPagamentos from "@/components/pages/folhaPagamento/CFolhasPagamentos";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "FolhasPagamentos",

  /// COMPONENTS
  components: {
    CFolhasPagamentos,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("folhasPagamentos").id,
      idPageAlt: getPagina("folhaPagamento").id,

      funcs: {
        fnParams: () => {
          const params = JSON.parse(JSON.stringify(this.params));
          delete params.nome;
          delete params.home;

          return JSON.stringify(params);
        },

        pontosEletronicos: () => {
          return this.dataset.aux.pontosEletronicos.map((pontoEletronico) => {
            return {
              value: pontoEletronico.id,
              text: `#${pontoEletronico.id} (${this.dateFormat(
                pontoEletronico.dataInicio,
                "date"
              )} - ${this.dateFormat(pontoEletronico.dataFim)})`,
            };
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      params: {},
    };
  },

  /// BEFOREMOUNT
  beforeMount() {
    this.params = this.copyObject(this.$route.query);
  },

  /// ACTIVATED
  activated() {
    this.params = this.copyObject(this.$route.query);
  },
};
</script>