<!-- COD: BJB 21/07/2022 CSnackbar -->
<!-- *VER: JER 10/08/2022 CSnackbar -->
<!-- /11/ -->

<template>
  <v-snackbar
    v-model="snackbar.show"
    app
    bottom
    :color="snackbar.color"
    text
    :timeout="snackbar.timeout"
    multi-line
  >
    <v-container class="pa-0 ma-0">
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" cols="1">
          <c-btn classe="mr-1" :color="snackbar.color" icon @click="clickClose">
            <v-icon>mdi-close</v-icon>
          </c-btn>
        </v-col>
        <v-col v-bind="prop.col" align="center" cols="10">
          <span class="text-h6 p-1">
            {{ translate(snackbar.title) }}
          </span>
        </v-col>
        <v-col v-bind="prop.col" cols="1">
          <c-btn
            v-if="snackbar.icone"
            :color="snackbar.color"
            icon
            right
            @click="$emit('click', snackbar.action)"
          >
            <v-icon>
              {{ snackbar.icone }}
            </v-icon>
          </c-btn>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <v-progress-linear
            :color="snackbar.color"
            background-color="white"
            height="2"
            rounded
            :value="progress"
          />
        </v-col>
      </v-row>

      <v-row v-bind="prop.row" v-for="(text, i) in texts" :key="i">
        <v-col v-bind="prop.col">
          <span class="text-body-1 py-1">
            {{ translate(text) }}
          </span>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row" v-if="icon">
        <v-col v-bind="prop.col" cols="2">
          <v-icon>icon</v-icon>
        </v-col>
        <v-col v-bind="prop.col" cols="7">
          <chip v-bind="prop.chip" v-if="pessoaDestaque">
            {{ pessoaDestaque }}
          </chip>
        </v-col>
        <v-col v-bind="prop.col" align="end" cols="3">
          <span class="text-overline py-1">
            {{ `#${snackbar.params.notificacao.idRegistro}` }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CSnackbar",

  /// PROPS
  props: {
    snackbar: { type: Object, required: true },
  },

  /// DATA
  data() {
    return {
      interval: null,
      tempoDecorrido: 400,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getPagina: "paginas/getPagina",
      getPaginas: "paginas/getPaginas",
    }),

    icon() {
      if (
        this.snackbar.params &&
        this.snackbar.params.notificacao &&
        this.snackbar.params.notificacao.idTabela &&
        this.snackbar.params.notificacao.idRegistro
      ) {
        return this.getPaginas()
          .filter(
            (page) => page.idTabela == this.snackbar.params.notificacao.idTabela
          )
          .map((page) => this.getPagina(page.value))
          .map((page) => this.getFeature(page.idFeature))[0].icon;
      } else {
        return null;
      }
    },

    pessoaDestaque() {
      return this.snackbar.params.pessoaDestaque
        ? this.snackbar.params.pessoaDestaque
        : null;
    },

    progress() {
      return (this.tempoDecorrido / this.snackbar.timeout) * 100;
    },

    show() {
      return this.snackbar.show;
    },

    texts() {
      return typeof this.snackbar.text == "string"
        ? this.snackbar.text.split("\n")
        : "";
    },
  },

  /// WATCH
  watch: {
    show() {
      if (this.snackbar.timeout) {
        if (this.snackbar.show) {
          this.interval = setInterval(() => {
            this.tempoDecorrido += 100;
          }, 100);
        }
      }

      if (!this.snackbar.show) {
        clearInterval(this.interval);
        this.interval = null;
        this.tempoDecorrido = 400;
      }
    },
  },

  /// METHODS
  methods: {
    clickClose() {
      this.snackbar.show = false;
    },
  },
};
</script>