<!-- COD: BJB 03/08/2022 CCarrinhoEdit -->
<!-- *VER: JER 08/08/2022 CCarrinhoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- cliente_nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn color="primary" text @click="clickPessoa">
          {{ dataset.aux.detail.cliente_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.data,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- itens / valor total -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ itens }}
        </span>
        <span class="ml-1 text-body-1">
          {{ numberFormat(valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- titulo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.titulo"
          label="título"
          :max="25"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CCarrinhoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// DATA
  data() {
    return {
      showPessoa: true,
    };
  },

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    itens() {
      return `${this.dataset.main.itens.length}
              ${this.dataset.main.itens.length > 1 ? "itens" : "item"}`;
    },

    valorTotal() {
      return !this.context.changed
        ? this.dataset.main.detail.valorTotal
        : this.dataset.main.itens
            .map((item, i) => {
              return {
                ...item,
                indice: i,
              };
            })
            .filter((item) => !item.del)
            .reduce(
              (subtotal, item) =>
                (subtotal +=
                  parseFloat(item.preco.clean(2)) *
                  parseFloat(
                    (item.quantidade ?? 0).clean(
                      this.dataset.aux.itens[item.indice].decimalEstoque
                    )
                  )),
              0
            );
    },
  },

  /// METHODS
  methods: {
    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idCliente}`);
    },
  },
};
</script>