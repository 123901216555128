<!-- COD: FDS 06/07/2022 CPerguntaFrequenteEdit -->
<!-- *VER: JER 19/07/2022 CPerguntaFrequenteEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- idioma -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-base-idiomas
          v-model="context.idLocale"
          :idiomas="context.dadosParceiro.idiomas"
        />
      </v-col>
    </v-row>

    <!-- pergunta -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.pergunta[context.localeParceiro]"
          class="ml-2"
          label="pergunta"
          :max="100"
          :min="10"
          :noAccept="''"
          prepend-inner-icon="mdi-translate"
          required
        />
      </v-col>
    </v-row>

    <!-- grupo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.grupo[context.localeParceiro]"
          :itemsIniciais="
            dataset.aux.autocomplete.grupos &&
            dataset.aux.autocomplete.grupos[context.localeParceiro]
              ? dataset.aux.autocomplete.grupos[context.localeParceiro]
              : []
          "
          label="grupo"
          :path="`/perguntasFrequentes/autocomplete/grupos/0/1/${dataset.main.detail.idPessoaParceiro}/`"
          :rules="[]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import CBaseIdiomas from "@/components/bases/CBaseIdiomas";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPerguntaFrequenteEdit",

  /// COMPONENTS
  components: { CBaseIdiomas },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },
};
</script>