<!-- COD: BJB 09/08/2022 CBaseDetailImages -->
<!-- *VER: JER 10/08/2022 CBaseDetailImages -->
<!-- /11/ -->

<template>
  <PinchZoom
    :limitPan="true"
    :limitZoom="1000"
    class="transparent"
    disableZoomControl="disable"
  >
    <!-- // IMAGENS -->
    <v-container class="pa-0">
      <v-row
        v-bind="prop.row"
        v-if="pageModeView && auxImagens && auxImagens.length"
      >
        <v-col v-bind="prop.col" align="center">
          <v-carousel
            v-if="auxImagens && auxImagens.length > 1"
            :cycle="carouselCycle"
            :height="height"
            hide-delimiters
            :interval="getConst('carousel').interval"
            progress
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(imagem, i) in auxImagens"
              contain
              :key="i"
              :src="imagem.endereco"
              @click.stop="clickCarousel"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row
        v-bind="prop.row"
        v-if="
          pageModeView &&
          auxImagens &&
          auxImagens.length == 1 &&
          auxImagens[0].endereco
        "
      >
        <v-col v-bind="prop.col" align="center">
          <v-avatar v-if="avatar" size="150">
            <v-img :src="auxImagens[0].endereco" contain alt="imagem" />
          </v-avatar>
          <v-img
            v-else
            alt="imagem"
            class="ml-0 mt-0"
            contain
            :height="height"
            :src="auxImagens[0].endereco"
            :width="width"
          />
        </v-col>
      </v-row>

      <v-row
        v-bind="prop.row"
        v-if="
          !pageModeView &&
          auxImagens &&
          auxImagens.length &&
          auxImagens[0].endereco
        "
      >
        <v-col v-bind="prop.col" align="center">
          <v-avatar v-if="avatar" size="150">
            <v-img
              alt="imagem"
              contain
              :src="auxImagens[0].endereco"
              @click="clickImage"
            />
          </v-avatar>
          <v-img
            v-else
            alt="imagem"
            class="ml-0 mt-0"
            contain
            :height="height"
            :src="auxImagens[0].endereco"
            :width="width"
            @click="clickImage"
          />
        </v-col>
      </v-row>
    </v-container>
  </PinchZoom>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import PinchZoom from "vue-pinch-zoom";

export default {
  /// NAME
  name: "CBaseDetailImages",

  ///COMPONENTS
  components: { PinchZoom },

  /// PROPS
  props: {
    avatar: { type: Boolean, default: false },
    dataset: { type: Object, required: true },
    height: { type: Number, default: 200 },
    pageModeView: { type: Boolean, required: true },
    width: { type: Number, default: 200 },
  },

  /// DATA
  data() {
    return {
      carouselCycle: true,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
    }),

    auxImagens() {
      return this.dataset.aux
        ? this.dataset.aux.imagens
        : this.dataset.auxImagens;
    },
  },

  /// METHODS
  methods: {
    clickCarousel() {
      this.carouselCycle = !this.carouselCycle;
    },

    clickImage() {
      this.$router.push(`/anexo/-1/${this.auxImagens[0].id}`);
    },
  },
};
</script>