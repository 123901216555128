<!-- COD: BJB 10/08/2022 Print -->
<!-- *VER: JER 10/08/2022 Print -->
<!-- /12/ -->

<template>
  <div class="pa-0" ref="divPrint"></div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "Print",

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
  },

  /// MOUNTED
  async mounted() {
    const varKey = this.getConst("app").varKey;
    const localStorageVarTemp = await localStorage.getItem(varKey);

    if (localStorageVarTemp) {
      const localStorageVar = JSON.parse(localStorageVarTemp);

      if (localStorageVar) {
        this.setVar(localStorageVar);
      }

      const printKey = this.$route.query.printKey;
      const print = this.getVar("print");

      if (printKey == "relatorio") {
        this.$root.$emit("resize", { cols: 12 });
      }

      this.$refs.divPrint.innerHTML = print[printKey];

      setTimeout(() => {
        const rect = this.$refs.divPrint.getBoundingClientRect();
        const root = document.querySelector(":root");
        root.style.setProperty("--height", `${rect.height + 100}px`);

        window.print();
        window.onafterprint;
        this.afterPrint = true;
      }, 500);
    }
  },
};
</script>

<style>
:root {
  --height: 99%;
}

@media print {
  html,
  body {
    height: var(--height);
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
</style>