// COD: LYF 10/08/2022 mixinLib
// #VER: JER __/08/2022 mixinLib
// 3?

import { mapActions, mapGetters } from "vuex";
import { Money, VMoney } from "v-money";
//?bbb import { filetoDataURL } from "image-conversion";  // esta linha esta dando erro na compilacao

export default {
  /// NAME
  name: "mixinLib",

  /// COMPONENTS
  components: { Money },

  /// DIRECTIVES
  directives: { money: VMoney },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getBanco: "bancos/getBanco",
      getConst: "constantes/getConst",
      getDicionario: "dicionarios/getDicionario",
      getEstado: "estados/getEstado",
      getImposto: "impostos/getImposto",
      getLocalidade_configuracoesRegionais: "localidades/getLocalidade_configuracoesRegionais",
      getLocalidade_idioma: "localidades/getLocalidade_idioma",
      getLocalidade_idiomas: "localidades/getLocalidade_idiomas",
      getLocalidade_moedas: "localidades/getLocalidade_moedas",
      getLocalidade: "localidades/getLocalidade",
      getLocalidades: "localidades/getLocalidades",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
      getVar: "variaveis/getVar",
    }),

    locales() {
      return this.getLocalidade_idiomas()
        .filter((locale) => this.getLocalidade_idioma(locale.value).isLocale)
        .map((locale) => {
          return {
            value: locale.value,
            text: this.localeById(locale.value)
          };
        })
        .sort((a, b) => (a.text < b.text ? -1 : 1));
    },

    regExpTokens() {
      return new RegExp(`(${this.strTokens})+`, "g")
    },

    paisesAtivos() {
      return this.getLocalidades(this.getTipo_item("localidadePais").id)
        .filter(localidade => localidade.idEstado == this.getEstado('ativo').id);
    },

    strTokens() {
      const tokens = this.getConst("config").tokens;
      return Object.keys(tokens)
        .join("|")
        .replace("*", "\\*")
        .replace("$", "\\$");
    },

    userLocale() {
      let locale = null

      if (this.getVar("idPessoaParceiro")) {
        const usuario = this.getVar("usuarios").find(usuario => usuario.pessoa.id == this.getVar("idPessoaParceiro"))
        const idPais = usuario.enderecos[0].idPais
        const idioma = this.getLocalidade_idiomas(idPais)
          .map(idioma => this.getLocalidade_idioma(idioma.value))
          .find(idioma => idioma.isOficial && idioma.isLocale)

        if (idioma) {
          locale = this.localeById(idioma.id).substring(0, 2)
        }
      }

      return locale
    },
  },

  /// DATA
  data() {
    return {
      clickAbort: false,
      clickPressed: false,
      commonRules: {
        common: (type, required, min = 0, max, noAccept = "?", clean, msg, mask, optionsDV, callBackPanel, dynamicsRules = []) => {
          clean = clean || (type === "text" || type === "email" || type === "password" ? "" : -1);

          if (mask) {
            const masks = this.cleanMask(mask, true)

            min = Array.isArray(masks) ? masks[0].length : masks.length;
            max = Array.isArray(masks) ? masks[masks.length - 1].length : masks.length;
          }

          msg = msg || `${(min && min < max) ? "min " + min + " max " : ""}${max} carac`;

          return [
            ...dynamicsRules,
            (value) => {
              const ret = !required || !!value || value === 0 || "requerido"
              if (callBackPanel && typeof ret === "string") {
                callBackPanel();
              }

              return ret
            },
            (value) => {
              const ret = typeof value !== "string" ||
                !noAccept ||
                !value ||
                !noAccept
                  .split("")
                  .some((caractere) => value.includes(caractere)) ||
                `não aceita ${noAccept}`

              if (callBackPanel && typeof ret === "string") {
                callBackPanel();
              }

              return ret
            },
            (value) => {
              // value = mask ? value.mask(mask, { clean: true }) : value;

              const ret = typeof value !== "string" ||
                type === "select" ||
                type === "date" ||
                !value ||
                (!!value &&
                  (mask ? value : value.clean(clean)).length >= min &&
                  (mask ? value : value.clean(clean)).length <= max) ||
                msg

              if (callBackPanel && typeof ret === "string") {
                callBackPanel();
              }

              return ret
            },
            (value) => {
              const ret = !optionsDV ||
                this.digitoVerificador(value, optionsDV).isDigitoVerificadorOk ||
                "dígito verificador inválido"
              if (callBackPanel && typeof ret === "string") {
                callBackPanel();
              }

              return ret
            },
          ];
        },
      },
      iconScroll: null,
      load: false,
      pageYOffsetScroll: 0,
      prop: {
        chip: {
          class: "corRetirar corRetirar--text mx-1 text-body-1",
          outlined: true,
          ripple: false,
          xSmall: true
        },
        col: {
          class: "px-1"
        },
        container: {
          class: "pa-0"
        },
        divider: {
          class: "my-4 grey"
        },
        expansionPanel: {
          class: "ma-0 mb-1 pa-0"
        },
        expansionPanelHeader: {
          class: "pa-1"
        },
        expansionPanelContent: {
          class: "ml-n4 px-0"
        },
        row: {
          align: "center",
          class: "py-1",
          noGutters: true
        },
      },
      showBottom: true,
      showFab: false,
      timeOutBottom: null,
      timeOutFab: null,
      vTouch: {
        start: ($event) => {
          this.startTouchPressed($event);
        },
        end: ($event) => {
          this.endTouchPressed($event);
        },
      },
    }
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar"
    }),

    //cepMascara
    cepMascara(idPais) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalCodigoPostal").id
      )[0]?.mascara;

      return mascara;
    },

    //clickElement
    clickElement(e) {
      if (!this.clickAbort) {
        this.$emit("click", e);
      } else {
        e.preventDefault();
        this.clickAbort = false;
      }
    },

    // clickOnTooltip
    clickOnTooltip(on, event) {
      on.click(event);
      event.keyCode = 27; // keyCode esc
      on.keydown(event);
    },

    // codigoBarraVerificar
    codigoBarraVerificar(value) {
      const tamanho = 13

      if (!value || value.length != tamanho) {
        return false
      }

      const digitos = 1
      const modulo = 10
      const posicao = tamanho - digitos
      const codigoNumero = value.slice(0, posicao)
      const codigoDigito = value.slice(posicao)
      const codigoArray = codigoNumero.split("")

      const soma = codigoArray
        .reduce((acc, numero, i) => acc + (parseInt(numero) * (i % 2 ? 3 : 1)), 0)

      const digitoTemp = ((Math.floor(soma / modulo) + 1) * modulo) - soma

      const digitoCalculado = digitoTemp < modulo ? digitoTemp : 0

      return codigoDigito == digitoCalculado
    },

    //configuracaoRegionalByTipo
    configuracaoRegionalByTipo(idLocalidade, idTipo, search, idTipoPessoa) {
      idTipo = idTipo || this.getTipo_item(search)?.id;

      let configuracaoRegional;

      try {
        if (!idLocalidade) {
          throw ("idLocalidade não informado")
        }

        configuracaoRegional =
          this.getLocalidade_configuracoesRegionais(idLocalidade, idTipo, idTipoPessoa)[0];
      } catch (err) {
        configuracaoRegional = {};
      }

      return configuracaoRegional
    },

    // contaBancariaFormat
    contaBancariaFormat(contaBancaria, hasBancoNome = false) {
      const banco = contaBancaria.idBanco ? this.getBanco(contaBancaria.idBanco) : {};
      const localidade = banco.idPais ? this.getLocalidade(banco.idPais) : {};
      const bancoNomeAlt = hasBancoNome ? contaBancaria.idBanco ? banco.nomeAlt : "" : "";
      const bancoCodigo = contaBancaria.idBanco ? banco.codigo : contaBancaria.banco || "";
      const swift = `${banco.swiftPrefixo || ""}${contaBancaria.swiftSufixo || ""}`;
      const aba = `${contaBancaria.agencia}${bancoCodigo}${contaBancaria.agenciaDV || ""}`;
      const agencia = `${contaBancaria.agencia}${contaBancaria.agenciaDV ? '-' + contaBancaria.agenciaDV : ''} `;
      const conta = `${contaBancaria.conta}${contaBancaria.contaDV ? '-' + contaBancaria.contaDV : ""} `;

      if (localidade.codigo2 == 'US') {
        return `${bancoNomeAlt} ${swift} ${aba} ${conta}`.trim();
      } else {
        return `${bancoNomeAlt} ${bancoCodigo} ${agencia} ${conta}`.trim();
      }
    },

    copyObject(objeto) {
      const ret = Object.keys(objeto)
        .filter((key) => {
          try {
            JSON.stringify(objeto[key]);
            return true;
          } catch (err) {
            return false;
          }
        })
        .reduce((acc, key) => {
          acc[key] = objeto[key];
          return acc;
        }, {});

      return JSON.parse(JSON.stringify(ret));
    },

    // documentoVerificar
    documentoVerificar(documento, idPais, idTipoDocumento, tipoRetorno) {
      // tipoRetorno: 0-Digito correto (bit), 1-Documento calculado, 2-Digito calculado
      documento = documento.replace(/\D/g, "")

      if (idPais == this.getConst("localidades").paises.brasil.id) {
        const tamanho = idTipoDocumento == this.getTipo_item('documentoPFFederal').id
          ? this.getTipo_item('documentoPFFederal').options.length
          : this.getTipo_item('documentoPJFederal').options.length
        const digitos = 2
        const modulo = 11

        if (documento.length != tamanho) {
          return false
        }

        const posicao = tamanho - digitos
        const documentoNumero = documento.slice(0, posicao)
        const documentoDigito = documento.slice(posicao)
        const documentoArray = documentoNumero.split("")

        for (let i = 0; i < digitos; i++) {
          const resto =
            documentoArray
              .reverse()
              .reduce(
                (acc, item, index) =>
                  acc +
                  parseInt(item) *
                  (index + (idTipoDocumento == this.getTipo_item('documentoPJFederal').id && index > 7 ? -6 : 2)),
                0
              ) % modulo
          const digito = resto > 1 ? modulo - resto : 0
          documentoArray.reverse().push(digito)
        }

        const documentoCalculado = documentoArray.join("")
        const documentoCalculadoDigito = documentoCalculado.slice(posicao)

        if (tipoRetorno == 0) {
          return documentoDigito == documentoCalculadoDigito
        } else if (tipoRetorno == 1) {
          return documentoCalculado
        } else {
          return documentoCalculadoDigito
        }
      } else {
        return true
      }
    },

    // digitoVerificador
    digitoVerificador(numero, options = {}, numeroTemDigitoVerificador = true) {
      if (!numero) {
        return {}
      }

      options = JSON.parse(JSON.stringify(options || {}));

      if (typeof options !== "object") {
        return {}
      }

      // 0. funções auxiliares
      const setOptions = () => {
        /*
        EXEMPLOS
        options = {
          tipo: 
            - "ar_cuit", "br_cpf", "br_cnpj", "..."
          algoritmo:
            - omitido: (default)
            - "noSub": algoritmo não subtrai (options.modulo - modulo)
            - "luhn": algoritmo de Luhn
          tamanho: 
            - omitido: (default)
            - 11: (br_cpf)
            - 14: (br_cnpj)
          digitos: 
            - 1: (default)
            - 2: (br_cpf, br_cnpj)
          modulo: 
            - 11: (default)
            - 10: (luhn)
          zeros:
            - [10, 4, 6, 2];  (pa_ruc)
          n:
            - '5' (pa_ruc_pf)
            - ''  (pa_ruc_pf)
          componentes: [
            {
              inicio: 0,
              fim: 2,
              isWhitelist: true,
              itens: ["10", "20", "30"],
            },
          ],
          replace: { V: "1", E: "2", J: "3", P: "4", G: "5" }  (ve_rif)
          digitoAlfabeto: (converte letra em numero para ser usado no calcula do dv) (mx_rfc_pf, mx_rfc_pj)
          pesosAlt: [
            {
              inicio: 10,
              fim: 11,
              itens: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
              pesos: [7],
            },
          ],
          pesos: 
            - [] (default) .............: []:      (br_cpf)
            - [fim] ....................: [7]:     (ar_cuit, cl_rut, pe_ruc, ve_rif)
                                          [9]:     (br_cnpj, py_ruc, uy_rut)
            - [inicio, fim] ............: [2, -1]: (luhn: ca_)
            - [a, b, c,...] (específico): [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71]:
                                           (co_nit)
          digitosVerificadores:
            - "0" (default) ............: "0":     (br_cpf, br_cnpj)
            - "a" ......................: "1":     (mod 10)
                                          "9":     (ar_cuit)
                                          "K":     (cl_rut, gt_nit)
                                          "X":     (br_rg_sp, br_BB)
            - "abcedfghijk" (específico): não está em uso no momento
        }
        */

        switch (options.tipo) {
          case "ag_tin":
            options.tamanho = 7;
            break;
          case "ai_ssn":
            options.tamanho = 5;
            break;
          case "ai_crn":
            options.tamanho = 5;
            break;
          case "ar_cuit":
            options.tamanho = 11;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 2,
                isWhitelist: true,
                itens: [
                  "20", "23", "24", "27",  // individuals
                  "30", "33", "34",        // companies
                  "50", "51", "55",        // international purposes
                ],
              },
            ];
            options.pesos = [7];
            break;
          case "bb_tin":
            options.tamanho = 13;
            break;
          // case "bo_nit":
          //   break;
          case "bm_sin":
            options.tamanho = 5;
            break;
          case "bm_crn":
            options.tamanho = 5;
            break;
          case "bo_nit":
            break;
          case "br_cpf":
            options.tamanho = 11;
            options.digitos = 2;
            break;
          case "br_cnpj":
            options.tamanho = 14;
            options.digitos = 2;
            options.pesos = [9];
            break;
          case "br_rg_sp":  // provisorio
            options.algoritmo = "noSub";
            options.digitos = 1;
            options.digitosVerificadores = "X";
            break;
          case "bs_tin":
            options.tamanho = 9;
            break;
          case "bz_tin":
            options.tamanho = 6;
            break;
          case "ca_sin":
            options.algoritmo = "luhn";
            options.tamanho = 9;
            options.componentes = [
              {
                inicio: 0,
                fim: 1,
                isWhitelist: true,
                itens: [
                  "1",  // NB, NF, NS, PE
                  "2",  // QC
                  "3",  // QC
                  "4",  // ON
                  "5",  // ON
                  "6",  // AB, MB, SK, NT, NU
                  "7",  // BC, YU
                  "9",  // immigrants and other temp
                ],
              },
              {
                inicio: 0,
                fim: 1,
                isWhitelist: false,
                itens: [
                  "0",  // not used
                  "8",  // not used
                ],
              },
            ];
            break;
          case "ca_bn":
            options.algoritmo = "luhn";
            options.tamanho = 9;
            break;
          case "cl_rut":
            options.digitos = 1;
            options.pesos = [7];
            options.digitosVerificadores = "K";
            break;
          case "co_nit":
            options.digitos = 1;
            options.pesos = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
            // options.digitosVerificadores = "01987654321";  //? confirmar
            break;
          case "cr_cpf":
            options.tamanho = 9;
            options.componentes = [
              {
                inicio: 0,
                fim: 1,
                isWhitelist: true,
                itens: ["0"],
              },
            ];
            break;
          case "cr_cpj":
            options.tamanho = 10;
            options.componentes = [
              {
                inicio: 0,
                fim: 4,
                isWhitelist: true,
                itens: [
                  "2100", "2200", "2300", "2400",
                  "3002", "3003", "3004", "3005", "3006", "3007", "3008", "3009",
                  "3010", "3011", "3012", "3013", "3014",
                  "3101", "3102", "3103", "3104", "3105", "3106", "3107", "3108", "3109",
                  "3110",
                  "4000",
                  "5001",
                ],
              },
            ];
            break;
          case "cw_pn":
            options.tamanho = 9;
            break;
          case "do_ci":
            options.algoritmo = "luhn";
            options.tamanho = 11;
            break;
          case "do_rnc":
            options.tamanho = 9;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 9,
                isWhitelist: true,
                itens: [
                  "101581601", "101582245", "101595422", "101595785",
                  "010233317", "131188691", "401007374", "501341601",
                  "501378067", "501620371", "501651319", "501651823",
                  "501651845", "501651926", "501656006", "501658167",
                  "501670785", "501676936", "501680158", "504654542",
                  "504680029", "504681442", "505038691",
                ],
              },
            ];
            options.pesos = [2, 3, 4, 5, 6, 8, 9, 7];
            options.digitosVerificadores = "012345678912";
            break;
          case "ec_ruc_pf":
            options.algoritmo = "luhn";
            options.tamanho = 10;
            options.componentes = [
              {
                inicio: 0,
                fim: 2,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
                  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
                  "21", "22", "23", "24", "30", "50",
                ],
              },
              {
                inicio: 2,
                fim: 3,
                isWhitelist: true,
                itens: [
                  "0", "1", "2", "3", "4", "5",  // natural RUC
                ],
              },
              {
                inicio: 3,
                fim: 6,
                isWhitelist: false,
                itens: [
                  "000",
                ],
              },
            ];
            break;
          case "ec_ruc_pj":
            options.tamanho = 10;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 2,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
                  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
                  "21", "22", "23", "24", "30", "50",
                ],
              },
              {
                inicio: 2,
                fim: 3,
                isWhitelist: true,
                itens: [
                  "6",  // public RUC
                  "9",  // juridical RUC
                ],
              },
              {
                inicio: 2,
                fim: 7,
                isWhitelist: false,
                itens: [
                  "60000",
                ],
              },
              {
                inicio: 2,
                fim: 6,
                isWhitelist: false,
                itens: [
                  "9000",
                ],
              },
            ];
            options.pesos = [7];
            break;
          case "es_nif_pj":  //? CONFIRMAR
            options.tamanho = 9;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 1,
                isWhitelist: true,
                itens: [
                  "A",  // Sociedades anónimas
                  "B",  // Sociedades de responsabilidad limitada
                  "C",  // Sociedades colectivas
                  "D",  // Sociedades comanditarias
                  "E",  // Comunidades de bienes y herencias yacentes
                  "F",  // Sociedades cooperativas
                  "G",  // Asociaciones
                  "H",  // Comunidades de propietarios en régimen de propiedad horizontal
                  "J",  // Sociedades civiles, con o sin personalidad jurídica
                  "P",  // Corporaciones Locales
                  "Q",  // Organismos públicos
                  "R",  // Congregaciones e instituciones religiosas
                  "S",  // Órganos de la Administración del Estado y de las Comunidades Autónomas
                  "U",  // Uniones Temporales de Empresas
                  "V",  // Otros tipos no definidos en el resto de claves
                ],
              },
            ]
            break;
          case "gt_nit":
            options.digitos = 1;
            options.digitosVerificadores = "K";
            break;
          case "gy_tin":
            options.tamanho = 9;
            break;
          case "hn_rtn":
            options.tamanho = 14;
            break;
          case "ht_nif":
            options.tamanho = 10;
            break;
          case "jm_trn":
            options.tamanho = 9;
            break;
          case "ky_tin":
            options.tamanho = 5;
            break;
          case "ky_tbl":
            options.tamanho = 5;
            break;
          case "lc_tin":
            options.tamanho = 6;
            break;
          case "mx_rfc_pf":
            options.tamanho = 13;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 4,
                isWhitelist: false,
                itens: [
                  "BUEI", "BUEY", "CACA", "CACO", "CAGA", "CAGO", "CAKA", "CAKO", "COGE",
                  "COJA", "COJE", "COJI", "COJO", "CULO", "FETO", "GUEY", "JOTO", "KACA",
                  "KACO", "KAGA", "KAGO", "KAKA", "KOGE", "KOJO", "KULO", "MAME", "MAMO",
                  "MEAR", "MEAS", "MEON", "MION", "MOCO", "MULA", "PEDA", "PEDO", "PENE",
                  "PUTA", "PUTO", "QULO", "RATA", "RUIN",
                ],
              },
              {
                inicio: 4,
                fim: 10,
                isWhitelist: false,
                itens: [
                  "010229", "020229", "030229", "050229", "060229", "070229", "090229",
                  "100229", "110229", "130229", "140229", "150229", "170229", "180229", "190229",
                  "210229", "220229", "230229", "250229", "260229", "270229", "290229",
                  "300229", "310229", "330229", "340229", "350229", "370229", "380229", "390229",
                  "410229", "420229", "430229", "450229", "460229", "470229", "490229",
                  "500229", "510229", "530229", "540229", "550229", "570229", "580229", "590229",
                  "610229", "620229", "630229", "650229", "660229", "670229", "690229",
                  "700229", "710229", "730229", "740229", "750229", "770229", "780229", "790229",
                  "810229", "820229", "830229", "850229", "860229", "870229", "890229",
                  "900229", "910229", "930229", "940229", "950229", "970229", "980229", "990229",
                ],
              },
              {
                inicio: 6,
                fim: 10,
                isWhitelist: false,
                itens: [
                  "0230", "0231", "0431", "0631", "0931", "1131"
                ],
              },
              {
                inicio: 6,
                fim: 8,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09",
                  "10", "11", "12",
                ],
              },
              {
                inicio: 8,
                fim: 10,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09",
                  "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
                  "20", "22", "22", "23", "24", "25", "26", "27", "28", "29",
                  "30", "31",
                ],
              },
            ];
            //                       "0         1         2         3
            //                       "012345678901234567890123456789012345678
            options.digitoAlfabeto = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ";
            options.digitosVerificadores = "A";
            break;
          case "mx_rfc_pj":
            options.tamanho = 13;  // 12 + 1 espaço no início
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 4,
                fim: 10,
                isWhitelist: false,
                itens: [
                  "010229", "020229", "030229", "050229", "060229", "070229", "090229",
                  "100229", "110229", "130229", "140229", "150229", "170229", "180229", "190229",
                  "210229", "220229", "230229", "250229", "260229", "270229", "290229",
                  "300229", "310229", "330229", "340229", "350229", "370229", "380229", "390229",
                  "410229", "420229", "430229", "450229", "460229", "470229", "490229",
                  "500229", "510229", "530229", "540229", "550229", "570229", "580229", "590229",
                  "610229", "620229", "630229", "650229", "660229", "670229", "690229",
                  "700229", "710229", "730229", "740229", "750229", "770229", "780229", "790229",
                  "810229", "820229", "830229", "850229", "860229", "870229", "890229",
                  "900229", "910229", "930229", "940229", "950229", "970229", "980229", "990229",
                ],
              },
              {
                inicio: 6,
                fim: 10,
                isWhitelist: false,
                itens: [
                  "0230", "0231", "0431", "0631", "0931", "1131"
                ],
              },
              {
                inicio: 6,
                fim: 8,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09",
                  "10", "11", "12",
                ],
              },
              {
                inicio: 8,
                fim: 10,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09",
                  "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
                  "20", "22", "22", "23", "24", "25", "26", "27", "28", "29",
                  "30", "31",
                ],
              },
            ];
            //                       "0         1         2         3
            //                       "012345678901234567890123456789012345678
            options.digitoAlfabeto = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ";
            options.digitosVerificadores = "A";
            break;
          case "ni_ruc_pf":
            options.tamanho = 14;
            break;
          case "pa_ruc_pf":
            options.tamanho = 22;
            options.digitos = 2;
            // options.zeros = [n, provincia, letra, folio, asiento, dv]
            options.zeros = [0, 2, 2, 3, 5, 2];
            options.n = '5';  // #1
            options.replaces = [  // #3
              {
                trocar: {
                  inicio: 10,
                  fim: 12,
                  dePor: {
                    "NT": "43", "AV": "15", "PI": "79",
                    "PE": "75", "0E": "50", "0N": "40"
                  }
                },
              },
            ];
            break;
          case "pa_ruc_pj":
            options.tamanho = 22;
            options.digitos = 2;
            // option.zeros = [n, tomo, letra, folio, asiento, dv]
            options.zeros = [0, 10, 4, 6, 2];
            options.n = '';  // #1
            options.replaces = [
              {
                condicoes: {
                  inicio: 2,
                  fim: 5,
                  itens: ["000", "001", "002", "003", "004", "005"],
                },
                trocar: {
                  inicio: 5,
                  fim: 7,
                  dePor: {
                    "00": "00", "10": "01", "11": "02", "12": "03",
                    "13": "04", "14": "05", "15": "06", "16": "07",
                    "17": "08", "18": "09", "19": "01", "20": "02",
                    "21": "03", "22": "04", "23": "07", "24": "08",
                    "25": "09", "26": "02", "27": "03", "28": "04",
                    "29": "05", "30": "06", "31": "07", "32": "08",
                    "33": "09", "34": "01", "35": "02", "36": "03",
                    "37": "04", "38": "05", "39": "06", "40": "07",
                    "41": "08", "42": "09", "43": "01", "44": "02",
                    "45": "03", "46": "04", "47": "05", "48": "06",
                    "49": "07"
                  }
                },
              },
              {
                trocar: {
                  inicio: 10,
                  fim: 12,
                  dePor: { "NT": "43" }
                },
              },
            ];
            options.pesosAlt = [
              {
                inicio: 3,
                fim: 6,
                itens: ["000", "001", "002", "003", "004"],
                pesos: [
                  2, 3, 4, 5, 6, 7, 8, 9,
                  10, 11, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                  20, 21
                ]
              }
            ];
            break;
          case "pe_ruc":
            options.tamanho = 11;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 2,
                isWhitelist: true,
                itens: [
                  "10",  // natural person
                  "15",  // forças armadas?
                  "17",  // estrangeiros?
                  "20",  // legal person
                ],
              },
            ];
            options.pesos = [7];
            break;
          case "pr_ssn":
            options.tamanho = 9;
            options.componentes = [
              {
                inicio: 0,
                fim: 1,
                isWhitelist: false,
                itens: ["9"],
              },
              {
                inicio: 0,
                fim: 3,
                isWhitelist: false,
                itens: ["000", "666"],
              },
              {
                inicio: 0,
                fim: 9,
                isWhitelist: false,
                itens: ["078051120", "457555462", "219099999"],
              },
              {
                inicio: 3,
                fim: 5,
                isWhitelist: false,
                itens: ["00"],
              },
              {
                inicio: 5,
                fim: 9,
                isWhitelist: false,
                itens: ["0000"],
              },
            ];
            break;
          case "pr_ein":
            options.tamanho = 9;
            options.componentes = [
              {
                inicio: 0,
                fim: 2,
                isWhitelist: true,
                itens: [
                  // Brookhaven
                  "01", "02", "03", "04", "05", "06",
                  "11", "13", "14", "16",
                  "21", "22", "23", "25",
                  "34",
                  "51", "52", "54", "55", "56", "57", "58", "59",
                  "65",
                  // Andover
                  "10", "12",
                  // Fresno
                  "15",
                  "24",
                  // Internet
                  "20", "26", "27",
                  "45", "46", "47",
                  "81", "82", "83", "84",
                  // Cincinnati
                  "30", "32", "35", "36", "37", "38",
                  "61",
                  // Small Business Administration (SBA)
                  "31",
                  // Philadelphia
                  "33", "39",
                  "41", "42", "43", "46", "48",
                  "62", "63", "64", "66", "68",
                  "71", "72", "73", "74", "75", "76", "77",
                  "85", "86", "87", "88",
                  "91", "92", "93", "98", "99",
                  // Kansas City
                  "40", "44",
                  // Austin
                  "50", "53",
                  // Atlanta
                  "60", "67",
                  // Ogden
                  "80", "90",
                  // Memphis
                  "94", "95",
                ],
              },
              {
                inicio: 0,
                fim: 2,
                isWhitelist: false,
                itens: [
                  "07", "08", "09",
                  "17", "18", "19",
                  "28", "29",
                  "49",
                  "69",
                  "70", "78", "79",
                  "89",
                  "96", "97"
                ]
              },
            ];
            break;
          case "py_ruc":
            options.digitos = 1;
            options.pesos = [9];
            break;
          case "sr_tin":
            options.tamanho = 5;
            break;
          case "sv_nit":
            options.tamanho = 14;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 1,
                isWhitelist: true,
                itens: [
                  "0", "1",  // pessoa natural de El Salvador
                  "6",       // empresas???
                  "9",       // estrangeiros
                ],
              },
              {
                inicio: 4,
                fim: 10,
                isWhitelist: false,
                itens: [
                  "290201", "290202", "290203", "290205", "290206", "290207", "290209",
                  "290210", "290211", "290213", "290214", "290215", "290217", "290218", "290219",
                  "290221", "290222", "290223", "290225", "290226", "290227", "290229",
                  "290230", "290231", "290233", "290234", "290235", "290237", "290238", "290239",
                  "290241", "290242", "290243", "290245", "290246", "290247", "290249",
                  "290250", "290251", "290253", "290254", "290255", "290257", "290258", "290259",
                  "290261", "290262", "290263", "290265", "290266", "290267", "290269",
                  "290270", "290271", "290273", "290274", "290275", "290277", "290278", "290279",
                  "290281", "290282", "290283", "290285", "290286", "290287", "290289",
                  "290290", "290291", "290293", "290294", "290295", "290297", "290298", "290299",
                ],
              },
              {
                inicio: 4,
                fim: 8,
                isWhitelist: false,
                itens: [
                  "2002", "3102", "3104", "3106", "3109", "3111"
                ],
              },
              {
                inicio: 4,
                fim: 6,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09",
                  "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
                  "20", "22", "22", "23", "24", "25", "26", "27", "28", "29",
                  "30", "31",
                ],
              },
              {
                inicio: 6,
                fim: 8,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09",
                  "10", "11", "12",
                ],
              },
            ];
            options.pesosAlt = [
              {
                inicio: 10,
                fim: 13,
                itens: ["100"],
                pesos: [],
              },
              {
                inicio: 10,
                fim: 11,
                itens: ["0"],
                pesos: [],
              },
            ];
            options.pesos = [7];
            break;
          case "sx_ssc":
            options.tamanho = 5;
            break;
          case "sx_tin":
            options.tamanho = 5;
            break;
          case "tt_bir":
            options.tamanho = 10;
            break;
          case "us_ssn":
            options.tamanho = 9;
            options.componentes = [
              {
                inicio: 0,
                fim: 1,
                isWhitelist: false,
                itens: ["9"],
              },
              {
                inicio: 0,
                fim: 3,
                isWhitelist: false,
                itens: ["000", "666"],
              },
              {
                inicio: 0,
                fim: 9,
                isWhitelist: false,
                itens: ["078051120", "457555462", "219099999"],
              },
              {
                inicio: 3,
                fim: 5,
                isWhitelist: false,
                itens: ["00"],
              },
              {
                inicio: 5,
                fim: 9,
                isWhitelist: false,
                itens: ["0000"],
              },
            ];
            break;
          case "us_ein":
            options.tamanho = 9;
            options.componentes = [
              {
                inicio: 0,
                fim: 2,
                isWhitelist: true,
                itens: [
                  // Brookhaven
                  "01", "02", "03", "04", "05", "06",
                  "11", "13", "14", "16",
                  "21", "22", "23", "25",
                  "34",
                  "51", "52", "54", "55", "56", "57", "58", "59",
                  "65",
                  // Andover
                  "10", "12",
                  // Fresno
                  "15",
                  "24",
                  // Internet
                  "20", "26", "27",
                  "45", "46", "47",
                  "81", "82", "83", "84",
                  // Cincinnati
                  "30", "32", "35", "36", "37", "38",
                  "61",
                  // Small Business Administration (SBA)
                  "31",
                  // Philadelphia
                  "33", "39",
                  "41", "42", "43", "46", "48",
                  "62", "63", "64", "66", "68",
                  "71", "72", "73", "74", "75", "76", "77",
                  "85", "86", "87", "88",
                  "91", "92", "93", "98", "99",
                  // Kansas City
                  "40", "44",
                  // Austin
                  "50", "53",
                  // Atlanta
                  "60", "67",
                  // Ogden
                  "80", "90",
                  // Memphis
                  "94", "95",
                ],
              },
              {
                inicio: 0,
                fim: 2,
                isWhitelist: false,
                itens: [
                  "07", "08", "09",
                  "17", "18", "19",
                  "28", "29",
                  "49",
                  "69",
                  "70", "78", "79",
                  "89",
                  "96", "97"
                ]
              },
            ];
            break;
          case "uy_rut":
            options.tamanho = 12;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 2,
                isWhitelist: true,
                itens: [
                  "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
                  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
                  "21",
                ],
              },
              {
                inicio: 2,
                fim: 8,
                isWhitelist: false,
                itens: ["000000"],
              },
              {
                inicio: 8,
                fim: 11,
                isWhitelist: true,
                itens: ["001"],
              },
            ];
            options.pesos = [9];
            break;
          case "ve_rif":
            options.tamanho = 10;
            options.digitos = 1;
            options.componentes = [
              {
                inicio: 0,
                fim: 1,
                isWhitelist: true,
                itens: ["V", "E", "J", "P", "G"],
              },
            ];
            options.replaces = [
              {
                trocar: {
                  inicio: 0,
                  fim: 1,
                  dePor: {
                    "V": "1",  // natural person born in Venezuela
                    "E": "2",  // foreign natural person
                    "J": "3",  // company
                    "P": "4",  // passport
                    "G": "5",  // government
                  }
                },
              },
            ];
            options.pesos = [7];
            break;
          case "vg_bvi":
            options.tamanho = 6;
            break;
        }

        if (options.algoritmo === "luhn") {
          options.digitos = 1;
          options.modulo = 10;
          options.pesos = [2, -1];
        }

        options.digitos = options.digitos ?? 0;  // número de digitos do dígito verificador
        options.modulo = options.modulo || 11;
      }

      const calcDigitoVerificador = (numero, options) => {
        const getDigito = (digito, options) => {
          if (options.digitoAlfabeto) {
            return parseInt(options.digitoAlfabeto.indexOf(digito));
          } else {
            return parseInt(digito);
          }
        }

        const getPeso = (numero, index, pesos = []) => {
          let inicio;
          let fim;

          switch (pesos.length) {
            case 0:
              inicio = 2;
              fim = numero.length + 1;
              break;
            case 1:
              inicio = 2;
              fim = pesos[0];
              break;
            case 2:
              inicio = pesos[0];
              fim = pesos[1];
              break;
            default:
              inicio = null;
              fim = null;
              break;
          }

          if (fim !== null) {
            return (Math.sign(fim) * (index % (fim - inicio + 1))) + inicio;
          } else {
            return pesos[index % pesos.length];
          }
        }

        const getDigitoComPeso = (digitoComPeso, options) => {
          if (options.algoritmo === "luhn") {
            return digitoComPeso
              .toString()
              .split("")
              .reduce((acc, item) => acc += parseInt(item), 0);
          } else {
            return digitoComPeso;
          }
        }

        const getDigitoVerificador = (digitosVerificadores = "0") => {
          switch (digitosVerificadores) {
            case "0":
              return "012345678900"  // (br_cpf, br_cnpj)
            case "1":
              return "012345678901"  // (mod 10)
            case "9":
              return "012345678990"  // (ar_cuit)
            case "A":
              return "0123456789A0"  // (mx_rfc_pf, mx_rfc_pj)
            case "K":
              return "0123456789K0"  // (cl_rut, gt_nit)
            case "X":
              return "0123456789X0"  // (br_rg_sp, br_BB)
            default:
              return digitosVerificadores;
          }
        }

        const somatoria = numero
          .split("")
          .reverse()
          .reduce((acc, item, index) => {
            const digitoComPeso = getDigitoComPeso(
              getDigito(item, options) * getPeso(numero, index, options.pesos),
              options
            );

            return acc += digitoComPeso;
          }, 0);

        const modulo = somatoria % options.modulo;

        return getDigitoVerificador(options.digitosVerificadores)[
          options.algoritmo === "noSub"
            ? modulo
            : options.modulo - modulo
        ];
      }

      // 1. options
      setOptions();

      // 2. numeroLimpo
      let numeroLimpo;

      if (options.digitos > 0) {
        if (options.zeros) {  // (pa_ruc_pf, pa_ruc_pj)
          // o último elemento de options.zeros pode ser o spliter
          // então, inverte-se os lementos do array para pegar o spliter no primeiro elemento
          let spliter = options.zeros.reverse()[0];
          // depois de pegar o spliter, inverte-se de novo para voltar o array ao normal
          options.zeros.reverse();
          spliter = "./-".includes(spliter) ? spliter : "-";

          const numeroTemp = numero.split(spliter)

          if (options.tipo == "pa_ruc_pf") {
            if (numeroTemp.length === 3) {
              numeroTemp.unshift("0");
              //? neste caso, ajusta os zeros e torna n=''
              // options.zeros = [n, provincia, letra, folio, asiento, dv]
              options.zeros = [0, 1, 2, 11, 6, 2];
              options.n = "";
            } else if (/[A-Z]{1,2}/.test(numeroTemp[0])) {
              numeroTemp.unshift("0");
            }

            if (!(/[A-Z]{1,2}/.test(numeroTemp[1]))) {
              // Exceção #3
              if (numeroTemp[0].length > 0 && numeroTemp[0].length <= 2) {
                numeroTemp.splice(1, 0, "00");
              }
            }
          } else if (options.tipo == "pa_ruc_pj") {
            if (numeroTemp.length == 5) {
              // neste caso, mesmo sendo pj, deve usar os zeros do pf
              // e pesosAlt default
              options.zeros = [0, 2, 2, 3, 5, 2];
              options.pesosAlt[0].pesos = [];
            }
          }

          numeroTemp.unshift(options.n);

          numeroLimpo = numeroTemp
            .map((item, i) => item.padStart(options.zeros[i], "0"))
            .join("")

          numeroLimpo = numeroLimpo.padStart(options.tamanho, "0");
        } else {
          numeroLimpo = numero.replace(/\W/g, "");
        }
      } else {
        numeroLimpo = numero;
      }

      // 3. prep
      options.tamanho = options.tamanho || numeroLimpo.length;  //  tamanho do numero com digito verificador

      const digitos = numeroTemDigitoVerificador ? options.digitos : 0;
      const posicao = options.tamanho - digitos;

      numeroLimpo = numeroLimpo.padStart(options.tamanho, " ");

      const prep = {
        numeroSemDigitoVerificador: numeroLimpo.substring(0, posicao),
        numeroSemDigitoVerificadorReplaced: numeroLimpo.substring(0, posicao),
        digitoVerificador: numeroLimpo.substring(posicao, posicao + digitos),
        isComponentesOk: null,
      };

      // options.componentes
      if (options.componentes) {
        if (Array.isArray(options.componentes)) {
          // prep.isComponentesOk = !options.componentes.some(item => {
          // const componente = prep.numeroSemDigitoVerificadorReplaced
          //   .substring(item.inicio, item.fim);

          for (const item of options.componentes) {
            const componente = numeroLimpo.substring(item.inicio, item.fim);
            const includesInItens = item.itens.includes(componente);

            // case  isWhiteList  tamanhoFull  includes  acao          
            // 1     false        false        FALSE     continua
            // 2     false        false        true      return false   ok
            // 3     false        true         FALSE     continua
            // 4     false        true         true      return false   ok

            // 5     true         false    =   false     return false   ok
            // 6     true         false    x   true      continua
            // 7     true         true     X   false     contina
            // 8     true         true     =   true      return true    ok

            // return true: 8
            // return false: 2, 4, 5
            // continua: 1, 3, 6, 7

            if (item.isWhitelist) {
              if ((item.fim - item.inicio === options.tamanho) === includesInItens) {
                // case 5, 8
                prep.isComponentesOk = includesInItens;
                break;
              }
            } else {
              if (includesInItens) {
                // case 2, 4
                prep.isComponentesOk = false;
                break;
              }
            }
            // return item.isWhitelist ? !includesInItens : includesInItens   REMOVER
          }
        }
      }

      // options.replaces
      if (options.replaces) {
        if (Array.isArray(options.replaces)) {
          options.replaces.forEach(item => {
            let condicao = true;

            if (item.condicoes) {
              const componente = prep.numeroSemDigitoVerificadorReplaced
                .substring(item.condicoes.inicio, item.condicoes.fim);
              condicao = item.condicoes.itens.includes(componente);
            }

            if (condicao) {
              const trocarDe = prep.numeroSemDigitoVerificadorReplaced
                .substring(item.trocar.inicio, item.trocar.fim);
              const trocarPara = item.trocar.dePor[trocarDe];

              if (trocarPara) {
                const parte1 = prep.numeroSemDigitoVerificadorReplaced
                  .substring(0, item.trocar.inicio);
                const parte2 = prep.numeroSemDigitoVerificadorReplaced
                  .substring(item.trocar.fim);
                prep.numeroSemDigitoVerificadorReplaced =
                  `${parte1}${trocarPara}${parte2}`;
              }
            }
          })
        }
      }

      // options.pesosAlt
      if (options.pesosAlt) {
        for (const item of options.pesosAlt) {
          const componente = numeroLimpo.substring(item.inicio, item.fim);

          if (item.itens.includes(componente)) {
            options.pesos = item.pesos;
          }
        }
      }

      // 4. calc
      const calc = {
        tipo: options.tipo,
        algoritmo: options.algoritmo || "default",
        tamanho: options.tamanho,
        digitos: options.digitos,
        modulo: options.modulo,
        numeroOriginal: numero,
        numeroLimpo,
        numeroConsiderado: prep.numeroSemDigitoVerificadorReplaced,
        digitoVerificador: "",
      };

      if (prep.isComponentesOk === null) {
        for (let index = 0; index < options.digitos; index++) {
          calc.numeroConsiderado =
            `${prep.numeroSemDigitoVerificadorReplaced}${calc.digitoVerificador}`;

          calc.digitoVerificador += calcDigitoVerificador(calc.numeroConsiderado, options)
        }
      } else if (!prep.isComponentesOk) {
        calc.digitoVerificador = "?";
      }

      calc.numeroComDigitoVerificador = `${prep.numeroSemDigitoVerificador}${calc.digitoVerificador}`;
      calc.isDigitoVerificadorOk =
        prep.isComponentesOk || prep.digitoVerificador === calc.digitoVerificador;

      return calc;
    },

    documentoMascara(idPais, idTipo, idTipoPessoa) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id,
        idTipo,
        idTipoPessoa
      )[0]?.mascara;

      return mascara;
    },

    // enderecoFormat
    enderecoFormat(objeto) {
      return this.getLocalidade(objeto.idPais).isEnderecoNumeroPosicaoInicio
        ? `${objeto.numero} ${objeto.endereco}`
        : `${objeto.endereco} ${objeto.numero}`;
    },

    // endTouchPressed
    endTouchPressed() {
      this.clickPressed = false;

      setTimeout(() => {
        this.clickAbort = false;
      }, 100);
    },

    // getFirstLocaleContent
    getFirstLocaleContent(objeto) {
      return (objeto && typeof objeto == "object") ? Object.values(objeto).reduce((acc, value) => acc = acc || value, "") : "";
    },

    // getMoeda
    getMoeda(idPais) {
      const localidadeMoeda = this.getLocalidade_moedas(
        idPais || this.getVar("idPaisConfiguracaoRegional")
      ).find((moeda) => moeda.isOficial);

      return this.getTipo_item(localidadeMoeda?.value);
    },

    getPreco(produto, idFaturamentoMensal) {
      const tiposFaturamentoMensal = this.getTipo_itens(this.getTipo("faturamentoMensal").id)
      const tipoFaturamentoMensal = this.getTipo_item(idFaturamentoMensal)
      const faixas = tiposFaturamentoMensal.length - 1
      const faixa = (tipoFaturamentoMensal?.order || 1) - 1

      return parseFloat(produto.preco) * (1 + (((parseFloat(produto.variacaoPreco || 0) / faixas) * faixa) / 100));
    },

    // getVal
    getVal(texto) {
      try {
        return eval(texto);
      } catch (e) {
        return texto;
      }
    },

    getValorTraduzido(objeto, coluna) {
      let valorTraduzido = objeto[coluna]
      let locale = this.getVar("locale");

      if (valorTraduzido && typeof valorTraduzido == "object") {
        if (objeto.idPessoaParceiro && !valorTraduzido[locale]) {
          const usuario = this.getVar("usuarios").find(usuario => usuario.pessoa.id == objeto.idPessoaParceiro);

          if (usuario?.parceiro?.idiomas?.length) {
            locale = this.localeById(usuario.parceiro.idiomas[0].idLocale);
          }
        }

        valorTraduzido = valorTraduzido[locale];
      }

      return valorTraduzido;
    },

    // hexToRgba
    hexToRgba(isBarraInferiorColorida = false, alpha) {
      let colors;

      if (isBarraInferiorColorida) {
        colors = this.$vuetify.theme.themes.light.primary
          .match(/\w{2}/g)
          .map(color => parseInt(color, 16))
          .join();
        alpha = alpha || 0.7;
      } else {
        colors = '0, 0, 0';
        alpha = alpha || 0.6;
      }

      return `rgba(${colors}, ${alpha})`
    },

    // localeById
    localeById(id, padrao = true) {
      const locale = this.getLocalidade_idioma(id);
      const idioma = padrao
        ? this.getTipo_item(locale.idIdioma).codigo
        : this.getTipo_item(locale.idIdioma).nomeAlt;
      const separador = padrao ? "-" : "";
      const localidade = padrao ? this.getLocalidade(locale.idPai)?.codigo2 : "";

      return idioma && (localidade || !padrao) ? `${idioma}${separador}${localidade}` : "pt-BR";
    },

    // localeByPais
    localeByPais(id, padrao = true) {
      let locale = "";
      const idiomas = this.getLocalidade_idiomas(id)
        .map(idioma => this.getLocalidade_idioma(idioma.value))

      const idLocale = (idiomas.find(idioma => idioma.isLocale) || idiomas[0])?.id;

      if (idLocale) {
        locale = this.localeById(idLocale, padrao);
      }

      return locale;
    },

    // cleanMask
    cleanMask(masks, cleanOptional = false) {
      if (!Array.isArray(masks) && masks.includes("?")) {

        const cleanMask = (mask) => {
          const cleanMask = [];

          if (cleanOptional) {
            mask = mask
              .replace(/(\(.+\)\?)/g, function () {
                return arguments[1].replace(/[()?]/g, "");
              }).replace(/[!?]/g, "")
              .replace(/\?/g, "");
          }

          let arrMaskMatch = [];

          (mask.match(/\[.+\]/) || []).forEach(maskMatch => {
            const maskMatchClean = maskMatch.replace(/[[\]]/g, "");
            const indexMask = arrMaskMatch.length;
            const currentMask = arrMaskMatch[indexMask - 1] || mask;

            maskMatchClean.match(/.!?(?!!)/g).forEach(char => {
              arrMaskMatch
                .filter((_, i) => i < indexMask)
                .forEach((maskCleaned, i) => arrMaskMatch[i] = maskCleaned.replace(maskMatch, char))

              arrMaskMatch.push(currentMask.replace(maskMatch, char))
            });
          });

          arrMaskMatch = arrMaskMatch.length ? arrMaskMatch : [mask];

          cleanMask.push(...arrMaskMatch);

          return cleanMask;
        }

        const arrMask = [...cleanMask(masks)];

        if (cleanOptional) {
          while (masks.includes("?")) {
            const regex = new RegExp("(\\(.+\\)\\?)");
            const regexReplace = masks.match(regex) ? regex : new RegExp(".\\?");
            masks = masks.replace(regexReplace, "");
            arrMask.push(...cleanMask(masks));
          }
        }

        masks = arrMask.sort((a, b) => a.length - b.length);
      }

      return masks
    },

    // maskByTipo
    maskByTipo(idLocalidade, idTipo, search, idTipoPessoa) {
      if (idLocalidade) {
        const configuracaoRegional = this.configuracaoRegionalByTipo(idLocalidade, idTipo, search, idTipoPessoa)

        return Array.isArray(configuracaoRegional?.mascaras)
          ? configuracaoRegional?.mascara
            .match(new RegExp(`(${this.strTokens})+`, "g"))
            .join("")
          : configuracaoRegional?.mascara || "";
      } else {
        return "";
      }
    },

    // masksByPais
    masksByPais(id, idTipo, search = "", idTipoPessoa) {
      let masks

      try {
        idTipo = idTipo || this.getTipo_item(search)?.id;

        if (id, idTipo) {
          masks = this.getLocalidade_configuracoesRegionais(id, idTipo, idTipoPessoa)[0].mascaras;
          masks = this.cleanMask(masks);
        }
      } catch (err) {
        masks = ""
      }

      return masks;
    },

    // masksInfoByPais
    masksInfoByPais(id, idTipo, search = "", idTipoPessoa) {
      if (!id) {
        return ""
      }

      idTipo = idTipo || this.getTipo_item(search)?.id;

      const tokens = this.getConst("config").tokens;
      const masks = this.masksByPais(id, idTipo, search, idTipoPessoa);
      const localidade_configuracoesRegionais = this.getLocalidade_configuracoesRegionais(id, idTipo, idTipoPessoa)[0];
      const options = localidade_configuracoesRegionais?.options || {}
      let ret = "";
      let legenda = [""];

      if (masks) {
        Object.keys(tokens).forEach(token => {
          const regex = new RegExp(`${token.replace("*", "\\*").replace("$", "\\$")}(?!!)`)
          let indexToken

          const hasTokenVerify = (mask) => {
            const tokenMatch = mask.match(regex)
            if (tokenMatch) {
              indexToken = tokenMatch.index
              return true;
            } else {
              return false;
            }
          }

          const hasToken = (!Array.isArray(masks) ? [masks] : masks).some(mask => hasTokenVerify(mask))

          if (hasToken) {
            let tokenLegenda = tokens[token].legenda

            if (tokens[token].simbolos != undefined) {
              if (tokenLegenda.includes("<>")) {
                let simbolos = options[token]?.simbolos || tokens[token].simbolos
                simbolos = simbolos ? ` ou ${simbolos}` : "";
                tokenLegenda = tokenLegenda.replace("<>", simbolos);
              } else {
                tokenLegenda += options[token]?.simbolos || tokens[token].simbolos
              }
            }

            legenda.push({ legenda: `${token}: ${tokenLegenda}`, indexToken });
          }
        })

        if (legenda.length > 1) {
          legenda = legenda
            .sort((a, b) => a.indexToken - b.indexToken)
            .map(item => item.legenda);

          if ((!Array.isArray(masks) ? [masks] : masks).some(mask => mask.includes("?"))) {
            legenda.push([
              {
                text: "caractere opcional",
                class: "orange--text"
              }
            ])
          }
        }

        let configuracao = localidade_configuracoesRegionais.nome
          ? localidade_configuracoesRegionais.nome + "\n"
          : "";

        configuracao += localidade_configuracoesRegionais.nomeAlt && localidade_configuracoesRegionais.nome != localidade_configuracoesRegionais.nomeAlt
          ? localidade_configuracoesRegionais.nomeAlt + "\n"
          : "";

        configuracao += configuracao ? "\n" : ""

        let strMasks = (Array.isArray(masks) ? masks.join("\n") : masks).replaceAll("!", "")
        strMasks = strMasks
          .split("\n")
          .map(strMask => {
            return strMask.match(/.(?=\?)\?|.(?!\?)| /g)
              .map(item => {
                return {
                  text: item.replace("?", ""),
                  class: item.includes("?") ? "orange--text" : item.includes(" ") ? "ml-1" : ""
                }
              })
          })

        // ret = `${ configuracao }${ strMasks }${ legenda }`;
        const text = [
          ...configuracao.split("\n"),
          ...strMasks,
          ...legenda,
        ]

        const types = [
          ...configuracao.split("\n").map(() => null),
          ...strMasks.map(() => null),
          ...legenda.map(() => 'label'),
        ]

        ret = { text, types }
      }

      return ret;
    },

    // moneyValor
    moneyValor(
      prefix = "",
      precision = this.getConst("config").money.precision,
      suffix = "",
      decimal = ",",
      thousands = ".",
      masked = true
    ) {
      return {
        decimal,
        thousands,
        prefix,
        suffix,
        precision,
        masked
      };
    },

    // moneyWidth
    moneyWidth(valor, tamanhoSimbolo = 0, decimal = 0, ajuste = 0) {
      if (typeof valor !== 'number') {
        if (!isNaN(valor)) {
          valor = parseFloat(valor)
        }
      }
      if (typeof valor === 'number') {
        valor = valor || 1

        if (valor > -1 && valor < 0) {
          valor -= 1
        }

        if (valor > 0 && valor < 1) {
          valor += 1
        }
      }

      let tamanho

      if (typeof valor === 'number') {
        const tamanhoNegativo = valor < 0 ? + 1 : 0
        const tamanhoDecimal = decimal > 0 ? decimal + 1 : 0
        const tamanhoValor = Math.floor(Math.log10(Math.abs(valor))) + 1
        const tamanhoMilhar = Math.floor((tamanhoValor - 1) / 3)
        tamanho = tamanhoSimbolo + tamanhoNegativo + tamanhoValor + tamanhoMilhar + tamanhoDecimal
      } else if (typeof valor === 'string') {
        tamanho = valor.split("").length
      }

      return Math.round((tamanho * this.getConst("config").money.width) * (1 + (ajuste / 100)))
    },

    // numberFormat
    // numberFormat(valor, style, decimal, moedaCodigo, locale)
    numberFormat(value, style = 'decimal', decimal, moedaCodigo, idPais, locale) {
      if ([undefined, null].includes(value)) {
        return value;
      }

      let options;

      if (style == 'currency') {
        const localidadeMoeda = this.getLocalidade_moedas(
          this.getVar("idPaisConfiguracaoRegional")
        ).find((moeda) => moeda.isOficial);

        const moeda = this.getTipo_item(localidadeMoeda?.value);

        const currency = moedaCodigo || moeda?.codigo || this.getVar("moedaCodigo");

        if (decimal) {
          options = { style, currency, minimumFractionDigits: decimal, maximumFractionDigits: decimal }
        } else {
          options = { style, currency }
        }
      } else {
        if (decimal) {
          options = { style, minimumFractionDigits: decimal, maximumFractionDigits: decimal }
        } else {
          options = { style }
        }
      }

      idPais = idPais ? this.getVal(idPais) : this.getVar("idPaisConfiguracaoRegional");
      locale = locale || this.localeByPais(idPais);

      return locale ? new Intl.NumberFormat(locale, options).format(value) : value;
    },

    // onScroll
    onScroll(e) {
      if (typeof window === "undefined") return;

      this.showFab = false;

      clearTimeout(this.timeOutFab);
      clearTimeout(this.timeOutBottom);

      const top = window.pageYOffset || e.target?.scrollTop || 0;

      this.timeOutFab = setTimeout(() => (this.showFab = false), 5000);

      this.timeOutBottom = setTimeout(() => {
        this.showFab =
          top > 20 &&
          top <
          document.documentElement.offsetHeight - window.screen.height - 20;
      }, 500);
      //? }, this.getConst("config").bottomBar.timeout);

      this.iconScroll =
        window.pageYOffset < this.pageYOffsetScroll
          ? "mdi-arrow-up"
          : "mdi-arrow-down";

      this.pageYOffsetScroll = window.pageYOffset;
    },

    // optionsByTipo
    optionsByTipo(idLocalidade, idTipo, search, idTipoPessoa) {
      if (idLocalidade) {
        const configuracaoRegional = this.configuracaoRegionalByTipo(idLocalidade, idTipo, search, idTipoPessoa)

        return Array.isArray(configuracaoRegional?.mascaras)
          ? configuracaoRegional?.mascara
            .match(new RegExp(`(${this.strTokens})+`, "g"))
            .join("")
          : configuracaoRegional?.options || "";
      } else {
        return "";
      }
    },

    // pagePrint
    async pagePrint(printKey) {
      let html = Object.keys(this.$refs)
        .filter((key) => key.startsWith("printArea"))
        .reduce((acc, key) => (acc += this.$refs[key].innerHTML), "");

      html = html.replaceAll("v-expansion-panel", " ");
      html = html.replaceAll(" -header", "v-expansion-panel-header");

      const print = this.getVar("print");
      print[printKey] = html;
      this.setVar({ print });

      const localStorageVar = JSON.parse(
        await localStorage.getItem(this.getConst("app").varKey)
      );

      if (localStorageVar) {
        localStorageVar.print = print
      }

      await localStorage.setItem(
        this.getConst("app").varKey,
        JSON.stringify(localStorageVar)
      );

      const routeData = this.$router.resolve({ name: 'Print', query: { printKey } });
      window.open(routeData.href, '_blank');
    },

    //pessoaDocumentosFormat
    pessoaDocumentosFormat(pessoa) {
      return pessoa.documentos.reduce(
        (acc, documento, i) =>
        (acc +=
          i == 0
            ? documento.numero.mask(
              this.documentoMascara(pessoa.endereco.idPais, pessoa.idTipo)
            )
            : ` • ${documento.numero}`),
        ""
      );
    },

    pessoaTelefonesFormat(pessoa) {
      const tokens = this.getConst("config").tokens;
      const strTokens = Object.keys(tokens)
        .join("|")
        .replace("*", "\\*")
        .replace("$", "\\$");

      return pessoa.telefones.reduce(
        (acc, telefone, i) => {
          const configuracaoRegionalTelefone =
            this.getLocalidade_configuracoesRegionais(
              telefone.idPais,
              this.getTipo_item("configuracaoRegionalTelefone").id
            )[0];

          const mascara = Array.isArray(
            configuracaoRegionalTelefone.mascaras
          )
            ? configuracaoRegionalTelefone.mascara
              .match(new RegExp(`(${strTokens}) + `, "g"))
              .join("")
            : configuracaoRegionalTelefone.mascara;

          return acc += `${i == 0 ? "" : " • "}${telefone.numero.mask(mascara)}`
        }, "");
    },

    // pessoasEnderecoFormat
    pessoasEnderecoFormat(pessoaEndereco, hasSearch = false) {
      const ret = {};

      const endereco = this.enderecoFormat(pessoaEndereco);
      const complemento = pessoaEndereco.complemento ? ` ${pessoaEndereco.complemento}` : "";
      const bairro = pessoaEndereco.bairro ? `, ${pessoaEndereco.bairro}` : "";
      ret.endereco1 = `${endereco}${complemento}${bairro}`;

      let objCidade = this.getLocalidade(pessoaEndereco.idCidade);

      //? caso a cidade não tenha sido carregada pelo vuex
      // if (!objCidade) {
      //   const url = `${this.getConst("app").baseUrlBackend}/localidades/${this.endereco.idCidade}`;
      //   const resCidade = await axios.get(url);
      //   objCidade = resCidade.data ? resCidade.data.main.detail : null;
      // }

      const cidade = objCidade?.nome || "";
      const uf = ` ${this.getLocalidade(objCidade?.idPai)?.codigo2 || ""}` || "";
      const codigoPostal = pessoaEndereco.codigoPostal ? pessoaEndereco.codigoPostal.mask(
        this.cepMascara(pessoaEndereco.idPais)
      ) : "";
      const pais = this.getLocalidade(pessoaEndereco.idPais).nome;
      ret.endereco2 = `${cidade}${uf} ${pais} ${codigoPostal}`;

      if (hasSearch) {
        ret.search = `${pais} ${uf} ${cidade} ${endereco}`
      }

      return ret;
    },

    // produtoFormat
    produtoFormat(objeto, omitirId = false, omitirDescricaoComplementar = false, omitirDescricao = false, omitirPartNumber = false, omitirMarca = false) {
      omitirDescricaoComplementar = omitirDescricaoComplementar || omitirDescricao

      const produto = objeto.produto
      const marca = !omitirMarca && objeto.marca ? ` ${objeto.marca.toUpperCase()} ` : ''
      const serie = objeto.serie ? ` ${objeto.serie} ` : ''
      const descricaoTemp = this.getValorTraduzido(objeto, "descricao")
      const descricao = !omitirDescricao && descricaoTemp ? ` ${descricaoTemp} ` : ''
      const descricaoComplementarTemp = this.getValorTraduzido(objeto, "descricaoComplementar")
      const descricaoComplementar = !omitirDescricaoComplementar && descricaoComplementarTemp ? `; ${descricaoComplementarTemp} ` : ''
      const modelo = objeto.modelo ? objeto.modelo : ''
      const partNumber = !omitirPartNumber && objeto.partNumber ? objeto.partNumber != modelo ? `~${objeto.partNumber} ` : '~' : ''
      const id = !omitirId ? ` #${objeto.idProduto || objeto.id} ` : ''
      const espaco = modelo && partNumber ? ' ' : ''
      const parentesesAberto = modelo || partNumber ? ' (' : ''
      const parentesesFechado = modelo || partNumber ? ')' : ''

      return `${produto}${marca}${serie}${descricao}${descricaoComplementar}${parentesesAberto}${modelo}${espaco}${partNumber}${parentesesFechado}${id} `
    },

    // startTouchPressed
    startTouchPressed() {
      this.clickPressed = true;

      setTimeout(() => {
        if (this.clickPressed) {
          this.clickAbort = true;
          if (this.help && (this.help?.hasHelp ?? true)) {
            this.help.function(this.help);
          }
        }
      }, this.getConst("config").bottomSheet.timeout);
    },

    // toScroll
    toScroll() {
      const scrollTo =
        this.iconScroll == "mdi-arrow-up"
          ? 0
          : document.documentElement.offsetHeight;

      this.showFab = false;

      this.$vuetify.goTo(scrollTo);
    },

    // translate
    translate(texto) {
      let textoTraduzido;

      if (!this.$route.name.startsWith("Dicionario") && texto && !this.getDicionario(texto)) {
        // textoTraduzido = this.$vuetify.lang.t(`$vuetify.${ texto } `)
        textoTraduzido = this.$vuetify.lang.locales[this.$vuetify.lang.current][texto];

        if (!textoTraduzido) {
          if (this.userLocale) {
            textoTraduzido = this.$vuetify.lang.locales[this.userLocale][texto];
          }
        }
      }

      textoTraduzido = textoTraduzido || texto;

      return textoTraduzido;
    },
  }
}