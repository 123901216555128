<!-- COD: BJB 01/07/2022 Pessoas -->
<!-- *VER: JER 04/07/2022 Pessoas -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ context, dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-pessoas :context="context" :dataset="dataset" />
    </c-base-list-item>

    <!-- dialog incluir pessoa -->
    <c-base-dialog-pessoa-incluir
      v-if="showPessoaIncluir"
      :idPagina="idPageAlt"
      :params="params"
      @close="closeDialogPessoa($event)"
    />
  </c-base-list>
</template>

<script>
/// IMPORT
import CBaseDialogPessoaIncluir from "@/components/bases/CBaseDialogPessoaIncluir";
import CPessoas from "@/components/pages/pessoa/CPessoas";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Pessoas",

  /// COMPONENTS
  components: {
    CBaseDialogPessoaIncluir,
    CPessoas,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("pessoas").id,
      idPageAlt: getPagina("pessoa").id,

      context: null,
      funcs: {
        clickIncluir: (context) => {
          this.context = context;
          this.showPessoaIncluir = true;
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },
      params: {},
      showPessoaIncluir: false,
    };
  },

  /// METHODS
  methods: {
    closeDialogPessoa(event) {
      this.showPessoaIncluir = false;

      if (event?.id) {
        this.$router.push(`/${this.context.pageAlt.nome}/${event.id}`);
      }
    },
  },
};
</script>