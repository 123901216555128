<!-- COD: FDS 04/07/2022 CFeedbackView -->
<!-- *VER: JER 05/07/2022 CFeedbackView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pagina -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPagina">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getPagina(dataset.main.detail.idPagina).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- titulo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.titulo">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.titulo }}
        </span>
      </v-col>
    </v-row>

    <!-- usuario -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.usuario_nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.usuario_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.data">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.data,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFeedbackView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getPagina: "paginas/getPagina",
    }),
  },
};
</script>