<!-- COD: LYF 05/07/2022 Colaborador -->
<!-- *VER: JER 05/07/2022 Colaborador -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-colaborador-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-colaborador-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CColaboradorEdit from "@/components/pages/colaborador/CColaboradorEdit";
import CColaboradorView from "@/components/pages/colaborador/CColaboradorView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Colaborador",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CColaboradorEdit,
    CColaboradorView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("colaborador").id,
      idPageAlt: getPagina("colaboradores").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("colaboradorColaborador").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idPessoaColaborador: null,
            nomeAlt: null,
            grupo: null,
            dataAdmissao: null,
            dataDemissao: null,
            hasPontoEletronico: null,
            observacoes: null,
            saldoFerias: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          situacoesTrabalhos: [],
          cargosSalarios: [],
          horarios: [],
          pessoasRelacionadas: [],
          horasExtrasAutorizacoes: [],
          beneficios: [],
          ausencias: [],
          atendimentos_dias: [],
        },
        aux: {
          detail: {
            pessoa_nome: null,
          },
          autocomplete: {
            grupos: [],
          },
        },
      },

      funcs: {
        clickApontamentos: () => {
          this.$router.push(
            `/colaborador/apontamentos/${this.dataset.main.detail.id}`
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },
      },

      params: {
        context: this,
        showHistoricoCargos: false,
        showHistoricoSituacaoTrabalho: false,
        showHistoricoHorasExtras: false,
        verbas: null,
        planos: null,
        prestadores: null,
        niveis: null,
        horariosTrabalho: null,
        flexibilidade: null,
        cargos: null,
        areas: null,
        situacoesTrabalho: null,
        diasSemanaAtendimento: [
          { value: 0, text: "D" },
          { value: 1, text: "S" },
          { value: 2, text: "T" },
          { value: 3, text: "Q" },
          { value: 4, text: "Q" },
          { value: 5, text: "S" },
          { value: 6, text: "S" },
        ],
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>