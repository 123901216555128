<!-- COD: BJB 28/07/2022 CBaseDialogHorasExtras -->
<!-- *VER: JER 10/08/2022 CBaseDialogHorasExtras -->
<!-- /21/ -->

<template>
  <v-dialog v-model="show" max-width="380px" persistent scrollable>
    <!-- /// HORAS EXTRAS -->
    <v-card class="mx-auto" max-width="380">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row justify="end">
            <v-toolbar-title>HORAS EXTRAS</v-toolbar-title>
          </v-row>
          <v-spacer />
          <v-btn icon @click="clickSave">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-8">
        <v-form class="px-0" ref="form">
          <!-- idColaboradores -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idColaboradores"
                clearable
                :items="colaboradores"
                label="colaboradores"
                multiple
                required
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <!-- dataInicio -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="searchData.inicio"
                classe="text-body-1 pr-1"
                clearable
                date
                icon
                label="início"
                :maxValue="searchData.fim"
                :minValue="new Date().toISOString().substring(0, 10)"
                required
                @input="$emit('searchData', searchData)"
              />
            </v-col>
            <!-- dataFim -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="searchData.fim"
                classe="text-body-1 pl-1"
                clearable
                date
                icon
                label="fim"
                :minValue="
                  searchData.inicio || new Date().toISOString().substring(0, 10)
                "
                required
                @input="$emit('searchData', searchData)"
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <!-- horaInicio -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="horaInicio"
                classe="text-body-1 pr-1"
                clearable
                :help="{
                  title: 'HORA EXTRA',
                  text: 'Hora inicio e hora fim é o intervalo em que a hora extra poderá ser feita.',
                  function: setUiBottomSheet,
                }"
                icon
                label="hora início"
                :minValue="format(new Date(), 'HH:mm')"
                required
                time
                @change="horaFim = null"
              />
            </v-col>
            <!-- horaFim -->
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="horaFim"
                classe="text-body-1 pl-1"
                clearable
                :disabled="!horaInicio"
                :help="{
                  title: 'HORA EXTRA',
                  text: 'Hora inicio e hora fim é o intervalo em que a hora extra poderá ser feita.',
                  function: setUiBottomSheet,
                }"
                icon
                label="hora fim"
                :minValue="horaInicio"
                required
                time
              />
            </v-col>
          </v-row>

          <!-- horasAutorizadas -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="horasAutorizadas"
                classe="text-body-1 pr-1"
                clearable
                :help="{
                  title: 'HORA EXTRA',
                  text: 'Horas autorizadas é o número de horas extras que estão autorizadas.',
                  function: setUiBottomSheet,
                }"
                icon
                label="horas autorizadas"
                :maxValue="'12:00'"
                required
                :stepMinutes="15"
                time
              />
            </v-col>
          </v-row>

          <!-- /// BOTTOMSHEET -->
          <c-bottom-sheet :bottomSheet="bottomSheet" />
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CBaseDialogHorasExtras",

  /// MIXINS
  mixins: [mixinBottomSheet, mixinData, mixinSnackbar],

  /// PROPS
  props: {
    colaboradores: { type: Array, required: true },
    context: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {
      horasAutorizadas: null,
      horaFim: null,
      horaInicio: null,
      idColaboradores: null,
      searchData: {
        inicio: null,
        fim: null,
      },
      url: null,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),
  },

  /// METHODS
  methods: {
    async clickSave() {
      if (this.$refs.form.validate()) {
        const method = "put";
        let url = `${this.context.getConst("app").baseUrlBackend}/${
          this.context.page.nome
        }/all`;

        const datasetsSave = {
          details: {},
        };

        datasetsSave.details = this.idColaboradores.map((idColaborador) => {
          return {
            id: null,
            dataInicio: this.searchData.inicio,
            dataFim: this.searchData.fim,
            horaInicio: this.horaInicio,
            horaFim: this.horaFim,
            horasExtras: this.horasAutorizadas,
            data: new Date(),
            idPessoaAprovador: this.context.getVar("idUsuario"),
            observacao: null,
            idPai: idColaborador,
            del: false,
          };
        });

        const res = await this.context.submit(method, url, datasetsSave);

        if (res) {
          this.$emit("incluido", true);
        }
      } else {
        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );
      }
    },
  },
};
</script>