<!-- COD: FDS 05/08/2022 Anexo -->
<!-- *VER: JER 10/07/2022 Anexo -->
<!-- /32/ -->

<template>
  <div>
    <!-- ??? Onde posicionar o snacbar? -->
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" :title="page.nomeAlt" />

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// DETAIL -->
    <v-form class="mb-10 pa-0" ref="form">
      <!-- /// PAGE-MODE-INSERT -->
      <v-container v-if="anexo.anexo.idTipo == 0" class="pa-0 my-6">
        <!-- tipos -->
        <v-row v-bind="prop.row" justify="center">
          <v-col v-bind="prop.col" align="center" cols="auto">
            <v-slide-group
              v-model="anexo.anexo.idTipo"
              center-active
              class="pb-3"
            >
              <v-slide-item
                v-for="(tipoAnexo, i) in getTipo_itens(getTipo('tipoAnexo').id)"
                #default="{ active, toggle }"
                :key="i"
                :value="tipoAnexo.value"
              >
                <c-btn
                  active-class="primary white--text"
                  class="mx-2 primary--text"
                  color="primary"
                  depressed
                  :input-value="active"
                  outlined
                  rounded
                  @click="toggle"
                >
                  {{ tipoAnexo.text }}
                </c-btn>
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>

        <!-- explicacao dos tipos -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <ul class="mb-6">
              <li
                v-for="(tipo, i) in getTipo_itens(getTipo('tipoAnexo').id)"
                class="text-justify pa-2"
                :key="i"
              >
                <span class="text-h6">
                  {{ tipo.text }}
                  :
                </span>
                <span class="text-boby-1 ml-2">
                  {{ getTipo_item(tipo.value).options.ajuda }}.
                </span>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>

      <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
      <div v-else>
        <v-container class="pa-0">
          <!-- // IMAGES -->

          <!-- file uploaded -->
          <v-row
            v-bind="prop.row"
            v-if="!!fileUploaded && anexo.anexo.tipo.includes('image')"
          >
            <v-col v-bind="prop.col" align="center">
              <v-img
                :max-width="getConst('config').image.params.dimensao.min"
                :src="fileUploaded"
              />
            </v-col>
          </v-row>

          <!-- arquivo -->
          <v-row
            v-bind="prop.row"
            v-else-if="
              anexo.anexo.idTipo == getTipo_item('anexoArquivo').id &&
              anexo.anexo.tipo &&
              anexo.anexo.tipo.includes('image') &&
              anexo.anexo.endereco
            "
          >
            <v-col v-bind="prop.col" align="center">
              <v-img alt="imagem" max-width="200" :src="anexo.anexo.endereco" />
            </v-col>
          </v-row>

          <!-- icone -->
          <v-row v-bind="prop.row" v-else-if="anexo.anexo.tipo">
            <v-col v-bind="prop.col">
              <v-icon x-large>
                {{
                  anexo.anexo.idTipo == getTipo_item("anexoArquivo").id
                    ? "mdi-file-pdf"
                    : "mdi-link-variant"
                }}
              </v-icon>
            </v-col>
          </v-row>

          <!-- // INFO-COMUNS INÍCIO -->
          <v-row v-bind="prop.row">
            <!-- icone clip -->
            <v-col v-bind="prop.col" cols="1">
              <v-icon x-large> mdi-paperclip </v-icon>
            </v-col>
            <!-- tipo -->
            <v-col v-bind="prop.col" align="center" cols="10">
              <v-chip class="grey darken-3 white--text text-body-1" x-small>
                {{ getTipo_item(anexo.anexo.idTipo).nomeAlt }}
              </v-chip>
            </v-col>
            <!-- ordem -->
            <v-col v-bind="prop.col" align="end" cols="1">
              <span class="text-overline">
                {{ anexo.anexo.order }}
              </span>
            </v-col>
          </v-row>
        </v-container>

        <!-- /// MODEVIEW -->
        <v-container v-if="modeView" class="pa-0">
          <!-- endereco da imagem -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="font-weight-medium text-body-1">
                <a :href="anexo.anexo.endereco" target="_blank">
                  {{ anexo.anexo.nome }}
                </a>
              </span>
            </v-col>
          </v-row>

          <!-- endereco da imagem -->
          <v-row
            v-bind="prop.row"
            v-if="anexo.anexo.idTipo == getTipo_item('anexoLink').id"
          >
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2">
                <a :href="anexo.anexo.endereco" target="_blank">
                  {{ anexo.anexo.endereco }}
                </a>
              </span>
            </v-col>
          </v-row>
        </v-container>

        <!-- /// MODEEDIT -->
        <v-container v-else class="pa-0">
          <!-- arquivo -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-file-input
                v-model="file"
                v-if="anexo.anexo.idTipo == getTipo_item('anexoArquivo').id"
                :accept="acceptComponent"
                :rules="rules.file"
              />
            </v-col>
          </v-row>

          <!-- nome arquivo -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-text-field
                v-model.trim="anexo.anexo.nome"
                clearable
                label="nome"
                :max="50"
                :min="5"
              />
            </v-col>
          </v-row>

          <!-- nome arquivo -->
          <v-row
            v-bind="prop.row"
            v-if="anexo.anexo.idTipo == getTipo_item('anexoLink').id"
          >
            <v-col v-bind="prop.col">
              <v-textarea
                v-model.trim="anexo.anexo.endereco"
                auto-grow
                class="text-body-1"
                label="endereço"
                maxlength="200"
                placeholder="endereço"
                type="text"
                :rules="rules.inputUrl"
              />
            </v-col>
          </v-row>
        </v-container>

        <!-- // INFO-COMUNS FIM -->
        <v-container class="pa-0">
          <v-row
            v-bind="prop.row"
            v-if="anexo.anexo.idTipo == getTipo_item('anexoArquivo').id"
          >
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-1">
                {{ anexo.anexo.tipo }}{{ tamanho }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-form>

    <c-the-navbar bottom mode="page" :slots="buttonsBar.length" title="">
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-btn
          v-if="!Array.isArray(button)"
          icon
          :key="i"
          @click="button.clickFunction(button)"
        >
          <v-icon :color="button.options.color || 'white'" large>
            {{ button.options.icon }}
          </v-icon>
        </c-btn>
        <v-menu v-else :key="i" offset-y top>
          <template #activator="{ on }">
            <c-btn v-on="on" icon>
              <v-icon large>mdi-dots-horizontal</v-icon>
            </c-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, i) in button"
              :key="i"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </c-the-navbar>
  </div>
</template>

<script>
/// IMPORT
import { compress } from "image-conversion";
import { mapActions, mapGetters } from "vuex";
import { VMoney } from "v-money";
import axios from "axios";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinLib from "@/mixins/mixinLib";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "Anexo",

  /// COMPONENTS
  components: { CTheNavbar },

  /// DIRECTIVES
  directives: { money: VMoney },

  /// MIXINS
  mixins: [mixinLib, mixinSnackbar, mixinUsuarios],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("anexo").id,
      idPageAlt: getPagina("anexos").id,

      accept: [
        "jpg",
        "jpeg",
        "png",
        "pdf",
        "bai2",
        "ofc",
        "json",
        "doc",
        "docx",
        "webp",
      ],
      anexo: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        anexo: {
          id: null,
          idTipo: 0,
          idTabela: null,
          idRegistro: null,
          order: null,
          nome: null,
          tipo: "",
          tamanho: 0,
          endereco: null,
        },
      },

      anexos: null,
      anexoTemp: null,
      file: null,
      fileUploaded: null,
      links: [
        {
          icon: "mdi-delete-outline",
          text: "deletar",
          action: "deletar",
          color: "red--text",
          estados: [],
          perfis: [],
        },
      ],
      modeView: true,
      rules: {
        file: [
          (value) =>
            (!!value &&
              (!value.name || !!value.name.match(this.acceptRegex))) ||
            (!value && !!this.anexo.anexo.id) ||
            "tipo inválido",
        ],
        inputUrl: [
          (value) =>
            !value ||
            value.length == 0 ||
            value.length >= 5 ||
            "vazio ou mínimo 5 caracteres",
          (value) =>
            !value ||
            !!value.match(
              // /^(https?:\/\/)?([a-z0-9\-_]{2,26}\.)?([a-z0-9][a-z0-9-]{0,24}[a-z0-9])(\.[a-z]{2,6})(\.[a-z]{2})?$/g
              // /^(https?:\/\/)?([a-z0-9\-_]{2,26}\.)?([a-z0-9][a-z0-9-]{0,24}[a-z0-9])(\.[a-z]{2,6})/g
              /^(https?:\/\/)?([a-z0-9\-_]{1,26}\.)?([a-z0-9][a-z0-9-]{0,24}[a-z0-9])(\.[a-z]{2,6})/g
            ) ||
            "endereço inválido",
        ],
        select: [
          (value) => !!value || !!this.anexo.anexo.id || "campo requerido",
        ],
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getPagina_configuracoes: "paginas/getPagina_configuracoes",
      getPagina_documentos: "paginas/getPagina_documentos",
      getPagina: "paginas/getPagina",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
      getVar: "variaveis/getVar",
    }),

    acceptComponent() {
      return this.accept.map((el) => `.${el}`).join(", ");
    },

    acceptRegex() {
      return new RegExp(`.+\\.(${this.accept.join("|")})$`, "gi");
    },

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true,
        null
      ).sortKeys('"order"');
    },

    buttonsBar() {
      let ret;

      if (this.modeView) {
        ret = [
          ...this.buttons.map((button) => {
            return {
              ...button,
              clickFunction: this.clickOption,
            };
          }),
          this.options,
        ];
      } else {
        ret = [];

        if (this.anexo.anexo.id) {
          ret.push({
            clickFunction: this.clickEditView,
            options: {
              icon: "mdi-close",
              color: "red accent-3",
            },
          });
        }

        ret.push({
          clickFunction: this.submit,
          options: {
            icon: "mdi-check",
            color: "green accent-3",
          },
        });
      }

      return ret;
    },

    linksFiltered() {
      return this.links
        .filter(
          (link) =>
            link.estados.length == 0 ||
            link.estados.includes(this.anexo.anexo.idEstado)
        )
        .filter(
          (link) =>
            link.perfis.length == 0 ||
            link.perfis.some((perfil) =>
              this.perfis.map((perfil) => perfil.idPerfil).includes(perfil)
            )
        );
    },

    moneyTamanho() {
      return {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " KB",
        precision: 0,
        masked: true,
      };
    },

    options() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        false,
        null
      ).sortKeys('"order"');
    },

    ordens() {
      const ordens = [];
      for (let i = 0; i < this.anexos; i++) {
        ordens.push(i + 1);
      }
      return ordens;
    },

    page() {
      return this.getPagina(this.idPage);
    },

    pageAlt() {
      return this.getPagina(this.idPageAlt);
    },

    tamanho() {
      return this.anexo.anexo.tamanho
        ? ` (${this.numberFormat(this.anexo.anexo.tamanho, "decimal", 0)} KB)`
        : "";
    },
  },

  /// WATCH
  watch: {
    file(valor, valorAnterior) {
      if (!valor || !valorAnterior || valor.name != valorAnterior.name) {
        this.arquivoObter();
      }
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    async anexoSelected() {
      this.setVar({ overlay: true });
      const url = `${this.getConst("app").baseUrlBackend}/anexos/${
        this.anexo.anexo.id
      }`;

      await axios
        .get(url)
        .then((res) => {
          this.anexo = res.data;
          this.file = null;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });
    },

    anexoTempControle() {
      // deep clone object: JSON.parse e JSON.stringify não são os métodos mais eficientes, mas funcionam
      if (this.modeView)
        this.anexo = JSON.parse(JSON.stringify(this.anexoTemp));
      else this.anexoTemp = JSON.parse(JSON.stringify(this.anexo));
    },

    async arquivoObter() {
      if (!this.file) return;
      if (this.getVar("overlay")) return;

      this.setVar({ overlay: true });

      if (!this.anexo.anexo.id) {
        const extensao =
          this.file.name.match(/\.[0-9a-z]+$/i)?.find((_, i) => i == 0) || "";

        this.anexo.anexo.nome = `${this.anexo.anexo.idRegistro}${extensao}`;
      }

      this.anexo.anexo.tipo = this.file.type;
      this.anexo.anexo.tamanho = Math.round(this.file.size / 1024);

      if (!this.anexo.anexo.tipo.includes("image")) {
        this.setVar({ overlay: false });
        return;
      }

      let mensagem = "";

      if (
        this.anexo.anexo.tamanho <
        this.getConst("config").image.params.tamanho.min
      ) {
        mensagem += `Imagem menor que ${
          this.getConst("config").image.params.tamanho.min
        } KB. `;
      } else if (
        this.anexo.anexo.tamanho >
        this.getConst("config").image.params.tamanho.max
      ) {
        mensagem += `Imagem maior que ${
          this.getConst("config").image.params.tamanho.max / 1000
        } MB. `;
      }

      const img = await this.getImage();
      const config = this.getConst("config").image.config;

      config.width =
        this.getConst("config").image.params.dimensao.min *
        (img.width >= img.height ? 1 : img.width / img.height);
      config.height =
        this.getConst("config").image.params.dimensao.min *
        (img.height >= img.width ? 1 : img.height / img.width);

      if (img.width < this.getConst("config").image.params.dimensao.min) {
        mensagem += `Largura menor que ${
          this.getConst("config").image.params.dimensao.min
        } px. `;
      }

      if (img.height < this.getConst("config").image.params.dimensao.min) {
        mensagem += `Altura menor que ${
          this.getConst("config").image.params.dimensao.min
        } px. `;
      }

      const aspectRatio = img.width / img.height;

      if (
        aspectRatio <
        eval(
          this.getConst("config").image.params.aspectRatio.min.replace(":", "/")
        )
      ) {
        mensagem += `Aspect ratio menor que ${
          this.getConst("config").image.params.aspectRatio.min
        }. `;
      } else if (
        aspectRatio >
        eval(
          this.getConst("config").image.params.aspectRatio.max.replace(":", "/")
        )
      ) {
        mensagem += mensagem += `Aspect ratio maior que ${
          this.getConst("config").image.params.aspectRatio.max
        }.`;
      }

      if (!mensagem) {
        if (this.anexo.anexo.tamanho > 50) {
          const nameFile = this.file.name;
          this.file = await compress(this.file, config);

          if (!this.file.name) {
            this.file.name = nameFile;
          }
        }
        this.fileUploaded = await this.getBase64();
      } else {
        this.file = null;
        this.anexo.anexo.nome = null;
        this.anexo.anexo.tipo = null;
        this.anexo.anexo.tamanho = null;
        this.setSnackbar(true, "ERRO NA IMAGEM", mensagem, "error");
      }

      this.setVar({ overlay: false });
    },

    clickEditView() {
      this.modeView = !this.modeView;
      this.anexoTempControle();
    },

    clickOption(paginaFeature) {
      const action =
        paginaFeature.options?.click || paginaFeature.options?.route;

      if (action == "clickAlterar") {
        this.clickEditView();
      } else if (action == "clickDeletar") {
        this.deleteConfirm();
      } else if (action == "clickRecuperar") {
        this.delete(
          this.anexo.anexo.id,
          this.getTipo_item("eventoRecuperado").id
        );
      } else if (action == "clickLogs") {
        const url = `/logs/0/${this.getTabela(this.pageAlt.nome).id}/${
          this.anexo.anexo.id
        }/${this.getTabela(this.page.idTabela).description || this.page.nome}`;
        this.$router.push(encodeURI(url));
      } else if (action == "clickAjuda") {
        const paginaDocumento = this.getPagina_documentos(
          this.getPagina("ajuda")?.id
        ).find(
          (paginaDocumento) =>
            paginaDocumento.options.feature &&
            this.getFeature(paginaDocumento.options.feature)?.id ==
              this.getFeature("optionAnexos")?.id
        );

        const url = paginaDocumento ? `/ajuda#${paginaDocumento.id}` : "/ajuda";
        this.$router.push(encodeURI(url));
      }
    },

    clickSnackbar(action) {
      if (action == "delete") {
        this.delete(this.anexo.anexo.id);
      }

      this.setSnackbar();
    },

    delete(id, idEvento = this.getTipo_item("eventoDeletado").id) {
      this.setVar({ overlay: true });
      this.setSnackbar();
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const url = `${
        this.getConst("app").baseUrlBackend
      }/anexos/${id}/${idEvento}/${idPessoaParceiro}/${idUsuario}`;

      axios
        .delete(url)
        .then((res) => {
          this.anexo.anexo.idEstado = parseInt(res.data);
          this.$refs.form.resetValidation();
          this.setVar({ overlay: false });
          this.$router.go(-1);
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });
    },

    deleteConfirm() {
      this.setSnackbar(
        true,
        "DELETAR",
        "Deletar anexo?",
        "error",
        "mdi-check",
        "delete"
      );
    },

    getBase64() {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onloadend = () => {
          const base64data = reader.result;

          resolve(base64data);
        };
      });
    },

    async getImage() {
      const img = new Image();
      img.src = await this.getBase64();

      return new Promise((resolve) => {
        img.onload = () => {
          resolve(img);
        };
      });
    },

    submit() {
      this.setVar({ overlay: true });

      if (this.$refs.form.validate()) {
        this.anexo.metadados.idUsuario = this.getVar("idUsuario");
        this.anexo.metadados.idPessoaParceiro = this.getVar("idPessoaParceiro");

        let formData = new FormData();
        formData.append("anexo", JSON.stringify(this.anexo));

        if (this.file) formData.append("file", this.file);

        if (this.anexo.anexo.id) {
          const url = `${this.getConst("app").baseUrlBackend}/anexos`;
          this.anexo.anexo.endereco = null;

          axios
            .put(url, formData)
            .then((res) => {
              this.$refs.form.resetValidation();
              this.anexo = res.data;
              this.file = null;

              if (
                this.anexo.anexo.idTabela == this.getTabela("pessoas").id &&
                this.anexo.anexo.idRegistro == this.getVar("idUsuario")
              )
                this.setVar({ "atualizacao.usuarios": null });

              this.modeView = true;
              window.scrollTo(0, 0);

              if (this.anexos < 0) {
                this.$router.go(-1);
              }
            })
            .catch((err) => {
              this.setSnackbar(true, "ERRO", err.response.data, "error");
            })
            .finally(() => {
              this.setVar({ overlay: false });
            });
        } else {
          const url = `${this.getConst("app").baseUrlBackend}/anexos`;

          axios
            .post(url, formData)
            .then((res) => {
              this.$refs.form.resetValidation();
              this.anexo = res.data;
              this.file = null;

              if (
                this.anexo.anexo.idTabela == this.getTabela("pessoas").id &&
                this.anexo.anexo.idRegistro == this.getVar("idUsuario")
              )
                this.setVar({ "atualizacao.usuarios": null });

              this.modeView = true;
              window.scrollTo(0, 0);

              if (this.anexos < 0) {
                this.$router.go(-1);
              } else {
                this.anexoSelected();
              }
            })
            .catch((err) => {
              this.setSnackbar(true, "ERRO", err.response.data, "error");
            })
            .finally(() => {
              this.setVar({ overlay: false });
            });
        }
      } else {
        this.setVar({ overlay: false });

        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );
      }
    },
  },

  /// MOUNTED
  async mounted() {
    if (this.getTabela("extratos").id == this.$route.params.idTabela) {
      const url = `${this.getConst("app").baseUrlBackend}/extratos/${
        this.$route.params.idRegistro
      }/{}`;

      const resExtrato = await axios.get(url);

      if (resExtrato.data) {
        const paginaConfiguracao = this.getPagina_configuracoes(
          this.getPagina("bancos").id
        ).find(
          (paginaConfiguracao) =>
            paginaConfiguracao.idBanco == resExtrato.data.aux.detail.idBanco &&
            paginaConfiguracao.idFeature ==
              this.getFeature("adapterExtratoBanco").id
        );

        if (paginaConfiguracao) {
          this.accept = [
            this.getTipo_item(paginaConfiguracao.idTipoArquivo).nomeAlt,
          ];
        }
      }
    }

    this.anexos = parseInt(this.$route.params.anexos);

    if (eval(this.$route.params.id)) {
      this.anexo.anexo.id = parseInt(this.$route.params.id);

      await this.anexoSelected();

      if (this.anexos < 0) {
        this.modeView = false;
        this.anexoTempControle();
      }
    } else {
      this.anexo.anexo.idTabela = this.$route.params.idTabela;
      this.anexo.anexo.idRegistro = this.$route.params.idRegistro;

      if (this.anexos < 0) {
        this.anexo.anexo.order = 1;
        this.anexo.anexo.idTipo = this.getTipo_item("anexoArquivo").id;
      } else {
        this.anexos = this.anexos + 1;
        this.anexo.anexo.order = this.ordens.length;
      }

      this.modeView = false;
    }
  },
};
</script>