<!-- COD: BJB 29/07/2022 FolhaPagamento_Ocorrencias -->
<!-- *VER: JER 08/08/2022 FolhaPagamento_Ocorrencias -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPageDetail"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context, pageModeView }">
        <c-folhaPagamento-view
          v-if="pageModeView"
          :context="context"
          :dataset="dataset"
        />
      </template>
    </c-base-detail>

    <c-base-list
      v-if="loadList"
      :hasSearch="true"
      :funcs="funcsList"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :noList="true"
      :pageModeEdit="true"
      @showSearchOptions="showSearchOptions = $event"
    >
      <template #searchInSearch="{ context }">
        <v-container>
          <v-row v-bind="prop.row" class="mt-n6">
            <!-- so acerto -->
            <v-col v-bind="prop.col" cols="2">
              <c-switch
                v-model="soAcerto"
                class="ml-n2"
                :help="{
                  title: 'ACERTO',
                  text: 'Verba de acerto ou todas',
                  function: setUiBottomSheet,
                }"
                @click="idVerbas = []"
              />
            </v-col>
            <!-- verbas -->
            <v-col v-bind="prop.col" cols="10">
              <c-select
                v-model="idVerbas"
                class="ml-n1"
                clearable
                :items="funcsList.verbas(context, soAcerto)"
                label="verbas"
                multiple
                :properties="{
                  chip: true,
                }"
              />
            </v-col>
          </v-row>

          <!-- colaboradores -->
          <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n2">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idColaboradores"
                clearable
                :items="funcsList.colaboradores(context)"
                label="colaboradores"
                multiple
                :properties="{
                  chip: true,
                }"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #listContent="{ context, datasets }">
        <c-expansion-panels
          flat
          :pageModeView="true"
          :params="params"
          :value="datasets"
        >
          <template #header="{ dataset: ocorrencia }">
            <v-container class="py-0">
              <v-row v-bind="prop.row">
                <!-- nome -->
                <v-col v-bind="prop.col" cols="6">
                  <span class="text-body-1">
                    {{ ocorrencia.nome }}
                  </span>
                </v-col>
                <!-- valor -->
                <v-col v-bind="prop.col" align="end" cols="6">
                  <span class="text-body-1">
                    {{ numberFormat(ocorrencia.valor, "currency") }}
                  </span>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <!-- verba -->
                <v-col v-bind="prop.col" v-if="ocorrencia.idVerba" cols="6">
                  <span class="text-body-1">
                    {{
                      `${ocorrencia.idVerba} ${
                        getVerba(ocorrencia.idVerba).nomeAlt
                      }`
                    }}
                  </span>
                </v-col>
                <!-- acerto -->
                <v-col
                  v-bind="prop.col"
                  v-if="ocorrencia.isAcerto"
                  align="end"
                  cols="6"
                >
                  <v-chip v-bind="prop.chip">acerto</v-chip>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template #content="{ dataset: ocorrencia, index }">
            <c-folhaPagamento-page-ocorrencia-view
              v-if="pageModeView"
              :context="context"
              :ocorrencia="ocorrencia"
            />
            <c-folhaPagamento-page-ocorrencia-edit
              v-else
              :context="context"
              :index="index"
              :ocorrencia="ocorrencia"
            />
          </template>
        </c-expansion-panels>
      </template>

      <template #insert="{ context }">
        <!-- icone + -->
        <v-row v-bind="prop.row" v-if="!pageModeView">
          <v-col v-bind="prop.col">
            <c-btn icon @click="incluir(context)">
              <v-icon color="green">mdi-plus-circle</v-icon>
            </c-btn>
          </v-col>
        </v-row>
      </template>
    </c-base-list>

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CFolhaPagamentoPageOcorrenciaEdit from "@/components/pages/folhaPagamento/pages/CFolhaPagamentoPageOcorrenciaEdit";
import CFolhaPagamentoPageOcorrenciaView from "@/components/pages/folhaPagamento/pages/CFolhaPagamentoPageOcorrenciaView";
import CFolhaPagamentoView from "@/components/pages/folhaPagamento/CFolhaPagamentoView";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "FolhaPagamento_Ocorrencias",

  /// COMPONENTS
  components: {
    CFolhaPagamentoPageOcorrenciaEdit,
    CFolhaPagamentoPageOcorrenciaView,
    CFolhaPagamentoView,
  },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idFeature: getFeature("optionOcorrenciasFolha").id,
      idPage: getPagina("folhaPagamentoOcorrencias").id,
      idPageAlt: getPagina("folhasPagamentos").id,
      idPageDetail: getPagina("folhaPagamento").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("fpNormal").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            dataInicio: null,
            dataFim: null,
          },
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          this.contextDetail = context;
          context.params.context = context;

          this.dataset = context.dataset;
          this.id = context.dataset.main.detail.id;

          if (this.contextList && this.loadList) {
            this.contextList.clickSearch();
          }

          this.loadList = true;
        },
      },

      funcsList: {
        changed: () => {
          return !this.pageModeView;
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );

          this.funcsList.clickEditView();
        },

        clickEditView: () => {
          this.contextDetail.clickEditView();
          this.pageModeView = !this.pageModeView;
        },

        clickSave: async (context) => {
          this.funcsList.clickEditView();

          const metadados = {
            idEvento: this.getTipo_item("eventoAlterado").id,
            idPessoaParceiro: context.options.click.getVar("idPessoaParceiro"),
            idUsuario: context.options.click.getVar("idUsuario"),
            modo: context.options.click.getConst("config").modo.cadastro.id,
          };

          const datasetsSave = {
            metadados,
            main: {
              detail: this.dataset.main.detail,
              ocorrencias: context.options.click.datasets
                .filter((dataset, i) => {
                  const datasetTemp = context.options.click.datasetsTemp[i];
                  delete datasetTemp.autocompletePessoaFavorecida;
                  datasetTemp.dataInicio = this.dateFormat(
                    datasetTemp.dataInicio,
                    "yyyy-MM-dd"
                  );
                  datasetTemp.dataFim = this.dateFormat(
                    datasetTemp.dataFim,
                    "yyyy-MM-dd"
                  );

                  return (
                    !dataset.id ||
                    Object.keys(dataset).some((key) => {
                      return datasetTemp[key] !== undefined
                        ? dataset[key] != datasetTemp[key]
                        : false;
                    })
                  );
                })
                .map((dataset) => {
                  return {
                    id: dataset.id,
                    idVerba: dataset.idVerba,
                    idPessoaColaborador: dataset.idPessoaColaborador,
                    idPessoaFavorecida: dataset.idPessoaFavorecida,
                    dataInicio: dataset.dataInicio,
                    dataFim: dataset.dataFim,
                    data: dataset.data,
                    referencia: dataset.referencia,
                    valor: dataset.valor,
                    isAcerto: dataset.isAcerto,
                    del: dataset.del,
                  };
                }),
            },
          };

          datasetsSave.main.ocorrencias.forEach((ocorrencia) => {
            if (ocorrencia.dataFim == "") {
              ocorrencia.dataFim = null;
            }
          });

          const method = "put";
          let url = `${context.options.click.getConst("app").baseUrlBackend}/${
            context.options.click.pageAlt.nome
          }`;
          const res = await context.options.click.submit(
            method,
            url,
            datasetsSave
          );

          if (res) {
            context.options.click.clickSearch();
          }
        },

        clickVerbas: () => {
          this.$router.push(
            `/folhaPagamento/folhaPagamento_Verbas/${this.dataset.main.detail.id}`
          );
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        colaboradores: (context) => {
          if (context.datasetList?.dataset?.aux?.colaboradores) {
            return context.datasetList.dataset.aux.colaboradores.map(
              (colaborador) => {
                return {
                  value: colaborador.id,
                  text: colaborador.nome,
                };
              }
            );
          } else {
            return [];
          }
        },

        fnParams: () => {
          let params = null;

          params = {
            idFolhaPagamento: this.id,
            idTipo: this.dataset.main.detail.idTipo,
            idVerbas: this.idVerbas,
            idColaboradores: this.idColaboradores,
          };

          return JSON.stringify(params);
        },

        getDetail: () => {
          return this.dataset;
        },

        posSubmit: async (dataset, context, datasets, index) => {
          dataset.valorItem = dataset.valor;

          if (index == 0) {
            this.contextList = context;
            context.page.nomeAjuda = context.pageAlt.nome;
          }

          dataset.del = false;
        },

        verbas: (context, acerto) => {
          if (context.datasetList?.dataset?.aux?.verbas) {
            const idTiposOcorrencia = this.getTipo_itens(
              this.getTipo("tipoVerba").id
            )
              .filter((tipo) => this.getTipo_item(tipo.value).isAplicar)
              .map((tipo) => tipo.value);

            return context.datasetList.dataset.aux.verbas
              .filter((verba) =>
                acerto
                  ? verba.allowAcerto
                  : idTiposOcorrencia.includes(verba.idTipo)
              )
              .map((verba) => {
                return {
                  value: verba.id,
                  text: `${verba.id} ${verba.nomeAlt}`,
                };
              });
          } else {
            return [];
          }
        },
      },

      params: {
        context: null,
      },

      colaboradores: [],
      contextDetail: null,
      contextList: null,
      datas: [],
      idColaboradores: null,
      idVerbas: null,
      loadList: false,
      pageModeView: true,
      search: "",
      showSearchOptions: false,
      soAcerto: false,
      verbas: [],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
    }),
  },

  /// METHODS
  methods: {
    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    incluir(context) {
      const ocorrencia = {
        autocompletePessoaFavorecida: [],
        auxDataInicioFim: null,
        auxDataValor: null,
        auxVerba: null,
        colNome: null,
        data: null,
        dataFim: null,
        dataInicio: null,
        del: false,
        id: null,
        idPessoaColaborador: null,
        idPessoaFavorecida: null,
        idVerba: null,
        isAcerto: false,
        nome: null,
        nomeAlt: null,
        allowAcerto: null,
        pessoaFavorecida_nome: null,
        referencia: null,
        valor: null,
        valorItem: null,
        verbNomeAlt: null,
      };

      context.datasets.unshift(ocorrencia);
      context.datasetsTemp.unshift(ocorrencia);
    },
  },
};
</script>