<!-- COD: FDS 26/07/2022 CAssistenciaTecnicaPagePedidoSaidaEdit -->
<!-- *VER: JER 05/08/2022 CAssistenciaTecnicaPagePedidoSaidaEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- status -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip
          v-bind="prop.chip"
          v-for="(statusAssistenciaTecnica, i) in getStatusAssitencia"
          :key="i"
        >
          {{ statusAssistenciaTecnica }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- selecionar produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-checkbox v-model="assistenciaTecnica.produtoSelecionado" />
      </v-col>
    </v-row>

    <!-- produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ produtoFormat(assistenciaTecnica) }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- numero serie -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-2">NS</span>
        <span class="ml-1 text-body-1">
          {{ assistenciaTecnica.numeroSerie }}
        </span>
      </v-col>
      <!-- data -->
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-1">
          {{ dateFormat(assistenciaTecnica.data, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa cliente nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ assistenciaTecnica.pessoaCliente_nome }}
        </span>
      </v-col>
    </v-row>

    <!-- identification -->
    <c-base-item-identification
      v-if="assistenciaTecnica.estado"
      :dataset="assistenciaTecnica"
    />
  </v-container>
</template>

<script>
/// IMPORT
import CBaseItemIdentification from "@/components/bases/CBaseItemIdentification";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CAssistenciaTecnicaPagePedidoSaidaEdit",

  /// COMPONENTS
  components: { CBaseItemIdentification },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    assistenciaTecnica: { type: Object, required: true },
  },

  /// DATA
  data() {
    return {
      status: ["isProprio", "isDefeito", "isCliente", "isPendenteFornecedor"],
    };
  },

  /// COMPUTED
  computed: {
    getStatusAssitencia() {
      return this.status.filter((chip) => this.assistenciaTecnica[chip]);
    },
  },
};
</script>