<!-- COD: FDS 05/07/2022 CPerguntaFrequentePageFaqs -->
<!-- BUG: BJB 23/09/2022 CPerguntaFrequentePageFaqs: Codificar de acordo com planilha interface -->
<!-- #VER: JER __/09/2022 CPerguntaFrequentePageFaqs -->
<!-- 11 -->

<template>
  <v-container>
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end">
        <c-btn class="mr-n3" icon @click="clickPanels()">
          <v-icon>
            {{ !panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up" }}
          </v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <v-expansion-panels v-model="panelsOpen" multiple>
      <v-expansion-panel
        v-for="(dataset, i) in datasetsFiltered"
        class="pl-n6"
        expand
        :key="i"
      >
        <v-expansion-panel-header class="grey lighten-3 pa-0">
          <v-container>
            <!-- grupo -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" cols="11">
                <span class="text-h6">
                  {{ dataset.grupo[locale] }}
                </span>
              </v-col>
              <!-- quantidade registros -->
              <v-col v-bind="prop.col" align="end" cols="1">
                <v-badge
                  color="grey"
                  :content="perguntasFrequentes(dataset).length"
                  inline
                  overlap
                  :value="!!perguntasFrequentes(dataset).length"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-n6 mt-2">
          <c-expansion-panels flat :value="perguntasFrequentes(dataset)">
            <template #header="{ dataset: pergunta }">
              <v-container>
                <!-- pergunta -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <span class="text-body-1">
                      {{ pergunta.pergunta[locale] }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <template #content="{ dataset: pergunta }">
              <!-- resposta -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-base-html :html="pergunta.resposta[locale]" />
                </v-col>
              </v-row>

              <!-- isso foi util -->
              <v-row v-bind="prop.row" v-if="!!getVar('idUsuario')">
                <v-col v-bind="prop.col">
                  <span>Isso foi útil?</span>
                  <v-btn
                    class="ml-3"
                    icon
                    :ripple="false"
                    @click="clickCurtir(true, pergunta, context, i)"
                  >
                    <v-icon color="green">
                      {{
                        pergunta.isCurtiu
                          ? "mdi-thumb-up"
                          : "mdi-thumb-up-outline"
                      }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    icon
                    :ripple="false"
                    @click="clickCurtir(false, pergunta, context)"
                  >
                    <v-icon color="red">
                      {{
                        pergunta.isCurtiu == false
                          ? "mdi-thumb-down"
                          : "mdi-thumb-down-outline"
                      }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </c-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
/// IMPORT
import axios from "axios";
import CBaseHtml from "@/components/bases/CBaseHtml";

export default {
  /// NAME
  name: "CPerguntaFrequentePageFaqs",

  /// COMPONENTS
  components: {
    CBaseHtml,
  },

  /// PROPS
  props: {
    context: { type: Object },
    datasets: { type: Array, required: true },
  },

  /// DATA
  data() {
    return {
      panelsOpen: [],
    };
  },

  /// COMPUTED
  computed: {
    locale() {
      return this.context.getVar("locale");
    },

    datasetsFiltered() {
      return this.datasets.filter((dataset) => this.filterDatasetsFn(dataset));
    },
  },

  /// METHODS
  methods: {
    clickCurtir(like, pergunta, context) {
      like
        ? (pergunta.isCurtiu = pergunta.isCurtiu ? null : like)
        : (pergunta.isCurtiu = pergunta.isCurtiu == false ? null : like);

      const perguntasFrequentes_curtidas = {
        id: pergunta.idCurtida,
        idPai: pergunta.id,
        data: new Date(),
        idUsuario: this.getVar("idUsuario"),
        isCurtiu: pergunta.isCurtiu,
      };

      const url = `${this.getConst("app").baseUrlBackend}/faq`;
      axios
        .post(url, perguntasFrequentes_curtidas)
        .then((res) => {
          const indice =
            context.datasetList.dataset.main.perguntasFrequentes.findIndex(
              (perguntaFrequente) => perguntaFrequente.id == pergunta.id
            );

          context.datasetList.dataset.main.perguntasFrequentes[
            indice
          ].idCurtida = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? []
        : Array(this.datasetsFiltered.length)
            .fill()
            .map((_, i) => i);
    },

    filterDatasetsFn(dataset) {
      return (
        dataset.grupo[this.locale] &&
        this.context.datasetList.dataset.main.perguntasFrequentes.filter(
          (pergunta) =>
            pergunta.grupo[this.locale] == dataset.grupo[this.locale]
        ).length > 0
      );
    },

    perguntasFrequentes(dataset) {
      return this.context.datasetList.dataset.main.perguntasFrequentes.filter(
        (pergunta) => pergunta.grupo[this.locale] == dataset.grupo[this.locale]
      );
    },
  },
};
</script>