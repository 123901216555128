var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"9"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"disabled":_vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomCashback').id,"label":"código","max":25,"required":""},model:{value:(_vm.dataset.main.detail.codigo),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "codigo", $$v)},expression:"dataset.main.detail.codigo"}})],1),(_vm.dataset.main.detail.idTipo != _vm.getTipo_item('cupomRebate').id)?_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"disabled":_vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomCashback').id,"label":"quantidade","max":4,"options":{
          length: 4,
          precision: 0,
        },"required":_vm.dataset.main.detail.idTipo != _vm.getTipo_item('cupomGerador').id},model:{value:(_vm.dataset.main.detail.quantidade),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "quantidade", $$v)},expression:"dataset.main.detail.quantidade"}})],1):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"disabled":_vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomCashback').id,"label":"descrição","max":50,"required":""},model:{value:(_vm.dataset.main.detail.descricao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "descricao", $$v)},expression:"dataset.main.detail.descricao"}})],1)],1),(
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomGerador').id ||
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomDesconto').id
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(_vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomDesconto').id)?_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"icon":"","help":{
          title: 'VALOR OU PERCENTUAL',
          text: 'Clique no ícone para alternar entre valor ou percentual.',
          function: _vm.context.setUiBottomSheet,
        }},on:{"click":_vm.clickIcon}},[_c('v-icon',[_vm._v(_vm._s(_vm.icone))])],1)],1):_vm._e(),(_vm.showValor)?_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"disabled":_vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomCashback').id,"label":"valor","max":10,"options":{
          style: 'currency',
          length: 8,
          precision: 2,
        },"required":""},model:{value:(_vm.dataset.main.detail.valor),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "valor", $$v)},expression:"dataset.main.detail.valor"}})],1):_vm._e(),(
        !_vm.showValor ||
        _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomGerador').id
      )?_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"percentual","max":5,"options":{
          style: 'percent',
          length: 3,
          precision: 1,
          suffix: '%',
        },"required":""},model:{value:(_vm.dataset.main.detail.percentual),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "percentual", $$v)},expression:"dataset.main.detail.percentual"}})],1):_vm._e()],1):_vm._e(),(
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomDesconto').id ||
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomRebate').id
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"itemsIniciais":_vm.dataset.aux.autocomplete.fornecedor,"label":"fornecedores","path":("/pessoas/autocomplete/fornecedores/0/1/" + _vm.idPessoaParceiro + "/"),"required":_vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomRebate').id,"rules":[]},model:{value:(_vm.dataset.main.detail.idPessoaFornecedor),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idPessoaFornecedor", $$v)},expression:"dataset.main.detail.idPessoaFornecedor"}})],1)],1):_vm._e(),(
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomDesconto').id ||
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomCashback').id
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"disabled":_vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomCashback').id,"itemsIniciais":_vm.dataset.aux.autocomplete.cliente,"label":"cliente","path":("/pessoas/autocomplete/cliente/0/0/" + _vm.idPessoaParceiro + "/"),"rules":[]},model:{value:(_vm.dataset.main.detail.idPessoaCliente),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idPessoaCliente", $$v)},expression:"dataset.main.detail.idPessoaCliente"}})],1)],1):_vm._e(),(
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomDesconto').id ||
      _vm.dataset.main.detail.idTipo == _vm.getTipo_item('cupomRebate').id
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-select',{attrs:{"help":{
          title: 'DESCONSIDERAR',
          text: 'Tempo que será desconsiderado para cálculo do vencimento do cupom.',
          function: _vm.context.setUiBottomSheet,
        },"items":_vm.getTipo_itens(_vm.getTipo('unidadeTempo').id),"label":"desconsiderar","required":""},model:{value:(_vm.dataset.main.detail.idDesconsiderar),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idDesconsiderar", $$v)},expression:"dataset.main.detail.idDesconsiderar"}})],1),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
          title: 'PRAZO',
          text: 'Prazo que será considerado para cálculo do vencimento do cupom.',
          function: _vm.context.setUiBottomSheet,
        },"label":"prazo","max":3,"required":"","type":"tel"},model:{value:(_vm.dataset.main.detail.prazo),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "prazo", $$v)},expression:"dataset.main.detail.prazo"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }