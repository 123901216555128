<!-- COD: FDS 02/08/2022 CPedidoPageNumeroSerieView -->
<!-- *VER: JER 08/08/2022 CPedidoPageNumeroSerieView -->
<!-- /22/ -->

<template>
  <v-container v-bind="prop.container" v-if="dataset.main.detail">
    <!-- operacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idOperacao">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getOperacao(dataset.main.detail.idOperacao).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.data, "date timeNoSecond") }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn color="primary" text @click="clickPessoa">
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- limpar NS -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn color="primary" text @click="clickLimparNumerosSerie">
          limpar todos os números de série
        </c-btn>
      </v-col>
    </v-row>

    <!-- picking/unpincking -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn color="primary" text @click="clickPicking(null)">
          {{ labelPickingAll }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- quantidade de itens -->
    <v-row v-bind="prop.row" v-if="dataset.main.itens">
      <v-col v-bind="prop.col">
        <v-badge
          color="grey"
          :content="dataset.main.itens.length"
          inline
          overlap
        ></v-badge>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPedidoPageNumeroSerieView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getOperacao: "operacoes/getOperacao",
    }),

    labelPickingAll() {
      return `${
        this.dataset.main.itens &&
        this.dataset.main.itens.some((item) => item.idColaborador)
          ? "UNPICKING"
          : "PICKING"
      } TODOS`;
    },
  },

  /// METHODS
  methods: {
    async clickLimparNumerosSerie() {
      await this.context.setSnackbar(
        true,
        "NÚMEROS DE SERIES",
        "Limpar todos os números de serie?",
        "warning",
        "mdi-check",
        "clickLimparNS",
        null,
        false
      );
      const resSnackbar = !!this.context.snackbar.params;
      this.context.setSnackbar();

      if (!resSnackbar) {
        return;
      }

      this.dataset.main.numerosSerie = this.dataset.main.numerosSerie.filter(
        (numeroSerie) => numeroSerie.id
      );

      this.dataset.main.numerosSerie.forEach(
        (numeroSerie) => (numeroSerie.del = true)
      );

      this.context.save(false);
    },

    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
    },

    async clickPicking(idProduto) {
      let idColaborador = null;
      let colaborador = null;

      if (
        !this.dataset.main.itens.some(
          (item) =>
            (!idProduto || idProduto == item.idProduto) && item.idColaborador
        )
      ) {
        idColaborador = this.getVar("idUsuario");
        colaborador = this.getVar("usuarios").find(
          (usuario) => usuario.pessoa.id == idColaborador
        ).pessoa.nomeAlt;
      }

      if (
        this.dataset.main.numerosSerie_movimentos.find(
          (numeroSerie, i) =>
            !numeroSerie.del && this.dataset.main.numerosSerie[i].numeroSerie
        )
      ) {
        this.context.setSnackbar(
          true,
          "NÚMEROS DE SERIES",
          "Para fazer unpicking, é necessário limpar antes todos os números de serie.",
          "error"
        );
        return;
      }

      if (!idProduto) {
        const mensagem = `Confirma ${
          idColaborador ? "picking" : "unpicking"
        } de todos produtos?`;

        await this.context.setSnackbar(
          true,
          "NÚMEROS DE SERIES",
          mensagem,
          "warning",
          "mdi-check",
          "clickPicking",
          null,
          false
        );
        const resSnackbar = !!this.context.snackbar.params;
        this.context.setSnackbar();

        if (!resSnackbar) {
          return;
        }
      }

      this.dataset.main.itens
        .filter((item) => !idProduto || idProduto == item.idProduto)
        .forEach((item) => {
          item.idColaborador = idColaborador;
        });

      this.dataset.aux.produtosNS
        .filter((produto) => !idProduto || idProduto == produto.idProduto)
        .forEach((produto) => {
          produto.colaborador = colaborador;
        });

      this.context.save(false);
    },
  },
};
</script>