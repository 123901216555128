<!-- COD: LYF 29/07/2022 CFolhaPagamentoPageColaboradorView -->
<!-- *VER: JER 07/08/2022 CFolhaPagamentoPageColaboradorView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome colaborador -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <a class="text-body-1" @click="clickHolerite">
          {{ colaborador.nome }}
        </a>
      </v-col>
    </v-row>

    <!-- data admissao demissao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ getAdmissaoDemissao }}
        </span>
      </v-col>
    </v-row>

    <!-- comissao -->
    <v-row v-bind="prop.row" v-if="colaborador.comissao && !colaborador.peso">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">comissão</span>
      </v-col>
      <v-col v-bind="prop.col">
        <!-- //?b Poderia usar parametro "percent" na funcao numberFormat? -->
        <span class="text-body-1">
          {{ numberFormat(colaborador.comissao, "decimal", 2) }}%
        </span>
      </v-col>
    </v-row>

    <!-- peso -->
    <v-row v-bind="prop.row" v-if="colaborador.peso">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">peso</span>
      </v-col>
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ numberFormat(colaborador.peso, "decimal", 0) }}
        </span>
      </v-col>
    </v-row>

    <!-- metas -->
    <c-expansion-panels
      flat
      :pageModeView="true"
      :value="
        context.datasetList.dataset.main.metas.filter(
          (meta) => meta.idPessoaColaborador == colaborador.id
        )
      "
    >
      <template #header="{ dataset: meta }">
        <!-- meta verba -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-2">
              {{ metaVerba(meta.index) }}
            </span>
          </v-col>
        </v-row>

        <!-- meta percentual-->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" cols="1">
            <span class="text-body-2">
              {{ metaPercentual(meta.index, true) }}
            </span>
          </v-col>
          <v-col v-bind="prop.col" cols="11">
            <v-progress-linear
              class="ml-n7 mt-5"
              :color="colorProgressLinear(metaPercentual(meta.index))"
              height="2"
              rounded
              :value="metaPercentual(meta.index)"
            />
          </v-col>
        </v-row>
      </template>

      <template #content="{ dataset: meta }">
        <!-- meta planejada -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-2">planejado</span>
          </v-col>
          <v-col v-bind="prop.col" cols="6">
            <span class="text-body-1">
              {{ metaPlanejada(meta.index) }}
            </span>
          </v-col>
        </v-row>

        <!-- meta realizada-->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-2">realizado</span>
          </v-col>
          <v-col v-bind="prop.col" cols="6">
            <span class="text-body-1">
              {{ metaRealizada(meta.index) }}
            </span>
          </v-col>
        </v-row>
      </template>
    </c-expansion-panels>
    <c-folhaPagamento-dialog-holerite
      v-if="showHolerite"
      v-model="showHolerite"
      :dataset="context.dataset"
      :idPessoaColaborador="colaborador.id"
    />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CFolhaPagamentoDialogHolerite from "@/components/pages/folhaPagamento/dialogs/CFolhaPagamentoDialogHolerite.vue";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhaPagamentoPageColaboradorView",

  /// COMPONENTS
  components: {
    CFolhaPagamentoDialogHolerite,
  },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    colaborador: { type: Object, required: true },
    context: { type: Object },
  },

  /// DATA
  data() {
    return {
      showHolerite: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVerba: "verbas/getVerba",
    }),

    getAdmissaoDemissao() {
      const dataDemissao = this.colaborador.dataDemissao
        ? this.dateFormat(this.colaborador.dataDemissao, "date")
        : "atual";

      return `${this.dateFormat(
        this.colaborador.dataAdmissao,
        "date"
      )} - ${dataDemissao}`;
    },
  },

  /// METHODS
  methods: {
    clickHolerite() {
      this.showHolerite = true;
    },

    colorProgressLinear(column) {
      if (column == 0) {
        return "grey";
      } else if (column < 90) {
        return "red";
      } else if (column < 100) {
        return "orange";
      } else {
        return "green";
      }
    },

    metaPercentual(index, formatada = false) {
      const meta = this.context.datasetList.dataset.main.metas[index];

      const percentualRealizado = parseFloat(meta.percentualRealizado || 0);

      return formatada
        ? this.numberFormat(percentualRealizado / 100, "percent", 0)
        : percentualRealizado;
    },

    metaPlanejada(index) {
      const meta = this.context.datasetList.dataset.main.metas[index];
      const suffix = this.getVerba(meta.idVerba)?.options?.unidade || "";

      return `${
        meta.options?.faixa
          ? this.numberFormat(meta.metaPlanejada, "decimal", 2)
          : meta.options?.percentual
          ? this.numberFormat(meta.metaPlanejada, "decimal", 0)
          : this.numberFormat(meta.metaPlanejada, "currency")
      } ${suffix}`;
    },

    metaRealizada(index) {
      const meta = this.context.datasetList.dataset.main.metas[index];
      const suffix = this.getVerba(meta.idVerba)?.options?.unidade || "";

      return `${
        meta.options?.faixa
          ? this.numberFormat(meta.metaRealizada, "decimal", 2)
          : meta.options?.percentual
          ? this.numberFormat(meta.metaRealizada, "decimal", 0)
          : this.numberFormat(meta.metaRealizada, "currency")
      } ${suffix}`;
    },

    metaVerba(index) {
      const meta = this.context.datasetList.dataset.main.metas[index];

      return `${meta.options?.nomeAlt ? meta.options.nomeAlt : meta.nomeAlt}`;
    },
  },
};
</script>