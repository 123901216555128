var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{attrs:{"funcs":_vm.funcsDetail,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"params":_vm.params,"showPanels":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
return [_c('c-extrato-view',{attrs:{"context":context,"dataset":_vm.dataset,"isConcilicao":true}})]}},{key:"bottom",fn:function(ref){
var context = ref.context;
return [_c('v-row',_vm._b({staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-checkbox',{attrs:{"label":"débitos"},on:{"click":function($event){return _vm.clickDebitoCredito(true)}},model:{value:(_vm.soDebito),callback:function ($$v) {_vm.soDebito=$$v},expression:"soDebito"}})],1)],1),_c('v-row',_vm._b({staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-checkbox',{attrs:{"label":"créditos"},on:{"click":function($event){return _vm.clickDebitoCredito(false)}},model:{value:(_vm.soCredito),callback:function ($$v) {_vm.soCredito=$$v},expression:"soCredito"}})],1)],1),_c('v-row',_vm._b({staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-checkbox',{attrs:{"help":{
                title: 'NÃO CONCILIADOS',
                text: "marcado: conciliados\ndesmarcado: não conciliados\ntraço: todos",
                function: context.setUiBottomSheet,
              },"indeterminate":"","label":_vm.labelNaoConciliados},model:{value:(_vm.naoConciliados),callback:function ($$v) {_vm.naoConciliados=$$v},expression:"naoConciliados"}})],1)],1),_c('v-expansion-panels',{ref:"panelsDetail",staticClass:"px-0",attrs:{"multiple":""},model:{value:(_vm.panelsOpen),callback:function ($$v) {_vm.panelsOpen=$$v},expression:"panelsOpen"}},[_c('c-base-panel',{attrs:{"badge":_vm.badgeLancamentos,"datasets":_vm.lancamentos,"header":"lancamentos"}},_vm._l((_vm.lancamentosDatas_distinct()),function(lancamentoData,i){return _c('v-container',{key:("lancamentoData" + i),staticClass:"mt-3"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"11"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"font-weight-medium ml-2 primary--text"},[_vm._v(" "+_vm._s(_vm.dateFormat(lancamentoData.data, "dateWeekday"))+" ")])]),_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-badge',{staticClass:"mb-n10 ml-n5",attrs:{"content":lancamentoData.quantidade,"color":"grey"}})],1)],1),_c('c-expansion-panels',{key:_vm.componentKey,attrs:{"flat":"","params":_vm.params,"value":_vm.lancamentos_distinct_filter(lancamentoData.data)},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var lancamento = ref.dataset;
return [_c('v-row',_vm._b({staticClass:"my-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('c-checkbox',{attrs:{"label":""},on:{"click":function($event){$event.stopPropagation();return _vm.getValoresPagRec(context)}},model:{value:(lancamento.conciliar),callback:function ($$v) {_vm.$set(lancamento, "conciliar", $$v)},expression:"lancamento.conciliar"}})],1),_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(lancamento.historico ? lancamento.historico : lancamento.identificador)+" ")])]),_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:((lancamento.valor < 0 ? 'red--text' : '') + " text-body-1")},[_vm._v(" "+_vm._s(_vm.numberFormat(lancamento.valor, "currency"))+" ")])])],1),(lancamento.idValorPagRec)?_c('v-row',_vm._b({staticClass:"my-n5 ml-n3"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-btn',{staticClass:"text-body-1",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.clickValorPagRec(lancamento.idValorPagRec)}}},[_vm._v(" "+_vm._s(("VALOR #" + (lancamento.idValorPagRec)))+" ")])],1)],1):_vm._e()]}},{key:"content",fn:function(ref){
                        var lancamento = ref.dataset;
return [_c('c-extrato-page-conciliacao-view',{attrs:{"dataset":_vm.dataset,"isLancamento":true,"objeto":lancamento,"params":_vm.params}})]}}],null,true)})],1)}),1),(
              _vm.dataset.aux.valoresPagRec &&
              _vm.lancamentos_distinct.filter(
                function (lancamento) { return lancamento.conciliar && !lancamento.idValorPagRec; }
              ).length == 1
            )?_c('c-base-panel',{attrs:{"badge":_vm.badgeValoresPagRec,"datasets":_vm.dataset.aux.valoresPagRec,"header":"valores pag/rec"}},_vm._l((_vm.valoresPagRecDatas_distinct),function(valorPagRecData,i){return _c('v-container',{key:("valorPagRecData" + i),staticClass:"mt-3"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"11"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"font-weight-medium ml-2 primary--text"},[_vm._v(" "+_vm._s(_vm.dateFormat(valorPagRecData.dataEmissao, "dateWeekday"))+" ")])]),_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-badge',{staticClass:"mb-n10 ml-n5",attrs:{"content":valorPagRecData.quantidade,"color":"grey"}})],1)],1),_c('c-expansion-panels',{attrs:{"flat":"","params":_vm.params,"value":_vm.valoresPagRec_distinct_filter(valorPagRecData.dataEmissao)},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var valorPagRec = ref.dataset;
return [_c('v-row',_vm._b({staticClass:"my-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('c-checkbox',{attrs:{"label":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickValorPagRecConciliar(context, valorPagRec)}},model:{value:(valorPagRec.conciliar),callback:function ($$v) {_vm.$set(valorPagRec, "conciliar", $$v)},expression:"valorPagRec.conciliar"}})],1),_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(valorPagRec.historico ? valorPagRec.historico : valorPagRec.identificador)+" ")])]),_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:((valorPagRec.valor < 0 ? 'red--text' : '') + " text-body-1")},[_vm._v(" "+_vm._s(_vm.numberFormat(valorPagRec.valor, "currency"))+" ")])])],1)]}},{key:"content",fn:function(ref){
                        var valorPagRec = ref.dataset;
return [_c('c-extrato-page-conciliacao-view',{attrs:{"dataset":_vm.dataset,"isLancamento":false,"objeto":valorPagRec,"params":_vm.params}})]}}],null,true)})],1)}),1):_vm._e()],1),_c('v-row',_vm._b({staticClass:"mt-6"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{staticClass:"text-h5 font-weight-light",attrs:{"clearable":false,"label":"lançamentos selecionados","max":15,"options":{
                length: 11,
                precision: 2,
                style: 'currency',
              },"outlined":"","readonly":"","value":_vm.getTotaisLancamentos().valor}}),(_vm.getTotaisLancamentos().quantidade > 0)?_c('v-badge',{staticClass:"mb-n3 ml-n2",attrs:{"color":"grey","content":_vm.getTotaisLancamentos().quantidade,"overlap":""}}):_vm._e()],1),(
              _vm.dataset.aux.valoresPagRec &&
              _vm.lancamentos_distinct.filter(
                function (lancamento) { return lancamento.conciliar && !lancamento.idValorPagRec; }
              ).length == 1
            )?_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{staticClass:"text-h5 font-weight-light",attrs:{"clearable":false,"label":"valores pag/rec selecionados","max":15,"options":{
                length: 11,
                precision: 2,
                style: 'currency',
              },"outlined":"","readonly":"","value":_vm.getTotaisValoresPagRec().valor}}),(_vm.getTotaisValoresPagRec().quantidade > 0)?_c('v-badge',{staticClass:"mb-n3 ml-n2",attrs:{"color":"grey","content":_vm.getTotaisValoresPagRec().quantidade,"overlap":""}}):_vm._e()],1):_vm._e()],1)]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }