var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("localização")])]),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("caixas")])]),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("unidades")])]),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("inventário")])])],1),_vm._l((_vm.localizacoes),function(localizacao,i){return _c('v-row',_vm._b({key:i},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(localizacao.localizacao)+" ")])]),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"","max":13,"options":{
          length: 11,
          precision: localizacao.decimalEstoque,
          style: 'decimal',
        }},model:{value:(localizacao.caixas),callback:function ($$v) {_vm.$set(localizacao, "caixas", $$v)},expression:"localizacao.caixas"}})],1),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"label":"","max":13,"options":{
          length: 11,
          precision: localizacao.decimalEstoque,
          style: 'decimal',
        }},model:{value:(localizacao.unidades),callback:function ($$v) {_vm.$set(localizacao, "unidades", $$v)},expression:"localizacao.unidades"}})],1),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.getInventarioLocalizacao(localizacao))+" ")])])],1)}),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("un/cx")])]),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("inventário")])]),(_vm.soGerente)?_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("estoque")])]):_vm._e(),(_vm.soGerente && !!_vm.diferenca)?_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{class:("text-body-2 " + (_vm.diferenca < 0 ? 'red--text' : ''))},[_vm._v("diferença")])]):_vm._e()],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.produto.unidadesPorCaixa, "decimal"))+" ")])]),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.totalInventario)+" ")])]),(_vm.soGerente)?_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.produto.estoqueProprio, "decimal"))+" ")])]):_vm._e(),(_vm.soGerente && !!_vm.diferenca)?_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('span',{class:("text-body-1 " + (_vm.diferenca < 0 ? 'red--text' : ''))},[_vm._v(" "+_vm._s(_vm.diferenca)+" ")])]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }