<!-- COD: FDS 05/07/2022 CConviteEdit -->
<!-- *VER: JER 08/07/2022 CConviteEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais bandeira -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-menu
          v-model="dataset.main.detail.idPais"
          classe="ml-n2"
          :items="paisesAtivos"
        >
          <template #btn="{ itemSelected }">
            <v-img
              alt="imagem"
              class="mr-2"
              contain
              :src="itemSelected.image"
              width="25"
            />
            <span v-if="itemSelected" class="text=body-2">
              {{ itemSelected.text }}
            </span>
          </template>
          <template #item="{ item }">
            <v-img alt="imagem" contain :src="item.image" width="25" />
            <v-list-item-title class="ml-2 text-body-2">
              {{ item.text }}
            </v-list-item-title>
          </template>
        </c-menu>
      </v-col>
    </v-row>

    <!-- documentoFederal -->
    <v-row
      v-bind="prop.row"
      v-if="
        getTipo_item('conviteContato').id == dataset.main.detail.idTipo ||
        getTipo_item('conviteColaborador').id == dataset.main.detail.idTipo
      "
    >
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="dataset.main.detail.documentoFederal"
          :label="labelDocumentoFederal"
          :max="25"
          :options="{
            mask: getDocumentoMask(dataset.main.detail),
          }"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row
      v-bind="prop.row"
      v-if="
        getTipo_item('conviteContato').id == dataset.main.detail.idTipo ||
        getTipo_item('conviteColaborador').id == dataset.main.detail.idTipo
      "
    >
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nome e sobrenome"
          :max="25"
          required
        />
      </v-col>
    </v-row>

    <!-- email -->
    <v-row
      v-bind="prop.row"
      v-if="
        getTipo_item('conviteContato').id == dataset.main.detail.idTipo ||
        getTipo_item('conviteColaborador').id == dataset.main.detail.idTipo
      "
    >
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.email"
          label="email"
          :max="80"
          required
        />
      </v-col>
    </v-row>

    <!-- celular -->
    <v-row
      v-bind="prop.row"
      v-if="
        getTipo_item('conviteContato').id == dataset.main.detail.idTipo ||
        getTipo_item('conviteColaborador').id == dataset.main.detail.idTipo
      "
    >
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.celular"
          label="celular"
          :max="15"
          :options="{
            mask: dataset.main.detail.celular ? mascara : null,
            options: options,
          }"
          :placeholder="placeholderTelefone"
          type="tel"
        />
      </v-col>
    </v-row>

    <!-- convidado por -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.aux.detail.pessoaColaborador_nomeAlt"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">convidado por</span>
      </v-col>
    </v-row>

    <!-- colaborador nomeAlt -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.aux.detail.pessoaColaborador_nomeAlt"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoaColaborador_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.data">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.data, "date timeNoSecond") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CConviteEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidades: "localidades/getLocalidades",
      getLocalidade_configuracoesRegionais:
        "localidades/getLocalidade_configuracoesRegionais",
      getTipo_item: "tipos/getTipo_item",
    }),

    configuracaoRegionalTelefone() {
      return this.configuracaoRegionalByTipo(
        this.idLocalidade,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    labelDocumentoFederal() {
      const configuracaoRegionalDocumento =
        this.getLocalidade_configuracoesRegionais(
          this.dataset.main.detail.idPais,
          this.getTipo_item("configuracaoRegionalDocumento").id,
          this.getTipo_item("pessoaFisica").id
        );

      return configuracaoRegionalDocumento[0]?.nomeAlt;
    },

    mascara() {
      return this.maskByTipo(
        this.dataset.main.detail.idPais,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    options() {
      return this.optionsByTipo(
        this.dataset.main.detail.idPais,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    placeholderTelefone() {
      return Array.isArray(this.configuracaoRegionalTelefone?.mascaras)
        ? ""
        : this.mascara.replaceAll("!", "");
    },
  },

  /// METHODS
  methods: {
    changePais() {
      const configuracaoRegionalDocumento =
        this.getLocalidade_configuracoesRegionais(
          this.dataset.main.detail.idPais,
          this.getTipo_item("configuracaoRegionalDocumento").id,
          this.getTipo_item("pessoaFisica").id
        );
      const documentoNome = configuracaoRegionalDocumento[0]?.nomeAlt;
      this.dataset.aux.detail.labelDocumentoFederal = documentoNome;

      this.forceUpdate = true;
      this.$forceUpdate();
    },

    getDocumentoMask(documento) {
      return this.getLocalidade_configuracoesRegionais(
        documento.idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id,
        this.getTipo_item("pessoaFisica").id
      )[0].mascara;
    },
  },
};
</script>