<!-- COD: LYF 01/07/2022 CProdutos -->
<!-- *VER: JER 03/07/2022 CProdutos -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- imagem -->
      <v-col v-bind="prop.col" v-if="context.isBuscaLayout" cols="2">
        <c-base-image :endereco="dataset.anexo_endereco" height="60" />
      </v-col>
      <v-col v-bind="prop.col">
        <!-- grupo -->
        <v-row v-bind="prop.row" v-if="dataset.parceiro_grupoProduto">
          <v-col v-bind="prop.col">
            <v-chip v-bind="prop.chip">
              {{ dataset.parceiro_grupoProduto }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- produto -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="font-weight-medium text-body-1">
              {{ produtoFormat(dataset, true) }}
            </span>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <!-- estoque -->
          <v-col v-bind="prop.col" cols="3">
            <span v-if="dataset.estoqueProprio" class="text-body-1">
              {{ estoque }}
            </span>
          </v-col>
          <!-- data disponibilidade -->
          <v-col v-bind="prop.col" align="center" cols="4">
            <span class="text-body-1">
              {{ dateFormat(dataset.dataDisponibilidade, "date") }}
            </span>
          </v-col>
          <!-- preco -->
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-1">
              {{ numberFormat(dataset.preco, "currency") }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- entrega -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-chip
          v-if="dataset.isEntregar != dataset.isRetirar"
          v-show="order.text != 'tipo entrega'"
          class="text-overline white--text"
          :class="dataset.isEntregar ? 'corEntregar' : 'corRetirar'"
          x-small
        >
          {{ dataset.isEntregar ? "entregar" : "retirar" }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CProdutos",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    order: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    estoque() {
      return `${this.numberFormat(
        this.dataset.estoqueProprio,
        "decimal",
        this.dataset.decimalEstoque
      )} ${
        this.getTipo_item(this.dataset.idUnidadeMedida)?.options?.abreviado
      }`;
    },
  },
};
</script>