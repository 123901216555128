<!-- COD: LYF 12/08/2022 CCupons -->
<!-- *VER: JER 19/08/2022 CCupons -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- codigo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-chip
          class="darken-3 white--text text-body-1"
          :class="corCodigo"
          :outlined="false"
        >
          {{ dataset.codigo }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- descricao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.descricao }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor -->
      <v-col v-bind="prop.col" v-if="dataset.valor" cols="5">
        <span class="text-body-1">
          {{ numberFormat(dataset.valor, "currency") }} OFF
        </span>
      </v-col>
      <!-- percentual -->
      <v-col v-bind="prop.col" v-if="dataset.percentual" cols="5">
        <span class="text-body-1">
          {{ numberFormat(dataset.percentual / 100, "percent", 1) }} OFF
        </span>
      </v-col>
      <!-- valor compra minimo -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.valorCompraMinimo"
        align="end"
        cols="7"
      >
        <span class="text-body-1">
          valor mínimo
          {{ numberFormat(dataset.valorCompraMinimo, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa fornecedor nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.pessoaFornecedor_nomeAlt">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoaFornecedor_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa cliente nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.pessoaCliente_nomeAlt">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoaCliente_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- data inicio fim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">válido até {{ dataFim }} </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CCupons",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    corCodigo() {
      let color = "";
      if (!this.dataset.idPessoaParceiro) {
        color = "black";
      } else if (
        this.dataset.idPessoaParceiro &&
        !this.dataset.idPessoaFornecedor
      ) {
        color = "blue";
      } else if (
        this.dataset.idPessoaParceiro &&
        this.dataset.idPessoaFornecedor
      ) {
        color = "green";
      }

      return color;
    },

    dataFim() {
      const dataFim = this.dataset.dataFim
        ? `${this.dateFormat(
            this.dataset.dataFim,
            "date timeNoSecond",
            null,
            true
          )}`
        : "";

      return `${dataFim}`;
    },
  },
};
</script>