var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),_vm._l((_vm.dataset.marcacoes[_vm.data.id]),function(marcacao,i){return _c('span',{key:("marcacao" + i)},[(_vm.isMarcacaoAnterior(marcacao))?_c('v-icon',{staticClass:"ml-2 mr-n3",attrs:{"small":""}},[_vm._v(" mdi-arrow-down-thin ")]):_vm._e(),(_vm.isMarcacaoSeguinte(marcacao))?_c('v-icon',{staticClass:"ml-2 mr-n3",attrs:{"small":""}},[_vm._v(" mdi-arrow-up-thin ")]):_vm._e(),(
            marcacao.data &&
            (i ==
              _vm.dataset.marcacoes[_vm.data.id].filter(function (marcacao) { return marcacao.id; })
                .length -
                1 ||
              i == 0 ||
              marcacao.isUpdated)
          )?_c('c-btn',{staticClass:"ml-n3",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('clickMarcacao', marcacao, _vm.dataset, _vm.data, i)}}},[_c('span',{class:marcacao.isUpdated
                ? 'font-weight-medium text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(marcacao.data), "HH:mm"))+" ")])]):(marcacao.data)?_c('c-btn',{staticClass:"ml-n3",attrs:{"ripple":false,"text":""}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(marcacao.data), "HH:mm"))+" ")])]):_vm._e()],1)}),0)],1),(_vm.data.idInconsistencia)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v("marcações inconsistentes")])],1)],1):_vm._e(),(_vm.data.idInconsistencia)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({on:{"click":function($event){return _vm.$emit('clickComplementar', _vm.dataset, _vm.data)}}},'v-col',_vm.prop.col,false),[_c('c-btn',{staticClass:"text-body-2 text-lowercase",attrs:{"color":"error"}},[_vm._v(" complementar ")])],1)],1):_vm._e(),_vm._l((_vm.dataset.apontamentos[_vm.data.id]),function(apontamento,i){return _c('div',{key:("apontamento" + i)},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v(" "+_vm._s(typeof apontamento == "string" ? apontamento : _vm.getVerba(apontamento.idVerba).options.nomeAlt)+" ")])],1),(
          apontamento.horasApontadas &&
          _vm.getVerba(apontamento.idVerba).options.unidade == 'hora'
        )?_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2",class:_vm.apontamentosColor(_vm.data, apontamento)},[_vm._v(" "+_vm._s(apontamento.horasApontadas.substring(0, 5))+" ")])]):_vm._e(),(
          apontamento.idVerba &&
          _vm.getVerba(apontamento.idVerba).options.unidade == 'hora'
        )?_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1 mt-n4 ml-4","disabled":apontamento.disabled,"label":"confirmado","maxValue":apontamento.horasApontadas,"time":""},on:{"change":function($event){apontamento.isAlterado = true}},model:{value:(apontamento.horasConfirmadas),callback:function ($$v) {_vm.$set(apontamento, "horasConfirmadas", $$v)},expression:"apontamento.horasConfirmadas"}})],1):_vm._e(),_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-spacer')],1),(
          apontamento.idVerba &&
          _vm.getVerba(apontamento.idVerba).options.unidade == 'hora'
        )?_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clickConfirm(apontamento, false)}}},[_c('v-icon',{staticClass:"red--text mt-n2"},[_vm._v("mdi-close")])],1)],1):_vm._e(),(
          apontamento.idVerba &&
          _vm.getVerba(apontamento.idVerba).options.unidade == 'hora'
        )?_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clickConfirm(apontamento, true)}}},[_c('v-icon',{staticClass:"green--text mt-n2"},[_vm._v("mdi-check")])],1)],1):_vm._e()],1),(_vm.getVerba('d4Atraso').id == apontamento.idVerba)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{staticClass:"mt-n6",attrs:{"clearable":"","items":_vm.justificativasAtraso,"label":""},on:{"change":function($event){return _vm.changeSituacaoTrabalhoAtraso(_vm.dataset, apontamento)}},model:{value:(apontamento.idSituacaoTrabalho),callback:function ($$v) {_vm.$set(apontamento, "idSituacaoTrabalho", $$v)},expression:"apontamento.idSituacaoTrabalho"}})],1)],1):(
        _vm.getVerba(apontamento.idVerba) &&
        _vm.getVerba(apontamento.idVerba).options.hasJustificativa
      )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-select',{staticClass:"mt-n6",attrs:{"items":_vm.justificativas,"label":""},on:{"change":function($event){return _vm.changeSituacaoTrabalho(apontamento)}},model:{value:(apontamento.idSituacaoTrabalho),callback:function ($$v) {_vm.$set(apontamento, "idSituacaoTrabalho", $$v)},expression:"apontamento.idSituacaoTrabalho"}})],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[(apontamento.idSituacaoTrabalho == _vm.getTipo_item('outros').id)?_c('c-text-field',{staticClass:"mt-n6",attrs:{"clearable":"","label":"","max":30,"placeholder":"justificativa","required":_vm.getVerba('d4Atraso').id == apontamento.idVerba},on:{"change":function($event){return _vm.changeJustificativa(apontamento)}},model:{value:(apontamento.justificativa),callback:function ($$v) {_vm.$set(apontamento, "justificativa", $$v)},expression:"apontamento.justificativa"}}):_vm._e()],1)],1)],1)}),(_vm.data.idFeriado)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v("feriado")])],1)],1):_vm._e(),(_vm.data.idFeriado)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.getFeriado(_vm.data.idFeriado).nome)+" ")])])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }