<!-- COD: LYF 28/07/2022 CValorPagRecDialogCheque -->
<!-- *VER: JER 06/08/2022 CValorPagRecDialogCheque -->
<!-- /33/ -->

<template>
  <!-- /// CHEQUE -->
  <v-dialog v-model="model" persistent scrollable>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="clickClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>CHEQUE</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="printCheque()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-container class="mt-3 px-2">
          <v-row v-bind="prop.row">
            <!-- locale -->
            <v-col v-bind="prop.col" cols="5">
              <c-select
                v-model="localeTemp"
                :items="localesTeste"
                label="locale"
              />
            </v-col>
            <!-- valor -->
            <v-col v-bind="prop.col" cols="7">
              <c-text-money
                v-model="dataset.main.detail.valor"
                label="valor"
                :max="15"
                :options="{
                  currency: moedaTemp,
                  length: 11,
                  locale: localeTemp,
                  style: 'currency',
                }"
                type="tel"
              />
            </v-col>
          </v-row>

          <v-divider v-bind="prop.divider" />

          <v-row v-bind="prop.row">
            <!-- numero documento -->
            <v-col v-bind="prop.col" cols="3">
              <span class="text-body-1">
                {{ dataset.main.detail.numeroDocumento }}
              </span>
            </v-col>
            <!-- valor -->
            <v-col
              v-bind="prop.col"
              v-if="dataset.main.detail.valor"
              align="end"
              cols="9"
            >
              <span class="text-h6">
                ({{
                  numberFormat(
                    dataset.main.detail.valor,
                    "currency",
                    2,
                    moedaTemp,
                    idPaisTemp
                  )
                }})
              </span>
            </v-col>
          </v-row>

          <!-- valor extenso completo -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" v-if="dataset.main.detail.valor">
              <span class="text-caption">
                {{ valorExtensoCompleto.toUpperCase() }}
              </span>
            </v-col>
          </v-row>

          <!-- nominal a -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-body-2">nominal a</span>
            </v-col>
          </v-row>

          <!-- nominal -->
          <v-row v-bind="prop.row" class="mt-n3">
            <v-col v-bind="prop.col">
              <span class="text-body-1">
                {{ dataset.main.detail.nominal.toUpperCase() }}
              </span>
            </v-col>
          </v-row>

          <!-- local emissao data -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-body-1">
                {{ localEmissaoData }}
              </span>
            </v-col>
          </v-row>

          <!-- emitente nome -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="font-weight-medium text-body-1">
                {{
                  getTipo_item("valorRec").id == dataset.main.detail.idTipo
                    ? dataset.main.detail.emitenteNome
                    : dataset.aux.detail.pessoaParceiro_nome
                }}
              </span>
            </v-col>
          </v-row>

          <!-- emitente -->
          <v-row v-bind="prop.row" class="mt-n3">
            <v-col v-bind="prop.col">
              <span class="text-caption">emitente</span>
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <!-- logo banco -->
            <v-col v-bind="prop.col" cols="2">
              <img class="ml-n1" :src="banco.endereco" width="50" />
            </v-col>
            <v-col v-bind="prop.col" cols="10">
              <!-- banco -->
              <div>
                <span class="font-weight-medium mt-n1 ml-n1 text-body-1">
                  {{ banco.nome }}
                </span>
              </div>

              <!-- conta bancaria -->
              <div>
                <span class="mt-n1 ml-n1 text-body-2">
                  {{
                    `${contaBancaria.banco} ${contaBancaria.agencia} ${contaBancaria.conta}`
                  }}
                </span>
              </div>
            </v-col>
          </v-row>

          <v-divider v-bind="prop.divider" />

          <!-- id -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="end">
              <span class="text-caption">
                #
                {{ dataset.main.detail.id }}
              </span>
            </v-col>
          </v-row>

          <!-- historico -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-body-1">
                {{ dataset.main.detail.historico }}
              </span>
            </v-col>
          </v-row>

          <!-- data apropriacao -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-body-1">
                <span class="text-body-2">bom para</span>
                <span class="ml-1 text-body-1">
                  {{ dateFormat(dataset.main.detail.dataApropriacao, "date") }}
                </span>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <iframe
      v-show="false"
      class="iframeCheque"
      frameborder="3"
      ref="iframeCheque"
      :srcdoc="htmlCheque"
      @load="setPrint"
    ></iframe>
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CValorPagRecDialogCheque",

  /// MIXINS
  mixins: [mixinData],

  /// MODEL
  model: { prop: "value", event: "input" },

  /// PROPS
  props: {
    dataset: Object,
    value: Boolean,
  },

  /// DATA
  data() {
    return {
      htmlCheque: "",
      local: "",
      localesTeste: [
        { value: "pt-BR", text: "pt-BR" },
        { value: "en-US", text: "en-US" },
        { value: "es-ES", text: "es-ES" },
      ],
      localeTemp: "en-US",
      nominal: "",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getBanco: "bancos/getBanco",
      getLocalidade_moedas: "localidades/getLocalidade_moedas",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    ano() {
      return this.hasMesAno
        ? this.dateFormat(
            this.dataset.main.detail.dataEmissao,
            "extenso",
            this.idPaisTemp,
            false,
            null,
            {
              year: "numeric",
            }
          )
        : "";
    },

    banco() {
      return this.contaBancaria
        ? this.getBanco(this.contaBancaria.idBanco)
        : {};
    },

    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },

    camposCheque() {
      // const camposCheque = Object.keys(this.dataset.aux.banco_layoutCheque)
      const camposCheque = (this.dataset.aux.banco_layoutCheque?.config || [])
        .filter((config) => config.linha)
        .map((config) => {
          return {
            ...config,
            key: config.label,
          };
        })
        .sort((a, b) => (a.linha < b.linha ? -1 : 1));

      return camposCheque;
    },

    contaBancaria() {
      return this.dataset.aux.contasBancarias.find(
        (contaBancaria) =>
          contaBancaria.id ==
          this.dataset.main.detail.idPessoaParceiroContaBancaria
      );
    },

    dataExtenso() {
      return this.dateFormat(
        this.dataset.main.detail.dataEmissao,
        "extenso",
        this.idPaisTemp,
        false
      );
    },

    dia() {
      return this.hasMesAno
        ? this.dateFormat(
            this.dataset.main.detail.dataEmissao,
            "extenso",
            this.idPaisTemp,
            false,
            null,
            {
              day: "2-digit",
            }
          )
        : this.dataExtenso;
    },

    enderecoParceiro() {
      return this.parceiro?.enderecos?.find((_, i) => i == 0);
    },

    extenso1() {
      const campoExtenso1 = this.camposCheque.filter(
        (campo) => campo.key == "extenso1"
      );

      return this.valorExtenso.substring(
        0,
        Math.min(this.valorExtenso.length, campoExtenso1.tamanho)
      );
    },

    extenso2() {
      return this.valorExtenso.length > this.extenso1.length
        ? this.valorExtenso.substring(
            this.extenso1.length,
            this.valorExtenso.length
          )
        : "";
    },

    hasMesAno() {
      return this.camposCheque.some(
        (campo) => campo.key == "mes" || campo.key == "ano"
      );
    },

    idPaisTemp() {
      switch (this.localeTemp) {
        case "pt-BR":
          return 20300000;
        case "en-US":
          return 10200000;
        case "es-ES":
          return 41300000;
        default:
          return 20300000;
      }
    },

    locale() {
      return this.localeByPais(this.enderecoParceiro?.idPais);
    },

    localEmissaoData() {
      return `${
        this.dataset.main.detail.localEmissao
          ? this.dataset.main.detail.localEmissao + ", "
          : ""
      }${this.dataExtenso}`;
    },

    mes() {
      return this.hasMesAno
        ? this.dateFormat(
            this.dataset.main.detail.dataEmissao,
            "extenso",
            this.idPaisTemp,
            false,
            null,
            {
              month: "long",
            }
          )
        : "";
    },

    moeda() {
      const idMoeda = this.getLocalidade_moedas(
        this.enderecoParceiro?.idPais
      ).find((moeda) => moeda.isOficial).value;

      const tipoMoeda = this.getTipo_item(idMoeda);

      const moeda = {
        inteiro: {
          singular: tipoMoeda.nomeAlt,
          plural: tipoMoeda.options.plural,
        },
        fracao: {
          singular: tipoMoeda.options.fracaoSingular,
          plural: tipoMoeda.options.fracaoPlural,
        },
      };

      return moeda;
    },

    moedaTemp() {
      switch (this.localeTemp) {
        case "pt-BR":
          return "BRL";
        case "en-US":
          return "USD";
        case "es-ES":
          return "EUR";
        default:
          return "BRL";
      }
    },

    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    parceiro() {
      return this.getVar("usuarios").find(
        (usuario) =>
          usuario.pessoa.id == this.dataset.main.detail.idPessoaParceiro
      );
    },

    valorExtenso() {
      const camposExtenso = this.camposCheque.filter((campo) => {
        return campo.key.startsWith("extenso");
      });

      const tamanho = camposExtenso.reduce(
        (acc, campoExtenso) =>
          (acc +=
            this.valorExtensoCompleto.length > acc ? campoExtenso.tamanho : 0),
        0
      );

      return this.valorExtensoCompleto.padEnd(tamanho, " *");
    },

    valorExtensoCompleto() {
      return `(${this.dataset.main.detail.valor.numberToWords(
        this.localeTemp
      )})`;
    },

    valorFormatado() {
      return this.numberFormat(
        this.dataset.main.detail.valor,
        "currency",
        null,
        this.moedaTemp,
        this.idPaisTemp,
        this.localeTemp
      );
    },
  },

  /// MOUNTED
  mounted() {
    this.local =
      this.getLocalidade(this.enderecoParceiro?.idCidade)?.nome || "";

    this.nominal = this.dataset.aux.detail.pessoa_nome;
  },

  /// METHODS
  methods: {
    clickConfirm() {
      this.model = false;
    },

    clickClose() {
      this.model = false;
    },

    closePrint() {
      this.htmlCheque = "";
    },

    printCheque() {
      const chequeHtmlContent = this.camposCheque.reduce((acc, campo) => {
        const prefix =
          campo.colunaApp == "id" ? "#" : campo.colunaApp == "valor" ? "(" : "";
        const suffix = campo.colunaApp == "valor" ? ")" : "";

        const conteudo = `${prefix}${(
          this.dataset.main.detail[campo.colunaApp] ?? this[campo.colunaApp]
        ).toUpperCase()}${suffix || ""}`;

        return (acc += `<p style="top:${campo.linha}mm; left:${
          campo.coluna
        }mm">${conteudo || ""}</p>`);
      }, "");

      this.htmlCheque = `<!DOCTYPE html>
<html>

<head>
  <style>
    @media print {
        @page {
            margin: 0 !important;
        }
    }

    body {
      background-color: white;
      margin: 0.0cm;
    }

    p {
      color: black;
      font-size: 3mm;
      line-height: 5mm;
      position: absolute;
      font-family: "Lucida Console", "Courier New", monospace;
    }
  </style>
</head>


<body media="print">
${chequeHtmlContent}
</body>

</html>`;
    },

    setPrint(event) {
      if (this.htmlCheque) {
        const contentWindow = event.target.contentWindow;
        const eventWindow =
          this.breakpointName == "xl" ? "afterprint" : "focus";

        contentWindow.focus(); // Required for IE

        contentWindow.addEventListener(eventWindow, () => {
          this.htmlCheque = "";
          contentWindow.addEventListener(eventWindow, null);
        });

        contentWindow.print();
      }
    },
  },
};
</script>

<style scoped>
.iframeCheque {
  border: "0";
  bottom: "0";
  height: "0";
  position: "fixed";
  right: "0";
  width: "0";
}
</style>