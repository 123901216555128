var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{attrs:{"funcs":_vm.funcsDetail,"idFeature":_vm.idFeature,"idPage":_vm.idPageAlt,"idPageAlt":_vm.idPage,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(context.dataset.main.detail.nomeAlt)+" ")])])],1)]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}),(_vm.loadList)?_c('c-base-list',{attrs:{"funcs":_vm.funcsList,"hasSearch":false,"idFeature":_vm.cIdFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"noList":true,"pageModeEdit":true},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var context = ref.context;
return [_c('v-container',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"append-outer-icon":"mdi-tune","clearable":"","max":100,"noAccept":null,"placeholder":"busca","prepend-inner-icon":"mdi-magnify","solo":"","type":"search"},on:{"clickAppendOuter":function($event){_vm.showSearchOptions = !_vm.showSearchOptions},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.$emit('search', _vm.search);
                $event.target.blur();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1 pr-1","date":"","icon":"","label":"início","maxValue":new Date().toISOString().substring(0, 10),"solo":""},on:{"change":_vm.changeDataInicio},model:{value:(_vm.searchData.inicio),callback:function ($$v) {_vm.$set(_vm.searchData, "inicio", $$v)},expression:"searchData.inicio"}})],1),_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1 pl-1","date":"","disabled":!_vm.searchData.inicio,"label":"fim","icon":"","maxValue":_vm.minDateValue([
                  new Date(),
                  _vm.addYears(_vm.parseISO(_vm.searchData.inicio), 1) ])
                  .toISOString()
                  .substring(0, 10),"minValue":_vm.searchData.inicio,"solo":""},model:{value:(_vm.searchData.fim),callback:function ($$v) {_vm.$set(_vm.searchData, "fim", $$v)},expression:"searchData.fim"}})],1)],1),_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchOptions),expression:"showSearchOptions"}],staticClass:"mt-n5"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"block":"","classe":"primary white--text mb-4","label":"buscar","large":""},on:{"click":function($event){return _vm.clickSearch(context)}}},[_vm._v(" buscar ")])],1)],1)],1)]}},{key:"default",fn:function(ref){
                  var dataset = ref.dataset;
                  var context = ref.context;
                  var index = ref.index;
return [(index == 0)?_c('v-container',{staticClass:"py-0"},[(index == 0)?_c('v-sparkline',{attrs:{"auto-draw":"","auto-draw-duration":5000,"gradient":['green', 'yellow', 'red'],"line-width":2,"padding":5,"smooth":2,"value":context.datasetList.dataset.aux.datas}}):_vm._e(),_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":true,"params":_vm.params,"value":[dataset]},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                  var dataset = ref.dataset;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"10"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(dataset.tipoNome)+" ")])]),_c('v-col',_vm._b({attrs:{"align":"center","cols":"2"}},'v-col',_vm.prop.col,false),[_c('v-badge',{attrs:{"color":"grey","content":context.datasetList.dataset.aux.items.filter(
                      function (item) { return item.tipo == dataset.tipo; }
                    ).length,"inline":"","overlap":"","value":!!context.datasetList.dataset.aux.items.filter(
                      function (item) { return item.tipo == dataset.tipo; }
                    ).length}})],1)],1),_c('v-row',_vm._b({attrs:{"justify":"end"}},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center","cols":"2"}},'v-col',_vm.prop.col,false),[_c('span',[_vm._v(" "+_vm._s(_vm.itemTemposTotais(dataset, context))+" ")])])],1)]}},{key:"content",fn:function(ref){
                    var dataset = ref.dataset;
return [_c('c-expansion-panels',{attrs:{"flat":"","hasDot":_vm.hasDot,"isDisabled":_vm.isDisabled,"pageModeView":true,"params":_vm.params,"value":_vm.getItems(context, dataset)},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                    var item = ref.dataset;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"10"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.itemData(item))+" ")])]),_c('v-col',_vm._b({attrs:{"align":"center","cols":"2"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.itemTempos(item))+" ")])])],1)]}},{key:"content",fn:function(ref){
                    var item = ref.dataset;
return [_c('c-colaborador-page-apontamento-view',{attrs:{"item":item}})]}}],null,true)})]}}],null,true)})],1):_vm._e()]}}],null,false,3947873919)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }