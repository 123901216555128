<!-- COD: BJBN 09/01/2023 Legislacoes -->
<!-- *VER: JER __/01/2023 Legislacoes -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-legislacoes :dataset="dataset" :order="order" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CLegislacoes from "@/components/pages/legislacao/CLegislacoes";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Legislacoes",

  /// COMPONENTS
  components: {
    CLegislacoes,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("legislacoes").id,
      idPageAlt: getPagina("legislacao").id,
      funcs: {
        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },
    };
  },
};
</script>