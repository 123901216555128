<!-- COD: LYF 25/08/2022 CExtratoView -->
<!-- *VER: JER 30/08/2022 CExtratoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- banco_anexo -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.banco_anexo_endereco">
      <v-col v-bind="prop.col" align="center">
        <v-img :src="dataset.aux.detail.banco_anexo_endereco" width="30" />
      </v-col>
    </v-row>

    <!-- banco/agencia/conta -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idPessoaParceiroContaBancaria"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ this.contaBancariaFormat(dataset.aux.detail) }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- requer conciliação -->
    <v-row
      v-bind="prop.row"
      v-if="context.dataset.main.detail.requireConciliacao"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip"> requer conciliação </v-chip>
      </v-col>
    </v-row>

    <!-- isIntraday -->
    <v-row v-bind="prop.row" v-if="context.dataset.main.detail.isIntraday">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip"> intraday </v-chip>
      </v-col>
    </v-row>

    <!-- dataInicioFim -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idEstado != getEstado('cadastro').id"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- dataEmissao -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idEstado != getEstado('cadastro').id"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">
          {{
            dataFormata(
              this.dataset.main.detail.dataEmissao,
              "date timeNoSecond"
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- diferenca de saldo -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.aux.detail.extratoAnterior_saldoFinal &&
        dataset.main.detail.saldoInicial !=
          dataset.aux.detail.extratoAnterior_saldoFinal
      "
    >
      <v-col v-bind="prop.col" align="center">
        <v-icon color="red"> mdi-link-variant-off </v-icon>
      </v-col>
    </v-row>

    <!-- saldo inicial -->
    <v-row
      v-bind="prop.row"
      v-if="context.dataset.main.detail.saldoInicial && !isConcilicao"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">saldo inicial</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.saldoInicial)"
        >
          {{
            numberFormat(context.dataset.main.detail.saldoInicial, "currency")
          }}
        </span>
      </v-col>
    </v-row>

    <!-- debitos -->
    <v-row
      v-bind="prop.row"
      v-if="context.dataset.main.detail.debitos && !isConcilicao"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="mr-n1 red--text text-caption">
          {{ context.dataset.main.detail.debitos }}
        </span>
        <v-icon class="red--text">mdi-alpha-d-circle</v-icon>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.totalDebitos)"
        >
          {{
            numberFormat(context.dataset.main.detail.totalDebitos, "currency")
          }}
        </span>
      </v-col>
    </v-row>

    <!-- creditos -->
    <v-row
      v-bind="prop.row"
      v-if="context.dataset.main.detail.creditos && !isConcilicao"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="blue--text mr-n1 text-caption">
          {{ context.dataset.main.detail.creditos }}
        </span>
        <v-icon class="blue--text">mdi-alpha-c-circle</v-icon>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.totalCreditos)"
        >
          {{
            numberFormat(context.dataset.main.detail.totalCreditos, "currency")
          }}
        </span>
      </v-col>
    </v-row>

    <!-- saldo final -->
    <v-row
      v-bind="prop.row"
      v-if="context.dataset.main.detail.saldoFinal && !isConcilicao"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">saldo final</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span
          class="text-body-1"
          :class="classValor(context.dataset.main.detail.saldoFinal)"
        >
          {{ numberFormat(context.dataset.main.detail.saldoFinal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- extrato anterior -->
    <v-row
      v-bind="prop.row"
      v-if="context.dataset.main.detail.idAnterior"
      class="mb-n2"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn
          color="primary"
          class="text-body-1"
          text
          @click="clickExtrato(context.dataset.main.detail.idAnterior)"
        >
          {{ `extrato anterior ${context.dataset.main.detail.idAnterior}` }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- extrato seguinte -->
    <v-row
      v-bind="prop.row"
      v-if="context.dataset.aux.detail.idExtratoSeguinte"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn
          color="primary"
          class="text-body-1"
          text
          @click="clickExtrato(context.dataset.aux.detail.idExtratoSeguinte)"
        >
          {{
            `extrato seguinte ${context.dataset.aux.detail.idExtratoSeguinte}`
          }}
        </c-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CExtratoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    isConcilicao: { type: Boolean, default: false },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getTabela: "tabelas/getTabela",
    }),

    dataInicioFim() {
      return `${this.dataFormata(
        this.dataset.main.detail.dataInicio,
        "dateNoYear"
      )} - ${this.dataFormata(this.dataset.main.detail.dataFim, "date")}`;
    },
  },

  /// METHODS
  methods: {
    classValor(valor) {
      return parseFloat(valor) < 0 ? "red--text" : "";
    },

    clickExtrato(idExtrato) {
      this.$router.push(`/extrato/${idExtrato}`);
    },
  },
};
</script>