<!-- COD: FDS 05/07/2022 CFornecedorClienteEdit -->
<!-- #BUG: JER __/08/2022 CFornecedorClienteEdit: Não tem o campo prazo conserto no banco de dados -->
<!-- VER: JER __/08/2022 CFornecedorClienteEdit -->

<template>
  <v-container v-bind="prop.container">
    <!-- incluir Fornecedor/Cliente -->
    <v-row v-bind="prop.row" v-if="!dataset.main.detail.idPessoa">
      <v-col v-bind="prop.col" align="center">
        <c-btn class="text-body-1" color="primary" text @click="openDialog">
          incluir {{ getTipo_item(dataset.main.detail.idTipo).nomeAlt }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row" v-else>
      <v-col v-bind="prop.col" align="center">
        <c-btn class="text-body-1" color="primary" text @click="openDialog">
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- idClassificacaoFornecedor -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idTipo == getTipo_item(`fornecedor`).id &&
        dataset.main.detail.idPessoa
      "
    >
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idClassificacaoFornecedor"
          clearable
          :items="getTipo_itens(getTipo(`classificacaoFornecedor`).id)"
          label="classificação"
        />
      </v-col>
    </v-row>

    <!-- idNoFornecedorCliente -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPessoa">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.idNoFornecedorCliente"
          :label="labelIdNoFornecedorCliente"
          :max="20"
        />
      </v-col>
    </v-row>

    <!-- dialog incluir pessoa -->
    <c-base-dialog-pessoa-incluir
      v-if="showPessoaIncluir"
      :idPagina="getPagina('fornecedorCliente').id"
      :idTipoConvite="getTipo_item('conviteCliente').id"
      :params="params"
      @close="clickPessoaIncluir($event)"
    />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseDialogPessoaIncluir from "@/components/bases/CBaseDialogPessoaIncluir";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFornecedorClienteEdit",

  /// COMPONENTS
  components: {
    CBaseDialogPessoaIncluir,
  },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// DATA
  data() {
    return {
      showPessoaIncluir: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getPagina: "paginas/getPagina",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
    }),

    labelIdNoFornecedorCliente() {
      return `id no ${
        this.getTipo_item(this.dataset.main.detail.idTipo).nomeAlt
      }`;
    },
  },

  /// METHODS
  methods: {
    clickPessoaIncluir(event) {
      this.showPessoaIncluir = false;

      if (event?.id) {
        this.dataset.main.detail.idPessoa = event.id;
        this.dataset.aux.detail.pessoa_nome = event.nome;
      }
    },

    openDialog() {
      if (
        this.dataset.main.detail.idEstado == this.getEstado("cadastro").id ||
        !this.dataset.main.detail.id
      ) {
        this.showPessoaIncluir = true;
      }
    },
  },
};
</script>