var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-help',{attrs:{"help":_vm.help,"offsetX":_vm.offsetX,"offsetY":_vm.offsetY}},[_c('c-base-money',_vm._b({attrs:{"aceitaNegativo":_vm.aceitaNegativo,"classe":_vm.classe,"clearable":_vm.clearable,"label":_vm.cLabel,"max":_vm.max,"options":_vm.options,"rules":_vm.$attrs.disabled
        ? []
        : _vm.cRules ||
          _vm.commonRules.common(
            _vm.type,
            _vm.required,
            _vm.min,
            _vm.max,
            _vm.noAccept,
            _vm.clean,
            _vm.msg,
            null,
            null,
            _vm.panelCallback
          ),"type":_vm.type},on:{"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('change', $event)},"clickClear":function($event){return _vm.$emit('clickClear', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keyup":function($event){return _vm.$emit('keyup', $event)}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'c-base-money',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }