<!-- COD: FDS 26/07/2022 Produto_NumerosSerie -->
<!-- *VER: JER 28/07/2022 Produto_NumerosSerie -->
<!-- /11/ -->

<template>
  <c-base-list
    v-if="idPage && idPageAlt"
    :funcs="funcs"
    :idFeature="idFeature"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
    :pageModeEdit="true"
  >
    <template #header>
      <v-row v-bind="prop.row" v-if="$route.query.nome">
        <v-col v-bind="prop.col" align="center">
          <span class="text-body-1">
            {{ $route.query.nome }}
          </span>
        </v-col>
      </v-row>
    </template>

    <template v-slot="{ dataset: produto }">
      <c-produto-page-numeroSerie-view v-if="pageModeView" :produto="produto" />
    </template>
  </c-base-list>
</template>

<script>
/// IMPORT
import CProdutoPageNumeroSerieView from "@/components/pages/produto/pages/CProdutoPageNumeroSerieView";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Produto_NumerosSerie",

  /// COMPONENTS
  components: {
    CProdutoPageNumeroSerieView,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idFeature: getFeature("optionNumerosSerie").id,
      idPage: getPagina("produtos").id,
      idPageAlt: getPagina("produto").id,

      funcs: {
        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          if (this.$route.params.idProduto) {
            const params = {
              idProduto: this.$route.params.idProduto,
            };

            return JSON.stringify(params);
          } else {
            return JSON.stringify({});
          }
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },
    };
  },

  /// COMPUTED
  computed: {
    pageModeView() {
      return true;
    },
  },
};
</script>