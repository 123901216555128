<!-- COD: FDS 29/07/2022 CPontoEletronicoDialogMarcacao -->
<!-- *VER: JER 07/08/2022 CPontoEletronicoDialogMarcacao -->
<!-- /22/ -->

<template>
  <v-dialog v-model="show" max-width="380px" persistent scrollable>
    <!-- /// MARCAÇÃO -->
    <v-card class="mx-auto" max-width="380">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <c-btn
            icon
            @click="
              data = null;
              $emit('close');
            "
          >
            <v-icon>mdi-close</v-icon>
          </c-btn>
          <v-row justify="end">
            <v-toolbar-title>MARCAÇÃO</v-toolbar-title>
          </v-row>
          <v-spacer />
          <c-btn icon @click="clickSave">
            <v-icon>mdi-check</v-icon>
          </c-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-8">
        <v-form class="px-0" ref="form">
          <!-- data -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-h6 grey--text">
                {{
                  dataset.isAlterarMarcacao
                    ? format(
                        parseISO(dataset.data.data.substring(0, 10)),
                        "dd/MM/yyyy"
                      )
                    : format(parseISO(dataset.data.data), "dd/MM/yyyy HH:mm")
                }}
              </span>
            </v-col>
          </v-row>

          <!-- alterar marcacao -->
          <v-row v-bind="prop.row" justify="center">
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="data"
                :clearable="isClearable"
                date
                :label="dataset.label"
                :maxValue="maxValue"
                :minValue="minValue"
                :required="!isClearable"
                :time="dataset.isAlterarMarcacao || isClearable"
              />
            </v-col>
          </v-row>
          <!-- /// BOTTOMSHEET -->
          <c-bottom-sheet :bottomSheet="bottomSheet" />
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CPontoEletronicoDialogMarcacao",

  /// MIXINS
  mixins: [mixinBottomSheet, mixinData, mixinSnackbar],

  /// PROPS
  props: {
    dataset: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {
      data: this.dataset?.marcacao?.isUpdated
        ? this.dataset.marcacao.data
        : null,
    };
  },

  /// COMPUTED
  computed: {
    isClearable() {
      return this.dataset?.marcacao?.isUpdated ? true : false;
    },

    maxValue() {
      return this.format(
        new Date(this.dataset.dataSeguinte?.data),
        "yyyy-MM-dd HH:mm:ss"
      );
    },

    minValue() {
      this.$forceUpdate();
      return this.format(
        new Date(this.dataset.dataAnterior?.data),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
  },

  /// METHODS
  methods: {
    async clickSave() {
      if (this.$refs.form.validate()) {
        if (this.dataset.isAlterarMarcacao) {
          this.dataset.colaborador.marcacoes[
            this.dataset.data.id.toString()
          ].find((marcacao) => !marcacao.id).data = new Date(
            this.parseISO(this.data).valueOf() +
              this.parseISO(this.data).getTimezoneOffset() * 60000
          ).toISOString();

          this.dataset.colaborador.marcacoes[
            this.dataset.data.id.toString()
          ].forEach((marcacao) => {
            if (!marcacao.id) {
              marcacao.isAlterado = true;
            }
          });
        } else {
          const datas = [this.dataset.dataAnterior, this.dataset.dataSeguinte];
          const data = datas.find((data) => {
            if (data.data) {
              return this.dataset.marcacao.isUpdated
                ? data.id == this.dataset.marcacao.idPai2 && this.data != null
                : this.format(new Date(data.data), "yyyy-MM-dd") ==
                    this.format(this.parseISO(this.data), "yyyy-MM-dd");
            }
          });

          Object.keys(this.dataset.colaborador.apontamentos).forEach(
            (chave) => {
              const aDatas = [
                this.dataset.dataReferencia,
                this.dataset.dataAnterior,
                this.dataset.dataSeguinte,
              ];

              const idDatas = [...new Set(aDatas.map((data) => data.id))];

              if (
                this.dataset.colaborador.apontamentos[chave].some(
                  (apontamento) => idDatas.includes(apontamento.idPai2)
                )
              ) {
                this.dataset.colaborador.apontamentos[chave].forEach(
                  (apontamento) => (apontamento.del = true)
                );
                this.dataset.colaborador.apontamentos[chave].forEach(
                  (apontamento) => (apontamento.isAlterado = true)
                );
              }
            }
          );

          if (this.dataset.marcacao.isUpdated) {
            this.dataset.marcacao.data = this.data
              ? new Date(
                  new Date(this.data).valueOf() +
                    new Date(this.data).getTimezoneOffset() * 60000
                ).toISOString()
              : null;
          } else {
            this.dataset.marcacao.data = data
              ? this.dataset.marcacao.data
              : null;
          }

          this.dataset.marcacao.idPai2 = data
            ? data.id
            : this.dataset.marcacao.idPai2;
          this.dataset.marcacao.del = data || this.data ? false : true;
          this.dataset.marcacao.isAlterado = true;
        }

        this.data = null;

        this.$emit("incluido", true);
      } else {
        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );
      }
    },
  },
};
</script>