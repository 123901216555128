// COD: BJB 15/08/2022 axios
// *VER: JER 19/08/2022 axios
// /12/

/// IMPORT
import axios from "axios"
import constantes from './store/modules/constantes'

axios.interceptors.request.use(config => {
  // const authorizedUrls = ['http://localhost', 'https://app2.w4y.com.br']
  // const urlOrigin = window.location.origin.split(':').filter((el, i) => i <= 1).join(':')

  //?b Verificar este trecho
  // if (authorizedUrls.includes(urlOrigin)) {
  const userKey = JSON.parse(localStorage.getItem(constantes.state.app.userKey))

  if (config.data && config.data.metadados) {
    config.data.metadados.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  if (userKey) {
    const token = userKey.metadados?.token
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
  // }
}, err => {
  console.log("reject", err)
  // Do something with request error
  return Promise.reject(err);
})