<!-- COD: FDS 26/07/2022 AssistenciasTecnicas_PedidoSaida -->
<!-- *VER: JER 08/08/2022 AssistenciasTecnicas_PedidoSaida -->
<!-- /33/ -->

<template>
  <v-form class="mb-10 pa-0" id="formSelect" ref="form">
    <c-base-list
      v-if="idPage && idPageAlt"
      :funcs="funcs"
      :hasSearch="false"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :pageModeEdit="true"
    >
      <template #header="{ context }">
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <v-chip-group
              v-model="isCliente"
              :disabled="context.snackbar.show"
              style="width: fit-content"
              @change="changeFilter(context)"
            >
              <v-chip
                v-for="(cliFor, i) in clienteFornecedor"
                :class="
                  isCliente != cliFor.value ? 'primary--text' : 'white--text'
                "
                color="primary"
                :key="i"
                :outlined="isCliente != cliFor.value"
                :value="cliFor.value"
              >
                {{ cliFor.text }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <!-- pessoa -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-select
              v-model="idPessoa"
              :disabled="context.snackbar.show"
              :items="pessoas"
              label="pessoa"
              required
            />
          </v-col>
        </v-row>
      </template>

      <template #insert="{ context }">
        <div v-if="context.datasets.length">
          <!-- label -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="red--text text-body-2">
                selecione os itens a enviar
              </span>
            </v-col>
          </v-row>

          <!-- selecionar/deselecionar todos -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <c-btn
                classe="pa-0 primary--text"
                text
                @click="selecionarTodos(context)"
              >
                {{ labelSelecionarDeselecionarTodos(context) }}
              </c-btn>
            </v-col>
          </v-row>
        </div>
      </template>

      <template #bottom="{ context }">
        <div v-if="context.datasets.length" class="mt-5">
          <!-- operacao -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idOperacao"
                :disabled="context.snackbar.show"
                :items="operacoes"
                label="operação"
                required
              />
            </v-col>
          </v-row>

          <!-- prestador -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idPrestador"
                :disabled="context.snackbar.show"
                :items="prestadores"
                label="prestador"
                required
              />
            </v-col>
          </v-row>

          <!-- prazo -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="4">
              <c-text-money
                v-model="prazo"
                :disabled="context.snackbar.show"
                label="prazo"
                :max="3"
                :options="{
                  length: 3,
                  precision: 0,
                  prefix: '',
                  suffix: ' dias',
                }"
                required
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="5">
              <!-- total selecionado -->
              <c-text-money
                class="text-h5 font-weight-light"
                :clearable="false"
                label="total selecionado"
                :max="15"
                :options="{
                  length: 11,
                  precision: 2,
                  style: 'currency',
                }"
                outlined
                readonly
                :value="getTotais(context).valor"
              />
              <!-- quantidade selecionada -->
              <v-badge
                v-if="getTotais(context).quantidade > 0"
                class="mb-n3 ml-n2"
                color="grey"
                :content="getTotais(context).quantidade"
                overlap
              />
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-slot="{ dataset: assistenciaTecnica }">
        <c-assistenciaTecnica-page-pedido-saida-edit
          :assistenciaTecnica="assistenciaTecnica"
        />
      </template>
    </c-base-list>
  </v-form>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CAssistenciaTecnicaPagePedidoSaidaEdit from "@/components/pages/assistenciaTecnica/pages/CAssistenciaTecnicaPagePedidoSaidaEdit";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "AssistenciasTecnicas_PedidoSaida",

  /// COMPONENTS
  components: {
    CAssistenciaTecnicaPagePedidoSaidaEdit,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idFeature: getFeature("optionPedidoSaidaGerar").id,
      idPage: getPagina("assistenciasTecnicas").id,
      idPageAlt: getPagina("assistenciaTecnica").id,

      isCliente: false,
      clienteFornecedor: [
        {
          value: false,
          text: "fornecedor",
        },
        {
          value: true,
          text: "cliente",
        },
      ],
      idPessoa: null,
      idPrestador: null,
      idOperacao: null,
      pessoas: [],
      prazo: null,
      prestadores: [],

      funcs: {
        changed: (context) => {
          return context.datasets.some(
            (dataset, i) =>
              dataset.produtoSelecionado !=
              context.datasetsTemp[i].produtoSelecionado
          );
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        clickSave: async (context) => {
          if (!this.$refs.form.validate()) {
            context.options.click.setSnackbar(
              true,
              "CAMPOS INVÁLIDOS",
              "Verificar campos inválidos.",
              "error",
              "",
              "depositarErro",
              this.getConst("config").snackbar.timeout,
              false
            );

            let interval = setInterval(() => {
              if (!context.options.click.snackbar.show) {
                clearInterval(interval);
                interval = null;
              }
            }, 100);

            return null;
          }

          const itensSelecionados = this.getTotais(
            context.options.click
          ).quantidade;
          const textoItensSelecionados =
            itensSelecionados > 1
              ? `os ${itensSelecionados} itens selecionados.`
              : "o item selecionado.";
          const textoSelecionado = "Será gerado um pedido de saída para";
          const acao = "Prosseguir";
          const texto = `${textoSelecionado} ${textoItensSelecionados}\n${acao}?`;

          context.options.click.setSnackbar(
            true,
            "PEDIDO DE SAÍDA",
            texto,
            "warning",
            "mdi-check",
            "pedidoGerarConfirm",
            this.getConst("config").snackbar.timeout,
            null
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "pedidoSaida") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          } else if (action == "pedidoGerarConfirm") {
            this.pedido_gerar(context);
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          let params = null;

          if (this.idPessoa) {
            params = this.isCliente
              ? {
                  "ast_pesCli.isCliente": this.isCliente,
                  "ast_pesCli.idPessoa": this.idPessoa,
                  "_tab.isCliente": this.isCliente,
                }
              : {
                  "ast_pesForn.isCliente": this.isCliente,
                  "ast_pesForn.idPessoa": this.idPessoa,
                  "_tab.isPendenteFornecedor": !this.isCliente,
                };
          } else {
            params = this.isCliente
              ? { "_tab.isCliente": this.isCliente }
              : { "_tab.isPendenteFornecedor": !this.isCliente };
          }

          return JSON.stringify(params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
          dataset.produtoSelecionado = false;
        },

        preLoad: async (context) => {
          const method = "get";
          let url = `${context.getConst("app").baseUrlBackend}/${
            context.page.nome
          }/getPessoas/${this.getVar("idPessoaParceiro")}`;

          const res = await context.submit(method, url, null);

          if (res) {
            this.prestadores = res.data.prestadores.map((prestador) => {
              return {
                value: prestador.idPessoa,
                text: prestador.nome,
              };
            });

            this.pessoas = res.data.pessoas.map((pessoa) => {
              return {
                value: pessoa.idPessoa,
                text: pessoa.nome,
                cliente: pessoa.cliente,
              };
            });
          }
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getOperacao: "operacoes/getOperacao",
      getOperacoes: "operacoes/getOperacoes",
    }),

    operacoes() {
      return this.getOperacoes()
        .filter(
          (operacao) =>
            this.getOperacao(operacao.value).options.hasAssistenciaTecnica &&
            !this.getOperacao(operacao.value).isEntrada &&
            !this.isCliente == !this.getOperacao(operacao.value).hasCliente &&
            this.isCliente == this.getOperacao(operacao.value).hasCliente
        )
        .map((operacao) => {
          return {
            value: operacao.value,
            text: this.getOperacao(operacao.value).nomeAlt,
          };
        });
    },
  },

  /// METHODS
  methods: {
    changeFilter(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    getTotais(context) {
      const valor = context.datasets
        .filter((dataset) => dataset.produtoSelecionado)
        .reduce((acc, item) => {
          const idPessoa = this.isCliente
            ? item.idPessoa
            : item.idPessoaFornecedor;

          const pedidoReferencia =
            item.pedidos.find(
              (pedido) =>
                pedido.idTipo == this.getTipo_item("pedidoEntrada").id &&
                pedido.idPessoa == idPessoa
            ) || null;

          const valor =
            parseFloat(
              pedidoReferencia?.preco || item.custoMedio || item.custoMedio || 0
            ) * (item.produtoSelecionado ? 1 : -1);

          return (acc += valor);
        }, 0);

      const quantidade = context.datasets.filter(
        (dataset) => dataset.produtoSelecionado
      ).length;

      const total = {
        valor,
        quantidade,
      };

      return total;
    },

    labelSelecionarDeselecionarTodos(context) {
      return context.datasets.some((dataset) => dataset.produtoSelecionado)
        ? "deselecionar todos"
        : "selecionar todos";
    },

    async pedido_gerar(context) {
      const metadados = {
        idEvento: this.getTipo_item("eventoIncluido").id,
        idPessoaParceiro: context.getVar("idPessoaParceiro"),
        idUsuario: context.getVar("idUsuario"),
        modo: context.getConst("config").modo.cadastro.id,
      };

      //Detalhes do endereço para o pedido
      const enderecoEndereco =
        this.getVar("usuarios").find(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        ).enderecos[0].endereco || "";

      const enderecoCidade =
        this.getLocalidade(
          this.getVar("usuarios").find(
            (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
          ).enderecos[0].idCidade
        ).nome || "";

      const dataset = {
        metadados,
        params: {
          itensSelecionados: context.datasets
            .filter((dataset) => dataset.produtoSelecionado)
            .filter((dataset, i) =>
              Object.keys(dataset).some(
                (key) => dataset[key] != context.datasetsTemp[i][key]
              )
            ),
          enderecoEndereco,
          enderecoCidade,
          idOperacao: this.idOperacao,
          idPrestador: this.idPrestador,
          idPessoa: this.idPessoa,
          prazo: this.isCliente ? 0 : this.prazo,
        },
      };

      const produtos = [
        ...new Set(
          dataset.params.itensSelecionados.map((detail) => {
            return {
              idProduto: parseInt(detail.idProduto),
              idPedidoItemReferencia:
                detail.pedidoReferencia?.idPedidoItem || null,
              preco:
                detail.pedidoReferencia?.preco ||
                detail.custoMedio ||
                detail.custoReposicao ||
                0,
            };
          })
        ),
      ];

      dataset.params.itensSelecionados = produtos.map((produto) => {
        const itensFiltered = dataset.params.itensSelecionados.filter(
          (item) =>
            item.idProduto == produto.idProduto &&
            item.pedidoReferencia?.idPedidoItem ==
              produto.idPedidoItemReferencia
        );

        const numerosSerie = itensFiltered.map((item) => {
          return {
            idProduto: produto.idProduto,
            numeroSerie: item.numeroSerie,
          };
        });

        const numerosSerie_movimentos = itensFiltered.map((item) => {
          return {
            idPai: null,
            data: null,
            idPedido: null,
            idAssistenciaTecnica: item.id,
            isDefeito: item.isDefeito,
            idColaborador: metadados.idUsuario,
          };
        });

        return {
          idProduto: produto.idProduto,
          preco: produto.preco,
          idPedidoItemReferencia: produto.idPedidoItemReferencia,
          quantidade: itensFiltered.length,
          numerosSerie,
          numerosSerie_movimentos,
        };
      });

      const method = "post";
      const url = `${this.getConst("app").baseUrlBackend}/${
        context.page.nome
      }/pedidoSaidaGerar`;
      const res = await context.submit(method, url, dataset);

      if (res) {
        context.datasets = JSON.parse(JSON.stringify(context.datasetsTemp));

        const pedido_id = res.data;
        const itensSelecionados = this.getTotais(context).quantidade;
        const textoItensSelecionados =
          itensSelecionados > 1
            ? `dos ${itensSelecionados} itens selecionados.`
            : "do item selecionado.";
        const textoSelecionado = `Foi criado o pedido de saída #${pedido_id} para o envio`;
        const acao = "Detalhar";
        const texto = `${textoSelecionado} ${textoItensSelecionados}\n${acao}?`;
        const color = "success";

        context.setSnackbar(
          true,
          "BAIXA CRUZADA",
          texto,
          color,
          "mdi-check",
          "pedidoSaida",
          this.getConst("config").snackbar.timeout,
          false
        );

        let interval = setInterval(() => {
          if (context.snackbar.params) {
            clearInterval(interval);
            const url = `/pedido/${pedido_id}`;
            context.$router.push(encodeURI(url));
          } else if (!context.snackbar.show) {
            clearInterval(interval);
            interval = null;
            context.funcs.clickVoltar();
          }
        }, 100);
      }
    },

    selecionarTodos(context) {
      if (context?.datasets.some((ds) => !ds.produtoSelecionado)) {
        context?.datasets.forEach((ds) => {
          ds.produtoSelecionado = true;
        });
      } else {
        context?.datasets.forEach((ds) => (ds.produtoSelecionado = false));
      }
    },
  },
};
</script>