<!-- COD: FDS 01/07/2022 Convites -->
<!-- *VER: JER 04/07/2022 Convites -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
    :funcs="funcs"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-convites :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CConvites from "@/components/pages/convite/CConvites";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Convites",

  /// COMPONENTS
  components: {
    CConvites,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("convites").id,
      idPageAlt: getPagina("convite").id,

      funcs: {
        fnParams: (context) => {
          return JSON.stringify({
            tipos: context.tipos.map((tipo) => tipo.value),
          });
        },

        posLoad: async (context) => {
          if (
            [
              context.getTipo_item("conviteAmigo").id,
              context.getTipo_item("conviteCliente").id,
            ].includes(context.tipos[0].value)
          ) {
            let urlConvite;

            if (
              [
                this.getFeature("filterFiltrar").id,
                this.getFeature("filterAtivos").id,
              ].includes(context.idFilter) &&
              !context.datasets.filter(
                (dataset) => dataset.idTipo == context.tipos[0].value
              ).length
            ) {
              const method = "post";
              const url = `${context.getConst("app").baseUrlBackend}/${
                context.page.nome
              }`;
              const dataset = {
                metadados: {
                  modo: this.getConst("config").modo.cadastro.id,
                  idUsuario: this.getVar("idUsuario"),
                  idPessoaParceiro: this.getVar("idPessoaParceiro"),
                  idEvento: this.getTipo_item("eventoIncluido").id,
                },
                main: {
                  detail: {
                    id: null,
                    idTipo: context.tipos[0].value,
                    idEstado: this.getEstado("ativo").id,
                    isSuspenso: false,
                    idPessoaParceiro: null,
                    data: null,
                    idColaborador: null,
                    idPais: this.getConst("localidades").paises.brasil.id,
                    documentoFederal: null,
                    nomeAlt: null,
                    email: null,
                    observacoes: null,
                  },
                },
              };

              const res = await context.submit(method, url, dataset);
              urlConvite = `/${context.pageAlt.nome}/${res.data.main.detail.id}`;
            }

            if (context.tipos.length == 1) {
              urlConvite =
                urlConvite ||
                `/${context.pageAlt.nome}/${context.datasets[0].id}`;

              const routeIgnore = context.getVar("routeIgnore");
              routeIgnore.push(this.$route.name);
              context.setVar({ routeIgnore });

              this.$router.push(urlConvite);
            } else {
              context.clickSearch();
            }
          }
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },
    };
  },
};
</script>