<!-- COD: BJB 28/07/2022 CColaboradorPageHoraExtraEdit -->
<!-- *VER: JER 07/08/2022 CColaboradorPageHoraExtraEdit -->
<!-- /12/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- deletar -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-base-panel-delete
          class="ml-n1"
          :datasets="context.datasets"
          :indice="index"
          :panel="panel"
          @click="deletar(context)"
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row" v-if="horaExtra.nomeAlt">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ horaExtra.nomeAlt.toUpperCase() }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataInicio -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="horaExtra.dataInicio"
          classe="text-body-1"
          clearable
          date
          :disabled="
            new Date(`${horaExtra.dataFim} ${horaExtra.horaFim}`) <
              new Date() ||
            new Date(`${horaExtra.dataInicio} ${horaExtra.horaInicio}`) <
              new Date()
          "
          label="data início"
          :maxValue="horaExtra.dataFim"
          :minValue="new Date().toISOString().substring(0, 10)"
        />
      </v-col>
      <!-- dataFim -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="horaExtra.dataFim"
          classe="text-body-1 ml-2"
          clearable
          date
          :disabled="
            new Date(`${horaExtra.dataFim} ${horaExtra.horaFim}`) < new Date()
          "
          label="data fim"
          :minValue="
            horaExtra.dataInicio || new Date().toISOString().substring(0, 10)
          "
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- horaInicio -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="horaExtra.horaInicio"
          classe="text-body-1"
          clearable
          :disabled="
            new Date(`${horaExtra.dataFim} ${horaExtra.horaFim}`) <
              new Date() ||
            new Date(`${horaExtra.dataInicio} ${horaExtra.horaInicio}`) <
              new Date()
          "
          :help="{
            title: 'HORA EXTRA',
            text: 'Hora inicio e hora fim é o intervalo em que a hora extra poderá ser feita.',
            function: context.setUiBottomSheet,
          }"
          label="hora início"
          :minValue="format(new Date(), `kk:mm`)"
          time
        />
      </v-col>
      <!-- horaFim -->
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="horaExtra.horaFim"
          classe="text-body-1 ml-2"
          clearable
          :disabled="
            new Date(`${horaExtra.dataFim} ${horaExtra.horaFim}`) <
              new Date() ||
            new Date(`${horaExtra.dataInicio} ${horaExtra.horaInicio}`) <
              new Date()
          "
          :help="{
            title: 'HORA EXTRA',
            text: 'Hora inicio e hora fim é o intervalo em que a hora extra poderá ser feita.',
            function: context.setUiBottomSheet,
          }"
          label="hora fim"
          :minValue="horaExtra.horaInicio"
          time
        />
      </v-col>
    </v-row>

    <!-- horasExtras -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="5">
        <c-datetime-picker
          v-model="horaExtra.horasExtras"
          classe="text-body-1"
          clearable
          :disabled="
            new Date(`${horaExtra.dataFim} ${horaExtra.horaFim}`) <
              new Date() ||
            new Date(`${horaExtra.dataInicio} ${horaExtra.horaInicio}`) <
              new Date()
          "
          :help="{
            title: 'HORA EXTRA',
            text: 'Horas autorizadas é o número de horas extras que estão autorizadas.',
            function: context.setUiBottomSheet,
          }"
          label="horas autorizadas"
          maxValue="12:00"
          :minValue="format(new Date(), `kk:mm`)"
          :stepMinutes="15"
          time
        />
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="horaExtra.observacao"
          label="observação"
          :max="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBasePanelDelete from "@/components/bases/CBasePanelDelete";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CColaboradorPageHoraExtraEdit",

  /// COMPONENTS
  components: {
    CBasePanelDelete,
  },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object, required: true },
    horaExtra: { type: Object, required: true },
    index: { type: Number, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
    }),

    panel() {
      return this.getFeature("optionHorasExtras");
    },
  },

  /// METHODS
  methods: {
    deletar(context) {
      context.datasets = context.datasets.filter((dataset) => !dataset.del);
      context.datasets.forEach((dataset, i) => {
        dataset.indice = i;
      });
    },
  },
};
</script>