<!-- COD: FDS 04/07/2022 CDicionarioView -->
<!-- *VER: JER 05/07/2022 CDicionarioView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- locale -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idLocale">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ localeById(dataset.main.detail.idLocale) }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- texto -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.texto">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.texto }}
        </span>
      </v-col>
    </v-row>

    <!-- tabela -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.tabela">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">tabela</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ getTabela(context.dataset.main.detail.idTabela).nome }}
        </span>
      </v-col>
    </v-row>

    <!-- traducaoFrontend -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">traduzido no</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.hasTraducaoFrontend ? "frontend" : "backend" }}
        </span>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.observacao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">({{ dataset.main.detail.observacao }})</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CDicionarioView",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTabela: "tabelas/getTabela",
    }),
  },
};
</script>