<!-- COD: LYF 05/07/2022 CContaPagRecEdit -->
<!-- *VER: JER 05/08/2022 CContaPagRecEdit -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pessoa -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPessoa"
          :itemsIniciais="dataset.aux.autocomplete.pessoas"
          label="pessoa"
          :path="`/pessoas/autocomplete/cliente/0/0/${getVar(
            'idPessoaParceiro'
          )}/`"
          required
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- importe -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="2">
        <c-switch
          v-model="dataset.main.detail.isImporte"
          :help="{
            title: 'IMPORTE',
            text: 'Indica que o conta pag/rec não ira contabilizar ao avançar seu estado para aberto.',
            function: context.setUiBottomSheet,
          }"
          label="importe"
        ></c-switch>
      </v-col>
    </v-row>

    <!-- historico padrao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idHistoricoPadrao"
          :itemsIniciais="dataset.aux.autocomplete.historicoPadrao"
          label="histórico padrão"
          path="/historicosPadrao/autocomplete/historicoPadrao/null/null/"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- imposto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idImposto"
          :items="imposto"
          label="imposto"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- id pedido -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="dataset.main.detail.idPedido"
          disabled
          label="pedido"
          :max="15"
        />
      </v-col>
      <!-- duplicata -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="dataset.main.detail.duplicata"
          label="duplicata"
          :max="15"
        />
      </v-col>
    </v-row>

    <!-- forma pagamento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idFormaPagamento"
          :items="formaPagamento"
          label="forma pagamento"
          required
        />
      </v-col>
    </v-row>

    <!-- conta bancaria -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idPessoaParceiroContaBancaria"
    >
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="contaBancaria"
          disabled
          label="banco/agência/conta"
          :max="35"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data emissao -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataEmissao"
          date
          label="data emissão"
          required
        />
      </v-col>
      <!-- prazo -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-field
          v-model="dataset.main.detail.prazo"
          label="prazo"
          :max="15"
          required
          suffix=" dias"
          type="tel"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data vencimento -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataVencimento"
          date
          disabled
          label="data vencimento"
        />
      </v-col>
      <!-- valor -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valor"
          label="valor"
          :max="20"
          :options="{
            style: 'currency',
            length: 11,
            precision: 2,
          }"
          required
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CContaPagRecEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
    }),

    contaBancaria() {
      const contaBancaria = this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ].contas.find(
        (conta) =>
          conta.id == this.dataset.main.detail.idPessoaParceiroContaBancaria
      );

      return contaBancaria ? this.contaBancariaFormat(contaBancaria) : null;
    },

    formaPagamento() {
      return this.getTipo_itens(this.getTipo("formaPagamento").id).filter(
        (item) => {
          const formaPagamento = this.getTipo_item(item.value);
          return (
            !formaPagamento?.options?.localidades ||
            formaPagamento.options.localidades.includes(
              this.enderecoParceiro?.idPais
            )
          );
        }
      );
    },

    imposto() {
      return this.getTipo_itens(this.getTipo("tributo").id);
    },
  },
};
</script>