var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.load)?_c('c-base-detail',{attrs:{"funcs":_vm.funcs,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
var pageModeView = ref.pageModeView;
return [_c('div',[(_vm.dataset.main.detail.id && context.showEstado)?_c('c-base-dialog-estado',{attrs:{"context":context,"datasetTemp":JSON.parse(JSON.stringify(context.dataset)),"params":_vm.paramsCamposEspecificos(),"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"show":context.showEstado,"value":JSON.parse(JSON.stringify(context.dataset))},on:{"alterado":function($event){context.showEstado = false},"close":function($event){context.showEstado = false}},scopedSlots:_vm._u([{key:"camposEspecificos",fn:function(ref){
var datasetTemp = ref.datasetTemp;
return [(
                context.dataset.main.detail.idEstado ==
                  _vm.getEstado('aberto').id &&
                datasetTemp.main.detail.idEstado == _vm.getEstado('analise').id
              )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"sincronizar colaboradores"},model:{value:(_vm.params.sincronizarColaboradores),callback:function ($$v) {_vm.$set(_vm.params, "sincronizarColaboradores", $$v)},expression:"params.sincronizarColaboradores"}})],1)],1):_vm._e(),(
                context.dataset.main.detail.idTipo ==
                  _vm.getTipo_item('fpNormal').id &&
                context.dataset.main.detail.idEstado ==
                  _vm.getEstado('aberto').id &&
                datasetTemp.main.detail.idEstado == _vm.getEstado('analise').id
              )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"recalcular vendas"},model:{value:(_vm.params.recalcularVendas),callback:function ($$v) {_vm.$set(_vm.params, "recalcularVendas", $$v)},expression:"params.recalcularVendas"}})],1)],1):_vm._e(),(
                context.dataset.main.detail.idEstado ==
                  _vm.getEstado('aberto').id &&
                datasetTemp.main.detail.idEstado == _vm.getEstado('analise').id
              )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"recalcular verbas alteradas"},model:{value:(_vm.params.recalcularVerbasAlteradas),callback:function ($$v) {_vm.$set(_vm.params, "recalcularVerbasAlteradas", $$v)},expression:"params.recalcularVerbasAlteradas"}})],1)],1):_vm._e()]}}],null,true)}):_vm._e(),(pageModeView)?_c('c-folhaPagamento-view',{attrs:{"context":context,"dataset":_vm.dataset}}):_c('c-folhaPagamento-edit',{attrs:{"context":context,"dataset":_vm.dataset}})],1)]}}],null,false,2879388152),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }