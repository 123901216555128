<!-- COD: FDS 05/07/2022 Feriados_FeriadosDoAno -->
<!-- *VER: JER 20/07/2022 Feriados_FeriadosDoAno -->
<!-- /22/ -->

<template>
  <div>
    <c-base-list
      v-if="idPage && idPageAlt"
      :funcs="funcs"
      :hasSearch="false"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :pageModeEdit="false"
    >
      <template #search="{ context }">
        <!-- ano -->
        <v-row v-bind="prop.row" justify="center">
          <v-col v-bind="prop.col" align="center" cols="5">
            <c-select
              v-model="ano"
              :items="anos"
              prepend-inner-icon="mdi-calendar-search"
              solo
              @change="changeAno(context)"
            />
          </v-col>
        </v-row>

        <!-- search -->
        <v-row v-bind="prop.row" class="mt-n2">
          <v-col v-bind="prop.col">
            <c-text-field
              v-model="search"
              appendOuterIcon="mdi-tune"
              :max="100"
              :noAccept="null"
              placeholder="busca"
              prependInnerIcon="mdi-magnify"
              solo
              type="search"
              @clickAppendOuter="showSearchOptions = !showSearchOptions"
              @keyup="filtroDatasets(context)"
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n7">
          <!-- data inicio -->
          <v-col v-bind="prop.col" cols="6">
            <c-datetime-picker
              v-model="searchData.dataInicio"
              classe="text-body-1 pr-1"
              date
              icon
              label="início"
              :maxValue="searchData.dataFim || `${ano}-12-31`"
              :minValue="`${ano}-01-01`"
              solo
              @change="filtroDatasets(context)"
              @input="$emit('searchData', searchData)"
            />
          </v-col>
          <!-- data fim -->
          <v-col v-bind="prop.col" cols="6">
            <c-datetime-picker
              v-model="searchData.dataFim"
              classe="text-body-1 pl-1"
              date
              icon
              label="fim"
              :maxValue="`${ano}-12-31`"
              :minValue="searchData.dataInicio || `${ano}-01-01`"
              solo
              @change="filtroDatasets(context)"
              @input="$emit('searchData', searchData)"
            />
          </v-col>
        </v-row>

        <!-- só feriados não facultativos -->
        <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n7">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="soFeriadosNaoFacultativos"
              label="só feriados não facultativos"
              @click="filtroDatasets(context)"
            />
          </v-col>
        </v-row>

        <!-- só em dias úteis -->
        <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n7">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="soEmDiasUteis"
              label="só em dias úteis"
              @click="filtroDatasets(context)"
            />
          </v-col>
        </v-row>

        <!-- só locais -->
        <v-row v-bind="prop.row" v-show="showSearchOptions" class="mt-n7">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="soLocais"
              label="só locais"
              @click="clickSearch(context)"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot="{ context, dataset, order }">
        <c-base-list-item
          :context="context"
          :dataset="dataset"
          :identification="false"
          :idFeature="idFeature"
          :idPage="idPage"
          :order="order"
          :pageModeView="true"
        >
          <c-feriados-page-feriadosDoAno :dataset="dataset" />
        </c-base-list-item>
      </template>
    </c-base-list>

    <div class="mt-3">
      <v-row
        v-bind="prop.row"
        v-for="(legenda, i) in legendasFiltered"
        v-show="filtroLegenda(legenda)"
        :key="i"
      >
        <!-- icone -->
        <v-col v-bind="prop.col" align="center" cols="1">
          <v-icon class="corRetirar--text" small>
            {{ legenda.icone }}
          </v-icon>
        </v-col>
        <!-- text -->
        <v-col v-bind="prop.col" cols="11">
          <span class="ml-1 text-body-2 py-1">
            {{ legenda.text }}
          </span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/// IMPORT
import { format, parseISO } from "date-fns";
import CFeriadosPageFeriadosDoAno from "@/components/pages/feriado/pages/CFeriadosPageFeriadosDoAno";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Feriados_FeriadosDoAno",

  /// COMPONENTS
  components: {
    CFeriadosPageFeriadosDoAno,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idFeature: getFeature("optionPageFeriadosDoAno").id,
      idPage: getPagina("feriados").id,
      idPageAlt: getPagina("feriado").id,

      funcs: {
        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          const params = {
            ano: this.ano,
            soFeriadosNaoFacultativos: this.soFeriadosNaoFacultativos,
            soEmDiasUteis: this.soEmDiasUteis,
            soLocais: this.soLocais,
            search: this.search,
          };

          if (this.soLocais) {
            Object.assign(params, {
              idCidade: this.idCidadeParceiro,
              idEstado: this.getLocalidade(this.idCidadeParceiro).idPai,
              idPais: this.idPaisParceiro,
            });
          } else {
            delete params.idCidade;
            delete params.idEstado;
            delete params.idPais;
          }

          this.searchData.dataInicio
            ? Object.assign(params, { dataInicio: this.searchData.dataInicio })
            : delete params.dataInicio;
          this.searchData.dataFim
            ? Object.assign(params, { dataFim: this.searchData.dataFim })
            : delete params.dataFim;

          return JSON.stringify(params);
        },

        posSubmit: (dataset, context) => {
          context.limit = 100; //? f- remover
          dataset.anexo_endereco =
            this.getLocalidade(dataset.idLocalidade)?.endereco ||
            this.getLocalidade(this.getLocalidade(dataset.idLocalidade)?.idPai)
              ?.endereco ||
            this.getLocalidade(
              this.getLocalidade(
                this.getLocalidade(dataset.idLocalidade)?.idPai
              )?.idPai
            )?.endereco;
          dataset.icon = dataset.idLocalidade ? null : "mdi-earth";
          dataset.localidade = null;
          dataset.nome =
            dataset.icone != " " && dataset.observacao
              ? dataset.observacao
              : dataset.nome;

          if (
            !dataset.icon &&
            !this.getLocalidade(dataset.idLocalidade)?.endereco
          ) {
            dataset.localidade =
              // this.getLocalidade(dataset.idLocalidade).codigo2 ||
              `${this.getLocalidade(dataset.idLocalidade).nome} ${
                this.getLocalidade(
                  this.getLocalidade(dataset.idLocalidade)?.idPai
                )?.codigo2
              }`;
          }

          this.datasetsFiltered.push(dataset);
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },

      ano: new Date().getFullYear(),
      datasetsFiltered: [],
      idCidadeParceiro: getVar("usuarios")[
        getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == getVar("idPessoaParceiro")
        )
      ].enderecos.find((endereco) => endereco.order == 1).idCidade,
      idPaisParceiro: getVar("usuarios")[
        getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == getVar("idPessoaParceiro")
        )
      ].enderecos.find((endereco) => endereco.order == 1).idPais,
      legendasFiltered: [
        {
          icone: "mdi-alpha-f-circle-outline",
          text: "feriado facultativo - não trabalhado",
          show: getVar("idUsuario") != getVar("idPessoaParceiro"),
        },
        {
          icone: "mdi-alpha-t-circle-outline",
          text: "feriado facultativo - trabalhado",
          show: getVar("idUsuario") != getVar("idPessoaParceiro"),
        },
        {
          icone: "mdi-alpha-c-circle-outline",
          text: "data comemorativa - trabalhada",
          show: true,
        },
      ],
      search: "",
      searchData: {
        dataInicio: null,
        dataFim: null,
      },
      showSearchOptions: false,
      soEmDiasUteis: false,
      soFeriadosNaoFacultativos: false,
      soLocais: true,
    };
  },

  /// COMPUTED
  computed: {
    anos() {
      const anos = [];
      const startYear = new Date().getFullYear() - 20;
      const endedYear = new Date().getFullYear() + 5;

      for (
        let currentYear = startYear;
        currentYear <= endedYear;
        currentYear = currentYear + 1
      ) {
        anos.push({
          value: currentYear,
          text: currentYear,
        });
      }

      return anos;
    },

    pageModeView() {
      return false;
    },
  },

  /// METHODS
  methods: {
    changeAno(context) {
      const dataInicio = this.searchData.dataInicio
        ? parseISO(this.searchData.dataInicio).setYear(this.ano)
        : null;

      const dataFim = this.searchData.dataFim
        ? parseISO(this.searchData.dataFim).setYear(this.ano)
        : null;

      this.searchData.dataInicio = dataInicio
        ? format(dataInicio, "yyyy-MM-dd")
        : null;

      this.searchData.dataFim = dataFim ? format(dataFim, "yyyy-MM-dd") : null;
      this.clickSearch(context);
    },

    clickSearch(context) {
      context.params = context.funcs.fnParams(context);
      context.clickSearch();
    },

    filtroDatasets(context) {
      context.datasets = JSON.parse(JSON.stringify(context.datasetsTemp));

      context.datasets = context.datasets.filter(
        (dataset) =>
          (this.soEmDiasUteis && dataset.isDiaUtil) || !this.soEmDiasUteis
      );

      context.datasets = context.datasets.filter(
        (dataset) =>
          (this.soFeriadosNaoFacultativos && dataset.isFeriadoNaoFacultativo) ||
          !this.soFeriadosNaoFacultativos
      );

      context.datasets = context.datasets.filter(
        (dataset) =>
          dataset.nome
            .normalize("NFD")
            .replace(/[^a-zA-Zs]/g, "")
            .toUpperCase()
            .indexOf(
              this.search
                .normalize("NFD")
                .replace(/[^a-zA-Zs]/g, "")
                .toUpperCase()
            ) >= 0
      );

      context.datasets = context.datasets.filter((dataset) => {
        return this.searchData.dataInicio && this.searchData.dataFim
          ? dataset.data >= this.searchData.dataInicio &&
              dataset.data <= this.searchData.dataFim
          : this.searchData.dataInicio && !this.searchData.dataFim
          ? dataset.data >= this.searchData.dataInicio
          : !this.searchData.dataInicio && this.searchData.dataFim
          ? dataset.data <= this.searchData.dataFim
          : true;
      });

      this.datasetsFiltered = context.datasets;
    },

    filtroLegenda(legenda) {
      return (
        legenda.show &&
        this.datasetsFiltered.some(
          (datasetFiltered) => datasetFiltered.icone == legenda.icone
        )
      );
    },
  },
};
</script>