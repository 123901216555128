<!-- COD: LYF 26/07/2022 CNotaFiscalDialogNotaFiscal -->
<!-- *VER: JER 08/08/2022 CNotaFiscalDialogNotaFiscal -->
<!-- /31/ -->

<template>
  <v-dialog v-model="value" max-width="380px" persistent scrollable>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="print()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text ref="printArea">
        <v-row v-bind="prop.row">
          <!-- logo -->
          <v-col v-bind="prop.col" cols="3">
            <img :src="dadosParceiro.imagem.endereco" width="100" />
          </v-col>
          <!-- titulo -->
          <v-col v-bind="prop.col" align="end" class="mb-n8" cols="9">
            <span class="font-weight-bold mr-n1 text-body-2">
              COMMERCIAL INVOICE
            </span>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" class="mt-n2" />

        <!-- emitente -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <p class="font-weight-medium mb-n1 text-body-1">
              {{ notaFiscal.aux.pessoaEmitente.nome.toUpperCase() }}
            </p>
            <p class="mb-n1 text-body-2">
              {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco1 }}
            </p>
            <p class="mb-n2 text-body-2">
              {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco2 }}
            </p>
            <p class="mb-n2 text-body-2">
              {{ notaFiscal.aux.pessoaEmitente.documentoFederal }}
              <span
                v-if="notaFiscal.aux.pessoaEmitente.documentoEstadual"
                class="mx-0 text-h6"
              >
                •
              </span>
              {{ notaFiscal.aux.pessoaEmitente.documentoEstadual }}
            </p>
            <p class="mb-n1 text-caption">
              {{ notaFiscal.aux.pessoaEmitente.telefone }}
            </p>
            <p class="font-weight-bold mb-n1 text-caption">
              {{ notaFiscal.aux.pessoaEmitente.email }}
            </p>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" class="mb-0" />

        <!-- nota fiscal/data -->
        <v-row v-bind="prop.row" class="mt-4 mb-n2">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">nota fiscal</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span class="text-body-1">
              {{ notaFiscalData }}
            </span>
          </v-col>
        </v-row>

        <!-- pedido/data entrada saida -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">pedido</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span class="text-body-1">
              {{ pedidoDataEntradaSaida }}
            </span>
          </v-col>
        </v-row>

        <!-- transacao -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">transação</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span class="text-body-1">
              {{ getOperacao(notaFiscal.main.detail.idOperacao).nomeAlt }}
            </span>
          </v-col>
        </v-row>

        <!-- vendedor -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">vendedor</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span class="text-body-1">
              {{ notaFiscal.main.detail.colaborador }}
            </span>
          </v-col>
        </v-row>

        <!-- seu pedido -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">customer PO</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span class="text-body-1">
              {{ notaFiscal.main.detail.seuPedido }}
            </span>
          </v-col>
        </v-row>

        <!-- terms -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">terms</span>
          </v-col>
          <v-col
            v-bind="prop.col"
            v-if="diferencaDias(notaFiscal) != null"
            cols="7"
          >
            <span class="text-body-1">
              {{ diferencaDias(notaFiscal) }}
            </span>
            <span class="ml-1 text-body-2">d</span>
          </v-col>
        </v-row>

        <!-- data vencimento -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">due date</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span v-if="notaFiscal.main.duplicatas.length" class="text-body-1">
              {{
                dateFormat(
                  notaFiscal.main.duplicatas[0].dataVencimento,
                  "date",
                  null,
                  true
                )
              }}
            </span>
          </v-col>
        </v-row>

        <!-- pessoa transportadora nome -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">freight forwader</span>
          </v-col>
          <v-col
            v-bind="prop.col"
            v-if="notaFiscal.aux.pessoaTransportadora"
            cols="7"
          >
            <span class="text-body-1">
              {{ notaFiscal.aux.pessoaTransportadora.nome }}
            </span>
          </v-col>
        </v-row>

        <!-- modal transporte -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">ship via</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span class="text-body-1">
              {{ notaFiscal.main.detail.modalTransporte }}
            </span>
          </v-col>
        </v-row>

        <!-- incoterm -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">incoterm</span>
          </v-col>
          <v-col v-bind="prop.col" cols="7">
            <span class="text-body-1">
              {{ notaFiscal.main.detail.incoterm }}
            </span>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" class="mb-0" />

        <!-- sold to (destinatario)-->
        <v-row v-bind="prop.row" class="mb-n0">
          <v-col v-bind="prop.col" align="center">
            <p class="mt-6 mb-n1 text-caption">SOLD TO</p>
            <p class="font-weight-medium mb-n1 text-body-1">
              {{ notaFiscal.aux.pessoaDestinatario.nome.toUpperCase() }}
            </p>
            <p class="mb-n1 text-body-2">
              {{
                enderecoFormatado(notaFiscal.aux.pessoaDestinatario).endereco1
              }}
            </p>
            <p class="mb-n2 text-body-2">
              {{
                enderecoFormatado(notaFiscal.aux.pessoaDestinatario).endereco2
              }}
            </p>
            <p class="mb-n2 text-body-2">
              {{ notaFiscal.aux.pessoaDestinatario.documentoFederal }}
              <span
                v-if="notaFiscal.aux.pessoaDestinatario.documentoEstadual"
                class="mx-0 text-h6"
              >
                •
              </span>
              {{ notaFiscal.aux.pessoaDestinatario.documentoEstadual }}
            </p>
            <p class="mb-n1 text-caption">
              {{ notaFiscal.aux.pessoaDestinatario.telefone }}
            </p>
            <p class="font-weight-bold mb-n1 text-caption">
              {{ notaFiscal.aux.pessoaDestinatario.email }}
            </p>
          </v-col>
        </v-row>
        <v-divider v-bind="prop.divider" class="mb-0" />

        <!-- ship to -->
        <v-row
          v-bind="prop.row"
          v-if="notaFiscal.aux.pessoaLocalEntrega"
          class="mb-n0"
        >
          <v-col v-bind="prop.col" align="center">
            <p class="mt-6 mb-n1 text-caption">SHIP TO</p>
            <p class="font-weight-medium mb-n1 text-body-1">
              {{ notaFiscal.aux.pessoaLocalEntrega.nome.toUpperCase() }}
            </p>
            <p class="mb-n1 text-body-2">
              {{
                enderecoFormatado(notaFiscal.aux.pessoaLocalEntrega).endereco1
              }}
            </p>
            <p class="mb-n2 text-body-2">
              {{
                enderecoFormatado(notaFiscal.aux.pessoaLocalEntrega).endereco2
              }}
            </p>
            <p class="mb-n2 text-body-2">
              {{ notaFiscal.aux.pessoaLocalEntrega.documentoFederal }}
              <span
                v-if="notaFiscal.aux.pessoaLocalEntrega.documentoEstadual"
                class="mx-0 text-h6"
              >
                •
              </span>
              {{ notaFiscal.aux.pessoaLocalEntrega.documentoEstadual }}
            </p>
            <p class="mb-n1 text-caption">
              {{ notaFiscal.aux.pessoaLocalEntrega.telefone }}
            </p>
            <p class="font-weight-bold mb-n1 text-caption">
              {{ notaFiscal.aux.pessoaLocalEntrega.email }}
            </p>
          </v-col>
        </v-row>

        <v-divider
          v-bind="prop.divider"
          v-if="notaFiscal.aux.pessoaLocalEntrega"
        />

        <!-- itens -->
        <v-row v-bind="prop.row" class="mb-n0">
          <v-col v-bind="prop.col" align="center">
            <span class="text-caption">ITENS</span>
          </v-col>
        </v-row>

        <div
          v-for="(item, i) in notaFiscal.main.itens.filter((item) => item.id)"
          class="mb-n2"
          :key="i"
        >
          <!-- produto -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <span class="text-caption">
                {{ produtoFormat(item) }}
              </span>
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" class="mt-n2 mb-4">
            <!-- quantidade -->
            <v-col v-bind="prop.col" cols="3">
              <span class="text-body-2">
                {{ numberFormat(item.quantidade, "decimal") }}
              </span>
              <span class="ml-1 text-body-2">
                {{ item.unidadeMedida }}
              </span>
            </v-col>
            <!-- valor unitario -->
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-doby-2">
                {{ numberFormat(item.valorUnitario, "currency") }}
              </span>
            </v-col>
            <!-- valor total -->
            <v-col v-bind="prop.col" align="end" cols="5">
              <span class="text-body-2">
                {{ numberFormat(item.valorTotal, "currency") }}
              </span>
            </v-col>
          </v-row>
        </div>

        <v-divider v-bind="prop.divider" class="mt-4" />

        <!-- total produtos -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">sub total</span>
          </v-col>
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-1">
              {{
                numberFormat(notaFiscal.main.detail.totalProdutos, "currency")
              }}
            </span>
          </v-col>
        </v-row>

        <!-- total impostos -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">impostos</span>
          </v-col>
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-1">
              {{
                numberFormat(notaFiscal.main.detail.totalImpostos, "currency")
              }}
            </span>
          </v-col>
        </v-row>

        <!-- valor frete -->
        <v-row v-bind="prop.row" class="my-n3">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="text-body-2">frete</span>
          </v-col>
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="text-body-1">
              {{ numberFormat(notaFiscal.main.detail.valorFrete, "currency") }}
            </span>
          </v-col>
        </v-row>

        <!-- valor nota -->
        <v-row v-bind="prop.row" class="mt-2">
          <v-col v-bind="prop.col" align="end" cols="5">
            <span class="font-weight-medium text-body-2">total</span>
          </v-col>
          <v-col v-bind="prop.col" align="end" cols="6">
            <span class="font-weight-medium text-body-1">
              {{ numberFormat(notaFiscal.main.detail.totalNota, "currency") }}
            </span>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" />

        <!-- bank account -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-caption">BANK ACCOUNT</span>
          </v-col>
        </v-row>

        <!-- pessoa emitente conta bancaria -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-1">
              {{ contaFormatado(notaFiscal.aux.pessoaEmitente.contaBancaria) }}
            </span>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" />

        <!-- qrCode -->
        <v-row v-bind="prop.row" class="mt-n6">
          <v-col v-bind="prop.col" align="center">
            <canvas v-show="false" id="canvas"></canvas>
            <img class="mt-5 mb-n3" :src="canvasUrl" />
          </v-col>
        </v-row>

        <!-- terms of service -->
        <v-row v-bind="prop.row" class="mt-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2 py-1">
              <a :href="linkTermos" target="_blank">Terms of Service</a>
            </span>
          </v-col>
        </v-row>

        <!-- pessoa parceiro email -->
        <v-row v-bind="prop.row" class="mt-2">
          <v-col v-bind="prop.col" align="center">
            <span class="font-weight-thin text-h4 py-1">
              <a
                :href="notaFiscal.aux.detail.pessoaParceiro_url"
                target="_blank"
              >
                {{ urlFormat }}
              </a>
            </span>
          </v-col>
        </v-row>

        <v-divider v-bind="prop.divider" />

        <v-row v-bind="prop.row" class="mb-6">
          <v-col v-bind="prop.col">
            <span class="text-body-2">
              Received merchandise in good condition:
            </span>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <!-- data -->
          <v-col v-bind="prop.col" cols="4">
            <p class="text-body-2">____/____/____</p>
            <p class="mt-n5 caption">date</p>
          </v-col>
          <!-- nome -->
          <v-col v-bind="prop.col" cols="8">
            <p class="text-body-2">______________________________</p>
            <p class="mt-n5 caption">name</p>
          </v-col>
        </v-row>

        <!-- signature -->
        <v-row v-bind="prop.row" class="mt-8">
          <v-col v-bind="prop.col">
            <p class="text-body-2">______________________________</p>
            <p class="mt-n5 caption">signature</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import qrCode from "qrcode";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CNotaFiscalDialogNotaFiscal",

  /// MIXINS
  mixins: [mixinData, mixinLib],

  /// PROPS
  props: {
    notaFiscal: Object,
    value: Boolean,
  },

  /// DATA
  data() {
    return {
      canvasUrl: null,
      linkTermos: null,
      printing: false,
      title: "NOTA FISCAL",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getOperacao: "operacoes/getOperacao",
      getVar: "variaveis/getVar",
    }),

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    notaFiscalData() {
      return `${this.notaFiscal.main.detail.notaFiscal}, ${this.dateFormat(
        this.notaFiscal.main.detail.data,
        "date",
        null,
        true
      )}`;
    },

    pedidoDataEntradaSaida() {
      return `${this.notaFiscal.main.detail.idPedido}, ${this.dateFormat(
        this.notaFiscal.main.detail.dataEntradaSaida,
        "date",
        null,
        true
      )}`;
    },

    urlFormat() {
      return this.notaFiscal.aux.detail.pessoaParceiro_url.replace(
        /^https?:\/\/www./,
        ""
      );
    },
  },

  /// METHODS
  methods: {
    contaFormatado(dataset) {
      return this.contaBancariaFormat(dataset, true);
    },

    diferencaDias(dataset) {
      return dataset.main.duplicatas.length
        ? this.differenceInDays(
            this.parseISO(dataset.main.duplicatas[0].dataVencimento),
            this.parseISO(dataset.main.detail.data)
          )
        : null;
    },

    enderecoFormatado(dataset) {
      const endereco = {
        endereco: dataset.endereco,
        numero: dataset.numero,
        complemento: dataset.complemento,
        bairro: dataset.bairro,
        cidade: dataset.cidade,
        idCidade: dataset.idCidade,
        idPais: dataset.idPais,
        codigoPostal: dataset.codigoPostal,
      };

      return this.pessoasEnderecoFormat(endereco);
    },

    print() {
      this.printing = true;
      setTimeout(() => {
        this.pagePrint("notaFiscal");
        setTimeout(() => {
          this.printing = false;
        }, 10);
      }, 10);
    },
  },

  /// MOUNTED
  mounted() {
    this.code = `${window.location.origin}/parceiro/${
      this.notaFiscal.aux.detail.parceiro_id
    }?idPanel=${this.getFeature("CParceiroPanelTermosServico").id}`;

    const canvas = document.querySelector("#canvas");

    qrCode.toCanvas(canvas, this.code);

    setTimeout(() => {
      this.canvasUrl = document.querySelector("#canvas").toDataURL();
      this.linkTermos = this.code;
    }, 100);

    canvas.style.width = "150px";
    canvas.style.height = "150px";
  },
};
</script>