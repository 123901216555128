<!-- COD: LYF 10/08/2022 Avaliacao -->
<!-- *VER: JER 10/08/2022 Avaliacao -->
<!-- /21/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
      <template v-slot="{ context }">
        <div>
          <c-avaliacao-view :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CAvaliacaoView from "@/components/pages/avaliacao/CAvaliacaoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Avaliacao",

  /// COMPONENTS
  components: {
    CAvaliacaoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeatures = this.$store.getters["paginas/getFeatures"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("avaliacao").id,
      idPageAlt: getPagina("pedidos").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idEstado: getEstado("recebido").id,
            isSuspenso: false,
            idTipo: getTipo_item("pedidoSaida").id,
            idPessoaParceiro: null,
            idPessoa: null,
            itens: 0,
            valorTotal: 0,
          },
          avaliacoes: [
            {
              id: null,
              idPai: null,
              idAvaliador: null,
              idAvaliado: null,
              avaliacao: 0,
              comentario: null,
              dataAvaliacao: new Date(),
              resposta: null,
              dataResposta: null,
              pedidoModeracao: null,
              dataPedidoModeracao: null,
              isPublicar: false,
              idModerador: null,
              dataModeracao: null,
            },
            {
              id: null,
              idPai: null,
              idAvaliador: null,
              idAvaliado: null,
              avaliacao: null,
              comentario: null,
              dataAvaliacao: new Date(),
              resposta: null,
              dataResposta: null,
              pedidoModeracao: null,
              dataPedidoModeracao: null,
              isPublicar: true,
              idModerador: null,
              dataModeracao: null,
            },
          ],
          avaliacoes_detalhes: [[], []],
        },
        aux: {
          detail: {
            pessoaParceiro_nomeAlt: null,
            pessoaParceiro_mediaAvaliacoes: null,
            pessoaParceiro_numeroAvaliacoes: null,
            pessoaModerador_nomeAlt: null,
            pessoa_nome: null,
            pessoa_mediaAvaliacoes: null,
            numeroAvaliacoes: null,
          },
        },
      },

      funcs: {
        changed: (context) => {
          let ret = false;

          // avaliacao
          if (context.datasetTemp) {
            // avaliacoes
            if (!ret) {
              context.dataset.main.avaliacoes.forEach((item, index) => {
                Object.keys(item).forEach((campo) => {
                  if (
                    item[campo] !=
                      context.datasetTemp.main.avaliacoes[index][campo] &&
                    campo != "isPublicar"
                  ) {
                    ret = true;
                  }
                });
              });
            }

            // avaliacoes_detalhes
            if (!ret) {
              context.dataset.main.avaliacoes_detalhes.forEach(
                (item, index) => {
                  item.forEach((item2, index2) => {
                    Object.keys(item2).forEach((campo) => {
                      if (
                        item2[campo] !=
                        context.datasetTemp.main.avaliacoes_detalhes[index][
                          index2
                        ][campo]
                      ) {
                        ret = true;
                      }
                    });
                  });
                }
              );
            }
          }

          return ret;
        },

        fnParams: () => {
          return JSON.stringify({
            idPessoa: this.getVar("idPessoaParceiro"),
          });
        },

        moderar: (context) => {
          context.dataset.main.avaliacoes[0].idModerador =
            context.getVar("idUsuario");
          context.clickSave();
        },

        posSubmit: (context) => {
          context.datasetTemp = JSON.parse(JSON.stringify(context.dataset));

          context.params.clickSave = context.clickSave;

          if (
            !context.dataset.main.avaliacoes[0].dataPedidoModeracao ||
            context.dataset.main.avaliacoes[0].dataModeracao
          ) {
            const index = context.buttons.findIndex(
              (button) => button.id == getFeatures("optionModerar").id
            );

            if (index >= 0) {
              context.buttons.splice(index, 1);
            }
          }

          if (
            context.dataset.main.detail.idPessoa ==
            context.getVar("idPessoaParceiro")
          ) {
            const avaliacao = context.dataset.main.avaliacoes.find(
              (avaliacao) =>
                avaliacao.idAvaliador == context.dataset.main.detail.idPessoa
            );

            if (avaliacao && avaliacao.id && !avaliacao.isPublicar) {
              const dataAvaliacao = this.dataFormata(
                avaliacao.dataAvaliacao,
                "yyyy-MM-dd",
                true
              );
              const hoje = this.dataFormata(
                new Date().toISOString(),
                "yyyy-MM-dd",
                true
              );

              if (dataAvaliacao == hoje) {
                context.setSnackbar(
                  true,
                  "AVALIACAO",
                  "Avaliação poderá ser publicada em 7 dias."
                );
              }
            }
          }
        },
      },
      params: {
        context: null,
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getVar: "variaveis/getVar",
    }),
  },
};
</script>