<!-- COD: LYF 05/07/2022 ContaPagRec -->
<!-- *VER: JER 20/07/2022 ContaPagRec -->
<!-- /22/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-contaPagRec-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-contaPagRec-edit v-else :context="context" :dataset="dataset" />
        </div>

        <c-contaPagRec-dialog-carta-anuencia
          v-if="dataset.main.detail.id && showRelatorioCartaAnuencia"
          v-model="showRelatorioCartaAnuencia"
          :contaPagRec="dataset"
        />

        <c-contaPagRec-dialog-notaDebito
          v-if="dataset.main.detail.id && showRelatorioNotaDebito"
          v-model="showRelatorioNotaDebito"
          :contaPagRec="dataset"
        />
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CContaPagRecDialogCartaAnuencia from "@/components/pages/contaPagRec/dialogs/CContaPagRecDialogCartaAnuencia.vue";
import CContaPagRecDialogNotaDebito from "@/components/pages/contaPagRec/dialogs/CContaPagRecDialogNotaDebito.vue";
import CContaPagRecEdit from "@/components/pages/contaPagRec/CContaPagRecEdit";
import CContaPagRecView from "@/components/pages/contaPagRec/CContaPagRecView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "ContaPagRec",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CContaPagRecDialogCartaAnuencia,
    CContaPagRecDialogNotaDebito,
    CContaPagRecEdit,
    CContaPagRecView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("contaPagRec").id,
      idPageAlt: getPagina("contasPagRec").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("contaRec").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idPessoaParceiroContaBancaria: null,
            idPessoa: null,
            isImporte: null,
            idHistoricoPadrao: null,
            idImposto: null,
            idPedido: null,
            idFolhaPagamento: null,
            duplicata: null,
            dataEmissao: null,
            idFormaPagamento: null,
            prazo: null,
            dataVencimento: null,
            valor: null,
            saldo: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          ocorrencias: [],
          valoresDepositados: [],
        },
        aux: {
          detail: {
            parceiro: {
              nome: null,
              nomeAlt: null,
              idTipo: null,
              documentos: [],
              contaBancaria: {
                codigo: null,
                agencia: null,
                agenciaDV: null,
                conta: null,
                contaDV: null,
              },
              endereco: {
                idPais: null,
                codigoPostal: null,
                idCidade: null,
                bairro: null,
                endereco: null,
                numero: null,
                complemento: null,
              },
            },
            pessoa: {
              nome: null,
              idTipo: null,
              documentos: [],
              endereco: {
                idPais: null,
                codigoPostal: null,
                idCidade: null,
                bairro: null,
                endereco: null,
                numero: null,
                complemento: null,
              },
            },
          },
          contaBancaria: {
            banco: null,
            agencia: null,
            conta: null,
          },
          ocorrencias: [],
          valoresDepositados: [],
          colaboradoresFinancas: [],
          autocomplete: {
            pessoas: [],
            historicoPadrao: [],
          },
        },
      },

      funcs: {
        clickBaixaCruzada: (context) => {
          const tipo = context.tipos.find(
            (tipo) => tipo.value != context.dataset.main.detail.idTipo
          );
          context.pageAlt.idContexto = tipo.value;

          this.$router.push(
            `/contaPagRec/baixaCruzada/${this.dataset.main.detail.id}`
          );
        },

        clickPedido: () => {
          this.$router.push(`/pedido/${this.dataset.main.detail.idPedido}`);
        },

        clickRelatorioCartaAnuencia: async () => {
          this.showRelatorioCartaAnuencia = true;
        },

        clickRelatorioNotaDebito: async () => {
          this.showRelatorioNotaDebito = true;
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posSubmit: (context) => {
          if (context.dataset.metadados.mensagemErro) {
            context.setSnackbar(
              true,
              "PROVSÃO",
              context.dataset.metadados.mensagemErro,
              "warning"
            );
          }
        },
      },

      params: {
        context: null,
      },

      showRelatorioCartaAnuencia: false,
      showRelatorioNotaDebito: false,
    };
  },

  /// COMPUTED
  computed: {
    enderecoParceiro() {
      return this.parceiro?.enderecos?.find((_, i) => i == 0);
    },

    parceiro() {
      return this.getVar("usuarios").find(
        (usuario) =>
          usuario.pessoa.id == this.dataset.main.detail.idPessoaParceiro
      );
    },
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>