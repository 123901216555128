<!-- COD: LYF 04/08/2022 CComentarioItem -->
<!-- *VER: JER 10/08/2022 CComentarioItem -->
<!-- /11/ -->

<template>
  <!-- /// LIST -->
  <v-list-item-content @click.stop="clickList">
    <v-row v-bind="prop.row">
      <!-- col -->
      <v-col v-bind="prop.col" v-if="comentario.idComentario" cols="1"> </v-col>
      <!-- usuario -->
      <v-row v-bind="prop.row" class="py-1 grey lighten-2">
        <v-col v-bind="prop.col" cols="12">
          <span class="font-weight-medium text-body-1">
            {{ comentario.usuario }}
          </span>
        </v-col>
        <!-- data -->
        <v-col v-bind="prop.col" cols="12">
          <span class="text-caption">
            {{ dataFormataRelativa(comentario.data, null) }}
          </span>
        </v-col>
      </v-row>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- col -->
      <v-col v-bind="prop.col" v-if="comentario.idComentario" cols="1"> </v-col>
      <!-- comentario -->
      <v-col v-bind="prop.col" :cols="comentario.idComentario ? 9 : 10">
        <span class="text-body-1">
          {{ comentario.comentario }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- col -->
      <v-col v-bind="prop.col" v-if="comentario.idComentario" cols="1"> </v-col>
      <!-- curtiu -->
      <v-col v-bind="prop.col" cols="3">
        <c-btn class="ml-n2" icon @click.stop="$emit('curtir', comentario)">
          <v-icon :class="comentario.isCurtiu ? 'red--text' : 'grey--text'">
            {{ comentario.isCurtiu ? "mdi-heart" : "mdi-heart-outline" }}
          </v-icon>
        </c-btn>
        <span class="text-overline font-weight-light">
          {{ comentario.curtidas }}
        </span>
      </v-col>
      <!-- comentou -->
      <v-col v-bind="prop.col" cols="3">
        <c-btn
          class="ml-n2"
          icon
          @click.stop="$emit('clickComentario', comentario.id)"
        >
          <v-icon :class="comentario.comentou ? 'primary--text' : 'grey--text'">
            {{ comentario.comentou ? "mdi-comment" : "mdi-comment-outline" }}
          </v-icon>
        </c-btn>
        <span class="text-overline font-weight-light">
          {{ comentario.comentadas }}
        </span>
      </v-col>
      <!-- trash -->
      <v-col
        v-bind="prop.col"
        v-if="deletar && permiteDeletar"
        :cols="comentario.idComentario ? 5 : 6"
      >
        <c-btn icon @click.stop="$emit('delete', comentario)">
          <v-icon color="red">mdi-trash-can-outline</v-icon>
        </c-btn>
      </v-col>
    </v-row>
    <slot></slot>
  </v-list-item-content>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CComentarioItem",

  // MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    comentario: Object,
    permiteDeletar: Boolean,
  },

  /// DATA
  data() {
    return {
      deletar: false,
    };
  },

  /// METHODS
  methods: {
    clickList() {
      this.deletar = !this.deletar;
    },
  },
};
</script>