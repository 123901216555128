var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-menu',{attrs:{"classe":"ml-n4","items":_vm.paises},scopedSlots:_vm._u([{key:"btn",fn:function(ref){
var itemSelected = ref.itemSelected;
return [(_vm.dataset.main.detail.idPais)?_c('v-img',{staticClass:"mr-2",attrs:{"alt":"imagem","contain":"","src":_vm.getLocalidade(_vm.dataset.main.detail.idPais).endereco,"width":"25"}}):_c('v-icon',{attrs:{"large":"","color":"grey"}},[_vm._v("mdi-earth")]),(itemSelected)?_c('span',{staticClass:"text=body-2"},[_vm._v(" "+_vm._s(itemSelected.text)+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"alt":"imagem","contain":"","src":_vm.getLocalidade(item.value).endereco,"width":"25"}}),_c('v-list-item-title',{staticClass:"ml-2 text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.dataset.main.detail.idPais),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idPais", $$v)},expression:"dataset.main.detail.idPais"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nome","max":40,"required":""},model:{value:(_vm.dataset.main.detail.nome),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "nome", $$v)},expression:"dataset.main.detail.nome"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nomeAlt","max":25,"required":""},model:{value:(_vm.dataset.main.detail.nomeAlt),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "nomeAlt", $$v)},expression:"dataset.main.detail.nomeAlt"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
            title: 'SWIFT',
            text: "Society for Worldwide Interbank Financial Telecommunication\nBIC (Bank Identifier Code)\n\nAAAABBCCDDD\nA: bank\nB: country\nC: location (*)\nD: branch (*) (optional)\n\n(*) Campo informado na conta bancária da pessoa",
            function: _vm.context.setUiBottomSheet,
          },"label":"swift (prefixo)","max":6},model:{value:(_vm.dataset.main.detail.swiftPrefixo),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "swiftPrefixo", $$v)},expression:"dataset.main.detail.swiftPrefixo"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"número banco","max":3,"required":""},model:{value:(_vm.dataset.main.detail.codigo),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "codigo", $$v)},expression:"dataset.main.detail.codigo"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }