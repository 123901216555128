// COD: BJB 15/08/2022 store/index
// *VER: JER 19/08/2022 store/index
// /11/

/// IMPORT
import Vue from "vue";
import Vuex from "vuex";

import bancos from "./modules/bancos.js";
import cartoes from "./modules/cartoes.js";
import categorias from "./modules/categorias.js";
import constantes from "./modules/constantes.js";
import contasContabeis from "./modules/contasContabeis.js";
import dicionarios from "./modules/dicionarios.js";
import estados from "./modules/estados.js";
import feriados from "./modules/feriados.js";
import historicosPadrao from "./modules/historicosPadrao.js";
import impostos from "./modules/impostos.js";
import legislacao_itensAtualizados from "./modules/legislacao_itensAtualizados.js";
import localidades from "./modules/localidades.js";
import operacoes from "./modules/operacoes.js";
import paginas from "./modules/paginas.js";
import tabelas from "./modules/tabelas.js";
import tipos from "./modules/tipos.js";
import variaveis from "./modules/variaveis.js";
import verbas from "./modules/verbas.js";


/// VUEX
Vue.use(Vuex);
const store = new Vuex.Store({
  // export default new Vuex.Store({
  // STRICT deve ser FALSE em ambiente de producao.
  // video Shaun Pelling VUEX video 6, 08:00.
  strict: false,

  modules: {
    bancos,
    cartoes,
    categorias,
    constantes,
    contasContabeis,
    dicionarios,
    estados,
    feriados,
    historicosPadrao,
    impostos,
    legislacao_itensAtualizados,
    localidades,
    operacoes,
    paginas,
    tabelas,
    tipos,
    variaveis,
    verbas,
  },
});

export default store;