<!-- COD: LYF 05/08/2022 Notificacoes -->
<!-- *VER: JER 10/08/2022 Notificacoes -->
<!-- /32/ -->

<template>
  <div
    v-touch="{ down: ($event) => swipe('down', swipeRefresh, $event) }"
    class="notificacoes"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" :title="page.nomeAlt">
      <template #icon>
        <c-btn icon router to="/">
          <v-icon class="ml-2" large>
            {{ icon }}
          </v-icon>
        </c-btn>
      </template>
    </c-the-navbar>

    <!-- //? Onde posicionar o snacbar? -->
    <!-- //?b Por que foi declarado dois snacbar? -->
    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
    <!-- /// SNACKBAR -->
    <c-snackbar @click="clickSnackbar($event)" :snackbar="snackbar" />

    <v-container
      v-if="
        getVar('pendencias').transacoes.priority != 1 &&
        getVar('pendencias').pedidos.priority != 1 &&
        !tiposNotificacoes.length
      "
    >
      <!-- label -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center" class="mt-10">
          <span class="text-overline">Não há notificações no momento</span>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-container>
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="end">
            <v-btn
              v-if="panelsOpen.length"
              class="mr-n1"
              icon
              @click="clickPanelsDouble"
            >
              <v-icon>
                {{ chevronDoubleIcon }}
              </v-icon>
            </v-btn>
            <v-btn icon @click="clickPanels" class="mr-n3">
              <v-icon>
                {{ !panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up" }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-expansion-panels v-model="panelsOpen" accordion flat multiple>
        <!-- /// TRANSACOES -->
        <v-expansion-panel
          v-if="getVar('pendencias').transacoes.priority == 1"
          id="transacoes"
        >
          <v-expansion-panel-header class="grey lighten-4 px-0 pl-2">
            <v-container v-bind="prop.container">
              <v-row v-bind="prop.row">
                <!-- transacoes -->
                <v-col v-bind="prop.col" class="mb-n3" cols="10">
                  <span class="text-body-1">
                    <v-icon class="mr-2">mdi-currency-usd</v-icon>
                  </span>
                  <span class="text-body-1"> transações </span>
                </v-col>
                <!-- quantidade -->
                <v-col v-bind="prop.col" align="end" class="mt-n3" cols="2">
                  <v-badge
                    class="mr-6"
                    color="corDisabled white--text"
                    :content="getVar('pendencias').transacoes.value"
                    overlap
                  ></v-badge>
                </v-col>
              </v-row>

              <!-- transacoes valor total -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="end">
                  <span
                    class="text-body-1"
                    :class="
                      transacoesValorSaldoTotal.clean(2) < 0 ? 'red--text' : ''
                    "
                  >
                    {{ numberFormat(transacoesValorSaldoTotal, "currency") }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- arrow arrow -->
            <v-row v-bind="prop.row" class="mt-3">
              <v-col v-bind="prop.col" align="center">
                <c-btn
                  class="mr-4"
                  icon
                  @click="
                    $router.push(
                      `/transacoes/${
                        getFeature('filterPagamentosPendentes').id
                      }`
                    )
                  "
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </c-btn>
              </v-col>
            </v-row>
            <c-expansion-panels
              v-if="transacoesTipos.length"
              v-model="transacoesTipos"
              flat
              :params="params"
              :panel="getPanel('transacoes')"
            >
              <template #header="{ dataset: transacaoTipo }">
                <v-container
                  v-for="(transacao, y) in transacoes.filter(
                    (transacao) => transacao.idTipo == transacaoTipo
                  )"
                  :key="`transacao${y}`"
                >
                  <v-row v-bind="prop.row">
                    <!-- icone -->
                    <v-col v-bind="prop.col" cols="1">
                      <v-icon color="red" x-small>mdi-circle</v-icon>
                    </v-col>
                    <!-- parceiro -->
                    <v-col v-bind="prop.col" cols="9">
                      <span class="text-body-1">
                        {{ transacao.pessoaParceiro_nomeAlt }}
                      </span>
                    </v-col>
                    <!-- data -->
                    <v-col v-bind="prop.col" align="end" cols="2">
                      <span class="text-body-2">
                        {{ dataFormataRelativa(transacao.data, null) }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template #content="{ dataset: transacaoTipo }">
                <v-container
                  v-for="(transacao, y) in transacoes.filter(
                    (transacao) => transacao.idTipo == transacaoTipo
                  )"
                  :key="`transacao${y}`"
                >
                  <v-row v-bind="prop.row">
                    <!-- data vencimento -->
                    <v-col v-bind="prop.col" cols="6">
                      <span class="text-body-1">
                        {{
                          dataFormata(transacao.dataVencimento, "date", true)
                        }}
                      </span>
                    </v-col>
                    <!-- valor saldo -->
                    <v-col v-bind="prop.col" align="end" cols="6">
                      <span
                        class="text-body-1"
                        :class="
                          transacao.valorSaldo.clean(2) < 0 ? 'red--text' : ''
                        "
                      >
                        {{ numberFormat(transacao.valorSaldo, "currency") }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" cols="11">
                      <!-- tipo -->
                      <v-chip
                        class="text-overline white--text"
                        :class="getTipo_item(transacaoTipo).nome"
                        x-small
                      >
                        {{ getTipo_item(transacaoTipo).nome }}
                      </v-chip>
                      <!-- estado -->
                      <v-chip
                        class="ml-n3 text-overline white--text"
                        :class="getEstado(transacao.idEstado).nome"
                        x-small
                      >
                        {{ getEstado(transacao.idEstado).nome }}
                      </v-chip>
                    </v-col>
                    <!-- id -->
                    <v-col v-bind="prop.col" align="end" cols="1">
                      <a
                        class="text-body-1"
                        @click="clickTransacao(transacao.id)"
                      >
                        #
                        {{ transacao.id }}
                      </a>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </c-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- /// PEDIDOS -->
        <v-expansion-panel
          v-if="getVar('pendencias').pedidos.priority == 1"
          id="pedidos"
        >
          <v-expansion-panel-header class="grey lighten-4 px-0 pl-2">
            <v-container v-bind="prop.container">
              <v-row v-bind="prop.row">
                <!-- notificacao -->
                <v-col v-bind="prop.col" class="mb-n3" cols="10">
                  <span class="text-body-1">
                    <v-icon class="mr-2">mdi-clipboard-list-outline</v-icon>
                  </span>
                  <span class="text-body-1"> pedidos </span>
                </v-col>
                <!-- quantidade -->
                <v-col v-bind="prop.col" align="end" class="mt-n3" cols="2">
                  <v-badge
                    class="mr-6"
                    color="corDisabled white--text"
                    :content="getVar('pendencias').pedidos.value"
                    overlap
                  ></v-badge>
                </v-col>
              </v-row>

              <!-- pedidos valor total -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col" align="end">
                  <span class="text-body-1">
                    {{ numberFormat(pedidosValorTotal, "currency") }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- arrow -->
            <v-row v-bind="prop.row" class="mt-3">
              <v-col v-bind="prop.col" align="center">
                <c-btn
                  icon
                  @click="
                    $router.push(
                      `/pedidos/${getFeature('filterMinhasPendencias').id}`
                    )
                  "
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </c-btn>
              </v-col>
            </v-row>
            <c-expansion-panels
              v-if="pedidosEstados.length"
              v-model="pedidosEstados"
              flat
              :params="params"
              :panel="getPanel('pedidos')"
            >
              <template #header="{ dataset: pedidosEstado }">
                <v-row v-bind="prop.row">
                  <!-- estado -->
                  <v-col v-bind="prop.col" cols="10">
                    <span class="text-body-2">
                      {{ getEstado(pedidosEstado).nome }}
                    </span>
                  </v-col>
                  <!-- quantidade -->
                  <v-col v-bind="prop.col" align="end" class="mt-n3" cols="2">
                    <v-badge
                      class="mr-6"
                      :content="pedidosFiltrados(pedidosEstado).length"
                      overlap
                      color="corDisabled white--text"
                    ></v-badge>
                  </v-col>
                </v-row>

                <!-- pedidos filtrados valor total -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col" align="end">
                    <span class="text-body-1">
                      {{
                        numberFormat(
                          pedidosFiltradosValorTotal(
                            pedidosFiltrados(pedidosEstado)
                          ),
                          "currency"
                        )
                      }}
                    </span>
                  </v-col>
                </v-row>
              </template>
              <template #content="{ dataset: pedidosEstado }">
                <v-container
                  v-for="(pedido, y) in pedidosFiltrados(pedidosEstado)"
                  :key="`pedido${y}`"
                >
                  <v-divider v-bind="prop.divider" v-if="y != 0" />

                  <v-row v-bind="prop.row">
                    <!-- icone -->
                    <v-col v-bind="prop.col" cols="1">
                      <v-icon color="red" x-small>mdi-circle</v-icon>
                    </v-col>
                    <!-- parceiro -->
                    <v-col v-bind="prop.col" cols="9">
                      <span class="text-body-1">
                        {{
                          pedido.idCliente != getVar("idPessoaParceiro")
                            ? pedido.cliente
                            : pedido.parceiro
                        }}
                      </span>
                    </v-col>
                    <!-- data -->
                    <v-col v-bind="prop.col" align="end" cols="2">
                      <span class="text-body-2">
                        {{ dataFormataRelativa(pedido.data, null) }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row v-bind="prop.row" v-if="pedido.dataPrevisaoEntrega">
                    <!-- col -->
                    <v-col v-bind="prop.col" cols="1"> </v-col>
                    <!-- data previsao entrega -->
                    <v-col v-bind="prop.col" cols="11">
                      <span
                        :class="
                          new Date().toISOString() > pedido.dataPrevisaoEntrega
                            ? ' red--text'
                            : ''
                        "
                      >
                        <span class="text-body-2">previsão entrega</span>

                        <span class="font-weight-medium text-body-1">
                          {{
                            dataFormata(
                              pedido.dataPrevisaoEntrega,
                              "dateNoYear timeNoSecond",
                              true
                            )
                          }}
                        </span>
                      </span>
                    </v-col>
                  </v-row>

                  <v-row v-bind="prop.row">
                    <!-- col -->
                    <v-col v-bind="prop.col" cols="1"> </v-col>
                    <!-- itens -->
                    <v-col v-bind="prop.col" cols="5">
                      <span class="text-body-1">
                        {{ pedido.itens }}
                        {{ pedido.itens > 1 ? "itens" : "item" }}
                      </span>
                    </v-col>
                    <!-- valor total -->
                    <v-col v-bind="prop.col" cols="5">
                      <span class="text-body-1">
                        {{ numberFormat(pedido.valorTotal, "currency") }}
                      </span>
                    </v-col>
                    <!-- id -->
                    <v-col v-bind="prop.col" cols="1">
                      <a class="text-body-1" @click="clickPedido(pedido.id)">
                        #
                        {{ pedido.id }}
                      </a>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </c-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- /// CHATS - implementar -->

        <!-- /// NOTIFICAÇÕES -->
        <v-expansion-panel
          v-for="(tipoNotificao, i) in tiposNotificacoes"
          :key="`3.${i}`"
        >
          <v-expansion-panel-header class="grey lighten-4 px-0 pl-2">
            <v-row v-bind="prop.row">
              <!-- notificacao -->
              <v-col v-bind="prop.col" cols="10">
                <span class="text-body-1">
                  <v-icon class="mr-2">
                    {{ tipoNotificao.icone }}
                  </v-icon>
                </span>
                <span class="text-body-1">
                  {{ tipoNotificao.titulo }}
                </span>
              </v-col>
              <!-- quantidade -->
              <v-col v-bind="prop.col" align="end" class="mt-n3" cols="2">
                <v-badge
                  class="mr-6"
                  :color="`${
                    notificacoesQuantidade[tipoNotificao.id - 1].cor
                  } white--text`"
                  :content="notificacoesQuantidade[tipoNotificao.id - 1].value"
                  overlap
                ></v-badge>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- ler/limpar todas -->
            <v-row v-bind="prop.row" class="mt-3">
              <v-col v-bind="prop.col" align="center">
                <a
                  class="text-body-1"
                  @click.stop="notificacaoAcao(tipoNotificao.id)"
                >
                  {{ tipoNotificao.texto }}
                </a>
              </v-col>
            </v-row>
            <c-expansion-panels
              v-if="tipoNotificao.notificacoes.length"
              v-model="tipoNotificao.notificacoes"
              flat
              :params="params"
              :panel="getPanel('notificacoes')"
            >
              <template #header="{ dataset: notificacao }">
                <v-row v-bind="prop.row">
                  <!-- icone -->
                  <v-col v-bind="prop.col" cols="1">
                    <v-icon
                      :color="
                        notificacao.isDestaque || notificacao.requireConfirmacao
                          ? 'red'
                          : 'blue'
                      "
                      x-small
                    >
                      {{
                        notificacao.dataLeitura
                          ? "mdi-circle-outline"
                          : "mdi-circle"
                      }}
                    </v-icon>
                  </v-col>
                  <!-- parceiro -->
                  <v-col v-bind="prop.col" cols="9">
                    <span class="text-body-2">
                      {{ notificacao.parceiro }}
                    </span>
                  </v-col>
                  <!-- data -->
                  <v-col v-bind="prop.col" align="end" cols="2">
                    <span class="text-body-2">
                      {{ dataFormataRelativa(notificacao.data, null) }}
                    </span>
                  </v-col>
                </v-row>

                <v-row v-bind="prop.row">
                  <!-- col -->
                  <v-col v-bind="prop.col" cols="1"> </v-col>
                  <!-- titulo -->
                  <v-col v-bind="prop.col" cols="11">
                    <span class="text-body-2">
                      {{ notificacao.titulo }}
                    </span>
                  </v-col>
                </v-row>
              </template>
              <template #content="{ dataset: notificacao }">
                <!-- descricao -->
                <v-row v-bind="prop.row">
                  <v-col v-bind="prop.col">
                    <span class="text-body-2">
                      {{ notificacao.descricao }}
                    </span>
                  </v-col>
                </v-row>

                <!-- id registro -->
                <v-row v-bind="prop.row" v-if="notificacao.idRegistro">
                  <v-col v-bind="prop.col" align="end">
                    <a
                      class="text-body-1"
                      @click="usuarioAtualizar(notificacao, null)"
                    >
                      #
                      {{ notificacao.idRegistro }}
                    </a>
                  </v-col>
                </v-row>

                <v-row v-bind="prop.row" v-if="notificacao.requireConfirmacao">
                  <!-- check -->
                  <v-col v-bind="prop.col" align="end" cols="6">
                    <c-btn
                      class="mr-2"
                      :disabled="notificacao.isConfirmado == false"
                      icon
                      @click.stop="
                        notificacao.isConfirmado != null ||
                          usuarioAtualizar(notificacao, true)
                      "
                    >
                      <v-icon class="green--text">mdi-check</v-icon>
                    </c-btn>
                  </v-col>
                  <!-- close -->
                  <v-col v-bind="prop.col" cols="6">
                    <c-btn
                      class="ml-2"
                      :disabled="notificacao.isConfirmado"
                      icon
                      @click.stop="
                        notificacao.isConfirmado != null ||
                          usuarioAtualizar(notificacao, false)
                      "
                    >
                      <v-icon class="red--text">mdi-close</v-icon>
                    </c-btn>
                  </v-col>
                </v-row>
              </template>
            </c-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <c-the-navbar bottom mode="page" title="">
      <template v-for="(button, i) in buttons" v-slot:[i]>
        <c-btn
          v-for="(button, y) in buttons"
          icon
          :key="`4.${i}.${y}`"
          @click="clickOption(button)"
        >
          <v-icon large>
            {{ button.options.icon }}
          </v-icon>
        </c-btn>
        <v-menu :key="`5.${i}`" offset-y top>
          <template #activator="{ on }">
            <c-btn v-on="on" icon>
              <v-icon large>mdi-dots-horizontal</v-icon>
            </c-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, y) in options"
              :key="`5.${i}.${y}`"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn icon @click="panels = panels.length ? [] : [0, 1, 2]">
        <v-icon>
          {{
            !panels.length ? "mdi-chevron-down" : "mdi-chevron-up"
          }}
        </v-icon>
      </v-btn> -->
      </template>
    </c-the-navbar>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "Notificacoes",

  /// COMPONENTS
  components: { CTheNavbar },

  // MIXINS
  mixins: [mixinData, mixinLib, mixinSnackbar, mixinSwipe, mixinUsuarios],

  /// HEAD
  head: {
    title() {
      return {
        inner: this.getPagina("notificacoes").nomeAlt,
      };
    },
  },

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("notificacoes").id,
      idPageAlt: getPagina("notificacao").id,

      limit: 100,
      notificacoes: [],
      notificacoesFiltered: null,
      panels: [
        { nome: "transacoes", nomeAlt: "transacoes" },
        { nome: "pedidos", nomeAlt: "pedidos" },
        { nome: "notificacoes", nomeAlt: "notificacoes" },
      ],
      panelsOpen: [0, 1, 2],
      params: {
        clickPanelsOpen: null,
        panels: [
          {
            nome: "transacoes",
            specificPanelsOpen: [],
          },
          {
            nome: "pedidos",
            specificPanelsOpen: [],
          },
          {
            nome: "notificacoes",
            specificPanelsOpen: [],
          },
        ],
      },
      pedidos: [],
      pedidosFiltered: null,
      transacoes: [],
      transacoesFiltered: null,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getEstado: "estados/getEstado",
      getPagina: "paginas/getPagina",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true,
        null
      ).sortKeys('"order"');
    },

    chevronDoubleIcon() {
      return !this.subPanelsOpen
        ? "mdi-chevron-double-down"
        : "mdi-chevron-double-up";
    },

    icon() {
      return this.getFeature(this.page.idFeature).options?.icon;
    },

    notificacoesLidas() {
      return this.notificacoes.filter((notificacao) => notificacao.dataLeitura);
    },

    notificacoesNaoLidas() {
      return this.notificacoes.filter(
        (notificacao) => !notificacao.dataLeitura
      );
    },

    notificacoesQuantidade() {
      const retorno = [];

      const tipo = {
        cor: null,
        value: null,
      };

      let quantidade = 0;

      // não lidas
      quantidade = this.notificacoesNaoLidas.filter(
        (notificacao) => notificacao.isDestaque || notificacao.requireConfirmacao
      ).length;

      if (quantidade) {
        tipo.cor = "red";
      } else {
        quantidade = this.notificacoesNaoLidas.filter(
          (notificacao) => !(notificacao.isDestaque || notificacao.requireConfirmacao)
        ).length;

        if (quantidade) {
          tipo.cor = "blue";
        }
      }

      tipo.value = quantidade;
      retorno.push({ ...tipo });

      // lidas
      tipo.cor = "corDisabled";
      tipo.value = this.notificacoesLidas.length;
      retorno.push({ ...tipo });

      return retorno;
    },

    options() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        false,
        null
      ).sortKeys('"order"');
    },

    page() {
      return this.getPagina(this.idPage);
    },

    pageAlt() {
      return this.getPagina(this.idPageAlt);
    },

    pedidosEstados() {
      return [...new Set(this.pedidos.map((pedido) => pedido.idEstado))];
    },

    pedidosValorTotal() {
      return this.pedidos.reduce(
        (valorTotal, pedido) => (valorTotal += parseFloat(pedido.valorTotal)),
        0
      );
    },

    subPanelsOpen() {
      return this.panels.some(
        (panel) =>
          !!this.params.panels.find(
            (paramsPanel) => paramsPanel.nome == panel.nome
          )?.specificPanelsOpen?.length
      );
    },

    transacoesTipos() {
      return [...new Set(this.transacoes.map((transacao) => transacao.idTipo))];
    },

    transacoesValorSaldoTotal() {
      return this.transacoes.reduce(
        (valorSaldoTotal, transacao) =>
          (valorSaldoTotal += parseFloat(transacao.valorSaldo)),
        0
      );
    },

    tiposNotificacoes() {
      const tiposNotificacoes = [];

      if (this.notificacoesNaoLidas.length) {
        const tipoNotificao = {
          id: 1,
          titulo: "não lidas",
          texto: "MARCAR TODAS COMO NÃO LIDAS",
          icone: "mdi-bell",
          botao: {
            icone: "mdi-check-all",
            cor: "green",
          },
          notificacoes: this.notificacoesNaoLidas,
        };

        tiposNotificacoes.push(tipoNotificao);
      }

      if (this.notificacoesLidas.length) {
        const tipoNotificao = {
          id: 2,
          titulo: "lidas",
          texto: "LIMPAR TODAS",
          icone: "mdi-bell-outline",
          botao: {
            icone: "mdi-delete-outline",
            cor: "red",
          },
          notificacoes: this.notificacoesLidas,
        };

        tiposNotificacoes.push(tipoNotificao);
      }

      return tiposNotificacoes;
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    clickOption(paginaFeature) {
      const action =
        paginaFeature.options?.click || paginaFeature.options?.route;

      if (action == "clickIncluir") {
        this.$router.push(`/${this.pageAlt.nome}`);
      } else if (action == "clickAjuda") {
        this.$router.push(`/ajuda#${this.page.nome}`);
      }
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? []
        : Array(3)
            .fill()
            .map((_, i) => i);
    },

    clickPanelsDouble() {
      this.params.clickPanelsOpen = !this.subPanelsOpen;

      setTimeout(() => {
        this.params.clickPanelsOpen = null;
      }, 10);
    },

    clickPedido(idPedido) {
      this.$router.push(`/pedido/${idPedido}`);
    },

    clickTransacao(idTransacao) {
      this.$router.push(`transacao/${idTransacao}`);
    },

    clickSnackbar(action) {
      if (action == "marcarTodasComoLidas") {
        const notificacao_usuario = {
          dataLeitura: null,
        };

        this.notificacoesUsuariosAtualizar(notificacao_usuario);
      } else if (action == "limparTodasLidas") {
        const notificacao_usuario = {
          isLimpo: true,
        };

        this.notificacoesUsuariosAtualizar(notificacao_usuario);
      }

      this.setSnackbar();
    },

    dataAberturaAtualizar() {
      this.setVar({ overlay: true });
      const idUsuario = this.getVar("idUsuario");
      const url = `${
        this.getConst("app").baseUrlBackend
      }/notificacoes/abertura/${idUsuario}`;

      axios
        .put(url)
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
          }, this.getConst("config").overlay.timeout);
        });
    },

    detalhar(notificacao) {
      if (notificacao.idTabela) {
        let url;

        if (notificacao.idRegistro) {
          url = `/${this.getTabela(notificacao.idTabela).nomeAlt}/${
            notificacao.idRegistro
          }`;
        } else {
          url = `/${this.getTabela(notificacao.idTabela).nome}`;
        }

        if (url) this.$router.push(encodeURI(url));
      } else if (notificacao.endereco) {
        window.open(notificacao.endereco, "_blank");
      }
    },

    getPanel(panelNome) {
      return this.panels.find((panel) => panel.nome == panelNome);
    },

    notificacaoAcao(id) {
      if (id == 1) {
        this.setSnackbar(
          true,
          "NOTIFICAÇÕES NÃO LIDAS",
          "Marcar como lidas todas as notificações azuis?",
          "warning",
          "mdi-check",
          "marcarTodasComoLidas"
        );
      } else if (id == 2) {
        this.setSnackbar(
          true,
          "NOTIFICAÇÕES LIDAS",
          "Limpar todas as notificações lidas?",
          "error",
          "mdi-check",
          "limparTodasLidas"
        );
      }
    },

    async notificacoesFilter() {
      this.setVar({ overlay: true });

      const search = "*";
      const idContexto = "*";
      const idFilter = null;
      const idOrder = null;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;
      const offset = 0;
      let filters;
      let filter;
      let url;

      // transacoes
      //? descomentar trecho quando criar transacoes.minhasPendencias
      // filters = this.direitosFeatures(
      //   this.getPagina("transacoes").id,
      //   this.getTipo_item("featureFilter").id
      // );

      // filter = filters.length
      //   ? filters.find(
      //       (filter) =>
      //         filter.idFeature ==
      //         this.getFeature("filterMinhasPendencias").id
      //     )
      //   : null;

      const idFilterTransacao = this.getFeature("filterPagamentosPendentes").id;

      const params = JSON.stringify({});

      if (idFilterTransacao) {
        url = `${
          this.getConst("app").baseUrlBackend
        }/transacoes/${search}/${idContexto}/${idFilterTransacao}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
          this.limit
        }/${params}`;

        await axios
          .get(url)
          .then((res) => {
            const result = res.data;
            this.transacoes = [];
            result.forEach((element) => {
              this.transacoes.push(element);
            });
            this.transacoes.sortKeys('"idTipo"');
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          });
      }

      // pedidos
      filters = this.direitosFeatures(
        this.getPagina("pedidos").id,
        this.getTipo_item("featureFilter").id
      );

      filter = filters.length
        ? filters.find(
            (filter) =>
              filter.idFeature == this.getFeature("filterMinhasPendencias").id
          )
        : null;

      const idFilterPedido = filter ? filter.idFeature : null;

      if (idFilterPedido) {
        url = `${
          this.getConst("app").baseUrlBackend
        }/pedidos/${search}/${idContexto}/${idFilterPedido}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
          this.limit
        }/${params}`;

        await axios
          .get(url)
          .then((res) => {
            const result = res.data;
            this.pedidos = [];
            result.forEach((element) => {
              this.pedidos.push(element);
            });
            this.pedidos.sortKeys('"idEstado"');
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          });
      }

      // chats - implementar

      // notificacoes
      url = `${
        this.getConst("app").baseUrlBackend
      }/notificacoes/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
        this.limit
      }`;

      await axios
        .get(url)
        .then((res) => {
          const result = res.data;
          this.notificacoes = [];
          result.forEach((element) => {
            this.notificacoes.push(element);
          });
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
          }, this.getConst("config").overlay.timeout);
        });
    },

    notificacoesUsuariosAtualizar(notificacao_usuario) {
      this.setVar({ overlay: true });

      const idUsuario = this.getVar("idUsuario");
      const url = `${
        this.getConst("app").baseUrlBackend
      }/notificacoes/usuario/null/${idUsuario}`;

      axios
        .put(url, notificacao_usuario)
        .then(() => {
          this.notificacoesFilter();
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
          }, this.getConst("config").overlay.timeout);
        });
    },

    pedidosFiltrados(estado) {
      return this.pedidos.filter((pedido) => pedido.idEstado == estado);
    },

    pedidosFiltradosValorTotal(pedidos) {
      return pedidos.reduce(
        (valorTotal, pedido) => (valorTotal += parseFloat(pedido.valorTotal)),
        0
      );
    },

    swipeRefresh() {
      this.notificacoesFilter();
      this.dataAberturaAtualizar();
    },

    usuarioAtualizar(notificacao, isConfirmado) {
      this.setVar({ overlay: true });
      if (
        !notificacao.dataLeitura ||
        (notificacao.isConfirmado == null && isConfirmado != null)
      ) {
        const notificacao_usuario = {
          dataLeitura: null,
          isConfirmado,
        };

        const idUsuario = this.getVar("idUsuario");
        const url = `${
          this.getConst("app").baseUrlBackend
        }/notificacoes/usuario/${notificacao.id}/${idUsuario}`;

        axios
          .put(url, notificacao_usuario)
          .then((res) => {
            if (res && res.data) {
              const ret = res.data;
              notificacao.dataLeitura = ret.notificacao_usuario.dataLeitura;
              notificacao.isConfirmado = ret.notificacao_usuario.isConfirmado;

              if (isConfirmado == null) this.detalhar(notificacao);

              if (ret.pessoaParceiro && ret.pessoaParceiro.id) {
                this.setSnackbar(
                  true,
                  "CONVITE",
                  `Você aceitou o convite de ${notificacao.parceiro}`,
                  "success"
                );
              } else if (isConfirmado === false) {
                this.setSnackbar(
                  true,
                  "CONVITE",
                  `Você recusou o convite de ${notificacao.parceiro}`,
                  "error"
                );
              }
            }
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          })
          .finally(() => {
            this.setVar({ overlay: false });
          });
      } else {
        this.detalhar(notificacao);
      }
    },
  },

  /// ACTIVATED
  activated() {
    this.notificacoesFilter();
    this.dataAberturaAtualizar();
  },

  /// MOUNTED
  mounted() {
    this.notificacoesFilter();
    this.dataAberturaAtualizar();
  },
};
</script>