<!-- COD: FDS 08/05/2023 CLotes -->
<!-- #VER: JER __/05/2023 CLotes -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- tipo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.idTipo).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- dataInicioFim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataInicioFim }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLotes",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    order: { type: Object },
  },

  computed: {
    dataInicioFim() {
      let ret = "";
      const data = this.dataset.data
        ? this.dateFormat(this.dataset.data, "date")
        : "";

      const dataInicio = this.dataset.dataInicio
        ? this.dateFormat(this.dataset.dataInicio, "date")
        : "";

      const dataFim = this.dataset.dataFim
        ? `- ${this.dateFormat(this.dataset.dataFim, "date")}`
        : "";

      ret = `${dataInicio} ${dataFim} (${data})`;

      return ret;
    },
  },
};
</script>