<!-- COD: BJB 30/06/2022 CFornecedoresClientes -->
<!-- *VER: JER 01/07/2022 CFornecedoresClientes -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- imagem -->
      <v-col v-bind="prop.col" v-if="context.isBuscaLayout" cols="2">
        <c-base-image :endereco="dataset.anexo_endereco" height="60" />
      </v-col>

      <v-col v-bind="prop.col">
        <!-- classificacaoFornecedores -->
        <v-row v-bind="prop.row" v-if="classificacaoFornecedores">
          <v-col v-bind="prop.col">
            <v-chip v-bind="prop.chip">
              {{ classificacaoFornecedores }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- nome -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-h6">
              {{ dataset.pessoa_nome }}
            </span>
          </v-col>
        </v-row>

        <!-- nomeAlt -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-1">
              {{ dataset.pessoa_nomeAlt }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CFornecedoresClientes",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getTipo_item: "tipos/getTipo_item",
    }),

    classificacaoFornecedores() {
      return this.context.idOrder != this.getFeature("orderClassificacao").id
        ? this.getTipo_item(this.dataset.idClassificacaoFornecedor)?.nomeAlt
        : null;
    },
  },
};
</script>