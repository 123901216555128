<!-- COD: LYF 04/07/2022 CPedidoView -->
<!-- *VER: JER 08/08/2022 CPedidoView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- parceiro numero avaliacoes -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.aux.detail.pessoaParceiro_numeroAvaliacoes"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn icon small @click="clickAvaliacaoParceiro">
          <v-icon class="orange--text" small>mdi-star</v-icon>
          <span>
            {{ avaliacaoParceiro }}
          </span>
        </c-btn>
      </v-col>
    </v-row>

    <!-- origem -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          origem {{ getTipo_item(dataset.main.detail.idOrigem).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- operacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idOperacao">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getOperacao(dataset.main.detail.idOperacao).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.data,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- nota fiscal -->
    <v-row v-bind="prop.row" v-show="notaFiscal">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          color="primary"
          class="ma-1 text-body-1"
          text
          @click="clickNotaFiscal"
        >
          {{ notaFiscal }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- carrinho -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idCarrinho">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          color="primary"
          class="ma-1 text-body-1"
          text
          @click="clickCarrinho"
        >
          {{ `carrinho ${dataset.main.detail.idCarrinho}` }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="dataset.main.detail.observacao"
        align="center"
      >
        <span class="red--text text-body-1">
          ({{ dataset.main.detail.observacao }})
        </span>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          color="primary"
          class="ma-1 text-body-1"
          text
          @click="clickPessoa"
        >
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- pessoa numero avaliacoes -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoa_numeroAvaliacoes">
      <v-col v-bind="prop.col" align="center">
        <c-btn icon @click="clickAvaliacaoPessoa">
          <v-icon class="orange--text" small>mdi-star</v-icon>
          <span>
            {{ avaliacaoPessoa }}
          </span>
        </c-btn>
      </v-col>
    </v-row>

    <!-- avaliacao pedido -->
    <v-row v-bind="prop.row" v-if="showAvaliacao">
      <v-col v-bind="prop.col" align="center">
        <c-btn text @click="clickAvaliePedido">
          <span
            :class="
              !!dataset.aux.detail.avaliacaoPedido
                ? 'black--text'
                : 'blue--text'
            "
          >
            {{ avaliacaoPedido }}
          </span>
          <v-rating
            v-model="dataset.aux.detail.avaliacaoPedido"
            :background-color="
              dataset.aux.detail.avaliacaoPedido > 0
                ? 'orange lighten-3'
                : 'blue'
            "
            :color="dataset.aux.detail.avaliacaoPedido > 0 ? 'orange' : 'blue'"
            dense
            hover
            readonly
            size="18"
          />
        </c-btn>
      </v-col>
    </v-row>

    <!-- avaliacao encerrada -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ avaliacaoEncerrada }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPedidoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getTipo_item: "tipos/getTipo_item",
      getOperacao: "operacoes/getOperacao",
    }),

    avaliacaoEncerrada() {
      const isAvaliacaoEncerrada = this.dataset.main.detail.dataEntrega
        ? new Date() >
          this.addDays(
            this.parseISO(this.dataset.main.detail.dataEntrega),
            this.getConst("config").avaliacao.prazoAvaliacao
          )
        : false;

      return isAvaliacaoEncerrada ? "prazo de avaliação encerrado" : null;
    },

    avaliacaoParceiro() {
      return `${
        this.dataset.aux.detail.pessoaParceiro_mediaAvaliacoes
          ? this.dataset.aux.detail.pessoaParceiro_mediaAvaliacoes
          : 0
      } 
      (${
        this.dataset.aux.detail.pessoaParceiro_numeroAvaliacoes
          ? this.dataset.aux.detail.pessoaParceiro_numeroAvaliacoes
          : 0
      })`;
    },

    avaliacaoPedido() {
      return this.dataset.aux.detail.avaliacaoPedido
        ? "Avaliação: "
        : "Avalie: ";
    },

    avaliacaoPessoa() {
      return `${
        this.dataset.aux.detail.pessoa_mediaAvaliacoes
          ? this.dataset.aux.detail.pessoa_mediaAvaliacoes
          : 0
      } 
      (${
        this.dataset.aux.detail.pessoa_numeroAvaliacoes
          ? this.dataset.aux.detail.pessoa_numeroAvaliacoes
          : 0
      })`;
    },

    notaFiscal() {
      return this.dataset.main.notasFiscais.notaFiscal
        ? `NF ${this.dataset.main.notasFiscais.notaFiscal} ${
            this.dataset.main.notasFiscais.serie
              ? this.dataset.main.notasFiscais.serie
              : ""
          } ${this.dataFormata(this.dataset.main.notasFiscais.data, "date")}`
        : null;
    },

    showAvaliacao() {
      return (
        this.dataset.aux.detail.avaliacaoPedido != null ||
        (this.dataset.main.detail.dataEntrega &&
          new Date() <=
            this.addDays(
              this.parseISO(this.dataset.main.detail.dataEntrega),
              this.getConst(`config`).avaliacao.prazoAvaliacao
            ))
      );
    },
  },

  /// METHODS
  methods: {
    clickAvaliacaoParceiro() {
      this.$router.push(
        `/avaliacoes/${this.dataset.main.detail.idPessoaParceiro}`
      );
    },

    clickAvaliePedido() {
      this.$router.push(`/avaliacao/${this.dataset.main.detail.id}`);
    },

    clickAvaliacaoPessoa() {
      this.$router.push(`/avaliacoes/${this.dataset.main.detail.idPessoa}`);
    },

    async clickCarrinho() {
      this.$router.push(`/carrinho/${this.dataset.main.detail.idCarrinho}`);
    },

    async clickNotaFiscal() {
      this.$router.push(`/notaFiscal/${this.dataset.main.notasFiscais.id}`);
    },

    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
    },
  },
};
</script>