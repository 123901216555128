var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
    down: function ($event) { return _vm.swipe('down', _vm.swipeRefresh, $event); },
    up: function ($event) { return _vm.swipe('up', _vm.swipeInject, $event); },
  }),expression:"{\n    down: ($event) => swipe('down', swipeRefresh, $event),\n    up: ($event) => swipe('up', swipeInject, $event),\n  }"}],staticClass:"anexos"},[_c('c-the-navbar',{attrs:{"mode":"page","title":_vm.page.nomeAlt}}),_c('v-container',{staticClass:"pa-0"},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-col',_vm._b({attrs:{"cols":"11"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.registro)+" # "+_vm._s(_vm.idRegistro)+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('v-badge',{staticClass:"ml-5",attrs:{"color":"grey","content":_vm.anexos.length.toString(),"left":""}})],1),(_vm.anexos.length > 1)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"9"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"red--text text-body-2"},[_vm._v(" Arraste e solte para ordenar ")])]):_vm._e()],1),_c('draggable',_vm._b({on:{"end":function($event){return _vm.dragEnd($event)},"start":_vm.dragStart},model:{value:(_vm.anexos),callback:function ($$v) {_vm.anexos=$$v},expression:"anexos"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition"}},_vm._l((_vm.anexos),function(anexo){return _c('v-card',{key:anexo.id,staticClass:"pl-0",attrs:{"router":"","text":"","to":("/anexo/" + (_vm.anexos.length) + "/" + (anexo.id))}},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(
                anexo.idTipo == _vm.getTipo_item('anexoArquivo').id &&
                anexo.tipo.includes('image')
              )?_c('v-col',_vm._b({attrs:{"cols":"3","md":"2"}},'v-col',_vm.prop.col,false),[_c('v-img',{attrs:{"src":anexo.endereco,"max-width":"100"}})],1):_c('v-col',_vm._b({attrs:{"cols":"2","md":"1"}},'v-col',_vm.prop.col,false),[_c('v-icon',{staticClass:"primary--text pa-0",attrs:{"x-large":""}},[_vm._v(" "+_vm._s(_vm.iconeAnexo(anexo.nome))+" ")])],1),_c('v-col',_vm._b({attrs:{"cols":anexo.idTipo == _vm.getTipo_item('anexoArquivo').id &&
                anexo.tipo.includes('image')
                  ? 7
                  : 8,"md":anexo.idTipo == _vm.getTipo_item('anexoArquivo').id &&
                anexo.tipo.includes('image')
                  ? 8
                  : 9}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(anexo.nome)+" ")])]),_c('v-col',_vm._b({attrs:{"align":"end","cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clickOpen($event, anexo.endereco)}}},[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)],1),_c('v-col',_vm._b({attrs:{"align":"end","cols":"1"}},'v-col',_vm.prop.col,false),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-drag-horizontal-variant ")])],1)],1)],1)}),1)],1)],1),_c('c-the-navbar',{attrs:{"bottom":"","mode":"page","pageModeView":_vm.modeView,"slots":_vm.buttonsBar.length,"title":""},scopedSlots:_vm._u([_vm._l((_vm.buttonsBar),function(button,i){return {key:i,fn:function(){return [(!Array.isArray(button))?_c('c-btn',{key:i,attrs:{"icon":""},on:{"click":function($event){return button.clickFunction(button)}}},[_c('v-icon',{attrs:{"color":button.options.color || 'white',"large":""}},[_vm._v(" "+_vm._s(button.options.icon)+" ")])],1):_c('v-menu',{key:i,attrs:{"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('c-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',_vm._l((button),function(option,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.clickOption(option)}}},[_c('v-icon',{class:((option.options.color) + "--text")},[_vm._v(" "+_vm._s(option.options.icon)+" ")]),_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(option.text)+" ")])],1)}),1)],1)]},proxy:true}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }