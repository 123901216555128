<!-- COD: FDS 05/07/2022 CContaContabilEdit -->
<!-- *VER: JER 06/08/2022 CContaContabilEdit -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pai -->
    <v-row
      v-bind="prop.row"
      v-if="getConst(`config`).pageMode.insert.id == context.idPageMode"
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPai"
          :itemsIniciais="dataset.aux.autocomplete.contasContabeisPai"
          label="conta contábil pai"
          :path="`/contasContabeis/autocomplete/contasContabeisPai/${dataset.main.detail.idTipo}/`"
          :rules="[]"
          required
          @change="changeContaPai"
        />
      </v-col>
    </v-row>

    <!-- nivel -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nivel">
      <v-col v-bind="prop.col" align="center">
        <v-badge
          :color="dataset.main.detail.allowLancamento ? 'green' : 'grey'"
          :content="dataset.main.detail.nivel"
          inline
          overlap
          :value="!!dataset.main.detail.nivel"
        />
      </v-col>
    </v-row>

    <!-- pai nomeAlt -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idPai &&
        getConst(`config`).pageMode.edit.id == context.idPageMode
      "
    >
      <v-col v-bind="prop.col" align="center">
        <span classe="text-body-1">
          {{ getContaContabil(dataset.main.detail.idPai).nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- id -->
    <v-row
      v-bind="prop.row"
      v-if="getConst(`config`).pageMode.insert.id == context.idPageMode"
    >
      <v-col v-bind="prop.col" cols="4">
        <c-text-field
          v-model="dataset.main.detail.id"
          :help="{
            title: 'ID CONTA CONTÁBIL',
            text: `Formato 'abccddee', onde:
- a: classe
- b: grupo
- c: subgrupo
- d: conta
- e: subconta`,
            function: params.context.setUiBottomSheet,
          }"
          label="id"
          :max="10"
          required
          type="tel"
        />
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nome alt"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- tipo detalhe -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="dataset.main.detail.idTipoDetalhe"
          clearable
          :items="getTipo_itens(getTipo(`tipoDetalhe`).id)"
          label="tipo detalhe"
        />
      </v-col>
    </v-row>

    <!-- detalhe requerido -->
    <v-row v-bind="prop.row" class="mt-3">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.isDetalheRequerido"
          label="detalhe requerido"
        />
      </v-col>
    </v-row>

    <!-- permite lançamento -->
    <v-row v-bind="prop.row" class="mt-3">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.allowLancamento"
          label="permite lançamento"
        />
      </v-col>
    </v-row>

    <!-- permite lançamento manual -->
    <v-row v-bind="prop.row" class="mt-3">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="
            dataset.main.detail.permiteLancamentoallowLancamentoManualManual
          "
          disabled
          label="permite lançamento manual"
        />
      </v-col>
    </v-row>

    <!-- saldo devedor -->
    <v-row v-bind="prop.row" class="mt-3">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.isSaldoDevedor"
          label="saldo devedor"
        />
      </v-col>
    </v-row>

    <!-- saldo credor -->
    <v-row v-bind="prop.row" class="mt-3">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.isSaldoCredor"
          label="saldo credor"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CContaContabilEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getContaContabil: "contasContabeis/getContaContabil",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
    }),
  },

  /// METHODS
  methods: {
    changeContaPai() {
      if (this.dataset.main.detail.idPai) {
        const contaPai = this.getContaContabil(this.dataset.main.detail.idPai);
        this.dataset.main.detail.id = contaPai.id;
        this.dataset.main.detail.nivel = contaPai.nivel + 1;

        const prefix = this.getTipo_item(this.dataset.main.detail.idTipo)
          .options.prefix;
        this.dataset.main.detail.nome = `${prefix}${this.dataset.main.detail.nivel}`;
      }
    },

    clickContaContabil() {
      this.$router.push(`/contaContabil/${this.dataset.main.detail.idPai}`);
    },
  },

  /// MOUNTED
  mounted() {
    if (this.context.idPageMode == this.getConst("config").pageMode.insert.id) {
      const prefix = this.getTipo_item(this.dataset.main.detail.idTipo).options
        .prefix;
      this.dataset.main.detail.nome = `${prefix}`;
    }
  },
};
</script>