<!-- COD: LYF 05/07/2022 CValorPagRecView -->
<!-- *VER: JER 05/08/2022 CValorPagRecView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- forma pagamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idFormaPagamento">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.main.detail.idFormaPagamento).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          class="text-body-1 ma-1"
          color="primary"
          text
          @click="clickPessoa"
        >
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- conta bancaria -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ contaBancaria }}
        </span>
      </v-col>
    </v-row>

    <!-- numero documento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.numeroDocumento">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">número documento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.numeroDocumento }}
        </span>
      </v-col>
    </v-row>

    <!-- motivo devolucao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.motivoDevolucao">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">motivo devolução</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.motivoDevolucao }}
        </span>
      </v-col>
    </v-row>

    <!-- emitente nome -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.emitenteNome">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">emitente</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.emitenteNome }}
        </span>
      </v-col>
    </v-row>

    <!-- emitente documento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.emitenteDocumento">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">documento emitente</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.emitenteDocumento }}
        </span>
      </v-col>
    </v-row>

    <!-- nominal -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nominal">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">nominal a</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.nominal }}
        </span>
      </v-col>
    </v-row>

    <!-- local emissao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.localEmissao">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">local emissão</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.localEmissao }}
        </span>
      </v-col>
    </v-row>

    <!-- data emissao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">emissão</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataEmissao, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- data apropriacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">apropriação</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataApropriacao, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- valor -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">valor</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.valor, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- saldo associar -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">saldo associar</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.saldoAssociar, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- identificador -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.identificador">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">identificador</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.identificador }}
        </span>
      </v-col>
    </v-row>

    <!-- historico -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.historico">
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-2">histórico</span>
      </v-col>
      <v-col v-bind="prop.col" cols="8">
        <span class="text-body-1">
          {{ dataset.main.detail.historico }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CValorPagRecView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    contaBancaria() {
      const isCheque =
        this.getTipo_item("formaPagamentoCheque").id ==
        this.dataset.main.detail.idFormaPagamento;
      const isCreditoConta =
        this.getTipo_item("formaPagamentoCreditoConta").id ==
        this.dataset.main.detail.idFormaPagamento;
      const isTipoPag =
        this.getTipo_item("valorPag").id == this.dataset.main.detail.idTipo;
      let contaBancaria = null;

      if ((isCheque && isTipoPag) || isCreditoConta) {
        contaBancaria = this.getVar("usuarios")[
          this.getVar("usuarios").findIndex(
            (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
          )
        ].contas.find(
          (conta) =>
            conta.id == this.dataset.main.detail.idPessoaParceiroContaBancaria
        );
      } else if (isCheque) {
        contaBancaria = {
          banco: this.dataset.main.detail.banco,
          agencia: this.dataset.main.detail.agencia,
          agenciaDV: null,
          conta: this.dataset.main.detail.conta,
          contaDV: null,
        };
      }

      return contaBancaria ? this.contaBancariaFormat(contaBancaria) : null;
    },
  },

  /// METHODS
  methods: {
    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
    },
  },
};
</script>