var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),_vm._l((_vm.dataset.marcacoes[_vm.data.id]),function(marcacao,i){return _c('span',{key:("marcacao" + i)},[(_vm.isMarcacaoAnterior(marcacao))?_c('v-icon',{staticClass:"ml-2 mr-n3",attrs:{"small":""}},[_vm._v(" mdi-arrow-down-thin ")]):_vm._e(),(_vm.isMarcacaoSeguinte(marcacao))?_c('v-icon',{staticClass:"ml-2 mr-n3",attrs:{"small":""}},[_vm._v(" mdi-arrow-up-thin ")]):_vm._e(),(marcacao.isUpdated && marcacao.data)?_c('span',{staticClass:"ml-2 pt-3"},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(marcacao.data), "HH:mm"))+" ")]):(marcacao.id && marcacao.data)?_c('a',{staticClass:"ml-2 pt-3",on:{"click":function($event){return _vm.$emit('clickComprovante', marcacao.id)}}},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(marcacao.data), "HH:mm"))+" ")]):_vm._e()],1)}),0)],1),(_vm.data.idInconsistencia)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v("marcações inconsistentes")])],1)],1):_vm._e(),(_vm.data.idInconsistencia)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-btn',{staticClass:"text-body-2 text-lowercase",attrs:{"color":"error"}},[_vm._v(" complementar ")])],1)],1):_vm._e(),_vm._l((_vm.dataset.apontamentos[_vm.data.id]),function(apontamento,i){return _c('div',{key:("apontamento" + i)},[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v(" "+_vm._s(typeof apontamento == "string" ? apontamento : _vm.getVerba(apontamento.idVerba).options.nomeAlt)+" ")])],1),(
          apontamento.horasApontadas &&
          _vm.getVerba(apontamento.idVerba).options.unidade == 'hora'
        )?_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('span',{class:((_vm.apontamentosColor(_vm.data, apontamento)) + " text-body-2")},[_vm._v(" "+_vm._s(apontamento.horasApontadas.substring(0, 5))+" ")])]):_vm._e(),(
          apontamento.horasConfirmadas != null &&
          _vm.getVerba(apontamento.idVerba).options.unidade == 'hora'
        )?_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"ml-4 text-body-2",class:_vm.apontamentosEfetivosColor(apontamento)},[_vm._v(" "+_vm._s(apontamento.horasConfirmadas.substring(0, 5))+" ")])]):_vm._e()],1),(
        _vm.isUsuarioGestorSelected &&
        apontamento.horasConfirmadas == null &&
        apontamento.idSituacaoTrabalho == null &&
        apontamento.idVerba
      )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-btn',{staticClass:"text-body-2 text-lowercase",attrs:{"color":"error"}},[_vm._v(" confirmar ")])],1)],1):_vm._e(),(
        _vm.isUsuarioGestorSelected &&
        apontamento.idVerba &&
        _vm.getVerba(apontamento.idVerba).options.nomeAlt == 'falta'
      )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-btn',{staticClass:"text-body-2 text-lowercase",attrs:{"color":"error"}},[_vm._v(" justificar ")])],1)],1):_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[(apontamento.idSituacaoTrabalho)?_c('div',[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.getTipo_item(apontamento.idSituacaoTrabalho).nomeAlt)+" ")])]):_vm._e(),(apontamento.justificativa)?_c('div',[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(apontamento.justificativa)+" ")])]):_vm._e()])],1)],1)}),(_vm.data.idFeriado)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v("feriado")])],1)],1):_vm._e(),(_vm.data.idFeriado)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.getFeriado(_vm.data.idFeriado).nome)+" ")])])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }