<!-- COD: FDS 04/07/2022 Dicionario -->
<!-- *VER: JER 08/07/2022 Dicionario -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- // IMAGENS -->
      <template #images="{ dataset, pageModeView }">
        <c-base-detail-images
          :dataset="dataset"
          :height="70"
          :pageModeView="pageModeView"
          :width="70"
        />
      </template>

      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getPagina(page.nome).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-dicionario-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-dicionario-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailImages from "@/components/bases/CBaseDetailImages";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CDicionarioEdit from "@/components/pages/dicionario/CDicionarioEdit";
import CDicionarioView from "@/components/pages/dicionario/CDicionarioView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Dicionario",

  /// COMPONENTS
  components: {
    CBaseDetailImages,
    CBaseDetailInsert,
    CBaseDialogEstado,
    CDicionarioEdit,
    CDicionarioView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("dicionario").id,
      idPageAlt: getPagina("dicionarios").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("dicionarioGeral").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            texto: null,
            idLocale: null,
            idTabela: null,
            hasTraducaoFrontend: false,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          traducoes: [],
        },
        aux: {
          detail: {
            pessoaParceiro: null,
          },
          traducoes: [],
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },
      },

      params: {
        context: this,
        panelsOpen: null,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    }
  },
};
</script>