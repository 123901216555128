// #COD: BJB __/08/2022 router/index
// VER: JER __/08/2022 router/index
// 3?

/// IMPORT
import Vue from 'vue'
import Router from 'vue-router'
import variaveis from '../store/modules/variaveis'
import paginas from '../store/modules/paginas'
import Home from '../views/Home.vue'
import Comentarios from '../views/Comentarios.vue'
import Print from '../views/Print.vue'
import Dicionarios from '../views/Dicionarios.vue'
import Dicionario from '../views/Dicionario.vue'
import Localidades from '../views/Localidades.vue'
import Localidade from '../views/Localidade.vue'
import Feriados from '../views/Feriados.vue'
import Feriados_FeriadosDoAno from '../views/Feriados_FeriadosDoAno'
import Feriado from '../views/Feriado.vue'
import Bancos from '../views/Bancos.vue'
import Banco from '../views/Banco.vue'
import Cartoes from '../views/Cartoes.vue'
import Cartao from '../views/Cartao.vue'
import Impostos from '../views/Impostos.vue'
import Imposto from '../views/Imposto.vue'
import Legislacoes from '../views/Legislacoes.vue'
import Legislacao from '../views/Legislacao.vue'
import Legislacao_Itens from '../views/Legislacao_Itens.vue'
import Convites from '../views/Convites.vue'
import Convite from '../views/Convite.vue'
import Convidados from '../views/Convidados.vue'
import Pessoas from '../views/Pessoas.vue'
import Pessoa from '../views/Pessoa.vue'
import Busca from '../views/Busca.vue'
import Notificacoes from '../views/Notificacoes.vue'
import Notificacao from '../views/Notificacao.vue'
import Ajuda from '../views/Ajuda.vue'
import Sobre from '../views/Sobre.vue'
import Logs from '../views/Logs.vue'
import Anexos from '../views/Anexos.vue'
import Anexo from '../views/Anexo.vue'
import Parceiros from '../views/Parceiros.vue'
import Parceiro from '../views/Parceiro.vue'
import FornecedoresClientes from '../views/FornecedoresClientes.vue'
import FornecedorCliente from '../views/FornecedorCliente.vue'
import Produtos from '../views/Produtos.vue'
import Produtos_Gerenciar from '../views/Produtos_Gerenciar.vue'
import Produtos_Inventario from '../views/Produtos_Inventario.vue'
import Produtos_InventarioDiferencas from '../views/Produtos_InventarioDiferencas.vue'
import Produto_NumerosSerie from '../views/Produto_NumerosSerie.vue'
import Produto_Kardex from '../views/Produto_Kardex.vue'
import Produto from '../views/Produto.vue'
import Publicacoes from '../views/Publicacoes.vue'
import Publicacao from '../views/Publicacao.vue'
import Cupons from '../views/Cupons.vue'
import Cupom from '../views/Cupom.vue'
import Carrinhos from '../views/Carrinhos.vue'
import Carrinho from '../views/Carrinho.vue'
import Pedidos from '../views/Pedidos.vue'
import Pedido from '../views/Pedido.vue'
import Pedido_NumerosSerie from '../views/Pedido_NumerosSerie.vue'
import Avaliacoes from '../views/Avaliacoes.vue'
import Avaliacao from '../views/Avaliacao.vue'
import Lotes from '../views/Lotes.vue'
import Lote from '../views/Lote.vue'
import NotasFiscais from '../views/NotasFiscais'
import NotaFiscal from '../views/NotaFiscal'
import ContasPagRec from '../views/ContasPagRec'
import ContaPagRec from '../views/ContaPagRec'
import ContaPagRec_BaixaCruzada from '../views/ContaPagRec_BaixaCruzada'
import ValoresPagRec from '../views/ValoresPagRec'
import ValorPagRec from '../views/ValorPagRec'
import ValoresPagRec_DepositoBancario from '../views/ValoresPagRec_DepositoBancario'
import ValorPagRec_ContasPagRec from '../views/ValorPagRec_ContasPagRec'
import Extratos from '../views/Extratos'
import Extrato from '../views/Extrato'
import Extrato_Conciliacoes from '../views/Extrato_Conciliacoes'
import AssistenciasTecnicas from '../views/AssistenciasTecnicas'
import AssistenciaTecnica from '../views/AssistenciaTecnica'
import AssistenciasTecnicas_PedidoSaida from '../views/AssistenciasTecnicas_PedidoSaida'
import PerguntasFrequentes from '../views/PerguntasFrequentes.vue'
import PerguntaFrequente from '../views/PerguntaFrequente.vue'
import PerguntasFrequentes_Faqs from '../views/PerguntasFrequentes_Faqs'
import Feedbacks from '../views/Feedbacks.vue'
import Feedback from '../views/Feedback.vue'
import PontosEletronicos from '../views/PontosEletronicos'
import PontoEletronico_Apontamentos from '../views/PontoEletronico_Apontamentos'
import PontoEletronico from '../views/PontoEletronico'
import FolhasPagamentos from '../views/FolhasPagamentos'
import FolhaPagamento from '../views/FolhaPagamento'
import FolhaPagamento_Colaboradores from '../views/FolhaPagamento_Colaboradores'
import FolhaPagamento_Ocorrencias from '../views/FolhaPagamento_Ocorrencias'
import FolhaPagamento_Verbas from '../views/FolhaPagamento_Verbas'
import Relatorios from '../views/Relatorios.vue'
import Transacoes from '../views/Transacoes.vue'
import Transacao from '../views/Transacao.vue'
import ContasContabeis from '../views/ContasContabeis.vue'
import ContaContabil from '../views/ContaContabil.vue'
import HistoricosPadrao from '../views/HistoricosPadrao.vue'
import HistoricoPadrao from '../views/HistoricoPadrao.vue'
import LancamentosContabeis from '../views/LancamentosContabeis.vue'
import LancamentoContabil from '../views/LancamentoContabil.vue'
import Colaboradores from '../views/Colaboradores'
import Colaborador from '../views/Colaborador'
import Colaboradores_HorasExtras from '../views/Colaboradores_HorasExtras'
import Colaborador_Apontamentos from '../views/Colaborador_Apontamentos'
import Verbas from '../views/Verbas'
import Verba from '../views/Verba'
import TermosServico from '../views/TermosServico.vue'
import PoliticaPrivacidade from '../views/PoliticaPrivacidade.vue'
import Agendas from '../views/Agendas.vue'

let goBack = 0;

Vue.use(Router)

/// ROUTER
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: "public",
    },
    {
      path: '/oops',
      name: 'Home',
      component: Home,
      meta: "public"
    },
    {
      path: '/invite/:idConvite',
      name: 'Invite',
      component: Home,
      meta: "public"
    },
    {
      path: '/comentarios/:idPublicacao/:idComentario',
      name: 'Comentarios/:idPublicacao/:idComentario',
      component: Comentarios
    },
    {
      path: '/busca/:idTipoBusca',
      name: 'Busca',
      component: Busca,
      meta: "public"
    },
    {
      path: '/notificacoes',
      name: 'Notificacoes',
      component: Notificacoes,
      meta: "public"
    },
    {
      path: '/notificacao',
      name: 'Notificacao',
      component: Notificacao
    },
    {
      path: '/print',
      name: 'Print',
      component: Print,
      meta: "public"
    },
    {
      path: '/termos-servico',
      name: 'Termos de Serviço',
      component: TermosServico,
      meta: "public"
    },
    {
      path: '/politica-privacidade',
      name: 'Política de Privacidade',
      component: PoliticaPrivacidade,
      meta: "public"
    },
    {
      path: '/ajuda',
      name: 'Ajuda',
      component: Ajuda,
      meta: "public"
    },
    {
      path: '/sobre',
      name: 'Sobre',
      component: Sobre,
      meta: "public"
    },
    {
      path: '/logs/:modeBusca/:idTabela/:idRegistro/:registro',
      name: 'Logs/modeBusca/idTabela/idRegistro/registro',
      component: Logs,
      meta: "public"
    },
    {
      path: '/anexos/:idTabela/:idRegistro/:registro',
      name: 'Anexos/idTabela/idRegistro/registro',
      component: Anexos
    },
    {
      path: '/anexo',
      name: 'Anexo',
      component: Anexo
    },
    {
      path: '/anexo/:idTabela/:idRegistro/:anexos',
      name: 'Anexo/idTabela/idRegistro/anexos',
      component: Anexo
    },
    {
      path: '/anexo/:anexos/:id',
      name: 'Anexo/anexos/id',
      component: Anexo
    },
    {
      path: '/dicionarios',
      name: 'Dicionarios',
      component: Dicionarios,
      meta: "public",
    },
    {
      path: '/dicionario',
      name: 'Dicionario',
      component: Dicionario,
      meta: "public",
    },
    {
      path: '/dicionario/:id',
      name: 'Dicionario/id',
      component: Dicionario,
      meta: "public",
    },
    {
      path: '/localidades',
      name: 'Localidades',
      component: Localidades,
      meta: "public",
    },
    {
      path: '/localidade',
      name: 'Localidade',
      component: Localidade
    },
    {
      path: '/localidade/:id',
      name: 'Localidade/id',
      component: Localidade
    },
    {
      path: '/feriados',
      name: 'Feriados',
      component: Feriados,
      meta: "public",
    },
    {
      path: '/feriadosDoAno',
      name: 'FeriadosDoAno',
      component: Feriados_FeriadosDoAno,
      meta: "public",
    },
    {
      path: '/feriado/:id',
      name: 'Feriado/id',
      component: Feriado,
      meta: "public",
    },
    {
      path: '/Feriado',
      name: 'Feriado',
      component: Feriado,
      meta: "public",
    },
    {
      path: '/bancos',
      name: 'Bancos',
      component: Bancos
    },
    {
      path: '/bancos/:idFilter',
      name: 'BancosFiltrar',
      component: Bancos
    },
    {
      path: '/banco',
      name: 'Banco',
      component: Banco,
      meta: "public",
    },
    {
      path: '/banco/:id',
      name: 'Banco/id',
      component: Banco,
      meta: "public",
    },
    {
      path: '/cartoes',
      name: 'Cartoes',
      component: Cartoes
    },
    {
      path: '/cartoes/:idFilter',
      name: 'CartoesFiltrar',
      component: Cartoes
    },
    {
      path: '/cartao',
      name: 'Cartao',
      component: Cartao
    },
    {
      path: '/cartao/:id',
      name: 'Cartao/id',
      component: Cartao
    },
    {
      path: '/impostos',
      name: 'Impostos',
      component: Impostos,
      meta: "public"
    },
    {
      path: '/impostos/:idFilter',
      name: 'ImpostosFiltrar',
      component: Impostos,
      meta: "public"
    },
    {
      path: '/imposto',
      name: 'Imposto',
      component: Imposto,
      meta: "public"
    },
    {
      path: '/imposto/:id',
      name: 'Imposto/id',
      component: Imposto,
      meta: "public"
    },
    {
      path: '/legislacoes',
      name: 'Legislacoes',
      component: Legislacoes,
      meta: "public",
    },
    {
      path: '/legislacoes/:idFilter',
      name: 'LegislacoesFiltrar',
      component: Legislacoes
    },
    {
      path: '/legislacao',
      name: 'Legislacao',
      component: Legislacao,
      meta: "public",
    },
    {
      path: '/legislacao/:id',
      name: 'Legislacao/id',
      component: Legislacao,
      meta: "public",
    },
    {
      path: '/legislacao/legislacao_Itens/:id',
      name: 'legislacao_Itens',
      component: Legislacao_Itens,
      meta: "public"
    },
    {
      path: '/pessoas',
      name: 'Pessoas',
      component: Pessoas,
    },
    {
      path: '/pessoas/:idFilter',
      name: 'PessoasFiltrar',
      component: Pessoas,
    },
    {
      path: '/pessoa',
      name: 'Pessoa',
      component: Pessoa,
      meta: "public"
    },
    {
      path: '/pessoa/:id',
      name: 'Pessoa/id',
      component: Pessoa,
      meta: "public"
    },
    {
      path: '/parceiros',
      name: 'Parceiros',
      component: Parceiros,
      meta: "public"
    },
    {
      path: '/parceiros/:idFilter',
      name: 'ParceirosFiltrar',
      component: Parceiros,
      meta: "public"
    },
    {
      path: '/parceiro',
      name: 'Parceiro',
      component: Parceiro,
      meta: "public"
    },
    {
      path: '/parceiro/:id',
      name: 'Parceiro/id',
      component: Parceiro,
      meta: "public"
    },
    {
      path: '/parceiro/:id/:idTipo/:nome/:nomeAlt',
      name: 'Parceiro/id/idTipo/nome/nomeAlt',
      component: Parceiro,
      meta: "public"
    },
    {
      path: '/fornecedoresClientes',
      name: 'FornecedoresClientes',
      component: FornecedoresClientes
    },
    {
      path: '/fornecedorCliente',
      name: 'FornecedorCliente',
      component: FornecedorCliente,
      meta: "public"
    },
    {
      path: '/fornecedorCliente/:id',
      name: 'FornecedorCliente/id',
      component: FornecedorCliente,
      meta: "public"
    },
    {
      path: '/produtos',
      name: 'Produtos',
      component: Produtos
    },
    {
      path: '/produtos/gerenciar',
      name: 'Produtos_Gerenciar',
      component: Produtos_Gerenciar,
      meta: "public"
    },
    {
      path: '/produtos/inventario',
      name: 'Produtos_Inventario',
      component: Produtos_Inventario,
      meta: "public"
    },
    {
      path: '/produtos/inventario/diferencas',
      name: 'Produtos_InventarioDiferencas',
      component: Produtos_InventarioDiferencas,
      meta: "public"
    },
    {
      path: '/produto/kardex/:id',
      name: 'Produto_Kardex',
      component: Produto_Kardex,
      meta: "public"
    },
    {
      path: '/produto/numerosserie',
      name: 'Produto_NumerosSerie',
      component: Produto_NumerosSerie,
      meta: "public"
    },
    {
      path: '/produto/numerosserie/:idProduto',
      name: 'Produto_NumerosSerie/idProduto',
      component: Produto_NumerosSerie
    },
    {
      path: '/produtos/:idFilter',
      name: 'ProdutosFiltrar',
      component: Produtos
    },
    {
      path: '/produto',
      name: 'Produto',
      component: Produto
    },
    {
      path: '/produto/:id',
      name: 'Produto/id',
      component: Produto,
      meta: "public"
    },
    {
      path: '/publicacoes',
      name: 'Publicacoes',
      component: Publicacoes
    },
    {
      path: '/publicacoes/:idFilter',
      name: 'PublicacoesFiltrar',
      component: Publicacoes
    },
    {
      path: '/publicacao',
      name: 'Publicacao',
      component: Publicacao
    },
    {
      path: '/publicacao/:id',
      name: 'Publicacao/id',
      component: Publicacao,
      meta: "public"
    },
    {
      path: '/convites',
      name: 'Convites',
      component: Convites
    },
    {
      path: '/convites/:idFilter',
      name: 'ConvitesFiltrar',
      component: Convites
    },
    {
      path: '/convite',
      name: 'Convite',
      component: Convite
    },
    {
      path: '/convite/:id',
      name: 'Convite/id',
      component: Convite
    },
    {
      path: '/convidados/:idConvite',
      name: 'Convidados/idConvite',
      component: Convidados
    },
    {
      path: '/cupons',
      name: 'Cupons',
      component: Cupons,
      meta: "public"
    },
    {
      path: '/cupons/:idFilter',
      name: 'CuponsFiltrar',
      component: Cupons,
      meta: "public"
    },
    {
      path: '/cupom',
      name: 'Cupom',
      component: Cupom,
      meta: "public"
    },
    {
      path: '/cupom/:id',
      name: 'Cupom/id',
      component: Cupom,
      meta: "public"
    },
    {
      path: '/carrinhos',
      name: 'Carrinhos',
      component: Carrinhos,
      meta: "public"
    },
    {
      path: '/carrinhos/:idFilter',
      name: 'carrinhosFiltrar',
      component: Carrinhos,
      meta: "public"
    },
    {
      path: '/carrinho',
      name: 'Carrinho',
      component: Carrinho,
      meta: "public"
    },
    {
      path: '/carrinho/:id',
      name: 'Carrinho/id',
      component: Carrinho,
      meta: "public",
    },
    {
      path: '/pedidos',
      name: 'Pedidos',
      component: Pedidos
    },
    {
      path: '/pedidos/:idFilter',
      name: 'PedidosFiltrar',
      component: Pedidos
    },
    {
      path: '/pedido',
      name: 'Pedido',
      component: Pedido
    },
    {
      path: '/pedido/:id',
      name: 'Pedido/id',
      component: Pedido,
      meta: "public"
    },
    {
      path: '/pedido/numerosserie/:id',
      name: 'Pedido/NumerosSerie/id',
      component: Pedido_NumerosSerie,
      meta: "public"
    },
    {
      path: '/avaliacoes/:idAvaliado',
      name: 'Avaliacoes/idAvaliado',
      component: Avaliacoes
    },
    {
      path: '/avaliacao/:id',
      name: 'Avaliacao/id',
      component: Avaliacao
    },
    {
      path: '/lotes',
      name: 'Lotes',
      component: Lotes,
      meta: 'public'
    },
    {
      path: '/lotes/:idFilter',
      name: 'LotesFiltrar',
      component: Lotes,
      meta: 'public'
    },
    {
      path: '/lote',
      name: 'Lote',
      component: Lote
    },
    {
      path: '/lote/:id',
      name: 'Lote/id',
      component: Lote,
      meta: "public"
    },
    {
      path: '/notasFiscais',
      name: 'NotasFiscais',
      component: NotasFiscais
    },
    {
      path: '/notaFiscal/:id',
      name: 'NotaFiscal/id',
      component: NotaFiscal,
      meta: "public"
    },
    {
      path: '/assistenciasTecnicas',
      name: 'AssistenciasTecnicas',
      component: AssistenciasTecnicas,
      meta: "public"
    },
    {
      path: '/assistenciaTecnica/:id',
      name: 'AssistenciaTecnica/:id',
      component: AssistenciaTecnica,
      meta: "public"
    },
    {
      path: '/assistenciasTecnicas/gerarPedidoSaida',
      name: 'AssistenciasTecnicas_PedidoSaida',
      component: AssistenciasTecnicas_PedidoSaida,
      meta: "public"
    },
    {
      path: '/contasPagRec',
      name: 'ContasPagRec',
      component: ContasPagRec,
      meta: "public"
    },
    {
      path: '/contaPagRec',
      name: 'ContaPagRec',
      component: ContaPagRec,
      meta: "public"
    },
    {
      path: '/contaPagRec/:id',
      name: 'ContaPagRec/id',
      component: ContaPagRec,
      meta: "public"
    },
    {
      path: '/contaPagRec/baixaCruzada/:id',
      name: 'ContaPagRec_BaixaCruzada/id',
      component: ContaPagRec_BaixaCruzada,
      meta: "public"
    },
    {
      path: '/valoresPagRec',
      name: 'ValoresPagRec',
      component: ValoresPagRec,
      meta: "public"
    },
    {
      path: '/valorPagRec',
      name: 'ValorPagRec',
      component: ValorPagRec,
      meta: "public"
    },
    {
      path: '/valorPagRec/:id',
      name: 'ValorPagRec/id',
      component: ValorPagRec,
      meta: "public"
    },
    {
      path: '/valoresPagRec/depositoBancario',
      name: 'ValoresPagRec_DepositoBancario',
      component: ValoresPagRec_DepositoBancario,
      meta: "public"
    },
    {
      path: '/valorPagRec/contasPagRec/:id',
      name: 'ValorPagRec_ContasPagRec/id',
      component: ValorPagRec_ContasPagRec,
      meta: "public"
    },
    {
      path: '/extratos',
      name: 'Extratos',
      component: Extratos,
      meta: 'public'
    },
    {
      path: '/extrato',
      name: 'Extrato',
      component: Extrato,
      meta: "public"
    },
    {
      path: '/extrato/:id',
      name: 'Extrato/id',
      component: Extrato,
      meta: "public"
    },
    {
      path: '/extrato/Extrato_Conciliacoes/:id',
      name: 'Extrato_Conciliacoes',
      component: Extrato_Conciliacoes,
      meta: "public"
    },
    {
      path: '/perguntasFrequentes',
      name: 'PerguntasFrequentes',
      component: PerguntasFrequentes,
      meta: 'public'
    },
    {
      path: '/perguntasFrequentes/:idFilter',
      name: 'PerguntasFrequentesFiltrar',
      component: PerguntasFrequentes,
      meta: 'public'
    },
    {
      path: '/perguntaFrequente',
      name: 'PerguntaFrequente',
      component: PerguntaFrequente,
      meta: 'public'
    },
    {
      path: '/perguntaFrequente/:id',
      name: 'PerguntaFrequente/id',
      component: PerguntaFrequente,
      meta: 'public'
    },
    {
      path: '/faq',
      name: 'Faq',
      component: PerguntasFrequentes_Faqs,
      meta: 'public'
    },
    {
      path: '/feedbacks',
      name: 'Feedbacks',
      component: Feedbacks
    },
    {
      path: '/feedbacks/:idFilter',
      name: 'FeedbacksFiltrar',
      component: Feedbacks
    },
    {
      path: '/feedback',
      name: 'Feedback',
      component: Feedback
    },
    {
      path: '/feedback/:id',
      name: 'Feedback/id',
      component: Feedback
    },
    {
      path: '/pontoEletronico',
      name: 'PontoEletronico',
      component: PontoEletronico,
    },
    {
      path: '/pontosEletronicos',
      name: 'PontosEletronicos',
      component: PontosEletronicos,
      meta: "public"
    },
    {
      path: '/pontoEletronico/PontoEletronico_Apontamentos/:id',
      name: 'PontoEletronico_Apontamentos',
      component: PontoEletronico_Apontamentos,
      meta: "public"
    },
    {
      path: '/pontoEletronico/:id',
      name: 'PontoEletronico/id',
      component: PontoEletronico,
      meta: "public"
    },
    {
      path: '/verbas',
      name: 'Verbas',
      component: Verbas,
      meta: "public"
    },
    {
      path: '/verba/:id',
      name: 'Verba/id',
      component: Verba,
      meta: 'public',
    },
    {
      path: '/verba',
      name: 'Verba',
      component: Verba,
    },
    {
      path: '/folhasPagamento',
      name: 'FolhasPagamentos',
      component: FolhasPagamentos,
      meta: "public"
    },
    {
      path: '/folhaPagamento/:id',
      name: 'FolhaPagamento/id',
      component: FolhaPagamento,
      meta: "public"
    },
    {
      path: '/folhaPagamento',
      name: 'FolhaPagamento',
      component: FolhaPagamento,
      meta: "public"
    },
    {
      path: '/folhaPagamento/FolhaPagamento_Colaboradores/:id',
      name: 'FolhaPagamento_Colaboradores',
      component: FolhaPagamento_Colaboradores,
      meta: "public"
    },
    {
      path: '/folhaPagamento/FolhaPagamento_Ocorrencias/:id',
      name: 'FolhaPagamento_Ocorrencias',
      component: FolhaPagamento_Ocorrencias,
      meta: "public"
    },
    {
      path: '/folhaPagamento/folhaPagamento_Verbas/:id',
      name: 'FolhaPagamento_Verbas',
      component: FolhaPagamento_Verbas,
      meta: "public"
    },
    {
      path: '/relatorios/:idPagina/:idTabela/:idRegistro/:idRelatorio/:idContexto/:idFilter/:idOrder/:search',
      name: 'Relatorios/idPagina/idTabela/idRegistro/idRelatorio/idFilter/idOrder/search',
      component: Relatorios,
      meta: "public",
    },
    {
      path: '/relatorios/:idPagina/:idTabela/:idRegistro/:idRelatorio/:idContexto/:idFilter/:idOrder/:search/:params',
      name: 'Relatorios/idPagina/idTabela/idRegistro/idRelatorio/idContexto/idFilter/idOrder/search/params',
      component: Relatorios,
      meta: "public",
    },
    {
      path: '/transacoes',
      name: 'Transacoes',
      component: Transacoes
    },
    {
      path: '/transacoes/:idFilter',
      name: 'TransacoesFiltrar',
      component: Transacoes
    },
    {
      path: '/transacao/:id',
      name: 'Transacao/id',
      component: Transacao
    },
    {
      path: '/contasContabeis',
      name: 'ContasContabeis',
      component: ContasContabeis,
      meta: "public"
    },
    {
      path: '/contasContabeis/:idFilter',
      name: 'ContasContabeisFiltrar',
      component: ContasContabeis,
      meta: "public"
    },
    {
      path: '/contaContabil',
      name: 'ContaContabil',
      component: ContaContabil,
      meta: 'public'
    },
    {
      path: '/contaContabil/:id',
      name: 'ContaContabil/id',
      component: ContaContabil,
      meta: "public"
    },
    {
      path: '/historicosPadrao',
      name: 'HistoricosPadrao',
      component: HistoricosPadrao,
      meta: "public"
    },
    {
      path: '/historicosPadrao/:idFilter',
      name: 'HistoricosPadraoFiltrar',
      component: HistoricosPadrao,
      meta: "public"
    },
    {
      path: '/historicoPadrao',
      name: 'HistoricoPadrao',
      component: HistoricoPadrao,
      meta: 'public'
    },
    {
      path: '/historicoPadrao/:id',
      name: 'HistoricoPadrao/id',
      component: HistoricoPadrao,
      meta: "public"
    },
    {
      path: '/lancamentosContabeis',
      name: 'LancamentosContabeis',
      component: LancamentosContabeis,
      meta: "public"
    },
    {
      path: '/lancamentosContabeis/:idFilter',
      name: 'LancamentosContabeisFiltrar',
      component: LancamentosContabeis,
      meta: "public"
    },
    {
      path: '/lancamentoContabil',
      name: 'LancamentoContabil',
      component: LancamentoContabil,
      meta: "public"
    },
    {
      path: '/lancamentoContabil/:id',
      name: 'LancamentoContabil/id',
      component: LancamentoContabil,
      meta: "public"
    },
    {
      path: '/colaborador',
      name: 'Colaborador',
      component: Colaborador,
      meta: 'public'
    },
    {
      path: '/colaboradores',
      name: 'Colaboradores',
      component: Colaboradores,
      meta: "public"
    },
    {
      path: '/colaboradores/horasExtras',
      name: 'Colaboradores_HorasExtras',
      component: Colaboradores_HorasExtras,
      meta: "public"
    },
    {
      path: '/colaborador/:id',
      name: 'Colaborador/id',
      component: Colaborador,
      meta: 'public'
    },
    {
      path: '/colaborador/apontamentos/:id',
      name: 'Colaborador_Apontamentos',
      component: Colaborador_Apontamentos,
      meta: "public"
    },
    {
      path: '/Agendas',
      name: 'Agendas',
      component: Agendas
    },
    {
      path: '*',
      name: 'Error',
      component: Home,
      meta: "public"
    }
  ],

  scrollBehavior: to => {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

const routerGo = router.go

router.go = (...args) => {
  goBack = !isNaN(args[0]) && args[0] < 0 ? args[0] : 0
  routerGo.apply(router, args);
}

router.beforeEach((to, from, next) => {
  to.query.from = from;
  const toName = to.name.split("/")
  const fromName = from.name ? from.name.split("/") : [];

  if (goBack >= 0) {
    variaveis.state.rotas.push({
      ...to,
      icon: null
    });
  } else {
    goBack -= 1
    variaveis.state.rotas = variaveis.state.rotas.filter((rota, i, arr) => i <= arr.length + goBack);
  }

  goBack = 0;

  if (!variaveis.state.routeReplace && (toName[0] == fromName[0] && fromName[0] != "Home" && toName.length == 1)
    || (variaveis.state.routeIgnore.includes(to.name))) {
    variaveis.state.routeIgnore = [];
    router.go(-1);
    return
  }

  variaveis.state.routeReplace = false;

  if (!from.name && to.meta != "public") {
    router.push('/')
  }

  if (!variaveis.state.idPessoaParceiro) {
    const page = paginas.state.paginas.find(pagina => pagina.nome == to.name.toLowerCase())

    if (page) {
      const paginasDireitos = paginas.state.paginas_direitos.filter(paginaDireito => paginaDireito.idPai == page.id)

      if (paginasDireitos.length && !paginasDireitos.find(paginaDireito => !paginaDireito.idAtuacao)) {
        router.push('/oops')
        return
      }
    }
  }

  if (from && from.name == "Home") {
    to.query.home = true;
  }

  next()
})

export default router