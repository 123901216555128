<!-- COD: FDS 26/07/2022 Produto_Kardex -->
<!-- *VER: JER 28/07/2022 Produto_Kardex -->
<!-- /11/ -->

<template>
  <c-base-list :funcs="funcs" :idPage="idPage" :idPageAlt="idPageAlt">
    <template #header="{ context }">
      <!-- tipos kardex -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" align="center">
          <v-chip-group
            v-model="tipoKardex"
            mandatory
            style="width: fit-content"
            @change="changeTipoKardex(context)"
          >
            <v-chip
              v-for="(tipo, i) in tiposKardex"
              :key="i"
              class="primary text-overline"
              :class="
                tipoKardex != tipo.value ? 'primary--text' : 'white--text'
              "
              :outlined="tipoKardex != tipo.value"
              :value="tipo.value"
              @click.stop
            >
              {{ translate(tipo.text) }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </template>

    <template #search="{ context }">
      <!-- agrupar -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" cols="4">
          <c-switch
            v-model="agrupar"
            label="agrupar"
            @click="changeAgrupar(context)"
          />
        </v-col>
      </v-row>
    </template>

    <template v-slot="{ dataset: produto }">
      <c-produto-page-kardex-view :produto="produto" />
    </template>
  </c-base-list>
</template>

<script>
/// IMPORT
import CProdutoPageKardexView from "@/components/pages/produto/pages/CProdutoPageKardexView";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Produto_Kardex",

  /// COMPONENTS
  components: {
    CProdutoPageKardexView,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("produtoKardex").id,
      idPageAlt: getPagina("produto").id,

      funcs: {
        fnParams: () => {
          const params = {
            agrupar: this.agrupar,
            idProduto: this.$route.params.id,
            tipoKardex: this.tipoKardex,
            totais: this.totais,
          };

          return JSON.stringify(params);
        },

        snackbarShowed: (context) => {
          return context.snackbar.show;
        },
      },

      agrupar: false,
      tipoKardex: false,
      tiposKardex: [
        { value: true, text: "contábil" },
        { value: false, text: "gerencial" },
      ],
      totais: false,
    };
  },

  ///METHODS
  methods: {
    changeAgrupar(context) {
      context.params = this.funcs.fnParams();
      context.clickSearch();
    },

    changeTipoKardex(context) {
      context.params = this.funcs.fnParams();
      context.clickSearch();
    },
  },
};
</script>