<!-- COD: FDS 05/08/2022 Anexos -->
<!-- *VER: JER 10/08/2022 Anexos -->
<!-- /22/ -->

<template>
  <div
    v-touch="{
      down: ($event) => swipe('down', swipeRefresh, $event),
      up: ($event) => swipe('up', swipeInject, $event),
    }"
    class="anexos"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" :title="page.nomeAlt" />

    <!-- /// LIST -->
    <v-container class="pa-0">
      <v-row v-bind="prop.row">
        <!-- icone -->
        <v-col v-bind="prop.col" cols="1">
          <v-icon large>
            {{ icon }}
          </v-icon>
        </v-col>
        <!-- registro -->
        <v-col v-bind="prop.col" cols="11">
          <span class="font-weight-medium text-body-1">
            {{ registro }}
            #
            {{ idRegistro }}
          </span>
        </v-col>
      </v-row>

      <v-row v-bind="prop.row">
        <!-- quantidade anexos -->
        <v-col v-bind="prop.col" cols="3">
          <v-badge
            class="ml-5"
            color="grey"
            :content="anexos.length.toString()"
            left
          ></v-badge>
        </v-col>
        <!-- registro -->
        <v-col v-bind="prop.col" v-if="anexos.length > 1" align="end" cols="9">
          <span class="red--text text-body-2">
            Arraste e solte para ordenar
          </span>
        </v-col>
      </v-row>

      <draggable
        v-bind="dragOptions"
        v-model="anexos"
        @end="dragEnd($event)"
        @start="dragStart"
      >
        <transition-group type="transition">
          <v-card
            v-for="anexo in anexos"
            class="pl-0"
            :key="anexo.id"
            router
            text
            :to="`/anexo/${anexos.length}/${anexo.id}`"
          >
            <v-row v-bind="prop.row">
              <v-col
                v-bind="prop.col"
                v-if="
                  anexo.idTipo == getTipo_item('anexoArquivo').id &&
                  anexo.tipo.includes('image')
                "
                cols="3"
                md="2"
              >
                <v-img :src="anexo.endereco" max-width="100" />
              </v-col>
              <v-col v-bind="prop.col" v-else cols="2" md="1">
                <v-icon x-large class="primary--text pa-0">
                  {{ iconeAnexo(anexo.nome) }}
                </v-icon>
              </v-col>
              <v-col
                v-bind="prop.col"
                :cols="
                  anexo.idTipo == getTipo_item('anexoArquivo').id &&
                  anexo.tipo.includes('image')
                    ? 7
                    : 8
                "
                :md="
                  anexo.idTipo == getTipo_item('anexoArquivo').id &&
                  anexo.tipo.includes('image')
                    ? 8
                    : 9
                "
              >
                <span class="text-body-1">
                  {{ anexo.nome }}
                </span>
              </v-col>
              <v-col v-bind="prop.col" align="end" cols="1">
                <v-btn icon @click="clickOpen($event, anexo.endereco)">
                  <v-icon> mdi-open-in-new </v-icon>
                </v-btn>
              </v-col>
              <v-col v-bind="prop.col" align="end" cols="1">
                <v-icon large> mdi-drag-horizontal-variant </v-icon>
              </v-col>
            </v-row>
          </v-card>
        </transition-group>
      </draggable>
    </v-container>

    <c-the-navbar
      bottom
      mode="page"
      :pageModeView="modeView"
      :slots="buttonsBar.length"
      title=""
    >
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-btn
          v-if="!Array.isArray(button)"
          icon
          :key="i"
          @click="button.clickFunction(button)"
        >
          <v-icon :color="button.options.color || 'white'" large>
            {{ button.options.icon }}
          </v-icon>
        </c-btn>
        <v-menu v-else :key="i" offset-y top>
          <template #activator="{ on }">
            <c-btn v-on="on" icon>
              <v-icon large>mdi-dots-horizontal</v-icon>
            </c-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, i) in button"
              :key="i"
              @click="clickOption(option)"
            >
              <v-icon :class="`${option.options.color}--text`">
                {{ option.options.icon }}
              </v-icon>
              <v-list-item-title class="ml-2">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </c-the-navbar>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CTheNavbar from "@/components/bases/CTheNavbar";
import draggable from "vuedraggable";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "Anexos",

  /// COMPONENTS
  components: { draggable, CTheNavbar },

  /// MIXINS
  mixins: [mixinSwipe, mixinUsuarios],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("anexos").id,
      idPageAlt: getPagina("anexo").id,

      anexos: [],
      anexosFiltered: null,
      anexosTemp: [],
      //?b Obter extensoes de forma dinamica
      arquivoTipos: [
        { extensao: null, icone: "mdi-link-variant" },
        { extensao: ".jpg", icone: null },
        { extensao: ".jpeg", icone: null },
        { extensao: ".png", icone: null },
        { extensao: ".pdf", icone: "mdi-file-pdf" },
        { extensao: ".bai2", icone: null },
        { extensao: ".ofc", icone: null },
        { extensao: ".json", icone: null },
        // no futuro, poderemos aceitar outros tipos de arquivo
        // { extensao: ".xlsx", icone: "mdi-file-excel" },
        // { extensao: ".docx", icone: "mdi-file-word" },
        // { extensao: ".pptx", icone: "mdi-file-powerpoint" },
        // { extensao: ".xls", icone: "mdi-file-excel" },
        // { extensao: ".doc", icone: "mdi-file-word" },
        // { extensao: ".ppt", icone: "mdi-file-powerpoint" }
      ],
      dragging: false,
      dragInicio: null,
      icon: null,
      idRegistro: null,
      idTabela: null,
      limit: 10,
      links: [
        {
          icon: "mdi-help",
          text: "ajuda",
          action: "ajuda",
          color: "",
          estados: [],
          perfis: [],
        },
      ],
      modeView: true,
      registro: null,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getPagina_documentos: "paginas/getPagina_documentos",
      getPagina: "paginas/getPagina",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true,
        null
      ).sortKeys('"order"');
    },

    buttonsBar() {
      let ret;

      if (this.modeView) {
        ret = [
          ...this.buttons.map((button) => {
            return {
              ...button,
              clickFunction: this.clickOption,
            };
          }),
          this.options,
        ];
      } else {
        ret = [
          {
            clickFunction: this.clickEditView,
            options: {
              icon: "mdi-close",
              color: "red accent-3",
            },
          },
          {
            clickFunction: this.submit,
            options: {
              icon: "mdi-check",
              color: "green accent-3",
            },
          },
        ];
      }

      return ret;
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    page() {
      return this.getPagina(this.idPage);
    },

    pageAlt() {
      return this.getPagina(this.idPageAlt);
    },

    options() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        false,
        null
      ).sortKeys('"order"');
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    anexosFilter(offset = 0) {
      this.setVar({ overlay: true });
      const idContexto = "*";
      const idFilter = 0;
      const idOrder = 0;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;
      const params = JSON.stringify({
        idTabela: this.idTabela,
        idRegistro: this.idRegistro,
      });

      const url = `${
        this.getConst("app").baseUrlBackend
      }/anexos/search/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
        this.limit
      }/${params}`;

      axios
        .get(url)
        .then((res) => {
          const result = res.data;

          if (offset == 0) {
            this.anexos = [];
          }

          result.forEach((element) => {
            this.anexos.push(element);
          });

          this.anexosTempControle();
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });
    },

    anexosTempControle() {
      // deep clone object: JSON.parse e JSON.stringify não são os métodos mais eficientes, mas funcionam
      if (this.modeView)
        this.anexosTemp = JSON.parse(JSON.stringify(this.anexos));
      else this.anexos = JSON.parse(JSON.stringify(this.anexosTemp));
    },

    clickEditView() {
      this.anexosTempControle();
      this.modeView = !this.modeView;
    },

    clickOpen(event, endereco) {
      event.preventDefault();
      event.stopPropagation();
      window.open(endereco, "_blank");
    },

    clickOption(paginaFeature) {
      const action =
        paginaFeature.options?.click || paginaFeature.options?.route;

      if (action == "clickIncluir") {
        this.$router.push(
          `/${this.pageAlt.nome}/${this.idTabela}/${this.idRegistro}/${this.anexos.length}`
        );
      } else if (action == "clickAjuda") {
        const paginaDocumento = this.getPagina_documentos(
          this.getPagina("ajuda")?.id
        ).find(
          (paginaDocumento) =>
            paginaDocumento.options.feature &&
            this.getFeature(paginaDocumento.options.feature)?.id ==
              this.getFeature("optionAnexos")?.id
        );

        const url = paginaDocumento ? `/ajuda#${paginaDocumento.id}` : "/ajuda";
        this.$router.push(encodeURI(url));
      }
    },

    dragEnd(event) {
      if (event.newIndex == event.oldIndex) {
        if (this.modeView) {
          const dragFim = new Date();

          if (dragFim - this.dragInicio <= 100) {
            this.$router.push(
              `/anexo/${this.anexos.length}/${this.anexos[event.newIndex].id}`
            );
          }
        }
      } else {
        this.modeView = false;
      }

      this.dragging = false;
    },

    dragStart() {
      this.dragInicio = new Date();
      this.dragging = true;
    },

    icone(idTabela) {
      return idTabela > 0 ? this.getTabela(idTabela).icon : "";
    },

    iconeAnexo(arquivoNome) {
      const posicao = arquivoNome.lastIndexOf(".");
      const extensao =
        posicao >= 0 ? arquivoNome.substring(posicao).toLowerCase() : null;
      return this.arquivoTipos.find((element) => element.extensao == extensao)
        .icone;
    },

    submit() {
      this.setVar({ overlay: true });
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");

      const url = `${this.getConst("app").baseUrlBackend}/anexos/ordens`;

      const dataset = {
        metaDados: {
          idUsuario,
          idPessoaParceiro,
        },
        anexos: this.anexos,
      };

      axios
        .put(url, dataset)
        .then((res) => {
          const result = res.data;
          this.anexos = [];
          result.forEach((element) => {
            this.anexos.push(element);
          });
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.modeView = !this.modeView;
          this.setVar({ overlay: false });
        });
    },

    swipeInject() {
      if (!this.dragging) {
        this.anexosFilter(this.anexos.length);
      }
    },

    swipeRefresh() {
      if (!this.dragging) {
        this.anexosFilter();
      }
    },
  },

  /// MOUNTED
  mounted() {
    this.idTabela = parseInt(this.$route.params.idTabela);
    this.idRegistro = parseInt(this.$route.params.idRegistro);
    this.registro = this.$route.params.registro;

    if (this.$route.query.page.idFeature) {
      this.icon = this.getFeature(
        this.$route.query.page.idFeature
      ).options?.icon;
    }

    this.anexosFilter();
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0;
}
</style>