<!-- COD: LYF 12/08/2022 CCupomView -->
<!-- *VER: JER 19/08/2022 CCupomView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.data,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- codigo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip
          class="darken-3 white--text text-body-1"
          :class="corCodigo"
          :outlined="false"
        >
          {{ dataset.main.detail.codigo }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- descricao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.descricao }}
        </span>
      </v-col>
    </v-row>

    <!-- quantidade //? ver a quantidade total-->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.main.detail.idPessoaParceiro == getVar('idPessoaParceiro') &&
        dataset.main.detail.quantidade
      "
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          disponível
          {{
            dataset.main.detail.quantidade - (dataset.aux.pedidos.length || 0)
          }}
          /
          {{ dataset.main.detail.quantidade }}
        </span>
      </v-col>
    </v-row>

    <!-- valor -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valor">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.valor, "currency") }} OFF
        </span>
      </v-col>
    </v-row>

    <!-- percentual -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.percentual">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.percentual / 100, "percent", 1) }}
          OFF
        </span>
      </v-col>
    </v-row>

    <!-- pessoa fornecedor nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoaFornecedor_nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoaFornecedor_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa cliente nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoaCliente_nome">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoaCliente_nome }}
        </span>
      </v-col>
    </v-row>

    <!-- gerador -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPai">
      <v-col v-bind="prop.col" align="center">
        <c-btn color="primary" text @click="clickGerador">
          gerador {{ dataset.main.detail.idPai }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- pedido -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPedido">
      <v-col v-bind="prop.col" align="center">
        <c-btn color="primary" text @click="clickPedido">
          pedido {{ dataset.main.detail.idPedido }}
        </c-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CCupomView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    corCodigo() {
      let color = "";
      if (!this.dataset.main.detail.idPessoaParceiro) {
        color = "black";
      } else if (
        this.dataset.main.detail.idPessoaParceiro &&
        !this.dataset.main.detail.idPessoaFornecedor
      ) {
        color = "blue";
      } else if (
        this.dataset.main.detail.idPessoaParceiro &&
        this.dataset.main.detail.idPessoaFornecedor
      ) {
        color = "green";
      }

      return color;
    },
  },

  /// METHODS
  methods: {
    clickGerador() {
      this.$router.push(`/cupom/${this.dataset.main.detail.idPai}`);
    },

    clickPedido() {
      this.$router.push(`/pedido/${this.dataset.main.detail.idPedido}`);
    },
  },
};
</script>