// COD: BJB 15/08/2022 prototypes
// *VER: JER 19/08/2022 prototypes
// /23/

/// IMPORT
import constantes from './store/modules/constantes'

/// SORTKEYS
Array.prototype.sortKeys = function (params = '"id"', normalize = true) {
  const fnNormalize = value => (normalize && typeof value === 'string')
    ? value.normalizeBr()
    : value;

  const asc = (a, b) => (fnNormalize(a) > fnNormalize(b));
  const desc = (a, b) => (fnNormalize(a) < fnNormalize(b));

  // params = {grupo: 1, valor: -1, id: 1} (1-ascending, -1-descending)
  // params = JSON.parse(params);
  params = typeof params == 'string' ? Object.fromEntries([[params, 1]]) : params;
  const keys = Object.keys(params);

  const sortKeys = (a, b) => {
    return keys
      .reduce((order, key) => {
        if (!order) {
          //?e solução provisoria para ordenar por numeros.
          let aValue = a[key];
          let bValue = b[key];

          if (["id", "idPublicado"].includes(key)) {
            aValue = parseInt(aValue);
            bValue = parseInt(bValue);
          } else if (["valor", "valorTotal"].includes(key)) {
            aValue = parseFloat(aValue.clean());
            bValue = parseFloat(bValue.clean());
          }

          const func = params[key] == 1 ? desc : asc
          order = func(aValue, bValue) ? -1 : 0
        }

        return order;
      }, 0);
  };

  return this.sort((a, b) => sortKeys(a, b));
};

/// CLEAN (Number)
Number.prototype.clean = function () {
  return this;
};

/// CLEAN (String)
String.prototype.clean = function (param = -1) {
  // param: (number - decimal do número, string - caracteres da string) a serem limpos
  if (typeof param == 'number') {
    let sinal = "";
    const index = this.split("").findIndex(value => value.trim() && !isNaN(value));

    if (index > 0) {
      sinal = this[index - 1] == "-" ? "-" : ""
    }

    const numero = param >= 0
      ? (parseInt(this.replace(/\D/g, "")) / Math.pow(10, param))
      : this.replace(/\D/g, "");

    return `${sinal}${numero}`;
  } else {
    return param.split("")
      .reduce((acc, caractere) => acc.replaceAll(caractere, ""), this);
  }
};

/// FIRSTLETTERTOLOWERCASE
String.prototype.firstLetterToLowerCase = function () {
  const temp = this.split("");
  temp[0] = temp[0].toLowerCase();
  return temp.join("");
};

/// FIRSTLETTERTOUPPERCASE
String.prototype.firstLetterToUpperCase = function () {
  const temp = this.split("");
  temp[0] = temp[0].toUpperCase();
  return temp.join("");
};

/// MASK
// String.prototype.mask = function (mask, clean = false, validacao = false) {
String.prototype.mask = function (mask, options = {}) {
  if (!mask) {
    return this;
  }

  const tokens = JSON.parse(JSON.stringify(constantes.state.config.tokens));
  const constTokens = constantes.state.config.tokens

  Object.keys(tokens).forEach(token => {
    if (tokens[token].simbolos != undefined) {
      tokens[token].simbolos = options[token]?.simbolos || tokens[token].simbolos;
      constTokens[token].transform = options[token]?.transform ? eval(options[token]?.transform) : constTokens[token].transform;
      tokens[token].pattern = tokens[token].pattern.replace("<>", tokens[token].simbolos);

      if (tokens[token].legenda.includes("<>")) {
        const simbolos = tokens[token].simbolos ? ` ou ${tokens[token].simbolos}` : "";
        tokens[token].legenda = tokens[token].legenda.replace("<>", simbolos);
      } else {
        tokens[token].legenda += tokens[token].simbolos;
      }

      tokens[token].legenda = options[token]?.legenda || tokens[token].legenda;
    }
  })

  let ret = this.toString();
  let opcional = "";

  const originalMask = mask.replace(/(\(.+\)\?)/g, function () {
    return arguments[1].replace(/[()?]/g, "");
  }).replace(/[!?]/g, "");

  if (!options?.validacao) {
    opcional = "?";

    mask = mask.replace(/(\(.+\)\?)/g, function () {
      return `${arguments[1]
        .replace(/[()?]/g, "")
        .split("")
        .join("?")}?`;
    })
  }

  const strTokens = (addToken = "") => Object.keys(tokens).join(`${addToken}|`).replace("*", "\\*").replace("$", "\\$") + addToken;

  const tokenToPattern = (texto, addPattern = opcional) => {
    return texto.match(new RegExp(`(${strTokens()}|.)(?!!)+?`, "g"))
      .reduce((acc, token) => acc += `${tokens[token]?.pattern || token}${addPattern}`, "");
  };

  let indexDynamicToken = 0;

  // ttt: prefixo que indica um dynamicToken
  const insertDynamicToken = (token, pattern, length) => {
    const dynamicToken = `ttt${indexDynamicToken}`;
    mask = mask.replace(token, dynamicToken);
    tokens[dynamicToken] = { pattern, length };
    indexDynamicToken++;
  };

  (mask.match(/\[.+\]/) || []).forEach(maskMatch => {
    const maskMatchClean = maskMatch.replace(/\[\]/g, "");
    const pattern = tokenToPattern(maskMatchClean.replace(/\[\]/g, ""), "");
    insertDynamicToken(maskMatch, pattern, maskMatchClean.length);
  });

  // tokens não sucedidos por ! (?!!), 1 ou mais (+)
  const regexSeparadores = () => new RegExp(`${strTokens("(?!!)+")}`, "g");

  // grupos de tokens 1 ou mais (+)
  const regexGrupos = () => new RegExp(`(${strTokens()})+`, "g");

  const maskLength = () => mask
    .replace(/(\(.+\)\?)/g, function () {
      return arguments[1].replace(/[()?]/g, "")
    })
    .replace(/(ttt\d\d?)/g, "#")
    .replace(/(.!)/g, "")
    .match(regexGrupos())
    .join("")
    .replaceAll("?", "")
    .length;

  // verifica se os caracteres opicionais fazem parte da string, 
  // se sim, remove apenas a interrogação, 
  // se não, remove a interrogação e o caractere opcional
  while (mask.includes("?") && maskLength() > this.length) {
    if (mask.match(/\(.+\)\?/)) {
      mask = mask.replace(/(\(.+\)\?)/g, "");
    } else {
      mask = mask.replace(new RegExp(`(${strTokens()})\\?`), "");
    }
  }

  (mask.match(/(\(.+\)\?)/g) || []).forEach(maskMatch => {
    const pattern = tokenToPattern(maskMatch.replaceAll("?", ""), "");
    insertDynamicToken(maskMatch, pattern, maskMatch.replace(/(\(.+\)\?)/g, "").length);
  });

  mask = mask.replaceAll("?", "");

  const separadores = mask
    .split(regexSeparadores())
    .filter(sep => !!sep)
    .map(sep => sep.replace(/!/g, ""));

  mask = mask.replace(/(.!)/g, "");
  const gruposTemp = mask.match(regexGrupos());

  if (gruposTemp.length > separadores.length || (separadores[gruposTemp.length - 1] && originalMask.endsWith(separadores[gruposTemp.length - 1]))) {
    separadores.unshift("");
  }

  if (separadores.length >= gruposTemp.length) {
    separadores[0] = originalMask.startsWith(separadores[0]) ? separadores[0] : "";

    for (let i = 0; separadores[0][i] && ret.startsWith(separadores[0][i]); i++) {
      ret = ret.replace(separadores[0][i], "");
    }

    if (separadores[gruposTemp.length]) {
      separadores[gruposTemp.length] = originalMask.endsWith(separadores[gruposTemp.length]) ? separadores[gruposTemp.length] : "";
    }
  }

  separadores.forEach(separador => {
    ret = ret.replaceAll(separador, "");
  })

  let separadoresIndexAdded = 0
  const grupos = gruposTemp.reduce((acc, grupo, i) => {
    const subGrupo = grupo.match(new RegExp(strTokens("+"), "g"))
    const lengthSubGrupo = subGrupo.length
    if (lengthSubGrupo == 1) {
      acc.push(grupo)
    } else {
      acc.push(...subGrupo);
      const separadoresSubGrupo = Array(lengthSubGrupo - 1).fill("");
      const indexSeparadores = i + separadoresIndexAdded + 1
      separadores.splice(indexSeparadores, 0, ...separadoresSubGrupo);
      separadoresIndexAdded += separadoresSubGrupo.length;
    }

    return acc;
  }, []);

  const regexMask = new RegExp(
    grupos
      .map(grupo => {
        grupo = tokenToPattern(grupo);
        return grupo.startsWith("(") ? grupo : `(${grupo})${opcional}`;
      })
      .join("")
  );

  const fnGruposMask = function () {
    // arguments: array obtido pela função do replace, contendo os grupos da string nas posições de 1 a n
    const aplicarMascara = !options?.validacao ||
      (!grupos.some((_, i) => !arguments[i + 1]) &&
        grupos.reduce((acc, _, i) => acc += arguments[i + 1] || "", "").length == ret.length);

    const verifyArgument = (i) => {
      return (i == 0 || (arguments[i] && arguments[i].length == (tokens[grupos[i - 1]]?.length || grupos[i - 1].length)));
    }

    const getGrupoMasked = (grupo, i) => {
      const separador = !options?.clean ? separadores[i] : "";
      const argument = constTokens[grupo[0]]?.transform ? constTokens[grupo[0]].transform(arguments[i + 1]) : arguments[i + 1];
      const separadorFinal = i == (grupos.length - 1) && !options?.clean ? (separadores[grupos.length] || "") : ""

      return `${separador}${argument}${separadorFinal}`
    }

    ret = aplicarMascara
      ? grupos
        .map((grupo, i) => {
          return arguments[i + 1] && verifyArgument(i)
            ? getGrupoMasked(grupo, i)
            : "";
        })
        .join("")
      : "";

    return ret;
  };

  if (ret.match(regexMask)) {
    ret.replace(regexMask, fnGruposMask);
  } else {
    ret = "";
  }

  if (!ret && !options?.clean && !options?.validacao && !!separadores[0]) {
    ret = separadores[0];
  }

  return ret;
};

/// MAXLENGTH
String.prototype.maxLength = function (max) {
  let complemento = this.length > max ? '...' : '';
  return `${this.substring(0, max)}${complemento}`;
};

/// NUMBERTOWORDS
String.prototype.numberToWords = function (locale = "pt-BR", moeda = {}) {
  /*
  https://clevert.com.br/t/pt-br/numbers-to-words/index/pt
  https://www.normaculta.com.br/numeros-por-extenso/
  https://www.normaculta.com.br/como-escrever-o-dinheiro-por-extenso/
  
  1. A escrita de um número grande deverá ser feita por classes (grupos).
     - trilhão bilhão milhão milhar inteiro fração
     - R$000.111.222.333.444,55
  2. A conjunção "e" deverá ser usada entre centenas, dezenas e unidades.
     - R$123,00: cento "e" vinte "e" três reais
  3. A conjunção "e" deverá ser usada entre os milhares e as centenas apenas
     quando o número acabar nas centenas.
     - R$1.200,00: um mil "e" duzentos reais
  4. Não deverá ser usada a vírgula na escrita de numerais por extenso.
     - R$123.456.789.123.456,78: cento e vinte e três trilhões quatrocentos e cinquenta e seis bilhões
       setecentos e oitenta e nove milhões cento e vinte e três mil quatrocentos e cinquenta e seis reais
       "e" setenta e oito centavos
  5. A partir dos milhões, deverá ser usada a preposição "de" na escrita por extenso
     de números redondos.
     - R$2.000.000,00: dois milhões "de" reais
  6. Na escrita por extenso de valores inferiores a um real, havendo só a indicação
     dos centavos, deverá ser usada a preposição "de" e a indicação da moeda (real).
     - R$0,12: doze centavos "de real"
  */

  const extensos = {
    "pt-BR": {
      palavras: {
        "000": "", "**0": "zero", "001": "um", "**1": "um", "002": "dois", "003": "três", "004": "quatro",
        "005": "cinco", "006": "seis", "007": "sete", "008": "oito", "009": "nove",
        "010": "dez", "011": "onze", "012": "doze", "013": "treze", "014": "quatorze",
        "015": "quinze", "016": "dezeseis", "017": "dezesete", "018": "dezoito", "019": "dezenove",
        "020": "vinte", "030": "trinta", "040": "quarenta", "050": "cinquenta",
        "060": "sessenta", "070": "setenta", "080": "oitenta", "090": "noventa",
        "100": "cento", "1**": "cem", "200": "duzentos", "300": "trezentos", "400": "quatrocentos",
        "500": "quinhentos", "600": "seiscentos", "700": "setecentos", "800": "oitocentos", "900": "novecentos"
      },
      grupos: [
        // ["não tem", "singular", "plural"],
        ["", "trilhão", "trilhões"],
        ["", "bilhão", "bilhões"],
        ["", "milhão", "milhões"],
        ["", "mil", "mil"],
        ["", "real", "reais"],
        ["", "centavo", "centavos"],
      ],
      // ["tri/bi/milhão", "centena", "dezena", "unidade precedida por centena", "unidade precedida por dezena"],
      prefixos: ["de", "e", "e", "e", "e"],
      // ["inteiro/fracao", "fracao"],
      sufixos: ["e", "de"],
    },
    "en-US": {
      palavras: {
        "000": "", "**0": "zero", "001": "one", "**1": "one", "002": "two", "003": "three", "004": "four",
        "005": "five", "006": "six", "007": "seven", "008": "eight", "009": "nine",
        "010": "ten", "011": "eleven", "012": "twelve", "013": "thirteen", "014": "fourteen",
        "015": "fifteen", "016": "sixteen", "017": "seventeen", "018": "eighteen", "019": "nineteen",
        "020": "twenty", "030": "thirty", "040": "forty", "050": "fifty",
        "060": "sixty", "070": "seventy", "080": "eighty", "090": "ninety",
        "100": "one hundred", "1**": "one hundred", "200": "two hundred", "300": "three hundred", "400": "four hundred",
        "500": "five hundred", "600": "six hundred", "700": "seven hundred", "800": "eight hundred", "900": "nine hundred"
      },
      grupos: [
        // ["não tem", "singular", "plural"],
        ["", "trilion", "trilions"],
        ["", "bilion", "bilions"],
        ["", "milion", "milions"],
        ["", "thousand", "thousand"],
        ["", "dollar", "dollars"],
        ["", "cent", "cents"],
      ],
      // ["tri/bi/milhão", "centena", "dezena", "unidade precedida por centena", "unidade precedida por dezena"],
      prefixos: ["", "", "", "", ""],
      // ["inteiro/fracao", "fracao"],
      sufixos: ["and", "of"],
    },
    "es-ES": {
      palavras: {
        "000": "", "**0": "cero", "001": "un", "002": "dos", "003": "tres", "004": "cuatro",
        "005": "cinco", "006": "seis", "007": "siete", "008": "ocho", "009": "nueve",
        "010": "diez", "011": "once", "012": "doce", "013": "trece", "014": "catorze",
        "015": "quince", "016": "dieciséis", "017": "diecisiete", "018": "dieciocho", "019": "diecinueve",
        "020": "veinte", "021": "veintiún", "022": "veintidós", "023": "veintitrés", "024": "veinticuatro",
        "025": "veinticinco", "026": "veintiséis", "027": "veintisiete", "028": "veintiocho", "029": "veintinueve",
        "030": "treinta", "040": "cuarenta", "050": "cincuenta",
        "060": "sesenta", "070": "setenta", "080": "ochenta", "090": "noventa",
        "100": "ciento", "1**": "cien", "200": "doscientos", "300": "trescientos", "400": "cuatrocientos",
        "500": "quinientos", "600": "seiscientos", "700": "setecientos", "800": "ochocientos", "900": "novecientos"
      },
      grupos: [
        // ["não tem", "singular", "plural"],
        ["", "billón", "billones"],  // dúvida
        ["", "mil millones", "mil millones"],
        ["", "millón", "millones"],
        ["", "mil", "mil"],
        ["", "euro", "euros"],
        ["", "centavo", "centavos"],
      ],
      // ["tri/bi/milhão", "centena", "dezena", "unidade precedida por centena", "unidade precedida por dezena"],
      prefixos: ["", "", "", "", "y"],
      // ["inteiro/fracao", "fracao"],
      sufixos: ["con", "de"],
    },
  }

  const toPalavra = (parte) => {
    return extensos[locale].palavras[parte] || "";
  }

  const toGrupo = (i, iGrupo) => {
    return extensos[locale].grupos[i][iGrupo] || "";
  }

  const toParte = (parte, iPrefixo, iSufixo, iGrupo, i) => {

    const prefixo = extensos[locale].prefixos[iPrefixo];

    if (prefixo) {
      partes.push(prefixo);
    }

    let palavra;

    if (!parte && iGrupo) {
      palavra = toGrupo(i, iGrupo)
    } else if (parte) {
      palavra = toPalavra(parte)
    }

    palavras.push(palavra);
    partes.push(palavra);

    let sufixo = extensos[locale].sufixos[iSufixo];

    if (sufixo) {
      if (["de", "of"].includes(sufixo)) {
        sufixo += ` ${extensos[locale].grupos[4][1]}`;
      }

      partes.push(sufixo);
    }
  }

  const toExtenso = (grupo, i) => {
    partes = [];
    palavras = [];
    let palavra;
    let parte;
    let iGrupo;
    let iPrefixo;
    let iSufixo;

    // zero
    if (inteiro == 0 && fracao == 0 & i == 4) {
      parte = "**0";
      iPrefixo = null;
      toParte(parte, iPrefixo);
    } else if (grupo == "100") {
      // cem
      parte = "1**";
      iPrefixo = (i == 4 && inteiro > 1000 && inteiro % 100 == 0) ? 1 : null;
      toParte(parte, iPrefixo);
    } else {
      // centena
      parte = `${grupo[0]}00`;
      iPrefixo = (i == 4 && inteiro > 1000 && inteiro < 1000000 && inteiro % 100 == 0) ? 1 : null;
      toParte(parte, iPrefixo);

      // dezena-unidade
      parte = `0${grupo[1]}${grupo[2]}`;
      palavra = toPalavra(parte);

      if (palavra) {
        iPrefixo = (palavras[0]) ? 2 : null;
        toParte(parte, iPrefixo);
      } else {
        // dezena
        parte = `0${grupo[1]}0`;
        palavra = toPalavra(parte);

        if (palavra) {
          iPrefixo = (palavras[0]) ? 2 : null;
          toParte(parte, iPrefixo);
        }

        // unidade
        parte = `00${grupo[2]}`;
        palavra = toPalavra(parte);

        if (palavra) {
          // unidade precedida por centena (3) ou unidade precedida por dezena (4)
          iPrefixo = (palavras[0] && !palavras[1]) ? 3 : (palavras[1]) ? 4 : null;
          toParte(parte, iPrefixo);
        }
      }
    }

    iPrefixo = null;
    iSufixo = null;
    iGrupo = parseInt(grupo) > 1 ? 2 : parseInt(grupo) > 0 ? 1 : 0;

    if (i == 4) {
      iGrupo = inteiro > 1 ? 2 : inteiro > 0 ? 1 : 0;
      iPrefixo = ((parseInt(grupos[0]) > 0 || parseInt(grupos[1]) > 0 || parseInt(grupos[2]) > 0) &&
        (parseInt(grupos[3]) == 0 && parseInt(grupos[4]) == 0)
      ) ? 0 : null;
      iSufixo = (inteiro > 0 && fracao > 0) ? 0 : null;
    } else if (i == 5) {
      iSufixo = (inteiro == 0 && fracao > 0) ? 1 : null;
    }

    toParte(null, iPrefixo, iSufixo, iGrupo, i);

    const ret = partes
      .filter(item => item)
      .reduce((acc, parte) => acc += ` ${parte}`, "")
      .trim();

    return ret;
  }

  let partes = [];
  let palavras = [];

  if (Object.keys(moeda).length == 2) {
    extensos[locale].grupo[4][1] = moeda.inteiro.singular;
    extensos[locale].grupo[4][2] = moeda.inteiro.plural;
    extensos[locale].grupo[5][1] = moeda.fracao.singular;
    extensos[locale].grupo[5][2] = moeda.fracao.plural;
  }

  const temp = parseFloat(this).toFixed(2).split(".");
  const inteiro = parseInt(temp[0]);
  const fracao = parseInt(temp[1]);
  temp[0] = temp[0].padStart(15, "0");
  temp[1] = temp[1].padStart(3, "0");

  const grupos = temp
    .join("")
    .match(/.{1,3}/g);

  const ret = grupos
    .filter(item => item)
    .reduce((acc, grupo, i) => acc += ` ${toExtenso(grupo, i)}`, "")
    .trim();

  return ret;
}

/// NORMALIZEBR
String.prototype.normalizeBr = function () {
  return this.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
};

Date.prototype.toLocaleISOString = function () {
  var tzoffset = (this).getTimezoneOffset() * 60000; //offset in milliseconds
  return (new Date(this - tzoffset)).toISOString().slice(0, -1);
}