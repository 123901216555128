<!-- COD: BJB 26/07/2022 CProdutoPageInventarioDiferencaEdit -->
<!-- #BUG:# BJB __/08/2022 CProdutoPageInventarioDiferencaEdit: revisar campos -->
<!-- VER: JER __/08/2022 CProdutoPageInventarioDiferencaEdit -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- label localizacao -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">localização</span>
      </v-col>

      <!-- label caixas -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">caixas</span>
      </v-col>

      <!-- label unidades -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">unidades</span>
      </v-col>

      <!-- label inventario -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">inventário</span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" v-for="(localizacao, i) in localizacoes" :key="i">
      <!-- localizacao -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">
          {{ localizacao.localizacao }}
        </span>
      </v-col>

      <!-- caixas -->
      <v-col v-bind="prop.col" cols="3">
        <c-text-money
          v-model="localizacao.caixas"
          label=""
          :max="13"
          :options="{
            length: 11,
            precision: localizacao.decimalEstoque,
            style: 'decimal',
          }"
        />
      </v-col>

      <!-- unidades -->
      <v-col v-bind="prop.col" cols="3">
        <c-text-money
          v-model="localizacao.unidades"
          label=""
          :max="13"
          :options="{
            length: 11,
            precision: localizacao.decimalEstoque,
            style: 'decimal',
          }"
        />
      </v-col>

      <!-- label inventario -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">
          {{ getInventarioLocalizacao(localizacao) }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- label un/cx -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">un/cx</span>
      </v-col>

      <!-- label inventário -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-2">inventário</span>
      </v-col>

      <!-- label estoque -->
      <v-col v-bind="prop.col" v-if="soGerente" cols="3">
        <span class="text-body-2">estoque</span>
      </v-col>

      <!-- label diferenca -->
      <v-col v-bind="prop.col" v-if="soGerente && !!diferenca" cols="3">
        <span :class="`text-body-2 ${diferenca < 0 ? 'red--text' : ''}`"
          >diferença</span
        >
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- unidades por caixa -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-1">
          {{ numberFormat(produto.unidadesPorCaixa, "decimal") }}
        </span>
      </v-col>

      <!-- inventario -->
      <v-col v-bind="prop.col" cols="3">
        <span class="text-body-1">
          {{ totalInventario }}
        </span>
      </v-col>

      <!-- estoquue -->
      <v-col v-bind="prop.col" v-if="soGerente" cols="3">
        <span class="text-body-1">
          {{ numberFormat(produto.estoqueProprio, "decimal") }}
        </span>
      </v-col>

      <!-- diferenca -->
      <v-col v-bind="prop.col" v-if="soGerente && !!diferenca" cols="3">
        <span :class="`text-body-1 ${diferenca < 0 ? 'red--text' : ''}`">
          {{ diferenca }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CProdutoPageInventarioDiferencaEdit",

  /// PROPS
  props: {
    context: { type: Object, required: true },
    datasets: { type: Array, required: true },
    produto: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    diferenca() {
      return this.numberFormat(
        parseFloat(this.totalInventario) -
          parseFloat(this.produto.estoqueProprio),
        "decimal"
      );
    },

    localizacoes() {
      return this.datasets.filter(
        (dataset) => dataset.idProduto == this.produto.idProduto
      );
    },

    soGerente() {
      const perfisApropriados = [
        this.getTipo_item("perfilAdmin").id,
        this.getTipo_item("perfilLogistica").id,
      ];

      const niveisApropriados = [
        this.getTipo_item("nivelPresidente").id,
        this.getTipo_item("nivelDiretor").id,
        this.getTipo_item("nivelGerente").id,
        this.getTipo_item("nivelSupervisor").id,
      ];

      return this.getVar("usuarios")
        .find((usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro"))
        ?.perfis.some(
          (perfil) =>
            perfisApropriados.includes(perfil.idPerfil) &&
            niveisApropriados.includes(perfil.idNivel)
        );
    },

    totalInventario() {
      return this.numberFormat(
        this.localizacoes.reduce(
          (acc, localizacao) =>
            parseFloat((acc += this.getInventarioLocalizacao(localizacao))),
          0
        ),
        "decimal"
      );
    },
  },

  /// METHODS
  methods: {
    getInventarioLocalizacao(localizacao) {
      return this.numberFormat(
        parseFloat(localizacao.caixas || 0) *
          parseFloat(this.produto.unidadesPorCaixa || 0) +
          parseFloat(localizacao.unidades || 0),
        "decimal"
      );
    },
  },
};
</script>