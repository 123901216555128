<!-- COD: FDS 25/07/2022 CProdutoPageGerenciarEdit -->
<!-- *VER: JER 28/07/2022 CProdutoPageGerenciarEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- prazo pagamento -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="produto.prazoPagamento"
          label="prazo pagamento"
          :max="3"
          :options="{
            length: 3,
            precision: 0,
            suffix: 'dias',
          }"
        />
      </v-col>
      <!-- custo reposicao -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="produto.custoReposicao"
          label="custo reposição"
          :max="17"
          :options="{
            length: 11,
            precision: produto.decimalValor,
            style: 'currency',
          }"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- margem contribuicao -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="produto.margemContribuicao"
          label="margem contribuição"
          :max="7"
          :options="{
            length: 5,
            precision: 2,
            style: 'percent',
            suffix: '%',
          }"
        />
      </v-col>
      <!-- margem usuario -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="produto.margemUsuario"
          label="margem usuário"
          :max="7"
          :options="{
            length: 5,
            precision: 2,
            style: 'percent',
            suffix: '%',
          }"
        />
      </v-col>
    </v-row>

    <!-- prazo garantia -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="produto.prazoGarantia"
          label="prazo garantia"
          :max="3"
          :options="{
            length: 3,
            precision: 0,
            suffix: 'meses',
          }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  /// NAME
  name: "CProdutoPageGerenciarEdit",

  /// PROPS
  props: {
    dataset: { type: [Array, Object], required: true },
    produto: { type: Object, required: true },
  },
};
</script>