<!-- #COD: FDS 10/01/2023 CLegislacaoPageItem -->
<!-- VER: JER __/01/2023 CLegislacaoPageItem -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- deletar -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-btn icon :ripple="false" @click="deletar">
          <v-icon color="red">mdi-minus-circle</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <!-- referencia -->
    <v-row v-bind="prop.row" v-if="referencias.length">
      <v-col v-bind="prop.col">
        <c-select
          v-model="item.idReferencia"
          :items="referencias"
          label="referência"
          required
        />
      </v-col>
    </v-row>

    <!-- imposto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-select
          v-model="item.idImposto"
          :items="impostos"
          label="imposto"
          required
        />
      </v-col>
    </v-row>

    <!-- isPuxar -->
    <v-row v-bind="prop.row" v-if="item.idReferencia" class="mt-n8">
      <v-col v-bind="prop.col">
        <c-checkbox
          v-model="item.isPuxar"
          indeterminate
          :label="labelIsPuxar"
        />
      </v-col>
    </v-row>

    <!-- isSaida -->
    <v-row v-bind="prop.row" class="mt-n8">
      <v-col v-bind="prop.col">
        <c-checkbox
          v-model="item.isSaida"
          indeterminate
          :label="labelIsSaida"
        />
      </v-col>
    </v-row>

    <!-- item -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-menu v-model="showMenuItem" bottom offset-y>
          <template #activator="{}">
            <c-text-field
              v-model="item.item"
              label="item"
              :max="30"
              required
              @click="clickItem"
            />
          </template>
          <v-list class="pa-0 overflow-y-auto" max-height="300">
            <v-list-item
              v-for="(item, i) in itens"
              class="py-0 px-3"
              :key="`item${i}`"
              text
              @click="clickItemList(item)"
            >
              <v-list-item-title class="ml-2 text-body-2">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- itemReferencia -->
    <v-row v-bind="prop.row" v-if="item.idReferencia">
      <v-col v-bind="prop.col">
        <v-menu v-model="showMenuItemReferencia" bottom offset-y>
          <template #activator="{}">
            <c-text-field
              v-model="item.itemReferencia"
              label="item referência"
              :max="30"
              required
              @click="clickItemReferencia"
            />
          </template>
          <v-list class="pa-0 overflow-y-auto" max-height="300">
            <v-list-item
              v-for="(item, i) in itensReferencia"
              class="py-0 px-3"
              :key="`item${i}`"
              text
              @click="clickItemReferenciaList(item)"
            >
              <v-list-item-title class="ml-2 text-body-2">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- localidade origem -->
    <v-row v-bind="prop.row" v-if="item.idImposto">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="item.idLocalidadesOrigem"
          :itemsIniciais="localidadesOrigens"
          label="localidades origem"
          multiple
          :path="`/localidades/autocomplete/${
            getImposto(item.idImposto).idPais
          }/${getImposto(item.idImposto).idAbrangencia}/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- localidade destino -->
    <v-row v-bind="prop.row" v-if="item.idImposto">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="item.idLocalidadesDestino"
          :itemsIniciais="localidadesDestinos"
          label="localidades destino"
          multiple
          :path="`/localidades/autocomplete/${
            getImposto(item.idImposto).idPais
          }/${getImposto(item.idImposto).idAbrangencia}/`"
          :rules="[]"
          :searchLength="4"
        />
      </v-col>
    </v-row>

    <!-- codigos fiscais -->
    <v-row v-bind="prop.row" v-if="labelCodigoFiscal('códigos fiscais')">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="item.codigosFiscais"
          buscaIncluir
          :itemsIniciais="codigosFiscais"
          :label="labelCodigoFiscal('códigos fiscais')"
          multiple
          :rules="[]"
          :searchLength="4"
        />
      </v-col>
    </v-row>

    <!-- codigos fiscais excecao -->
    <v-row
      v-bind="prop.row"
      v-if="labelCodigoFiscal('códigos fiscais exceções')"
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="item.codigosFiscaisExcecoes"
          buscaIncluir
          :itemsIniciais="codigosFiscaisExcecoes"
          :label="labelCodigoFiscal('códigos fiscais exceções')"
          multiple
          :rules="[]"
          :searchLength="4"
        />
      </v-col>
    </v-row>

    <!-- codigosFiscais2 -->
    <v-row v-bind="prop.row" v-if="labelCodigoFiscal('códigos fiscais 2')">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="item.codigosFiscais2"
          buscaIncluir
          :itemsIniciais="codigosFiscais2"
          :label="labelCodigoFiscal('códigos fiscais 2')"
          multiple
          :rules="[]"
          :searchLength="7"
        />
      </v-col>
    </v-row>

    <!-- codigosFiscais2 excecao -->
    <v-row
      v-bind="prop.row"
      v-if="labelCodigoFiscal('códigos fiscais 2 exceções')"
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="item.codigosFiscais2Excecoes"
          buscaIncluir
          :itemsIniciais="codigosFiscais2Excecoes"
          :label="labelCodigoFiscal('códigos fiscais 2 exceções')"
          multiple
          :rules="[]"
          :searchLength="7"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- mva -->
      <v-col
        v-bind="prop.col"
        v-if="!(item.baseCalculo || item.aliquota)"
        cols="4"
      >
        <c-text-money
          v-model="item.mva"
          label="mva"
          :max="7"
          :options="{
            style: 'percent',
            length: 5,
            precision: 2,
            suffix: '%',
          }"
        />
      </v-col>
      <!-- base calculo -->
      <v-col v-bind="prop.col" v-if="!item.mva" cols="4">
        <c-text-money
          v-model="item.baseCalculo"
          label="base cálculo"
          :max="10"
          :options="{
            style: 'percent',
            length: 6,
            precision: 3,
            suffix: '%',
          }"
        />
      </v-col>
      <!-- aliquota -->
      <v-col v-bind="prop.col" v-if="!item.mva" cols="4">
        <c-text-money
          v-model="item.aliquota"
          label="aliquota"
          :max="7"
          :options="{
            style: 'percent',
            length: 5,
            precision: 2,
            suffix: '%',
          }"
        />
      </v-col>
    </v-row>

    <!-- isCumulativo -->
    <v-row v-bind="prop.row" class="mt-n2">
      <v-col v-bind="prop.col">
        <c-checkbox
          v-model="item.isCumulativo"
          indeterminate
          :label="labelIsCumulatvo"
        />
      </v-col>
    </v-row>

    <!-- options -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-help
          :help="{
            title: 'ORIGEM',
            text: `0 - Nacional, exceto códigos 3, 4, 5 e 8
1 - Estrangeira - importação direta, exceto código 6
2 - Estrangeira - adquirida no mercado interno, exceto código 7
3 - Nacional - com conteúdo de importação > 40% e <= 70%
4 - Nacional - produção em  conformidade com os processos produtivos básicos 
5 - Nacional - com conteúdo de importação <= 40% 
6 - Estrangeira - importação direta, sem similar nacional, constante em lista CAMEX
7 - Estrangeira - adquirida no mercado interno, sem similar nacional, constante em lista CAMEX
8 - Nacional - com conteúdo de importação > 70%`,
            function: context.setUiBottomSheet,
            alignText: 'text-left',
          }"
        >
          <c-textarea
            v-model.trim="item.options"
            class="text-body-1"
            label="options"
            :max="getConst('config').textarea.max"
            :rows="3"
            type="text"
          />
        </c-help>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field v-model="item.observacao" label="observação" :max="50" />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-textarea
          v-model.trim="item.observacoes"
          class="text-body-1"
          label="Observações"
          :max="getConst('config').textarea.max"
          placeholder="Observações"
          :rows="3"
          type="text"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLegislacaoPageItem",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    index: { type: Number, required: true },
    item: { type: Object, required: true },
  },

  /// DATA
  data() {
    return {
      showMenuItem: false,
      showMenuItemReferencia: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getImpostos: "impostos/getImpostos",
      getImposto: "impostos/getImposto",
      getLocalidade: "localidades/getLocalidade",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getVerba: "verbas/getVerba",
    }),

    impostos() {
      return this.getImpostos().map((imposto) => {
        return {
          value: imposto.value,
          text: this.getImposto(imposto.value).nomeAlt,
        };
      });
    },

    codigosFiscais2() {
      return (this.item.codigosFiscais2 || []).map((codigoFiscal) => {
        return {
          value: codigoFiscal,
          text: codigoFiscal,
        };
      });
    },

    codigosFiscais2Excecoes() {
      return (this.item.codigosFiscais2Excecoes || []).map((codigoFiscai2) => {
        return {
          value: codigoFiscai2,
          text: codigoFiscai2,
        };
      });
    },

    codigosFiscais() {
      return (this.item.codigosFiscais || []).map((codigoFiscal) => {
        return {
          value: codigoFiscal,
          text: codigoFiscal,
        };
      });
    },

    codigosFiscaisExcecoes() {
      return (this.item.codigosFiscaisExcecoes || []).map((codigoFiscal) => {
        return {
          value: codigoFiscal,
          text: codigoFiscal,
        };
      });
    },

    localidadesDestinos() {
      return (this.item.idLocalidadesDestino || []).map((localidade) => {
        return {
          value: localidade,
          text:
            this.getLocalidade(localidade).codigo2 ||
            this.getLocalidade(localidade).nome,
        };
      });
    },

    itens() {
      return (this.context.datasetList.dataset.aux.itens || [])
        .filter(
          (item) =>
            !this.item.item || this.item.idReferencia == item.idReferencia
        )
        .map((item) => {
          return {
            ...item,
            value: item.item,
            text: `${item.nome}, item ${item.item}`,
          };
        });
    },

    itensReferencia() {
      return (this.context.datasetList.dataset.aux.itens || [])
        .filter(
          (item) =>
            !this.item.itemReferencia ||
            this.item.idReferencia == item.idReferencia
        )
        .map((item) => {
          return {
            ...item,
            value: item.item,
            text: `${item.nome}, item ${item.item}`,
          };
        });
    },

    labelIsCumulatvo() {
      return `${
        this.item.isCumulativo == null
          ? "retorna todos os itens"
          : this.item.isCumulativo == false
          ? "retorna um dos itens"
          : "retorna a soma dos itens"
      }`;
    },

    labelIsPuxar() {
      return `${
        this.item.isPuxar == null
          ? "revogado"
          : this.item.isPuxar == false
          ? "empurra"
          : "puxa"
      }`;
    },

    labelIsSaida() {
      return `${
        this.item.isSaida == null
          ? "entrada/saída"
          : this.item.isSaida == false
          ? "entrada"
          : "saída"
      }`;
    },

    localidadesOrigens() {
      return (this.item.idLocalidadesOrigem || []).map((localidade) => {
        return {
          value: localidade,
          text:
            this.getLocalidade(localidade).codigo2 ||
            this.getLocalidade(localidade).nome,
        };
      });
    },

    referencias() {
      return (
        this.context.dataset.aux.referencias.filter(
          (ref) => ref && ref.id && ref.isReferencia
        ) || []
      ).map((referencia) => {
        return {
          value: referencia.id,
          text: referencia.nome,
        };
      });
    },
  },

  /// METHODS
  methods: {
    clickItem() {
      this.showMenuItem = !this.showMenuItem;
    },

    clickItemReferencia() {
      this.showMenuItemReferencia = !this.showMenuItemReferencia;
    },

    clickItemList(itemSelecionado) {
      Object.keys(this.item).forEach((key) => {
        if (!["id", "idReferencia"].includes(key)) {
          this.item[key] = itemSelecionado[key];
        }
      });
    },

    clickItemReferenciaList(itemSelecionado) {
      this.item.itemReferencia = itemSelecionado.value;
    },

    deletar() {
      if (this.item.id) {
        this.item.del = true;
      } else {
        this.context.datasets.splice(this.index, 1);
        this.context.datasetsTemp.splice(this.index, 1);
      }
    },

    labelCodigoFiscal(key) {
      const options = this.getImposto(this.item.idImposto).options;
      if (options && options[key]) {
        return options[key] || null;
      } else if (options && options["códigos fiscais"] && !options[key]) {
        return null;
      }
      return key;
    },
  },
};
</script>