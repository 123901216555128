<!-- #COD: FDS 05/01/2023 CPedidosPessoaProduto -->
<!-- VER: JER __/01/2023 CPedidosPessoaProduto -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- operacao -->
    <v-row v-bind="prop.row" v-if="operacao">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ operacao }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- grupo -->
    <v-row v-bind="prop.row" v-if="dataset.grupoProduto">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ dataset.grupoProduto }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="font-weight-medium text-body-1">
          {{ produtoFormat(dataset, true) }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- quantidade -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ quantidade }}
        </span>
      </v-col>
      <!-- preco -->
      <v-col v-bind="prop.col" align="end" cols="2">
        <span class="text-body-1">
          {{ numberFormat(dataset.preco, "currency") }}
        </span>
      </v-col>
      <!-- preco total -->
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-1">
          {{ numberFormat(dataset.preco * dataset.quantidade, "currency") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPedidosPessoaProduto",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getOperacao: "operacoes/getOperacao",
      getTipo_item: "tipos/getTipo_item",
    }),

    operacao() {
      return this.getOperacao(this.dataset.idOperacao)?.nomeAlt;
    },

    quantidade() {
      return `${this.numberFormat(
        this.dataset.quantidade,
        "decimal",
        this.dataset.decimalEstoque
      )} ${
        this.getTipo_item(this.dataset.idUnidadeMedida)?.options?.abreviado
      }`;
    },
  },
};
</script>