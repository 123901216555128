<!-- COD: LYF 05/07/2022 Publicacao -->
<!-- *VER: JER 05/07/2022 Publicacao -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- // IMAGENS -->
      <template #images="{ dataset, pageModeView }">
        <c-base-detail-images :dataset="dataset" :pageModeView="pageModeView" />
      </template>

      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-publicacao-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-publicacao-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailImages from "@/components/bases/CBaseDetailImages";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CPublicacaoEdit from "@/components/pages/publicacao/CPublicacaoEdit";
import CPublicacaoView from "@/components/pages/publicacao/CPublicacaoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Publicacao",

  /// COMPONENTS
  components: {
    CBaseDetailImages,
    CBaseDetailInsert,
    CBaseDialogEstado,
    CPublicacaoEdit,
    CPublicacaoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("publicacao").id,
      idPageAlt: getPagina("publicacoes").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idPublicado: null,
            marca: null,
            titulo: {},
            recorrencia: null,
            dataInicio: null,
            dataFim: null,
            data: null,
            hasNotificacao: false,
            busca: null,
            observacao: null,
            conteudo: {},
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
            curtidas: 0,
            comentadas: 0,
            compartilhadas: 0,
            visualizadas: 0,
          },
        },
        aux: {
          detail: {
            pessoaParceiro: null,
          },
          imagens: [
            {
              id: null,
              endereco: null,
            },
          ],
          autocomplete: {
            produtos: [],
            marcas: [],
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          } else if (action == "grupo") {
            context.dataset.grupoAlterar = true;
          }
          context.setSnackbar();
        },

        posSubmit: (context) => {
          context.defaultParams.escolheTipo = false;
        },
      },

      params: {
        context: this,
        escolheTipo: true,
        recarregar: false,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }

    if (!this.$route.params.id) {
      this.idPageMode = this.getConst("config").pageMode.insert.id;

      if (this.$route.query.idPublicado) {
        const value = parseInt(this.$route.query.idPublicado);
        const text = `${this.$route.query.titulo} #${value}`;

        this.dataset.main.detail.idPublicado = value;
        this.dataset.main.detail.titulo = text;
        this.dataset.aux.autocomplete.produtos.push({ value, text });
        this.dataset.main.detail.idTipo =
          this.getTipo_item("publicacaoProduto").id;
      }
    }
  },
};
</script>