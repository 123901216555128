<!-- COD: FDS 27/07/2022 CBaseDialogPagamento -->
<!-- *VER: JER 10/08/2022 CBaseDialogPagamento -->
<!-- /11/ -->

<template>
  <v-dialog v-model="value" max-width="380px" persistent scrollable>
    <v-card class="mx-auto" max-width="380">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>PAGAMENTO</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="print()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container class="pa-0">
            <!-- frases -->
            <v-row v-bind="prop.row" v-for="(frase, i) in frases" :key="i">
              <v-col v-bind="prop.col">
                <span :class="i == 0 ? 'text-h5 mb-2' : 'text-body-1 mt-2'">
                  {{ frases }}
                </span>
              </v-col>
            </v-row>

            <!-- canvas -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="center">
                <canvas id="canvas"></canvas>
              </v-col>
            </v-row>

            <!-- codigo confirmacao -->
            <v-row
              v-bind="prop.row"
              v-if="getVar('idPessoaParceiro') == getConst('app').id"
            >
              <v-col v-bind="prop.col">
                <c-text-field
                  v-model.trim="confirmacaoId"
                  label="código de confirmação"
                  :max="32"
                  required
                />
              </v-col>
            </v-row>

            <!-- confirmar pagamento -->
            <v-row
              v-bind="prop.row"
              v-if="getVar('idPessoaParceiro') == getConst('app').id"
            >
              <v-col v-bind="prop.col">
                <c-btn
                  block
                  class="primary white--text"
                  @click="clickConfirmarPagamento"
                >
                  confirmar pagamento
                </c-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUtil from "@/mixins/mixinUtil";
import qrCode from "qrcode";

export default {
  /// NAME
  name: "CBaseDialogSPagamento",

  /// MIXINS
  mixins: [mixinUtil, mixinSnackbar],

  /// PROPS
  props: {
    code: { type: String, default: "" },
    frasesProp: { type: Array },
    value: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {
      confirmacaoId: null,
      frases: this.frasesProp || [
        "Para realizar o pagamento:",
        "1. abra o seu app de pagamento,",
        "2. se for utilizar o Pix Copia e Cola, cole o código abaixo (que já está na área de transferência); se for utilizar o qrCode, use a camera do seu celular para ler o código abaixo.",
        "3. confirme o pagamento.",
      ],
      rules: {
        input32: [
          (value) =>
            (!!value && value.length == 32) || "requerido 32 caracteres",
        ],
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),
  },

  /// WATCH
  watch: {
    code() {
      if (this.code.length) {
        setTimeout(() => {
          this.qrcode();
        }, 10);
      }
    },
  },

  /// METHODS
  methods: {
    clickConfirmarPagamento() {
      if (this.$refs.form.validate()) {
        this.$emit("pagamentoConfirmado", this.confirmacaoId);
        this.$emit("input", false);
      } else {
        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );
      }
    },

    print() {
      const canvas = document.querySelector("#canvas");
      const win = window.open();
      self.focus();
      win.document.open();
      win.document.write("<html><body>");
      win.document.write("<br/>");
      this.frases.forEach((frase) => win.document.write(`<p>${frase}</p>`));
      win.document.write("<br/>");
      win.document.write("<img src='" + canvas.toDataURL() + "' />");
      win.document.write("<br/>");
      win.document.write("</body></html>");
      win.document.close();
      setTimeout(() => {
        win.print();
        win.close();
      }, 10);
    },

    qrcode() {
      let interval = setInterval(() => {
        const canvas = document.querySelector("#canvas");

        if (canvas) {
          clearInterval(interval);
          interval = null;
          qrCode.toCanvas(canvas, this.code);
          canvas.style.width = "300px";
          canvas.style.height = "300px";
        }
      }, 10);
    },
  },
};
</script>