// COD: BJB 15/08/2022 cartoes
// *VER: JER 19/08/2022 cartoes
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    cartoes: [],
    cartoes_numeros: [],
    cartoes_pagamentos: []
  },

  /// GETTERS
  getters: {
    getCartoes: state => () => {
      return state.cartoes
        .map(element => {
          return {
            value: element.id,
            text: element.nome,
          };
        })
    },

    getCartao: state => id => {
      return state.cartoes.length
        ? state.cartoes
          .find(element => element.id == id)
        : { id }
    },

    getPagamento_cartoes: (state, getters) => (id, prop = "id") => {
      return state.cartoes_pagamentos
        .filter(element => element.idFormaPagamento == id)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.idPai,
            text: getters.getCartao(element.idPai).nomeAlt
          };
        });
    }
  },

  /// MUTATIONS
  mutations: {
    setCartoes: (state, payload) => {
      state.cartoes = []
      payload.forEach(element => {
        state.cartoes.push(element)
      });
    },
  },

  /// ACTIONS
  actions: {
    setCartoes: (context, payload) => {
      context.commit("setCartoes", payload)
    },
  }
}