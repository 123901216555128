<!-- COD: BJB 08/07/2022 CBaseCategorias -->
<!-- *VER: JER 20/07/2022 CBaseCategorias -->
<!-- /22/ -->

<template>
  <v-container>
    <!-- /// CATEGORIAS 0 -->
    <v-row
      v-bind="prop.row"
      v-if="!indice"
      :class="!dataset ? 'ml-n9 mr-n5' : ''"
    >
      <v-col v-bind="prop.col">
        <v-chip-group
          v-model="categoriasSelected[0]"
          class="mb-2"
          center-active
          mandatory
          :show-arrows="!isMobile"
        >
          <v-chip
            v-for="(categoria, i) in categorias(0)"
            class="text-body-2"
            :class="`${
              categoriasSelected[0] != categoria.id
                ? 'primary--text'
                : 'white--text'
            }`"
            :color="pageModeView ? 'corDisabled' : 'primary'"
            :key="i"
            :outlined="categoriasSelected[0] != categoria.id"
            :value="categoria.id"
            @click="
              categoriasSelected[1] = getCategorias(categoria.id).find(
                (element) => element.isDefault
              ).id
            "
          >
            <v-icon
              v-if="categoria.options && categoria.options.icon"
              class="mr-1"
            >
              {{ categoria.options.icon }}
            </v-icon>
            {{ categoria.nomeAlt }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <!-- /// CATEGORIAS 1 -->
    <v-row v-bind="prop.row" :class="!dataset ? 'ml-n9 mr-n5' : ''">
      <v-col v-bind="prop.col">
        <v-slide-group
          v-model="categoriasSelected[1]"
          center-active
          mandatory
          :show-arrows="!isMobile"
        >
          <v-slide-item
            v-for="(categoria, n) in categorias(1)"
            v-slot="{ toggle }"
            :key="n"
            :value="categoria.id"
          >
            <v-card
              class="pa-0"
              :class="categoriasSelected[1] != categoria.id || 'selected'"
              flat
              :outlined="categoriasSelected[1] == categoria.id"
              rounded="md"
              width="90"
              @click="
                toggle();
                change();
              "
            >
              <v-row v-bind="prop.row" class="ma-0 pa-0">
                <v-col v-bind="prop.col" align="center">
                  <v-img
                    class="ma-0 pa-0"
                    max-height="50"
                    max-width="50"
                    :src="
                      (categoria.options && categoria.options.src) ||
                      getConst('app').urlSemImagem
                    "
                  />
                </v-col>
              </v-row>

              <v-row v-bind="prop.row" class="ma-0 pa-0">
                <v-col v-bind="prop.col" align="center">
                  <span class="text-caption">
                    {{ categoria.nomeAlt }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>

    <!-- /// CATEGORIAS 2 -->
    <v-row v-bind="prop.row" :class="!dataset ? 'ml-n9 mr-n5' : ''">
      <v-col v-bind="prop.col">
        <v-chip-group v-model="categoriasSelected[2]" column multiple>
          <v-chip
            v-for="(categoria, i) in categorias(2)"
            class="text-body-2"
            :class="`${pageModeView ? 'corDisabled' : 'primary'} ${
              !!categoriasSelected[2] &&
              !categoriasSelected[2].includes(categoria.id)
                ? 'primary--text'
                : 'white--text'
            }`"
            :disabled="pageModeView"
            :key="i"
            :outlined="
              !!categoriasSelected[2] &&
              !categoriasSelected[2].includes(categoria.id)
            "
            :value="categoria.id"
            @click="change"
          >
            <v-icon
              v-if="categoria.options && categoria.options.icon"
              class="mr-1"
            >
              {{ categoria.options.icon }}
            </v-icon>
            <v-img
              v-else-if="categoria.options && categoria.options.flag"
              class="ml-n2 mr-1"
              contain
              height="24"
              :src="getLocalidade(categoria.options.flag).endereco"
              width="24"
            />
            {{ categoria.nomeAlt }}
          </v-chip>
          <!-- {{ getLocalidade(categoria.options.flag) }} -->
        </v-chip-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CBaseCategorias",

  /// PROPS
  props: {
    dataset: { type: Object, default: null },
    indice: { type: Number, default: null },
    pageModeView: { type: Boolean, default: false },
  },

  /// DATA
  data() {
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      parceiroCategorias: [],
      categoriasSelected: [
        getVar("categoriasSelected")[0],
        getVar("categoriasSelected")[1],
        getVar("categoriasSelected")[2],
      ],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getCategoria: "categorias/getCategoria",
      getCategorias: "categorias/getCategorias",
      getVar: "variaveis/getVar",
    }),

    isMobile() {
      const toMatch = [/Android/i, /iPhone/i, /iPad/i, /iPod/i];

      return toMatch.some((toMatchItem) =>
        navigator.userAgent.match(toMatchItem)
      );
    },
  },

  /// MOUNTED
  mounted() {
    if (this.indice == null) {
      this.categoriasSelected = this.getVar("categoriasSelected");
    } else {
      this.parceiroCategorias = this.dataset.main.categorias.map(
        (idCategoria, i) => {
          return i == this.indice ? null : idCategoria;
        }
      );

      const categoria0 = this.dataset.main.categorias[0]
        ? this.getCategoria(this.dataset.main.categorias[0]).idPai
        : this.getVar("categoriasSelected")[0];

      this.categoriasSelected = [
        categoria0,
        this.dataset.main.categorias[this.indice],
        this.dataset.main.categorias_filhas[this.indice],
      ];
    }
  },

  /// METHODS
  methods: {
    categorias(nivel) {
      let categorias;

      if (this.pageModeView) {
        if (nivel < 2) categorias = [this.categoriasSelected[nivel]];
        else {
          categorias = this.categoriasSelected[nivel];
        }

        categorias = categorias.map((categoria) =>
          this.getCategoria(categoria)
        );
      } else {
        if (nivel == 1) {
          categorias = this.getCategorias(this.categoriasSelected[0])
            .filter((categoria) => {
              let res = true;

              if (
                this.dataset &&
                (this.dataset.main.detail.isEntregar ||
                  this.dataset.main.detail.isRetirar)
              ) {
                res = false;

                if (!this.parceiroCategorias.includes(categoria.id)) {
                  res =
                    !categoria.isExclusivaNoParceiro ||
                    (this.indice == 0 &&
                      this.dataset.main.categorias.length == 1);

                  if (res)
                    res =
                      categoria.isRealizaEntrega == null ||
                      (this.dataset.main.detail.isEntregar &&
                        !this.dataset.main.detail.isRetirar &&
                        categoria.isRealizaEntrega) ||
                      (this.dataset.main.detail.isRetirar &&
                        !this.dataset.main.detail.isEntregar &&
                        !categoria.isRealizaEntrega);
                }
              }

              return res;
            })
            .map((categoria) => {
              const res = { ...categoria };
              if (!res.options?.src) {
                res.options = {
                  ...categoria.options,
                  src: this.getCategoria(res.idPai).options.src,
                };
              }
              return res;
            });
        } else {
          const idCategoria =
            nivel > 0 ? this.categoriasSelected[nivel - 1] : null;

          categorias = this.getCategorias(idCategoria);
        }
      }

      return categorias;
    },

    change() {
      if (this.indice != null) {
        setTimeout(() => {
          this.dataset.main.categorias[this.indice] =
            this.categoriasSelected[1];
          this.dataset.main.categorias_filhas[this.indice] =
            this.categoriasSelected[2];
        }, 10);
      }
    },
  },
};
</script>

<style scoped>
.selected {
  border: 3px solid var(--v-primary);
}
</style>