<!-- COD: LYF 05/07/2022 CColaboradorEdit -->
<!-- *VER: JER 07/08/2022 CColaboradorEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- incluir colaborador -->
    <v-row v-bind="prop.row" v-if="!dataset.main.detail.idPessoaColaborador">
      <v-col v-bind="prop.col" align="center">
        <c-btn class="text-body-1" color="primary" text @click="openDialog">
          incluir colaborador
        </c-btn>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row" v-else>
      <v-col v-bind="prop.col" align="center">
        <c-btn class="text-body-1" color="primary" text @click="openDialog">
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nome alt"
          :max="40"
        />
      </v-col>
    </v-row>

    <!-- grupo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.grupo"
          buscaIncluir
          :itemsIniciais="dataset.aux.autocomplete.grupos"
          label="grupo"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data admissao -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataAdmissao"
          date
          label="admissão"
          required
        />
      </v-col>
      <!-- data demissao -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataDemissao"
          date
          label="demissão"
        />
      </v-col>
    </v-row>

    <!-- dialog incluir pessoa -->
    <c-base-dialog-pessoa-incluir
      v-if="showPessoaIncluir"
      :idPagina="getPagina('colaborador').id"
      :idTipoConvite="getTipo_item('conviteColaborador').id"
      :params="params"
      @close="clickPessoaIncluir($event)"
    />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseDialogPessoaIncluir from "@/components/bases/CBaseDialogPessoaIncluir";

export default {
  /// NAME
  name: "CColaboradorEdit",

  /// COMPONENTS
  components: {
    CBaseDialogPessoaIncluir,
  },

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// DATA
  data() {
    return {
      showPessoaIncluir: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
    }),
  },

  /// METHODS
  methods: {
    clickPessoaIncluir(event) {
      this.showPessoaIncluir = false;

      if (event?.id) {
        this.dataset.main.detail.idPessoaColaborador = event.id;
        this.dataset.aux.detail.pessoa_nome = event.nome;
      }
    },

    openDialog() {
      if (
        this.dataset.main.detail.idEstado == this.getEstado("cadastro").id ||
        !this.dataset.main.detail.id
      ) {
        this.showPessoaIncluir = true;
      }
    },
  },
};
</script>