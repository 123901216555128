<!-- COD: BJB 28/07/2022 CFolhaPagamentoDialogHolerite -->
<!-- *VER: JER 08/08/2022 CFolhaPagamentoDialogHolerite -->
<!-- /32/ -->

<template>
  <v-dialog max-width="1000" persistent ref="holerite" scrollable :value="true">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="clickClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>HOLERITE</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="print">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="px-0" ref="printArea">
        <v-container v-if="datasetList" class="px-0">
          <!-- imagem parceiro -->
          <v-row v-bind="prop.row" v-if="imagemParceiro">
            <v-col v-bind="prop.col" align="center">
              <img
                alt="imagem"
                class="mt-n3"
                contain
                :height="100"
                :src="imagemParceiro"
                :width="100"
              />
            </v-col>
          </v-row>

          <!-- pessoa parceiro_nomeAlt -->
          <v-row v-bind="prop.row" class="mb-2">
            <v-col v-bind="prop.col" align="center">
              <v-chip
                class="primary primary--text text-overline"
                outlined
                x-small
              >
                {{ datasetList.dataset.aux.colaborador.pessoaParceiro_nomeAlt }}
              </v-chip>
            </v-col>
          </v-row>

          <!-- pessoa parceiro_nomeAlt -->
          <v-row v-bind="prop.row" class="mb-2">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idFolhaPagamento"
                class="px-2"
                :items="folhasPagamentos"
                label="folha pagamento"
                @change="clickSearchById"
              />
            </v-col>
          </v-row>

          <!-- imagem colaborador -->
          <v-row v-bind="prop.row" v-if="imagemColaborador">
            <v-col v-bind="prop.col" align="center">
              <v-avatar size="60">
                <img :src="imagemColaborador" contain alt="imagem" />
              </v-avatar>
            </v-col>
          </v-row>

          <!-- nome -->
          <v-row v-bind="prop.row" v-if="imagemColaborador">
            <v-col v-bind="prop.col" align="center">
              <span class="font-weight-medium text-body-1">
                {{ datasetList.dataset.aux.colaborador.nome.toUpperCase() }}
              </span>
            </v-col>
          </v-row>

          <!-- colaborador id -->
          <v-row v-bind="prop.row" v-if="imagemColaborador">
            <v-col v-bind="prop.col" align="center">
              <span class="font-weight-medium text-body-1">
                #
                {{ datasetList.dataset.aux.colaborador.id }}
              </span>
            </v-col>
          </v-row>

          <!-- cargo -->
          <v-row v-bind="prop.row" v-if="imagemColaborador">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-1">
                {{ datasetList.dataset.aux.colaborador.cargo }}
              </span>
            </v-col>
          </v-row>

          <!-- data admissao demissao -->
          <v-row v-bind="prop.row" v-if="imagemColaborador">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-1">
                {{ datasetList.dataset.aux.colaborador.dataAdmissaoDemissao }}
              </span>
            </v-col>
          </v-row>

          <!-- situacao trabalho -->
          <v-row v-bind="prop.row" v-if="imagemColaborador">
            <v-col v-bind="prop.col" align="center">
              <v-chip v-bind="prop.chip">
                {{ datasetList.dataset.aux.colaborador.situacaoTrabalho }}
              </v-chip>
            </v-col>
          </v-row>
        </v-container>

        <div v-if="folhaPagamento">
          <v-container class="py-0">
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="end">
                <v-btn icon @click="clickPanels()" class="mr-1">
                  <v-icon>
                    {{
                      !panelsOpen.length ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-expansion-panels
            class="py-0 ma-0 my-6"
            flat
            multiple
            v-model="panelsOpen"
          >
            <v-expansion-panel class="mb-1 ma-0" expand>
              <v-expansion-panel-header class="grey lighten-3">
                <v-container class="py-0">
                  <!-- titulo -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" cols="10">
                      <span class="text-h6">verbas</span>
                    </v-col>
                    <!-- badge -->
                    <v-col v-bind="prop.col" cols="2">
                      <v-badge
                        :content="getVerbasHolerite(true).length"
                        overlap
                        inline
                        color="grey"
                      ></v-badge>
                    </v-col>
                  </v-row>

                  <!-- total liquidos -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" align="end">
                      <span class="text-body-1">
                        {{ numberFormat(totalLiquidos, "currency") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <c-expansion-panels
                  flat
                  :hasDot="hasDot"
                  :isDisabled="isDisabled"
                  :pageModeView="true"
                  :params="params"
                  :value="getVerbasHolerite(true)"
                >
                  <template #header="{ dataset: verba }">
                    <v-row v-bind="prop.row">
                      <!-- verba -->
                      <v-col v-bind="prop.col" cols="7">
                        <span class="text-body-1">
                          {{ verba.idVerba }}
                        </span>
                        <span class="ml-1 text-body-1">
                          {{ getVerba(verba.idVerba).nomeAlt }}
                        </span>
                      </v-col>
                      <!-- valor -->
                      <v-col v-bind="prop.col" cols="5">
                        <span class="text-body-1">
                          {{ numberFormat(verba.valor, "currency") }}
                        </span>
                      </v-col>
                    </v-row>

                    <!-- isAcerto -->
                    <v-row v-bind="prop.row" v-if="verba.isAcerto">
                      <v-col v-bind="prop.col">
                        <v-chip v-bind="prop.chip">acerto</v-chip>
                      </v-col>
                    </v-row>
                  </template>

                  <template #content="{ dataset: verba }">
                    <v-container>
                      <!-- valorBase -->
                      <v-row v-bind="prop.row" v-if="verba.valorBase">
                        <v-col v-bind="prop.col" align="end" cols="6">
                          <span class="text-body-2">base</span>
                        </v-col>
                        <v-col v-bind="prop.col" cols="6">
                          <span class="text-body-1">
                            {{ numberFormat(verba.valorBase, "currency") }}
                          </span>
                        </v-col>
                      </v-row>

                      <!-- multiplicador -->
                      <v-row v-bind="prop.row" v-if="verba.multiplicador">
                        <v-col v-bind="prop.col" align="end" cols="6">
                          <span class="text-body-2">multiplicador</span>
                        </v-col>
                        <v-col v-bind="prop.col" cols="6">
                          <span class="text-body-1">
                            {{
                              numberFormat(verba.multiplicador / 100, "percent")
                            }}
                          </span>
                        </v-col>
                      </v-row>

                      <!-- data -->
                      <v-row v-bind="prop.row" v-if="verba.isAcerto">
                        <v-col v-bind="prop.col" align="end" cols="6">
                          <span class="text-body-2">data</span>
                        </v-col>
                        <v-col v-bind="prop.col" cols="6">
                          <span class="text-body-1">
                            {{ dateFormat(verba.data, "date") }}
                          </span>
                        </v-col>
                      </v-row>

                      <!-- referencia -->
                      <v-row v-bind="prop.row">
                        <v-col v-bind="prop.col" align="center">
                          <span class="text-body-1">
                            {{ verba.referencia }}
                          </span>
                        </v-col>
                      </v-row>

                      <!-- pessoa favorecida_nome -->
                      <v-row
                        v-bind="prop.row"
                        v-if="
                          folhaPagamento.aux.verbas[verba.index]
                            .pessoaFavorecida_nome
                        "
                      >
                        <v-col v-bind="prop.col" align="center">
                          <v-icon>mdi-arrow-right</v-icon>
                          <span class="text-body-1 ml-1">
                            {{
                              folhaPagamento.aux.verbas[verba.index]
                                .pessoaFavorecida_nome
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </c-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-1 ma-0" expand>
              <v-expansion-panel-header class="grey lighten-3">
                <v-container class="py-0">
                  <!-- titulo -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" cols="10">
                      <span class="text-h6">valores totais</span>
                    </v-col>
                    <!-- badge -->
                    <v-col v-bind="prop.col" cols="2">
                      <v-badge
                        :content="totais.length"
                        overlap
                        inline
                        color="grey"
                      ></v-badge>
                    </v-col>
                  </v-row>

                  <!-- total liquidos -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" align="end">
                      <span class="text-body-1">
                        {{ numberFormat(totalLiquidos, "currency") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <c-expansion-panels
                  flat
                  :hasDot="hasDot"
                  :hasPanelsCollapse="false"
                  :isDisabled="() => true"
                  :pageModeView="true"
                  :value="totais"
                >
                  <template #header="{ dataset: total }">
                    <v-container class="pa-0">
                      <v-row v-bind="prop.row">
                        <v-col v-bind="prop.col" cols="6">
                          <span :class="`text-body-1 ${classTotais(total)}`">
                            {{ total.item }}
                          </span>
                        </v-col>
                        <v-col v-bind="prop.col" align="end" cols="6">
                          <span :class="`text-body-1 ${classTotais(total)}`">
                            {{ numberFormat(total.total, "currency") }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </c-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              v-show="getVerbasHolerite(false).length"
              class="mb-1 ma-0"
              expand
            >
              <v-expansion-panel-header class="grey lighten-3">
                <v-container class="py-0">
                  <!-- titulo -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" cols="10">
                      <span class="text-h6">bases</span>
                    </v-col>
                    <!-- badge -->
                    <v-col v-bind="prop.col" cols="2">
                      <v-badge
                        :content="getVerbasHolerite(false).length"
                        overlap
                        inline
                        color="grey"
                      ></v-badge>
                    </v-col>
                  </v-row>

                  <!-- total bases -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" align="end">
                      <span class="text-body-1">
                        {{ numberFormat(totalBases, "currency") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <c-expansion-panels
                  flat
                  :hasDot="hasDot"
                  :isDisabled="isDisabled"
                  :pageModeView="true"
                  :params="params"
                  :value="getVerbasHolerite(false)"
                >
                  <template #header="{ dataset: verba }">
                    <v-row v-bind="prop.row">
                      <!-- verba -->
                      <v-col v-bind="prop.col" cols="7">
                        <span class="text-body-1">
                          {{ verba.idVerba }}
                        </span>
                        <span class="ml-1 text-body-1">
                          {{ getVerba(verba.idVerba).nomeAlt }}
                        </span>
                      </v-col>
                      <!-- valor -->
                      <v-col v-bind="prop.col" cols="5">
                        <span class="text-body-1">
                          {{ numberFormat(verba.valor, "currency") }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>

                  <template #content="{ dataset: verba }">
                    <v-container>
                      <!-- valor base -->
                      <v-row v-bind="prop.row" v-if="verba.valorBase">
                        <v-col v-bind="prop.col" align="end" cols="6">
                          <span class="text-body-2">base</span>
                        </v-col>
                        <v-col v-bind="prop.col" cols="6">
                          <span class="text-body-1">
                            {{ numberFormat(verba.valorBase, "currency") }}
                          </span>
                        </v-col>
                      </v-row>

                      <!-- multiplicador -->
                      <v-row v-bind="prop.row" v-if="verba.multiplicador">
                        <v-col v-bind="prop.col" align="end" cols="6">
                          <span class="text-body-2">multiplicador</span>
                        </v-col>
                        <v-col v-bind="prop.col" cols="6">
                          <span class="text-body-1">
                            {{
                              numberFormat(verba.multiplicador / 100, "percent")
                            }}
                          </span>
                        </v-col>
                      </v-row>

                      <!-- referencia -->
                      <v-row v-bind="prop.row">
                        <v-col v-bind="prop.col" align="center">
                          <span class="text-body-1">
                            {{ verba.referencia }}
                          </span>
                        </v-col>
                      </v-row>

                      <!-- pessoa favorecida_nome -->
                      <v-row
                        v-bind="prop.row"
                        v-if="
                          folhaPagamento.aux.verbas[verba.index]
                            .pessoaFavorecida_nome
                        "
                      >
                        <v-col v-bind="prop.col" align="center">
                          <v-icon>mdi-arrow-right</v-icon>
                          <span class="text-body-1 ml-1">
                            {{
                              folhaPagamento.aux.verbas[verba.index]
                                .pessoaFavorecida_nome
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </c-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-show="mensagens.length"
              class="mb-1 ma-0"
              expand
            >
              <v-expansion-panel-header class="grey lighten-3">
                <v-container class="py-0">
                  <!-- titulo -->
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col" cols="10">
                      <span class="text-h6">mensagens</span>
                    </v-col>
                    <!-- badge -->
                    <v-col v-bind="prop.col" cols="2">
                      <v-badge
                        :content="mensagens.length"
                        overlap
                        inline
                        color="grey"
                      ></v-badge>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container class="py-0">
                  <v-row
                    v-bind="prop.row"
                    v-for="(mensagem, i) in mensagens"
                    :key="`mensagem${i}`"
                  >
                    <v-col v-bind="prop.col">
                      <span class="text-body-1">
                        {{ mensagem }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import axios from "axios";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CFolhaPagamentoDialogHolerite",

  /// MIXINS
  mixins: [mixinData, mixinSnackbar],

  /// MODEL
  model: { prop: "value", event: "input" },

  /// PROPS
  props: {
    dataset: { type: Object, required: false },
    idPessoaColaborador: { type: [Number, String], required: true },
    value: Boolean,
  },

  /// DATA
  data() {
    return {
      datasetList: null,
      folhaPagamento: null,
      folhasPagamentos: [],
      idFolhaPagamento: null,
      panelsOpen: [0],
      params: {
        clickPanelsOpen: null,
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getTipo_item: "tipos/getTipo_item",
      getVerba: "verbas/getVerba",
    }),

    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    imagemColaborador() {
      return this.folhaPagamento?.aux?.colaboradores[0]?.imagem || null;
    },

    imagemParceiro() {
      return this.folhaPagamento?.aux?.detail?.pessoaParceiro_imagem || null;
    },

    mensagens() {
      const colaborador = this.folhaPagamento?.main?.colaboradores[0];
      return (
        colaborador.mensagens ? colaborador.mensagens.split(/\r?\n/) : []
      ).filter((mensagem) => !!mensagem);
    },

    totais() {
      let total;
      let item;
      const ret = [];

      // total proventos
      total = this.folhaPagamento.main.verbas
        .filter(
          (verba) =>
            this.getVerba(verba.idVerba).idTipo !=
              this.getTipo_item("verbaBase").id &&
            this.getVerba(verba.idVerba).isProvento
        )
        .reduce((acc, verba) => (acc += parseFloat(verba.valor)), 0);

      if (total) {
        item = {
          item: "total proventos",
          total,
          isLiquido: false,
        };

        ret.push(item);
      }

      // total descontos
      total = this.folhaPagamento.main.verbas
        .filter(
          (verba) =>
            this.getVerba(verba.idVerba).idTipo !=
              this.getTipo_item("verbaBase").id &&
            this.getVerba(verba.idVerba).isProvento == false
        )
        .reduce((acc, verba) => (acc += parseFloat(verba.valor)), 0);

      if (total) {
        item = {
          item: "total descontos",
          total,
          isLiquido: false,
        };

        ret.push(item);
      }

      // total líquidos
      total = ret.reduce((acc, el) => (acc += parseFloat(el.total)), 0);

      if (total) {
        item = {
          item: "total líquidos",
          total,
          isLiquido: true,
        };

        ret.push(item);
      }

      return ret;
    },

    totalBases() {
      return this.folhaPagamento.main.verbas
        .filter(
          (verba) =>
            this.getVerba(verba.idVerba).idTipo ==
            this.getTipo_item("verbaBase").id
        )
        .reduce((acc, verba) => (acc += parseFloat(verba.valor)), 0);
    },

    totalLiquidos() {
      return this.totais.find((total) => total.isLiquido)?.total || 0;
    },
  },

  /// METHODS
  methods: {
    classTotais(total) {
      return total.isLiquido ? "font-weight-medium" : "";
    },

    clickClose() {
      this.model = false;
    },

    async clickSearch() {
      const method = "get";
      const search = "*";
      const idContexto = "*";
      const idFilter = this.getFeature("filterFiltrar").id;
      const idOrder = this.getFeature("orderOrdenar").id;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.idPessoaColaborador;
      const complete = 0;
      const offset = 0;
      const limit = 6;
      const params = JSON.stringify({
        idPessoaColaborador: this.idPessoaColaborador,
      });
      let url = `${
        this.getConst("app").baseUrlBackend
      }/folhasPagamentos/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${limit}/${params}`;

      const res = await this.submit(method, url, null);
      if (res.data) {
        this.datasetList = res.data;

        this.datasetList.dataset.aux.colaborador.cargo = this.datasetList
          .dataset.aux.colaborador.idCargo
          ? `${
              this.getTipo_item(
                this.datasetList.dataset.aux.colaborador.idCargo
              ).nomeAlt
            } ${this.datasetList.dataset.aux.colaborador.nivel}`
          : "";

        const dataDemissao = this.datasetList.dataset.aux.colaborador
          .dataDemissao
          ? this.dateFormat(
              this.datasetList.dataset.aux.colaborador.dataDemissao,
              "date"
            )
          : "atual";

        this.datasetList.dataset.aux.colaborador.dataAdmissaoDemissao = `${this.dateFormat(
          this.datasetList.dataset.aux.colaborador.dataAdmissao,
          "date"
        )} - ${dataDemissao}`;

        this.datasetList.dataset.aux.colaborador.situacaoTrabalho = this
          .datasetList.dataset.aux.colaborador.idSituacaoTrabalho
          ? this.getTipo_item(
              this.datasetList.dataset.aux.colaborador.situacaoTrabalho
            ).nomeAlt
          : this.getTipo_item("trabalhoNormal").nomeAlt;

        this.folhasPagamentos = this.datasetList.datasets.map(
          (folhaPagamento) => {
            return {
              value: folhaPagamento.id,
              text: `${this.dateFormat(
                folhaPagamento.dataInicio,
                "dateNoYear"
              )} - ${this.dateFormat(folhaPagamento.dataFim, "date")} #${
                folhaPagamento.id
              } (${this.getTipo_item(folhaPagamento.idTipo).nomeAlt})`,
            };
          }
        );

        if (this.dataset) {
          if (
            !this.folhasPagamentos.find(
              (folhaPagamento) =>
                folhaPagamento.value == this.dataset.main.detail.id
            )
          ) {
            this.folhasPagamentos.unshift({
              value: this.dataset.main.detail.id,
              text: `${this.dateFormat(
                this.dataset.main.detail.dataInicio,
                "date"
              )} - ${this.dateFormat(
                this.dataset.main.detail.dataFim,
                "date"
              )} (#${this.dataset.main.detail.id})`,
            });
          }

          this.idFolhaPagamento = this.dataset.main.detail.id;
        } else if (!this.idFolhaPagamento && this.folhasPagamentos.length) {
          this.idFolhaPagamento = this.folhasPagamentos[0].value;
        }

        this.clickSearchById();
      }
    },

    async clickSearchById() {
      if (!this.idFolhaPagamento) {
        this.setSnackbar(
          true,
          "HOLERITE",
          "selecione uma folha de pagamento",
          "warning"
        );

        return;
      }

      const method = "get";
      const params = JSON.stringify({
        idPessoaColaborador: this.idPessoaColaborador,
      });
      let url = `${this.getConst("app").baseUrlBackend}/folhasPagamentos/${
        this.idFolhaPagamento
      }/${params}`;

      const res = await this.submit(method, url, null);
      if (res.data) {
        this.folhaPagamento = res.data;
      }
    },

    clickPanels() {
      this.panelsOpen = this.panelsOpen.length
        ? (this.panelsOpen = [])
        : Array(4)
            .fill()
            .map((_, i) => i);
    },

    getVerbasHolerite(isBase) {
      return this.folhaPagamento.main.verbas
        .map((verba, i) => {
          const objVerba = this.getVerba(verba.idVerba);
          return {
            ...verba,
            verba: objVerba,
            isBase: objVerba.idTipo != this.getTipo_item("verbaBase").id,
            index: i,
          };
        })
        .filter((verba) => verba.isBase == isBase);
    },

    hasDot() {
      return false;
    },

    isDisabled(verba) {
      return (
        !verba.isAcerto &&
        !verba.valorBase &&
        verba.referencia &&
        !this.folhaPagamento.aux.verbas[verba.index].pessoaFavorecida_nome
      );
    },

    print() {
      this.panelsOpen = Array(4)
        .fill()
        .map((_, i) => i);

      this.params.clickPanelsOpen = true;

      setTimeout(() => {
        this.printing = true;
        setTimeout(() => {
          this.pagePrint("holerite");
          setTimeout(() => {
            this.printing = false;
          }, 10);
        }, 10);

        this.params.clickPanelsOpen = null;
      }, 10);
    },

    async submit(method, url, dataset) {
      let result = null;

      this.setVar({ overlay: true });

      await axios[method](url, dataset)
        .then((res) => {
          result = res;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
          }, this.getConst("config").overlay.timeout);
        });

      return result;
    },
  },

  /// MOUNTED
  async mounted() {
    await this.clickSearch();
  },
};
</script>