<!-- COD: BJB 17/08/2022 CBaseDocumentoEdit -->
<!-- *VER: JER 09/09/2022 CBaseDocumentoEdit -->
<!-- /22/ -->

<template>
  <v-container class="px-1 mx-0">
    <v-row
      v-bind="prop.row"
      v-if="'titulo' in documento.options.idioma[locale]"
    >
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="documento.options.idioma[locale].titulo"
          :max="80"
          placeholder="título"
          prepend-inner-icon="mdi-translate"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" v-if="'texto' in documento.options.idioma[locale]">
      <v-col v-bind="prop.col">
        <c-richtext
          v-model="documento.options.idioma[locale].texto"
          tipoToolbar="2"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" v-if="'videos' in documento.options">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="documento.options.videos"
          buscaIncluir
          :itemsIniciais="
            documento.options.videos.map((video) => {
              return {
                value: video,
                text: video,
              };
            }) || []
          "
          label="vídeos"
          multiple
          :rules="[]"
          :searchLength="1"
        />
      </v-col>
    </v-row>

    <v-row
      v-bind="prop.row"
      class="my-4 grey lighten-2 px-1 rounded-lg"
      id="botoes"
    >
      <!-- info -->
      <v-col v-bind="prop.col" cols="2">
        <c-btn
          :help="{
            title: 'TRADUÇÃO',
            text: `Se for necessário tradução, após configurar o texto em um idioma:
• copie o texto (botão copiar),
• utilize um tradutor,
• selecione o novo idioma (bandeira),
• cole o texto traduzido (botão colar).

NOTA: ':::' é um separador, sendo que a parte:
• acima do separador é o TÍTULO,
• abaixo é o TEXTO.
`,
            function: setUiBottomSheet,
          }"
          isHelp
        />
      </v-col>
      <!-- idiomas -->
      <v-col v-bind="prop.col" cols="2">
        <c-base-idiomas
          v-model="idLocale"
          classe="ml-1"
          :idiomas="idiomas"
          noText
        />
      </v-col>
      <!-- copy -->
      <v-col v-bind="prop.col" align="center" cols="2">
        <c-tooltip texto="texto copiado">
          <template v-slot="{ on }">
            <c-btn icon @click="clickCopy(on, $event)">
              <v-icon>mdi-content-copy</v-icon>
            </c-btn>
          </template>
        </c-tooltip>
      </v-col>
      <!-- paste -->
      <v-col v-bind="prop.col" align="center" cols="2">
        <c-tooltip texto="texto colado">
          <template v-slot="{ on }">
            <c-btn icon @click="clickPaste(on, $event)">
              <v-icon>mdi-content-paste</v-icon>
            </c-btn>
          </template>
        </c-tooltip>
      </v-col>
      <!-- cancel -->
      <v-col v-bind="prop.col" align="center" cols="2">
        <c-btn icon @click="$emit('clickEditView', false)">
          <v-icon color="red accent-3">mdi-close</v-icon>
        </c-btn>
      </v-col>
      <!-- save -->
      <v-col v-bind="prop.col" align="center" cols="2">
        <c-btn icon @click="submit">
          <v-icon color="green accent-3">mdi-check</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import axios from "axios";
import CBaseIdiomas from "@/components/bases/CBaseIdiomas";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CBaseDocumentoEdit",

  /// COMPONENTS
  components: {
    CBaseIdiomas,
  },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinSnackbar],

  props: {
    documento: { type: Object, required: true },
  },

  /// DATA
  data() {
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idLocale: getVar("idLocale"),
      separador: "\r\n:::\r\n",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    idiomas() {
      return this.getLocalidade_idiomas()
        .filter((locale) => this.getLocalidade_idioma(locale.value).isLocale)
        .map((locale) => {
          return {
            idLocale: locale.value,
          };
        });
    },

    isPanel() {
      return "open" in this.documento.options;
    },

    locale() {
      return this.localeById(this.idLocale);
    },
  },

  /// METHODS
  methods: {
    clickCopy(on, event) {
      const titulo =
        "titulo" in this.documento.options.idioma[this.locale]
          ? this.documento.options.idioma[this.locale].titulo || "*"
          : "-";
      const texto =
        "texto" in this.documento.options.idioma[this.locale]
          ? this.documento.options.idioma[this.locale].texto || "*"
          : "-";
      const copy = `${titulo}${this.separador}${texto}`;

      navigator.clipboard.writeText(copy);

      this.clickOnTooltip(on, event);
    },

    async clickPaste(on, event) {
      const paste = await navigator.clipboard.readText();
      const pasteSplit = paste.split(this.separador);

      if (pasteSplit[0] != "-") {
        this.documento.options.idioma[this.locale].titulo =
          pasteSplit[0] == "*" ? null : pasteSplit[0];
      }

      if (pasteSplit[1] != "-") {
        // retificação de possíveis traduções incorretas
        //? remover este replace
        pasteSplit[1] = pasteSplit[1].replaceAll(
          "/app/",
          this.getConst("app").nome
        );
        pasteSplit[1] = pasteSplit[1].replaceAll("<p><br></p>", "<p></p>");
        pasteSplit[1] = pasteSplit[1].replaceAll("<ul>", '<ul class="mt-n2">');
        pasteSplit[1] = pasteSplit[1].replaceAll("< p", "<p");
        pasteSplit[1] = pasteSplit[1].replaceAll("< /", "</");
        pasteSplit[1] = pasteSplit[1].replaceAll("ql-size -", "ql-size-");
        pasteSplit[1] = pasteSplit[1].replaceAll(
          '<span style="color de fondo:',
          '<span style="background-color:'
        );

        this.documento.options.idioma[this.locale].texto =
          pasteSplit[1] == "*" ? null : pasteSplit[1];
      }

      this.clickOnTooltip(on, event);
    },

    async submit() {
      const documentoSave = JSON.parse(JSON.stringify(this.documento));
      delete documentoSave.index;
      delete documentoSave.value;
      delete documentoSave.text;

      if (documentoSave) {
        this.setVar({ overlay: true });

        const url = `${this.getConst("app").baseUrlBackend}/paginas_documentos`;
        try {
          await axios.post(url, documentoSave);
        } catch (err) {
          this.setSnackbar(true, "DOCUMENTO", err.response.data, "ERROR");
        } finally {
          this.setVar({ overlay: false });
          this.$emit("clickEditView", true);
        }
      } else {
        this.setSnackbar(
          true,
          "DOCUMENTO",
          "não existe documento para ser salvo",
          "ERROR"
        );

        this.$emit("clickEditView", false);
      }
    },
  },
};
</script>