<!-- COD: LYF 05/07/2022 CVerbaView -->
<!-- *VER: JER 06/08/2022 CVerbaView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nivel -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nivel">
      <v-col v-bind="prop.col" align="center">
        <v-badge
          :color="dataset.main.detail.nivel ? 'green' : 'grey'"
          :content="dataset.main.detail.nivel"
          inline
          overlap
          :value="!!dataset.main.detail.nivel"
        ></v-badge>
      </v-col>
    </v-row>

    <!-- pai -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPai">
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="primary--text" text @click="clickVerba()">
          {{ dataset.aux.detail.verbaPai_nomeAlt }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nome">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="font-weight-medium text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CVerbaView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    options() {
      return JSON.parse(this.dataset.main.detail.options);
    },
  },

  /// METHODS
  methods: {
    clickVerba() {
      this.$router.push(`/verba/${this.dataset.main.detail.idPai}`);
    },
  },
};
</script>