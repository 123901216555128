<!-- COD: BJB 09/08/2022 CBaseDialogEstado -->
<!-- *VER: JER 10/08/2022 CBaseDialogEstado -->
<!-- /22/ -->

<template>
  <v-dialog v-model="show" max-width="380px" persistent scrollable>
    <!-- /// ALTERAR ESTADO -->
    <v-card class="mx-auto" max-width="380">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row justify="end">
            <v-toolbar-title>ALTERAR ESTADO</v-toolbar-title>
          </v-row>
          <v-spacer />
          <v-btn v-if="estados.length" icon @click="clickAlterarEstado">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-8">
        <v-form class="px-0" ref="form">
          <c-base-estados
            :idEstadoNext="idEstadoNext"
            :idPage="idPage"
            :isEstadoCancelar="isEstadoCancelar"
            :maquinaEstado="maquinaEstado.nome"
            :objeto="dataset.main.detail"
            :objetoTemp="datasetTemp.main.detail"
          />

          <slot :datasetTemp="dataset" name="camposEspecificos"></slot>

          <v-row v-if="maquinaEstadoTransicoes.hasMotivo" v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-text-field
                v-model="motivo"
                clearable
                label="motivo"
                :max="50"
                required
              />
            </v-col>
          </v-row>

          <!-- /// BOTTOMSHEET -->
          <c-bottom-sheet :bottomSheet="bottomSheet" />
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CBaseEstados from "@/components/bases/CBaseEstados";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "CBaseDialogEstado",

  /// COMPONENTS
  components: {
    CBaseEstados,
  },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinSnackbar, mixinUsuarios],

  /// PROPS
  props: {
    context: { type: Object, required: true },
    datasetTemp: { type: Object, required: true },
    idEstadoNext: { type: [Number, String], required: false },
    idPage: { type: Number, required: true },
    idPageAlt: { type: Number, required: true },
    isEstadoCancelar: { type: Boolean, default: false, required: false },
    params: { type: Object, required: false },
    show: { type: Boolean, required: true },
    value: { type: Object, required: true },
  },

  /// DATA
  data() {
    return {
      dataset: this.value,
      motivo: null,
      url: null,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getMaquinaEstado_transicoes: "estados/getMaquinaEstado_transicoes",
      getMaquinaEstado: "estados/getMaquinaEstado",
      getPagina: "paginas/getPagina",
      getTabela: "tabelas/getTabela",
      getVar: "variaveis/getVar",
    }),

    estados() {
      return this.getMaquinaEstado_transicoes(
        this.getMaquinaEstado(this.maquinaEstado.nome).id,
        this.datasetTemp.main.detail.idEstado,
        null
      )
        .filter((estado) =>
          !this.idEstadoNext
            ? !estado.isSistema
            : this.idEstadoNext == estado.value
        )
        .filter((estado) => {
          return (
            this.isEstadoCancelar ||
            ((!estado.idContexto ||
              estado.idContexto == this.datasetTemp.main.detail.idTipo) &&
              this.direitosTestar(
                this.getPagina("maquinasEstados_transicoes").id,
                estado.idTransicao
              ))
          );
        })
        .filter((estado) => {
          if (
            this.maquinaEstado.nome == "pedido" &&
            this.datasetTemp.main.detail.idEstado ==
              this.getEstado("expedicao").id
          ) {
            if (this.objeto.isEntregar) {
              return estado.value != this.getEstado("entregue").id;
            } else {
              return estado.value != this.getEstado("enviado").id;
            }
          }

          return true;
        });
    },

    maquinaEstado() {
      return this.getMaquinaEstado(
        this.getTabela(this.getPagina(this.idPage).idTabela).idMaquinaEstado
      );
    },

    maquinaEstadoTransicoes() {
      return (
        this.getMaquinaEstado_transicoes(
          this.getMaquinaEstado(
            this.getTabela(this.getPagina(this.idPage).idTabela).idMaquinaEstado
          ).id,
          this.datasetTemp.main.detail.idEstado,
          null
        ).find(
          (maquinaEstado) =>
            maquinaEstado.value == this.dataset.main.detail.idEstado
        ) || false
      );
    },

    pageAlt() {
      return this.getPagina(this.idPageAlt);
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    async clickAlterarEstado() {
      if (!this.$refs.form.validate()) {
        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );

        return;
      }

      this.context.clickEditView();

      setTimeout(() => {
        if (this.context.$refs.form.validate()) {
          this.context.clickEditView();

          this.dataset.metadados.idUsuario = this.getVar("idUsuario");
          this.dataset.metadados.idPessoaParceiro =
            this.getVar("idPessoaParceiro");
          this.dataset.metadados.motivo = this.motivo;

          const pageNome =
            this.idPage == this.getPagina("avaliacao").id
              ? "avaliacoes"
              : this.pageAlt.nome;

          this.url = `${this.getConst("app").baseUrlBackend}/${pageNome}/state`;

          if (this.params) {
            const params = JSON.stringify(this.params);
            this.url += `/${params}`;
          }

          if (
            this.maquinaEstadoTransicoes &&
            this.maquinaEstadoTransicoes.hasConfirmacao
          ) {
            this.setSnackbar(
              true,
              "CONFIRMAÇÃO",
              "Confirma alteração do estado?",
              "warning",
              "mdi-check",
              "alterarEstado",
              this.getConst("config").snackbar.timeout,
              false
            );
          } else {
            this.submit();
          }
        } else {
          this.setSnackbar(
            true,
            "CAMPOS INVÁLIDOS",
            "Verificar campos inválidos.",
            "error"
          );

          setTimeout(
            () => this.$emit("close"),
            this.getConst("config").snackbar.timeout
          );
        }
      }, 10);
    },

    clickSnackbar(action) {
      if (action == "alterarEstado") {
        this.submit();
      }

      this.setSnackbar();
    },

    submit() {
      this.setVar({ overlay: true });

      if (this.params) {
        Object.assign(this.dataset.aux.detail, this.params);
      }

      axios
        .put(this.url, this.dataset)
        .then(() => {
          if (this.context.clickSearch) {
            this.context.clickSearch();
          }

          this.$emit("alterado", this.dataset);
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
          this.setVar({ overlay: false });
        });
      // .finally(() => {
      //   this.$emit("alterado", this.dataset);
      // });
    },
  },
};
</script>