<!-- COD: FDS 08/07/2022 Localidade -->
<!-- *VER: JER 08/07/2022 Localidade -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- // IMAGENS -->
      <template #images="{ dataset, pageModeView }">
        <c-base-detail-images
          :dataset="dataset"
          :height="70"
          :pageModeView="pageModeView"
          :width="70"
        />
      </template>

      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getPagina(page.nome).id"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-localidade-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-localidade-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapActions } from "vuex";
import axios from "axios";
import CBaseDetailImages from "@/components/bases/CBaseDetailImages";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CLocalidadeEdit from "@/components/pages/localidade/CLocalidadeEdit";
import CLocalidadeView from "@/components/pages/localidade/CLocalidadeView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Localidade",

  /// COMPONENTS
  components: {
    CBaseDetailImages,
    CBaseDetailInsert,
    CBaseDialogEstado,
    CLocalidadeEdit,
    CLocalidadeView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("localidade").id,
      idPageAlt: getPagina("localidades").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idPai: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            nome: null,
            codigo: null,
            codigo2: null,
            codigo3: null,
            isCapital: null,
            hasConfiguracaoRegional: null,
            isEnderecoNumeroPosicaoInicio: null,
            nomeDivisao: null,
            nomeSubdivisao: null,
            latitude: null,
            longitude: null,
            altitude: null,
            options: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          configuracoesRegionais: [],
          codigosPostais: [],
          codigosAreas: [],
          idiomas: [],
          moedas: [],
          incidencias: [],
        },
        aux: {
          detail: {
            localidadePai_nome: null,
            localidadeAvo_nome: null,
            idTipoPai: 0,
          },
          autocomplete: {
            localizacao: [],
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posSubmit: (context) => {
          const url = context.getConst("app").baseUrlBackend;

          axios
            .get(`${url}/localidades`)
            .then((res) => this.setLocalidades(res.data));

          axios
            .get(`${url}/localidades_configuracoesRegionais`)
            .then((res) =>
              this.setLocalidades_configuracoesRegionais(res.data)
            );

          axios
            .get(`${url}/localidades_codigosPostais`)
            .then((res) => this.setLocalidades_codigosPostais(res.data));

          axios
            .get(`${url}/localidades_codigosAreas`)
            .then((res) => this.setLocalidades_codigosAreas(res.data));

          axios
            .get(`${url}/localidades_idiomas`)
            .then((res) => this.setLocalidades_idiomas(res.data));

          axios
            .get(`${url}/localidades_moedas`)
            .then((res) => this.setLocalidades_moedas(res.data));
        },
      },

      params: {
        context: null,
        panelsOpen: null,
      },
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },

  methods: {
    ...mapActions({
      setLocalidades_codigosAreas: "localidades/setLocalidades_codigosAreas",
      setLocalidades_codigosPostais:
        "localidades/setLocalidades_codigosPostais",
      setLocalidades_configuracoesRegionais:
        "localidades/setLocalidades_configuracoesRegionais",
      setLocalidades_idiomas: "localidades/setLocalidades_idiomas",
      setLocalidades_moedas: "localidades/setLocalidades_moedas",
      setLocalidades: "localidades/setLocalidades",
    }),
  },
};
</script>