// COD: BJB 15/08/2022 estados
// *VER: JER 19/08/2022 estados
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    estados: [],
    maquinaEstados: [],
    maquinaEstados_transicoes: []
  },

  /// GETTERS
  getters: {
    getEstados: state => (prop = "id") => {
      return state.estados
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nome
          };
        })
    },

    getEstado: state => search => {
      return state.estados.length
        ? state.estados
          .find(element => element.id == search || element.nome == search)
        : { search }
    },

    getMaquinasEstados: state => (prop = "id") => {
      return state.maquinasEstados
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            text: element.nome
          };
        });
    },

    getMaquinaEstado: state => search => {
      return state.maquinasEstados.length
        ? state.maquinasEstados
          .find(element => element.id == search || element.nome == search)
        : { search }
    },

    getMaquinaEstado_estados: (state, getters) => (id, prop = "id") => {
      return [...new Set(state.maquinasEstados_transicoes
        .filter(element => element.idPai == id)
        .filter(element => !!element.idEstadoPara)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1))]
        .map(element => element.idEstadoPara)
        .map(idEstado => {
          return {
            value: idEstado,
            text: getters.getEstado(idEstado).nome
          };
        })
    },

    getMaquinaEstado_transicoes: (state, getters) => (id, idEstado, isSistema = false, prop = "id") => {
      return state.maquinasEstados_transicoes
        .filter(element => element.idPai == id)
        .filter(element => element.idEstadoDe == idEstado)
        .filter(element => !!element.isSistema == isSistema || isSistema === null)
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            idTransicao: element.id,
            idTipo: element.idTipo,
            idContexto: element.idContexto,
            value: element.idEstadoPara,
            text: getters.getEstado(element.idEstadoPara).nome,
            textAlt: getters.getEstado(element.idEstadoPara).nomeAlt,
            isSistema: element.isSistema,
            isAvancar: element.isAvancar,
            isDefault: element.isDefault,
            hasMotivo: element.hasMotivo,
            hasConfirmacao: element.hasConfirmacao,
            icon: element.icon,
            color: element.color,
            perfis: element.perfis
          };
        });
    }
  },

  /// MUTATIONS
  mutations: {
    setEstados: (state, payload) => {
      state.estados = []
      payload.forEach(element => {
        state.estados.push(element)
      });
    },

    setMaquinasEstados: (state, payload) => {
      state.maquinasEstados = []
      payload.forEach(element => {
        state.maquinasEstados.push(element)
      });
    },

    setMaquinasEstados_transicoes: (state, payload) => {
      state.maquinasEstados_transicoes = []
      payload.forEach(element => {
        state.maquinasEstados_transicoes.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setEstados: (context, payload) => {
      context.commit("setEstados", payload)
    },

    setMaquinasEstados: (context, payload) => {
      context.commit("setMaquinasEstados", payload)
    },

    setMaquinasEstados_transicoes: (context, payload) => {
      context.commit("setMaquinasEstados_transicoes", payload)
    }
  }
}