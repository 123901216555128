<!-- COD: FDS 08/05/2023 Lote -->
<!-- #VER: JER __/05/2023 Lote -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-lote-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-lote-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CLoteEdit from "@/components/pages/lote/CLoteEdit";
import CLoteView from "@/components/pages/lote/CLoteView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Lote",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CLoteEdit,
    CLoteView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("lote").id,
      idPageAlt: getPagina("lotes").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            nome: null,
            nomeAlt: null,
            nomeComplementar: null,
            grupo: null,
            allowBaixaCruzada: null,
            isGerarContaPagRecOposta: null,
            options: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          contasContabeis: [],
          paginas: [],
        },
        aux: {
          autocomplete: {
            contasContabeis: [],
            grupos: [],
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posSubmit: (context) => {
          context.dataset.main.itens.forEach((item, i) => {
            item.quantidade = this.numberFormat(
              item.quantidade,
              "decimal",
              context.dataset.aux.itens[i].decimalEstoque || 0
            );
          });

          context.dataset.aux.pedidosItens.forEach((pedidoItem) => {
            pedidoItem.estado = this.getEstado(pedidoItem.idEstado).nomeAlt;
            pedidoItem.tipo = this.getTipo_item(pedidoItem.idTipo).nomeAlt;
          });
        },
      },

      params: {
        context: this,
      },

      idModeInsert: getConst("config").pageMode.insert.id,
    };
  },
};
</script>