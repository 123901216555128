<!-- #COD: LYF __/11/2022 CBaseDialogPessoaIncluir -->
<!-- VER: JER __/11/2022 CBaseDialogPessoaIncluir -->

<template>
  <v-dialog v-model="idPagina" max-width="380px" persistent scrollable>
    <!-- /// INCLUIR PESSOA -->
    <v-card class="mx-auto" max-width="380">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" cols="1">
              <v-btn class="ml-n3" icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col v-bind="prop.col" align="center" cols="10">
              <v-toolbar-title>INCLUIR PESSOA</v-toolbar-title>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-8">
        <v-form class="px-0" ref="form">
          <!-- pais bandeira -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-menu
                v-model="documento.idPais"
                classe="ml-n2"
                :disabled="jaVerificou"
                :items="paisesAtivos"
              >
                <template #btn="{ itemSelected }">
                  <v-img
                    alt="imagem"
                    class="mr-2"
                    contain
                    :src="itemSelected.image"
                    width="25"
                  />
                  <span v-if="itemSelected" class="text=body-2">
                    {{ itemSelected.text }}
                  </span>
                </template>
                <template #item="{ item }">
                  <v-img alt="imagem" contain :src="item.image" width="25" />
                  <v-list-item-title class="ml-2 text-body-2">
                    {{ item.text }}
                  </v-list-item-title>
                </template>
              </c-menu>
            </v-col>
          </v-row>

          <!-- tiposPessoa -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-select
                v-model="idTipo"
                :disabled="jaVerificou"
                :items="tiposPessoa"
                @change="changeTipoPessoa"
              />
            </v-col>
          </v-row>

          <!-- documentoFederal -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-text-field
                v-model="documento.federal"
                :disabled="jaVerificou"
                :dynamicsRules="rules.documento"
                :help="{
                  title: 'NÚMERO DOCUMENTO',
                  text: documentoInfo,
                  function: setUiBottomSheet,
                }"
                :label="labelDocumentoFederal(false)"
                :max="25"
                :options="{
                  mask: mascaraDocumento(false),
                  options: optionsDocumento(false),
                }"
                :placeholder="placeholderDocumento(false)"
                required
              />
            </v-col>
          </v-row>

          <!-- dataNascimento -->
          <v-row
            v-bind="prop.row"
            v-if="idTipo == getTipo_item('pessoaFisica').id"
          >
            <v-col v-bind="prop.col" cols="6">
              <c-datetime-picker
                v-model="dataNascimento"
                date
                :disabled="jaVerificou"
                label="data nascimento"
                :maxValue="maxValueDataNascimento"
                required
              />
            </v-col>
          </v-row>

          <!-- buscar -->
          <v-row v-bind="prop.row" v-if="!jaVerificou">
            <v-col v-bind="prop.col" cols="6">
              <c-btn classe="text-body-1" color="primary" @click="clickBuscar">
                buscar
              </c-btn>
            </v-col>
          </v-row>

          <div v-if="jaVerificou && dataset.nome">
            <!-- nome -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="center">
                <a class="text-h6" @click="clickPessoa">
                  {{ dataset.nome }}
                </a>
              </v-col>
            </v-row>

            <!-- selecionar -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="center">
                <c-btn
                  classe="text-body-1"
                  color="primary"
                  @click="clickSelecionar"
                >
                  selecionar
                </c-btn>
              </v-col>
            </v-row>
          </div>

          <div v-if="jaVerificou && !dataset.nome">
            <!-- tipos cadastro -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="center">
                <v-chip-group
                  v-model="tipoCadastro"
                  mandatory
                  style="width: fit-content"
                >
                  <v-chip
                    v-for="(tipo, i) in tiposCadastro.filter(
                      (tipoCadastro) =>
                        idTipoConvite || tipoCadastro.text == 'cadastrar'
                    )"
                    :key="i"
                    class="primary text-overline"
                    :class="
                      tipoCadastro != tipo.value
                        ? 'primary--text'
                        : 'white--text'
                    "
                    :outlined="tipoCadastro != tipo.value"
                    :value="tipo.value"
                    @click.stop
                  >
                    {{ translate(tipo.text) }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>

            <div v-if="tipoCadastro">
              <!-- nome fantasia -->
              <v-row
                v-bind="prop.row"
                v-if="idTipo == getTipo_item('pessoaJuridica').id"
              >
                <v-col v-bind="prop.col">
                  <c-text-field
                    v-model="convite.nomeFantasia"
                    label="nome fantasia"
                    :max="20"
                    required
                  />
                </v-col>
              </v-row>

              <!-- documentoFederal -->
              <v-row
                v-bind="prop.row"
                v-if="idTipo == getTipo_item('pessoaJuridica').id"
              >
                <v-col v-bind="prop.col">
                  <c-text-field
                    v-model="convite.documentoFederal"
                    :help="{
                      title: 'NÚMERO DOCUMENTO',
                      text: documentoInfo,
                      function: setUiBottomSheet,
                    }"
                    :label="labelDocumentoFederal(true)"
                    :max="25"
                    :options="{
                      mask: mascaraDocumento(true),
                      options: optionsDocumento(true),
                    }"
                    :placeholder="placeholderDocumento(true)"
                    required
                  />
                </v-col>
              </v-row>

              <!-- nome e sobrenome -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-text-field
                    v-model="convite.nomeCompleto"
                    label="nome e sobrenome"
                    :max="30"
                    required
                  />
                </v-col>
              </v-row>

              <!-- email -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-text-field
                    v-model="convite.email"
                    label="e-mail"
                    :max="80"
                    required
                    type="text"
                  />
                </v-col>
              </v-row>

              <!-- celular -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <c-text-field
                    v-model="convite.celular"
                    :help="{
                      title: 'NÚMERO TELEFONE',
                      text: telefoneInfo,
                      function: setUiBottomSheet,
                    }"
                    label="celular"
                    :max="15"
                    :options="{
                      mask: mascaraTelefone,
                      options: optionsTelefone,
                    }"
                    :placeholder="placeholderTelefone"
                    type="tel"
                  />
                </v-col>
              </v-row>
            </div>

            <v-row v-bind="prop.row">
              <!-- voltar -->
              <v-col v-bind="prop.col" cols="2">
                <c-btn class="primary--text" icon @click="clickVoltar">
                  <v-icon class="primary--text">mdi-arrow-left</v-icon>
                </c-btn>
              </v-col>
              <!-- convidar/cadastrar -->
              <v-col v-bind="prop.col" cols="6">
                <c-btn
                  block
                  classe="text-body-1"
                  color="primary"
                  @click="clickConvidarCadastrar(tipoCadastro)"
                >
                  {{ tipoCadastro ? "convidar" : "cadastrar" }}
                </c-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// BOTTOMSHEET -->
    <c-bottom-sheet :bottomSheet="bottomSheet" />

    <!-- /// SNACKBAR -->
    <c-snackbar @click="clickSnackbar($event)" :snackbar="snackbar" />
  </v-dialog>
</template>

<script>
/// IMPORT
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CBaseDialogPessoaIncluir",

  /// DIRETIVES
  directives: { mask },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinData, mixinSnackbar],

  /// PROPS
  props: {
    idPagina: { type: Number, required: true },
    idFeature: { type: Number },
    idTipoConvite: { type: Number },
    params: {},
  },

  /// DATA
  data() {
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getLocalidade = this.$store.getters["localidades/getLocalidade"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      dataset: {},
      convite: {
        nomeFantasia: null,
        documentoFederal: null,
        nomeCompleto: null,
        email: null,
        celular: null,
      },
      dataNascimento: null,
      documento: {
        federal: null,
        idPais: getLocalidade("brasil").id,
      },
      idTipo:
        [getPagina("fornecedorCliente").id, getPagina("pessoa").id].includes(
          this.idPagina
        ) &&
        (!this.idFeature ||
          this.idFeature == getFeature("optionPageEmpresa").id)
          ? getTipo_item("pessoaJuridica").id
          : getTipo_item("pessoaFisica").id,
      jaVerificou: false,
      labelDocFederal: null,
      rules: {
        documento: [
          (value) =>
            !this.params?.documentoFederal ||
            value.clean() != this.params.documentoFederal.clean() ||
            "documento inválido para este contato",
        ],
      },
      tipoCadastro: true,
      tiposCadastro: [
        { value: true, text: "convidar" },
        { value: false, text: "cadastrar" },
      ],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getLocalidade_configuracoesRegionais:
        "localidades/getLocalidade_configuracoesRegionais",
      getLocalidades: "localidades/getLocalidades",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
    }),

    configuracaoRegionalDocumento() {
      return this.configuracaoRegionalByTipo(
        this.documento.idPais,
        null,
        "configuracaoRegionalDocumento",
        this.idTipo
      );
    },

    configuracaoRegionalTelefone() {
      return this.configuracaoRegionalByTipo(
        this.documento.idPais,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    maxValueDataNascimento() {
      return this.subYears(new Date(), 16).toISOString().substring(0, 10);
    },

    mascaraTelefone() {
      return this.maskByTipo(
        this.documento.idPais,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    optionsTelefone() {
      return this.optionsByTipo(
        this.documento.idPais,
        null,
        "configuracaoRegionalTelefone"
      );
    },

    placeholderTelefone() {
      return Array.isArray(this.configuracaoRegionalTelefone?.mascaras)
        ? ""
        : this.mascaraTelefone.replaceAll("!", "");
    },

    documentoInfo() {
      return (
        this.masksInfoByPais(
          this.documento.idPais,
          null,
          "configuracaoRegionalDocumento",
          this.idTipo
        ) || ""
      );
    },

    telefoneInfo() {
      return (
        this.masksInfoByPais(
          this.documento.idPais,
          null,
          "configuracaoRegionalTelefone"
        ) || ""
      );
    },

    tiposPessoa() {
      return this.getTipo_itens(this.getTipo("tipoPessoa").id).filter(
        (tipoPessoa) =>
          (tipoPessoa.value == this.getTipo_item("pessoaFisica").id &&
            this.idFeature != this.getFeature("optionPageEmpresa").id) ||
          (tipoPessoa.value == this.getTipo_item("pessoaJuridica").id &&
            this.idFeature !=
              this.getFeature("CFornecedorClientePanelCreditos").id &&
            this.idPagina != this.getPagina("colaborador").id)
      );
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    changePais() {
      const configuracaoRegionalDocumento =
        this.getLocalidade_configuracoesRegionais(
          this.idPais,
          this.getTipo_item("configuracaoRegionalDocumento").id,
          this.idTipo
        );
      const documentoNome = configuracaoRegionalDocumento[0]?.nomeAlt;
      this.labelDocFederal = documentoNome;

      this.forceUpdate = true;
      this.$forceUpdate();
    },

    changeTipoPessoa() {
      this.dataNascimento = null;
    },

    async clickBuscar() {
      try {
        await this.validate();

        const search = "*";
        const idContexto = "*";
        const idFilter = this.getFeature("filterAbertos").id;
        const idOrder = this.getFeature("orderOrdenar").id;
        const idPessoaParceiro = this.getConst("app").id;
        const idUsuario = this.getConst("app").id;
        const complete = 0;
        const offset = 0;
        const limit = 20;
        const params = {
          "pes_doc.numero": this.documento.federal,
        };

        if (this.dataNascimento) {
          params["_tab.dataNascimento"] = this.dataNascimento;
        }

        const url = `${
          this.getConst("app").baseUrlBackend
        }/pessoas/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${limit}/${JSON.stringify(
          params
        )}`;

        const res = await axios.get(url);

        if (res?.data?.length) {
          this.dataset = res.data[0];
        }

        this.jaVerificou = true;
      } catch (err) {
        this.jaVerificou = false;

        if (err) {
          this.setSnackbar(err.show, err.title, err.text, err.color);
        }
      }
    },

    async clickConvidarCadastrar(convidar) {
      if (convidar) {
        this.setVar({ overlay: true });

        try {
          const metadados = {
            idEvento: this.getTipo_item("eventoIncluido").id,
            modo: this.getConst("config").modo.cadastro.id,
            idUsuario: this.getVar("idUsuario"),
            idPessoaParceiro: this.getVar("idPessoaParceiro"),
          };

          const dataset = {
            metadados,
            main: {
              detail: {
                idEstado: this.getEstado("cadastro").id,
                idTipo: this.idTipoConvite,
                idPais: this.documento.idPais,
                documentoFederal:
                  this.convite.documentoFederal || this.documento.federal,
                nomeAlt: this.convite.nomeCompleto,
                email: this.convite.email,
                celular: this.convite.celular,
                documentoFederalPJ:
                  this.idTipo == this.getTipo_item("pessoaJuridica").id
                    ? this.documento.federal
                    : null,
                nomeAltPJ: this.convite.nomeFantasia,
              },
            },
            aux: {
              enviarConvite: true,
              idPagina: this.idPagina,
              idFeature: this.idFeature,
            },
          };

          let url = `${this.getConst("app").baseUrlBackend}/convites`;

          await axios.post(url, dataset);

          this.setSnackbar(
            true,
            "CONVITE DE CADASTRO",
            "Convite enviado por email.\nVocê receberá uma notificação quando a pessoa se cadastrar.",
            "success"
          );

          setTimeout(() => {
            this.$emit("close");
          }, this.getConst("config").snackbar.timeout);
        } catch (err) {
          this.setSnackbar(
            true,
            "CONVITE DE CADASTRO",
            err.response.data,
            "error"
          );
        } finally {
          this.setVar({ overlay: false });
        }
      } else {
        const objectRoute = {
          path: "/pessoa",
          query: {
            idTipo: this.idTipo,
            dataNascimento: this.dataNascimento,
            idPais: this.documento.idPais,
            documentoFederal: this.documento.federal,
          },
        };

        if (this.idPagina == this.getPagina("pessoa").id) {
          setTimeout(() => {
            this.$router.push(objectRoute);
          }, 100);
          this.$emit("close");
        } else {
          objectRoute.query.blank = true;
          const route = this.$router.resolve(objectRoute);
          window.open(route.href);
        }
      }
    },

    clickPessoa() {
      const objectRoute = {
        path: `/pessoa/${this.dataset.id}`,
      };

      if (this.idPagina == this.getPagina("pessoa").id) {
        setTimeout(() => {
          this.$router.push(objectRoute);
        }, 100);
        this.$emit("close");
      } else {
        objectRoute.query.blank = true;
        const route = this.$router.resolve(objectRoute);
        window.open(route.href);
      }
    },

    clickSelecionar() {
      this.$emit("close", this.dataset);
    },

    clickVoltar() {
      this.jaVerificou = false;
      this.dataset = {};
      this.convite = {
        nomeFantasia: null,
        documentoFederal: null,
        nomeCompleto: null,
        email: null,
        celular: null,
      };
    },

    labelDocumentoFederal(isConvite) {
      const configuracaoRegionalDocumento =
        this.getLocalidade_configuracoesRegionais(
          this.documento.idPais,
          this.getTipo_item("configuracaoRegionalDocumento").id,
          isConvite ? this.getTipo_item("pessoaFisica").id : this.idTipo
        );

      return `${configuracaoRegionalDocumento[0]?.nomeAlt}${
        isConvite ? " (admin)" : ""
      }`;
    },

    mascaraDocumento(isConvite) {
      return this.maskByTipo(
        this.documento.idPais,
        null,
        "configuracaoRegionalDocumento",
        isConvite ? this.getTipo_item("pessoaFisica").id : this.idTipo
      );
    },

    optionsDocumento(isConvite) {
      return this.optionsByTipo(
        this.documento.idPais,
        null,
        "configuracaoRegionalDocumento",
        isConvite ? this.getTipo_item("pessoaFisica").id : this.idTipo
      );
    },

    placeholderDocumento(isConvite) {
      return Array.isArray(this.configuracaoRegionalDocumento?.mascaras)
        ? ""
        : this.mascaraDocumento(isConvite).replaceAll("!", "");
    },

    async validate() {
      return new Promise((resolve, reject) => {
        if (this.$refs.form.validate()) {
          this.$refs.form.resetValidation();
          resolve();
        } else {
          const err = {
            show: true,
            title: "CAMPOS INVÁLIDOS",
            text: "Verificar campos inválidos.",
            color: "error",
          };
          reject(err);
        }
      });
    },
  },
};
</script>