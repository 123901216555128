<!-- COD: FDS 26/07/2022 CContaPagRecDialogCartaAnuencia -->
<!-- *VER: JER 05/08/2022 CContaPagRecDialogCartaAnuencia -->
<!-- /22/ -->

<template>
  <v-dialog v-model="value" max-width="380px" persistent scrollable>
    <v-form ref="form">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="primary" dark flat>
            <v-btn icon @click="$emit('input', false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer />
            <v-toolbar-title>
              {{ title }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="print()">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text ref="printArea">
          <!-- colaborador financeiro -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col">
              <c-select
                v-model="colaboradorFinancas_id"
                :items="colaboradoresFinancas"
                label="colaborador financeiro"
                required
              />
            </v-col>
          </v-row>

          <v-row v-bind="prop.row">
            <!-- logo -->
            <v-col v-bind="prop.col" cols="3">
              <img :src="logoParceiro" width="100" />
            </v-col>
            <!-- title -->
            <v-col v-bind="prop.col" align="center" class="mb-n8" cols="6">
              <span v-if="printing" class="font-weight-bold text-body-2">
                {{ title }}
              </span>
            </v-col>
            <!-- id -->
            <v-col v-bind="prop.col" align="end" class="mb-n8" cols="3">
              <span class="font-weight-bold mr-n1 text-body-2">
                #
                {{ contaPagRec.main.detail.id }}
              </span>
            </v-col>
          </v-row>

          <v-divider v-bind="prop.divider" />

          <!-- emitente -->
          <v-row v-bind="prop.row" class="mb-n2">
            <v-col v-bind="prop.col" align="center">
              <p class="font-weight-medium mb-n1 text-body-1">
                {{ contaPagRec.aux.detail.pessoaParceiro.nome.toUpperCase() }}
              </p>
              <p class="mb-n1 text-body-2">
                <!-- //?b fazer endereço conforme código comentado -->
                <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco1 }} -->
                {{ pessoaParceiroEndereco.endereco1 }}
              </p>
              <p class="mb-n1 text-body-2">
                <!-- //?b fazer endereço conforme código comentado -->
                <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco2 }} -->
                {{ pessoaParceiroEndereco.endereco2 }}
              </p>
              <p class="mb-n1 text-body-2">
                {{
                  pessoaDocumentosFormat(contaPagRec.aux.detail.pessoaParceiro)
                }}
              </p>
            </v-col>
          </v-row>

          <v-divider v-bind="prop.divider" />

          <!-- devedor -->
          <v-row v-bind="prop.row" class="mb-n2">
            <v-col v-bind="prop.col" align="center">
              <p class="mt-3 mb-n1 text-caption">DEVEDOR</p>
              <p class="font-weight-medium mb-n1 text-body-1">
                {{ contaPagRec.aux.detail.pessoa.nome.toUpperCase() }}
              </p>
              <p class="mb-n1 text-body-2">
                <!-- //?b fazer endereço conforme código comentado -->
                <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco1 }} -->
                {{ pessoaEndereco.endereco1 }}
              </p>
              <p class="mb-n1 text-body-2">
                <!-- //?b fazer endereço conforme código comentado -->
                <!-- {{ enderecoFormatado(notaFiscal.aux.pessoaEmitente).endereco2 }} -->
                {{ pessoaEndereco.endereco2 }}
              </p>
              <p class="mb-n1 text-body-2">
                {{ pessoaDocumentosFormat(contaPagRec.aux.detail.pessoa) }}
              </p>
            </v-col>
          </v-row>

          <v-divider v-bind="prop.divider" />

          <!-- duplicata -->
          <v-row
            v-bind="prop.row"
            v-if="contaPagRec.main.detail.duplicata"
            class="mb-n3"
          >
            <v-col v-bind="prop.col" align="end" cols="6">
              <span class="text-body-2">duplicata</span>
            </v-col>
            <v-col v-bind="prop.col" cols="6">
              <span class="text-body-1">
                {{ contaPagRec.main.detail.duplicata }}
              </span>
            </v-col>
          </v-row>

          <!-- emissao -->
          <v-row
            v-bind="prop.row"
            v-if="contaPagRec.main.detail.dataEmissao"
            class="my-n3"
          >
            <v-col v-bind="prop.col" align="end" cols="6">
              <span class="text-body-2">emissão</span>
            </v-col>
            <v-col v-bind="prop.col" cols="6">
              <span class="text-body-1">
                {{
                  dateFormat(
                    contaPagRec.main.detail.dataEmissao,
                    "date",
                    null,
                    true
                  )
                }}
              </span>
            </v-col>
          </v-row>

          <!-- vencimento -->
          <v-row
            v-bind="prop.row"
            v-if="contaPagRec.main.detail.dataVencimento"
            class="mt-n3"
          >
            <v-col v-bind="prop.col" align="end" cols="6">
              <span class="text-body-2">vencimento</span>
            </v-col>
            <v-col v-bind="prop.col" cols="6">
              <span class="text-body-1">
                {{
                  dateFormat(
                    contaPagRec.main.detail.dataVencimento,
                    "date",
                    null,
                    true
                  )
                }}
              </span>
            </v-col>
          </v-row>

          <!-- valor -->
          <v-row
            v-bind="prop.row"
            v-if="contaPagRec.main.detail.valor"
            class="mb-n3"
          >
            <v-col v-bind="prop.col" align="center">
              <span class="font-weight-medium text-body-1">
                {{ numberFormat(contaPagRec.main.detail.valor, "currency") }}
              </span>
            </v-col>
          </v-row>

          <!-- valor extenso -->
          <v-row
            v-bind="prop.row"
            v-if="contaPagRec.main.detail.valor"
            class="my-n3"
          >
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2">
                ({{ contaPagRec.main.detail.valor.numberToWords(locale) }})
              </span>
            </v-col>
          </v-row>

          <v-divider v-bind="prop.divider" />

          <!-- texto -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-1">
                Recebemos a importância acima, damos quitação e autorizamos
                cancelar o protesto, nos termos da Lei 6609/79, com as
                alterações introduzidas pela Lei 7401/85.
              </span>
            </v-col>
          </v-row>

          <!-- local -->
          <v-row v-bind="prop.row" class="mt-5">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-1">
                {{
                  `${
                    getLocalidade(dadosParceiro.enderecos[0].idCidade).nome
                  }, ${dateFormat(new Date(), "extenso", null, true)}`
                }}
              </span>
            </v-col>
          </v-row>

          <!-- assinatura -->
          <v-row v-bind="prop.row" class="mb-8mt-8">
            <v-col v-bind="prop.col" align="center">
              <p class="mt-6 text-body-1">______________________________</p>
              <p v-if="colaboradorFinancas" class="mt-n5 text-body-1">
                {{ colaboradorFinancas.nome }}
              </p>
              <p v-if="colaboradorFinancas" class="mt-n5 text-body-2">
                {{
                  colaboradorFinancas.documentoFederal.mask(
                    documentoMascara(
                      contaPagRec.aux.detail.pessoa_endereco_idPais,
                      contaPagRec.aux.detail.pessoa_idTipo
                    )
                  )
                }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- /// SNACKBAR -->
    <c-snackbar @click="clickSnackbar($event)" :snackbar="snackbar" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CContaPagRecDialogCartaAnuencia",

  /// MIXINS
  mixins: [mixinData, mixinSnackbar],

  /// PROPS
  props: {
    contaPagRec: Object,
    value: Boolean,
  },

  /// DATA
  data() {
    return {
      colaboradorFinancas_id: null,
      printing: false,
      title: "CARTA DE ANUÊNCIA",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
      getLocalidade_configuracoesRegionais:
        "localidades/getLocalidade_configuracoesRegionais",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    colaboradorFinancas() {
      return this.contaPagRec.aux.colaboradoresFinancas.find(
        (item) => item.id == this.colaboradorFinancas_id
      );
    },

    colaboradoresFinancas() {
      return this.contaPagRec.aux.colaboradoresFinancas.map((item) => {
        return {
          value: item.id,
          text: item.nome,
        };
      });
    },

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    locale() {
      return this.localeByPais(
        this.contaPagRec.aux.detail.pessoaParceiro.endereco.idPais
      );
    },

    logoParceiro() {
      return this.dadosParceiro.imagem.endereco;
    },

    pessoaEndereco() {
      return this.pessoasEnderecoFormat(
        this.contaPagRec.aux.detail.pessoa.endereco
      );
    },

    pessoaParceiroEndereco() {
      return this.pessoasEnderecoFormat(
        this.contaPagRec.aux.detail.pessoaParceiro.endereco
      );
    },
  },

  /// METHODS
  methods: {
    documentoMascara(idPais, idTipo) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id,
        idTipo
      )[0]?.mascara;

      return mascara;
    },

    print() {
      if (!this.$refs.form.validate()) {
        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );

        return null;
      }

      this.printing = true;
      setTimeout(() => {
        this.pagePrint("cartaAnuencia");
        setTimeout(() => {
          this.printing = false;
        }, 10);
      }, 10);
    },
  },
};
</script>