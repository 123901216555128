var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.indice)?_c('v-row',_vm._b({class:!_vm.dataset ? 'ml-n9 mr-n5' : ''},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip-group',{staticClass:"mb-2",attrs:{"center-active":"","mandatory":"","show-arrows":!_vm.isMobile},model:{value:(_vm.categoriasSelected[0]),callback:function ($$v) {_vm.$set(_vm.categoriasSelected, 0, $$v)},expression:"categoriasSelected[0]"}},_vm._l((_vm.categorias(0)),function(categoria,i){return _c('v-chip',{key:i,staticClass:"text-body-2",class:("" + (_vm.categoriasSelected[0] != categoria.id
              ? 'primary--text'
              : 'white--text')),attrs:{"color":_vm.pageModeView ? 'corDisabled' : 'primary',"outlined":_vm.categoriasSelected[0] != categoria.id,"value":categoria.id},on:{"click":function($event){_vm.categoriasSelected[1] = _vm.getCategorias(categoria.id).find(
              function (element) { return element.isDefault; }
            ).id}}},[(categoria.options && categoria.options.icon)?_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(categoria.options.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(categoria.nomeAlt)+" ")],1)}),1)],1)],1):_vm._e(),_c('v-row',_vm._b({class:!_vm.dataset ? 'ml-n9 mr-n5' : ''},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-slide-group',{attrs:{"center-active":"","mandatory":"","show-arrows":!_vm.isMobile},model:{value:(_vm.categoriasSelected[1]),callback:function ($$v) {_vm.$set(_vm.categoriasSelected, 1, $$v)},expression:"categoriasSelected[1]"}},_vm._l((_vm.categorias(1)),function(categoria,n){return _c('v-slide-item',{key:n,attrs:{"value":categoria.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var toggle = ref.toggle;
return [_c('v-card',{staticClass:"pa-0",class:_vm.categoriasSelected[1] != categoria.id || 'selected',attrs:{"flat":"","outlined":_vm.categoriasSelected[1] == categoria.id,"rounded":"md","width":"90"},on:{"click":function($event){toggle();
              _vm.change();}}},[_c('v-row',_vm._b({staticClass:"ma-0 pa-0"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('v-img',{staticClass:"ma-0 pa-0",attrs:{"max-height":"50","max-width":"50","src":(categoria.options && categoria.options.src) ||
                    _vm.getConst('app').urlSemImagem}})],1)],1),_c('v-row',_vm._b({staticClass:"ma-0 pa-0"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(categoria.nomeAlt)+" ")])])],1)],1)]}}],null,true)})}),1)],1)],1),_c('v-row',_vm._b({class:!_vm.dataset ? 'ml-n9 mr-n5' : ''},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip-group',{attrs:{"column":"","multiple":""},model:{value:(_vm.categoriasSelected[2]),callback:function ($$v) {_vm.$set(_vm.categoriasSelected, 2, $$v)},expression:"categoriasSelected[2]"}},_vm._l((_vm.categorias(2)),function(categoria,i){return _c('v-chip',{key:i,staticClass:"text-body-2",class:((_vm.pageModeView ? 'corDisabled' : 'primary') + " " + (!!_vm.categoriasSelected[2] &&
            !_vm.categoriasSelected[2].includes(categoria.id)
              ? 'primary--text'
              : 'white--text')),attrs:{"disabled":_vm.pageModeView,"outlined":!!_vm.categoriasSelected[2] &&
            !_vm.categoriasSelected[2].includes(categoria.id),"value":categoria.id},on:{"click":_vm.change}},[(categoria.options && categoria.options.icon)?_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(categoria.options.icon)+" ")]):(categoria.options && categoria.options.flag)?_c('v-img',{staticClass:"ml-n2 mr-1",attrs:{"contain":"","height":"24","src":_vm.getLocalidade(categoria.options.flag).endereco,"width":"24"}}):_vm._e(),_vm._v(" "+_vm._s(categoria.nomeAlt)+" ")],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }