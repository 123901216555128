<!-- COD: FDS 20/07/2022 CPublicacaoEdit -->
<!-- *VER: JER 20/07/2022 CPublicacaoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- publicado -->
    <v-row v-bind="prop.row" v-if="isTipo(['publicacaoProduto'])">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPublicado"
          :itemsIniciais="autocompleteProdutos"
          label="produto"
          :path="`/produtos/autocomplete/produtos/${dataset.main.detail.idPessoaParceiro}/${context.localeParceiro}/0/`"
          required
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- marca -->
    <v-row v-bind="prop.row" v-if="isTipo(['publicacaoMarca'])">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.marca"
          :itemsIniciais="dataset.aux.autocomplete.marcas"
          label="marca"
          :path="`/produtos/autocomplete/marcas/${dataset.main.detail.idPessoaParceiro}/${context.localeParceiro}/0/`"
          required
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- idioma -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-base-idiomas
          v-model="context.idLocale"
          classe="ml-n1"
          :idiomas="context.dadosParceiro.idiomas"
        />
      </v-col>
    </v-row>

    <!-- titulo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.titulo[context.localeParceiro]"
          label="titulo"
          :max="100"
          :min="1"
          prepend-inner-icon="mdi-translate"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data inicio -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataInicio"
          date
          label="data início"
          :minValue="format(new Date(), `yyyy-MM-dd HH:mm`)"
          required
          time
        />
      </v-col>
      <!-- data fim -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataFim"
          date
          :disabled="!dataset.main.detail.dataInicio"
          label="data fim"
          :minValue="dataset.main.detail.dataInicio || new Date().toISOString()"
          time
        />
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.data"
          date
          :help="{
            title: 'DATA DA PUBLICAÇÃO',
            text: `Data em que esta publicação será exibida na página inicial.`,
            function: context.setUiBottomSheet,
          }"
          label="data"
          time
        />
      </v-col>

      <!-- recorrencia -->
      <v-col v-bind="prop.col" cols="5">
        <c-select
          v-model="dataset.main.detail.recorrencia"
          class="ml-2"
          :help="{
            title: 'RECORRÊNCIA',
            text: `Intervalo de tempo (em horas) em que esta publicação volta para o topo da página inicial.`,
            function: context.setUiBottomSheet,
          }"
          :items="itemsRecorrencia"
          label="recorrência"
        />
      </v-col>
    </v-row>

    <!-- notificacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.hasNotificacao"
          label="Enviar notificação para os clientes?"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseIdiomas from "@/components/bases/CBaseIdiomas";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPublicacaoEdit",

  /// COMPONENTS
  components: { CBaseIdiomas },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// DATA
  data() {
    return {
      itemsRecorrencia: [
        { value: null, text: "desativada" },
        { value: 1, text: "1 hora" },
        { value: 2, text: "2 horas" },
        { value: 3, text: "3 horas" },
        { value: 6, text: "6 horas" },
        { value: 12, text: "12 horas" },
        { value: 24, text: "1 dia" },
        { value: 48, text: "2 dias" },
        { value: 72, text: "3 dias" },
        { value: 168, text: "7 dias" },
      ],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    autocompleteProdutos() {
      return this.dataset.aux.autocomplete.produtos.map((produto) => {
        return {
          value: produto.value,
          text: this.produtoFormat(produto, false, true, false, true),
        };
      });
    },
  },

  /// METHODS
  methods: {
    isTipo(tipos) {
      return tipos.includes(
        this.getTipo_item(this.dataset.main.detail.idTipo)?.nome
      );
    },
  },
};
</script>