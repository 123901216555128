<!-- COD: LYF 27/07/2022 ContaPagRec_BaixaCruzada -->
<!-- *VER: JER 05/08/2022 ContaPagRec_BaixaCruzada -->
<!-- /32/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcsDetail"
      :idFeature="idFeature"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <template v-slot="{ context }">
        <c-contaPagRec-view :context="context" :dataset="dataset" />
      </template>
    </c-base-detail>
    <c-base-list
      v-if="loadList"
      :funcs="funcsList"
      :idFeature="cIdFeature"
      :idPage="idPageAlt"
      :idPageAlt="idPage"
      :pageModeEdit="true"
    >
      <template #insert="{ context }">
        <div v-if="context.datasets.length">
          <!-- label -->
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="red--text text-body-2">
                selecione as contas a baixar
              </span>
            </v-col>
          </v-row>
        </div>

        <!-- saldo a baixar -->
        <v-row
          v-if="context.datasets.length"
          class="sticky mt-3"
          justify="center"
        >
          <v-col v-bind="prop.col" cols="6">
            <!-- div criada para o campo não ficar transparente sobre a tela -->
            <div class="white" style="height: 65%">
              <c-text-money
                classe="font-weight-light text-h5"
                :clearable="false"
                label="saldo a baixar"
                :max="13"
                :options="{
                  length: 11,
                  precision: 2,
                  style: 'currency',
                }"
                outlined
                readonly
                :success="getTotais(context).saldo == 0"
                :value="getTotais(context).saldo"
              />
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot="{ context, dataset: contaPagRec, index }">
        <c-contaPagRec-page-baixaCruzada-edit
          :contaPagRec="contaPagRec"
          :context="context"
          :index="index"
          :idPage="idPage"
          :saldoBaixar="saldoBaixar"
        />
      </template>
      <template #bottom="{ context }">
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center" cols="6">
            <!-- total selecionado -->
            <c-text-money
              classe="font-weight-light mt-5 text-h5"
              :clearable="false"
              label="total selecionado"
              :max="13"
              :options="{
                length: 11,
                precision: 2,
                style: 'currency',
              }"
              outlined
              readonly
              :value="getTotais(context).totalSelecionado"
            />
            <!-- quantidade selecionada -->
            <v-badge
              v-if="getTotais(context).quantidade > 0"
              class="mb-n3 ml-n2"
              color="grey"
              :content="getTotais(context).quantidade"
              overlap
            />
          </v-col>
        </v-row>
      </template>
    </c-base-list>
  </div>
</template>

<script>
/// IMPORT
import CContaPagRecView from "@/components/pages/contaPagRec/CContaPagRecView";
import CContaPagRecPageBaixaCruzadaEdit from "@/components/pages/contaPagRec/pages/CContaPagRecPageBaixaCruzadaEdit";
import mixinPageDetail from "@/mixins/mixinPageDetail";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "ContaPagRec_BaixaCruzada",

  /// COMPONENTS
  components: {
    CContaPagRecPageBaixaCruzadaEdit,
    CContaPagRecView,
  },

  /// MIXINS
  mixins: [mixinPageDetail, mixinPageList],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getFeature = this.$store.getters["paginas/getFeature"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idFeature: getFeature("optionBaixaCruzada").id,
      idPage: getPagina("contaPagRec").id,
      idPageAlt: getPagina("contasPagRec").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("contaRec").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            idPessoaParceiroContaBancaria: null,
            idPessoa: null,
            duplicata: null,
            dataEmissao: null,
            idFormaPagamento: null,
            prazo: null,
            dataVencimento: null,
            valor: null,
            saldo: null,
            observacoes: null,
            dataAtualizacao: null,
          },
        },
      },

      funcsDetail: {
        clickSnackbar: () => {},

        posSubmit: (context) => {
          context.params.context = context;
          this.id = context.dataset.main.detail.id;

          if (context.dataset.main.detail.valor) {
            context.dataset.main.detail.valor = parseFloat(
              context.dataset.main.detail.valor
            ).toFixed(2);
          }

          const contaBancaria = this.getVar("usuarios")[
            this.getVar("usuarios").findIndex(
              (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
            )
          ].contas.find(
            (conta) =>
              conta.id ==
              context.dataset.main.detail.idPessoaParceiroContaBancaria
          );

          context.dataset.aux.detail.contaBancaria = contaBancaria
            ? this.contaBancariaFormat(contaBancaria)
            : null;

          this.saldoBaixar = context.dataset.main.detail.saldo;
          this.loadList = true;
        },
      },

      funcsList: {
        changed: (context) => {
          return context.datasets.some(
            (dataset, i) =>
              dataset.contaAssociada != context.datasetsTemp[i].contaAssociada
          );
        },

        clickCancel: (context) => {
          context.options.click.datasets = JSON.parse(
            JSON.stringify(context.options.click.datasetsTemp)
          );
        },

        clickSave: async (context) => {
          const itensSelecionados = this.getTotais(
            context.options.click
          ).quantidade;
          const totalSelecionado = this.getTotais(
            context.options.click
          ).totalSelecionado;
          const saldoBaixar = this.getTotais(context.options.click).saldo;
          const textoItensSelecionados =
            itensSelecionados > 1
              ? `nos ${itensSelecionados} itens selecionados.`
              : "no item selecionado.";
          const textoTotalSelecionado = `${this.numberFormat(
            totalSelecionado,
            "currency"
          )} serão aplicados`;
          const textoSaldoBaixar = saldoBaixar
            ? `\nMas ainda há ${this.numberFormat(
                saldoBaixar,
                "currency"
              )} de saldo a baixar.`
            : "";
          const acao = saldoBaixar ? "Prosseguir assim mesmo" : "Prosseguir";
          const texto = `${textoTotalSelecionado} ${textoItensSelecionados}${textoSaldoBaixar}\n${acao}?`;
          const color = saldoBaixar ? "error" : "warning";

          context.options.click.setSnackbar(
            true,
            "BAIXA CRUZADA",
            texto,
            color,
            "mdi-check",
            "baixaCruzadaConfirm",
            this.getConst("config").snackbar.timeout,
            null
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "baixaCruzada") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          } else if (action == "baixaCruzadaConfirm") {
            this.gerarValoresPagRec(context);
          }
        },

        clickVoltar: () => {
          this.$router.go(this.getVar("goBack"));
        },

        fnParams: () => {
          return JSON.stringify({
            isBaixa: true,
          });
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
          dataset.valorItem = dataset.saldo;
          dataset.valorBaixar = null;
          dataset.pessoaParceiro_nomeAlt = null;
          dataset.contaAssociada = false;
          dataset.disabled = false;
        },
      },

      params: {
        rules: {},
        context: null,
        clickValorPagRec: null,
      },

      id: null,
      loadList: false,
      saldoBaixar: "0",
    };
  },

  /// COMPUTED
  computed: {
    cIdFeature() {
      const idFeature = new Number(this.idFeature);
      idFeature.pageAlt = true;

      return idFeature;
    },
  },

  /// METHODS
  methods: {
    async gerarValoresPagRec(context) {
      const metadados = {
        idEvento: context.getTipo_item("eventoIncluido").id,
        idPessoaParceiro: context.getVar("idPessoaParceiro"),
        idUsuario: context.getVar("idUsuario"),
        modo: context.getConst("config").modo.cadastro.id,
      };

      const dataset = {
        metadados,
        params: {
          itensSelecionados: context.datasets
            .filter((dataset) => dataset.contaAssociada)
            .filter((dataset, i) =>
              Object.keys(dataset).some(
                (key) => dataset[key] != context.datasetsTemp[i][key]
              )
            ),
        },
      };

      if (dataset.params.itensSelecionados.length) {
        dataset.params.itensSelecionados = dataset.params.itensSelecionados.map(
          (detail) => {
            return {
              id: parseInt(detail.id),
              valor: parseFloat(detail.valorBaixar),
            };
          }
        );

        const method = "put";
        let url = `${context.getConst("app").baseUrlBackend}/${
          context.page.nome
        }/baixaCruzada/${this.id}`;

        const res = await context.submit(method, url, dataset);

        if (res) {
          context.datasets = JSON.parse(JSON.stringify(context.datasetsTemp));

          const valor_id = res.data.id;
          const valor_tipo = context.getTipo_item(res.data.idTipo).nomeAlt;
          const itensSelecionados = context.datasets[0].itensSelecionados;
          const textoItensSelecionados =
            itensSelecionados > 1
              ? `dos ${itensSelecionados} itens selecionados.`
              : "do item selecionado.";
          const textoSelecionado = `Foi criado o valor ${valor_tipo} #${valor_id} para a baixa cruzada`;
          const acao = "Detalhar";
          const texto = `${textoSelecionado} ${textoItensSelecionados}\n${acao}?`;
          const color = "success";

          context.setSnackbar(
            true,
            "BAIXA CRUZADA",
            texto,
            color,
            "mdi-check",
            "baixaCruzada",
            this.getConst("config").snackbar.timeout,
            false
          );

          let interval = setInterval(() => {
            if (context.snackbar.params) {
              clearInterval(interval);
              const url = `/valorPagRec/${res.data.id}`;
              context.$router.push(encodeURI(url));
            } else if (!context.snackbar.show) {
              clearInterval(interval);
              interval = null;
              context.funcs.clickVoltar();
            }
          }, 100);
        }
      }
    },

    getTotais(context) {
      const totalSelecionado = context.datasets
        .filter((dataset) => dataset.contaAssociada)
        .reduce((acc, item) => {
          return (acc += parseFloat(item.valorBaixar));
        }, 0);

      const saldo =
        this.saldoBaixar -
        context.datasets
          .filter((dataset) => dataset.contaAssociada)
          .reduce((acc, item) => {
            return (acc += parseFloat(item.valorBaixar));
          }, 0);

      const quantidade = context.datasets.filter(
        (dataset) => dataset.contaAssociada
      ).length;

      const totais = {
        totalSelecionado,
        saldo,
        quantidade,
      };

      return totais;
    },
  },
};
</script>