<!-- COD: BJB 21/07/2022 CAutocomplete -->
<!-- *VER: JER 10/08/2022 CAutocomplete -->
<!-- /22/ -->

<template>
  <div>
    <v-form :id="`form${label}`" @submit="submitForm($event)"> </v-form>
    <c-help :help="help" :offset-x="offsetX" :offset-y="offsetY">
      <v-autocomplete
        v-bind="$attrs"
        v-model="model"
        chips
        class="text-body-1 mt-3"
        :clearable="clearable"
        color="blue-grey lighten-2"
        filled
        :filter="() => true"
        :form="form"
        :items="items"
        :label="translate(cLabel)"
        :loading="isLoading"
        :multiple="multiple"
        no-data-text="nenhuma opção encontrada"
        placeholder="digite para buscar..."
        :return-object="returnObject"
        :ref="`autocomplete${label}`"
        :rules="
          rules.length
            ? rules
            : commonRules.common(
                'select',
                required,
                null,
                1,
                null,
                null,
                msg,
                null,
                null,
                panelCallback
              )
        "
        :search-input.sync="search"
        @change="$emit('change', $event)"
        @focus="focus($event)"
        @keydown="changeForm(`form${label}`, $event)"
        @keyup="changeForm(`formDetail`, $event)"
      >
        <template #selection="data">
          <v-chip
            v-bind="data.attrs"
            v-if="lengthLimit"
            :class="chipClass"
            class="mt-3 ml-n1 mr-2"
            :close="chipClose"
            :color="data.item.cor"
            dark
            :input-value="data.selected"
            :ref="`chip${label}`"
            small
            @click="clickChip(data)"
            @click:close="remove(data)"
          >
            {{ data.item.textAlt || data.item.text }}
          </v-chip>
          <div v-else class="my-2">
            <span class="text-body-2">
              {{ data.item.textAlt || data.item.text }}
            </span>
            <v-icon class="ml-1" x-small @click="remove(data)"
              >mdi-close</v-icon
            >
          </div>
        </template>
        <template #item="data">
          <v-list-item-content>
            <span class="text-body-2" @click="clickSpan(data)">
              {{ data.item.text }}
            </span>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </c-help>
  </div>
</template>

<script>
/// IMPORT
import axios from "axios";
import { mapGetters } from "vuex";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CAutocomplete",

  /// MIXINS
  mixins: [mixinLib],

  /// MODEL
  model: { prop: "value", event: "input" },

  /// PROPS
  props: {
    arraySearch: { type: Array },
    buscaIncluir: { type: Boolean, default: false },
    chipClose: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    itemsIniciais: { type: Array },
    help: { type: Object },
    label: { type: String, required: true },
    msg: { type: String },
    multiple: { type: Boolean, default: false },
    panel: { type: Object },
    path: { type: String, required: false },
    rejectValues: { type: Array, required: false },
    required: { type: Boolean, default: false },
    route: { type: String, required: false },
    rules: { type: Array, default: new Array() },
    returnObject: { type: Boolean, default: false },
    searchLength: { type: Number, required: false },
    value: {
      validator(value) {
        return (
          Array.isArray(value) ||
          value == null ||
          Number.isInteger(value) ||
          typeof value == "string" ||
          typeof value == "object"
        );
      },
      required: true,
    },
  },

  /// DATA
  data() {
    return {
      form: "formDetail",
      items: this.itemsIniciais,
      itemsIniciaisTemp: JSON.parse(JSON.stringify(this.itemsIniciais)),
      isLoading: false,
      modelValue: this.value,
      search: "",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
    }),

    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },

    chipClass() {
      return this.multiple ? "text-body-2" : "text-body-1";
    },

    cLabel() {
      const label = this.translate(this.label);

      return `${label}${label && this.required ? " *" : ""}`;
    },

    isIos() {
      return (
        (/iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" &&
            navigator.maxTouchPoints > 1)) &&
        !window.MSStream
      );
    },

    itemsIniciaisChanged() {
      let ret = false;

      if (this.itemsIniciais && this.itemsIniciaisTemp) {
        this.itemsIniciais.forEach((item, i) => {
          Object.keys(item).forEach((campo) => {
            if (
              this.itemsIniciaisTemp[i] &&
              item[campo] != this.itemsIniciaisTemp[i][campo]
            ) {
              ret = true;
            }
          });
        });
      }

      return ret;
    },

    lengthLimit() {
      return !this.items.some(
        (item) => (item.textAlt || item.text || "").length > 30
      );
    },

    offsetX() {
      return "8";
    },

    offsetY() {
      return "32";
    },

    panelCallback() {
      return this.panel ? this.panel.callbackRules : null;
    },
  },

  /// WATCH
  watch: {
    itemsIniciais() {
      const value = this.returnObject ? this.model?.value : this.model;

      const items = this.items.filter((item) =>
        (Array.isArray(this.model) ? this.model : [value]).includes(item.value)
      );

      this.items = [...items, ...(this.itemsIniciais || [])];
    },

    itemsIniciaisChanged() {
      if (this.itemsIniciaisChanged) {
        this.items = this.itemsIniciais;
        this.itemsIniciaisTemp = JSON.parse(JSON.stringify(this.itemsIniciais));
      }
    },

    model() {
      this.search = "";
    },

    search() {
      if (
        !this.path ||
        !this.search?.trim() ||
        this.search.length <
          (this.searchLength ||
            this.getConst("config").autocomplete.searchLength)
      ) {
        if (this.items.length) {
          const value = this.returnObject ? this.model?.value : this.model;
          this.items = this.items.filter(
            (item) =>
              this.model &&
              (this.multiple
                ? this.model.includes(item.value)
                : value == item.value)
          );
        }

        if (
          !this.arraySearch &&
          this.search &&
          this.buscaIncluir &&
          this.search.length >=
            (this.searchLength ||
              this.getConst("config").autocomplete.searchLength)
        ) {
          if (this.items.length && !this.multiple && this.items[0].value == 0) {
            this.items[0].text = this.search;
          } else {
            this.items.unshift({
              value: this.search,
              text: this.search,
            });
          }
        } else if (
          !this.path &&
          this.arraySearch &&
          (this.search || this.searchLength == 0) &&
          (this.search || "").length >=
            (this.searchLength ??
              this.getConst("config").autocomplete.searchLength)
        ) {
          this.items.unshift(
            ...this.arraySearch.filter(
              (item) =>
                !this.search ||
                item.value
                  .toString()
                  .normalizeBr()
                  .toUpperCase()
                  .startsWith(this.search.normalizeBr().toUpperCase()) ||
                item.text
                  .toString()
                  .normalizeBr()
                  .toUpperCase()
                  .startsWith(this.search.normalizeBr().toUpperCase())
            )
          );
        }

        return;
      }

      let interval = setInterval(() => {
        if (!this.isLoading && this.search?.trim()) {
          clearInterval(interval);
          interval = null;

          this.isLoading = true;
          const url = `${this.getConst("app").baseUrlBackend}${this.path}${
            this.search
          }`;

          axios
            .get(url)
            .then((res) => {
              if (this.items) {
                if (!this.multiple) {
                  this.items.splice(0, this.items.length);
                }

                this.items.unshift(...res.data);

                this.items = this.items.filter((data) =>
                  this.rejectValues
                    ? !this.rejectValues.includes(data.value)
                    : true
                );
              } else {
                this.items = res.data.filter((data) =>
                  this.rejectValues
                    ? !this.rejectValues.includes(data.value)
                    : true
                );
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }, 100);
    },
  },

  /// METHODS
  methods: {
    changeForm(form, event) {
      if (this.isIos) {
        return;
      }

      let model;

      if (
        event &&
        ((event.type == "keydown" && event.keyCode == 13) || event.keyCode == 9)
      ) {
        event.preventDefault();
        return;
      }

      if (event && event.type == "keyup") {
        if (event.keyCode == 13) {
          model = this.model;
        } else {
          return;
        }
      }

      if (this.form != form) {
        this.form = form;
        if (event?.keyCode == 13) {
          this.$refs[`autocomplete${this.label}`].blur();
          setTimeout(() => {
            this.$refs[`autocomplete${this.label}`].focus();
            if (model) {
              this.model = model;
            }
          }, 1);
        }
      }
    },

    clickChip(data) {
      data.select;
      !this.route || this.$router.push(`/${this.route}/${data.item.value}`);
    },

    clickSpan(data) {
      this.$emit("changeItem", data.item);
      this.changeForm("formDetail");
    },

    focus(event) {
      event.target.value = "";
    },

    remove(data) {
      if (!this.multiple) {
        this.model = null;
      } else {
        const index = data.index;
        if (index >= 0) {
          this.model.splice(index, 1);
        }
      }

      this.$emit("change");
    },

    submitForm(event) {
      event.preventDefault();
      return event.key != "Enter";
    },
  },
};
</script>