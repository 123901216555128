<!-- COD: LYF 01/07/2022 CPublicacoes -->
<!-- *VER: JER 03/07/2022 CPublicacoes -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- imagem -->
      <v-col v-bind="prop.col" v-if="context.isBuscaLayout" cols="2">
        <c-base-image :endereco="dataset.anexo_endereco" height="60" />
      </v-col>
      <v-col v-bind="prop.col">
        <!-- titulo -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-h6">
              {{ dataset.titulo[getVar("locale")] }}
            </span>
          </v-col>
        </v-row>

        <!-- data -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-1">
              {{ dateFormat(dataset.data, "date") }}
            </span>
          </v-col>
        </v-row>

        <!-- data inicio - fim -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="text-body-1">
              {{ dataInicioFim }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPublicacoes",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),

    dataInicioFim() {
      return `${this.dataFormata(
        this.dataset.dataInicio,
        "date",
        false
      )} - ${this.dataFormata(this.dataset.dataFim, "date", false)}`;
    },
  },
};
</script>