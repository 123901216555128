var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-base-detail',{staticClass:"mb-n15",attrs:{"funcs":_vm.funcsDetail,"idFeature":_vm.idFeature,"idPage":_vm.idPageDetail,"idPageAlt":_vm.idPageAlt,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
return [_c('c-legislacao-view',{attrs:{"context":context,"dataset":_vm.dataset}})]}}]),model:{value:(_vm.dataset),callback:function ($$v) {_vm.dataset=$$v},expression:"dataset"}}),(_vm.loadList)?_c('c-base-list',{attrs:{"hasSearch":_vm.pageModeView,"funcs":_vm.funcsList,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"noData":true,"noList":true,"pageModeEdit":true},on:{"showSearchOptions":function($event){_vm.showSearchOptions = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var context = ref.context;
return [_c('v-row',_vm._b({staticClass:"mb-n3"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[(_vm.hasItensAtualizados && _vm.hasItensAtualizados >= 1)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.clickOriginalAtualizado(context)}}},[_vm._v(" "+_vm._s(_vm.isOriginal ? "itens originais" : "itens atualizados")+" ")]):_c('span',{staticClass:"text-body-1"},[_vm._v(" itens originais ")])],1)],1)]}},{key:"searchInSearch",fn:function(ref){
var context = ref.context;
return [_c('v-container',[(!_vm.isOriginal)?_c('v-row',_vm._b({staticClass:"mt-n10"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{staticClass:"ml-n2",attrs:{"label":"só itens vigentes"},on:{"change":function($event){return _vm.clickSearch(context)}},model:{value:(_vm.soItensVigentes),callback:function ($$v) {_vm.soItensVigentes=$$v},expression:"soItensVigentes"}})],1)],1):_vm._e()],1)]}},{key:"listContent",fn:function(ref){
var datasets = ref.datasets;
var context = ref.context;
return [_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":true,"params":_vm.params,"isDisabled":_vm.isDisabled,"value":datasets},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.dataset;
var index = ref.index;
return [_c('v-container',{ref:("item" + index),staticClass:"py-0"},[(item.idImposto)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" "+_vm._s(_vm.getImposto(item.idImposto).nomeAlt)+" ")])]),(item.mva)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" mva "+_vm._s(_vm.numberFormat(item.mva / 100, "percent", 2))+" ")])]):(item.baseCalculo)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" bc "+_vm._s(_vm.numberFormat(item.baseCalculo / 100, "percent", 3))+" ")])]):_vm._e()],1):_vm._e(),_c('v-row',_vm._b({staticClass:"mt-n3"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[(item.item)?_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" "+_vm._s(item.item)+" ")]):_vm._e()]),(item.aliquota)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" aliquota "+_vm._s(_vm.numberFormat(item.aliquota / 100, "percent", 2))+" ")])]):_vm._e()],1),(item.item != item.itemReferencia)?_c('v-row',_vm._b({staticClass:"mt-n3"},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[(item.item)?_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" "+_vm._s(item.itemReferencia)+" ")]):_vm._e()])],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" "+_vm._s(item.isSaida == null ? "entrada/saída" : item.isSaida == false ? "entrada" : "saída")+" ")])]),(item.idReferencia)?_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{class:(((!_vm.isOriginal && _vm.hasDot(item)) ||
                    (_vm.isOriginal && item.isPuxar == null)
                      ? 'text-decoration-line-through'
                      : '') + " text-body-2")},[_vm._v(" "+_vm._s(item.isPuxar == false ? "empurra" : "puxa")+" ")])]):_vm._e()],1)],1)]}},{key:"content",fn:function(ref){
                  var item = ref.dataset;
                  var index = ref.index;
return [(_vm.pageModeView)?_c('c-legislacao-page-item-view',{attrs:{"context":context,"isOriginal":_vm.isOriginal,"item":item}}):_c('c-legislacao-page-item-edit',{attrs:{"context":context,"index":index,"item":item}})]}}],null,true)})]}},{key:"insert",fn:function(ref){
                  var context = ref.context;
return [_c('v-divider',_vm._b({},'v-divider',_vm.prop.divider,false)),(!_vm.pageModeView)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.incluir(context)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-plus-circle")])],1)],1),(
            context.dataset.aux.referencias &&
            context.dataset.aux.referencias.filter(function (ref) { return ref.isReferencia; })
              .length
          )?_c('v-col',_vm._b({attrs:{"align":"center","cols":"10"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.incluir(context)}}},[_vm._v(" incluir todos ")])],1):_vm._e()],1):_vm._e()]}}],null,false,1583131779)}):_vm._e(),_c('c-bottom-sheet',{attrs:{"bottomSheet":_vm.bottomSheet}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }