<!-- COD: FDS 01/07/2022 CFeedbacks -->
<!-- *VER: JER 01/07/2022 CFeedbacks -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pagina -->
    <v-row v-bind="prop.row" v-if="dataset.idPagina">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getPagina(dataset.idPagina).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- titulo -->
    <v-row v-bind="prop.row" v-if="dataset.titulo">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.titulo }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- usuario_nomeAlt -->
      <v-col v-bind="prop.col" v-if="dataset.usuario_nomeAlt" cols="6">
        <span class="text-body-1">
          {{ dataset.usuario_nomeAlt }}
        </span>
      </v-col>
      <!-- data -->
      <v-col v-bind="prop.col" v-if="dataset.data" align="end" cols="6">
        <span class="text-body-1">
          {{ dateFormat(dataset.data, "date timeNoSecond", null, true) }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFeedbacks",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getPagina: "paginas/getPagina",
    }),
  },
};
</script>