// COD: FDS 10/08/2022 mixinUsuarios
// *VER: JER 19/08/2022 mixinUsuarios
// /22/

import axios from "axios"
import { mapGetters, mapActions } from "vuex";

export default {
  /// NAME
  name: "mixinUsuarios",

  /// COMPUTED
  computed: {
    ...mapGetters({
      getCategoria: "categorias/getCategoria",
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getPagina_direito: "paginas/getPagina_direito",
      getPagina_direitos: "paginas/getPagina_direitos",
      getPagina_feature: "paginas/getPagina_feature",
      getPagina_features: "paginas/getPagina_features",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
      getUsuarios: "variaveis/getUsuarios",
      getVar: "variaveis/getVar",
    }),

    isParceiro() {
      return !!this.getVar("usuarios").find(usuario => usuario.pessoa.id == this.getVar("idPessoaParceiro"))?.parceiro?.id;
    },

    idAtuacao() {
      if (this.getVar("idPessoaParceiro")) {
        if (this.getVar("idPessoaParceiro") == this.getConst("app").id) {
          return this.getTipo_item("atuacaoApp").id
        } else if (
          this.isParceiro
        ) {
          return this.getTipo_item("atuacaoParceiro").id
        } else {
          return this.getTipo_item("atuacaoCliente").id
        }
      } else {
        return null;
      }
    },

    perfis() {
      return this.getVar("usuarios").find(
        usuario => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      )?.perfis.map(perfil => perfil.idPerfil);
    }
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar"
    }),

    atuacaoTestar(atuacoesAceitas) {
      return atuacoesAceitas.includes(this.idAtuacao)
    },

    direitosFeatures(idPage, idTipoFeature, isButton = null, detail = null, idEvento = null, idContexto = null, idEstado = null, proprietarios = null) {
      const ret = this.getPagina_features(idPage)
        .map(feature =>
          this.getPagina_feature(feature.value)
        )
        .filter(feature => {
          if (idContexto && feature.options?.contextos) {
            const idsContextos = feature.options.contextos.map(contexto => this.getTipo_item(contexto).id)
            return idsContextos.includes(idContexto)
          } else {
            return true
          }
        })
        .filter(feature => feature.isButton == isButton)
        .map(paginaFeature => {
          const feature = JSON.parse(JSON.stringify(this.getFeature(paginaFeature.idFeature)));
          paginaFeature.options = Object.assign(paginaFeature.options, feature.options);
          delete feature.options;

          return {
            ...paginaFeature,
            ...feature
          }
        })
        .filter(feature => feature.idTipo == idTipoFeature)
        .filter(feature => this.direitosTestar(idPage, null, feature.id, idEvento, idContexto, idEstado, proprietarios))
        .filter(feature => {
          // if (!feature.filter || idEstado == null) {
          //   return true;
          // } else {
          //   return feature.filter.includes(idEstado)
          // }

          if (feature.filter && detail) {
            return !Object.keys(feature.filter)
              .some(key => feature.filter[key]?.length ? !feature.filter[key].includes(detail[key]) : !detail[key])
          } else {
            return true;
          }

        })
        .map(feature => {
          return {
            ...feature,
            value: feature.id,
            text: feature.nomeAlt
          }
        });

      return ret;
    },

    direitosTestar(idPage, idRegistro, idFeature = null, idEvento = null, idContexto = null, idEstado = null, proprietarios = null) {
      const atuacoesAceitas = this.getPagina_direitos(idPage)
        .filter(direito => (!idRegistro || direito.idRegistro == idRegistro) &&
          (!idFeature || direito.idFeature == idFeature) &&
          (idEvento == null || direito.idEvento == idEvento) &&
          (idContexto == null || !direito.idContexto || direito.idContexto == idContexto) &&
          (idEstado == null || !direito.idEstado || direito.idEstado == idEstado) &&
          (proprietarios == null ||
            ((!direito.isProprietario || proprietarios.map(proprietario => proprietario.idProprietario).includes(this.getVar("idUsuario"))) &&
              (!direito.isParceiro || proprietarios.map(proprietario => proprietario.idPessoaParceiro).includes(this.getVar("idPessoaParceiro")))))
        );

      if (!atuacoesAceitas.length) {
        return true
      }

      const perfisAceitos = atuacoesAceitas
        .filter(direito =>
          direito.idAtuacao == this.idAtuacao
        )
        .map(direito => direito.idPerfil)

      if (!perfisAceitos.length) {
        return false
      }

      if (perfisAceitos.includes(null)) {
        return true
      }

      return this.perfisTestar(perfisAceitos)
    },

    loadOptionsNavBar() {
      const optionsNavBar = []

      const idPessoaParceiroInicial = this.getVar("idPessoaParceiro");

      for (let usuario of this.getUsuarios()) {
        this.setVar({ idPessoaParceiro: usuario.value });
        const idPessoaParceiro = this.getVar("idPessoaParceiro");

        const option = {
          idPessoaParceiro,
        }

        const params = '{ "groupBy": 1, "order": 1 }';

        option.options = this.direitosFeatures(
          this.getPagina("home").id,
          this.getTipo_item("featureOption").id,
          false
        )
          .map((feature) => {
            return {
              id: feature.idFeature,
              idContexto: feature.idContexto,
              group: feature.groupBy,
              order: feature.order,
              text: feature.nomeAlt,
              options: feature.options,
              pendencias: this.getVar("pendencias")[feature.nome] || {
                priority: null,
                value: null,
              },
            };
          })
          .filter(
            (feature) =>
              feature.id != this.getFeature("optionPageAgendas").id ||
              (idPessoaParceiro &&
                this.getVar("usuarios")
                  .find((usuario) => usuario.pessoa.id == idPessoaParceiro)
                  .parceiro.categorias?.some(
                    (idCategoria) =>
                      !!idCategoria || this.getCategoria(idCategoria).allowParceiroUsarAgenda //? temporario para testar agenda.
                  ))
          )
          .sortKeys(params);

        option.optionsModulos = [
          ...new Set(
            option.options
              .filter((option) => !!option.idContexto)
              .map((option) => option.idContexto)
          ),
        ];

        optionsNavBar.push(option);
      }

      this.setVar({ idPessoaParceiro: idPessoaParceiroInicial });
      this.setVar({ optionsNavBar });
    },

    perfisTestar(perfisAceitos) {
      return this.perfis.some(perfil => perfisAceitos.includes(perfil))
    },

    async usuariosAtualizar(idPessoaParceiro) {
      let url = `${this.getConst("app").baseUrlBackend
        }/usuarios/${this.getVar("idUsuario")}`;

      if (idPessoaParceiro) {
        url += `/${idPessoaParceiro}`
      }

      await axios.get(url).then(res => {
        const usuarios = res.data
        this.setVar({ usuarios: usuarios });
        this.setVar({ "atualizacao.usuarios": new Date() });
      });

    },
  }
}