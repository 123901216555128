<!-- COD: LYF 01/07/2022 Parceiros -->
<!-- *VER: JER 04/07/2022 Parceiros -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ context, dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-parceiros :context="context" :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CParceiros from "@/components/pages/parceiro/CParceiros";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Parceiros",

  /// COMPONENTS
  components: {
    CParceiros,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("parceiros").id,
      idPageAlt: getPagina("parceiro").id,

      funcs: {
        fnParams: (context) => {
          const contextParams = context.params
            ? JSON.parse(context.params)
            : null;
          const proprietarios = contextParams?.proprietarios ?? true;

          return JSON.stringify({
            proprietarios,
          });
        },

        posSubmit: (dataset) => {
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },
    };
  },
};
</script>