<!-- COD: FDS 06/07/2022 CValorPagRecEdit -->
<!-- *VER: JER 05/08/2022 CValorPagRecEdit -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pessoa -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPessoa"
          :itemsIniciais="dataset.aux.autocomplete.pessoas"
          label="pessoa"
          :path="`/pessoas/autocomplete/pessoas/0/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- forma pagamento -->
      <v-col v-bind="prop.col" cols="6">
        <c-select
          v-model="dataset.main.detail.idFormaPagamento"
          clearable
          :items="tiposFormaPagamento"
          label="forma pagamento"
        />
      </v-col>
      <!-- numero documento -->
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
          dataset.main.detail.idFormaPagamento
        "
        cols="4"
      >
        <c-text-field
          v-model="dataset.main.detail.numeroDocumento"
          label="número"
          :max="8"
          required
        />
      </v-col>
      <!-- motivo devolucao -->
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
          dataset.main.detail.idFormaPagamento
        "
        cols="2"
      >
        <c-text-field
          v-model="dataset.main.detail.motivoDevolucao"
          label="motivo"
          :max="2"
        />
      </v-col>
    </v-row>

    <!-- banco/agencia/conta -->
    <v-row
      v-bind="prop.row"
      v-if="
        (getTipo_item('formaPagamentoCheque').id ==
          dataset.main.detail.idFormaPagamento &&
          getTipo_item('valorPag').id == dataset.main.detail.idTipo) ||
        getTipo_item('formaPagamentoCreditoConta').id ==
          dataset.main.detail.idFormaPagamento
      "
    >
      <v-col v-bind="prop.col" cols="12" sm="7" md="6">
        <c-select
          v-model="dataset.main.detail.idPessoaParceiroContaBancaria"
          clearable
          :items="contasBancarias"
          label="banco/agência/conta"
          :properties="{
            image: true,
          }"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- banco -->
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
            dataset.main.detail.idFormaPagamento &&
          getTipo_item('valorRec').id == dataset.main.detail.idTipo
        "
        cols="4"
      >
        <c-text-field
          v-model="dataset.main.detail.banco"
          label="banco"
          :max="4"
          :options="{
            mask: 'X###?',
          }"
          required
        />
      </v-col>
      <!-- agencia -->
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
            dataset.main.detail.idFormaPagamento &&
          getTipo_item('valorRec').id == dataset.main.detail.idTipo
        "
        cols="4"
      >
        <c-text-field
          v-model="dataset.main.detail.agencia"
          label="agência"
          :max="5"
          :options="{
            mask: '#####?',
          }"
          required
        />
      </v-col>
      <!-- conta -->
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
            dataset.main.detail.idFormaPagamento &&
          getTipo_item('valorRec').id == dataset.main.detail.idTipo
        "
        cols="4"
      >
        <c-text-field
          v-model="dataset.main.detail.conta"
          label="conta"
          :max="15"
          :options="{
            mask: '#####?#?#?#?#?#?#?#?#?#?#?',
          }"
          required
        />
      </v-col>
    </v-row>

    <!-- emitente nome -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
            dataset.main.detail.idFormaPagamento &&
          getTipo_item('valorRec').id == dataset.main.detail.idTipo
        "
      >
        <c-text-field
          v-model="dataset.main.detail.emitenteNome"
          label="emitente"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- emitente documento -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
            dataset.main.detail.idFormaPagamento &&
          getTipo_item('valorRec').id == dataset.main.detail.idTipo
        "
        cols="6"
      >
        <c-text-field
          v-model="dataset.main.detail.emitenteDocumento"
          label="documento emitente"
          :max="25"
          required
        />
      </v-col>
    </v-row>

    <!-- nominal -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
          dataset.main.detail.idFormaPagamento
        "
      >
        <c-text-field
          v-model="dataset.main.detail.nominal"
          label="nominal a"
          :max="40"
        />
      </v-col>
    </v-row>

    <!-- local emissao -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="
          getTipo_item('formaPagamentoCheque').id ==
          dataset.main.detail.idFormaPagamento
        "
      >
        <c-text-field
          v-model="dataset.main.detail.localEmissao"
          label="local emissão"
          :max="35"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data emissao -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataEmissao"
          date
          label="data emissão"
          required
        />
      </v-col>
      <!-- data apropriacao -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataApropriacao"
          date
          label="data apropriação"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.valor"
          label="valor"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
      <!-- saldo associar -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.saldoAssociar"
          label="saldo associar"
          :max="13"
          :options="{
            length: 11,
            precision: 2,
            style: 'currency',
          }"
        />
      </v-col>
    </v-row>

    <!-- identificador -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.identificador"
          label="identificador"
          :max="25"
        />
      </v-col>
    </v-row>

    <!-- historico -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.historico"
          label="histórico"
          :max="25"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CValorPagRecEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getBanco: "bancos/getBanco",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
    }),

    contasBancarias() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ].contas.map((conta) => {
        return {
          value: conta.id,
          text: this.contaBancariaFormat(conta),
          image: this.getBanco(conta.idBanco).endereco,
        };
      });
    },

    tiposFormaPagamento() {
      return this.getTipo_itens(this.getTipo("formaPagamento").id).filter(
        (tipoFormaPagamento) =>
          tipoFormaPagamento.value !=
            this.getTipo_item("formaPagamentoCreditoConta").id ||
          !this.dataset.aux?.ultimoExtrato?.requireConciliacao
      );
    },
  },
};
</script>