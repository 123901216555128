<!-- COD: FDS 20/07/2022 CProdutoView -->
<!-- *VER: JER 28/07/2022 CProdutoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- grupo -->
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.aux.autocomplete.grupos &&
        dataset.aux.autocomplete.grupos.length &&
        dataset.aux.autocomplete.grupos[0].value
      "
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ dataset.aux.autocomplete.grupos[0].text }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="font-weight-medium text-body-1">
          {{ produtoFormat(dataset.main.detail, true) }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row" v-if="dataset.main.detail.idUnidadeMedida">
      <v-col v-bind="prop.col" align="center">
        <c-base-preco
          align="center"
          :color="context.getPrecoColor(dataset.main.detail)"
          :decimalValor="dataset.main.detail.decimalValor"
          :produto="dataset.main.detail"
          :idUnidadeMedida="dataset.main.detail.idUnidadeMedida"
          :preco="dataset.main.detail.preco"
          @clickComprar.stop="context.clickComprar(dataset.main.detail)"
        />
      </v-col>
    </v-row>

    <!-- fabricante -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idFabricante">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">fabricante</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoaFabricante }}
        </span>
      </v-col>
    </v-row>

    <!-- codigo barra -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.codigoBarra">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">código barras</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.codigoBarra }}
        </span>
      </v-col>
    </v-row>

    <!-- origem -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idOrigem">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">origem</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <c-help
          estilo="width: fit-content"
          :help="{
            title: 'ORIGEM',
            text: `0 - Nacional, exceto códigos 3, 4, 5 e 8
1 - Estrangeira - importação direta, exceto código 6
2 - Estrangeira - adquirida no mercado interno, exceto código 7
3 - Nacional - com conteúdo de importação > 40% e <= 70%
4 - Nacional - produção em  conformidade com os processos produtivos básicos 
5 - Nacional - com conteúdo de importação <= 40% 
6 - Estrangeira - importação direta, sem similar nacional, constante em lista CAMEX
7 - Estrangeira - adquirida no mercado interno, sem similar nacional, constante em lista CAMEX
8 - Nacional - com conteúdo de importação > 70%`,
            function: context.setUiBottomSheet,
            alignText: 'text-left',
          }"
        >
          <span class="text-body-1">
            {{ getTipo_item(dataset.main.detail.idOrigem).codigo }}
          </span>
        </c-help>
      </v-col>
    </v-row>

    <!-- codigo fiscal -->
    <v-row
      v-bind="prop.row"
      v-if="labelCodigoFiscal && dataset.main.detail.codigoFiscal"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">{{ labelCodigoFiscal }}</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.codigoFiscal }}
        </span>
      </v-col>
    </v-row>

    <!-- codigo fiscal 2-->
    <v-row
      v-bind="prop.row"
      v-if="labelCodigoFiscal2 && dataset.main.detail.codigoFiscal2"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">{{ labelCodigoFiscal2 }}</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.codigoFiscal2 }}
        </span>
      </v-col>
    </v-row>

    <!-- prazo garantia -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.prazoGarantia">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">garantia</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.prazoGarantia }}
        </span>
        <span class="ml-0 text-body-2">meses</span>
      </v-col>
    </v-row>

    <!-- variacao quantidade -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.quantidadeMinima">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">variação quantidade</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.quantidadeMinima, "decimal", 3) }}
        </span>
        <span class="ml-1 text-body-2">a</span>
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.quantidadeMaxima, "decimal", 3) }}
        </span>
      </v-col>
    </v-row>

    <!-- variacao preco -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">variação preço</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{
            numberFormat(
              (dataset.main.detail.variacaoPreco || 0) / 100,
              "percent"
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- externo -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.idExterno">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">id externo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.aux.detail.idExterno }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";
import CBasePreco from "@/components/bases/CBasePreco";

export default {
  /// NAME
  name: "CProdutoView",

  /// COMPONENTS
  components: {
    CBasePreco,
  },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    dadosParceiro() {
      return this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ];
    },

    labelCodigoFiscal() {
      return this.getLocalidade(this.dadosParceiro.enderecos[0].idPais).idPai ==
        this.getLocalidade("América do Sul").id &&
        [
          this.getTipo_item("produtoProduto").id,
          this.getTipo_item("produtoComplemento").id,
          this.getTipo_item("produtoGrupo").id,
          this.getTipo_item("produtoCombo").id,
        ].includes(this.dataset.main.detail.idTipo)
        ? "NCM"
        : this.dadosParceiro.enderecos[0].idPais ==
            this.getLocalidade("Brasil").id &&
          this.getTipo_item("produtoServico").id ==
            this.dataset.main.detail.idTipo
        ? "código prestação"
        : null;
    },

    labelCodigoFiscal2() {
      return this.getLocalidade(this.dadosParceiro.enderecos[0].idPais).idPai ==
        this.getLocalidade("América do Sul").id &&
        [
          this.getTipo_item("produtoProduto").id,
          this.getTipo_item("produtoComplemento").id,
          this.getTipo_item("produtoGrupo").id,
          this.getTipo_item("produtoCombo").id,
        ].includes(this.dataset.main.detail.idTipo)
        ? "CEST"
        : this.dadosParceiro.enderecos[0].idPais ==
            this.getLocalidade("Brasil").id &&
          this.getTipo_item("produtoServico").id ==
            this.dataset.main.detail.idTipo
        ? "código tomação"
        : null;
    },
  },
};
</script>