// COD: BJB 15/08/2022 categorias
// *VER: JER 19/08/2022 categorias
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    categorias: []
  },

  /// GETTERS
  getters: {
    getCategoriasLength: state => () => {
      return state.categorias.length
    },

    getCategorias: state => (id, prop = "order") => {
      return state.categorias
        .filter(element => element.idPai == id)
        .map(element => {
          return {
            id: element.id,
            nome: element.nome,
            nomeAlt: element.nomeAlt,
            level: element.level,
            isExclusivaNoParceiro: element.isExclusivaNoParceiro,
            isExclusivaNoProduto: element.isExclusivaNoProduto,
            isExclusivaNoCarrinho: element.isExclusivaNoCarrinho,
            allowReservaHorario: element.allowReservaHorario,
            isRealizaEntrega: element.isRealizaEntrega,
            requirePresencaClienteNaEntrega: element.requirePresencaClienteNaEntrega,
            idPai: element.idPai,
            isDefault: element.isDefault,
            options: element.options,
          };
        })
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
    },

    getCategoria: state => id => {
      return state.categorias.length
        ? state.categorias
          .find(element => element.id == id)
        : { id }
    }
  },

  /// MUTATIONS
  mutations: {
    setCategorias: (state, payload) => {
      state.categorias = []
      payload.forEach(element => {
        state.categorias.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setCategorias: (context, payload) => {
      context.commit("setCategorias", payload)
    }
  }
}