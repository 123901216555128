<!-- COD: FDS 30/06/2022 CCartoes -->
<!-- *VER: JER 01/07/2022 CCartoes -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- logo -->
      <v-col v-bind="prop.col" v-if="context.isBuscaLayout" cols="1">
        <v-img :src="dataset.anexo_endereco" width="30" />
      </v-col>
      <!-- nome -->
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <!-- pais -->
      <v-col v-bind="prop.col" v-if="order.text != 'localidade'" cols="1">
        <v-img
          v-if="dataset.localidade"
          alt="imagem"
          class="ml-1"
          contain
          :src="getLocalidade(dataset.localidade).endereco"
          width="25"
        />
        <v-icon v-else>mdi-earth</v-icon>
      </v-col>
      <v-col v-bind="prop.col" cols="11">
        <span class="text-body-1">
          {{ dataset.nomeAlt }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CCartoes",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    order: { type: Object },
  },
};
</script>