<!-- COD: BJB 05/07/2022 CParceiroEdit -->
<!-- VER: JER __/08/2022 CParceiroEdit -->

<template>
  <v-container v-bind="prop.container">
    <!-- pessoa nome -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoa_nome">
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="primary--text" text @click="clickPessoa()">
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- pessoa nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoa_nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoa_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- capacidade -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.capacidade"
          :aceitaNegativo="false"
          :help="{
            title: 'CAPACIDADE',
            text: `É a capacidade de atendimento do parceiro. Quantas pessoas o parceiro consegue atender simultaneamente.`,
            function: context.setUiBottomSheet,
          }"
          label="capacidade"
          :max="5"
          :options="{
            length: 4,
            precision: 0,
            style: 'decimal',
          }"
          type="tel"
        />
      </v-col>
      <!-- reserva duracao -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          v-model="dataset.main.detail.reservaDuracao"
          :aceitaNegativo="false"
          :help="{
            title: 'DURAÇÃO DA RESERVA',
            text: `Tempo de duração da reserva que o parceiro faz para seus clientes (horas).`,
            function: context.setUiBottomSheet,
          }"
          label="duração reserva"
          :max="3"
          :options="{
            length: 2,
            precision: 1,
            style: 'decimal',
            suffix: 'h',
          }"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- faturamento mensal -->
      <v-col v-bind="prop.col" cols="6">
        <c-select
          v-model="dataset.main.detail.idFaturamentoMensal"
          :help="{
            title: 'FATURAMENTO MENSAL',
            text: `Faixa de faturamento mensal que o parceiro realiza.`,
            function: context.setUiBottomSheet,
          }"
          :items="tiposFaturamentos"
          label="faturamento mensal"
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- percentual online -->
      <v-col v-bind="prop.col" cols="6">
        <c-text-money
          :aceitaNegativo="false"
          v-model="dataset.main.detail.percentualOnline"
          :help="{
            title: 'PERCENTUAL ONLINE',
            text: `Percentual cobrado do parceiro sobre o valor do pedido, referente ao pagamento online.`,
            function: context.setUiBottomSheet,
          }"
          label="percentual online"
          :max="6"
          :options="{
            length: 5,
            precision: 2,
            style: 'percent',
            suffix: '%',
          }"
        />
      </v-col>
    </v-row>

    <!-- limite conciliacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" cols="6">
        <c-select
          v-model="dataset.main.detail.limiteConciliacao"
          :help="{
            title: 'LIMITE CONCILIAÇÃO',
            text: `Se o extrato conciliado mais recente exceder este prazo, sistema não permite a conciliação de lançamento do extrato intraday com valores pag/rec, forçando, com isso, que os extratos passados sejam conciliados.`,
            function: context.setUiBottomSheet,
          }"
          :items="limitesConciliacao"
          label="limite conciliação"
        />
      </v-col>
    </v-row>

    <!-- label acerto contas -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-2">acerto contas</span>
      </v-col>
    </v-row>

    <!-- dias semana atendimento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-help
          :help="{
            title: 'ACERTO DE CONTAS',
            text: `Dia da semana que será feito o acerto de contas entre app e parceiro.`,
            function: context.setUiBottomSheet,
          }"
        >
          <v-chip-group
            v-model="dataset.main.detail.diaSemanaAcertoContas"
            active-class="primary"
          >
            <v-chip
              v-for="diaSemana in diasSemanaAtendimento"
              :key="diaSemana.value"
              :value="diaSemana.value"
            >
              {{ diaSemana.text }}
            </v-chip>
          </v-chip-group>
        </c-help>
      </v-col>
    </v-row>

    <!-- fechado -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.isFechado"
          label="fechado temporariamente?"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CParceiroEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// DATA
  data() {
    return {
      diasSemanaAtendimento: [
        { value: 0, text: "D" },
        { value: 1, text: "S" },
        { value: 2, text: "T" },
        { value: 3, text: "Q" },
        { value: 4, text: "Q" },
        { value: 5, text: "S" },
        { value: 6, text: "S" },
      ],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo: "tipos/getTipo",
      getTipo_itens: "tipos/getTipo_itens",
    }),

    limitesConciliacao() {
      return Array.from({ length: 15 }, (_, i) => i + 1).map((limite) => {
        return {
          value: limite,
          text: `${limite} ${limite > 1 ? "dias" : "dia"}`,
        };
      });
    },

    tiposFaturamentos() {
      const moeda = this.getMoeda();

      return this.getTipo_itens(this.getTipo("faturamentoMensal").id).map(
        (item) => {
          return {
            value: item.value,
            text: `${moeda?.options?.simbolo} ${item.text}`,
          };
        }
      );
    },
  },
};
</script>