<!-- COD: LYF 01/07/2022 CNotasFiscais -->
<!-- *VER: JER 05/07/2022 CNotasFiscais -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- operacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ dataset.operacao }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" cols="5">
        <span class="font-weight-medium text-body-1">
          {{ dateFormat(dataset.data, "date timeNoSecond") }}
        </span>
      </v-col>
      <!-- nota fiscal e serie -->
      <v-col v-bind="prop.col" align="end" cols="7">
        <span class="font-weight-medium text-body-2">NF</span>
        <span class="font-weight-medium ml-1 text-body-1">
          {{ dataset.notaFiscal }}
        </span>
        <span v-if="dataset.serie" class="font-weight-medium ml-1 text-body-2">
          série
        </span>
        <span v-if="dataset.serie" class="font-weight-medium ml-1 text-body-1">
          {{ dataset.serie }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- itens -->
      <v-col v-bind="prop.col" cols="5">
        <span class="text-body-1">
          {{ itensFormatado }}
        </span>
      </v-col>
      <!-- total nota -->
      <v-col v-bind="prop.col" align="end" cols="7">
        <span class="text-body-1">
          {{ numberFormat(dataset.totalNota, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoa_nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CNotasFiscais",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    itensFormatado() {
      return `${this.dataset.itens} ${
        this.dataset.itens > 1 ? " itens" : " item"
      }`;
    },
  },
};
</script>