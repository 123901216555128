<!-- COD: FDS 10/08/2022 Convidados -->
<!-- *VER: JER 10/08/2022 Convidados -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item
      :dataset="dataset"
      :identification="false"
      :idPage="idPage"
      :order="order"
      :title="false"
    >
      <template>
        <!-- dataset.nomeAlt -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <span class="font-weight-medium mb-n3 text-body-1">
              {{ dataset.nomeAlt }}
            </span>
          </v-col>
        </v-row>

        <!-- dataset.data -->
        <v-row v-bind="prop.row" class="mt-n6">
          <v-col v-bind="prop.col">
            <span class="text-caption">
              {{ dataFormata(dataset.data, "date time", true) }}
            </span>
          </v-col>
        </v-row>
      </template>
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Convites",

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("convidados").id,
      idPageAlt: getPagina("convidados").id,

      funcs: {
        fnParams: () => {
          return JSON.stringify({
            idConvite: this.$route.params.idConvite,
          });
        },

        preLoad: (context) => {
          context.idFilter = this.getFeature("filterFiltrar").id;
        },

        posLoad: (context) => {
          if (
            context
              .direitosFeatures(
                this.getPagina("home").id,
                this.getTipo_item("featureOption").id,
                false
              )
              .find(
                (feature) =>
                  feature.idFeature == this.getFeature("optionPagePessoas").id
              )
          ) {
            this.idPageAlt = this.getPagina("pessoa").id;
          }
        },
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeature: "paginas/getFeature",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
    }),
  },
};
</script>