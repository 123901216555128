<!-- COD: LYF 02/08/2022 CPedidoDialogPedido -->
<!-- *VER: JER 08/08/2022 CPedidoDialogPedido -->
<!-- /32/ -->

<template>
  <v-dialog v-model="value" max-width="380px" persistent scrollable>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="print()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text ref="printArea">
        <v-row v-bind="prop.row">
          <!-- logo -->
          <v-col v-bind="prop.col" cols="3">
            <img
              v-if="pedido.aux.detail.logoParceiro"
              :src="pedido.aux.detail.logoParceiro"
              width="100"
            />
          </v-col>
          <!-- titulo -->
          <v-col v-bind="prop.col" align="center" cols="6">
            <span class="text-body-1">
              {{ printing ? title : " " }}
            </span>
          </v-col>
          <!-- id -->
          <v-col v-bind="prop.col" align="end" cols="3">
            <span class="text-body-1">
              #
              {{ pedido.main.detail.id }}
            </span>
          </v-col>
        </v-row>

        <!-- operacao -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <v-chip v-bind="prop.chip" class="mt-n4">
              {{ getOperacao(pedido.main.detail.idOperacao).nomeAlt }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- data -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ dateFormat(pedido.main.detail.data, "date", null, true) }}
            </span>
          </v-col>
        </v-row>

        <!-- valor total -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ numberFormat(pedido.main.detail.valorTotal, "currency") }}
            </span>
          </v-col>
        </v-row>

        <!-- nota fiscal -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ notaFiscal }}
            </span>
          </v-col>
        </v-row>

        <!-- parceiro nome -->
        <v-row v-bind="prop.row" class="mt-3 mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="font-weight-medium text-body-2">
              {{ pedido.aux.detail.pessoaParceiro.nome.toUpperCase() }}
            </span>
          </v-col>
        </v-row>

        <!-- parceiro endereco -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaParceiroEndereco.endereco1 }}
            </span>
          </v-col>
        </v-row>

        <!-- parceiro endereco 2 -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaParceiroEndereco.endereco2 }}
            </span>
          </v-col>
        </v-row>

        <!-- parceiro documento -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaDocumentosFormat(pedido.aux.detail.pessoaParceiro) }}
            </span>
          </v-col>
        </v-row>

        <!-- parceiro telefone -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaTelefonesFormat(pedido.aux.detail.pessoaParceiro) }}
            </span>
          </v-col>
        </v-row>

        <!-- parceiro url -->
        <v-row v-bind="prop.row" class="mb-4">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pedido.aux.detail.pessoaParceiro.url.endereco }}
            </span>
          </v-col>
        </v-row>

        <!-- cliente -->
        <div class="grey lighten-2">
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2">cliente</span>
            </v-col>
          </v-row>
        </div>

        <!-- cliente nome -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="font-weight-medium text-body-2">
              {{ pedido.aux.detail.pessoa.nome.toUpperCase() }}
            </span>
          </v-col>
        </v-row>

        <!-- cliente endereco 1 -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaEndereco.endereco1 }}
            </span>
          </v-col>
        </v-row>

        <!-- cliente endereco 2 -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaEndereco.endereco2 }}
            </span>
          </v-col>
        </v-row>

        <!-- cliente documento -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaDocumentosFormat(pedido.aux.detail.pessoa) }}
            </span>
          </v-col>
        </v-row>

        <!-- cliente telefone -->
        <v-row v-bind="prop.row" class="mb-n2">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pessoaTelefonesFormat(pedido.aux.detail.pessoa) }}
            </span>
          </v-col>
        </v-row>

        <!-- cliente url -->
        <v-row v-bind="prop.row" class="mb-3">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pedido.aux.detail.pessoa.url.endereco }}
            </span>
          </v-col>
        </v-row>

        <!-- itens -->
        <div class="grey lighten-2">
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2">itens</span>
            </v-col>
          </v-row>
        </div>

        <div
          v-for="(item, i) in pedido.main.itens.filter((item) => item.id)"
          class="mb-3"
          :key="i"
        >
          <!-- produto -->
          <v-row v-bind="prop.row" v-if="pedido.aux.itens[i]" class="mb-n2">
            <v-col v-bind="prop.col">
              <span class="text-body-2">
                {{
                  `${i + 1}. ${produtoFormat(
                    pedido.aux.itens[i],
                    false,
                    true,
                    true,
                    true
                  )}`
                }}
              </span>
            </v-col>
          </v-row>

          <v-row v-bind="prop.row" class="mb-n2">
            <!-- quantidade -->
            <v-col v-bind="prop.col" cols="4">
              <span class="text-caption">
                {{
                  `${item.quantidade} ${
                    getTipo_item(pedido.aux.itens[i].idUnidadeMedida).options
                      .abreviado
                  }`
                }}
              </span>
            </v-col>
            <!-- preco -->
            <v-col v-bind="prop.col" cols="4">
              <span class="text-caption">
                {{ numberFormat(item.preco, "currency") }}
              </span>
            </v-col>
            <!-- valor item -->
            <v-col v-bind="prop.col" cols="4">
              <span class="text-caption">
                {{ numberFormat(pedido.aux.itens[i].valorItem, "currency") }}
              </span>
            </v-col>
          </v-row>

          <!-- observacao -->
          <v-row v-bind="prop.row" v-if="item.observacao" class="mb-2">
            <v-col v-bind="prop.col">
              <span class="text-caption">({{ item.observacao }})</span>
            </v-col>
          </v-row>
        </div>

        <!-- parcelas -->
        <div class="grey lighten-2">
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2">parcelas</span>
            </v-col>
          </v-row>
        </div>

        <div
          v-for="(contaPagRec, i) in pedido.aux.contasPagRec"
          class="mb-3"
          :key="`contaPagRec${i}`"
        >
          <v-row v-bind="prop.row" class="mb-n2">
            <!-- forma pagamento -->
            <v-col v-bind="prop.col" cols="6">
              <span class="text-caption">
                {{
                  `${i + 1}. ${
                    contaPagRec.idFormaPagamento
                      ? getTipo_item(contaPagRec.idFormaPagamento).nomeAlt
                      : ""
                  }`
                }}
              </span>
            </v-col>
            <!-- prazo -->
            <v-col v-bind="prop.col" align="end" cols="1">
              <span class="text-caption">
                {{ `${contaPagRec.prazo ? `${contaPagRec.prazo}d` : " "}` }}
              </span>
            </v-col>
            <!-- data vencimento -->
            <v-col v-bind="prop.col" align="end" cols="5">
              <span class="text-caption">
                {{
                  `${
                    contaPagRec.dataVencimento
                      ? dateFormat(
                          contaPagRec.dataVencimento,
                          "date",
                          null,
                          true
                        )
                      : " "
                  }`
                }}
              </span>
            </v-col>
          </v-row>

          <!-- valor -->
          <v-row v-bind="prop.row" class="mb-2">
            <v-col v-bind="prop.col" align="end">
              <span class="text-caption">
                {{ numberFormat(contaPagRec.valor, "currency") }}
              </span>
            </v-col>
          </v-row>
        </div>

        <!-- transporte -->
        <div class="grey lighten-2">
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2">transporte</span>
            </v-col>
          </v-row>
        </div>

        <div class="mb-3">
          <!-- previsao entrega -->
          <v-row
            v-bind="prop.row"
            v-if="pedido.main.detail.dataPrevisaoEntrega"
          >
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">previsão entrega</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{
                  dateFormat(
                    pedido.main.detail.dataPrevisaoEntrega,
                    "date",
                    null,
                    true
                  )
                }}
              </span>
            </v-col>
          </v-row>

          <!-- transportadora -->
          <v-row
            v-bind="prop.row"
            v-if="
              pedido.aux.detail.transportadora_nome &&
              pedido.main.detail.isEntregar
            "
          >
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">transportadora</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{
                  pedido.main.detail.idPessoaTransportadora ==
                  pedido.main.detail.idPessoaParceiro
                    ? "O mesmo"
                    : pedido.aux.detail.transportadora_nome
                }}
              </span>
            </v-col>
          </v-row>

          <!-- volumes -->
          <v-row v-bind="prop.row" v-if="pedido.main.detail.volumes">
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">volumes</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{ pedido.main.detail.volumes }}
              </span>
            </v-col>
          </v-row>

          <!-- peso bruto -->
          <v-row v-bind="prop.row" v-if="pedido.main.detail.totalPesoBruto">
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">peso bruto</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{ pedido.main.detail.totalPesoBruto }} Kg
              </span>
            </v-col>
          </v-row>

          <!-- peso liquido -->
          <v-row v-bind="prop.row" v-if="pedido.main.detail.totalPesoLiquido">
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">peso líquido</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{ pedido.main.detail.totalPesoLiquido }} Kg
              </span>
            </v-col>
          </v-row>

          <!-- peso consolidado -->
          <v-row
            v-bind="prop.row"
            v-if="pedido.main.detail.totalPesoConsolidado"
          >
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">peso consolidado</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{ pedido.main.detail.totalPesoConsolidado }} Kg
              </span>
            </v-col>
          </v-row>

          <!-- portador -->
          <v-row v-bind="prop.row" v-if="pedido.main.detail.portadorNome">
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">portador</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{ pedido.main.detail.portadorNome }}
              </span>
            </v-col>
          </v-row>

          <!-- documento -->
          <v-row v-bind="prop.row" v-if="pedido.main.detail.portadorDocumento">
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">documento</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{ pedido.main.detail.portadorDocumento }}
              </span>
            </v-col>
          </v-row>

          <!-- placa veiculo -->
          <v-row
            v-bind="prop.row"
            v-if="pedido.main.detail.portadorPlacaVeiculo"
          >
            <v-col v-bind="prop.col" align="end" cols="4">
              <span class="text-caption">placa veículo</span>
            </v-col>
            <v-col v-bind="prop.col" cols="8">
              <span class="text-caption">
                {{ pedido.main.detail.portadorPlacaVeiculo }}
              </span>
            </v-col>
          </v-row>
        </div>

        <!-- observacoes -->
        <div v-if="pedido.main.detail.observacoes" class="grey lighten-2">
          <v-row v-bind="prop.row">
            <v-col v-bind="prop.col" align="center">
              <span class="text-body-2">observacoes</span>
            </v-col>
          </v-row>
        </div>

        <!-- observacoes -->
        <v-row v-bind="prop.row" v-if="pedido.main.detail.observacoes">
          <v-col v-bind="prop.col" align="center">
            <span class="text-body-2">
              {{ pedido.main.detail.observacoes }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/// IMPORT
import { format } from "date-fns";
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CPedidoDialogPedido",

  /// MIXINS
  mixins: [mixinData, mixinLib],

  /// PROPS
  props: {
    pedido: Object,
    value: Boolean,
  },

  /// DATA
  data() {
    return {
      printing: false,
      title: "PEDIDO",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade_configuracoesRegionais:
        "localidades/getLocalidade_configuracoesRegionais",
      getOperacao: "operacoes/getOperacao",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    notaFiscal() {
      return this.pedido.main.notasFiscais.id
        ? `invoice ${this.pedido.main.notasFiscais.id}, ${this.dateFormat(
            this.pedido.main.notasFiscais.data,
            "date",
            null,
            true
          )}`
        : "";
    },

    pessoaEndereco() {
      return this.pessoasEnderecoFormat(
        this.pedido.aux.pessoaEnderecos.find((endereco) => endereco.order == 1)
      );
    },

    pessoaParceiroEndereco() {
      return this.pessoasEnderecoFormat(
        this.pedido.aux.parceiroEnderecos.find(
          (endereco) => endereco.order == 1
        )
      );
    },
  },

  /// METHODS
  methods: {
    format,

    documentoMascara(idPais, idTipo) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id,
        idTipo
      )[0]?.mascara;

      return mascara;
    },

    print() {
      this.printing = true;
      setTimeout(() => {
        this.pagePrint("pedido");
        setTimeout(() => {
          this.printing = false;
        }, 10);
      }, 10);
    },
  },
};
</script>