<!-- COD: LYF 12/08/2022 CCupons -->
<!-- *VER: JER 19/08/2022 CCupons -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- codigo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <v-chip
          class="darken-3 white--text text-body-1"
          :class="corCodigo"
          :outlined="false"
        >
          {{ dataset.codigo }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- quantidade min ped -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          quant min ped {{ dataset.quantidadeMin }}
        </span>
      </v-col>

      <!-- quantidade max ped -->
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-1">
          quant max ped {{ dataset.quantidadeMax }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- valor -->
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          valor {{ numberFormat(dataset.valor, "currency") }}
        </span>
      </v-col>

      <!-- valor total -->
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-1">
          valor total
          {{
            numberFormat(
              parseFloat(dataset.valor || 0) *
                parseFloat(dataset.quantidadeMax || 0),
              "currency"
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- data inicio fim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">válido até {{ dataFim }} </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CCuponsProduto",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    corCodigo() {
      let color = "";
      if (!this.dataset.idPessoaParceiro) {
        color = "black";
      } else if (
        this.dataset.idPessoaParceiro &&
        !this.dataset.idPessoaFornecedor
      ) {
        color = "blue";
      } else if (
        this.dataset.idPessoaParceiro &&
        this.dataset.idPessoaFornecedor
      ) {
        color = "green";
      }

      return color;
    },

    dataFim() {
      const dataFim = this.dataset.dataFim
        ? `${this.dateFormat(this.dataset.dataFim, "date timeNoSecond")}`
        : "";

      return `${dataFim}`;
    },
  },
};
</script>