var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-base-panel-delete',{staticClass:"ml-n1",attrs:{"datasets":_vm.context.datasets,"indice":_vm.index,"panel":_vm.panel},on:{"click":function($event){return _vm.deletar(_vm.context)}}})],1)],1),(_vm.horaExtra.nomeAlt)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.horaExtra.nomeAlt.toUpperCase())+" ")])])],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1","clearable":"","date":"","disabled":new Date(((_vm.horaExtra.dataFim) + " " + (_vm.horaExtra.horaFim))) <
            new Date() ||
          new Date(((_vm.horaExtra.dataInicio) + " " + (_vm.horaExtra.horaInicio))) <
            new Date(),"label":"data início","maxValue":_vm.horaExtra.dataFim,"minValue":new Date().toISOString().substring(0, 10)},model:{value:(_vm.horaExtra.dataInicio),callback:function ($$v) {_vm.$set(_vm.horaExtra, "dataInicio", $$v)},expression:"horaExtra.dataInicio"}})],1),_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1 ml-2","clearable":"","date":"","disabled":new Date(((_vm.horaExtra.dataFim) + " " + (_vm.horaExtra.horaFim))) < new Date(),"label":"data fim","minValue":_vm.horaExtra.dataInicio || new Date().toISOString().substring(0, 10)},model:{value:(_vm.horaExtra.dataFim),callback:function ($$v) {_vm.$set(_vm.horaExtra, "dataFim", $$v)},expression:"horaExtra.dataFim"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1","clearable":"","disabled":new Date(((_vm.horaExtra.dataFim) + " " + (_vm.horaExtra.horaFim))) <
            new Date() ||
          new Date(((_vm.horaExtra.dataInicio) + " " + (_vm.horaExtra.horaInicio))) <
            new Date(),"help":{
          title: 'HORA EXTRA',
          text: 'Hora inicio e hora fim é o intervalo em que a hora extra poderá ser feita.',
          function: _vm.context.setUiBottomSheet,
        },"label":"hora início","minValue":_vm.format(new Date(), "kk:mm"),"time":""},model:{value:(_vm.horaExtra.horaInicio),callback:function ($$v) {_vm.$set(_vm.horaExtra, "horaInicio", $$v)},expression:"horaExtra.horaInicio"}})],1),_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1 ml-2","clearable":"","disabled":new Date(((_vm.horaExtra.dataFim) + " " + (_vm.horaExtra.horaFim))) <
            new Date() ||
          new Date(((_vm.horaExtra.dataInicio) + " " + (_vm.horaExtra.horaInicio))) <
            new Date(),"help":{
          title: 'HORA EXTRA',
          text: 'Hora inicio e hora fim é o intervalo em que a hora extra poderá ser feita.',
          function: _vm.context.setUiBottomSheet,
        },"label":"hora fim","minValue":_vm.horaExtra.horaInicio,"time":""},model:{value:(_vm.horaExtra.horaFim),callback:function ($$v) {_vm.$set(_vm.horaExtra, "horaFim", $$v)},expression:"horaExtra.horaFim"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"5"}},'v-col',_vm.prop.col,false),[_c('c-datetime-picker',{attrs:{"classe":"text-body-1","clearable":"","disabled":new Date(((_vm.horaExtra.dataFim) + " " + (_vm.horaExtra.horaFim))) <
            new Date() ||
          new Date(((_vm.horaExtra.dataInicio) + " " + (_vm.horaExtra.horaInicio))) <
            new Date(),"help":{
          title: 'HORA EXTRA',
          text: 'Horas autorizadas é o número de horas extras que estão autorizadas.',
          function: _vm.context.setUiBottomSheet,
        },"label":"horas autorizadas","maxValue":"12:00","minValue":_vm.format(new Date(), "kk:mm"),"stepMinutes":15,"time":""},model:{value:(_vm.horaExtra.horasExtras),callback:function ($$v) {_vm.$set(_vm.horaExtra, "horasExtras", $$v)},expression:"horaExtra.horasExtras"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"observação","max":50},model:{value:(_vm.horaExtra.observacao),callback:function ($$v) {_vm.$set(_vm.horaExtra, "observacao", $$v)},expression:"horaExtra.observacao"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }