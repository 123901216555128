<!-- COD: BJB 21/07/2022 CSelect -->
<!-- *VER: JER 10/08/2022 CSelect -->
<!-- /22/ -->

<template>
  <c-help :help="help" :offset-x="offsetX" :offset-y="offsetY">
    <v-select
      v-bind="$attrs"
      v-model="model"
      :class="classe"
      :disabled="$attrs.disabled || !itemsTraduzidos.length"
      :items="itemsTraduzidos"
      :label="cLabel"
      :multiple="multiple"
      offset-y
      :ref="reference"
      :rules="
        cRules ||
        commonRules.common(
          'select',
          required,
          null,
          1,
          null,
          null,
          msg,
          null,
          null,
          panelCallback
        )
      "
      @change="$emit('change', $event)"
      @click="$emit('click', $event)"
      @click:prepend-inner="$emit('clickPrependInner', $event)"
    >
      <template
        v-if="
          cProperties &&
          (cProperties.chip || cProperties.image || cProperties.icon)
        "
        v-slot:selection="{ item, index }"
      >
        <img
          v-if="cProperties.image"
          class="mr-1"
          :src="item.image"
          width="25"
        />
        <draggable
          v-bind="dragOptions"
          v-if="cProperties.chip"
          :id="index"
          :list="model"
          :move="move"
          @change="change"
        >
          <v-chip
            draggable
            small
            text-color="white"
            @mousedown.stop
            @click.stop
          >
            {{ item.text }}
          </v-chip>
        </draggable>
        <span v-else class="mr-4">
          <v-icon
            v-bind="icon.properties"
            v-for="(icon, i) in item ? item.icons : []"
            :class="`ml-1 ${icon.class || ''}`"
            :key="i"
          >
            {{ icon.icon }}
          </v-icon>
          {{ item.text }}
        </span>
      </template>
      <template v-if="cProperties && cProperties.image" v-slot:item="{ item }">
        <c-checkbox v-if="multiple" :value="model.includes(item.value)" />
        <img class="mr-2" :src="item.image" width="25" />
        {{ item.text }}
      </template>
      <template
        v-else-if="cProperties && cProperties.icon"
        v-slot:item="{ item }"
      >
        <v-row align="center">
          <v-col :cols="item.colsIcon || 1">
            <v-row v-for="(icon, i) in item ? item.icons : []" :key="i">
              <v-icon
                v-bind="icon.properties"
                :class="`ml-1 mb-1 ${icon.class || ''}`"
              >
                {{ icon.icon }}
              </v-icon>
            </v-row>
          </v-col>
          <v-col :cols="item.colsText || 11">
            <span :class="item.classText || ''">
              {{ item.text }}
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-if="multiple && cProperties.todos" #prepend-item>
        <v-list-item ripple @click="selectAll">
          <v-list-item-action>
            <v-icon :color="(model || []).length > 0 ? 'primary' : ''">
              {{ iconSelectAll }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>todos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </c-help>
</template>

<script>
/// IMPORT
import draggable from "vuedraggable";
import mixinLib from "@/mixins/mixinLib";

export default {
  /// NAME
  name: "CSelect",

  /// MIXINS
  mixins: [mixinLib],

  /// COMPONENTS
  components: {
    draggable,
  },

  /// PROPS
  props: {
    classe: { type: String, default: "text-body-1" },
    cRules: { type: Array },
    help: { type: Object },
    isDraggable: { type: Boolean, default: false },
    items: { type: Array, default: Array },
    label: { type: String, default: "" },
    msg: { type: String },
    multiple: { type: Boolean, default: false },
    panel: { type: Object },
    properties: {
      type: Object,
      default: function () {
        return {};
      },
    },
    reference: { type: String, default: "" },
    required: { type: Boolean, default: false },
    value: { type: [Number, String, Array, Boolean] },
  },

  /// DATA
  data() {
    return {
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
    };
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },

    cLabel() {
      const label = this.translate(this.label);

      return `${label}${label && this.required ? " *" : ""}`;
    },

    cProperties() {
      const cProperties = JSON.parse(JSON.stringify(this.properties));

      if (cProperties) {
        delete cProperties.items;
      }

      return cProperties;
    },

    dragOptions() {
      return {
        animation: 200,
        group: "group",
        disabled: !(this.isDraggable && this.multiple),
        ghostClass: "ghost",
      };
    },

    iconSelectAll() {
      if (this.selectedAll) {
        return "mdi-checkbox-marked";
      } else if ((this.model || []).length > 0) {
        return "mdi-minus-box";
      } else {
        return "mdi-checkbox-blank-outline";
      }
    },

    itemsTraduzidos() {
      const items = this.items || this.properties.items;

      if (this.properties.icon) {
        const maxIcons = items.reduce(
          (acc, item) => Math.max(acc, item.icons?.length || 0),
          0
        );
        items.forEach((item) => {
          item.icons = item.icons || [];
          const iconsAdd = maxIcons - item.icons.length;
          item.icons.push(
            ...Array(iconsAdd)
              .fill()
              .map(() => "")
          );
        });
      }

      if (Array.isArray(items)) {
        return items.map((item) => {
          item.text = this.translate(item.text);
          return item;
        });
      } else {
        return [];
      }
    },

    offsetX() {
      return "8";
    },

    offsetY() {
      return "32";
    },

    panelCallback() {
      return this.panel ? this.panel.callbackRules : null;
    },

    selectedAll() {
      return (this.model || []).length == this.items.length;
    },
  },

  // METHODS
  methods: {
    change: function (value) {
      if (value.removed) {
        const model = this.model;
        // insert
        model.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          model[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          model.splice(this.dragged.from, 1);
        // RTL
        else model.splice(this.dragged.from + 1, 1);

        this.model = model;
      }
    },

    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },

    selectAll() {
      if (this.selectedAll) {
        this.model = [];
      } else {
        this.model = this.items.map((item) => item.value);
      }

      this.$emit("change");
    },
  },
};
</script>