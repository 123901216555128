<!-- COD: BJB 18/07/2022 CSwitch -->
<!-- *VER: JER 18/07/2022 CSwitch -->
<!-- /11/ -->

<template>
  <c-help
    estilo="width: fit-content"
    :help="help"
    :offsetX="offsetX"
    :offsetY="offsetY"
  >
    <v-switch
      v-bind="$attrs"
      v-model="model"
      :inset="inset"
      :label="label"
      style="width: fit-content"
      @change="$emit('change', $event)"
      @click="$emit('click', $event)"
    ></v-switch>
  </c-help>
</template>

<script>
export default {
  /// NAME
  name: "CSwitch",

  /// PROPS
  props: {
    help: { type: Object },
    inset: { type: Boolean, default: true },
    label: { type: String, default: "" },
    value: { type: Boolean },
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },

    offsetX() {
      return this.label ? "8" : "20";
    },

    offsetY() {
      return "35";
    },

    width() {
      return 50 + 10 * (this.label || "").length;
    },
  },

  /// WATCH
  watch: {
    value(valor) {
      this.model = valor;
    },
  },
};
</script>