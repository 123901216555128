<!-- COD: FDS 29/07/2022 CPontoEletronicoPageApontamentoEdit -->
<!-- *VER: JER 07/08/2022 CPontoEletronicoPageApontamentoEdit -->
<!-- /32/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- marcacoes -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span
          v-for="(marcacao, i) in dataset.marcacoes[data.id]"
          :key="`marcacao${i}`"
        >
          <!-- marcacao referente a data anterior -->
          <v-icon v-if="isMarcacaoAnterior(marcacao)" class="ml-2 mr-n3" small>
            mdi-arrow-down-thin
          </v-icon>
          <!-- marcacao referente a data seguinte -->
          <v-icon v-if="isMarcacaoSeguinte(marcacao)" class="ml-2 mr-n3" small>
            mdi-arrow-up-thin
          </v-icon>
          <!-- marcacao editavel -->
          <c-btn
            v-if="
              marcacao.data &&
              (i ==
                dataset.marcacoes[data.id].filter((marcacao) => marcacao.id)
                  .length -
                  1 ||
                i == 0 ||
                marcacao.isUpdated)
            "
            class="ml-n3"
            text
            @click="$emit('clickMarcacao', marcacao, dataset, data, i)"
          >
            <span
              :class="
                marcacao.isUpdated
                  ? 'font-weight-medium text-body-1'
                  : 'text-body-1'
              "
            >
              {{ format(parseISO(marcacao.data), "HH:mm") }}
            </span>
          </c-btn>
          <!-- marcacao nao editavel-->
          <c-btn v-else-if="marcacao.data" class="ml-n3" :ripple="false" text>
            <span class="text-body-1">
              {{ format(parseISO(marcacao.data), "HH:mm") }}
            </span>
          </c-btn>
        </span>
      </v-col>
    </v-row>

    <!-- alerta de inconsistencias -->
    <v-row v-bind="prop.row" v-if="data.idInconsistencia">
      <v-col v-bind="prop.col">
        <v-chip v-bind="prop.chip">marcações inconsistentes</v-chip>
      </v-col>
    </v-row>

    <!-- botao para complementar a marcacao -->
    <v-row v-bind="prop.row" v-if="data.idInconsistencia">
      <v-col
        v-bind="prop.col"
        @click="$emit('clickComplementar', dataset, data)"
      >
        <c-btn class="text-body-2 text-lowercase" color="error">
          complementar
        </c-btn>
      </v-col>
    </v-row>

    <!-- apontamentos -->
    <div
      v-for="(apontamento, i) in dataset.apontamentos[data.id]"
      :key="`apontamento${i}`"
    >
      <v-row v-bind="prop.row">
        <!-- apontamento -->
        <v-col v-bind="prop.col" cols="4">
          <v-chip v-bind="prop.chip">
            {{
              typeof apontamento == "string"
                ? apontamento
                : getVerba(apontamento.idVerba).options.nomeAlt
            }}
          </v-chip>
        </v-col>
        <!-- horas apontadas -->
        <v-col
          v-bind="prop.col"
          v-if="
            apontamento.horasApontadas &&
            getVerba(apontamento.idVerba).options.unidade == 'hora'
          "
          cols="2"
        >
          <span
            class="text-body-2"
            :class="apontamentosColor(data, apontamento)"
          >
            {{ apontamento.horasApontadas.substring(0, 5) }}
          </span>
        </v-col>
        <!-- horas confirmadas -->
        <v-col
          v-bind="prop.col"
          v-if="
            apontamento.idVerba &&
            getVerba(apontamento.idVerba).options.unidade == 'hora'
          "
          cols="3"
        >
          <c-datetime-picker
            v-model="apontamento.horasConfirmadas"
            classe="text-body-1 mt-n4 ml-4"
            :disabled="apontamento.disabled"
            label="confirmado"
            :maxValue="apontamento.horasApontadas"
            time
            @change="apontamento.isAlterado = true"
          />
        </v-col>
        <v-col v-bind="prop.col" cols="1">
          <v-spacer></v-spacer>
        </v-col>
        <!-- nao confirma apontamento -->
        <v-col
          v-bind="prop.col"
          v-if="
            apontamento.idVerba &&
            getVerba(apontamento.idVerba).options.unidade == 'hora'
          "
          cols="1"
        >
          <c-btn icon @click="clickConfirm(apontamento, false)">
            <v-icon class="red--text mt-n2">mdi-close</v-icon>
          </c-btn>
        </v-col>
        <!-- confirma apontamento -->
        <v-col
          v-bind="prop.col"
          v-if="
            apontamento.idVerba &&
            getVerba(apontamento.idVerba).options.unidade == 'hora'
          "
          cols="1"
        >
          <c-btn icon @click="clickConfirm(apontamento, true)">
            <v-icon class="green--text mt-n2">mdi-check</v-icon>
          </c-btn>
        </v-col>
      </v-row>

      <!-- situacao trabalho -->
      <v-row
        v-bind="prop.row"
        v-if="getVerba('d4Atraso').id == apontamento.idVerba"
      >
        <v-col v-bind="prop.col">
          <c-select
            v-model="apontamento.idSituacaoTrabalho"
            class="mt-n6"
            clearable
            :items="justificativasAtraso"
            label=""
            @change="changeSituacaoTrabalhoAtraso(dataset, apontamento)"
          />
        </v-col>
      </v-row>

      <!-- situacao trabalho -->
      <v-row
        v-bind="prop.row"
        v-else-if="
          getVerba(apontamento.idVerba) &&
          getVerba(apontamento.idVerba).options.hasJustificativa
        "
      >
        <v-col v-bind="prop.col">
          <c-select
            v-model="apontamento.idSituacaoTrabalho"
            class="mt-n6"
            :items="justificativas"
            label=""
            @change="changeSituacaoTrabalho(apontamento)"
          />
        </v-col>
      </v-row>

      <!-- justificativa -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <c-text-field
            v-if="apontamento.idSituacaoTrabalho == getTipo_item('outros').id"
            v-model="apontamento.justificativa"
            class="mt-n6"
            clearable
            label=""
            :max="30"
            placeholder="justificativa"
            :required="getVerba('d4Atraso').id == apontamento.idVerba"
            @change="changeJustificativa(apontamento)"
          />
        </v-col>
      </v-row>
    </div>

    <!-- feriado -->
    <v-row v-bind="prop.row" v-if="data.idFeriado">
      <v-col v-bind="prop.col">
        <v-chip v-bind="prop.chip">feriado</v-chip>
      </v-col>
    </v-row>

    <!-- feriado -->
    <v-row v-bind="prop.row" v-if="data.idFeriado">
      <v-col v-bind="prop.col">
        <span class="text-body-2">
          {{ getFeriado(data.idFeriado).nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPontoEletronicoPageApontamentoEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    data: { type: Object, required: true },
    dataset: { type: Object, required: true },
    idGestorImediato: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getFeriado: "feriados/getFeriado",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getVar: "variaveis/getVar",
      getVerba: "verbas/getVerba",
    }),

    isUsuarioGestorSelected() {
      return this.getVar("idUsuario") == this.idGestorImediato;
    },

    justificativas() {
      return this.getTipo_itens(this.getTipo("situacaoTrabalho").id).sortKeys(
        "text"
      );
    },

    justificativasAtraso() {
      return this.getTipo_itens(this.getTipo("situacaoTrabalho").id)
        .filter(
          (situacaoTrabalho) =>
            this.getTipo_item(situacaoTrabalho.value).options?.isAtraso
        )
        .sortKeys("text");
    },

    marcacoes() {
      return this.dataset.main.marcacoes.filter(
        (marcacao) => marcacao.idPai2 == this.data.id
      );
    },
  },

  /// WATCH
  watch: {
    dataset: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },

  /// METHODS
  methods: {
    apontamentosColor(data, apontamento) {
      const greyColor = data.isFolga || data.idFeriado;

      if (greyColor) {
        return ["grey--text"];
      }

      const idVerbasOcorrencia = [
        this.getVerba("p4FeriasMes").id,
        this.getVerba("p4SalarioMaternidade").id,
      ];

      if (
        !idVerbasOcorrencia.includes(apontamento.idVerba) &&
        apontamento.idPai2 == data.id &&
        apontamento.idSituacaoTrabalho == null &&
        apontamento.horasConfirmadas == null
      ) {
        return "red--text";
      }
    },

    apontamentosEfetivosColor(apontamento) {
      if (apontamento.horasApontadas == apontamento.horasConfirmadas) {
        return "green--text";
      } else if (apontamento.horasConfirmadas == "00:00:00") {
        return "red--text";
      } else {
        return "blue--text";
      }
    },

    changeJustificativa(apontamento) {
      apontamento.isAlterado = true;
    },

    changeSituacaoTrabalho(apontamento) {
      apontamento.justificativa = null;
      apontamento.isAlterado = true;
    },

    changeSituacaoTrabalhoAtraso(colaborador, apontamento) {
      const isOldSituacaoTrabalhoDoenca = apontamento.oldIdSituacaoTrabalho
        ? this.getTipo_item(apontamento.oldIdSituacaoTrabalho).options?.isDoenca
        : false;
      const isSituacaoTrabalhoDoenca = this.getTipo_item(
        apontamento.idSituacaoTrabalho
      ).options?.isDoenca;
      const isSituacaoTrabalhoDesconta = this.getTipo_item(
        apontamento.idSituacaoTrabalho
      ).options?.isDescontado;
      const minutosAtraso = this.fnConvertTimeToMinutes(
        apontamento.horasApontadas
      );
      let minutosConfirmados = null;

      if (isOldSituacaoTrabalhoDoenca) {
        colaborador.saldoDoenca += minutosAtraso;
      }

      if (isSituacaoTrabalhoDoenca) {
        if (
          !colaborador.saldoDoenca ||
          colaborador.saldoDoenca >= minutosAtraso
        ) {
          minutosConfirmados = 0;
          colaborador.saldoDoenca = colaborador.saldoDoenca
            ? colaborador.saldoDoenca - minutosAtraso
            : null;
          apontamento.disabled = true;
        } else {
          minutosConfirmados = minutosAtraso - colaborador.saldoDoenca;
          colaborador.saldoDoenca = 0;
          apontamento.disabled = false;
        }
      } else {
        if (isSituacaoTrabalhoDesconta) {
          minutosConfirmados = minutosAtraso;
          apontamento.disabled = false;
        } else {
          minutosConfirmados = 0;
          apontamento.disabled = false;
        }
      }

      apontamento.oldIdSituacaoTrabalho = apontamento.idSituacaoTrabalho;
      apontamento.horasConfirmadas = new Date(
        null,
        null,
        null,
        null,
        minutosConfirmados,
        null
      )
        .toTimeString()
        .split(" ")[0]
        .substring(0, 8);
      apontamento.justificativa = null;
      apontamento.isAlterado = true;
    },

    clickConfirm(apontamento, confirm) {
      if (confirm) {
        apontamento.horasConfirmadas = apontamento.horasApontadas;
        apontamento.isAlterado = true;
      } else {
        apontamento.horasConfirmadas = "00:00";
        apontamento.isAlterado = true;
      }
    },

    convertTimeToMinutes(time) {
      if (!time) {
        return null;
      }

      const horario = time.split(/[.:]/);
      return this.hoursToMinutes(
        parseInt(horario[0], 10) + parseInt(horario[1], 10) / 60
      );
    },

    isMarcacaoAnterior(marcacao) {
      return (
        marcacao.data &&
        this.format(this.parseISO(marcacao.data), "yyyy-MM-dd") >
          this.format(
            this.parseISO(this.data.data.substring(0, 10)),
            "yyyy-MM-dd"
          )
      );
    },

    isMarcacaoSeguinte(marcacao) {
      return (
        marcacao.data &&
        this.format(this.parseISO(marcacao.data), "yyyy-MM-dd") <
          this.format(
            this.parseISO(this.data.data.substring(0, 10)),
            "yyyy-MM-dd"
          )
      );
    },
  },
};
</script>