<!-- COD: FDS 01/07/2022 CLocalidades -->
<!-- *VER: JER 05/07/2022 CLocalidades -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- imagem bandeira -->
      <v-col
        v-bind="prop.col"
        v-if="context.isBuscaLayout && dataset.anexo_endereco"
        cols="2"
      >
        <v-img :src="dataset.anexo_endereco" width="90"></v-img>
      </v-col>
      <!-- nome -->
      <v-col v-bind="prop.col" v-if="dataset.nome">
        <p class="text-h6">
          {{ dataset.nome }}
        </p>
        <!-- localizacao -->
        <a @click.stop="context.funcs.clickPai(dataset, context)">
          {{ localizacao }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLocalidades",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  computed: {
    localizacao() {
      return `${this.dataset.localidadePai_nome || ""}${
        this.dataset.localidadeAvo_nome
          ? " - " + this.dataset.localidadeAvo_nome
          : ""
      }`;
    },
  },
};
</script>