<!-- COD: LYF 01/07/2022 CVerbas -->
<!-- *VER: JER 03/07/2022 CVerbas -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome pai -->
    <v-row v-bind="prop.row" v-if="nomePai">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ nomePai }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- nivel -->
      <v-col v-bind="prop.col" cols="2">
        <v-badge
          :color="dataset.nivel == 4 ? `green` : `grey`"
          :content="dataset.nivel"
          inline
          overlap
          :value="!!dataset.nivel"
        ></v-badge>
      </v-col>
      <!-- provento -->
      <v-col v-bind="prop.col" v-if="dataset.isProvento" cols="4">
        <v-chip v-bind="prop.chip">provento</v-chip>
      </v-col>
      <!-- beneficio -->
      <v-col v-bind="prop.col" v-if="dataset.isBeneficio" cols="4">
        <v-chip v-bind="prop.chip">benefício</v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CVerbas",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  computed: {
    ...mapGetters({
      getVerba: "verbas/getVerba",
      getVerbas: "verbas/getVerbas",
    }),

    nomePai() {
      return this.getVerba(this.dataset.idPai)?.nomeAlt;
    },
  },
};
</script>