<!-- COD: FDS 01/07/2022 CFolhasPagamentos -->
<!-- *VER: JER 03/07/2022 CFolhasPagamentos -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- dataPagamento -->
      <v-col v-bind="prop.col" v-if="dataset.dataPagamento" cols="6">
        <span class="text-h6">
          {{ dateFormat(dataset.dataPagamento, "date") }}
        </span>
      </v-col>
      <!-- valorTotal -->
      <v-col v-bind="prop.col" v-if="dataset.valorTotal" align="end" cols="6">
        <span class="text-h6">
          {{ numberFormat(dataset.valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- data inicioFim -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataFolhaInicioFim }}
        </span>
      </v-col>
    </v-row>

    <!-- colaboradores -->
    <v-row v-bind="prop.row" v-if="dataset.colaboradores">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">colaboradores</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.colaboradores }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhasPagamentos",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  computed: {
    dataFolhaInicioFim() {
      return `${this.dataFormata(
        this.dataset.dataInicio,
        "date"
      )} - ${this.dataFormata(this.dataset.dataFim, "date")}`;
    },
  },
};
</script>