<!-- #COD: FDS 10/01/2023 CLegislacaoPageItemView -->
<!-- VER: JER __/01/2023 CLegislacaoPageItemView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- iscumulativo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{
            item.isCumulativo == null
              ? "retorna todos os itens"
              : item.isCumulativo
              ? "retorna a soma dos itens"
              : "retorna um dos itens"
          }}
        </v-chip>
      </v-col>
    </v-row>

    <div v-if="item.idReferenciaInicio == item.idReferenciaFim">
      <v-row v-bind="prop.row" v-if="!isOriginal">
        <v-col v-bind="prop.col" align="center">
          <span class="text-body-2"
            >a partir de
            {{ dateFormat(item.dataInicio, "date", null, true) }} até
            {{ dateFormat(item.dataFim, "date", null, true) }}</span
          >
        </v-col>
      </v-row>
      <v-row v-bind="prop.row" v-if="!isOriginal" class="mt-n3">
        <v-col v-bind="prop.col" align="center">
          <a
            class="text-body-1"
            @click="clickLegislacao(item, 'idReferenciaInicio')"
            >{{ item.legislacao_nomeInicio }}</a
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <!-- data inicio -->
      <v-row v-bind="prop.row" v-if="!isOriginal && item.dataInicio">
        <v-col v-bind="prop.col" align="center">
          <span class="text-body-2"
            >a partir de
            {{ dateFormat(item.dataInicio, "date", null, true) }}</span
          >
        </v-col>
      </v-row>
      <v-row
        v-bind="prop.row"
        v-if="!isOriginal && item.idReferenciaInicio"
        class="mt-n3"
      >
        <v-col v-bind="prop.col" align="center">
          <a
            class="text-body-1"
            @click="clickLegislacao(item, 'idReferenciaInicio')"
            >{{ item.legislacao_nomeInicio }}</a
          >
        </v-col>
      </v-row>

      <!-- data fim -->
      <v-row v-bind="prop.row" v-if="!isOriginal && item.dataFim">
        <v-col v-bind="prop.col" align="center">
          <span class="text-body-2"
            >até {{ dateFormat(item.dataFim, "date", null, true) }}</span
          >
        </v-col>
      </v-row>
      <v-row
        v-bind="prop.row"
        v-if="!isOriginal && item.idReferenciaFim"
        class="mt-n3"
      >
        <v-col v-bind="prop.col" align="center">
          <a
            class="text-body-1"
            @click="clickLegislacao(item, 'idReferenciaFim')"
            >{{ item.legislacao_nomeFim }}</a
          >
        </v-col>
      </v-row>
    </div>

    <!-- localidade origem -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip
          v-for="(idLocalidadeOrigem, i) of item.idLocalidadesOrigem"
          class="grey text-body-1 ml-1"
          :key="i"
          outlined
          x-small
        >
          {{
            getLocalidade(idLocalidadeOrigem).codigo2 ||
            `${getLocalidade(idLocalidadeOrigem).nome} ${
              getLocalidade(getLocalidade(idLocalidadeOrigem).idPai).codigo2
            }`
          }}
        </v-chip>
        <v-chip
          v-if="
            item.idLocalidadesDestino.length && !item.idLocalidadesOrigem.length
          "
          class="grey text-body-1 ml-1"
          outlined
          x-small
        >
          todas
        </v-chip>
      </v-col>
    </v-row>

    <!-- icon -->
    <v-row
      v-bind="prop.row"
      v-if="
        (item.idLocalidadesOrigem && item.idLocalidadesOrigem.length) ||
        (item.idLocalidadesDestino && item.idLocalidadesDestino.length)
      "
      class="my-n1"
    >
      <v-col v-bind="prop.col" align="center">
        <v-icon>mdi-arrow-down</v-icon>
      </v-col>
    </v-row>

    <!-- localidade destino -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip
          v-for="(idLocalidadeDestino, i) of item.idLocalidadesDestino"
          class="grey text-body-1 ml-1"
          :key="i"
          outlined
          x-small
        >
          {{
            getLocalidade(idLocalidadeDestino).codigo2 ||
            `${getLocalidade(idLocalidadeDestino).nome} ${
              getLocalidade(getLocalidade(idLocalidadeDestino).idPai).codigo2
            }`
          }}
        </v-chip>
        <v-chip
          v-if="
            item.idLocalidadesOrigem.length && !item.idLocalidadesDestino.length
          "
          class="grey text-body-1 ml-1"
          outlined
          x-small
        >
          todas
        </v-chip>
      </v-col>
    </v-row>

    <!-- codigos fiscais -->
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscais && item.codigosFiscais.length"
      class="mb-n3"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">{{
          labelCodigoFiscal("códigos fiscais")
        }}</span>
      </v-col>
    </v-row>
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscais && item.codigosFiscais.length"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip
          v-for="(codigoFiscal, i) of item.codigosFiscais"
          class="grey text-body-1 ml-1"
          :key="i"
          outlined
          x-small
        >
          {{ codigoFiscal }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- codigos fiscais excecoes -->
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscaisExcecoes && item.codigosFiscaisExcecoes.length"
      class="mb-n3"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">{{
          labelCodigoFiscal("códigos fiscais exceções")
        }}</span>
      </v-col>
    </v-row>
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscaisExcecoes && item.codigosFiscaisExcecoes.length"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip
          v-for="(codigoFiscalExcecao, i) of item.codigosFiscaisExcecoes"
          class="grey text-body-1 ml-1"
          :key="i"
          outlined
          x-small
        >
          {{ codigoFiscalExcecao }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- codigosFiscais2 -->
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscais2 && item.codigosFiscais2.length"
      class="mb-n3"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">{{
          labelCodigoFiscal("códigos fiscais 2")
        }}</span>
      </v-col>
    </v-row>
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscais2 && item.codigosFiscais2.length"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip
          v-for="(codigosFiscais2, i) of item.codigosFiscais2"
          class="grey text-body-1 ml-1"
          :key="i"
          outlined
          x-small
        >
          {{ codigosFiscais2 }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- codigosFiscais2 excecoes -->
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscais2Excecoes && item.codigosFiscais2Excecoes.length"
      class="mb-n3"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">{{
          labelCodigoFiscal("códigos fiscais 2 exceções")
        }}</span>
      </v-col>
    </v-row>
    <v-row
      v-bind="prop.row"
      v-if="item.codigosFiscais2Excecoes && item.codigosFiscais2Excecoes.length"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip
          v-for="(codigosFiscais2Ex, i) of item.codigosFiscais2Excecoes"
          class="grey text-body-1 ml-1"
          :key="i"
          outlined
          x-small
        >
          {{ codigosFiscais2Ex }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- options -->
    <v-row v-bind="prop.row" v-if="item.options" class="mb-n3">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">options</span>
      </v-col>
    </v-row>
    <v-row v-bind="prop.row" v-if="item.options">
      <v-col v-bind="prop.col" align="center">
        <c-help
          estilo="width: fit-content"
          :help="{
            title: 'ORIGEM',
            text: `0 - Nacional, exceto códigos 3, 4, 5 e 8
1 - Estrangeira - importação direta, exceto código 6
2 - Estrangeira - adquirida no mercado interno, exceto código 7
3 - Nacional - com conteúdo de importação > 40% e <= 70%
4 - Nacional - produção em  conformidade com os processos produtivos básicos 
5 - Nacional - com conteúdo de importação <= 40% 
6 - Estrangeira - importação direta, sem similar nacional, constante em lista CAMEX
7 - Estrangeira - adquirida no mercado interno, sem similar nacional, constante em lista CAMEX
8 - Nacional - com conteúdo de importação > 70%`,
            function: context.setUiBottomSheet,
            alignText: 'text-left',
          }"
        >
          <span class="text-body-1">{{ item.options }}</span>
        </c-help>
      </v-col>
    </v-row>

    <!-- observacao-->
    <v-row v-bind="prop.row" v-if="item.observacao" class="mt-5">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">{{ item.observacao }}</span>
      </v-col>
    </v-row>

    <!-- observacoes-->
    <v-row v-bind="prop.row" v-if="item.observacoes">
      <v-col v-bind="prop.col" align="center">
        <c-textarea
          v-model.trim="item.observacoes"
          class="text-body-1"
          :clearable="false"
          readonly
          :rows="3"
        />
        <!-- <span class="text-body-1">{{ item.observacoes }}</span> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLegislacaoPageItemView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    item: { type: Object, required: true },
    isOriginal: { type: Boolean, required: true },
  },

  /// DATA
  data() {
    return {
      showHolerite: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVerba: "verbas/getVerba",
      getImposto: "impostos/getImposto",
    }),
  },

  /// METHODS
  methods: {
    clickLegislacao(item, referencia) {
      this.$router.push(`/legislacao/${item[referencia]}`);
    },

    labelCodigoFiscal(key) {
      const options = this.getImposto(this.item.idImposto).options;
      if (options && options[key]) {
        return options[key] || null;
      }
      return key;
    },
  },
};
</script>