<!-- COD: FDS 01/07/2022 CPedidos -->
<!-- *VER: JER 05/07/2022 CPedidos -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- operacao -->
    <v-row v-bind="prop.row" v-if="operacao">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ operacao }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data -->
      <v-col v-bind="prop.col" v-if="dataset.data" cols="auto">
        <span class="text-body-1">
          {{ dateFormat(dataset.data, `date timeNoSecond`, null, true) }}
        </span>
      </v-col>
      <!-- itensFormatado -->
      <v-col v-bind="prop.col" v-if="itensFormatado" cols="auto" class="ml-3">
        <span class="text-body-1">
          {{ itensFormatado }}
        </span>
      </v-col>
      <!-- valorTotal -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.valorTotal"
        cols="auto"
        class="ml-3"
      >
        <span class="text-body-1">
          {{ numberFormat(dataset.valorTotal, "currency") }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa -->
    <v-row v-bind="prop.row" v-if="pessoa">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ pessoa }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPedidos",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getOperacao: "operacoes/getOperacao",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    itensFormatado() {
      return `${this.dataset.itens} ${
        this.dataset.itens > 1 ? " itens" : " item"
      }`;
    },

    operacao() {
      return this.getOperacao(this.dataset.idOperacao)?.nomeAlt;
    },

    pessoa() {
      return this.getVar("idPessoaParceiro") == this.getConst("app").id ||
        this.dataset.idCliente != this.getVar("idPessoaParceiro")
        ? this.dataset.cliente
        : this.dataset.parceiro;
    },
  },
};
</script>