<!-- COD: BJB 09/08/2022 CBaseListItem -->
<!-- *VER: JER 10/08/2022 CBaseListItem -->
<!-- /11/ -->

<template>
  <v-list-item-content>
    <v-row
      v-bind="prop.row"
      v-if="
        dataset.pessoaParceiro_nomeAlt &&
        dataset.idPessoaParceiro != this.getVar('idPessoaParceiro')
      "
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip class="primary primary--text text-overline" outlined x-small>
          {{ dataset.pessoaParceiro_nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>
    <slot> </slot>
    <v-list-item-subtitle v-if="identification" id="identification">
      <c-base-item-identification
        class="ml-n2 mt-n2"
        classe="ml-2"
        :dataset="dataset"
        :idPage="idPage"
        :order="order"
      >
        <template #icon>
          <slot name="icon"></slot>
        </template>

        <template #info>
          <slot name="info"></slot>
        </template>
      </c-base-item-identification>
    </v-list-item-subtitle>
    <slot name="action"></slot>
  </v-list-item-content>
</template>

<script>
import { mapGetters } from "vuex";
import CBaseItemIdentification from "@/components/bases/CBaseItemIdentification";

export default {
  /// NAME
  name: "CBaseListItem",

  /// COMPONENTS
  components: {
    CBaseItemIdentification,
  },

  /// PROPS
  props: {
    context: { type: Object, required: false },
    dataset: { type: Object, required: true },
    identification: { type: Boolean, default: true },
    idFeature: { type: Number, default: null },
    idPage: { type: Number, required: true },
    order: { type: Object, default: Object },
    pageModeView: { type: Boolean, default: true },
    title: { type: Boolean, default: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVar: "variaveis/getVar",
    }),
  },
};
</script>