<!-- COD: FDS 01/07/2022 Pedidos -->
<!-- *VER: JER 04/07/2022 Pedidos -->
<!-- /12/ -->

<template>
  <c-base-list
    v-slot="{ context, dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
    :pessoaParceiro="params.nome || null"
  >
    <c-base-list-item
      :context="context"
      :dataset="dataset"
      :idPage="idPage"
      :order="order"
    >
      <c-pedidos-produto
        v-if="$route.query.idProduto"
        :context="context"
        :dataset="dataset"
      />
      <c-pedidos-pessoa-produto
        v-else-if="$route.query.idPessoa && $route.query.isProduto"
        :context="context"
        :dataset="dataset"
      />
      <c-pedidos v-else :context="context" :dataset="dataset" />
      <template #action>
        <v-row
          v-bind="prop.row"
          v-if="
            !!dataset.avaliacao ||
            agora <=
              addDays(
                parseISO(dataset.dataEntrega),
                getConst('config').avaliacao.prazoAvaliacao
              )
          "
        >
          <v-col v-bind="prop.col" align="center">
            <c-btn
              v-show="
                [getEstado('entregue').id, getEstado('finalizado').id].includes(
                  dataset.idEstado
                )
              "
              text
              @click.stop="$router.push(`/avaliacao/${dataset.id}`)"
            >
              <span
                :class="!!dataset.avaliacao ? 'black--text' : 'primary--text'"
              >
                {{ !!dataset.avaliacao ? "Avaliação:" : "Avalie:" }}
              </span>
              <v-rating
                v-model="dataset.avaliacao"
                :background-color="
                  dataset.avaliacao > 0 ? 'orange lighten-3' : 'grey'
                "
                :color="dataset.avaliacao > 0 ? 'orange' : 'grey'"
                dense
                hover
                readonly
                size="18"
              />
            </c-btn>
          </v-col>
        </v-row>

        <v-row
          v-bind="prop.row"
          v-if="
            agora >
            addDays(
              parseISO(dataset.dataEntrega),
              getConst('config').avaliacao.prazoAvaliacao
            )
          "
        >
          <v-col v-bind="prop.col">
            <span class="text-body-1">prazo de avaliação encerrado</span>
          </v-col>
        </v-row>
      </template>

      <template #info>
        <v-row v-bind="prop.row" v-show="order.text != 'entrega'">
          <v-col v-bind="prop.col">
            <v-chip
              class="ml-3 text-overline white--text"
              :class="dataset.isEntregar ? 'corEntregar' : 'corRetirar'"
              x-small
            >
              {{ dataset.isEntregar ? "entregar" : "retirar" }}
            </v-chip>
          </v-col>
        </v-row>
      </template>
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CPedidos from "@/components/pages/pedido/CPedidos";
import CPedidosPessoaProduto from "@/components/pages/pedido/CPedidosPessoaProduto";
import CPedidosProduto from "@/components/pages/pedido/CPedidosProduto";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Pedidos",

  /// COMPONENTS
  components: {
    CPedidos,
    CPedidosPessoaProduto,
    CPedidosProduto,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("pedidos").id,
      idPageAlt: getPagina("pedido").id,

      funcs: {
        clickChip: () => {
          this.params = {};
          this.setVar({ routeReplace: true });
          this.$router.replace({ query: null });
        },

        clickRelatorioPedidos: () => {
          setTimeout(() => {
            this.$router.push(`/pedidos/relatorio/pedidos`);
          }, 10);
        },

        fnParams: (context) => {
          const contextParams = context?.params
            ? JSON.parse(context.params)
            : null;

          const params = JSON.parse(JSON.stringify(this.params));
          delete params.nome;
          delete params.home;
          delete params.from;
          params.proprietarios = contextParams?.proprietarios ?? true;
          params.idTipoPedido = context.idTipo;

          return JSON.stringify(params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo)?.nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado)?.nomeAlt;
          dataset.tipoEntrega = dataset.isEntregar ? "entregar" : "retirar";
        },
      },

      params: {},

      agora: new Date(),
    };
  },

  /// BEFOREMOUNT
  beforeMount() {
    this.params = this.copyObject(this.$route.query);

    if (this.params?.idPessoa) {
      this.params["_tab.idPessoa"] = this.params.idPessoa;
      delete this.params.idPessoa;
    }
  },

  /// ACTIVATED
  activated() {
    this.params = this.copyObject(this.$route.query);
    if (this.params?.idPessoa) {
      this.params["_tab.idPessoa"] = this.params.idPessoa;
      delete this.params.idPessoa;
    }
  },
};
</script>