<!-- COD: BJB 09/08/2022 CBaseEnderecos -->
<!-- *VER: JER 10/08/2022 CBaseEnderecos -->
<!-- /22/ -->

<template>
  <!-- /// ENDERECOS -->
  <div class="enderecos">
    <v-menu v-model="showEnderecos" bottom offset-y>
      <template #activator="{ on }">
        <v-btn v-on="on" icon :input-value="showEnderecos">
          <v-icon large>mdi-map-marker-outline</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-container class="pa-0">
          <v-row v-bind="prop.row" justify="center">
            <v-col v-bind="prop.col" align="center">
              <v-chip-group
                v-model="isEntregar"
                mandatory
                style="width: fit-content"
              >
                <v-chip
                  v-for="(entrega, i) in entregas"
                  class="text-overline"
                  :class="`primary ${
                    isEntregar != entrega.value ? 'primary' : 'white'
                  }--text`"
                  :key="i"
                  :outlined="isEntregar != entrega.value"
                  :value="entrega.value"
                  @click.stop
                >
                  {{ entrega.text }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <v-list-item
            v-for="(endereco, i) in enderecos"
            class="ml-n2"
            :key="i"
            @click.stop="clickEndereco(endereco)"
          >
            <v-icon :class="endereco.color">
              {{ endereco.icone }}
            </v-icon>
            <v-list-item-title class="ml-1 text-body-2" :class="endereco.color">
              {{ endereco.text.trim() || "Endereço incompleto" }}
            </v-list-item-title>
          </v-list-item>

          <v-row v-bind="prop.row" class="mx-3">
            <v-col v-bind="prop.col">
              <c-btn block classe="primary white--text" @click="setAtual(true)">
                aplicar
              </c-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list>
    </v-menu>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />
  </div>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CBaseEnderecos",

  /// MIXINS
  mixins: [mixinSnackbar],

  /// DATA
  data() {
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      enderecosIcone: [
        {
          idTipo: getTipo_item("enderecoCasa").id,
          icone: "mdi-home-outline",
        },
        {
          idTipo: getTipo_item("enderecoTrabalho").id,
          icone: "mdi-office-building",
        },
        {
          idTipo: getTipo_item("enderecoOutro").id,
          icone: "mdi-account-outline",
        },
      ],
      isEntregar: getVar("isEntregar"),
      entregas: [
        { value: true, text: "entregar" },
        { value: false, text: "retirar" },
      ],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    showEnderecos: {
      get: function () {
        return this.getVar("showEnderecos");
      },
      set: function (newValue) {
        this.setVar({ showEnderecos: newValue });
      },
    },

    enderecos() {
      let enderecos = [];
      if (this.getVar("usuarios").length && this.getVar("idPessoaParceiro")) {
        enderecos =
          (
            this.getVar("parceiroCliente").enderecos ||
            this.getVar("usuarios").filter(
              (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
            )[0]?.enderecos
          ).map((endereco) => {
            return {
              id: endereco.id,
              icone: this.enderecosIcone.filter(
                (icone) => icone.idTipo == endereco.idTipo
              )[0].icone,
              text: `${endereco.endereco} ${endereco.numero || ""} ${
                endereco.complemento || ""
              } ${endereco.bairro || ""}`,
              action: this.getTipo_item(endereco.idTipo).nome,
              color:
                endereco.id == this.getVar("idPessoaEndereco")
                  ? "primary--text"
                  : "",
            };
          }) || [];
      }

      const endereco = {
        id: null,
        icone: "mdi-target",
        text: "Usar minha localização atual",
        action: "GPS",
        color: !this.getVar("idPessoaEndereco") ? "primary--text" : "",
      };

      enderecos.push(endereco);

      if (
        !(
          enderecos.find(
            (endereco) => endereco.id == this.getVar("idPessoaEndereco")
          )?.text || ""
        ).trim()
      ) {
        this.setVar({ idPessoaEndereco: null });
      }

      return enderecos;
    },

    logado() {
      return !!this.getVar("idPessoaParceiro");
    },
  },

  /// WATCH
  watch: {
    isEntregar() {
      this.setVar({ isEntregar: this.isEntregar });
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    aplicar() {
      this.$emit("aplicar");
      this.showEnderecos = false;
    },

    clickEndereco(endereco) {
      this.setVar({ idPessoaEndereco: endereco.id });
    },

    clickSnackbar(action) {
      if (action == "carrinhosRoute") {
        this.$router.push("/carrinhos");
      } else if (action == "endereco") {
        this.$router.push(`/pessoa/${this.getVar("idUsuario")}?edit=1`);
      }

      this.setSnackbar();
    },

    async setAtual() {
      if (!this.logado) {
        this.aplicar();
        return;
      }

      if (
        !(
          this.enderecos.find(
            (endereco) => endereco.id == this.getVar("idPessoaEndereco")
          )?.text || ""
        ).trim()
      ) {
        this.showEnderecos = false;

        await this.setSnackbar(
          true,
          "LOCALIZAÇÃO",
          "Endereço incompleto. Deseja atualizar seu endereço?",
          "warning",
          "mdi-check",
          "endereco",
          null
        );
      }

      this.setVar({ overlay: true });
      this.setSnackbar();
      const idCarrinho = 0;
      const idCliente = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const atualizar = 0;

      const url = `${
        this.getConst("app").baseUrlBackend
      }/carrinhos/setAtual/${idCarrinho}/${idCliente}/${idUsuario}/${atualizar}`;

      axios
        .put(url)
        .then(() => {
          this.setVar({
            "pendencias.carrinhoAtualItens": { priority: null, value: null },
          });

          const carrinhoAtual = this.getVar("carrinhos").find(
            (carrinho) => !!carrinho.idAtual
          );

          if (carrinhoAtual) {
            carrinhoAtual.idAtual = null;
          }
        })
        .catch()
        .finally(async () => {
          this.setVar({ overlay: false });

          if (this.$route.name != "Carrinhos") {
            const carrinhos = this.getVar("carrinhos").filter(
              (carrinho) => carrinho.isEntregar == this.getVar("isEntregar")
            );

            if (carrinhos.length) {
              this.showEnderecos = false;

              await this.setSnackbar(
                true,
                "COMPRA",
                "Existem carrinhos abertos. Deseja gerenciar seus carrinhos e tornar algum deles o atual?",
                "warning",
                "mdi-check",
                "carrinhosRoute",
                null
              );
            }
          }

          this.aplicar();
        });
    },
  },
};
</script>