<!-- COD: LYF 06/07/2022 LancamentoContabil -->
<!-- *VER: JER 06/08/2022 LancamentoContabil -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo('tipoHistoricoPadrao').id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-lancamentoContabil-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-lancamentoContabil-edit
            v-else
            :context="context"
            :dataset="dataset"
          />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CLancamentoContabilEdit from "@/components/pages/lancamentoContabil/CLancamentoContabilEdit";
import CLancamentoContabilView from "@/components/pages/lancamentoContabil/CLancamentoContabilView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "LancamentoContabil",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CLancamentoContabilEdit,
    CLancamentoContabilView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("lancamentoContabil").id,
      idPageAlt: getPagina("lancamentosContabeis").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            data: null,
            dataBalancete: null,
            idHistoricoPadrao: null,
            historicoComplementar: null,
            valorTotal: null,
            isEstorno: false,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          contasContabeis: [],
          fatosContabeis: [],
        },

        aux: {
          detail: {
            historicoPadrao: null,
          },
          autocomplete: {
            historicoPadrao: [],
            contasContabeis: [],
            detalhes: [],
          },
        },
      },

      funcs: {
        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },
      },

      params: {
        context: this,
      },
    };
  },
};
</script>