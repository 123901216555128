var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[(_vm.dataset.aux.detail.pessoaCliente_nome)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"classe":"primary--text","text":""},on:{"click":_vm.clickPessoa}},[_vm._v(" "+_vm._s(_vm.dataset.aux.detail.pessoaCliente_nome)+" ")])],1)],1):_vm._e(),(_vm.dataset.main.detail.dataVencimento)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("vencimento")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('span',{class:_vm.parseISO(_vm.dataset.main.detail.dataVencimento) < new Date()
              ? 'red--text text-body-1'
              : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.dataset.main.detail.dataVencimento, "date"))+" ")])])],1):_vm._e(),(_vm.dataset.main.detail.idPedido)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("pedido")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('a',{staticClass:"primary--text text-body-1",on:{"click":_vm.clickPedido}},[_vm._v(" "+_vm._s(((_vm.dataset.main.detail.idPedido) + " (" + (_vm.dataFormata( _vm.dataset.main.detail.data, "dateNoYear", true )) + ")"))+" ")])])],1):_vm._e(),(_vm.dataset.main.detail.idFormaPagamento)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("pagamento")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(((_vm.dataset.main.detail.isPagamentoOnline ? "online" : "") + " " + (_vm.getTipo_item(_vm.dataset.main.detail.idFormaPagamento).nomeAlt)))+" ")])])],1):_vm._e(),(_vm.dataset.main.detail.documento)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("documento")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dataset.main.detail.documento)+" ")])])],1):_vm._e(),(_vm.dataset.main.detail.valor)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("valor")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'VALOR',
            text: "Valor da transação.",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{class:_vm.dataset.main.detail.valor < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.valor, "currency"))+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.valorCuponsApp)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("cupons app")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'CUPONS APP',
            text: "Valor total dos cupons emitidos pelo app.",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{class:_vm.dataset.main.detail.valorCuponsApp < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.valorCuponsApp, "currency"))+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.valorCuponsParceiro)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("cupons parceiro")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'CUPONS PARCEIRO',
            text: "Valor total dos cupons emitidos pelo parceiro.",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{class:_vm.dataset.main.detail.valorCuponsParceiro < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.valorCuponsParceiro, "currency"))+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.valorApp)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("valor app")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'VALOR APP',
            text: "Valor de responsabilidade do app.\n\n\nSe pagamento:\n- online = valor - cupons parceiro\n- não online = cupons app",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{class:_vm.dataset.main.detail.valorApp < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.valorApp, "currency"))+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.valorComissao)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("comissão")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'COMISSÃO',
            text: "Valor da comissão da transação.\n\n            \ncomissao = valor * %comissao",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{class:_vm.dataset.main.detail.valorComissao < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.valorComissao, "currency"))+" ")]),_c('span',{class:_vm.dataset.main.detail.percentual < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" ("+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.percentual / 100, "percent", 2))+")")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.valorAcerto)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("acerto")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'ACERTO',
            text: "Valor do acerto da transação.\n\n            \nacerto = valor app - comissao",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{class:_vm.dataset.main.detail.valorAcerto < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.valorAcerto, "currency"))+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.valorSaldo)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("saldo")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'SALDO',
            text: "Saldo das transações até esta transação.\n\n            \nsaldo = saldo anterior + acerto",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{class:_vm.dataset.main.detail.valorSaldo < 0
                ? 'red--text text-body-1'
                : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.dataset.main.detail.valorSaldo, "currency"))+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.idTransacaoPagamento)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("transação pagamento")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'TRANSAÇÃO PAGAMENTO',
            text: "Id da transação que foi feito o acerto (pagamento) desta transação.",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dataset.main.detail.idTransacaoPagamento)+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.idTransacaoFaturamento)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"5"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("transação faturamento")])]),_c('v-col',_vm._b({attrs:{"cols":"7"}},'v-col',_vm.prop.col,false),[_c('c-help',{attrs:{"help":{
            title: 'TRANSAÇÃO FATURAMENTO',
            text: "Id da transação que foi feito a nota fiscal de serviço desta transação.",
            function: _vm.params.context.setUiBottomSheet,
          }}},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.numberFormat( _vm.dataset.main.detail.idTransacaoFaturamento, "currency" ))+" ")])])],1)],1):_vm._e(),(_vm.dataset.main.detail.observacao)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("("+_vm._s(_vm.dataset.main.detail.observacao)+")")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }