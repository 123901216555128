<!-- COD: BJB 05/07/2022 CHistoricoPadraoEdit -->
<!-- *VER: JER 06/08/2022 CHistoricoPadraoEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- id -->
    <v-row
      v-bind="prop.row"
      v-if="getConst('config').pageMode.insert.id == context.idPageMode"
    >
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.id"
          label="id"
          :max="15"
          required
          type="tel"
        />
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeAlt"
          label="nome alt"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <!-- nomeComplementar -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nomeComplementar"
          label="nome complementar"
          :max="40"
        />
      </v-col>
    </v-row>

    <!-- grupo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.grupo"
          buscaIncluir
          label="grupo"
          :itemsIniciais="dataset.aux.autocomplete.grupos"
          path="/historicosPadrao/autocomplete/grupos/1/null/"
          required
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- permite baixa cruzada -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.allowBaixaCruzada"
          label="permite baixa cruzada"
        />
      </v-col>
    </v-row>

    <!-- gerarContaPagRecOposta -->
    <v-row v-bind="prop.row" class="mt-n4">
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.isGerarContaPagRecOposta"
          label="gerar conta pag/rec oposta"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CHistoricoPadraoEdit",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
    }),
  },
};
</script>