var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[(
      _vm.getTipo_item('localidadeContinente').id != _vm.dataset.main.detail.idTipo
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"itemsIniciais":_vm.dataset.aux.autocomplete.localizacao,"label":"localizada em","path":("/localidades/autocomplete/" + _vm.idTipoPai + "/"),"rules":[]},model:{value:(_vm.dataset.main.detail.idPai),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "idPai", $$v)},expression:"dataset.main.detail.idPai"}})],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"nome","max":35,"min":1,"required":""},model:{value:(_vm.dataset.main.detail.nome),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "nome", $$v)},expression:"dataset.main.detail.nome"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[(
        [
          _vm.getTipo_item('localidadePais').id,
          _vm.getTipo_item('localidadeEstado').id,
          _vm.getTipo_item('localidadeCidade').id ].includes(_vm.dataset.main.detail.idTipo)
      )?_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
          title: 'CÓDIGO',
          text: 'Código numérico da localidade (BR: IBGE/BACEN)',
          function: _vm.context.setUiBottomSheet,
        },"label":"código","max":7,"min":2},model:{value:(_vm.dataset.main.detail.codigo),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "codigo", $$v)},expression:"dataset.main.detail.codigo"}})],1):_vm._e(),(
        [
          _vm.getTipo_item('localidadePais').id,
          _vm.getTipo_item('localidadeEstado').id ].includes(_vm.dataset.main.detail.idTipo)
      )?_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
          title: 'CÓDIGO 2',
          text: 'Código de duas letras da localidade  (ISO 3166-1 alfa-2 ou ISO 3166-2)',
          function: _vm.context.setUiBottomSheet,
        },"label":"código 2","max":2,"min":2,"required":""},model:{value:(_vm.dataset.main.detail.codigo2),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "codigo2", $$v)},expression:"dataset.main.detail.codigo2"}})],1):_vm._e(),(
        [_vm.getTipo_item('localidadePais').id].includes(
          _vm.dataset.main.detail.idTipo
        )
      )?_c('v-col',_vm._b({attrs:{"cols":"4"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
          title: 'CÓDIGO 3',
          text: 'Código de três letras da localidade  (ISO 3166-1 alfa-3)',
          function: _vm.context.setUiBottomSheet,
        },"label":"código 3","max":3,"min":3,"required":""},model:{value:(_vm.dataset.main.detail.codigo3),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "codigo3", $$v)},expression:"dataset.main.detail.codigo3"}})],1):_vm._e()],1),(
      [
        _vm.getTipo_item('localidadeEstado').id,
        _vm.getTipo_item('localidadeCidade').id ].includes(_vm.dataset.main.detail.idTipo)
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"capital","required":""},model:{value:(_vm.dataset.main.detail.isCapital),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "isCapital", $$v)},expression:"dataset.main.detail.isCapital"}})],1)],1):_vm._e(),(
      [_vm.getTipo_item('localidadePais').id].includes(_vm.dataset.main.detail.idTipo)
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"configuração regional","required":""},model:{value:(_vm.dataset.main.detail.hasConfiguracaoRegional),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "hasConfiguracaoRegional", $$v)},expression:"dataset.main.detail.hasConfiguracaoRegional"}})],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"observação","max":50},model:{value:(_vm.dataset.main.detail.observacao),callback:function ($$v) {_vm.$set(_vm.dataset.main.detail, "observacao", $$v)},expression:"dataset.main.detail.observacao"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }