// COD: BJB 15/08/2022 contasContabeis
// *VER: JER 19/08/2022 contasContabeis
// /11/

export default {
  namespaced: true,

  /// STATE
  state: {
    contasContabeis: []
  },

  /// GETTERS
  getters: {
    getContasContabeis: state => (prop = "id") => {
      return state.contasContabeis
        .sort((a, b) => a[prop] < b[prop] ? -1 : 1)
        .map(element => {
          return {
            value: element.id,
            idTipo: element.idTipo,
            text: element.nomeAlt,
            nivel: element.nivel,
            idTipoDetalhe: element.idTipoDetalhe,
            isDetalheRequerido: element.isDetalheRequerido,
            allowLancamento: element.allowLancamento,
            allowLancamentoManual: element.allowLancamentoManual,
            isSaldoDevedor: element.isSaldoDevedor,
            isSaldoCredor: element.isSaldoCredor,
            options: element.options
          };
        })
    },

    getContaContabil: state => search => {
      return state.contasContabeis.length
        ? state.contasContabeis
          .find(element => element.id == search || element.nome == search)
        : { search }
    }
  },

  /// MUTATIONS
  mutations: {
    setContasContabeis: (state, payload) => {
      state.contasContabeis = []
      payload.forEach(element => {
        state.contasContabeis.push(element)
      });
    }
  },

  /// ACTIONS
  actions: {
    setContasContabeis: (context, payload) => {
      context.commit("setContasContabeis", payload)
    }
  }
}