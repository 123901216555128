var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[(_vm.produtoMarca)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v(" "+_vm._s(_vm.produtoMarca)+" ")])],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.dataset.main.detail.titulo[_vm.getVar("locale")])+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("início")])]),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dateFormat( _vm.dataset.main.detail.dataInicio, "date timeNoSecond", null, true ))+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("fim")])]),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dateFormat( _vm.dataset.main.detail.dataFim, "date timeNoSecond", null, true ))+" ")])])],1),(
      [_vm.dataset.main.detail.idPessoaParceiro, _vm.getConst("app").id].includes(
        _vm.getVar("idPessoaParceiro")
      )
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("data")])]),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dateFormat( _vm.dataset.main.detail.data, "date timeNoSecond", null, true ))+" ")])])],1):_vm._e(),(
      [_vm.dataset.main.detail.idPessoaParceiro, _vm.getConst("app").id].includes(
        _vm.getVar("idPessoaParceiro")
      )
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("recorrência")])]),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.dataset.main.detail.recorrencia)+" ")]),_c('span',{staticClass:"ml-1 text-body-2"},[_vm._v("h")])])],1):_vm._e(),(
      _vm.dataset.main.detail.hasNotificacao &&
      [_vm.dataset.main.detail.idPessoaParceiro, _vm.getConst("app").id].includes(
        _vm.getVar("idPessoaParceiro")
      )
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('v-chip',_vm._b({},'v-chip',_vm.prop.chip,false),[_vm._v("notificação")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }