var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[(
      _vm.context.datasetList.dataset.main.metas.some(
        function (meta) { return meta.idPessoaColaborador == _vm.colaborador.id; }
      )
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v("comissão")])]),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"max":9,"options":{
          length: 5,
          precision: 2,
          style: 'decimal',
          suffix: '%',
        }},model:{value:(_vm.colaborador.comissao),callback:function ($$v) {_vm.$set(_vm.colaborador, "comissao", $$v)},expression:"colaborador.comissao"}})],1)],1):_vm._e(),(
      !_vm.context.datasetList.dataset.main.metas.some(
        function (meta) { return meta.idPessoaColaborador == _vm.colaborador.id; }
      )
    )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v("peso")])]),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"max":5,"options":{
          length: 5,
          precision: 0,
          style: 'decimal',
        }},model:{value:(_vm.colaborador.peso),callback:function ($$v) {_vm.$set(_vm.colaborador, "peso", $$v)},expression:"colaborador.peso"}})],1)],1):_vm._e(),_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":true,"value":_vm.context.datasetList.dataset.main.metas.filter(
        function (meta) { return meta.idPessoaColaborador == _vm.colaborador.id; }
      )},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var meta = ref.dataset;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.metaVerba(meta.index))+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"1"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.metaPercentual(meta.index, true))+" ")])]),_c('v-col',_vm._b({attrs:{"cols":"11"}},'v-col',_vm.prop.col,false),[_c('v-progress-linear',{staticClass:"ml-2",attrs:{"color":_vm.colorProgressLinear(_vm.metaPercentual(meta.index)),"height":"6","rounded":"","value":_vm.metaPercentual(meta.index)}})],1)],1)]}},{key:"content",fn:function(ref){
      var meta = ref.dataset;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"end","cols":"6"}},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v("planejado")])]),_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"max":15,"options":{
              length: _vm.metaAux(meta.index).numberFormatLength,
              precision: _vm.metaAux(meta.index).numberFormatPrecision,
              style: _vm.metaAux(meta.index).numberFormatStyle,
              suffix: _vm.metaAux(meta.index).numberFormatSuffix,
            }},model:{value:(meta.metaPlanejada),callback:function ($$v) {_vm.$set(meta, "metaPlanejada", $$v)},expression:"meta.metaPlanejada"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }