<!-- COD: BJB 30/06/2022 CPessoaView -->
<!-- *VER: JER 30/06/2022 CPessoaView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- dataNascimento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataNascimento, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- sexo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idSexo">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ getTipo_item(dataset.main.detail.idSexo).nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- id externo -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.idExterno">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">id externo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.aux.detail.idExterno }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CPessoaView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),
  },
};
</script>