<!-- COD: BJB 19/07/2022 CTextarea -->
<!-- *VER: JER 19/07/2022 CTextarea -->
<!-- /12/ -->

<template>
  <v-textarea
    v-bind="$attrs"
    v-model="model"
    :auto-grow="autogrow"
    :class="classe"
    :clearable="clearable"
    :counter="max"
    :label="cLabel"
    :maxlength="max"
    :outlined="outlined"
    :placeholder="placeholder"
    :rows="cRows"
    :rules="
      cRules ||
      (max
        ? commonRules.common(type, required, min, max, noAccept, clean, msg)
        : [])
    "
    :type="type"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
export default {
  /// NAME
  name: "CTextarea",

  /// PROPS
  props: {
    autoGrow: { type: Boolean, default: true },
    classe: { type: String, default: "text-body-1" },
    clean: { type: String, default: "text" },
    clearable: { type: Boolean, default: true },
    cRules: { type: Array },
    label: { type: String, default: "" },
    max: { type: Number },
    maxRows: { type: Number, default: 10 },
    msg: { type: String },
    min: { type: Number },
    noAccept: { type: String },
    outlined: { type: Boolean, default: false },
    placeholder: { type: String },
    required: { type: Boolean, default: false },
    rows: { type: Number, default: 2 },
    type: { type: String, default: "text" },
    value: { type: [Number, String] },
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },

    lineLength() {
      return this.$vuetify.breakpoint.name == "xs" ? 40 : 70;
    },

    autogrow() {
      const rows = this.model ? this.model.split(/\r?\n/).length : 0;

      return (
        this.autoGrow &&
        rows < this.maxRows &&
        (this.model || "").length < this.lineLength * this.maxRows
      );
    },

    autoRows() {
      const rows = this.model
        ? this.model.length / this.lineLength + this.model.split(/\r?\n/).length
        : 0;

      return rows;
    },

    cLabel() {
      const label = this.translate(this.label);

      return `${label}${this.required ? " *" : ""}`;
    },

    cRows() {
      return this.autoGrow
        ? this.autoRows < this.maxRows
          ? Math.max(this.autoRows, this.rows)
          : this.maxRows
        : this.rows;
    },
  },

  /// WATCH
  watch: {
    value(valor) {
      this.model = valor;
    },
  },
};
</script>