<!-- COD: FDS 05/07/2022 Convite -->
<!-- *VER: JER 05/07/2022 Convite -->
<!-- /22/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
      ref="detail"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ pageModeView, page, context }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
          :params="params"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-convite-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-convite-edit v-else :context="context" :dataset="dataset" />
        </div>
        <c-convite-dialog-convite v-model="showDialog" :code="code" />
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CConviteDialogConvite from "@/components/pages/convite/dialogs/CConviteDialogConvite";
import CConviteEdit from "@/components/pages/convite/CConviteEdit";
import CConviteView from "@/components/pages/convite/CConviteView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Convite",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CConviteDialogConvite,
    CConviteEdit,
    CConviteView,
  },

  /// DIRECTIVES
  directives: { mask },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("convite").id,
      idPageAlt: getPagina("convites").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: 0,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            data: null,
            idColaborador: null,
            idPais: getVar("usuarios")
              .find(
                (usuario) => usuario.pessoa.id == getVar("idPessoaParceiro")
              )
              ?.enderecos.find(
                (endereco, i) =>
                  endereco.id == getVar("idPessoaEndereco") || i == 0
              )?.idPais,
            documentoFederal: null,
            nomeAlt: null,
            email: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
        },
        aux: {
          detail: {
            pessoaParceiro_nomeAlt: null,
            pessoaColaborador_nomeAlt: null,
            localidadePais_nome: null,
          },
        },
      },

      funcs: {
        clickCompartilhar: (context) => {
          const url = `${window.location.origin}/invite/${context.dataset.main.detail.id}`;

          context.copyToClipboard(url);

          if (navigator.share) {
            navigator
              .share({
                title: "Convite",
                text: `
                  ${this.frases[0]} 

                  ${this.frases[1]} 

                  ${this.frases[2]}`,
                url,
              })
              .then();
          } else {
            context.setSnackbar(
              true,
              "COMPARTILHAR",
              "Informações copiadas para área de transferência",
              "success"
            );
          }
        },

        clickEnviar: async (context) => {
          if (
            context.dataset.main.detail.idEstado ==
            this.getEstado("cadastro").id
          ) {
            context.dataset.main.detail.idEstado = context
              .getMaquinaEstado_transicoes(
                context.getMaquinasEstados("mePadrao").id,
                context.dataset.main.detail.idEstado
              )
              .find((estado) => estado.isDefault).value;

            await context.clickSave();
          }

          const method = "post";
          const url = `${
            context.getConst("app").baseUrlBackend
          }/convites/convidarPessoa/${context.dataset.main.detail.id}`;
          const dataset = null;
          const ret = await context.submit(method, url, dataset);

          if (ret) {
            context.setSnackbar(true, "CONVITE", ret.data);
          }
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posCancel: (context) => {
          this.buttonsAdjust(context);
          // this.panelsAdjust(context);
        },
        posSubmit: (context) => {
          if (!context.dataset.main.detail.id) {
            context.dataset.main.detail.idPais = getVar("usuarios")
              .find(
                (usuario) => usuario.pessoa.id == getVar("idPessoaParceiro")
              )
              ?.enderecos.find(
                (endereco, i) =>
                  endereco.id == getVar("idPessoaEndereco") || i == 0
              )?.idPais;
          }
        },

        qrcodeTimeout: () => {
          this.code = `${window.location.origin}/invite/${this.dataset.main.detail.id}`;
          this.showDialog = true;
        },
      },

      params: {
        context: null,
      },

      const_idTipoDocumento: getTipo_item("documentoPFFederal").id,
      code: "",
      forceUpdate: false,
      frases: [
        `Olá, você já conhece o ${getConst("app").nome}?`,
        `O ${
          getConst("app").nome
        } é um aplicativo de compras para aproximar os moradores do bairro ao comércio local.`,
        "Clique no link e saiba mais 👇",
        "Aponte a camera para o qrCode 👇",
      ],
      showDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getLocalidades: "localidades/getLocalidades",
      getMaquinasEstados: "estados/getMaquinasEstados",
      getTipo: "tipos/getTipo",
      getTipo_item: "tipos/getTipo_item",
    }),

    documentoDigitos() {
      if (
        this.dataset.main.detail.idPais ==
        this.getConst("localidades").paises.brasil.id
      ) {
        return this.getTipo_item("documentoPFFederal").options.length;
      } else {
        return this.getTipo("tipoDocumento").options.max;
      }
    },

    documentoMask() {
      if (
        this.dataset.main.detail.idPais ==
        this.getConst("localidades").paises.brasil.id
      ) {
        return "###.###.###-##";
      } else {
        return "####################";
      }
    },
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }

    if (!this.$route.params.id) {
      if (this.$refs.detail && this.$refs.detail.tipos.length == 1) {
        this.dataset.main.detail.idTipo = this.$refs.detail.tipos[0].value;
      }
    }
  },

  /// METHODS
  methods: {
    buttonsAdjust(context) {
      const idsFeature = [];
      const nome =
        context.dataset.main.detail.idTipo ==
        this.getTipo_item("conviteColaborador").id
          ? "optionComentar"
          : "optionEnviar";

      idsFeature.push(this.getFeature(nome).id);

      if (
        context.dataset.main.detail.idTipo ==
        this.getTipo_item("conviteCliente").id
      ) {
        idsFeature.push(this.getFeature("optionAlterar").id);
      }

      idsFeature.forEach((idFeature) => {
        const index = context.buttons.findIndex(
          (button) => button.idFeature == idFeature
        );

        if (index >= 0) {
          context.buttons.splice(index, 1);
        }
      });
    },

    panelsAdjust(context) {
      const idsFeature = [];

      const tipos = [
        this.getTipo_item("conviteAmigo").id,
        this.getTipo_item("conviteCliente").id,
      ];

      if (tipos.includes(context.dataset.main.detail.idTipo)) {
        idsFeature.push(this.getFeature("CPanelObservacoes").id);
      }

      idsFeature.forEach((idFeature) => {
        const index = context.panels.findIndex(
          (panel) => panel.idFeature == idFeature
        );

        if (index >= 0) {
          context.panels.splice(index, 1);
        }
      });
    },

    setForceUpdate() {
      this.forceUpdate = false;
      this.$forceUpdate();
    },
  },
};
</script>