<!-- COD: LYF 04/07/2022 CFornecedorClienteView -->
<!-- *VER: JER 05/07/2022 CFornecedorClienteView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- inadimplente -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.aux.detail.isInadimplente"
      class="mb-3"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn classe="red white--text" rounded @click="clickInadimplente()">
          INADIMPLENTE
        </c-btn>
      </v-col>
    </v-row>

    <!-- classificacao fornecedor -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.idClassificacaoFornecedor"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{
            getTipo_item(dataset.main.detail.idClassificacaoFornecedor).nomeAlt
          }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- pais (bandeira) -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.pessoaEndereco_idPais">
      <v-col v-bind="prop.col" align="center">
        <v-img
          :src="
            getLocalidade(dataset.aux.detail.pessoaEndereco_idPais).endereco
          "
          width="30"
        />
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn class="text-body-1" color="primary" text @click="clickPessoa">
          {{ dataset.aux.detail.pessoa_nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- pessoa nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoaNomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- data desde -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.dataDesde">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">
          {{ labelDataDesde }}
        </span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataDesde, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- id no fornecedor/cliente -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idNoFornecedorCliente">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">
          {{ labelIdNoFornecedorCliente }}
        </span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.idNoFornecedorCliente }}
        </span>
      </v-col>
    </v-row>

    <!-- id externo -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.idExterno">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">id externo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.aux.detail.idExterno }}
        </span>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.observacao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">({{ dataset.main.detail.observacao }})</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFornecedorClienteView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
      getFeature: "paginas/getFeature",
    }),

    labelDataDesde() {
      return `${
        this.getTipo_item(this.dataset.main.detail.idTipo).nomeAlt
      } desde`;
    },

    labelIdNoFornecedorCliente() {
      return `id no ${
        this.getTipo_item(this.dataset.main.detail.idTipo).nomeAlt
      }`;
    },
  },

  /// METHODS
  methods: {
    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
    },

    clickInadimplente() {
      const route = {
        path: "/contasPagRec",
        query: {
          nome: this.dataset.aux.detail.pessoa_nome,
        },
      };
      route.query["idPessoa"] = this.dataset.main.detail.idPessoa;

      this.$router.push(route);
    },
  },
};
</script>