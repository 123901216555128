var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-1 mx-0"},[('titulo' in _vm.documento.options.idioma[_vm.locale])?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"max":80,"placeholder":"título","prepend-inner-icon":"mdi-translate"},model:{value:(_vm.documento.options.idioma[_vm.locale].titulo),callback:function ($$v) {_vm.$set(_vm.documento.options.idioma[_vm.locale], "titulo", $$v)},expression:"documento.options.idioma[locale].titulo"}})],1)],1):_vm._e(),('texto' in _vm.documento.options.idioma[_vm.locale])?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-richtext',{attrs:{"tipoToolbar":"2"},model:{value:(_vm.documento.options.idioma[_vm.locale].texto),callback:function ($$v) {_vm.$set(_vm.documento.options.idioma[_vm.locale], "texto", $$v)},expression:"documento.options.idioma[locale].texto"}})],1)],1):_vm._e(),('videos' in _vm.documento.options)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"buscaIncluir":"","itemsIniciais":_vm.documento.options.videos.map(function (video) {
              return {
                value: video,
                text: video,
              };
            }) || [],"label":"vídeos","multiple":"","rules":[],"searchLength":1},model:{value:(_vm.documento.options.videos),callback:function ($$v) {_vm.$set(_vm.documento.options, "videos", $$v)},expression:"documento.options.videos"}})],1)],1):_vm._e(),_c('v-row',_vm._b({staticClass:"my-4 grey lighten-2 px-1 rounded-lg",attrs:{"id":"botoes"}},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"help":{
            title: 'TRADUÇÃO',
            text: "Se for necessário tradução, após configurar o texto em um idioma:\n• copie o texto (botão copiar),\n• utilize um tradutor,\n• selecione o novo idioma (bandeira),\n• cole o texto traduzido (botão colar).\n\nNOTA: ':::' é um separador, sendo que a parte:\n• acima do separador é o TÍTULO,\n• abaixo é o TEXTO.\n",
            function: _vm.setUiBottomSheet,
          },"isHelp":""}})],1),_c('v-col',_vm._b({attrs:{"cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-base-idiomas',{attrs:{"classe":"ml-1","idiomas":_vm.idiomas,"noText":""},model:{value:(_vm.idLocale),callback:function ($$v) {_vm.idLocale=$$v},expression:"idLocale"}})],1),_c('v-col',_vm._b({attrs:{"align":"center","cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-tooltip',{attrs:{"texto":"texto copiado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var on = ref.on;
return [_c('c-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clickCopy(on, $event)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])})],1),_c('v-col',_vm._b({attrs:{"align":"center","cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-tooltip',{attrs:{"texto":"texto colado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var on = ref.on;
return [_c('c-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clickPaste(on, $event)}}},[_c('v-icon',[_vm._v("mdi-content-paste")])],1)]}}])})],1),_c('v-col',_vm._b({attrs:{"align":"center","cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('clickEditView', false)}}},[_c('v-icon',{attrs:{"color":"red accent-3"}},[_vm._v("mdi-close")])],1)],1),_c('v-col',_vm._b({attrs:{"align":"center","cols":"2"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"icon":""},on:{"click":_vm.submit}},[_c('v-icon',{attrs:{"color":"green accent-3"}},[_vm._v("mdi-check")])],1)],1)],1),_c('c-snackbar',{attrs:{"snackbar":_vm.snackbar},on:{"click":function($event){return _vm.clickSnackbar($event)}}}),_c('c-bottom-sheet',{attrs:{"bottomSheet":_vm.bottomSheet}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }