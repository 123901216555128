<!-- COD: LYF 22/09/2022 CExtratoPageConciliacaoView -->
<!-- #VER: JER __/09/2022 CExtratoPageConciliacaoView -->

<template>
  <v-container v-bind="prop.container">
    <div
      v-for="(objeto_filter, i) in objeto_filter(
        objeto.data || objeto.dataEmissao,
        objeto.idLancamento || objeto.idValorPagRec,
        isLancamento
      )"
      :key="`objeto${i}`"
    >
      <!-- numero documento -->
      <v-row
        v-bind="prop.row"
        v-if="
          objeto_filter.numeroDocumento && objeto_filter.numeroDocumento != '/'
        "
      >
        <v-col v-bind="prop.col">
          <span class="text-body-2">número</span>
          <span class="text-body-1">
            {{ objeto_filter.numeroDocumento }}
          </span>
        </v-col>
      </v-row>

      <!-- identificador -->
      <v-row
        v-bind="prop.row"
        v-if="objeto_filter.historico && objeto_filter.identificador"
      >
        <v-col v-bind="prop.col">
          <span class="text-body-1">
            {{ objeto_filter.identificador }}
          </span>
        </v-col>
      </v-row>

      <!-- canal -->
      <v-row v-bind="prop.row" v-if="objeto_filter.canal">
        <v-col v-bind="prop.col">
          <span class="text-body-1">
            {{ objeto_filter.canal }}
          </span>
        </v-col>
      </v-row>

      <!-- pessoa -->
      <v-row v-bind="prop.row" v-if="objeto_filter.pessoa">
        <v-col v-bind="prop.col">
          <span class="text-body-1">
            {{ objeto_filter.pessoa }}
          </span>
        </v-col>
      </v-row>

      <!-- identification -->
      <c-base-item-identification
        v-if="objeto_filter.estado"
        :dataset="objeto_filter"
      />
    </div>
  </v-container>
</template>

<script>
/// IMPORT
import CBaseItemIdentification from "@/components/bases/CBaseItemIdentification";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CExtratoPageConciliacaoView",

  /// COMPONENTS
  components: { CBaseItemIdentification },

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    dataset: { type: Object, required: true },
    isLancamento: { type: Boolean, default: false },
    objeto: { type: Object, required: true },
    params: {},
  },

  /// METHODS
  methods: {
    objeto_filter(data, id, isLancamento) {
      let objeto;

      if (isLancamento) {
        objeto = this.dataset.main.lancamentos
          .map((lancamento, i) => {
            return {
              ...lancamento,
              index: i,
            };
          })
          .filter(
            (lancamento) => lancamento.data == data && lancamento.id == id
          )
          .sort((a, b) => (a.id < b.id ? -1 : 1));
      } else {
        objeto = this.dataset.aux.valoresPagRec
          .map((valorPagRec, i) => {
            return {
              ...valorPagRec,
              index: i,
            };
          })
          .filter(
            (valorPagRec) =>
              valorPagRec.dataEmissao == data && valorPagRec.id == id
          )
          .sort((a, b) => (a.id < b.id ? -1 : 1));
      }

      return objeto;
    },
  },
};
</script>