<!-- COD: LYF 08/08/2022 Notificacao -->
<!-- *VER: JER 10/08/2022 Notificacao -->
<!-- /12/ -->

<template>
  <v-card>
    <!-- NAVBAR -->
    <c-the-navbar mode="page" title="Notificação" />

    <!-- /// DETAIL -->
    <v-card-text>
      <v-form id="formDetail" class="px-0" ref="form">
        <!-- /// MODEINSERT -->
        <!-- titulo -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-text-field
              v-model.trim="notificacao.notificacao.titulo"
              label="título"
              :max="100"
              :min="5"
            />
          </v-col>
        </v-row>

        <!-- descricao -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-textarea
              v-model.trim="notificacao.notificacao.descricao"
              auto-grow
              clearable
              :cRules="rules.inputMin5"
              label="descrição"
              :max="200"
              placeholder="descrição da notificação"
            />
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <!-- data inicio -->
          <v-col v-bind="prop.col" cols="6">
            <c-datetime-picker
              v-if="notificacao.notificacao.dataInicio"
              v-model="notificacao.notificacao.dataInicio"
              date
              label="início"
              time
            />
          </v-col>
          <!-- data fim -->
          <v-col v-bind="prop.col" cols="6">
            <c-datetime-picker
              v-if="notificacao.notificacao.dataFim"
              v-model="notificacao.notificacao.dataFim"
              date
              label="fim"
              time
            />
          </v-col>
        </v-row>

        <!-- endereco -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-text-field
              v-model.trim="notificacao.notificacao.endereco"
              :cRules="rules.inputUrl"
              label="endereço"
              :max="200"
              :min="5"
            />
          </v-col>
        </v-row>

        <!-- destaque -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="notificacao.notificacao.isDestaque"
              inset
              label="destaque"
            />
          </v-col>
        </v-row>

        <!-- broadcast -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col">
            <c-switch
              v-model="notificacao.notificacao.isBroadcast"
              label="broadcast"
              inset
              @click="clickBroadcast"
            />
          </v-col>
        </v-row>

        <!-- usuarios -->
        <v-row v-bind="prop.row" v-if="!notificacao.notificacao.isBroadcast">
          <v-col v-bind="prop.col">
            <c-autocomplete
              v-model="notificacao.usuarios"
              label="usuários"
              :itemsIniciais="usuarios"
              multiple
              :path="`/pessoas/autocomplete/usuario/${
                getConst('localidades').paises.brasil.id
              }/`"
              :rules="rules.usuarios"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <c-the-navbar bottom mode="page" :slots="buttonsBar.length" title="">
      <template v-for="(button, i) in buttonsBar" v-slot:[i]>
        <c-btn
          v-if="!Array.isArray(button)"
          icon
          :key="i"
          @click="button.clickFunction(button)"
        >
          <v-icon :color="button.options.color || 'white'" large>
            {{ button.options.icon }}
          </v-icon>
        </c-btn>
      </template>
    </c-the-navbar>
  </v-card>
</template>

<script>
/// IMPORT
import { addDays } from "date-fns";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "Notificacao",

  /// COMPONENTS
  components: {
    CTheNavbar,
  },

  /// MIXINS
  mixins: [mixinSnackbar],

  /// HEAD
  head: {
    title() {
      return {
        inner: this.getPagina("notificacao").nomeAlt,
      };
    },
  },

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      modeView: true,
      notificacao: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        notificacao: {
          id: null,
          idTipo: getTipo_item("notificacao").id,
          titulo: "",
          descricao: "",
          data: null,
          dataInicio: new Date().toISOString(),
          dataFim: addDays(new Date(), 7).toISOString(),
          idTabela: null,
          idRegistro: null,
          endereco: "",
          isDestaque: false,
          requireConfirmacao: false,
          idPessoaParceiro: null,
          idUsuario: null,
          isBroadcast: false,
        },
        usuarios: [],
      },
      notificacaoTemp: null,
      rules: {
        date: [(value) => (!!value && value.length == 16) || "campo requerido"],
        inputMin5: [
          (value) => (!!value && value.length >= 5) || "mínimo 5 caracteres",
        ],
        inputUrl: [
          (value) =>
            !value ||
            value.length == 0 ||
            value.length >= 5 ||
            "vazio ou mínimo 5 caracteres",
          (value) =>
            !value ||
            !!value.match(
              /^(https?:\/\/)?([a-z0-9\-_]{2,26}\.)?([a-z0-9][a-z0-9-]{0,24}[a-z0-9])(\.[a-z]{2,6})(\.[a-z]{2})?$/g
            ) ||
            "endereço inválido",
        ],
        select: [(value) => !!value || "campo requerido"],
        usuarios: [
          (value) => !!value.length || "selecione ao menos um usuário",
        ],
      },
      usuarios: [],
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getPagina: "paginas/getPagina",
      getVar: "variaveis/getVar",
    }),

    buttonsBar() {
      let ret = [];

      if (!this.modeView) {
        ret.push({
          clickFunction: this.submit,
          options: {
            icon: "mdi-check",
            color: "green accent-3",
          },
        });
      }

      return ret;
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    clickBroadcast() {
      if (this.notificacao.notificacao.isBroadcast) {
        this.notificacao.usuarios = [];
      }
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.setVar({ overlay: true });
        this.notificacao.metadados.idUsuario = this.getVar("idUsuario");
        this.notificacao.metadados.idPessoaParceiro =
          this.getVar("idPessoaParceiro");

        const url = `${this.getConst("app").baseUrlBackend}/notificacoes`;

        axios
          .post(url, this.notificacao)
          .then(() => {
            this.$refs.form.resetValidation();
            this.modeView = true;
            this.$router.push({ path: "/notificacoes" });
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          })
          .finally(() => {
            this.setVar({ overlay: false });
          });
      } else {
        this.setSnackbar(
          true,
          "CAMPOS INVÁLIDOS",
          "Verificar campos inválidos.",
          "error"
        );
      }
    },
  },

  /// MOUNTED
  mounted() {
    this.modeView = false;
  },
};
</script>