<!-- COD: LYF 01/07/2022 CValoresPagRec -->
<!-- *VER: JER 03/07/2022 CValoresPagRec -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- formaPagamento -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.idFormaPagamento).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- dataEmissao -->
      <v-col v-bind="prop.col" cols="5">
        <span :class="`font-weight-medium text-body-2`">emis</span>
        <span :class="`ml-1 text-h6`">
          {{ dateFormat(dataset.dataEmissao, "date") }}
        </span>
      </v-col>
      <!-- valor -->
      <v-col v-bind="prop.col" align="end" cols="7">
        <span class="font-weight-medium text-body-2">valor</span>
        <span class="ml-1 text-h6">
          {{ numberFormat(dataset.valor, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data apropriacao -->
      <v-col v-bind="prop.col" cols="5">
        <span class="text-body-2">aprop</span>
        <span class="text-body-1">
          {{ dateFormat(dataset.dataApropriacao, "date") }}
        </span>
      </v-col>
      <!-- saldo associar -->
      <v-col v-bind="prop.col" align="end" cols="7">
        <span class="text-body-2">associar</span>
        <span class="ml-1 text-body-1">
          {{ numberFormat(dataset.saldoAssociar, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- numero documento -->
      <v-col v-bind="prop.col" v-if="dataset.numeroDocumento" cols="7">
        <span class="text-body-2">documento</span>
        <span class="ml-1 text-body-1">
          {{ dataset.numeroDocumento }}
        </span>
      </v-col>
      <!-- motivo devolucao -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.motivoDevolucao"
        align="end"
        cols="5"
      >
        <span class="text-body-2">motivo</span>
        <span class="ml-1 text-body-1">
          {{ dataset.motivoDevolucao }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.pessoa_nome }}
        </span>
      </v-col>
    </v-row>

    <!-- parceiro conta bancaria -->
    <v-row v-bind="prop.row" v-if="pessoaParceiro_bancoAgenciaConta">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ pessoaParceiro_bancoAgenciaConta }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CValoresPagRec",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    pessoaParceiro_bancoAgenciaConta() {
      return this.dataset.pessoaParceiro_banco_codigo
        ? `${this.dataset.pessoaParceiro_banco_codigo}/${this.dataset.pessoaParceiro_agencia}/${this.dataset.pessoaParceiro_conta}`
        : null;
    },
  },
};
</script>