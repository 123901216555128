<!-- COD: FDS 04/07/2022 CHistoricoPadraoView -->
<!-- *VER: JER 05/07/2022 CHistoricoPadraoView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- grupo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.grupo">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ dataset.main.detail.grupo }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nome">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.nome }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nomeAlt">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeComplementar -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.nomeComplementar">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.nomeComplementar }}
        </span>
      </v-col>
    </v-row>

    <!-- permiteBaixaCruzada -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.allowBaixaCruzada">
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          permite baixa cruzada
        </v-chip>
      </v-col>
    </v-row>

    <!-- gerarContaPagRecOposta -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.main.detail.isGerarContaPagRecOposta"
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip class="grey text-body-1" outlined x-small>
          gerar conta pag/rec oposta
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CHistoricoPadraoView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>