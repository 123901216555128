var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"mb-10 pa-0",attrs:{"id":"formSelect"}},[(_vm.idPage && _vm.idPageAlt)?_c('c-base-list',{attrs:{"funcs":_vm.funcs,"idFeature":_vm.idFeature,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"pageModeEdit":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var context = ref.context;
return [_c('v-container',[(!_vm.reloadLocalizacoes)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"arraySearch":_vm.localizacoes,"buscaIncluir":"","itemsIniciais":_vm.localizacaoInicial,"label":"localização","rules":[],"searchLength":2},on:{"change":function($event){return _vm.changeLocalizacao(context)}},model:{value:(_vm.localizacao),callback:function ($$v) {_vm.localizacao=$$v},expression:"localizacao"}})],1)],1):_vm._e(),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-autocomplete',{attrs:{"itemsIniciais":[],"label":"produto","path":("/produtos/autocomplete/produtos/" + (_vm.getVar(
                'idPessoaParceiro'
              )) + "/" + (_vm.getVar('locale')) + "/0/"),"returnObject":"","rules":[],"searchLength":2},model:{value:(_vm.produto),callback:function ($$v) {_vm.produto=$$v},expression:"produto"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-btn',{staticClass:"primary--text",attrs:{"disabled":!_vm.localizacao || !_vm.produto,"text":""},on:{"click":function($event){return _vm.clickIncluir(context)}}},[_vm._v(" incluir ")])],1)],1)],1)]}},{key:"listContent",fn:function(ref){
              var context = ref.context;
              var datasets = ref.datasets;
return [_c('c-expansion-panels',{attrs:{"flat":"","pageModeView":false,"value":datasets},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var produto = ref.dataset;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.produtoFormat(produto, false))+" ")])])],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-2"},[_vm._v("inventário")]),_c('span',{staticClass:"ml-0 text-body-1"},[_vm._v(" "+_vm._s(parseFloat(produto.caixas) * produto.unidadesPorCaixa + parseFloat(produto.unidades) || "0")+" ")])])],1)]}},{key:"content",fn:function(ref){
              var produto = ref.dataset;
return [(!_vm.pageModeView)?_c('c-produto-page-inventario-edit',{attrs:{"context":context,"dataset":datasets,"produto":produto,"showColaboradores":_vm.showColaboradores}}):_vm._e()]}}],null,true)})]}},{key:"search",fn:function(ref){
              var context = ref.context;
return [_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"mostrar só localizações inventáriadas"},on:{"click":function($event){return _vm.clickInventariadas(context)}},model:{value:(_vm.inventariadas),callback:function ($$v) {_vm.inventariadas=$$v},expression:"inventariadas"}})],1)],1),(_vm.parceiro.isInventario)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-switch',{attrs:{"label":"mostrar só localizações pendentes"},on:{"click":function($event){return _vm.clickPendentes(context)}},model:{value:(_vm.pendentes),callback:function ($$v) {_vm.pendentes=$$v},expression:"pendentes"}})],1)],1):_vm._e(),(!_vm.inventariadas && _vm.localizacao)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[(context.datasets.length > 0 && _vm.idDirecao)?_c('c-btn',{attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.clickArrow(context)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.iconArrow)+" ")])],1):_vm._e()],1)],1):_vm._e()]}}],null,false,1371794531)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }