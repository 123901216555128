var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"380px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"380"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.clickCloseDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("ALTERAR SENHA")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.clickAlterarSenha}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-form',{ref:"form",staticClass:"px-0"},[(_vm.isChangeSenha)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"senha atual","max":20,"required":"","type":"password"},model:{value:(_vm.dataset.main.senhas[0].atual),callback:function ($$v) {_vm.$set(_vm.dataset.main.senhas[0], "atual", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"dataset.main.senhas[0].atual"}})],1)],1)],1):(!_vm.isCodigoVerificacaoValido)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-tooltip',{attrs:{"close-delay":3000,"texto":"tempo expirado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.timerLeft)+" s "+_vm._s(_vm.loadTimer(on))+" ")])]}}])})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-tooltip',{attrs:{"close-delay":3000,"texto":"código de verificação inválido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-otp-input',{attrs:{"type":"number"},on:{"change":function($event){return _vm.changeCodigoVerificacao(on)}},model:{value:(_vm.codigoVerificacao),callback:function ($$v) {_vm.codigoVerificacao=$$v},expression:"codigoVerificacao"}})]}}])})],1)],1),_c('v-row',_vm._b({staticClass:"mt-n4 ml-n3",attrs:{"justify":"center"}},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"8"}},'v-col',_vm.prop.col,false),[_c('c-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.clickNaoRecebiCodigo()}}},[_vm._v(" não recebi o código ")])],1)],1)],1):_vm._e(),(_vm.isChangeSenha || _vm.isCodigoVerificacaoValido)?_c('div',[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"help":{
                  title: 'SENHA',
                  text: 'A senha deve ter de 6 a 20 caracteres, com ao menos um maíusculo, um minúsculo e um número.',
                  function: _vm.setUiBottomSheet,
                },"label":"nova senha","max":20,"required":"","rules":_vm.rules.senhaNova,"type":"password"},model:{value:(_vm.dataset.main.senhas[0].senha),callback:function ($$v) {_vm.$set(_vm.dataset.main.senhas[0], "senha", $$v)},expression:"dataset.main.senhas[0].senha"}})],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"6"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"label":"confirmar senha","max":20,"required":"","rules":_vm.rules.senhaConfirmar,"type":"password"},model:{value:(_vm.dataset.main.senhas[0].confirmar),callback:function ($$v) {_vm.$set(_vm.dataset.main.senhas[0], "confirmar", $$v)},expression:"dataset.main.senhas[0].confirmar"}})],1)],1)],1):_vm._e()])],1)],1),_c('c-snackbar',{attrs:{"snackbar":_vm.snackbar},on:{"click":function($event){return _vm.clickSnackbar($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }