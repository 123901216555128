<!-- COD: BJB 19/04/2023 CBasePreco -->
<!-- VER: JER __/04/2023 CBasePreco -->
<!-- /11/ -->

<template>
  <div class="ma-0 pa-0" style="width: 100%">
    <v-container class="pa-0">
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col" :align="align">
          <!-- preco -->
          <c-btn
            classe="px-3  mx-2 text-body-2"
            :color="color"
            :help="{
              element: extrato,
              function: clickExtrato,
              hasHelp: !!extrato,
              offsetX: '9',
              offsetY: '5',
            }"
            small
            @click.stop="$emit('clickComprar', $event)"
          >
            <span>
              {{ numberFormat(parseFloat(preco), "currency", decimalValor) }}
            </span>
            <span
              v-if="idUnidadeMedida != getTipo_item('unidadeUnidade').id"
              class="mt-2 text-lowercase"
            >
              /{{ getTipo_item(idUnidadeMedida).codigo }}
            </span>
          </c-btn>
        </v-col>
      </v-row>
    </v-container>

    <c-base-dialog-extrato
      v-if="showExtrato"
      :configuracao="configuracaoExtrato"
      :produto="produto"
      :extrato="extrato"
      :show="showExtrato"
      @close="showExtrato = false"
    />
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import CBaseDialogExtrato from "@/components/bases/CBaseDialogExtrato";

export default {
  /// NAME
  name: "CBasePreco",

  /// COMPONENTS
  components: {
    CBaseDialogExtrato,
  },

  props: {
    align: { type: String, default: "start" },
    color: { type: String, required: true },
    decimalValor: { type: Number, default: 2 },
    produto: { type: Object, required: false },
    extrato: { type: Array, required: false },
    idLocalidade: { type: Number, required: false },
    idUnidadeMedida: { type: Number, required: true },
    preco: { type: [Number, String], required: true },
  },

  /// DATA
  data() {
    return {
      configuracaoExtrato: null,
      showExtrato: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
      getTipo_item: "tipos/getTipo_item",
    }),
  },

  /// METHODS
  methods: {
    clickExtrato() {
      this.showExtrato = true;

      this.configuracaoExtrato = {
        prazo: 0,
        localidade: this.getLocalidade(this.idLocalidade).codigo2,
      };
    },
  },
};
</script>