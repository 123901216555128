<!-- COD: FDS 08/05/2023 CLoteEdit -->
<!-- #VER: JER __/05/2023 CLotesEdit -->
<!-- /11/ -->


<template>
  <v-container v-bind="prop.container">
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="40"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data inicio -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataInicio"
          date
          label="data início"
          :minValue="format(new Date(), `yyyy-MM-dd HH:mm`)"
          required
        />
      </v-col>
      <!-- data fim -->
      <v-col v-bind="prop.col" cols="6">
        <c-datetime-picker
          v-model="dataset.main.detail.dataFim"
          date
          :disabled="!dataset.main.detail.dataInicio"
          label="data fim"
          :minValue="dataset.main.detail.dataInicio || new Date().toISOString()"
        />
      </v-col>
    </v-row>

    <!-- observação -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.observacao"
          label="observação"
          :max="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLoteEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },
};
</script>