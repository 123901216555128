<!-- COD: LYF 30/06/2022 CBaseDialogSenha -->
<!-- *VER: JER 10/08/2022 CBaseDialogSenha -->
<!-- /21/ -->

<template>
  <v-dialog scrollable persistent max-width="380px" v-model="show">
    <!-- /// ALTERAR SENHA -->
    <v-card class="mx-auto" max-width="380">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-btn icon @click="clickCloseDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>ALTERAR SENHA</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="clickAlterarSenha">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-8">
        <v-form class="px-0" ref="form">
          <div v-if="isChangeSenha">
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col">
                <!-- senha atual -->
                <c-text-field
                  v-model.trim="dataset.main.senhas[0].atual"
                  label="senha atual"
                  :max="20"
                  required
                  type="password"
                />
              </v-col>
            </v-row>
          </div>

          <div v-else-if="!isCodigoVerificacaoValido">
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="center">
                <c-tooltip :close-delay="3000" texto="tempo expirado">
                  <template v-slot="{ on }">
                    <span class="text-caption">
                      {{ timerLeft }} s {{ loadTimer(on) }}
                    </span>
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" align="center">
                <!-- codigo verificacao -->
                <c-tooltip
                  :close-delay="3000"
                  texto="código de verificação inválido"
                >
                  <template v-slot="{ on }">
                    <v-otp-input
                      v-model="codigoVerificacao"
                      type="number"
                      @change="changeCodigoVerificacao(on)"
                    />
                  </template>
                </c-tooltip>
              </v-col>
            </v-row>

            <v-row v-bind="prop.row" class="mt-n4 ml-n3" justify="center">
              <v-col v-bind="prop.col" cols="8">
                <c-btn color="primary" text @click="clickNaoRecebiCodigo()">
                  não recebi o código
                </c-btn>
              </v-col>
            </v-row>
          </div>

          <div v-if="isChangeSenha || isCodigoVerificacaoValido">
            <!-- senha -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" cols="6">
                <c-text-field
                  v-model="dataset.main.senhas[0].senha"
                  :help="{
                    title: 'SENHA',
                    text: 'A senha deve ter de 6 a 20 caracteres, com ao menos um maíusculo, um minúsculo e um número.',
                    function: setUiBottomSheet,
                  }"
                  label="nova senha"
                  :max="20"
                  required
                  :rules="rules.senhaNova"
                  type="password"
                />
              </v-col>
            </v-row>

            <!-- confirmar -->
            <v-row v-bind="prop.row">
              <v-col v-bind="prop.col" cols="6">
                <c-text-field
                  v-model="dataset.main.senhas[0].confirmar"
                  label="confirmar senha"
                  :max="20"
                  required
                  :rules="rules.senhaConfirmar"
                  type="password"
                />
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- /// SNACKBAR -->
    <c-snackbar @click="clickSnackbar($event)" :snackbar="snackbar" />
  </v-dialog>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import axios from "axios";
import mixinBottomSheet from "@/mixins/mixinBottomSheet";
import mixinSnackbar from "@/mixins/mixinSnackbar";

export default {
  /// NAME
  name: "CBaseDialogSenha",

  /// DIRETIVES
  directives: { mask },

  /// MIXINS
  mixins: [mixinBottomSheet, mixinSnackbar],

  /// PROPS
  props: {
    idPessoa: Number,
    objetoCodigo: Object,
    show: Boolean,
    context: Object,
  },

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      codigoVerificacao: null,
      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: this.idPessoa,
          },
          senhas: [
            {
              idTipo: getTipo_item("senhaApp").id,
              atual: null,
              senha: null,
              confirmar: null,
            },
          ],
        },
      },
      isCodigoVerificacaoValido: false,
      loadTimerOn: null,
      rules: {
        codigoVerificacao: [
          (value) => (!!value && value.length == 6) || "6 carac",
        ],
        senhaConfirmar: [
          (value) =>
            (!!value && value == this.dataset.main.senhas[0].senha) ||
            "senha não confere",
        ],
        senhaNova: [
          (value) => (!!value && value.length >= 6) || "min 6 carac",
          (value) =>
            (!!value &&
              !!value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,20}$/g)) ||
            "senha inválida",
          (value) =>
            (!!value && value != this.dataset.main.senhas[0].atual) ||
            "senha já utilizada",
        ],
      },
      intervalTimer: null,
      timer: 60,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getVar: "variaveis/getVar",
    }),

    isChangeSenha() {
      return (
        this.getVar("idPessoaParceiro") || this.getVar("isSenhaProvisoria")
      );
    },

    timerLeft() {
      return this.timer;
    },
  },

  /// MOUNTED
  mounted() {
    this.setIntervalTimer();
  },

  /// WATCH
  watch: {
    show() {
      if (!this.show) {
        this.$refs.form.resetValidation();
        this.pessoaReset();
      }
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    async changeCodigoVerificacao(on) {
      if (this.codigoVerificacao && this.codigoVerificacao.length == 6) {
        try {
          const url = `${
            this.getConst("app").baseUrlBackend
          }/pessoas/tokenValidate`;

          const objeto = {
            token: this.objetoCodigo.token,
            codigoVerificacao: this.codigoVerificacao,
          };

          await axios.post(url, objeto);

          this.codigoVerificacao = null;
          this.isCodigoVerificacaoValido = true;
        } catch (err) {
          this.codigoVerificacao = null;
          this.clickOnTooltip(on, { stopPropagation: () => {} });
        }
      }
    },

    async clickAlterarSenha() {
      if (this.$refs.form.validate()) {
        this.setVar({ overlay: true });
        this.dataset.metadados.idUsuario = this.getVar("isSenhaProvisoria")
          ? this.dataset.main.detail.id
          : this.getVar("idUsuario");
        this.dataset.metadados.idPessoaParceiro = this.getVar(
          "isSenhaProvisoria"
        )
          ? this.dataset.main.detail.id
          : this.getVar("idPessoaParceiro");

        const url = `${this.getConst("app").baseUrlBackend}/pessoas`;

        axios
          .put(url, this.dataset)
          .then(() => {
            clearInterval(this.intervalTimer);
            this.intervalTimer = null;
            this.pessoaReset();
            this.$emit("alterado", this.context);
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          })
          .finally(() => {
            this.setVar({ overlay: false });
          });
      }
    },

    clickCloseDialog() {
      clearInterval(this.intervalTimer);
      this.intervalTimer = null;
      this.$emit("close");
    },

    async clickNaoRecebiCodigo() {
      this.isCodigoVerificacaoValido = false;
      this.codigoVerificacao = null;
      this.loadTimerOn = null;

      await this.esqueciSenha();
    },

    async esqueciSenha() {
      this.setVar({ overlay: true });
      const url = `${this.getConst("app").baseUrlBackend}/esqueciSenha`;

      await axios
        .post(url, this.objetoCodigo.email)
        .then((res) => {
          this.setSnackbar(true, "SENHA", res.data.mensagem);
          this.objetoCodigo.token = res.data.token;
          this.timer = 60;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
        });
    },

    loadTimer(on) {
      this.loadTimerOn = on;
    },

    pessoaReset() {
      this.dataset.main.senhas[0].atual = null;
      this.dataset.main.senhas[0].senha = null;
      this.dataset.main.senhas[0].confirmar = null;
    },

    async setIntervalTimer() {
      if (!this.show) {
        return;
      }

      //? 1000 duro
      this.intervalTimer = setInterval(async () => {
        if (this.timer != 0) {
          this.timer = this.timer - 1;
        } else {
          clearInterval(this.intervalTimer);
          this.intervalTimer = null;

          if (!this.isCodigoVerificacaoValido && this.show) {
            await this.clickNaoRecebiCodigo();
            this.setIntervalTimer();
          }
        }
      }, 1000);
    },
  },
};
</script>