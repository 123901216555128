<!-- COD: BJB 18/07/2022 CRichtext -->
<!-- *VER: JER 18/07/2022 CRichtext -->
<!-- /12/ -->

<template>
  <vue-editor v-model="model" :editor-toolbar="editorToolbar[tipoToolbar]" />
  <!-- :editor-config="editorConfig" -->
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  /// NAME
  name: "CRichtext",

  /// COMPONENTS
  components: { VueEditor },

  /// PROPS
  props: {
    tipoToolbar: { type: [Number, String], default: 0 },
    value: { type: String },
  },

  /// DATA
  data() {
    return {
      editorToolbar: [
        // 0 - simples
        [
          // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          // [{ header: 1 }, { header: 2 }],
          // [{ font: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ script: "sub" }, { script: "super" }],
          // ["blockquote", "code-block"],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          [
            { list: "ordered" },
            { list: "bullet" },
            /*, { list: "check" } */
          ],
          [{ indent: "-1" }, { indent: "+1" }],
          // ["link", "image", "video"],
          ["clean"],
        ],
        // 1 - intermediario
        [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          // [{ header: 1 }, { header: 2 }],
          // [{ font: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ script: "sub" }, { script: "super" }],
          // ["blockquote", "code-block"],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          [
            { list: "ordered" },
            { list: "bullet" },
            /*, { list: "check" } */
          ],
          [{ indent: "-1" }, { indent: "+1" }],
          ["link" /* "image", "video" */],
          ["clean"],
        ],
        // 2 - completo
        [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          // [{ header: 1 }, { header: 2 }],
          // [{ font: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ script: "sub" }, { script: "super" }],
          // ["blockquote", "code-block"],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          [
            { list: "ordered" },
            { list: "bullet" },
            /*, { list: "check" } */
          ],
          [{ indent: "-1" }, { indent: "+1" }],
          ["link", "image", "video"],
          ["clean"],
        ],
      ],
    };
  },

  /// COMPUTED
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style>
img {
  max-width: 100%;
  height: auto;
}

#quill-container {
  /* height: 1000px; */
  max-height: 600px;
  min-height: 300px;
  overflow-y: scroll;
}
</style>