<!-- COD: LYF 29/07/2022 CFolhaPagamentoPageVerbaView -->
<!-- *VER: JER 07/08/2022 CFolhaPagamentoPageVerbaView -->
<!-- /12/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <a
          class="text-body-1"
          @click="context.funcs.clickHeader(context, null, verba)"
        >
          {{ verba.nome }}
        </a>
      </v-col>
    </v-row>

    <!-- valor base -->
    <v-row v-bind="prop.row" v-if="verba.valorBase">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">base</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(verba.valorBase, "currency", 2) }}
        </span>
      </v-col>
    </v-row>

    <!-- multiplicador -->
    <v-row v-bind="prop.row" v-if="verba.multiplicador">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">multiplicador</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(verba.multiplicador, "decimal", 2) }}
        </span>
        <span class="ml-1 text-body-2">
          {{ verba.unidade }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row" v-if="verba.data">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">data</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(verba.data, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- referencia -->
    <v-row v-bind="prop.row" v-if="verba.referencia">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">
          {{ verba.referencia }}
        </span>
      </v-col>
    </v-row>

    <!-- meta planejada -->
    <v-row v-bind="prop.row" v-if="metaPlanejada">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">meta</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ metaPlanejada }}
        </span>
      </v-col>
    </v-row>

    <!-- meta realizada-->
    <v-row v-bind="prop.row" v-if="metaRealizada">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">realizado</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ metaRealizada }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa favorecida -->
    <v-row v-bind="prop.row" v-if="verba.pessoaFavorecida_nome">
      <v-col v-bind="prop.col" align="center">
        <v-icon small>mdi-arrow-right</v-icon>
        <span class="ml-1 text-body-1">
          {{ verba.pessoaFavorecida_nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CFolhaPagamentoPageVerbaView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    verba: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getVerba: "verbas/getVerba",
    }),

    metaPlanejada() {
      const meta = this.context.datasetList.dataset.main.metas.find(
        (meta) =>
          meta.idPessoaColaborador == this.verba.idPessoaColaborador &&
          meta.idVerba == this.verba.idVerba
      );

      if (meta) {
        const suffix = this.getVerba(meta.idVerba)?.options?.unidade || "";

        return `${
          meta.options?.faixa
            ? this.numberFormat(meta.metaPlanejada, "decimal", 2)
            : meta.options?.percentual
            ? this.numberFormat(meta.metaPlanejada, "decimal", 0)
            : this.numberFormat(meta.metaPlanejada, "currency")
        } ${suffix}`;
      }

      return "";
    },

    metaRealizada() {
      const meta = this.context.datasetList.dataset.main.metas.find(
        (meta) =>
          meta.idPessoaColaborador == this.verba.idPessoaColaborador &&
          meta.idVerba == this.verba.idVerba
      );
      if (meta) {
        const suffix = this.getVerba(meta.idVerba)?.options?.unidade || "";

        return `${
          meta.options?.faixa
            ? this.numberFormat(meta.metaRealizada, "decimal", 2)
            : meta.options?.percentual
            ? this.numberFormat(meta.metaRealizada, "decimal", 0)
            : this.numberFormat(meta.metaRealizada, "currency")
        } ${suffix}`;
      }

      return "";
    },
  },
};
</script>