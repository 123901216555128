<!-- COD: LYF 04/08/2022 Comentarios -->
<!-- *VER: JER 10/08/2022 Comentarios -->
<!-- /32/ -->

<template>
  <div
    v-touch="{
      down: ($event) => swipe('down', swipeRefresh, $event),
      up: ($event) => swipe('up', swipeInject, $event),
    }"
    class="comentarios"
  >
    <!-- /// NAVBAR -->
    <c-the-navbar mode="page" title="Comentários">
      <template #icon>
        <c-btn icon router to="/">
          <v-icon class="ml-2" large>mdi-comment-outline</v-icon>
        </c-btn>
      </template>
    </c-the-navbar>

    <!-- ??? Onde posicionar o snacbar? -->
    <!-- /// SNACKBAR -->
    <c-snackbar :snackbar="snackbar" @click="clickSnackbar($event)" />

    <v-row
      v-bind="prop.row"
      v-if="!isTouchDevice"
      class="my-3"
      justify="space-around"
    >
      <c-btn fab small @click="swipeRefresh">
        <v-icon> mdi-refresh </v-icon>
      </c-btn>
    </v-row>

    <!-- /// COMENTARIO -->
    <v-container>
      <v-row v-bind="prop.row">
        <!-- publicacao -->
        <v-col v-bind="prop.col" cols="1">
          <v-icon class="primary--text">
            {{ setIcon(publicacao) }}
          </v-icon>
        </v-col>
        <!-- parceiro -->
        <v-col v-bind="prop.col" cols="11">
          <v-chip
            class="primary primary--text text-overline"
            outlined
            x-small
            @click:close="isEntregar = null"
          >
            {{ publicacao.parceiro }}
          </v-chip>
        </v-col>
      </v-row>

      <!-- titulo -->
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <span class="font-weight-medium text-body-1">
            {{ publicacao.titulo[getVar("locale")] }}
          </span>
        </v-col>
      </v-row>

      <!-- data -->
      <v-row v-bind="prop.row" class="mt-n2">
        <v-col v-bind="prop.col">
          <span class="text-caption">
            {{ dataFormataRelativa(publicacao.data, null) }}
          </span>
        </v-col>
      </v-row>
    </v-container>

    <!-- /// LIST -->
    <v-list three-line class="mt-n6">
      <template v-for="comentarioPublicacao in comentariosPublicacao">
        <v-list-item :key="comentarioPublicacao.id" class="pa-0">
          <c-comentario-item
            :comentario="comentarioPublicacao"
            :id="comentarioPublicacao.id"
            :permiteDeletar="permiteDeletar(comentarioPublicacao.idUsuario)"
            @clickComentario="clickComentario($event)"
            @curtir="curtir($event)"
            @delete="deleteConfirm($event)"
          >
            <template
              v-for="comentarioComentario in comentariosComentario(
                comentarioPublicacao.id
              )"
            >
              <v-list-item
                v-show="
                  comentarioComentario.idComentario == comentario.idComentario
                "
                :key="comentarioComentario.id"
                class="pa-0"
              >
                <c-comentario-item
                  :comentario="comentarioComentario"
                  :id="comentarioComentario.id"
                  :permiteDeletar="
                    permiteDeletar(comentarioComentario.idUsuario)
                  "
                  @curtir="curtir($event)"
                  @delete="deleteConfirm($event)"
                />
              </v-list-item>
            </template>
          </c-comentario-item>
        </v-list-item>
      </template>
    </v-list>

    <!-- botao plus -->
    <v-row
      v-bind="prop.row"
      v-if="!isTouchDevice && !btnInjectClicked && comentarios.length >= limit"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn class="mt-3" fab small @click="swipeInject">
          <v-icon>mdi-plus</v-icon>
        </c-btn>
      </v-col>
    </v-row>

    <div style="height: 130px" />

    <v-footer fixed padless shaped :style="style">
      <v-container>
        <v-row v-bind="prop.row" v-if="comentario.idComentario">
          <!-- Respondendo a -->
          <v-col v-bind="prop.col" cols="4">
            <span class="font-weight-light text-body-2">Respondendo a</span>
          </v-col>
          <!-- usuario resposta -->
          <v-col v-bind="prop.col" class="ml-n7" cols="7">
            <span class="font-weight-regular text-body-1">
              {{ usuarioResposta }}
            </span>
          </v-col>
          <!-- botao close -->
          <v-col v-bind="prop.col" align="end" cols="1">
            <c-btn icon @click="clickClose">
              <v-icon>mdi-close</v-icon>
            </c-btn>
          </v-col>
        </v-row>

        <v-row v-bind="prop.row">
          <!-- comentario -->
          <v-col v-bind="prop.col" cols="11">
            <c-textarea
              v-model="comentario.comentario"
              :auto-grow="autogrow"
              class="mt-2"
              :clearable="false"
              :label="comentario.idComentario ? 'resposta' : 'comentário'"
              :max="300"
              outlined
              :placeholder="
                comentario.idComentario
                  ? 'adicione uma resposta...'
                  : 'adicione um comentário...'
              "
              ref="resposta"
              :rows="2"
            />
          </v-col>
          <!-- botao send -->
          <v-col v-bind="prop.col" cols="1">
            <c-btn
              class="ml-2 mt-n5"
              :disabled="
                !comentario.comentario || !comentario.comentario.trim()
              "
              icon
              @click="comentar"
            >
              <v-icon class="primary--text" large>mdi-send</v-icon>
            </c-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <c-the-navbar bottom id="bottomNavbar" mode="page" title="">
      <c-btn
        v-for="(button, i) in buttons"
        icon
        :key="i"
        @click="clickOption(button)"
      >
        <v-icon color="white">
          {{ button.options.icon }}
        </v-icon>
      </c-btn>
    </c-the-navbar>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CComentarioItem from "@/components/pages/comentario/CComentarioItem";
import CTheNavbar from "@/components/bases/CTheNavbar";
import mixinData from "@/mixins/mixinData";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";

export default {
  /// NAME
  name: "Comentarios",

  /// COMPONENTS
  components: { CComentarioItem, CTheNavbar },

  // MIXINS
  mixins: [mixinData, mixinSnackbar, mixinSwipe, mixinUsuarios],

  /// HEAD
  head: {
    title() {
      return {
        inner: this.getPagina("comentarios").nomeAlt,
      };
    },
  },

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("comentarios").id,
      comentarios: [],
      comentario: {
        comentario: null,
        idComentario: null,
        idPai: this.$route.params.idPublicacao,
        idUsuario: getVar("idUsuario"),
      },
      idComentario: this.$route.params.idComentario,
      idPublicacao: this.$route.params.idPublicacao,
      limit: 20,
      publicacao: this.$route.query.publicacao,
      style: "",
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    autogrow() {
      const rows = this.comentario.comentario
        ? this.comentario.comentario.split(/\r?\n/).length
        : 0;

      return rows < this.getConst("config").textarea.maxRows;
    },

    buttons() {
      return this.direitosFeatures(
        this.idPage,
        this.getTipo_item("featureOption").id,
        true,
        null
      ).sortKeys('"order"');
    },

    comentariosPublicacao() {
      return this.comentarios.filter((comentario) => !comentario.idComentario);
    },

    page() {
      return this.getPagina(this.idPage);
    },

    usuarioResposta() {
      const comentario = this.comentarios.find(
        (comentario) => comentario.id == this.comentario.idComentario
      );
      return comentario.usuario;
    },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    clickClose() {
      this.comentario.idComentario = null;
      this.comentario.comentario = null;
    },

    clickComentario(idComentario) {
      this.comentario.idComentario = idComentario;
    },

    clickSnackbar(action) {
      if (action == "delete") {
        this.deletar(this.snackbar.params);
      }

      this.setSnackbar();
    },

    comentar() {
      const url = `${this.getConst("app").baseUrlBackend}/publicacoes/comentar`;

      this.comentario.comentario = this.comentario.comentario.trim();
      if (this.comentario.comentario) {
        axios
          .put(url, this.comentario)
          .then((res) => {
            if (res.data.id) {
              const publicacao_comentario = res.data;
              publicacao_comentario.usuario =
                this.getVar("usuarios")[0].pessoa.nomeAlt;
              publicacao_comentario.usuarioImagem =
                this.getVar("usuarios")[0].imagem.endereco;
              this.comentarios.push(publicacao_comentario);

              if (this.comentario.idComentario) {
                const comentarioMae = this.comentarios.find(
                  (comentario) => comentario.id == this.comentario.idComentario
                );
                comentarioMae.comentadas++;
                comentarioMae.comentou = this.getVar("usuarios")[0].pessoa.id;
              }

              this.comentario.comentario = null;
              setTimeout(() => {
                this.goTo(res.data.id);
              }, 10);
            }
          })
          .catch((err) => {
            this.setSnackbar(true, "ERRO", err.response.data, "error");
          });
      }
    },

    comentariosComentario(idComentario) {
      return this.comentarios.filter(
        (comentario) => comentario.idComentario == idComentario
      );
    },

    async comentariosFilter(offset = 0) {
      this.setVar({ overlay: true });

      const search = "*";
      const idContexto = "*";
      const idFilter = null;
      const idOrder = null;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;

      const params = {
        idPublicacao: this.idPublicacao,
        idComentario: null,
      };

      const url = `${
        this.getConst("app").baseUrlBackend
      }/comentarios/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
        this.limit
      }/${JSON.stringify(params)}`;

      axios
        .get(url)
        .then((res) => {
          const result = res.data;

          if (offset == 0) {
            this.comentarios = [];
          }

          result.forEach((element) => {
            this.comentarios.push(element);
          });
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
            this.btnInjectClicked = false;
          }, this.getConst("config").overlay.timeout);
        });
    },

    curtir(el) {
      el.isCurtiu = !el.isCurtiu;

      const publicacao = {
        id: this.publicacao.id,
        idComentario: el.id,
        idUsuario: this.getVar("idUsuario"),
        isCurtiu: el.isCurtiu,
      };

      const url = `${this.getConst("app").baseUrlBackend}/publicacoes/curtir`;

      axios
        .put(url, publicacao)
        .then((res) => {
          el.curtidas = res.data.curtidas;
          el.isCurtiu = res.data.isCurtiu;
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },

    deletar(comentario) {
      this.comentario.idComentario = null;

      const url = `${this.getConst("app").baseUrlBackend}/publicacoes/comentar`;

      comentario.idModerador = this.getVar("idUsuario");

      axios
        .put(url, comentario)
        .then((res) => {
          if (!res.data.isPublicar) {
            const index = this.comentarios.findIndex(
              (elemento) => elemento.id == comentario.id
            );

            this.comentarios.splice(index, 1);

            if (comentario.idComentario) {
              const comentarioMae = this.comentarios.find(
                (elemento) => elemento.id == comentario.idComentario
              );

              comentarioMae.comentadas--;
              comentarioMae.comentou = this.comentarios.filter(
                (elemento) =>
                  elemento.idComentario == comentario.idComentario &&
                  elemento.idUsuario == this.getVar("idUsuario")
              ).length
                ? this.getVar("idUsuario")
                : null;
            }
          }
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        });
    },

    deleteConfirm(comentario) {
      this.setSnackbar(
        true,
        "DELETAR",
        "Deletar comentário?",
        "error",
        "mdi-check",
        "delete",
        this.getConst("config").snackbar.timeout,
        comentario
      );
    },

    goTo(id) {
      setTimeout(() => {
        const params = {
          duration: 1000,
          offset: 0,
          easing: "linear",
        };
        const element = document.getElementById(id);
        this.$vuetify.goTo(element, params);
      }, 10);
    },

    permiteDeletar(idUsuario) {
      return [idUsuario, this.publicacao.idPessoaParceiro].includes(
        this.getVar("idPessoaParceiro")
      );
    },

    setIcon(el) {
      let icon;
      const tipo = this.getTipo_item(el.idTipo).nome;

      if (tipo == "news") icon = "mdi-alert-octagram";
      else if (tipo == "produto") icon = "mdi-tag";
      else if (tipo == "marca") icon = "mdi-bookmark";
      else icon = "mdi-briefcase";

      return icon;
    },

    swipeInject() {
      this.btnInjectClicked = true;
      this.comentariosFilter(this.comentarios.length);
    },

    swipeRefresh() {
      this.comentariosFilter();
    },
  },

  /// MOUNTED
  mounted() {
    this.comentariosFilter();

    const bottomNavbar = document
      .getElementById("bottomNavbar")
      .getBoundingClientRect();
    this.style = `margin-bottom: ${bottomNavbar.width * 0.1}px`;
  },
};
</script>