<!-- COD: FDS 10/08/2022 CParceiroDialogCorParceiro -->
<!-- *VER: JER 10/08/2022 CParceiroDialogCorParceiro -->
<!-- /11/ -->

<template>
  <!-- /// COR EMPRESA -->
  <v-dialog v-model="show" persistent scrollable width="380px">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-row v-bind="prop.row">
            <!-- close -->
            <v-col v-bind="prop.col" cols="1">
              <c-btn icon @click="clickClose()">
                <v-icon>mdi-close</v-icon>
              </c-btn>
            </v-col>
            <!-- title -->
            <v-col v-bind="prop.col" align="center" cols="10">
              <v-toolbar-title>COR DO PARCEIRO</v-toolbar-title>
            </v-col>
            <!-- save -->
            <v-col v-bind="prop.col" cols="1">
              <c-btn class="ml-n6" icon @click="clickConfirm()">
                <v-icon>mdi-check</v-icon>
              </c-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <!-- paleta de cores -->
        <v-row v-bind="prop.row">
          <v-col v-bind="prop.col" align="center">
            <v-color-picker
              v-model="$vuetify.theme.themes.light.primary"
              class="ma-2"
              flat
              hide-canvas
              hide-inputs
              hide-sliders
              show-swatches
              :swatches="swatches"
              swatches-max-height="310"
              width="250"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /// NAME
  name: "CParceiroDialogCor",

  /// PROPS
  props: {
    show: Boolean,
  },

  /// DATA
  data() {
    return {
      //? cores duro
      swatches: [
        ["#E53935", "#D81B60", "#8E24AA", "#5E35B1"],
        ["#D32F2F", "#C2185B", "#7B1FA2", "#512DA8"],
        ["#C62828", "#AD1457", "#6A1B9A", "#4527A0"],
        ["#B71C1C", "#880E4F", "#4A148C", "#311B92"],

        ["#3949AB", "#1E88E5", "#039BE5", "#00ACC1"],
        ["#303F9F", "#1976D2", "#0288D1", "#0097A7"],
        ["#283593", "#1565C0", "#0277BD", "#00838F"],
        ["#1A237E", "#0D47A1", "#01579B", "#006064"],

        ["#00897B", "#43A047", "#7CB342", "#C0CA33"],
        ["#00796B", "#388E3C", "#689F38", "#AFB42B"],
        ["#00695C", "#2E7D32", "#558B2F", "#9E9D24"],
        ["#004D40", "#1B5E20", "#33691E", "#827717"],

        ["#FDD835", "#FFB300", "#FB8C00", "#F4511E"],
        ["#FBC02D", "#FFA000", "#F57C00", "#E64A19"],
        ["#F9A825", "#FF8F00", "#EF6C00", "#D84315"],
        ["#F57F17", "#FF6F00", "#E65100", "#BF360C"],

        ["#6D4C41", "#546E7A", "#757575", "#000000"],
        ["#5D4037", "#455A64", "#616161"],
        ["#4E342E", "#37474F", "#424242"],
        ["#3E2723", "#263238", "#212121"],
      ],
    };
  },

  /// METHODS
  methods: {
    clickClose() {
      this.$emit("close", false);
    },

    clickConfirm() {
      this.$emit("close", true);
    },
  },
};
</script>