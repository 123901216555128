var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-base-list',{attrs:{"funcs":_vm.funcs,"idPage":_vm.idPage,"idPageAlt":_vm.idPageAlt,"pessoaParceiro":_vm.params.nome || null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var context = ref.context;
var dataset = ref.dataset;
var order = ref.order;
return [_c('c-base-list-item',{attrs:{"context":context,"dataset":dataset,"idPage":_vm.idPage,"order":order},scopedSlots:_vm._u([{key:"action",fn:function(){return [(
          !!dataset.avaliacao ||
          _vm.agora <=
            _vm.addDays(
              _vm.parseISO(dataset.dataEntrega),
              _vm.getConst('config').avaliacao.prazoAvaliacao
            )
        )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('c-btn',{directives:[{name:"show",rawName:"v-show",value:(
              [_vm.getEstado('entregue').id, _vm.getEstado('finalizado').id].includes(
                dataset.idEstado
              )
            ),expression:"\n              [getEstado('entregue').id, getEstado('finalizado').id].includes(\n                dataset.idEstado\n              )\n            "}],attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push(("/avaliacao/" + (dataset.id)))}}},[_c('span',{class:!!dataset.avaliacao ? 'black--text' : 'primary--text'},[_vm._v(" "+_vm._s(!!dataset.avaliacao ? "Avaliação:" : "Avalie:")+" ")]),_c('v-rating',{attrs:{"background-color":dataset.avaliacao > 0 ? 'orange lighten-3' : 'grey',"color":dataset.avaliacao > 0 ? 'orange' : 'grey',"dense":"","hover":"","readonly":"","size":"18"},model:{value:(dataset.avaliacao),callback:function ($$v) {_vm.$set(dataset, "avaliacao", $$v)},expression:"dataset.avaliacao"}})],1)],1)],1):_vm._e(),(
          _vm.agora >
          _vm.addDays(
            _vm.parseISO(dataset.dataEntrega),
            _vm.getConst('config').avaliacao.prazoAvaliacao
          )
        )?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-body-1"},[_vm._v("prazo de avaliação encerrado")])])],1):_vm._e()]},proxy:true},{key:"info",fn:function(){return [_c('v-row',_vm._b({directives:[{name:"show",rawName:"v-show",value:(order.text != 'entrega'),expression:"order.text != 'entrega'"}]},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('v-chip',{staticClass:"ml-3 text-overline white--text",class:dataset.isEntregar ? 'corEntregar' : 'corRetirar',attrs:{"x-small":""}},[_vm._v(" "+_vm._s(dataset.isEntregar ? "entregar" : "retirar")+" ")])],1)],1)]},proxy:true}],null,true)},[(_vm.$route.query.idProduto)?_c('c-pedidos-produto',{attrs:{"context":context,"dataset":dataset}}):(_vm.$route.query.idPessoa && _vm.$route.query.isProduto)?_c('c-pedidos-pessoa-produto',{attrs:{"context":context,"dataset":dataset}}):_c('c-pedidos',{attrs:{"context":context,"dataset":dataset}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }