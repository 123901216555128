<!-- COD: FDS 01/07/2022 ContasPagRec -->
<!-- *VER: JER 05/07/2022 ContasPagRec -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
    :pessoaParceiro="params.nome || null"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-contasPagRec :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CContasPagRec from "@/components/pages/contaPagRec/CContasPagRec";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "ContasPagRec",

  /// COMPONENTS
  components: {
    CContasPagRec,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("contasPagRec").id,
      idPageAlt: getPagina("contaPagRec").id,

      funcs: {
        clickChip: (context) => {
          this.params = {};
          context.params = this.funcs.fnParams();
        },

        fnParams: () => {
          const params = JSON.parse(JSON.stringify(this.params));
          delete params.nome;
          delete params.home;

          return JSON.stringify(params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      params: {},
    };
  },

  /// BEFOREMOUNT
  beforeMount() {
    this.params = this.copyObject(this.$route.query);
  },

  /// ACTIVATED
  activated() {
    this.params = this.copyObject(this.$route.query);
  },
};
</script>