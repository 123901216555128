<!-- COD: BJB 23/08/2022 CAjudaElementos -->
<!-- *VER: JER 09/08/2022 CAjudaElementos -->
<!-- /22/ -->

<template>
  <!-- /// ELEMENTOS COMUNS -->

  <!-- // mais -->
  <v-expansion-panel v-if="panel == 'mais' && showMais">
    <v-expansion-panel-header class="grey lighten-4 px-1 text-h6">
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
          <span class="ml-3">mais</span>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <!-- // tipos -->
      <p v-show="tipo_itens.length" class="mb-n1 mt-4">
        <span class="font-weight-medium text-body-2">TIPOS</span>
        <span class="ml-1 text-caption">({{ tipo_itens.length }})</span>
      </p>
      <ul class="mb-6">
        <li v-for="(tipo, i) in tipo_itens" :key="i">
          <p class="font-weight-medium mt-2 text-body-1">
            {{ tipo.text }}
          </p>
          <p class="mt-n4 text-body-2">
            {{ getTipo_item(tipo.value).options.ajuda }}
          </p>
        </li>
      </ul>

      <!-- // estados -->
      <p v-show="estados.length" class="mb-n1">
        <span class="font-weight-medium text-body-2">ESTADOS</span>
        <span class="ml-1 text-caption">({{ estados.length }})</span>
      </p>
      <ul class="mb-6">
        <li v-for="(estado, i) in estados" :key="i">
          <p class="font-weight-medium mt-2 text-body-1">
            {{ estado.nomeAlt }}
          </p>
          <p class="mt-n4 text-body-2">
            {{ estado.options.ajuda }}
          </p>
        </li>
      </ul>

      <!-- // funções -->
      <p v-show="funcoes.length" class="mb-n1">
        <span class="font-weight-medium text-body-2">FUNÇÕES</span>
        <span class="ml-1 text-caption">({{ funcoes.length }})</span>
      </p>

      <ul class="mb-6">
        <li v-for="(funcao, i) in funcoes" :key="i">
          <p class="font-weight-medium mt-2 text-body-1">
            <v-icon class="mr-1">
              {{ funcao.options.icon }}
            </v-icon>
            <span class="ml-0">
              {{ funcao.nomeAlt }}
            </span>
          </p>
          <p class="mt-n4 text-body-2">
            {{ funcao.options.ajuda }}
          </p>
        </li>
      </ul>

      <!-- // painéis -->
      <p v-show="paineis.length" class="mb-n1">
        <span class="font-weight-medium text-body-2">PAINÉIS</span>
        <span class="ml-1 text-caption">({{ paineis.length }})</span>
      </p>
      <ul class="mb-6">
        <li v-for="(painel, i) in paineis" :key="i">
          <p class="font-weight-medium mt-2 text-body-1">
            {{ painel.nomeAlt }}
          </p>
          <p class="mt-n4 text-body-2">
            {{ painel.options.ajuda }}
          </p>
        </li>
      </ul>

      <!-- // relatórios -->
      <p v-show="paginaRelatorios.length" class="mb-n1">
        <span class="font-weight-medium text-body-2">RELATÓRIOS</span>
        <span class="ml-1 text-caption">({{ paginaRelatorios.length }})</span>
      </p>
      <ul class="mb-6">
        <li v-for="(paginaRelatorio, i) in paginaRelatorios" :key="i">
          <p class="font-weight-medium mt-2 text-body-1">
            {{ paginaRelatorio.nomeAlt }}
          </p>
          <p v-if="paginaRelatorio.optionsFeature" class="mt-n4 text-body-2">
            {{ paginaRelatorio.optionsFeature.ajuda }}
          </p>
        </li>
      </ul>
    </v-expansion-panel-content>
  </v-expansion-panel>

  <!-- // videos -->
  <v-expansion-panel v-else-if="panel == 'videos' && videos.length">
    <v-expansion-panel-header class="grey lighten-4 px-1 text-h6">
      <v-row v-bind="prop.row">
        <v-col v-bind="prop.col">
          <v-icon>mdi-youtube</v-icon>
          <span class="ml-3">vídeos</span>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row v-bind="prop.row" class="mt-4">
        <v-col
          v-bind="prop.col"
          v-for="(video, i) in videos"
          cols="12"
          :key="i"
          sm="6"
        >
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            frameborder="0"
            height="100%"
            :src="`https://www.youtube.com/embed/${video}`"
            title="YouTube video player"
            width="100%"
          />
        </v-col>
      </v-row>

      <v-row v-bind="prop.row" class="mt-4">
        <v-col v-bind="prop.col">
          <span class="ml-n6 text-body-1">Veja a playlist completa no</span>
          <a
            class="ml-1 text-body-1"
            @click="clickLink(getConst('redesSociais').youtube)"
          >
            <v-icon color="red">mdi-youtube</v-icon>
            <span class="ml-1">YouTube</span>
          </a>
          <span class="ml-n1 text-body-1">.</span>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>

  <!-- //? videos - implementar -->

  <!-- // links -->
  <v-container v-else-if="panel == 'links'" class="pa-0">
    <v-row v-bind="prop.row" class="grey lighten-4">
      <!-- compartilhar -->
      <v-col v-bind="prop.col" align="center">
        <c-btn
          class="text-body-1 primary--text"
          icon
          @click="compartilharAjuda(`#${documento.id}`)"
        >
          <v-icon class="primary--text">mdi-share</v-icon>
        </c-btn>
      </v-col>
      <!-- ir para -->
      <v-col v-bind="prop.col" align="center">
        <c-btn
          v-if="
            hasLink &&
            tabela &&
            tabela.nome == 'pessoas' &&
            getVar('idPessoaParceiro') == getVar('idUsuario')
          "
          class="primary--text"
          icon
          @click="clickLink(`/pessoa/${getVar('idUsuario')}`)"
        >
          <v-icon class="text-body-1 primary--text">mdi-arrow-right</v-icon>
        </c-btn>
        <c-btn
          v-else-if="
            hasLink &&
            tabela &&
            tabela.nome == 'parceiros' &&
            getVar('idPessoaParceiro') != getConst('app').id
          "
          class="primary--text"
          icon
          @click="clickLink(`/parceiro/2`)"
        >
          <v-icon class="primary--text">mdi-arrow-right</v-icon>
        </c-btn>
        <c-btn
          v-else-if="
            hasLink &&
            tabela &&
            tabela.nome == 'logs' &&
            getVar('idPessoaParceiro') == getConst('app').id
          "
          class="primary--text"
          icon
          @click="clickLink(`/logs/1/0/0/''`)"
        >
          <v-icon class="primary--text">mdi-arrow-right</v-icon>
        </c-btn>
        <c-btn
          v-else-if="hasLink"
          class="primary--text"
          icon
          @click="clickLink(`${feature.options.route}`)"
        >
          <v-icon class="primary--text">mdi-arrow-right</v-icon>
        </c-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinUsuarios from "@/mixins/mixinUsuarios";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "CAjudaElementos",

  /// MIXINS
  mixins: [mixinUsuarios, mixinUtil],

  /// PROPS
  props: {
    documento: { type: Object },
    hasLink: { type: Boolean, default: true },
    panel: { type: String, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getMaquinaEstado_estados: "estados/getMaquinaEstado_estados",
      getPagina_feature: "paginas/getPagina_feature",
      getPagina_features: "paginas/getPagina_features",
      getPagina_configuracoes: "paginas/getPagina_configuracoes",
      getPagina: "paginas/getPagina",
      getPaginas: "paginas/getPaginas",
      getTabela: "tabelas/getTabela",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
      getVar: "variaveis/getVar",
    }),

    estados() {
      return this.page?.idTabela
        ? [
            ...new Set(
              this.getMaquinaEstado_estados(
                this.getTabela(this.page?.idTabela).idMaquinaEstado
              ).map((estado) => estado.value)
            ),
          ].map((idEstado) => this.getEstado(idEstado))
        : [];
    },

    feature() {
      return this.getFeature(this.documento.options.feature);
    },

    features() {
      return this.getPagina_features(this.page?.id)
        .concat(this.getPagina_features(this.pageAlt?.id) || [])
        .map((feature) => this.getPagina_feature(feature.value))
        .map((feature) => {
          const ret = JSON.parse(
            JSON.stringify(this.getFeature(feature.idFeature))
          );

          ret.options.ajuda = feature.options.ajuda
            ? feature.options.ajuda
            : ret.options.ajuda;
          return ret;
        })
        .filter((feature) => !!feature.options.ajuda);
    },

    funcoes() {
      return this.features.filter(
        (feature) => feature.idTipo == this.getTipo_item("featureOption").id
      );
    },

    page() {
      return this.getPaginas().find(
        (pagina) => pagina.idFeature == this.feature?.id
      );
    },

    pageAlt() {
      return this.getPagina(this.page?.idPaginaAlt);
    },

    paginaRelatorios() {
      return this.getPagina_configuracoes(this.page?.id)
        .concat(this.getPagina_configuracoes(this.pageAlt?.id) || [])
        .filter(
          (relatorio) =>
            !relatorio.idPessoaParceiro &&
            !relatorio.idPessoaFornecedor &&
            !relatorio.idBanco &&
            !relatorio.idCartao
        )
        .map((relatorio) => {
          const feature = this.getFeature(relatorio.idFeature);

          return {
            ...relatorio,
            nome: feature.nome,
            nomeAlt: feature.nomeAlt,
            optionsFeature: feature.options,
          };
        });
    },

    paineis() {
      return this.features.filter(
        (feature) => feature.idTipo == this.getTipo_item("featurePanel").id
      );
    },

    showMais() {
      return (
        this.page?.idTipo != this.getTipo_item("pageAuxiliar").id &&
        (this.tipo_itens.length ||
          this.estados.length ||
          this.funcoes.length ||
          this.paineis.length ||
          this.paginaRelatorios.length)
      );
    },

    tabela() {
      return this.getTabela(this.page?.idTabela);
    },

    tipo() {
      const idContexto =
        this.page?.idContexto || this.pageAlt?.idContexto || null;

      return this.getTipo(this.getTipo_item(idContexto)?.idPai);
    },

    tipo_itens() {
      return this.getTipo_itens(this.tipo?.id) || [];
    },

    videos() {
      return this.documento.options?.videos || [];
    },
  },

  /// METHODS
  methods: {
    clickLink(hash) {
      if (hash.substring(0, 4) == "http" || hash.substring(0, 6) == "mailto") {
        window.open(hash, "_blank");
      } else if (hash.substring(0, 1) == "/") {
        this.$router.push(hash);
      }
    },

    compartilharAjuda(hash) {
      const url = `${window.location.origin}/ajuda${hash}`;

      this.copyToClipboard(url);

      if (navigator.share) {
        navigator
          .share({
            title: "Ajuda",
            text: "",
            url,
          })
          .then();
      }
    },
  },
};
</script>