<!-- COD: LYF 04/07/2022 CLocalidadeView -->
<!-- *VER: JER 14/07/2022 CLocalidadeView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- capital -->
    <v-row
      v-bind="prop.row"
      v-if="
        isTipo(['localidadeEstado', 'localidadeCidade']) &&
        dataset.main.detail.isCapital
      "
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">capital</v-chip>
      </v-col>
    </v-row>

    <!-- configuracao regional -->
    <v-row
      v-bind="prop.row"
      v-if="
        isTipo(['localidadePais']) && dataset.main.detail.hasConfiguracaoRegional
      "
    >
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">configuração regional</v-chip>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <a class="text-h6" @click="clickLocalidadeGoogle()">
          {{ dataset.main.detail.nome }}
        </a>
      </v-col>
    </v-row>

    <!-- codigo -->
    <v-row
      v-bind="prop.row"
      v-if="isTipo(['localidadePais', 'localidadeEstado'])"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ codigo }}
        </span>
      </v-col>
    </v-row>

    <!-- localizacao -->
    <v-row
      v-bind="prop.row"
      v-if="isTipo(['localidadePais', 'localidadeEstado', 'localidadeCidade'])"
    >
      <v-col v-bind="prop.col" align="center">
        <a @click="clickLocalidade(context)">
          <span class="text-body-1">
            {{ localizacao }}
          </span>
        </a>
      </v-col>
    </v-row>

    <v-divider v-bind="prop.divider" />

    <!-- codigo -->
    <v-row
      v-bind="prop.row"
      v-if="isTipo(['localidadePais', 'localidadeEstado', 'localidadeCidade'])"
    >
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">código</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.codigo }}
        </span>
      </v-col>
    </v-row>

    <!-- id externo -->
    <v-row v-bind="prop.row" v-if="dataset.aux.detail.idExterno">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">id externo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.aux.detail.idExterno }}
        </span>
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col
        v-bind="prop.col"
        v-if="dataset.main.detail.observacao"
        align="center"
      >
        <span class="text-body-2">
          ({{ dataset.main.detail.observacao }})
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLocalidadeView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getConst: "constantes/getConst",
      getTipo_item: "tipos/getTipo_item",
    }),

    localizacao() {
      return `${
        this.translate(this.dataset.aux.detail.localidadePai_nome) || ""
      }${
        this.dataset.aux.detail.localidadeAvo_nome
          ? " - " + this.translate(this.dataset.aux.detail.localidadeAvo_nome)
          : ""
      }`;
    },

    codigo() {
      return this.dataset.main.detail.idTipo ==
        this.getTipo_item("localidadePais").id
        ? `${this.translate(
            this.dataset.main.detail.codigo2
          )} - ${this.translate(this.dataset.main.detail.codigo3)}`
        : this.dataset.main.detail.idTipo ==
          this.getTipo_item("localidadeEstado").id
        ? this.translate(this.dataset.main.detail.codigo2)
        : null;
    },
  },

  /// METHODS
  methods: {
    clickLocalidade() {
      this.$router.push(`/localidade/${this.dataset.main.detail.idPai}`);
    },

    clickLocalidadeGoogle() {
      const baseUrl = this.getConst("config").urlGoogleMaps;
      const complementoUrl = `${this.dataset.main.detail.nome} ${
        this.dataset.aux.detail.localidadePai_nome || ""
      }`;
      const url = `${baseUrl}${complementoUrl}`;
      window.open(url, "_blank");
    },

    isTipo(tipos) {
      return tipos.includes(
        this.getTipo_item(this.dataset.main.detail.idTipo)?.nome
      );
    },
  },
};
</script>