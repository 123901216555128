<!-- COD: LYF 04/07/2022 CContaPagRecView -->
<!-- *VER: JER 05/07/2022 CContaPagRecView -->
<!-- /21/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- forma pagamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idFormaPagamento">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.main.detail.idFormaPagamento).nomeAlt }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- pessoa nome -->
    <v-row v-bind="prop.row" v-if="dataset.aux">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          class="text-body-1 ma-1"
          color="primary"
          text
          @click="clickPessoa"
        >
          {{ dataset.aux.detail.pessoa.nome }}
        </c-btn>
      </v-col>
    </v-row>

    <!-- importe -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.isImporte">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">importe</v-chip>
      </v-col>
    </v-row>

    <!-- historico padrao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idHistoricoPadrao">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{
            getHistoricoPadrao(dataset.main.detail.idHistoricoPadrao).nomeAlt
          }}
        </span>
      </v-col>
    </v-row>

    <!-- imposto -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idImposto">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ getTipo_item(dataset.main.detail.idImposto).nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- conta bancaria -->
    <v-row v-bind="prop.row" v-if="contaBancaria">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.contaBancaria }}
        </span>
      </v-col>
    </v-row>

    <!-- pedido -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idPedido">
      <v-col v-bind="prop.col" align="center">
        <a @click="clickPedido">
          <span class="text-body-2">pedido</span>
          <span class="text-body-1">
            {{ dataset.main.detail.idPedido }}
          </span>
        </a>
      </v-col>
    </v-row>

    <!-- folha pagamento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.idFolhaPagamento">
      <v-col v-bind="prop.col" align="center">
        <a @click="clickFolhaPagamento">
          <span class="text-body-2">folha pagamento</span>
          <span class="text-body-1">
            {{ dataset.main.detail.idFolhaPagamento }}
          </span>
        </a>
      </v-col>
    </v-row>

    <!-- duplicata -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.duplicata">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">duplicatas</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.duplicata }}
        </span>
      </v-col>
    </v-row>

    <!-- data emissao -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.dataEmissao">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">emissão</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataEmissao, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- prazo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">prazo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dataset.main.detail.prazo }}
        </span>
        <span class="ml-1 text-body-2">dias</span>
      </v-col>
    </v-row>

    <!-- data vencimento -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.dataVencimento">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">vencimento</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ dateFormat(dataset.main.detail.dataVencimento, "date") }}
        </span>
      </v-col>
    </v-row>

    <!-- valor -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.valor">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">valor</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{
            numberFormat(
              parseFloat(context.dataset.main.detail.valor).toFixed(2),
              "currency"
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- saldo -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.saldo">
      <v-col v-bind="prop.col" align="end" cols="6">
        <span class="text-body-2">saldo</span>
      </v-col>
      <v-col v-bind="prop.col" cols="6">
        <span class="text-body-1">
          {{ numberFormat(dataset.main.detail.saldo, "currency") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CContaPagRecView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getHistoricoPadrao: "historicosPadrao/getHistoricoPadrao",
      getTipo_item: "tipos/getTipo_item",
      getVar: "variaveis/getVar",
    }),

    contaBancaria() {
      const contaBancaria = this.getVar("usuarios")[
        this.getVar("usuarios").findIndex(
          (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
        )
      ].contas.find(
        (conta) =>
          conta.id == this.dataset.main.detail.idPessoaParceiroContaBancaria
      );

      return contaBancaria ? this.contaBancariaFormat(contaBancaria) : null;
    },
  },

  /// METHODS
  methods: {
    clickFolhaPagamento() {
      this.$router.push(
        `/folhaPagamento/${this.dataset.main.detail.idFolhaPagamento}`
      );
    },

    clickPedido() {
      this.$router.push(`/pedido/${this.dataset.main.detail.idPedido}`);
    },

    clickPessoa() {
      this.$router.push(`/pessoa/${this.dataset.main.detail.idPessoa}`);
    },
  },
};
</script>