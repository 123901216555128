<!-- COD: LYF 10/08/2022 CAvaliacaoPageAvaliacoes -->
<!-- *VER: JER 10/08/2022 CAvaliacaoPageAvaliacoes -->
<!-- /12/ -->

<template>
  <v-container>
    <!-- avaliado -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h5 font-weight-medium">
          {{ avaliado.avaliado }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <!-- star -->
        <v-icon class="orange--text"> mdi-star </v-icon>
        <!-- media avaliacoes -->
        <span class="text-h6">
          {{ avaliado.mediaAvaliacoes }}
        </span>
        <!-- numero avaliacoes -->
        <span class="text-overline"> ({{ avaliado.numeroAvaliacoes }}) </span>
      </v-col>
    </v-row>

    <v-list three-line>
      <template v-for="(avaliacao, i) in dataset">
        <v-list-item v-show="avaliacao.avaliacao" class="pa-0" :key="i">
          <v-list-item-content>
            <v-list-item-title>
              <!-- avaliador -->
              <v-row v-bind="prop.row">
                <v-col v-bind="prop.col">
                  <span class="font-weight-medium text-body-1">
                    {{ avaliacao.avaliador }}
                  </span>
                </v-col>
              </v-row>

              <v-row v-bind="prop.row">
                <!-- media avaliacoes -->
                <v-col v-bind="prop.col" cols="3" sm="2">
                  <v-rating
                    v-model="avaliacao.avaliacao"
                    background-color="orange lighten-3"
                    color="orange"
                    dense
                    hover
                    readonly
                    size="14"
                    style="width: fit-content"
                  />
                </v-col>
                <!-- data avaliacao -->
                <v-col v-bind="prop.col" cols="3" sm="2">
                  <span class="text-overline ml-2">
                    {{ dataFormata(avaliacao.dataAvaliacao, "date", true) }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item-title>
            <!-- comentario -->
            <v-row v-bind="prop.row" v-if="avaliacao.comentario">
              <v-col v-bind="prop.col">
                <span class="text-body-1">
                  {{ avaliacao.comentario }}
                </span>
              </v-col>
            </v-row>

            <!-- resposta -->
            <v-row v-bind="prop.row" v-if="avaliacao.resposta">
              <v-col v-bind="prop.col">
                <span class="text-body-1"> resposta </span>
              </v-col>
            </v-row>

            <!-- resposta -->
            <v-row v-bind="prop.row" v-if="avaliacao.resposta">
              <v-col v-bind="prop.col">
                <span class="text-body-1">
                  {{ avaliacao.resposta }}
                </span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="(i + 1) * -1" class="mt-n2" />
      </template>
    </v-list>

    <!-- botao mais -->
    <v-row
      v-bind="prop.row"
      v-if="!isTouchDevice && !btnInjectClicked && dataset.length >= limit"
    >
      <v-col v-bind="prop.col" align="center">
        <c-btn class="mt-3" fab small @click="swipeInject"> mdi-plus </c-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import axios from "axios";
import { mapActions } from "vuex";
import mixinPageList from "@/mixins/mixinPageList";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "CAvaliacaoPageAvaliacoes",

  /// MIXINS
  mixins: [mixinPageList, mixinSwipe, mixinUtil],

  /// PROPS
  props: {
    avaliado: { type: Object, required: true },
    dataset: { type: Array, required: true },
    limit: { type: Number, required: true },
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    async avaliacoesFilter(offset = 0) {
      this.setVar({ overlay: true });

      const search = "*";
      const idContexto = "*";
      const idFilter = null;
      const idOrder = null;
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const idUsuario = this.getVar("idUsuario");
      const complete = 0;

      const params = {
        searchIdPessoaParceiro: this.$route.params.idAvaliado,
      };

      const url = `${
        this.getConst("app").baseUrlBackend
      }/avaliacoes/${search}/${idContexto}/${idFilter}/${idOrder}/${idPessoaParceiro}/${idUsuario}/${complete}/${offset}/${
        this.limit
      }/${JSON.stringify(params)}`;

      await axios
        .get(url)
        .then((res) => {
          const result = res.data;

          this.avaliado = JSON.parse(JSON.stringify(result[0]));
          result.splice(0, 1);

          this.avaliacoes = [];
          result.forEach((element) => {
            this.avaliacoes.push(element);
          });
        })
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          this.setVar({ overlay: false });
          this.btnInjectClicked = false;
        });
    },

    swipeInject() {
      this.btnInjectClicked = true;
      this.avaliacoesFilter(this.avaliacoes.length);
    },

    swipeRefresh() {
      this.avaliacoesFilter();
    },
  },
};
</script>