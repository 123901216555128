<!-- COD: FDS 08/07/2022 CLocalidadeEdit -->
<!-- *VER: JER 20/07/2022 CLocalidadeEdit -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pai -->
    <v-row
      v-bind="prop.row"
      v-if="
        getTipo_item('localidadeContinente').id != dataset.main.detail.idTipo
      "
    >
      <v-col v-bind="prop.col">
        <c-autocomplete
          v-model="dataset.main.detail.idPai"
          :itemsIniciais="dataset.aux.autocomplete.localizacao"
          label="localizada em"
          :path="`/localidades/autocomplete/${idTipoPai}/`"
          :rules="[]"
        />
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.nome"
          label="nome"
          :max="35"
          :min="1"
          required
        />
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- codigo -->
      <v-col
        v-bind="prop.col"
        v-if="
          [
            getTipo_item('localidadePais').id,
            getTipo_item('localidadeEstado').id,
            getTipo_item('localidadeCidade').id,
          ].includes(dataset.main.detail.idTipo)
        "
        cols="4"
      >
        <c-text-field
          v-model="dataset.main.detail.codigo"
          :help="{
            title: 'CÓDIGO',
            text: 'Código numérico da localidade (BR: IBGE/BACEN)',
            function: context.setUiBottomSheet,
          }"
          label="código"
          :max="7"
          :min="2"
        />
      </v-col>
      <!-- código 2 -->
      <v-col
        v-bind="prop.col"
        v-if="
          [
            getTipo_item('localidadePais').id,
            getTipo_item('localidadeEstado').id,
          ].includes(dataset.main.detail.idTipo)
        "
        cols="4"
      >
        <c-text-field
          v-model="dataset.main.detail.codigo2"
          :help="{
            title: 'CÓDIGO 2',
            text: 'Código de duas letras da localidade  (ISO 3166-1 alfa-2 ou ISO 3166-2)',
            function: context.setUiBottomSheet,
          }"
          label="código 2"
          :max="2"
          :min="2"
          required
        />
      </v-col>
      <!-- código 3 -->
      <v-col
        v-bind="prop.col"
        v-if="
          [getTipo_item('localidadePais').id].includes(
            dataset.main.detail.idTipo
          )
        "
        cols="4"
      >
        <c-text-field
          v-model="dataset.main.detail.codigo3"
          :help="{
            title: 'CÓDIGO 3',
            text: 'Código de três letras da localidade  (ISO 3166-1 alfa-3)',
            function: context.setUiBottomSheet,
          }"
          label="código 3"
          :max="3"
          :min="3"
          required
        />
      </v-col>
    </v-row>

    <!-- capital -->
    <v-row
      v-bind="prop.row"
      v-if="
        [
          getTipo_item('localidadeEstado').id,
          getTipo_item('localidadeCidade').id,
        ].includes(dataset.main.detail.idTipo)
      "
    >
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.isCapital"
          label="capital"
          required
        />
      </v-col>
    </v-row>

    <!-- configuracao regional -->
    <v-row
      v-bind="prop.row"
      v-if="
        [getTipo_item('localidadePais').id].includes(dataset.main.detail.idTipo)
      "
    >
      <v-col v-bind="prop.col">
        <c-switch
          v-model="dataset.main.detail.hasConfiguracaoRegional"
          label="configuração regional"
          required
        />
      </v-col>
    </v-row>

    <!-- observacao -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <c-text-field
          v-model="dataset.main.detail.observacao"
          label="observação"
          :max="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CLocalidadeEdit",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    params: {},
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),

    idTipoPai() {
      return this.getTipo_item(
        this.getTipo_item(
          this.dataset.main.detail.idTipo || this.context.pageAlt.idContexto
        ).options.tipoLocalidadePai
      )?.id;
    },
  },
};
</script>