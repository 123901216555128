<!-- COD: LYF 05/07/2022 FolhaPagamento -->
<!-- *VER: JER 07/08/2022 FolhaPagamento -->
<!-- /21/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :params="paramsCamposEspecificos()"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          >
            <template #camposEspecificos="{ datasetTemp }">
              <!-- sincronizarColaboradores -->
              <v-row
                v-bind="prop.row"
                v-if="
                  context.dataset.main.detail.idEstado ==
                    getEstado('aberto').id &&
                  datasetTemp.main.detail.idEstado == getEstado('analise').id
                "
              >
                <v-col v-bind="prop.col">
                  <c-switch
                    v-model="params.sincronizarColaboradores"
                    label="sincronizar colaboradores"
                  />
                </v-col>
              </v-row>

              <!-- recalcularVendas -->
              <v-row
                v-bind="prop.row"
                v-if="
                  context.dataset.main.detail.idTipo ==
                    getTipo_item('fpNormal').id &&
                  context.dataset.main.detail.idEstado ==
                    getEstado('aberto').id &&
                  datasetTemp.main.detail.idEstado == getEstado('analise').id
                "
              >
                <v-col v-bind="prop.col">
                  <c-switch
                    v-model="params.recalcularVendas"
                    label="recalcular vendas"
                  />
                </v-col>
              </v-row>

              <!-- recalcularVerbasAlteradas -->
              <v-row
                v-bind="prop.row"
                v-if="
                  context.dataset.main.detail.idEstado ==
                    getEstado('aberto').id &&
                  datasetTemp.main.detail.idEstado == getEstado('analise').id
                "
              >
                <v-col v-bind="prop.col">
                  <c-switch
                    v-model="params.recalcularVerbasAlteradas"
                    label="recalcular verbas alteradas"
                  />
                </v-col>
              </v-row>
            </template>
          </c-base-dialog-estado>

          <c-folhaPagamento-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-folhaPagamento-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CFolhaPagamentoEdit from "@/components/pages/folhaPagamento/CFolhaPagamentoEdit";
import CFolhaPagamentoView from "@/components/pages/folhaPagamento/CFolhaPagamentoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "FolhaPagamento",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CFolhaPagamentoEdit,
    CFolhaPagamentoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("folhaPagamento").id,
      idPageAlt: getPagina("folhasPagamentos").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("fpNormal").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            dataInicio: null,
            dataFim: null,
            dataPagamento: null,
            valorTotal: 0,
            idPontoEletronico: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          parametros: [],
        },
        aux: {
          detail: {},
        },
      },

      funcs: {
        clickColaboradores: () => {
          this.$router.push(
            `/folhaPagamento/folhaPagamento_Colaboradores/${this.dataset.main.detail.id}`
          );
        },

        clickOcorrencias: () => {
          this.$router.push(
            `/folhaPagamento/folhaPagamento_Ocorrencias/${this.dataset.main.detail.id}`
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        clickVerbas: () => {
          this.$router.push(
            `/folhaPagamento/folhaPagamento_Verbas/${this.dataset.main.detail.id}`
          );
        },
      },

      params: {
        context: null,
        sincronizarColaboradores: false,
        recalcularVendas: false,
        recalcularVerbasAlteradas: false,
      },
    };
  },

  /// METHODS
  methods: {
    paramsCamposEspecificos() {
      const params = {
        sincronizarColaboradores: this.params.sincronizarColaboradores,
        recalcularVendas: this.params.recalcularVendas,
        recalcularVerbasAlteradas: this.params.recalcularVerbasAlteradas,
      };

      return params;
    },
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>