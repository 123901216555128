<!-- COD: FDS 06/07/2022 Transacao -->
<!-- *VER: JER 08/07/2022 Transacao -->
<!-- /22/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, pageModeView }">
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-transacao-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
            :params="params"
          />
          <c-transacao-edit v-else :context="context" :dataset="dataset" />
        </div>
        <c-base-dialog-pagamento
          v-model="showDialog"
          :code="code"
          @pagamentoConfirmado="pagamentoConfirmado(context, $event)"
        />
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CBaseDialogPagamento from "@/components/bases/CBaseDialogPagamento";
import CTransacaoEdit from "@/components/pages/transacao/CTransacaoEdit";
import CTransacaoView from "@/components/pages/transacao/CTransacaoView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Extrato",

  /// COMPONENTS
  components: {
    CBaseDialogEstado,
    CBaseDialogPagamento,
    CTransacaoEdit,
    CTransacaoView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];

    return {
      idPage: getPagina("transacao").id,
      idPageAlt: getPagina("transacoes").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("transacaoPedido").id,
            idEstado: getEstado("confirmar").id,
            isSuspenso: false,
            idPessoaParceiro: null,
            data: null,
            dataVencimento: null,
            idPessoa: null,
            idPedido: null,
            isPagamentoOnline: true,
            idFormaPagamento: null,
            documento: null,
            valor: 0,
            valorConsiderado: 0,
            valorCuponsApp: null,
            valorCuponsParceiro: null,
            valorApp: 0,
            percentual: 0,
            valorComissao: 0,
            valorAcerto: 0,
            valorSaldo: 0,
            isPagamento: null,
            isFaturamento: null,
            idTransacaoPagamento: null,
            idTransacaoFaturamento: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
        },
        aux: {
          detail: {
            pessoaParceiro_idTipo: null,
            pessoaParceiro_documentoFederal: null,
            pessoaParceiro_nomeAlt: null,
            pessoaCliente_nome: null,
            pagamento: {
              id: null,
              isPagamento: null,
              isDevolucao: null,
              idCarrinho: null,
              idPedido: null,
              idTransacao: null,
              dataCriacao: null,
              valor: null,
              observacao: null,
              transacaoId: null,
              dataConfirmacao: null,
              confirmacaoId: null,
              status: null,
              motivo: null,
              idReferencia: null,
            },
          },
        },
      },

      funcs: {
        clickPedido: (context) => {
          if (context.dataset.main.detail.idPedido) {
            this.clickPedido();
          } else {
            context.setSnackbar(
              true,
              "PEDIDO",
              "Esta transação não possui pedido. \n Verificar.",
              "warning"
            );
          }
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        posSubmit: (context) => {
          this.params.context = context;
          this.buttonsAdjust(context);
        },

        qrcodeTimeout: () => {
          this.code = `${window.location.origin}/invite/${this.dataset.main.detail.id}`;
          this.copyToClipboard(this.code);
          this.showDialog = true;
        },
      },

      params: {
        context: null,
      },

      code: "",
      showDialog: false,
    };
  },

  /// METHODS
  methods: {
    buttonsAdjust(context) {
      if (
        context.dataset.main.detail.idTipo !=
        this.getTipo_item("transacaoPedido").id
      ) {
        const index = context.buttons.findIndex(
          (button) => button.idFeature == this.getFeature("optionPedidos").id
        );

        if (index >= 0) {
          context.buttons.splice(index, 1);
        }
      }
    },

    clickPedido() {
      this.$router.push(`/pedido/${this.dataset.main.detail.idPedido}`);
    },

    async clickPagarAgora(context) {
      if (
        this.getVar("idPessoaParceiro") == this.getConst("app").id ||
        this.dataset.aux.pagamento.id
      ) {
        this.funcs.qrcodeTimeout();
      } else {
        await this.pagamento(context);
      }
    },

    async pagamento(context) {
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const method = "post";
      const url = `${this.getConst("app").baseUrlBackend}/pagamentos`;

      const devedor = {
        nome: context.dataset.aux.pessoaParceiro.nomeAlt,
      };

      devedor[
        this.getTipo_item("pessoaFisica").id ==
        context.dataset.aux.pessoaParceiro.idTipoPessoa
          ? "cpf"
          : "cnpj"
      ] = context.dataset.aux.pessoaParceiro.documentoFederal;

      const dataset = {
        metadados: {
          idUsuario,
          idPessoaParceiro,
        },
        detail: {
          isPagamento: false,
          isDevolucao: false,
          idTransacao: context.dataset.main.detail.id,
          valor: Math.abs(parseFloat(context.dataset.main.detail.valorSaldo)),
          idUsuario,
        },
        cobranca: {
          calendario: {
            expiracao: this.getConst("config").pix.transacao.expiracao,
          },
          devedor,
          valor: {
            original: context.dataset.main.detail.valorSaldo,
          },
          chave: this.getConst("config").pix.chave,
          solicitacaoPagador: eval(
            this.getConst("config").pix.transacao.solicitacaoPagador
          ),
        },
        dataset: context.dataset,
      };

      const ret = await context.submit(method, url, dataset);

      if (ret && ret.status == 200) {
        this.dataset.aux.pagamento = ret.data;
        this.funcs.qrcodeTimeout();
      }
    },

    async pagamentoConfirmado(context, confirmacaoId) {
      const idUsuario = this.getVar("idUsuario");
      const idPessoaParceiro = this.getVar("idPessoaParceiro");
      const method = "post";
      const url = `${
        this.getConst("app").baseUrlBackend
      }/transacoes/pagamentoConfirmado`;

      this.dataset.metadados.idUsuario = idUsuario;
      this.dataset.metadados.idPessoaParceiro = idPessoaParceiro;

      if (!this.dataset.aux.pagamento.id) {
        this.dataset.aux.pagamento.isPagamento =
          parseFloat(this.dataset.main.detail.valorSaldo) > 0 ? true : false;
        this.dataset.aux.pagamento.isDevolucao = false;
        this.dataset.aux.pagamento.idTransacao = null;
        this.dataset.aux.pagamento.valor = Math.abs(
          parseFloat(this.dataset.main.detail.valorSaldo)
        );
        this.dataset.aux.pagamento.confirmacaoId = confirmacaoId;
        this.dataset.aux.pagamento.status = confirmacaoId ? "CONCLUIDO" : null;
        this.dataset.aux.pagamento.idUsuario = idUsuario;
      }

      const dataset = {
        metadados: { ...this.dataset.metadados },
        detail: {
          ...this.dataset.main.detail,
        },
        pagamento: {
          ...this.dataset.aux.pagamento,
        },
      };

      dataset.main.detail.isPagamentoOnline = true;
      dataset.main.detail.idFormaPagamento = this.getTipo_item(
        "formaPagamentoInstantaneo"
      ).id;

      const ret = await context.submit(method, url, dataset);

      if (ret && ret.status == 200) {
        const text =
          this.dataset.main.detail.valorSaldo > 0
            ? "O processo de pagamento foi concluído."
            : "Obrigado pela confirmação do pagamento.\nAssim que o pagamento for processado, a transação será concluída.";

        context.setSnackbar(true, "PAGAMENTO", text, "success");
      }
    },
  },
};
</script>