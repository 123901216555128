<!-- COD: BJB 14/07/2022 CExpansionPanel -->
<!-- *VER: JER 18/07/2022 CExpansionPanel -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container" class="pa-0">
    <v-row no-gutters>
      <v-expansion-panel v-bind="prop.expansionPanel" :disabled="disabled">
        <v-expansion-panel-header
          v-bind="prop.expansionPanelHeader"
          class="grey lighten-4"
        >
          <v-container v-bind="prop.container">
            <v-row v-bind="prop.row">
              <slot name="header"></slot>
            </v-row>
          </v-container>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-bind="prop.expansionPanelContent"
          class="mt-4"
        >
          <slot name="content"></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-row>
  </v-container>
</template>

<script>
export default {
  /// NAME
  name: "CExpansionPanel",

  /// PROPS
  props: {
    disabled: { type: Boolean, default: true },
  },
};
</script>

<style>
.v-expansion-panel--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>