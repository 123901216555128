<!-- COD: FDS 26/07/2022 CProdutoPageKardexView -->
<!-- *VER: JER 28/07/2022 CProdutoPageKardexView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- produto -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-chip v-bind="prop.chip">
          {{ getOperacao(produto.idOperacao).nome }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- metodo custo -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-2">
          {{ getTipo_item(produto.idMetodoCusto).nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- data produto kardex-->
      <v-col v-bind="prop.col" cols="5">
        <span class="text-body-1">
          {{ dateFormat(produto.dataProdutoKardex, "date") }}
        </span>
      </v-col>
      <!-- nota fiscal-->
      <v-col
        v-bind="prop.col"
        align="center"
        cols="7"
        v-if="produto.notaFiscal"
      >
        <span class="text-body-2">NF</span>
        <span class="ml-0 text-body-1">
          {{ produto.notaFiscal }}
        </span>
      </v-col>
    </v-row>

    <!-- pessoa-->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ produto.pessoaNome }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- quantidade -->
      <v-col v-bind="prop.col" cols="3">
        <div class="grey lighten-5">
          <span class="text-body-2">quant</span>
        </div>
      </v-col>
      <!-- unitario -->
      <v-col v-bind="prop.col" align="end" cols="4">
        <div class="grey lighten-5">
          <span class="text-body-2">unitário</span>
        </div>
      </v-col>
      <!-- total -->
      <v-col v-bind="prop.col" align="end" cols="5">
        <div class="grey lighten-5">
          <span class="text-body-2">total</span>
        </div>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- quantidade -->
      <v-col v-bind="prop.col" cols="2">
        <span
          class="text-body-1"
          :class="getOperacao(produto.idOperacao).isEntrada ? '' : 'red--text'"
        >
          {{
            numberFormat(
              getOperacao(produto.idOperacao).isEntrada
                ? produto.quantidadeItem
                : produto.quantidadeItem * -1
            )
          }}
        </span>
      </v-col>
      <!-- unitario -->
      <v-col v-bind="prop.col" align="end" cols="3">
        <span class="text-body-1">
          {{
            numberFormat(
              parseFloat(produto.custoTotalItem) /
                parseFloat(produto.quantidadeItem),
              "currency"
            )
          }}
        </span>
      </v-col>
      <!-- total -->
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="text-body-1">
          {{ numberFormat(produto.custoTotalItem, "currency") }}
        </span>
      </v-col>
    </v-row>

    <v-row v-bind="prop.row">
      <!-- quantidade kardex -->
      <v-col v-bind="prop.col" cols="2">
        <span class="font-weight-medium text-body-1">
          {{ numberFormat(produto.quantidadeKardex) }}
        </span>
      </v-col>
      <!-- unitario kardex -->
      <v-col v-bind="prop.col" align="end" cols="3">
        <span class="font-weight-medium text-body-1">
          {{
            numberFormat(
              parseFloat(produto.custoTotalKardex) /
                parseFloat(produto.quantidadeKardex),
              "currency"
            )
          }}
        </span>
      </v-col>
      <!-- total kardex -->
      <v-col v-bind="prop.col" align="end" cols="4">
        <span class="font-weight-medium text-body-1">
          {{ numberFormat(produto.custoTotalKardex, "currency") }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CProdutoPageKardexView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    produto: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getOperacao: "operacoes/getOperacao",
      getTipo_item: "tipos/getTipo_item",
    }),
  },
};
</script>