<!-- COD: BJB 10/08/2022 CTheNavbar -->
<!-- *VER: JER 08/09/2022 CTheNavbar -->
<!-- /33/ -->

<template>
  <nav>
    <component
      app
      dark
      :is="component"
      :class="componentClass"
      :color="componentColor"
      :fixed="bottom"
    >
      <!-- :rounded="bottom ? 'xl' : false" -->
      <v-container>
        <v-row no-gutters justify="center">
          <v-col cols="12" sm="10" md="8" lg="6" xl="4">
            <v-row
              v-bind="prop.row"
              v-if="!bottom && title"
              class="ml-n6 mr-n4"
            >
              <v-col v-bind="prop.col" align="start" cols="1">
                <slot name="icon"></slot>
              </v-col>

              <v-col v-bind="prop.col" align="center" cols="10">
                <c-btn
                  v-if="mode == 'page'"
                  classe="text-h6"
                  router
                  text
                  :to="pageModeView ? '/' : null"
                >
                  {{ translate(title) }}
                </c-btn>
                <v-toolbar-title
                  v-if="mode != 'page'"
                  :class="toolbarTitleClass"
                >
                  {{ translate(title) }}
                </v-toolbar-title>

                <!-- ///?ee info provisoria -->
                <span
                  v-if="
                    (getVar('socket') && !getVar('socket').connected) ||
                    getVar('disconnections')
                  "
                  class="ml-0 mt-8 text--overline"
                  :class="
                    getVar('socket') && getVar('socket').connected ? '' : 'red'
                  "
                  overlap
                >
                  {{ getVar("disconnections").toString() }}
                </span>
              </v-col>

              <v-col v-bind="prop.col" align="end" cols="1">
                <v-badge
                  v-if="
                    logado &&
                    pageModeView &&
                    $route.name != 'Notificacoes' &&
                    getVar('pendencias').notificacoes.priority == 0
                  "
                  bottom
                  color="red"
                  :content="getVar('pendencias').notificacoes.value"
                  :dot="pedidosRecebidos && !pedidosRecebidosBadgeDot"
                  overlap
                  :value="
                    logado &&
                    $route.name != 'Notificacoes' &&
                    getVar('pendencias').notificacoes.priority == 0
                  "
                >
                  <c-btn
                    v-if="
                      logado &&
                      $route.name != 'Notificacoes' &&
                      getVar('pendencias').notificacoes.priority == 0
                    "
                    classe="ml-n3 mt-n3"
                    icon
                    large
                    router
                    to="/notificacoes"
                  >
                    <v-icon large>mdi-bell-outline</v-icon>
                  </c-btn>
                </v-badge>

                <div v-else>
                  <slot v-for="slot in slots" :name="slot - 1"></slot>
                </div>
              </v-col>
            </v-row>

            <v-row
              v-bind="prop.row"
              v-else
              class="mx-n6"
              :justify="titleJustify"
            >
              <template v-if="pageModeView">
                <v-badge
                  v-if="mode == 'home'"
                  bottom
                  color="red"
                  :content="pendenciasNavbar"
                  overlap
                  :value="mode == 'home' && pendenciasNavbar"
                >
                  <v-app-bar-nav-icon
                    @click="clickNavbar()"
                  ></v-app-bar-nav-icon>
                </v-badge>
                <v-menu v-model="showBackOptions" top offset-y>
                  <template #activator="{}">
                    <c-btn
                      v-if="mode == 'page'"
                      v-touch="vTouch"
                      icon
                      large
                      @click="clickBack"
                      @mousedown="startTouch"
                      @mouseup="endTouch"
                    >
                      <v-icon large>mdi-arrow-left</v-icon>
                    </c-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(backOption, i) in backOptions"
                      :key="`backOption${i}`"
                      text
                      @click="clickSpecificBack(backOption)"
                    >
                      <v-icon v-if="backOption.icon">
                        {{ backOption.icon }}
                      </v-icon>
                      <v-list-item-title class="text-body-2 ml-2">
                        {{ backOption.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <slot v-for="slot in slots" :name="slot - 1"> </slot>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </component>
    <!-- </v-app-bar> -->

    <!-- //? Onde posicionar o snackbar? -->
    <!-- /// SNACKBAR -->
    <c-snackbar @click="clickSnackbar($event)" :snackbar="snackbar" />

    <!-- /// NAVIGATIONDRAWER -->
    <!-- v-if="mode != 'page'" -->
    <v-navigation-drawer
      v-model="drawer"
      app
      class="primary"
      temporary
      width="320"
    >
      <v-list
        v-touch="{
          down: ($event) => {
            if ($refs.vList.$el.scrollTop == 0) {
              swipe('down', swipeRefresh, $event);
            }
          },
        }"
        dense
        :max-height="windowScreenHeight"
        nav
        ref="vList"
      >
        <v-list-item v-if="!!logado" one-line>
          <v-list-item-avatar class="ml-n1 mt-n2">
            <v-img
              v-if="
                getVar('usuarios').length &&
                getVar('usuarios')[0].imagem.endereco
              "
              alt="imagem"
              :src="getVar('usuarios')[0].imagem.endereco"
              @click="clickAvatar"
            ></v-img>
            <v-icon v-else x-large class="white--text" @click="clickAvatar">
              mdi-account-outline
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="ml-n3 mt-n3">
            <v-list-item-title>
              <c-select
                v-if="!hasApp"
                v-model="idPessoaParceiro"
                dark
                :disabled="$route.name != 'Home'"
                :items="getUsuarios()"
                @change="usuarioChange"
              />
              <c-autocomplete
                v-else-if="hasApp"
                v-model="idParceiro"
                :chipClose="false"
                :clearable="false"
                dark
                dense
                :itemsIniciais="getUsuarios()"
                label=""
                :path="`/pessoas/autocomplete/parceiro/0/0/0/`"
                :rules="[]"
                @change="changeParceiro"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- parceiroCliente -->
        <v-row v-bind="prop.row" v-if="showCliente" class="mt-n8">
          <v-col v-bind="prop.col">
            <c-autocomplete
              v-model="parceiroCliente"
              dark
              dense
              :itemsIniciais="
                parceiroCliente && parceiroCliente.value
                  ? [parceiroCliente]
                  : []
              "
              label="cliente"
              :path="`/pessoas/autocomplete/cliente/0/0/${idPessoaParceiroSelecionado}/`"
              returnObject
              :rules="[]"
              @change="changeCliente"
            />
          </v-col>
        </v-row>

        <template
          v-for="(option, i) in options.filter(
            (option, i) => !option.idContexto && i < indexOptionContext
          )"
        >
          <v-list-item :key="i" @click="clickOption(option)">
            <v-list-item-icon class="mr-4">
              <v-badge
                bottom
                :color="option.pendencias.priority <= 1 ? 'red' : 'blue'"
                :content="option.pendencias.value"
                overlap
                :value="!!option.pendencias.priority"
              >
                <v-icon class="white--text">
                  {{ option.options.icon }}
                </v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <span class="text-h6 font-weight-light white--text">
                {{ translate(option.text) }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template
          v-for="(option, i) in options.filter(
            (option, i) => !option.idContexto && i > indexOptionContext
          )"
        >
          <v-list-item :key="i" @click="clickOption(option)" class="mt-4 mb-8">
            <v-list-item-icon class="mr-4">
              <v-badge
                bottom
                :color="option.pendencias.priority <= 1 ? 'red' : 'blue'"
                :content="option.pendencias.value"
                overlap
                :value="!!option.pendencias.priority"
              >
                <v-icon class="white--text">
                  {{ option.options.icon }}
                </v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <span class="text-h6 font-weight-light white--text">
                {{ translate(option.text) }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-expansion-panels class="mt-n5" flat v-model="panelOpen">
          <v-expansion-panel
            v-for="(optionsModulo, i) in optionsModulos"
            :key="i"
            class="mb-n4 pa-0 primary"
          >
            <v-expansion-panel-header>
              <span
                class="font-weight-light text-h6 text-uppercase"
                :class="`${getContextColor(optionsModulo)}--text`"
              >
                {{ translate(getTipo_item(optionsModulo).nomeAlt) }}
              </span>
              <template v-slot:actions>
                <v-icon class="white--text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container
                v-for="(option, i) in options.filter(
                  (option) => option.idContexto == optionsModulo
                )"
                class="px-0"
                :key="i"
              >
                <v-list-item @click="clickOption(option)" class="mt-n6">
                  <v-row v-bind="prop.row">
                    <v-list-item-icon class="ml-n5 mr-4">
                      <v-badge
                        bottom
                        :color="
                          option.pendencias.priority <= 1 ? 'red' : 'blue'
                        "
                        :content="option.pendencias.value"
                        overlap
                        :value="!!option.pendencias.priority"
                      >
                        <v-icon class="white--text">
                          {{ option.options.icon }}
                        </v-icon>
                      </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <span class="text-h6 font-weight-light white--text">
                        {{ translate(option.text) }}
                      </span>
                    </v-list-item-content>
                  </v-row>
                </v-list-item>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mb-n4 pa-0 primary">
            <v-expansion-panel-header
              class="text-h6 font-weight-light white--text"
            >
              <span>
                {{ translate("PREFERÊNCIAS") }}
              </span>
              <template v-slot:actions>
                <v-icon class="white--text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-list-item>
                <v-list-item-icon class="ml-n4 mr-3">
                  <v-icon class="mt-5 white--text">mdi-translate</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col">
                      <c-menu
                        v-model="$vuetify.lang.current"
                        classe="ml-n3"
                        :items="localesVuetify"
                        @change="changeLocale"
                      >
                        <template #btn="{ itemSelected }">
                          <v-img
                            :src="itemSelected.image"
                            width="25"
                            class="mr-2"
                          />
                          <span
                            v-if="itemSelected"
                            class="text=body-2 white--text"
                          >
                            {{ itemSelected.text }}
                          </span>
                        </template>
                        <template #item="{ item }">
                          <v-img :src="item.image" width="25" class="mr-2" />
                          <v-list-item-title class="text-body-2">
                            {{ item.text }}
                          </v-list-item-title>
                        </template>
                      </c-menu>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-n7">
                <v-list-item-icon class="ml-n4 mr-3">
                  <v-icon class="mt-8 white--text">mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col">
                      <c-menu
                        v-model="idPaisConfiguracaoRegional"
                        classe="ml-n3 mt-3"
                        :items="getConfiguracoesRegionais"
                      >
                        <template #btn="{ itemSelected }">
                          <v-img
                            :src="itemSelected.image"
                            width="25"
                            class="mr-2"
                          />
                          <span
                            v-if="itemSelected"
                            class="text=body-2 white--text"
                          >
                            {{ itemSelected.text }}
                          </span>
                        </template>
                        <template #item="{ item }">
                          <v-img :src="item.image" width="25" class="mr-2" />
                          <v-list-item-title class="text-body-2">
                            {{ item.text }}
                          </v-list-item-title>
                        </template>
                      </c-menu>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="brightnessMode" class="mt-n7">
                <v-list-item-content class="ml-n4">
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col">
                      <c-menu
                        v-model="idBrightnessMode"
                        classe="ml-n3 mt-3"
                        :items="brightnessModes"
                        @change="brightnessAuto"
                      >
                        <template #btn="{ itemSelected }">
                          <v-icon
                            class="mr-2"
                            :class="itemSelected.options.color"
                          >
                            {{ itemSelected.options.icon }}
                          </v-icon>
                          <span
                            v-if="itemSelected"
                            class="text=body-2 white--text"
                          >
                            {{ itemSelected.text }}
                          </span>
                        </template>
                        <template #item="{ item }">
                          <v-icon class="primary--text mr-2">
                            {{ item.options.icon }}
                          </v-icon>
                          <v-list-item-title class="text-body-2">
                            {{ item.text }}
                          </v-list-item-title>
                        </template>
                      </c-menu>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-n5 ml-n6">
                <v-list-item-content>
                  <v-row v-bind="prop.row">
                    <v-col v-bind="prop.col">
                      <c-btn icon @click="clickBarraInferiorColorida">
                        <v-icon color="white">mdi-color-helper</v-icon>
                      </c-btn>
                      <c-btn
                        classe="ml-n3 white--text"
                        text
                        @click="clickBarraInferiorColorida"
                      >
                        {{ labelBarraInferiorColorida }}
                      </c-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row v-bind="prop.row" v-if="showBaterPonto" class="mt-10">
          <v-col v-bind="prop.col" align="center">
            <c-btn class="mb-3" color="primary" fab @click="clickPonto">
              <v-icon x-large dark>mdi-fingerprint</v-icon>
            </c-btn>
          </v-col>
        </v-row>
      </v-list>
    </v-navigation-drawer>
    <c-pontoEletronico-dialog-pontoEletronico
      v-if="showPontoEletronico"
      v-model="showPontoEletronico"
    />
    <c-folhaPagamento-dialog-holerite
      v-if="showHolerite"
      v-model="showHolerite"
      :idPessoaColaborador="getVar('idUsuario')"
    />
    <!-- dialog incluir pessoa -->
    <c-base-dialog-pessoa-incluir
      v-if="showPessoaIncluir"
      :idFeature="getFeature('optionPageEmpresa').id"
      :idPagina="getPagina('pessoa').id"
      :params="{}"
      @close="closeDialogPessoa($event)"
    />
  </nav>
</template>

<script>
/// IMPORT
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { differenceInMinutes, getHours } from "date-fns";
import CBaseDialogPessoaIncluir from "@/components/bases/CBaseDialogPessoaIncluir";
import CFolhaPagamentoDialogHolerite from "@/components/pages/folhaPagamento/dialogs/CFolhaPagamentoDialogHolerite.vue";
import CPontoEletronicoDialogPontoEletronico from "@/components/pages/pontoEletronico/pontoEletronico/CPontoEletronicoDialogPontoEletronico.vue";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinData from "@/mixins/mixinData";
import mixinPendencias from "@/mixins/mixinPendencias";
import mixinSnackbar from "@/mixins/mixinSnackbar";
import mixinSwipe from "@/mixins/mixinSwipe";
import mixinUsuarios from "@/mixins/mixinUsuarios";
import mixinUtil from "@/mixins/mixinUtil";

export default {
  /// NAME
  name: "CTheNavbar",

  ///COMPONENTS
  components: {
    CBaseDialogPessoaIncluir,
    CFolhaPagamentoDialogHolerite,
    CPontoEletronicoDialogPontoEletronico,
  },

  /// MIXINS
  mixins: [
    mixinCarrinhos,
    mixinData,
    mixinPendencias,
    mixinSnackbar,
    mixinSwipe,
    mixinUsuarios,
    mixinUtil,
  ],

  /// PROPS
  props: {
    bottom: { type: Boolean, default: false },
    mode: { type: String, required: true },
    pageModeView: { type: Boolean, default: true },
    slots: { type: Number, default: 1 },
    title: { type: String, required: true },
  },

  /// DATA
  data() {
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];
    return {
      backClickPressed: false,
      isBarraInferiorColorida: getVar("isBarraInferiorColorida"),
      changeUsuariosRunning: false,
      drawer: false,
      idBrightnessMode: getTipo_item("modoClaro").id,
      idPaisConfiguracaoRegional: getVar("idPaisConfiguracaoRegional"),
      idParceiro: getVar("idPessoaParceiro"),
      idPessoaParceiro: getVar("idPessoaParceiro"),
      idTipoPolitica: null,
      panelOpen: null,
      parceiroCliente: getVar("parceiroCliente"),
      pedidosRecebidosBadgeDot: false,
      showBackOptions: false,
      showHolerite: false,
      showPessoaIncluir: false,
      showPolitica: false,
      showPontoEletronico: false,
      timerBadgeDot: null,
      vTouch: {
        start: ($event) => {
          this.startTouch($event);
        },
        end: ($event) => {
          this.endTouch($event);
        },
      },
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getCategoria: "categorias/getCategoria",
      getConst: "constantes/getConst",
      getFeature: "paginas/getFeature",
      getLocalidade_idioma: "localidades/getLocalidade_idioma",
      getLocalidade_idiomas: "localidades/getLocalidade_idiomas",
      getLocalidade: "localidades/getLocalidade",
      getLocalidades: "localidades/getLocalidades",
      getPagina: "paginas/getPagina",
      getTipo_item: "tipos/getTipo_item",
      getTipo_itens: "tipos/getTipo_itens",
      getTipo: "tipos/getTipo",
      getUsuario: "variaveis/getUsuario",
      getUsuarios: "variaveis/getUsuarios",
      getVar: "variaveis/getVar",
    }),

    backOptions() {
      const rotas = this.getVar("rotas")
        .map((rota, i) => {
          return {
            ...rota,
            index: i,
          };
        })
        .filter((rota) => rota.name != "Home")
        .filter((rota, i, arr) => i >= arr.length - 4 && i != arr.length - 1);

      rotas.unshift({ name: "home", fullPath: "/", icon: "mdi-home-outline" });

      rotas.unshift({
        name: "menu lateral",
        function: this.clickNavbar,
        icon: "mdi-menu",
      });

      return rotas;
    },

    brightnessMode() {
      return this.brightnessModes.find(
        (brightness) => brightness.value == this.idBrightnessMode
      );
    },

    brightnessModes() {
      return this.getTipo_itens(this.getTipo("modoBrilho").id)
        .map((item) => this.getTipo_item(item.value))
        .map((item) => {
          return {
            value: item.id,
            text: this.translate(item.nomeAlt),
            options: item.options,
          };
        });
    },

    component() {
      return this.bottom ? "v-footer" : "v-app-bar";
    },

    componentClass() {
      return this.bottom ? "ma-0 pt-0 pb-4" : "";
    },

    componentColor() {
      return this.bottom
        ? this.hexToRgba(this.getVar("isBarraInferiorColorida"))
        : "primary";
    },

    currOptionsNavBar() {
      const optionsNavBar = this.getVar("optionsNavBar");

      return optionsNavBar.find(
        (option) => option.idPessoaParceiro == this.idPessoaParceiro
      );
    },

    getConfiguracoesRegionais() {
      return this.getLocalidades(this.getTipo_item("localidadePais").id)
        .map((localidade) => this.getLocalidade(localidade.value))
        .filter((localidade) => localidade.hasConfiguracaoRegional)
        .map((localidade) => {
          return {
            value: localidade.id,
            text: this.translate(localidade.nome),
            image: localidade.endereco,
          };
        });
    },

    hasApp() {
      return !!this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getConst("app").id
      );
    },

    hasParceiro() {
      return !!this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.idParceiro
      );
    },

    idPage() {
      return this.getPagina("home").id;
    },

    idPessoaParceiroSelecionado() {
      return this.idParceiro || this.idPessoaParceiro;
    },

    indexOptionContext() {
      return this.options.findIndex((option) => option.idContexto);
    },

    labelBarraInferiorColorida() {
      return this.isBarraInferiorColorida
        ? "barra colorida"
        : "barra não colorida";
    },

    localesVuetify() {
      return this.getLocalidade_idiomas()
        .filter((locale) => this.getLocalidade_idioma(locale.value).isLocale)
        .map((locale) => {
          return {
            value: this.localeById(locale.value).substring(0, 2),
            text: this.localeById(locale.value, false),
            idLocale: locale.value,
            locale: this.localeById(locale.value),
            image: this.getLocalidade(
              this.getLocalidade_idioma(locale.value).idPai
            ).endereco,
            id: locale.value,
          };
        })
        .sort((a, b) => (a.text < b.text ? -1 : 1));
    },

    logado() {
      return !!this.getVar("idPessoaParceiro");
    },

    options() {
      if (
        this.currOptionsNavBar?.options &&
        this.currOptionsNavBar?.options.length > 3
      ) {
        return this.currOptionsNavBar.options;
      } else {
        const params = '{ "groupBy": 1, "order": 1 }';

        return this.direitosFeatures(
          this.idPage,
          this.getTipo_item("featureOption").id,
          false
        )
          .map((feature) => {
            return {
              id: feature.idFeature,
              idContexto: feature.idContexto,
              group: feature.groupBy,
              order: feature.order,
              text: feature.nomeAlt,
              options: feature.options,
              pendencias: this.getVar("pendencias")[feature.nome] || {
                priority: null,
                value: null,
              },
            };
          })
          .filter(
            (feature) =>
              feature.id != this.getFeature("optionPageAgendas").id ||
              (this.idPessoaParceiro &&
                this.getVar("usuarios")
                  .find((usuario) => usuario.pessoa.id == this.idPessoaParceiro)
                  .parceiro.categorias?.some(
                    (idCategoria) =>
                      this.getCategoria(idCategoria).allowParceiroUsarAgenda
                  ))
          )
          .sortKeys(params);
      }
    },

    optionsModulos() {
      if (this.currOptionsNavBar?.optionsModulos) {
        return this.currOptionsNavBar.optionsModulos;
      } else {
        return [
          ...new Set(
            this.options
              .filter((option) => !!option.idContexto)
              .map((option) => option.idContexto)
          ),
        ];
      }
    },

    pedidosRecebidos() {
      return (
        this.getVar("pendencias").pedidos.priority == 1 &&
        this.perfisTestar([
          this.getTipo_item("perfilAdmin").id,
          this.getTipo_item("perfilVendas").id,
        ])
      );
    },

    pendenciasNavbar() {
      return Object.values(this.getVar("pendencias")).filter(
        (pendencia, i) => i > 1 && pendencia.priority && pendencia.priority <= 1
      ).length;
    },

    showBaterPonto() {
      if (this.getVar("idPessoaParceiro") != this.getVar("idUsuario")) {
        return this.getUsuario(
          this.getVar("idPessoaParceiro")
        ).servicosContratados?.some(
          (servicoContratado) =>
            servicoContratado.produto == "folha pagamento" &&
            new Date(servicoContratado.dataInicio) <= new Date()
        );
      } else {
        return false;
      }
    },

    showCliente() {
      const servicosERP = ["ERP pedidos", "ERP faturamento"];

      const hasServicoERPContratado = this.getUsuario(
        this.getVar("idPessoaParceiro")
      ).servicosContratados?.some(
        (servicoContratado) =>
          servicosERP.includes(servicoContratado.produto) &&
          new Date(servicoContratado.dataInicio) <= new Date()
      );
      if (!hasServicoERPContratado) {
        return false;
      } else if (this.idPessoaParceiro != this.getConst("app").id) {
        return true;
      } else if (this.idParceiro) {
        return true;
      }

      return false;
    },

    tiposPedido() {
      return this.getTipo_itens(this.getTipo("tipoPedido").id).filter(
        () =>
          !!this.idPessoaParceiro &&
          !!this.getUsuario(this.idPessoaParceiro).parceiro.id
      );
    },

    titleJustify() {
      return this.title ? "center" : "space-around";
    },

    toolbarTitleClass() {
      return this.mode == "home" && this.title.length <= 15
        ? "text-h4"
        : "text-h6";
    },

    valueWatchParceiroCliente() {
      return this.getVar("parceiroCliente").value;
    },

    windowScreenHeight() {
      return `${window.screen.height}px`;
    },
  },

  /// WATCH
  watch: {
    isBarraInferiorColorida() {
      this.setVar({
        isBarraInferiorColorida: this.isBarraInferiorColorida,
      });
    },

    drawer() {
      if (this.drawer) {
        this.optionsUpdate();
      }
    },

    idPaisConfiguracaoRegional() {
      this.setVar({
        idPaisConfiguracaoRegional: this.idPaisConfiguracaoRegional,
      });
    },

    idPessoaParceiro() {
      if (!this.changeUsuariosRunning) {
        this.setVar({ idPessoaParceiro: this.idPessoaParceiro });
        this.navbarPendenciasAtualizar();
      }
    },

    logado() {
      this.idPessoaParceiro = this.getVar("idPessoaParceiro");
      this.idParceiro = this.idPessoaParceiro;

      this.usuarioChange();
    },

    pedidosRecebidos() {
      this.changePedidosRecebidosBadgeDot();
    },

    valueWatchParceiroCliente() {
      this.parceiroCliente = this.getVar("parceiroCliente");
      this.changeCliente();
    },
  },

  /// MOUNTED
  mounted() {
    const idioma =
      navigator.browserLanguage != undefined
        ? navigator.browserLanguage
        : navigator.language;
    this.$vuetify.lang.current = idioma ? idioma.substring(0, 2) : "pt";

    this.changePedidosRecebidosBadgeDot();
  },

  /// METHODS
  methods: {
    ...mapActions({
      setVar: "variaveis/setVar",
    }),

    brightnessAuto() {
      let darkMode = this.brightnessMode.options.isDark;

      if (darkMode == null) {
        const hora = getHours(new Date());
        darkMode = hora <= 6 || hora > 18;
      }

      this.$vuetify.theme.dark = darkMode;
    },

    changeCliente() {
      const parceiroCliente = {
        idPessoa: this.parceiroCliente?.value || null,
        nome: this.parceiroCliente?.nome || null,
        idTipo: this.parceiroCliente?.idTipo || null,
        enderecos: this.parceiroCliente?.enderecos || null,
        value: this.parceiroCliente?.value || null,
        text: this.parceiroCliente?.text || this.parceiroCliente?.nome || null,
        textAlt:
          this.parceiroCliente?.textAlt || this.parceiroCliente?.nome || null,
      };

      this.setVar({ parceiroCliente: parceiroCliente });

      const idPessoaEndereco = parceiroCliente.enderecos
        ? parceiroCliente.enderecos[0].id
        : this.getVar("usuarios").filter(
            (usuario) => usuario.pessoa.id == this.idPessoaParceiro
          )[0]?.enderecos[0]?.id;

      this.setVar({ idPessoaEndereco });
      this.carrinhosAtualizar();
    },

    async changeParceiro() {
      this.idPessoaParceiro = this.getVar("usuarios")[0].pessoa.id;

      if (!this.hasParceiro) {
        await this.usuariosAtualizar(this.idParceiro);
      } else {
        await this.usuariosAtualizar();
      }

      if (this.idParceiro) {
        this.idPessoaParceiro = this.idParceiro;
      } else {
        this.idParceiro = this.idPessoaParceiro;
      }

      this.usuarioChange();
      this.parceiroCliente = null;
      this.changeCliente();
    },

    changePedidosRecebidosBadgeDot() {
      if (this.pedidosRecebidos) {
        if (this.timerBadgeDot) {
          clearInterval(this.timerBadgeDot);
          this.timerBadgeDot = null;
        }
        this.timerBadgeDot = setInterval(() => {
          this.pedidosRecebidosBadgeDot = !this.pedidosRecebidosBadgeDot;
          if (!this.pedidosRecebidos) {
            this.pedidosRecebidosBadgeDot = false;
            clearInterval(this.timerBadgeDot);
            this.timerBadgeDot = null;
          }
        }, this.getConst("config").alerta.timeout);
      }
    },

    changeLocale(event) {
      const locale = this.localesVuetify.find(
        (locale) => locale.value == event
      );

      this.setVar({ locale: locale.locale });
      this.setVar({ idLocale: locale.idLocale });
    },

    clickAvatar() {
      this.$router.push(`pessoa/${this.idPessoaParceiro}`);
    },

    clickBack() {
      !this.showBackOptions ? this.$router.go(this.getVar("goBack")) : null;
    },

    clickBackOption(backOption) {
      const rotas = this.getVar("rotas");
      if (backOption.name == "home") {
        this.setVar({ rotas: [] });
        this.$router.push(backOption.fullPath);
      } else {
        this.$router.go(backOption.index - (rotas.length - 1));
      }
    },

    clickBarraInferiorColorida() {
      this.isBarraInferiorColorida = !this.isBarraInferiorColorida;
    },

    clickNavbar() {
      this.drawer = true;
      this.navbarAtualizar(false);
    },

    clickOption(option) {
      this.drawer = false;

      if (option.text == "entrar") {
        this.$emit("auth");
      } else if (option.text == "sair") {
        this.setSnackbar(
          true,
          "SAIR",
          "Fazer logout?",
          "warning",
          "mdi-check",
          "logout"
        );
      } else {
        let route = option.options.route;

        if (option.id == this.getFeature("optionPageConvites").id) {
          if (this.getVar("pendencias").convites.priority == 1) {
            route += `/${this.getFeature("filterConvitesNaoAceitos").id}`;
          } else if (this.getVar("pendencias").convites.priority == 2) {
            route += `/${this.getFeature("filterPendentes").id}`;
          }
        } else if (option.id == this.getFeature("optionPagePedidos").id) {
          if (this.getVar("pendencias").pedidos.priority == 1) {
            route += `/${this.getFeature("filterMinhasPendencias").id}`;
          } else if (this.getVar("pendencias").pedidos.priority == 2) {
            route += `/${this.getFeature("filterPedidosAndamento").id}`;
          }
        } else if (option.id == this.getFeature("optionPageAvaliacoes").id) {
          if (this.getVar("pendencias").avaliacoes.priority == 1) {
            if (this.idAtuacao == this.getTipo_item("atuacaoApp").id) {
              route += `/${this.getFeature("filterModeracoesPendentes").id}`;
            } else {
              route += `/${this.getFeature("filterRespostasPendentes").id}`;
            }
          } else {
            route += `/${this.getFeature("filterAvaliacoesPendentes").id}`;
          }
        } else if (option.id == this.getFeature("optionPageHolerite").id) {
          this.showHolerite = true;
          return;
        } else if (option.id == this.getFeature("optionPageEmpresa").id) {
          this.showPessoaIncluir = true;
          return;
        } else {
          if (
            this.getVar("pendencias")[option.text.normalizeBr()]?.priority >= 1
          ) {
            route += `/${this.getFeature("filterPendentes").id}`;
          }
        }

        this.$router.push(route);
      }
    },

    async clickPonto() {
      this.showPontoEletronico = true;
    },

    clickSnackbar(action) {
      if (action == "login") {
        this.$emit("auth");
      }
      if (action == "logout") {
        this.logout();
      }

      this.setSnackbar();
    },

    clickSpecificBack(backOption) {
      return backOption.fullPath
        ? this.clickBackOption(backOption)
        : backOption.function();
    },

    closeDialogPessoa(event) {
      this.showPessoaIncluir = false;

      if (event?.id) {
        this.$router.push(`/pessoa/${event.id}`);
      }
    },

    endTouch() {
      this.backClickPressed = false;
    },

    getContextColor(idContexto) {
      return (
        this.options.find((option) => option.idContexto == idContexto)?.color ||
        "white"
      );
    },

    async logout() {
      this.setVar({ logout: true });

      this.$vuetify.theme.themes.light.primary = this.getUsuario(
        this.getVar("idUsuario")
      ).parceiro.corPrimaria;

      const userData = JSON.parse(
        await localStorage.getItem(this.getConst("app").userKey)
      );

      const id = userData.metadados.idLogin;
      const isLogoutUsuario = true;
      const url = `${
        this.getConst("app").baseUrlBackend
      }/signout/${id}/${isLogoutUsuario}`;

      await axios
        .put(url)
        .then()
        .catch((err) => {
          this.setSnackbar(true, "ERRO", err.response.data, "error");
        })
        .finally(() => {
          setTimeout(() => {
            this.setVar({ overlay: false });
          }, this.getConst("config").overlay.timeout);
        });

      this.setVar({ keepAlive: false });
      this.setVar({ usuarios: [] });

      this.setVar({ idUsuario: null });
      this.setVar({ idPessoaParceiro: null });
      this.setVar({ idPessoaEndereco: null });
      localStorage.removeItem(this.getConst("app").userKey);
      this.navbarPendenciasLimpar();
      setTimeout(() => {
        this.setVar({ keepAlive: true });
        if (this.$router.history.current.path != "/") {
          this.$router.push("/");
        }
      }, 10);
    },

    navbarAtualizar(atualizar) {
      const tempo = differenceInMinutes(
        new Date(),
        this.getVar("atualizacao").pendencias || 0
      );

      if (atualizar || tempo >= this.getConst("config").pendenciasAtualizar) {
        this.navbarPendenciasAtualizar();
      }

      if (!this.getVar("atualizacao").usuarios) {
        this.usuariosAtualizar();
      }

      this.brightnessAuto();
    },

    navbarPendenciasAtualizar() {
      if (!this.getVar("usuarios").length) return;

      this.pendenciasAtualizar(this.optionsUpdate);
    },

    navbarPendenciasLimpar() {
      this.pendenciasLimpar();

      this.options.forEach((element) => {
        element.pendencias = { priority: null, value: null };
      });
    },

    optionsUpdate() {
      this.options.forEach(
        (option) =>
          (option = {
            ...option,
            pendencias: this.getVar("pendencias")[option.nome] || {
              priority: null,
              value: null,
            },
          })
      );
    },

    politica(idTipoPolitica) {
      this.showPolitica = true;
      this.idTipoPolitica = idTipoPolitica;
    },

    startTouch() {
      this.backClickPressed = true;

      setTimeout(() => {
        if (this.backClickPressed) {
          this.showBackOptions = true;
          this.$emit("showMenuVoltar");
        }
      }, 500);
    },

    swipeRefresh() {
      this.navbarAtualizar(true);
    },

    async usuarioChange() {
      this.panelOpen = [];
      this.changeUsuariosRunning = true;

      const isParceiro = !!this.getVar("usuarios").find(
        (usuario) => usuario.pessoa.id == this.getVar("idPessoaParceiro")
      )?.parceiro?.id;

      if (this.idPessoaParceiro == this.getConst("app").id) {
        this.setVar({
          idAtuacao: this.getTipo_item("atuacaoApp").id,
        });
      } else if (isParceiro) {
        this.setVar({
          idAtuacao: this.getTipo_item("atuacaoParceiro").id,
        });
      } else {
        this.setVar({
          idAtuacao: this.getTipo_item("atuacaoCliente").id,
        });
      }

      this.setVar({
        idPessoaEndereco: this.getVar("usuarios").filter(
          (usuario) => usuario.pessoa.id == this.idPessoaParceiro
        )[0]?.enderecos[0]?.id,
      });

      this.setVar({ idPessoaParceiro: this.idPessoaParceiro });
      this.pendenciasAtualizar(this.optionsUpdate);

      setTimeout(() => {
        this.changeUsuariosRunning = false;
      }, 100);
    },
  },
};
</script>

<style>
.appbar {
  position: fixed;
  top: 92%;
}
</style>