<!-- COD: FDS 05/07/2022 ValorPagRec -->
<!-- *VER: JER 08/07/2022 ValorPagRec -->
<!-- /11/ -->

<template>
  <div>
    <c-base-detail
      v-if="load"
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="context.showEstado = false"
            @close="context.showEstado = false"
          />

          <c-valorPagRec-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-valorPagRec-edit v-else :context="context" :dataset="dataset" />
        </div>
      </template>
    </c-base-detail>
    <c-valorPagRec-dialog-cheque
      v-if="dataset.main.detail.id && showCheque"
      v-model="showCheque"
      :dataset="dataset"
    />
  </div>
</template>

<script>
/// IMPORT
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CValorPagRecDialogCheque from "@/components/pages/valorPagRec/dialogs/CValorPagRecDialogCheque.vue";
import CValorPagRecEdit from "@/components/pages/valorPagRec/CValorPagRecEdit";
import CValorPagRecView from "@/components/pages/valorPagRec/CValorPagRecView";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "ValorPagRec",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogEstado,
    CValorPagRecDialogCheque,
    CValorPagRecEdit,
    CValorPagRecView,
  },

  /// MIXINS
  mixins: [mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("valorPagRec").id,
      idPageAlt: getPagina("valoresPagRec").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("valorRec").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            idPessoaParceiroContaBancaria: null,
            idPessoa: null,
            idFormaPagamento: null,
            numeroDocumento: null,
            motivoDevolucao: null,
            banco: null,
            agencia: null,
            conta: null,
            emitenteNome: null,
            emitenteDocumento: null,
            nominal: null,
            localEmissao: null,
            dataEmissao: null,
            dataApropriacao: null,
            valor: null,
            saldoAssociar: null,
            identificador: null,
            historico: null,
            observacao: null,
            observacoes: null,
            dataEstado: null,
            dataAtualizacao: null,
          },
          contasPagRec: [],
          depositosBancarios: [],
          extratos: [],
        },
        aux: {
          detail: {
            pessoaParceiro: null,
            pessoa: null,
          },
          contasBancarias: [],
          contasPagRec: [],
          depositosBancarios: [],
          extratos: [],
          autocomplete: {
            pessoas: [],
          },
        },
      },

      funcs: {
        clickContasPagRecAssociar: (context) => {
          this.$router.push(
            `/valorPagRec/contasPagRec/${context.dataset.main.detail.id}`
          );
        },

        clickRelatorioCheque: () => {
          this.showCheque = true;
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
          }

          context.setSnackbar();
        },

        fnParams: () => {
          return JSON.stringify({
            idPessoaParceiro: getVar("idPessoaParceiro"),
          });
        },
      },

      params: {
        context: null,
      },

      showCheque: false,
    };
  },

  /// MOUNTED
  async mounted() {
    if (!this.getCategoriasLength()) {
      await this.inicio();
    } else {
      this.load = true;
    }
  },
};
</script>