<!-- COD: FDS 01/07/2022 Feedbacks -->
<!-- *VER: JER 04/07/2022 Feedbacks -->
<!-- /11/ -->

<template>
  <c-base-list
    v-slot="{ dataset, order }"
    :funcs="funcs"
    :idPage="idPage"
    :idPageAlt="idPageAlt"
  >
    <c-base-list-item :dataset="dataset" :idPage="idPage" :order="order">
      <c-feedbacks :dataset="dataset" />
    </c-base-list-item>
  </c-base-list>
</template>

<script>
/// IMPORT
import CFeedbacks from "@/components/pages/feedback/CFeedbacks";
import mixinPageList from "@/mixins/mixinPageList";

export default {
  /// NAME
  name: "Feedbacks",

  /// COMPONENTS
  components: {
    CFeedbacks,
  },

  /// MIXINS
  mixins: [mixinPageList],

  /// DATA
  data() {
    const getPagina = this.$store.getters["paginas/getPagina"];

    return {
      idPage: getPagina("feedbacks").id,
      idPageAlt: getPagina("feedback").id,

      funcs: {
        fnParams: () => {
          return JSON.stringify(this.params);
        },

        posSubmit: (dataset) => {
          dataset.tipo = this.getTipo_item(dataset.idTipo).nomeAlt;
          dataset.estado = this.getEstado(dataset.idEstado).nomeAlt;
        },
      },

      params: {},
    };
  },
};
</script>