var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({},'v-container',_vm.prop.container,false),[_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"align":"center"}},'v-col',_vm.prop.col,false),[_c('v-chip-group',{staticStyle:{"width":"fit-content"},model:{value:(_vm.produto.localizacao),callback:function ($$v) {_vm.$set(_vm.produto, "localizacao", $$v)},expression:"produto.localizacao"}},_vm._l((_vm.localizacoes),function(localizacao,i){return _c('v-chip',{key:i,class:_vm.localizacaoClass(localizacao),attrs:{"disabled":_vm.context.funcs.changed(_vm.context),"outlined":_vm.localizacaoOutlined(localizacao),"small":"","value":localizacao.value},on:{"click":function($event){return _vm.clickLocalizacao(_vm.context, _vm.dataset)}}},[_vm._v(" "+_vm._s(localizacao.text)+" ")])}),1)],1)],1),_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"disabled":"","label":"un/cx","max":13,"options":{
          length: 11,
          precision: _vm.produto.decimalEstoque,
          style: 'decimal',
        }},model:{value:(_vm.produto.unidadesPorCaixa),callback:function ($$v) {_vm.$set(_vm.produto, "unidadesPorCaixa", $$v)},expression:"produto.unidadesPorCaixa"}})],1),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"disabled":!_vm.parceiroInventario,"label":"caixas","max":13,"options":{
          length: 11,
          precision: _vm.produto.decimalEstoque,
          style: 'decimal',
        },"required":_vm.isRequired},model:{value:(_vm.produto.caixas),callback:function ($$v) {_vm.$set(_vm.produto, "caixas", $$v)},expression:"produto.caixas"}})],1),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-money',{attrs:{"disabled":!_vm.parceiroInventario,"label":"unidades","max":13,"options":{
          length: 11,
          precision: _vm.produto.decimalEstoque,
          style: 'decimal',
        },"required":_vm.isRequired},model:{value:(_vm.produto.unidades),callback:function ($$v) {_vm.$set(_vm.produto, "unidades", $$v)},expression:"produto.unidades"}})],1),_c('v-col',_vm._b({attrs:{"cols":"3"}},'v-col',_vm.prop.col,false),[_c('c-text-field',{attrs:{"disabled":"","clearable":false,"label":"inventario","max":20},model:{value:(_vm.inventario),callback:function ($$v) {_vm.inventario=$$v},expression:"inventario"}})],1)],1),(_vm.showColaboradores && _vm.produto.dataContagem)?_c('v-row',_vm._b({},'v-row',_vm.prop.row,false),[_c('v-col',_vm._b({},'v-col',_vm.prop.col,false),[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(((_vm.dateFormat(_vm.produto.dataContagem, "date timeNoSecond")) + " " + (_vm.produto.colaborador)))+" ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }