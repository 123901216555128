<!-- COD: LYF 06/07/2022 Pedido -->
<!-- *VER: JER 08/08/2022 Pedido -->
<!-- /33/ -->

<template>
  <div>
    <c-base-detail
      v-model="dataset"
      :funcs="funcs"
      :idPage="idPage"
      :idPageAlt="idPageAlt"
      :params="params"
    >
      <!-- /// DETAIL -->
      <template v-slot="{ context, page, pageModeView }">
        <!-- /// PAGE-MODE-INSERT -->
        <c-base-detail-insert
          v-if="dataset.main.detail.idTipo == 0"
          :dataset="dataset"
          :idTipo="getTipo(`tipo${page.nome.firstLetterToUpperCase()}`).id"
        />
        <!-- /// PAGE-MODE-NO-INSERT (VIEW/EDIT) -->
        <div v-else>
          <!-- // BOTOES-ESTADO -->
          <c-base-dialog-estado
            v-if="dataset.main.detail.id && context.showEstado"
            :context="context"
            :datasetTemp="JSON.parse(JSON.stringify(context.dataset))"
            :idEstadoNext="context.idEstadoNext"
            :idPage="idPage"
            :idPageAlt="idPageAlt"
            :isEstadoCancelar="context.isEstadoCancelar"
            :params="paramsCamposEspecificos()"
            :show="context.showEstado"
            :value="JSON.parse(JSON.stringify(context.dataset))"
            @alterado="alterado(context)"
            @close="
              context.showEstado = false;
              context.idEstadoNext = null;
              context.isEstadoCancelar = false;
            "
          >
            <template #camposEspecificos="{ datasetTemp }">
              <v-row
                v-bind="prop.row"
                v-if="
                  getTipo_item(`pedidoEntrada`).id ==
                    dataset.main.detail.idTipo &&
                  dataset.main.detail.idEstado == getEstado('faturamento').id &&
                  datasetTemp.main.detail.idEstado ==
                    getEstado('recebimento').id
                "
              >
                <!-- nota fiscal -->
                <v-col v-bind="prop.col" cols="5">
                  <c-text-field
                    v-model="params.notaFiscal"
                    label="nota fiscal"
                    :max="25"
                  />
                </v-col>
                <!-- nota fiscal serie -->
                <v-col v-bind="prop.col" cols="2">
                  <c-text-field
                    v-model="params.notaFiscal_serie"
                    class="ml-2"
                    id="serie"
                    label="série"
                    :max="1"
                  />
                </v-col>
                <!-- nota fiscal data -->
                <v-col v-bind="prop.col" cols="5">
                  <c-datetime-picker
                    v-model="params.notaFiscal_data"
                    classe="ml-2 pr-1 text-body-1"
                    clearable
                    date
                    label="data emissão"
                  />
                </v-col>
              </v-row>
            </template>
          </c-base-dialog-estado>

          <c-pedido-view
            v-if="pageModeView"
            :context="context"
            :dataset="dataset"
          />
          <c-pedido-edit v-else :context="context" :dataset="dataset" />
        </div>
        <c-pedido-dialog-pedido
          v-if="dataset.main.detail.id"
          v-model="showPrint"
          :pedido="dataset"
        />
        <c-base-dialog-associar-lotes
          v-if="dataset.main.detail.id && showAssociarLotes"
          :show="showAssociarLotes"
          :pedido="dataset"
          :context="context"
          @close="showAssociarLotes = false"
        />
      </template>
    </c-base-detail>
  </div>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import { differenceInHours, parseISO } from "date-fns";
import CBaseDetailInsert from "@/components/bases/CBaseDetailInsert";
import CBaseDialogAssociarLotes from "@/components/bases/CBaseDialogAssociarLotes";
import CBaseDialogEstado from "@/components/bases/CBaseDialogEstado";
import CPedidoDialogPedido from "@/components/pages/pedido/dialogs/CPedidoDialogPedido";
import CPedidoEdit from "@/components/pages/pedido/CPedidoEdit";
import CPedidoView from "@/components/pages/pedido/CPedidoView";
import mixinCarrinhos from "@/mixins/mixinCarrinhos";
import mixinPageDetail from "@/mixins/mixinPageDetail";

export default {
  /// NAME
  name: "Pedido",

  /// COMPONENTS
  components: {
    CBaseDetailInsert,
    CBaseDialogAssociarLotes,
    CBaseDialogEstado,
    CPedidoDialogPedido,
    CPedidoEdit,
    CPedidoView,
  },

  /// MIXINS
  mixins: [mixinCarrinhos, mixinPageDetail],

  /// DATA
  data() {
    const getConst = this.$store.getters["constantes/getConst"];
    const getEstado = this.$store.getters["estados/getEstado"];
    const getPagina = this.$store.getters["paginas/getPagina"];
    const getTipo_item = this.$store.getters["tipos/getTipo_item"];
    const getVar = this.$store.getters["variaveis/getVar"];

    return {
      idPage: getPagina("pedido").id,
      idPageAlt: getPagina("pedidos").id,

      dataset: {
        metadados: {
          modo: getConst("config").modo.cadastro.id,
          idUsuario: null,
          idPessoaParceiro: null,
        },
        main: {
          detail: {
            id: null,
            idTipo: getTipo_item("pedidoSaida").id,
            idEstado: getEstado("cadastro").id,
            isSuspenso: false,
            idPessoaParceiro: getVar("idPessoaParceiro"),
            isFechado: false,
            data: new Date(),
            idOrigem: getTipo_item("pedidoOrigemErp").id,
            idOperacao: null,
            idPessoa: null,
            idLocalEntrega: null,
            idUsuario: getVar("idUsuario"),
            idConvite: null,
            hasNotaFiscal: true,
            isEntregar: true,
            idPessoaEndereco: null,
            enderecoObservacao: null,
            enderecoEndereco: null,
            enderecoCidade: null,
            idPessoaTransportadora: null,
            transportadora: null,
            idModalTransporte: null,
            idIncoterm: null,
            dataPrevisaoEntrega: null,
            volumes: null,
            totalPesoLiquido: null,
            totalPesoBruto: null,
            totalPesoConsolidado: null,
            portadorNome: null,
            portadorDocumento: null,
            portadorPlacaVeiculo: null,
            codigoRastreio: null,
            isPagamentoOnline: false,
            idFormaPagamento: getTipo_item("formaPagamentoCreditoConta").id,
            troco: null,
            idCartao: null,
            idPessoaCartao: null,
            parcelas: 1,
            prazo1: 0,
            prazoN: null,
            prazoMedioPagamento: 0,
            variacao: 0,
            valorFrete: 0,
            valorOutrasDespesas: 0,
            valorImpostos: 0,
            valorTotal: null,
            valorTotalOriginal: 0,
            valorComissaoRevenda: 0,
            seuPedido: null,
            observacao: null,
            observacoes: null,
            idCarrinho: null,
            dataEstado: null,
            dataAtualizacao: null,
            dataFaturamento: null,
            dataEntrega: null,
          },
          itens: [],
          kardexes: [],
          resultados: [],
          contasPagRec: [],
          cupons: [],
          notasFiscais: {},
          avaliacoes: [
            {
              id: null,
              idPai: null,
              idAvaliador: null,
              idAvaliado: null,
              avaliacao: null,
              comentario: null,
              dataAvaliacao: null,
              resposta: null,
              dataResposta: null,
              pedidoModeracao: null,
              dataPedidoModeracao: null,
              isPublicar: null,
              idModerador: null,
              dataModeracao: null,
              observacao: null,
            },
            {
              id: null,
              idPai: null,
              idAvaliador: null,
              idAvaliado: null,
              avaliacao: null,
              comentario: null,
              dataAvaliacao: null,
              resposta: null,
              dataResposta: null,
              pedidoModeracao: null,
              dataPedidoModeracao: null,
              isPublicar: null,
              idModerador: null,
              dataModeracao: null,
              observacao: null,
            },
          ],
          avaliacoes_detalhes: [[], []],
        },
        aux: {
          detail: {
            pessoaParceiro: {
              idTipo: null,
              nome: null,
              nomeAlt: null,
              documentos: [],
              endereco: {},
              telefones: [],
              url: {},
            },
            pessoa: {
              idTipo: null,
              nome: null,
              nomeAlt: null,
              documentos: [],
              endereco: {},
              telefones: [],
              url: {},
            },
            pessoaParceiro_nomeAlt: null,
            pessoaParceiro_mediaAvaliacoes: null,
            pessoaParceiro_numeroAvaliacoes: null,
            pessoaParceiro_percentualCancelamentos: null,
            pessoa_nome: null,
            pessoa_mediaAvaliacoes: null,
            pessoa_numeroAvaliacoes: null,
            pessoa_percentualCancelamentos: null,
            fecharPedido: true,
          },
          cartao: [],
          itens: [],
          cupons: [],
          kardexes: [],
          resultados: [],
          contasPagRec: [],
          pessoaEntregador: {
            nomeAlt: null,
          },
          agendas: [],
          transacao: [],
          pedidosEntregasAtrasadas: [],
          autocomplete: {
            produtos: [],
          },
        },
      },

      funcs: {
        cancelarConfirm: (context) => {
          this.cancelar(context);
          // context.setSnackbar(
          //   true,
          //   "CANCELAR PEDIDO",
          //   "Cancelar o pedido?",
          //   "error",
          //   "mdi-check",
          //   "cancelar"
          // );
        },

        clickAssociarLotes: async () => {
          this.showAssociarLotes = true;
        },

        clickImprimir: async () => {
          this.showPrint = true;
        },

        clickNumerosSerie: async () => {
          this.$router.push(
            `/pedido/numerosserie/${this.dataset.main.detail.id}`
          );
        },

        clickSnackbar: (action, context) => {
          if (action == "delete") {
            context.delete(context.dataset.main.detail.id);
            context.setSnackbar();
          } else if (action == "cancelar") {
            this.cancelar(context);
            context.setSnackbar();
          } else if (action == "entregaAtrasada") {
            context.snackbar.params = true;
            context.snackbar.show = false;
          }
        },

        clickTransacao: (context) => {
          if (context.dataset.aux.transacao) {
            this.$router.push(`/transacao/${context.dataset.aux.transacao.id}`);
          } else {
            context.setSnackbar(
              true,
              "TRANSAÇÃO",
              "Este pedido não possui transação. \n Verificar.",
              "warning"
            );
          }
        },

        getSearchProduto: (context, index) => {
          const operacao = this.getOperacao(
            context.dataset.main.detail.idOperacao
          );

          const referencia =
            !!operacao.idOperacaoReferencia &&
            !context.dataset.aux.itens[index].referenciaExterna;

          const idPessoaFornecedor = !operacao.hasCliente
            ? context.dataset.main.detail.idPessoa
            : "0";

          const url = `/produtos/autocomplete/${
            referencia ? "pedidos" : "produtos"
          }/${
            context.dataset.main.detail.idPessoaParceiro
          }/${idPessoaFornecedor}/${getVar("locale")}/0/`;

          return url;
        },

        preValidate: async (context) => {
          if (!context.dataset.main.detail.id) {
            if (context.params.parcelasSync) {
              context.params.parcelasSync();
            }
          }

          if (
            context.dataset.main.detail.idOperacao &&
            context.dataset.main.detail.idPessoa &&
            !context.dataset.main.itens.length
          ) {
            this.setVar({ overlay: false });

            context.setSnackbar(
              true,
              "ITENS",
              "O pedido precisa ter pelo menos um item.",
              "error"
            );

            throw null;
          }

          if (
            context.dataset.aux.pedidosEntregasAtrasadas.length &&
            context.datasetTemp.main.detail.idEstado ==
              this.getEstado("recebido").id &&
            !context.dataset.aux.pedidosEntregasAtrasadas.find(
              (pedido) => pedido.id == context.dataset.main.detail.id
            )
          ) {
            const dataReferencia = new Date();

            const mindataPrevisaoEntrega = new Date(
              Math.min.apply(
                null,
                context.dataset.aux.pedidosEntregasAtrasadas.map((pedido) =>
                  parseISO(pedido.dataPrevisaoEntrega)
                )
              )
            );

            const diffInHours = differenceInHours(
              dataReferencia,
              mindataPrevisaoEntrega
            );

            const atrasoError =
              this.getConst("config").pedido.entrega.atrasoError;
            const atrasoWarning =
              this.getConst("config").pedido.entrega.atrasoWarning;

            if (diffInHours >= atrasoError) {
              this.setVar({ overlay: false });

              context.setSnackbar(
                true,
                "ENTREGAS ATRASADAS",
                `Você tem pedidos em andamento com entrega atrasada há mais de ${atrasoError} h. \n Por favor, gerencie antes esses pedidos.`,
                "error"
              );

              let interval = setInterval(() => {
                if (!context.snackbar.show) {
                  clearInterval(interval);
                  interval = null;

                  this.$router.push(
                    `/pedidos/${this.getFeature("filterEntregasAtrasadas").id}`
                  );
                }
              }, 100);

              throw null;
            } else if (diffInHours >= atrasoWarning) {
              this.setVar({ overlay: false });
              await context.setSnackbar(
                true,
                "ENTREGAS ATRASADAS",
                `Você tem pedidos em andamento com entrega atrasada há mais de ${atrasoWarning} h. \n Gostaria de gerenciar esses pedidos?`,
                "warning",
                "mdi-check",
                "entregaAtrasada",
                null,
                false
              );

              const resSnackbar = !!context.snackbar.params;
              context.setSnackbar();

              if (resSnackbar) {
                setTimeout(() => {
                  this.$router.push(
                    `/pedidos/${this.getFeature("filterEntregasAtrasadas").id}`
                  );
                }, 10);

                throw null;
              }

              this.setVar({ overlay: true });
            }
          }
        },

        posLoad: (context) => {
          this.params.context = context;

          setTimeout(() => {
            context.params.setSnackbar = context.setSnackbar;
          }, 10);
        },

        posSubmit: (context) => {
          context.params.context = context;

          if (!context.dataset.main.detail.id) {
            context.dataset.main.detail.idUsuario = this.getVar("idUsuario");
          }

          const operacao = this.getOperacao(
            context.dataset.main.detail.idOperacao
          );

          context.dataset.aux.detail.avaliacaoPedido = this.avaliacaoPedido;

          context.dataset.main.itens.forEach((item, i) => {
            const resultadoGerencial = context.dataset.main.resultados.find(
              (resultado) =>
                resultado.idPai2 == item.id && !resultado.isContabil
            );

            if (resultadoGerencial) {
              context.dataset.aux.itens[
                i
              ].margemContribuicao = `margem ${this.numberFormat(
                resultadoGerencial.valorContribuicao /
                  resultadoGerencial.precoBase,
                "percent",
                2
              )}`;
            }

            context.dataset.aux.itens[i].labelProduto = () =>
              context.dataset.aux.itens[i].referenciaExterna ||
              !operacao.idOperacaoReferencia
                ? "produto"
                : "pedido referencia";

            const labelReferenciaPedido = item.idPedidoItemReferencia
              ? ` pedido ${context.dataset.aux.itens[i].pedidoReferencia}`
              : "";
            const labelReferenciaNF = item.notaFiscalReferencia
              ? ` NF ${item.notaFiscalReferencia}, ${this.dateFormat(
                  item.dataNotaFiscalReferencia,
                  "date",
                  null,
                  true
                )}`
              : "";

            if (labelReferenciaPedido || labelReferenciaNF) {
              context.dataset.aux.itens[
                i
              ].labelReferencia = `ref:${labelReferenciaPedido}${labelReferenciaNF}`;
            }

            const decimalEstoque = context.dataset.aux.itens[i].decimalEstoque;
            const decimalValor = context.dataset.aux.itens[i].decimalValor;

            item.quantidade = parseFloat(item.quantidade).toFixed(
              decimalEstoque
            );

            item.preco = parseFloat(item.preco).toFixed(decimalValor);

            context.datasetTemp = JSON.parse(JSON.stringify(context.dataset));
            context.params.datasetTemp = context.datasetTemp;

            if (
              context.dataset.aux.itens[i].quantidadeMaxima &&
              context.dataset.aux.itens[i].quantidadeMinima
            ) {
              const multiplo =
                context.dataset.aux.itens[i].carrinhoQuantidade /
                context.dataset.aux.itens[i].quantidadeMaxima;

              const quantidadeMinima = (
                context.dataset.aux.itens[i].carrinhoQuantidade -
                (context.dataset.aux.itens[i].quantidadeMaxima -
                  context.dataset.aux.itens[i].quantidadeMinima) *
                  multiplo
              ).toFixed(decimalEstoque);

              const quantidadeMaxima =
                context.dataset.aux.itens[i].carrinhoQuantidade;

              context.params.rules[`quantidade${item.id}`] = [
                (value) =>
                  (!!value &&
                    value.clean(decimalEstoque) >=
                      parseFloat(quantidadeMinima)) ||
                  `min ${quantidadeMinima}`,
                (value) =>
                  (!!value &&
                    value.clean(decimalEstoque) <= quantidadeMaxima) ||
                  `max ${quantidadeMaxima}`,
              ];
            }
          });
        },
      },
      params: {
        datasetTemp: {},
        changeTipo: null,
        clickEntregar: null,
        context: null,
        notaFiscal: null,
        notaFiscal_serie: null,
        notaFiscal_data: null,
        pessoas: null,
        rules: {},
        tiposKardex: [
          { value: true, text: "contábil" },
          { value: false, text: "GERENCIAL" },
        ],
        tipoKardex: true,
        tiposResultado: [
          { value: true, text: "contábil" },
          { value: false, text: "GERENCIAL" },
        ],
        tipoResultado: true,
        tipoValor: [
          { value: true, text: "unitário" },
          { value: false, text: "total" },
        ],
      },

      showPrint: false,
      showAssociarLotes: false,
    };
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getEstado: "estados/getEstado",
      getFeature: "paginas/getFeature",
      getTipo: "tipos/getTipo",
    }),

    avaliacaoPedido() {
      let avaliacaoPedido = null;
      const avaliacao = this.dataset.main.avaliacoes.filter(
        (avaliacao) =>
          avaliacao.idAvaliador ==
          (this.dataset.main.detail.idPessoaParceiro ==
          this.getVar("idPessoaParceiro")
            ? this.dataset.main.detail.idPessoaParceiro
            : this.dataset.main.detail.idPessoa)
      );

      if (avaliacao.length) {
        avaliacaoPedido = parseFloat(avaliacao[0].avaliacao);
      }

      return avaliacaoPedido;
    },
  },

  /// METHODS
  methods: {
    alterado(context) {
      context.showEstado = false;
    },

    cancelar(context) {
      context.clickEditView();
      context.dataset.main.detail.idEstado = context.getEstado("cancelado").id;

      setTimeout(() => {
        this.$refs.motivo.focus();
      }, 10);
    },

    codigoPostalMascara(idPais) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalCodigoPostal").id
      )[0]?.mascara;

      return mascara;
    },

    documentoMascara(idPais, idTipo) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id,
        idTipo
      )[0]?.mascara;

      return mascara;
    },

    paramsCamposEspecificos() {
      const params = {
        notaFiscal: this.params.notaFiscal,
        notaFiscal_serie: this.params.notaFiscal_serie,
        notaFiscal_data: this.params.notaFiscal_data,
      };

      return params;
    },

    telefoneMascara(idPais) {
      const mascara = this.getLocalidade_configuracoesRegionais(
        idPais,
        this.getTipo_item("configuracaoRegionalTelefone").id
      )[0]?.mascara;

      return mascara;
    },
  },

  /// MOUNTED
  mounted() {
    this.getVar("socket").on("pedido", (dataset) => {
      if (
        this.$route.name == "Pedido/id" &&
        dataset.main.detail.id == this.dataset.main.detail.id
      ) {
        this.setVar({ overlay: true });
        this.dataset.main.detail = dataset.main.detail;

        setTimeout(() => {
          this.setVar({ overlay: false });
        }, this.getConst("config").overlay.timeout);
      }
    });
  },
};
</script>