<!-- COD: FDS 09/01/2023 CImpostos -->
<!-- #VER: JER __/01/2023 CImpostos -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <v-row v-bind="prop.row">
      <!-- pais -->
      <v-col
        v-bind="prop.col"
        v-if="dataset.idPais && order.text != 'localidade'"
        cols="auto"
      >
        <v-img
          alt="imagem"
          contain
          :src="getLocalidade(dataset.idPais).endereco"
          width="30"
        />
      </v-col>
      <!-- abrangencia -->
      <v-col v-bind="prop.col" v-if="dataset.idAbrangencia" cols="auto">
        <v-chip v-bind="prop.chip" class="ml-2">
          {{ getTipo_item(dataset.idAbrangencia).options.abrangenciaImposto }}
        </v-chip>
        <!-- nomeAlt -->
        <span class="ml-2 text-body-1">
          {{ dataset.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row" v-if="dataset.nome">
      <v-col v-bind="prop.col">
        <span class="text-body-1">
          {{ dataset.nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT

export default {
  /// NAME
  name: "CImpostos",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
    order: { type: Object },
  },
};
</script>