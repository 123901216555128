<!-- COD: FDS 01/07/2022 CFeriados -->
<!-- *VER: JER 01/07/2022 CFeriados -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- abrangencia -->
    <v-row v-bind="prop.row" v-if="dataset.idAbrangencia">
      <v-col v-bind="prop.col">
        <v-chip v-bind="prop.chip">
          {{ getTipo_item(dataset.idAbrangencia).options.abrangencia }}
        </v-chip>
      </v-col>
    </v-row>

    <!-- nome -->
    <v-row v-bind="prop.row" v-if="dataset.nome">
      <v-col v-bind="prop.col">
        <span class="text-h6">
          {{ dataset.nome }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";

export default {
  /// NAME
  name: "CFeedbacks",

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  // COMPUTED
  computed: {
    ...mapGetters({
      getTipo_item: "tipos/getTipo_item",
    }),
  },
};
</script>