<!-- COD: LYF 05/07/2022 CConviteView -->
<!-- *VER: JER 08/07/2022 CConviteView -->
<!-- /11/ -->

<template>
  <v-container v-bind="prop.container">
    <!-- pais -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <v-img
          alt="imagem"
          contain
          :src="getLocalidade(dataset.main.detail.idPais).endereco"
          width="30"
        />
      </v-col>
    </v-row>

    <!-- documento federal -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ documentoFederal }}
        </span>
      </v-col>
    </v-row>

    <!-- nomeAlt -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-h6">
          {{ dataset.main.detail.nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- email -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.main.detail.email }}
        </span>
      </v-col>
    </v-row>

    <!-- celular -->
    <v-row v-bind="prop.row" v-if="dataset.main.detail.celular">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ celularMasked }}
        </span>
      </v-col>
    </v-row>

    <!-- convidado por -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.aux.detail.pessoaColaborador_nomeAlt"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">convidado por</span>
      </v-col>
    </v-row>

    <!-- colaborador nomeAlt -->
    <v-row
      v-bind="prop.row"
      v-if="dataset.aux.detail.pessoaColaborador_nomeAlt"
    >
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{ dataset.aux.detail.pessoaColaborador_nomeAlt }}
        </span>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <span class="text-body-1">
          {{
            dateFormat(
              dataset.main.detail.data,
              "date timeNoSecond",
              null,
              true
            )
          }}
        </span>
      </v-col>
    </v-row>

    <!-- convidados -->
    <v-row v-bind="prop.row">
      <v-col v-bind="prop.col" align="center">
        <c-btn
          text
          class="text-body-1 ma-1"
          color="primary"
          @click="listaConvidados"
        >
          convidados
        </c-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/// IMPORT
import { mapGetters } from "vuex";
import mixinData from "@/mixins/mixinData";

export default {
  /// NAME
  name: "CConviteView",

  /// MIXINS
  mixins: [mixinData],

  /// PROPS
  props: {
    context: { type: Object },
    dataset: { type: Object, required: true },
  },

  /// COMPUTED
  computed: {
    ...mapGetters({
      getLocalidade: "localidades/getLocalidade",
      getLocalidade_configuracoesRegionais:
        "localidades/getLocalidade_configuracoesRegionais",
    }),

    configuracaoRegionalDocumento() {
      return this.getLocalidade_configuracoesRegionais(
        this.dataset.main.detail.idPais,
        this.getTipo_item("configuracaoRegionalDocumento").id,
        this.getTipo_item("pessoaFisica").id
      );
    },

    documentoFederal() {
      return this.dataset.main.detail.documentoFederal
        ? this.dataset.main.detail.documentoFederal.mask(
            this.configuracaoRegionalDocumento[0]?.mascara
          )
        : null;
    },

    celularMasked() {
      const idPais = this.dataset.main.detail.idPais;

      return this.dataset.main.detail.celular.mask(
        this.maskByTipo(idPais, null, "configuracaoRegionalTelefone")
      );
    },
  },

  /// METHODS
  methods: {
    listaConvidados() {
      this.$router.push(`/convidados/${this.dataset.main.detail.id}`);
    },
  },
};
</script>