// COD: FDS 10/08/2022 mixinBottomSheet
// *VER: JER 19/08/2022 mixinBottomSheet
// /21/

export default {
  /// NAME
  name: "mixinBottomSheet",

  /// DATA
  data() {
    return {
      bottomSheet: {
        height: "180px",
        show: false,
        text: "",
        title: "",
      },
      //? Acho que isto deve ser deprecado após a queda do builder
      bottomSheetData: {
        auth: [
          {
            title: "POR QUE PEDIMOS CPF?",
            text: "Usamos o CPF para evitar duplicidade de cadastro.",
            height: "180px"
          },
          {
            title: "POR QUE PEDIMOS CEP?",
            text:
              "Usamos o CEP para oferecer produtos dos parceiros que atendem sua região.",
            height: "180px"
          }
        ],
        senha: [
          {
            title: "SENHA",
            text:
              "A senha deve ter de 6 a 20 caracteres, com ao menos um maíusculo, um minúsculo e um número.",
            height: "180px"
          }
        ],
        cartao: [
          {
            title: "CÓDIGO DE SEGURANÇA",
            text:
              "Digite o código de segurança (cvv) de 3 ou 4 dígitos que está escrito no verso do cartão.",
            height: "180px"
          }
        ],
        pessoa: [
          {
            title: "ID ANTERIOR",
            text: "Id da pessoa no sistema anterior do parceiro.",
            height: "180px"
          },
        ],
        parceiro: [
          {
            title: "CAPACIDADE",
            text:
              "É a capacidade de atendimento do parceiro. Quantas pessoas o parceiro consegue atender simultaneamente.",
            height: "180px"
          },
          {
            title: "DURAÇÃO DA RESERVA",
            text:
              "Tempo de duração da reserva que o parceiro faz para seus clientes (horas).",
            height: "180px"
          },
          {
            title: "COMISSÃO BASE",
            text:
              "Comissão cobrada do parceiro sobre o valor do pedido, referente aos serviços de intermediação do app.",
            height: "180px"
          },
          {
            title: "COMISSÃO ADICIONAL",
            text:
              "Percentual cobrado do parceiro sobre o valor do pedido, referente ao pagamento online.",
            height: "180px"
          },
          {
            title: "ACERTO DE CONTAS",
            text:
              "Dia da semana que será feito o acerto de contas entre app e parceiro.",
            height: "180px"
          },
          {
            title: "RAIO DE ENTREGA",
            text:
              "Distância máxima que o parceiro efetua entrega de pedido (km).",
            height: "180px"
          },
          {
            title: "TEMPO PARA RETIRADA",
            text:
              "Tempo para a retirada do pedido pelo cliente(min).",
            height: "180px"
          },
          {
            title: "CATEGORIAS",
            text:
              "As categorias consideram as entregas selecionadas.\nSe a categoria desejada não estiver aparecendo, verifique as entregas selecionadas.",
            height: "180px"
          },
          {
            title: "AGRUPADOR",
            text:
              "Agrupador é um número dado aos grupos, que fará com que os serviços desses grupos sejam agendados, respeitando-se a ordem de seus grupos dentro do agrupador.",
            height: "180px"
          },
          {
            title: "MANTER ORDEM",
            text:
              "Este campo fará com que a ordem de agendamento de um serviço do grupo seja mantida no momento do agendamento, mesmo fora do agrupador.",
            height: "180px"
          },
        ],
        fornecedorCliente: [
          {
            title: "ID ANTERIOR",
            text: "Id do fornecedor/cliente no sistema anterior do parceiro.",
            height: "180px"
          },
        ],
        produto: [
          {
            title: "VARIAÇÃO DE QUANTIDADE",
            text: "Quantidade (Kg) mínima e máxima que cada pacote deste produto pode conter.\nNormalmente fica em branco, podendo ser usado em casos específicos\n(Ex: a unidade de medida da picanha é Kg e o pacote fechado é vendido com a variação de peso entre 0,8 a 1,5 Kg).",
            height: "240px"
          },
          {
            title: "PREÇO MÁXIMO",
            text: "Preço máximo que este produto pode ter.\nNormalmente fica em branco, podendo ser usado em casos específicos\n(Ex: o preço da coloração de cabelo pode variar entre R$200,00 a R$400,00).",
            height: "200px"
          },
          {
            title: "ID ANTERIOR",
            text: "Id do produto no sistema anterior do parceiro.",
            height: "180px"
          },
        ],
        publicacao: [
          {
            title: "RECORRÊNCIA",
            text:
              "Intervalo de tempo (em horas) em que esta publicação volta para o topo da página inicial.",
            height: "240px"
          },
          {
            title: "DATA DA PUBLICAÇÃO",
            text:
              "Data em que esta publicação será exibida na página inicial.",
            height: "240px"
          }
        ],
        cupom: [
          {
            title: "CÓDIGO DO CUPOM",
            text:
              "Informe o código do cupom, de preferência o nome do parceiro e o valor do desconto.",
            height: "180px"
          },
          {
            title: "VALOR OU PERCENTUAL",
            text:
              "Clique no icone ao lado para alternar entre valor ou percentual.",
            height: "180px"
          },
          {
            title: "SOMENTE NA PRIMEIRA COMPRA",
            text:
              "Cupom válido somente na primeira compra; não sendo aceito se cliente já tiver compra.",
            height: "180px"
          },
          {
            title: "SOMENTE EM UMA ÚNICA COMPRA",
            text:
              "Cupom válido somente em uma única compra; não sendo aceito em compras futuras.",
            height: "180px"
          },
          {
            title: "SOMENTE SE ÚNICO NA COMPRA",
            text:
              "Cupom válido se for o único na compra; não sendo aceito com outros cupons na mesma compra.",
            height: "180px"
          }
        ],
        carrinho: [
          {
            title: "QUANTIDADE",
            text:
              "Foi considerado múltiplos da quantidade máxima deste produto.\nApós a confirmação da quantidade que será entregue, a diferença, se houver, será automaticamente devolvida.",
            height: "200px"
          }
        ],
        pedido: [
          {
            title: "DEVOLUÇÃO",
            text: "Após o faturamento do pedido, será realizada a devolução da diferença.",
            height: "200px"
          },
          {
            title: "DEVOLUÇÃO",
            text: "Após o faturamento do pedido, será cancelada a reserva do cartão e cobrado o valor correto.",
            height: "200px"
          }
        ],
        transacao: [
          {
            title: "VALOR",
            text:
              "Valor da transação.",
            height: "180px"
          },
          {
            title: "CUPONS APP",
            text:
              "Valor total dos cupons emitidos pelo app.",
            height: "180px"
          },
          {
            title: "CUPONS PARCEIRO",
            text:
              "Valor total dos cupons emitidos pelo parceiro.",
            height: "180px"
          },
          {
            title: "VALOR APP",
            text:
              "Valor de responsabilidade do app.\n\nSe pagamento:\n\n- online = valor - cupons parceiro\n- não online = cupons app",
            height: "200px"
          },
          {
            title: "COMISSÃO",
            text:
              "Valor da comissão da transação.\n\ncomissão = valor * (taxa comissão + taxa cartão*)\n * se for pagamento online com cartão",
            height: "180px"
          },
          {
            title: "ACERTO",
            text:
              "Valor do acerto da transação.\n\nacerto = valor app - comissão",
            height: "180px"
          },
          {
            title: "SALDO",
            text:
              "Saldo das transações até esta transação.\n\nsaldo = saldo anterior + acerto",
            height: "180px"
          },
          {
            title: "TRANSAÇÃO PAGAMENTO",
            text:
              "Id da transação que foi feito o acerto(pagamento) desta transação.",
            height: "180px"
          },
          {
            title: "TRANSAÇÃO FATURAMENTO",
            text:
              "Id da transação que foi feito a nota fiscal de serviço desta transação.",
            height: "180px"
          }
        ],
        localidade: [
          {
            title: "ID ANTERIOR",
            text: "Id da localidade no sistema anterior do parceiro.",
            height: "180px"
          },
        ],
      }
    }
  },

  /// METHODS
  methods: {
    clickBottomSheet(item, index) {
      this.setBottomSheet(item, index)
    },

    setBottomSheet(item, index) {
      this.bottomSheet = {
        show: true, ...this.bottomSheetData[item][index]
      }
    },

    setUiBottomSheet(options) {
      if (typeof options.text === "object" && options.text.text) {
        options.types = options.text.types || null;
        options.actions = options.text.actions || null;
        options.text = options.text.text || null;
        options.alignText = options.text.alignText || 'text-center'
      }

      const heightBase = 60;
      const heightDefault = 180;
      const limitLines = 4;
      const heightLine = (heightDefault / limitLines);
      const lines = options?.text ? (Array.isArray(options.text) ? options.text : options.text.split("\n")).length : 0;
      const height = heightDefault + (lines <= limitLines ? 0 : ((lines - limitLines) * heightLine)) + heightBase;

      options.height = options.height || `${height}px`;

      this.bottomSheet = {
        show: true, ...options
      };
    }
  }
}